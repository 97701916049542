
const generateMessage = (transaction) => {
  return `Invoice ${transaction.quickbooks_doc_number} is created. ${transaction.order_type === 'PAYG' ? 'pay now or pay in instalments.' : ''}`
}

const transformInvoiceToNotificationData = (transaction, location) => {
  const url = '/retailer/finances'
  const notificationType = 'new-invoice'
  return {
    notification: {
      title: 'New Invoice',
      body: generateMessage(transaction)
    },
    data: {
      type: 'info',
      url,
      action: 'view',
      label: notificationType
    },
    // Unclear if these works, app uses data.url right now
    'webpush': {
      'fcm_options': {
        'link': url,
        'analytics_label': notificationType
      }
    },
    // SL Backend Props:
    locationId: location.fsid,
    event: `${transaction.id}:${transaction.txn_id}:${transaction.company_code}:created`
  }
}

module.exports = transformInvoiceToNotificationData
