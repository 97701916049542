module.exports = { docToEntity }

const cloneDeep = require('lodash/cloneDeep')

function docToEntity (doc, version = '1.0.0') {
  if (doc.version !== version) {
    throw new Error(`proposal docs at version ${version} not supported`)
  }

  return cloneDeep(doc)
}
