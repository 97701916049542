import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import SignaturePad from 'react-signature-pad-wrapper'

import DeemphasizedLink from '../DeemphasizedLink'

class SignPad extends Component {
  componentDidMount () {
    if (this.props.signature && this.signaturePad.instance) {
      this.signaturePad.instance.fromDataURL(this.props.signature)
    }
  }

  handleClear = () => {
    const { onValueChange } = this.props
    this.signaturePad.instance.clear()
    if (typeof onValueChange === 'function') {
      onValueChange('')
    }
  }

  render () {
    const {
      showLabel,
      clearButton,
      disabled,
      signature,
      hasError,
      onSignatureError,
      onSignatureChange,
      onValueChange,
      ...otherProps
    } = this.props
    return (
      <div className='signature-pad' height='200px'>
        {showLabel && (
          <div className='signature-pad__label'>
            Please sign here
          </div>
        )}
        <div className={classnames(
          'signature-pad__pad',
          {'signature-pad__pad--disabled': disabled},
          {'signature-pad__pad--has-error': hasError}
        )}>
          <SignaturePad
            ref={(el) => { this.signaturePad = el }}
            onError={onSignatureError}
            onChange={onSignatureChange}
            defaultWidth={400}
            height={200}
            redrawOnResize
            options={{
              onEnd: () => {
                const value = this.signaturePad.instance.toDataURL('image/svg+xml')
                if (typeof onValueChange === 'function') {
                  onValueChange(value)
                }
              }
            }}
            {...otherProps}
          />
        </div>
        {/*
          * This hidden button is here because SignaturePad kept auto activating the next focus element when user had drawn,
          * so if the next element is clear, it would keep clearing the signature. TODO: figure out why and remove this trap.
          */}
        <button className='vs-u-hide-visually' aria-hidden='true' />

        <div className={classnames(
          'signature-pad__actions',
          {'signature-pad__actions--hidden': !clearButton || disabled},
          'vs-u-hide-on-print'
        )}
        >
          <DeemphasizedLink
            onClick={() => this.handleClear()}
          >
            Clear
          </DeemphasizedLink>
        </div>
      </div>
    )
  }
}

SignPad.propTypes = {
  showLabel: PropTypes.bool,
  clearButton: PropTypes.bool
}

SignPad.defaultProps = {
  showLabel: true,
  clearButton: true
}

export default SignPad
