const { createAllocations, translateToStockCountRecord } = require('./tools/create-allocations')
const { createReportIdsOnProgram } = require('../../tools/create-report-ids-on-program')
const { getProgram, getFields } = require('../api/master-data')
const { getAll: getServices } = require('./../../service')

// TODO: move this to allocations api as a backend service
// see https://docs.google.com/document/d/1kNs1zGSxXeTaavEEA1Z0Eg6YxGr0d77ery5NWRpIsoA/edit#
exports.create = async function (
  state, { locations, products, programId, orders, date, reportingPeriod, orderType }
) {
  const program = await getProgram(state, programId)
  const keys = createReportIdsOnProgram({ locations, program, date, reportingPeriod })
  const reportDocs = await state.dal.order.listReportsByKeys(state, { keys })
  const services = await getServices(state, programId)
  const fieldDefinitions = await getFields(state, services)
  const reports = translateToStockCountRecord(reportDocs, services, fieldDefinitions)
  return createAllocations({ programId, locations, products, orders, reports, orderType })
}
