exports.getConfigurationsByIds = function (state, ids, params) {
  return state.dal.configuration.list(state, ids)
    .then(configurations => {
      if (!params || !params.includeProducts) {
        return configurations
      }
      // Get the products and add them to the configurations
      const productIds = configurations.reduce((ids, c) => {
        for (let pId of c.products) {
          ids.add(pId)
        }
        return ids
      }, new Set())

      return state.dal.product.getRaw(state, [...productIds])
        .then(products => {
          // Replace the product ids array with the actual products
          for (let c of configurations) {
            c.products = c.products.map(pId => products.find(p => p._id === pId))
          }
          return configurations
        })
    })
}
