// TODO: extract from VAN and move into van-shared
import React, { Component } from 'react'
import get from 'lodash/get'
import flowRight from 'lodash/flowRight'
import { connect } from 'react-redux'
import { addMonths } from 'date-fns'

import AddBatchPage from '../../../van-shared/components/AddBatchPage'
import { withApi } from '../../../common/ApiProvider'

import {
  updateProduct
} from './report-entry-reducer'

import withReport from './withReport'

import {
  batchesByIDForProductId,
  manufacturerOptions
} from './selectors'
import { updateCurrentMasterData } from '../../../van-shared/utils/utils'

const getBatchesFromReportEntry = (reportEntry, productId, expiryForm) => {
  if (!reportEntry) return []

  let indexedBatches = {}
  if (expiryForm) {
    indexedBatches = reportEntry.ongoingEntry.expiry.batches || {}
  } else if (reportEntry.ongoingEntry && reportEntry.ongoingEntry.batches) {
    indexedBatches = reportEntry.ongoingEntry.batches
  } else if (productId) {
    indexedBatches = reportEntry.report.stock[productId].batches
  }
  return indexedBatches
}

class AddBatchContainer extends Component {
  handleSubmit = ({
    batch,
    quantity
  }) => { // eslint-disable-line no-undef
    const {
      updateBatchQuantity,
      history
    } = this.props

    if (!(quantity && batch.manufacturer && batch.expiry)) {
      return
    }

    const update = {
      fields: {
        'field:standard-physical-count': {
          // TODO: https://github.com/fielded/van-orga/issues/1203
          amount: quantity
        }
      }
    }

    return updateBatchQuantity(batch, update, this.props.fields)
      .then(() => history.goBack())
  }

  render () {
    const {
      history,
      product,
      manufacturers,
      batchesByID,
      batchesInReport,
      expiryForm
    } = this.props

    const alreadyAddedBatches = Object.keys(batchesInReport).map(batchId => batchId)

    let latestExpiryDate
    if (expiryForm) {
      const inSixMonths = addMonths(new Date(), 6)
      latestExpiryDate = {
        year: inSixMonths.getFullYear(),
        month: inSixMonths.getMonth()
      }
    }

    return (
      <AddBatchPage
        allowExpired={expiryForm}
        latestExpiryDate={latestExpiryDate}
        batches={batchesByID}
        alreadyAddedBatches={alreadyAddedBatches}
        alreadyAddedLabel='already in report'
        manufacturers={manufacturers}
        product={product}
        onSubmit={this.handleSubmit}
        history={history}
      />
    )
  }
}

const mapStateToProps = (state, { match: { params } }) => {
  const newState = updateCurrentMasterData(state, 'masterDataReports')

  // Add batch in PSM expiry form
  const expiryForm = params.expiryForm === 'expiry'

  return {
    expiryForm: expiryForm,
    manufacturers: manufacturerOptions(newState),
    batchesByID: batchesByIDForProductId(newState, params.productId, false),
    fields: newState.masterData.fields,
    product: {
      id: params.productId,
      name: params.productId.split(':').pop(),
      definition: newState.masterData.products.byId[params.productId]
    },
    batchesInReport: getBatchesFromReportEntry(get(newState, 'reportEntry.data', {}), params.productId, expiryForm)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const reportId = get(ownProps, 'match.params.reportId')
  const productId = get(ownProps, 'match.params.productId')
  // Add batch in PSM expiry form
  const expiryForm = get(ownProps, 'match.params.expiryForm') === 'expiry'

  const maybeCreateBatch = (batch = {}) => {
    if (batch._id) {
      return Promise.resolve(batch)
    }
    // TODO: master data API expects batchNo, but AddBatch (from van-shared)sets
    // batchNumber. Align the two.
    const normalisedBatch = Object.assign({}, batch, {
      batchNo: batch.batchNumber
    })
    return ownProps.api.batch.new(normalisedBatch)
  }

  const updateBatchQuantity = (batch = {}, update = {}, fields = {}) => {
    return maybeCreateBatch(batch)
      .then(batch => {
        const updateKey = expiryForm ? `expiry:${batch._id}` : batch._id
        return dispatch(updateProduct(reportId, productId, updateKey, update, fields))
      })
  }

  return {
    updateBatchQuantity
  }
}

const withHOCs = flowRight(
  withApi,
  withReport,
  connect(mapStateToProps, mapDispatchToProps)
)

export default withHOCs(AddBatchContainer)
