import React from 'react'
import { Truck } from '@fielded/shared-ui/src/icons'

const shipmentsBreadcrumb = {
  title: 'Shipments',
  path: '/shipments/',
  icon: <Truck />
}

export default shipmentsBreadcrumb
