const installments = require('./common/installments')
const transactions = require('./common/transactions')
const { pending } = require('./common/pending')
const transactionsListSelector = require('./common/transactions-list-selector')
const installmentsListSelector = require('./common/installments-list-selector')

const transactionsSelect = transactionsListSelector(`
  LEFT JOIN LATERAL(
        SELECT "p"."id" FROM "avocado"."data_payment" as "p"
        LEFT JOIN "avocado"."data_invoice_quickbooks" as "i" ON "i"."invoice_id" = "p"."invoice_id"
        WHERE "t"."txn_id" = "i"."invoice_txn_id" AND "t"."company_code" = "i"."company_code"
   ) as "client_side_paid" on true
  WHERE "txn_type" = 'invoice' and "client_side_paid" IS NULL
  AND ("amount" - coalesce("amount_paid", 0) > 0 OR "due_date" > current_date)
`)

const installmentsSelect = installmentsListSelector(`
    WHERE
        (due_date <= (current_date + interval '6 days') AND "amount" - coalesce("amount_paid", 0) > 0) OR -- Return the next unpaid instalment (that’s due in the next 6 days)
        (due_date < current_date AND "amount" - coalesce("amount_paid", 0) > 0) -- Return all unpaid overdue instalments
`)

const createPinnedTransactionsListQuery = () => {
  const cte = [
    transactions,
    installments,
    pending,
    `transactions_list AS (
        SELECT tl.* FROM (
          ${transactionsSelect}
          UNION
          SELECT * FROM (${installmentsSelect}) il
        ) tl
        LEFT OUTER JOIN pending p
          ON p.location_id = tl.location_id
         AND p.quickbooks_doc_number = tl.quickbooks_doc_number
        WHERE p.id IS NULL
      )`
  ]
  return `
    WITH ${cte.join(', ')}
    SELECT * FROM transactions_list
  `
}

module.exports = { createPinnedTransactionsListQuery, transactionsSelect }
