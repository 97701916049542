import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

const PageComposableHeaderContent = ({
  children,
  spread
}) => (
  <div className={classnames(
    'vs-page-header__content',
    { 'vs-page-header__content--spread': spread }
  )}>
    {children}
  </div>
)

PageComposableHeaderContent.propTypes = {
  /**
   * The children to render
   */
  children: PropTypes.node.isRequired,

  /**
   * Spread to fill parent container?
   */
  spread: PropTypes.bool
}

PageComposableHeaderContent.defaultProps = {
  spread: false
}

PageComposableHeaderContent.displayName = 'Page.ComposableHeader.Content'

export default PageComposableHeaderContent
