const installmentQueryBuilder = require('../../finances/queries/common/installments-query-builder')

const installmentsQuery = () => {
  return `
    with installments AS (
      ${installmentQueryBuilder({instalmentTableJoin: false, isActiveClause: false, includeBalance: true})}
    )

    SELECT *
    FROM installments tl
    where payment_plan_id = $1
`
}

module.exports = {
  installmentsQuery
}
