module.exports = async (state, {
  reportId
}) => {
  let doc
  try {
    doc = await state.dal.report.read(state, reportId)
  } catch (err) {
    // We ignore not found because then the db is already in the desired state
    if (err.name !== 'not_found') {
      throw err
    }
    return
  }
  return state.dal.report.write(state, Object.assign({}, doc, {_deleted: true}))
}
