const installments = require('./common/installments')

const paymentPlanInstalmentsQuery = () => {
  const cte = [
    installments
  ]
  return `
    WITH ${cte.join(', ')}
    SELECT * FROM installments
  `
}

module.exports = { paymentPlanInstalmentsQuery }
