const getCurrentPrice = require('./get-current-price')

// DEFAULT_COLUMNS is the default header and content to be displayed in our exported
// it supports both string value or object value
// if object the value is gotten from the .transform
// else we just use the keys(Object.keys(DEFAULT_COLUMNS)) to extrapolate the value from product: product[key] and title
const DEFAULT_COLUMNS = {
  _id: {
    title: 'Product Id',
    transform: product => product._id.toLowerCase()
  },
  code: 'Code',
  name: 'Name',
  fullName: 'Full Name',
  productType: 'Product Type',
  storageType: 'Storage Type',
  basicUnit: 'Basic Unit',
  presentation: 'Presentation',
  prices: {
    title: 'Current Price',
    transform: product => getCurrentPrice(product.prices)
  },
  maxPrice: {
    title: 'Current Max Price',
    transform: product => getCurrentPrice(product.prices, 'maxPrice')
  },
  buyPrice: {
    title: 'Current Buy Price',
    transform: product => getCurrentPrice(product.buyPrices, 'buyPrice')
  },
  vat: {
    title: 'Current VAT',
    transform: product => getCurrentPrice(product.vats, 'vat')
  },
  description: 'Description'
}

const getRows = (products, opts = {}) => {
  opts = Object.assign({
    exclude: [],
    additionalColumns: {}
  }, opts)

  // can't exlude _id from export, since we need it for import
  opts.exclude = opts.exclude.filter(i => i !== '_id')

  const isExcluded = key => opts.exclude.indexOf(key) > -1
  const columns = Object.assign({}, DEFAULT_COLUMNS, opts.additionalColumns)

  // make both string column value and object return {title, transform}
  const columnProps = Object.keys(columns).filter(key => !isExcluded(key))
    .map(key => {
      const value = columns[key]
      if (typeof value === 'object') {
        return value // {title, transform}
      }
      return {title: value, transform: product => product[key]}
    })

  const headers = columnProps.map(column => column.title)
  const rows = []

  for (let product of products) {
    rows.push(columnProps.map(column => column.transform(product)))
  }

  return { rows, headers }
}

module.exports = {
  DEFAULT_COLUMNS, getRows
}
