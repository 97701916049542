const { removeDestination } = require('../../tools/write/remove-destination')

// delete destination from an ordercloseDestinationSnapshots
exports.closeDestinationSnapshots = closeDestinationSnapshots
async function closeDestinationSnapshots (state, {destinationId, groupId}) {
  const orders = await state.dal.order.getGroup(state, {groupId})
  const destinationDocsToRemove = removeDestination(orders, destinationId, state.user)

  try {
    await state.dal.order.bulkDocs(state, destinationDocsToRemove)
    return orders.filter((o) => o.destinationId !== destinationId)
  } catch (error) {
    throw new Error(`Unable to delete ${destinationId} from plan with groupId ${groupId}`)
  }
}
