const get = require('lodash/get')

module.exports = function toRelationalModel (route) {
  const programId = Object.keys(route.programs)[0]
  return {
    id: route.uuid,
    couchdb_id: route._id,
    couchdb_service_id: get(route, `programs.${programId}.services[0]`),
    name: route.name,
    delivery_day: route.day,
    delivery_cycle_weeks: route.deliveryFrequencyWeeks,
    first_delivery_date: route.firstDeliveryDate,
    count_day: route.countDay,
    count_cycle_weeks: route.countFrequencyWeeks,
    first_count_date: route.firstCountDate,
    created_at: route.createdAt,
    created_by: route.createdBy,
    updated_at: route.updatedAt,
    updated_by: route.updatedBy
  }
}
