const get = require('lodash/get')
const { ORDER_TYPES_TO_SUPPLIER_TYPE } = require('../../constants')

exports.getSupplierIdsForLocation = getSupplierIdsForLocation
function getSupplierIdsForLocation (state, location, options = {}) {
  const { planType, global } = options

  const supplierRelationships = location.supplierRelationships || {}

  if (global) {
    let allSuppliers = []
    Object.keys(supplierRelationships).forEach(plan => {
      allSuppliers.push(...supplierRelationships[plan])
    })
    return [...new Set(allSuppliers)]
  }

  const suppliers = get(supplierRelationships, `${ORDER_TYPES_TO_SUPPLIER_TYPE[planType]}`)

  if (!suppliers) {
    return []
  }
  return suppliers
}
