const get = require('lodash/get')
const { getTerritoryForProduct } = require('../../service/tools/territory-lookup')
const { translateTerritoryAlias, productAliasesByMarket } = require('../../product/tools/translate-territory-alias')
const { batchIdToProductId } = require('../../shipment/tools/product-batch-util')

exports.bulkTranslateShipmentProducts = bulkTranslateShipmentProducts
function bulkTranslateShipmentProducts (state, shipment, destination, allProducts, specificCountsObject) {
  const {logger} = state
  let {counts} = shipment
  if (specificCountsObject) {
    counts = specificCountsObject
  }

  const destinationService = get(destination, 'services[0]')
  const productsForTranslation = productAliasesByMarket(allProducts)

  return Object.keys(counts).reduce((acc, batch) => {
    const productId = batchIdToProductId(batch)
    const {service: productService} = getTerritoryForProduct(productId)

    if (productService !== destinationService) {
      const translatedProductId = translateTerritoryAlias(productId, productsForTranslation, destinationService)
      // If we could not translate this product log it to sentry.
      if (productId === translatedProductId) {
        logger.warn(`Error: Could not translate product ${productId} in delivery ${shipment.id}`)
      }
      const newBatchId = batch.replace(productId, translatedProductId)
      acc[newBatchId] = counts[batch]
      return acc
    }

    acc[batch] = counts[batch]
    return acc
  }, {})
}
