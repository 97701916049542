import React from 'react'

import { Button, FilterInput, Page } from '@fielded/shared-ui'
import { filterByProp } from '@fielded/shared-ui/src/components/FilterInput'
import sortBy from 'lodash/sortBy'

import { Arrow } from '@fielded/shared-ui/src/icons'
import ProductItem from '../../components/ProductItem'

const filterProducts = (products, filterValue) => {
  if (!filterValue || filterValue === '') return products
  return filterByProp(products, filterValue, 'name')
}

class NewPSMShipmentProducts extends React.Component {
  constructor (props) {
    super(props)
    const { shipment } = this.props
    let existingProducts = shipment.products.map(product => product._id) || []
    this.state = {
      selected: existingProducts,
      program: shipment.program.id,
      productFilterValue: ''
    }
  }

  handleSelection = async (target) => {
    const { selected } = this.state
    const { addProduct } = this.props
    if (!selected.includes(target.value)) {
      await addProduct(target.value)
      return this.setState({
        selected: selected.concat(target.value),
        error: null
      })
    } else {
      return this.handleRemoval(target.value)
    }
  }

  submit = () => {
    const { handleSubmit } = this.props
    const { selected } = this.state
    if (!selected.length) {
      return this.setState({
        error: 'At least one (1) product is required'
      })
    }
    handleSubmit()
  }

  handleRemoval = (id) => {
    const { selected } = this.state
    const { removeProduct } = this.props
    removeProduct(id)
    return this.setState({
      selected: selected.filter(p => p !== id)
    })
  }

  render () {
    const { cancel, goBack, shipment, products } = this.props
    const { error, selected, productFilterValue } = this.state
    const productOptions = products[shipment.program.id].map((product) => ({ value: product._id, name: product.fullName }))
    const sortedProducts = sortBy(productOptions, 'name')
    const filteredProducts = filterProducts(sortedProducts, productFilterValue)
    return (
      <Page>
        <Page.Intro title={'Select products'} />
        <Page.Panel className='product-bulk-selection new-psm-shipment__content-container'>
          {error && <div className='new-psm-shipment__error-wrapper'>
            {<span className='new-psm-shipment__errors'>{error}</span>}
          </div>
          }
          <Page.Panel.Section>
            <FilterInput
              placeholder='Find product …'
              value={productFilterValue}
              onValueChange={productFilterValue => {
                this.setState({
                  productFilterValue
                })
              }}
            />
          </Page.Panel.Section>
          <ul className='selectable-list'>
            {filteredProducts.map(product => {
              const isAlreadyAdded = selected.length > 0 && selected.includes(product.value)
              return <ProductItem
                product={product}
                selected={isAlreadyAdded}
                onToggle={() => this.handleSelection(product)}
                key={product.value}
              />
            })}
          </ul>
          {!filteredProducts.length && (
            <Page.Panel.Section>
              No products found {productFilterValue && `matching "${productFilterValue}"`}
            </Page.Panel.Section>
          )}
        </Page.Panel>
        <Page.Footer fixed>
          <Button
            onClick={this.submit}
            icon={<Arrow direction='right' />}
            iconSide='right'
            fill='full'
            colorVariant='brand'
          >
            Next
          </Button>
          <Button
            onClick={goBack}
            icon={<Arrow direction='left' />}
            iconSide='left'
            fill='outline'
            colorVariant='brand'
          >
            Prev
          </Button>
          <Button
            onClick={cancel}
            colorVariant='brand'
            alignOpposite
          >
            Cancel
          </Button>
        </Page.Footer>

      </Page>
    )
  }
}

export default NewPSMShipmentProducts
