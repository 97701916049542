const jjv = require('jjv')
const keyBy = require('lodash/keyBy')
const cloneDeep = require('lodash/cloneDeep')
const isEqual = require('lodash/isEqual')
const get = require('lodash/get')
const addSchemaDefaults = require('./pouch-schema-defaults')
const { mergeCouchResponse } = require('../tools/utils/couch-response')

// inputs are entities, outputs are entites.
// things the adapter reads & writes from pouch/couch are docs.
// this Adapter defaults one entity to one doc to cover some of the starter cases.
class PouchAdapter {
  constructor (user, schema, pouchDB) {
    if (typeof user !== 'object' || !user.name) {
      throw new Error('PouchAdapter usage: user object with name is required')
    }

    if (typeof schema !== 'object' || !schema.properties || !schema.name) {
      throw new Error('PouchAdapter usage: schema object with {properties: {}, name} is required')
    }

    if (typeof pouchDB !== 'object') {
      throw new Error('PouchAdapter usage: pouchDB is required')
    }

    this.schema = addSchemaDefaults(schema)
    this.jjvEnv = jjv()
    this.jjvEnv.addSchema(schema.name, this.schema)
    this.type = schema.name
    this.user = user
    this.pouchDB = pouchDB
  }

  validate (doc) {
    return this.jjvEnv.validate(this.schema.name, doc)
  }

  throwIfInvalid (doc) {
    const validationErrors = this.validate(doc)
    if (validationErrors) {
      const err = new Error()
      Object.assign(err, validationErrors)
      err.message = `Validation errors found ${doc.type} ${doc._id} ${JSON.stringify(validationErrors)}`
      throw err
    }
  }

  getSchemaDefaults () {
    return Object.keys(this.schema.properties)
      .reduce((acc, propName) => {
        const {default: propDefault} = this.schema.properties[propName]
        if (!propDefault) return acc

        acc[propName] = (typeof propDefault === 'function')
          ? propDefault(this)
          : propDefault
        return acc
      }, {})
  }

  // this is what all calls use to return something back to the entity api level.
  toEntity (doc) {
    const entity = cloneDeep(doc)
    if (doc._id) {
      entity.id = doc._id
      delete entity._id
    }
    delete entity._rev
    return entity
  }

  // toDocCreate & toDocUpdate are like our 'toDoc' now, but specific
  // to which is happening
  toDocCreate (entity) {
    const doc = Object.assign(
      this.getSchemaDefaults(),
      cloneDeep(entity)
    )
    if (entity.id) {
      doc._id = entity.id
      delete doc.id
    }
    return doc
  }

  toDocUpdate (entity, _rev) {
    if (!_rev) {
      throw new Error('usage: toDocUpdate requires _rev')
    }
    const doc = cloneDeep(entity)
    doc._rev = _rev
    doc._id = entity.id
    delete doc.id
    return doc
  }

  // this is expected to throw a 404 error.
  async get (id) {
    const doc = await this.pouchDB.get(id)
    return this.toEntity(doc)
  }

  async list (options) {
    console.warn('pouch-adapter.list comes with a big perf penalty client side, please try to use allDocs instead')
    const defaultOptions = {selector: {type: this.type}, limit: Number.MAX_SAFE_INTEGER}
    const {docs} = await this.pouchDB.find(Object.assign(defaultOptions, options))
    return docs.map(this.toEntity)
  }

  async find (params = {}) {
    const options = Object.assign({
      limit: Number.MAX_SAFE_INTEGER,
      params
    })
    const {docs} = await this.pouchDB.find(options)

    return docs.map(doc => this.toEntity(doc))
  }

  async getByIds (keys) {
    const {rows} = await this.pouchDB.allDocs({keys, include_docs: true})
    const missing = rows.filter(row => !row.doc)
    if (missing.length) {
      console.warn(`keys not found ${missing}`)
    }
    return rows.map(row => row.doc ? this.toEntity(row.doc) : row)
  }

  // this expects to throw if the entity already exists.
  async create (entity) {
    const doc = this.toDocCreate(entity)
    this.throwIfInvalid(doc)

    // confusing warning: pouch/couch return `id` on creates (and sometimes other functions)
    // but `_id` other times.
    // toEntity is going to expect an `_id` because normally docs have it.
    const response = await this.pouchDB.post(doc)
    return this.get(response.id)
  }

  async update (entity) {
    const {_rev} = await this.pouchDB.get(entity.id)
    const doc = this.toDocUpdate(entity, _rev)

    await this.pouchDB.put(doc)
    return entity
  }

  async remove (id) {
    if (typeof id !== 'string') throw new Error('remove expects id')
    const doc = await this.pouchDB.get(id)
    // doing this with pouch.remove(id) threw a 404
    return this.pouchDB.put(Object.assign({}, doc, {_deleted: true}))
  }

  // this can also returned a mixed bag of [{createdModel, {error!}}]
  // because there are no "all or nothing" bulk insertions in couch,
  // some succeed, some might not.
  async createMany (entities) {
    const docs = entities.map(row => this.toDocCreate(row))
    docs.forEach(doc => this.throwIfInvalid(doc))

    const response = await this.pouchDB.bulkDocs(docs)
    // response is not the full doc,
    // but we need to get the {id} out of it in case pouch/couch
    // was expected to auto-create it.
    return entities.map((row, index) => {
      if (response[index].error || !response[index].ok) {
        return response[index]
      }

      const _id = response[index].id

      return this.toEntity(Object.assign({_id}, docs[index]))
    })
  }

  // TODO: this could be done in batches of like 500ish
  // for 10k+ updates to couch that will network fail
  async updateMany (entities) {
    const revsResponse = await this.pouchDB.find({
      selector: {_id: {'$in': entities.map(entity => entity.id)}},
      limit: Number.MAX_SAFE_INTEGER,
      fields: ['_id', '_rev']
    })
    const revsById = keyBy(revsResponse.docs, '_id')
    const docs = entities
      .map(entity => this.toDocUpdate(entity, get(revsById[entity.id], '_rev')))

    docs.forEach(doc => this.throwIfInvalid(doc))

    // return the original entity sent if there was no error
    const response = await this.pouchDB.bulkDocs(docs)

    return entities.map((entity, index) => {
      if (response[index].error || !response[index].ok) {
        return response[index]
      }
      return entity
    })
  }

  /*
  will update if
  - existing doc is not deleted
  - existing row is not identical to this doc
  otherwise will insert
*/
  async bulkUpsert (docs, customMerge) {
    const keys = docs.map(doc => doc._id)
    const anyExistingDocs = await this.pouchDB.allDocs({ keys, include_docs: true })
    const upsertDocs = anyExistingDocs.rows.map((existingRow, i) => {
      // deleted values still return revs, but posting with those revs
      // will result in conflict. NB, then posting without those revs
      // will give you a later rev then the deleted one :)
      if (!existingRow.value || existingRow.value.deleted) {
        return docs[i]
      }
      const doc = customMerge ? customMerge(existingRow.doc, docs[i]) : docs[i]

      // We Stringify and then parse to remove props with functions or any other thing weird
      const noChanges = isEqual(JSON.parse(JSON.stringify(existingRow.doc)), JSON.parse(JSON.stringify(Object.assign({}, doc,
        {
          _rev: existingRow.value.rev,
          updatedAt: existingRow.doc.updatedAt,
          updatedBy: existingRow.doc.updatedBy
        }))))

      // skip docs which haven't changed
      if (noChanges) {
        return false
      }

      return Object.assign({}, doc, { _rev: existingRow.value.rev })
    })
      .filter(x => x)
    const response = await this.pouchDB.bulkDocs(upsertDocs)
    return mergeCouchResponse(upsertDocs, response)
  }
}

module.exports = PouchAdapter
