import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Page } from '@fielded/shared-ui'

import { CloseCircle, MinusCircle, PlusCircle } from '@fielded/shared-ui/src/icons'

import ShipmentHeader from '../common/ShipmentHeader'

const ShipmentOptions = (
  {
    breadcrumbItems,
    closeUrl,
    shipment,
    headerLocation,
    formattedDate,
    addProductsLink,
    deleteShipmentLink,
    deleteProducts,
    config
  }) => {
  return (
    <Page className='shipment-options'>
      <ShipmentHeader
        breadcrumbItems={breadcrumbItems}
        location={headerLocation}
        formattedDate={formattedDate}
        shipment={shipment}
        config={config}
        hasShipmentDetails
        isOptions
      />
      <Page.Panel withMargin={false} spread>
        <div className='shipment-options__content-wrapper'>
          <div className='shipment-options__content'>

            {/* hide date updating feature tempoarily for rollout. See https://github.com/fielded/van-orga/issues/771 */}
            {/* <div className='shipment-options__option'>
              <p className='shipment-options__option-intro'>You can change the date this shipment is expected to be distributed.</p>
              <Button
                component={Link}
                to={`/shipments/pick-list/${shipment.snapshotId}/shipment-options/change-date`}
                colorVariant='brand'
                fill='outline'
              >
                Change shipment date
              </Button>
            </div>

            <br /> */}

            {deleteProducts && (
              <div className='shipment-options__option'>
                <p className='shipment-options__option-intro'>If there is a whole product type that won’t be distributed, you can remove it.</p>
                <Button
                  component={Link}
                  to={`/shipments/pick-list/${shipment.snapshotId}/shipment-options/remove-products`}
                  colorVariant='brand'
                  fill='outline'
                  icon={<MinusCircle />}
                >
                  Remove products
                </Button>
              </div>
            )}

            <div className='shipment-options__option'>
              <p className='shipment-options__option-intro'>If there is a product missing from this shipment, you can add it.</p>
              <Button
                component={Link}
                to={addProductsLink}
                colorVariant='brand'
                fill='outline'
                icon={<PlusCircle />}
              >
                Add products
              </Button>
            </div>

            {deleteShipmentLink && <div className='shipment-options__option'>
              <p className='shipment-options__option-intro'>If this shipment was created mistakenly, you can remove it.</p>
              <Button
                component={Link}
                to={deleteShipmentLink}
                colorVariant='danger'
                fill='outline'
                icon={<MinusCircle />}
              >
                Delete shipment
              </Button>
            </div>}

            <footer className='shipment-options__close'>
              <Button
                component={Link}
                to={closeUrl}
                colorVariant='neutral'
                fill='outline'
                icon={<CloseCircle />}
              >
                Close
              </Button>
            </footer>
          </div>
        </div>
      </Page.Panel>
    </Page>
  )
}

export default ShipmentOptions
