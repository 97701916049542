const get = require('lodash/get')
const cloneDeep = require('lodash/cloneDeep')
const { getLastUpdated } = require('../utils')
const { getOrderStatus } = require('./statuses')
/*
  Heads up! This does not expose a snapshot history because nothing outside of
  the order api should know about order snapshots.
  If something's needed from snapshots, try exposing it as a prop on the entity.
*/
exports.docsToOrderEntity = function ({docs, withSnapshots, status, useAcceptedStatus = false}) {
  if (!docs || !docs.length) throw new Error('docsToOrderEntity requires docs')

  const entity = {}
  const snapshotsByStatus = getSnapshotsByStatus(docs)
  const snapshot = getStatusSnapshot(snapshotsByStatus, status, useAcceptedStatus)

  // 'original' and 'adjusted' product quantities on the snapshots will update
  // to be the 'warehouse' snapshot's 'adjusted' value when transitioning into status
  // 'accepted'.
  //
  // In this final status we want to return the original (what has been requested),
  // accepted (what has been accepted) and (if the order has already been fulfilled)
  // supplied (what has been delivered) values on the entity.
  //
  // In case the order is in a pre-accepted status, we can pass through the requested
  // status' product quantities as-is.
  entity.products = (status === 'accepted' || useAcceptedStatus)
    ? getAcceptedProducts(snapshotsByStatus, snapshot)
    : cloneDeep(snapshot.products)

  // Make sure comments array is there:
  Object.keys(entity.products).forEach(productId => {
    entity.products[productId].comments = entity.products[productId].comments || []
  })

  const defaults = [
    'destinationId', 'supplierId', '_id', 'createdAt', 'createdBy', 'version', 'programId', 'orderId', 'suborderId', 'groupId', 'funderId', 'closedAt', 'closedBy', 'closedStatus', 'deliveryDate', 'provisionalCost', 'request',
    'patientName', 'patientId', 'vat', 'deliveryFee', 'totalAmount', 'routeId'
  ]
  defaults.forEach(key => {
    entity[key] = snapshot[key]
  })

  entity.routeId = get(snapshot, 'routeId', snapshot.funderId)

  if (entity.routeId && entity.routeId.startsWith('funder')) {
    entity.routeId = entity.routeId.replace('funder:', 'route:')
  }

  let orderType = get(snapshot, 'orderType', 'routine')
  if (orderType === 'top-up') {
    // The spelling of the topup type changed at some point
    orderType = 'topup'
  }
  entity.orderType = orderType
  let paymentStatus = get(snapshot, 'paymentStatus')
  if (paymentStatus) {
    entity.paymentStatus = paymentStatus
  }

  let paymentTimestamp = get(snapshot, 'paymentTimestamp')
  if (paymentTimestamp) {
    entity.paymentTimestamp = paymentTimestamp
  }

  let paymentChoice = get(snapshot, 'paymentChoice')
  if (paymentChoice) {
    entity.paymentChoice = paymentChoice
  }

  let planningCycle = get(snapshot, 'planningCycle')
  if (planningCycle) {
    entity.planningCycle = planningCycle
  }

  // a requesting user sees the last updated at by any snapshot on the order
  // and the suppliers handling the order.
  // NB: this ended up not being used anywhere the user can see it yet
  // so we can change it if need be for the REST API
  const {updatedAt, updatedBy} = getLastUpdated(docs)
  Object.assign(entity, {updatedAt, updatedBy})
  entity.suppliers = docs.map(({supplierId}) => supplierId).filter(x => x)
  Object.assign(entity, getOrderStatus(snapshotsByStatus))

  return withSnapshots
    ? Object.assign({}, entity, {snapshotsByStatus})
    : entity
}

function getSnapshotsByStatus (docs) {
  return docs
    .reduce((acc, doc) => {
      acc[doc.status] = acc[doc.status] || []
      acc[doc.status].push(doc)
      return acc
    }, {})
}

function getStatusSnapshot (snapshotsByStatus, status, useLatestSnapshot) {
  let statusSnapshots = snapshotsByStatus[status]
  if (useLatestSnapshot) {
    statusSnapshots = snapshotsByStatus['accepted'] || snapshotsByStatus['warehouse'] || snapshotsByStatus['new'] || snapshotsByStatus['request']
  }

  if (!statusSnapshots) {
    console.error(statusSnapshots)
    throw new Error(`Could not find snapshot for status ${status}`)
  }

  return Object.assign({}, statusSnapshots[0], {
    // Heads up: If an order has more than one snapshot at a status
    // (just the "accepted" status after splitting on product.commodityType),
    // we expect the product list to be different, so this merge wouldn't be safe on
    // the same product across two snapshots.
    products: statusSnapshots
      .reduce((acc, snapshot) => Object.assign({}, acc, snapshot.products), {})
  })
}

// reminder: this only gets called for accepted status
function getAcceptedProducts (snapshotsByStatus, snapshot) {
  const products = cloneDeep(snapshot.products)
  // this guards against a missing 'new' snapshot (happens only in tests)
  const atNewStatus = snapshotsByStatus['new']
    ? getStatusSnapshot(snapshotsByStatus, 'new')
    : {}

  // If there's only a 'new' snapshot, and no other level
  // we can not do the transformation below because it will "flip" original/accepted values
  if (snapshotsByStatus['new'] && Object.keys(snapshotsByStatus).length === 1) {
    return atNewStatus.products
  }

  const atNewStatusProducts = atNewStatus.products || {}

  const allProductIds = [...new Set(Object.keys(products).concat(
    Object.keys(atNewStatusProducts)
  ))]

  return allProductIds.reduce((acc, productId) => {
    // NB confusing warning: we want the first number to be the adjusted
    // number from the first snapshot (the first user's adjustments), so new snapshot adjusted
    // and the second number to be the results of the pack out, which is the original number of the accepted snapshot.
    acc[productId] = {
      original: get(atNewStatusProducts, `${productId}.adjusted`, 0),
      // this one intentionally does not default to zero so we know it was never a value
      // in the excel export
      adjusted: get(products, `${productId}.original`),
      comments: get(products, `${productId}.comments`, [])
    }
    if (snapshot.closedStatus) {
      acc[productId].supplied = snapshot.closedStatus === 'failed'
        ? 0
        // this intentionally does not default to zero so we know it was never a value in the excel export
        : get(products, `${productId}.adjusted`)
    }
    return acc
  }, {})
}
