// Note: these also live in fs-api/lib/allocation/config
export const PAY_AS_YOU_SELL = 'pay_as_you_sell'
export const PAY_ON_DELIVERY = 'pay_on_delivery'
export const NOT_SUBSCRIBED = 'not_subscribed'

export const DIRECT_ORDER_TYPES = {
  PAY_ON_DELIVERY: 'pay_on_delivery',
  PAY_AS_YOU_SELL: 'pay_as_you_sell',
  IMMEDIATE_PURCHASE: 'immediate_purchase',
  NOT_SUBSCRIBED: 'not_subscribed'
}

export const DIRECT_ORDER_TYPES_HUMANIZED = {
  [DIRECT_ORDER_TYPES.PAY_ON_DELIVERY]: 'Pay As You Go',
  [DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL]: 'Pay As You Sell',
  [DIRECT_ORDER_TYPES.IMMEDIATE_PURCHASE]: 'Immediate Purchase',
  [DIRECT_ORDER_TYPES.NOT_SUBSCRIBED]: 'Currently Not Subscribed'
}
