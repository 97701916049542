const { sha512 } = require('./../../tools/hash')

module.exports = getDebitStatus
async function getDebitStatus (getDebitStatusOptions) {
  const {mandateId, requestId} = getDebitStatusOptions
  const {merchantId, apiKey} = this

  const headers = {
    'Content-Type': 'application/json'
  }

  const hash = await sha512(`${mandateId}${merchantId}${requestId}${apiKey}`)
  const body = {
    'merchantId': merchantId,
    'hash': hash,
    'requestId': requestId,
    'mandateId': mandateId
  }

  return this.fetch(this.endpoints.getDebitStatus, {
    method: 'POST',
    body: JSON.stringify(body),
    headers
  })
}
