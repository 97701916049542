const {
  TABLE_NAMES: {
    TRANSACTIONS_TABLE_NAME,
    PAYMENT_DEBIT_TABLE_NAME,
    DATA_PAYMENTS_TABLE_NAME,
    PAYG_CREDIT_PAYMENTS_TABLE_NAME
  }
} = require('../../constants')

const pendingPaymentDebits = `
  SELECT
    p.id,
     p.data ->> 'locationId' as "location_id",
    due_date,
    p.amount,
    amount_paid,
    null AS description,
    'pending invoice payment' AS txn_type,
    quickbooks_doc_number,
    p.updated_at AS txn_date,
    p.created_at,
    p.updated_at,
    t.order_type,
    null::jsonb AS payment_plan,
    null AS shipment_id,
    null AS order_id
  FROM ${PAYMENT_DEBIT_TABLE_NAME} p
  INNER join ${TRANSACTIONS_TABLE_NAME} t
    ON t.txn_type = 'payment'
   AND t.description ilike  '%' || ("data" -> 'remitaResponse' -> 'body' ->> 'transactionRef') || '%'
   AND t.location_id = p.data->>'locationId'
  WHERE p.data->'remitaResponse'->'body'->>'status' = 'Successful' AND t.id is null
`

const pendingPayments = `
  SELECT
    p.id,
    p.location_id,
    due_date,
    -- The amount from paystack is in the currencies subunit, convert to main unit
    case when p.total_amount > 0 then p.total_amount
    else p.amount_in_cents / 100  end as amount,
    amount_paid,
    null AS description,
    'pending invoice payment' AS txn_type,
    quickbooks_doc_number,
    p.updated_at AS txn_date,
    p.created_at,
    p.updated_at,
    t.order_type,
    null::jsonb AS payment_plan,
    null AS shipment_id,
    null AS order_id
  FROM ${DATA_PAYMENTS_TABLE_NAME} p
  LEFT JOIN  "avocado"."data_quickbookstransactions" t
    ON t.txn_type = 'payment'
   AND t.description = "p"."payment_data" ->> 'reference'
   AND t.location_id = p.location_id
  WHERE (p.status = 'success' OR p.status = 'client_side_paid')
  and p.payment_method != 'credit' -- we need exclude credits from pending payments list
  and t.id IS NULL
  -- Payments made prior to the specified date should not be included in the pending payment logic.
  and p.created_at > '2023-06-08'
`

const pendingPaygCreditPayments = `
  SELECT
    p.id,
    p.location_id,
    due_date,
    -- The amount from paystack is in the currencies subunit, convert to main unit
    p.amount_in_cents / 100 as amount,
    amount_paid,
    null AS description,
    'pending invoice payment' AS txn_type,
    quickbooks_doc_number,
    p.updated_at AS txn_date,
    p.created_at,
    p.updated_at,
    t.order_type,
    null::jsonb AS payment_plan,
    null AS shipment_id,
    null AS order_id
  FROM ${PAYG_CREDIT_PAYMENTS_TABLE_NAME} p
  INNER join ${TRANSACTIONS_TABLE_NAME} t
     ON t.txn_id = p.qbo_txn_id
    AND t.company_code = p.qbo_company_code
    AND t.amount_paid < t.amount
`

const pendingInstallmentPayments = `
  SELECT
    p.id,
    location_id,
    i.due_date,
    p.amount,
    amount_paid,
    null AS description,
    'pending installment payment' AS txn_type,
    code AS quickbooks_doc_number,
    p.updated_at AS txn_date,
    p.created_at,
    p.updated_at,
    null as order_type,
    i.payment_plan,
    null AS shipment_id,
    null AS order_id
  FROM ${PAYMENT_DEBIT_TABLE_NAME} p
  INNER JOIN installments i
    ON i.code = p.reference
   AND i.location_id = p.data->>'locationId'
   AND i.amount_paid < i.amount
  WHERE p.data->'remitaResponse'->'body'->>'status' = 'Successful'
  OR p.data -> 'paystackResponse' ->> 'status' = 'success'
`

const pending = `
pending AS (
  ${pendingPaymentDebits}
  UNION
  ${pendingPayments}
  UNION
  ${pendingInstallmentPayments}
  UNION
  ${pendingPaygCreditPayments}
)
`

const pendingSelector = (whereCondition) => `SELECT * FROM pending ${whereCondition || ''}`

module.exports = {
  pending,
  pendingSelector
}
