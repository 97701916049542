import React from 'react'
import PropTypes from 'prop-types'

import PrintHeader from './PrintHeader/PrintHeader'
import PrintSection from './PrintSection/PrintSection'

const PrintPage = ({children}) => {
  return (
    <div className='print-page'>
      {children}
    </div>
  )
}

PrintPage.propTypes = {
  /**
   * The child elements of the PrintPage.
   * It can be anything, we recommend using predefined ones:
   * PrintPage.Header, PrintPage.Section
   */
  children: PropTypes.node
}

PrintPage.Header = PrintHeader
PrintPage.Section = PrintSection

export default PrintPage
