import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'

/**
 *  Generic <DeemphasizedLink/> Component.
 */
const DeemphasizedLink = (props) => {
  const {
    children,
    colorVariant,
    className,
    bold,
    ...otherProps
  } = props

  return (
    <Button
      fill='none'
      className={classnames(
        'vs-deemphasized-link',
        {'vs-deemphasized-link--bold': bold},
        {'vs-deemphasized-link--danger': colorVariant === 'danger'},
        {'vs-deemphasized-link--brand': colorVariant === 'brand'},
        className
      )}
      {...otherProps}
    >
      { children }
    </Button>
  )
}

DeemphasizedLink.propTypes = {
  /**
   * Content of deemphasized link. Can be text or any tag valid by HTML standards.
   */
  children: PropTypes.node,
  /**
   * Use the colorVariant to change the color of a label.
   */
  colorVariant: PropTypes.oneOf(['danger', 'brand', 'light']),

  /**
   * In case you want to extend the default class names without overriding the default look.
   * e.g adding custom padding class `className='add-padding'`
   */
  className: PropTypes.string

}

DeemphasizedLink.defaultProps = {
  colorVariant: undefined
}

export default DeemphasizedLink
