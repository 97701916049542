/*
 * this is more of a debounce than a throttle
 * it keeps the last set of arguments and
 * calls the function after the last call has been made
 */
export function simpleThrottle (func, limit) {
  let timeout

  return function () {
    const args = arguments
    const context = this

    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func.apply(context, args)
    }, limit)
  }
}
