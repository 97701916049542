const get = require('lodash/get')
const set = require('lodash/set')
const cloneDeep = require('lodash/cloneDeep')

exports.resetQTOs = function ({snapshots, productId}) {
  const differentSnapshots = snapshots
    .filter(doc => {
      const original = get(doc, `products.${productId}.original`, 0)
      const adjusted = get(doc, `products.${productId}.adjusted`, 0)
      return original !== adjusted
    })

  return differentSnapshots
    .map(doc => {
      const updatedDoc = cloneDeep(doc)
      const original = get(doc, `products.${productId}.original`, 0)
      set(updatedDoc, `products.${productId}.adjusted`, original)
      return updatedDoc
    })
}
