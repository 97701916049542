import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Accordion from '../../../Accordion'
import Radio from '../../../Radio'
import Checkbox from '../../../Checkbox'

import { FieldContext } from '../'
import { requiredValidationErrorMessage } from '../../utils'
import FormFieldRegistrationManager from '../../FormFieldRegistrationManager'

const FormAccordion = ({
  options,
  defaultValue,
  required,
  validate,
  onValueChange,
  multi,
  withLightBackground,
  className,
  ...otherProps
}) => (
  <FieldContext.Consumer>
    {
      (field) => (
        <FormFieldRegistrationManager
          register={() => {
            field.register({
              value: defaultValue,
              required,
              validate,
              requiredValidator: async (value) => {
                if (Array.isArray(value) && value.length === 0) {
                  // this is a multi choice, check for length instead
                  return required && requiredValidationErrorMessage(required)
                } else if (!value) {
                  return required && requiredValidationErrorMessage(required)
                }
              },
              ...otherProps
            })
          }}
          deregister={() => field.deregister()}
        >
          <section
            className={classnames(
              'vs-form-accordion',
              {'vs-form-accordion--with-light-background': withLightBackground},
              {'vs-form-accordion--error': field.hasErrors()},
              className
            )}
          >
            {options && (
              <Accordion>
                {options.map(option => {
                  const isSingleOption = options.length === 1
                  const isDisabled = option.disabled
                  const currentValue = field.props.value || defaultValue || (multi ? [] : '')
                  const isOptionSelected = multi
                    ? currentValue.indexOf(option.value) >= 0
                    : currentValue === option.value
                  const isExpanded = isOptionSelected || isSingleOption

                  return (
                    <Accordion.Section
                      key={option.value}
                      className={
                        classnames(
                          'vs-form-accordion__options',
                          { 'vs-form-accordion__options--enabled': isOptionSelected }
                        )
                      }
                      isExpanded={isExpanded}
                    >
                      <Accordion.Section.Header className='vs-form-accordion__header'>
                        <div className='vs-form-accordion__option-toggle'>
                          {multi ? (
                            <Checkbox.Stateless
                              {...option}
                              name={option.value}
                              checked={isOptionSelected}
                              onChange={event => {
                                let nextValue
                                if (event.target.checked) {
                                  nextValue = currentValue.concat(event.target.name)
                                } else {
                                  nextValue = currentValue.filter(v => v !== event.target.name)
                                }
                                field.handleOnChange(nextValue)
                                onValueChange(nextValue, currentValue)
                              }}
                              disabled={isDisabled}
                            />
                          ) : (
                            <Radio
                              {...option}
                              name={field.props.fieldName}
                              isSelected={isOptionSelected}
                              onChange={event => {
                                const nextValue = event.target.value
                                field.handleOnChange(nextValue)
                                onValueChange(nextValue)
                              }}
                              disabled={isDisabled}
                            />
                          )}
                        </div>
                      </Accordion.Section.Header>
                      {isExpanded && (
                        <Accordion.Section.Content className='vs-form-accordion__content'>
                          {option.content}
                        </Accordion.Section.Content>
                      )}
                    </Accordion.Section>
                  )
                })}
              </Accordion>
            )}
          </section>
        </FormFieldRegistrationManager>
      )
    }
  </FieldContext.Consumer>
)

FormAccordion.displayName = 'Form.Field.Accordion'
FormAccordion.hasLabels = true

FormAccordion.propTypes = {
  /**
    * Array of available top level options
    */
  options: PropTypes.array.isRequired,

  /**
   * Fires when user selects/deselects an option.
   * Returns the value(s) of the selected option(s) as string/array (if multi)
   */
  onValueChange: PropTypes.func,

  /**
   * Whether the field is required, optionally pass a string to define a custom
   * validation error message
   */
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),

  /**
   * A custom validation function
   */
  validate: PropTypes.func,

  /**
   * Any additional className you want to add
   */
  className: PropTypes.string

}

FormAccordion.defaultProps = {
  required: false,
  validate: undefined,
  onValueChange: () => null,
  defaultValue: undefined
}

export default FormAccordion
