const get = require('lodash/get')
const sortBy = require('lodash/sortBy')
const { addMilliseconds } = require('date-fns')
const getDoc = require('./offtrend-get')

const DAY = 24 * 60 * 60 * 1000
const addOfftrend = async (state, params) => {
  const doc = await getDoc(state, params)
  const updateTime = new Date().toJSON()

  const {
    facilityId,
    programId,
    username,
    funders,
    productId,
    startDate,
    endDate,
    value
  } = params

  const oldOfftrends = get(doc, `products.${productId}`, [])
  let days = (new Date(endDate).getTime() - new Date(startDate).getTime()) / DAY
  days = Math.round(days) // let's only do full days for now
  const dailyValue = value / days

  const newItem = {
    startDate,
    endDate,
    value,
    dailyValue
  }

  // Figure out dates:
  // 1. Remove items covered by new item
  let newOfftrends = oldOfftrends.filter(item => {
    return !(item.startDate >= startDate && item.endDate <= endDate)
  })
  // 2. Adjust dates of overlapping items
  newOfftrends.forEach(item => {
    if (item.startDate < startDate && item.endDate >= startDate) {
      item.endDate = addMilliseconds(startDate, -1).toJSON()
    }

    if (item.startDate <= endDate && item.endDate > endDate) {
      item.startDate = addMilliseconds(endDate, 1).toJSON()
    }
  })
  // 3. Push new item + sort
  // You can remove an applied offtrend value by saying 'null'
  if (typeof value !== 'undefined' && value !== null) {
    newOfftrends.push(newItem)
  }

  newOfftrends = sortBy(newOfftrends, ['startDate'])

  const newDoc = {
    // These 4 props will be added on new docs,
    // but overwritten by existing data on existing docs
    facilityId,
    programId,
    createdBy: username,
    createdAt: updateTime,
    funders,
    ...doc,
    updatedBy: username,
    updatedAt: updateTime,
    products: {
      ...doc.products,
      [productId]: newOfftrends
    }
  }

  const { rev } = await state.allocationsDB.put(newDoc)

  return {
    ...newDoc,
    _rev: rev
  }
}

module.exports = addOfftrend
