const validateProductsExist = require('./validate-products-exist')
module.exports = validateServiceProducts

async function validateServiceProducts (state, service, products) {
  if (!service) throw new Error('Property \'service\' is required')

  products.forEach(product => {
    if (typeof product !== 'string' || !product.match(/product:/)) {
      throw new Error(`Product id "${product}" is invalid.`)
    }
    if (product.indexOf(',') > -1) {
      throw new Error(`Product id "${product}" is invalid.`)
    }
  })

  await validateProductsExist(state, products)
}
