import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import flowRight from 'lodash/flowRight'

import { Loading } from '@fielded/shared-ui'

import { hasNewRootReducer } from '../../../van-shared/utils/utils'
import withConfig from '../../../van-shared/hoc/withConfig'
import { withUser } from '../../../common/AuthenticationProvider'
import { withApi } from '../../../common/ApiProvider'
import { configureAppStore } from './../../../root/store'
import { persistedReducer } from './root-reducer'
import AppContainer from '../app/AppContainer'
import SyncViewPage from '../../../van-shared/components/SyncViewPage'

import 'react-day-picker/lib/style.css'

class Root extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    isSyncFinished: PropTypes.bool,
    shipmentsCanBeAccessed: PropTypes.bool,
    percentComplete: PropTypes.number
  }

  constructor (props) {
    super(props)

    const { config, user, api } = props

    // TODO: remove this when the feature flag is no longer needed
    const withNewRootReducer = hasNewRootReducer()

    this.state = { withNewRootReducer }

    if (!withNewRootReducer) {
      this.store = configureAppStore(
        persistedReducer, config, {}, { user, api }
      )
    }
  }

  render () {
    const {
      routerParams,
      rootHistory,
      config,
      isSyncFinished,
      shipmentsCanBeAccessed,
      percentComplete
    } = this.props

    const { withNewRootReducer } = this.state

    if (!shipmentsCanBeAccessed) {
      return <Loading />
    }

    if (!isSyncFinished) {
      return <SyncViewPage progress={percentComplete} />
    }

    if (!withNewRootReducer) {
      const persistor = persistStore(this.store)

      return (
        <Provider store={this.store}>
          <PersistGate persistor={persistor}>
            <AppContainer
              rootHistory={rootHistory}
              routerParams={routerParams}
              config={config}
            />
          </PersistGate>
        </Provider>
      )
    }

    return (
      <AppContainer
        rootHistory={rootHistory}
        routerParams={routerParams}
        config={config}
      />
    )
  }
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig
)

export default withHOCs(Root)
