import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const StatusCardContent = ({
  contentLabel,
  contentText,
  children,
  className
}) => (
  <div
    className={classnames(
      'vs-status-card__content',
      className
    )}
  >
    {contentLabel && (
      <span className='vs-status-card__content-label'>
        { contentLabel }
      </span>
    )}

    {contentText && (
      <span className='vs-status-card__content-text'>
        { contentText }
      </span>
    )}

    { children }
  </div>
)

StatusCardContent.propTypes = {
  /**
   * The status card content label to display
   */
  contentLabel: PropTypes.string,
  /**
   * The status card content text/node to display
   */
  contentText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Set custom CSS classes to extend the StatusCardContent for your needs.
   */
  className: PropTypes.string,
  /**
   * Content that is not structured as `title: text`
   */
  children: PropTypes.node
}

StatusCardContent.defaultProps = {
  contentLabel: undefined,
  contentText: undefined,
  className: '',
  children: undefined
}

StatusCardContent.displayName = 'StatusCard.Content'

export default StatusCardContent
