const COLUMN_WIDTH_DEFAULT = undefined
const COLUMN_WIDTH_WIDE = 200
const COLUMN_WIDTH_X_WIDE = 200
const COLUMN_WIDTH_NARROW = 60

const ROW_HEIGHT_DEFAULT = undefined
const ROW_HEIGHT_HIDDEN = 0
const ROW_HEIGHT_HIGH = 30
const ROW_HEIGHT_X_HIGH = 60
const ROW_HEIGHT_XX_HIGH = 90

const STYLE_TEXT_CENTER = {
  alignment: {
    horizontal: 'center',
    vertical: 'center',
    wrapText: true
  },
  sz: 10
}

const STYLE_TEXT_WHITE = {
  color: { rgb: 'ffffff' }
}

const STYLE_TEXT_BOLD = {
  bold: true
}

const STYLE_BACKGROUND_FAWN = {
  fgColor: { rgb: 'f8cbad' }
}

const STYLE_BACKGROUND_FAWN_LIGHT = {
  fgColor: { rgb: 'fbe5d6' }
}

const STYLE_BACKGROUND_BLUE = {
  fgColor: { rgb: 'b4c7e7' }
}

const STYLE_BACKGROUND_BLUE_LIGHT = {
  fgColor: { rgb: 'deebf7' }
}

const STYLE_BACKGROUND_GREEN = {
  fgColor: { rgb: 'c5e0b4' }
}

const STYLE_BORDER = {
  top: { style: 'thin' },
  right: { style: 'thin' },
  bottom: { style: 'thin' },
  left: { style: 'thin' }
}

const LOCATION_HEADERS = ['Site ID', 'State', 'IPName', 'Type of service', 'SiteName', 'LGA', 'Order Type']
const PADDING_LEFT = LOCATION_HEADERS.length
const PADDING_TOP = 5
const PER_PRODUCT_HEADERS = ['Order Quantity', 'Validated Order Quantity', 'Quantity Supplied', 'Variance']
const PER_PRODUCT_COLUMNS = PER_PRODUCT_HEADERS.length

const HEADLINE_FACILITY_PROFILE = 'Facility Profile'

const PER_LOCATION_TOTALS_HEADERS = [
  'Count of Commodity line issued',
  'Volume of Commodities issued (cubic metre)',
  'Weight of Commodities issued (Kilogram)',
  'Value of Commodities issued (US Dollar)'
]

const PER_PRODUCT_TOTALS_HEADERS = [
  'Total Quantity',
  'Unit Volume',
  'Unit Weight',
  'Unit Price',
  'Total Volume issued',
  'Total Weight issued',
  'Total Price issued'
]

module.exports = {
  COLUMN_WIDTH_DEFAULT,
  COLUMN_WIDTH_WIDE,
  COLUMN_WIDTH_X_WIDE,
  COLUMN_WIDTH_NARROW,
  ROW_HEIGHT_DEFAULT,
  ROW_HEIGHT_HIDDEN,
  ROW_HEIGHT_HIGH,
  ROW_HEIGHT_X_HIGH,
  ROW_HEIGHT_XX_HIGH,
  STYLE_TEXT_CENTER,
  STYLE_TEXT_WHITE,
  STYLE_TEXT_BOLD,
  STYLE_BACKGROUND_FAWN,
  STYLE_BACKGROUND_FAWN_LIGHT,
  STYLE_BACKGROUND_BLUE,
  STYLE_BACKGROUND_BLUE_LIGHT,
  STYLE_BACKGROUND_GREEN,
  STYLE_BORDER,
  LOCATION_HEADERS,
  PADDING_LEFT,
  PADDING_TOP,
  PER_PRODUCT_HEADERS,
  PER_PRODUCT_COLUMNS,
  HEADLINE_FACILITY_PROFILE,
  PER_LOCATION_TOTALS_HEADERS,
  PER_PRODUCT_TOTALS_HEADERS
}
