import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { default as RegularButton } from '../../Button'
import DeemphasizedLink from '../../DeemphasizedLink'

import { FormContext } from '../Form'

const Button = ({
  onClick,
  type,
  resetConfirmationText,
  children,
  variant,
  alignOpposite,
  colorVariant,
  className,
  ...otherProps
}) => {
  return (
    <FormContext.Consumer>
      {(form) => {
        const handleOnClick = (event) => {
          event.preventDefault()

          if (typeof onClick === 'function') {
            return onClick(form)
          }

          if (type === 'reset') {
            if (resetConfirmationText) {
              if (window.confirm(resetConfirmationText)) {
                return form.reset()
              }
            } else {
              return form.reset()
            }
          }

          return form.submit()
        }

        const classes = classnames(
          'vs-form-button',
          className
        )

        if (variant === 'deemphasized') {
          return (
            <span className={classes}>
              <DeemphasizedLink
                onClick={handleOnClick}
                {...otherProps}
              >
                {children}
              </DeemphasizedLink>
            </span>
          )
        }

        return (
          <span className={classes}>
            <RegularButton
              type={type}
              onClick={handleOnClick}
              colorVariant={colorVariant || (type === 'submit' ? 'brand' : 'neutral')}
              fill='full'
              {...otherProps}
            >
              {children}
            </RegularButton>
          </span>
        )
      }}
    </FormContext.Consumer>
  )
}

Button.propTypes = {
  /**
   * The button alignment can be offset from other buttons in left and right aligned footers
   * This depends on the Button to be in a Actions container.
   */
  alignOpposite: PropTypes.bool,

  /**
   * The type of Form.Button. `submit|reset|button` (default: submit)
   */
  type: PropTypes.oneOf([
    'submit',
    'reset',
    'button'
  ]),

  /**
   * Classname(s) to apply to the button wrapper
   */
  className: PropTypes.string,

  /**
   * The Button Color Variant
   */
  colorVariant: PropTypes.oneOf(['neutral', 'dark', 'light', 'brand', 'warning', 'confirmation', 'danger']),

  /**
   * If type is reset and this is set, the user will be prompted
   * with this in a confirmation prompt before resetting
   */
  resetConfirmationText: PropTypes.string,

  /**
   * Style variant (regular|deemphasized)
   */
  variant: PropTypes.oneOf([
    'regular',
    'deemphasized'
  ]),

  /**
   * Optional onClick handler
   */
  onClick: PropTypes.func
}

Button.defaultProps = {
  type: 'submit',
  resetConfirmationText: undefined,
  className: undefined,
  colorVariant: undefined,
  onClick: undefined,
  variant: 'regular',
  alignOpposite: false
}

Button.displayName = 'Form.Button'

export default Button
