import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import AccordionSectionHeader from './AccordionSectionHeader'
import AccordionSectionContent from './AccordionSectionContent'

class AccordionSection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      contentVisible: this.props.isExpanded
    }
  }

  static Header = AccordionSectionHeader
  static Content = AccordionSectionContent

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func
    ]).isRequired,
    className: PropTypes.string,
    isExpanded: PropTypes.bool
  }

  static defaultProps = {
    className: '',
    isExpanded: false
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    return {
      ...prevState,
      contentVisible: nextProps.isExpanded
    }
  }

  render () {
    const { className, children } = this.props
    const { contentVisible } = this.state

    return (
      <div
        className={
          classnames(
            'vs-accordion__section',
            {'vs-accordion__section--expanded': contentVisible},
            className
          )
        }
      >
        { children }
      </div>
    )
  }
}

export default AccordionSection
