/** Create the relationship graph from a list of locations
 *
 * The graph will have the following types of nodes and edges:
 *
 * Nodes:
 *  [location]
 *  [geolocation]
 *  [level]
 *
 * Edges:
 *  [Location] ----[supplies]----> [Location]
 *  [Location] ----[supplied-by]-> [Location]
 *  [GeoLocation] -[contains-]---> [Location]
 *  [Level] -------[ranks]-------> [Location]
 */
const { createLocationId, createGeoLocationId, createLevelId } = require('./id-utils')
const { getNodeRelationships } = require('./get-node-relationships')

/** Get the supplier location id from the suppliee location id
 *
 * Relationships are currrently baked into the ids. This is a
 * helper function to get the supplier id from a locations id.
 */
const getSupplierIdFromId = (locationId, locationNodesMap) => {
  if (locationId === 'national') {
    return
  }
  const nameIndex = locationId.indexOf(':name:')
  const endIndex = nameIndex !== -1 ? nameIndex : locationId.length
  const parts = locationId.slice(0, endIndex - 2).split(':')
  for (let i = parts.length - 2; i >= 0; i -= 2) {
    const id = parts.slice(0, i).join(':')
    if (locationNodesMap.has(id)) {
      return id
    }
  }
  if (locationNodesMap.has('national')) {
    return 'national'
  }
}

/** Get the supply relationship edges of a location
 */
const getSupplyEdges = (locationNode, locationNodesMap) => {
  const relationships = getNodeRelationships(locationNode, locationNodesMap)

  if (!relationships) {
    // No explicit relationships defined, we infer the supplier from the location id
    const supplierId = getSupplierIdFromId(locationNode.location._id, locationNodesMap)
    if (supplierId == null) {
      return []
    }
    return [
      [createLocationId(supplierId), 'supplies', locationNode.id],
      [locationNode.id, 'supplied-by', createLocationId(supplierId)]
    ]
  }
  const edges = []
  if (relationships.suppliedBy) {
    for (const supplierId of relationships.suppliedBy) {
      edges.push(
        [createLocationId(supplierId), 'supplies', locationNode.id],
        [locationNode.id, 'supplied-by', createLocationId(supplierId)]
      )
    }
  }
  if (relationships.supplies) {
    for (const supplieeId of relationships.supplies) {
      edges.push(
        [locationNode.id, 'supplies', createLocationId(supplieeId)],
        [createLocationId(supplieeId), 'supplied-by', locationNode.id]
      )
    }
  }
  return edges
}

/** Get the nodes and edges of the relationship graph
 *
 * By looking at the locations we can create a relationship graph of
 * location, geolocation and level nodes connecting
 * each other. This function will create the nodes and edges.
 * Edges are always of a type, e.g. "supplies", so that the complete
 * edges looks like `[location-a, supplies, location-b]`
 */
exports.createNodesAndEdges = (locations) => {
  // We will create nodes of different types and store them in maps
  // keyed by id, so that we will not have duplicate nodes and can
  // have fast lookups for nodes where needed.
  const locationNodesMap = new Map()
  const geoLocationNodesMap = new Map()
  const levelNodesMap = new Map()

  // Create the location nodes first to have a lookup map for locations
  for (const location of locations) {
    const locationNode = {
      id: createLocationId(location._id),
      type: 'location',
      location
    }
    locationNodesMap.set(location._id, locationNode)
  }

  // Create all edges and the rest of the nodes
  const edges = []
  for (const locationNode of locationNodesMap.values()) {
    // add edges for supply relationships
    edges.push(...getSupplyEdges(locationNode, locationNodesMap))
    const location = locationNode.location

    // add level nodes and edges
    const levelId = createLevelId(location.level)
    const levelNode = {id: levelId, type: 'level'}
    levelNodesMap.set(levelId, levelNode)
    edges.push([levelId, 'ranks', locationNode.id])

    // add geolocation nodes and edges
    if (location.location) {
      const geoLocation = location.location.id
      const geoParts = geoLocation.split(':')
      for (let i = 2; i <= geoParts.length; i += 2) {
        const geoId = geoParts.slice(0, i).join(':')
        const geoNode = {id: createGeoLocationId(geoId), type: 'geolocation'}
        geoLocationNodesMap.set(geoId, geoNode)
        edges.push([geoNode.id, 'contains', locationNode.id])
      }
    }
  }
  const nodes = [
    ...locationNodesMap.values(),
    ...geoLocationNodesMap.values(),
    ...levelNodesMap.values()
  ]
  return [nodes, edges]
}
