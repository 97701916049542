import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const StatusPill = ({
  label,
  value,
  withAccent,
  className
}) => (
  <div className={
    classnames(
      'vs-status-pill',
      {'vs-status-pill--accent': withAccent},
      className
    )
  }>
    <span className='vs-status-pill__label'>
      {label}
    </span>
    <span className='vs-status-pill__value'>
      {value}
    </span>
  </div>
)

StatusPill.propTypes = {
  /**
   * Displayed label for the StatusPill
   */
  label: PropTypes.string,
  /**
   * Displayed value for the StatusPill
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The status `null|success`
   */
  withAccent: PropTypes.bool,
  /**
   * Optional className for further styling
   */
  className: PropTypes.string
}

StatusPill.defaultProps = {
  label: undefined,
  value: undefined,
  withAccent: false,
  className: ''
}

export default StatusPill
