import React, { useState } from 'react'

import { Form, Page } from '@fielded/shared-ui'

// fixme: this need to be changed https://github.com/fielded/field-supply/pull/3060 after this merge. its already there
const countries = [{
  name: 'Nigeria',
  states: [{
    name: 'Lagos',
    cities: ['Lagos']
  }, {
    name: 'Oyo',
    cities: ['Oyo']
  }, {
    name: 'Edo',
    cities: ['Edo']
  }, {
    name: 'Kaduna',
    cities: ['Kaduna']
  }, {
    name: 'Ogun',
    cities: ['Ogun']
  }, {
    name: 'Nasarawa',
    cities: ['Nasarawa']
  }, {
    name: 'Rivers',
    cities: ['Port harcourt']
  }, {
    name: 'Kano',
    cities: ['Kano']
  }, {
    name: 'Enugu',
    cities: ['Enugu']
  }, {
    name: 'Delta',
    cities: ['Delta']
  }, {
    name: 'Kwara',
    cities: ['Kwara']
  }, {
    name: 'Akwa Ibom',
    cities: ['Uyo']
  }, {
    name: 'Osun',
    cities: ['Osogbo']
  }, {
    name: 'Ekiti',
    cities: ['Ado Ekiti']
  }, {
    name: 'Ondo',
    cities: ['Akure']
  }]
}, {
  name: 'Kenya',
  states: [{
    name: 'Nairobi',
    cities: ['Nairobi']
  }, {
    name: 'Coastal region',
    cities: ['Mombasa']
  }, {
    name: 'Nyanza',
    cities: ['Kisumu']
  }, {
    name: 'Central Province',
    cities: ['Kiambu']
  }]
}]

const countriesOptions = countries.map(c => {
  return {
    value: c.name,
    label: c.name
  }
})

// fixme: code duplication from signup-wizard-and-value-caclulator PR. need make generic solution after merging
export default (props) => {
  const { formData } = props
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  const filterCountry = name => {
    const filteredCountry = countries.find(c => c.name === name)
    if (filteredCountry) {
      setStates(filteredCountry.states.map(s => {
        return {
          value: s.name,
          label: s.name
        }
      }))
    }
  }

  const filterState = data => {
    const { country, state } = data
    const filteredCountry = countries.find(c => c.name === country)
    if (filteredCountry) {
      if (!state) {
        setCities([])
      }
      const filteredState = filteredCountry.states.find(s => s.name === state)
      if (filteredState) {
        const cities = filteredState.cities.map(s => {
          return {
            value: s,
            label: s
          }
        })
        setCities(cities)
        if (cities.length === 1) {
          // formData.city = cities[0].value
        }
      }
    }
  }

  const onCountryChange = e => {
    filterCountry(e.value)
    setCities([])
  }

  const onStateChange = e => {
    const formFields = formData.current.getFields()
    filterState({ country: formFields.country, state: e.value })
  }

  return (
    <Page.Panel.Section title='Location'>
      <Form.Row>
        <Form.Field labelText='Country' fieldName='country'>
          <Form.Field.Select
            options={countriesOptions}
            onChange={onCountryChange}
            required
          />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Field labelText='State' fieldName='state'>
          <Form.Field.Select
            options={states}
            onChange={onStateChange}
            required
          />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Field labelText='City' fieldName='city'>
          <Form.Field.Select
            options={cities}
            required
          />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Field labelText='Address' fieldName='address'>
          <Form.Field.Text required />
          <Form.Field.ValidationErrors />
        </Form.Field>
      </Form.Row>
    </Page.Panel.Section>
  )
}
