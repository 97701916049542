const { format, addDays, isFuture } = require('date-fns')
const first = require('lodash/first')
const { smartId } = require('../tools')
const { ACTIVE_MANDATE_DATE_DELAY, GRACE_PERIOD, OVERDUE_AMOUNT_THRESHOLD } = require('./constants')

const getDueDate = ({
  hasActiveMandate,
  dueDate
}) => {
  dueDate = format(addDays(dueDate, GRACE_PERIOD))
  if (hasActiveMandate) {
    dueDate = format(addDays(dueDate, ACTIVE_MANDATE_DATE_DELAY))
  }
  return dueDate
}

function getInvoiceStatus (invoiceData) {
  const {
    amount,
    amount_paid: amountPaid
  } = invoiceData

  const invoiceIsOpen = amountPaid < amount
  if (invoiceIsOpen) {
    if (!amountPaid) return INVOICE_STATUS.open

    return INVOICE_STATUS.incomplete
  }

  return INVOICE_STATUS.closed
}

/**
 * get **DUE DATE** of invoice with most recent **TRANSACTION DATE**
 * @param invoices
 * @returns {string|*}
 */
function getDueDateOfLastInvoice (invoices) {
  const sortedByTxnDate = invoices.sort((a, b) => a.txn_date < b.txn_date)
  if (sortedByTxnDate[0]) return sortedByTxnDate[0].due_date
}

function getIsInvoicePaid (invoice) {
  return invoice.amount_paid >= invoice.amount
}

function getIsInvoiceOverdue (invoice, hasActiveMandate, isLegacy) {
  const today = format(new Date(), 'YYYY-MM-DD')
  let dueDate = getDueDate({
    hasActiveMandate,
    dueDate: invoice.due_date
  })
  if (isLegacy) {
    dueDate = hasActiveMandate ? format(addDays(invoice.due_date, ACTIVE_MANDATE_DATE_DELAY), 'YYYY-MM-DD') : invoice.due_date
  }
  const dueDateFormatted = format(dueDate, 'YYYY-MM-DD')
  return (dueDateFormatted <= today) && !getIsInvoicePaid(invoice)
}

function getAllInvoicesArePaid (invoices) {
  return invoices.every(getIsInvoicePaid)
}

function getDateOfLastUpdate (invoices) {
  const sortedByUpdateDate = invoices.filter((d) => d.amount_paid < d.amount).sort((a, b) => a.updated_at < b.updated_at)
  if (sortedByUpdateDate[0]) return sortedByUpdateDate[0].updated_at
}

function getUnpaidInvoicesCount (invoices) {
  return invoices.length - invoices.filter(getIsInvoicePaid).length
}

function getInvoiceType (invoice) {
  if (invoice.quickbooks_doc_number && invoice.quickbooks_doc_number.endsWith('-MDP')) {
    return 'mdp'
  }

  return 'regular'
}

const INVOICE_STATUS = {
  closed: 'closed',
  incomplete: 'incomplete',
  open: 'open'
}

// groups rows of invoice data to documents, eg:
// [{ invoiceRowWithLocationIncluded }] => { locationId1: { ...locationData, invoices: [{...invoiceData0}, {...invoiceDataN}, ...] }, ...]
// (see test for example)
// todo: would prefer this was done in avocado
function groupInvoicesByLocation (invoices) {
  return invoices.reduce((acc, locationInvoiceSmsRecord) => {
    let newAcc = {...acc}

    const invoiceData = {
      id: locationInvoiceSmsRecord.id,
      quickbooks_id: locationInvoiceSmsRecord.quickbooks_id,
      quickbooks_doc_number: locationInvoiceSmsRecord.quickbooks_doc_number,
      due_date: locationInvoiceSmsRecord.due_date,
      txn_date: locationInvoiceSmsRecord.txn_date,
      amount: locationInvoiceSmsRecord.amount,
      amount_paid: locationInvoiceSmsRecord.amount_paid
    }

    const locationData = {
      uuid: locationInvoiceSmsRecord.uuid,
      fsid: locationInvoiceSmsRecord.fsid,
      owners_phone_number: locationInvoiceSmsRecord.owners_phone_number
    }

    if (!newAcc[locationData.uuid]) {
      newAcc[locationData.uuid] = {
        ...locationData,
        invoices: [invoiceData]
      }
    } else {
      newAcc[locationData.uuid].invoices.push(invoiceData)
    }

    return newAcc
  }, {})
}

function countOverdueInvoices (invoices) {
  return invoices.filter(getIsInvoiceOverdue).length
}

function getOverdueInstalments (instalments, hasActiveMandate, isLegacy) {
  const today = format(new Date(), 'YYYY-MM-DD')
  if (isLegacy) {
    return instalments.filter(instalment => {
      const dueDate = new Date(hasActiveMandate ? addDays(instalment.due_date, ACTIVE_MANDATE_DATE_DELAY) : instalment.due_date)
      const formatDueDate = format(dueDate, 'YYYY-MM-DD')
      return instalment.payment_plan.is_active && !instalment.payment_plan.is_paid && formatDueDate < today
    })
  }
  return instalments.filter(instalment => {
    const dueDate = getDueDate({
      hasActiveMandate,
      dueDate: instalment.due_date
    })
    const formatDueDate = format(dueDate, 'YYYY-MM-DD')
    return instalment.payment_plan.is_active && !instalment.payment_plan.is_paid && formatDueDate <= today
  })
}

const blockingRules = {
  legacy: ({ invoices, hasActiveMandate, paymentPlansInstalment, country }) => {
    const overdueInvoices = invoices ? invoices.filter(invoice => getIsInvoiceOverdue(invoice, hasActiveMandate, true)) : []
    const overdueInstalments = getOverdueInstalments(paymentPlansInstalment, hasActiveMandate, true)
    const invoicesOverdueCount = overdueInvoices.length
    const overdueInstalmentsCount = overdueInstalments.length
    const overdueAmount = [...overdueInvoices, ...overdueInstalments].reduce((acc, t) => {
      acc = acc + (t.amount - t.amount_paid)
      return acc
    }, 0)
    const threshold = OVERDUE_AMOUNT_THRESHOLD[country] || 0

    const isInvoicesOverdue = invoicesOverdueCount > 2
    const isPaymentPlanInstalmentsOverdue = overdueInstalmentsCount >= 1

    return (isInvoicesOverdue || isPaymentPlanInstalmentsOverdue) && (overdueAmount > threshold)
  },
  default: ({ invoices, hasActiveMandate, paymentPlansInstalment, country }) => {
    const overdueInvoices = invoices ? invoices.filter(invoice => getIsInvoiceOverdue(invoice, hasActiveMandate)) : []
    const overdueInstalments = getOverdueInstalments(paymentPlansInstalment, hasActiveMandate)
    const invoicesOverdueCount = overdueInvoices.length
    const overdueInstalmentsCount = overdueInstalments.length
    const overdueAmount = [...overdueInvoices, ...overdueInstalments].reduce((acc, t) => {
      acc = acc + (t.amount - t.amount_paid)
      return acc
    }, 0)
    const threshold = OVERDUE_AMOUNT_THRESHOLD[country] || 0

    const isInvoicesOverdue = invoicesOverdueCount >= 1
    const isPaymentPlanInstalmentsOverdue = overdueInstalmentsCount >= 1

    return (isInvoicesOverdue || isPaymentPlanInstalmentsOverdue) && (overdueAmount > threshold)
  }
}

async function isPaused (invoices, hasActiveMandate, paymentPlansInstalment = [], locationApi) {
  const locationId = invoices && first(invoices) && first(invoices).location_id
  const { blockingRule } = await locationApi.getLocationBlockingRule(locationId)
  const { id: fsid } = locationApi.adapter.user.location
  const { country } = smartId.parse(fsid)
  if (blockingRule === 'bypass') {
    return false
  }
  return blockingRules[blockingRule]({ invoices, hasActiveMandate, paymentPlansInstalment, country })
}

const getPaymentPlansBalance = (paymentPlan) => {
  const unpaidInstalments = []

  return paymentPlan.instalments.reduce((bal, instalment) => {
    bal.outstandingLoanBalance += (instalment.amount - instalment.amount_paid)
    bal.totalLoanBalance += instalment.amount

    if (instalment.amount_paid < instalment.amount) {
      unpaidInstalments.push(instalment)
      bal.unpaidInstalmentCount = unpaidInstalments.length

      if (isFuture(instalment.due_date) && !paymentPlan.waive_service_fee) {
        bal.futureInstalmentFees += paymentPlan.service_fee
      }
    }

    return bal
  }, {
    outstandingLoanBalance: 0,
    totalLoanBalance: 0,
    unpaidInstalmentCount: 0,
    futureInstalmentFees: 0
  })
}

module.exports = {
  getIsInvoicePaid,
  getIsInvoiceOverdue,
  getInvoiceStatus,
  getUnpaidInvoicesCount,
  getAllInvoicesArePaid,
  getDateOfLastUpdate,
  getDueDateOfLastInvoice,
  groupInvoicesByLocation,
  countOverdueInvoices,
  getInvoiceType,
  isPaused,
  getPaymentPlansBalance,
  getDueDate
}
