const get = require('lodash/get')
const keyBy = require('lodash/keyBy')
const {isDiscontinued} = require('../../product/tools')
const { DIRECT_ORDER_TYPES } = require('../../allocation/config')

exports.createForecastAllocations = async function (state, mainApi, {locations, deliveryDate}) {
  const warnings = []
  locations.forEach(location => {
    if (!Object.keys(location.subscriptions).length) {
      warnings.push({
        locationId: location._id,
        locationName: location.fullName,
        errorType: 'subscription_missing',
        message: `${location._id} ${location.name} No subscriptions found`
      })
    }
  })
  const locationsWithSubscriptions = locations
    .filter(l => Object.keys(l.subscriptions).length)

  const forecastsByLocationId = {}
  // TODO: in parallel, this often fails.
  // in series, it's very slow. Probably some optimizations
  // we can make in the avocado query + rest framework view.
  for (let i = 0; i < locationsWithSubscriptions.length; i++) {
    const location = locationsWithSubscriptions[i]
    const forecasts = await mainApi.forecast.list({
      locationIds: [location._id]
    })
    forecastsByLocationId[location._id] = forecasts[0]
  }

  const allProducts = await mainApi.product.listAll()
  const allProductsById = keyBy(allProducts, '_id')

  const allocations = locationsWithSubscriptions.map(location => {
    const {products, warnings: productWarnings} = getProducts(location, forecastsByLocationId[location._id], allProductsById)
    warnings.push(...productWarnings)
    return {
      location,
      products
    }
  })
  return {allocations, warnings}
}

function getProducts (location, forecast = {}, allProducts) {
  const latestAllocations = forecast.latestAllocation
  const warnings = []
  const locationSubscriptions = location.subscriptions
  const products = Object.keys(locationSubscriptions)
    .reduce((acc, productId) => {
      const subscriptionType = get(locationSubscriptions[productId], 'forecast.type', '')
      const directOrder = get(locationSubscriptions[productId], 'forecast.directOrder', 0)
      const product = get(allProducts, productId, {})
      const productName = get(product, 'fullName', 'unknown product')
      const discontinued = isDiscontinued(product)
      if (discontinued) return acc

      if (!product.prices || (product.prices && product.prices.length === 0)) {
        warnings.push({
          productId: productId,
          productName: get(allProducts[productId], 'fullName', 'unknown product'),
          errorType: 'no_sell_price',
          message: `This product has no sell price`
        })
        return acc
      }

      const latestAllocation = get(latestAllocations, productId)
      const allocationType = get(forecast, `forecastTypes.${productId}`, 'allocation_missing')

      if (subscriptionType && subscriptionType === DIRECT_ORDER_TYPES.PAY_ON_DELIVERY) {
        const level = get(location, 'level')
        let original = directOrder

        if (level === 'pack-point' && directOrder === 0) {
          original = ((typeof latestAllocation === 'number') && (!Number.isNaN(latestAllocation)))
            ? latestAllocation
            : 0
        }
        acc[productId] = { original, allocationType: DIRECT_ORDER_TYPES.PAY_ON_DELIVERY }
        return acc
      }

      if (latestAllocation === undefined) {
        warnings.push({
          locationId: location._id,
          locationName: location.fullName,
          productId: productId,
          productName,
          errorType: 'allocation_missing',
          message: `Missing forecast for ${location._id} and ${productId}. Could not calculate QTO`
        })
        acc[productId] = { original: 0, allocationType }
        return acc
      }

      if (latestAllocation === 'non_allocated') {
        warnings.push({
          locationId: location._id,
          locationName: location.fullName,
          productId: productId,
          productName: get(allProducts[productId], 'fullName', 'unknown product'),
          errorType: 'non_allocated',
          message: `The 'allocation_type' is 'non_allocated'. Could not calculate QTO`
        })
        acc[productId] = { original: 0, allocationType }
        return acc
      }

      acc[productId] = { original: latestAllocation, allocationType }
      return acc
    }, {})

  return {products, warnings}
}
