import { normaliseManufacturerValue } from './manufacturer'

export const manufacturerFromId = batchId => (batchId && typeof batchId === 'string')
  ? batchId.split('manufacturer:')[1].split(':')[0]
  : undefined

export const manufacturerFromBatch = batch => {
  if (!batch || typeof batch !== 'object') {
    console.warn('manufacturerFromBatch was called without a batch object')
    return undefined
  }
  // id is most canonical, so we try there first
  if (batch.id || batch._id) return manufacturerFromId((batch.id || batch._id))

  // if no id is set, try manufacturer prop
  return batch.manufacturer
}

export function constructBatchId (props) {
  if (!props) {
    console.warn('constructBatchId was called without props')
    return undefined
  }

  const {
    product,
    batchNumber,
    manufacturer,
    manufacturers
  } = props

  if (!batchNumber || typeof batchNumber !== 'string') {
    console.warn('constructBatchId was called without a batch number string')
    return undefined
  }
  if (!manufacturer || typeof manufacturer !== 'string') {
    console.warn('constructBatchId was called without a manufacturer string')
    return undefined
  }
  if (!(product && product.id)) {
    console.warn('constructBatchId was called without a valid product')
    return undefined
  }

  const manufacturerValue = normaliseManufacturerValue(manufacturer, manufacturers)
  return `${product.id}:manufacturer:${manufacturerValue}:batchNo:${batchNumber}`
}
