import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import get from 'lodash/get'

import { pluralize } from '../../../../utils'
import FilterButton from '../../../FilterButton'
import DeemphasizedLink from '../../../DeemphasizedLink'
import ActionsBar from '../../../ActionsBar'
import Form from '../../../Form'
import ExpandableOptions from '../../../ExpandableOptions/ExpandableOptions'
import capitalize from '../../../../utils/capitalize'

const byActiveStatus = filters => (
  Object.keys(filters).reduce((byActiveStatus, id) => {
    const {
      name,
      items = []
    } = filters[id]

    const active = items.filter(item => item.active)

    const status = active.length ? 'active' : 'nonActive'

    byActiveStatus[status].push({
      id,
      name,
      active
    })

    return byActiveStatus
  }, { active: [], nonActive: [] })
)

class FilterSelector extends Component {
  state = {
    selected: Object.keys(this.props.filters)[0]
  }

  updateFilters = fields => {
    const {
      onFilterChange
    } = this.props

    if (!onFilterChange) {
      return
    }

    onFilterChange(fields)
  }

  render () {
    const { selected } = this.state
    const { filters } = this.props

    const active = get(filters[selected], 'items', []).filter(item => item.active)

    return (
      <div className='vs-page-header__filter-selector'>
        <span className='vs-page-header__filter-selector-available-filters'>
          {Object.keys(filters).map(id => {
            return (id === selected ? (
              <span
                key={id}
                className={classnames(
                  'vs-page-header__filter-selector-item',
                  {'vs-page-header__filter-selector-item--selected': id === selected}
                )}
              >
                {capitalize(filters[id].name)}
              </span>
            ) : (
              <DeemphasizedLink
                key={id}
                className='vs-page-header__filter-selector-item'
                colorVariant='brand'
                onClick={() => this.setState({
                  selected: id
                })}
              >
                {capitalize(filters[id].name)}
              </DeemphasizedLink>
            ))
          })}
        </span>
        <span className='vs-page-header__filter-selector-form'>
          <Form onSubmit={this.updateFilters}>
            <Form.Row>
              <Form.Field
                key={selected}
                labelText={`Choose ${filters[selected].name}`}
                fieldName={selected}
              >
                <Form.Field.Select
                  options={filters[selected].items}
                  defaultValue={active[0]}
                />
              </Form.Field>
            </Form.Row>
            <Form.Actions layout='left'>
              <Form.Button>
                Submit
              </Form.Button>
            </Form.Actions>
          </Form>
        </span>
      </div>
    )
  }
}

class PageHeaderFilters extends Component {
  filterExpandableOptions = React.createRef()

  onFilterCleared = (filterId, itemId) => {
    const {
      onFilterChange
    } = this.props

    if (!onFilterChange) {
      return
    }

    onFilterChange({
      [filterId]: null
    })
  }

  render () {
    const {
      filters,
      onFilterChange,
      inactiveFiltersVisible
    } = this.props

    const filtersByActiveStatus = byActiveStatus(filters)

    return (
      <div className={'vs-page-header__filter'}>
        <ActionsBar layout='left' className='vs-page-header__filter-buttons'>
          <span>Filters:</span>
          {inactiveFiltersVisible && filtersByActiveStatus.nonActive.map(filter => (
            <FilterButton
              key={`all-${pluralize(filter.name)}`}
              colorVariant='neutral'
            >
              {`All ${pluralize(filter.name)}`}
            </FilterButton>
          ))}
          {!inactiveFiltersVisible && !filtersByActiveStatus.active.length && (
            <span>None</span>
          )}
          {filtersByActiveStatus.active.map(filter => (
            filter.active.map(item => (
              <FilterButton
                key={item.value}
                onClick={() => this.onFilterCleared(filter.id, item.value)}
                colorVariant='brand'
              >
                {item.label}
              </FilterButton>
            ))
          ))}
        </ActionsBar>
        <ExpandableOptions
          ref={this.filterExpandableOptions}
          label='Open filters'
          expandedLabel='Close filters'
          labelClassName={classnames(
            'vs-page-header__filter-options-label',
            {'vs-page-header__filter-options-label--no-filters': !inactiveFiltersVisible && !filtersByActiveStatus.active.length}
          )}
          expandableChildren={
            <FilterSelector
              filters={filters}
              onFilterChange={onFilterChange}
            />
          }
          expandWrapperClassName='vs-page-header__filter-options'
          expandSide={false}
          inlineLabel
        />
      </div>
    )
  }
}

PageHeaderFilters.propTypes = {
  filters: PropTypes.object,
  onFilterChange: PropTypes.func,
  inactiveFiltersVisible: PropTypes.bool
}

PageHeaderFilters.defaultProps = {
  inactiveFiltersVisible: true
}

PageHeaderFilters.displayName = 'Page.ComposableHeader.Filters'

export default PageHeaderFilters
