import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Accordion from '../Accordion'
import Button from '../Button'
import { Chevron } from '../../icons'

import { pluralize } from '../../utils'

const INCREMENT_SIZE = 3

class ExpandableList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      extraItemsVisible: false,
      currentCutoff: this.props.cutoffLength
    }
  }

  toggleExpand = () => {
    this.setState({
      extraItemsVisible: !this.state.extraItemsVisible
    })
  }

  incrementTeaserItems = () => {
    const { currentCutoff } = this.state
    const newCutoff = currentCutoff + INCREMENT_SIZE
    this.setState({
      currentCutoff: newCutoff
    })
  }

  render () {
    const {
      incrementVisibility,
      buttonClassname,
      children
    } = this.props

    const {
      extraItemsVisible,
      currentCutoff
    } = this.state

    let itemsLengthOverCutoff = children.length > currentCutoff

    let teaserItems = children
    let cutoffItems = []
    if (itemsLengthOverCutoff) {
      teaserItems = children.slice(0, currentCutoff)
      cutoffItems = children.slice(currentCutoff)
    }

    let expandBtnCopy = extraItemsVisible ? 'See less' : `See ${cutoffItems.length} more ${pluralize('item', cutoffItems.length)}`

    return (
      <Fragment>
        <ul>
          {teaserItems}
        </ul>
        {itemsLengthOverCutoff && (
          <Accordion>
            <Accordion.Section
              isExpanded={extraItemsVisible}
            >
              <Accordion.Section.Content
                className='expandable-list__cutoff-items'
              >
                <ul>
                  {cutoffItems}
                </ul>
              </Accordion.Section.Content>
              <Accordion.Section.Header>
                {incrementVisibility ? (
                  <Button
                    className={classnames(
                      'expandable-list__button',
                      buttonClassname
                    )}
                    colorVariant='dark'
                    size='small'
                    onClick={this.incrementTeaserItems}
                    iconSide='right'
                    icon={<Chevron direction={'down'} />}
                  >
                    See more
                  </Button>
                ) : (
                  <Button
                    className={classnames(
                      'expandable-list__button',
                      buttonClassname
                    )}
                    colorVariant='dark'
                    size='small'
                    onClick={this.toggleExpand}
                    iconSide='right'
                    icon={<Chevron direction={extraItemsVisible ? 'up' : 'down'} />}
                  >
                    {expandBtnCopy}
                  </Button>
                )}
              </Accordion.Section.Header>
            </Accordion.Section>
          </Accordion>
        )}
      </Fragment>
    )
  }
}

ExpandableList.propTypes = {
  /**
   * Should be ann array of items you want to show
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  /**
   * Amount of items you want to show before the "show more" CTA
   */
  cutoffLength: PropTypes.number,
  /**
   * Determines if the list should reveal by a bit and not fully at once
   */
  incrementVisibility: PropTypes.bool,
  /**
   * Additional classname to style the "Show more" button (esp for placement)
   */
  buttonClassname: PropTypes.string
}

ExpandableList.defaultProps = {
  cutoffLength: 3,
  children: []
}

export default ExpandableList
