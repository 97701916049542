import React, {Fragment, useState} from 'react'

import { Button, Page } from '@fielded/shared-ui'

import { getNavigationItem } from '../../../common/utils/navigationInfo'

import { getSubNavigationItems } from './navigation-items'
import { SHIPMENT_STATUS } from '@fielded/fs-api/lib/shipment/constants'

import PSMDriverDeliveryCardContainer from './psm-shipments/PSMDriverDelivery/PSMDriverDeliveryCardContainer'

const Shipments = ({
  shipments,
  programs,
  locationName,
  showCompleted,
  config,
  loadOnlineShipments,
  history
}) => {
  const [selectedDeliveries, setSelectedDeliveries] = useState([])
  const activeTab = showCompleted ? 'completed' : 'shipments'

  const isStartTripButtonDisabled = !!selectedDeliveries.length
  const pageTitle = `${locationName} deliveries`

  const packedDeliveries = shipments.filter(shipment => shipment.status === SHIPMENT_STATUS.PACKED)

  const subNavigationItems = getSubNavigationItems({
    activeTab
  })

  subNavigationItems.forEach(item => {
    if (item.active) {
      item.title = `${item.title} (${shipments.length})`
    }
  })

  const onToggleCheckbox = (event, delivery = null) => {
    let isChecked = event
    let newSelectedDeliveries = []

    if (event.target) {
      isChecked = event.target.checked
    }

    // this handles selecting all deliveries
    if (!delivery) {
      newSelectedDeliveries = isChecked ? packedDeliveries : []
    }

    if (delivery) {
      if (isChecked) {
        newSelectedDeliveries = [...selectedDeliveries, delivery]
      } else {
        newSelectedDeliveries = selectedDeliveries.filter(d => d.snapshotId !== delivery.snapshotId)
      }
    }

    setSelectedDeliveries(newSelectedDeliveries)
  }

  const onStartTrip = () => {
    history.push({
      pathname: '/shipments/confirm-pick-up',
      state: { packedDeliveries, selectedDeliveries }
    })
  }

  return (
    <Page className='shipments'>
      <Page.ComposableHeader>
        <Page.ComposableHeader.Breadcrumb
          items={[getNavigationItem({ item: 'shipments', config, includePath: false })]}
        />
        <Page.ComposableHeader.Content>
          <Page.ComposableHeader.Title title={pageTitle} />
          <Page.ComposableHeader.SubNavigation
            label=''
            items={subNavigationItems}
          />
        </Page.ComposableHeader.Content>
      </Page.ComposableHeader>

      <Page.Panel narrow alignLeft>
        <Fragment>
          <PSMDriverDeliveryCardContainer
            history={history}
            showCompleted={showCompleted}
            filteredShipments={shipments}
            selectedDeliveries={selectedDeliveries}
            packedDeliveries={packedDeliveries}
            onToggleCheckbox={onToggleCheckbox}
            programs={programs}
          />
        </Fragment>

        {loadOnlineShipments && (
          <div className='shipments__show-more'>
            <Button
              colorVariant='brand'
              fill='outline'
              size='small'
              onClick={loadOnlineShipments}
            >
              Show all completed shipments (online)
            </Button>
          </div>
        )}
      </Page.Panel>

      <Page.Footer fixed nowrap>
        <Button
          colorVariant='brand'
          fill='full'
          disabled={!isStartTripButtonDisabled}
          onClick={() => onStartTrip()}
        >
          Start trip
        </Button>
      </Page.Footer>
    </Page>
  )
}

export default Shipments
