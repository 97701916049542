import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ActionsBar = ({
  children,
  layout,
  nowrap,
  className
}) => (
  <div
    className={classnames(
      'vs-actions-bar',
      {'vs-actions-bar--right': layout === 'right'},
      {'vs-actions-bar--left': layout === 'left'},
      {'vs-actions-bar--centered': layout === 'centered'},
      {'vs-actions-bar--space-between': layout === 'space-between'},
      {'vs-actions-bar--nowrap': nowrap},
      className
    )}
  >
    {React.Children.toArray(children).map((child, index) => {
      const {
        // grab this prop here to avoid passing it on to the child element
        alignOpposite,
        fullWidth,
        ...otherProps
      } = child.props

      return (
        <div
          key={`actionitem${index}`}
          className={classnames(
            'vs-actions-bar__item',
            {'vs-actions-bar__item--full-width': fullWidth},
            {'vs-actions-bar__item--align-opposite': alignOpposite}
          )}
        >
          {React.createElement(child.type, {...otherProps})}
        </div>
      )
    })}
  </div>
)

ActionsBar.propTypes = {
  /**
   * User action options, usually buttons. Will be wrapped in bem element.
   */
  children: PropTypes.node,

  /**
   * The layout pattern for the actions
   */
  layout: PropTypes.oneOf(['right', 'left', 'centered', 'space-between']),

  /**
   * Puts items next to each other on small screen
   */
  nowrap: PropTypes.bool,
  /**
   * Optional additional classes
   */
  className: PropTypes.string
}

ActionsBar.defaultProps = {
  children: null,
  layout: 'right',
  className: undefined
}

export default ActionsBar
