import React from 'react'

export const PayAsYouSell = () => {
  return (
    <svg viewBox='0 0 30 22' fill='none' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
      <path d='M29.6926 1.65482C29.6955 1.48611 29.6644 1.31855 29.6014 1.16204C29.5383 1.00553 29.4445 0.863256 29.3256 0.743612C29.2066 0.623968 29.0648 0.529391 28.9087 0.465464C28.7525 0.401537 28.5852 0.369578 28.4164 0.371442L1.4823 0.252128C1.31359 0.249319 1.14602 0.280339 0.989517 0.343389C0.83301 0.40644 0.69073 0.500239 0.571086 0.619215C0.451442 0.73819 0.356865 0.879943 0.292938 1.03609C0.229011 1.19225 0.197027 1.35962 0.19889 1.52834L0.13787 16.3365C0.135061 16.5052 0.166106 16.6728 0.229157 16.8293C0.292207 16.9858 0.385981 17.1281 0.504957 17.2477C0.623932 17.3674 0.765685 17.4619 0.921836 17.5259C1.07799 17.5898 1.24536 17.6218 1.41408 17.6199L14.1016 17.6695C13.9425 16.9007 13.8889 16.1139 13.9421 15.3306C13.9469 15.2593 13.952 15.1837 13.9568 15.1123L6.16036 15.0829C5.93951 14.2523 5.5049 13.494 4.89982 12.8837C4.29475 12.2733 3.54031 11.8321 2.71167 11.604L2.73143 6.27851C3.56147 6.05738 4.31907 5.62258 4.92873 5.01744C5.53838 4.41229 5.9788 3.65795 6.20608 2.82957L23.6626 2.89529C23.8835 3.72589 24.3181 4.48411 24.9231 5.09448C25.5282 5.70485 26.2826 6.14607 27.1113 6.37415L27.1024 8.33051C28.0918 8.90195 28.9582 9.66392 29.6513 10.5723L29.6926 1.65482Z' fill='#8A69A6' />
      <path d='M18.5132 8.21248C16.713 9.18941 15.3109 10.7648 14.5494 12.6662C13.9604 12.6084 13.394 12.4097 12.898 12.0869C12.402 11.764 11.991 11.3265 11.6998 10.8113C11.4086 10.296 11.2457 9.71827 11.2249 9.12682C11.2041 8.53536 11.3261 7.94759 11.5804 7.4132C11.8347 6.87882 12.214 6.41353 12.6861 6.05666C13.1582 5.69978 13.7093 5.46182 14.2928 5.3629C14.8763 5.26397 15.475 5.30699 16.0384 5.48831C16.6018 5.66963 17.1132 5.98392 17.5294 6.40462C18.0216 6.90217 18.3627 7.52901 18.5132 8.21248Z' fill='#8A69A6' />
      <path d='M23.0952 9.94938C21.92 9.87057 20.7477 10.1421 19.7268 10.7295C18.7059 11.317 17.8822 12.1941 17.3598 13.2498C16.8374 14.3055 16.6399 15.4924 16.7922 16.6604C16.9445 17.8284 17.4397 18.925 18.2153 19.8115C18.9909 20.698 20.012 21.3345 21.1494 21.6406C22.2868 21.9466 23.4895 21.9085 24.6052 21.531C25.721 21.1535 26.6997 20.4536 27.4176 19.5197C28.1354 18.5859 28.5603 17.4601 28.6383 16.2848C28.7428 14.7098 28.2176 13.1577 27.1782 11.9697C26.1388 10.7817 24.6702 10.055 23.0952 9.94938ZM24.5756 18.7538C24.4236 18.8832 24.227 18.9482 24.0278 18.9348C23.8286 18.9215 23.6425 18.8309 23.5091 18.6823L21.7856 16.711C21.7184 16.6334 21.6677 16.5429 21.6366 16.4451C21.6054 16.3473 21.5945 16.2442 21.6044 16.1421L21.8319 12.7495C21.8589 12.5603 21.9564 12.3883 22.1048 12.2679C22.2533 12.1476 22.4418 12.0878 22.6325 12.1006C22.8232 12.1134 23.002 12.1978 23.1331 12.3369C23.2642 12.476 23.3378 12.6595 23.3393 12.8506L23.1312 15.9535L24.6471 17.6873C24.7786 17.8384 24.8449 18.0355 24.8315 18.2354C24.8181 18.4352 24.7261 18.6216 24.5756 18.7538Z' fill='#471B6A' />
    </svg>
  )
}
