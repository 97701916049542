import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'

import { Button } from '@fielded/shared-ui'

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node
  }

  state = {}

  componentDidCatch (error, errorInfo) {
    this.setState({ error })
    Sentry.captureException(error, { extra: errorInfo })
  }

  showReportDialog () {
    Sentry.showReportDialog()
  }

  render () {
    if (!this.state.error) {
      return this.props.children
    }

    const lastEventId = Sentry.lastEventId()

    return (
      <div>
        <p>We are sorry, something has gone wrong.</p>
        <p>The Field Supply team has been notified.</p>
        { lastEventId &&
          <Button
            fill='full'
            onClick={this.showReportDialog}
            colorVariant='brand'
          >Report issue</Button>
        }
      </div>
    )
  }
}

export default ErrorBoundary
