import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import UppercaseLabel from '../UppercaseLabel'
import Checkbox from '../Checkbox'
import Radio from '../Radio'
import { Chevron } from '../../icons'

const StatelessOptionsGroup = ({
  className,
  label,
  withInlineLabel,
  options,
  inRows,
  multi,
  block,
  withBackground,
  withLightBackground,
  withHighlightOption,
  withoutControlVisble,
  spread,
  hasError,
  disabled,
  defaultValue,
  value,
  onValueChange,
  onToggleOptionVisibility,
  optionsVisible,
  additionalInfoWhenActive,
  boldLabel,
  ...otherProps
}) => {
  const optionsCollapsible = onToggleOptionVisibility
  const labelComponent = () => {
    if (!label) return null
    if (withInlineLabel) {
      return (
        <legend className={
          classnames(
            'vs-options-group__legend',
            { 'vs-options-group__legend-bold': boldLabel }
          )
        }
        >
          {label}
        </legend>
      )
    }
    if (optionsCollapsible) {
      return (
        <legend className='vs-options-group__legend vs-options-group__legend--colapsible' onClick={onToggleOptionVisibility}>
          {label} <span className='vs-options-group__legend-icon'><Chevron direction={optionsVisible ? 'up' : 'down'} /></span>
        </legend>
      )
    }

    return (
      <legend className='vs-options-group__legend'>
        <UppercaseLabel>
          {label}
        </UppercaseLabel>
      </legend>
    )
  }

  return (
    <fieldset
      className={classnames(
        'vs-options-group',
        { 'vs-options-group--in-rows': inRows || withHighlightOption || withoutControlVisble },
        { 'vs-options-group--block': block },
        { 'vs-options-group--spread': spread || withHighlightOption || withoutControlVisble },
        { 'vs-options-group--with-background': (withBackground || withLightBackground) && !withInlineLabel },
        { 'vs-options-group--with-highlight-option': withHighlightOption && !withInlineLabel },
        { 'vs-options-group--with-light-background': withLightBackground && !withInlineLabel },
        { 'vs-options-group--with-no-control': withoutControlVisble && !withInlineLabel },
        { 'vs-options-group--has-error': hasError },
        { 'vs-options-group--inline-label': withInlineLabel },
        className
      )}
    >
      <div className='vs-options-group__wrapper'>
        {labelComponent()}
        {options && (
          <Fragment>
            {!optionsVisible && (
              <div>
                {value.length ? (
                  value.map(item => {
                    return (
                      <span
                        key={item}
                        className='vs-options-group__option-preview'
                      >
                        {item}
                      </span>
                    )
                  })
                ) : 'All'}
              </div>
            )}

            <div className={classnames(
              'vs-options-group__options',
              { 'vs-options-group__options--hidden': !optionsVisible },
              { 'vs-options-group__options--collapsible': optionsCollapsible }
            )}>
              {options.map(option => {
                const isDisabled = disabled || option.disabled
                const currentValue = value || defaultValue || (multi ? [] : '')
                const isOptionSelected = multi
                  ? currentValue.indexOf(option.value) >= 0
                  : currentValue === option.value

                const isOptionHighlighted = option.highlighted === true
                const showAdditionalInfo = additionalInfoWhenActive ? isOptionSelected && !!option.additionalInfo : !!option.additionalInfo

                let dataQa = null
                if (otherProps['data-qa']) {
                  dataQa = `${otherProps['data-qa']}-${option.value}`
                }

                return (
                  <div
                    className={classnames(
                      'vs-options-group__item',
                      { 'vs-options-group__item--selected': isOptionSelected },
                      { 'vs-options-group__item--disabled': isDisabled },
                      { 'vs-options-group__item--highlighted': isOptionHighlighted },
                      { 'vs-options-group__item-additional-info--has-error': option.blocked === true }
                    )}
                    key={`option-${option.value}`}
                  >
                    {multi ? (
                      <Checkbox.Stateless
                        {...option}
                        name={option.value}
                        checked={isOptionSelected}
                        onChange={event => {
                          let nextValue
                          if (event.target.checked) {
                            nextValue = currentValue.concat(event.target.name)
                          } else {
                            nextValue = currentValue.filter(v => v !== event.target.name)
                          }
                          onValueChange(nextValue, event.target.name)
                        }}
                        disabled={isDisabled}
                        data-qa={dataQa}
                      />
                    ) : (
                      <Radio
                        {...option}
                        isSelected={isOptionSelected}
                        onChange={event => {
                          const nextValue = event.target.value
                          onValueChange(nextValue)
                        }}
                        disabled={isDisabled}
                        controlInvisible={withoutControlVisble}
                        data-qa={dataQa}
                      />
                    )}
                    {showAdditionalInfo && (
                      <div
                        className={classnames(
                          'vs-options-group__item-additional-info',
                          { 'vs-options-group__item-additional-info-selected': isOptionSelected },
                          { 'vs-options-group__item-additional-info--has-error': option.blocked === true }
                        )}>
                        {option.additionalInfo}
                      </div>
                    )
                    }
                  </div>
                )
              })}
            </div>
          </Fragment>
        )}
      </div>
    </fieldset>
  )
}

class OptionsGroup extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      value: this.props.value || this.props.selectedOption
    }
  }

  handleValueChange = (nextValue, changedOption) => {
    this.setState({ value: nextValue }, () => {
      this.props.onValueChange(nextValue, changedOption)
      this.props.onChange(nextValue)
    })
  }

  render () {
    return (
      <StatelessOptionsGroup
        {...this.props}
        value={this.state.value}
        onValueChange={this.handleValueChange}
      />
    )
  }
}

OptionsGroup.Stateless = StatelessOptionsGroup
OptionsGroup.Stateless.displayName = 'OptionsGroup.Stateless'

OptionsGroup.propTypes = {
  /**
   * Label describing the group, e.g. "Sort list by:"
   */
  label: PropTypes.node,

  /**
   * Options to be rendered
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.string,
      subLabel: PropTypes.node,
      additionalInfo: PropTypes.node
    })
  ).isRequired,

  /**
   * Pass the value of an option to pre-select it.
   * For multi groups, pass an array of values
   * for the options that should be selected.
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),

  /**
   * Legacy: pass the value of an option to pre-select it.
   */
  selectedOption: PropTypes.string,

  /**
   * Allow user to select multiple options
   */
  multi: PropTypes.bool,

  /**
   * Render each option in an own row no matter the screen size
   */
  inRows: PropTypes.bool,

  /**
   * Option to be used when all radio buttons are in row.
   * Renders the legend (label) in one row with all options.
   */
  withInlineLabel: PropTypes.bool,

  /**
   * Makes the group spread to take up all available space
   * Works well for multiple groups in flex containers,
   * e.g. where some groups have sublabels and others don't.
   */
  spread: PropTypes.bool,

  /**
   * This will render a box around the options with a light grey background
   */
  withBackground: PropTypes.bool,

  /**
   * This will render a box around the options with a light background, for use on grey
   */
  withLightBackground: PropTypes.bool,

  /**
   * This will render a box around each option and allow the highlight of selected option
   */
  withHighlightOption: PropTypes.bool,

  /**
   * This extends the withHighlightOption by hiding the control and using left border of the box to show the selected item
   */
  withoutControlVisble: PropTypes.bool,
  /**
   * onValueChange to apply to the whole group, fires when new selection is made, returns the selected value.
   */
  onValueChange: PropTypes.func,

  /**
   * onChange to apply to the whole group, fires when new selection is made, returns the selected value.
   */
  onChange: PropTypes.func,

  /**
   * Pass to render with error highlight to alert user of issue with the group
   */
  hasError: PropTypes.bool,

  /**
   * Allows disabling all options at once
   */
  disabled: PropTypes.bool,

  /**
   * Add extra class
   */
  className: PropTypes.string,

  /**
   * Determines if list of options is visible or not.
   */
  optionsVisible: PropTypes.bool,

  /**
   * Determines whether to show the additional info only when item is active
   */
  additionalInfoWhenActive: PropTypes.bool
}

OptionsGroup.defaultProps = {
  defaultValue: undefined,
  selectedOption: undefined,
  multi: false,
  inRows: false,
  withBackground: false,
  onValueChange: () => null,
  onChange: () => null,
  hasError: false,
  disabled: false,
  optionsVisible: true,
  additionalInfoWhenActive: false
}

StatelessOptionsGroup.defaultProps = OptionsGroup.defaultProps
StatelessOptionsGroup.propTypes = OptionsGroup.propTypes

export default OptionsGroup
