const { updateSnapshot } = require('./snapshot-update')

exports.updateOrder = function ({
  productQuantities,
  order,
  status,
  user,
  timestamp = new Date().toJSON()
}) {
  const parentSnapshot = order.snapshotsByStatus[status][0]
  if (!parentSnapshot) {
    throw new Error(`Error updating order ${order.orderId}: could not find snapshot for status ${status}`)
  }

  const snapshot = updateSnapshot({parentSnapshot, products: productQuantities, user, timestamp})
  return [snapshot]
}
