// Takes forecasts coming from the Django Rest API
// (one forecast object per product-location pair) and converts it
// to one forecast object per location
const toForecastPerLocation = forecasts => {
  const byLocation = forecasts.reduce((byLocation, forecast) => {
    const locationId = forecast.location
    byLocation[locationId] = byLocation[locationId] || {
      locationId,
      // Limitation: it assumes that the active forecasts for all products in a
      // location have the same `effectiveDate`
      effectiveDate: forecast.created_at,
      createdAt: forecast.created_at,
      dailyDemand: {},
      safetyStock: {},
      // Contains the QTO for that date, as calculated by the data pipeline
      latestAllocation: {},
      // Keep the allocation_type, which is an SKU level prop
      forecastTypes: {},
      // Assumes that the supply plan is the same for all products in
      // the location, which is currently the case in ShelfLife
      supplyPlans: { default: { supplyPeriodDays: forecast.supply_period_days } }
    }
    byLocation[locationId].dailyDemand[forecast.product] = forecast.daily_demand
    byLocation[locationId].safetyStock[forecast.product] = forecast.safety_stock
    byLocation[locationId].latestAllocation[forecast.product] = forecast.allocation_type === 'non_allocated'
      ? 'non_allocated' : forecast.allocation
    byLocation[locationId].forecastTypes[forecast.product] = forecast.allocation_type
    return byLocation
  }, {})

  return Object.values(byLocation)
}

module.exports = toForecastPerLocation
