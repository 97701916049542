module.exports = filterInactiveLocations

/*
 * Filters locations that have no active programs
 * anything you're putting in here,
 * run it through getLocation first
 *
 * params {array} docs - array of locations docs that have been 'upgraded' via getLocation
 */
function filterInactiveLocations (docs) {
  return docs.filter(doc => Object.keys(doc.programs).length)
}
