module.exports = proxyPouch
function proxyPouch (PouchDB) {
  // These 2 returns event emitters
  // this proxy pattern does not really work for them somehow
  const noProxy = ['changes', 'replicate']

  return function PouchPerfProxy () {
    const db = new PouchDB(...arguments)
    var handler = {
      cache: {},
      get (target, prop) {
        if (typeof target[prop] !== 'function') {
          return target[prop]
        }

        if (noProxy.includes(prop)) {
          return target[prop]
        }

        if (this.cache[prop]) {
          return this.cache[prop]
        }

        this.cache[prop] = function () {
          // This is where you can put the conditional breakpoint
          // prop will be a pouchdb call like allDocs, get, find, query
          // db.name is database name
          // first argument is query (second for 'db.query')
          const ret = target[prop](...arguments)

          if (ret === null || typeof ret.then !== 'function') {
            return ret
          }

          const finish = () => {
            const query = arguments[0]

            console.timeEnd(key)
            console.log(key, query)
          }

          const queryUuid = Math.random().toString().slice(2, 6)
          const key = `${db.name}-${db.adapter}-${prop}-${queryUuid}`
          console.time(key)
          return ret.then((res) => {
            finish()
            return res
          }).catch(e => {
            finish()
            throw e
          })
        }

        return this.cache[prop]
      }
    }

    return new Proxy(db, handler)
  }
}
