import React, { useEffect, useRef, useState } from 'react'

import { Button, DeemphasizedLink, Form, Page } from '@fielded/shared-ui'
import { User } from '@fielded/shared-ui/src/icons'
import { toast } from '@fielded/shared-ui/src/components/Page'

import { isSelfSignup, hasOwnDatabase } from '../../../../common/utils'
import { General } from './FormSections'

const Profile = ({
  locationDisplayName,
  user,
  isRetailer,
  saving,
  config,
  history,
  success: profileSaveSuccess,
  error: profileSaveError,
  saveData
}) => {
  const form = useRef(null)

  const [showDiscardToast, setShowDiscardToast] = useState(false)

  useEffect(() => {
    if (profileSaveError || profileSaveSuccess) {
      toast({
        title: profileSaveSuccess || profileSaveError,
        type: profileSaveSuccess ? 'success' : 'warning'
      })
    }
  }, [profileSaveError, profileSaveSuccess])

  const submit = async () => {
    const fields = form.current.getFields()
    await saveData(fields)
  }

  const onCancelChanges = () => {
    form.current.reset()

    toast({
      title: 'Changes discarded',
      type: 'info',
      onDismiss: onDismissCancelChangesToast()
    })
  }

  const onDismissCancelChangesToast = () => {
    setShowDiscardToast(false)
  }

  const onShowCancelChangesToast = () => {
    setShowDiscardToast(true)
  }

  const breadcrumbItems = [{
    title: 'My account',
    icon: <User />
  }]

  const shouldViewNewHeader = isRetailer || (isSelfSignup(user) && !hasOwnDatabase(user))

  return (
    <Page className='my-profile' title={config.title}>
      {shouldViewNewHeader ? (
        <Page.HeaderNew
          title='My account'
          subtitle='My profile'
          icon={<User />}
          history={history}
        />
      ) : (
        <Page.Header
          breadcrumbItems={breadcrumbItems}
          title={locationDisplayName}
        />
      )}

      <Page.Panel>
        <Form ref={form} onSubmit={() => submit()}>
          <General
            isRetailer={isRetailer}
            user={user}
            onShowCancelChangesToast={onShowCancelChangesToast}
            locationDisplayName={locationDisplayName} />
        </Form>
      </Page.Panel>

      <Page.Footer fixed nowrap>
        <Button
          loading={saving}
          loadingLabel='Saving …'
          colorVariant='brand'
          onClick={() => form && form.current.submit()}
          fill='full'>
            Save
        </Button>
        <DeemphasizedLink
          disabled={!showDiscardToast}
          alignOpposite
          onClick={() => onCancelChanges()}
        >
            Cancel and discard changes
        </DeemphasizedLink>
      </Page.Footer>
    </Page>
  )
}
export default Profile
