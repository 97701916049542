import React, { Component } from 'react'
import get from 'lodash/get'

import { PriceDisplay, PrintTemplate } from '@fielded/shared-ui'
import { getCurrencySymbol } from '@fielded/shared-ui/src/components/PriceDisplay'

import { pluralize } from '../van-shared/utils/utils'
import { removeTime } from '../common/periods'

import { makeDeliveryItems } from '../subapps/shipments/common/makeDeliveryItems'

import { getInvoiceConfirmationRows } from '../subapps/reports/confirmation/confirmationUtils'

import { getTableTotal, getTableRows } from './SalesStatement'

class StatementsSummary extends Component {
  render () {
    const {
      location,
      serviceId,
      report,
      productsById,
      shipment,
      showInventory,
      showSales,
      showDelivery,
      config,
      order
    } = this.props

    const country = get(location, 'location.country')
    const currency = getCurrencySymbol({country})
    const locationDetails = get(location, 'location.state')
    const appName = config.name

    let reportDate
    let salesTotal
    let shipmentDate
    let deliveryTotal
    let hasIssuesWarning

    if ((showInventory || showSales) && report) {
      reportDate = report.submittedAt
      const rows = getInvoiceConfirmationRows({report, productsById, serviceId})
      const tableRows = getTableRows(rows)
      const rowsWithIssues = tableRows.filter(row => row.hasIssue).length
      hasIssuesWarning = rowsWithIssues > 0
      salesTotal = getTableTotal(rows)
    }
    if (showDelivery && shipment) {
      shipmentDate = get(shipment, 'snapshotDates.received')

      const items = makeDeliveryItems({order, shipment, report, productsById, includeReportItems: true})
      deliveryTotal = items.reduce((sum, item) => {
        // incase delivered value is NaN, so we don't have total of NaN
        if (item.delivered) sum += item.deliveredValue || 0
        return sum
      }, 0)
    }

    const num = [
      showInventory, showSales, showDelivery
    ].filter(t => t).length
    const numText = ['no', 'one', 'two', 'three'][num]
    const numStatementsText = pluralize('statement', num)

    return (
      <PrintTemplate>
        <PrintTemplate.Header
          title='Summary'
          config={config}
        />
        <PrintTemplate.Box
          left={<div><strong>{location.fullName}</strong><br />{location.additionalData.physicalAddress}</div>}
          right={<div><strong>{removeTime(new Date())}</strong></div>}
        />
        <PrintTemplate.Section>
        Please find enclosed {numText} {numStatementsText} from {appName}.
        </PrintTemplate.Section>
        {showInventory && <PrintTemplate.Section
          subtitle='Inventory statement'
        >
        Your inventory statement itemizes all of this pharmacy's current {appName} product subscriptions,
        showing the quantities that were counted and confirmed in the stock count conducted on <strong>{removeTime(reportDate)}</strong>.
        </PrintTemplate.Section>}
        {showSales && <PrintTemplate.Section
          subtitle='Sales statement'
        >
          Your sales statement is a summary of the sales totals calculated as a result of stock count
          conducted on <strong>{removeTime(reportDate)}</strong>. The total sales for this statement are <strong>{currency}<PriceDisplay country={country} value={salesTotal.total} /></strong>.
          Please note that this is not an invoice. The quantities listed may be revisited by {appName} before your
          final invoice is issued.
        </PrintTemplate.Section>}
        {showDelivery && <PrintTemplate.Section
          subtitle='Delivery statement'
        >
          Your delivery statement itemizes all {appName} products delivered to your pharmacy on <strong>{removeTime(shipmentDate)}</strong>.
          The value of products delivered today is <strong>{currency}<PriceDisplay country={country} value={deliveryTotal} /></strong>.
        </PrintTemplate.Section>}
        {hasIssuesWarning && <PrintTemplate.Section warning='*Important note'>
          Please note some products in these statements are flagged for review.
          These products in question are not included in the totals.
          A {appName} team member will be in touch to follow up.
        </PrintTemplate.Section>}
        <PrintTemplate.Footer location={locationDetails} />
      </PrintTemplate>
    )
  }
}

export default StatementsSummary
