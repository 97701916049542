import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

const ProgressButton = ({
  title,
  disabled,
  progressText,
  progressSupportingText,
  submitLabelText,
  isComplete,
  onClick
}) => {
  return (
    <Button
      className='progress-button'
      colorVariant='brand'
      fill={isComplete ? 'full' : 'outline'}
      size='large'
      title={title}
      disabled={disabled}
      onClick={onClick}
    >
      <div className='progress-button__content'>
        <div className='progress-button__progress'>
          <span className='progress-button__progress-text'>
            {progressText}
          </span>
          {progressSupportingText &&
            <span className='progress-button__progress-supporting-text'>
              {progressSupportingText}
            </span>
          }
        </div>
        <div className='progress-button__submit'>
          <span className='progress-button__submit-label'>
            {submitLabelText}
          </span>
          <span className='progress-button__submit-icon'>
            <Arrow direction='right' />
          </span>
        </div>
      </div>
    </Button>
  )
}

ProgressButton.propTypes = {
  /**
   * Pass a string to be used as on the left side of a button as a progressing item.
   * Examples: "2 of 5" or "58".
   * */

  progressText: PropTypes.string,

  /**
   * Pass a string to be used as on the left side of a button as a progressing item unit (or a supporting text).
   * This should be used only is progressingItemText is defined (won't be rendered otherwise).
   * Examples: "products done" or "%".
   * */
  progressSupportingText: PropTypes.string,

  /**
   * Pass a string to be used instead of "Next" text in the right side of a button.
   * */
  submitLabeltext: PropTypes.string,

  /**
   * Pass a title to a ProgressButton to have a small tooltip rendered on hover.
   */
  title: PropTypes.string,

  /**
   * Use to disable the button (not applied when link)
   * */
  disabled: PropTypes.bool,

  /**
   * type attribute, e.g. submit (not applied when link)
   * */
  type: PropTypes.string,

  /**
   * Any additional class names you want to add to the button
   * */
  className: PropTypes.string
}

ProgressButton.defaultProps = {
  progressText: null,
  progressSupportingText: null,
  submitLabelText: 'Next',
  title: null,
  disabled: false
}

export default ProgressButton
