import PouchDB from 'pouchdb-core'
import idbAdapter from 'pouchdb-adapter-idb'
import memoryAdapter from 'pouchdb-adapter-memory'
import httpAdapter from 'pouchdb-adapter-http'
import replication from 'pouchdb-replication'
import pendingUpload from './pending-upload'
import find from 'pouchdb-find'
import authentication from 'pouchdb-authentication'
import mapReduce from 'pouchdb-mapreduce'
import upsert from 'pouchdb-upsert'
import { useOnlineOffline } from './common'
import { userIsAuthorised } from '../van-shared/utils/auth'
import { getDBPerUserDatabaseName } from '@fielded/fs-api/lib/user/tools'
import { hasOwnDatabase } from '../common/utils/user-db-sync'
import { userToMainMatchers } from '@fielded/fs-api/lib/user/tools/user-db-sync'
import proxyPouch from '@fielded/fs-api/lib/tools/pouchdb-performance-proxy'
const NO_DATABASE_USER = 'no-database-user'

PouchDB
  .plugin(idbAdapter)
  .plugin(httpAdapter)
  .plugin(replication)
  .plugin(pendingUpload)
  .plugin(authentication)
  .plugin(mapReduce)
  .plugin(upsert)
  .plugin(memoryAdapter)
  .plugin(find)

const ONLINE_ONLY_ROLL = 'online-only'

const timeout = 1000 * 60 * 2 // 2 minutes

const REMOTE_DB_OPTIONS = {
  skip_setup: true,
  ajax: {
    timeout
  }
}

export const LIVE_DOWNLOAD_OPTIONS = {
  live: true,
  retry: true,
  timeout, // replication options takes this straight, not ajax prop like above
  batch_size: 500
}

export const LIVE_UPLOAD_OPTIONS = {
  ...LIVE_DOWNLOAD_OPTIONS,
  // This used to be 10 which was extremely chatty, users had to wait a while
  // when starting on a new set of IDs.
  batch_size: 500
}

// dbList looks like: [{ name: 'van-shipments', bidirectional: true }, { name: 'integrated-data' }]
// ...db at end intentionally so tests can use in memory localDb and remoteDb
export function getSyncDBs (remoteDbUrl, dbConfigList, user) {
  const dbList = getDBList({user, dbConfigList})
  const onlineOnly = user.roles && user.roles.includes(ONLINE_ONLY_ROLL)
  return dbList
    .map(db => {
      const LocalPouchDB = getLocalPouchDB(user)
      const RemotePouchDB = getRemotePouchDB(remoteDbUrl)
      return {
        remoteDbUrl,
        localDb: new LocalPouchDB(db.name),
        remoteDb: new RemotePouchDB(db.name),
        ...db,
        onlineOnly
      }
    })
}

export const isNoDBUser = user => userIsAuthorised(user, `feature:${NO_DATABASE_USER}`)

export function getDBList ({user, dbConfigList}) {
  // If the user has the special NO_DATABASE_USER role, they see field supply
  // without any remote CouchDB (i.e. for external user signups).
  const noDatabaseUser = isNoDBUser(user)
  if (noDatabaseUser) return []

  // This similar logic to the API constructor. consider a utility
  // or at least make sure this lines up with that code if changing.
  const usesDatabasePerUser = hasOwnDatabase(user)
  if (usesDatabasePerUser && !user.name) {
    throw new Error('user object needs a name to use user database sync')
  }

  if (usesDatabasePerUser) {
    const userDatabaseName = getDBPerUserDatabaseName(user)
    // Create a filter for the replication from browser to user-database.
    // The filter uses the same code as the main-db <-> user-db replication
    // to figure out which documents are allowed to replicate back.
    const uploadFilter = (doc) => (
      Object.values(userToMainMatchers).some(match => match.match({ locationId: user.location.id }, doc))
    )
    return [{
      name: userDatabaseName,
      bidirectional: true,
      syncOnId: false,
      uploadOptions: {
        ...LIVE_UPLOAD_OPTIONS,
        filter: uploadFilter
      }
    }]
  }

  const dbList = dbConfigList
    .filter(db => dbShouldSync(db, user.roles))
    .map(db => ({
      ...db,
      bidirectional: getBidirectionalConfig(db, user.roles)
    }))
  return markSelectorDbs(user, maybeNationalException(user, dbList))
}

function getBidirectionalConfig (dbConfig, userRoles = []) {
  if (dbConfig.bidirectionalRequiredRole && userRoles.includes(dbConfig.bidirectionalRequiredRole)) {
    return true
  }

  if (
    dbConfig.bidirectionalRequiredRoles &&
    userRoles.find(role => dbConfig.bidirectionalRequiredRoles.includes(role))
  ) {
    return true
  }

  return dbConfig.bidirectional
}

// if a config has no requiredRole nor requiredRoles, the db syncs
// otherwise, the user has to have the required role
// or one of the requiredRoles
function dbShouldSync (db, userRoles) {
  if (!db.requiredRole && !db.requiredRoles) return true

  if (db.requiredRoles) {
    return !!userRoles.find(role => !!db.requiredRoles.includes(role))
  }

  return userRoles.includes(db.requiredRole)
}

export function getLocalPouchDB (user = {}, measure = false) {
  const DB = (user && user.roles && user.roles.includes(ONLINE_ONLY_ROLL))
    ? PouchDB.defaults({ adapter: 'memory' })
    : PouchDB

  return measure ? proxyPouch(DB) : DB
}

export function getMemoryPouchDB () {
  return PouchDB.defaults({ adapter: 'memory' })
}

export function getRemotePouchDB (remoteDbUrl, opts = {}, measure = false) {
  const baseOpts = {
    prefix: remoteDbUrl,
    ...REMOTE_DB_OPTIONS
  }
  const defaults = {...baseOpts, ...opts}
  const DB = PouchDB.defaults(defaults)
  return measure ? proxyPouch(DB) : DB
}

// If the user is national, sync all integrated-data docs (not on id).
// Ticket: https://github.com/fielded/van-orga/issues/1476
function maybeNationalException (user, databases) {
  const nationalExceptionDbs = ['stock-count', 'integrated-data']
  const isNational = (user && user.location && user.location.id === 'national')
  const onlineOffline = useOnlineOffline(user)

  if (!isNational || onlineOffline) {
    return databases
  }

  return databases.map(dbConfig => {
    if (nationalExceptionDbs.includes(dbConfig.name)) {
      return {
        ...dbConfig,
        syncOnId: false
      }
    }
    return dbConfig
  })
}

// If you sync on selector you only need to update the selector once per day
const SELECTOR_POLL_INTERVALL = 24 * 60 * 60 * 1000
const SELECTOR_ROLE = 'feature:experimental-sync-selector'
const SELECTOR_SYNC_DBS = ['stock-count']
function markSelectorDbs (user, databases) {
  if (!user.roles || !user.roles.includes(SELECTOR_ROLE)) {
    return databases
  }

  return databases.map(dbConfig => {
    if (!SELECTOR_SYNC_DBS.includes(dbConfig.name)) {
      return dbConfig
    }

    return {
      ...dbConfig,
      pollInterval: SELECTOR_POLL_INTERVALL
    }
  })
}
