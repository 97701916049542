import get from 'lodash/get'

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { isLocationSubscriptionBased } from '@fielded/fs-api/lib/location/tools'

import { canCallEndpoint, getTimestampForNextApiCall } from '../../../../common/utils/redux-timestamp-call'

const MINUTES_TO_ADD = 10

const initialState = {
  userLocation: null,
  timestampForTheNextApiCall: 0,
  hasSubscriptionBasedService: false,
  country: '',
  loading: false,
  error: null
}

export const getLocation = createAsyncThunk('location/getLocation', async ({ api, locationId, user, forceRefresh = false }) => {
  // this call will only be triggered if the condition (prop) is true
  try {
    const location = await api.location.get(locationId)
    return [location]
  } catch (error) {
    return error
  }
}, {
  condition: (apiData, { getState }) => {
    const { forceRefresh } = apiData
    if (forceRefresh) return true // This ensures that the API is called

    const { timestampForTheNextApiCall } = getState().location
    const callEndpoint = canCallEndpoint(timestampForTheNextApiCall)
    return callEndpoint
  }
})

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocation.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        if (action.payload.length && action.payload[0]) {
          const [location] = action.payload
          const nextApiCallTimestamp = getTimestampForNextApiCall(MINUTES_TO_ADD)
          const hasSubscriptionBasedService = isLocationSubscriptionBased(location)
          const country = get(location, 'location.country', '')

          state.loading = false
          state.userLocation = location
          state.timestampForTheNextApiCall = nextApiCallTimestamp
          state.hasSubscriptionBasedService = hasSubscriptionBasedService
          state.country = country
        } else {
          state.loading = false
          state.timestampForTheNextApiCall = 0
        }
      })
      .addCase(getLocation.rejected, (state, action) => {
        state.loading = false
        state.timestampForTheNextApiCall = 0
        state.error = action.payload
      })
  }
})

export const selectUserLocation = (state) => state.location.userLocation
export const selectHasSubscriptionBasedService = (state) => state.location.hasSubscriptionBasedService
export const selectUserCountry = (state) => state.location.country
export const selectLoadingState = (state) => state.location.loading
export const selectErrorState = (state) => state.location.error

export default locationSlice.reducer
