/*
 * Note exposed as an external method
 * used for Shelflife C&R shipments
 */
const toSnapshotDocIdProperties = require('./tools/to-doc-id-properties')
const toLocationProperties = require('./tools/to-location-properties')

const docType = `collection`

async function saveCollectionStats (state, params) {
  const {
    snapshotId,
    collection,
    exclusions
  } = params

  const originId = toSnapshotDocIdProperties(snapshotId).origin
  const locationId = toLocationProperties(originId).id

  const doc = {
    _id: `${snapshotId}:collection`,
    type: docType,
    createdAt: new Date().toJSON(),
    createdBy: state.user.name,
    collection,
    snapshotId,
    locationId,
    exclusions
  }

  const existingDoc = await state.dal.shipment.getById(state, doc._id, { catch404: true })
  if (existingDoc) {
    doc._rev = existingDoc._rev
  }

  const write = await state.dal.shipment.write(state, doc)
  doc._rev = write.rev

  return doc
}

module.exports = saveCollectionStats
module.exports.DOC_TYPE = docType
