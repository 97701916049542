import get from 'lodash/get'
import capitalize from '@fielded/shared-ui/src/utils/capitalize'

// Delivery distance in km
export const MAXIMUM_DELIVERY_RADIUS_KM = 2

export const transformShipmentGPSAndOTPFailedOptions = (gpsReasons, otpReasons) => {
  const gpsFailedOptions = []
  const otpFailedOptions = []

  // we delete the Location permission option
  // because we dont want it visible on the UI
  delete gpsReasons.permission_denied
  for (let reason in gpsReasons) {
    gpsFailedOptions.push({
      label: gpsReasons[reason],
      value: gpsReasons[reason] // we send the object value to the API
    })
  }

  for (let reason in otpReasons) {
    otpFailedOptions.push({
      label: otpReasons[reason],
      value: reason // we send the object key to the API
    })
  }

  return { gpsFailedOptions, otpFailedOptions }
}

export const getPSMDriverDeliveryTitle = (delivery) => {
  let title = ''
  let location = ''
  const id = get(delivery, 'destination.id')

  if (!id) {
    return { title, location }
  }

  const name = id.split(':sdp:')[1]
  if (!name) {
    return { title, location }
  }

  location = capitalize(name.replace(/-/g, ' '))
  title = `Distribution to ${location}`

  return { title, location }
}

export const isWithinDeliveryRadius = (distance) => {
  const formattedDistance = Math.ceil(distance)
  return formattedDistance <= MAXIMUM_DELIVERY_RADIUS_KM
}
