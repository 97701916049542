import get from 'lodash/get'
import { hasFeature } from '../../../van-shared/utils/features'
import { userIsAuthorised } from '../../../van-shared/utils/auth'

import {
  HAS_NO_DATA, IS_LOADING, LOADING_FAILED, LOADING_SUCCEEDED
} from '../common/loading-status'

const reducerName = 'overview'

export const REQUEST_OVERVIEW = `${reducerName}/REQUEST_OVERVIEW`
export const RECEIVE_OVERVIEW = `${reducerName}/RECEIVE_OVERVIEW`
export const USE_CACHE = `${reducerName}/USE_CACHE`
export const RECEIVE_ERROR = `${reducerName}/RECEIVE_ERROR`
export const RESET = `${reducerName}/RESET`

export const defaultState = {
  status: HAS_NO_DATA,
  overviews: null,
  lastOptions: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_OVERVIEW: return {
      ...state,
      status: IS_LOADING,
      lastOptions: action.options
    }
    case RECEIVE_OVERVIEW: return {
      ...state,
      status: LOADING_SUCCEEDED,
      overviews: action.overviews
    }
    case USE_CACHE: return {
      ...state,
      status: LOADING_SUCCEEDED
    }
    case RECEIVE_ERROR: return {
      ...state,
      status: LOADING_FAILED,
      overviews: null
    }
    case RESET: return {
      ...defaultState
    }
    default: return state
  }
}

export const reset = () => {
  return {
    type: RESET
  }
}

export const fetchOverview = (options, config) => async (dispatch, getState, { user, api }) => {
  const { locationId, programId = null } = options
  const date = new Date(options.date)

  const state = getState()
  const status = state.overview.status
  const lastOptions = state.overview.lastOptions

  const partialCounts = hasFeature(config.features, 'stockCount.adjustments')

  if (status === IS_LOADING) {
    return
  }

  if (lastOptions &&
      options.locationId === lastOptions.locationId &&
      options.programId === lastOptions.programId &&
      options.date === lastOptions.date) {
    return dispatch({type: USE_CACHE})
  }
  dispatch({ type: REQUEST_OVERVIEW, options })

  const programs = await api.program.list()
  let userPrograms = programs.filter(p => user.programs.includes(p.id))
  if (programId) {
    userPrograms = userPrograms.filter(p => p.id === programId)
  }

  // Check if we already have the requested overviews loaded
  const existingOverviews = state.overview.overviews
  if (existingOverviews) {
    const alreadyLoaded = userPrograms.every(program => {
      const overview = existingOverviews.find(o => o.id === program.id)
      if (!overview) {
        return false
      }
      if (locationId !== overview.locationId) {
        return false
      }
      if (date < overview.periods.current.entryStartDate ||
          date > overview.periods.current.entryEndDate) {
        return false
      }
      return true
    })
    if (alreadyLoaded) {
      return dispatch({type: USE_CACHE})
    }
  }

  const overviews = await Promise.all(
    userPrograms.map(
      p => api.report.overview.get({locationId, program: p, date})
    )
  )

  /*
   * When partial stock counts are enabled, check which locations can submit those:
   */
  if (partialCounts) {
    await Promise.all(
      overviews.map(async overview => {
        const submitPartials = await api.report.submitPartial({
          locations: overview.locations.all,
          date: options.date,
          programId: overview.id
        })

        // Mutate overview response:
        overview.locations.all.forEach(location => {
          // Partial counts only supported on single-service locations right now
          if (location.services.length > 1) {
            return
          }
          location.submitPartial = get(submitPartials, `${location._id}.submitPartial`, false)
        })
      })
    )
  }

  // This will set isEditablePeriod to true
  // so you can edit all reports from the locations/services view
  const opensExisting = hasFeature(config.features, 'stockCount:opensExistingReport')
  const opensFuture = hasFeature(config.features, 'stockCount:opensFutureReport')
  const userEditsAny = userIsAuthorised(user, 'feature:edits-any-reporting-period')
  const hasReadOnlyReports = userIsAuthorised(user, 'feature:read-only-reports')
  const canSeeReports = (opensExisting || userEditsAny || hasReadOnlyReports)
  overviews.forEach(o => {
    // Active: the one in entry now, current: the period displayed
    const isFuture = o.periods.active.id <= o.periods.current.id
    let canSee = canSeeReports || (isFuture && opensFuture)
    if (canSee) {
      o.periods.current.isEditable = canSee
    }
  })
  dispatch({ type: RECEIVE_OVERVIEW, overviews })
}
