const { getGroup } = require('../read/get')
const { flattenOrdersSnapshots } = require('../../tools/utils')

exports.resetOrderGroup = async function (state, {groupId}) {
  const groupResponse = await getGroup(state, {groupId, withSnapshots: true})
  const {user} = state
  /**
   * if you are a national user
   * if the status of the order you are trying to reset is already accepted
   */
  const isNational = user.location.id === 'national'
  const hasOrderRequester = state.user.roles.includes('feature:orders:requester')
  const orderStatusAccepted = groupResponse.status === 'accepted'
  if (isNational && orderStatusAccepted) {
    throw new Error('National users cannot reset accepted orders')
  }

  if (hasOrderRequester && orderStatusAccepted) {
    throw new Error('State users cannot reset accepted orders')
  }

  const docs = getChangedDocs({groupResponse})
  if (orderStatusAccepted) {
    await state.dal.order.clearShipmentsOnGroup(state, groupId)
  }
  return state.dal.order.bulkDocs(state, docs)
}

function getChangedDocs ({groupResponse}) {
  const {status, isComplete, orders} = groupResponse
  const orderDocs = flattenOrdersSnapshots(orders)
  const {statusToDelete, priorStatus} = getStatusToDelete(status, isComplete)

  // Get the current status snapshots and mark them for delete
  const docsToDelete = orderDocs
    .filter(doc => doc.status === statusToDelete)
    .map(doc => Object.assign({}, doc, {_deleted: true}))

  // get the prior status docs and mark them as not complete.
  // NB: if priorStatus is 'deleted', this will find nothing, which is intenational
  // as we're just clearing out all new docs.
  const priorStatusDocs = orderDocs
    .filter(doc => doc.status === priorStatus)
    .map(doc => Object.assign({}, doc, {isComplete: false}))
  return docsToDelete.concat(priorStatusDocs)
}

function getStatusToDelete (status, isComplete) {
  // we are partially or fully accepted and need to delete accepted docs
  if (status === 'accepted' || (status === 'warehouse' && isComplete)) {
    return {statusToDelete: 'accepted', priorStatus: 'warehouse'}
  }

  // we are just in warehouse and need to go back to state
  if (status === 'warehouse') {
    return {statusToDelete: 'warehouse', priorStatus: 'new'}
  }

  // there was most likely an error in submitting from state to regional.
  // We don't want to delete anything, just reset the docs to isComplete: false
  if (status === 'new' && isComplete) {
    return {statusToDelete: null, priorStatus: 'new'}
  }

  // we are at new and can just delete all the docs
  return {statusToDelete: 'new', priorStatus: null}
}
