/* global XMLHttpRequest */

import { fetchIds, ID_DISP_DOC } from './fetcher'
import get from 'lodash/get'
import generateReplicationId from 'pouchdb-generate-replication-id'
// Setting the upload checkpoint and generic writing checkpoints is identical to replicate-all.
import {
  maybeSetUploadCheckpoint,
  writeCheckpoint,
  saveDocsLocally,
  getCouchDbInfo
} from './common'
import { updatePending, syncError } from './sync-reducer'

// Resolves to true if this browser has already setup replication on list of ids
// Mutates dbConfig input to add list of initialIds.
export async function checkForIDCheckpoint (user, dbConfig, dispatch) {
  const { remoteDb, localDb, idEndpoint, name } = dbConfig
  let ids
  let selector
  let hasCheckpoint = true
  let docCount

  try {
    const idResponse = await fetchIds(idEndpoint, user, name, localDb)
    ids = idResponse.ids
    selector = idResponse.selector
    docCount = typeof idResponse.docCount === 'number' ? idResponse.docCount : ids.length
  } catch (error) {
    const fetchFailMessage = 'Error initial fetching of ID endpoint'
    dispatch(syncError(name, 'direction', error.toString(), fetchFailMessage))
    try {
      let doc = await maybeFetchLocalIds(dbConfig)
      ids = doc.ids
      selector = doc.selector
      docCount = typeof doc.docCount === 'number' ? doc.docCount : ids.length
    } catch (error) {
      const message = 'Error initial fetching of ID endpoint and local datbase never initialized'
      dispatch(syncError(name, 'direction', error.toString(), message))
      return Promise.reject(new Error(message))
    }
  }

  const opts = {}
  if (selector) {
    opts.selector = selector
  } else {
    opts.doc_ids = ids
  }

  dbConfig.initialDownloadRepId = await generateReplicationId(remoteDb, localDb, opts)
  try {
    await localDb.get(dbConfig.initialDownloadRepId)
  } catch (e) {
    hasCheckpoint = false
  }
  dbConfig.selector = selector
  dbConfig.initialIds = ids
  dbConfig.docCount = docCount
  return hasCheckpoint
}

export async function firstReplicateIds (user, dbConfig, dispatch) {
  const { remoteDb, localDb, bidirectional } = dbConfig
  const checkpoint = await downloadAndCheckpoint(user, dbConfig, dispatch)
  if (bidirectional) {
    await maybeSetUploadCheckpoint(remoteDb, localDb)
  }
  return checkpoint
}

async function downloadAndCheckpoint (user, dbConfig, dispatch) {
  const { remoteDb, localDb, name, initialDownloadRepId } = dbConfig
  const info = getCouchDbInfo(remoteDb, name, dispatch)
  const { rows, docs } = await allDocsOnIdsWithProgress(user, dbConfig, info, dispatch)
  await saveDocsLocally(name, localDb, rows || docs, dispatch)
  const options = { writeTargetCheckpoint: true, writeSourceCheckpoint: true }
  return await writeCheckpoint(remoteDb, localDb, initialDownloadRepId, info.update_seq, options) // eslint-disable-line
}

// Differences from allDocsWithProgress: POST, keys arg, URL and size guestimate
export async function allDocsOnIdsWithProgress (
  user,
  {
    remoteDbUrl,
    name,
    initialIds,
    selector,
    docCount,
    docEndpoint,
    useDocDispenser
  },
  info,
  dispatch
) {
  const dbDocCount = get(info, 'doc_count')
  const dataSize = get(info, 'sizes.active') // changed in CouchDB 3.0
  const averageDocSize = dataSize / dbDocCount

  const downloadEstimate = averageDocSize * docCount
  if (initialIds && useDocDispenser) {
    const url = docEndpoint
    const batchSize = 2500
    const batches = []
    for (let i = 0; i < initialIds.length; i += batchSize) {
      const batchIds = initialIds.slice(i, i + batchSize)
      batches.push({ user, dbName: name, ids: batchIds })
    }
    dispatch(updatePending(name, 'download', downloadEstimate))
    const docs = []
    for (const data of batches) {
      const resp = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.withCredentials = true
        xhr.addEventListener('error', reject)
        xhr.addEventListener('abort', reject)
        xhr.addEventListener('progress', ({ loaded }) => {
          if (xhr.status < 400) {
            // TODO fix progress
            let progress = downloadEstimate - loaded
            progress = progress > 0 ? progress : 0
            dispatch(updatePending(name, 'download', progress))
          }
        })
        xhr.addEventListener('load', () => {
          if (xhr.status >= 400) {
            reject(xhr.responseText)
          } else {
            dispatch(updatePending(name, 'download', 0))
            resolve(JSON.parse(xhr.responseText))
          }
        })
        xhr.open('POST', url)
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.send(JSON.stringify(data))
      })
      docs.push(...resp.docs)
    }
    return { docs }
  } else {
    let url
    let data
    if (initialIds) {
      url = `${remoteDbUrl}${name}/_all_docs?include_docs=true`
      data = { keys: initialIds }
    } else if (selector) {
      url = `${remoteDbUrl}${name}/_find`
      data = { selector, limit: docCount + 5000 }
    }
    dispatch(updatePending(name, 'download', downloadEstimate))

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.withCredentials = true
      xhr.addEventListener('error', reject)
      xhr.addEventListener('abort', reject)
      xhr.addEventListener('progress', ({loaded}) => {
        if (xhr.status < 400) {
          let progress = downloadEstimate - loaded
          progress = progress > 0 ? progress : 0
          dispatch(updatePending(name, 'download', progress))
        }
      })
      xhr.addEventListener('load', () => {
        if (xhr.status >= 400) {
          reject(xhr.responseText)
        } else {
          dispatch(updatePending(name, 'download', 0))
          resolve(JSON.parse(xhr.responseText))
        }
      })
      xhr.open('POST', url)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(JSON.stringify(data))
    })
  }
}

async function getIdDispenserDoc (dbConfig) {
  return dbConfig.localDb.get(ID_DISP_DOC)
}

async function getLocalIds (dbConfig) {
  const response = await dbConfig.localDb.allDocs()
  return { ids: response.rows.map(row => row.id) }
}

async function maybeFetchLocalIds (dbConfig) {
  return getIdDispenserDoc(dbConfig)
    .catch(() => { return getLocalIds(dbConfig) })
}
