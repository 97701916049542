function getConfiguredFetch (url, fetch) {
  async function fetcher (endpoint, params) {
    const config = Object.assign(
      {
        headers: {
          'Content-Type': 'application/json'
        }
      },
      params
    )

    const urlWithSlash = url.endsWith('/')
      ? url
      : `${url}/`

    const fullUrl = `${urlWithSlash}${endpoint}`

    const response = await fetch(fullUrl, config)

    const body = (response.headers.get('Content-Type') === 'application/json')
      ? await response.json()
      : await response.text().then(parseJSONP)

    const {ok, status} = response

    return {ok, status, body, url: fullUrl}
  }

  return fetcher
}

// Remita sends back text with jsonp: then valid json.
function parseJSONP (str) {
  return str.includes('jsonp') ? JSON.parse(str.match(/^jsonp *\((.+)\)$/)[1] || '') : str
}

function getOTPHeaders (state) {
  const {apiToken, merchantId, apiKey, requestId, hash} = state
  // remita api wants eg "yyyy-mm-ddThh:mm:ss+000000".
  const timeStamp = new Date().toISOString().replace(/\.\d{3}Z$/, '') + '+0000'

  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${apiToken}`,
    'MERCHANT_ID': `${merchantId}`,
    'API_KEY': `${apiKey}`,
    'REQUEST_ID': `${requestId}`,
    'REQUEST_TS': `${timeStamp}`,
    'API_DETAILS_HASH': `${hash}`
  }
}

module.exports = {getConfiguredFetch, getOTPHeaders}
