import React from 'react'

import { Page } from '@fielded/shared-ui'

import { name } from '../../../common/utils/location-name'

import Header from './Header'
import ReportingProgress from '../common/ReportingProgress'
import OverviewDownloadButton from './OverviewDownloadButton'
import ServicesList from './ServicesList'
import DueDate from './due-date'

import {
  buildLocationsUrl,
  buildProgramsUrl,
  buildServicesUrl
} from '../app/routes-urls'

const Services = ({
  user,
  history,
  date,
  locationId,
  program,
  reportLocation,
  reportLocationId,
  isEditablePeriod,
  config,
  onOpenReport,
  error
}) => {
  const programUrl = buildProgramsUrl({locationId, date})
  const backUrl = buildLocationsUrl({ locationId, date, programId: program.id })
  const id = reportLocation ? reportLocation._id : reportLocationId
  const prevPeriodUrl = buildServicesUrl({
    locationId,
    date: program.periods.previous.entryStartDate,
    programId: program.id,
    reportLocationId: id
  })
  const nextPeriodUrl = buildServicesUrl({
    locationId,
    date: program.periods.next.entryStartDate,
    programId: program.id,
    reportLocationId: id
  })

  return (
    <Page title={config.title}>
      <Header
        config={config}
        periods={program.periods}
        prevPeriodUrl={prevPeriodUrl}
        nextPeriodUrl={nextPeriodUrl}
        title={name(reportLocation)}
        program={program}
        programUrl={programUrl}
        user={user}
        breadcrumbItems={[{
          title: program.name,
          path: backUrl
        }]}
      />
      {error && (
        <Page.Panel.Message type='warning'>
          Error:&nbsp;{error.message}
        </Page.Panel.Message>
      )}
      {!error && (
        <Page.Panel withBackground>
          <Page.Panel.Message fullWidth>
            <DueDate program={program} />
          </Page.Panel.Message>
          <Page.Panel.Section fullWidth className='reports-overview'>
            <div className='reports-overview__sidebar'>
              <ReportingProgress
                progress={reportLocation.progress}
                type='services'
              >
                <OverviewDownloadButton
                  user={user}
                  period={program.periods.current.id}
                  program={program}
                />
              </ReportingProgress>
            </div>
            <div className='main-column'>
              <div className='main-column__content'>
                <ServicesList
                  program={program}
                  reportLocation={reportLocation}
                  isEditablePeriod={isEditablePeriod}
                  onOpenReport={onOpenReport}
                />
              </div>
            </div>
          </Page.Panel.Section>
        </Page.Panel>
      )}
    </Page>
  )
}
export default Services
