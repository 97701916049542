import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ConnectivityStatus from '../../ConnectivityStatus'
import PageComposableHeaderContent from './PageComposableHeaderContent'
import PageComposableHeaderBackButton from './PageComposableHeaderBackButton'
import PageComposableHeaderBreadcrumb from './PageComposableHeaderBreadcrumb'
import PageComposableHeaderTitle from './PageComposableHeaderTitle'
import PageComposableHeaderSubNavigation from './PageComposableHeaderSubNavigation'
import PageComposableHeaderFilters from './PageComposableHeaderFilters'
import PageHeaderSidebarTrigger from './PageHeaderSidebarTrigger'

const PageComposableHeader = ({
  children,
  situation,
  showOfflineWarning,
  className,
  isOnline,
  withSidebarTrigger,
  withSidebarTriggerWrapped
}) => {
  const situationNotNeutral = situation && situation !== 'neutral'
  const warnAboutOffline = showOfflineWarning && !isOnline
  return (
    <header
      className={classnames(
        'vs-page-header',
        {'vs-page-header--offline-warning': warnAboutOffline},
        {'vs-page-header--situation': situation},
        {'vs-page-header--situation-not-neutral': situationNotNeutral},
        className
      )}
    >

      {withSidebarTrigger && (
        <PageHeaderSidebarTrigger wrapped={withSidebarTriggerWrapped} />
      )}

      {warnAboutOffline && (
        <div className='vs-page-header__offline'>
          <ConnectivityStatus isOnline={isOnline} onLight={!situationNotNeutral} />
        </div>
      )}
      {children}
    </header>
  )
}

PageComposableHeader.defaultProps = {
  withSidebarTrigger: true
}

PageComposableHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  withSidebarTrigger: PropTypes.bool
}

PageComposableHeader.displayName = 'Page.ComposableHeader'

PageComposableHeader.Content = PageComposableHeaderContent
PageComposableHeader.Title = PageComposableHeaderTitle
PageComposableHeader.BackButton = PageComposableHeaderBackButton
PageComposableHeader.Breadcrumb = PageComposableHeaderBreadcrumb
PageComposableHeader.SubNavigation = PageComposableHeaderSubNavigation
PageComposableHeader.Filters = PageComposableHeaderFilters

export default PageComposableHeader
