const keyBy = require('lodash/keyBy')
const { list: listRoutes } = require('./../../../routes')
const tools = require('../../tools')
const { getLocations } = require('../master-data')
const { listOrdersForUser } = require('./internal')

exports.listGroups = listGroups
async function listGroups (
  state,
  {parentLocationId, programId, date = new Date().toJSON(), doNotFilterByLocation, useSubOrderId, isComplete, relatedMarkets, filterInterwarehouseOrders = true} = {}
) {
  tools.validateParams('listGroups', arguments[1])
  parentLocationId = parentLocationId || state.user.location.id
  // This needs locations so it can create a count of locations on the order groups.
  // Location count could probably be done in a list groups query
  const locations = await getLocations(state, programId, parentLocationId, date)
  const {orders} = await listOrdersForUser(
    state, programId, parentLocationId, {withAccepted: true, withSnapshots: true, useSubOrderId, isComplete, relatedMarkets, filterInterwarehouseOrders}
  )
  return tools.listGroups({locations, orders, doNotFilterByLocation})
}

exports.listGroupsForDeliveryDates = listGroupsForDeliveryDates
async function listGroupsForDeliveryDates (state, programId, closedOrUnpaid = false) {
  const parentLocationId = state.user.location.id
  const locations = await getLocations(state, programId, parentLocationId)
  const snapshots = await state.dal.order.listGroupsForDeliveryDates(state, programId, parentLocationId, closedOrUnpaid)
  const allRoutes = await listRoutes(state)
  const routesById = keyBy(allRoutes, '_id')
  const status = tools.getUserStatus(state.user)
  const orders = tools.docsToOrderEntities({docs: snapshots, status, useSubOrderId: true})
  const {groups} = tools.listGroups({locations, orders})
  const withRoute = groups.map(group => {
    return {
      ...group,
      routes: group.routes
        .map(routeId => routesById[`route:${routeId}`])
        .filter(x => x)
    }
  })
  return withRoute.sort(sortByLatestCreatedAt)
}

function sortByLatestCreatedAt (a, b) {
  return (a.createdAt < b.createdAt) ? 1 : ((a.createdAt > b.createdAt) ? -1 : 0)
}
