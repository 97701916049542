const get = require('lodash/get')
/**
 * Took out `dlv`.
 * Not bundled properly with webpack when used in the fs-planning-aws lambda. See https://goo.gl/8vruCm
 * Was previously used here as const dlv = require('dlv'),
 * While it exported a default and used differently in other modules with es6 import
 * see https://goo.gl/33i49H
 */

const fieldOrZero = (fieldName, fields) => {
  const amount = (fields[fieldName] && fields[fieldName].amount)
    ? fields[fieldName].amount
    : 0

  // parseInt here comes from standard-consumed-calculated
  // not sure what was there in the early PSM reports ,
  // but it does not seem to hurt
  const value = parseInt(amount, 10)
  return Number.isNaN(value) ? 0 : value
}

const invalid = (fields, ...fieldNames) => {
  return fieldNames.some(name => {
    const value = get(fields, `${name}.amount`)
    return parseInt(value, 10) !== fieldOrZero(name, fields)
  })
}

const fields = {
  'field:standard-opening-balance': {
    name: 'Opening balance',
    id: 'field:standard-opening-balance',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 1,
    validationRules: {
      isEmptyValue: {
        message: 'Opening balance should not be empty'
      }
    }
  },
  'field:standard-opening-balance-shelflife': {
    name: 'Opening balance',
    id: 'field:standard-opening-balance',
    fieldType: 'display',
    type: 'number',
    disabled: true,
    sortIndex: 1,
    display: true
  },
  'field:opening-partner-balance': {
    name: 'Previous partner opening balance',
    id: 'field:opening-partner-balance',
    fieldType: 'display',
    type: 'number',
    disabled: true,
    display: false,
    displayConfirmation: true,
    sortIndex: 2
  },
  'field:standard-beginning-balance': {
    name: 'Beginning balance',
    id: 'field:standard-opening-balance',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 1,
    validationRules: {
      isEmptyValue: {
        message: 'Beginning balance should not be empty'
      }
    }
  },
  'field:standard-beginning-balance-lab': {
    name: 'Beginning balance for the reporting period',
    id: 'field:standard-opening-balance',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 1,
    validationRules: {
      isEmptyValue: {
        message: 'Beginning balance for the reporting period should not be empty'
      }
    }
  },
  'field:standard-opening-balance-malaria': {
    name: 'Beginning balance',
    id: 'field:standard-opening-balance',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 1,
    validationRules: {
      isEmptyValue: {
        message: 'Beginning balance should not be empty'
      }
    }
  },
  'field:standard-opening-balance-fp': {
    name: 'Stock balance at the beginning of the 2 months',
    id: 'field:standard-opening-balance',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 1,
    validationRules: {
      isEmptyValue: {
        message: 'Stock balance at the beginning of the 2 months should not be empty'
      }
    }
  },
  'field:standard-received': {
    name: 'Quantity received',
    id: 'field:standard-received',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 2,
    validationRules: {
      isEmptyValue: {
        message: 'Quantity received should not be empty'
      }
    }
  },
  'field:standard-received-fp': {
    name: 'Quantity received during the 2 months',
    id: 'field:standard-received',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 2,
    validationRules: {
      isEmptyValue: {
        message: 'Quantity received during the 2 months should not be empty'
      }
    }
  },
  'field:standard-consumed-entry': {
    name: 'Quantity dispensed',
    id: 'field:standard-consumed',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 3,
    validationRules: {
      isEmptyValue: {
        message: 'Quantity dispensed should not be empty'
      }
    }
  },
  'field:standard-consumed-entry-lab': {
    name: 'Quantity used',
    id: 'field:standard-consumed',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 3,
    validationRules: {
      isEmptyValue: {
        message: 'Quantity used should not be empty'
      }
    }
  },
  'field:standard-consumed-entry-fp': {
    name: 'Consumption over the past 2 months',
    id: 'field:standard-consumed',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 3,
    validationRules: {
      isEmptyValue: {
        message: 'Consumption over the past 2 months should not be empty'
      }
    }
  },
  'field:custom-tests-done': {
    id: 'field:custom-tests-done',
    name: 'Number of samples done excluding controls',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 3,
    validationRules: {
      isEmptyValue: {
        message: 'Number of samples done excluding controls should not be empty'
      }
    }
  },
  'field:standard-consumed-calculated': {
    name: 'Quantity dispensed',
    id: 'field:standard-consumed',
    fieldType: 'calculated',
    display: false,
    displayConfirmation: true,
    func: (fields) => {
      const getFieldValue = function (shortId) {
        return fieldOrZero(`field:standard-${shortId}`, fields)
      }

      const relevantFields = [
        'opening-balance',
        'received',
        'adjusted',
        'stock-on-hand',
        'lost'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        getFieldValue('opening-balance') + getFieldValue('received') + getFieldValue('adjusted') -
        getFieldValue('lost') - getFieldValue('physical-count')
      )
    },
    type: 'number',
    sortIndex: 3
  },
  'field:standard-consumed-calculated-negative-adjustment': {
    name: 'Consumption',
    id: 'field:standard-consumed',
    fieldType: 'calculated',
    display: true,
    displayConfirmation: true,
    func: (fields) => {
      const getFieldValue = function (shortId) {
        return fieldOrZero(`field:standard-${shortId}`, fields)
      }

      const relevantFields = [
        'opening-balance',
        'received',
        'adjusted',
        'stock-on-hand',
        'lost'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }

      // Opening Bal + received - losses + positive adj - negative adj - Physical count
      return (
        getFieldValue('opening-balance') + getFieldValue('received') + getFieldValue('adjusted') -
        getFieldValue('lost') - getFieldValue('adjusted-negative') - getFieldValue('physical-count')
      )
    },
    type: 'number',
    sortIndex: 3
  },
  // This is the "total sold" field for shelflife
  'field:sold-total-calculated': {
    name: 'Total sold',
    id: 'field:standard-consumed',
    fieldType: 'calculated',
    disabled: true,
    display: true,
    displayConfirmation: true,
    func: (fields, options = {}) => {
      const openingField = 'field:standard-opening-balance'
      const closingField = 'field:standard-physical-count'

      if (invalid(fields, openingField, closingField)) {
        return 0
      }

      const openingBalance = fieldOrZero(openingField, fields)
      const physicalCount = fieldOrZero(closingField, fields)
      const result = openingBalance - physicalCount

      // Note: 'no negative sales' for partner balance locations
      // is taken care of in the stock situation export
      return result
    },
    sortIndex: 4
  },

  // This is an extra field that's never entered, used to represent buy-outs of partner stock:
  'field:shelflife-partner-buyout': {
    name: 'Partner Balance Buy-Out',
    id: 'field:partner-buyout',
    fieldType: 'entry', // not actually entered, only done programmatically
    optional: true,
    type: 'number',
    sortIndex: 2,
    display: false
  },

  // This is an extra field that's never entered, used to represent how much of the buy-out
  // be applied on sales rather than invoice adjustments on stock situation export
  'field:shelflife-sales-adjustment': {
    name: 'Partner Balance Buy-Out',
    id: 'field:sales-adjustments',
    fieldType: 'entry', // not actually entered, only done programmatically
    optional: true,
    type: 'number',
    sortIndex: 3,
    display: false
  },

  'field:partner-balance-calculated': {
    name: 'Closing Partner Balance',
    id: 'field:partner-balance',
    fieldType: 'calculated',
    disabled: true,
    display: false,
    displayConfirmation: true,
    func: (fields, options = {}) => {
      if (!options.tracksPartnerBalances) {
        return 0
      }

      const countField = 'field:standard-physical-count'
      const openingField = 'field:standard-opening-balance'
      const openingPartnerField = 'field:opening-partner-balance'
      // This usually does not exist, so no need to validate:
      const buyOutField = 'field:partner-buyout'

      if (invalid(fields, countField, openingField, openingPartnerField)) {
        return 0
      }

      const physicalCount = fieldOrZero(countField, fields)
      const openingBalance = fieldOrZero(openingField, fields)
      const openingPartnerBalance = fieldOrZero(openingPartnerField, fields)
      const buyOut = fieldOrZero(buyOutField, fields)

      return Math.max(openingPartnerBalance + (physicalCount - openingBalance) - buyOut, 0)
    },
    sortIndex: 5,
    ledger: true
  },

  'field:sold-partner-calculated': {
    name: 'Sold (Partner)',
    id: 'field:partner-sold',
    fieldType: 'calculated',
    disabled: true,
    display: false,
    func: (fields, options = {}) => {
      // partnerSold =  `opening-partner-balance` - `partner-balance`
      const openingPartnerField = 'field:opening-partner-balance'
      const closingPartnerField = 'field:partner-balance'
      const buyOutField = 'field:partner-buyout'

      if (invalid(fields, openingPartnerField, closingPartnerField)) {
        return 0
      }

      const openingPartner = fieldOrZero(openingPartnerField, fields)
      const closingPartner = fieldOrZero(closingPartnerField, fields)
      const buyOut = fieldOrZero(buyOutField, fields)

      // There can't be anything sold if there was nothing to start with
      // for example for fixing the first count
      if (openingPartner === 0) {
        return 0
      }

      // This can be negative, so we can flag it
      // https://github.com/fielded/van-orga/issues/4206
      // (closing partner includes buyOut)
      return openingPartner - closingPartner - buyOut
    },
    // NOTE: needs to be higher than 'partner-balance-calculated'
    sortIndex: 6
  },

  'field:sold-shelflife-calculated': {
    name: 'Shelf Life QTY Sold',
    id: 'field:shelflife-sold',
    fieldType: 'calculated',
    disabled: true,
    display: false,
    displayConfirmation: true,
    func: (fields, options = {}) => {
      // slSold = `standard-consumed` - `partnerSold`
      const totalSoldField = 'field:standard-consumed'
      const partnerSoldField = 'field:partner-sold'

      if (invalid(fields, totalSoldField, partnerSoldField)) {
        return 0
      }

      const totalSold = fieldOrZero(totalSoldField, fields)
      // Partner sold can be negative, to be flagged for follow up
      const partnerSold = Math.max(fieldOrZero(partnerSoldField, fields), 0)

      // Because we 'reverse' buyout in partner sold, its reversed here too:
      const sold = totalSold - partnerSold
      if (options.tracksPartnerBalances) {
        return Math.max(sold, 0)
      }
      return sold
    },
    // Note: needs to be higher than 'sold-partner-balance'
    sortIndex: 7
  },

  'field:opening-shelflife-balance': {
    name: 'Opening Balance (Shelf Life)',
    id: 'field:opening-shelflife-balance',
    fieldType: 'calculated',
    disabled: true,
    display: false,
    func: (fields, options = {}) => {
      const openingField = 'field:standard-opening-balance'
      const openingPartnerField = 'field:opening-partner-balance'

      // Only check standard-opening field here
      // it's ok if opening partner does not exist
      if (invalid(fields, openingField)) {
        return 0
      }

      const openingBalance = fieldOrZero(openingField, fields)
      const openingPartnerBalance = fieldOrZero(openingPartnerField, fields)

      return openingBalance - openingPartnerBalance
    },
    sortIndex: 8
  },

  'field:closing-shelflife-balance': {
    name: 'Closing Balance (Shelf Life)',
    id: 'field:shelflife-balance',
    fieldType: 'calculated',
    disabled: true,
    display: false,
    func: (fields, options = {}) => {
      const balanceField = 'field:standard-physical-count'
      const partnerBalanceField = 'field:partner-balance'

      if (invalid(fields, balanceField, partnerBalanceField)) {
        return 0
      }

      const balance = fieldOrZero(balanceField, fields)
      // Buy-out is included in partner-balance, so that way it gets applied here too
      const partnerBalance = fieldOrZero(partnerBalanceField, fields)

      if (options.isAutomaticCount) {
        return balance - partnerBalance
      }

      return Math.max(balance - partnerBalance, 0)
    },
    sortIndex: 9
  },
  'field:standard-adjusted': {
    name: 'Positive adjustments (+)',
    id: 'field:standard-adjusted',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 4,
    validationRules: {
      isEmptyValue: {
        message: 'Positive adjustments (+) should not be empty'
      }
    }
  },
  'field:standard-adjustments-pmtct': {
    name: 'Positive adjustments (+)',
    id: 'field:standard-adjusted',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 7,
    validationRules: {
      isEmptyValue: {
        message: 'Positive adjustments (+) should not be empty'
      }
    }
  },
  'field:standard-lost': {
    name: 'Losses and Negative adjustments (-)',
    id: 'field:standard-lost',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 5,
    validationRules: {
      isEmptyValue: {
        message: 'Losses and Negative adjustments (-) should not be empty'
      }
    }
  },
  'field:standard-losses-hiv': {
    name: 'Losses',
    id: 'field:standard-lost',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 5,
    validationRules: {
      isEmptyValue: {
        message: 'Losses should not be empty'
      }
    }
  },
  'field:standard-adjusted-negative': {
    name: 'Negative adjustments (-)',
    id: 'field:standard-adjusted-negative',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 5,
    validationRules: {
      isEmptyValue: {
        message: 'Negative adjustments (-) should not be empty'
      }
    }
  },
  'field:standard-losses-pmtct': {
    name: 'Losses and Negative adjustments (-)',
    id: 'field:standard-lost',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 8,
    validationRules: {
      isEmptyValue: {
        message: 'Losses and Negative adjustments (-) should not be empty'
      }
    }
  },
  'field:standard-losses-malaria': {
    name: 'Losses',
    id: 'field:standard-lost',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 3,
    validationRules: {
      isEmptyValue: {
        message: 'Losses should not be empty'
      }
    }
  },
  'field:standard-losses-fp': {
    name: 'Losses',
    id: 'field:standard-lost',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 4,
    validationRules: {
      isEmptyValue: {
        message: 'Losses should not be empty'
      }
    }
  },
  'field:standard-stock-on-hand-entry': {
    name: 'Stock on hand',
    id: 'field:standard-stock-on-hand',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 6,
    validationRules: {
      isEmptyValue: {
        message: 'Stock on hand should not be empty'
      }
    }
  },
  'field:standard-stock-on-hand-calculated': {
    name: 'Stock on hand',
    id: 'field:standard-stock-on-hand',
    fieldType: 'calculated',
    display: false,
    displayConfirmation: false,
    // used to reconcile stock on hand
    // opening + received + adjusted - consumed - lost
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'opening-balance',
        'received',
        'adjusted',
        'consumed',
        'lost'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        getFieldValue('opening-balance') + getFieldValue('received') + getFieldValue('adjusted') -
        getFieldValue('consumed') - getFieldValue('lost')
      )
    },
    type: 'number',
    sortIndex: 6
  },

  'field:standard-stock-on-hand-neg-adjustments-calculated': {
    name: 'Stock on hand',
    id: 'field:standard-stock-on-hand',
    fieldType: 'calculated',
    display: false,
    displayConfirmation: true,
    // used to reconcile stock on hand
    // opening + received + adjusted - consumed - lost
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'opening-balance',
        'received',
        'adjusted',
        'adjusted-negative',
        'consumed',
        'lost'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        getFieldValue('opening-balance') + getFieldValue('received') + getFieldValue('adjusted') -
        getFieldValue('consumed') - getFieldValue('lost') - getFieldValue('adjusted-negative')
      )
    },
    type: 'number',
    sortIndex: 6
  },
  'field:standard-closing-calculated': {
    name: 'Closing stock',
    id: 'field:closing-balance',
    fieldType: 'calculated',
    // used to then drive adjustments:
    // opening + received - consumed
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'opening-balance',
        'received',
        'consumed'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        getFieldValue('opening-balance') + getFieldValue('received') - getFieldValue('consumed')
      )
    },
    type: 'number',
    sortIndex: 6,
    display: true
  },
  'field:standard-losses-adjustments-calculated': {
    name: 'Losses/Adjustments',
    id: 'field:standard-adjusted',
    fieldType: 'calculated',
    // stock-on-hand - closing-balance
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'opening-balance',
        'received',
        'consumed',
        'physical-count'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        getFieldValue('physical-count') - (getFieldValue('opening-balance') + getFieldValue('received') - getFieldValue('consumed'))
      )
    },
    type: 'number',
    sortIndex: 5,
    display: true
  },
  'field:standard-physical-count-on-hand': {
    name: 'Stock on hand',
    id: 'field:standard-physical-count',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 7,
    validationRules: {
      isEmptyValue: {
        message: 'Stock on hand should not be empty'
      }
    }
  },
  'field:standard-physical-inventory': {
    name: 'Physical inventory',
    id: 'field:standard-physical-count',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 6,
    validationRules: {
      isEmptyValue: {
        message: 'Physical inventory should not be empty'
      }
    }
  },
  'field:standard-physical-count-on-hand-shelflife': {
    name: 'Quantity on shelf',
    id: 'field:standard-physical-count',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 2,
    sublabel: 'Please enter all stock on shelf'
  },
  'field:standard-physical-count': {
    name: 'Ending balance (physical count)',
    id: 'field:standard-physical-count',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 7,
    validationRules: {
      isEmptyValue: {
        message: 'Ending balance (physical count) should not be empty'
      }
    }
  },
  'field:standard-physical-count-tb': {
    name: 'Physical Inventory',
    id: 'field:standard-physical-count',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 7,
    validationRules: {
      isEmptyValue: {
        message: 'Physical Inventory should not be empty'
      }
    }
  },
  'field:standard-physical-count-pmtct': {
    name: 'Physical count',
    id: 'field:standard-physical-count',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 4,
    validationRules: {
      isEmptyValue: {
        message: 'Physical count should not be empty'
      }
    }
  },
  'field:standard-physical-count-immunization': {
    name: 'Physical count',
    id: 'field:standard-physical-count',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Physical count should not be empty'
      }
    }
  },
  'field:standard-remark': {
    name: 'Remark',
    id: 'field:standard-remark',
    fieldType: 'entry',
    type: 'text',
    sortIndex: 10001,
    optional: true,
    display: true
  },
  'field:standard-remark-pmtct': {
    name: 'Remark',
    id: 'field:standard-remark',
    fieldType: 'entry',
    type: 'text',
    sortIndex: 6,
    optional: true
  },
  'field:custom-days-out-of-stock': {
    id: 'field:custom-days-out-of-stock',
    name: 'Days out of stock',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      maxValue: {
        value: 60,
        message: 'You tried to enter {entered}. Days out of stock may not exceed 60.'
      },
      isEmptyValue: {
        message: 'Days out of stock should not be empty'
      }
    }
  },
  'field:custom-number-of-patients': {
    id: 'field:custom-number-of-patients',
    name: 'Number of patients',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 5,
    validationRules: {
      isEmptyValue: {
        message: 'Number of patients should not be empty'
      }
    }
  },
  'field:total-custom-number-of-patients': {
    id: 'field:custom-number-of-patients',
    name: 'Total patients last review period',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Total patients last review period should not be empty'
      }
    }
  },
  'field:custom-patients-in': {
    id: 'field:custom-patients-in',
    name: 'New patients, Transfer in, switch/substitute in',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'New patients, Transfer in, switch/substitute in should not be empty'
      }
    }
  },
  'field:custom-patients-out': {
    id: 'field:custom-patients-out',
    name: 'LTFU, Dead, Transfer out, switch/ substitute out',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'LTFU, Dead, Transfer out, switch/ substitute out should not be empty'
      }
    }
  },
  'field:custom-patients-cumulative': {
    id: 'field:custom-patients-cumulative',
    name: 'Cumulative (Current)',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Cumulative (Current) should not be empty'
      }
    }
  },
  'field:custom-patients-cumulative-calculated': {
    id: 'field:custom-patients-cumulative',
    name: 'Cumulative (Current)',
    fieldType: 'calculated',
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:${shortId}`] ? fields[`field:${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'custom-number-of-patients',
        'custom-patients-in',
        'custom-patients-out'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:${shortId}`] ? fields[`field:${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        getFieldValue('custom-number-of-patients') + getFieldValue('custom-patients-in') - getFieldValue('custom-patients-out')
      )
    },
    type: 'number',
    display: true
  },
  'field:custom-summary': {
    id: 'field:custom-summary',
    name: 'Summary',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Summary should not be empty'
      }
    }
  },
  'field:custom-htc': {
    id: 'field:custom-htc',
    name: 'HTC',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'HTC should not be empty'
      }
    }
  },
  'field:custom-pmtct': {
    id: 'field:custom-pmtct',
    name: 'PMTCT',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'PMTCT should not be empty'
      }
    }
  },
  'field:custom-clinical-diagnosis': {
    id: 'field:custom-clinical-diagnosis',
    name: 'Clinical Diagnosis',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Clinical Diagnosis should not be empty'
      }
    }
  },
  'field:custom-quality-control': {
    id: 'field:custom-quality-control',
    name: 'Quality Control',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Quality Control should not be empty'
      }
    }
  },
  'field:custom-training': {
    id: 'field:custom-training',
    name: 'Training',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Training should not be empty'
      }
    }
  },
  'field:custom-screening': {
    id: 'field:custom-screening',
    name: 'Recruit/Outreach Screening',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Recruit/Outreach Screening should not be empty'
      }
    }
  },
  'field:custom-total': {
    id: 'field:custom-total',
    name: 'Total',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Total should not be empty'
      }
    }
  },
  'field:custom-number-tests-done': {
    id: 'field:custom-tests-done',
    name: 'Number',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Number should not be empty'
      }
    }
  },
  'field:custom-month-1': {
    id: 'field:custom-month-1',
    name: 'Month 1',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 1,
    validationRules: {
      isEmptyValue: {
        message: 'Month 1 should not be empty'
      }
    }
  },
  'field:custom-month-2': {
    id: 'field:custom-month-2',
    name: 'Month 2',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 2,
    validationRules: {
      isEmptyValue: {
        message: 'Month 2 should not be empty'
      }
    }
  },
  'field:custom-failed-runs': {
    id: 'field:custom-failed-runs',
    name: 'Failed Runs',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Failed Runs should not be empty'
      }
    }
  },
  'field:custom-no-of-days': {
    id: 'field:custom-no-of-days',
    name: 'Number of Days',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Number of Days should not be empty'
      }
    }
  },
  'field:custom-model': {
    id: 'field:custom-model',
    name: 'Model',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'Model should not be empty'
      }
    }
  },

  'field:custom-a3mc-calculated': {
    name: 'Average Monthly Consumption',
    id: 'field:custom-average-monthly-consumption',
    fieldType: 'calculated',
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'consumed'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        Math.ceil(getFieldValue('consumed') / 3)
      )
    },
    type: 'number',
    sortIndex: 7,
    display: true
  },
  'field:custom-a3mc-5-calculated': {
    name: 'Max stock quantity',
    id: 'field:custom-max-stock-quantity',
    fieldType: 'calculated',
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'consumed'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        Math.ceil(getFieldValue('consumed') / 3 * 5)
      )
    },
    type: 'number',
    sortIndex: 10,
    display: true
  },
  'field:custom-a3mc-4-calculated': {
    name: 'Max stock quantity',
    id: 'field:custom-max-stock-quantity',
    fieldType: 'calculated',
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'consumed'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        Math.ceil(getFieldValue('consumed') / 3 * 4)
      )
    },
    type: 'number',
    sortIndex: 10,
    display: false
  },
  'field:custom-a3mc-4-diff-calculated': {
    name: 'QTO',
    id: 'field:custom-qto',
    fieldType: 'calculated',
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'physical-count'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      const res = Math.ceil((getFieldValue('consumed') / 3 * 4) - (getFieldValue('physical-count')))
      return res > 0 ? res : 0
    },
    type: 'number',
    sortIndex: 11,
    display: false
  },
  'field:custom-a3mc-5-diff-calculated': {
    name: 'QTO',
    id: 'field:custom-qto',
    fieldType: 'calculated',
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'physical-count'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      const res = Math.ceil((getFieldValue('consumed') / 3 * 5) - (getFieldValue('physical-count')))
      return res > 0 ? res : 0
    },
    type: 'number',
    sortIndex: 11,
    display: true
  },
  'field:custom-a2mc-calculated': {
    name: 'Max stock quantity',
    id: 'field:custom-max-stock-quantity',
    fieldType: 'calculated',
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'consumed'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        Math.ceil(getFieldValue('consumed') * 2)
      )
    },
    type: 'number',
    sortIndex: 10,
    display: false,
    displayConfirmation: true
  },
  'field:custom-a2mc-diff-calculated': {
    name: 'QTO',
    id: 'field:custom-qto',
    fieldType: 'calculated',
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'physical-count'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      const res = Math.ceil((getFieldValue('consumed') * 2) - (getFieldValue('physical-count')))
      return res > 0 ? res : 0
    },
    type: 'number',
    sortIndex: 11,
    display: false,
    displayConfirmation: true
  },
  'field:total-positive-test-results': {
    name: 'Total Number of Samples tested',
    id: 'field:total-positive-test-results',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 12,
    validationRules: {
      isEmptyValue: {
        message: 'Total Number of Samples tested should not be empty'
      }
    }
  },
  'field:total-negative-test-results': {
    name: 'Total Samples not tested',
    id: 'field:total-negative-test-results',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 13,
    validationRules: {
      isEmptyValue: {
        message: 'Total Samples not tested should not be empty'
      }
    }
  },
  'field:total-tests-carried-out-calculated': {
    name: 'Total no of Samples received',
    id: 'field:total-tests-carried-out-calculated',
    fieldType: 'calculated',
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:${shortId}`] ? fields[`field:${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'total-positive-test-results',
        'total-negative-test-results'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:${shortId}`] ? fields[`field:${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        getFieldValue('total-positive-test-results') + getFieldValue('total-negative-test-results')
      )
    },
    type: 'number',
    sortIndex: 14,
    display: true
  },
  'field:distributed-to-target-group': {
    name: 'Distributed to target group',
    id: 'field:distributed-to-target-group',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 15,
    validationRules: {
      isEmptyValue: {
        message: 'Distributed to target group should not be empty'
      }
    }
  },
  'field:quality-control': {
    name: 'Quality Control',
    id: 'field:quality-control',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 16,
    validationRules: {
      isEmptyValue: {
        message: 'Quality Control should not be empty'
      }
    }
  },
  'field:condom-demonstration': {
    name: 'Condom Demonstration',
    id: 'field:condom-demonstration',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 17,
    validationRules: {
      isEmptyValue: {
        message: 'Condom Demonstration should not be empty'
      }
    }
  },
  'field:advocacy': {
    name: 'Advocacy',
    id: 'field:advocacy',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 18,
    validationRules: {
      isEmptyValue: {
        message: 'Advocacy should not be empty'
      }
    }
  },
  'field:condom-lubricant-utilization-total': {
    name: 'Total',
    id: 'field:condom-lubricant-utilization-total',
    fieldType: 'calculated',
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:${shortId}`] ? fields[`field:${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'advocacy',
        'condom-demonstration',
        'quality-control',
        'distributed-to-target-group'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:${shortId}`] ? fields[`field:${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        getFieldValue('advocacy') + getFieldValue('condom-demonstration') + getFieldValue('quality-control') + getFieldValue('distributed-to-target-group')
      )
    },
    type: 'number',
    sortIndex: 19,
    display: true
  },
  'field:failed-runs': {
    name: 'Failed Runs',
    id: 'field:failed-runs',
    fieldType: 'entry',
    type: 'number',
    sortIndex: 3,
    validationRules: {
      isEmptyValue: {
        message: 'Failed Runs should not be empty'
      }
    }
  },
  'field:custom-max-stock-lab-calculated': {
    name: 'Max stock quantity',
    id: 'field:custom-max-stock-lab',
    fieldType: 'calculated',
    func: function (fields) {
      const getFieldValue = shortId => {
        const amount = fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : 0
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const relevantFields = [
        'consumed'
      ]
      const isAnyFieldNotEmpty = relevantFields.some(shortId =>
        fields[`field:standard-${shortId}`] ? fields[`field:standard-${shortId}`]['amount'] : undefined
      )
      if (!isAnyFieldNotEmpty) {
        return ''
      }
      return (
        Math.ceil(getFieldValue('consumed') * 2)
      )
    },
    display: false
  },
  'field:custom-qto-calculated': {
    name: 'Custom qto',
    id: 'field:custom-qto',
    fieldType: 'calculated',
    func: function (fields) {
      const asNumber = (amount) => {
        const value = parseInt(amount, 10)
        return Number.isNaN(value) ? 0 : value
      }
      const customValue = get(fields, 'field:custom-max-stock-lab.amount')
      const standardPhysicalCountValue = get(fields, 'field:standard-physical-count.amount')
      if (customValue === undefined || standardPhysicalCountValue === undefined) {
        return ''
      }
      return (
        Math.ceil(asNumber(customValue) - asNumber(standardPhysicalCountValue))
      )
    },
    display: false
  },
  'field:no-distributed': {
    name: 'No Distributed',
    id: 'field:no-distributed',
    fieldType: 'entry',
    type: 'number',
    validationRules: {
      isEmptyValue: {
        message: 'No Distributed should not be empty'
      }
    }
  }
}

module.exports = fields
