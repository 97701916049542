class Customer {
  constructor (quickbooks) {
    this.quickbooks = quickbooks
  }

  async get (companyCode, id) {
    const { Customer: customer, Error: error } = await this.quickbooks.quickbooksInterface.get(
      companyCode,
      `customer/${id}?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`
    )
    if (customer) {
      return customer
    }
    if (error) {
      throw new Error(error.Message)
    }
  }
}

module.exports = {
  Customer
}
