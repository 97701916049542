module.exports = toDraftStockCountId

const toStockCountId = require('./to-stock-count-id')

const {
  toDraft
} = require('./utils/draft')

function toDraftStockCountId (params) {
  const stockCountId = toStockCountId(params)
  return toDraft(stockCountId)
}
