const tools = require('./tools')
const { PgInvoiceApi } = require('./pg-invoice-api.js')
const { AutoInvoiceApi, errors: autoInvoiceErrors } = require('./auto-invoice-api.js')

class InvoicesApi {
  constructor (state, { quickbooksApi, pgConnection }) {
    this.tools = tools
    this.rules = {
      OVERDUE_INVOICES_LIMIT: 3
    }
    this.errors = {}
    if (pgConnection) {
      const pgInvoiceApi = new PgInvoiceApi(pgConnection)
      this.pg = pgInvoiceApi
      this.auto = new AutoInvoiceApi(pgInvoiceApi, quickbooksApi)
      this.errors = {
        ...this.errors,
        ...autoInvoiceErrors
      }
    }
  }
}

module.exports = InvoicesApi
