module.exports = { update }

const tools = require('../../tools')

function update (state, { doc, effectiveDate, funders }) {
  return state.locationsDB.put(doc)
    .then(({ rev }) => {
      const updatedDoc = Object.assign({}, doc, { _rev: rev })
      return tools.docToEntity({ doc: updatedDoc, date: effectiveDate, funders })
    })
}
