import React, { Component, Fragment } from 'react'

import { preparePaymentGroups } from '../../../../../common/utils/shipment-statements'
import { isTopUp } from '../../../../../common/utils/shipment'
import StatementsContainer from '../../../../../statements/StatementsContainer'
import { DIRECT_ORDER_TYPES } from '@fielded/fs-api/lib/allocation/config'
const { PURCHASE } = DIRECT_ORDER_TYPES

class SLDeliveryNote extends Component {
  render () {
    const {
      shipment,
      showAllStatements
    } = this.props

    const locationId = shipment.destination.id
    const date = shipment.snapshotDates.received
    const isTopUpDelivery = isTopUp(shipment)
    const showCountStatements = !isTopUpDelivery && showAllStatements

    const isPurchase = shipment.planningType === PURCHASE
    let paymentTypes = Object.values(DIRECT_ORDER_TYPES)

    const statementsToDisplay = preparePaymentGroups(shipment, paymentTypes)

    return (
      <Fragment>
        {!statementsToDisplay.length && (
          <StatementsContainer
            locationId={locationId}
            shipment={statementsToDisplay}
            date={date}
            showInventory={showCountStatements}
            showSales={showCountStatements}
            showDelivery
            showSummary={!isPurchase}
          />
        )}

        {statementsToDisplay.length && statementsToDisplay.map((statement, key) => {
          return (
            <StatementsContainer
              key={key}
              locationId={locationId}
              shipment={statement.shipment}
              paymentType={statement.paymentType}
              date={date}
              showInventory={showCountStatements}
              showSales={showCountStatements}
              showDelivery
            />
          )
        })
        }
      </Fragment>
    )
  }
}

export default SLDeliveryNote
