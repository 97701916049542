import React from 'react'

import { Form, Page } from '@fielded/shared-ui'

export default () => {
  return (
    <Page.Panel.Section title='License'>
      <Form.Row>
        <Form.Field labelText='Superintending Pharm.' fieldName='superintendingPharm'>
          <Form.Field.Text required />
          <Form.Field.ValidationErrors />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Field labelText='Reg Number' fieldName='regNumber'>
          <Form.Field.Text required />
          <Form.Field.ValidationErrors />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Field labelText='Premise License Number' fieldName='premiseLicenseNumber'>
          <Form.Field.Text required />
          <Form.Field.ValidationErrors />
        </Form.Field>
      </Form.Row>
    </Page.Panel.Section>
  )
}
