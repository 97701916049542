/**
 * This is simply to check if to move forward or backward with 1 or -1 respectively
 * @param event
 * @return number {1|-1}
 */
export const keyboardMovement = event => {
  if (event.key === 'Enter' || event.key === 'Tab') {
    event.preventDefault()
    let movement = 1 // forward movement
    if (event.shiftKey) {
      movement = -1 // backward movement
    }
    return movement
  }
  return null
}

/**
 * Just move between the action buttons tied to a report form
 * @param movement {1|-1} for forward and backward movemment respectively
 *
 */
export const keyboardActionMovement = movement => {
  const nextProductBtn = document.getElementById('nextProductBtn')
  const prevProductBtn = document.getElementById('prevProductBtn')
  const saveStockBtn = document.getElementById('footerSubmitBtn')

  if (movement === 1) {
    // forward movement
    // focus on the next button
    if (nextProductBtn) {
      // check to see if its current in DOM or not
      nextProductBtn.focus()
    } else {
      // yeah not in DOM, means the final product
      saveStockBtn.focus()
    }
  } else {
    // backward movement
    if (prevProductBtn) {
      // check if this is not the first product, means the btn is in DOM
      prevProductBtn.focus()
    }
  }
}
