import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import NumberFormat from 'react-number-format'

import { Arrow } from '../../icons'

const QuantityDiff = (props) => {
  const {
    difference,
    size,
    showSymbol,
    showArrow,
    ...otherProps
  } = props

  const isPositive = difference > 0
  const isNegative = difference < 0

  return (difference !== 0 && difference !== undefined) ? (
    <span className={classnames(
      'vs-quantity-diff',
      {'vs-quantity-diff--positive': isPositive},
      {'vs-quantity-diff--negative': isNegative},
      {'vs-quantity-diff--size-small': size === 'small'},
      {'vs-quantity-diff--size-large': size === 'large'}
    )}>
      {showSymbol && (
        <span className='vs-quantity-diff__symbol'>
          {isPositive && '+'}
          {isNegative && '-'}
        </span>
      )}
      <NumberFormat
        value={Math.abs(difference)}
        displayType='text'
        thousandSeparator
        {...otherProps}
      />
      {showArrow && (
        <span className='vs-quantity-diff__icon'>
          {isNegative && (
            <Arrow direction='down' />
          )}
          {isPositive && (
            <Arrow direction='up' />
          )}
        </span>
      )}
    </span>
  ) : null
}

QuantityDiff.propTypes = {
  /**
   * Difference between e.g. count and expected count for a product.
   */
  difference: PropTypes.number.isRequired,
  /**
   * Determine the size of the label
   */
  size: PropTypes.oneOf(['inherit', 'small', 'large']),
  /**
   * Determines if +/- is shown in the diff
   */
  showSymbol: PropTypes.bool,
  /**
   * Determines if the arrow up or down is shown.
   * Used in case we don't actually show difference, but want to indicate that there was a difference.
   * eg: There was 8 less items delivered. Instead of showing -8 we show 30 (as opposed to 38).
   */
  showArrow: PropTypes.bool
}

QuantityDiff.defaultProps = {
  size: 'small',
  showSymbol: true,
  showArrow: true
}

export default QuantityDiff
