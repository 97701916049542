import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const BatchNumber = ({
  children,
  bold,
  className
}) => {
  return (
    <span className={classnames(
      'vs-batch-number',
      {'vs-batch-number--regular': !bold},
      {'vs-batch-number--bold': bold},
      className
    )}>
      {children}
    </span>
  )
}

BatchNumber.propTypes = {
  /** The content/batch number to render */
  children: PropTypes.node.isRequired,
  /** Render in bold or not */
  bold: PropTypes.bool,
  /** Any additional class names you might need to add */
  className: PropTypes.string
}

export default BatchNumber
