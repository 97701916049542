const cloneDeep = require('lodash/cloneDeep')

exports.editComment = editComment
async function editComment (state, { orders, commentId, text, productId }) {
  const updatedOrders = orders
    .filter((o) => !!o.products[productId])
    .map((order) => {
      const updatedOrder = cloneDeep(order)
      updatedOrder.products[productId].comments = updatedOrder.products[productId].comments
        .map((comment) => {
          if (comment._id !== commentId) return comment
          comment.text = text
          return comment
        })
      return updatedOrder
    })
  await state.dal.order.bulkDocs(state, updatedOrders)
  return updatedOrders[0].products[productId]
}
