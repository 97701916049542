import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import withOffline from '@fielded/shared-ui/src/common/offline'

import { BackButton, Banner, Button, Page, PriceDisplay } from '@fielded/shared-ui'
import { getCurrencySymbol } from '@fielded/shared-ui/src/components/PriceDisplay'
import { Orientation, PartnerBalanceIndicator } from '@fielded/shared-ui/src/icons'

import { pluralize } from '../../../van-shared/utils'
import { hasFeature } from '../../../van-shared/utils/features'

import ConfirmationTable from './ConfirmationTable'

import {
  getInvoiceConfirmationRows
} from './confirmationUtils'

const getTableHeaders = (fields, showVAT) => {
  const keys = [
    'products',
    'shelflife-sold',
    'price',
    showVAT && 'vat',
    'total'
  ].filter(x => x)

  return keys.map(k => ({
    name: k,
    value: get(fields, `field:${k}.name`, k)
  }))
}

const getTableRows = (data = [], country, showVAT, showWarning) => {
  return data.filter(item => {
    const { isProductLine, isTotal } = item

    if (isProductLine || isTotal) {
      return false
    }

    return true
  }).filter(item => {
    const sl = get(item, 'fields.field:shelflife-sold.amount', 0)
    const sob = get(item, 'fields.field:standard-opening-balance.amount', 0)
    const spc = get(item, 'fields.field:standard-physical-count.amount', 0)
    const osb = get(item, 'fields.field:opening-shelflife-balance.amount', 0)

    const hasWarning = showWarning && spc > sob && (sob > 0)

    return ((!hasWarning && sl !== 0) || (hasWarning && osb > 0))
  }).map((item, idx) => {
    const name = get(item, 'name', '-')
    const basicUnit = get(item, 'basicUnit', '-')
    const fullName = `${name} – ${basicUnit}`

    const fields = get(item, 'fields', {})

    // standard
    const sob = get(item, 'fields.field:standard-opening-balance.amount', 0)
    const spc = get(item, 'fields.field:standard-physical-count.amount', 0)

    // other
    const opb = get(item, 'fields.field:opening-partner-balance.amount', 0)
    const pb = get(item, 'fields.field:partner-balance.amount', 0)
    const sls = get(item, 'fields.field:shelflife-sold.amount', 0)
    const price = get(item, 'fields.field:price.price', 0)
    const vat = get(item, 'fields.field:unit-vat.vat', '-')
    const totalSold = get(item, 'fields.field:sold.price', 0)

    const hasPartnerBalance = (opb > 0) || (pb > 0)
    const hasOOCWarning = spc > sob && (sob > 0)
    const hasWarning = showWarning && hasOOCWarning

    const sl = hasOOCWarning ? 'TBD' : sls

    return {
      key: `row.${idx}`,
      cols: [{
        name: 'products',
        value: fullName
      }, {
        name: 'shelflife-sold',
        value: sl
      }, {
        name: 'price',
        value: <PriceDisplay country={country} value={price} />
      }, showVAT && {
        name: 'vat',
        value: <PriceDisplay value={vat} country={country} />
      }, {
        name: 'sold',
        value: hasOOCWarning ? 'TBD' : <PriceDisplay country={country} value={totalSold} />
      }].filter(x => x),
      name: fullName,
      fields,
      hasPartnerBalance,
      hasWarning
    }
  })
}

const getProductsWithZeroSold = (data = []) =>
  data.filter(item => {
    const sl = get(item, 'fields.field:standard-consumed.amount', 0)

    return sl === 0
  })

const getTableTotal = (data = []) => {
  const element = data.find(item => item.isTotal)
  const { name, total } = element

  return {
    name,
    total
  }
}

const SalesSummary = ({
  config,
  report,
  reportLocation,
  serviceId,
  fieldsById: fields,
  productsById,
  onClickGoBack,
  onClickConfirm
}) => {
  const country = get(reportLocation, 'location.country')
  const currency = getCurrencySymbol({country})
  const showVAT = hasFeature(config.features, `showVAT.${get(reportLocation, 'location.country')}`)
  const partialCount = report.partialCount
  const appName = config.name

  // column headers to show in the table
  const confirmationTableHeaders = getTableHeaders(fields, showVAT)

  // rows to show in the table (+ filtered out ones with zero sold)
  const confirmationRows = getInvoiceConfirmationRows({report, productsById, serviceId})
  const confirmationTableRows = getTableRows(confirmationRows, country, showVAT, !partialCount)

  // get the number of products with zero sold
  const zeroSold = getProductsWithZeroSold(confirmationRows).length
  const zeroSoldString = pluralize('product', zeroSold)

  // get total for the table
  const confirmationTableTotal = getTableTotal(confirmationRows)

  // checks if there are out of contract buyings
  const rowsWithWarnings = confirmationTableRows.filter(row => row.hasWarning).length
  const hasOOCWarning = rowsWithWarnings > 0
  const productsString = pluralize('product', rowsWithWarnings)

  // checks if there were no sales
  const noSales = getProductsWithZeroSold(confirmationRows).length === confirmationRows.length && rowsWithWarnings.length !== confirmationRows.length

  // checks if location has Partner Balance enabled
  const hasPartnerBalance = get(reportLocation, 'tracksPartnerBalances')
  const anyPartnerBalanceItems = confirmationTableRows.some(row => row.hasPartnerBalance)

  return (
    <Page>
      <header className='confirmation__back-button'>
        <BackButton
          onClick={onClickGoBack}
        />
      </header>
      <Page.Intro centered className='confirmation__intro'>Sales Summary <Orientation /></Page.Intro>
      {hasOOCWarning &&
      <Page.Panel centered className='confirmation__warning'>
        <Banner
          type='warning'
          inline
          title='Outside buying'
        >
          {`${rowsWithWarnings} ${productsString} have been flagged for outside buying, as we counted units that were not supplied by ${appName}. You will be invoiced for the remaining ${appName} balance for these products.`}
        </Banner>
      </Page.Panel>}
      <Page.Panel
        withBackground
        className='confirmation__panel'
      >
        <Page.Panel.Section fullWidth>
          <header className='confirmation__header'>
            <div className='confirmation__header-title'>
              {reportLocation.name}
              <div className='confirmation__header-info'>{appName} Pharmaceuticals</div>
            </div>
            {hasPartnerBalance && anyPartnerBalanceItems && <div className='confirmation__header-pb'>
              <span className='confirmation__header-pb-icon'>
                <PartnerBalanceIndicator fill='#230D53' />
              </span>
              = {appName} subscription with pharmacy stock remaining
            </div>}
          </header>
          {!noSales &&
          <ConfirmationTable
            headers={confirmationTableHeaders}
            rows={confirmationTableRows}
            total={confirmationTableTotal}
            currency={currency}
            country={country}
            locationName={reportLocation.name}
            theme='summary'
            showVAT={showVAT}
            appName={appName}
          />}
          {noSales && <Page.Panel className='confirmation__no-sales'>You have no sales for this week.</Page.Panel>}
        </Page.Panel.Section>
      </Page.Panel>
      {!noSales && <Page.Panel className='confirmation__block'>
        {`Note: This location is subscribed to an additional ${zeroSold} ${zeroSoldString} with no recorded sales for this period.`}
      </Page.Panel>}
      {hasOOCWarning && <Page.Panel className='confirmation__block confirmation__block--warning'>Kindly note: Total sold for flagged products will be calculated and available in your sales statement and invoice.</Page.Panel>}
      <Page.Footer nowrap>
        <Button
          onClick={onClickConfirm}
          colorVariant='brand'
          fill='full'
          size='large'
        >
          Confirm
        </Button>

        <Button
          alignOpposite
          onClick={onClickGoBack}
          colorVariant='brand'
          fill='outline'
        >
          Go back
        </Button>
      </Page.Footer>
    </Page>
  )
}

SalesSummary.propTypes = {
  config: PropTypes.object.isRequired,
  /**
   * Report object for the count to confirm
   */
  report: PropTypes.object.isRequired,
  /**
   * Full location object for report location
   */
  reportLocation: PropTypes.object.isRequired,
  /**
   * From masterdata
   */
  programName: PropTypes.string.isRequired,
  /**
   * From masterdata
   */
  serviceId: PropTypes.string.isRequired,
  /**
   * Indexed, all products from masterdata
   */
  productsById: PropTypes.object.isRequired,
  /**
   * Indexed object, all fields from report definition
   */
  fieldsById: PropTypes.object.isRequired,
  onClickGoBack: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
  isOnline: PropTypes.bool.isRequired
}

export default withOffline(SalesSummary)
