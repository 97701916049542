import React from 'react'

import { BatchNumber, Button, Card, Page } from '@fielded/shared-ui'

const BatchOptions = ({ batches, selectedProduct, selectBatch, removeBatchCount }) => {
  return (
    <div className='new-psm-shipment__batch-options-wrapper'>
      <Page.Intro title={`${selectedProduct.fullName}`}>
        <div className='new-psm-shipment__batch-shipment-wrapper'>
          {batches.length > 0 && batches.map((batch) => {
            const count = batch.quantity
            return (
              <div className='new-psm-shipment__batch-card-container' key={batch._id}>
                <Card tight key={batch._id} className={'new-psm-shipment__batch-card'}>
                  <Card.Content className={'new-psm-shipment__batch-card-content'}>
                    <BatchNumber bold>{batch.batchNumber}</BatchNumber>
                    <span>amount: {count}</span>
                  </Card.Content>
                  <Card.Action centered>
                    <Button
                      colorVariant='brand'
                      fill='outline'
                      onClick={() => selectBatch(batch._id)}
                    >
                      Edit
                    </Button>
                    <Button
                      colorVariant='warning'
                      fill='outline'
                      onClick={() => removeBatchCount(batch)}
                    >
                      Remove
                    </Button>
                  </Card.Action>
                </Card>
              </div>
            )
          })}
          <div className='new-psm-shipment__batch-card-container'>
            <Card className={'new-psm-shipment__batch-card'} tight>
              <Card.Content className={'new-psm-shipment__batch-card-content'}>
                <span>Add / Create a batch:</span>
              </Card.Content>
              <Card.Action centered>
                <Button
                  colorVariant='brand'
                  fill='outline'
                  onClick={() => selectBatch()}
                >
                  Create
                </Button>
              </Card.Action>
            </Card>
          </div>
        </div>
      </Page.Intro>
    </div>
  )
}

export default BatchOptions
