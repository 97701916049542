import React from 'react'

export const PayOnDelivery = () => {
  return (
    <svg viewBox='0 0 30 18' fill='none' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
      <path d='M29.3795 16.6998L29.3828 2.0632C29.3833 1.73053 29.2519 1.41128 29.0174 1.17531C28.783 0.939329 28.4645 0.805904 28.1318 0.804271L1.51874 0.797128C1.18605 0.797791 0.866993 0.92937 0.630563 1.16342C0.394133 1.39746 0.259332 1.71517 0.255312 2.04782L0.251923 16.6845C0.251434 17.0175 0.382646 17.3372 0.616951 17.5739C0.851255 17.8106 1.16964 17.945 1.50267 17.9479L28.1157 17.9549C28.4496 17.9555 28.7701 17.8237 29.0069 17.5885C29.2438 17.3532 29.3777 17.0337 29.3795 16.6998ZM2.77407 12.0139L2.77448 6.74905C3.59317 6.52711 4.33968 6.09521 4.94008 5.49604C5.54047 4.89687 5.97394 4.15125 6.19753 3.33304L23.4442 3.3153C23.6665 4.13454 24.0991 4.88144 24.6991 5.48192C25.2992 6.08239 26.0457 6.51556 26.8648 6.7385L26.8647 11.9989C26.0454 12.2212 25.2985 12.6538 24.698 13.2538C24.0975 13.8538 23.6643 14.6003 23.4414 15.4194L6.2005 15.4153C5.97439 14.5994 5.53943 13.8565 4.93855 13.26C4.33767 12.6635 3.59164 12.2341 2.77407 12.0139Z' fill='#3D4FAE' />
      <path d='M17.395 11.9569C18.818 10.5344 18.8184 8.22778 17.3959 6.80483C15.9733 5.38187 13.6666 5.38147 12.2436 6.80392C10.8206 8.22637 10.8202 10.533 12.2427 11.956C13.6652 13.3789 15.972 13.3793 17.395 11.9569Z' fill='#3D4FAE' />
    </svg>
  )
}
