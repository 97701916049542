const { PouchAdapter } = require('../../common')
const schema = require('../schema')

class FunderPouchAdapter extends PouchAdapter {
  constructor (user, locationsDB, restAdapter) {
    super(user, schema, locationsDB)
    this.restAdapter = restAdapter
  }
}

module.exports = FunderPouchAdapter
