const md5 = require('pouchdb-md5')

const generateId = ({
  facilityId,
  programId,
  funders = []
}) => {
  if (funders.length === 0) {
    funders = ['funder:all-funders']
  }

  const funderHash = md5.stringMd5(funders.sort().join(':'))

  return `off-trend:${facilityId}:${programId}:funder-hash:${funderHash}`
}

const getOfftrend = async (state, params) => {
  if (!params.facilityId) {
    throw new Error('Need facilityId for off-trends')
  }

  if (!params.programId) {
    throw new Error('Need programId for off-trends')
  }

  const id = generateId(params)

  const doc = await state.allocationsDB.get(id)
    .catch(err => {
      if (err.status === 404) {
        return { _id: id, products: {} }
      }

      throw err
    })

  const formatted = {
    ...doc,
    products: {}
  }

  // Let the user send a cutoffdate,
  // where we're only interested in off-trends after this date
  if (params.cutOffDate) {
    Object.keys(doc.products).forEach(productId => {
      formatted.products[productId] = doc.products[productId].filter(item => {
        return item.endDate > params.cutOffDate
      })
    })
  } else {
    formatted.products = doc.products
  }

  return formatted
}

module.exports = getOfftrend
module.exports.offtrendId = generateId
