const { transFormOriginBonusShareQuery } = require('./queries')

async function priceBonusShareJob ({data, pgConnection}) {
  const {
    from_date: fromDate,
    to_date: toDate,
    from_market: fromMarket,
    to_market: toMarket
  } = data

  const tableName = 'avocado.price_calculator_bonus_share'

  // Get transform query
  const tranformedOriginBonusShareQuery = transFormOriginBonusShareQuery(tableName)

  try {
    const { rowCount: replicatedBonusShareRowsCount } = await pgConnection.query(tranformedOriginBonusShareQuery, [fromDate, toDate, fromMarket, toMarket])
    return {replicatedBonusShareRows: Number(replicatedBonusShareRowsCount)}
  } catch (e) {
    throw new Error('Error creating new bonus share rows', e)
  }
}

module.exports = priceBonusShareJob
