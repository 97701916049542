const { getLtForSelector } = require('../../tools/utils')
// pouchdb's db.changes() function does not return the `pending`
// number in the response (only during replication, see https://github.com/pouchdb/pouchdb/issues/5710)
// so we have to use the fetch adapter & post to `_changes` ourselves
exports.listChanges = async function (state, queryParams = {}) {
  const params = getListParams(queryParams)
  const {
    results,
    last_seq: lastSeq,
    pending
  } = await postJSON(state.fetch, `${state.orderDbName}/_changes`, params)

  // prefer to use the document level sequence number as it is less likely
  // to generate skipped docs, see the following issue for more information:
  // https://github.com/apache/couchdb/issues/1221
  const sequence = results.length
    ? results[results.length - 1].seq
    : lastSeq

  const docs = results.map(row => row.doc).filter(x => x)
  return {docs, sequence, pending: !!pending}
}

async function postJSON (fetch, endpoint, {urlParams, bodyParams}) {
  const body = JSON.stringify(bodyParams)
  const response = await fetch(`${endpoint}${urlParams}`, {method: 'POST', body})
  return returnOrRejectJSON(response)
}

// Couch does not return an error object with a `.message` prop,
// so this error construction is to get the response to return
// a message & an HTTP error in the lamdbas https://github.com/fielded/van-indicators-aws-lambda/blob/master/lib/utils/httpError.js#L11
async function returnOrRejectJSON (response) {
  const body = await response.json()
  if (!response.ok) {
    const error = new Error()
    error.status = response.status
    // couch gives a 'reason' on some errors
    error.message = body.reason
    throw error
  }

  return body
}

function getListParams ({
  geolocationId, since = 0, limit, programId, status, funderId, isComplete = false, closedStatus
}) {
  const bodyParams = {
    selector: {
      _deleted: {'$exists': false},
      status: {'$eq': status},
      isComplete: {'$eq': isComplete}
    }
  }

  // closedStatus either doesn't exist or is a string with length > 0
  if (closedStatus === null) {
    bodyParams.selector.closedStatus = { '$exists': false }
  } else if (closedStatus !== undefined) {
    bodyParams.selector.closedStatus = { '$eq': closedStatus }
  }

  if (programId) {
    bodyParams.selector.programId = {'$eq': programId}
  }

  if (geolocationId) {
    bodyParams.selector.destinationId = {
      '$gte': geolocationId,
      '$lt': getLtForSelector(geolocationId)
    }
  }

  // NB: we never committed to funder filtering in the REST API, however
  // it does help the PSM team from time to time in troubleshooting.
  // ONE integration does not use it (nor any of these filters as far as we know.)
  if (funderId) {
    bodyParams.selector.funderId = {'$regex': funderId}
  }

  const urlParams = encodeQueryData({
    filter: '_selector',
    since,
    limit,
    include_docs: true
  })
  return {urlParams, bodyParams}
}

function encodeQueryData (data) {
  const params = []
  for (let key in data) {
    params.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
  }
  return `?${params.join('&')}`
}
