import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import get from 'lodash/get'

import { Button, Page, StatusPill, Text } from '@fielded/shared-ui'
import { Landmark, MapMarker, Phone, Truck } from '@fielded/shared-ui/src/icons'
import capitalize from '@fielded/shared-ui/src/utils/capitalize'

import { SHIPMENT_STATUS, SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS } from '@fielded/fs-api/lib/shipment/constants'
import { getPSMDriverDeliveryTitle } from '../utils'
import PSMDeliveryAuthenticationContainer from './PSMDeliveryAuthentication/PSMDeliveryAuthenticationContainer'
import { PERMISSION_STATES } from '../../../../../../utils/permissions-service'
import DriverDeliveryPermissionModal from '../component/DriverDeliveryPermissionModal'

const PSMDeliveryLocationDetails = ({ details }) => {
  const { icon, info, type } = details
  const isPhoneDetails = type === 'phoneNumber'
  const hasNoPhoneDetails = isPhoneDetails && !info

  let infoText = info
  if (hasNoPhoneDetails) {
    infoText = 'No phone number'
  }

  return (
    <li className='delivery-location__info'>
      <section className='delivery-location__info-item'>
        <div className='delivery-location__info-icon-wrapper'>
          <Text className={classnames(
            'delivery-location__icon',
            {'delivery-location__info--disabled': hasNoPhoneDetails}
          )}
          >
            {icon}
          </Text>
          <Text className={classnames(
            'delivery-location__text',
            {'delivery-location__info--disabled': hasNoPhoneDetails}
          )}>
            {infoText}
          </Text>
        </div>

        {isPhoneDetails && (
          <Button
            component={!hasNoPhoneDetails && Link}
            href={!hasNoPhoneDetails ? `tel:${infoText}` : ''}
            fill='outline'
            colorVariant='brand'
            size='small'
            disabled={hasNoPhoneDetails}
          >
            Call
          </Button>
        )}
      </section>
    </li>
  )
}

const PSMDeliveryLocation = ({
  api,
  otpKey,
  skipGPSValidation,
  history,
  shipment,
  watchId,
  permission,
  geoPosition,
  position,
  distance,
  locationObject,
  positionErrorMsg,
  isBottomDrawerOpen,
  isPermissionModalOpen,
  isRequestingPermission,
  onOpenBottomDrawer,
  onCloseBottomDrawer,
  onGetGeolocation,
  onOpenPermissionModal,
  onClosePermissionModal,
  onRequestLocationPermission
}) => {
  const { title: subtitle, location: facilityZone } = getPSMDriverDeliveryTitle(shipment)

  const shipmentId = get(shipment, 'id')
  const status = get(shipment, 'status')
  const programId = get(shipment, 'programId') || get(shipment, 'program')
  const isShipmentInTransit = status === SHIPMENT_STATUS.SENT

  const facilityDetails = {
    address: {
      type: 'address',
      info: get(locationObject, 'additionalData.physicalAddress', ''),
      icon: <MapMarker />
    },
    zone: {
      type: 'zone',
      info: facilityZone,
      icon: <Landmark />
    },
    phoneNumber: {
      type: 'phoneNumber',
      info: get(locationObject, `contacts${programId}.phone`, ''),
      icon: <Phone />
    }
  }

  const onHandleConfirmLocation = () => {
    if (permission === PERMISSION_STATES.PROMPT) {
      onOpenPermissionModal()
    } else {
      onOpenBottomDrawer()
    }
  }

  return (
    <Page className='delivery-location'>
      <Page.HeaderNew
        title='Deliveries'
        subtitle={subtitle}
        icon={<Truck />}
        history={history}
        showOfflineWarning
      />
      <Page.Panel narrow alignLeft>
        <Page.Panel.Section>
          <StatusPill
            className={`delivery-location__status--${status}`}
            label='Status'
            value={capitalize(SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS[status])}
          />
        </Page.Panel.Section>

        <Page.Panel.Section>
          <Text.Heading>Facility details</Text.Heading>

          <div className='delivery-location__info-wrapper'>
            <ul>
              {Object.keys(facilityDetails).map(detailKey => (
                <PSMDeliveryLocationDetails
                  key={detailKey}
                  details={facilityDetails[detailKey]}
                />
              ))}
            </ul>
          </div>
        </Page.Panel.Section>
      </Page.Panel>

      <PSMDeliveryAuthenticationContainer
        api={api}
        otpKey={otpKey}
        skipGPSValidation={skipGPSValidation}
        history={history}
        shipmentId={shipmentId}
        isBottomDrawerOpen={isBottomDrawerOpen}
        permission={permission}
        geoPosition={geoPosition}
        position={position}
        distance={distance}
        facilityName={facilityZone}
        locationObject={locationObject}
        positionErrorMsg={positionErrorMsg}
        onClose={onCloseBottomDrawer}
      />

      {isShipmentInTransit && (
        <Page.Footer fixed nowrap>
          <Button
            colorVariant='brand'
            fill='full'
            onClick={onHandleConfirmLocation}
          >
            Confirm location
          </Button>
        </Page.Footer>
      )}

      <DriverDeliveryPermissionModal
        isPermissionModalOpen={isPermissionModalOpen}
        isRequestingPermission={isRequestingPermission}
        onClosePermissionModal={onClosePermissionModal}
        onRequestLocationPermission={onRequestLocationPermission}
      />
    </Page>
  )
}

export default PSMDeliveryLocation
