const get = require('lodash/get')
const set = require('lodash/set')
const { listReportFields } = require('../../service')
const docToStockCountRecord = require('../../tools/doc-to-stock-count-record')

async function createAutomaticFullCount (state, { api, location, doNotMergeCounts = false }) {
  // Using fsid and service for the location create a new full count with either a ledger or an empty object
  const serviceId = get(location, 'programs[0].services[0].id')
  const programId = get(location, 'programs[0].id')

  const service = await api.service.get(serviceId)

  const date = new Date().toJSON()
  const period = await api.report.period.get({
    programId,
    date,
    isEffectiveDate: false
  })

  const ledger = await api.stock.getLedgerBalanceAsReport({
    location: { id: location._id },
    service,
    date: date,
    excludeProducts: 'configuration'
  })

  // Copy opening-balances
  Object.values(ledger.stock).forEach(productField => {
    const opening = get(productField, 'fields.field:standard-physical-count.amount', 0)
    const openingPartner = get(productField, 'fields.field:partner-balance.amount', 0)

    set(productField, 'fields.field:standard-opening-balance.amount', opening)
    set(productField, 'fields.field:opening-partner-balance.amount', openingPartner)
  })

  const fields = await listReportFields(state, serviceId)
  const stockCount = docToStockCountRecord(ledger, service, { fields, fieldOpts: { tracksPartnerBalances: location.tracksPartnerBalances, isAutomaticCount: true } })

  // There could be multiple service changes within the same reporting period
  // Or a change that happened mid reporting period
  // This is to make sure we get the current service we're on after updating
  period.effectiveStartDate = date

  const params = {
    locationId: location._id,
    service,
    period,
    stock: stockCount.stock,
    partialCount: false,
    submittedAt: date,
    documents: stockCount.documents,
    doNotMergeCounts
  }

  return params
}

module.exports = createAutomaticFullCount
