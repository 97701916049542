const PGAdapter = require('./../common/pg-adapter')

class PriceUpdateEntryPGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = [
      'id',
      'price_update_id',
      'visibility',
      'markup',
      'created_at',
      'updated_at'
    ]
    const opts = {
      addUuid: false
    }

    super(pgConnection, 'avocado.data_priceupdateentry', username, columns, 'id', {}, logger, opts)
  }

  async clearEntriesForPriceUpdate (priceUpdate) {
    return this.pgConnection.query(`DELETE FROM ${this.tableName} WHERE price_update_id = $1`, [priceUpdate.id])
  }
}

module.exports = {PriceUpdateEntryPGAdapter}
