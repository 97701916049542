import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Form, Page, Text } from '@fielded/shared-ui'
import EntryViewWrapper from '../common/EntryViewWrapper'

const ForgotPassword = ({
  config,
  onSuccess,
  onSubmit,
  inProcess,
  error
}) => {
  return (
    <EntryViewWrapper config={config}>
      {onSuccess && (
        <Fragment>
          <Page.Panel.Section>
            <div className='login__panel-title'>
              Reset email sent
            </div>
          </Page.Panel.Section>
          <Page.Panel.Section>
            <p>
              Please check your email for instructions on resetting your password.
            </p>
          </Page.Panel.Section>
          <Page.Panel.Section>
            <NavLink
              to='/login'
              className='vs-u-link'
            >
              Return to login
            </NavLink>
          </Page.Panel.Section>
        </Fragment>
      )}

      {!onSuccess && (
        <Fragment>
          <Page.Panel.Section>
            <div className='login__panel-title'>
              Forgot password
            </div>
          </Page.Panel.Section>

          {error && (
            <Page.Panel.Message type='danger' cta={false}>
              {error}
            </Page.Panel.Message>
          )}

          <Page.Panel.Section>
            <Text>
              Enter the email address you used to create this account,
              and we will send a link to reset your password.
            </Text>
          </Page.Panel.Section>

          <Page.Panel.Section>
            <Form
              onSubmit={onSubmit}
              component='form'
            >
              <Form.Row>
                <Form.Field
                  fieldName='email'
                  labelText='Your email address'
                >
                  <Form.Field.Text
                    autoFocus
                    placeholder='your@email.com'
                    required='Please provide a email'
                    type='email'
                    disabled={inProcess}
                    autoCapitalize='off'
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>

              <Form.Actions>
                <NavLink
                  to='/login'
                  className='vs-u-link'
                >
                  Return to login
                </NavLink>
                <Form.Button
                  alignOpposite
                  size='medium'
                  disabled={inProcess}
                  loading={inProcess}
                >
                  Send reset email
                </Form.Button>
              </Form.Actions>
            </Form>
          </Page.Panel.Section>
        </Fragment>
      )}
    </EntryViewWrapper>
  )
}

ForgotPassword.propTypes = {
  config: PropTypes.object.isRequired,
  loginInProgress: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
}

ForgotPassword.defaultProps = {
  loginInProgress: false,
  errorMessage: null
}

export default ForgotPassword
