const tools = require('../../tools')
const groupBy = require('lodash/groupBy')
const { getAllLocations } = require('../master-data')
const { listAll: getAllProducts } = require('../../../product')
const format = require('date-fns/format')
const addDays = require('date-fns/add_days')
const { EXPECTED_HEADERS } = require('../../constants')

exports.resupplyImport = (state, docs) => state.dal.order.bulkDocs(state, docs)

// UI calls this, previews it, and passes back snapshots to bulkCreate
exports.createResupplySnapshots = async function (state, {data, deliveryDateObj, orderType, api}, parseCsv = true) {
  let locations
  const products = await getAllProducts(state)

  // Get current date and add 3 days
  const deliveryDate = format(deliveryDateObj || addDays(new Date(), 3), 'YYYY-MM-DD')
  let unfilteredRows = data
  if (parseCsv) {
    unfilteredRows = tools.parseCSV(data)

    if (unfilteredRows.length === 0) {
      return {
        counts: {
          products: 0,
          orders: 0,
          locations: 0
        }
      }
    }
    tools.throwOnInvalidRow(unfilteredRows, EXPECTED_HEADERS)
  }

  if (api) {
    const locationSet = new Set()
    unfilteredRows.forEach(r => {
      locationSet.add(r.supplierId)
      locationSet.add(r.destinationId)
    })
    const locationIds = [...locationSet]
    locations = await api.location.getLocationsViaIds(locationIds)
  } else {
    locations = await getAllLocations(state)
  }

  const { rows } = tools.filterOutMissing(unfilteredRows, locations, products)
  if (rows.length === 0) {
    return {
      counts: {
        products: 0,
        orders: 0,
        locations: 0
      }
    }
  }
  const rowsbyDestinationId = groupBy(rows, 'destinationId')

  const allocations = tools.getAllLocationsFromSheet(rowsbyDestinationId, locations)

  const snapshots = tools.createNewOrders({
    programId: 'program:shelflife',
    allocations,
    user: state.user,
    deliveryDate,
    orderType: orderType || 'resupply'
  })

  const resultRows = []
  snapshots.forEach(snapshot => {
    Object.keys(snapshot.products).forEach((productId) => {
      const location = locations.find(l => l._id === snapshot.destinationId)
      const supplier = locations.find(l => l._id === snapshot.supplierId)
      const product = products.find(p => p._id === productId)

      resultRows.push({
        deliveryDate: snapshot.deliveryDate,
        locationId: snapshot.destinationId,
        supplierId: snapshot.supplierId,
        supplierName: supplier.name,
        locationName: location.name,
        productId: productId,
        productName: product.name,
        quantity: snapshot.products[productId].original
      })
    })
  })

  const productIds = new Set(rows.map(row => row['productId']))

  const counts = {
    products: productIds.size,
    orders: new Set(snapshots.map(row => row.suborderId)).size,
    locations: new Set(snapshots.map(row => row.destinationId)).size,
    suppliers: new Set(snapshots.map(row => row.supplierId)).size
  }

  return {snapshots, counts, rows: resultRows}
}
