const findForLocation = require('./report/api/find-for-location')
const { get: getService } = require('./service')
const { periodIdToDate } = require('./report/tools/ids')
const getPeriodFromProgram = require('./report/tools/get-period-from-program')

module.exports = async (state, options = {}) => {
  if (options.addMissingReports) {
    throw new Error('addMissingReport is no longer supported')
  }
  if (options.ignoreExisting) {
    throw new Error('ignoreExisting is no longer supported')
  }
  if (options.addMissingStock && options.addMissingStock !== 'products') {
    throw new Error('addMissingStock: "' + options.addMissingStock + '" is not supported, use "products", or getLedgerBalance')
  }
  const addProducts = options.addMissingStock === 'products'

  const service = await getService(state, options.serviceId || 'program:immunization:service:immunization')

  // Do reportingPeriod, startDate, endDate options parsing
  let startDate
  let endDate
  if (options.reportingPeriod) {
    const date = periodIdToDate(options.reportingPeriod)
    const period = getPeriodFromProgram(service.program, date, true)
    startDate = period.effectiveStartDate
    endDate = period.effectiveEndDate
  } else if (options.since || options.till) {
    if (!options.since) {
      // default startdate
      startDate = new Date('2018-01-01T00:00:00.000Z')
    } else if (options.since.match(/-(W|M)/)) {
      // since is a reportingPeriod
      startDate = periodIdToDate(options.since)
    } else {
      // since should be a date of some sort
      startDate = options.since
    }
    if (!options.till) {
      // default enddate
      endDate = new Date()
    } else if (options.till.match(/-(W|M)/)) {
      // till is a reportingPeriod
      endDate = periodIdToDate(options.till)
    } else {
      // till should be a date of some sort
      endDate = options.till
    }
  } else {
    const period = getPeriodFromProgram(service.program, new Date())
    startDate = period.effectiveStartDate
    endDate = period.effectiveEndDate
  }

  const includeChildren = options.hasOwnProperty('includeChildren') ? options.includeChildren : true

  return findForLocation(state, {
    locationId: options.location ? options.location.id : state.user.location.id,
    serviceId: service.id,
    startDate,
    endDate,
    includeChildren,
    entityOptions: {
      addProducts,
      addFields: addProducts,
      addProgress: options.addProgress,
      addSubmitConfig: options.addSubmitConfig,
      convertWarehouseUnits: options.convertWarehouseUnits
    }
  })
}
