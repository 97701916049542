import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const WizardStepHeader = ({
  icon,
  title,
  description,
  statusClassName,
  theme = 'primary',
  children,
  error,
  errorDescription
}) => {
  let content = null
  switch (theme) {
    case 'secondary':
      content = (
        <div className='wizard-step__theme wizard-step__theme--secondary'>
          <div className='wizard-step__divider' />
          <p className='theme__title'>
            {icon && <Fragment>{icon}&nbsp;{title}</Fragment>}
            {!icon && title}
          </p>
          {description && <p className='theme__description'>{description}</p>}
          {errorDescription && <p className='error__description'>{errorDescription}</p>}
        </div>
      )
      break
    default:
      content = (
        <Fragment>
          {icon && <Fragment>{icon}&nbsp;{title}</Fragment>}
          {!icon && title}
        </Fragment>
      )
      break
  }
  return (
    <div className={`wizard-step__header ${error ? 'wizard-step__header--error ' : statusClassName}`}>
      <div className='wizard-step__title'>
        {content}
      </div>
      {children}
    </div>
  )
}

WizardStepHeader.displayName = 'Wizard.Step.Header'

WizardStepHeader.propTypes = {
  /**
   * An optional icon to display
   */
  icon: PropTypes.node,

  /**
   * The step title
   */
  title: PropTypes.string.isRequired,

  /**
   * The components' children
   */
  children: PropTypes.node,

  /**
   * Type of theme displayed
   */
  theme: PropTypes.oneOf(['primary', 'secondary'])
}

WizardStepHeader.defaultProps = {
  icon: null,
  children: null
}

export default WizardStepHeader
