
const generateMessage = (transaction) => {
  return `Your Payment plan for Invoice ${transaction.quickbooks_doc_number} is created successfully.`
}

const transformInvoiceToNotificationData = (transaction, location) => {
  const url = '/retailer/finances'
  const notificationType = 'invoice-converted-to-pp'
  return {
    notification: {
      title: 'PAYG invoice has been converted to a PP',
      body: generateMessage(transaction)
    },
    data: {
      type: 'info',
      url,
      action: 'view',
      label: notificationType
    },
    // Unclear if these works, app uses data.url right now
    'webpush': {
      'fcm_options': {
        'link': url,
        'analytics_label': notificationType
      }
    },
    // SL Backend Props:
    locationId: location.fsid,
    event: `${transaction.txn_id}:${transaction.company_code}:converted-to-pp`
  }
}

module.exports = transformInvoiceToNotificationData
