import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { PayAsYouSell, PayOnDelivery } from '../../icons'
import { DIRECT_ORDER_TYPES, DIRECT_ORDER_TYPES_HUMANIZED, NOT_SUBSCRIBED } from '../../utils/subscription-type-constants'
const { PAY_ON_DELIVERY, PAY_AS_YOU_SELL } = DIRECT_ORDER_TYPES

const SubscriptionTypeLabel = ({
  subscriptionType,
  size
}) => (
  <span className={classnames(
    'vs-subscription-type-label',
    {'vs-subscription-type-label--on-demand': subscriptionType === PAY_ON_DELIVERY},
    {'vs-subscription-type-label--pays': subscriptionType === PAY_AS_YOU_SELL},
    {'vs-subscription-type-label--small': size === 'small'}
  )}>
    {subscriptionType === PAY_AS_YOU_SELL ? <PayAsYouSell /> : <PayOnDelivery />}
    <span
      className='vs-subscription-type-label__text'
    >
      {DIRECT_ORDER_TYPES_HUMANIZED[subscriptionType]}
    </span>
  </span>
)

SubscriptionTypeLabel.propTypes = {
  /**
   * One of two supported subscription types
   */
  subscriptionType: PropTypes.oneOf([PAY_AS_YOU_SELL, PAY_ON_DELIVERY, NOT_SUBSCRIBED]),
  /**
   * Size of the label
   */
  size: PropTypes.oneOf(['small', 'regular'])
}

SubscriptionTypeLabel.defaultProps = {
  subscriptionType: PAY_AS_YOU_SELL,
  size: 'regular'
}

export default SubscriptionTypeLabel
