import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Accordion, Button, DeemphasizedLink } from '@fielded/shared-ui'
import { Arrow, Checkmark, Chevron, Download } from '@fielded/shared-ui/src/icons'

import { toFriendableDate, isShelflifePowered } from '../common/utils'

const Header = ({
  programName,
  serviceName,
  location,
  period,
  updatedAt,
  submittedAt,
  submittedBy,
  onClickSave,
  backButtonLabel,
  contact,
  config,
  inventoryStatementUrl,
  salesStatementUrl
}) => {
  let lastSaveInfo
  if (updatedAt) {
    lastSaveInfo = `Auto saved at ${toFriendableDate(new Date(updatedAt))}`
  }
  let submittedAtInfo
  if (submittedAt && submittedBy) {
    submittedAtInfo = `Submitted ${toFriendableDate(new Date(submittedAt))} by ${submittedBy}`
  }
  const isServiceNameRedundant = programName === serviceName
  const isShelflife = isShelflifePowered(config)

  const [downloadsVisible, setDownloadsVisibility] = useState(false)

  return (
    <header className='header report-entry-header'>
      <div className='report-entry-header__actions'>
        <Button
          icon={<Arrow />}
          onClick={onClickSave}
        >
          {backButtonLabel}
        </Button>
        {!isShelflife && (
          <div className='report-entry-header__save-date'>
            {lastSaveInfo}
          </div>
        )}
      </div>
      <div className='report-entry-header__info'>
        {!isShelflife && (
          <h1 className='report-entry-header__heading'>
            {programName}
            {!isServiceNameRedundant &&
              <span> — {serviceName}</span>
            }
          </h1>
        )}
        <h2 className='report-entry-header__location'>{location}</h2>
        {submittedAtInfo && (
          <div className='report-entry-header__submitted'>
            <span className='icon-container__submitted'>
              <Checkmark />
            </span>
            {submittedAtInfo}
          </div>
        )}
        {(inventoryStatementUrl || salesStatementUrl) && (
          <Accordion>
            <Accordion.Section
              isExpanded={downloadsVisible}
            >
              <Accordion.Section.Header>
                <Button
                  colorVariant='dark'
                  size='small'
                  onClick={() => setDownloadsVisibility(!downloadsVisible)}
                  iconSide='right'
                  icon={<Chevron direction={downloadsVisible ? 'up' : 'down'} />}
                >
                  Download statements
                </Button>
              </Accordion.Section.Header>
              <Accordion.Section.Content>
                <div className='location-item__statements'>
                  { inventoryStatementUrl ? (
                    <div className='location-item__download-button'>
                      <Button size='small' component={Link} to={inventoryStatementUrl} icon={<Download />} fill='outline' colorVariant='brand'>Inventory Statement</Button>
                    </div>
                  ) : null }
                  { salesStatementUrl ? (
                    <div className='location-item__download-button'>
                      <Button size='small' component={Link} to={salesStatementUrl} icon={<Download />} fill='outline' colorVariant='brand'>Sales Statement</Button>
                    </div>
                  ) : null }
                </div>
              </Accordion.Section.Content>
            </Accordion.Section>
          </Accordion>
        )}
      </div>
      {!isShelflife && (
        <span className='report-entry-header__date'>Report for: {period}</span>
      )}
      {contact && (
        <DeemphasizedLink className='report-entry-header__contact' colorVariant='brand' href={`tel:${contact.phone}`}>
          Contact {contact.name}
        </DeemphasizedLink>
      )}

    </header>
  )
}

Header.propTypes = {
  programName: PropTypes.string.isRequired,
  serviceName: PropTypes.string,
  location: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  onClickSave: PropTypes.func,
  contact: PropTypes.object,
  backButtonLabel: PropTypes.string.isRequired
}

Header.defaultProps = {
  backButtonLabel: 'save and leave report'
}

export default Header
