const union = require('lodash/union')
const get = require('lodash/get')
const removeRev = require('./../../../utils/remove-rev')
const { getLocation } = require('./../../../tools')
const { construct, isPPMV } = require('./../../tools')
const getAllocation = require('./../../../allocation/api/get')
const { isValidJSONDate } = require('./../../../utils/validation')
const { isPPMV: isPPMVLocation } = require('../../../location/tools')
const { isDiscontinued } = require('./../../tools/utils')

const byId = list => list.reduce((index, item) => {
  if (item) {
    index[item._id] = item
  }
  return index
}, {})

const configurationsByIdWithoutPrefix = configurations => configurations.reduce((index, configuration) => {
  const id = configuration._id.startsWith('configuration') ? configuration._id.split('configuration:')[1] : configuration._id
  index[id] = configuration
  return index
}, {})

const maybeFilterSomeNonPPMVProducts = (products, locations, allocationsByFacilityId, configurationsById) => {
  // 1. Check if there are any PPMV locations
  let filteredProducts = products.filter(p => !isDiscontinued(p)) // filter for discontinued products.
  const nonPPMVLocations = locations.filter(location => !isPPMVLocation(location))

  if (nonPPMVLocations.length === locations.length) {
    return filteredProducts // This returns the filtered products if they are discontinued
  }

  // 2. Find which products shouldn't be filtered
  // because they're relevant for non PPMV locations
  const noFilterProductIds = nonPPMVLocations.reduce((products, location) => {
    const allocProductIds = get(allocationsByFacilityId, location._id, [])
    const configuration = configurationsById[location.configurations[0]]
    const configProductIds = get(configuration, products, [])
    return union(products, allocProductIds, configProductIds)
  }, [])

  // 3. Filter all non PPMV products unless they are in the "no filter" list.
  // All products not in the no filter list are products that are
  // only relevant for PPMV locations
  return filteredProducts
    .filter(
      product => noFilterProductIds.includes(product._id) ||
      isPPMV(product)
    )
}

exports.listForLocations = async function (state, locationIds, options = {}) {
  if (!(locationIds && locationIds.length)) {
    return Promise.reject(new Error('missing mandatory argument locations: it should contain a location id array'))
  }

  options = Object.assign({
    exclude: '',
    date: new Date().toJSON()
  }, options)

  // only accept JSON dates
  if (!isValidJSONDate(options.date)) {
    return Promise.reject(new Error('date format should be in toJSON format e.g new Date().toJSON()'))
  }

  // Get the locations for the configurations and the configurations for the products
  const locationsQueryRes = await state.locationsDB.allDocs({keys: locationIds, include_docs: true})
  const locations = locationsQueryRes.rows
    // Apply getLocation on docs that have been fetched, so they have the right shape
    .map(r => r.doc && getLocation(r.doc, options.date))
    .filter(r => r)

  // We filter service configuration products out, so that we use only allocation products to determine
  // what products are in a location e.g SL
  let configurations = []
  if (options.exclude !== 'configuration') {
    // `union` removes duplicates
    const configurationIds = union(
      locations.reduce((ids, l) => ids.concat(l.configurations), [])
    )
      .filter(configId => maybeFilterConfigByService(configId, options.serviceId))

    const configurationsQueryRes = await state.productsDB.allDocs({keys: configurationIds, include_docs: true})
    configurations = configurationsQueryRes.rows
      .filter(r => !r.error)
      .map(r => r.doc)
      .map(c => removeRev(c))
  }

  let allocationsByFacilityId = {}
  if (options.exclude !== 'allocation') {
    // check if there's allocation products for the locationIds
    // if no allocation doc for the location return empty allocation with products empty
    const defaultAllocation = facilityId => ({products: {}, facilityId})
    const allocationsP = locationIds.map(locationId => getAllocation(state, Object.assign({facilityId: locationId}, options))
      .then(resp => resp || defaultAllocation(locationId)))
    const allocationsQueryRes = await Promise.all(allocationsP)
    allocationsByFacilityId = allocationsQueryRes.reduce((byFacilityId, a) => {
      byFacilityId[a.facilityId] = Object.keys(a.products || a.aggregatedProducts)
      return byFacilityId
    }, {})
  }

  const configProductIds = configurations.reduce((ids, c) => ids.concat(c.products), [])
  const allocationProductIds = Object.keys(allocationsByFacilityId).reduce((ids, locationId) => {
    return ids.concat(allocationsByFacilityId[locationId])
  }, [])

  const allProductIds = union(configProductIds, allocationProductIds)

  if (options.idsOnly) {
    // Used to speed up getLedgerBalance
    return {
      products: allProductIds.reduce((sum, id) => {
        sum[id] = { _id: id }
        return sum
      }, {}),
      configurations: configurationsByIdWithoutPrefix(configurations),
      allocations: allocationsByFacilityId
    }
  }

  const productOpts = { now: options.date }
  const productsQueryRes = await state.productsDB.allDocs({keys: allProductIds, include_docs: true})
  let products = productsQueryRes.rows
    .reduce((result, row) => {
      if (!row.doc) {
        console.error(`Product document not available: ${row.key}`)
      } else {
        result.push(row.doc)
      }
      return result
    }, [])
    .map(p => removeRev(p))
    .map(p => construct(p, productOpts))

  products = maybeFilterSomeNonPPMVProducts(products, locations, allocationsByFacilityId, byId(configurations))

  return {
    products: byId(products),
    configurations: configurationsByIdWithoutPrefix(configurations),
    allocations: allocationsByFacilityId
  }
}

// Do not filter out VAN location configuration docs.
function maybeFilterConfigByService (configId, serviceId) {
  if (!serviceId || !configId.includes(':service:')) return true
  // config ids are just configuration:serviceId
  return (configId.replace('configuration:', '') === serviceId)
}
