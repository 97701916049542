const translateAllocation = require('../../allocation/tools/read/translate')

const { ChangesFeed, Checkpoint } = require('../../common/changes-feed')

const CHECKPOINT_ID = 'subscriptions'

async function resetAllocationsImport (db) {
  const checkpoint = new Checkpoint(db, CHECKPOINT_ID)
  return checkpoint.reset()
}

async function importAllocations (restApi, db) {
  const checkpoint = new Checkpoint(db, CHECKPOINT_ID)
  const since = await checkpoint.read()

  console.log('found checkpoint', since)

  const changesFeed = new ChangesFeed(db, { since, batchSize: 10, maxChanges: 10 })
  const allocationChanges = []
  let lastSeq = since
  try {
    const changes = await changesFeed.readAll()
    for (const change of changes) {
      lastSeq = change.seq
      if (change.id.startsWith('allocation:')) {
        allocationChanges.push(change)
      }
    }
  } catch (err) {
    console.error('Error reading changes feed')
    throw err
  }
  console.log('found', allocationChanges.length, 'allocations')
  console.log('changes feed has more changes', !changesFeed.hasNoMoreChanges)

  const subsByLocation = mapAllocationsToSubscriptions(allocationChanges)
  const hasChanges = Object.keys(subsByLocation).length > 0

  if (hasChanges) {
    console.log('writing subscriptions to rds')
    try {
      await restApi.add(subsByLocation)
    } catch (err) {
      console.error('Error writing subscriptions to rest endpoint', allocationChanges.map(c => c.id))
      throw err
    }
  }

  console.log('set checkpoint', lastSeq)
  try {
    await checkpoint.write(lastSeq)
  } catch (err) {
    console.error('Error setting checkpoint')
    throw err
  }

  return hasChanges
}

function parseProductCodeFromProductId (productId) {
  const rawCode = productId.split(':')[1]
  if (!rawCode) {
    return null
  }
  // remove commas and spaces from the product code
  return rawCode.replace(/[,\s]/g, '')
}

function mapAllocationsToSubscriptions (allocationChanges) {
  const allocationsByLocation = new Map()
  for (const {doc} of allocationChanges) {
    const a = translateAllocation(doc)
    const locationId = a.facilityId
    if (!locationId) {
      continue
    }
    const b = allocationsByLocation.get(locationId)
    if (!b || b.createdAt <= a.createdAt) {
      allocationsByLocation.set(locationId, a)
    }
  }

  let numSubscriptions = 0
  const byLocation = {}
  for (const a of allocationsByLocation.values()) {
    const locationId = a.facilityId
    const createdAt = a.createdAt
    const productIds = Object.keys(a.products || {})
    const subscriptions = []
    for (const productId of productIds) {
      const productCode = parseProductCodeFromProductId(productId)
      if (!productCode) {
        console.error('Failed to parse product code from', productId, a._id)
        continue
      }
      subscriptions.push({
        location_id: locationId,
        product_code: productCode
      })
      numSubscriptions++
    }
    byLocation[locationId] = {
      created_at: createdAt,
      subscriptions
    }
  }
  console.log('found', Object.keys(byLocation).length, 'locations')
  console.log('found', numSubscriptions, 'subscriptions')

  return byLocation
}

module.exports = {
  importAllocations,
  resetAllocationsImport
}
