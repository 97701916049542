module.exports = {
  title: 'route',
  name: 'route',
  description: 'A route',
  type: 'object',
  properties: {
    _id: {
      type: 'string'
    },
    type: {
      type: 'string',
      default: 'route'
    },
    name: {
      type: 'string'
    }
  },
  required: [
    '_id',
    'type',
    'name'
  ]
}
