const { listOrdersForUser } = require('./internal')
const get = require('lodash/get')

exports.listOpenOrders = listOpenOrders
async function listOpenOrders (
  state,
  location
) {
  const programId = get(location, 'programs[0].id')
  const locationId = location._id
  const { orders } = await listOrdersForUser(state, programId, locationId,
    { withAccepted: true, withSnapshots: true, isComplete: false, forceAtSupplier: true }, true)

  if (orders.length === 0) {
    return []
  }

  return orders.filter(order => !order.closedStatus)
}
