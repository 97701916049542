const PGAdapter = require('../../common/pg-adapter')
const PAYMENT_TABLE_NAME = '"avocado"."data_payment"'
const PAYMENT_TABLE_COLUMNS = [
  'location_id',
  'payment_provider',
  'payment_method',
  'currency',
  'amount_in_cents',
  'total_amount',
  'created_by',
  'email',
  'payment_data',
  'order_id',
  'created_at',
  'updated_at',
  'status',
  'error_code',
  'invoice_id'
]

class PaymentDbAdapter extends PGAdapter {
  constructor (
    pgConnection,
    username
  ) {
    super(pgConnection, PAYMENT_TABLE_NAME, username, PAYMENT_TABLE_COLUMNS)
    this.pgConnection = pgConnection
    this.pgAdapter = new PGAdapter(pgConnection, PAYMENT_TABLE_NAME, username, PAYMENT_TABLE_COLUMNS)
  }

  // we need process client_side_paid cuz it's intermidiate state to be able calculate immediately pending payments
  async getNotProcessedPayments () {
    const query = `SELECT * FROM ${PAYMENT_TABLE_NAME} WHERE "status" IS NULL or "status" = 'client_side_paid'`
    const { rows } = await this.pgConnection.query(query, [])
    return rows
  }

  async getOrderTransactions (orderId) {
    const query = `
      select qt.*
      from avocado.data_invoice_line l
      left join avocado.data_invoice i on l.invoice_id = i.id
      left join avocado.data_invoice_quickbooks ql on ql.invoice_id = i.id
      left join avocado.data_quickbookstransactions qt on qt.txn_id = ql.invoice_txn_id and qt.company_code = ql.company_code
      where l.order_id = $1
        and qt.id is not null
      limit 1
    `
    const { rows } = await this.pgConnection.query(query, [orderId])
    return rows[0]
  }
}

module.exports = PaymentDbAdapter
