import React, {Fragment, useEffect} from 'react'
import { Link } from 'react-router-dom'

import { ActionsBar, Button, Page } from '@fielded/shared-ui'
import { PlusCircle } from '@fielded/shared-ui/src/icons'
import { toast } from '@fielded/shared-ui/src/components/Page'

import { isReturnShipment } from '../../../common/utils/shipment'
import { getNavigationItem, getNavigationTitle } from '../../../common/utils/navigationInfo'

import { getSubNavigationItems } from './navigation-items'
import { hasFeature } from '../../../van-shared/utils/features'
import { filterShipments } from '../common/filters'
import { isShelflifePowered, isShipmentComplete } from '../common/utils'

import Shipment from './Shipment'
import DownloadShipmentsButton from '../../../van-shared/components/DownloadShipmentsButton/DownloadShipmentsButton'

const Shipments = ({
  shipments,
  newShipment,
  acknowledgeNewShipmentToast,
  funders,
  locationName,
  showCompleted,
  config,
  showImportLink,
  editPSMShipment,
  loadOnlineShipments,
  showRemoveShipmentLink,
  displayPlanningType,
  displayNewShipmentButton,
  user,
  api,
  activeFilters = {},
  onFilterChange,
  routesForFilter = [],
  sourcesForFilter = [],
  destinationsForFilter = [],
  deliveryDatesForFilter = [],
  marketsForFilter = [],
  history
}) => {
  const activeTab = showCompleted ? 'completed' : 'shipments'
  const canFilterShipments = hasFeature(config, 'features.shipments.allowFilterShipments')
  const navigationTitle = getNavigationTitle({ item: 'shipments', config }).toLowerCase()
  const pageTitle = `${locationName} ${navigationTitle}`

  const isShelflife = isShelflifePowered(config)
  const filteredShipments = filterShipments(shipments, activeFilters)

  const subNavigationItems = getSubNavigationItems({
    showImportLink: showImportLink,
    activeTab
  })

  subNavigationItems.forEach(item => {
    if (item.active) {
      item.title = `${item.title} (${filteredShipments.length})`
    }
  })
  const filterItems = {
    route: {
      name: 'Route',
      items: routesForFilter.map(route => ({
        label: route.replace('funder:', ''),
        value: route,
        active: activeFilters.route === route
      }))
    },
    source: {
      name: 'Source',
      items: sourcesForFilter.map(source => ({
        label: source.title,
        value: source.id,
        active: activeFilters.source === source.id
      }))
    },
    destination: {
      name: 'Destination',
      items: destinationsForFilter.map(destination => ({
        label: destination.title,
        value: destination.id,
        active: activeFilters.destination === destination.id
      }))
    },
    pack: {
      name: 'Packing',
      items: ['All', 'Full'].map(progress => ({
        label: progress,
        value: progress.toLowerCase(),
        active: activeFilters.pack === progress.toLowerCase()
      }))
    },
    deliveryDate: {
      name: 'Delivery Date',
      items: deliveryDatesForFilter.map(deliveryDate => ({
        label: deliveryDate,
        value: deliveryDate,
        active: activeFilters.deliveryDate === deliveryDate
      }))
    },
    market: {
      name: 'Market',
      items: marketsForFilter.map(market => ({
        label: market,
        value: market,
        active: activeFilters.market === market
      }))
    }
  }

  const onAcknowledgeNewShipmentToast = () => {
    if (newShipment) {
      toast({
        title: `Delivery created`,
        children: `A follow-up delivery for ${newShipment.destination.id} was created`,
        type: 'success'
      })
      acknowledgeNewShipmentToast()
    }
  }

  useEffect(() => {
    onAcknowledgeNewShipmentToast()
  }, [newShipment])

  return (
    <Page className='shipments'>
      <Page.ComposableHeader>
        <Page.ComposableHeader.Breadcrumb
          items={[getNavigationItem({ item: 'shipments', config, includePath: false })]}
        />
        <Page.ComposableHeader.Content>
          <Page.ComposableHeader.Title title={pageTitle} />
          <Page.ComposableHeader.SubNavigation
            label=''
            items={subNavigationItems}
          />
        </Page.ComposableHeader.Content>
        {canFilterShipments && <Page.ComposableHeader.Filters
          filters={filterItems}
          onFilterChange={onFilterChange}
          inactiveFiltersVisible={false}
        />}
      </Page.ComposableHeader>

      <Page.Panel narrow alignLeft>
        <ActionsBar>
          {isShelflife && (
            <DownloadShipmentsButton
              exportFunction={api.shipment.slShipmentListExport}
              locationId={user.location.id}
              shipmentsList={filteredShipments}
            />
          )}
        </ActionsBar>

        <Fragment>
          <section>
            {filteredShipments.map(shipment => (
              <Shipment
                user={user}
                key={shipment.snapshotId}
                shipment={shipment}
                funder={funders.find((f) => f._id === shipment.funderId)}
                config={config}
                editPSMShipment={editPSMShipment}
                showRemoveShipmentLink={showRemoveShipmentLink}
                displayPlanningType={displayPlanningType}
                isCompleted={isShipmentComplete(shipment, user)}
                isReturn={isReturnShipment(user, shipment)}
                history={history}
              />
            ))}
          </section>
        </Fragment>

        {loadOnlineShipments && (
          <div className='shipments__show-more'>
            <Button
              colorVariant='brand'
              fill='outline'
              size='small'
              onClick={loadOnlineShipments}
            >
              Show all completed shipments (online)
            </Button>
          </div>
        )}

        {displayNewShipmentButton && (
          <div className='shipments__fixed-button'>
            <Button
              component={Link}
              to='/shipments/new'
              colorVariant='brand'
              fill='outline'
              icon={<PlusCircle />}
              size='large'
            >
              New shipment
            </Button>
          </div>
        )}
      </Page.Panel>
    </Page>
  )
}

export default Shipments
