const { PouchAdapter } = require('../../common')
// shipment api is not one to one with couch docs, this schema
// is a dummy schema.
const schema = {
  title: 'shipment',
  name: 'shipment',
  description: 'A shipment',
  type: 'object',
  properties: {
    shipmentNo: {type: 'string'}
  }
}

class ShipmentPouchAdapter extends PouchAdapter {
  constructor (user, shipmentsDb, restAdapter) {
    super(user, schema, shipmentsDb)
    this.restAdapter = restAdapter
  }
}

module.exports = ShipmentPouchAdapter
