const { locationIdToProperties } = require('../../../tools')
const tools = require('../../tools')

const getLocationDoc = async (state, params, date, includeProgramsHistory = false) => {
  const filter = tools.getUserFilter(state.user)
  const isoDate = new Date(date).toJSON()
  const doc = await state.dal.location.getRawDoc(state, params)
  const allConfigurations = await state.dal.configuration.listAll(state)

  if (!doc) {
    return
  }
  return tools.docToEntity({doc, date: isoDate, filter, allConfigurations, includeProgramsHistory})
}

exports.get = getLocation
function getLocation (state, locationId, date, includeProgramsHistory = false) {
  return getLocationDoc(state, {locationId}, date, includeProgramsHistory)
}

exports.getByWarehouseCode = getByWarehouseCode
async function getByWarehouseCode (state, warehouseCode, date) {
  return getLocationDoc(state, {warehouseCode}, date)
}

// Old PSM hack: no state locations exist to display, fake name
exports.getOrFakeName = getOrFakeName
async function getOrFakeName (state, locationId, date) {
  const location = await getLocation(state, locationId, date)
  if (location) return location

  const locationProperties = locationIdToProperties(locationId)
  return {fullName: locationProperties[locationProperties.level]}
}
