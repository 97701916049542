import React from 'react'
import get from 'lodash/get'

import { Info } from '@fielded/shared-ui/src/icons'

export const getNavigationPath = ({item, config}) => get(config, `navigationItems.${item}.path`)
export const getNavigationTitle = ({item, config}) => get(config, `navigationItems.${item}.title`, item)
export const getNavigationIcon = ({item, config}) => get(config, `navigationItems.${item}.icon`, <Info />)

// TODO: this might not be strictly tied to navigation, consider another place for this
export const getNavigationEntityName = ({item, config}) => get(config, `navigationItems.${item}.entityName`)

export const getNavigationItem = ({
  item,
  config,
  // In the root of each sub-app, the breadcrumb item should not be a link,
  // so we have an option to not include the path in the nav item
  includePath = true
}) => ({
  [includePath && 'path']: getNavigationPath({item, config}),
  title: getNavigationTitle({item, config}),
  icon: getNavigationIcon({item, config})
})
