const get = require('lodash/get')

exports.isDiscontinued = isDiscontinued
function isDiscontinued (product = {}) {
  const additionalData = get(product, 'additionalData', {})
  // From ticket:
  // vis_1 is the main set (FCT)
  // vis_2 is the second (NAS)
  // but if a product is discontinued in one place it will be discontinued in the other,
  // so we trust vis 1.
  // In case visibility_1 is explicitly null, we have to also fall back to '' again.
  const visibility = get(additionalData, 'visibility_1', '') || ''
  return visibility.trim().toLowerCase() === 'discontinued'
}

exports.isPPMV = isPPMV
function isPPMV (product) {
  return get(product, 'additionalData.ppmv')
}

exports.isCore = product => get(product, 'additionalData.phase') === 1

exports.isPPMVCore = product =>
  isPPMV(product) && get(product, 'additionalData.ppmv_core')
