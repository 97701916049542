import React from 'react'
import ProgressButton from '../common/ProgressButton'

const ProductsSelectedButton = ({ selectedProducts, onClickNext, submitLabelText }) => (
  <ProgressButton
    progressText={`${selectedProducts} ${selectedProducts === 1 ? 'product' : 'products'}`}
    progressSupportingText='selected'
    isComplete={selectedProducts > 0}
    onClick={onClickNext}
    disabled={!selectedProducts}
    submitLabelText={submitLabelText || 'Next'}
  />
)

export default ProductsSelectedButton
