import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import withPageContext from '../withPageContext'
import ActionsBar from '../../ActionsBar'

const PageFooter = ({
  children,
  fixed,
  situation,
  layout,
  className,
  withBottomNav,
  ...otherProps
}) => {
  /**
   * If Form.Actions, which also is an ActionsBar wrapper, is nested in here
   * avoid double wrapping of the ActionsBar.
   */
  let hasNestedFooter = false

  React.Children.forEach(children, (child) => {
    if (!child) return null
    if (child.type && child.type.displayName === 'Form.Actions') {
      hasNestedFooter = true
    }
  })

  return (
    <footer
      className={classnames(
        'vs-page-footer',
        {'vs-page-footer--fixed': fixed},
        {'vs-page-footer--fixed-with-bottom-nav': withBottomNav},
        {'vs-page-footer--layout-centered': layout === 'centered'},
        {'vs-page-footer--situation': situation},
        className
      )}
    >
      {hasNestedFooter ? children : (
        <ActionsBar layout={layout} {...otherProps}>
          { children }
        </ActionsBar>
      )}
    </footer>
  )
}

PageFooter.propTypes = {
  /*
   * The content of the footer
   */
  children: PropTypes.node.isRequired,

  /**
   * Should the footer be fixed on the page bottom?
   */
  fixed: PropTypes.bool,

  /**
   * We only use this as a modifier prop. it styles (shell styles) the
   * footer element differently if fixed (prop) is true and the bottom nav is visible
   */
  withBottomNav: PropTypes.bool,

  /*
  * The classname(s) to apply to the Page
  */
  className: PropTypes.string,

  /**
   * This will be passed down by the wrapping Page component,
   * so you don't have to set here again.
   */
  situation: PropTypes.oneOf(['ready', 'success', 'caution', 'danger', 'neutral', undefined])
}

PageFooter.defaultProps = {
  fixed: false,
  withBottomNav: true,
  className: undefined
}

PageFooter.displayName = 'Page.Footer'

export default withPageContext(PageFooter)

// This is to be consumed by the storybook only, otherwise the props are not showing in the stories
export const PageFooterNoHOC = PageFooter
