const mergeReports = require('../tools/merge-reports')
const getReport = require('./get')
const { get: getService } = require('../../service/api/read/get')
const docToReport = require('./doc-to-report')

const findWithAdjustments = async (state, { reportId, keepInitialCount } = {}) => {
  const report = await getReport(state, { reportId: reportId })

  if (!report.partialCount && keepInitialCount) {
    return report
  }

  // Lets get those props we need from the first report:
  const { serviceId, location: { id: locationId }, date: { reportingPeriod: period } } = report
  const service = await getService(state, serviceId)

  // Sorry for skipping findReport but it's just too backwards
  // i have to convert the period id to a date and let findReport convert it back
  const keys = [[period, locationId]]
  const keysResult = await state.dal.report.readView(state, keys, { localOnly: false, includeDocs: true })
  const docs = keysResult.rows.map(r => r.doc)
    .filter(x => x)
    .filter(doc => doc.serviceId === serviceId)

  const reports = await Promise.all(docs.map(doc => docToReport(state, { doc, service, entityOptions: { addFields: true } })))
  const merged = mergeReports(reports)

  return merged
}

module.exports = findWithAdjustments
