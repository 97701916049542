import PropTypes from 'prop-types'

// Mirror report statuses without included tools packages from here:
// https://github.com/fielded/fs-api/blob/master/lib/tools/utils/progress-statuses.js
export const STATUS_NOT_STARTED = 'notStarted'
export const STATUS_IN_PROGRESS = 'inProgress'
export const STATUS_COMPLETE = 'complete'
export const STATUS_OFFLINE = 'offline'

export const statusKeys = {
  'STATUS_NOT_STARTED': STATUS_NOT_STARTED,
  'STATUS_IN_PROGRESS': STATUS_IN_PROGRESS,
  'STATUS_COMPLETE': STATUS_COMPLETE,
  'STATUS_OFFLINE': STATUS_OFFLINE
}

export const reportStatusPropType = PropTypes.oneOf(
  [STATUS_NOT_STARTED, STATUS_IN_PROGRESS, STATUS_COMPLETE, STATUS_OFFLINE]
)
