function log (eventName, data) {
  if (window.gtag) {
    window.gtag('event', eventName, data)
  }
}

function logNotification (location) {
  try {
    const url = new URL(location)
    // Service Worker appends this on all push notifications so we
    // can track when they are opened
    if (url.searchParams.get('notification_background')) {
      const label = url.searchParams.get('notification_background')
      const messageId = url.searchParams.get('notification_id')
      log('notification_background_open', {
        message_name: label,
        message_id: messageId,
        label: label
      })
    }
  } catch (e) {
    // Just catch this so it does not break anything
  }
}

export { log, logNotification }
