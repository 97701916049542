const tools = require('../../tools')

// used for the REST API POST /orders
exports.updateOrders = async function (
  state,
  {updatedOrders, dryRun = false, date = new Date().toJSON(), locationId}
) {
  const updatedOrdersWithIds = updatedOrders.map(doc => Object.assign({}, doc, {
    _id: doc._id || tools.createOrderId({...doc, locationId})
  }))

  const currentDocs = await state.dal.order.listById(
    state, updatedOrdersWithIds.map(d => d._id)
  )
  const user = state.user
  const updatedDocs = tools.updateOrders(
    {currentDocs, updatedOrders: updatedOrdersWithIds, date, user}
  )

  if (dryRun) {
    return updatedDocs
  }

  await state.dal.order.bulkDocs(state, updatedDocs)
  return updatedOrders
}
