import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Badge = ({
  children,
  className
}) => (
  <div className={classnames(
    'vs-badge',
    className
  )}>
    {children}
  </div>
)

Badge.propTypes = {
  /**
   * Displayed text within the Badge
   */
  children: PropTypes.string,
  /**
   * Custom classname for styling component container
   */
  classnames: PropTypes.string

}

export default Badge
