const DbAdapter = require('./db-adapter')

class PaymentAdapter {
  constructor (
    state,
    {
      agaveAdapter,
      pgConnection,
      quickbooksApi,
      invoicesApi,
      logger
    }
  ) {
    const { user = {} } = state
    this.user = user
    this.agaveAdapter = agaveAdapter
    this.quickbooksApi = quickbooksApi
    this.invoicesApi = invoicesApi
    this.logger = logger
    const username = user.name
    if (pgConnection) {
      this.pgConnection = pgConnection
      this.dbAdapter = new DbAdapter(this.pgConnection, username)
    }
  }

  getOrderTransaction (orderId) {
    if (this.dbAdapter) {
      return this.dbAdapter.getOrderTransactions(orderId)
    }
    return this.agaveAdapter.get('finances/payment/get_order_transaction', { orderId })
  }

  capture (data) {
    return this.agaveAdapter.create('finances/payment', data)
  }

  payWithCredits (data) {
    return this.agaveAdapter.create('finances/payment/pay_with_credits', data)
  }

  savePayment (data) {
    if (!this.dbAdapter) {
      throw new Error('Action only allows thru backend')
    }
    return this.dbAdapter.create(data)
  }

  updatePayment (data) {
    if (!this.dbAdapter) {
      throw new Error('Action only allows thru backend')
    }
    return this.dbAdapter.update(data)
  }

  async createInvoice ({
    quickbooksLocation,
    orderData,
    payment,
    customVatLineCountries
  } = {}) {
    const { quickbooksInterface } = this.quickbooksApi

    const pgQboInvoices = await this.invoicesApi.pg.getQboInvoices({
      orderId: payment.order_id,
      locationId: quickbooksLocation.location_id
    })
    let pgInvoiceId
    let qboInvoice
    const paymentArr = []
    const invoiceArr = []

    if (pgQboInvoices.length > 0) {
      const pgQboInvoice = pgQboInvoices[0]
      const { Invoice } = await quickbooksInterface.get(
        quickbooksLocation.company_code,
        `invoice/${pgQboInvoice.qboInvoiceId}?minorversion=${quickbooksInterface.apiVersion}`
      )
      pgInvoiceId = pgQboInvoice.pgInvoiceId
      qboInvoice = Invoice
    } else {
      const invoiceDate = new Date().toJSON()
      const pgInvoiceId = await this.invoicesApi.pg.insertInvoice({
        invoice: {
          locationId: quickbooksLocation.location_id
        },
        lines: Object.keys(orderData.products)
          .map(productId => {
            const product = orderData.products[productId]
            const sku = productId.split(':')[1]
            return {
              orderInvoicingType: 'prepaid',
              eventType: 'delivery',
              sku,
              date: invoiceDate,
              quantity: product.adjusted,
              bonus: 0,
              orderId: payment.order_id,
              reconciled: false
            }
          }).filter(p => p)
      })

      const result = await this.invoicesApi.auto.pgInvoiceToQuickbooks(
        pgInvoiceId,
        { customVatLineCountries }
      )
      qboInvoice = result.qboInvoice
    }

    try {
      const {
        payment: paymentResponse
      } = await this.markInvoiceAsPaid({
        quickbooksLocation,
        qboInvoice,
        privateNote: payment.payment_data.reference
      })
      paymentArr.push(paymentResponse)
      // if payment has been paid normally we need fetch invoice again from qbo with updated data cuz payment marking invoice as it's paid
      const { Invoice: updatedInvoice } = await quickbooksInterface.get(
        quickbooksLocation.company_code,
        `invoice/${qboInvoice.Id}?minorversion=${quickbooksInterface.apiVersion}`
      )
      invoiceArr.push(updatedInvoice)
    } catch (error) {
      this.logger.warn({ code: 'failed-mark-invoice-as-paid', qboInvoice, error })
      // even if payment creation is failed we still need save invoice quickbooks transform method will upsert data even if invoice exists
      invoiceArr.push(qboInvoice)
    }
    await this.quickbooksApi.transform.transformAndSave(
      quickbooksLocation.company_code,
      {
        Invoice: invoiceArr,
        Payment: paymentArr
      }
    )
    await this.updatePayment({
      invoice_id: pgInvoiceId,
      status: 'success',
      error_code: null,
      id: payment.id
    })

    return invoiceArr[0]
  }

  async markInvoiceAsPaid ({
    quickbooksLocation,
    qboInvoice,
    privateNote
  }) {
    const { quickbooksInterface } = this.quickbooksApi

    const {
      paystackDepositAccountId
    } = quickbooksInterface.accountIds[quickbooksLocation.company_code]

    // get quickbooks customer
    const { Customer: qboCustomer } = await quickbooksInterface.get(
      quickbooksLocation.company_code,
      `/customer/${quickbooksLocation.quickbooks_customer_id}?minorversion=${quickbooksInterface.apiVersion}`
    )

    const { Account: depositAccount } = await quickbooksInterface.get(
      quickbooksLocation.company_code,
      `/account/${paystackDepositAccountId}?minorversion=${quickbooksInterface.apiVersion}`
    )

    // create payment
    const paymentData = {
      TotalAmt: qboInvoice.Balance,
      CustomerRef: {
        value: qboCustomer.Id,
        name: qboCustomer.DisplayName
      }
    }
    const { Payment } = await quickbooksInterface.post(
      quickbooksLocation.company_code,
      `payment?minorversion=${quickbooksInterface.apiVersion}`,
      paymentData
    )

    // update payment and link Deposit to Payment with totalAmount
    const transformedData = {
      Id: Payment.Id,
      TotalAmt: Payment.TotalAmt,
      CurrencyRef: Payment.CurrencyRef,
      SyncToken: Payment.SyncToken,
      CustomerRef: Payment.CustomerRef,
      Line: [{
        Amount: qboInvoice.Balance,
        LinkedTxn: [{
          TxnId: qboInvoice.Id,
          TxnType: 'Invoice'
        }]
      }],
      DepositToAccountRef: {
        value: depositAccount.Id,
        name: depositAccount.Name
      },
      PrivateNote: privateNote
    }

    const { Payment: finalPayment } = await quickbooksInterface.post(
      quickbooksLocation.company_code,
      `payment?minorversion=${quickbooksInterface.apiVersion}`,
      transformedData
    )

    return {
      payment: finalPayment
    }
  }
}

module.exports = PaymentAdapter
