const normalizeAllocation = require('../tools/read/normalize')
const translateAllocation = require('../tools/read/translate')
const { takeLatest } = require('../tools/read/generic-utils')
const { addCalculations } = require('../tools/read/calculate')
const { listAll: getAllProducts } = require('./../../product/api/read/list-all')

const addProductData = (state, allocation) => {
  const addData = (allocationProducts, productsById) => {
    if (!allocationProducts) {
      return
    }
    Object.keys(allocationProducts).forEach(key => {
      allocationProducts[key].product = productsById[key]
    })
  }
  return getAllProducts(state)
    .then(products => {
      const productsById = products.reduce((acc, p) => {
        acc[p._id] = p
        return acc
      }, {})
      addData(allocation.products, productsById)
      addData(allocation.aggregatedProducts, productsById)
      return allocation
    })
}

const getAllocation = async (
  state,
  options
) => {
  if (!(options && options.facilityId)) {
    throw new Error('Option facilityId is required')
  }
  options = Object.assign({
    includeProducts: false,
    includeDemandPlan: false,
    date: new Date().toISOString(),
    raw: false
  }, options)

  const date = options.date.split('T')[0]
  const facilityId = options.facilityId

  const result = await state.allocationsDB.allDocs({
    // Use the id sort descending to only get the latest allocation doc
    startkey: `allocation:${facilityId}:date:${date}{}`,
    endkey: `allocation:${facilityId}:date`,
    descending: true,
    include_docs: true,
    limit: 1
  })

  const docs = result.rows.map(r => translateAllocation(r.doc))
  if (!docs.length) {
    return
  }

  const doc = takeLatest(docs)[0]
  const rawAllocation = doc
  const allocation = options.raw ? rawAllocation : normalizeAllocation(doc)

  if (options.includeDemandPlan) {
    addCalculations(allocation)
  }

  if (options.includeProducts) {
    return addProductData(state, allocation)
  }

  return allocation
}

module.exports = getAllocation
