/** This module contains common methods for allocation data
 */

const { areAllocationsEqual } = require('./equals')
const { groupByFacility, sortDateDescending } = require('./tools/read/generic-utils')

/** Create an allocation struct
 *
 * Always use this instead of creating an allocation struct manually.
 */
const createAllocation = ({ facilityId, date, products, unsubscriptions, aggregatedProducts, createdBy }) => {
  if (!facilityId) {
    throw new Error('facility id is required')
  }
  if (!date) {
    throw new Error('date is required')
  }

  const effectiveDate = new Date(date).toISOString().split('T')[0]
  const createdAt = new Date().toISOString()
  return {
    _id: `allocation:${facilityId}:date:${effectiveDate}:createdAt:${createdAt}`,
    facilityId,
    date: effectiveDate,
    createdAt,
    createdBy,
    type: 'allocation',
    version: '1.0.0',
    products,
    unsubscriptions,
    aggregatedProducts
  }
}

/** Group allocations by date
 */
const groupByDate = (allocations) => {
  return allocations.reduce((groups, a) => Object.assign({}, groups, {
    [a.date]: (groups[a.date] || []).concat(a)
  }), {})
}

/** Reduce two lists of allocations on location level
 *
 * This function assumes there is at most one allocation for a particular
 * location in each list. This will return a list that has one allocation
 * for each location in either lists, while prefering allocations from
 * the second list.
 *
 * When mergeProducts is set and we find allocations for the same location
 * in both lists, the products of the allocations will be merged prefering
 * product data from the second list.
 *
 * keepUnsubscriptions ensures that stored unsubscriptions are not
 * going to be deleted. There is no support for merging unsubscriptions
 * as it is not needed for now (i.e. imported products do not provide with
 * unsubscriptions data)
 */
const combine = (xs, ys, mergeProducts = false, keepUnsubscriptions = true) => {
  const combined = xs.reduce((map, x) => map.set(x.facilityId, x), new Map())
  for (const y of ys) {
    const id = y.facilityId
    const x = combined.get(id)
    let resultAllocation = y
    if (x) {
      if (mergeProducts) {
        resultAllocation = mergeAllocations(x, y)
      }
      if (keepUnsubscriptions && x.unsubscriptions) {
        resultAllocation.unsubscriptions = x.unsubscriptions
      }
    }
    combined.set(id, resultAllocation)
  }
  return [...combined.values()]
}

// used by `combine` above
const mergeAllocations = (x, y) => {
  const allocation = Object.assign({}, y)
  if (x.products || y.products) {
    allocation.products = mergeProducts(x.products, y.products)
  }
  if (x.aggregatedProducts || y.aggregatedProducts) {
    allocation.aggregatedProducts = mergeProducts(x.aggregatedProducts, y.aggregatedProducts)
  }
  return allocation
}

// used by `combine` above
const mergeProducts = (p, q) => {
  p = p || {}
  q = q || {}
  const products = {}
  const ids = new Set([...Object.keys(p), ...Object.keys(q)])
  for (const id of ids) {
    const a = p[id] || {}
    const b = q[id] || {}
    products[id] = {}
    if (a.supplyPlan || b.supplyPlan) {
      products[id].supplyPlan = Object.assign({}, a.supplyPlan, b.supplyPlan)
    }
    if (a.forecast || b.forecast) {
      products[id].forecast = Object.assign({}, a.forecast, b.forecast)
    }
  }
  return products
}

/** Remove any allocation where an equal allocation already exists
 *
 * This takes two lists of allocations and will remove a list that has all
 * allocation that are in the first list, but where no equal allocation is found
 * in the second list. Equal here means, that the allocations products data,
 * like forecasts and supply plans, are equal.
 */
const removeExisting = (all, existing) => {
  const existingGrouped = groupByFacility(existing)
  return all.filter(a => {
    const b = existingGrouped[a.facilityId] && existingGrouped[a.facilityId][0]
    if (!b) {
      return true
    }
    const equal = areAllocationsEqual(a, b)
    return !equal
  })
}

/** Reduce a list of docs with `facilityId` and a date field
 * to a doc per facility for the provided `date`.
 *
 * `docs` can contain multiple docs for a single facility,
 * with different dates.
 *
 * This function will return a filtered doc list, which contains a single
 * doc per facility. The single doc is picked by looking at
 * the date and picking the doc that was active at that date.
 */
const filterByDateMoreRecent = (docs, date, dateField = 'date') => {
  const byFacilityId = groupByFacility(docs)
  return Object.keys(byFacilityId)
    .reduce((acc, facilityId) => {
      return acc.concat([
        sortDateDescending(byFacilityId[facilityId], dateField)
          .find(a => date >= a[dateField])
      ])
    }, [])
    .filter(a => !!a)
}

module.exports = {
  createAllocation,
  combine,
  removeExisting,
  groupByDate,
  filterByDateMoreRecent
}
