const uuid = require('uuid/v4')
const vectorClock = require('vectorclock')

module.exports = (options) => {
  // The alert id and the alert document id are not the same. Here we create
  // the predictable alert id and append a uuid to get the doc id.
  return {
    _id: options.alertId + ':' + uuid(),
    createdAt: new Date().toISOString(),
    createdBy: options.userId,
    deviceId: options.deviceId,
    alertId: options.alertId,
    clock: vectorClock.increment(options.clock || {}, options.deviceId),
    version: options.version
  }
}
