const get = require('lodash/get')
const keyBy = require('lodash/keyBy')
const {getVisitDisplayDay} = require('../../routes/tools')
const { getProperRouteIdentifier } = require('../../order/tools/read/utils')

exports.decorateAdminLocations = decorateAdminLocations
function decorateAdminLocations (locations, routes, services, programId) {
  const routesById = keyBy(routes, '_id')
  const servicesById = keyBy(services, 'id')

  return locations.map(location => decorateLocation(location, routesById, servicesById, programId))
}

function decorateLocation (location, routesById, servicesById, programId) {
  const email = get(location, `contacts.${programId}.email`)
  const mobilizedDate = get(location, `additionalData.mobilizedDate`)
  const level = get(location, `additionalData.level`)
  const active = get(location, `additionalData.active`, false)
  const ordersStatus = get(location, `additionalData.ordersStatus`)

  const row = {
    id: location._id,
    name: location.name,
    email,
    level,
    active,
    ordersStatus,
    mobilizedDate: mobilizedDate ? mobilizedDate.split('T')[0] : ''
  }

  const {routeName, enabledService, visitDay, supplyWeeks} = getEnabledRouteDetails(location, routesById)

  return Object.assign(
    row,
    {
      routeName,
      visitDay,
      supplyWeeks,
      marketName: enabledService ? get(servicesById[enabledService.id], 'name') : '',
      mobilizedDate
    }
  )
}

exports.getEnabledRouteDetails = getEnabledRouteDetails
function getEnabledRouteDetails (location, routesById) {
  // SL Hack for a locations currently assigned route, service
  const enabledService = get(location, 'programs[0].services[0]')
  if (!enabledService) return {}

  const routeId = getProperRouteIdentifier(enabledService)
  const route = routesById[routeId]
  if (!route) return {}

  const routeName = get(route, 'name')
  let supplyWeeks
  let visitDay
  if (route && route.day !== null && route.day !== undefined) {
    supplyWeeks = route.deliveryFrequencyWeeks
    visitDay = getVisitDisplayDay(route.day)
  }
  return {routeName, enabledService, visitDay, supplyWeeks}
}
