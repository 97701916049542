const is = (type) => (input) => {
  return Object.prototype.toString.call(input) === `[object ${type}]`
}

// TODO: this could be misleading as it returns Number for NaN which means 'not a number'
const isNumber = is('Number')

const isString = is('String')

module.exports = {isNumber, isString}
