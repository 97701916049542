module.exports = wrap
module.exports.wrapEntityApi = wrapEntityApi

// wrap takes an object and walks all nodes while wrapping all functions
// to ensure the following:
// 1. the device ID used by the shipment API has been set up before calling the method
// 2. the `state` argument is prepended to each call
// outObj can be used to pass in an object which api methods get assigned to
function wrap (obj, state, setupFunc, skiplist, outObj = null) {
  const api = Object.keys(obj).reduce((acc, keyName) => {
    // these are excpetions where the methods are synchronous and won't be
    // wrapped
    if (Array.isArray(skiplist) && skiplist.indexOf(keyName) !== -1) {
      acc[keyName] = obj[keyName]
    } else if (typeof obj[keyName] === 'function') {
      acc[keyName] = (...args) => {
        if (!state.setup) {
          state.setup = setupFunc(state)
        }

        if (outObj) {
          return state.setup.then(() => obj[keyName].call(outObj, state, ...args))
        }

        return state.setup.then(() => obj[keyName](state, ...args))
      }
    } else if (typeof obj[keyName] === 'object') {
      acc[keyName] = wrap(obj[keyName], state, setupFunc, skiplist)
    }
    return acc
  }, {})
  if (outObj) {
    return Object.assign(outObj, api)
  }
  return api
}

function wrapEntityApi (obj, state) {
  return wrap(obj, state, () => Promise.resolve({}), ['tools'])
}
