const get = require('lodash/get')

const { Roles } = require('./user-db-sync')

const DB_PER_USER_PREFIX = 'user-database-'
exports.DB_PER_USER_PREFIX = DB_PER_USER_PREFIX
const RETAIL_STOCK_PERMISSION = 'retail-stock-shipments'
exports.RETAIL_STOCK_PERMISSION = RETAIL_STOCK_PERMISSION

exports.getDBPerUserDatabaseName = getDBPerUserDatabaseName
function getDBPerUserDatabaseName (user = {}) {
  if (!user._id && !user.name) {
    throw new Error('`_id` or `name` property is required on user, cannot continue')
  }
  const userName = encodePerUserDatabaseName(
    user.name || user._id.replace(/^org\.couchdb\.user:/, '')
  )
  return `${DB_PER_USER_PREFIX}${userName}`
}

const bannedRoles = ['shelflife-user', 'psm-user', '_admin']

exports.preparePharmacyUser = preparePharmacyUser
function preparePharmacyUser (user, dbPerUserRole = Roles.UserDBSyncUser) {
  const extraRoles = (user.featureRoles || [])
    .filter(role => !bannedRoles.includes(role))
    .map(role => `feature:${role}`)

  const roles = [
    'userRole:pharmacyUser',
    `feature:${dbPerUserRole}`,
    `feature:location:${user.locationId || user.location.id}`,
    `feature:${DB_PER_USER_PREFIX}${encodePerUserDatabaseName(user.name)}`,
    ...extraRoles
  ]

  if (user._id) {
    return {
      ...user,
      roles,
      featureRoles: undefined,
      programs: ['program:shelflife']
    }
  }

  if (!user.name || !user.password || !user.locationId) {
    throw new Error(`Required properties name, password, and locationId`)
  }

  const fullName = get(user.profile, 'fullName', user.name)
  const email = get(user.profile, 'email', '')
  return {
    name: user.name,
    location: {id: user.locationId},
    profile: {
      fullName,
      email: email
    },
    funders: [],
    password: user.password,
    programs: ['program:shelflife'],
    roles
  }
}

exports.deriveLocationId = deriveLocationId
function deriveLocationId (user = {}) {
  if (!Array.isArray(user.roles)) {
    throw new Error('`roles` property is required on user as an Array, cannot continue')
  }
  const [locationRole = null] = user.roles.filter(r => r.indexOf('location:') === 0)
  if (!locationRole) {
    throw new Error('No location role found on user.')
  }
  return locationRole.replace(/^location:/, '')
}

exports.deriveReplicationId = deriveReplicationId
function deriveReplicationId (userId, params) {
  let type = ''
  if (params) {
    type = `from:${params.from}:to:${params.to}`
  }

  if (!userId) throw new Error('`_id` property is required on user, cannot continue')

  return `replication:per-user-db:${userId.replace(/^org\.couchdb\.user:/, '')}:${type}`
}

// encode given name and covert to couchdb friendly string
// if string match to existing username pattern then it will return that string
exports.encodePerUserDatabaseName = encodePerUserDatabaseName
function encodePerUserDatabaseName (str) {
  return str.split('')
    .map(t => {
      if (/[a-z0-9-]/.test(t)) {
        return t
      }
      const charCode = t.charCodeAt(0).toString().padStart(5, '0')
      return '$' + charCode
    })
    .join('')
}

exports.isRetailUser = isRetailUser
function isRetailUser ({ roles = [] }) {
  return roles.includes('userRole:pharmacyUser')
}

exports.isNonRetailUser = isNonRetailUser
function isNonRetailUser ({ roles = [] }) {
  return roles.includes('shelflife-user') || roles.includes('_admin')
}

exports.isNoDatabaseUser = isNoDatabaseUser
function isNoDatabaseUser ({ roles = [] }) {
  return roles.includes('feature:no-database-user')
}

const PHARMACY_USER_FEATURE_ROLES = [
  {
    role: RETAIL_STOCK_PERMISSION,
    displayInFS: true,
    displayName: 'Shipments/Stock Counts',
    description: 'User can view shipments, stock counts and ledger via the retail UI'
  },
  {
    role: 'settings:facilities:products:manageSubscriptions',
    displayInFS: true,
    displayName: 'Subscription Management',
    description: 'User can manage their product subscription'
  },
  {
    role: 'view-finances',
    displayInFS: true,
    displayName: 'Finances',
    description: 'User can view any existing invoices or payment plans'
  },
  {
    role: 'topup-requests',
    displayInFS: true,
    displayName: 'Retailer Topup Request',
    description: 'User can request topups'
  }
]
exports.PHARMACY_USER_FEATURE_ROLES = PHARMACY_USER_FEATURE_ROLES

// validateUserLocations takes a user context and a user object and checks
// if the user context is allowed to access information (or edit) the given
// user object based on the location information. In case the validation fails,
// an error with the given status code is returned.
function validateUserLocations (userCtx, user) {
  if (userCtx.location && userCtx.location.id !== 'national') {
    if (user.location.id.indexOf(userCtx.location.id) !== 0) {
      const err = new Error(
        `User with location of "${userCtx.location.id}" is not allowed to access location "${user.location.id}"`
      )
      err.status = 403
      return err
    }
  }
  return null
}
exports.validateUserLocations = validateUserLocations
