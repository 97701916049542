exports.listAll = listAll
function listAll (state) {
  return query(state, {
    startkey: `configuration:`,
    endkey: `configuration:\ufff0`,
    include_docs: true
  })
}

exports.list = list
function list (state, ids) {
  return query(state, {
    keys: ids,
    include_docs: true
  })
}

function query (state, params) {
  return state.productsDB.allDocs(params)
    .then(response => {
      return response.rows
        .filter(row => row.doc)
        .map(row => row.doc)
    })
}
