const tools = require('../../tools')
const { getOrder } = require('../read/get')

exports.updateOrder = async function (
  state,
  {productQuantities, orderId}
) {
  tools.validateParams('updateOrder', arguments[1])

  const status = tools.getUserStatus(state.user)

  const order = await getOrder(state, {orderId, withSnapshots: true})
  const snapshots = tools.updateOrder({
    productQuantities,
    order,
    status,
    user: state.user
  })
  await state.dal.order.bulkDocs(state, snapshots)
  const updatedOrder = await getOrder(state, {orderId})
  return updatedOrder
}
