import get from 'lodash/get'
import {
  BATCHES_LIST_UPDATED
} from '../batches/batches-reducer'

import {
  batchCanBeChecked
} from '../batches/utils'

import {
  isShipmentComplete,
  isPreAdvice,
  batchIdToProductId
} from '../common/utils'
import {
  hasBatchesOrQuantityForProduct
} from './utils'

export const LOAD = 'pick-list/LOAD'

export const totalsByProduct = (batches, ignoreUnchecked) => {
  const add = (sum, next) => {
    if (ignoreUnchecked) {
      return next.checked ? sum + next.quantity : sum
    }
    return sum + next.quantity
  }
  return Object.keys(batches).reduce((index, batchId) => {
    const productId = batchIdToProductId(batchId)
    index[productId] = index[productId] || 0
    index[productId] = add(index[productId], batches[batchId])

    return index
  }, {})
}

const readAdjustmentMeta = (batches, productId) => {
  return Object.keys(batches)
    .filter(batchId => {
      return batchIdToProductId(batchId) === productId
    })
    .reduce((adjustment, batchId) => {
      return batches[batchId].adjustment || adjustment
    }, null)
}

const pickDeletedProducts = shipment => {
  const changes = [get(shipment, `history.${shipment.snapshotId}.counts`, [])]
    .concat(get(shipment, 'changes', []).map(current => current.changes))

  const batchesByProductId = changes.reduce((accumulator, current) => {
    Object.keys(current).forEach(batchId => {
      const productId = batchIdToProductId(batchId)
      if (!accumulator[productId]) {
        accumulator[productId] = {}
      }
      // if this batch has been recorded before i.e (same product, manufacturer & batchNo),
      // save it's most recent entry
      if (accumulator[productId][batchId]) {
        if (accumulator[productId][batchId].timestamp === undefined && current[batchId].timestamp) {
          accumulator[productId][batchId] = current[batchId]
          return
        }
        if (current[batchId].timestamp > accumulator[productId][batchId].timestamp) {
          accumulator[productId][batchId] = current[batchId]
        }
        return
      }
      // record the first entry of this batch
      accumulator[productId][batchId] = current[batchId]
    })
    return accumulator
  }, {})

  return Object.keys(batchesByProductId).filter(productId => {
    const batches = batchesByProductId[productId]
    return !Object.keys(batches).find(batchId => !batches[batchId].removed)
  })
}

export const load = (state, action) => {
  const shipment = action.shipment
  const snapshot = shipment.history[shipment.snapshotId]
  const user = action.user
  const isComplete = isShipmentComplete(shipment, user)

  const deletedProducts = pickDeletedProducts(shipment)
  // there used to be a different version for completed shipments,
  // showing the difference between planned/received in VAN
  // but was removed here: https://github.com/fielded/van-orga/issues/1968
  // Now i was thinking it could be useful for showing
  // completed shipment before adjustment vs. completed shipment after adjustment:
  const nextCounts = {
    planned: snapshot.counts,
    picked: shipment.counts
  }

  const plannedTotals = totalsByProduct(nextCounts.planned, false)
  const pickedTotals = totalsByProduct(nextCounts.picked, !isComplete)

  const byProductId = (index, productId) => {
    const pickedCounts = nextCounts.picked
    const batchIds = Object.keys(pickedCounts)
    const {code, name} = action.products.byId[productId]
    index[productId] = {
      id: productId,
      code: code,
      name: name,
      total: plannedTotals[productId] || 0,
      picked: pickedTotals[productId] || 0,
      snapshotId: shipment.snapshotId,
      adjustment: readAdjustmentMeta(nextCounts.picked, productId),
      /**
        * All new products added in a context where batches are unknown are a assigned
        * a faux batch called "unknown" (e.g. on pre-advice and orphan arrival).
        * This happens regardless of whether they are tracked or untracked (dry goods).
        *
        * The quantity of untracked products are recorded on this unknown batch,
        * while tracked products get additional batches added by user.
        *
        * We use this check to see if a product has *user-added* batches or quantity,
        * so that we can prompt user to add batches or quantity, depending on product type,
        * for those products.
        */
      hasRelevantBatches: hasBatchesOrQuantityForProduct(pickedCounts, productId),
      unchecked: []
    }

    if (!isComplete) {
      const isCurrentProduct = batchId => batchId.indexOf(productId) === 0
      const isUnchecked = batchId => !pickedCounts[batchId].checked
      const isCheckable = batchId => batchCanBeChecked(shipment.counts, batchId)
      index[productId].unchecked = batchIds
        .filter(isCurrentProduct)
        .filter(isCheckable)
        .filter(isUnchecked)
    }
    return index
  }

  // This creates a new set of all products (planned and picked)
  // planned and picked products can be different on shipments, so we need this here
  const productIds = Array.from(new Set(Object.keys(plannedTotals)
    .concat(Object.keys(pickedTotals))))
    .filter(productId => !deletedProducts.includes(productId))
  return productIds.reduce(byProductId, {})
}

const maybeUpdatedTotalQuantity = (batch, batches, state) => {
  const {
    productId,
    snapshotId
  } = batch
  if (isPreAdvice(snapshotId)) {
    return totalsByProduct(batches, false)[productId]
  }
  return state[productId].total
}

const batchesListUpdated = (state, {updatedBatch, currentBatches}) => {
  const productId = updatedBatch.productId
  return {
    ...state,
    [productId]: {
      ...state[productId],
      hasRelevantBatches: hasBatchesOrQuantityForProduct(currentBatches, productId),
      total: maybeUpdatedTotalQuantity(updatedBatch, currentBatches, state)
    }
  }
}

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD: return load(state, action)
    case BATCHES_LIST_UPDATED: return batchesListUpdated(state, action)
    default: return state
  }
}

export const loadPickList = (shipment, products, user) => (dispatch, getState) => {
  return dispatch({
    type: LOAD,
    shipment,
    products,
    user
  })
}
