const get = require('lodash/get')
const {
  INITIALIZED_IN_OPS,
  BACKEND_SERVICE_USERNAME,
  locationRouteToRelationalModel
} = require('../tools')
const { byId } = require('../../tools')
const { withQueryString } = require('../../../lib/common/rest-adapter')
const getServiceRoutes = require('../tools/get-service-routes')

class LocationRestAdapter {
  constructor (restAdapter) {
    this.restAdapter = restAdapter
  }

  async get (id) {
    return this.restAdapter.get(`location/${encodeURIComponent(id)}`)
  }

  async getByFilters (params = {}) {
    return this.restAdapter.get(withQueryString('location', Object.assign({limit: 1000}, params)))
  }

  async getByFSID (_id) {
    const {results} = await this.restAdapter.get('location', {fsid: _id})
    if (!results.length) return

    if (results.length === 1) {
      return results[0]
    }

    throw new Error(`Found duplicate rows with the same FS ID ${_id}`)
  }

  // TODO: make the rest adapter here return regular location entity shapes as best it can
  async listAll (params = {}) {
    // listing locations at 1k a request still happens very fast
    const {results} = await this.restAdapter.listAll('location', Object.assign({limit: 1000}, params))
    return results
  }

  async listAllMobilized () {
    const {results} = await this.restAdapter.listAll('location', {level: INITIALIZED_IN_OPS})
    return results
  }

  async update (location) {
    return this.restAdapter.update(`location/${location.uuid}`, location)
  }

  async create (location) {
    return this.restAdapter.create('location', location)
  }

  async patch (id, data) {
    return this.restAdapter.patch(`location/${id}`, data)
  }

  async getCheckpoint (checkpointId) {
    const {offset} = await this.restAdapter.get(`checkpoint/${checkpointId}`)
    return offset
  }

  async createCheckpoint (checkpointId) {
    return this.restAdapter.create('checkpoint', {id: checkpointId, offset: '0'})
  }

  async updateCheckpoint (checkpointId, offset) {
    const updatedCheckpoint = {
      id: checkpointId,
      offset
    }

    return this.restAdapter.patch(`checkpoint/${checkpointId}`, updatedCheckpoint)
  }

  async getChanges (limit, offset, fromLevel = 0, sinceDate, mustHaveOrdersStatus) {
    // relies on created_at ordering in rest framework view/change.py
    let resource = `change/?tablename=data_location&limit=` +
      `${limit}&offset=${offset}&level__gte=${fromLevel}` +
      `&updated_by=${BACKEND_SERVICE_USERNAME}`
    if (sinceDate) {
      resource = `${resource}&created_at__gte=${sinceDate}`
    }
    if (mustHaveOrdersStatus) {
      resource = `${resource}&orders_status__isnull=false`
    }

    const {results} = await this.restAdapter.get(resource)
    return results
  }

  /**
   * fetch locations which have been involved in any changes to data_locationsupply table since {offset}, whether as
   * location which is supplied from, or supplies to, other suppliers (or both)
   * @param limit
   * @param offset
   * @returns {Promise<{
   * sendersChanged: UUID-string[],
   * recipientsChanged: UUID-string[]
   * length: integer,
   * }>}
   */
  async getSupplyChanges (limit, offset) {
    const changeResource = `change/?tablename=data_locationsupply&limit=${limit}&offset=${offset}&include_deletes=true`
    const {results} = await this.restAdapter.get(changeResource)

    const sendersChanged = []
    const recipientsChanged = []

    results.forEach(result => {
      if (!sendersChanged.includes(result.new_val.supply_from_id)) {
        sendersChanged.push(result.new_val.supply_from_id)
      }
      if (!recipientsChanged.includes(result.new_val.supply_to_id)) {
        recipientsChanged.push(result.new_val.supply_to_id)
      }
    })

    return {
      sendersChanged,
      recipientsChanged,
      length: results.length
    }
  }

  // Bulk gets by ID in django rest framework are possible but require
  // some lot of manual work and hit URL limits.
  // this is for locations syncing, which rarely sees more than a few updates at a time.
  // We can make a get by id if necessary.
  async getLocationsById (locationUUIDs, logger) {
    const locations = []
    for (let i = 0; i < locationUUIDs.length; i++) {
      const uuid = locationUUIDs[i]
      try {
        const location = await this.get(uuid)
        locations.push(location)
      } catch (error) {
        logger.warn(`Error: could not find location with UUID ${uuid}`, JSON.stringify(error))
      }
    }
    return locations
  }

  // Upsert in the RDS the route assignments for this location
  async upsertRouteChanges (location, allRoutes) {
    const routesById = byId(allRoutes)
    const locationUUID = get(location, 'additionalData.uuid')
    if (!locationUUID) {
      return
    }

    // 1. get already synced assignments for this location
    const PATH = 'location_route_history'
    const queryParams = { location_id: locationUUID }
    const syncedRouteHistory = (await this.restAdapter.get(PATH, queryParams)).results

    // 2. iterate over all services assignments and sync the new ones or
    // the ones that have changed (i.e. and `endDate` has been added)
    const syncing = []
    Object.keys(get(location, 'programs', {})).forEach((programShortId) => {
      Object.keys(get(location, `programs.${programShortId}`, {})).forEach((serviceShortId) => {
        const serviceIterator = getServiceRoutes(location.programs[programShortId][serviceShortId])

        serviceIterator.forEach(assignment => {
          const route = routesById[`route:${assignment.routeId || assignment.funderId}`] // For old assignments before we refactored funderId to routeId
          if (!(route && route.uuid)) { return }
          const synced = syncedRouteHistory.find(item =>
            item.route_id === route.uuid &&
        new Date(item.start_date).toJSON() === assignment.startDate
          )
          if (!synced || new Date(synced.end_date).toJSON() !== assignment.endDate) {
            const relModel = locationRouteToRelationalModel(
              locationUUID,
              route.uuid,
              assignment
            )
            // `create` in the avocado `location_route_history` endpoint
            // is in fact an upsert
            syncing.push(this.restAdapter.create(PATH, relModel))
          }
        })
      })
    })
    return Promise.all(syncing)
  }
}

module.exports = LocationRestAdapter
