
import get from 'lodash/get'
import capitalize from './capitalize'

export const getManufacturerLabel = (value, manufacturers) => {
  if (!value) return undefined

  const fallback = capitalize(value)
  if (!manufacturers) return fallback

  return get(
    manufacturers.find((manufacturer) => manufacturer.value === value),
    'label',
    fallback
  )
}
