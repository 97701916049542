const { translateTerritoryAlias, productAliasesByMarket } = require('../../product/tools')
const get = require('lodash/get')
const keyBy = require('lodash/keyBy')
const { getServiceForLocationId } = require('../../service/tools/territory-lookup')

async function getProducts (supplierShipments, db) {
  let productIds = supplierShipments.map(shipment => shipment.sku)
  productIds = Array.from(new Set(productIds))

  const { rows } = await db.allDocs({
    keys: productIds,
    include_docs: true
  })

  const products = rows.map(r => r.doc).filter(x => x)
  const result = keyBy(products, '_id')
  return result
}

exports.getShipmentsToUpdateLedger = getShipmentsToUpdateLedger
async function getShipmentsToUpdateLedger ({location, productsDB, ledger, outgoingShipments = [], incomingShipments = []}) {
  const allShipments = [...outgoingShipments, ...incomingShipments]
  if (allShipments.length === 0) return ledger

  const locationService = get(location, 'services')[0]
  const productsById = await getProducts(allShipments, productsDB)
  const productsForTranslation = productAliasesByMarket(productsById)

  outgoingShipments.forEach(product => {
    const { service: serviceId } = getServiceForLocationId(product.destination_id)
    const needsTranslation = serviceId !== locationService
    const id = product.sku
    let productId
    try {
      productId = needsTranslation
        ? translateTerritoryAlias(id, productsForTranslation, serviceId)
        : id
    } catch (e) {
      console.warn(`Failed to translate '${id}' to ${serviceId} equivalent sku`)
    }
    if (productId) { // could be undefined if `translateTerritoryAlias` throws
      if (!ledger[productId]) {
        ledger[productId] = 0 - product.quantity
      } else {
        ledger[productId] -= product.quantity
      }
    }
  })

  incomingShipments.forEach(product => {
    const { service: serviceId } = getServiceForLocationId(product.origin_id)
    const needsTranslation = serviceId !== locationService
    const id = product.sku
    let productId
    try {
      productId = needsTranslation
        ? translateTerritoryAlias(id, productsForTranslation, serviceId)
        : id
    } catch (e) {
      console.warn(`Failed to translate '${id}' to ${serviceId} equivalent sku`)
    }
    if (productId) { // could be undefined if `translateTerritoryAlias` throws
      if (!ledger[productId]) {
        ledger[productId] = product.quantity
      } else {
        ledger[productId] += product.quantity
      }
    }
  })

  return ledger
}
