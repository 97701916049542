import { formatDate } from '../../../van-shared/utils'
import { isUntracked } from './utils'
import get from 'lodash/get'

export const batchWithMasterData = (state, batchId) => {
  const productId = state.batches[batchId].productId
  const masterDataBatch = state.masterData.batches[batchId]
  const product = state.masterData.products.byId[productId]
  return {
    ...masterDataBatch,
    ...state.batches[batchId],
    formattedExpiry: formatDate(get(masterDataBatch, 'expiry'), 'expiry'),
    productName: product.name,
    productCode: product.code,
    productPresentation: product.presentation,
    isUntracked: isUntracked(product)
  }
}

export const batchesByShipment = (state, snapshotId) => {
  return Object.keys(state.batches)
    .filter(batchId => {
      const batch = state.batches[batchId]
      return batch.snapshotId === snapshotId
    })
    .map(batchId => batchWithMasterData(state, batchId))
}
