import React from 'react'
import PropTypes from 'prop-types'

const WizardStepContent = ({
  children
}) => (
  <div className='wizard-step__body'>
    {children}
  </div>
)

WizardStepContent.displayName = 'Wizard.Step.Content'

WizardStepContent.propTypes = {
  /**
   * The components' children
   */
  children: PropTypes.node.isRequired
}

export default WizardStepContent
