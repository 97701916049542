import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FieldContext } from '../'
import { requiredValidationErrorMessage } from '../../utils'
import FormFieldRegistrationManager from '../../FormFieldRegistrationManager'

import NumberFormat from '../../../NumberFormat'
import { removeDelimiters } from '../../../../utils'

const Price = ({
  required,
  validate,
  onValueChange,
  defaultValue,
  value,
  textAlign,
  ...otherProps
}) => {
  const fieldValue = value || defaultValue || ''

  return (
    <FieldContext.Consumer>
      {
        (field) => (
          <FormFieldRegistrationManager
            register={() => {
              field.register({
                required,
                validate,
                requiredValidator: async (value) => {
                  if (!value || value === '') {
                    return required && requiredValidationErrorMessage(required)
                  }
                },
                value: fieldValue,
                ...otherProps
              })
            }}
            deregister={() => field.deregister()}
          >
            <NumberFormat
              name={field.props.fieldName}
              className={classnames(
                'vs-form-field__input',
                'vs-text-input',
                {'vs-text-input--text-align-right': textAlign === 'right'},
                {'vs-text-input--has-error': field.hasErrors()}
              )}
              value={field.props.value}
              type='tel'
              thousandSeparator
              fixedDecimalScale
              onChange={event => {
                const value = removeDelimiters(event.target.value)
                field.handleOnChange(value)
                if (typeof onValueChange === 'function') {
                  onValueChange(value)
                }
              }}
              {...otherProps}
            />
          </FormFieldRegistrationManager>
        )
      }
    </FieldContext.Consumer>
  )
}

Price.propTypes = {
  /**
   * Whether the field is required, optionally pass a string to define a custom
   * validation error message
   */
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),

  /**
   * A custom validation function
   */
  validate: PropTypes.func,

  /**
   * Runs when field value changes (returns new value)
   */
  onValueChange: PropTypes.func,

  /**
   * Field value (will be handled by form)
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * Number of decimal places to return as Field value
   */
  decimalScale: PropTypes.number,

  /**
   * Initial value if form needs to be reset
   */
  defaultValue: PropTypes.number,

  /**
   * Align value on the left or right side
   */
  textAlign: PropTypes.oneOf(['left', 'right']),

  /**
   * Text to display when no value is present in the field
   */
  placeholder: PropTypes.string
}

Price.defaultProps = {
  required: false,
  validate: undefined,
  onValueChange: undefined,
  value: undefined,
  defaultValue: undefined,
  decimalScale: 2,
  textAlign: 'left',
  placeholder: undefined
}

Price.displayName = 'Form.Field.Price'

export default Price
