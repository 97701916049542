/* global URL */
/*
 * This config is stand-alone
 * because we need to re-use it in the service worker
 * and the common/config file was pulling in too many things
 */
const SL_DEV = {
  config: {
    apiKey: 'AIzaSyAAZ8Qen-1AafZNdKGEHE3kN0phl2mGM00',
    authDomain: 'shelflife-development.firebaseapp.com',
    projectId: 'shelflife-development',
    storageBucket: 'shelflife-development.appspot.com',
    messagingSenderId: '519294456933',
    appId: '1:519294456933:web:aed7d36c66e7c26880e28c',
    measurementId: 'G-N2534JTF0G' // not used, check common/config instead
  },
  vapidkey: {
    vapidKey: 'BOtPk8cwGGfrrkYQGOeoco2i2UxsH8mrToNUcKgUDzhWIyX1GRB-32wiNu3qqgIHPq1UQFCBjbcU0vftZ1IxCuU'
  }
}

const PSM_DEV = {
  config: {
    apiKey: 'AIzaSyCuv5i9u6xsse8JUCpr6kTDUlaXQzes0_w',
    authDomain: 'psm-development.firebaseapp.com',
    projectId: 'psm-development',
    storageBucket: 'psm-development.appspot.com',
    messagingSenderId: '700842779709',
    appId: '1:700842779709:web:9ec3fb5a4ed911a84af654'
  },
  vapidkey: {
    vapidKey: 'BMHhp-ZqviVpPeLIFpjcU_P8DyN83PwHfS8J4YKcgFEPdBQaRANEhwt085DE_82jicC9dNqzjE6afHfCKRF81Z4'
  }
}

const PSM_STAGING = {
  config: {
    apiKey: 'AIzaSyA4J_dGB4J8qbnbZZPOhby3-HTBrV3OBgU',
    authDomain: 'psm-staging-2b065.firebaseapp.com',
    projectId: 'psm-staging-2b065',
    storageBucket: 'psm-staging-2b065.appspot.com',
    messagingSenderId: '372926977691',
    appId: '1:372926977691:web:36d49f7d923a3d3cf2d83e'
  },
  vapidKey: {
    vapidKey: 'BGDWKBHw_i3AsCWCaCK5PARC3K06iQIIDwQzLAsepjKAAdHQg9vS2Havve2DQzjF9sTKzWIX39B5kQC7u8mL4qY'
  }
}

const SL_STAGING = {
  config: {
    apiKey: 'AIzaSyCm1ulsmImeSgMdsQ6kBG5OQgXsmRdv6J4',
    authDomain: 'shelflife-staging.firebaseapp.com',
    projectId: 'shelflife-staging',
    storageBucket: 'shelflife-staging.appspot.com',
    messagingSenderId: '483179860886',
    appId: '1:483179860886:web:0d2256e5df058286f2c3b7',
    measurementId: 'G-051WTQKV5J' // not used, check common/config instead
  },
  vapidkey: {
    vapidkey: 'BAy2xy7ydr4G64ZE53N3YAoCIAWKsvTB9wdzCngtTV_LMAZlkrrMPFEjuI0uabWoitqjAx1ScVpHFHJ_QSG139o'
  }
}

const SL_PROD = {
  config: {
    apiKey: 'AIzaSyCMWdDnbBY-wm6HO_88s0ozP_jdAIlvGVk',
    authDomain: 'shelflife-production.firebaseapp.com',
    projectId: 'shelflife-production',
    storageBucket: 'shelflife-production.appspot.com',
    messagingSenderId: '67633275527',
    appId: '1:67633275527:web:5b8df9e14b34ddd8e04e22',
    measurementId: 'G-6V31SJLF4X' // not used, check common/config instead
  },
  vapidkey: {
    vapidkey: 'BMJae3gBaY9EWnk-8zW5L8Z7SXFJTtsX2P9b9PoVAI5rV-BLV06yoL3_Ti8VtVCjk2txlOPiqPU1a1J3Tv0uHPY'
  }
}

const HOST_MAP = [
  // SHELF LIFE
  ['local.shelflife.localhost:3000', SL_DEV],
  ['dev.shelflife.localhost:3000', SL_DEV],
  ['dev.psm.localhost:3000', PSM_DEV],
  ['dev-local-django.shelflife.localhost:3000', SL_DEV],
  ['staging.shelflife.localhost:3000', SL_STAGING],
  ['dev.shelflife.field.supply', SL_DEV],
  ['dev.nscip.field.supply', PSM_DEV],
  ['staging.nscip.field.supply', PSM_STAGING],
  ['staging.shelflife.field.supply', SL_STAGING],
  ['shelflife.field.supply', SL_PROD],
  // Pull request previews
  [/^pr-\d+\.shelflife.preview.field.supply/, SL_DEV],
  [/^pr-\d+\.psm.preview.field.supply/, PSM_DEV]
]

export default function getFirebaseConfig (href) {
  const url = new URL(href)
  for (const [key, value] of HOST_MAP) {
    if (key instanceof RegExp) {
      if (key.test(url.host)) {
        return value
      }
    }
    if (key === url.host) {
      return value
    }
  }

  return null
}
