module.exports = shouldTrackBatches

const getSubmitProperties = require('./get-submit-properties')
const get = require('lodash/get')

function shouldTrackBatches (params) {
  const {
    location,
    product = {},
    serviceId,
    service
  } = params

  if (hasBatchTrackingServicesProp(location, serviceId)) {
    return true
  }

  if (service && service.tracksBatches) {
    return true
  }

  if (location && !getSubmitProperties(serviceId, location.id).submitsBatchedCounts) {
    return false
  }

  if (product.productType === 'dry') {
    return false
  }

  if (product.productType === 'diluent') {
    return false
  }

  return true
}

function hasBatchTrackingServicesProp (location, serviceId) {
  if (get(location, `batchTrackingServices.all`)) return true
  if (get(location, `batchTrackingServices.${serviceId}`)) return true
}
