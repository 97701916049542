const { getProducts } = require('../master-data')
const { listBatches: getBatches } = require('./../../../batch/api/batch-list')
const { getAllocatedStock } = require('../read/get-allocated-stock')
const tools = require('../../tools')
const createBulkSnapshots = require('../../../shipment/shipment-bulk-create-snapshots')

// Creating warehouse suborders creates snapshots that are suborders,
// moving to accepted use the same suborder ID as the warehouse suborder
// but still create a new snapshot doc for the REST API users to edit.
exports.acceptOrders = async function (state, {snapshots, programId, dryRun, date = new Date().toJSON()}) {
  if (!snapshots.length) {
    return {
      acceptedSnapshotsCreated: 0,
      snapshots: [],
      shipmentSnapshots: []
    }
  }

  const [productsList, batchesList, stockResponse] = await Promise.all([
    getProducts(state, programId),
    getBatches(state),
    getAllocatedStock(state, {programId, date, locationId: snapshots[0].supplierId, includeLedger: true})
  ])
  const {ledgerSDPUnits, funderId} = stockResponse
  const acceptedSnapshots = tools.acceptOrders({
    snapshots, user: state.user, ledger: ledgerSDPUnits, productsList, batchesList, date
  })

  const shipmentSnapshots = tools.createShipmentSnapshots({acceptedSnapshots, funderId, productsList}).map(snapshot => {
    return {...snapshot, removePaymentType: true}
  })

  if (dryRun) {
    return {
      acceptedSnapshotsCreated: acceptedSnapshots.length,
      snapshots: acceptedSnapshots,
      shipmentSnapshots
    }
  }

  await state.dal.order.bulkDocs(state, acceptedSnapshots)
  const shipments = await createBulkSnapshots(state, shipmentSnapshots)

  // TODO: this is a temporary response, see note in ordersFromSnapshotUpdates
  return {
    acceptedSnapshotsCreated: acceptedSnapshots.length,
    snapshots: acceptedSnapshots,
    shipments
  }
}
