const { get: getService } = require('./service')
const { periodIdToDate } = require('./report/tools/ids')
const getPeriodFromProgram = require('./report/tools/get-period-from-program')
const saveReport = require('./report/api/save')

module.exports = async (state, {
  location,
  serviceId,
  reportingPeriod,
  stock = null,
  expiry = null
}) => {
  const locationId = location ? location.id : state.user.location.id
  const service = await getService(state, serviceId)
  const date = periodIdToDate(reportingPeriod)
  const period = getPeriodFromProgram(service.program, date, true)

  return saveReport(state, {locationId, service, period, stock, expiry})
}
