module.exports = denormaliseRoles

function denormaliseRoles (normalisedRoles) {
  return normalisedRoles.map(function (role) {
    switch (true) {
      // VAN shows stock-reporting to all users, so this role
      // has no effect there
      case role === 'feature:stock-reporting':
        return 'stock-reports'
      case role === 'feature:analytics:utilization':
        return 'analytics-utilization'
      case role.startsWith('feature:'):
        return role.replace('feature:', '')
      case role === 'admin':
        return role
      case role.includes('userRole'):
        return role
      default:
        // Strip out any other types of role
        return null
    }
  }).filter(role => role)
}
