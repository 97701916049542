/* global localStorage */
import { initializeApp as initializeFirebase } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { log as logGA } from './googleAnalytics'
import get from 'lodash/get'
import { addMonths } from 'date-fns'
import { toast } from '@fielded/shared-ui/src/components/Page/Page'
import prepareFetch from '../common/prepare-fetch'

const NOTIFICATION_SETTINGS = 'notifications'

const getSettings = () => {
  const items = localStorage.getItem(NOTIFICATION_SETTINGS)
  if (!items) {
    return {}
  }
  return JSON.parse(items)
}

// 2 types of messages, 'notification' and 'data'
// https://firebase.google.com/docs/cloud-messaging/concept-options
// This handler renders notifications that when the window is active using a toast,
// for background-notifications, see service-worker.js
const onMessageHandler = message => {
  let settings
  let body = get(message, 'notification.body') || get(message, 'data.body', '')
  let target = get(message, 'data.url')
  let ctaProps = null
  const label = get(message, 'data.label', 'label_missing')

  const analyticsProps = {
    message_id: message.messageId,
    message_name: label,
    label: label,
    type: get(message, 'data.type', 'info')
  }

  if (target) {
    // It's not a complete url so can't use window.URL
    const separator = target.includes('?') ? '&' : '?'
    ctaProps = {
      label: get(message, 'data.cta', 'View Page'),
      href: `${target}${separator}notification_foreground=${label}&notification_id=${message.messageId}`,
      onClick: () => { logGA('notification_foreground_open', analyticsProps) }
    }
  }

  logGA('notification_foreground', analyticsProps)

  if (message.notification) {
    settings = {
      title: message.notification.title,
      type: message.data.type || 'info',
      autoDismiss: false,
      children: body,
      ctaProps
    }
    toast()
  } else if (message.data) {
    settings = {
      title: message.data.title,
      type: message.data.type || 'info',
      autoDismiss: 10000,
      children: body,
      ctaProps
    }
    toast()
  } else {
    console.log('Unknown FCM Message', message)
  }

  if (settings) {
    toast(settings)
  }
}

let messaging
const initializeNotifications = async (user, config, api) => {
  if (messaging) {
    return messaging
  }

  const firebaseConfig = get(config, 'firebase.config', null)
  const firebaseKey = get(config, 'firebase.vapidkey', null)

  if (!firebaseConfig) {
    console.warn('No firebase notifications config available')
    return
  }

  const app = initializeFirebase(firebaseConfig)
  messaging = getMessaging(app)
  const permission = await window.Notification.requestPermission()
  if (permission === 'denied') {
    const settings = {
      dismissed: new Date().getTime(),
      denied: true
    }

    localStorage.setItem(NOTIFICATION_SETTINGS, JSON.stringify(settings))

    return
  }

  if (permission !== 'granted') {
    // I guess something went wrong? :shrug:
    const settings = { permission, status: 'unknown permission' }
    localStorage.setItem(NOTIFICATION_SETTINGS, JSON.stringify(settings))
    return
  }

  if (!window.location.origin.startsWith('https')) {
    console.warn('Are you running dev mode locally? Unfortunately we can\'t test notifications there because there\'s no service worker, run in a PR preview instead')
  }

  return {
    // Let this finish on its' own, we should not wait for it
    firebasePromise: navigator.serviceWorker.ready
      .then(serviceWorkerRegistration => {
        // because we provide our own service worker, we need to let firebase know about it
        // https://firebase.google.com/docs/reference/js/messaging_.gettokenoptions.md#gettokenoptions_interface
        return getToken(messaging, {
          serviceWorkerRegistration,
          firebaseKey
        })
      })
      .then(token => {
        if (token) {
          const settings = {
            user: user._id,
            token: token,
            permission
          }

          // Send token to server
          api.notificationsFcm.upsert({ fcmToken: token })

          localStorage.setItem(NOTIFICATION_SETTINGS, JSON.stringify(settings))
          onMessage(messaging, onMessageHandler)
        } else {
          // TODO: what's the right thing to do here?
          const settings = {
            user: user._id,
            permission
          }

          localStorage.setItem(NOTIFICATION_SETTINGS, JSON.stringify(settings))
        }
      })
      .catch(e => {
        console.log('SW reg error, no notifications', e)
      })
  }
}

const checkNotifications = async (user, config, api) => {
  const settings = getSettings()
  // Approving notifications
  const setupUser = get(settings, 'user')
  const denied = get(settings, 'denied')
  const dismissed = get(settings, 'dismissed')
  const notificationsRetry = get(config, 'features.notifications.retryInterval', false)

  if (setupUser === user._id) {
    await initializeNotifications(user, config, api)
    return 'initialized'
  }

  // User has explicitly denied permissions
  // don't ask again
  if (denied) {
    return 'denied'
  }

  // User dismissed the settings, let's not ask again in a month
  if (notificationsRetry && dismissed && addMonths(dismissed, notificationsRetry) > new Date()) {
    return 'dismissed'
  }

  return false
}

const dismissNotifications = (api) => {
  const oldSettings = getSettings()

  if (oldSettings.token) {
    api.notificationsFcm.delete(oldSettings.token)
      .catch(e => console.log('delete token error', e))
  }

  const settings = {
    dismissed: new Date().toJSON()
  }

  localStorage.setItem(NOTIFICATION_SETTINGS, JSON.stringify(settings))
}

const testNotifications = (config) => {
  const fetch = prepareFetch(config.agaveApiUrl)

  return fetch('/notifications/test-fcm-message/', { method: 'post' })
    .then(res => res.status === 204)
}

export {
  checkNotifications,
  initializeNotifications,
  dismissNotifications,
  testNotifications
}
