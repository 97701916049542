import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const PrintTemplateBox = ({
  title,
  left,
  right,
  additionalInfo
}) => {
  return (
    <section className={classnames(
      {'print-template__box-wrapper': !!additionalInfo}
    )}>
      {title && <div className='print-template__box-title'>{title}</div>}
      <div className='print-template__box vs-u-align-baseline'>
        <div className='print-template__box-section'>
          {left}
        </div>
        <div className='print-template__box-section'>
          {right}
        </div>
        {additionalInfo && additionalInfo}
      </div>
    </section>
  )
}

PrintTemplateBox.propTypes = {
  /** Children components of the Print Template aligned left in the layout */
  left: PropTypes.node,

  /** Children components of the Print Template aligned right in the layout */
  right: PropTypes.node,

  /** Children components of the Print Template. If more information is required */
  additionalInfo: PropTypes.node
}

export default PrintTemplateBox
