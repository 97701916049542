const getProductSkuName = (data) => {
  const {
    presentationQty,
    presentationUnit,
    presentationType,
    name,
    variety,
    dosageQty,
    dosageUnit
  } = data

  const varietyDetails = variety
    ? `(${variety})`
    : ''

  const dosageDetails = dosageQty && dosageUnit
    ? `${dosageQty}${dosageUnit}`
    : ''

  const presentationDetails = presentationQty && presentationUnit
    ? `${presentationQty} X ${presentationUnit}`
    : ''

  const parts = [name, varietyDetails, dosageDetails, presentationDetails, presentationType].filter(part => part && part !== '')

  return parts.join(' ')
}

module.exports = getProductSkuName
