import React from 'react'
import PropTypes from 'prop-types'

const Section = ({ title, intro, children }) => {
  return (
    <div className='vs-form-section'>
      {title && (
        <div className='vs-form-section__title'>{ title }</div>
      )}
      {intro && (
        <div className='vs-form-section__intro'>{ intro }</div>
      )}
      <div className='vs-form-section__items'>
        {children}
      </div>
    </div>
  )
}

Section.displayName = 'Form.Section'

Section.propTypes = {
  /**
   * Optionally add a title to the fields in the Section
   */
  title: PropTypes.string,

  /**
   * Optionally add an intro to the fields in the Section. You may use a simple string or paragraphs.
   */
  intro: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),

  /**
   * The Fields in this Section.
   */
  children: PropTypes.node.isRequired
}

Section.defaultProps = {
  title: undefined,
  intro: undefined
}

export default Section
