exports.bulkCreateServiceConfigs = async (state, services = [], username = '') => {
  const docsToCreate = services.map(service => {
    return {
      _id: `configuration:${service.id}`,
      service: service.id,
      type: 'configuration',
      products: [],
      packages: [],
      createdBy: username,
      createdAt: new Date().toISOString()
    }
  })

  const doc = await state.dal.configuration.bulkDocs(state, docsToCreate)
  return doc
}
