/**
 * CSV Parser
 */
const {
  createAllocationFromRows,
  columns
} = require('./parse-utils')

const {CSV_DELIMITER} = require('./config')

const groupByFacilityId = (rows) => rows.reduce((byFacilityId, row) => {
  const facilityProductAllocation = columns.reduce((allocations, column, index) => Object.assign({}, allocations, {
    [column.name]: column.parse(row[index])
  }), {})

  return Object.assign(
    {},
    byFacilityId,
    {
      [facilityProductAllocation.id]: [
        ...byFacilityId[facilityProductAllocation.id] || [],
        facilityProductAllocation
      ]
    }
  )
}, {})

function sanitiseRows (rows) {
  // Remove header
  const [headerRow, ...rowsWithoutHeader] = rows // eslint-disable-line no-unused-vars
  // Filter out rows without a location id
  return rowsWithoutHeader.filter(r => !!r[0])
}

module.exports = (csv, effectiveDate, userName) => {
  const lines = csv.split('\n')
  const rows = lines.map(r => {
    const cs = r.split(CSV_DELIMITER)
    return cs.map(c => c.trim().slice(1, -1))
  })
  const sanitisedRows = sanitiseRows(rows)
  const productsByFacilityId = groupByFacilityId(sanitisedRows)
  const allocations = Object.keys(productsByFacilityId)
    .map(key => createAllocationFromRows(productsByFacilityId[key], effectiveDate, userName))
    .filter(a => !!a)
  return allocations
}
