import React from 'react'
import get from 'lodash/get'

import { Accordion, Banner, Page, StatusPill, Text, UppercaseLabel } from '@fielded/shared-ui'
import capitalize from '@fielded/shared-ui/src/utils/capitalize'
import { Home, Truck } from '@fielded/shared-ui/src/icons'
import { SHIPMENT_STATUS, SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS } from '@fielded/fs-api/lib/shipment/constants'

import DeliveryDetailProduct from '../../../../retailer/shipments/DeliveryDetail/DeliveryDetailProduct'
import { useLocation } from 'react-router'
import { formatDate } from '../../../../../van-shared/utils/utils'

const FacilityDeliveryDetail = ({
  otp,
  history,
  delivery,
  products
}) => {
  const location = useLocation()

  const prevPath = get(location, 'state.prevPath', '')
  const fromHomeRoute = prevPath === '/'

  const formattedDate = formatDate(delivery.date, 'long')
  const driverName = get(delivery, 'updatedBy.user', '') // TODO: get driver's name
  const isDeliveryDelayed = delivery.status === SHIPMENT_STATUS.DELAYED

  let title = 'Deliveries'
  let icon = <Truck />

  if (fromHomeRoute) {
    title = 'Home'
    icon = <Home />
  }

  return (
    <Page>
      <Page.HeaderNew
        title={title}
        icon={icon}
        history={history}
        subtitle='Delivery'
      />

      <Page.Panel narrow alignLeft>
        <Page.Panel.Section>
          <section className='facility-delivery-detail__header-info'>
            <StatusPill
              className={`delivery-location__status--${delivery.status}`}
              label='Status'
              value={capitalize(SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS[delivery.status])}
            />
            <StatusPill
              label='Date'
              value={formattedDate}
            />
            <StatusPill
              label='Driver'
              value={driverName || 'TBD'}
            />
            <StatusPill
              label='ID'
              value={delivery.shipmentNo || 'TBD'}
            />
          </section>
        </Page.Panel.Section>
      </Page.Panel>

      {isDeliveryDelayed && (
        <Page.Panel narrow alignLeft withMinimalMargin>
          <Banner
            title='Temporary delay in delivery'
            center={false}
            inline
          >
            We regret to inform you of delays in your order delivery. We appreciate your understanding during this time.
          </Banner>
        </Page.Panel>
      )}

      {otp && (
        <Page.Panel narrow alignLeft withMinimalMargin>
          <section className='facility-delivery-detail__otp-info'>
            <Text>To receive this delivery, please provide the OTP directly to the driver in person.</Text>
            <Text
              className='facility-delivery-detail__otp'
              color='brand'
              bold
            >
              Your OTP is: {otp}
            </Text>
          </section>
        </Page.Panel>
      )}

      <Page.Panel narrow alignLeft>
        <section className='facility-delivery-detail__table'>
          <Text className='vs-u-margin-bottom-double'>The following products are scheduled for delivery:</Text>

          <div className='facility-delivery-detail__table-header'>
            <UppercaseLabel>Products</UppercaseLabel>
            <UppercaseLabel>Qty</UppercaseLabel>
          </div>

          <Accordion>
            {products.map(product => (
              <DeliveryDetailProduct
                key={product._id}
                product={product}
              />
            ))}
          </Accordion>
        </section>
      </Page.Panel>
    </Page>
  )
}

export default FacilityDeliveryDetail
