const PGAdapter = require('../../common/pg-adapter')

const PRODUCT_PRESENTATION_TABLE_NAME = 'avocado.data_productpresentation'
const PRODUCT_PRESENTATION_COLUMNS = [
  'created_at',
  'updated_at',
  'id',
  'presentation_unit',
  'presentation_qty',
  'parent_presentation_pack_size',
  'parent_presentation_id',
  'product_id',
  'presentation_type',
  'packaging_type'
]

class ProductPresentationAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    const opts = {
      strictColumns: false,
      addUuid: false
    }
    super(
      pgConnection,
      PRODUCT_PRESENTATION_TABLE_NAME,
      username,
      PRODUCT_PRESENTATION_COLUMNS,
      'id',
      {},
      logger,
      opts
    )
  }
}

module.exports = ProductPresentationAdapter
