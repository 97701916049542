import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { NumberFormat, Form, QuantityTotal } from '@fielded/shared-ui'
import { validators } from '@fielded/shared-ui/src/components/Form'

class ReportField extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    inputPrefix: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string.isRequired,
    validationRules: PropTypes.object,
    isShelflife: PropTypes.bool,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    onProductUpdate: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    handleClick: PropTypes.func,
    hasIssue: PropTypes.bool,
    isInModal: PropTypes.bool
  }

  static defaultProps = {
    inputPrefix: 'report-entry-',
    validationRules: null,
    subLabel: undefined,
    isInModal: false
  }

  shouldComponentUpdate (nextProps) {
    const keys = Object.keys(nextProps)

    let changed = false
    for (var key of keys) {
      if (key === 'handleClick') {
        continue
      }

      if (!Object.is(this.props[key], nextProps[key])) {
        changed = true
      }
    }

    return changed
  }

  updateQuantity = amount => {
    const {
      id,
      onProductUpdate,
      type
    } = this.props

    onProductUpdate(id, type, { amount })
  }

  render () {
    const {
      id,
      inputPrefix,
      type,
      value,
      label,
      subLabel,
      hasIssue,
      onProductUpdate,
      validationRules,
      batchSize,
      autoFocus,
      handleClick,
      isShelflife,
      isInModal,
      fieldIsDisabled,
      ...otherProps
    } = this.props

    const isTypeDisplay = type === 'display'
    const isTypeCommit = type === 'commit'
    const isTypeCalculated = type === 'calculated'
    const isTypeText = type === 'text'
    const isTypeBatch = type === 'batch'
    const isTypeNumber = type === 'number'
    const isQuantityType = isTypeBatch || isTypeNumber

    let updateQuantity = this.updateQuantity

    if (isTypeCommit) {
      updateQuantity = amount => {
        return onProductUpdate(inputPrefix + id, 'number', { amount })
      }
    }

    if (isTypeBatch) {
      updateQuantity = quantity => {
        const update = {
          fields: {
            'field:standard-physical-count': {
              amount: quantity
            }
          }
        }
        return onProductUpdate(id, null, update)
      }
    }

    const validationFromRules = (value) => {
      const {
        validationRules,
        disabled,
        fields
      } = this.props
      if (validationRules) {
        const max = validationRules['maxValue']
        const isEmpty = validationRules['isEmptyValue']

        // check if fields are empty
        if (!disabled && isEmpty && (!value && value !== 0)) {
          return validators.validateEmptyFields({
            message: isEmpty.message,
            fields
          })
        }

        if (max) {
          return validators.maximum({
            value,
            checkValue: max.value,
            message: max.message
          })
        }

        return null
      }
    }

    return (
      <Form.Field
        className={classnames(
          'report-field',
          {'report-field--commit': isTypeCommit},
          {'report-field--text': isTypeText},
          {'report-field--no-border': isInModal}
        )}
        fieldName={id}
      >
        <div
          className={classnames(
            'report-field__field',
            {'report-field__field--has-issue': hasIssue}
          )}
          key={id}
        >
          {isTypeDisplay && (
            <div className='report-field__display-value'>
              <NumberFormat value={value || 0} displayType='text' thousandSeparator />
            </div>
          )}
          {isTypeCalculated && !isShelflife && (
            <div className='report-field__calculated-value'>
              <QuantityTotal value={value || 0} />
            </div>
          )}
          {
            /* For SL we add parenthesis when the calculated sales value is negative
          https://github.com/fielded/van-orga/issues/2611
          */}
          {isTypeCalculated && isShelflife && (
            <div className='report-field__display-value'>
              {value < 0
                ? <span>
                  (<NumberFormat value={Math.abs(value)} displayType='text' thousandSeparator />)
                </span>
                : <NumberFormat value={value || 0} displayType='text' thousandSeparator />
              }
            </div>
          )}
          {isQuantityType && !fieldIsDisabled && (
            <Form.Field.Quantity
              id={inputPrefix + id}
              data-qa='formfield-input'
              className='report-field__input'
              textAlign='right'
              autoComplete='off'
              value={value}
              onValueChange={updateQuantity}
              getInputRef={input => input && autoFocus && input.focus()}
              onClick={handleClick}
              validate={(value) => {
                const minValidation = validators.minimum({
                  value,
                  checkValue: 0,
                  message: 'Quantity must not be negative'
                })
                const maxValidation = validators.maximum({
                  value,
                  checkValue: 2147483647,
                  message: 'Quantity too large!'
                })

                if (batchSize) {
                  return validators.multipleOf({
                    value,
                    checkValue: batchSize,
                    message: 'The quantity must be a multiple of the full vial size of {allowed} doses.'
                  }) || minValidation || validationFromRules(value)
                }

                return minValidation || maxValidation || validationFromRules(value)
              }}
              {...otherProps}
            />
          )}
          {isQuantityType && fieldIsDisabled && (
            <div className='report-field__display-value'>
              <NumberFormat value={value || 0} displayType='text' thousandSeparator />
            </div>
          )}
          {isTypeCommit && (
            <Form.Field.Quantity
              id={inputPrefix + id}
              data-qa='formfield-input'
              className='report-field__input'
              textAlign='right'
              autoComplete='off'
              value={value}
              onValueChange={updateQuantity}
              getInputRef={input => input && autoFocus && input.focus()}
              onClick={handleClick}
              validate={(value) => {
                return validators.minimum({
                  value,
                  checkValue: 0,
                  message: 'Commit must not be negative'
                }) || validationFromRules(value)
              }}
              {...otherProps}
            />
          )}
          {isTypeText && (
            <Form.Field.Text
              id={'report-entry-' + id}
              className='report-field__input report-field__input--text'
              rows={isInModal ? 6 : 2}
              defaultValue={value}
              onChange={event =>
                onProductUpdate(id, type, { amount: event.target.value })
              }
              getInputRef={input => input && autoFocus && input.focus()}
              onClick={handleClick}
              validate={(value) => {
                return validationFromRules(value)
              }}
              {...otherProps}
            />
          )}
          {!isInModal && (
            <div
              className='report-field__label'
            >
              <span>
                <span
                  className={classnames(
                    {'report-field__name': !isTypeBatch},
                    {'report-field__batch-id': isTypeBatch}
                  )}
                >
                  {label}
                </span>
                {subLabel && (
                  <span className='report-field__sublabel'>
                    {subLabel}
                  </span>
                )}
              </span>
            </div>
          )}
        </div>
        <Form.Field.ValidationErrors
          alignRight
          className='report-field__invalid-warning'
        />
      </Form.Field>
    )
  }
}

export default ReportField
