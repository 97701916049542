import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import NumberFormat from 'react-number-format'

import QuantityTotal from '../QuantityTotal'

const AdjustmentsInfo = ({change}) => {
  if (!change) return null
  const date = new Date(change.date)
  const formattedDate = date.toLocaleString('en-GB', { dateStyle: 'short' })

  return (
    <Fragment>
      <p className='vs-diff-summary__adjustment' key={change.date}>
        Adjusted <strong>on {formattedDate}</strong> by <strong>{change.user}</strong> • Entered on delivery: {change.original}
      </p>
    </Fragment>
  )
}

const formatDifferenceMessage = (difference, differenceMessage, id) => {
  if (!difference || !differenceMessage) return undefined

  const formattedDifference = <NumberFormat
    value={Math.abs(difference)}
    displayType='text'
    thousandSeparator
  />

  const diffmoreless = <Fragment>{formattedDifference} {difference > 0 ? 'more' : 'less'}</Fragment>

  return (
    <div className={classnames(
      'vs-diff-summary__difference-message',
      {'vs-diff-summary__difference-message--positive': difference > 0},
      {'vs-diff-summary__difference-message--negative': difference < 0}
    )}>
      {/* trying to replace part of a string with a component outputs [object Object], this is to circumvent
          without using a package, like react-string-replace.
          See e.g. https://github.com/facebook/react/issues/3386
      */}
      { differenceMessage.split('{moreless}')
        .reduce((prev, current, i) => {
          if (!i) {
            return [current]
          }
          return prev.concat(<Fragment key={id}>{diffmoreless}</Fragment>, current)
        }, [])
      }
    </div>
  )
}

const DiffSummaryItem = (props) => {
  const {
    adjustment,
    productName,
    counted,
    expected,
    reserved,
    children,
    differenceMessage,
    noneCountedMessage,
    onRowClick,
    isExpanded
  } = props

  const noneCounted = noneCountedMessage && counted === 0
  const difference = typeof expected === 'number' ? counted - expected : undefined
  const hasPositiveDifference = difference > 0
  const hasNegativeDifference = difference < 0
  const isHeaderColored = difference || noneCounted
  const showDetailLines = (reserved || isExpanded)

  let formattedDifferenceMessage = null
  // Difference can be NaN when comparing incomplete stock report drafts
  if (!isNaN(difference) && difference !== 0) {
    formattedDifferenceMessage = formatDifferenceMessage(difference, differenceMessage, productName)
  }

  const showNoneCountedMesssage = noneCountedMessage && (counted === 0 || !counted)

  return (
    <Fragment>
      <tr
        className={classnames(
          'vs-diff-summary__product',
          {'vs-diff-summary__product--diff-up': hasPositiveDifference},
          {'vs-diff-summary__product--diff-down': hasNegativeDifference || noneCounted},
          {'vs-diff-summary__product--solid': isHeaderColored && showDetailLines},
          {'vs-diff-summary__product--tappable': onRowClick}
        )}
        onClick={onRowClick}
      >
        <td className='vs-diff-summary__product-name' colSpan={2}>
          {productName}
          <AdjustmentsInfo change={adjustment} />
        </td>
        <td className='vs-diff-summary__product-total'>
          {!showNoneCountedMesssage && (
            <QuantityTotal value={counted} alignEdge='content' />
          )}
        </td>
      </tr>

      {((isExpanded && children) || formattedDifferenceMessage) && (
        <tr>
          <td colSpan='3'>
            <div className='vs-diff-summary__details'>
              {/* Batch list is passed as children */}
              {children && (
                <div className='vs-diff-summary__batches'>
                  {children}
                </div>
              )}
              {formattedDifferenceMessage}
            </div>
          </td>
        </tr>
      )}
    </Fragment>
  )
}

DiffSummaryItem.propTypes = {
  /**
   * A human-readable product name, e.g.: BCG
   */
  productName: PropTypes.string.isRequired,

  /**
   * Picked count for this item
   */
  counted: PropTypes.number.isRequired,

  /**
   * Expected count for this item. Used to calculate if item is trending (+/- expected).
   * Can be left out if item should show as matching.
   */
  expected: PropTypes.number,

  /**
   * Stock amount reserved for campaign use.
   */
  reserved: PropTypes.number,

  /**
  * Name of a reservation/campaign that stock might be reserved for
  */
  reservationName: PropTypes.string,

  /**
   * Child component to render, usually batch information
   */
  children: PropTypes.node,

  /**
   * A message to help users understand a difference, if applicable.
   * Use placeholder `{moreless}` to indicate where in the string `XX more` or `XX less` should be inserted
   */
  differenceMessage: PropTypes.string,

  /**
   * If no count is passed this should explain to the user why
   */
  noneCountedMessage: PropTypes.string,

  isExpanded: PropTypes.bool
}

DiffSummaryItem.defaultProps = {
  expected: null,
  differenceMessage: '',
  noneCountedMessage: undefined,
  isExpanded: false
}

export default DiffSummaryItem
