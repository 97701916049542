import React from 'react'

import { Page } from '@fielded/shared-ui'

import { getNavigationItem } from '../../../common/utils/navigationInfo'
import { getSubNavigationItems } from './navigation-items'
import NewPSMShipment from './psm-shipments/NewPSMShipmentContainer'

const ShipmentsCreate = ({ config, shipmentId, history }) => {
  return (
    <Page>
      <Page.Header
        breadcrumbItems={[getNavigationItem({ item: 'shipments', config, includePath: false })]}
        subNavigationItems={getSubNavigationItems({ showImportLink: true, activeTab: 'new', shipmentId })}
        title={shipmentId ? 'Edit Shipment' : 'New Shipment'}
      />
      <NewPSMShipment shipmentId={shipmentId} history={history} />
    </Page>
  )
}

export default ShipmentsCreate
