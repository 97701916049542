const PGAdapter = require('./../common/pg-adapter')
const PAYMENT_DEBIT_TABLE_NAME = 'avocado.data_paymentdebit'
const PAYMENT_DEBIT_COLUMNS = [
  'created_at',
  'updated_at',
  'id',
  'data',
  'amount',
  'created_by',
  'payment_method_id',
  'reference'
]

class RemitaMandateAdapter extends PGAdapter {
  constructor (pgConnection, username, remitaInterface, safaricomInterface) {
    super(
      pgConnection,
      PAYMENT_DEBIT_TABLE_NAME,
      username,
      PAYMENT_DEBIT_COLUMNS
    )
    this.remitaInterface = remitaInterface
    this.safaricomInterface = safaricomInterface
  }

  async create ({ mandateId, requestId }) {
    const response = await this.remitaInterface.createOTP({mandateId, requestId})
    if (!response.ok) {
      const error = new Error(response.body)
      error.status = response.status
      throw error
    }
    return response
  }

  async update (data) {
    const response = await this.remitaInterface.validateOTP(data)
    if (!response.ok) {
      const error = new Error(response.body)
      error.status = response.status
      throw error
    }
    return response
  }
}

module.exports = RemitaMandateAdapter
