import React from 'react'

import { Text, ProgressBar } from '@fielded/shared-ui'

const SyncViewPage = ({progress}) => {
  const percentageComplete = progress === -1 ? 0 : progress
  return (
    <div className='vs-sync-view-page'>
      <div className='vs-sync-view-page__image' />
      <ProgressBar
        className='vs-u-margin-top-tripple vs-u-margin-bottom'
        colorVariant='brand'
        percentage={percentageComplete}
      />
      <Text.Heading bold size='medium'>
        Downloading required data ({(percentageComplete * 100).toFixed(2)}%)
      </Text.Heading>
      <Text size='large'>
        This may take some time. We're working to ensure the data is accurate and fully up-to-date for you.
      </Text>
    </div>
  )
}

export default SyncViewPage
