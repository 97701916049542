const cloneDeep = require('lodash/cloneDeep')
const get = require('lodash/get')
const getCurrentPrice = require('./get-current-price')

// remove ',' for formatted price columns
const toNumber = sheetPrice => {
  if (typeof sheetPrice === 'string') {
    return Number(sheetPrice.replace(/,/g, ''))
  }
  return Number(sheetPrice)
}

/**
 * Clean up sheet product (row)
 *
 * @param sheetProduct is product.construct(sheetRow) to be cleaned up (i.e column.prices which is a Number to array, etc.)
 * @param opts.existingProduct if provided it means the sheetProduct is not a new product, and this is the product that the sheetProduct is going to update
 * @param opts.date the date to use for createdAt and updatedAt for existing product
 *
 * @return Product
 */
module.exports = (sheetProduct, opts = {}) => {
  opts = Object.assign({
    existingProduct: {},
    date: new Date().toISOString()
  }, opts)
  const product = Object.assign({}, opts.existingProduct, sheetProduct)
  if (product._id) {
    product._id = product._id.toLowerCase()
  }
  // sheet.prices column holds just the current price if changed unlike the
  // original product.prices which is an array of prices with date
  const price = toNumber(get(sheetProduct, 'prices.price'))
  const maxPrice = toNumber(get(sheetProduct, 'prices.maxPrice'))

  product.prices = cloneDeep(get(opts, 'existingProduct.prices', []))
  const currentPrice = Number(getCurrentPrice(product.prices))
  const currentMaxPrice = Number(getCurrentPrice(product.prices, 'maxPrice'))

  const newPrices = {maxPrice: currentMaxPrice, price: currentPrice, date: opts.date}
  let priceChanged = false

  if (price && price !== currentPrice) {
    newPrices.price = price
    priceChanged = true
  }

  if (maxPrice && maxPrice !== currentMaxPrice) {
    newPrices.maxPrice = maxPrice
    priceChanged = true
  }

  if (priceChanged) {
    product.prices.push(newPrices)
  }

  // the buyPrice column in the products import sheet contains the current buy price
  // whereas the original product.buyPrices is an array of buy prices and dates
  const buyPriceFromSheet = toNumber(get(sheetProduct, 'buyPrices.buyPrice'))
  product.buyPrices = cloneDeep(get(opts, 'existingProduct.buyPrices', []))
  const currentProductBuyPrice = Number(getCurrentPrice(product.buyPrices, 'buyPrice'))

  const newBuyPrices = {buyPrice: currentProductBuyPrice, date: opts.date}
  let buyPriceChanged = false

  if (buyPriceFromSheet && buyPriceFromSheet !== currentProductBuyPrice) {
    newBuyPrices.buyPrice = buyPriceFromSheet
    buyPriceChanged = true
  }

  if (buyPriceChanged) {
    product.buyPrices.push(newBuyPrices)
  }

  product.vats = getVATs(sheetProduct, opts)
  // for existing product use the createdAt and update just the updatedAt
  product.createdAt = opts.existingProduct.createdAt || opts.date
  product.updatedAt = opts.date
  product.createdBy = opts.existingProduct.createdBy || opts.username
  product.updatedBy = opts.username

  product.services = opts.existingProduct.services || []
  // keep track of additionalData
  product.additionalData = Object.assign(
    {},
    (opts.existingProduct.additionalData || {}),
    product.additionalData
  )

  return Object.assign(
    {},
    product,
    withOptionalFields(sheetProduct)
  )
}

function withOptionalFields (sheetProduct) {
  const optionalTransforms = {
    tracer: p => p.tracer && p.tracer.toLowerCase() === 'true',
    unitOfIssue: p => toNumber(p.unitOfIssue),
    unitOfReporting: p => toNumber(p.unitOfReporting),
    genericFactor: p => toNumber(p.genericFactor),
    genericParent: p => p.genericParent.toUpperCase(),
    unitWeight: p => toNumber(p.unitWeight),
    unitVolume: p => toNumber(p.unitVolume),
    unitPrice: p => toNumber(p.unitPrice),
    alias: sanitizeOneItemIds
  }

  return Object.keys(optionalTransforms)
    .reduce((acc, field) => {
      if (sheetProduct[field] !== undefined && optionalTransforms[field]) {
        acc[field] = optionalTransforms[field](sheetProduct)
      }
      return acc
    }, {})
}

// heads up: ONE is hard coded here as the default alias
function sanitizeOneItemIds (product) {
  const foundAliases = product.alias !== 'none' ? product.alias.split(',') : []
  // remove dups
  const aliases = [...new Set(foundAliases)]
  return {one: aliases}
}

// get the history array of value added tax
// works like prices array or buy prices array
function getVATs (sheetProduct, opts) {
  const vats = cloneDeep(get(opts, 'existingProduct.vats', []))
  const currentProductVAT = Number(getCurrentPrice(vats, 'vat'))
  const vatFromSheet = toNumber(get(sheetProduct, 'vats.vat'))
  if (vatFromSheet && vatFromSheet !== currentProductVAT) {
    vats.push({vat: vatFromSheet, date: opts.date})
  }

  return vats
}
