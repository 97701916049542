import React, { Component } from 'react'
import flowRight from 'lodash/flowRight'

import withMasterData from './../common/withMasterData'
import withShipment from './../common/WithShipmentWrapper'

import DeleteShipment from './DeleteShipment'

import withConfig from '../../../van-shared/hoc/withConfig'
import { withApi } from '../../../common/ApiProvider'

class DeleteShipmentContainer extends Component {
  state = {
    deleting: false
  }

  onConfirmDelete = () => {
    const {history, shipment, api} = this.props
    this.setState({deleting: true}, async () => {
      await api.shipment.markVoid(shipment.id)
      history.push(`/shipments`)
    })
  }

  render () {
    const {
      shipment,
      history,
      location
    } = this.props
    const backToOverview = location.search.includes('backToOverview=true')

    return (
      <DeleteShipment
        deleting={this.state.deleting}
        shipment={shipment}
        history={history}
        onConfirmDelete={this.onConfirmDelete}
        backToOverview={backToOverview}
      />
    )
  }
}

const withHOCs = flowRight(
  withApi,
  withConfig,
  withMasterData,
  withShipment
)

export default withHOCs(DeleteShipmentContainer)
