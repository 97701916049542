const get = require('lodash/get')

// all of these functions are targeting for 4 months of stock for
// the facility based on previous stock counts
exports.getQuantityToOrder = getQuantityToOrder
function getQuantityToOrder (programId, report, product) {
  const defaultQto = defaultAdjusted
  const programFunctions = {
    'program:family-planning': defaultAdjusted,
    'program:hiv-aids': defaultAdjusted,
    'program:malaria': malariaAdjusted,
    'program:tb': defaultAdjusted
  }
  if (!programFunctions[programId]) {
    console.warn(
      `no qto function found for program ${programId}, using default.`
    )
  }
  const qtoFunction = programFunctions[programId] || defaultQto
  return qtoFunction(report, product)
}

function defaultAdjusted (report, product) {
  const consumed = get(report, `stock.${product._id}.fields.field:standard-consumed.amount`, 0)
  const stockOnHand = get(report, `stock.${product._id}.fields.field:standard-physical-count.amount`, 0)
  const comment = get(report, `stock.${product._id}.fields.field:standard-remark.amount`, undefined)
  // we multiply by 2 as the PSM reporting cycle is bimonthly and
  // 4 months of stock are the target
  const quantity = consumed * 2 - stockOnHand
  const comments = comment ? [getCommentObject(comment, report)] : []
  const qto = roundQuantity(quantity)
  return {qto, soh: stockOnHand, consumed, comments}
}

function malariaAdjusted (report, product) {
  const consumedField = `stock.${product._id}.fields.field:standard-consumed.amount`
  const consumed = get(report, consumedField, 0)
  const daysOutOfStock = get(report, `stock.${product._id}.fields.field:custom-days-out-of-stock.amount`, 0)
  const stockOnHand = get(report, `stock.${product._id}.fields.field:standard-physical-count.amount`, 0)
  const comment = get(report, `stock.${product._id}.fields.field:standard-remark.amount`, undefined)
  if (daysOutOfStock === 60) {
    return 0
  }

  const adjustedConsumption = (consumed / (60 - daysOutOfStock)) * 60
  // we multiply by 2 as the PSM reporting cycle is bimonthly and
  // 4 months of stock are the target
  const quantity = adjustedConsumption * 2 - stockOnHand
  const comments = comment ? [getCommentObject(comment, report)] : []
  const qto = roundQuantity(quantity)
  return {qto, soh: stockOnHand, consumed: adjustedConsumption, comments}
}

function getCommentObject (text, report) {
  const { createdAt, createdBy } = report
  return {
    createdAt,
    createdBy,
    text
  }
}

function roundQuantity (quantity) {
  return Math.ceil(
    Math.max(quantity, 0)
  )
}
