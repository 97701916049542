const PGAdapter = require('./../common/pg-adapter')
const fs = require('fs')
const path = require('path')

class CostBasisPGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    super(pgConnection, 'real.cost_basis', username, ['location_sku', 'date'], 'location_sku')
    this.logger = logger
  }

  /*
    cost basis is calculated by iteratively building a model of clients' shelves
    using daily buyprices from buypricedaily and deliveries tables. this is not strictly
    overloads the `POST` method against this endpoint to delete any cost basis rows that
    might exist for the {date, sku} and recalculate them using the latest buyprices
  */
  async create ({sku, date}) {
    this.logger.debug('regenerating cost_basis')
    if (sku && date) {
      await this.pgConnection.query(`
      DELETE FROM ${this.tableName} 
      WHERE date >= $1 
        and location_sku in (
          select distinct location_sku
          from real.deliveries
          where sku = $2);`, [date, sku])
    }

    const response = []

    // these scripts are run on every pipeline run also so referencing
    // them here rather than duplicating
    const scripts = [
      'api/sql/cost_basis/cost_basis_update_prep.sql',
      'api/sql/cost_basis/cost_basis_update.sql',
      'api/sql/cost_basis/cost_basis.sql',
      // regenerate tables which will have been cascade dropped
      'api/sql/price_calculator/held.sql',
      'api/sql/price_calculator/suggestions.sql',
      'api/sql/price_calculator/summary.sql',
      'api/sql/price_calculator/tiers.sql'
    ]

    for (let i = 0; i < scripts.length; i++) {
      const buffer = await fs.promises.readFile(path.resolve(__dirname, '../../../' + scripts[i]))
      const query = buffer && buffer.toString()
      buffer && response.push(await this.pgConnection.query(query))
    }
    this.logger.debug('done regenerating cost_basis')
    // todo: sku is a bogus return value needed by RA. should update the data-provider there
    // not return junk here
    return { id: sku || '', count: response.reduce((a, c) => a + (c.rowCount || 0), 0) }
  }
  async delete () {
    throw new Error('buyprice_daily cannot be modified directly')
  }
  async update () {
    throw new Error('buyprice_daily cannot be modified directly')
  }
}

module.exports = {CostBasisPGAdapter}
