exports.createShipmentSnapshots = function ({acceptedSnapshots, funderId, productsList = []}) {
  return acceptedSnapshots.map(
    orderSnapshot => createShipmentSnapshot({orderSnapshot, funderId, productsById: getProductsById(productsList)})
  )
}

function getProductsById (productsList) {
  return productsList
    .reduce((acc, product) => {
      acc[product._id] = product
      return acc
    }, {})
}

exports.createReceivedShipmentSnapshot = function ({orderSnapshot, productQuantities, productsList = []}) {
  const productsById = getProductsById(productsList)
  return createReceivedShipmentSnapshot({orderSnapshot, productQuantities, productsById})
}

function createDetails (orderSnapshot, funderId) {
  return {
    origin: orderSnapshot.supplierId,
    destination: orderSnapshot.destinationId,
    // VAN store api expects YYYY-MM-DD
    date: orderSnapshot.createdAt.split('T')[0],
    overrideCreatedAt: orderSnapshot.createdAt,
    status: 'new',
    // this is so we can see allocation:{parentFunderId}
    // vs. funderId: actual location sub funder
    shipmentTypeId: `allocated:${funderId}`,
    funderId: orderSnapshot.funderId,
    programId: orderSnapshot.programId,
    orderId: orderSnapshot._id,
    totalAmount: orderSnapshot.totalAmount
  }
}

function createShipmentSnapshot ({orderSnapshot, funderId, productsById}) {
  const details = createDetails(orderSnapshot, funderId)
  const counts = createShipmentCounts({orderSnapshotProducts: orderSnapshot.products, productsById})
  return Object.assign({}, details, { counts })
}

function createShipmentCounts ({orderSnapshotProducts, productsById}) {
  return Object.keys(orderSnapshotProducts)
    .reduce((acc, productId) => {
      const productDetails = orderSnapshotProducts[productId]
      const {genericFactor = 1} = productsById[productId] || {}
      Object.keys(productDetails.batches).forEach(batchId => {
        const quantity = productDetails.batches[batchId].original / genericFactor
        acc[batchId] = {quantity}
      })

      return acc
    }, {})
}

function createReceivedShipmentSnapshot ({orderSnapshot, productQuantities, productsById}) {
  const details = createDetails(orderSnapshot, orderSnapshot.funderId)
  const counts = createReceiveShipmentCounts(productQuantities, productsById)
  return Object.assign({}, details, { counts })
}

function createReceiveShipmentCounts (productQuantities, productsById) {
  return Object.keys(productQuantities)
    .reduce((acc, productId) => {
      const productDetails = productQuantities[productId]
      const {genericFactor = 1} = productsById[productId] || {}
      Object.keys(productDetails.batches).forEach(batchId => {
        const quantity = productDetails.batches[batchId].amount / genericFactor
        acc[batchId] = {quantity, checked: true}
      })

      return acc
    }, {})
}
