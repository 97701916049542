import { createElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Heading from './Heading'

// Extends Component for propTypes to show up in storybook,
// which they otherwise won't when the component starts with
// createElement
class Text extends Component {
  render () {
    const {
      children,
      tag,
      size,
      tight,
      weight,
      bold,
      color,
      uppercase,
      italic,
      display,
      className,
      ...otherProps
    } = this.props

    return createElement(
      tag,
      {
        className: classnames(
          'vs-text',
          {'vs-text--weight-regular': weight === 'regular' && !bold},
          {'vs-text--weight-bold': weight === 'bold' || bold},
          {'vs-text--size-tiny': size === 'tiny'},
          {'vs-text--size-xsmall': size === 'xsmall'},
          {'vs-text--size-small': size === 'small'},
          {'vs-text--size-standard': size === 'standard'},
          {'vs-text--size-large': size === 'large'},
          {'vs-text--size-xlarge': size === 'xlarge'},
          {'vs-text--color-light': color === 'light'},
          {'vs-text--color-brand': color === 'brand'},
          {'vs-text--color-warning': color === 'warning'},
          {'vs-text--tight': tight},
          {'vs-text--uppercase': uppercase},
          {'vs-text--italic': italic},
          {'vs-text--display': display},
          className
        ),
        ...otherProps
      },
      children
    )
  }
}

Text.propTypes = {
  /**
   * The child elements/content of the Text.
   * Can be text or other elements.
   */
  children: PropTypes.node.isRequired,
  /**
   * Alternative HTML tag for the text element
   */
  tag: PropTypes.string,
  /**
   * The size you want the text to render at
   */
  size: PropTypes.oneOf(['inherit', 'tiny', 'xsmall', 'small', 'standard', 'large', 'xlarge']),
  /**
   * Should this text render with a tight line-height?
   * This is recommended in narrow spaces, e.g. narrow columns.
   */
  tight: PropTypes.bool,
  /**
   * At what weight should the text render
   */
  weight: PropTypes.oneOf(['inherit', 'regular', 'bold']),
  /**
   * Just a shortcut to weight='bold'
   */
  bold: PropTypes.bool,
  /**
   * Uses display type of font
   * instead of standard one
   */
  display: PropTypes.bool,
  /**
   * Makes text uppercase
   */
  uppercase: PropTypes.bool,
  /**
   * Makes text italic
   */
  italic: PropTypes.bool,
  /**
   * Optionally set color, e.g. if text is used on a colored background
   */
  color: PropTypes.oneOf(['inherit', 'light', 'brand', 'warning']),
  /**
   * Any classname(s) to apply to the outer Page wrapper
   */
  className: PropTypes.string
}

Text.defaultProps = {
  tag: 'div',
  size: 'inherit',
  tight: false,
  weight: 'regular',
  color: 'inherit',
  display: false
}

Text.Heading = Heading

export default Text
