import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import flatMap from 'lodash/flatMap'
import { format } from 'date-fns'

import { userIsAuthorised } from '../../../van-shared/utils/auth'
import { isHeldLocation } from '@fielded/fs-api/lib/shipment/tools/is-held-location'
import { Button, UppercaseLabel } from '@fielded/shared-ui'
import { Adjust, Arrow, Checkmark } from '@fielded/shared-ui/src/icons'

import {
  STATUS_NOT_STARTED,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETE,
  STATUS_OFFLINE
} from '../common/report-status'

import ReportStatus from './report-status/ReportStatus'

import YourLocation from './your-location/YourLocation'

import {
  buildServicesUrl,
  buildViewReportUrl
} from '../app/routes-urls'

import LocationsListStatementDownload from './LocationsListStatementDownload'

const createListItemStatusControl = (status, canSubmit, target) => {
  switch (status) {
    case STATUS_OFFLINE:
    case STATUS_NOT_STARTED:
      if (canSubmit) {
        return (
          <Button
            component={Link}
            to={target}
            icon={<Arrow direction='right' />}
            iconSide='right'
            colorVariant='brand'
            fill='full'
          >
            Start report
          </Button>
        )
      } else {
        return <ReportStatus status={status} url={canSubmit ? target : null} />
      }
    case STATUS_IN_PROGRESS:
      if (canSubmit) {
        return (
          <Button
            component={Link}
            to={target}
            icon={<Arrow direction='right' />}
            iconSide='right'
            colorVariant='brand'
            fill='full'
          >
            Continue report
          </Button>
        )
      } else {
        return undefined
      }
    case STATUS_COMPLETE:
      return (
        <Fragment>
          <ReportStatus status={status} url={canSubmit ? target : null} />
        </Fragment>
      )
    default:
      return undefined
  }
}

const ChildLocationItem = ({
  id,
  target,
  canSubmit,
  status,
  services,
  submittedAt,
  name,
  programs,
  period,
  showStatementsDownload,
  showPartialCount,
  submitPartial
}) => {
  const reports = flatMap(services, service => {
    return service.progress.reports
      .filter(r => r.status === STATUS_COMPLETE)
      .map(r => ({ ...r, serviceId: service.id }))
  })
  sortBy(reports, 'submittedAt')
  /*
   * - PSM are be able to edit reports, so it should be true for them.
   * - SL has showPartialCount and therefore can not edit, the status_complete check is for SL.
   */
  const showSubmitButton = canSubmit && (status !== STATUS_COMPLETE || !showPartialCount)
  // For now: only display statement for the last report:
  const statementReport = reports[reports.length - 1]

  return (
    <li className='location-item-wrapper'>
      <div className='location-item'>
        <p className='location-item__text'>{name}</p>
        {submittedAt && (
          <p className='location-item__date'>Submitted {submittedAt}</p>
        )}
        {!submitPartial && createListItemStatusControl(status, showSubmitButton, target)}
        {submitPartial &&
          <Button
            component={Link}
            to={`${target}/partial`}
            icon={<Adjust />}
            iconSide='right'
            colorVariant='brand'
            fill='outline'
          >
            Adjust Count
          </Button>}

      </div>
      {showPartialCount && (reports.length || null) &&
        (<ul className='location-item__reports'>
          {reports.slice(0).reverse().map(report => {
            // Because of getOverview + PSM needs,
            // we don't have access to the entire report, just the view response
            // so we'll have to compromise a bit with what we can do:
            let reportLinkText = report.id.split(':date:')[1]
            if (reportLinkText) { // V2 id, multiple counts per period
              reportLinkText = `Submitted ${format(reportLinkText, 'd LLL hh:mma')}`
            } else { // V1 id, legacy reports
              // A bit awkward regex extracting the reporting period from the ID
              const periodMatch = report.id.match(/(:week:|:month:)(.+)?(?=:program:)/)
              const periodId = periodMatch ? periodMatch[2] : 'unmatched period'
              reportLinkText = `Submitted ${periodId}`
            }

            return (
              <li className='location-item__reports-item' key={report.id}>
                {report.status === STATUS_OFFLINE
                  ? (<small>Go online to view these reports</small>)
                  : (<small><Link to={buildViewReportUrl({ reportId: report.id })}><Checkmark /> {reportLinkText}</Link></small>)}
              </li>
            )
          })}
        </ul>)
      }
      {showStatementsDownload && statementReport && (
        <LocationsListStatementDownload
          statementReport={statementReport}
          period={period}
          locationId={id}
        />
      )}
    </li>
  )
}

const LocationsList = ({
  isEditablePeriod,
  locations,
  urlParams,
  user,
  period,
  showStatementsDownload,
  showPartialCount,
  alwaysSubmitPartials,
  showShipmentSyncBanner
}) => {
  const createUrl = locationId => buildServicesUrl({...urlParams, reportLocationId: locationId})

  const isExternalPlanner = userIsAuthorised(user, 'feature:userRole:external-planner')
  const yourLocation = locations.find(location => location._id === user.location.id)
  let childLocations = locations.filter(location => location._id !== user.location.id)

  if (isExternalPlanner) {
    childLocations = childLocations.filter(l => l.level !== 'supplier')
  }

  let listTitle = ''
  if (childLocations.length > 0) {
    let level = childLocations[0].level
    if (level === 'zone') { level = 'zonal' }
    listTitle += level + ' stores:'
  }

  const yourLocalSubmitPartials = yourLocation ? alwaysSubmitPartials && isHeldLocation(yourLocation._id) : false

  // showShipmentSyncBanner is used for psm
  // We do not want users to start reports whiles their shipment data is still being downloaded
  return (
    <div className='locations-list'>
      {yourLocation && (
        <div className='locations-list__your-location'>
          <YourLocation
            target={createUrl(yourLocation._id)}
            canSubmit={yourLocation.canSubmit && isEditablePeriod && !showShipmentSyncBanner}
            status={yourLocation.progress.status}
            lastSubmit={yourLocation.progress.lastSubmittedAt}
            name={yourLocation.fullName}
            level={yourLocation.level}
            submitPartial={yourLocalSubmitPartials || yourLocation.submitPartial}
          />
        </div>
      )}
      {childLocations.length > 0 && (
        <Fragment>
          <div className='locations-list__group-header'>
            <UppercaseLabel>
              {listTitle}
            </UppercaseLabel>
          </div>
          <ul className='locations-list__group'>
            {childLocations.map(location => {
              const heldAlwaysPartial = alwaysSubmitPartials && isHeldLocation(location._id)
              return location._id && (
                <ChildLocationItem
                  key={location._id}
                  id={location._id}
                  target={createUrl(location._id)}
                  programs={location.programs}
                  canSubmit={location.canSubmit && isEditablePeriod && !showShipmentSyncBanner}
                  status={location.progress.status}
                  services={location.progress.services || []}
                  submittedAt={location.progress.lastSubmittedAt}
                  name={location.fullName}
                  period={period}
                  showStatementsDownload={showStatementsDownload}
                  showPartialCount={showPartialCount}
                  submitPartial={heldAlwaysPartial || location.submitPartial}
                />
              )
            })}
          </ul>
        </Fragment>
      )}
    </div>
  )
}

export default LocationsList
