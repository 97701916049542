const {addSeconds} = require('date-fns')

module.exports = getToken
async function getToken ({apiKey, apiSecret, fetch, logger, endpoints}, getLatestToken, storeLatestToken) {
  let data

  try {
    data = await getLatestToken()
    // If we're past the expiry we need a new token.
    // (this EXPIRY_BUFFER_MS means we should assume that even if it's not for another 100 ms,
    // we're too close to the expiry and should grab another token.
    const EXPIRY_BUFFER_MS = 100
    if ((new Date(data.expiry) - new Date()) > EXPIRY_BUFFER_MS) {
      logger.info('Using found and still valid token')
      return data.access_token
    } else {
      logger.info('existing token is expired, fetching new one')
    }
  } catch (error) {
    logger.info('No stored tokens found, fetching new ones.')
  }

  const config = {
    headers: {
      'Authorization': `Basic ${Buffer.from(`${apiKey}:${apiSecret}`).toString('base64')}`
    }
  }

  const response = await fetch(endpoints.getToken, config)

  if (!response.ok) {
    logger.warn('Error fetching new token, cannot proceed', response.status, response.statusText)
    return
  }

  try {
    const body = await response.json()
    const expiry = addSeconds(new Date(), body.expires_in)
    const payload = {
      expiry: expiry,
      access_token: body.access_token,
      apiKey
    }
    await storeLatestToken(payload)
    return body.access_token
  } catch (e) {
    logger.warn('error caught in getting access token', e)
  }
}
