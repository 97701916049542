const get = require('lodash/get')

/*
 * Unit Price is a PSM thing
 * where we have a price for a 'warehouse unit' which is sometimes larger than the reporting unit
 * https://github.com/fielded/field-supply/issues/3773#issuecomment-875527860
 */
module.exports = (productDoc = {}) => {
  const factor = get(productDoc, 'genericFactor', 1)
  const price = get(productDoc, 'unitPrice')

  if (!price) {
    return price
  }

  return price / factor
}
