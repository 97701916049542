const { addMinutes, subMinutes, format } = require('date-fns')

// This contains functions to help with timezone issues when calculating
// period dates. Since we can only do date calculations in local time with
// `date-fns`, we sometimes need to add or subtract the local timezone offset
// of a date to pretend that the local timezone is the timezone we want
// to do calculations in.
//
// We currently just convert between UTC and local time, since the period is
// defined to start at 00:00:00 UTC and end at 23:59:59 UTC.
//
// NB: js `date.getTimezoneOffset()` will return a negative value when the
//     date is ahead of UTC, e.g. a date in UTC+1 will return -60(minutes).
//     In order to shift the date to UTC we add the negative offset.

const subTZ = date => {
  date = new Date(date)
  return addMinutes(date, date.getTimezoneOffset())
}
exports.subTZ = subTZ

const addTZ = date => {
  date = new Date(date)
  return subMinutes(date, date.getTimezoneOffset())
}
exports.addTZ = addTZ

const formatTZ = (date, pattern) => {
  // Subtract the timezone so that the local date has the day and time of the target tz utc.
  return format(subTZ(date), pattern)
}
exports.formatTZ = formatTZ
