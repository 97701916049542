/**
 * Creates a new price object holding original value, current price and applied discount.
 * In the end all the price metainfo is handled by UI components that may want to render original prices and/or discounts
 * e.g. by crossing out the original price and showing the current one to the side
 *
 * @param {*} price Actual/Final selling price. This is the price for the invoice and to be presented as current price
 * @param {*} discount Discount percentage, so if price===100 and discount===23 (that's a 23% discount) the original calculated price would be 129.87
 * @param {*} discountPrice Same as price. Added to allow Pouch save stock count drafts where it can't save a function
 * @returns an object that can be treated as a regular number or string depending on the context thanks to the valueOf and toString methods it contains.
 */

const makeDiscountedPrice = function (price, discount) {
  const originalPrice = price / (1 - (discount / 100))
  const discountPrice = price
  return {
    originalPrice,
    discount,
    discountPrice,
    valueOf () {
      return price
    },
    toString () {
      return this.valueOf().toString()
    }
  }
}

module.exports = {
  makeDiscountedPrice
}
