import { simpleThrottle } from './utils'

export default {
  initPendingUpload,
  getPendingUploadCount
}

function initPendingUpload (syncFeed) {
  const db = this
  // This is throttled to avoid race conditions
  // Where an earlier update would write over a newer one
  const updateFunc = simpleThrottle(info => {
    updatePendingCount(db, info.update_seq || info.last_seq)
  }, 500)

  syncFeed
    .on('change', updateFunc)
    .on('paused', err => {
      if (!err) {
        db.info().then(updateFunc)
      }
    })
}

const COUNTER_DOC_ID = '_local/last_pushed_seq_no'

function getPendingUploadCount () {
  const db = this
  return getOrCreateLocalDoc(db, COUNTER_DOC_ID).then(doc => {
    const options = doc.last_seq ? { since: doc.last_seq } : {}
    return db.changes(options).then(changes => {
      return {
        pending: changes.results.length,
        changes
      }
    })
  })
}

function updatePendingCount (db, seq) {
  getOrCreateLocalDoc(db, COUNTER_DOC_ID).then(doc => {
    doc.last_seq = seq
    db.put(doc)
  })
}

function getOrCreateLocalDoc (db, counterDocId) {
  return db.get(counterDocId).catch(() => {
    return db.info().then(info => {
      const doc = { _id: counterDocId, last_seq: info.update_seq }
      return db.put(doc).then(response => {
        doc._rev = response.rev
        return doc
      })
    })
  })
}
