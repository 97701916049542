const tools = require('../../tools')
const {packPointRationing} = require('../../tools/pack-point-rationing')

exports.createForLocation = createForLocation
async function createForLocation (
  state,
  mainApi,
  programId,
  locationId,
  deliveryDate,
  products,
  orderType,
  {dryRun = false, groupId, dryRunPackPointRation = false} = {}
) {
  if (!locationId || !deliveryDate || !products || !orderType) {
    throw new Error(`locationId, products deliveryDate, orderType are required params`)
  }
  // I could not find a way to validate date input by format with our date fns
  // 1.2.9
  if (isNaN(new Date(deliveryDate))) {
    throw new Error(`Given delivery date ${deliveryDate} is not valid.`)
  }

  const location = await mainApi.location.get(locationId)

  const allocation = {
    location,
    products
  }

  const allocationsWithSupplierId = await packPointRationing(state, mainApi, { allocations: [allocation], locationsWithSubscriptions: [location], deliveryDate, orderType, dryRun: dryRunPackPointRation })

  const snapshots = tools.createNewOrders({
    programId,
    allocations: allocationsWithSupplierId,
    user: state.user,
    deliveryDate,
    groupId,
    orderType,
    doNotAddUpdateParams: true
  })

  if (dryRun) return snapshots

  await state.dal.order.bulkDocs(state, snapshots)

  return tools.docsToOrderEntities({docs: snapshots, status: 'new', withSnapshots: true})
}
