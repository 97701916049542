import React, { Component } from 'react'
import { Link, BrowserRouter as Router } from 'react-router-dom'

import { Loading } from '@fielded/shared-ui'
import { configure as configurePage } from '@fielded/shared-ui/src/components/Page'

import { withUser } from '../../../common/AuthenticationProvider'
import OfflineLoadingError from '../common/OfflineLoadingError'

import Routes from './Routes'

export class AppContainer extends Component {
  constructor () {
    super()
    this.state = { initialised: false, error: null, isAuthenticated: false }
  }

  // We were previously fetching the master data within `componentDidMount`,
  // this can't work anymore cause the relevant master data depends on the service
  // and the service is not known on initialization
  componentDidMount () {
    const {
      user
    } = this.props
    configurePage({
      // There was a small misunderstanding with Babel here,
      // it created a _this context for the fat arrow function,
      // which kept the AppContainer in memory as long as nothing else called configurePage
      linkRenderer: function (props) { return (<Link {...props} />) }
    })

    if (user && user.location && user.location.id) {
      this.setState({ isAuthenticated: true })
      // v-a-s will not initialize our root component without first downloading all data.
      this.setState({ initialised: true })
    }
  }

  render () {
    const {
      initialised,
      error,
      isAuthenticated
    } = this.state

    if (!isAuthenticated) {
      return <p>Login</p>
    }

    if (!initialised) {
      return <Loading />
    }

    if (error) {
      return (
        <OfflineLoadingError error={error} />
      )
    }
    return (
      <Router {...this.props.routerParams}>
        <Routes
          api={this.props.api}
          rootHistory={this.props.rootHistory}
          showCountSyncBanner={this.props.showCountSyncBanner}
          showShipmentSyncBanner={this.props.showShipmentSyncBanner}
        />
      </Router>
    )
  }
}

export default withUser(AppContainer)
