module.exports = normalisePrograms
function normalisePrograms (user) {
  if (user.programs) {
    return user.programs
  }

  const isVanUser = user.roles && user.roles.find(role => role === 'van-user')
  // for van users without programs
  if (isVanUser) {
    return ['program:immunization']
  }
  const isShelfLifeUser = user.roles && user.roles.find(role => role === 'shelflife-user')
  // for shelflife users without programs
  if (isShelfLifeUser) {
    return ['program:shelflife']
  }

  // worse case scenario
  return []
}
