const sortBy = require('lodash/sortBy')
const { periodIdToDate, isV1Id } = require('../../report/tools/ids')
const { listChildren: listLocationChildren } = require('../../location')
const findForLocation = require('../../report/api/find-for-location')
const findShipment = require('../../shipment/shipment-find')
const findShipmentAdjustments = require('../../shipment/shipment-find-adjustments')
const { getRelevantSnapshotDate } = require('../tools')
const { parse } = require('../../tools/smart-id')

// Use createdAt for V1 ids
// (because we used to set back submittedAt in these days)
// this is just to maintain compatibility if we would re-run pre-2021 events
// that were using V1 ids (only one count per period)
const getDate = count => isV1Id(count._id) ? count.createdAt : count.submittedAt

// TODO: this is duplicated
const isAdjustmentRegexp = /:adjustment:/
const isShipmentAdjustment = item => isAdjustmentRegexp.test(item.id)

// get all stock counts and shipments from couch
// this is basically unmodified taken from previous version
const fetchData = async ({
  state,
  locationId, serviceId, period,
  service, locations,
  includeChildren, ignoreService
}) => {
  const startDate = periodIdToDate(period)

  if (!locations) {
    locations = await listLocationChildren(
      state,
      locationId,
      {
        includeSelf: true,
        date: startDate.toJSON(),
        filters: { services: [serviceId] }
      }
    )
  }

  const locationsMap = new Set(locations.map(l => l._id))
  const location = locationsMap[locationId]

  const stockCountsResult = await findForLocation(state, {
    location,
    locationId,
    service,
    serviceId,
    startDate: startDate.toJSON(),
    includeChildren,
    ignoreService,
    entityOptions: {
      addFields: true
    }
  })
  const stockCounts = sortBy(stockCountsResult, ['submittedAt'])

  const indexedStockCounts = stockCounts.reduce((acc, stockCount) => {
    const locationId = stockCount.location.id
    acc[locationId] = acc[locationId] || {}

    // Note: use reporting period here, not submittedAt
    // since we added adjustments, submittedAt can be after end of reporting period
    // but it should still belong to a certain week.
    if (stockCount.date.reportingPeriod === period) {
      // With V2, we might have more than one stock count
      acc[locationId].current = acc[locationId].current || []
      acc[locationId].current.push(stockCount)

      if (!acc[locationId].startdate) { // use the earliest startdate for the cycle
        acc[locationId].startdate = getDate(stockCount)
      }
    } else if (stockCount.date.reportingPeriod > period) {
      // This finds the first report in a period after the exported one
      // this marks the end of the reporting cycle
      if (!acc[locationId].enddate || stockCount.submittedAt < acc[locationId].enddate) {
        acc[locationId].enddate = getDate(stockCount)
      }
    }

    return acc
  }, {})

  // console.log('indexed stock counts', indexedStockCounts)

  const reportRows = {}

  // Now we need to merge all stock counts from 'current week' into one per location
  Object.keys(indexedStockCounts).forEach(locationId => {
    const currentCounts = indexedStockCounts[locationId].current

    // Only process locations that have a reporting cycle starting in this week
    // (cycle = distance between 2 stock counts)
    if (!currentCounts || !currentCounts.length) {
      return
    }

    reportRows[locationId] = {
      // We need to keep all counts from current period available to determine buy-out date #1106
      current: currentCounts,
      location: locationsMap[locationId],
      deliveryCycle: currentCounts[0].date,
      enddate: indexedStockCounts[locationId].enddate,
      startdate: indexedStockCounts[locationId].startdate
    }
  })
  // console.log('pure report rows', reportRows)

  const locationIdsForExport = Object.keys(reportRows)

  await Promise.all(
    locationIdsForExport
      .map(async (locationId) => {
        const { startdate, enddate = null } = reportRows[locationId]

        // We are looking for shipments made after the period stock report,
        // but the api will return all shipments that were modified within that cycle
        // we need filter the shipments by date manually afterwards.
        let shipments = await findShipment(state, {
          location: locationId,
          startdate,
          enddate
        }, {checkSnapShotLocation: true})

        const statusIds = {}
        const shipmentStatuses = !shipments
          ? []
          : shipments.reduce((acc, val) => {
            statusIds[val.id] = {}
            acc[val.id] = Object.keys(val.history).map(id => {
              const { status } = parse(id)
              statusIds[val.id][status] = id
              return status
            })
            return acc
          }, {})

        shipments = shipments
          .map(s => {
            const isOrigin = s.origin.id === locationId
            const statusHistory = shipmentStatuses[s.id]

            // in case when packed & sent snapshots exist, packed snapshots take presedence over the sent snapshots for the origin location
            // we ought to use their counts as it's what updates the ledger when they exist in fs
            if (isOrigin && s.status === 'sent' && statusHistory.includes('packed')) {
              const packedSnapshotId = statusIds[s.id].packed
              s.status = 'packed'
              s.counts = s.history[packedSnapshotId].counts
            }
            return s
          })
          .filter(s => {
            const isOrigin = s.origin.id === locationId
            const isDestination = s.destination.id === locationId
            const statusHistory = shipmentStatuses[s.id]

            // Get the relevant snapshot date
            const relevantSnapshotDate = getRelevantSnapshotDate(isOrigin, s)

            // If the location is the shipment origin and packed snapshot exist, take packed snapshot into consideration
            // If the location is the shipment origin and no packed snapshot exist, take sent snapshot into consideration
            // If the location is the shipment destination, only take received snapshot into consideration
            return (
              (isDestination && s.status === 'received') ||
              (isOrigin && s.status === 'sent' && !statusHistory.includes('packed')) ||
              (isOrigin && s.status === 'packed')
            ) &&
              (relevantSnapshotDate > startdate) &&
              (!enddate || relevantSnapshotDate < enddate)
          })

        // this call is to find adjustments that were created in this
        // period. This is used for invoicing.
        // To read adjustments that were effective in this period,
        // which is needed for forecasting, we use the 'changes' array
        // on the shipments themselves
        let shipmentAdjustments = await findShipmentAdjustments(state, {
          location: { id: locationId },
          startdate,
          enddate
        })

        // Ignore adjustments where the adjustment was created within this cycle:
        // This is because we assume those to be applied to the opening value of next cycle already:
        // (therefore they are assumed to be included in the invoicing already)
        // https://docs.google.com/document/d/1sFCu7P-bAiHqg2ZcdwendyQMQI01Xi77QxzT83sFm9c/edit#heading=h.aqwf6bu6ni77
        shipmentAdjustments = shipmentAdjustments.filter(change => {
          return change.effectiveAt <= startdate && (!enddate || change.createdAt <= enddate)
        })

        // We need to pick up the shipments that were adjusted so we can check the delivery type
        shipmentAdjustments = await Promise.all(shipmentAdjustments.map(async adjustment => {
          const shipments = await findShipment(state, adjustment.id.split(':status:')[0])
          return {
            ...adjustment,
            // will only be one when we search by id,
            // but the api returns an array
            shipment: shipments[0]
          }
        }))

        const earliestAdjustment = sortBy(shipmentAdjustments, ['effectiveAt'])[0]

        let adjustmentCounts = []
        if (earliestAdjustment) {
          // Find all reports between the earliest adjustment
          // and the current cycle, we'll need this in invoice-adjustments later
          // fielded/fiels-supply#1106
          adjustmentCounts = await findForLocation(state, {
            location: locationsMap[locationId],
            locationId,
            service,
            serviceId,
            startDate: earliestAdjustment.effectiveAt,
            endDate: startdate, // we already have all reports after this date
            includeChildren: false,
            entityOptions: { raw: true }
          })

          adjustmentCounts = sortBy(adjustmentCounts, ['submittedAt'])
        }

        reportRows[locationId] = Object.assign(reportRows[locationId], {shipments, locationId, shipmentAdjustments, adjustmentCounts})
      })
  )

  return Object.values(reportRows).flatMap(reportsMap => {
    const events = reportsMap.current.concat(reportsMap.shipments).concat(reportsMap.shipmentAdjustments)
    const sorter = item => {
      if (item.type === 'stockCount') {
        return item.submittedAt
      }

      if (isShipmentAdjustment(item)) {
        return item.createdAt
      }

      if (item.shipmentNo) {
        return item.snapshotDates.received
      }

      throw new Error(`Unknown item: ${item._id}`)
    }

    const sortedEvents = sortBy(events, [sorter])

    return sortedEvents.map(entry => ({ ...reportsMap, entry }))
  })
}

module.exports = {
  fetchData
}
