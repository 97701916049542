const { listPrograms } = require('../../program/api')
const getPeriodFromProgram = require('../tools/get-period-from-program')
const { getPeriodDefinitionFromProgram } = require('../tools/get-period-from-program')
const getPreviousPeriodFromProgram = require('../tools/get-previous-period')
const getNextPeriodFromProgram = require('../tools/get-next-period')
const { periodIdToDate } = require('../tools/ids')
//
// Reporting period structs look like this:
//
// {
//   "definition": {
//     "periodType": {
//       "unit": "week",
//       "quantity": 1
//     },
//     "dueDateOffset": {
//       "unit": "day",
//       "quantity": 5
//     },
//     "entryPeriodRule": "current-period"
//   },
//   "id": "2019-W28",
//   "display": "Week 2019 / 28",
//   "effectiveStartDate": "2019-07-07T22:00:00.000Z",
//   "effectiveEndDate": "2019-07-14T21:59:59.999Z",
//   "entryStartDate": "2019-07-07T22:00:00.000Z",
//   "entryEndDate": "2019-07-14T21:59:59.999Z",
//   "entryDueDate": "2019-07-12T21:59:59.999Z",
//   "isEditable": true
// }
//
// The dates, e.g. effectiveStartDate, are date objects. The dates are calculated
// in local time, which means that a period always starts at zero a clock in the morning
// local time and ends 1ms before midnight local time.
//
// The definition configuration is set on the program documents
//

const findProgram = async (state, {program, programId}) => {
  if (program) {
    return program
  }
  if (!programId) {
    throw new Error('Pass either program or programId option')
  }
  const programs = await listPrograms(state)
  return programs.find(p => p.id === programId)
}

exports.getPeriodDefinition = getPeriodDefinition
async function getPeriodDefinition (state, {
  program = null, // either program or programId is required
  programId = null // see above
}) {
  program = await findProgram(state, {program, programId})
  return getPeriodDefinitionFromProgram(program)
}

// Get a reporting period
exports.getPeriod = getPeriod
async function getPeriod (state, {
  program = null, // either program or programId is required
  programId = null, // see above
  date, // a date inside the period
  isEffectiveDate = false // whether the date is in the effective range or the entry range
}) {
  date = new Date(date)
  program = await findProgram(state, {program, programId})
  return getPeriodFromProgram(program, date, isEffectiveDate)
}

// Get a full reporting period object from a period ID
exports.getPeriodFromId = getPeriodFromId
async function getPeriodFromId (state, {
  program = null, // either program or programId is required
  programId = null, // see above
  periodId
}) {
  if (!periodId) {
    throw new Error('You need to pass in periodId')
  }

  program = await findProgram(state, {program, programId})
  const date = periodIdToDate(periodId)
  // Always look at effective date here
  return getPeriodFromProgram(program, date, true)
}

exports.getNextPeriod = getNextPeriod
async function getNextPeriod (state, {
  program, // either program or programId is required
  programId, // see above
  period // the current period
}) {
  program = await findProgram(state, {program, programId})
  return getNextPeriodFromProgram(program, period)
}

exports.getPreviousPeriod = getPreviousPeriod
async function getPreviousPeriod (state, {
  program, // either program or programId is required
  programId, // see above
  period // the current period
}) {
  program = await findProgram(state, {program, programId})
  return getPreviousPeriodFromProgram(program, period)
}
