const XLSX = require('@sheet/core')
const { DEFAULT_COLUMNS, getRows } = require('../tools')

const SHEET_NAME = 'products'

const encode = (products, opts = {}) => {
  const { rows, headers } = getRows(products, opts)
  const ws = XLSX.utils.aoa_to_sheet([
    headers,
    ...rows
  ])

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, SHEET_NAME)
  return XLSX.write(wb, {type: 'array', compression: true})
}

const decode = buffer => {
  if (!buffer) {
    throw new Error('buffer parameter is required')
  }

  const wb = XLSX.read(buffer, {type: 'array'})
  const ws = wb.Sheets[SHEET_NAME]

  if (!ws) {
    throw new Error(`Worksheet '${SHEET_NAME}' not found`)
  }

  return XLSX.utils.sheet_to_json(ws)
}

// convert sheet row to actual product doc based on the column titles
const sheetToDoc = (sheetProduct, additionalColumns = {}) => {
  const columns = Object.assign({}, DEFAULT_COLUMNS, additionalColumns)
  return Object.keys(columns).reduce((doc, key) => {
    const column = columns[key]
    const title = typeof column === 'string' ? column : column.title
    // filter of null or undefined inputs
    if (sheetProduct[title]) {
      doc[key] = sheetProduct[title]
    }
    return doc
  }, {})
}

module.exports = {
  encode, decode, SHEET_NAME, sheetToDoc
}
