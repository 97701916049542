import React from 'react'
import {numberWithCommas} from './utils'

/*

Qty

Takes a number as props.quantity and formats it with thousand-separators.
Source: https://stackoverflow.com/a/2901298

*/

const Qty = ({ quantity, className }) => {
  let formattedQuantity = null
  if (typeof quantity === 'undefined' || isNaN(quantity) || quantity === null) {
    formattedQuantity = '-'
  } else {
    formattedQuantity = numberWithCommas(quantity)
  }

  return <span className={className}>{formattedQuantity}</span>
}

export default Qty
