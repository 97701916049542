const { getOrderExportData } = require('./data')
const { buildWorkbook, createWorksheetData } = require('./excel')

// This returns an excel file thing and is called by the UI
async function exportLMD (state, programId) {
  const result = await exportLMDData(state, programId)
  return buildWorkbook(result)
}

// This returns the data used in the above function as objects & is used in tests.
async function exportLMDData (state, programId) {
  const data = await getOrderExportData(state, programId)
  return createWorksheetData(data)
}

module.exports = {exportLMD, exportLMDData}
