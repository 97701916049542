const batchToProduct = require('./product-batch-util').batchIdToProductId

module.exports = shipmentByProduct

function shipmentByProduct (shipments, params) {
  const ignoreUnchecked = (params || {}).ignoreUnchecked

  if (!Array.isArray(shipments)) {
    shipments = [shipments]
  }

  return shipments.reduce((acc, shipment) => {
    // See fixtures/van-shipments for example of how an incoming shipment looks
    Object.keys(shipment.counts).forEach((key) => {
      // We get a batch id on the shipment doc
      const productId = batchToProduct(key)
      acc[productId] = (acc[productId] || 0)

      if (!ignoreUnchecked || shipment.counts[key].checked) {
        acc[productId] += shipment.counts[key].quantity
      }
    })

    return acc
  }, {})
}
