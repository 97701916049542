module.exports = {
  id: 'https://schema.field.partners/1.0/van-batch-id#',
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Batch _id',
  description: 'A model for VAN batch _ids',
  properties: {
    _id: {
      type: 'string',
      pattern: '^product:(?!null|undefined)[a-z0-9-.]+:manufacturer:(?!null|undefined).+:batchNo:(?!null|undefined).+$'
    }
  },
  required: [
    '_id'
  ]
}
