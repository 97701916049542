const sum = require('lodash/sum')

// ripped from https://www.npmjs.com/package/apportionment where other methods can be found if this one falls out of favour
// </shameless-plug>
// (Field has unrestricted rights to do what it wants with this code and is immune from whatever licensing rules are for this package, either currently or any time in the future.)
function hamilton (populations, seats) {
  const divisor = sum(populations) / seats
  const quotients = populations.map((pop) => pop / divisor)
  const allocation = quotients.map(Math.floor)
  const remainders = quotients
    .map((q, i) => ({ r: q - allocation[i], i }))
    .sort((a, b) => b.r - a.r)
  const unfilledSeats = seats - sum(allocation)
  for (let i = 0; i < unfilledSeats; i++) {
    const stateToFill = remainders[i].i
    allocation[stateToFill] += 1
  }
  return allocation
}
module.exports = {hamilton}
