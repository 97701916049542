const tools = require('../../tools')
const { listReportFields: getServiceFields, get: getService } = require('./../../service')
const getReportStatus = require('../tools/get-report-status')
const { getVersion } = require('../../utils/versions')
const translateReport = require('../../tools/translate-report')
const { listForLocations: listProductsForLocations } = require('../../product')
const { docToReportingPeriodProps } = require('../tools/ids')
const { get: getLocation } = require('./../../location')

const translateReportDoc = (service, report) => {
  const currentVersion = getVersion(service.id)
  const reportVersion = report.version
  if (reportVersion !== currentVersion) {
    return translateReport(report, currentVersion, service)
  }
  return report
}

const getProducts = async (state, {locationId, service, date, exclude}) => {
  const {submitsMultiFieldCounts, submitsOwnReport} = tools.getSubmitProperties(service.id, locationId)

  // Quick and dirty: multi field stock counts for now are only present in PSM services
  // which have a configuration per service instead of a configuration per location.
  //
  // A better way of solving this would be to have a configuration per service also for
  // immunization, so that in any case (batched, unbatched, multi field stock counts) the
  // configuration we take to check which products are relevant is the service one

  if (submitsMultiFieldCounts || submitsOwnReport) {
    const ps = await listProductsForLocations(state, [locationId], {serviceId: service.id, date, exclude})

    if (submitsMultiFieldCounts) {
      return Object.keys(ps.products).map(id => ps.products[id])
    }
    if (submitsOwnReport) {
      const ids = ps.configurations[locationId] ? ps.configurations[locationId].products : []
      return ids.map(id => ps.products[id])
    }
  }

  const parentId = tools.locationIdToParent(locationId)
  const parentSubmitsReport = tools.getSubmitProperties(service.id, parentId).submitsChildrenReport

  if (parentSubmitsReport) {
    const ps = await listProductsForLocations(state, [parentId], {serviceId: service.id, date})
    const ids = ps.configurations[parentId] ? ps.configurations[parentId].products : []
    return ids.map(id => ps.products[id])
  }
  return []
}

module.exports = async (state, {doc, service = null, entityOptions = null}) => {
  entityOptions = Object.assign({
    addProducts: false, // make sure stock has all products
    excludeProducts: null, // can be `null | configuration | allocation` to exlcude a products configuration source
    addFields: false, // make sure stock products have fields
    addProgress: false, // not sure this is actually used anymore
    addSubmitConfig: false, // not sure this is actually used anymore
    rawDocs: false, // set true to get the bare document
    convertWarehouseUnits: false, // convert warehouse units to single units,
    products: null // optionally pass in products (so you don't have to load them every time)
  }, entityOptions)
  if (entityOptions.rawDocs) {
    return doc
  }
  if (!service) {
    service = await getService(state, doc.serviceId)
  }

  const toStockCountOptions = {
    addSubmitConfig: entityOptions.addSubmitConfig
  }

  let products = entityOptions.products
  const locationId = tools.stockCountIdToLocationProperties(doc._id).id

  if (entityOptions.addProducts || entityOptions.convertWarehouseUnits) {
    const dateProps = docToReportingPeriodProps(service, doc)
    const date = new Date(dateProps.period.effectiveStart).toJSON()
    products = products || await getProducts(state, {locationId, service, date, exclude: entityOptions.excludeProducts})

    if (products.length) {
      toStockCountOptions.products = products

      if (entityOptions.addProducts) {
        toStockCountOptions.addMissingStock = 'products'
      }

      toStockCountOptions.convertWarehouseUnits = entityOptions.convertWarehouseUnits
    }
  }

  if (entityOptions.addFields) {
    const fields = await getServiceFields(state, service.id)
    const location = await getLocation(state, locationId, doc.submittedAt)
    if (fields && fields.length > 0) {
      toStockCountOptions.fields = fields
      toStockCountOptions.fieldOpts = {
        tracksPartnerBalances: location.tracksPartnerBalances
      }
    }
  }
  // isPristine is used by drafts and should only ever occur on them
  const isPristine = doc.isPristine
  doc = translateReportDoc(service, doc)
  const report = tools.docToStockCountRecord(doc, service, toStockCountOptions)
  if (entityOptions.addProgress) {
    report.progress = {status: getReportStatus(report)}
  }
  if (isPristine) {
    report.isPristine = isPristine
  }
  return report
}
