module.exports = {
  isValidJSONDate,
  isValidEmail
}

// minimal regex test, tested for some cases, any regex expert can update this 🙂
function isValidJSONDate (date) {
  // eslint-disable-next-line
  const timestamp = /^\d{4}[./-]\d{2}[./-]\d{2}[./T]\d{2}[./:]\d{2}[./:]\d{2}[./:]\d{3}[./Z]$/
  return (date && timestamp.test(date))
}
function isValidEmail (email) {
  const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  return email && emailRegex.test(email)
}
