import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

import UppercaseLabel from '../UppercaseLabel'
import BatchNumber from '../BatchNumber'

const BatchList = ({
  batches,
  productName
}) =>
  <div className='vs-batch-list'>
    <div className='vs-batch-list__head'>
      <UppercaseLabel>{productName} batches</UppercaseLabel>
      <UppercaseLabel>Quantity</UppercaseLabel>
    </div>
    <ul className='vs-batch-list__batches'>
      {batches.map(batch =>
        <li className='vs-batch-list__batch' key={batch.id}>
          <span>
            <BatchNumber bold className='vs-batch-list__batch-id'>
              {batch.number === 'unknown' ? 'Untracked' : batch.number}
            </BatchNumber>
            {batch.expiry && (
              <span className='vs-batch-list__batch-expiry'>exp. {batch.expiry}</span>
            )}
          </span>
          {batch.count && (
            <NumberFormat
              value={batch.count}
              displayType='text'
              thousandSeparator
            />
          )}
        </li>
      )}
    </ul>
  </div>

BatchList.propTypes = {
  /**
   * A list of batches with picked counts
   */
  batches: PropTypes.array.isRequired,

  /**
   * A human-readable product name these batches correspond to
   */
  productName: PropTypes.string.isRequired
}

export default BatchList
