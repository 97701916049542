module.exports = markVoid
const find = require('./shipment-find')
const dateToIdFriendlyTimestamp = require('./tools/date-to-id-friendly-timestamp')
const { canShipmentBeMarkedVoid } = require('./utils/utils')

/**
 * This is just to soft delete new shipments
 * See {@link https://github.com/fielded/van-orga/issues/2352#issue-376778063} for more info
 * @params shipmentId
 * @return Promise<Shipment>
 */
function markVoid (state, shipmentId, opts = {}) {
  return find(state, shipmentId)
    .then(([shipment = {}]) => {
      if (opts.force === true && shipment.status !== 'received') {
        return shipment
      }

      if (!canShipmentBeMarkedVoid(shipment)) {
        throw new Error('Only orphan shipments, or shipments with status new or pre-advice can be marked void')
      }
      return shipment
    })
    .then(shipment => {
      const now = new Date()
      const doc = {
        _id: toVoidId(shipment.snapshotId, now),
        type: 'snapshot-void',
        version: '1.0.0',
        createdAt: now.toISOString(),
        createdBy: state.user.name
      }

      return state.dal.shipment.write(state, doc)
        .then(() => doc)
    })
}

function toVoidId (snapshotId, date) {
  const timestamp = dateToIdFriendlyTimestamp(date)
  return `${snapshotId}:void:${timestamp}`
}
