import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { pluralize } from '../../../utils/utils'
import Page from '../Page'
import DataTable from '../../DataTable'
import FilterInput, { filterByProp, filterByProps } from '../../FilterInput'
import FilterTitle from '../../FilterTitle'
import Text from '../../Text'
import Button from '../../Button'
import Checkbox from '../../Checkbox'

const CellButton = ({label, ...otherProps}) => <Button
  fill='outline'
  colorVariant='brand'
  size='small'
  {...otherProps}
>{label || 'view'}</Button>

class PageFilterTable extends Component {
  static propTypes = {
    /**
     * Column definitions, see readme and DataTable for options
     */
    columns: PropTypes.array.isRequired,
    /**
     * Source data for the table rows
     */
    data: PropTypes.array.isRequired,
    /**
     * Initial sort prop
     */
    sortBy: PropTypes.string,
    /**
     * Initial sort direction
     */
    sortDirection: PropTypes.string,
    /**
     * Sort function
     */
    entrySorter: PropTypes.func,
    /**
     * What type of entry does the table contain, e.g. "user" or "location".
     * Used for the title, filter message ("All users", "1 user", "17 users").
     */
    entity: PropTypes.string,
    /**
     * Which data props should the filter apply to
     */
    filterBy: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    /**
     * Placeholder for the filter input
     */
    filterPlaceholder: PropTypes.string,
    /**
     * Prefill the filter with this text
     */
    filterTerm: PropTypes.string,
    /**
     * Button for the header
     */
    cta: PropTypes.node,
    /**
    * Additional classnames for the Panel. Use carefully.
    */
    className: PropTypes.string,
    /**
    * Additional classnames for the Panel Header. Use carefully.
    */
    headerClassName: PropTypes.string,
    /**
    * Property for rendering compact version without the header feedback.
    */
    compact: PropTypes.bool,

    // Overwrite the "All {entities} heading title".
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  }

  static defaultProps = {
    sortBy: 'name',
    sortDirection: 'ASC',
    entity: 'entry',
    filterBy: 'name'
  }

  state = {
    filterTerm: this.props.filterTerm
  }

  filterInput = React.createRef()

  setFilterTerm = (filterTerm) => {
    this.setState({
      filterTerm
    })
  }

  render () {
    const {
      columns,
      data,
      extra,
      sortBy,
      sortDirection,
      entrySorter,
      entity,
      entityContext,
      filterBy,
      filterPlaceholder,
      cta,
      className,
      headerClassName,
      compact,
      // We don't use this here, it's just to avoid
      // having them applied to the DOM via allProps
      filterTerm,
      title,
      ...otherProps
    } = this.props

    let filteredData = data
    if (typeof filterBy === 'string') {
      filteredData = filterByProp(data, this.state.filterTerm, filterBy)
    } else if (typeof filterBy === 'object') {
      filteredData = filterByProps(data, this.state.filterTerm, filterBy)
    }
    const pluralizedEntity = pluralize(entity, 2)
    const filterPlaceholderFallback = `Filter ${pluralizedEntity} by ${filterBy}`

    const filterInput = (
      <FilterInput
        ref={this.filterInput}
        onValueChange={(value) => this.setFilterTerm(value)}
        placeholder={filterPlaceholder || filterPlaceholderFallback}
        value={this.state.filterTerm}
        className='vs-u-hide-on-print'
      />
    )

    if (compact) {
      return (
        <Page.Panel
          className={classnames(
            'vs-table-panel',
            className
          )}
          spread
          withMargin={false}
        >
          <Page.Panel.Section>
            <FilterInput
              ref={this.filterInput}
              onValueChange={(value) => this.setFilterTerm(value)}
              placeholder={filterPlaceholder || filterPlaceholderFallback}
              value={this.state.filterTerm}
              className='vs-u-hide-on-print'
            />
          </Page.Panel.Section>
          <Page.Panel.Section>
            {title || <FilterTitle
              count={filteredData.length}
              entity={entity}
              entityContext={entityContext}
              filterTerm={this.state.filterTerm}
              onFilterClear={() => this.filterInput.current.clear()}
              extra={extra}
              compact
            />
            }
          </Page.Panel.Section>
          <Page.Panel.Section
            spread
            style={{ position: 'relative' }}
          >
            <DataTable
              entries={filteredData}
              noRowsBannerContent={this.state.filterTerm ? (
                <span>
                No {pluralizedEntity} found matching <Text weight='bold' tag='strong'>&ldquo;{this.state.filterTerm}&rdquo;</Text>.
                </span>) : `No ${pluralizedEntity} found`
              }
              {...otherProps}
              sortBy={sortBy}
              sortDirection={sortDirection}
              entrySorter={entrySorter}
            >
              {columns.map(column => {
                const {
                  key,
                  dataKey,
                  title,
                  flexGrow,
                  ...rest
                } = column
                return (
                  <DataTable.Column
                    key={`column${key || dataKey || new Date().getTime()}`}
                    label={title}
                    dataKey={key || dataKey}
                    flexGrow={flexGrow || 1}
                    {...rest}
                  />
                )
              })}
            </DataTable>
          </Page.Panel.Section>
        </Page.Panel>
      )
    }

    return (
      <Page.Panel
        className={classnames(
          'vs-table-panel',
          className
        )}
        spread
      >
        <Page.Panel.Header
          title={title || (
            <FilterTitle
              count={filteredData.length}
              entity={entity}
              entityContext={entityContext}
              filterTerm={this.state.filterTerm}
              onFilterClear={() => this.filterInput.current.clear()}
            />
          )}
          className={headerClassName}
          cta={cta || filterInput}
        />
        {cta && (
          <Page.Panel.Section>
            {filterInput}
          </Page.Panel.Section>
        )}
        {extra && (
          <Page.Panel.Section>
            <div className='vs-table-panel__extra'>{extra}</div>
          </Page.Panel.Section>)}
        {otherProps.filterToggle && (
          <Page.Panel.Section>
            <Checkbox
              label={otherProps.filterToggleLabel}
              onChange={otherProps.filterToggle}
              defaultChecked={otherProps.filterToggleDefault}
            />
          </Page.Panel.Section>
        )}
        <Page.Panel.Section
          spread
          style={{ position: 'relative' }}
        >
          <DataTable
            entries={filteredData}
            noRowsBannerContent={this.state.filterTerm ? (
              <span>
                No {pluralizedEntity} found matching <Text weight='bold' tag='strong'>&ldquo;{this.state.filterTerm}&rdquo;</Text>.
              </span>) : `No ${pluralizedEntity} found`
            }
            {...otherProps}
            sortBy={sortBy}
            sortDirection={sortDirection}
            entrySorter={entrySorter}
          >
            {columns.map(column => {
              const {
                key,
                dataKey,
                title,
                flexGrow,
                ...rest
              } = column
              return (
                <DataTable.Column
                  key={`column${key || dataKey || new Date().getTime()}`}
                  label={title}
                  dataKey={key || dataKey}
                  flexGrow={flexGrow || 1}
                  {...rest}
                />
              )
            })}
          </DataTable>
        </Page.Panel.Section>
      </Page.Panel>
    )
  }
}

PageFilterTable.CellButton = CellButton

PageFilterTable.displayName = 'Page.FilterTable'

export default PageFilterTable
