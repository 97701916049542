const removeRev = require('./../../../utils/remove-rev')
const { construct } = require('./../../tools')

exports.listProductsForPrograms = listProductsForPrograms
function listProductsForPrograms (state, programIds, opts) {
  return listAll(state, Object.assign({programIds}, opts))
}

exports.listProductsForServices = listProductsForServices
function listProductsForServices (state, serviceIds, opts) {
  return listAll(state, Object.assign({serviceIds}, opts))
}

function listAll (state, { programIds = [], serviceIds = [], date = new Date().toISOString(), getProductObjects = true }) {
  const lookupByProgramId = Boolean(programIds.length)
  const productIdsPromise = Promise.all(
    (lookupByProgramId ? programIds : serviceIds).map((id) => {
      return lookupByProgramId
        ? state.dal.product.getProgramConfiguration(state, id)
        : state.dal.product.getServiceConfiguration(state, id)
    })
  )
    .then(programResponses => {
      return programResponses
        .reduce((arr, docs) => {
          // Filter out docs already in the reduced array
          const newDocs = docs.filter(doc =>
            arr.find(d => d._id === doc._id) === undefined)
          return arr.concat(newDocs)
        }, [])
    })
    .then(services => {
      return services.map(service => service.products)
    })
    .then(productIds => {
      // Flatten product id arrays, get unique keys
      productIds = Array.prototype.concat.apply([], productIds)
      return Array.from(new Set(productIds))
    })

  if (!getProductObjects) return productIdsPromise

  return productIdsPromise
    // getRaw filters out non-existing products
    .then(productIds => state.dal.product.getRaw(state, productIds))
    .then(productDocs => productDocs.map(record => removeRev(construct(record, { now: date }))))
}
