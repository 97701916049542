import get from 'lodash/get'
import React, { Component, Fragment } from 'react'

import { DiffSummary, Page } from '@fielded/shared-ui'

import ConfirmationTable from './ConfirmationTable'
import { batchIdToProductId } from '../common/utils'

class ConfirmationSummary extends Component {
  constructor () {
    super()
    this.state = {
      showBatches: false
    }
  }

  render () {
    const {
      pickListWithName,
      shipment,
      isIncomplete,
      batches,
      config,
      title,
      showHeading,
      hasAdjustments
    } = this.props
    const isDistribution = shipment.statusType === 'distribution'
    const isArrival = shipment.statusType === 'arrival'
    const manifestName = config.app === 'van' ? 'SIV' : 'manifest'

    const distributionNoneCountedMessage = 'None counted'
    const arrivalNoneCountedMessage = 'None received'
    const noneCountedMessage = isDistribution ? distributionNoneCountedMessage : arrivalNoneCountedMessage

    let pendingAdjustments = []
    if (shipment.pendingAdjustments) {
      pendingAdjustments = shipment.pendingAdjustments.map(batch => {
        const productId = batchIdToProductId(batch.batchId)

        return {
          cols: [{
            name: 'products',
            value: get(pickListWithName, `${productId}.name`, 'UNKNOWN')
          },
          {
            name: 'previous-quantity',
            value: batch.previous
          },
          {
            name: 'updated-quantity',
            value: batch.updated
          }]
        }
      })
    }

    return (
      <Fragment>
        <Page.Intro centered title={title || 'Confirm quantities'} />
        <Page.Panel withMargin narrow withBackground>
          {showHeading &&
            <Page.Panel.Section spread fullWidth>
              <h3 className='floating-card__heading'>
                {isIncomplete && isDistribution &&
                  'Planned and counted quantities do not match!'
                }
                {isIncomplete && !isDistribution &&
                  `Counted quantities do not match ${manifestName}!`
                }

                { /* TODO: human readable location names, see: fielded/van-orga#135 */}
                {!isIncomplete && isDistribution &&
                  `${manifestName} for ${shipment.destination.id.split(':').pop()}`
                }

                { /* TODO: human readable location names, see: fielded/van-orga#135 */}
                {!isIncomplete && !isDistribution &&
                  `Arrival list from ${shipment.origin.id.split(':').pop()}`
                }
              </h3>

              <p className='floating-card__subtitle'>
                {isIncomplete &&
                  'Please review discrepancies (you can go back to make changes.)'
                }

                {!isIncomplete && isDistribution &&
                  `The following quantities will be printed on the ${manifestName}.`
                }

                {!isIncomplete && isArrival &&
                  'The following quantities will be saved.'
                }
              </p>
            </Page.Panel.Section>}
          <Page.Panel.Section spread fullWidth>
            {pendingAdjustments.length > 0 ? <ConfirmationTable rows={pendingAdjustments} /> : <div className='confirmation__empty-state'>No adjustments were made</div>}
            {!hasAdjustments && <DiffSummary
              // only pass batches if there actually are any
              batches={Object.keys(batches).length ? batches : null}
              productGroups={[{products: pickListWithName}]}
              noneCountedMessage={noneCountedMessage}
            />}
          </Page.Panel.Section>
        </Page.Panel>
      </Fragment>
    )
  }
}

export default ConfirmationSummary
