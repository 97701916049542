const pluckDocs = require('../../utils/pluck-docs')
const { sanitiseBatchesForDuplicates } = require('../tools/index')

let cachedList = null

// This is required to delete the cache living at module scope in tests.
// Ideally, the cache would instead live on the `state` object so that
// multiple instances never share the same cache.
exports.purgeCache = function () {
  cachedList = null
}

exports.listBatches = listBatches
// Note: the cache will cause `batch.list` to return a stale list of batches.
// Once activated the cache will remain activated.
// It is intended to be used only when using also `batch.onListUpdated`, which
// will subscribe to the changes feed and take care of updating the cache
// with changes from the DB.
// Because of this it's considered an internal API.
function listBatches (state, options = {}, updatedList) {
  const maybeUpdateCache = list => {
    if (cachedList || options.activateCache) {
      cachedList = list
    }
    return list
  }

  if (updatedList) {
    cachedList = updatedList
  }

  if (Array.isArray(options.ids)) {
    return state.masterDataDB.allDocs({
      keys: options.ids,
      include_docs: true
    })
      .then(pluckDocs)
      .then(sanitiseBatchesForDuplicates)
  }

  if (cachedList && !options.updateBefore) {
    return Promise.resolve(cachedList)
  }

  const params = {
    include_docs: true,
    startkey: 'product:',
    endkey: 'product:\uffff'
  }

  return state.masterDataDB.allDocs(params)
    .then(pluckDocs)
    .then(sanitiseBatchesForDuplicates)
    .then(maybeUpdateCache)
}
