const { get } = require('../product/api/read/get')

const validateGenericParent = async (state, product, validateAlias) => {
  if (!validateAlias) return

  const keys = Object.keys(product.alias)
  const genericParent = product.genericParent

  // This will usually only contain ['one'], but tb also has 'product:tb'
  const isInAlias = keys.filter(aliasKey => product.alias[aliasKey].indexOf(genericParent) >= 0)
  if (isInAlias && isInAlias.length) return

  const genericParentExist = await get(state, `product:${genericParent.toLowerCase()}`)
  if (genericParentExist) return

  throw new Error(`GenericParent ${genericParent} does not exist`)
}

exports.validateGenericParent = validateGenericParent
