const keyBy = require('lodash/keyBy')

module.exports = { decorateWithMasterData, applyFilters, transformLocationSkuHistory }

function parseShipmentIdIntoLocationProps (id) {
  const result = {
    origin: {},
    destination: {}
  }

  const pattern = /origin:country-(\w+)-state-([\w-]+)-sdp-([\w-]+):destination:country-(\w+)-state-([\w-]+)-sdp-([\w-]+):/
  const match = pattern.exec(id)

  if (match) {
    // Extract information for 'origin'
    result.origin.country = match[1].toUpperCase()
    result.origin.state = match[2]
    result.origin.sdp = match[3]
    result.origin.id = `country:${match[1]}:state:${result.origin.state}:sdp:${result.origin.sdp}`
    result.origin.name = match[3]

    // Extract information for 'destination'
    result.destination.country = match[4].toUpperCase()
    result.destination.state = match[5]
    result.destination.sdp = match[6]
    result.destination.id = `country:${match[4]}:state:${result.destination.state}:sdp:${result.destination.sdp}`
    result.destination.name = match[6]
  } else {
    // Extract information for 'destination'
    const dest = id.split(':destination:')[1]
    const destCountry = dest.split(':')[0].split('-state-')[0].replace('-', ':')
    result.destination.state = dest.split(':')[0].split('-state-')[1].split('-sdp-')[0]
    result.destination.name = dest.split(':')[0].split('-state-')[1].split('-sdp-')[1]
    result.destination.sdp = dest.split(':')[0].split('-sdp-')[1]
    result.destination.id = `${destCountry}:state:${result.destination.state}:sdp:${result.destination.name}`
  }
  return result
}

function transformLocationSkuHistory (locationId, rows) {
  const locations = []
  let slBalance = 0
  let partnerBalance = 0
  const final = rows.map(row => {
    const totalBalance = row.sl_balance + row.partner_balance
    const change = row.prev_opening - totalBalance

    if (row.event_type === 'stock_count') {
      slBalance = row.sl_balance
      partnerBalance = row.partner_balance
      return {
        type: 'stockCount',
        subType: 'count',
        productId: `product:${row.sku}`,
        _id: row.event_id,
        ledgerDate: row.date,
        batchId: `product:${row.sku}:manufacturer:unknown:batchNo:UNKNOWN`,
        batchNo: 'UNKNOWN',
        manufacturer: 'unknown',
        quantity: totalBalance,
        transfer: 0,
        partnerBalance: row.partner_balance,
        balanceTransfer: 0,
        slBalance: row.sl_balance,
        user: row.driver,
        change,
        currentValue: row.current_opening,
        previousBatchQuantity: row.prev_opening,
        balance: totalBalance,
        expiry: ''
      }
    } else if (row.event_type === 'shipment') {
      const locProps = parseShipmentIdIntoLocationProps(row.event_id)
      const otherLocationId = locationId === locProps.origin.id
        ? locProps.destination.id
        : locProps.origin.id

      const isReceive = locationId === locProps.destination.id

      slBalance += row.delivered

      locations.push(otherLocationId)
      return {
        type: 'shipment',
        _id: row.event_id,
        ledgerDate: row.date,
        snapshotId: row.event_id,
        otherLocationId,
        origin: locProps.origin,
        destination: locProps.destination,
        batchNo: 'UNKNOWN',
        manufacturer: 'unknown',
        batchId: `product:${row.sku}:manufacturer:unknown:batchNo:UNKNOWN`,
        quantity: row.delivered,
        hasDiscrepancy: false,
        isReceive,
        discrepancy: 0,
        isUnrecordedArrival: false,
        user: row.driver,
        change: row.delivered,
        currentValue: row.current_opening,
        previousBatchQuantity: row.prev_opening,
        balance: row.current_opening,
        expiry: ''
      }
    } else if (row.event_type === 'buyout') {
      const balanceTransfer = (row.sl_balance || 0) - (slBalance || 0)
      slBalance = row.sl_balance
      partnerBalance = row.partner_balance
      const totalBalance = row.sl_balance + row.partner_balance
      const change = row.prev_opening - totalBalance

      return {
        type: 'stockCount',
        subType: 'balance-transfer',
        productId: `product:${row.sku}`,
        _id: row.event_id,
        ledgerDate: row.date,
        batchId: `product:${row.sku}:manufacturer:unknown:batchNo:UNKNOWN`,
        batchNo: 'UNKNOWN',
        manufacturer: 'unknown',
        quantity: totalBalance,
        transfer: 0,
        partnerBalance: row.partner_balance,
        balanceTransfer: Math.abs(balanceTransfer),
        slBalance: row.sl_balance,
        user: row.driver,
        change,
        currentValue: row.current_opening,
        previousBatchQuantity: row.prev_opening,
        balance: totalBalance,
        expiry: ''
      }
    } else {
      slBalance = row.sl_balance
      partnerBalance = row.partner_balance
      const totalBalance = row.sl_balance + row.partner_balance
      const change = row.prev_opening - totalBalance

      return {
        type: 'stockCount',
        subType: 'adjustment',
        productId: `product:${row.sku}`,
        _id: row.event_id,
        ledgerDate: row.date,
        batchId: `product:${row.sku}:manufacturer:unknown:batchNo:UNKNOWN`,
        batchNo: 'UNKNOWN',
        manufacturer: 'unknown',
        quantity: totalBalance,
        transfer: 0,
        partnerBalance: row.partner_balance,
        balanceTransfer: 0,
        slBalance: row.sl_balance,
        user: row.driver,
        change,
        currentValue: row.current_opening,
        previousBatchQuantity: row.prev_opening,
        balance: totalBalance,
        expiry: ''
      }
    }
  })

  const latestRow = final[rows.length - 1]
  return {
    items: final.reverse(),
    locations: [...new Set([
      ...locations,
      locationId
    ])],
    balances: {
      partnerBalance: partnerBalance || 0,
      shelflifeBalance: slBalance && slBalance > 0
        ? slBalance
        : 0,
      overallBalance: latestRow ? latestRow.currentValue : 0
    }
  }
}

function oldestFirst (a, b) {
  if (!a.ledgerDate) {
    return -1
  } else if (!b.ledgerDate) {
    return 1
  } else {
    return (a.ledgerDate < b.ledgerDate) ? -1 : ((a.ledgerDate > b.ledgerDate) ? 1 : 0)
  }
}

function addResultingQuantities (rows) {
  let total = 0
  const lastBatch = {}
  let currentValue

  const withQuantities = rows.sort(oldestFirst).map(row => {
    const previousBatchQuantity = lastBatch[row.batchId]
      ? lastBatch[row.batchId]
      : 0

    let change
    if (row.type === 'stockCount') {
      change = lastBatch[row.batchId]
        ? row.quantity - lastBatch[row.batchId]
        : row.quantity

      total += change
      lastBatch[row.batchId] = row.quantity
      currentValue = row.quantity
    } else {
      change = row.quantity
      lastBatch[row.batchId] = lastBatch[row.batchId] || 0
      lastBatch[row.batchId] += row.quantity
      total += change
      currentValue = previousBatchQuantity + row.quantity
    }
    return Object.assign({}, row, { change, currentValue, previousBatchQuantity, balance: total })
  }).filter(row => {
    // A balance transfer does not include a quantity change,
    // but should be displayed anyway:
    return row.change || row.balanceTransfer
  })
  withQuantities.reverse()
  return withQuantities
}

function decorateWithMasterData (rows, locations) {
  const locationsById = keyBy(locations, '_id')

  return rows.map(row => {
    if (row.type === 'stockCount') {
      return Object.assign({}, row)
    }

    const origin = Object.assign({}, row.origin, getLocationNameObject(locationsById, row.origin.id))
    const destination = Object.assign({}, row.destination, getLocationNameObject(locationsById, row.destination.id))
    return Object.assign({}, row, { origin, destination })
  })
}

function getLocationNameObject (locationsById = {}, id = '') {
  const loc = locationsById[id]
  const name = loc ? loc.name : id.split(':').pop()
  return { name }
}

// Pass in full list of unfiltered rows, current balances, and row filters
// (The two row filteres in use are batchNo and otherLocationId)
function applyFilters (filters, unfilteredBalances, unfilteredRows) {
  if (!filters || !Object.keys(filters).length) {
    return { rows: unfilteredRows, balances: unfilteredBalances }
  }
  const filteredRows = unfilteredRows.filter(row => (
    Object.keys(filters).every(filterKey => (row[filterKey] === filters[filterKey]))
  ))
  const rows = addResultingQuantities(filteredRows)
  const finalBalance = rows.length ? rows[0].balance : 0
  const balances = {
    routineStockBalance: finalBalance,
    campaignStockBalance: 0,
    overallBalance: finalBalance
  }
  return { rows, balances }
}
