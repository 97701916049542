module.exports = {
  getLedgerBalance,
  getLedgerDate
}

const { parse } = require('./smart-id')
const cloneDeep = require('lodash/cloneDeep')
const sortBy = require('lodash/sortBy')
const get = require('lodash/get')
const set = require('lodash/set')
const shouldTrackBatches = require('./should-track-batches')
const isShipmentRelevant = require('./is-shipment-relevant')
const { getCommitsTotal } = require('./utils/balances')
const { sortByLatestReport } = require('./date-utils')

const { REPORT_BALANCE_FIELD } = require('./utils/constants')
const { isShelflifeService } = require('./utils/program')
const BUY_OUT_FIELD = 'fields.field:partner-buyout.amount'
const PARTNER_ID = 'field:partner-balance'
const types = {
  STOCK: 'stock',
  SHIPMENT: 'shipment',
  BUYOUT: 'buyout',
  ORDER: 'order'
}

function getLedgerBalance (params) {
  const {
    location,
    date,
    products,
    reports,
    shipments,
    includeScheduledOutbound,
    service,
    fields,
    includeOpenOrders,
    orders = {}
  } = params

  const ledgerFields = (fields || []).filter(f => f.ledger === true)

  // 1. Figure out what documents are needed:
  const baseReport = getBaseReport(reports, date)
  const lastReportDate = get(baseReport, 'submittedAt')
  const partialReports = getPartialReports(reports, lastReportDate, date)
  const latestReport = getLatestReport(reports)

  const relevantShipments = shipments.filter(shipment =>
    isShipmentRelevant({ shipment, location, endDate: date, startDate: lastReportDate, includeScheduledOutbound })
  )

  // 2. Build list of ledger vents
  let events = extractReport(baseReport, ledgerFields)
    .concat(partialReports.flatMap(r => extractReport(r, ledgerFields)))
    .concat(relevantShipments.flatMap(s => extractShipment(s, location, includeScheduledOutbound)))

  if (includeOpenOrders) {
    events = events.concat(Object.keys(orders).flatMap(o => extractOrders(o, orders)))
  }

  events = sortBy(events, ['date'])

  // 3. "reduce" event list to stock status
  const ledger = events.reduce(addEvents, {})

  // 4. Add products that are tracked on the location, remove non-tracked ones (if not SL)
  checkProductList(products, ledger, service, location, latestReport)
  // 5. check extra fields, zero batches, remove internal use keys:
  Object.values(ledger).forEach(item => cleanUp(item, service, location, products, ledgerFields))

  return {
    ledger,
    baseReport,
    addedShipments: relevantShipments
  }
}

/*
 * Step 1: Figure out the documents involved
 */
function getBaseReport (reports, date) {
  if (!reports) return null
  const sorted = reports
    .filter(r => !r.partialCount)
    .filter(r => {
      return r.submittedAt && r.submittedAt <= date
    })
    .sort(sortByLatestReport)

  return sorted.length ? sorted[sorted.length - 1] : null
}

function getPartialReports (reports, startDate, endDate) {
  if (!reports) {
    return null
  }

  return reports
    .filter(r => r.partialCount)
    .filter(r => {
      // This is an edge case happens when:
      // a new pharmacy that has not been counted yet, accepts a pay_on_delivery shipment
      // which will generate a partial count to mark the sales
      // this partial count is a balance transfer and we need that included in the ledger
      if (!startDate) {
        return r.submittedAt <= endDate
      }
      return r.submittedAt > startDate && r.submittedAt <= endDate
    })
}

/*
 * Step 2: Extract stock events from reports/shipments
 */
function extractReport (report, ledgerFields = []) {
  if (!report) {
    return []
  }

  const date = report.submittedAt
  const productIds = Object.keys(report.stock)
  return productIds.map(productId => {
    const product = report.stock[productId]
    if (!product) {
      return
    }

    if (product.autoFilled) {
      const buyOut = get(product, BUY_OUT_FIELD)
      if (buyOut) {
        return {
          productId,
          date,
          amount: buyOut,
          type: types.BUYOUT
        }
      }

      // for pay_on_delivery products, we can have autoFilled fields even in
      // "full" counts, we need to keep those on the ledger
      // (hopefully this will be replaced "soon")
      if (report.partialCount) {
        return
      }
    }

    let batches
    let total = 0
    for (let batchId in product.batches) {
      const amount = get(product.batches[batchId], REPORT_BALANCE_FIELD, 0)
      total += amount
      batches = batches || {}
      batches[batchId] = amount
    }

    let commits
    if (product.commits) {
      commits = cloneDeep(product.commits)
    }

    if (!batches) {
      total = get(product, REPORT_BALANCE_FIELD, 0)
    }

    const extras = {}
    ledgerFields.forEach(field => {
      const value = get(product, `fields.${field.id}.amount`, 0)
      extras[field.id] = value
    })

    return {
      productId,
      total,
      batches,
      commits,
      date,
      extras,
      type: types.STOCK
    }
  })
    .filter(x => x)
}

function extractShipment (shipment, location, includeScheduledOutbound) {
  const date = isShipmentRelevant.getShipmentDate(location, includeScheduledOutbound, shipment)

  const totals = {}
  for (let batchId in shipment.counts) {
    const { product } = parse(batchId)
    const productId = `product:${product}`

    const absoluteQuantity = get(shipment, `counts.${batchId}.quantity`)

    const batchQuantity = (shipment.origin.id === location.id)
      ? absoluteQuantity * -1
      : absoluteQuantity

    // Simplification - we only have one of each batch per shipment
    totals[productId] = totals[productId] || { total: 0, batches: {}, commits: {} }
    totals[productId].batches[batchId] = batchQuantity
    totals[productId].total += batchQuantity

    const commit = get(shipment, 'shipmentType.id', 'routine')
    if (commit !== 'routine') {
      const commitKey = `${productId}.commits.${commit}.amount`
      const commited = get(totals, commitKey, 0)
      set(totals, commitKey, commited + batchQuantity)
    }
  }

  return Object.entries(totals).map(([productId, { total, batches, commits }]) => {
    return {
      productId,
      total,
      batches,
      commits,
      date,
      type: types.SHIPMENT
    }
  })
}

function extractOrders (product, orders) {
  return {productId: product, ...orders[product]}
}

/*
 * Step 3:
 * Walk through events and create ledger state from them
 */
const addEvents = (ledger, item) => {
  let newItem
  // Nothing was there before, this is the new balance,
  // regardless of stock or shipment
  if (!ledger[item.productId] && item.type !== types.ORDER) {
    newItem = item
  } else if (item.type === types.STOCK) {
    // A stock count always resets the value
    newItem = item
  } else if (item.type === types.SHIPMENT) {
    // A shipment is added on top of existing stock
    newItem = addItems(ledger[item.productId], item)
  } else if (item.type === types.BUYOUT) {
    // SL partner balance transfer
    newItem = applyBuyOut(ledger[item.productId], item)
  } else if (item.type === types.ORDER) {
    newItem = removeItems({reportItem: ledger[item.productId], orderItem: item})
  }
  if (!newItem) {
    throw new Error(`Unknown type: ${item.type}`)
  }

  ledger[item.productId] = newItem
  return ledger
}

function addMaps (map1, map2) {
  if (!map1) {
    return map2
  }

  if (!map2) {
    return map1
  }

  const keys = Array.from(new Set((Object.keys(map1).concat(Object.keys(map2)))))
  const map = {}
  for (const key of keys) {
    if (typeof map1[key] === 'number' || typeof map2[key] === 'number') {
      map[key] = (map1[key] || 0) + (map2[key] || 0)
    } else {
      map[key] = addMaps(map1[key], map2[key])
    }
  }

  return map
}

function addItems (reportItem, shipmentItem) {
  const total = reportItem.total + shipmentItem.total
  const date = shipmentItem.date
  const productId = reportItem.productId

  const commits = addMaps(reportItem.commits, shipmentItem.commits)
  const batches = addMaps(reportItem.batches, shipmentItem.batches)

  return {
    productId,
    total,
    date,
    commits,
    batches,
    extras: reportItem.extras,
    type: shipmentItem.type // used to prevent cleanup
  }
}

function removeItems ({reportItem = {}, orderItem}) {
  const reportItemTotal = reportItem.total || 0
  let total = reportItemTotal - orderItem.total

  if (total < 0) {
    total = 0
  }

  const productId = reportItem.productId

  return {
    productId,
    total,
    extras: reportItem.extras,
    type: orderItem.type
  }
}

function applyBuyOut (reportItem, buyOutItem) {
  reportItem.extras = reportItem.extras || {}
  const transfer = buyOutItem.amount
  const partner = reportItem.extras[PARTNER_ID] || 0
  const extras = {
    ...reportItem.extras,
    // Sometimes "more stock than exists" is transfered
    // so that the client can be credited on their invoice:
    // So do we need a case where more' is transfered from SL to partner
    // than what was originally on the SL balance?
    [PARTNER_ID]: Math.max(partner - transfer, 0)
  }

  return {
    ...reportItem,
    extras
  }
}
/*
 * Step 4/5:
 *
 * Add missing products, fields, clean up 0-items
 */

// Note: this one manipulates the object,
// it does not create a new one
function cleanUp (item, service, location, products, ledgerFields) {
  // Calculate available total on end result
  const committed = getCommitsTotal(item.commits)
  item.availableTotal = item.total - committed

  const tracksBatches = shouldTrackBatches({
    serviceId: service.id, product: products[item.productId], location
  })

  if (tracksBatches) {
    item.batches = item.batches || {}
    // withoutZeroBatches
    for (let batchId in item.batches) {
      if (item.batches[batchId] === 0) {
        delete item.batches[batchId]
      }
    }
  } else {
    delete item.batches
  }

  if (Object.keys(item.commits || {}).length === 0) {
    delete item.commits
  }

  if (!item.extras) {
    // Add exta fields if not present already:
    ledgerFields.forEach(field => {
      item[field.id] = 0
    })
  } else {
    // Copy extras to object (e.g. partner-balance)
    Object.assign(item, item.extras || {})
    delete item.extras
  }

  // Delete internal book-keeping props:
  delete item.type
  delete item.date
  delete item.productId
}

function checkProductList (products, ledger, service, location, latestReport) {
  for (let product of Object.values(products)) {
    if (product._id in ledger) {
      continue
    }

    const tracksBatches = shouldTrackBatches({
      serviceId: service.id, product, location
    })

    const item = { total: 0 }
    if (tracksBatches) {
      item.batches = {}
    }

    ledger[product._id] = item
  }

  // Remove SL products that are no longer subscribed and don't have a balance
  const keepUnsubscribed = isShelflifeService(service.id)
  const extraProducts = Object.keys(ledger).filter(productId => !products[productId])
  for (const productId of extraProducts) {
    if (!keepUnsubscribed) {
      delete ledger[productId]
      continue
    }

    // Previous ledger balance did the 'clean up zeroes' step on the stock report
    // so we need to keep products that got 'zeroed' by shipments, they are needed in orders
    // We want the ledger to record the 'zeroing' report event but after that delete that product from the ledger
    const openingBalance = latestReport
      ? get(latestReport.stock, `${productId}.fields.field:standard-opening-balance.amount`, 0)
      : 0

    if (ledger[productId].total === 0 && ledger[productId].type !== 'shipment' && openingBalance === 0) {
      delete ledger[productId]
    }
  }
}

function getLatestReport (reports) {
  const sortedReports = reports.sort((a, b) => {
    if (!a.submittedAt && b.submittedAt) {
      return -1
    }
    if (!b.submittedAt && a.submittedAt) {
      return 1
    }
    if (a.submittedAt < b.submittedAt) {
      return -1
    }
    if (a.submittedAt > b.submittedAt) {
      return 1
    }
    return 0
  })

  return sortedReports[sortedReports.length - 1]
}

/*
 * Extra:
 * Set ledger date and explain involved documents
 */
function getLedgerDate (params) {
  const {
    location, date, reports, shipments, includeScheduledOutbound
  } = params

  const baseReport = getBaseReport(reports, date)
  const baseReportDate = get(baseReport, 'submittedAt')
  const baseReportId = get(baseReport, '_id')
  const partialReports = getPartialReports(reports, baseReportDate, date)
  const partialReportDates = partialReports.map(r => r.submittedAt)

  const shipmentDates = shipments
    .filter(shipment => isShipmentRelevant({ shipment, location, endDate: date, startDate: baseReportDate, includeScheduledOutbound }))
    .map(shipment => isShipmentRelevant.getShipmentDate(location, includeScheduledOutbound, shipment))

  const allDates = [baseReportDate].concat(partialReportDates).concat(shipmentDates)
  const dates = sortBy(allDates).filter(x => x)
  // If there is a shipment date, isShipmentRelevant will
  // already have filtered those who are earlier than latestReportDate
  let stockCountId = baseReportId
  if (partialReports.length) {
    stockCountId = partialReports[partialReports.length - 1]._id
  }

  const counts = []
  if (baseReportId) {
    counts.push(baseReportId)
  }

  return {
    lastChangeDate: dates[dates.length - 1],
    stockCountId,
    counts: counts.concat(partialReports.map(r => r._id))
  }
}
