import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

import withConfig from '../../../van-shared/hoc/withConfig'
import exportLmd from '../common/export-lmd'

export class DownloadLMDReportButton extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    program: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    title: PropTypes.string,
    period: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    locationId: PropTypes.string
  }

  static defaultProps = {
    title: undefined,
    locationId: undefined
  }

  state = {
    downloading: false
  }

  handleDownloadLMD = async () => {
    this.setState({downloading: true})
    const {user, program, period, config, locationId} = this.props
    const err = await exportLmd(user, program, period, config, locationId)
    if (err) {
      this.setState({downloading: false})
      throw err
    } else {
      this.setState({downloading: false})
    }
  }

  render () {
    const {disabled, title, label} = this.props
    const {downloading} = this.state

    return (
      <Button
        icon={<Arrow direction='down' />}
        loading={downloading}
        fill='full'
        colorVariant='brand'
        loadingLabel='Exporting'
        onClick={this.handleDownloadLMD}
        disabled={disabled}
        title={title}
      >
        {label}
      </Button>
    )
  }
}

export default withConfig(DownloadLMDReportButton)
