const { docsToOrderEntity } = require('./docs-to-order-entity')
const { getAllSnapshots } = require('../utils')
const { byId } = require('../../../tools/by-id')

// TODO: clean up or remove this. It's used in two places, advance status
// and updateOrdersOnProduct. But in first case the UI doesn't use the order entiteis at all
// and in the second case it shows "udpated X orders" regardless of how many
// snapshots were actually changed.
exports.ordersFromSnapshotUpdates = function ({
  orders,
  updatedSnapshots,
  locationId,
  status
}) {
  const snapshotsByOrderId = updatedSnapshots
    .reduce((acc, snapshot) => {
      acc[snapshot.orderId] = acc[snapshot.orderId] || []
      acc[snapshot.orderId].push(snapshot)
      return acc
    }, {})

  return orders.map(order => {
    const curentSnapshots = getAllSnapshots(order.snapshotsByStatus)
    const curentSnapshotsById = byId(curentSnapshots)
    const orderUpdatedSnapshots = snapshotsByOrderId[order.orderId] || []
    const orderUpdatedSnapshotsById = byId(orderUpdatedSnapshots)

    const updatedSnapshots = curentSnapshots
      .map(snapshot => {
        return orderUpdatedSnapshotsById[snapshot._id] || snapshot
      })

    const newSnapshots = orderUpdatedSnapshots
      .filter(({_id}) => !curentSnapshotsById[_id])

    return docsToOrderEntity({docs: updatedSnapshots.concat(newSnapshots), locationId, status})
  })
}
