const { getNextDate } = require('./list-delivery-dates')
const subDays = require('date-fns/sub_days')

const yesterday = subDays(new Date(), 1)
exports.listCountDates = function (service, refDate = yesterday) {
  const props = {
    day: 'countDay',
    frequency: 'countFrequencyWeeks',
    start: 'firstCountDate'
  }

  return getNextDate(service, refDate, props, this.mainApi)
}
