module.exports = validateProduct
const isEqual = require('lodash/isEqual')
const toString = require('lodash/toString')

function transformValidation (validations = []) {
  return validations.map(validation => {
    return typeof validation === 'string' ? {field: validation} : validation
  })
}

function validateProduct (product, options = {}) {
  if (!product.code) throw new Error('Property \'code\' is required')
  if (new RegExp(/\s/).test(product.code)) throw new Error(`Code must not contain space(s)`)
  if (!product._id) throw new Error('Property \'_id\' is required')
  if (!product.type) throw new Error('Property \'type\' is required')
  if (product.type !== 'product') throw new Error('Property \'type\' must be set to \'product\'')
  if (!product.name) throw new Error('Property \'name\' is required')
  if (!product.createdAt) throw new Error('Property \'createdAt\' is required')
  if (!product.createdBy) throw new Error('Property \'createdBy\' is required')

  // additional validations based on options
  const {additionalValidations = []} = options

  for (let validation of transformValidation(additionalValidations)) {
    const value = product[validation.field]
    if (!value) {
      throw new Error(`Property '${validation.field}' is required`)
    }
    if (validation.value && !isEqual(value, validation.value)) {
      throw new Error(`Property '${validation.field}' can only be ${toString(validation.value)}`)
    }

    // Validate property based on regex
    // These regexes are re-used, do not use global flags
    // https://geekingfrog.com/blog/post/reuse-javascript-regexp-global-flag-gotcha
    if (validation.regex && !validation.regex.test(value)) {
      throw new Error(`Property '${validation.field}' format must be like ${validation.sample} - was "${value}"`)
    }
  }
}
