import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'
import kebabCase from 'lodash/kebabCase'
import isEmpty from 'lodash/isEmpty'

import { PriceDisplay } from '@fielded/shared-ui'

const SubscriptionsTable = ({
  rows,
  currency,
  total,
  country,
  appName = ''
}) => {
  const items = rows.map(row => {
    const { hasIssue, cols = [] } = row

    if (isEmpty(cols)) {
      return null
    }

    const columns = cols.map(col => {
      const colName = kebabCase(col.name)
      const colValue = col.value < 0 ? '?' : col.value
      if (col.url) {
        return <td className={`inventory-table__td--${colName}`}>
          <Link to={col.url}>{ colValue }</Link>
        </td>
      }
      return (
        <td className={`inventory-table__td--${colName}`}>{ colValue }</td>
      )
    })

    return (
      <tr
        key={`${row.key}`}
        className={cx(
          'inventory-table__tr',
          {'inventory-table__tr--issue': hasIssue}
        )}
      >
        { columns }
      </tr>
    )
  })

  return (
    <table className={cx(
      'inventory-table', 'inventory-table--subscription'
    )}>
      <thead>
        <tr className='inventory-table__tr'>
          <th className='inventory-table__th inventory-table__th--products'>
            Products
          </th>
          <th className='inventory-table__th inventory-table__th--opening'>
            Opening
          </th>
          <th className='inventory-table__th inventory-table__th--on-shelf'>
            On Shelf
          </th>
          <th className='inventory-table__th inventory-table__th--unit-price'>
            Unit Price ({currency})
          </th>
          <th className='inventory-table__th inventory-table__th--total'>
            Total ({currency})
          </th>
        </tr>
      </thead>
      <tbody>
        { items }
      </tbody>
      {total > 0 &&
        <tfoot>
          <tr>
            <td colSpan={5} className='delivery-note-table__td--summary'>
              <div className='delivery-note-table__total'>CURRENT VALUE OF {appName.toUpperCase()} PRODUCTS</div>
            </td>
            <td className='delivery-note-table__td--value'>{currency}{total < 0 ? '?' : <PriceDisplay value={total} country={country} />}</td>
          </tr>
        </tfoot>
      }
    </table>
  )
}

SubscriptionsTable.propTypes = {
  /* An array of rows */
  rows: PropTypes.array,
  /* Currency in which the product is sold */
  currency: PropTypes.string
}

export default SubscriptionsTable
