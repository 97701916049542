import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { ExclamationCircle } from '../../icons'

/**
 * Generic component to show app-level messages.
 */
const Banner = ({
  children,
  title,
  icon,
  cta,
  footer,
  type,
  inline,
  tight,
  center,
  className
}) => (
  <div
    className={classnames(
      'vs-banner',
      {'vs-banner--inline': inline},
      {'vs-banner--has-cta': cta},
      {'vs-banner--center': center},
      {'vs-banner--tight': tight},
      {'vs-banner--attention': type === 'attention'},
      {'vs-banner--info': type === 'info'},
      {'vs-banner--success': type === 'success'},
      {'vs-banner--warning': type === 'warning'},
      {'vs-banner--danger': type === 'danger'},
      className
    )}
  >
    <div className='vs-banner__content'>
      {title && (
        <div className='vs-banner__title'>
          <div className='vs-banner__icon'>
            {icon || <ExclamationCircle />}
          </div>
          {title}
        </div>
      )}
      {children}
    </div>
    {cta && (
      <div className='vs-banner__cta'>
        {cta}
      </div>
    )}
    {footer && (
      <div className='vs-banner__footer'>
        {footer}
      </div>
    )}
  </div>
)

Banner.propTypes = {
  /**
   * Something to render inside the banner. Can be text or any tag valid by HTML standards.
   */
  children: PropTypes.node,

  /**
   * Optional title to summarize the issue for the user at a glance.
   */
  title: PropTypes.string,

  /**
   * Optional icon (defaults to ExclamationCircle). Used for the Toast component.
   */
  icon: PropTypes.node,
  /**
   * optional Button, will be placed next to content on larger screens
   */
  cta: PropTypes.node,

  /**
   * Footer, does not have the content padding
   */
  footer: PropTypes.node,

  /**
   * The type of feedback, determines color scheme.
   */
  type: PropTypes.oneOf(['attention', 'info', 'success', 'warning', 'danger']),

  /**
   * To inline banner in another component (i.e. not at the top of the window)
   */
  inline: PropTypes.bool,

  /**
   * Renders the Banner with less spacing inside, for use in compact areas
   */
  tight: PropTypes.bool,

  /**
   * Centers the content with a max width on wide banners
   */
  center: PropTypes.bool
}

Banner.defaultProps = {
  children: undefined,
  title: undefined,
  cta: undefined,
  type: 'info',
  inline: false,
  tight: false,
  center: true
}

export default Banner
