const { format, addDays, addWeeks, getDay, subDays } = require('date-fns')
const { applyDeliveryRules } = require('../routes/api/read/list-delivery-dates')

// Numeric keys represent the delivery day (0 is Sunday).
//
// cut off time = route next delivery date - MARKETS[market]
//
// Example: for FCT the cut off date for Monday deliveries is
// `next delivery date` - 4 and for Friday deliveries is
// `next delivery day` - 3
const MARKETS = {
  oyo: 5,
  fct: {
    1: 4,
    5: 3,
    default: 4
  },
  nasarawa: {
    1: 4,
    5: 3,
    default: 4
  },
  edo: 4,
  lagos: 4,
  nairobi: 3,
  coastal: 4,
  nyanza: 4,
  ph: 4,
  kaduna: 3

}

const cutOffTime = (market, nextDeliveryDate) => {
  market = market.toLowerCase()
  const day = getDay(nextDeliveryDate)
  if (!MARKETS[market]) return null
  let subtraction = MARKETS[market]
  if (typeof MARKETS[market] === 'object') {
    subtraction = MARKETS[market][day] || MARKETS[market]['default']
  }
  return subDays(nextDeliveryDate, subtraction)
}

const notificationSendDay = cutOffTime => cutOffTime ? subDays(cutOffTime, 1) : null

const nextDeliveryDate = (firstDeliveryDate, frequency, nextDay) => {
  firstDeliveryDate = new Date(firstDeliveryDate)
  const weekDay = firstDeliveryDate.getDay()
  nextDay = nextDay ? addDays(new Date(nextDay), 1) : new Date()

  const dates = applyDeliveryRules({
    frequency,
    start: firstDeliveryDate,
    day: weekDay,
    refDate: nextDay
  })

  return dates[0]
}

const nextDeliveryDateAfterCutOff = ({
  route,
  market,
  after,
  items = 1,
  weeksAfter = 0,
  getAllDates,
  restrictedDates = [],
  customFrequency
}) => {
  if (!route || !market) {
    throw new Error('Pass in route and market')
  }
  const day = route.day
  const start = route.firstDeliveryDate
  const frequency = customFrequency || route.deliveryFrequencyWeeks
  const dateUntil = addWeeks(new Date(), weeksAfter)

  if (after) {
    after = addDays(after, 1)
  } else {
    after = new Date()
  }

  let dates = applyDeliveryRules({
    frequency,
    start,
    day,
    refDate: after,
    endDate: weeksAfter > 0 && dateUntil
  }) || []

  const today = new Date()
  if (restrictedDates.length) {
    dates = dates.filter(date => {
      const dateFormatted = format(date, 'YYYY-MM-DD')
      return !restrictedDates.includes(dateFormatted)
    })
  }
  if (!getAllDates) {
    dates = dates.filter(date => {
      const cutOff = cutOffTime(market, date)
      return cutOff > today
    })
  }

  if (items === 1) {
    return dates[0]
  }

  return dates.slice(0, items).filter(x => x)
}

exports.nextDeliveryDateAfterCutOff = nextDeliveryDateAfterCutOff
exports.nextDeliveryDate = nextDeliveryDate
exports.cutOffTime = cutOffTime
exports.notificationSendDay = notificationSendDay
