import React from 'react'

import { Page, ShelfLifeLogo, Text } from '@fielded/shared-ui'

import LoginIntercomLink from './LoginIntercomLink'

const EntryViewWrapper = ({ children, config, showIntercom = false }) => {
  const {
    app,
    name,
    shortName,
    program
  } = config

  const isPSM = app === 'psm'
  const isShelfLife = app === 'shelflife'

  const loginProgramHead = (
    <div className='login__intro'>
      {name && isShelfLife && (
        <h1 className='login__logo'>
          <ShelfLifeLogo fill='#fff' />
        </h1>
      )}

      {name && isPSM && (
        <Text.Heading className='login__app-name'>
          {name} {`(${shortName})`}
        </Text.Heading>
      )}

      {program && <Text.Heading className='login__program'>{program}</Text.Heading>}
    </div>
  )

  return (
    <div className='login'>
      <Page
        situation='ready'
        className='login__main'
        contentClassName='login__main-inner'
        title={config.title}
      >
        {isShelfLife && (<div className='login__texture' />)}
        {loginProgramHead}

        <Page.Panel
          withBackground
          withMargin
          className='login__panel'
        >
          {children}
        </Page.Panel>

        {showIntercom && (
          <LoginIntercomLink config={config} />
        )}
      </Page>
    </div>
  )
}

export default EntryViewWrapper
