const DepositAdapter = require('./deposit-adapter')

class DepositApi {
  constructor (
    state,
    pgConnection
  ) {
    const { user = {} } = state
    const username = user.name

    if (pgConnection) {
      this.deposit = new DepositAdapter(pgConnection, username)
    }
  }
}

module.exports = DepositApi
