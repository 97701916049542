const PGAdapter = require('./../common/pg-adapter')
const { calculateManagedCreditFee } = require('./tools')
const LOAN_LIMIT_HISTORY_TABLE_NAME = 'avocado.data_loan_limit_history'
const LOAN_LIMIT_HISTORY_COLUMNS = [
  'id',
  'location_id',
  'loan_limit',
  'managed_credit_fee',
  'created_at'
]
class LoanLimitHistoryAdapter extends PGAdapter {
  constructor (location, pgConnection, username) {
    super(
      pgConnection,
      LOAN_LIMIT_HISTORY_TABLE_NAME,
      username,
      LOAN_LIMIT_HISTORY_COLUMNS,
      'id'
    )
    this.location = location
  }

  async getLatestHistory (locationId) {
    const { results } = await this.getList({
      ordering: '-created_at',
      filter: {
        location_id: locationId
      },
      limit: 1,
      offset: 0
    })
    return results[0]
  }

  async updateLoanLimitHistory (locationId) {
    const location = await this.location.restAdapter.get(locationId)
    const latestRecords = await this.getLatestHistory(locationId)
    if (latestRecords && latestRecords.loan_limit === location.loan_limit) {
      return Promise.resolve(latestRecords)
    }
    const managedCreditFee = calculateManagedCreditFee(location.loan_limit)
    return this.create({
      location_id: locationId,
      loan_limit: location.loan_limit || 0,
      managed_credit_fee: managedCreditFee
    })
  }
}

module.exports = LoanLimitHistoryAdapter
