const { locationListIsEditable } = require('../utils')

exports.listLocations = function (
  {locations, orders, productId, groupId}
) {
  const ordersByDestinationId = orders
    .reduce((acc, order) => {
      acc[order.destinationId] = acc[order.destinationId] || []
      acc[order.destinationId].push(order)
      return acc
    }, {})

  const rows = locations
    .map(location =>
      getLocationRow({ordersByDestinationId, productId, location, groupId})
    )

  const canEdit = locationListIsEditable({groupId, locations})

  return {locationRows: rows, canEdit}
}

function getLocationRow ({ordersByDestinationId, productId, location, groupId}) {
  const orderDetails = {original: 0, adjusted: 0, inOtherOrders: 0}
  const row = Object.assign({}, location, {order: orderDetails})
  const ordersOnLocation = ordersByDestinationId[location._id]
  if (!ordersOnLocation) return row

  ordersOnLocation.forEach(orderOnLocation => {
    const orderProduct = orderOnLocation.products[productId]
    if (!orderProduct) return
    row.order.original += orderProduct.original

    if (groupId && orderOnLocation.groupId !== groupId) {
      row.order.inOtherOrders += orderProduct.adjusted
      return
    }

    row.groupId = orderOnLocation.groupId
    row.order.adjusted += orderProduct.adjusted
    // we don't want these to be aggregate numbers
    row.order.soh = orderProduct.soh || 0
    row.order.consumed = orderProduct.consumed || 0
    row.order.comments = orderProduct.comments || []
  })

  return row
}
