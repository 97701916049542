import React from 'react'
import { Redirect } from 'react-router-dom'
import { withAuth } from './../../../common/AuthenticationProvider'

import { buildProgramsUrl } from './routes-urls'

const Home = ({locationId}) => {
  return <Redirect to={buildProgramsUrl({locationId, date: new Date()})} />
}

export default withAuth(({user}) => ({locationId: user.location.id}))(Home)
