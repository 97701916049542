import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Text from '../Text'
import { Arrow } from '../../icons'

const StatusCardFooter = ({
  className,
  children,
  boldLink
}) => (
  <div
    className={classnames(
      'vs-status-card__footer',
      className
    )}
  >
    { children &&
      <div className='vs-status-card__footer-content'>
        { children }
      </div>
    }

    <div className='vs-status-card__footer-cta-container'>
      <Text
        size='small'
        color='brand'
        tight
        tag='span'
        bold={boldLink}
        className='vs-status-card__footer-cta'
      >
        Details <Arrow direction='right' />
      </Text>
    </div>
  </div>
)

StatusCardFooter.propTypes = {
  /**
   * The status card footer left panel
   */
  children: PropTypes.node,
  /**
   * Set custom CSS classes to extend the StatusCardFooter for your needs.
   */
  className: PropTypes.string,
  /**
   * It gives the link text a bold font style.
   */
  boldLink: PropTypes.bool
}

StatusCardFooter.defaultProps = {
  className: '',
  children: undefined,
  boldLink: false
}

StatusCardFooter.displayName = 'StatusCard.Footer'

export default StatusCardFooter
