import React from 'react'
import PropTypes from 'prop-types'

const PanelHeaderCta = ({ children }) => (
  <div className='vs-panel-header__cta-wrapper'>
    <div className='vs-panel-header__cta'>
      {children}
    </div>
  </div>
)

PanelHeaderCta.propTypes = {
  children: PropTypes.node.isRequired
}

PanelHeaderCta.displayName = 'Page.Panel.Header.Cta'

export default PanelHeaderCta
