import React from 'react'

import { ExpandableList, TotalAmount } from '@fielded/shared-ui'

const PaymentConfirmationSection = ({
  countryId,
  totalAmount,
  headerLabel,
  children,
  totalFootnote,
  isProvisional
}) => {
  return (
    <section className='payment-confirmation-section'>
      <h2 className='payment-confirmation-section__header'>
        {`${children.length} x ${headerLabel}`}
      </h2>
      <ExpandableList
        buttonClassname='payment-confirmation-section__toggle'
      >
        {children}
      </ExpandableList>
      <TotalAmount
        label='Sub-total to pay'
        value={totalAmount}
        countryId={countryId}
        footnote={totalFootnote}
        isTight
        withPagePadding
        isProvisional={isProvisional}
      />
    </section>
  )
}

PaymentConfirmationSection.defaultProps = {
  countryId: null,
  children: [],
  totalAmount: 0,
  headerLabel: 'products',
  totalFootnote: ''
}

export default PaymentConfirmationSection
