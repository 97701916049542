import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'

import AdvancedFilters from '../AdvancedFilters'
import FilterButton from '../FilterButton'
import FilterInput, { filterByProp, filterByProps } from '../FilterInput'
import ExpandableOptions from '../ExpandableOptions'
import EmptyState from '../EmptyState'
import OptionsGroup from '../OptionsGroup'
import VirtualizedList from '../VirtualizedList'
import { pluralize } from '../../utils/utils'

class VirtualizedListExtended extends Component {
  /**
   * State
   */

  state = {
    sortSelected: this.props.sortingOptions[0],
    filterSelected: this.props.filteringOptions ? this.props.filteringOptions[0] : undefined,
    filterTerm: '',
    isSortExpanded: false,
    isFilteringExpanded: false,
    selectedFilters: this.props.starterFilters,
    selectedFiltersArray: cloneDeep(this.props.starterFilters),
    itemsFiltered: this.props.items,
    advancedFiltersVisible: false,
    anyFiltersSelected: false
  }

  handleOpenAdvancedFiltersModal = () => {
    this.setState({
      advancedFiltersVisible: true
    })
  }

  handleCloseAdvancedFiltersModal = () => {
    this.setState({
      advancedFiltersVisible: false
    })
  }

  handleToggleOptionVisibility = (option) => {
    const { visibleFilters } = this.props
    const updatedVisibleFilters = visibleFilters
    updatedVisibleFilters[option] = !visibleFilters[option]
    this.setState({
      visibleFilters: updatedVisibleFilters
    })
  }

  handleSortChange = (selected) => {
    const selectedSortingOption = this.props.sortingOptions.find(opt => opt.value === selected)

    this.setState({
      sortSelected: selectedSortingOption,
      isSortExpanded: false
    })
  }

  handleFilterChange = (selected) => {
    const selectedFilteringOption = this.props.filteringOptions.find(opt => opt.value === selected)

    this.setState({
      filterSelected: selectedFilteringOption,
      isFilteringExpanded: false
    })
  }

  handleFilterInput = (term) => {
    this.setState({ filterTerm: term })
  }

  handleToggleExpandSort = (expanded) => {
    this.setState({
      isSortExpanded: !expanded,
      isFilteringExpanded: false
    })
  }

  handleToggleExpandFilter = (expanded) => {
    this.setState({
      isFilteringExpanded: !expanded,
      isSortExpanded: false
    })
  }

  changeFilterValues = (filter, selection) => {
    const { selectedFilters } = this.state
    const updatedFilters = selectedFilters

    updatedFilters[filter].selected = selection

    this.setState({
      selectedFilters: updatedFilters
    })
  }

  removeSingleFilter = (filter, valueRemoved) => {
    const { selectedFilters } = this.state
    let updatedSelectedFilters = selectedFilters
    const updatedSelection = updatedSelectedFilters[filter].selected.filter(item => item !== valueRemoved)

    updatedSelectedFilters = {
      ...updatedSelectedFilters,
      [filter]: {
        rule: updatedSelectedFilters[filter].rule,
        selected: updatedSelection,
        isBoolean: updatedSelectedFilters[filter].isBoolean
      }
    }

    this.setState({
      selectedFilters: cloneDeep(updatedSelectedFilters)
    })

    this.updateListWithFilters(updatedSelectedFilters)
  }

  clearFilters = () => {
    const { selectedFilters } = this.state
    const updatedFilters = selectedFilters
    Object.keys(updatedFilters).forEach(filter => {
      updatedFilters[filter].selected = []
    })

    this.setState({
      selectedFilters: updatedFilters
    })

    this.updateListWithFilters(updatedFilters)
    this.handleCloseAdvancedFiltersModal()
  }

  updateListWithFilters = (filters) => {
    const { selectedFilters } = this.state
    const updatedFilters = filters || selectedFilters
    const { items } = this.props
    const anyFilterPopulated = Object.keys(updatedFilters).some(filter => {
      return updatedFilters[filter].selected.length
    })

    let uniqueResults = items

    if (anyFilterPopulated) {
      let combinedResult = []
      let booleanFilters = []

      Object.keys(updatedFilters).forEach(filter => {
        const filterList = updatedFilters[filter].selected
        const isBoolean = updatedFilters[filter].isBoolean
        const useSoundex = updatedFilters[filter].useSoundex
        if (filterList.length) {
          const allItems = items

          if (!isBoolean) {
            filterList.forEach(item => {
              const filterResults = filterByProp(allItems, item, updatedFilters[filter].rule, useSoundex)
              combinedResult = [...combinedResult, ...filterResults]
            })
          }

          if (isBoolean) {
            booleanFilters = [...booleanFilters, filter]
          }
        }
      })

      booleanFilters.forEach(filter => {
        const filterList = updatedFilters[filter].selected
        if (filterList.length) {
          const allItems = items
          const listToFilter = combinedResult.length ? combinedResult : allItems
          // Since it is boolean attr we want to only look at the already prefiltered results. We always assume truthy value to filter.
          const filterResults = listToFilter.filter(item => {
            const rule = updatedFilters[filter].rule
            if (Array.isArray(rule)) {
              return !rule.some((singleRule) => {
                return !get(item, `${singleRule}`)
              })
            }

            return get(item, `${rule}`)
          })
          combinedResult = filterResults
        }
      })

      uniqueResults = [...new Set(combinedResult)]
    }

    this.setState({
      itemsFiltered: uniqueResults,
      selectedFiltersArray: cloneDeep(updatedFilters),
      anyFiltersSelected: anyFilterPopulated
    })
    this.handleCloseAdvancedFiltersModal()
  }

  // removing unnedeed fields to make OptionsGroup happy
  sortOptionsLabels = this.props.sortingOptions.map(opt => (
    { value: opt.value, label: opt.label }
  ))

  filterOptionsLabels = this.props.filteringOptions ? this.props.filteringOptions.map(opt => (
    { value: opt.value, label: opt.label }
  )) : []

  /**
   * Render
   */
  render () {
    const {
      items,
      itemComponent,
      componentHeight,
      itemName,
      filterRule,
      getItemKey,
      withBorder,
      withShadow,
      className,
      filteringOptions,
      customFilteringFunction,
      customLoggingFunction,
      additionalFilters,
      visibleFilters,
      customSearchPlaceholder
    } = this.props

    const {
      sortSelected,
      filterTerm,
      filterSelected,
      isSortExpanded,
      isFilteringExpanded,
      itemsFiltered,
      advancedFiltersVisible,
      selectedFilters,
      selectedFiltersArray,
      anyFiltersSelected
    } = this.state

    // sort and filter
    const sortBy = sortSelected.sortBy
    const itemListToUse = itemsFiltered
    let filteredByTermItems = itemListToUse
    if (typeof filterRule === 'string') {
      filteredByTermItems = filterByProp(itemListToUse, filterTerm, filterRule, true)
    } else if (typeof filterRule === 'object') {
      filteredByTermItems = filterByProps(itemListToUse, filterTerm, filterRule, true)
    }

    let filteredByExtraFiltersItems = filteredByTermItems

    let additionalItemsList = []

    // There is an option to pass a custom function to create a new additional list of results.
    // The function will take list of all items and list of filtered items.
    // Example usage: if result list is small, we make a list of additional props to search with the list again

    if (customFilteringFunction) {
      additionalItemsList = customFilteringFunction(items, filteredByExtraFiltersItems)
    }

    let filteredItems = filteredByExtraFiltersItems
    let filteredAdditonalItems = additionalItemsList
    if (filteringOptions && filterSelected.filterProp) {
      filteredItems = filteredByExtraFiltersItems.filter(item => item[filterSelected.filterProp] === filterSelected.filterValue)
    }

    const sortedItems = orderBy(filteredItems, sortBy.prop, sortBy.dir)

    const sortedAdditonalItems = orderBy(filteredAdditonalItems, sortBy.prop, sortBy.dir)

    const results = [...sortedItems, ...sortedAdditonalItems]

    if (customLoggingFunction) {
      customLoggingFunction(filterTerm, selectedFilters, results)
    }

    // sorting options
    const sortingOptionsElement = (
      <OptionsGroup
        options={this.sortOptionsLabels}
        defaultValue={sortSelected.value}
        onValueChange={this.handleSortChange}
        inRows
        spread
      />
    )

    // filtering options
    let filteringOptionsElement
    if (filteringOptions) {
      filteringOptionsElement = (
        <OptionsGroup
          options={this.filterOptionsLabels}
          defaultValue={filterSelected.value}
          onValueChange={this.handleFilterChange}
          inRows
          spread
        />
      )
    }

    return (
      <Fragment>
        <div className='vs-u-row vs-u-row-centered vs-u-gap vs-u-margin-bottom'>
          <FilterInput
            placeholder={customSearchPlaceholder || `Filter ${pluralize(itemName, 2)}...`}
            onValueChange={this.handleFilterInput}
            autoHideClearButton
            className={classnames(
              'vs-virtualized-list-extended__search'
            )}
          />
          {additionalFilters && (
            <AdvancedFilters
              availableFilters={additionalFilters}
              selectedFilters={selectedFilters}
              visibleFilters={visibleFilters}
              onApplyFilters={this.updateListWithFilters}
              onClearFilters={this.clearFilters}
              onChangeFilterValues={this.changeFilterValues}
              modalVisible={advancedFiltersVisible}
              onCloseModal={this.handleCloseAdvancedFiltersModal}
              onOpenModal={this.handleOpenAdvancedFiltersModal}
              onToggleOption={this.handleToggleOptionVisibility}
            />
          )}
        </div>

        {/* Show result number of results */}
        <div
          className='vs-u-margin-bottom-half'
        >
          Showing { results.length } {pluralize(itemName, results.length)} {anyFiltersSelected && 'for following filters:'}
        </div>

        <div className='vs-virtualized-list-extended__applied-filters'>
          {selectedFiltersArray && Object.entries(selectedFiltersArray).map(([filterName, {rule, selected}]) => {
            return selected.map(item => {
              return (
                <FilterButton
                  key={item}
                  colorVariant='dark'
                  fill='outline'
                  onClick={() => this.removeSingleFilter(filterName, item)}
                  size='small'
                >
                  {item}
                </FilterButton>
              )
            })
          })}
        </div>

        <div
          className={
            classnames(
              'vs-virtualized-list-extended',
              {'vs-virtualized-list-extended--with-shadow': withShadow},
              className
            )
          }
        >
          {/* List Header */}
          <div
            className={classnames(
              'vs-virtualized-list-extended__header',
              {'vs-virtualized-list-extended__header--spread': filteringOptions}
            )}
          >
            {filteringOptions && (
              <div className='vs-virtualized-list-extended__dropdown-container'>
                <span className='vs-virtualized-list-extended__dropdown-label'>
                  Showing:
                </span>
                <ExpandableOptions
                  label={filterSelected.label}
                  isExpanded={isFilteringExpanded}
                  expandWrapperClassName='vs-virtualized-list-extended__dropdown'
                  expandableChildren={filteringOptionsElement}
                  expandSide='right'
                  onExpand={this.handleToggleExpandFilter}
                />
              </div>
            )}

            <ExpandableOptions
              label='Sort by'
              isExpanded={isSortExpanded}
              expandWrapperClassName='vs-virtualized-list-extended__dropdown'
              expandableChildren={sortingOptionsElement}
              expandSide='left'
              onExpand={this.handleToggleExpandSort}
            />
          </div>

          <div className='vs-virtualized-list-extended__list-wrapper'>
            {/* Virtualized List */}
            <div className='vs-virtualized-list-extended__list'>
              <VirtualizedList
                componentHeight={componentHeight}
                items={results}
                itemComponent={itemComponent}
                classNameItemComponent={
                  classnames(
                    'vs-virtualized-list-extended__list-item',
                    {'vs-virtualized-list-extended__list-item--with-border': withBorder}
                  )
                }
                getItemKey={getItemKey}
              />
            </div>

            {/* Show empty state when no products result after filtering */}
            {results.length === 0 && <EmptyState
              text='No matching products'
              className='vs-virtualized-list-extended__empty' />
            }
          </div>
        </div>
      </Fragment>
    )
  }
}

VirtualizedListExtended.propTypes = {
  /**
   * Items array
   */
  items: PropTypes.array.isRequired,
  /**
   * Row component
   */
  itemComponent: PropTypes.func.isRequired,
  /**
   * Component height. Virtualized list needs to know the height to render efficiently
   */
  componentHeight: PropTypes.number.isRequired,
  /**
   * A simple label of the item rendered. (ie: Product, People, Location, etc)
   */
  itemName: PropTypes.string.isRequired,
  /**
   * A rule to use when filtering [''] ie       filterRule={['name', 'additionalData.category']}
   * or filterRule='name'
   */
  filterRule: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  /**
   * Sorting options. The defult one is the first item in the array
   *
   * The array should be similar to this where prop indicates the field to sort and dir indicates
   * the sorting direction
   *
   *  sortingOptions = [
   *    { value: 'name-a-z', label: 'Name A to Z', sortBy: { prop: 'name', dir: 'asc' } },
   *    { value: 'name-z-a', label: 'Name Z to A', sortBy: { prop: 'name', dir: 'desc' } },
   *  ]
   *
   */
  sortingOptions: PropTypes.array.isRequired,
  /**
   * Filtering options. The defult one is the first item in the array
   *
   * The array should be similar to this where prop indicates the field to filter and
   *
   *  filteringOptions = [
   *    { value: 'all', label: 'All products', filterProp: '', filterValue: '' },
   * { value: 'pay_as_you_sell', label: 'Pay As You Sell', filterProp: 'type', filterValue: 'pay_as_you_sell' },
   *  ]
   */
  filteringOptions: PropTypes.array,
  /**
   * Determines if the border between items should be shown
   */
  withBorder: PropTypes.bool,
  /**
   * Determines if the shadow around the component should be shown
   */
  withShadow: PropTypes.bool,
  /**
   * Additional className
   */
  className: PropTypes.string,
  /**
   * A custom function to create an additional partition of results outside of regular result
   */
  customFilteringFunction: PropTypes.func,
  /**
   * A custom funtion to be invoked after filter term is changed. Will invoke with three props: filterTerm, selected filters and the results of filtering.
   */
  customLoggingFunction: PropTypes.func
}

VirtualizedListExtended.defaultProps = {
  withBorder: true,
  withShadow: true,
  filteringOptions: undefined,
  filterSelected: undefined
}

export default VirtualizedListExtended
