import * as React from 'react'
import withConfig from '../../../van-shared/hoc/withConfig/withConfig'
import ShipmentsImport from './ShipmentsImport'
import { withApi } from '../../../common/ApiProvider'

const initialState = {
  currentStep: 'import',
  loading: false,
  previewData: {},
  importData: null,
  error: null
}

class ShipmentsImportContainer extends React.PureComponent {
  state = {...initialState}

  getImportTemplateUrl = async () => {
    this.setState({ loading: true })

    const data = await this.props.api.shipment.getImportTemplate()
    const blob = new global.Blob([
      new global.Uint8Array(data)
    ], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })

    return global.URL.createObjectURL(blob)
  }

  handleImported = async (importData) => {
    try {
      const previewData = await this.props.api.shipment.importShipmentsFromSheet(
        importData, {dryRun: true}
      )
      this.setState({
        loading: false,
        currentStep: 'confirm',
        error: null,
        // We have to save this from the import button to give to confirm
        // cus it ain't coming back.
        importData,
        previewData
      })
    } catch (error) {
      this.setState({...initialState, currentStep: 'import', error})
    }
  }

  handleConfirm = () => new Promise(async (resolve, reject) => {
    this.setState({loading: true})
    const {importData} = this.state
    try {
      await this.props.api.shipment.importShipmentsFromSheet(importData)
      this.setState({
        ...initialState,
        loading: false,
        currentStep: 'finished',
        error: null
      })
    } catch (error) {
      this.setState({...initialState, currentStep: 'import', error})
    }
  })

  render () {
    const { history, config } = this.props
    const {
      loading,
      error,
      previewData,
      currentStep
    } = this.state

    return (
      <ShipmentsImport
        currentStep={currentStep}
        error={error}
        history={history}
        loading={loading}
        onError={error => this.setState({loading: false, error})}
        onExport={this.getImportTemplateUrl}
        onExported={() => this.setState({currentStep: 'import', loading: false})}
        onImported={this.handleImported}
        onCancel={() => this.setState({...initialState})}
        onConfirm={this.handleConfirm}
        onStepChange={nextStep => this.setState({currentStep: nextStep})}
        previewData={previewData}
        config={config}
      />
    )
  }
}

export default withApi(withConfig(ShipmentsImportContainer))
