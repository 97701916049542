module.exports = locationIdToParent

const locationIdToProperties = require('./location-id-to-properties')

function locationIdToParent (locationId) {
  const location = locationIdToProperties(locationId)
  switch (location.level) {
    case 'lga':
      return `zone:${location.zone}:state:${location.state}`
    case 'state':
      return `zone:${location.zone}`
    case 'zone':
      return 'national'
    case 'national':
      return 'country'
  }
}
