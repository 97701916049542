'use strict';
var formats = require('ajv/lib/compile/formats')();
var validate = (function() {
  var pattern0 = new RegExp('^program:[a-z][a-zA-Z-]*:service:[a-zA-Z0-9-]+$');
  var refVal = [];
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    'use strict'; /*# sourceURL=https://schema.field.partners/1.0/get-ledger-balance-params# */
    var vErrors = null;
    var errors = 0;
    if (rootData === undefined) rootData = data;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      if (true) {
        var errs__0 = errors;
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !(false || validate.schema.properties.hasOwnProperty(key0));
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
            break;
          }
        }
        if (valid1) {
          var data1 = data.location;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'location'
              },
              message: 'should have required property \'location\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
              var errs__1 = errors;
              var valid2 = true;
              if (data1.id === undefined) {
                valid2 = true;
              } else {
                var errs_2 = errors;
                if (typeof data1.id !== "string") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.location.id',
                    schemaPath: '#/properties/location/properties/id/type',
                    params: {
                      type: 'string'
                    },
                    message: 'should be string'
                  }];
                  return false;
                }
                var valid2 = errors === errs_2;
              }
              if (valid2) {}
              if (errs__1 == errors) {}
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.location',
                schemaPath: '#/properties/location/type',
                params: {
                  type: 'object'
                },
                message: 'should be object'
              }];
              return false;
            }
            if (errors === errs_1) {}
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            var data1 = data.date;
            if (data1 === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if ((typeof data1 === "number")) {
                if (true) {}
              }
              if (errors === errs_1) {
                if (typeof data1 === "string") {
                  if (!formats['date-time'].test(data1)) {
                    validate.errors = [{
                      keyword: 'format',
                      dataPath: (dataPath || '') + '.date',
                      schemaPath: '#/properties/date/format',
                      params: {
                        format: 'date-time'
                      },
                      message: 'should match format "date-time"'
                    }];
                    return false;
                  } else {}
                } else {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.date',
                    schemaPath: '#/properties/date/type',
                    params: {
                      type: 'string'
                    },
                    message: 'should be string'
                  }];
                  return false;
                }
                if (errors === errs_1) {}
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              var data1 = data.service;
              if (data1 === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'service'
                  },
                  message: 'should have required property \'service\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
                  var errs__1 = errors;
                  var valid2 = true;
                  var data2 = data1.id;
                  if (data2 === undefined) {
                    valid2 = true;
                  } else {
                    var errs_2 = errors;
                    if (typeof data2 === "string") {
                      if (!pattern0.test(data2)) {
                        validate.errors = [{
                          keyword: 'pattern',
                          dataPath: (dataPath || '') + '.service.id',
                          schemaPath: '#/properties/service/properties/id/pattern',
                          params: {
                            pattern: '^program:[a-z][a-zA-Z-]*:service:[a-zA-Z0-9-]+$'
                          },
                          message: 'should match pattern "^program:[a-z][a-zA-Z-]*:service:[a-zA-Z0-9-]+$"'
                        }];
                        return false;
                      } else {}
                    } else {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.service.id',
                        schemaPath: '#/properties/service/properties/id/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      }];
                      return false;
                    }
                    if (errors === errs_2) {}
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    var data2 = data1.program;
                    if (data2 === undefined) {
                      valid2 = true;
                    } else {
                      var errs_2 = errors;
                      if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                        var errs__2 = errors;
                        var valid3 = true;
                        if (data2.reportingPeriod === undefined) {
                          valid3 = true;
                        } else {
                          var errs_3 = errors;
                          if (typeof data2.reportingPeriod !== "string") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.service.program.reportingPeriod',
                              schemaPath: '#/properties/service/properties/program/properties/reportingPeriod/type',
                              params: {
                                type: 'string'
                              },
                              message: 'should be string'
                            }];
                            return false;
                          }
                          var valid3 = errors === errs_3;
                        }
                        if (valid3) {}
                        if (errs__2 == errors) {}
                      } else {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.service.program',
                          schemaPath: '#/properties/service/properties/program/type',
                          params: {
                            type: 'object'
                          },
                          message: 'should be object'
                        }];
                        return false;
                      }
                      if (errors === errs_2) {}
                      var valid2 = errors === errs_2;
                    }
                    if (valid2) {}
                  }
                  if (errs__1 == errors) {}
                } else {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.service',
                    schemaPath: '#/properties/service/type',
                    params: {
                      type: 'object'
                    },
                    message: 'should be object'
                  }];
                  return false;
                }
                if (errors === errs_1) {}
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                var data1 = data.since;
                if (data1 === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  var errs__1 = errors,
                    prevValid1 = false,
                    valid1 = false,
                    passingSchemas1 = null;
                  var errs_2 = errors;
                  if ((typeof data1 === "number")) {
                    if (true) {}
                  }
                  if (errors === errs_2) {
                    if (typeof data1 === "string") {
                      if (!formats['date-time'].test(data1)) {
                        var err = {
                          keyword: 'format',
                          dataPath: (dataPath || '') + '.since',
                          schemaPath: '#/properties/since/oneOf/0/format',
                          params: {
                            format: 'date-time'
                          },
                          message: 'should match format "date-time"'
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      } else {}
                    } else {
                      var err = {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.since',
                        schemaPath: '#/properties/since/oneOf/0/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    }
                    if (errors === errs_2) {}
                  }
                  var valid2 = errors === errs_2;
                  if (valid2) {
                    valid1 = prevValid1 = true;
                    passingSchemas1 = 0;
                  }
                  var errs_2 = errors;
                  if ((typeof data1 === "number")) {
                    if (true) {}
                  }
                  if (errors === errs_2) {
                    if (typeof data1 === "string") {
                      if (!formats.date.test(data1)) {
                        var err = {
                          keyword: 'format',
                          dataPath: (dataPath || '') + '.since',
                          schemaPath: '#/properties/since/oneOf/1/format',
                          params: {
                            format: 'date'
                          },
                          message: 'should match format "date"'
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      } else {}
                    } else {
                      var err = {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.since',
                        schemaPath: '#/properties/since/oneOf/1/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    }
                    if (errors === errs_2) {}
                  }
                  var valid2 = errors === errs_2;
                  if (valid2 && prevValid1) {
                    valid1 = false;
                    passingSchemas1 = [passingSchemas1, 1];
                  } else {
                    if (valid2) {
                      valid1 = prevValid1 = true;
                      passingSchemas1 = 1;
                    }
                  }
                  if (!valid1) {
                    var err = {
                      keyword: 'oneOf',
                      dataPath: (dataPath || '') + '.since',
                      schemaPath: '#/properties/since/oneOf',
                      params: {
                        passingSchemas: passingSchemas1
                      },
                      message: 'should match exactly one schema in oneOf'
                    };
                    if (vErrors === null) vErrors = [err];
                    else vErrors.push(err);
                    errors++;
                    validate.errors = vErrors;
                    return false;
                  } else {
                    errors = errs__1;
                    if (vErrors !== null) {
                      if (errs__1) vErrors.length = errs__1;
                      else vErrors = null;
                    }
                  }
                  if (errors === errs_1) {}
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.withEntities === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.withEntities !== "boolean") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.withEntities',
                        schemaPath: '#/properties/withEntities/type',
                        params: {
                          type: 'boolean'
                        },
                        message: 'should be boolean'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.online === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data.online !== "boolean") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.online',
                          schemaPath: '#/properties/online/type',
                          params: {
                            type: 'boolean'
                          },
                          message: 'should be boolean'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.localOnly === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.localOnly !== "boolean") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.localOnly',
                            schemaPath: '#/properties/localOnly/type',
                            params: {
                              type: 'boolean'
                            },
                            message: 'should be boolean'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.includeScheduledOutbound === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.includeScheduledOutbound !== "boolean") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.includeScheduledOutbound',
                              schemaPath: '#/properties/includeScheduledOutbound/type',
                              params: {
                                type: 'boolean'
                              },
                              message: 'should be boolean'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.excludeProducts === undefined) {
                            valid1 = true;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.excludeProducts !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.excludeProducts',
                                schemaPath: '#/properties/excludeProducts/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.excludeSubscriptions === undefined) {
                              valid1 = true;
                            } else {
                              var errs_1 = errors;
                              if (!Array.isArray(data.excludeSubscriptions)) {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.excludeSubscriptions',
                                  schemaPath: '#/properties/excludeSubscriptions/type',
                                  params: {
                                    type: 'array'
                                  },
                                  message: 'should be array'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                            if (valid1) {
                              if (data.includeOpenOrders === undefined) {
                                valid1 = true;
                              } else {
                                var errs_1 = errors;
                                if (typeof data.includeOpenOrders !== "boolean") {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.includeOpenOrders',
                                    schemaPath: '#/properties/includeOpenOrders/type',
                                    params: {
                                      type: 'boolean'
                                    },
                                    message: 'should be boolean'
                                  }];
                                  return false;
                                }
                                var valid1 = errors === errs_1;
                              }
                              if (valid1) {
                                var data1 = data.orders;
                                if (data1 === undefined) {
                                  valid1 = true;
                                } else {
                                  var errs_1 = errors;
                                  if ((!data1 || typeof data1 !== "object" || Array.isArray(data1))) {
                                    validate.errors = [{
                                      keyword: 'type',
                                      dataPath: (dataPath || '') + '.orders',
                                      schemaPath: '#/properties/orders/type',
                                      params: {
                                        type: 'object'
                                      },
                                      message: 'should be object'
                                    }];
                                    return false;
                                  }
                                  var valid1 = errors === errs_1;
                                }
                                if (valid1) {
                                  if (data.ignoreService === undefined) {
                                    valid1 = true;
                                  } else {
                                    var errs_1 = errors;
                                    if (typeof data.ignoreService !== "boolean") {
                                      validate.errors = [{
                                        keyword: 'type',
                                        dataPath: (dataPath || '') + '.ignoreService',
                                        schemaPath: '#/properties/ignoreService/type',
                                        params: {
                                          type: 'boolean'
                                        },
                                        message: 'should be boolean'
                                      }];
                                      return false;
                                    }
                                    var valid1 = errors === errs_1;
                                  }
                                  if (valid1) {}
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (errs__0 == errors) {}
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    if (errors === 0) {}
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate.schema = {
  "$id": "https://schema.field.partners/1.0/get-ledger-balance-params#",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "getLedgerBalance parameters",
  "type": "object",
  "properties": {
    "location": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string"
        }
      }
    },
    "date": {
      "type": "string",
      "format": "date-time"
    },
    "service": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string",
          "pattern": "^program:[a-z][a-zA-Z-]*:service:[a-zA-Z0-9-]+$"
        },
        "program": {
          "type": "object",
          "properties": {
            "reportingPeriod": {
              "type": "string"
            }
          }
        }
      }
    },
    "since": {
      "oneOf": [{
        "type": "string",
        "format": "date-time"
      }, {
        "type": "string",
        "format": "date"
      }]
    },
    "withEntities": {
      "type": "boolean"
    },
    "online": {
      "type": "boolean"
    },
    "localOnly": {
      "type": "boolean"
    },
    "includeScheduledOutbound": {
      "type": "boolean"
    },
    "excludeProducts": {
      "type": "string"
    },
    "excludeSubscriptions": {
      "type": "array"
    },
    "includeOpenOrders": {
      "type": "boolean"
    },
    "orders": {
      "type": "object"
    },
    "ignoreService": {
      "type": "boolean"
    }
  },
  "required": ["location", "service"],
  "additionalProperties": false
};
validate.errors = null;
module.exports = validate;