// In general, when showing the bundle name we'll like to use
// the `name` prop. The `fullName` prop is there in case at some
// point we need to show all bundles a product belongs to (we
// don't do that anywhere yet).
// Right now `fullName` is also used in the tests.
exports.CORE_BUNDLES = {
  default: {
    name: 'Starter Bundle',
    fullName: 'Starter Bundle'
  },
  ppmv: {
    name: 'Starter Bundle',
    fullName: 'PPMV Starter Bundle'
  }
}
