const { addDays } = require('date-fns')
const { transform } = require('./tools')
const PGAdapter = require('./../common/pg-adapter')
const TRANSACTIONS_TABLE_NAME = 'avocado.data_transaction'
const TRANSACTION_COLUMNS = [
  'id',
  'value_date',
  'narration',
  'amount',
  'created_by',
  'matching_status',
  'booking_status',
  'failed_reason',
  'quickbooks_deposit_data',
  'quickbooks_payment_data',
  'matched_data',
  'created_at',
  'updated_at'
]

// transaction filter via created_at will return only current date transactions
const columnPreparers = {
  created_at: (key, value) => {
    return [
      { statement: `"${key}">=%INDEX%`, value },
      { statement: `"${key}"<%INDEX%`, value: addDays(value, 1) }
    ]
  },
  matching_status: (key, value) => {
    if (value === null) {
      return [{ statement: `"${key}" IS NULL` }]
    }
    return [{ statement: `"${key}"=%INDEX%`, value }]
  },
  booking_status: (key, value) => {
    if (value === false) {
      return [{ statement: `("${key}" IS NULL)` }]
    }
    return [{ statement: `"${key}"=%INDEX%`, value }]
  }
}

class TransactionsAdapter extends PGAdapter {
  constructor (pgConnection, username) {
    super(
      pgConnection,
      TRANSACTIONS_TABLE_NAME,
      username,
      TRANSACTION_COLUMNS,
      'id',
      columnPreparers
    )
  }

  async getList (data) {
    const response = await super.getList(data)
    const { filter, search } = data
    const { whereText, whereValues } = this.getWhereCondition(filter, search)
    const totalMatched = await this.getCount(
      'id',
      `${whereText ? `${whereText} AND "matching_status" = $${whereValues.length + 1}` : 'WHERE "matching_status" = $1'}`,
      [...whereValues, 'Matched']
    )
    const totalBooked = await this.getCount(
      'id',
      `${whereText ? `${whereText} AND ("matching_status" = $${whereValues.length + 1} AND "booking_status" = $${whereValues.length + 2})` : 'WHERE "matching_status" = $1 AND "booking_status" = $2'}`,
      [...whereValues, 'Matched', 'Booked']
    )
    const totalDirectDebit = await this.getCount(
      'id',
      `${whereText ? `${whereText} AND "narration" LIKE $${whereValues.length + 1}` : 'WHERE "narration" LIKE $1'}`,
      [...whereValues, `REM:R%`]
    )
    return {
      ...response,
      totalMatched,
      totalBooked,
      totalDirectDebit
    }
  }

  createBulk (transactions) {
    const data = transform(transactions)
    return super.create(data)
  }
}

module.exports = TransactionsAdapter
