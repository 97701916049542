const StockSummaryToAvocado = require('./stock-summary-to-avocado')
const StockReportReconciler = require('./stock-report-reconciler')

class TransactionApi {
  constructor (state, mainApi, logger) {
    const stockCountDB = state.db
    const stockCountReconciledDB = state.stockCountReconciledDB
    const shipmentsDB = state.shipmentsDb
    const ledgerDB = state.ledgerDB
    this.stockSummaryToAvocado = new StockSummaryToAvocado(mainApi, logger, stockCountDB, shipmentsDB)
    this.stockReportReconciler = new StockReportReconciler(mainApi, logger, stockCountDB, ledgerDB, stockCountReconciledDB)
  }
}

module.exports = TransactionApi
