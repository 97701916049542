import React from 'react'
import PropTypes from 'prop-types'
import ReactNumberFormat from 'react-number-format'

const NumberFormat = ({ ...props }) => <ReactNumberFormat {...props} />

/**
 * These are some of the props available on ReactNumberFormat.
 * You can check the documentation for more props at:
 *  https://www.npmjs.com/package/react-number-format
 */

NumberFormat.propTypes = {
  /**
   * Value to show up
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /**
   * Default value when no value is provided
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /**
   * Whether the field should appear as text or input
   */
  displayType: PropTypes.oneOf(['text', 'input']),
  /**
   * If there should be comma between thousands
   */
  thousandSeparator: PropTypes.bool,
  /**
   * A renderText method useful if you want to render formattedValue
   * in different element other than span.
   * It also returns the custom props that are added to the component
   * which can allow passing down props to the rendered element.
   * (formattedValue, customProps) => React Element
   */
  renderText: PropTypes.func,
  /**
   * Input type attribute
   */
  type: PropTypes.oneOf(['text', 'tel', 'password']),
  /**
   * What should show as a placeholder for input attribute
   */
  placeholder: PropTypes.string,
  /**
   * What should show up before value
   */
  prefix: PropTypes.string,
  /**
   * What should show up after value
   */
  suffix: PropTypes.string,
  /**
   * If defined it limits to given decimal scale
   */
  decimalScale: PropTypes.number,
  /**
   * If true it add 0s to match given decimalScale.
   */
  fixedDecimalScale: PropTypes.bool
}

export default NumberFormat
