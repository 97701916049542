const PGAdapter = require('../../common/pg-adapter')

const PRODUCT_BASE_TABLE_NAME = 'avocado.data_productbase'
const PRODUCT_BASE_COLUMNS = [
  'created_at',
  'updated_at',
  'id',
  'name',
  'api',
  'manufacturer',
  'distributor',
  'therapeutic_class',
  'variety',
  'dosage_qty',
  'dosage_unit',
  'category',
  'substitutes',
  'supplier'
]

const searchColumns = [
  'name'
]

class ProductBaseAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    const opts = {
      strictColumns: false,
      addUuid: false,
      searchColumns
    }
    super(
      pgConnection,
      PRODUCT_BASE_TABLE_NAME,
      username,
      PRODUCT_BASE_COLUMNS,
      'id',
      {},
      logger,
      opts
    )
  }
}

module.exports = ProductBaseAdapter
