const LicenseRestAdapter = require('./data-access/rest-adapter')

class LicenseApi {
  constructor (restAdapter) {
    if (restAdapter) {
      this.restAdapter = new LicenseRestAdapter(restAdapter)
    }
  }
  upload (formData) {
    return this.restAdapter.create(formData)
  }
}

module.exports = LicenseApi
