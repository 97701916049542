import React, { Fragment } from 'react'

import { Button, Page } from '@fielded/shared-ui'

import CrossLink from '../../../common/cross-link'
import { Arrow } from '@fielded/shared-ui/src/icons'
import { getNavigationItem, getNavigationPath } from '../../../common/utils/navigationInfo'
import { addProductData } from '../shipments/shipment-utils'
import Shipment from '../shipments/Shipment'

const Deliveries = (props) => {
  const {
    shipments,
    products,
    location,
    config,
    rootHistory
  } = props

  const refinedShipments = addProductData(shipments, products)

  return (
    <Page>
      <Page.Header
        title={location}
        breadcrumbItems={[getNavigationItem({item: 'shipments', config})]}
      />
      <section>
        {refinedShipments.length
          ? refinedShipments.map(shipment => (
            <Shipment
              key={shipment.snapshotId}
              shipment={shipment}
              config={config}
              history={rootHistory}
            />
          ))
          : (
            <Fragment>
              <Page.Panel>
                <Page.Panel.Header title='No deliveries' />
                <Page.Panel.Section>
                  There is nothing to deliver to this location.
                </Page.Panel.Section>
                <Page.Panel.Section>
                  <Button
                    component={CrossLink}
                    to={getNavigationPath({item: 'reports', config})}
                    history={rootHistory}
                    icon={<Arrow direction='right' />}
                    iconSide='right'
                    fill='full'
                    colorVariant='brand'
                  >
                    Go to counts
                  </Button>
                </Page.Panel.Section>
              </Page.Panel>
            </Fragment>
          )
        }
      </section>
    </Page>
  )
}

export default Deliveries
