import React from 'react'
import { formatDate } from '../../../van-shared/utils/utils'
import { Page, Form, Button, BackButton, PriceDisplay, QuantityDisplay } from '@fielded/shared-ui'
import { getCurrencySymbol } from '@fielded/shared-ui/src/components/PriceDisplay'

const RotatedTh = ({ children, postfix }) => (
  <th className='rotate-col-header'>
    <div className='rotate-col-header__position-wrapper'>
      <div className='rotate-col-header__rotated'>
        {children} {postfix && (
          <span className='rotate-col-header__postfix'>
            {postfix}
          </span>
        )}
      </div>
    </div>
  </th>
)

const PackedTable = ({
  items,
  date,
  country,
  includeVAT
}) => {
  let colCount = 5
  if (includeVAT) colCount += 1

  return (
    <div
      className='delivery-contents delivery-contents--has-code'
    >
      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Product</th>
            <RotatedTh>Packed</RotatedTh>
            <RotatedTh postfix={getCurrencySymbol({country: country})}>
              {formatDate(date, 'monthShort')} Unit price
            </RotatedTh>
            {includeVAT && <RotatedTh>VAT</RotatedTh>}
            <RotatedTh postfix={getCurrencySymbol({country: country})}>
              'Packed value'
            </RotatedTh>
          </tr>
        </thead>
        <tbody>
          {items && items.length ? items.map(item => {
            return (
              <tr key={item.code}>
                <td>{item.code}</td>
                <td className='delivery-contents__highlight'>{item.name}</td>
                <td><QuantityDisplay value={item.packed} /></td>
                <td><PriceDisplay value={item.price} country={country} /></td>
                {includeVAT && (
                  <td className='delivery-contents__highlight'><PriceDisplay value={item.unitVat} country={country} /></td>
                )}
                <td><PriceDisplay value={parseInt(item.price, 10) * item.packed} country={country} /></td>
              </tr>
            )
          }) : (
            <tr>
              <td colSpan={colCount}>
                There are no products in this delivery
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

const ConfirmationTable = ({
  items,
  date,
  country,
  signature,
  name,
  formRef,
  onConfirm,
  onGoBack,
  title,
  subTitle,
  appName
}) => {
  return (
    <Page>
      <Page.Header
        title={title}
        backButton={<BackButton onClick={onGoBack}>Go back</BackButton>}
      >
        {subTitle}
      </Page.Header>
      <Page.Panel withBackground>
        <Page.Panel.Section fullWidth>
          <PackedTable items={items} country={country} />
        </Page.Panel.Section>
        <Page.Panel.Section
          fullWidth
          className='sl-delivery-confirmation__signature'
        >
          <Form ref={formRef}>
            <Form.Section>
              <Form.Row>
                <Form.Field
                  fieldName='name'
                  labelText='Name'
                  value={name}
                  spread
                >
                  <Form.Field.Text
                    onChange={() => {}}
                    disabled={name}
                    defaultValue={name}
                    required={'Please enter a name'}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>
              <Form.Row>
                <Form.Field
                  labelText={`Please confirm return of products listed above to ${appName}.`}
                  fieldName='signature'
                  spread
                >
                  <Form.Field.Signature
                    disabled={signature}
                    defaultValue={signature}
                    required={'Please sign'}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>
            </Form.Section>
          </Form>
        </Page.Panel.Section>
      </Page.Panel>
      <Page.Footer layout='centered'>
        <Button
          fill='full'
          size='large'
          colorVariant='confirmation'
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Page.Footer>
    </Page>
  )
}

export default ConfirmationTable
