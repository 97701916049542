const { addMonths, subMonths, addWeeks, subWeeks, format, addDays, endOfDay, subDays } = require('date-fns')
const getPeriodDates = require('./get-period-dates')
const getDisplayPeriod = require('./get-display-period')
const createPeriodId = require('./create-period-id')
const { addTZ, subTZ } = require('./tz')

module.exports = (definition, date, isEffectiveDate = false) => {
  // We are dealing with two timezones here. The timezone in which the period is
  // defined and the local timezone of the machine this code is run on.
  //
  // The period dates are calculated in local time of the machine, because
  // date-fns functions operate only in local time. Because of that we need
  // to modify the input date and the output dates.
  //
  // The input date needs to be set to the same time in the period tz as it is in
  // local time. For that we subtract the tz offset from the input date.
  // We can then do the date calculations in local time and pretend its in the
  // period tz. After we are done with the calculations, we need to add the local
  // tz offset back to the dates, so that the returned dates have the right time
  // in the period tz.
  //
  // We currently use UTC+0 as period timezone.
  // So periods start at 00:00 UTC+0 and end at 23:59 UTC+0
  //
  date = subTZ(date)

  const entryPeriodRules = {
    'next-period': nextPeriod,
    'current-period': currentPeriod
  }

  const entryPeriodRuleFunction = entryPeriodRules[definition.entryPeriodRule]
  if (!entryPeriodRuleFunction) {
    throw new Error(`Entry period rule not found for entryPeriodRule: ${definition.entryPeriodRule}`)
  }

  const dates = entryPeriodRuleFunction(definition, date, isEffectiveDate)

  const display = getDisplayPeriod(
    definition.periodType,
    dates.effectiveStartDate,
    dates.effectiveEndDate
  )

  const result = {
    definition,
    id: createPeriodId(definition, addTZ(dates.effectiveStartDate)),
    display,
    displayDue: format(dates.entryDueDate, 'ddd D MMM'),
    effectiveStartDate: addTZ(dates.effectiveStartDate),
    effectiveEndDate: addTZ(dates.effectiveEndDate),
    entryStartDate: addTZ(dates.entryStartDate),
    entryEndDate: addTZ(dates.entryEndDate),
    entryDueDate: addTZ(dates.entryDueDate),
    entryCutOffDate: addTZ(dates.entryCutOffDate)
  }
  return result
}

const getDueDate = (definition, entryStartDate, entryEndDate) => {
  const offset = definition.dueDateOffset
  if (!offset) {
    return entryEndDate
  }
  return subDays(endOfDay(offsetDate(entryStartDate, offset)), 1)
}

const getEntryCutOffDate = (definition, entryStartDate, entryEndDate) => {
  const offset = definition.entryCutOffOffset
  if (!offset) {
    return entryEndDate
  }
  return subDays(endOfDay(offsetDate(entryStartDate, offset)), 1)
}

const currentPeriod = (definition, date) => {
  const {startDate, endDate} = getPeriodDates(definition.periodType, date)

  return {
    effectiveStartDate: startDate,
    effectiveEndDate: endDate,
    entryStartDate: startDate,
    entryEndDate: endDate,
    entryDueDate: getDueDate(definition, startDate, endDate),
    entryCutOffDate: endDate
  }
}

const nextPeriod = (definition, date, isEffectiveDate) => {
  let entryDate
  let effectiveDate

  if (isEffectiveDate) {
    entryDate = addPeriod(definition.periodType, date)
    effectiveDate = date
  } else {
    entryDate = date
    effectiveDate = subtractPeriod(definition.periodType, date)
  }

  const {
    startDate: entryStartDate,
    endDate: entryEndDate
  } = getPeriodDates(definition.periodType, entryDate)
  const {
    startDate: effectiveStartDate,
    endDate: effectiveEndDate
  } = getPeriodDates(definition.periodType, effectiveDate)

  return {
    entryStartDate,
    entryEndDate,
    effectiveStartDate,
    effectiveEndDate,
    entryDueDate: getDueDate(definition, entryStartDate, entryEndDate),
    entryCutOffDate: getEntryCutOffDate(definition, entryStartDate, entryEndDate)
  }
}

const subtractPeriod = (periodType, date) => {
  switch (periodType.unit) {
    case 'month':
      return subMonths(new Date(date), periodType.quantity)
    case 'week':
      return subWeeks(new Date(date), periodType.quantity)
    default:
      throw new Error(`subtractPeriod period type unit not supported ${periodType.unit}`)
  }
}

const addPeriod = (periodType, date) => {
  switch (periodType.unit) {
    case 'month':
      return addMonths(new Date(date), periodType.quantity)
    case 'week':
      return addWeeks(new Date(date), periodType.quantity)
    default:
      throw new Error(`subtractPeriod period type unit not supported ${periodType.unit}`)
  }
}

const offsetDate = (date, offset) => {
  if (!offset) {
    return date
  }
  switch (offset.unit) {
    case 'day':
      return addDays(date, offset.quantity)
    case 'month':
      return addMonths(date, offset.quantity)
    default:
      throw new Error('Unknown offset unit type: ' + offset.unit)
  }
}
