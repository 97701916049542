import React from 'react'
import PropTypes from 'prop-types'

import { formatDate } from '../../../../van-shared/utils'
import {
  STATUS_NOT_STARTED,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETE,
  STATUS_OFFLINE,
  reportStatusPropType
} from '../../common/report-status'

const ReportMeta =
({ status, user, time }) => {
  let savedLabelByStatus = {}
  savedLabelByStatus[STATUS_COMPLETE] = 'Submitted'
  savedLabelByStatus[STATUS_IN_PROGRESS] = 'Last saved'
  savedLabelByStatus[STATUS_NOT_STARTED] = ''
  savedLabelByStatus[STATUS_OFFLINE] = ''

  const formattedTime = formatDate(time, 'long')
  const savedLabel = savedLabelByStatus[status] || ''

  return (
    <div className='report-meta'>
      {savedLabel}
      {formattedTime && ` at ${formattedTime}`}
      {user && ` by ${user}`}
    </div>
  )
}

ReportMeta.propTypes = {
  status: reportStatusPropType.isRequired,
  user: PropTypes.string,
  time: PropTypes.string
}

ReportMeta.defaultProps = {
  user: undefined,
  time: undefined
}

export default ReportMeta
