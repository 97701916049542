import React, { Component } from 'react'

import PlanningProducts from './PlanningProducts'

class PlanningProductsContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedIds: new Set(props.products.filter(p => p.selected).map(p => p._id))
    }
  }

  toggleProductHandler = (productId) => {
    const { selectedIds } = this.state
    const { products } = this.props
    const select = !selectedIds.has(productId)
    const newSelectedIds = new Set(selectedIds)
    if (select) {
      newSelectedIds.add(productId)
    } else {
      newSelectedIds.delete(productId)
    }
    this.setState({ selectedIds: newSelectedIds })
    const product = products.find(p => p._id === productId)
    product.selected = select
  }

  render () {
    const {
      origin,
      destination,
      onGoBack,
      onSubmit,
      products,
      history
    } = this.props
    const {
      selectedIds
    } = this.state

    return (
      <PlanningProducts
        history={history}
        title={'Select products to send'}
        subTitle={`New shipment from ${origin.name} to ${destination.name}`}
        products={products}
        selectedProducts={selectedIds.size}
        onClickNext={onSubmit}
        onGoBack={onGoBack}
        onToggle={this.toggleProductHandler}
        nextButtonText={'Next'}
      />
    )
  }
}

export default PlanningProductsContainer
