import partition from 'lodash/partition'
import { isSameDay, isPast, addDays, differenceInCalendarISOWeeks } from 'date-fns'
import get from 'lodash/get'

import { formatDate } from '../../../../van-shared/utils/utils'

const DAYS_TO_ADD = 5

const ORDER_TYPES = {
  PAYG: 'PAYG',
  POD: 'POD'
}

const TRANSACTION_STATUS = {
  DUE: 'due',
  PAID: 'paid',
  OVERDUE: 'overdue'
}

// currently we are supporting only paystack
const PAYMENT_PROVIDERS = {
  paystack: 'paystack'
}

// Please this is the transaction types and their UI transalations
// take care when modifying this
const TXN_TYPES_HUMANIZED = {
  // debits
  invoice: 'invoice',
  installment: 'Instalment',
  refund: 'withdrawal',

  // credits
  payment: 'payment',
  'credit memo': 'credit memo',
  loan: 'payment plan',
  'write-off': 'write-off',
  bonus: 'cashback',
  'installment payment': 'payment',
  'pending invoice payment': 'payment',
  'pending installment payment': 'payment'
}

const [
  invoice,
  paymentPlanInstalment,
  refund,
  payment,
  creditMemo,
  loan,
  writeOff,
  bonus,

  // eslint-disable-next-line
  installmentPayment,

  pendingInvoicePayment,
  pendingInstallmentPayment
] = Object.keys(TXN_TYPES_HUMANIZED)

const pendingTxnTypes = [pendingInvoicePayment, pendingInstallmentPayment]

const getInvoicePaymentPlanTag = (transaction) => {
  const {
    amount,
    amount_paid: amountPaid,
    due_date: dueDate,
    txn_type: type
  } = transaction

  if (!([invoice, paymentPlanInstalment].includes(type))) return null

  const today = formatDate(new Date(), 'snapshotId')
  const dueDateFormated = formatDate(dueDate, 'snapshotId')

  // this check is necessary if an invoice/instalment due date is in the future
  if (dueDateFormated >= today && amountPaid < amount) {
    const decoratedNewDate = formatDate(addDays(today, DAYS_TO_ADD), 'snapshotId')

    if (decoratedNewDate >= dueDate) {
      return TRANSACTION_STATUS.DUE
    }
    return null
  }
  return amountPaid >= amount ? TRANSACTION_STATUS.PAID : TRANSACTION_STATUS.OVERDUE
}

const isPreviousInstallmentPaid = (installments, installment, index) => {
  // we return the status of the first installment ie index is falsy
  if (!index) {
    return getInvoicePaymentPlanTag(installment) === TRANSACTION_STATUS.PAID
  }

  const previousInstallment = installments[index - 1]
  return getInvoicePaymentPlanTag(previousInstallment) === TRANSACTION_STATUS.PAID
}

const addtxnPendingStatus = (pendingTxn, txn) => {
  if (pendingTxn.length && [invoice, paymentPlanInstalment].includes(txn.txn_type)) {
    let isPending = true

    pendingTxn.map(pending => {
      if (pending.quickbooks_doc_number === txn.quickbooks_doc_number) {
        txn = {
          ...txn,
          isTxnPending: isPending,
          pendingTxnDate: formatDate(pending.txn_date, 'invoiceDateFormat')
        }
      }
    })
  }
  return txn
}

const getPaymentPlanStatus = (installments, tag) => {
  if (TRANSACTION_STATUS.PAID === tag) {
    return installments.every(transaction => getInvoicePaymentPlanTag(transaction) === tag)
  } else {
    return installments.some(transaction => getInvoicePaymentPlanTag(transaction) === tag)
  }
}

const getLoanDetails = (isLoan, details) => {
  if (!isLoan || !details) return null

  const {
    past_due: pastDue,
    installment_amount: instalmentAmount,
    service_fee: serviceFee,
    start_date: startDate,
    created_at: createdAt,
    frequency,
    term
  } = details

  // For fixed-term payment plan, we use the `createdAt` as the start date
  const date = term === 1 ? createdAt : startDate

  return {
    pastDue,
    instalmentAmount: instalmentAmount - serviceFee,
    serviceFee,
    instalment: instalmentAmount,
    term: frequency.toLowerCase(),
    startDate: formatDate(date, 'invoiceDateFormat')
  }
}

const getTxnHeader = (txnType, id = '') => {
  if (!TXN_TYPES_HUMANIZED[txnType]) {
    return {txnTitle: '', subText: ''}
  }

  const getTnxId = [invoice, paymentPlanInstalment, creditMemo].includes(txnType)
  const txnId = getTnxId ? id : ''

  if ([invoice, refund, creditMemo].includes(txnType)) {
    return {
      txnTitle: `${TXN_TYPES_HUMANIZED[txnType]} ${txnId}`,
      subText: 'issued'
    }
  }

  if ([payment, writeOff, bonus, loan].includes(txnType)) {
    return {
      txnTitle: TXN_TYPES_HUMANIZED[txnType],
      subText: 'received'
    }
  }

  return {
    txnTitle: `${TXN_TYPES_HUMANIZED[txnType]} ${txnId}`,
    subText: 'initiated'
  }
}

const DEBIT_TRANSACTION_TYPES_COUNT = 3

const getTxnDetails = (transaction) => {
  const {
    id: invoiceId,
    quickbooks_doc_number: docNumber,
    amount,
    amount_paid: amountPaid,
    due_date: dueDate,
    txn_date: issuedDate,
    txn_type: txnType,
    company_code: companyCode,
    isTxnPending,
    pendingTxnDate,
    code,
    description, // currently we get the payment plan instalment code from here (transaction list only)
    payment_plan: loanDetails, // details of the payment plan or the payment plan id
    order_type: orderType,
    ...otherProps
  } = transaction

  const isDebit = Object.keys(TXN_TYPES_HUMANIZED).slice(0, DEBIT_TRANSACTION_TYPES_COUNT).includes(txnType)
  const amountDue = amount - amountPaid
  const isPaymentPlan = txnType === paymentPlanInstalment
  const isInvoice = txnType === invoice
  const isCreditMemo = txnType === creditMemo
  const isLoan = txnType === loan
  const isTxnTypePending = txnType.includes('pending')
  const paymentDetailsVisible = amount !== amountPaid && amountPaid > 0
  const txnId = docNumber || code || description

  return {
    amount,
    amountPaid,
    amountDue,
    issuedDate,
    isPaymentPlan,
    isInvoice,
    isCreditMemo,
    isLoan,
    isTxnTypePending,
    paymentDetailsVisible,
    isDebit,
    invoiceId,
    txnId,
    txnType,
    loanDetails,
    isTxnPending,
    pendingTxnDate,
    orderType,
    companyCode,
    ...otherProps
  }
}

const sortTransactions = (transactions, sortDueDate = false) => {
  const newTransactionsList = [...transactions]

  if (!newTransactionsList.length) {
    return []
  }

  // txn_date: all transansactions except payment plan instalments
  // txn_created_at: payment plan instalments
  const cleanTransactionDate = (transaction) => {
    let sortDate = sortDueDate ? transaction.due_date : transaction.txn_date || transaction.created_at
    return new Date(sortDate).getTime()
  }

  return newTransactionsList
    .sort((a, b) => (cleanTransactionDate(b) - cleanTransactionDate(a)))
    .map(transaction => ({
      ...transaction,
      txn_date: transaction.txn_date || transaction.created_at
    }))
}

const isPastOrDueDate = (date) => {
  return isPast(date) || isSameDay(new Date(), date)
}

const getPaymentDueOptions = (payments) => {
  const [paymentsDue, paymentsNotDue] = partition(payments, (payment) => (
    isPastOrDueDate(payment.due_date)
  ))

  return {
    hasPaymentDue: !!paymentsDue.length,
    paymentsDueCount: paymentsDue.length,
    paymentsNotDue
  }
}

const getProcessedPaymentPlans = (paymentPlans) => {
  const processedPaymentPlans = paymentPlans
    .map(paymentPlan => (
      {...paymentPlan,
        instalments: paymentPlan.instalments.map(i => ({
          ...i,
          amount_due: i.amount - i.amount_paid,
          txn_type: paymentPlanInstalment,
          txn_date: paymentPlan.created_at
        }))
      }
    ))
    .reduce((data, plan) => {
      if (plan.is_active) {
        // Note: Using plan.is_paid for filtering is no longer reliable.
        // Some payment plans have is_paid set to true, but their quickbooks_account_balance
        // still shows a positive sum, indicating outstanding amounts.

        if (plan.quickbooks_account_balance > 0) {
          data.open.push(plan)
        }

        if (plan.quickbooks_account_balance <= 0) {
          data.closed.push(plan)
        }

        data.all.push(plan)
      }
      return data
    }, { open: [], closed: [], all: [] })

  return processedPaymentPlans
}

const isFutureFixedTermPayment = (dueDate) => {
  return differenceInCalendarISOWeeks(dueDate, new Date()) > 0
}

const PAYMENT_WEEKS = {
  CURRENT_WEEK: '0',
  NEXT_WEEK: '1',
  OVERDUE: 'overdue'
}

const { CURRENT_WEEK, NEXT_WEEK, OVERDUE } = PAYMENT_WEEKS

const getPayableDetails = (payables) => {
  if (!payables) {
    return
  }

  // if no payable listed for current week we add a default placeholder.
  // This is needed to display fixed term payments
  if (!payables[CURRENT_WEEK]) {
    payables[CURRENT_WEEK] = { week: '0', totaldue: 0, transactions: [] }
  }

  const paymentList = Object.values(payables)

  return paymentList.reduce((data, payment) => {
    // Payment due this week (payment could have multiple transactions ie invoice and instalment)
    if (payment.week === CURRENT_WEEK) {
      data.currentWeek.push(payment)
    }

    // Payment due next week (payment could have multiple transactions ie invoice and instalment)
    if (payment.week === NEXT_WEEK) {
      data.nextWeek.push(payment)
    }

    // Future payments. This includes all payments that are NOT part of the current week's (this week) payments
    // Future payments = next week payments and all other subsequent week payments
    // We have an exception though, every fixed-term payment should be visible on the overview page (ie current week).
    if (Number(payment.week) >= Number(NEXT_WEEK)) {
      const futureWeekTransactions = []
      let futureWeekTotalDue = 0

      payment.transactions.forEach(transaction => {
        const {
          due_date: duedate,
          amount_paid: amountPaid,
          amount
        } = transaction

        const amountDue = (amount - amountPaid)

        if (isFixedTermPayment(transaction) && isFutureFixedTermPayment(duedate)) {
          const currentWeekPayments = data.currentWeek[0]
          currentWeekPayments.totaldue += amountDue
          currentWeekPayments.transactions.push(transaction)
        } else {
          futureWeekTransactions.push(transaction)
          futureWeekTotalDue += amountDue
        }
      })

      data.futureWeeks.push({
        ...payment,
        transactions: futureWeekTransactions,
        totaldue: futureWeekTotalDue
      })
    }

    // Overdue payments (it could have multiple transactions ie invoices and instalments)
    if (payment.week === OVERDUE && payment.totaldue > 0) {
      data.overduePayments.push(payment)
    }

    payment.transactions.map(transaction => {
      transaction.amount_due = transaction.amount - transaction.amount_paid

      if (transaction.txn_type === invoice) {
        data.invoices.push(transaction)
      } else {
        data.instalments.push(transaction)
      }
    })
    return data
  }, {
    currentWeek: [],
    nextWeek: [],
    futureWeeks: [],
    overduePayments: [],
    invoices: [],
    instalments: []
  })
}

// pending -> this is the future value of the current balance (balance)
// balance -> this is the user's current balance.
function getAvailableCashBalance (balances) {
  const balance = get(balances, 'balance', 0)
  const pending = get(balances, 'pending', 0)

  // The pending and current balance will always be the same value (pending === balance),
  // unless there are some pending payments awiting reconciliation
  // if the balances are negative, the availableCashBalance will default to zero (0)
  let availableCashBalance = Math.max(pending, Math.max(balance, 0))
  return availableCashBalance
}

// A type of instalment with just 1 term
const isFixedTermPayment = (transaction) => {
  if (!transaction || !transaction.payment_plan || transaction.txn_type !== paymentPlanInstalment) {
    return false
  }
  return transaction.payment_plan.term === 1
}

export {
  ORDER_TYPES,
  TXN_TYPES_HUMANIZED,
  pendingTxnTypes,
  TRANSACTION_STATUS,
  PAYMENT_WEEKS,
  PAYMENT_PROVIDERS,
  getInvoicePaymentPlanTag,
  isPreviousInstallmentPaid,
  getTxnDetails,
  getTxnHeader,
  getLoanDetails,
  addtxnPendingStatus,
  sortTransactions,
  getPaymentPlanStatus,
  getPaymentDueOptions,
  getProcessedPaymentPlans,
  getPayableDetails,
  getAvailableCashBalance,
  isFixedTermPayment
}
