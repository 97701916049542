import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Form, Page, Text } from '@fielded/shared-ui'
import EntryViewWrapper from '../common/EntryViewWrapper'

const ResendEmail = ({
  config,
  onSuccess,
  onSubmit,
  inProcess,
  hasIntercom,
  error
}) => {
  return (
    <EntryViewWrapper config={config} showIntercom>
      <Fragment>
        {!onSuccess && (
          <Page.Panel.Message type='danger' cta={false}>
            {error || 'Your activation link has expired'}
          </Page.Panel.Message>
        )}

        <Page.Panel.Section>
          {onSuccess ? (
            <div>
              <Text>
                The email has been successfully sent to your email address. Please check your inbox (and/or spam folder).
              </Text>
              <Text>Please contact <a target='_blank' href='https://shelflife.co/contact/'> support</a> if you haven't received an email.</Text>
              <NavLink
                to='/login'
                className='vs-u-link'
              >
                Return to login
              </NavLink>
            </div>
          ) : (
            <Form
              onSubmit={onSubmit}
              component='form'
            >
              <Form.Row>
                <Form.Field
                  fieldName='email'
                  labelText='Please enter the same email address you used on sign up'
                >
                  <Form.Field.Text
                    type='text'
                    disabled={inProcess}
                    required
                    autoCapitalize='off'
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>

              <Form.Actions>
                <NavLink
                  to='/login'
                  className='vs-u-link'
                >
                  Already registered? Log in
                </NavLink>
                <Form.Button
                  alignOpposite
                  size='medium'
                  disabled={inProcess}
                  loading={inProcess}
                >
                  Resend Email
                </Form.Button>
              </Form.Actions>
            </Form>
          )}
        </Page.Panel.Section>
      </Fragment>
    </EntryViewWrapper>
  )
}

ResendEmail.propTypes = {
  config: PropTypes.object.isRequired,
  loginInProgress: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
}

ResendEmail.defaultProps = {
  loginInProgress: false,
  errorMessage: null
}

export default ResendEmail
