import React from 'react'

import ErrorBoundary from './ErrorBoundary'

const withInitialisers = Component =>
  <ErrorBoundary>
    <Component />
  </ErrorBoundary>

export default withInitialisers
