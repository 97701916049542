import React from 'react'
import PropTypes from 'prop-types'
import withOffline from '@fielded/shared-ui/src/common/offline'

import { BackButton, Button, Page } from '@fielded/shared-ui'
import { pluralize } from '../../../van-shared/utils'

import { DownloadLMDReportButton } from '../common/DownloadLMDReportButton'
import StockCountTable from './StockCountTable'

import {
  getConfirmationRows as nestedConfirmationRows,
  getConfirmationRowsMissingCount,
  getExpiryRows,
  getUnManagedProductIds
} from './confirmationUtils'
import { hasFeature } from '../../../van-shared/utils/features'

const Confirmation = ({
  user,
  program,
  config,
  report,
  reportLocation,
  serviceId,
  serviceName,
  programName,
  fieldsById: fields,
  productsById,
  batchesById,
  onClickGoBack,
  onClickConfirm,
  readOnly,
  isOnline
}) => {
  // incase we are deleting remark field to avoid any side effects
  let fieldsById = {...fields}
  let confirmationRowsMissingCount
  const hasPhysicalCountField = fieldsById['field:standard-physical-count']
  let title = readOnly ? 'Stock Report' : 'Confirm stock report'
  let issuesWarningText
  let unmanagedNotice

  const confirmationRows = nestedConfirmationRows({report, productsById, serviceId, serviceName})
  const expiryRows = report.expiry ? getExpiryRows({report, productsById, batchesById}) : undefined
  const unmanagedProducts = getUnManagedProductIds(report.stock)

  if (hasPhysicalCountField) {
    confirmationRowsMissingCount = getConfirmationRowsMissingCount(confirmationRows)
  }

  if (confirmationRowsMissingCount > 0) {
    const productsString = pluralize('product', confirmationRowsMissingCount)
    const have = confirmationRowsMissingCount === 1 ? 'has' : 'have'
    issuesWarningText = `${confirmationRowsMissingCount} ${productsString} ${have} not been counted`
  }

  if (unmanagedProducts.length) {
    const productsString = pluralize('product', unmanagedProducts.length)
    const unmanaged = unmanagedProducts.length === 1 ? 'is unmanaged' : 'are unmanaged'
    unmanagedNotice = `${unmanagedProducts.length} ${productsString} ${unmanaged} `
  }

  return (
    <Page
      situation={issuesWarningText ? 'caution' : null}
    >
      {/* Needs to be in markup for print preview to show as landscape */}
      <style type='text/css' media='print'>
        {'@page {size: landscape}'}
      </style>
      <header className='confirmation__back-button'>
        <BackButton
          onClick={onClickGoBack}
        />
      </header>
      <Page.Intro title={!issuesWarningText ? title : issuesWarningText} centered />
      <Page.Panel
        withBackground
        spread
      >
        <Page.Panel.Section fullWidth>
          <header className='stock-count-header'>
            <h2 className='stock-count-header__title'>
              {reportLocation.name} stock report
              <span className='stock-count-header__info'>{programName} {serviceName !== programName && serviceName}</span>
            </h2>
            {(issuesWarningText || unmanagedNotice) && (
              <div className='stock-count-header__issues'>
                {issuesWarningText && (
                  <p>{`${issuesWarningText}, count them or mark as product not in use`}</p>
                )}
                {unmanagedNotice && (
                  <p className='stock-count__unmanaged-product'>{unmanagedNotice}</p>
                )}
              </div>
            )}
          </header>
          <StockCountTable
            fieldsById={fieldsById}
            stockCountRows={confirmationRows}
            expiryRows={expiryRows}
          />
        </Page.Panel.Section>
      </Page.Panel>
      <Page.Footer
        className='vs-u-hide-on-print'
      >
        {(!readOnly && !issuesWarningText) && <Button
          onClick={onClickConfirm}
          colorVariant='brand'
          fill='full'
          size='large'
        >
          Confirm
        </Button>}

        {readOnly && hasFeature(config.features, 'facilityExportLMD') && <DownloadLMDReportButton
          user={user}
          config={config}
          program={program}
          report={report}
          label='Export'
          period={report.date.reportingPeriod}
          locationId={report.location.id}
          disabled={!isOnline}
          title={!isOnline ? 'Export not available offline' : ''}
        />}

        <Button
          alignOpposite
          colorVariant='brand'
          fill='outline'
          onClick={() => window.print()}
        >
          Download PDF
        </Button>

        <Button
          onClick={onClickGoBack}
          colorVariant='brand'
          fill='outline'
        >
          Go back
        </Button>
      </Page.Footer>
    </Page>
  )
}

Confirmation.propTypes = {
  config: PropTypes.object.isRequired,
  /**
   * Report object for the count to confirm
   */
  report: PropTypes.object.isRequired,
  /**
   * Full location object for report location
   */
  reportLocation: PropTypes.object.isRequired,
  /**
   * From masterdata
   */
  programName: PropTypes.string.isRequired,
  /**
   * From masterdata
   */
  serviceId: PropTypes.string.isRequired,
  /**
   * From masterdata
   */
  serviceName: PropTypes.string.isRequired,
  /**
   * Indexed, all products from masterdata
   */
  productsById: PropTypes.object.isRequired,
  /**
   * Indexed object, all fields from report definition
   */
  fieldsById: PropTypes.object.isRequired,
  /**
   * Indexed, all batches from masterdata
   */
  batchesById: PropTypes.object,
  onClickGoBack: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
  isOnline: PropTypes.bool.isRequired
}

export default withOffline(Confirmation)
