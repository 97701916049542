import React from 'react'
import { format } from 'date-fns'

const buildDate = format(new Date(process.env.REACT_APP_BUILD_DATE || Date.now()), 'dd LLL yyyy')
const commitSha = process.env.REACT_APP_COMMIT_SHA || 'local' // local user see's this
const buildVersion = process.env.REACT_APP_BUILD_VERSION || ''

const VersionInfo = () => (
  <div className='vs-u-column version-info'>
    <span>Version {buildVersion || commitSha.slice(0, 7)}</span>
    <span>Version released {buildDate}</span>
  </div>
)
export default VersionInfo
