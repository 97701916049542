import { fetchIds } from './fetcher'
import { monitorReplicationEvents } from './common'
import {
  LIVE_DOWNLOAD_OPTIONS
} from './databases'
import { syncError, updatePending } from './sync-reducer'

const DEFAULT_POLL_INTERVAL = 1000 * 60 * 2 // two minutes

const syncingFeeds = {}

// 'feeds' is a param for tests, otherwise this uses `syncingFeeds` object in memory
export async function liveDownloadById (dbConfig, user, dispatch, feeds = syncingFeeds) {
  const { name, initialIds, selector } = dbConfig
  // Never re-initialize if syncing already setup.
  if (feeds[name]) {
    return Promise.resolve({})
  }

  feeds[name] = {
    ...dbConfig,
    fetchingNewIds: false,
    ids: new Set(initialIds),
    selector: selector
  }

  setInterval(() => {
    if (!feeds[name].fetchingNewIds) {
      checkForNewIds(feeds[name], user, dispatch)
    }
  }, dbConfig.pollInterval || DEFAULT_POLL_INTERVAL)

  startOrRestartReplication(feeds[name], dispatch)
}

export async function checkForNewIds (dbConfig, user, dispatch) {
  dbConfig.fetchingNewIds = true
  let ids
  let selector
  let newIds = []
  try {
    const idResponse = await fetchIds(dbConfig.idEndpoint, user, dbConfig.name, dbConfig.localDb)
    ids = idResponse.ids
    selector = idResponse.selector
    newIds = ids.filter(id => !dbConfig.ids.has(id))
  } catch (error) {
    dispatch(syncError(dbConfig.name, 'download', error, 'check for new ids error posting to endpoint'))
  }

  // If using selector-based replication,
  // only restart when the selector has changed
  if (selector && JSON.stringify(selector) !== JSON.stringify(dbConfig.selector)) {
    dbConfig.selector = selector
    return startOrRestartReplication(dbConfig, dispatch)
  }

  dbConfig.fetchingNewIds = false
  if (newIds.length) {
    // Use full list of ids and replace existing set.
    dbConfig.ids = new Set(ids)
    startOrRestartReplication(dbConfig, dispatch)
  }
}

async function startOrRestartReplication (dbConfig, dispatch) {
  // Convert set to array for the replication parameter.
  const { downloadFeed, localDb, remoteDb, name } = dbConfig
  if (downloadFeed) downloadFeed.cancel()
  const options = {
    ...LIVE_DOWNLOAD_OPTIONS
  }

  if (dbConfig.selector) {
    options.since = 0
    options.selector = dbConfig.selector
  } else {
    options.doc_ids = [...dbConfig.ids]
  }

  dbConfig.downloadFeed = localDb.replicate.from(remoteDb, options)
    .on('change', ({ pending }) => dispatch(updatePending(name, 'download', pending || 0)))
  monitorReplicationEvents(dbConfig.downloadFeed, dbConfig.name, 'download', dispatch)
}
