// Heads up: these throw on invalid

const { isValidJSONDate } = require('../../utils/validation')

module.exports = {
  validateEntityCreateToDoc
}

function validateEntityCreateToDoc ({ effectiveDate, proposedDoc }) {
  if (!isValidJSONDate(effectiveDate)) {
    throw new Error('params: effectiveDate for proposals is required and must be a full JSON date')
  }

  if (!proposedDoc.type) {
    throw new Error('params: proposed documents must have type')
  }

  if (!proposedDoc._id) {
    throw new Error('params: proposed documents must have an _id')
  }
}
