import React from 'react'

import { BackButton, Button, Form, Page } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

import ConfirmPickUpContentsTable from './ConfirmPickUpContentsTable/ConfirmPickUpContentsTable'

const ConfirmPickUp = ({
  formRef,
  formData,
  history,
  situation,
  selectedDeliveries,
  onProceed
}) => {
  const {
    warehouseManagerName,
    signature,
    driverName
  } = formData

  const signatureInfoText = 'Please ensure that you have confirmed your delivery selection before proceeding by signing below. This step is crucial to avoid any unintended deliveries.'

  return (
    <Page situation={situation}>
      <Page.Header
        backButton={<BackButton history={history}>go back</BackButton>}
      />
      <Page.Intro title='Confirm pickup' />
      <Page.Panel withBackground>
        <Page.Panel.Section fullWidth>
          <ConfirmPickUpContentsTable
            shipments={selectedDeliveries}
          />
        </Page.Panel.Section>

        <Page.Panel.Section className='confirm-pick-up__form' fullWidth>
          <Form ref={formRef}>
            <Form.Section>
              <Form.Row>
                <Form.Field
                  fieldName='warehouseManagerName'
                  labelText='Warehouse manager’s name'
                  spread
                >
                  <Form.Field.Text
                    defaultValue={warehouseManagerName}
                    required={'Please enter a name'}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>

              <Form.Row>
                <Form.Field
                  fieldName='driverName'
                  labelText='Driver’s name'
                  spread
                >
                  <Form.Field.Text
                    disabled={driverName}
                    defaultValue={driverName}
                    required={'Please enter a name'}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>

              <Form.Row>
                <Form.Field
                  labelText={signatureInfoText}
                  fieldName='signature'
                  spread
                >
                  <Form.Field.Signature
                    // disabled={signature}
                    defaultValue={signature}
                    required={'Please sign'}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>
            </Form.Section>
          </Form>
        </Page.Panel.Section>
      </Page.Panel>

      <Page.Footer layout='centered'>
        <Button
          fill='full'
          size='large'
          colorVariant={'confirmation'}
          iconSide='right'
          icon={<Arrow direction='right' />}
          onClick={onProceed}
        >
          Proceed
        </Button>
      </Page.Footer>
    </Page>
  )
}

export default ConfirmPickUp
