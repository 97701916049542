const pluckDocs = res => res.rows.map(res => res.doc)

const sort = (ledgers, locationIds) => {
  // Order the ledgers following the order of the provided location ids
  return ledgers.sort((a, b) =>
    locationIds.indexOf(a.location.id) - locationIds.indexOf(b.location.id)
  )
}

module.exports = async function (state, locationIds, service, startdate, enddate) {
  const {ledgerDB, remoteLedgerDB} = state
  const ledgerDocPromises = []
  const remainingLocationIds = new Set(locationIds)

  const getLedgerIds = (ledgerIds, row) => {
    const {key, id} = row
    const locationId = key[1]
    if (remainingLocationIds.has(locationId)) {
      ledgerIds.push(id)
      remainingLocationIds.delete(locationId)
    }
    return ledgerIds
  }

  // Find the ids of the ledgers that are available locally
  const localLedgerDBRows = await ledgerDB.query('stock-count-api/by-submitted-at-and-location-id', {
    startkey: [enddate],
    endkey: [startdate],
    descending: true,
    include_docs: false
  })
    .then(res => res.rows)

  const localLedgerIds = localLedgerDBRows.reduce(getLedgerIds, [])

  // And request the actual ledger docs
  ledgerDocPromises.push(
    ledgerDB.allDocs({
      keys: localLedgerIds,
      include_docs: true
    })
      .then(pluckDocs)
  )

  let remoteQuery = Promise.resolve([])
  // Try to get the missing ledgers from the remote DB, if we need any
  if (remoteLedgerDB && remainingLocationIds.size > 0) {
    // First get the doc ids
    remoteQuery = remoteLedgerDB.query('stock-count-api/by-submitted-at-and-location-id', {
      startkey: [enddate],
      endkey: [startdate],
      descending: true,
      include_docs: false
    })
      .then(remoteResponse => {
        const remoteLedgerIds = remoteResponse.rows.reduce(getLedgerIds, [])

        // Don't make the allDocs call if there are no useful ledgers
        if (!remoteLedgerIds.length) {
          return []
        }

        return remoteLedgerDB.allDocs({
          keys: remoteLedgerIds,
          include_docs: true
        })
          .then(pluckDocs)
      })
      .catch(e => {
        return {
          status: 'offlineError',
          missing: Array.from(remainingLocationIds),
          error: e
        }
      })
  }

  ledgerDocPromises.push(remoteQuery)

  return Promise.all(ledgerDocPromises)
    .then(([localLedgers, remoteResponse]) => {
      // Success:
      // This means we either had all data we need offline,
      // or we successfully talked to remote
      if (Array.isArray(remoteResponse)) {
        if (remoteResponse.length) {
          // Saving fetched remote ledgers to the local ledger DB.
          // The added `fetched` timestamp can be used to wipe old docs from the DB
          // when it becomes too large.
          const fetched = remoteResponse.map(ledger => Object.assign({}, ledger, {fetched: new Date().toJSON()}))
          ledgerDB.bulkDocs(fetched, {new_edits: false})
        }

        return {
          status: 'success',
          ledgers: sort(localLedgers.concat(remoteResponse), locationIds)
        }
      }

      // Offline:
      if (remoteResponse && remoteResponse.status === 'offlineError') {
        return {
          status: 'error',
          ledgers: sort(localLedgers, locationIds),
          missing: remoteResponse.missing
        }
      }
    })
}
