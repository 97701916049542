const { format: formatDate } = require('date-fns')
const { getUnknownBatchID } = require('../../../shipment/tools/virtual-batch')
const { DIRECT_ORDER_TYPES } = require('../../../allocation/config')
const { getServiceForLocationId } = require('../../../service/tools')
const { translateTerritoryAlias, productAliasesByMarket } = require('../../../product/tools')
const pick = require('lodash/pick')
const { ORDER_SHIPMENT_SHARED_PROPS } = require('../../constants')
const { MEMBERSHIPS } = require('../../../location/tools/constants')

exports.createDestinationShipments = createDestinationShipments
function createDestinationShipments ({
  orderSnapshots,
  closedAt,
  products,
  defaultSupplier,
  includeZero,
  allowTranslateProducts = true,
  membershipType
}) {
  const shipmentSnapshots = orderSnapshots.map(
    orderSnapshot => createShipmentSnapshot(
      orderSnapshot,
      closedAt,
      products,
      defaultSupplier,
      includeZero,
      allowTranslateProducts,
      membershipType
    ))
  return removeEmptyShipments(shipmentSnapshots)
}

function removeEmptyShipments (snapshots) {
  return snapshots.filter(snapshot => {
    const counts = Object.keys(snapshot.counts)
    if (counts.length > 0) {
      return snapshot
    }
  })
}

const SHIPMENT_DATE_FMT = 'YYYY-MM-DD'
function getDateOnly (date) {
  if (!date) {
    return formatDate(new Date(), SHIPMENT_DATE_FMT)
  }

  if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return date
  }

  return formatDate(date, SHIPMENT_DATE_FMT)
}

function createShipmentSnapshot (orderSnapshot, closedAt, products, defaultSupplier, includeZero, allowTranslateProducts, membershipType) {
  const supplierId = orderSnapshot.supplierId || defaultSupplier
  const destinationId = orderSnapshot.destinationId
  const { service: originServiceId } = getServiceForLocationId(supplierId)
  const { service: destinationServiceID } = getServiceForLocationId(destinationId)
  const needsTranslation = allowTranslateProducts && destinationServiceID !== originServiceId

  const orderPropsToCloneIfDefined = pick(orderSnapshot, ORDER_SHIPMENT_SHARED_PROPS)

  const details = {
    origin: supplierId,
    destination: destinationId,
    // delivery dates are in the format YYYY-MM-DD so we strip off the
    // time part from the deliveryDate which is a timestamp
    date: getDateOnly(orderSnapshot.deliveryDate),
    overrideCreatedAt: closedAt,
    status: 'new',
    shipmentTypeId: 'routine',
    planningType: orderSnapshot.orderType,
    orderId: orderSnapshot._id,
    ...orderPropsToCloneIfDefined
  }

  return {
    ...details,
    counts: createShipmentCounts(orderSnapshot.products, needsTranslation, originServiceId, products, includeZero, membershipType)
  }
}

function createShipmentCounts (orderSnapshotProducts, needsTranslation, originServiceId, products, includeZero, membershipType) {
  let productsForTranslation
  if (needsTranslation) {
    productsForTranslation = productAliasesByMarket(products)
  }

  return Object.keys(orderSnapshotProducts)
    .reduce((acc, productId) => {
      const {adjusted: quantity, allocationType, prescription, original: ordered} = orderSnapshotProducts[productId]

      if (!quantity && !includeZero) return acc

      const convertedProductId = needsTranslation
        ? translateTerritoryAlias(productId, productsForTranslation, originServiceId)
        : productId

      const batchId = getUnknownBatchID(convertedProductId)
      const diff = quantity - ordered

      return {
        ...acc,
        [batchId]: {
          quantity,
          ordered,
          difference: diff === 0 ? false : diff,
          ...(allocationType ? { paymentType: getPaymentType(allocationType, membershipType) } : {}),
          ...(prescription ? { prescription } : {})
        }
      }
    }, {})
}

function getPaymentType (allocation, membershipType) {
  if (allocation === DIRECT_ORDER_TYPES.PAY_ON_DELIVERY) {
    return DIRECT_ORDER_TYPES.PAY_ON_DELIVERY
  } else if (allocation === DIRECT_ORDER_TYPES.PURCHASE) {
    return DIRECT_ORDER_TYPES.PURCHASE
  } else if (allocation === DIRECT_ORDER_TYPES.IMMEDIATE_PURCHASE) {
    return DIRECT_ORDER_TYPES.IMMEDIATE_PURCHASE
  } else {
    if (membershipType === MEMBERSHIPS.CLASSIC_PAYG_ONLY) {
      return DIRECT_ORDER_TYPES.PAY_ON_DELIVERY
    } else {
      return DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL
    }
  }
}
