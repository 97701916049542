const {createAllocation} = require('./allocation')
const {methods, defaultSupplyPlan} = require('./config')

const parseFloatValue = value => {
  if (!value) {
    return
  }

  if (typeof value === 'string') {
    return parseFloat(value.replace(/,/g, ''))
  }

  return parseFloat(value)
}

const parseIntValue = value => value ? parseInt(value, 10) : undefined

/** Function to round numbers to decimal places
 *
 * This works by multiplying by `10 ^ decimals`, (e+decimals), before
 * rounding and then after rounding, it does multiply by `10 ^ -decimals`
 * (e-decimals) again to shift the number back to its original size.
 *
 * We are doing this, because the rounding ends up to be _more_ correct:
 * > Math.round('1.005e+2') // returns 101
 * then:
 * > Math.round(1.005 * 100) // returns 100
 *
 * Why the version in scientific notation works is a bit of a mistery to
 * me. The second version doesn't work, because the multiplication
 * looses precision and `1.005 * 100` is `100.49999999999999`.
 */
const round = (value, decimalPlaces) => {
  return Number(Math.round(value + 'e' + decimalPlaces) + 'e-' + decimalPlaces)
}

const columns = [
  {
    name: 'id',
    parse: v => v
  },
  {
    name: 'zone',
    parse: v => v
  },
  {
    name: 'state',
    parse: v => v
  },
  {
    name: 'lga',
    parse: v => v
  },
  {
    name: 'name',
    parse: v => v
  },
  {
    name: 'product',
    parse: v => 'product:' + v
  },
  {
    name: 'allocation-method',
    parse: v => v
  },
  {
    name: 'tp: target population',
    parse: v => round(parseFloatValue(v), 0)
  },
  {
    name: 'tp: wastage',
    parse: v => round(parseFloatValue(v), 2)
  },
  {
    name: 'tp: coverage',
    parse: v => round(parseFloatValue(v), 2)
  },
  {
    name: 'tp: adjustment',
    parse: v => round(parseFloatValue(v), 2)
  },
  {
    name: 'tp: doses',
    parse: v => round(parseFloatValue(v), 2)
  },
  {
    name: 'bundled: products',
    parse: v => v && v.split(',').map(p => 'product:' + p.trim())
  },
  {
    name: 'bundled: factor',
    parse: parseFloatValue
  },
  {
    name: 'flat: annual allocation',
    parse: parseFloatValue
  },
  {
    name: 'direct order',
    parse: parseIntValue
  },
  {
    name: 'consumption: days to average',
    parse: v => round(parseFloatValue(v), 0)
  },
  {
    name: 'supply: buffer days',
    parse: v => round(parseFloatValue(v), 2)
  },
  {
    name: 'supply: lead time',
    parse: v => round(parseFloatValue(v), 2)
  },
  {
    name: 'supply: period days',
    parse: v => round(parseFloatValue(v), 2)
  },
  {
    name: 'min',
    parse: parseIntValue
  },
  {
    name: 'reOrder',
    parse: parseIntValue
  },
  {
    name: 'max',
    parse: parseIntValue
  }
]

const forecastCreators = {
  [methods.TP]: row => ({
    method: methods.TP,
    targetPopulation: row['tp: target population'],
    wastageFactor: row['tp: wastage'],
    coverageFactor: row['tp: coverage'],
    adjustmentFactor: row['tp: adjustment'],
    dosesInSchedule: row['tp: doses']
  }),
  [methods.BUNDLED]: row => ({
    method: methods.BUNDLED,
    factor: row['bundled: factor'],
    dependentProducts: row['bundled: products']
  }),
  [methods.DIRECT_ORDER]: row => ({
    method: methods.DIRECT_ORDER,
    directOrder: row['direct order']
  })
}

const createSupplyPlan = (row, method) => {
  const defaults = defaultSupplyPlan[method] || {}
  const applyPlan = key => defaults.hasOwnProperty(key) ? defaults[key] : ''
  return {
    bufferDays: row['supply: buffer days'] || applyPlan('bufferDays'),
    leadTimeDays: row['supply: lead time'] || applyPlan('leadTimeDays'),
    supplyPeriodDays: row['supply: period days'] || applyPlan('supplyPeriodDays')
  }
}

const createAllocationFromRows = (productRows, effectiveDate, userName) => {
  const facilityId = productRows[0].id
  const products = productRows.reduce((acc, row) => {
    const method = row['allocation-method']
    if (!method) {
      return acc
    }
    const createForecast = forecastCreators[method]
    if (!createForecast) {
      throw new Error('Cannot create forecast for method: ' + method)
    }
    return Object.assign({}, acc, {
      [row.product]: {
        forecast: createForecast(row),
        supplyPlan: createSupplyPlan(row, method)
      }
    })
  }, {})
  // Return null when no product data was found for the locations rows
  if (Object.keys(products).length === 0) {
    return null
  }
  return createAllocation({facilityId, date: effectiveDate, products, createdBy: userName})
}

module.exports = {
  columns,
  createAllocationFromRows,
  round,
  parseFloatValue
}
