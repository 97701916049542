const FreshsalesAdapter = require('./data-access/crm-adapter')
const tools = require('./tools')
const PGAdapter = require('../common/pg-adapter')

// TODO: write a README for this API
class FreshsalesApi {
  constructor (freshsalesApiConfig = {}, isomorphicFetch, pgConnection, agaveAdapter, logger, user) {
    this.crmAdapter = new FreshsalesAdapter(freshsalesApiConfig, isomorphicFetch, logger)

    if (pgConnection) {
      const columns = ['created_at', 'updated_at', 'entity_name', 'freshsales_id', 'external_id', 'raw_data', 'location_update']
      this.pgAdapter = new PGAdapter(
        pgConnection,
        'avocado.data_freshsales',
        user.name,
        columns,
        'freshsales_id'
      )
    }

    this.agaveAdapter = agaveAdapter

    this.tools = tools
  }

  setMembershipType (membershipType) {
    // `create` is a funny way of calling `post` in the adapter.
    // The real `agaveAdapter.post` does not really call `post`.
    return this.agaveAdapter.create(
      'freshsales/edit-membership', { membershipType })
  }
}

module.exports = FreshsalesApi
