module.exports = toLocationId

const replaceColonWithHyphen = string => string.replace(/:/g, '-')

function toLocationId (properties) {
  if (typeof properties === 'string') {
    return replaceColonWithHyphen(properties)
  }

  if (properties.id) {
    return replaceColonWithHyphen(properties.id)
  }

  if (properties.national) {
    return 'national'
  }

  if (properties.country) {
    return 'country'
  }

  return ['zone', 'state', 'lga'].map((key) => {
    if (properties[key]) return [key, properties[key]].join('-')
  }).filter(Boolean).join('-')
}
