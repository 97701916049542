module.exports = findOrGenerateDeviceId

const DEVICE_STORE_NAME = 'van-device'
const DEVICE_DOC_NAME = '_local/device'

let deviceDb

async function findOrGenerateDeviceId (state) {
  if (!deviceDb) {
    deviceDb = new state.PouchDB({
      prefix: '', // in case a default prefix have been set to use a remote DB
      name: DEVICE_STORE_NAME
    })

    deviceDb.on('destroyed', () => {
      deviceDb = null
    })

    deviceDb.on('closed', () => {
      deviceDb = null
    })
  }

  try {
    return await deviceDb.get(DEVICE_DOC_NAME)
  } catch (error) {
    /* istanbul ignore if */
    if (error.status !== 404) {
      throw error
    }
  }

  const deviceId = generateDeviceId()
  try {
    await deviceDb.put({
      _id: DEVICE_DOC_NAME,
      deviceId,
      createdAt: new Date().toISOString()
    })

    return { deviceId: deviceId }
  } catch (error) {
    // If it's a conflict, we had a race condition
    // and have already saved our device id
    if (error.status === 409) {
      return deviceDb.get(DEVICE_DOC_NAME)
    }

    throw error
  }
}

function generateDeviceId () {
  return Math.random().toString(36).substr(2, 5)
}
