import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import kebabCase from 'lodash/kebabCase'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const ConfirmationTable = ({
  rows
}) => {
  const items = rows.map(row => {
    const { cols = [] } = row

    if (isEmpty(cols)) {
      return null
    }

    const columns = cols.map(col => {
      const colName = kebabCase(col.name)
      const colProps = get(col, 'props', {})

      return (
        <td className={`shipment-confirmation-table__td--${colName}`} {...colProps}>{ col.value }</td>
      )
    })

    return (
      <Fragment>
        <tr
          key={`${row.key}`}
          className={cx(
            'shipment-confirmation-table__tr'
          )}
        >
          { columns }
        </tr>
      </Fragment>
    )
  })

  return (
    <table className={cx(
      'shipment-confirmation-table'
    )}>
      <thead>
        <tr className='shipment-confirmation-table__tr'>
          <th className='shipment-confirmation-table__th shipment-confirmation-table__th--products'>
            Products
          </th>
          <th className='shipment-confirmation-table__th shipment-confirmation-table__th--previous'>
            Previous quantity
          </th>
          <th className='shipment-confirmation-table__th shipment-confirmation-table__th--updated'>
            Updated quantity
          </th>
        </tr>
      </thead>
      <tbody>
        { items }
      </tbody>
    </table>
  )
}

ConfirmationTable.propTypes = {
  /* An array of rows */
  rows: PropTypes.array
}

export default ConfirmationTable
