const dateWithDashRegex = /^(\d{2}|\d{4})-(\d{1,2})-(\d{1,2})$/
const dateWithSlashRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{2}|\d{4})$/

/**
 * Converts dashed year-first format (eg yyyy-mm-dd), to slashed day-first format (eg dd/mm/yyyy). Preserves number of digits given.
 * @param {string} dateString - [yy|yyyy]-[m|mm]-[d|dd]
 * @returns {string} - [d|dd]/[m|mm]/[yy|yyyy] (preserves number of digits given)
 */
function dateToSlashes (dateString) {
  return dateString.replace(dateWithDashRegex, '$3/$2/$1')
}

/**
 * Converts slashed day-first format (eg dd/mm/yyyy) dashed to dashed year-first format (eg yyyy-mm-dd). Preserves number of digits given.
 * @param {string} dateString - [d|dd]/[m|mm]/[yy|yyyy]
 * @returns {string} - [yy|yyyy]-[m|mm]-[d|dd]
 */
function dateToDashes (dateString) {
  return dateString.replace(dateWithSlashRegex, '$3-$2-$1')
}

module.exports = {
  dateToDashes,
  dateToSlashes
}
