import React from 'react'

export const TrendDown = (props) => {
  return (
    <svg stroke='currentColor' fill='currentColor' strokeWidth='0' width='1em' height='1em' viewBox='0 0 56 56' xmlns='http://www.w3.org/2000/svg'>
      <g stroke='none' strokeWidth='2' fill='none' fillRule='evenodd' opacity='0.607514881'>
        <g transform='translate(0.000000, 12.000000)' fill='#000000' fillRule='nonzero'>
          <polygon id='Path' points='39.9 35 46.4265 28.3208333 32.5185 14.0875 21.1185 25.7541667 0 4.1125 4.0185 0 21.1185 17.5 32.5185 5.83333333 50.4735 24.1791667 57 17.5 57 35' />
        </g>
      </g>
    </svg>
  )
}
