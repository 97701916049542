const XLSX = require('@sheet/core')
const SHEET_NAME = 'Warehouse shipment export'
const keyBy = require('lodash/keyBy')
const get = require('lodash/get')
const { getAll: getServices } = require('../../../service')

exports.bulkImport = async function (state, {api, bufferData, startDate, endDate, program, config, dryRun = false}) {
  // This bulk import is currently only used by PSM
  // 1. We read the sheet and get data for each route
  const workbook = XLSX.read(bufferData, {type: 'array'})
  const allocationSheet = workbook.Sheets[SHEET_NAME]
  if (!allocationSheet) {
    throw new Error(`Worksheet '${SHEET_NAME}' not found`)
  }

  const rows = XLSX.utils.sheet_to_json(allocationSheet)

  // Get services for program
  const services = await getServices(state, program)
  const serviceIds = services.map(service => service.id)

  // Get each distinct route
  const distinctRoutes = [...new Set(rows.map(row => row.route))]

  // Save those routes to the DB
  const routesToSave = distinctRoutes.map(route => {
    return {
      name: route,
      startDate,
      endDate,
      useUUID: true,
      programs: {
        [program]: {
          services: serviceIds
        }
      }
    }
  })

  const savedRoutes = []

  for (let route of routesToSave) {
    const savedRoute = await api.routes.save(route, config)
    savedRoutes.push(savedRoute)
  }

  const routesByName = keyBy(savedRoutes, 'name')
  const transfromedRows = rows.map(row => {
    return {
      ...row,
      startDate,
      endDate,
      routeId: get(routesByName[row.route], '_id'),
      program
    }
  })

  if (dryRun) return transfromedRows

  // Send those routes and locations to an SQS queue
  await api.routes.bulkImportLocationRoutes(transfromedRows)
}
