import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'

import { Loading, Page } from '@fielded/shared-ui'
import { Truck } from '@fielded/shared-ui/src/icons'

import { withUser } from '../../../../../common/AuthenticationProvider'
import { findShipments } from '../../../shipments/shipments-reducer'
import { fetchMasterData, selectMasterData } from '../../../root/master-data-reducer'
import FacilityDeliveries from './FacilityDeliveries'

const FacilityDeliveriesContainer = ({ user, history }) => {
  const dispatch = useDispatch()
  const masterData = useSelector(selectMasterData)
  const shipments = useSelector(state => state.shipments)

  const [loading, setLoading] = useState(true)

  const hasReceivedMasterData = get(masterData, 'hasReceivedMasterData', false)

  const initialize = async () => {
    try {
      dispatch(findShipments(user.location))

      if (!hasReceivedMasterData) {
        await dispatch(fetchMasterData())
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  if (loading) {
    return <Loading loadingText='Loading deliveries ...' />
  }

  return (
    <Page>
      <Page.HeaderNew
        title='Deliveries'
        icon={<Truck />}
        showOfflineWarning
      />
      <FacilityDeliveries
        user={user}
        history={history}
        shipments={shipments}
      />
    </Page>
  )
}

export default withUser(FacilityDeliveriesContainer)
