const tools = require('../../tools')

exports.mergeGroups = async function (state, groupIds, data) {
  const snapshots = await Promise.all(groupIds.map(groupId => state.dal.order.getGroup(state, {groupId})))
  const updatedSnapshots = snapshots.map(
    snapshot => tools.overrideSnapshot(snapshot, data, state.user, Date.now())
  )
  const mergedSnapshots = updatedSnapshots.length < 2 ? updatedSnapshots[0] : tools.mergeSnapshotsIntoOneGroup(updatedSnapshots)
  return state.dal.order.bulkDocs(state, mergedSnapshots)
}
