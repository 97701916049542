const isPlainObject = require('lodash/isPlainObject')

const tooBig = num => num > 1e12

const quantitiesValidator = (quantities) => {
  if (!isPlainObject(quantities)) {
    return 'quantities param should be an object'
  }

  const nonInteger = Object.keys(quantities)
    .find(key => !Number.isInteger(quantities[key].adjusted))

  if (nonInteger) {
    return `update quantities must be integers ${nonInteger}`
  }

  const largeNumber = Object.keys(quantities)
    .find(key => tooBig(quantities[key].adjusted))

  if (largeNumber) {
    return `update quantities must be less than a trillion ${largeNumber}`
  }
}

const updateOrderQuantitiesValidator = (quantities) => {
  const originalProductsError = quantitiesValidator(quantities)
  if (originalProductsError) return originalProductsError

  return Object.values(quantities).find(val =>
    (val.substitutions && quantitiesValidator(val.substitutions))
  )
}

const validateSnapshots = snapshots => {
  const badSnapshot = snapshots.find(snapshot =>
    updateOrderQuantitiesValidator(snapshot.products)
  )
  if (badSnapshot) return updateOrderQuantitiesValidator(badSnapshot)
}

const advanceStatusValidator = (programId, groupId) => {
  if (programId && !groupId) return

  if (!groupId) return `groupId required when no programId given.`
}

const validators = {
  listGroups: {
    required: ['programId']
  },
  listProducts: {
    required: ['programId']
  },
  listLocations: {
    required: ['programId', 'productId']
  },
  listOrdersForUser: {
    required: ['programId']
  },
  listOrdersByGroup: {
    required: ['groupId']
  },
  create: {
    required: ['programId']
  },
  updateOrder: {
    required: ['orderId', 'productQuantities'],
    validate: ({productQuantities}) => updateOrderQuantitiesValidator(productQuantities)
  },
  updateOrdersOnProduct: {
    required: ['locationQuantities', 'productId'],
    validate: ({locationQuantities}) => updateOrderQuantitiesValidator(locationQuantities)
  },
  get: {
    required: ['orderId']
  },
  getGroup: {
    required: ['groupId']
  },
  'advanceStatus': {
    validate: ({programId, groupId}) => advanceStatusValidator(programId, groupId)
  },
  'createSnapshot': {
    required: [
      'user',
      'products',
      'destinationId',
      'timestamp',
      'programId',
      'groupId',
      'orderId',
      'status'
    ]
  },
  'createWarehouseSuborders': {
    required: ['groupId']
  },
  'validateSnapshots': {
    validate: validateSnapshots
  }
}

exports.validateParams = function (functionName, params = {}) {
  const messageBase = `Usage error: api.order.${functionName} `

  if (!validators[functionName]) {
    throw new Error(`validateParams usage error: function ${functionName} has no validator setup.`)
  }

  const {required, validate} = validators[functionName]
  const missingParam = required && required.find(param => !params[param])

  if (missingParam) {
    throw new Error(`${messageBase} requires param ${missingParam}`)
  }

  const otherProblem = validate && validators[functionName].validate(params)

  if (otherProblem) {
    throw new Error(`${messageBase} ${otherProblem}`)
  }
}
