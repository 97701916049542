const { sha512 } = require('./../../tools/hash')

module.exports = getMandateStatus
async function getMandateStatus (mandateStatusOptions) {
  const {requestId} = mandateStatusOptions
  const {merchantId, apiKey} = this
  const hash = await sha512(requestId + apiKey + merchantId)
  const opts = {merchantId, requestId, hash}
  const url = Object.keys(opts).reduce((accum, curr) =>
    accum.replace('{{' + curr + '}}', opts[curr])
    , this.endpoints.getMandateStatus)

  return this.fetch(url, {
    method: 'GET'
  })
}
