import React, {Component} from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { thresholdKeys } from '../../utils/thresholdKeys'
import IndicatorColor from '../IndicatorColor'
import NumberFormat from '../NumberFormat'
import { getCurrencySymbol } from '../PriceDisplay'
import ToolTip from '../ToolTip'

const isPercentage = (units) => units === 'percentage' || units === '%'

// These are part of an attempt to determine if percentage labels are overlapping,
// which makes them hard or impossible to read https://github.com/fielded/van-orga/issues/2958
const isSmallThreshold = 13
const isLargeThreshold = 50

const getLineItems = (lineItems, name) => {
  if (!lineItems) return []

  if (Array.isArray(lineItems[name])) {
    return lineItems[name].map(lineItem => ({ id: lineItem }))
  }
  return lineItems[name]
}

class BarChartLine extends Component {
  static propTypes = {
    /**
     * Values are passed as an object with the following keys:
     * warning, alert, ok or excessive.
     */
    values: PropTypes.object.isRequired,
    /**
     * Lets you highlight some of the labels, e.g. to match a highlight in summary
     */
    highlights: PropTypes.array,
    /**
     * Lets you specify a currency, for displaying a currency symbol in monetary values
     */
    currency: PropTypes.oneOf(['NGN', 'KES', 'USD']),
    /**
     * absolute or percentage. Defaults to absolute values
     */
    units: PropTypes.oneOf(['absolute', 'percentage', '%']),
    /**
     * There are two possible heights. Set prop `tall` to render the tall one.
     */
    tall: PropTypes.bool,
    /**
     * Whether to show the tool tip on clicking Line
     */
    showToolTip: PropTypes.bool
  }

  static defaultProps = {
    highlights: [],
    tall: false,
    currency: null
  }

  state = {
    nonZeroItems: [],
    hasSmall: false,
    total: 0,
    max: 0
  }

  componentDidMount () {
    const { values, lineItems } = this.props
    const nonZeroItems = thresholdKeys
      .filter(name => values[name])
      .map(name => ({
        name,
        value: values[name],
        showToolTip: false,
        items: getLineItems(lineItems, name)
      }))

    const total = nonZeroItems.reduce((sum, item) => {
      return sum + item.value
    }, 0)

    let keepTotal = false
    if (Object.keys(values).length > 1) {
      // this means we're doing a split, months-of-stock bar
      // so don't recalc the items against 100%, let '4' fill the entire bar
      keepTotal = true
    }

    const max = total > 100 || keepTotal
      ? total
      : 100

    const hasSmall = nonZeroItems.find(({ value }) => (value / max) * 100 < isSmallThreshold)
    this.setState({nonZeroItems, hasSmall, total, max})
  }

  showItemToolTip = (e, item) => {
    e.preventDefault()
    const { nonZeroItems } = this.state
    const { name, showToolTip } = item

    const newArr = nonZeroItems.map(item => {
      return {
        ...item,
        showToolTip: false
      }
    })
    const updatedItem = {
      ...item,
      showToolTip: !showToolTip
    }

    const index = newArr.findIndex(obj => obj.name === name)
    newArr.splice(index, 1, updatedItem)
    this.setState({ nonZeroItems: newArr })
  }

  render () {
    const { highlights, units, tall, currency, showToolTip, allDefinitions, toolTipLink, usePercentage, text } = this.props
    const { nonZeroItems, total, hasSmall, max } = this.state
    return (
      <div
        className={classnames(
          'bar-chart-line',
          { 'bar-chart-line--tall': tall },
          { 'bar-chart-line--has-small': hasSmall }
        )}
      >
        {!!total && nonZeroItems.map((item, index) => {
          const valuePercentage = Math.round((item.value / total) * 100)
          const relativeWidth = (item.value / max) * 100
          return (
            <NumberFormat
              key={`item-${index}`}
              value={usePercentage ? valuePercentage : item.value}
              displayType='text'
              thousandSeparator
              suffix={usePercentage ? '%' : ''}
              renderText={formattedValue => (
                <span>
                  <span
                    key={item.name}
                    className={classnames(
                      'bar-chart-line__item',
                      { 'bar-chart-line__item--danger': item.name === 'danger' },
                      { 'bar-chart-line__item--critical': item.name === 'alert' },
                      { 'bar-chart-line__item--ok': item.name === 'warning' },
                      { 'bar-chart-line__item--good': item.name === 'ok' },
                      { 'bar-chart-line__item--extra': item.name === 'excessive' },
                      { 'bar-chart-line__item--no-data': item.name === 'noData' },
                      { 'bar-chart-line__item--is-small': relativeWidth < isSmallThreshold },
                      { 'bar-chart-line__item--is-large': relativeWidth > isLargeThreshold },
                      { 'bar-chart-line__item--highlight vs-u-font-bold': highlights.includes(item.name) }
                    )}
                    style={{ width: `${relativeWidth}%` }}
                    data-item-value={isPercentage(units) ? `${formattedValue}%`
                      : currency ? `${getCurrencySymbol({ currency })}${formattedValue}` : formattedValue
                    }
                    onClick={(e) => this.showItemToolTip(e, item)}
                  >
                    {showToolTip && item.items && item.showToolTip &&
                    <span key={item.name} className='bar-chart-line__tooltip'>
                      <ToolTip
                        items={item.items}
                        allDefinitions={allDefinitions}
                        status={item.name}
                        value={item.value}
                        valuePercentage={valuePercentage}
                        toolTipLink={toolTipLink}
                        text={text}
                      />
                    </span>
                    }
                    <IndicatorColor colorCode={item.name} />
                  </span>
                </span>
              )}
            />
          )
        })}
      </div>
    )
  }
}

export default BarChartLine
