const { getAllocatedStockParams, createExport } = require('./utils')
const { getAllocatedStock } = require('../api/read/get-allocated-stock')
const { listBatches } = require('../../batch/api/batch-list')
const { generateExcelSheet } = require('../../utils/generate-excel-sheet')

// returns data used in test
const exportReservedStockData = async function (state,
  {
    programs = [{
      programId: 'program:hiv-aids',
      funders: ['funder:pepfar'],
      warehouseCode: 'DC-303 :: Axios Warehouse Awka'
    }],
    exportStockLevels = false
  } = {}
) {
  const includeExpired = exportStockLevels
  const paramsPerVirtualWarehouse = getAllocatedStockParams({programs})
  const warehouseStockSituations = await Promise.all(
    paramsPerVirtualWarehouse.map(async locationsParams => {
      const stock = await getAllocatedStock(
        state, Object.assign(locationsParams, {includeExpired})
      )
      const {ledger, allocatedStock, productsById} = stock
      const {warehouseCode, programId, funderId} = locationsParams
      return {ledger, allocatedStock, warehouseCode, programId, productsById, funderId}
    })
  )
  const batches = exportStockLevels
    ? await listBatches(state)
    : []
  return createExport(warehouseStockSituations, exportStockLevels, batches)
}

// returns an excel file that is called by the UI
const exportReservedStock = async function (state, {programs, exportStockLevels}) {
  const reserveStock = await exportReservedStockData(state, {programs, exportStockLevels})
  return generateExcelSheet(reserveStock, 'Bulk Allocation')
}
module.exports = {exportReservedStockData, exportReservedStock}
