import React from 'react'

const PrintSectionHeader = ({
  title,
  subtitle
}) => {
  return (
    <div className='print-section-header'>
      <h1 className='print-section-header__title'>
        {title}
      </h1>
      {subtitle && (
        <div className='print-section-header__subtitle'>
          {subtitle}
        </div>
      )}
    </div>
  )
}

export default PrintSectionHeader
