/**
 * This module contains functions to encode allocations as XLSX
 */
const XLSX = require('@sheet/core')

const {takeLatest} = require('./tools/read/generic-utils')

const {
  ALLOCATIONS_WORKSHEET_NAME
} = require('./config')

const {
  createRowsFromAllocation,
  columns
} = require('./encode-utils')

const encode = (rows) => {
  const workbook = XLSX.utils.book_new()
  const header = columns.map(({name}) => name)
  const sheetData = [
    header,
    ...rows
  ]
  const worksheet = XLSX.utils.aoa_to_sheet(sheetData)

  // Color those columns grey to
  // show that they are calculated values
  const greyIndices = [
    header.indexOf('min'),
    header.indexOf('reOrder'),
    header.indexOf('max')
  ]

  // !ref is a special key referencing sheet range
  // https://docs.sheetjs.com/#sheet-objects
  const sheetRange = XLSX.utils.decode_range(worksheet['!ref'])
  const rowCount = sheetRange.e.r
  const greyedColumns = XLSX.utils.encode_range({
    s: { c: Math.min.apply(Math, greyIndices), r: 0 },
    e: { c: Math.max.apply(Math, greyIndices), r: rowCount }
  })

  XLSX.utils.sheet_set_range_style(worksheet, greyedColumns, {
    fgColor: { rgb: 0xEFEFEF }
  })

  XLSX.utils.book_append_sheet(workbook, worksheet, ALLOCATIONS_WORKSHEET_NAME)
  // Generate the workbook and return an ArrayBuffer
  return XLSX.write(workbook, {type: 'array', compression: true, cellStyles: true})
}

module.exports = (allocations, additionalRows = []) => {
  const rows = takeLatest(allocations).reduce(
    (acc, allocation) => acc.concat(createRowsFromAllocation(allocation)), []
  )
  return encode(rows.concat(additionalRows))
}
