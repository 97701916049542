const getLocation = require('../../../location/api/read').get
const getConfig = require('../../../configuration/api/read').getByServiceId
const toShipmentLocationId = require('../../../shipment/tools/to-location-id')
const { RETAIL_STOCK_PERMISSION } = require('../../tools')

const {
  getDBPerUserDatabaseName,
  deriveReplicationId,
  deriveLocationId
} = require('../../tools')

const getPharmacyUserConfig = async function (state, user, url) {
  if (!user || !user._id || !user.roles || !user.roles.length || url === undefined) {
    throw new Error('usage: user object and url required')
  }

  const {
    integratedDataDBName,
    documentsDBName,
    shipmentsDBName,
    stockCountDBName
  } = state

  const locationId = deriveLocationId(user)

  const integratedDataIds = ['programs', 'geolocations', locationId]
  const location = await getLocation(state, locationId)

  if (!location) {
    throw new Error(`Location with ID ${locationId} was not found`)
  }

  let config
  if (location.services.length > 0) {
    config = await getConfig(state, location.services[0])
  }
  if (!config) {
    throw new Error(`Service configuration with ID ${location.services[0]} was not found`)
  }

  integratedDataIds.push(config._id)
  const userDBName = getDBPerUserDatabaseName(user)

  const shipmentsAndStockCounts = user.roles.includes(RETAIL_STOCK_PERMISSION)

  // Just a constant for replication ids
  const userDB = 'userDB'

  /*
   * NOTE: when adding/removing/changing this list,
   * you can run the backend function "per_user_db_apply" to have
   * the changes applied to all users after redeploying the backend
   */
  const replications = [
    {
      _id: deriveReplicationId(user._id, { from: userDB, to: integratedDataDBName }),
      source: {
        url: `${url}${userDBName}`
      },
      target: {
        url: `${url}${integratedDataDBName}`
      },
      continuous: true,
      selector: {
        '$and': [
          // Hard code allocation docs as the only ones the user can edit
          // along with only their facility's allocation doc
          { type: 'allocation' },
          { 'facilityId': locationId }
        ]
      }
    },
    {
      _id: deriveReplicationId(user._id, { from: integratedDataDBName, to: userDB }),
      source: {
        url: `${url}${integratedDataDBName}`
      },
      target: {
        url: `${url}${userDBName}`
      },
      continuous: true,
      selector: {
        '$or': [
          { _id: { '$in': [...new Set(integratedDataIds)] } },
          { 'facilityId': locationId }, // this will replicate existing allocations
          { 'type': 'product' } // this will replicate all produc
        ]
      }
    },
    {
      _id: deriveReplicationId(user._id, { from: documentsDBName, to: userDB }),
      source: {
        url: `${url}${documentsDBName}`
      },
      target: {
        url: `${url}${userDBName}`
      },
      continuous: true,
      selector: { 'facilityId': locationId, type: 'documentEvent' }
    },
    {
      _id: deriveReplicationId(user._id, { from: userDB, to: documentsDBName }),
      source: {
        url: `${url}${userDBName}`
      },
      target: {
        url: `${url}${documentsDBName}`
      },
      continuous: true,
      selector: { 'facilityId': locationId, type: 'documentEvent' }
    }
  ]

  if (shipmentsAndStockCounts) {
    // Replicate Shipments
    replications.push({
      _id: deriveReplicationId(user._id, { from: shipmentsDBName, to: userDB }),
      source: {
        url: `${url}${shipmentsDBName}`
      },
      target: {
        url: `${url}${userDBName}`
      },
      continuous: true,
      selector: {
        _id: {
          // Note: extra colon is to "terminate" location id,
          // to handle cases like:
          // country:ke:state:nairobi:sdp:burhan-pharmacy
          // country:ke:state:nairobi:sdp:burhan-pharmacy-2
          '$regex': `${toShipmentLocationId(locationId)}:`
        }
      }
    })

    // Replicate Stock Counts
    replications.push({
      _id: deriveReplicationId(user._id, { from: stockCountDBName, to: userDB }),
      designDocs: ['_design/api'],
      source: {
        url: `${url}${stockCountDBName}`,
        name: stockCountDBName
      },
      target: {
        url: `${url}${userDBName}`,
        name: userDBName
      },
      continuous: true,
      selector: {
        _id: {
          // Note: extra colon is to "terminate" location id, see shipments
          '$regex': `${locationId}:`
        }
      }
    })
  }

  return replications
}

exports.getUserDBReplicationRules = async function (state, user, url) {
  if (!user || !user._id || !user.roles || !user.roles.length || url === undefined) {
    throw new Error('usage: user object and url required')
  }

  if (user.roles.includes('db-per-user-no-sync')) {
    return []
  }

  return getPharmacyUserConfig(state, user, url)
}
