// TODO: these are the basic properties but we'll have to fill this out more.
module.exports = {
  title: 'product',
  name: 'product',
  description: 'A product',
  type: 'object',
  properties: {
    _id: {
      type: 'string'
    },
    type: {
      type: 'string',
      default: 'product'
    },
    createdBy: {
      type: 'string'
    },
    updatedBy: {
      type: 'string'
    },
    name: {
      type: 'string'
    },
    fullName: {
      type: 'string'
    }
  },
  required: [
    '_id',
    'type',
    'createdAt',
    'createdBy',
    'updatedAt',
    'updatedBy',
    'name'
  ]
}
