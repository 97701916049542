import React from 'react'
import get from 'lodash/get'
import { pluralize } from '../../../van-shared/utils'
import { getThresholds } from '@fielded/fs-api/lib/forecast/tools/read/psm-thresholds'

/*
 * This has indicator configs,
 * what gets rendered on the card,
 * what scale they are using,
 */
const thresholds = {
  basic: [
    { size: 'xl', color: 'alert', range: '<70%', label: 'Critical' },
    { size: 'm', color: 'warning', range: '70-90%', label: 'Alert' },
    { size: 's', color: 'ok', range: '>90%', label: 'Good' }
  ],

  stockOutRate: [
    { size: 's', color: 'ok', range: '0-5%', label: 'Good' },
    { size: 'm', color: 'warning', range: '5-10%', label: 'Alert' },
    { size: 'xl', color: 'alert', range: '>10%', label: 'Critical' }
  ],

  flaggedReports: [
    { size: 's', color: 'ok', range: '0-20%', label: 'Good' },
    { size: 'm', color: 'warning', range: '20-50%', label: 'Alert' },
    { size: 'xl', color: 'alert', range: '>50%', label: 'Critical' }
  ],

  expiryPsm: [
    { size: 's', color: 'alert', range: '< 6 months', label: 'Written off', filterKey: 'warehouse' },
    { size: 's', color: 'warning', range: '< 10 Months', label: 'Almost written off', filterKey: 'warehouse' },
    { size: 's', color: 'ok', range: '11+ months', label: 'OK', filterKey: 'warehouse' },
    { size: 's', color: 'alert', range: '< 3 months', label: 'Written off', filterKey: 'sdp' },
    { size: 's', color: 'warning', range: '< 7 Months', label: 'Almost written off', filterKey: 'sdp' },
    { size: 's', color: 'ok', range: '8+ months', label: 'OK', filterKey: 'sdp' }
  ],

  consumption: [
    { size: 's', color: 'warning', range: '> 20%', label: 'Needs review' },
    { size: 's', color: 'ok', range: '0 - 20%', label: 'Normal' }
  ],

  none: []
}

const NUMBERS_AS_STRINGS = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five'
]

export default {
  'stock-report-rate': {
    name: 'stock reporting rate',
    message: 'How well is the stock reporting tool used?',
    info: (<div><p>Stock reporting rate describes how many stock reports were entered by each location.</p>
      <p>It is calculated as: <em>Number of <strong>entered reports</strong>  / expected number of reports.</em></p></div>),
    slug: 'stock-report-rate',
    unit: '%',
    thresholds: [
      { values: thresholds.basic }
    ],
    detailViews: [
      { detail: 'locations', tier: 'sub-tiers' },
      { detail: 'timeline' }
    ],
    offline: false,
    apps: { psm: true },
    serverName: 'stockReportingRate'
  },

  'flagged-reports': {
    name: 'flagged reports',
    message: 'How many of the locations have one or more QC flag on their reports',
    info: (<div><p>Number of locations that received one or more QC flags on their reports last period.</p></div>),
    slug: 'flagged-reports',
    unit: '%',
    thresholds: [
      { values: thresholds.flaggedReports }
    ],
    detailViews: [
      { detail: 'locations', tier: 'sub-tiers' },
      { detail: 'timeline' }
    ],
    offline: false,
    apps: { psm: true },
    serverName: 'flaggedReports'
  },

  'stock-out-rate': {
    name: 'stock out rate',
    message: 'How often was the location stocked out?',
    info: (<div><p>Stock out rate describes how many products have been reported with 0 stock.</p>
      <p>It is calculated as: <em>Number of <strong>products with 0 stock</strong>  / total number of products reported.</em></p></div>),
    slug: 'stock-out-rate',
    unit: '%',
    thresholds: [
      { values: thresholds.stockOutRate }
    ],
    detailViews: [
      { detail: 'locations', tier: 'sub-tiers' },
      { detail: 'products' }
    ],
    offline: false,
    apps: { psm: true },
    serverName: 'stockOutRate'
  },

  'months-of-stock': {
    name: 'months of stock (tracer)',
    message: (programIds = []) => {
      if (!programIds.length) {
        return ''
      }
      const reOrderPoint = get(getThresholds(programIds[0]), 'reOrder.months')
      for (const programId of programIds.slice(1)) {
        if (reOrderPoint !== get(getThresholds(programId), 'reOrder.months')) {
          // multiple thresholds to show
          return 'How many tracer commodities are in critical or alert state per location?'
        }
      }
      return `How many tracer commodities have less than ${NUMBERS_AS_STRINGS[reOrderPoint]} ${pluralize('month', reOrderPoint)} of stock per location?`
    },
    subLine: (programIds = []) => { // rendered on summary card
      if (!programIds.length) {
        return ''
      }
      const reOrderPoint = get(getThresholds(programIds[0]), 'reOrder.months')
      for (const programId of programIds.slice(1)) {
        if (reOrderPoint !== get(getThresholds(programId), 'reOrder.months')) {
          // multiple thresholds to show
          return 'in critical or alert state'
        }
      }
      return `less than ${reOrderPoint} ${pluralize('month', reOrderPoint)}`
    },
    info: (<div><p>This card depicts the overall months of stock across facilities for each commodity available in the state.</p></div>),
    slug: 'months-of-stock',
    unit: 'product(s)',
    thresholds: (programIds = []) => {
      const thresholds = []
      for (const programId of programIds) {
        const programThresholds = getThresholds(programId)
        // Something is wrong, we don't have thresholds for this program. Ignore.
        if (!programThresholds) {
          continue
        }
        thresholds.push({
          id: programId,
          values: [{
            size: 's',
            color: 'alert',
            range: `<${programThresholds.min.months} ${pluralize('month', programThresholds.min.months)}`,
            label: 'Critical'
          },
          {
            size: 's',
            color: 'warning',
            range: `${programThresholds.min.months}-${programThresholds.reOrder.months} ${pluralize('month', programThresholds.reOrder.months)}`,
            label: 'Alert'
          },
          {
            size: 's',
            color: 'ok',
            range: `${programThresholds.reOrder.months}-${programThresholds.max.months} months`,
            label: 'Good'
          },
          {
            size: 's',
            color: 'excessive',
            range: `>${programThresholds.max.months} months`,
            label: 'Overstock'
          }]
        })
      }
      return thresholds
    },
    detailViews: [
      { detail: 'locations', tier: 'sub-tiers' },
      { detail: 'products' },
      { detail: 'funders' }
    ],
    offline: false,
    apps: { psm: true },
    serverName: 'monthsOfStock'
  },

  'consumption': {
    name: 'Average Monthly Consumption',
    message: () => {
      return `How many tracer commodities are in need of review?`
    },
    subLine: () => {
      return `greater than 20% change`
    },
    info: (<div><p>This card shows percentage of change in average monthly consumption compared to an average from the previous 3 reporting cycles across facilities for each commodity available in the state.</p></div>),
    slug: 'consumption',
    unit: 'product(s)',
    detailViews: [
      { detail: 'products' },
      { detail: 'locations', tier: 'sub-tiers' }
    ],
    offline: false,
    apps: { psm: true },
    serverName: 'consumption',
    thresholds: [
      { values: thresholds.consumption }
    ]
  },

  'order-fill-rate': {
    name: 'order fill rate',
    message: 'How well is a location being replenished by its parent store?',
    info: (<div><p>Order fill rate measures how well an SDP is being replenished by its parent stores, relative to the requested needs. A low value means the store is not receiving enough supplies.<br />It's calculated as: <br /> <i>Approved QTO / Requested QTO.</i></p></div>),
    slug: 'order-fill-rate',
    unit: '%',
    thresholds: [
      { values: thresholds.basic }
    ],
    detailViews: [
      { detail: 'locations', tier: 'sub-tiers' },
      { detail: 'products' }
    ],
    offline: false,
    apps: { psm: true },
    serverName: 'orderFillRate'
  },

  /*
   * NOTE: this is expiry for PSM
   */
  'expiry-psm': {
    name: 'Expiry',
    // Line break is there to make it 2 lines like the others
    message: <span>What is the value of stock that has been written off?</span>,
    info: (
      <div>
        <p>
          Quantity and monetary value of products expiring in 6 months (in warehouses) or 3 months (in SDPs).
        </p>
      </div>
    ),
    slug: 'expiry-psm',
    currency: 'USD',
    unit: '$',
    subLine: '',
    thresholds: (programIds, filters) => {
      return [{ values: thresholds.expiryPsm.filter(({ filterKey }) => filterKey === filters.productLocations) }]
    },
    detailViews: [
      { detail: 'products' },
      { detail: 'locations', tier: 'sub-tiers' }
    ],
    offline: false,
    apps: { psm: true },
    serverName: 'expiryPSM'
  }
}

export const headerContent = {
  'stock-out-rate': {
    views: {
      product: {
        nameColumn: 'Product name',
        dataColumn: 'Stockout rate for product',
        totalColumn: ''
      },
      location: {
        nameColumn: 'Location',
        serviceColumn: 'Service Category',
        dataColumn: 'Stock out rate',
        totalColumn: 'Number of facilities'
      }
    }
  },
  'months-of-stock': {
    views: {
      product: {
        nameColumn: 'Product name',
        dataColumn: 'Locations',
        totalColumn: 'Total months of stock'
      },
      location: {
        nameColumn: 'Location name',
        dataColumn: 'Products',
        totalColumn: 'Products below minimum thresholds'
      },
      funder: {
        nameColumn: 'Funder Name',
        dataColumn: 'Products',
        totalColumn: 'Products Below Minimum Thresholds'
      }
    }
  },
  'consumption': {
    views: {
      product: {
        nameColumn: 'Product name',
        dataColumn: () => {
          return ['Distribution over facilities', 'Percent Change +/-']
        },
        totalColumn: 'Average Monthly Consumption'
      },
      location: {
        nameColumn: 'Location name',
        dataColumn: () => {
          return ['Distribution over facilities', 'Percent Change +/-']
        },
        totalColumn: 'Average Monthly Consumption'
      }
    }
  },
  'expiry': {
    views: {
      product: {
        nameColumn: 'Product name',
        dataColumn: 'Distribution of products over facilities',
        totalColumn: 'Value of stock written off'
      },
      location: {
        nameColumn: 'Location name',
        dataColumn: (filters) => {
          if (filters.productId) return ['Status', 'Batch Number', 'Expiry Date']
          return ['Distribution of products over facilities']
        },
        totalColumn: 'Value of stock written off'
      }
    }
  },
  'expiry-amounts': {
    views: {
      product: {
        nameColumn: 'Product name',
        dataColumn: 'Distribution of products over facilities',
        totalColumn: 'Amount of stock written off'
      },
      location: {
        nameColumn: 'Location name',
        dataColumn: ['Status', 'Batch Number', 'Expiry Date'],
        totalColumn: 'Amount of stock written off'
      }
    }
  },
  'forecast-accuracy': {
    views: {
      product: {
        nameColumn: 'Product name',
        dataColumn: 'Forecast accuracy for product',
        totalColumn: ''
      },
      location: {
        nameColumn: 'Location name',
        dataColumn: 'Forecast accuracy for location',
        totalColumn: ''
      }
    }
  },
  'distribution-reporting-rate': {
    views: {
      product: {
        nameColumn: 'Product name',
        dataColumn: 'Distribution reporting rate for product',
        totalColumn: ''
      },
      location: {
        nameColumn: 'Location name',
        dataColumn: 'Distribution reporting rate for location',
        totalColumn: ''
      }
    }
  },
  'stock-report-rate': {
    views: {
      location: {
        nameColumn: 'Location name',
        dataColumn: 'Stock report rate for location',
        totalColumn: ''
      }
    }
  },
  'flagged-reports': {
    views: {
      location: {
        nameColumn: 'Location name',
        dataColumn: 'Locations with one or more flagged reports',
        totalColumn: ''
      }
    }
  },
  'order-fill-rate': {
    views: {
      product: {
        nameColumn: 'Product name',
        dataColumn: 'Fill rate for product',
        totalColumn: ''
      },
      location: {
        nameColumn: 'Location name',
        dataColumn: 'Fill rate for location',
        totalColumn: ''
      }
    }
  }
}
