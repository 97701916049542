exports.createServiceConfig = async (state, service, username = '') => {
  const configDoc = {
    _id: `configuration:${service.id}`,
    service: service.id,
    type: 'configuration',
    products: [],
    packages: [],
    createdBy: username,
    createdAt: new Date().toISOString()
  }

  const doc = await state.dal.configuration.save(state, configDoc)
  return doc
}
