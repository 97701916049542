const tools = require('../../tools')
const shortid = require('shortid')

/*
  This function transfers all products set to be supplied by a certain supplier/packpoint
  and moves them to another supplier.
  If the supplier doesn't exist in the group, we create a new subOrder
  If the subOrder exist but that product does not exist we add it to that subOrder
*/

const switchSupplier = async function (
  state,
  { prevSupplier, newSupplier, groupId, dryRun }
) {
  // Get all snapshots and filter by supplierId to get snapshots we want to swap from aand to
  const snapshots = await state.dal.order.getGroup(state, { groupId })
  const snapShotsToAddTo = snapshots.filter(({ supplierId }) => supplierId === newSupplier)
  const snapShotsToZero = snapshots.filter(({ supplierId }) => supplierId === prevSupplier)

  let updatedOrders = []

  if (snapShotsToAddTo.length > 0) {
    updatedOrders = tools.addProductsToSubOrders(
      snapShotsToZero,
      snapShotsToAddTo,
      newSupplier
    )
  } else {
    // If a subOrder with that supplier deoes not exist, creating a new subOrder
    updatedOrders = snapShotsToZero.map(snapshot => {
      const suborderId = shortid.generate()
      const newId = `${snapshot._id.split(':suborderId')[0]}:suborderId:${suborderId}`
      return {
        ...snapshot,
        _id: newId,
        suborderId,
        supplierId: newSupplier
      }
    })
  }

  // Zero out products in subOrder we want to switch from
  const zeroedOutOrders = tools.zeroOutSubOrders(snapShotsToZero)

  // For test
  if (dryRun) {
    return updatedOrders.concat(zeroedOutOrders)
  }

  const updatedSnapshots = await state.dal.order.bulkDocs(
    state,
    updatedOrders.concat(zeroedOutOrders)
  )
  return updatedSnapshots
}
module.exports = { switchSupplier }
