import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { IconBadge, PriceDisplay, QuantityDisplay, Text } from '@fielded/shared-ui'
import { PayAsYouSell, PayOnDelivery } from '@fielded/shared-ui/src/icons'

import { DIRECT_ORDER_TYPES } from '@fielded/fs-api/lib/allocation/config'
const { PAY_ON_DELIVERY, PAY_AS_YOU_SELL } = DIRECT_ORDER_TYPES

// TODO: make reusable
// This isn't really a generic component just yet as some things,
// like breakpoint and expected label length, are hardcoded in the scss
export const RotatedTh = ({ children, postfix, className }) => (
  <th className={classnames(
    'rotate-col-header',
    className
  )}>
    <div className='rotate-col-header__position-wrapper'>
      <div className='rotate-col-header__rotated'>
        {children} {postfix && (
          <span className='rotate-col-header__postfix'>
            {postfix}
          </span>
        )}
      </div>
    </div>
  </th>
)

const ShipmentCRContentsTable = ({
  items,
  type,
  shipmentDate,
  shipmentCountry,
  includeVat,
  includeCode
}) => {
  let colCount = 6
  let colCutoff = 3
  if (includeCode) {
    colCount += 1
    colCutoff += 1
  }

  return (
    <div
      className={classnames(
        'delivery-cr-contents',
        {'delivery-cr-contents--has-code': includeCode}
      )}
    >
      <table>
        <thead>
          <tr>
            <th colspan={colCutoff} />
            <th
              colspan={colCount - colCutoff}
              className='delivery-cr-contents__table-cutoff delivery-cr-contents__table-info'
            >
              Invoicing
            </th>
          </tr>
          <tr>
            {includeCode && <th>Code</th>}
            <th>Product</th>
            <RotatedTh>
              Qty Requested
            </RotatedTh>
            <RotatedTh>
              Qty Returned
            </RotatedTh>
            <RotatedTh className='delivery-cr-contents__table-cutoff'>
              Qty Billed
            </RotatedTh>
            <RotatedTh>
              Unit price
            </RotatedTh>
            <RotatedTh>
              Sub-total
            </RotatedTh>
          </tr>
        </thead>
        <tbody>
          {items && items.length ? items.map(item => {
            return (
              <tr key={item.code}>
                {includeCode && <td>{item.code}</td>}
                <td className='delivery-cr-contents__highlight'>
                  <span className='delivery-cr-contents__icon'>
                    {item.subscriptionType === PAY_AS_YOU_SELL && (
                      <IconBadge
                        colorCode={PAY_AS_YOU_SELL}
                        icon={<PayAsYouSell />}
                      />
                    )}

                    {item.subscriptionType === PAY_ON_DELIVERY && (
                      <IconBadge
                        colorCode={PAY_ON_DELIVERY}
                        icon={<PayOnDelivery />}
                      />
                    )}
                  </span>
                  {item.name}
                </td>
                <td>
                  <QuantityDisplay
                    value={item.originalQuantity}
                  />
                </td>
                <td>
                  <QuantityDisplay
                    className='delivery-cr-contents__highlight'
                    value={item.originalQuantity - item.quantityToCover}
                  />
                </td>
                <td className='delivery-cr-contents__table-cutoff'>
                  <QuantityDisplay
                    value={item.quantityToCover}
                  />
                </td>
                <td>
                  <PriceDisplay
                    value={item.price}
                    country={shipmentCountry}
                    currencySymbol
                    bold
                    inColor
                  />
                </td>
                <td>
                  {item.totalToPay <= 0 ? (
                    <Text bold>-</Text>
                  ) : (
                    <PriceDisplay
                      value={item.totalToPay}
                      country={shipmentCountry}
                      currencySymbol
                      bold
                    />
                  )}
                </td>
              </tr>
            )
          }) : (
            <tr>
              <td colSpan={colCount}>
                There are no products in this delivery
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

ShipmentCRContentsTable.propTypes = {
  /**
   * Array of delivery items (can be generated with makeDeliveryItems())
   */
  items: PropTypes.array,
  /**
   * The type of shipment content to view
   */
  type: PropTypes.oneOf(['delivery', 'package']),
  /**
   * Which date should be shown as the price month
   */
  shipmentDate: PropTypes.date,
  /**
   * This will decide currency formatting
   */
  shipmentCountry: PropTypes.string,
  /**
   * Should the table include product code
   */
  includeCode: PropTypes.bool
}

ShipmentCRContentsTable.defaultProps = {
  items: [],
  type: 'delivery',
  shipmentDate: new Date(),
  shipmentCountry: 'ng',
  includeCode: false
}

export default ShipmentCRContentsTable
