const ENDPOINTS = {
  getToken: 'oauth/v1/generate?grant_type=client_credentials',
  createDebit: 'mpesa/stkpush/v1/processrequest',
  getDebitStatus: 'mpesa/stkpushquery/v1/query'
}

module.exports = {
  PROVIDER_CODE: 'SAFARICOM',
  DISPLAY_NAME: 'M-Pesa',
  SUPPORTED_GEOLOCATIONS: ['ke'],
  ENDPOINTS
}
