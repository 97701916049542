import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ReportField from './ReportField'
import { AlertCallout } from '@fielded/shared-ui'

const AlertReportField = (props) => {
  const {
    showAlert,
    isAlertClosed,
    isAlertExpanded,
    comments,
    message,
    onToggleClosed,
    onToggleExpanded,
    onSave,
    fieldProps
  } = props
  return (
    <div
      className={classnames(
        'alert-report-field',
        {'alert-report-field--show-alert': showAlert},
        {'alert-report-field--has-unclosed': showAlert && !isAlertClosed},
        {'alert-report-field--has-expanded': showAlert && isAlertExpanded}
      )}
    >
      {showAlert && (
        <AlertCallout
          message={message}
          closed={isAlertClosed}
          comments={comments}
          onToggleClosed={onToggleClosed}
          onSave={onSave}
          onToggleExpanded={onToggleExpanded}
          triggerWrapperClassName='alert-report-field__alert-trigger'
          detailsWrapperClassName='alert-report-field__alert-details'
        />
      )}
      <div className='alert-report-field__content'>
        <ReportField {...fieldProps} />
      </div>
    </div>
  )
}

AlertReportField.propTypes = {
  showAlert: PropTypes.bool,
  isAlertClosed: PropTypes.bool,
  isAlertExpanded: PropTypes.bool,
  comments: PropTypes.array,
  message: PropTypes.string.isRequired,
  onToggleClosed: PropTypes.func.isRequired,
  onToggleExpanded: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  fieldProps: PropTypes.object.isRequired
}

AlertReportField.defaultProps = {
  showAlert: false,
  isAlertClosed: false,
  isAlertExpanded: false,
  comments: undefined
}

export default AlertReportField
