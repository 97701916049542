import capitalize from '../capitalize'

const storeName = (location, prop = 'storeFullName') => {
  if (location[prop]) {
    return location[prop]
  }
  if (location.storeName) {
    return location.storeName
  }
  if (location.fullName || location.name) {
    return location.fullName || location.name
  }

  const id = location._id || (location.location && location.location.id)
  if (id) {
    const idName = id.split(':').pop().split('-').join(' ')
    return capitalize(idName)
  }
  return ''
}

export const name = (location, version = 'full') => {
  const versionToProp = {
    full: 'storeFullName',
    long: 'storeFullName',
    short: 'storeName'
  }

  if (!location) {
    return ''
  }
  return storeName(location, versionToProp[version])
}

export const geoName = (location, options = {}) => {
  const optsToProps = {
    'full-short': [
      'shortFullName',
      'fullName',
      'name'
    ],
    'full-long': [
      'fullName',
      'shortFullName',
      'name'
    ],
    'default-short': [
      'shortName',
      'name',
      'fullName'
    ],
    'default-long': [
      'name',
      'shortName',
      'fullName'
    ]
  }

  if (!location) {
    return ''
  }

  const geoLocation = location.location

  if (!geoLocation) {
    return storeName(location)
  }

  const {
    version = 'default',
    size = 'long'
  } = options
  const key = `${version}-${size}`
  const props = optsToProps[key] || optsToProps['default-long']
  for (let prop of props) {
    if (geoLocation[prop]) {
      return geoLocation[prop]
    }
  }

  return storeName(location)
}
