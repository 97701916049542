const FREE_DELIVERY_MARKET_LIMIT = {
  // For now, there is no delivery threshhold but leaving this code as is as we might go back.
  'ke': {
    nairobi: 8000,
    instantDelivery: 10000,
    default: 10000
  },
  'ng': {
    delta: 0, // 206000
    edo: 0, // 258000
    enugu: 0, // 161000
    fct: 0, // 196000
    kaduna: 0, // 204000
    kano: 0, // 212000
    kwara: 0, // 48000
    lagos: 0, // 86000
    oyo: 0, // 117000
    rivers: 0, // 232000
    instantDelivery: 0, // 53000
    default: 0 // 53000
  }
}

export const getFreeDeliveryLimitPerMarket = (country, market = 'default', isFastDelivery) => {
  if (!country) {
    return 0
  }

  if (isFastDelivery) {
    return FREE_DELIVERY_MARKET_LIMIT[country].instantDelivery
  }

  return FREE_DELIVERY_MARKET_LIMIT[country][market] || FREE_DELIVERY_MARKET_LIMIT[country].default
}

export const isDeliveryFree = (orderValue, country, market = 'default', isFastDelivery) => {
  if (!country || !orderValue) {
    return false
  }

  // We are making sure that if there was a market prop,
  /// but is not in the listed in const,
  // we return the default one
  const limitPerMarket = getFreeDeliveryLimitPerMarket(country, market, isFastDelivery)

  return orderValue >= limitPerMarket
}
