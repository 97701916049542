const tools = require('../../tools')
const { getAllLocations } = require('../master-data')
const { listAll: getAllProducts } = require('./../../../product')

// UI calls this, previews it, and passes back snapshots to bulkImport
exports.createPSMBulkImportSnapshots = async function (state, data, {date} = {}) {
  const locations = await getAllLocations(state, date)
  const products = await getAllProducts(state)
  const result = tools.createPSMBulkImportSnapshots(data, locations, products, state.user)
  // Throw if the user or our code made any invalid snapshots
  tools.validateParams('validateSnapshots', result.snapshots)
  return result
}

exports.bulkImport = async function (state, docs) {
  return state.dal.order.bulkDocs(state, docs)
}
