const get = require('lodash/get')
const fields = [
  {id: 'location_sku', exportId: 'Location SKU'},
  {id: 'yearweek', exportId: 'Week Nr.'},
  {id: 'sku', exportId: 'SKU'},
  {id: 'product', exportId: 'Product Name'},
  {id: 'location', exportId: 'Location'},
  {id: 'date', exportId: 'Delivery Date'},
  {id: 'closing', exportId: 'Closing'},
  {id: 'prev_opening', exportId: 'Opening balance'},
  {id: 'sold', exportId: 'Quantity sold'},
  {id: 'special'},
  {id: 'delivered', exportId: 'Delivery'},
  {id: 'current_opening', exportId: 'New Opening'},
  {id: 'sellprice', exportId: 'Sell Price'},
  {id: 'buyprice'},
  {id: 'sales_value', exportId: 'Sales$'},
  {id: 'credit'},
  {id: 'credit_value'},
  {id: 'notes', exportId: 'Notes'},
  {id: 'plus_vat'},
  {id: 'prev_sl_balance', exportId: 'Previous SL Balance'},
  {id: 'sl_balance', exportId: 'SL Balance'},
  {id: 'sl_sold', exportId: 'SL Sold'},
  {id: 'prev_partner_balance', exportId: 'Previous Partner Balance'},
  {id: 'partner_balance', exportId: 'Partner Balance'},
  {id: 'partner_sold', exportId: 'Partner Sold'},
  {id: 'location_id'},
  {id: 'driver', exportId: 'Driver'},
  {id: 'delivery_notes', exportId: 'Delivery comment'},
  {id: 'service_id'},
  {id: 'delivered_value', exportId: 'Delivered$'},
  {id: 'invoice_adjustment', exportId: 'Invoice Adjustments'},
  {id: 'forecast_adjustment', exportId: 'Forecast Adjustments'},
  {id: 'invoice_adjustment_date', exportId: 'Invoice Adjustment Date'},
  {id: 'sales_adjustment', exportId: 'Sales Adjustment'}
]

function getRestPayload (exportRow, transactionId, location, serviceId) {
  const row = {}
  for (const field of fields) {
    if (!field.exportId) {
      continue
    }
    const value = exportRow[field.exportId]
    if (value != null) {
      row[field.id] = exportRow[field.exportId]
    }
  }
  row.transaction_id = transactionId
  row.date = new Date(row.date).toJSON()
  if (row.invoice_adjustment_date) {
    row.invoice_adjustment_date = new Date(row.invoice_adjustment_date).toJSON()
  } else {
    row.invoice_adjustment_date = null
  }
  row.plus_vat = 0
  row.location_id = get(location, 'additionalData.uuid')
  row.service_id = serviceId
  return row
}

module.exports = getRestPayload
