import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

import { Banner, DownloadButton } from '@fielded/shared-ui'

import {withApi} from '../../../common/ApiProvider'

class DownloadShipmentsButton extends Component {
  state = {
    loading: false,
    error: null
  }

  // We either need shipmentsList or period from which we should pull shipments
  static propTypes = {
    api: PropTypes.object.isRequired,
    period: PropTypes.object,
    shipmentsList: PropTypes.array,
    isReceived: PropTypes.bool,
    exportFunction: PropTypes.func.isRequired
  }

  handleClick = async () => {
    this.setState({loading: true})

    const {api, locationId, period, shipmentsList, isReceived, exportFunction} = this.props
    let start, end
    if (period) {
      start = period.effectiveStartDate.toJSON()
      end = period.effectiveEndDate.toJSON()
    }
    let blob
    try {
      blob = await exportFunction({
        locationId: locationId,
        startdate: start,
        enddate: end,
        shipmentsList: shipmentsList,
        isReceived: isReceived,
        api
      })
    } catch (error) {
      console.error(error)
      this.setState({loading: false, error})
    }

    this.downloadLinkEl.href = global.URL.createObjectURL(blob)
    this.downloadLinkEl.click()
    this.setState({loading: false})
  }

  render () {
    const content = (() => {
      const cta = (
        <DownloadButton
          loading={this.state.loading}
          loadingLabel='Generating report ...'
          onClick={this.handleClick}
        >
          Export Shipments
        </DownloadButton>
      )
      if (this.state.error) {
        return (
          <Banner
            inline
            type='warning'
            cta={cta}
          >
            {`Generating export failed: ${this.state.error.message}`}
          </Banner>
        )
      }
      return cta
    })()
    return (
      <Fragment>
        {/* eslint-disable */}
        <a
          download={`shipments-export-${new Date().toJSON()}.xlsx`}
          ref={(el) => { this.downloadLinkEl = el }}
          style={{display: 'none'}}
        />
        {/* eslint-enable */}
        {content}
      </Fragment>
    )
  }
}

export default withApi(DownloadShipmentsButton)
