const get = require('lodash/get')
const sortBy = require('lodash/sortBy')
const { dateToWeeklyReportingPeriod, endOfReportingPeriodToDate } = require('../../tools/date-utils')

const getPriceAt = (productDoc, reportingPeriod) => {
  const prices = get(productDoc, 'prices', [])
  if (!prices.length) {
    return 0
  }
  // check if there is an exact match for the given reportingPeriod first
  const [exactMatch = null] = prices.filter((price) => {
    return reportingPeriod === dateToWeeklyReportingPeriod('weekly', price.date)
  })
  if (exactMatch) {
    return exactMatch.price
  }

  //
  //
  let referenceDate = new Date()
  if (reportingPeriod) {
    referenceDate = new Date(endOfReportingPeriodToDate(reportingPeriod))
  }
  const [nearest] = prices.slice()
    .filter(price => {
      return new Date(price.date) <= referenceDate
    })
    .sort((a, b) => {
      const aDate = new Date(a.date)
      const bDate = new Date(b.date)
      return Math.abs(aDate - referenceDate) > Math.abs(bDate - referenceDate) ? 1 : -1
    })
  return nearest && nearest.price
}

const OPENING = 'field:standard-opening-balance'
const PARTNER_OPENING = 'field:opening-partner-balance'
const SL_OPENING = 'field:opening-shelflife-balance'
const CLOSING = 'field:standard-physical-count'
const PARTNER_CLOSING = 'field:partner-balance'
const SL_CLOSING = 'field:shelflife-balance'
const SOLD = 'field:standard-consumed'
const PARTNER_SOLD = 'field:partner-sold'
const SL_SOLD = 'field:shelflife-sold'
const BUYOUT = 'field:partner-buyout'
const SALES_ADJUSTMENTS = 'field:sales-adjustments'

const add = (reports, fieldName) => {
  return reports.reduce((sum, item) => {
    const value = get(item, fieldName, { amount: 0 })
    return sum + value.amount
  }, 0)
}

const mergeCounts = (reports) => {
  // Merges stock counts according to rules described here:
  // https://github.com/fielded/van-orga/issues/4493#issuecomment-640594806
  const sortedReports = sortBy(reports, ['submittedAt'])
  const first = sortedReports[0]
  const last = sortedReports[sortedReports.length - 1]
  if (first === last) {
    return first
  }

  let products = reports.reduce((all, report) => {
    return all.concat(Object.keys(report.stock))
  }, [])
  products = Array.from(new Set(products))

  const stock = {}
  // 1. All products from all reports are neeeded (even though i'd expect it do be the same in all)
  products.forEach((productId) => {
    const field = fieldName => `stock.${productId}.fields.${fieldName}`

    const buyOuts = add(reports, field(BUYOUT))
    const salesAdjustments = add(reports, field(SALES_ADJUSTMENTS))
    const slSales = add(reports, field(SL_SOLD))
    const partnerSales = add(reports, field(PARTNER_OPENING)) - add(reports, field(PARTNER_CLOSING)) - buyOuts

    stock[productId] = {
      fields: {
        [OPENING]: get(first, field(OPENING), { amount: 0 }),
        [PARTNER_OPENING]: get(first, field(PARTNER_OPENING), { amount: 0 }),
        [SL_OPENING]: get(first, field(SL_OPENING), { amount: 0 }),

        [CLOSING]: get(last, field(CLOSING)),
        [SL_CLOSING]: get(last, field(SL_CLOSING)),
        [PARTNER_CLOSING]: get(last, field(PARTNER_CLOSING)),

        // NOTE: This is using the partner/sl sold fields
        // because the normal 'standard-consumed' field is not allowed to be negative
        // therefore we'd get the wrong value when adjustments are made upwards
        [SOLD]: { amount: slSales + partnerSales },
        [PARTNER_SOLD]: { amount: partnerSales },
        [SL_SOLD]: { amount: slSales },
        [BUYOUT]: { amount: buyOuts },
        [SALES_ADJUSTMENTS]: { amount: salesAdjustments }
      }
    }
  })

  return Object.assign({}, first, { stock })
}

module.exports = {getPriceAt, mergeCounts}
