import React from 'react'
import { urlWithoutFilter } from './utils'

import { FilterButton, UppercaseLabel } from '@fielded/shared-ui'

const LedgerActiveFilter = ({ filters, history, baseLedgerUrl, locationsMap }) => {
  const hasBatchFilter = filters && filters.batchNo
  const hasLocationFilter = filters && filters.otherLocationId

  return hasBatchFilter || hasLocationFilter ? (
    <div className='ledger-active-filter'>
      <UppercaseLabel>
        Filtered on
      </UppercaseLabel>
      <div className='ledger-active-filter__filter-list'>
        {hasLocationFilter && (
          <div className='ledger-active-filter__filter'>
            <FilterButton
              onClick={() => history.replace(urlWithoutFilter(baseLedgerUrl, filters, 'otherLocationId'))}
            >
              Location: {locationsMap[filters.otherLocationId]
                ? locationsMap[filters.otherLocationId].name
                : filters.otherLocationId
              }
            </FilterButton>
          </div>
        )}
        {hasBatchFilter && (
          <div className='ledger-active-filter__filter'>
            <FilterButton
              onClick={() => history.replace(urlWithoutFilter(baseLedgerUrl, filters, 'batchNo'))}
            >
              Batch: {filters.batchNo}
            </FilterButton>
          </div>
        )}
      </div>
    </div>
  ) : null
}

export default LedgerActiveFilter
