class Payment {
  constructor (quickbooks) {
    this.quickbooks = quickbooks
  }

  async get (companyCode, id) {
    const { Payment: payment, Error: error } = await this.quickbooks.quickbooksInterface.get(
      companyCode,
      `payment/${id}?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`
    )
    if (payment) {
      return payment
    }
    if (error) {
      throw new Error(error.Message)
    }
  }

  async create ({
    payment: {
      totalAmount,
      companyCode,
      customerId
    },
    lines
  }) {
    const qboPayment = {
      TotalAmt: totalAmount,
      CustomerRef: {
        value: customerId
      },
      Line: lines.map(({ type, id, amount }) => ({
        Amount: amount,
        LinkedTxn: [
          {
            TxnType: type,
            TxnId: id
          }
        ]
      }))
    }

    const { Payment: createdPayment } = await this.quickbooks.quickbooksInterface.post(
      companyCode,
      `payment?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`,
      qboPayment
    )
    return createdPayment
  }
}

module.exports = {
  Payment
}
