/* global fetch */
import pollForAndDownload from './../../../common/poll-for-download'

const opts = {
  method: 'GET',
  mode: 'cors',
  credentials: 'include'
}

const toUrl = (obj) => Object.keys(obj).map(i => `${i}=${encodeURI(obj[i])}`).join('&')

const exportLmd = (user, program, period, config, locationId) => {
  if (!(user.programs).includes(program.id)) {
    throw new Error(`You don't have permission to access this program ${program.id}`)
  }

  const lmdExportUrl = `${config.indicatorsEndpoint}/lmd-export`

  const params = {
    program_id: program.id,
    user: user.name,
    reportingPeriod: period,
    location_id: locationId || user.location.id
  }

  return fetch(`${lmdExportUrl}?${toUrl(params)}`, {...opts})
    .then(response => {
      if (response.status >= 200 && response.status < 308) {
        return response.text()
      }
      throw new Error('Could not get export url')
    })
    .then(url => pollForAndDownload(url))
}

export default exportLmd
