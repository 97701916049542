import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { QuantityDisplay, Text, TotalAmount } from '@fielded/shared-ui'
import { ExclamationTriangle } from '@fielded/shared-ui/src/icons'

import { hasProductQuantityDiscrepancies } from '../../common/makeDeliveryItems'

const getCalculatedColCount = ({colCount, withCode, withBasicUnit, displayIcon}) => {
  if (!withCode) colCount -= 1
  if (!withBasicUnit) colCount -= 1
  if (displayIcon) colCount += 1
  return colCount
}

const ShipmentContentsTablePSM = ({
  items,
  type,
  withCode,
  withBasicUnit,
  withIcon
}) => {
  const hasQuantityDiscrepancies = hasProductQuantityDiscrepancies(null, items)
  const displayIcon = hasQuantityDiscrepancies && withIcon

  const isDelivery = type === 'delivery'
  const propToAdd = isDelivery ? 'delivered' : 'packed'

  const totalQty = items.reduce((acc, item) => {
    acc = acc + item[propToAdd]
    return acc
  }, 0)
  const isEmpty = !items || items.length === 0
  const colCount = isDelivery ? 5 : 4
  const colCountProcessed = getCalculatedColCount({ colCount, withCode, withBasicUnit, displayIcon })

  return (
    <table className='vs-u-table vs-u-table--deemphasized vs-u-table--rows-distinctive'>
      <thead>
        <tr className='vs-u-table__header'>
          {withCode && (
            <th className='vs-u-table__header-cell'>
              Code
            </th>
          )}
          <th className='vs-u-table__header-cell'>
            Product
          </th>
          {withBasicUnit && (
            <th className='vs-u-table__header-cell'>
              Basic unit
            </th>
          )}
          <th className='vs-u-table__header-cell vs-u-table__header-cell--right'>
            Packed
          </th>
          {isDelivery && (
            <th className='vs-u-table__header-cell vs-u-table__header-cell--right'>
              Delivered
            </th>
          )}
          {displayIcon && (
            <th className='vs-u-table__header-cell vs-u-table__header-cell--right vs-u-hide-visually'>
              Alert information
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {!isEmpty ? items.map((item) => {
          const hasAlert = item.delivered !== item.packed

          return (
            <tr className={classnames(
              'vs-u-table__row',
              {'shipment-contents-table__row': hasAlert}
            )} key={item.code}>
              {withCode && (
                <td className='vs-u-table__cell'>
                  <Text bold>{item.code}</Text>
                </td>
              )}
              <td className='vs-u-table__cell'>
                <Text bold>{item.name}</Text>
              </td>
              {withBasicUnit && (
                <td className='vs-u-table__cell'>
                  {item.basicUnit}
                </td>
              )}
              <td className='vs-u-table__cell vs-u-table__cell--right'>
                <QuantityDisplay value={item.packed} />
              </td>
              {isDelivery && (
                <td className='vs-u-table__cell vs-u-table__cell--right'>
                  <QuantityDisplay value={item.delivered} />
                </td>
              )}
              {displayIcon && (
                <td className='vs-u-table__cell'>
                  {hasAlert && (
                    <div className='shipment-contents-table__icon'>
                      <ExclamationTriangle />
                    </div>
                  )}
                </td>
              )}
            </tr>
          )
        }) : (
          <tr>
            <td colSpan={colCountProcessed}>
              <Text
                bold
                className='vs-u-margin-top vs-u-margin-bottom vs-u-row-centered'
              >
                There are no products in this delivery
              </Text>
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <TotalAmount
          value={totalQty}
          asTableRow
          totalColumns={displayIcon ? colCountProcessed - 1 : colCountProcessed}
          label='TOTAL QUANTITY'
          qtyOnly
          isSecondary
        />
      </tfoot>
    </table>
  )
}

ShipmentContentsTablePSM.propTypes = {
  /**
   * Array of delivery items (can be generated with makeDeliveryItems())
   */
  items: PropTypes.array,
  /**
   *  A type of displayed note
   */
  type: PropTypes.oneOf(['delivery', 'package']),
  /**
   * Display the product's code
   */
  withCode: PropTypes.bool,
  /**
   * Display the product's basic unit
   */
  withBasicUnit: PropTypes.bool,
  /**
   * Display the icon column
   */
  withIcon: PropTypes.bool
}

ShipmentContentsTablePSM.defaultProps = {
  items: [],
  type: 'delivery',
  withCode: true,
  withBasicUnit: true,
  withIcon: false
}

export default ShipmentContentsTablePSM
