const mergeStock = require('../../utils/merge-stock')
const { getVersion } = require('../../utils/versions')
const { createId, formatShortDate } = require('./ids')
const getIdVersion = require('./get-id-version')
const filterEmptyStock = require('../../utils/filter-empty-stock')

module.exports = ({
  locationId,
  service,
  period,
  username,
  version = null,
  submittedAt = null,
  createdAt = null,
  stock = {},
  expiry = null,
  isDraft = false,
  partialCount = false
}) => {
  if (createdAt) {
    createdAt = new Date(createdAt).toJSON()
  } else {
    createdAt = new Date().toJSON()
  }
  if (submittedAt) {
    submittedAt = new Date(submittedAt).toJSON()
  }
  version = version || getVersion(service.id)
  let withDate
  if (!isDraft) {
    withDate = createdAt
  }
  const _id = createId({locationId, service, period, withDate})
  const report = {
    _id,
    version,
    type: 'stockCount',
    serviceId: service.id,
    createdAt: createdAt,
    updatedAt: createdAt,
    createdBy: username,
    updatedBy: username,
    date: {
      reportingPeriod: period.id,
      period: {
        effectiveStart: formatShortDate(period.effectiveStartDate),
        effectiveEnd: formatShortDate(period.effectiveEndDate)
      }
    },
    stock: mergeStock({}, stock),
    partialCount
  }
  if (expiry) {
    report.expiry = filterEmptyStock(mergeStock({}, expiry))
  }

  report.idVersion = getIdVersion(service)

  if (submittedAt) {
    report.submittedAt = submittedAt
  }
  return report
}
