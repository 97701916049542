import React from 'react'

const PrintTemplateFooter = ({location}) => {
  if (location === 'nairobi') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>nairobi@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>+254 757 075760</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'nyanza') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>nyanza@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>+254 114 597274 </span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'coastal') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>coastal@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>+254 114 594637 </span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'lagos') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>lagos@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>0809 809 8188</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'rivers') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>portharcourt@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>0809 867 1746</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'oyo') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>oyo@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>0907 149 3502</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'edo') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>edo@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>0904 035 2036</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'kano') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>kano@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>0809 867 1746</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'kwara') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>kwara@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>0809 867 1746</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'enugu') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>enugu@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>0809 867 1746</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'delta') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>delta@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>0809 867 1746</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'south-rift') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>southrift@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>+254 757 075760</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'north-rift') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>northrift@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>+254 757 075760</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'antara') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>nairobi@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>+254 757 075760</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'penda') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>nairobi@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>+254 757 075760</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  if (location === 'medbury') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>pharma@medburymedicals.com.ng</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>+234 907 036 6243</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.medburypharma.co</span>
    </footer>)
  }

  if (location === 'central-province') {
    return (<footer className='print-template__footer'>
      <span className='print-template__footer-item'>nairobi@shelflife.co</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>+254 757 075760</span>
      <span className='print-template__footer-item'>/</span>
      <span className='print-template__footer-item'>www.shelflife.co</span>
    </footer>)
  }

  return (<footer className='print-template__footer'>
    <span className='print-template__footer-item'>abuja@shelflife.co</span>
    <span className='print-template__footer-item'>/</span>
    <span className='print-template__footer-item'>0809 867 1746</span>
    <span className='print-template__footer-item'>/</span>
    <span className='print-template__footer-item'>www.shelflife.co</span>
  </footer>)
}

export default PrintTemplateFooter
