const findForLocation = require('./find-for-location')

module.exports = async (state, {
  locationId,
  service,
  period
}) => {
  const reports = await findForLocation(state, {
    locationId,
    serviceId: service.id,
    period,
    entityOptions: {rawDocs: true}
  })
  if (reports.length === 0) {
    return
  }
  const report = reports[reports.length - 1]

  report.submittedAt = new Date().toISOString()
  return state.dal.report.write(state, report)
}
