module.exports = getLedgerBalancesBulk

const getLedgerBalanceAsReport = require('./stock-get-ledger-balance-as-report')
const getLedger = require('./ledger/get')

function getLedgerBalancesBulk (state, params) {
  // TODO: Validate params
  // Start date could be optional but we should
  // warn if you don't provide it
  const date = params.enddate || new Date().toJSON()

  let locationIds = params.locations
  const userLedgerId = params.locations.find(id => id === state.user.location.id)
  const promises = []
  const userBalance = userLedgerId && !params.useCache

  // if user ledger is included, filter it out
  // call the 'normal' getLedgerBalance on that if its included
  if (userBalance) {
    promises.push(
      getLedgerBalanceAsReport(state, {
        service: params.service,
        date: date,
        location: { id: userLedgerId }
      })
        .then(ledger => {
          /*
          * submittedAt is the last stock/shipment event that the ledger is based on
          * if it's not set on the response, it means we don't have any data
          * for the ledger balance
          *
          * this should be displayed as 'no data' in stock tables etc
          */
          if (!ledger.submittedAt) {
            return null
          }

          return ledger
        })
    )

    // Don't request this as bulk ledger
    locationIds = params.locations.filter(id => id !== userLedgerId)
  }

  if (locationIds.length) {
    promises.push(getLedger(state, locationIds, params.service.id, params.startdate, date))
  }

  return Promise.all(promises)
    .then(responses => {
      let ledgers = []

      let remoteResponse
      if (userBalance && responses[0]) {
        ledgers.push(responses[0])
        remoteResponse = responses[1]
      } else {
        remoteResponse = responses[0]
      }

      // local/remote bulk response
      if (remoteResponse) {
        ledgers = ledgers.concat(remoteResponse.ledgers)
        return Object.assign(
          remoteResponse, // carry over online offline status
          { ledgers: ledgers }
        )
      }

      return {
        status: 'success',
        ledgers: ledgers
      }
    })
}
