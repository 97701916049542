import React from 'react'
import { Page, TextInput } from '@fielded/shared-ui'
import ShipmentHeader from '../common/ShipmentHeader'
import CountProduct from '../count/CountProduct'
import ProgressButton from '../common/ProgressButton'

const ReturnShipment = ({
  title,
  shipment,
  fractionChecked,
  config,
  batches,
  handleCountUpdate,
  handleProceed,
  comment,
  onCommentChange
}) => {
  return (
    <Page>
      <ShipmentHeader
        title={title}
        shipment={shipment}
        config={config}
        hideMeta
        isReturn
      />
      <Page.Intro>
        Check off products when they are done.
      </Page.Intro>
      <Page.Panel withMargin spread>
        <Page.Panel.Section>
          { batches.map(product => {
            product.picked = product.quantity // CountProduct component expect picked as quantity in case of return shipment it's just quantity
            return (
              <div
                className='count__card-slot'
                key={product.productId}
              >
                <CountProduct
                  {...product}
                  isChecked={product.checked}
                  onToggleChecked={handleCountUpdate}
                />
              </div>
            )
          })}
        </Page.Panel.Section>
        <Page.Panel.Section>
          <span>If you have any comment, please note it here.</span>
          <TextInput
            className='confirmation-commment__input'
            name='comment'
            value={comment}
            onChange={onCommentChange}
            rows={5}
          />
        </Page.Panel.Section>
      </Page.Panel>
      <Page.Footer layout='centered'>
        <ProgressButton
          onClick={handleProceed}
          isComplete={fractionChecked.checked === fractionChecked.total}
          progressText={`${fractionChecked.checked} of ${fractionChecked.total}`}
          progressSupportingText='products delivered'
          submitLabelText='Proceed'
        />
      </Page.Footer>
    </Page>
  )
}

export default ReturnShipment
