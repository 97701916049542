import React, { Component } from 'react'
import PropTypes from 'prop-types'
import normaliseUser from '@fielded/fs-api/lib/utils/normalise-user'
import { getTotalPendingUploadCount, clearLocalDatabases } from '../sync'
import withConfig from '../van-shared/hoc/withConfig'

const defaultState = {
  user: { location: {} },
  error: '',
  loggedIn: false,
  loginInProgress: false
}

const AuthContext = React.createContext(defaultState)

export const withAuth = (...args) => (WrappedComponent) => (props) => {
  let mappingFn = (ctxValue) => ({ auth: ctxValue })
  // consumers that pass arguments can use two behaviors:
  if (args.length && args.every(a => typeof a === 'string')) {
    // 1. pass a list of strings that will be picked from the `auth`
    // context for selective usage as props, e.g. calling withAuth('user', 'loggedIn')
    // will pass `user` and `loggedIn` props to the wrapped component
    mappingFn = (ctxValue) => {
      return Object.keys(ctxValue)
        .filter((k) => args.includes(k))
        .reduce((acc, next) => {
          acc[next] = ctxValue[next]
          return acc
        }, {})
    }
  } else if (args.length && args.every(a => typeof a === 'function')) {
    // 2. pass a list of functions that will be called to transform the context
    // value into an object, e.g.
    // `withAuth((auth) => ({locationId: auth.user.location.id}))`
    mappingFn = (ctxValue) => {
      return args.reduce((acc, fn) => {
        return fn(acc)
      }, ctxValue)
    }
  }

  return (
    <AuthContext.Consumer>
      {ctxValue => <WrappedComponent {...props} {...mappingFn(ctxValue)} />}
    </AuthContext.Consumer>
  )
}

// this is just a convenience method exported for the majority of use cases
export const withUser = withAuth('user')

class AuthenticationProvider extends Component {
  static propTypes = {
    loginApi: PropTypes.object.isRequired,
    loginAsAdmin: PropTypes.object
  }

  state = {
    error: null,
    user: null,
    loggedIn: false,
    loginInProgess: false,
    initialized: false
  }

  async componentDidMount () {
    const { config } = this.props
    const session = await this.props.loginApi.getCurrentUser(config.remoteDbUrl)
    if (session) {
      this.setState({
        user: normaliseUser(session),
        loggedIn: true,
        initialized: true // <- this is expected to be here to save a re-render
      })
    }
    this.setState({ initialized: true })
  }

  // this method added for dynamic update user data after some changes
  handleUpdateUser = async () => {
    const { config } = this.props
    const session = await this.props.loginApi.getCurrentUser(config.remoteDbUrl)
    if (session) {
      this.setState({ user: normaliseUser(session) })
    }
  }

  handleLogin = async (username, password, { isAdminLogin, loggedInUserName, history, from, config }) => {
    this.setState({
      user: null,
      error: null,
      loggedIn: false,
      loginInProgress: true
    })

    const request = isAdminLogin ? this.props.loginApi.loginAsAdmin : this.props.loginApi.login

    const user = await request(username, password, { config, loggedInUserName })
    if (user.error) {
      this.setState({
        user: null,
        error: user,
        loggedIn: false,
        loginInProgress: false
      })
      return
    }

    this.setState({
      error: null,
      user: normaliseUser(user),
      loggedIn: true,
      loginInProgress: false
    })

    history.push(from, { from: '/login' })
  }

  canUserLogout = async () => {
    const pendingUploadCount = await getTotalPendingUploadCount()
    return (pendingUploadCount === 0)
  }

  // The user doesn't do this one -- it's triggered by any 401 from pouch replication,
  // probably meaning the auth cookie expired.
  handleSessionExpired = async (user, { history, config }) => {
    const res = await this.props.loginApi.logout(user, { config })
    if (!res.ok) {
      this.setState({ error: res })
      return
    }

    this.setState({ ...defaultState })
    history.push('/login?sessionExpired=true')
    window.location.reload()
  }

  // We delete all local data if clearLocalData feature is enabled,
  // TODO: this is on dev only until feature is done.
  handleLogout = async (user, { history, config, clearLocalData = false }) => {
    if (clearLocalData) {
      try {
        await clearLocalDatabases()
      } catch (error) {
        console.error(error)
        window.alert(`Error clearing local databases. Please contact support, or clear your local cache and refresh.`)
        return
      }
    }

    const res = await this.props.loginApi.logout(user, { config })
    if (!res.ok) {
      this.setState({ error: res })
      return
    }
    this.setState({ ...defaultState })
    history.push('/login')
    window.location.reload()
  }

  handleResetLoginStatus = () => {
    this.setState({ loginInProgess: false })
  }

  refetchUser = async (history) => {
    const { config } = this.props
    const { user } = this.state
    // If one of this things is not present, we most likely are just logging in. No need to refetch
    if (!user || !config) return

    // We are fetching user object from remote DB to compare with what we are currently using during this session
    const session = await this.props.loginApi.getCurrentUser(config.remoteDbUrl)

    if (!session) {
      // If there is user but no session, it means that user was trying to access remote DB when they should not be able to. We log them out, as usually this will happen if their password was changed.
      return this.handleLogout(user, { config, history })
    }

    // If current user revision and fetched user revision don't match, we set new state that is immediately populated to the user.
    const isSameRevision = user._rev === session._rev
    if (!isSameRevision) {
      this.setState({ user: normaliseUser(session) })
    }
  }

  render () {
    const { initialized, ...stateToPass } = this.state
    if (!initialized) {
      return null
    }

    const value = {
      ...stateToPass,
      login: (...args) => this.handleLogin(...args),
      handleSessionExpired: (...args) => this.handleSessionExpired(...args),
      canUserLogout: (...args) => this.canUserLogout(...args),
      logout: (...args) => this.handleLogout(...args),
      resetLoginStatus: (...args) => this.handleResetLoginStatus(...args),
      updateUser: (...args) => this.handleUpdateUser(...args),
      refetchUser: (...args) => this.refetchUser(...args)
    }

    return (
      <AuthContext.Provider
        value={value}
      >
        {React.Children.map(this.props.children, c => c)}
      </AuthContext.Provider>
    )
  }
}

export default withConfig(AuthenticationProvider)
