const { bulkImport } = require('../../report/api/bulk-import')
const tools = require('../tools')
const XLSX = require('@sheet/core')
const SHEET_NAME = 'Bulk Allocation'

exports.importReservedStockTemplate = async function (state,
  {bufferData, dryRun = false, noSubmittedAt}
) {
  const workbook = XLSX.read(bufferData, {type: 'array'})
  const allocationSheet = workbook.Sheets[SHEET_NAME]
  if (!allocationSheet) {
    throw new Error(`Worksheet '${SHEET_NAME}' not found`)
  }
  const rows = XLSX.utils.sheet_to_json(allocationSheet)
  const productRows = rows.map(transformRow)
    .filter(row => row.productId)
  return bulkImport(state, {productRows, dryRun, version: '2.0.0', noSubmittedAt})
}

function transformRow (row) {
  const {warehouseCode, funderId, programId, productId, newAllocatedQuantity} = row
  const locationId = tools.warehouseCodeToVirtualId({warehouseCode, funderId, programId})
  const quantity = Number(newAllocatedQuantity)
  const fieldName = `commits.allocated:${funderId}.amount`
  return {locationId, productId, quantity, fieldName, programId}
}
