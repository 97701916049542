import React from 'react'
import classnames from 'classnames'

import UppercaseLabel from '../UppercaseLabel'
import SortIndicator from '../SortIndicator'

const TableHeaderCell = ({
  cell,
  sortBy,
  sortDirection,
  onHandleSort,
  headerTextStyle
}) => {
  const {
    dataKey,
    align,
    header,
    disableSort,
    headerClassName,
    width,
    hideHeader
  } = cell

  return (
    <th
      onClick={() => !disableSort && onHandleSort(dataKey, sortDirection)}
      className={classnames(
        'vs-u-table__header-cell',
        {'vs-u-table__header-cell--clickable': !disableSort},
        {'vs-u-table__header-cell--right': align === 'right'},
        {'vs-u-table__header-cell--center': align === 'center'},
        headerClassName
      )}
      style={{width: width}}
    >
      {header && (
        <div className={classnames({'vs-u-visually-hidden': hideHeader})}>
          {headerTextStyle === 'uppercase' &&
            <UppercaseLabel>
              {header}
            </UppercaseLabel>
          }
          {headerTextStyle === 'lowercase' &&
            <span>
              {header}
            </span>
          }
          {!disableSort && (
            <SortIndicator sortDirection={sortBy === dataKey ? sortDirection : undefined} />
          )}
        </div>
      )}
    </th>
  )
}

export default TableHeaderCell
