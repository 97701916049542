const PGAdapter = require('./../common/pg-adapter')
const PAYG_CREDIT_PAYMENT_TABLE_NAME = 'avocado.data_paygcreditpayment'
const PAYMENT_DEBIT_COLUMNS = [
  'location_id',
  'payment_provider',
  'payment_method',
  'currency',
  'amount_in_cents',
  'created_by',
  'payment_data',
  'qbo_txn_id',
  'qbo_company_code',
  'created_at',
  'updated_at'
]

class PaygCreditPaymentAdapter extends PGAdapter {
  constructor (state, agaveAdapter, pgConnection, logger) {
    super(
      pgConnection,
      PAYG_CREDIT_PAYMENT_TABLE_NAME,
      state.user.username,
      PAYMENT_DEBIT_COLUMNS,
      'id',
      null,
      logger
    )
    this.pgConnection = pgConnection
    this.agaveAdapter = agaveAdapter
  }

  captureInvoice (data) {
    return this.agaveAdapter.create('finances/payg_credit_payment/invoice', data)
  }

  captureInstalment (data) {
    return this.agaveAdapter.create('finances/payg_credit_payment/instalment', data)
  }

  checkInvoiceIsPaid (data) {
    return this.agaveAdapter.get('finances/payg_credit_payment/check_invoice_is_paid', data)
  }

  savePayment (data) {
    if (!this.pgConnection) {
      throw new Error('Action only allows thru backend')
    }
    return this.create(data)
  }
}

module.exports = PaygCreditPaymentAdapter
