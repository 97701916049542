const get = require('lodash/get')
const cloneDeep = require('lodash/cloneDeep')
const { getLedgerForSupplier } = require('../api/read/get-group-details')
const shortid = require('shortid')

/*
This rations products to be delivered from each held according to priority
And build subOrders for each destination's order
i.e. We exhaust all products in prio 1 held before moving on to prio 2 ....
*/

exports.packPointRationing = packPointRationing
async function packPointRationing (state, mainApi, { allocations, locationsWithSubscriptions, deliveryDate, orderType, dryRun }) {
  // Get all active pack points in territory
  const orderedPackPointsIds = await mainApi.order.getSupplierIdsForLocation(locationsWithSubscriptions[0], {planType: orderType})
  const orderedPackPoints = await mainApi.location.getByIds(orderedPackPointsIds)

  if (orderedPackPoints.length !== orderedPackPointsIds.length) {
    let packPointIds = orderedPackPoints.map(pp => pp._id)
    const missingPackPointsIds = orderedPackPointsIds.filter(id => !packPointIds.includes(id))
    const missingPackPoints = await mainApi.location.getLocationsViaIds(missingPackPointsIds, dryRun)
    orderedPackPoints.push(...missingPackPoints)
  }

  const territory = state.user.location.id

  // Get supplier ledgers
  const supplierLedgers = await Promise.all(orderedPackPoints.map(async supplier => {
    return getLedgerForSupplier(state, mainApi, {supplier, locationId: territory})
  }))

  let ledgerBySupplier = supplierLedgers.reduce((acc, d) => {
    let supplierId = d.supplier._id
    acc[supplierId] = d.ledger
    return acc
  }, {})

  const cloneAllocation = cloneDeep(allocations)

  let allocationByLocation = cloneAllocation.reduce((acc, d) => {
    let locationId = d.location._id
    acc[locationId] = d.products
    return acc
  }, {})

  const defaultPackPointId = get(orderedPackPoints[0], '_id')

  // For each destination's allocation build supplier/packpoint subOrders
  return allocations.reduce((acc, allocation) => {
    const allocationLocation = allocation.location._id
    const allocationProducts = allocation.products
    const orderId = shortid.generate()

    // Create an allocation(order) doc for each supplier
    const newAllocations = orderedPackPoints.map(packPoint => {
      const packPointId = packPoint._id
      const suborderId = shortid.generate()

      const supplierLedger = get(ledgerBySupplier, `${packPointId}`, 0)
      const locationAllocation = get(allocationByLocation, allocationLocation)

      const { supplierProducts, remainingLedger, remainingAllocation } = createSupplierAllocation(supplierLedger, locationAllocation)

      const supplierAllocation = {}
      supplierAllocation.location = allocation.location
      supplierAllocation.products = supplierProducts
      supplierAllocation.supplier = packPointId
      supplierAllocation.suborderId = suborderId
      supplierAllocation.orderId = orderId

      // Assign remaining allocation and leddger for next allocation/supplier
      allocationByLocation[allocationLocation] = remainingAllocation
      ledgerBySupplier[packPointId] = remainingLedger

      return supplierAllocation
    })

    const allocationsBySupplier = newAllocations.reduce((acc, allocation) => {
      const supplierId = allocation.supplier
      acc[supplierId] = allocation
      return acc
    }, {})

    // Allocate remaining allocation values to territory's packpoint
    const completeAllocations = addRemainingAllocationsToPackPoint(allocationProducts, allocationsBySupplier, defaultPackPointId)
    acc.push(...completeAllocations)
    return acc
  }, [])
}

function createSupplierAllocation (ledger, locationAllocation) {
  const supplierProducts = Object.keys(locationAllocation).reduce((acc, product) => {
    const originalValue = get(locationAllocation, `${product}.original`, 0)
    const allocationType = get(locationAllocation, `${product}.allocationType`, '')
    const supplierLedgerValue = get(ledger, product)
    let amountToDeduct = 0

    const productObject = {}
    productObject.allocationType = allocationType

    // If no allocation don't allocate packpoint stock
    if (originalValue > 0) {
      if (supplierLedgerValue >= originalValue) {
        productObject.original = originalValue
        amountToDeduct = originalValue
      } else if (supplierLedgerValue > 0) {
        productObject.original = supplierLedgerValue
        amountToDeduct = supplierLedgerValue
      } else {
        // Still return zero to give planners the option to edit on Plan
        productObject.original = 0
      }
    } else {
      productObject.original = 0
    }
    // Deduct amount from ledger and allocation clones
    ledger[product] -= amountToDeduct
    locationAllocation[product]['original'] -= amountToDeduct

    acc[product] = productObject
    return acc
  }, {})

  return {
    supplierProducts,
    remainingLedger: ledger,
    remainingAllocation: locationAllocation
  }
}

// We assign the unallocated values to territory's default pack point
function addRemainingAllocationsToPackPoint (allocationProducts, allocationsBySupplier, defaultPackPointId) {
  const territorySupplierAllocation = Object.values(allocationsBySupplier).find((d) => defaultPackPointId === d.supplier)

  if (!territorySupplierAllocation) {
    throw new Error('Cant find territory Pack point on Allocation')
  }

  const supplierAllocationProducts = territorySupplierAllocation.products

  const newSupplierProducts = Object.keys(allocationProducts).reduce((acc, product) => {
    const allocationOriginalValue = get(allocationProducts, `${product}.original`, 0)
    const allocationAllocationType = get(allocationProducts, `${product}.allocationType`, 0)
    const supplierAllocationProduct = get(supplierAllocationProducts, product, {})

    let totalPackPoint = 0

    Object.values(allocationsBySupplier).forEach(supplierAllocation => {
      const packPointValue = get(supplierAllocation, `products.${product}.original`, 0)
      totalPackPoint += packPointValue
    })
    const remaining = allocationOriginalValue - totalPackPoint

    if (remaining > 0) {
      // get total already in packpoint
      const quantity = get(supplierAllocationProducts, `${product}.original`, 0)
      // Add remaining
      acc[product] = Object.assign(supplierAllocationProduct, { original: quantity + remaining, allocationType: allocationAllocationType })
    }
    acc[product] = supplierAllocationProduct
    return acc
  }, {})

  const newSupplierAllocations = Object.assign(territorySupplierAllocation, { products: newSupplierProducts })

  const allocationsByPackPoint = Object.assign(allocationsBySupplier, { [defaultPackPointId]: newSupplierAllocations })

  // return just allocation objects
  const finalAllocations = Object.values(allocationsByPackPoint).map(value => value)

  return finalAllocations
}
