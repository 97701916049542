const { VAN_SERVICE_ID } = require('../../tools/utils/constants')

module.exports = listManufacturers

const immunizationManufacturers = [
  {
    id: 'amgen',
    name: 'Amgen'
  },
  {
    id: 'aj-vaccines',
    name: 'AJ Vaccines from Copenhagen, Denmark'
  },
  {
    id: 'barr',
    name: 'Barr'
  },
  {
    id: 'berna-biotech',
    name: 'Berna Biotech'
  },
  {
    id: 'bilthoven',
    name: 'Bilthoven Biologicals'
  },
  {
    id: 'biofarma',
    name: 'Bio Farma'
  },
  {
    id: 'biological-e',
    name: 'Biological E'
  },
  {
    id: 'chumakov',
    name: 'Chumakov'
  },
  {
    id: 'crucell',
    name: 'Crucell'
  },
  {
    id: 'dabur',
    name: 'Dabur'
  },
  {
    id: 'gsk',
    name: 'GSK'
  },
  {
    id: 'haffkine',
    name: 'Haffkine'
  },
  {
    id: 'helm',
    name: 'Helm Medical'
  },
  {
    id: 'intervax',
    name: 'InterVax'
  },
  {
    id: 'japan-bcg-lab',
    name: 'Japan BCG Laboratory'
  },
  {
    id: 'lg-life-science',
    name: 'LG Life Sciences'
  },
  {
    id: 'novartis',
    name: 'Novartis'
  },
  {
    id: 'pfizer',
    name: 'Pfizer'
  },
  {
    id: 'sanofi',
    name: 'Sanofi'
  },
  {
    id: 'roche',
    name: 'Roche'
  },
  {
    id: 'serum',
    name: 'Serum Institute'
  },
  {
    id: 'shantha',
    name: 'Shantha Biotechnics'
  },
  {
    id: 'ucb',
    name: 'UCB'
  }
]

const psmManufacturers = [
  {
    id: 'chemonics',
    name: 'Chemonics'
  }
]

// NOTE: this function is marked as 'async'
// because this will need to be read from the db one day
// the async keyword makes sure we always return a promise
async function listManufacturers (state, serviceId) {
  if (!serviceId || serviceId === VAN_SERVICE_ID) {
    return immunizationManufacturers
  }

  if (serviceId === 'all') {
    return immunizationManufacturers.concat(psmManufacturers)
  }

  return psmManufacturers
}
