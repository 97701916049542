import React from 'react'
import PropTypes from 'prop-types'

import { QuantityTotal } from '@fielded/shared-ui'

const ReportEntryTotal = (props) => {
  const { total } = props
  return (
    <div className='report-entry-total'>
      <div>
        Total counted:
      </div>
      <QuantityTotal value={total} size='large' />
    </div>
  )
}

ReportEntryTotal.propTypes = {
  /**
   * What's the total of the counted products/batches
   */
  total: PropTypes.number
}

ReportEntryTotal.defaultProps = {
  total: 0
}

export default ReportEntryTotal
