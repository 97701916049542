import React from 'react'

const reloadWindow = () => {
  window.location.reload()
}

const OfflineLoadingError = error =>
  <div>
    <div>
      Error with initial download. Please <a href='/' onClick={() => { reloadWindow() }}> refresh your browser to try again.</a>
    </div>
    <div>
      Technical Details to pass onto support:
      <pre>
        {JSON.stringify(error, null, 2)}
      </pre>
    </div>
  </div>

export default OfflineLoadingError
