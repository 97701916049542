import React, { Component } from 'react'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import { PriceDisplay, PrintTemplate, SignatureDisplay } from '@fielded/shared-ui'
import { getCurrencySymbol } from '@fielded/shared-ui/src/components/PriceDisplay'

import { removeTime } from '../common/periods'
import { userIsAuthorised } from '../van-shared/utils/auth'

import { getInvoiceConfirmationRows } from '../subapps/reports/confirmation/confirmationUtils'
import { pluralize, formatDate } from '../van-shared/utils/utils'

import InventoryTable from './InventoryTable'
import SubscriptionsTable from './SubscriptionsTable'

const getTableData = (data, country, baseLedgerUrl, appName) => {
  let tableTotal = 0
  const rows = data.filter(item => {
    const { isProductLine, isTotal } = item

    if (isProductLine || isTotal) {
      return false
    }
    const opb = get(item, 'fields.field:opening-partner-balance.amount', 0)
    const pb = get(item, 'fields.field:partner-balance.amount', 0)
    if (opb === 0 && pb === 0) {
      return false
    }

    return true
  }).map((item, idx) => {
    const name = get(item, 'name', '-')

    // fields
    const opb = get(item, 'fields.field:opening-partner-balance.amount', 0)
    const osl = get(item, 'fields.field:opening-shelflife-balance.amount', 0)

    const pb = get(item, 'fields.field:partner-balance.amount', 0)
    const sl = get(item, 'fields.field:shelflife-balance.amount', 0)

    const price = get(item, 'fields.field:price.price', 0)
    const total = sl * price

    tableTotal += total

    return {
      key: `row.${idx}`,
      cols: [{
        name: 'products',
        url: `${baseLedgerUrl}/${item.id}`,
        value: name,
        props: {
          rowSpan: 2
        }
      }, {
        name: 'Opening - Yours',
        value: opb
      }, {
        name: `Opening - ${appName}`,
        value: osl
      }, {
        name: 'On Shelf - Yours',
        value: pb
      }, {
        name: `On Shelf - ${appName}`,
        value: sl
      }, {
        name: 'Unit Price',
        value: price,
        props: {
          rowSpan: 2
        }
      }, {
        name: 'Total',
        value: <PriceDisplay value={total} country={country} />,
        props: {
          rowSpan: 2
        }
      }],
      colsTotal: [{
        name: 'Total Opening',
        value: opb + osl
      }, {
        name: 'Total On Shelf',
        value: pb + sl
      }],
      name
    }
  })
  return {
    total: tableTotal,
    rows
  }
}

const getTableDataForSubscription = (data, country, baseLedgerUrl) => {
  let tableTotal = 0
  const rows = data.filter(item => {
    const {isProductLine, isTotal} = item

    if (isProductLine || isTotal) {
      return false
    }
    const opb = get(item, 'fields.field:opening-partner-balance.amount', 0)
    const pb = get(item, 'fields.field:partner-balance.amount', 0)
    if (opb > 0 || pb > 0) {
      return false
    }

    return true
  }).map((item, idx) => {
    const name = get(item, 'name', '-')

    // fields
    const sob = get(item, 'fields.field:standard-opening-balance.amount', 0)
    const spc = get(item, 'fields.field:standard-physical-count.amount', 0)
    const price = get(item, 'fields.field:price.price', 0)
    const total = spc * price

    const hasIssue = spc > sob
    if (!hasIssue) {
      tableTotal += total
    }

    return {
      key: `row.${idx}`,
      cols: [{
        name: 'products',
        url: `${baseLedgerUrl}/${item.id}`,
        value: name
      }, {
        name: 'opening',
        value: sob
      }, {
        name: 'On Shelf',
        value: spc
      }, {
        name: 'Unit Price',
        value: hasIssue ? '?' : <PriceDisplay value={price} country={country} />
      }, {
        name: 'Total',
        value: hasIssue ? '?' : <PriceDisplay value={total} country={country} />,
        rawValue: total
      }],
      name,
      hasIssue
    }
  })
  return {
    rows,
    total: tableTotal
  }
}

class InventoryStatement extends Component {
  render () {
    const {
      location,
      serviceId,
      report,
      productsById,
      user,
      config
    } = this.props

    const country = get(location, 'location.country')
    const currency = getCurrencySymbol({country})

    const locationDetails = get(report, 'location.state')

    const isRetailer = userIsAuthorised(user, 'feature:userRole:pharmacyUser')
    let baseLedgerUrl = `/ledger/${serviceId}/${location._id}`
    if (isRetailer) {
      baseLedgerUrl = `/reporting${baseLedgerUrl}`
    }

    const appName = config.name
    const rows = getInvoiceConfirmationRows({report, productsById, serviceId})

    // Filter adjusted rows + put latest changes on top
    const adjustedRows = sortBy(rows.filter(row => row.adjusted), ['updatedAt', 'name']).reverse()
    const nonAdjustedRows = rows.filter(row => (!row.adjusted && !row.isTotal))

    const sharedTableAdjusted = getTableData(adjustedRows, country, baseLedgerUrl, appName)
    const subscriptionTableAdjusted = getTableDataForSubscription(adjustedRows, country, baseLedgerUrl)

    const sharedTableData = getTableData(nonAdjustedRows, country, baseLedgerUrl, appName)
    const subscriptionTableData = getTableDataForSubscription(nonAdjustedRows, country, baseLedgerUrl)

    const total = sharedTableAdjusted.total + subscriptionTableAdjusted.total + sharedTableData.total + subscriptionTableData.total

    const numProducts = rows.filter(({isProductLine, isTotal}) => !isProductLine && !isTotal).length

    // checks if there are out of contract buyings
    const rowsWithIssues = subscriptionTableData.rows.filter(row => row.hasIssue).length
    const hasIssuesWarning = rowsWithIssues > 0
    const productsString = pluralize('product', rowsWithIssues)

    const autoFilled = report.autoFilled
    let date = report.submittedAt
    let summary = (
      <div>A stock count of <strong>{numProducts} {appName} product subscriptions</strong> was conducted on <strong>{removeTime(date)}</strong>. The following quantities were recorded and confirmed.</div>
    )

    if (adjustedRows.length > 0) {
      summary = (
        <div>
          <div className='print-template__label'>Adjusted</div><br />
          The quantity of <strong>{adjustedRows.length} {pluralize('product', adjustedRows.length)} </strong> was last adjusted on <strong>{formatDate(report.updatedAt, 'long')}.</strong> <br />
          A complete stock count of <strong>{report.originalCount} {pluralize('product', report.originalCount)}</strong> was confirmed on <strong>{formatDate(report.submittedAt, 'long')}.</strong>
        </div>
      )
      // When merging reports, this becomes submittedAt from the last adjustment
      date = report.updatedAt
    }

    // This happens when a new location receives a POD delivery before the first full count is conducted
    if (autoFilled) {
      summary = (
        <div>
          <div className='print-template__label'>Auto-Filled</div><br />
          The items in this report has not been counted manually during this cycle. Any sales and transfers have been automatically marked.
        </div>
      )
      // When merging reports, this becomes submittedAt from the last adjustment
      date = report.updatedAt
    }

    return (
      <PrintTemplate>
        <PrintTemplate.Header
          title='Inventory statement'
          config={config}
        />
        <PrintTemplate.Box
          left={summary}
          right={<div><div><strong>{location.fullName}</strong></div>{location.additionalData.physicalAddress}</div>}
        />
        {sharedTableAdjusted.rows.length > 0 && <PrintTemplate.Section withOverflow titlePB={`Adjusted ${appName} subscriptions with pharmacy stock remaining`}>
          <InventoryTable
            rows={sharedTableAdjusted.rows}
            currency={currency}
            theme='summary'
            appName={appName}
          />
        </PrintTemplate.Section>}
        {subscriptionTableAdjusted.rows.length > 0 && <PrintTemplate.Section withOverflow title={`Adjusted ${appName} subscriptions`}>
          <SubscriptionsTable
            rows={subscriptionTableAdjusted.rows}
            total={nonAdjustedRows.length ? null : total}
            country={country}
            currency={currency}
            appName={appName}
          />
        </PrintTemplate.Section>}
        {(adjustedRows.length > 0 && nonAdjustedRows.length > 0) && (
          <PrintTemplate.Section>
            <div className='print-template__section--extra'>
              The following <strong>{nonAdjustedRows.length} {pluralize('product', nonAdjustedRows)}</strong> haven't been adjusted but are included for your reference
            </div>
          </PrintTemplate.Section>)}
        {sharedTableData.rows.length > 0 && <PrintTemplate.Section withOverflow titlePB={`${appName} subscriptions with pharmacy stock remaining`}>
          <InventoryTable
            appName={appName}
            rows={sharedTableData.rows}
            currency={currency}
            theme='summary'
          />
        </PrintTemplate.Section>}
        {subscriptionTableData.rows.length > 0 && <PrintTemplate.Section withOverflow title={`${appName} subscriptions`}>
          <SubscriptionsTable
            rows={subscriptionTableData.rows}
            total={total}
            country={country}
            currency={currency}
            appName={appName}
          />
        </PrintTemplate.Section>}
        {hasIssuesWarning && <PrintTemplate.Section>
          <div className='sales-statement__warning'>
            <strong>{rowsWithIssues} {productsString} will be reviewed</strong> before your final sale statement and invoice is issued.
            A {appName} team member will be in touch shortly.
          </div>
        </PrintTemplate.Section>}
        <PrintTemplate.Section>
          Count confirmed on <strong>{removeTime(date)}</strong>
        </PrintTemplate.Section>
        {report.signed && (
          <PrintTemplate.Section>
            <SignatureDisplay
              signature={report.signed.signature}
              name={report.signed.name}
            />
          </PrintTemplate.Section>
        )}
        <PrintTemplate.Footer location={locationDetails} />
      </PrintTemplate>
    )
  }
}

export default InventoryStatement
