const get = require('lodash/get')
const { getSupplyPlan } = require('./psm-thresholds')
const { locationIdToProperties, serviceIdToProgramId } = require('../../../tools')

const psmReportToForecast = (locationId, report, periodDefinition) => {
  if (!(report && periodDefinition)) {
    return
  }

  const { level } = locationIdToProperties(locationId)
  const supplyPlans = getSupplyPlan(serviceIdToProgramId(report.serviceId))
  // Some locations should not have supply plans and if we try to access this data by mistake, we should just return
  if (!supplyPlans) return

  const dailyDemand = {}
  const safetyStock = {}

  Object.keys(report.stock).forEach(productId => {
    let monthlyConsumption
    if (level === 'state') {
      monthlyConsumption = get(
        report.stock[productId], `fields.field:custom-average-monthly-consumption.amount`, 0)
    } else {
      const periodConsumption = get(report.stock[productId], `fields.field:standard-consumed.amount`, 0)
      const monthsInPeriod = periodDefinition.periodType.quantity
      monthlyConsumption = periodConsumption > 0 ? periodConsumption / monthsInPeriod : 0
    }

    dailyDemand[productId] = monthlyConsumption * 12 / 365
    safetyStock[productId] = supplyPlans.bufferDays * dailyDemand[productId]
  })

  return {
    locationId,
    effectiveDate: report.submittedAt,
    createdAt: report.createdAt,
    dailyDemand,
    safetyStock,
    supplyPlans: { default: supplyPlans }
  }
}

module.exports = psmReportToForecast
