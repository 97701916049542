import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import keyBy from 'lodash/keyBy'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'

import { Modal, DataTable, Button, ActionsBar, Loading } from '@fielded/shared-ui'
import {getShipmentBatches} from '@fielded/fs-api/lib/shipment/utils/utils'
import withOffline from '@fielded/shared-ui/src/common/offline'
import { getServiceForLocationId } from '@fielded/fs-api/lib/service/tools/territory-lookup'
import { parse } from '@fielded/fs-api/lib/tools/smart-id'
import { DIRECT_ORDER_TYPES } from '@fielded/shared-ui/src/utils/subscription-type-constants'
import { SHELFLIFE_PROGRAM_ID } from '@fielded/fs-api/lib/tools/utils/constants'
import { bulkTranslateShipmentProducts } from '@fielded/fs-api/lib/shipment/tools/bulk-translate-shipment-products'

import {updateShipment} from '../shipments/shipments-reducer'
import { createSnapshot } from '../confirmation/confirmation-reducer'
import { loadPickList } from '../pick-list/pick-list-reducer'

import { userIsAuthorised } from '../../../van-shared/utils/auth'
import withConfig from '../../../van-shared/hoc/withConfig'
import { withUser } from '../../../common/AuthenticationProvider'
import withShipment from './../common/WithShipmentWrapper'
import withMasterData from './../common/withMasterData'
import { withApi } from './../../../common/ApiProvider'
import {
  isPSMDriver,
  isShipmentComplete,
  isShelflifePowered
} from '../common/utils'
import { hasFeature } from '../../../van-shared/utils/features'
import { isReturnShipment } from '../../../common/utils/shipment'
import { getSupplierLedger } from '../common/add-products-held-quantity'
import { getPlannedQuantities, makeDeliveryItems } from '../common/makeDeliveryItems'
import { isShipmentInUserMarket } from '../shipments/shipment-utils'

import Count from '../count/Count'
import CompletedShipmentSummary from '../common/CompletedShipmentSummary'
import ShipmentSummaryContainer from '../../retailer/shipments/ShipmentSummaryContainer'
import { updateCurrentMasterData } from '../../../van-shared/utils/utils'

/*
* Count is for when a user (built for SL) needs to
* check off products in a one-page, "count" view.
* Intentionally built without picklist or batch reducer so we can
* move away from maintaining several batch lists in the redux store.
* see https://github.com/fielded/van-orga/issues/1099
* Supports unbatched products to start.
*/
class CountContainer extends Component {
  state = {
    quantityConflictProducts: [],
    supplierLedger: null,
    loaded: false,
    batches: [],
    nonTranslatedBatches: [],
    program: undefined
  }

  getBatches = async ({productsById, user, shipment, api, nonTranslatedBatches, config}) => {
    // If the user is a shelflife user, we need to translate the products
    if (isShelflifePowered(config)) {
      const program = await api.program.get(SHELFLIFE_PROGRAM_ID)
      return translateBatches(program, productsById, user, shipment, api)
    }
    return nonTranslatedBatches
  }

  async componentDidMount () {
    const {
      nonTranslatedBatches,
      loadPickList,
      shipment,
      products,
      history,
      api,
      user,
      config,
      isOnline,
      productsById
    } = this.props

    const batches = await this.getBatches({user, shipment, api, productsById, nonTranslatedBatches, config})
    const useProductTranslation = hasFeature(config, 'features.shipments.useProductTranslation')

    const shipmentIds = Object.keys(shipment.counts)
      .map(p => p.split(':manufacturer')[0])
      .filter(id => !products.allIds.includes(id))

    let processedProducts = { ...products }
    let missingProducts = []
    if (shipmentIds.length) {
      missingProducts = await api.product.getProductsViaIds(shipmentIds)
      const productsByIds = keyBy(missingProducts, '_id')

      processedProducts = {
        ...processedProducts,
        allIds: [...processedProducts.allIds, ...shipmentIds],
        byId: {...processedProducts.byId, ...productsByIds}
      }
    }
    loadPickList(shipment, processedProducts, user)

    const showPackPointLedger = hasFeature(config.features, 'shipments.showPackPointLedger')
    const isOperator = userIsAuthorised(user, 'feature:userRole:operator')
    const isGlobalPlanner = userIsAuthorised(user, 'feature:userRole:planner')

    let supplierLedger
    // lets also check if it's online then hit fetch ledger data.
    if (showPackPointLedger && (isOperator || isGlobalPlanner) && isOnline) {
      const location = await api.location.get(shipment.origin.id)
      // supplier ledger should load only once per shipment
      if (location && location.level === 'pack-point' && shipment.status === 'new') {
        supplierLedger = await getSupplierLedger({
          location,
          api
        })
        if (supplierLedger) {
          batches.forEach(batch => {
            batch.ledgerQuantity = supplierLedger.ledger[batch._id]
          })
        }
      }
    }
    this.setState({supplierLedger, batches, nonTranslatedBatches, useProductTranslation, loaded: true})

    if (isPSMDriver(user)) {
      const { orderId } = !shipment.orderId ? {orderId: null} : parse(shipment.orderId)
      const order = !orderId ? {} : await api.order.getOrder({ orderId })
      const plannedQuantities = getPlannedQuantities(shipment)
      const items = makeDeliveryItems({ order, shipment, productsById, plannedQuantities }) || []
      this.setState({ deliveryItems: items, order })
    }

    /*
     * Creating the "Arrived" Snapshot + translating products from one market to another if necessary
     *
     * This should happen when operators open a return marked as "sent"
     * and when drivers (FPs and PSM drivers) open a delivery marked as "sent"
     * For global planners it will happen to any shipment marked as "sent"
     * but creating the snapshot does not seem to cause any harm
     */
    if (shipment.status === 'sent' && !isShipmentComplete(shipment, user)) {
      const destinationId = shipment.destination.id
      const products = await api.product.listAll()
      const allProducts = [...missingProducts, ...products]
      const date = new Date().toJSON()
      const location = await api.location.get(destinationId, date)
      const counts = (
        useProductTranslation
          ? await api.shipment.bulkTranslateShipmentProducts(shipment, location, allProducts)
          : shipment.counts
      )

      const opts = {
        status: 'arrived',
        counts
      }
      try {
        const snapshot = await this.props.createSnapshot(opts, shipment)
        this.props.reloadShipment(shipment.id)
        return history.replace(`/shipments/pick-list/${snapshot.snapshotId}`)
      } catch (error) {
        // For online/offline users editing a shipment outside the sync range
        this.setState({ error: error })
      }
    }
  }

  async componentDidUpdate (prevProps, prevState, snapshot) {
    const { supplierLedger, order } = this.state
    const { nonTranslatedBatches, user, shipment, productsById, api, config } = this.props
    const { nonTranslatedBatches: previousNonTranslatedBatches } = prevProps

    // Every time the nonTranslatedBatches change, we want to update the decorated/translated batches in the state
    // but we only have to do something if the nonTranslatedBatches have changed and they're diffrent from the one
    // in the state.
    if (
      isEqual(previousNonTranslatedBatches, nonTranslatedBatches) ||
      this.state.nonTranslatedBatches.length === 0 ||
        isEqual(nonTranslatedBatches, this.state.nonTranslatedBatches)
    ) {
      return
    }

    if (supplierLedger) {
      nonTranslatedBatches.forEach((batch) => {
        batch.ledgerQuantity = supplierLedger.ledger[batch._id]
      })
    }

    if (isPSMDriver(user)) {
      const plannedQuantities = getPlannedQuantities(shipment)
      const items =
        makeDeliveryItems({
          order,
          shipment,
          productsById,
          plannedQuantities
        }) || []
      this.setState({ deliveryItems: items })
    }

    const batches = await this.getBatches({
      user,
      shipment,
      api,
      productsById,
      nonTranslatedBatches,
      config
    })
    this.setState({ nonTranslatedBatches, batches })
  }

  handleCountUpdate = async (batchId, inputQuantity, checked, paymentType = DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL) => {
    const {api, match: {params: {snapshotId}}} = this.props
    const counts = {
      [batchId]: { checked, quantity: inputQuantity, paymentType }
    }
    const updatedShipment = await api.shipment.saveChanges(snapshotId, counts)

    // this is a prop from withShipment wrapper
    await this.props.reloadShipment(snapshotId, updatedShipment)

    // the confirmation container still looks at `store.shipments`,
    // so this is to keep the shipment up to date
    // there as well for whichever point the user advances
    this.props.updateShipment(updatedShipment)
  }

  handleProceed = () => {
    const { user, shipment } = this.props
    const { batches, supplierLedger } = this.state
    const unMappedSkus = []
    const isUserGP = userIsAuthorised(user, 'feature:userRole:planner')
    const originMarket = shipment.origin.state
    const destinationMarket = shipment.destination.state

    // cross territory shipment alias check
    if (shipment.status === 'new' && (originMarket !== destinationMarket)) {
      const { service: destinationService } = getServiceForLocationId(shipment.destination.id)
      batches.forEach(product => {
        if (!product.alias || (!Object.keys(product.alias).includes(destinationService) && product.quantity > 0)) {
          unMappedSkus.push(product._id.split(':')[1])
        }
      })
    }

    if (unMappedSkus.length > 0) {
      window.alert(`${unMappedSkus.length} ${originMarket.toUpperCase()} products with ids ${unMappedSkus} don't have product mappings in ${destinationMarket.toUpperCase()} market. Remove or 0 these products to proceed.`)
      return
    }

    if (isUserGP) {
      const completeDelivery = window.confirm(`You're about to confirm this delivery as a GLOBAL PLANNER, Are you sure you want to continue?`)
      if (!completeDelivery) return
    }

    if (supplierLedger) {
      const quantityConflictProducts = batches.filter(product => product.ledgerQuantity < product.quantity)
      if (quantityConflictProducts.length) {
        return this.setState({quantityConflictProducts})
      }
    }

    return this.navigateToConfirmation()
  }

   navigateToConfirmation = async () => {
     const { loadPickList, shipment, history, products } = this.props
     // load picklist for confirmation screen from saved batches
     await loadPickList(shipment, products)
     return history.push(`/shipments/confirmation/${shipment.snapshotId}`)
   }

   resetQuantityConflictProducts = () => {
     this.setState({quantityConflictProducts: []})
   }

   render () {
     const {
       shipment,
       config,
       shipmentIsComplete,
       pickList,
       user,
       api
     } = this.props

     const {
       quantityConflictProducts,
       loaded,
       batches,
       useProductTranslation,
       deliveryItems
     } = this.state

     if (!loaded) {
       return <Loading />
     }

     const isReturn = isReturnShipment(user, shipment)

     if (shipmentIsComplete) {
       const isOperator = userIsAuthorised(user, 'feature:userRole:operator')
       const isSameMarketShipment = isShipmentInUserMarket(shipment, user)
       const userCanAdjust = userIsAuthorised(user, 'feature:userRole:planner') || (isOperator && isSameMarketShipment)
       const adjustmentFeature = hasFeature(config.features, 'shipments.adjustments')

       return (
         <CompletedShipmentSummary
           pickList={pickList}
           shipment={shipment}
           config={config}
           isReturn={isReturn}
           showAdjustment={userCanAdjust && adjustmentFeature}
           isOperator={isOperator}
         />
       )
     }

     // We always show external suppliers the shipment summary
     const isExternalPlanner = userIsAuthorised(user, 'feature:userRole:external-planner')

     if (isExternalPlanner) {
       return (
         <ShipmentSummaryContainer
           api={api}
           config={config}
           snapshotId={shipment.snapshotId}
           isExternalPlanner={isExternalPlanner}
         />
       )
     }

     return (
       <Fragment>
         <Count
           batches={batches}
           shipment={shipment}
           config={config}
           api={api}
           user={user}
           useProductTranslation={useProductTranslation}
           deliveryItems={deliveryItems}
           isReturnShipment={isReturn}
           onProductUpdate={this.handleCountUpdate}
           onClickProceed={this.handleProceed}
         />
         <Modal
           onClose={this.resetQuantityConflictProducts}
           isOpen={quantityConflictProducts.length}
           title={'The confirmed quantities are more than the available quantities in the origin pack point'}>
           <DataTable entries={quantityConflictProducts}>
             <DataTable.Column
               label='Product Name'
               dataKey='name'
             />
             <DataTable.Column
               label='PP Ledger quantity'
               dataKey='ledgerQuantity'
             />
             <DataTable.Column
               label='Confirmed Quantity'
               dataKey='quantity'
             />
           </DataTable>
           <ActionsBar>
             <Button onClick={this.navigateToConfirmation} colorVariant='neutral' fill='full'>
               Continue
             </Button>
             <Button onClick={this.resetQuantityConflictProducts} fill='full' colorVariant='brand'>
               Cancel
             </Button>
           </ActionsBar>
         </Modal>
       </Fragment>
     )
   }
}

const translateBatches = (program, productsById, user, shipment, api) => {
  const locationState = user.location.id.split(':sdp:')[0]
  const userMarketService = program.services.find(service => service.locations.includes(locationState))
  const userLocationDTO = {services: [userMarketService.id]}
  const translations = bulkTranslateShipmentProducts(api.state, shipment, userLocationDTO, productsById)
  const result = getShipmentBatches({...shipment, counts: translations}, productsById)
  return result
}

const mapStateToProps = (state, props) => {
  const newState = updateCurrentMasterData(state, 'masterDataShipments')

  const {products} = newState.masterData
  const productsById = products.byId
  const shipmentIsComplete = isShipmentComplete(props.shipment, props.user)
  const nonTranslatedBatches = getShipmentBatches(props.shipment, productsById)
  return {
    ...props,
    nonTranslatedBatches,
    shipmentIsComplete,
    pickList: newState.pickList,
    user: props.user,
    productsById,
    products
  }
}

const mapDispatchToProps = {
  updateShipment,
  loadPickList,
  createSnapshot
}

const withHOCs = flowRight(
  withUser,
  withApi,
  withConfig,
  withMasterData,
  withShipment,
  withOffline,
  connect(mapStateToProps, mapDispatchToProps)
)

export default withHOCs(CountContainer)
