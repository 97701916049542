const tranformExistingDestinationRowsQuery = (tableName) => {
  return (
    `WITH sub as (
      SELECT ph.*
      FROM real.price_history ph
      LEFT JOIN avocado.vw_products p ON ph.sku = p.product_id
      LEFT JOIN avocado.data_market m ON p.market=ANY(m.aliases)
      WHERE ph.date >= $1 AND ph.date <= $2 AND m.id = $3
    )
    UPDATE real.price_history rph
    SET buyprice = null 
    FROM sub where sub.sku = rph.sku AND sub.date = rph.date
    `
  )
}

const transFormOriginPriceQuery = () => {
  return (
    `
    INSERT INTO real.price_history (
      id,
      sku,
      date,
      month,
      buyprice,
      sellprice,
      discount,
      markup
    )

    WITH fromPrices AS (
      SELECT 
        ph.id,
        ph.sku,
        ph.date,
        ph.month,
        ph.buyprice,
        ph.sellprice,
        ph.discount,
        p.product,
        ph.markup
      FROM real.price_history ph
      LEFT JOIN avocado.vw_products p ON ph.sku = p.product_id
      LEFT JOIN avocado.data_market m ON p.market=ANY(m.aliases)
      WHERE 
        date >= $1
        AND date <= $2
        AND m.id = $3
        AND ph.buyprice IS NOT NULL
        AND lower(coalesce(visibility_1, '')) <> 'discontinued' 
    ),

    targetSkus as (
      SELECT distinct ON (product)
        product_id,
        product
      FROM
        avocado.vw_products p
        INNER JOIN avocado.data_market m ON p.market = m.product_market_code and m.id = $4
      ORDER BY product, product_id
    ),

    translatedSkus AS (
      SELECT 
        sn.*, 
        t.product_id AS translatedSku
      FROM fromPrices sn
      LEFT join targetSkus t on sn.product = t.product
    ),
    
    distinctRows AS (
      SELECT
        coalesce(ts.translatedSku, sellprices.sku) AS sku,
        ts.date as date,
        ts.month,
        ts.buyprice AS buyprice,
        sellprices.sellprice AS sellprice,
        ts.discount,
        ts.markup,
        row_number() over (partition by coalesce(ts.translatedSku, sellprices.sku), ts.date) as rownum
      FROM translatedSkus ts
      LEFT JOIN real.price_history sellprices ON sellprices.sku = ts.translatedSku AND ts.date = sellprices.date 
    ),

    vw as (
      SELECT 
        uuid_generate_v4() AS id,
        sku,
        date,
        month,
        buyprice,
        sellprice,
        discount,
        markup
      FROM 
        distinctRows dr
      WHERE 
        dr.sku IS NOT null
        and rownum =1
      )

      SELECT * 
      FROM vw 
      ON conflict (sku, date)    
        DO
        UPDATE SET buyprice = EXCLUDED.buyprice;
    `
  )
}

const transformDeleteDestinationRowsQuery = () => {
  return (
    `
    WITH destinationRows AS (
      SELECT ph.*, m.id AS market
      FROM real.price_history ph
      LEFT JOIN avocado.vw_products p ON ph.sku = p.product_id
      LEFT JOIN avocado.data_market m ON p.market=ANY(m.aliases)
      WHERE date >= $1 AND date <= $2 AND m.id = $3
       )
    DELETE FROM real.price_history ph WHERE ph.id IN (SELECT id FROM destinationRows) AND buyprice IS NULL AND sellprice IS NULL
    `
  )
}

const transformDeleteBuyPriceDailyRowsQuery = () => {
  return (
    `
    WITH destinationRows AS (
      SELECT ph.*
      FROM real.buyprice_daily ph
      LEFT JOIN avocado.vw_products p ON ph.sku = p.product_id
      LEFT JOIN avocado.data_market m ON p.market=ANY(m.aliases)
      WHERE date >= $1 AND m.id = $2
       )
    DELETE FROM real.buyprice_daily ph WHERE ph.sku IN (SELECT sku FROM destinationRows) AND ph.date IN (SELECT date FROM destinationRows)
    `
  )
}

const transformDeleteSellPriceDailyRowsQuery = () => {
  return (
    `
    WITH destinationRows AS (
      SELECT ph.*
      FROM real.sellprice_daily ph
      LEFT JOIN avocado.vw_products p ON ph.sku = p.product_id
      LEFT JOIN avocado.data_market m ON p.market=ANY(m.aliases)
      WHERE date >= $1 AND m.id = $2
       )
    DELETE FROM real.sellprice_daily ph WHERE ph.sku IN (SELECT sku FROM destinationRows) AND ph.date IN (SELECT date FROM destinationRows)
    `
  )
}

const transFormOriginBonusQuery = () => {
  return (
    `INSERT INTO avocado.price_calculator_bonus (
      month,
      sku,
      product_name,
      total_bought,
      bonus_earned,
      price_percentage
    )

    WITH fromPrices AS (
      SELECT 
        ph.month,
        ph.sku,
        ph.product_name,
        ph.total_bought,
        ph.bonus_earned,
        ph.price_percentage,
        p.product
    FROM avocado.price_calculator_bonus ph
    LEFT JOIN avocado.vw_products p ON ph.sku = p.product_id
    LEFT JOIN avocado.data_market m ON p.market=ANY(m.aliases)
    WHERE 
      month >= $1
      AND month <= $2
      AND m.id = $3
      AND lower(coalesce(visibility_1, '')) <> 'discontinued' 
    ),

    skuNames AS (
      SELECT *, p.product AS name 
      FROM fromPrices fp
      LEFT JOIN avocado.vw_products p ON fp.sku = p.product_id
    ),

    translatedSkus AS (
      SELECT *, coalesce(p.product_id, NULL) AS translatedSku, p.product AS productName
      FROM skuNames sn
      LEFT JOIN avocado.vw_products p ON sn.name = p.product
      LEFT JOIN avocado.data_market m ON p.market=ANY(m.aliases)
      WHERE m.id = $4
    ),

    distinctRows AS (
      SELECT DISTINCT
        ts.month,
        coalesce(ts.translatedSku, pcb.sku) AS sku,
        ts.productName AS product_name,
        ts.total_bought,
        ts.bonus_earned,
        ts.price_percentage
        FROM translatedSkus ts
      LEFT JOIN avocado.price_calculator_bonus pcb ON ts.translatedSku = pcb.sku AND ts.month = pcb.month
    )
  
    SELECT 
      *
    FROM distinctRows dr
    WHERE dr.sku IS NOT NULL
    ON conflict (month, sku)
    DO
    UPDATE SET (total_bought, bonus_earned, price_percentage) = (EXCLUDED.total_bought, EXCLUDED.bonus_earned, EXCLUDED.price_percentage);
    `
  )
}

const transFormOriginBonusShareQuery = (tableName) => {
  return (
    `INSERT INTO ${tableName} (
      month,
      market_id,
      share
    )

    WITH fromPrices AS (
      SELECT * FROM ${tableName} ph
      WHERE month >= $1 AND month <= $2 AND market_id = $3
    )
  
    SELECT 
      fp.month,
      $4,
      fp.share
    FROM fromPrices fp
    ON conflict (month, market_id)
    DO
    UPDATE SET share = EXCLUDED.share;
    `
  )
}

module.exports = {
  transFormOriginPriceQuery,
  transFormOriginBonusQuery,
  transFormOriginBonusShareQuery,
  transformDeleteDestinationRowsQuery,
  tranformExistingDestinationRowsQuery,
  transformDeleteBuyPriceDailyRowsQuery,
  transformDeleteSellPriceDailyRowsQuery
}
