import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ActionsBar from '../../../ActionsBar'

const PanelActions = ({
  title,
  children,
  className,
  ...otherProps
}) => (
  <div
    className={
      classnames(
        'vs-panel-actions',
        'vs-u-hide-on-print',
        className
      )
    }
  >
    <div className='vs-panel-actions__content'>
      {title && (
        <div className='vs-panel-actions__title'>
          {title}
        </div>
      )}
      <ActionsBar layout='centered' {...otherProps}>
        {children}
      </ActionsBar>
    </div>
  </div>
)

PanelActions.propTypes = {
  /**
   * The children of the component
   */
  children: PropTypes.node.isRequired,

  /**
   * Whether the component should expand to fit the full width of the Panel,
   * ignoring padding
   */
  fullWidth: PropTypes.bool,

  /**
   * Whether the component should expand to flex the full available height of
   * the Panel
   */
  spread: PropTypes.bool,

  className: PropTypes.string
}

PanelActions.defaultProps = {
  fullWidth: false
}

PanelActions.displayName = 'Page.Panel.Actions'

export default PanelActions
