const { parse } = require('../../tools/smart-id')
const get = require('lodash/get')
const uniqBy = require('lodash/uniqBy')
const { translateTerritoryAlias, productAliasesByMarket } = require('./translate-territory-alias')

const validateMappedProducts = ({ marketData = [], orders, productsById, suppliers }) => {
  const mapped = []
  const notMapped = []
  const invalidMapping = []
  suppliers = uniqBy(suppliers, (supplier) => {
    const { state } = parse(supplier.id)
    return state
  })
  const productsForTranslation = productAliasesByMarket(productsById)

  suppliers.forEach(supplier => {
    const { state } = parse(supplier.id)
    const productAlias = get(marketData.find(m => m.aliases.includes(state)), 'fs_service_id')
    orders.forEach(order => {
      const { state: destinationState } = parse(order.destinationId)
      if (state !== destinationState) {
        Object.keys(order.products).forEach(productId => {
          const product = productsById[productId]
          try {
            const mappedProductId = translateTerritoryAlias(productId, productsForTranslation, productAlias)
            if (mappedProductId === productId) {
              notMapped.push(product)
            } else {
              mapped.push(product)
            }
          } catch (error) {
            console.warn('cant validate mapping:', error)
            invalidMapping.push(product)
          }
        })
      }
    })
  })
  return {
    mapped,
    notMapped,
    invalidMapping
  }
}

module.exports = {
  validateMappedProducts
}
