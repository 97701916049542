import React from 'react'
import classnames from 'classnames'

import { Card } from '@fielded/shared-ui'
import { RETAILER_SERVICES } from './service-constants'

const DefaultCard = ({ children, className }) => (
  <div className={className}>{children}</div>
)

const ServiceCard = ({
  children,
  colorVariant = 'delivery', // default color
  isSingle,
  withBorder,
  withDefaultCard = false,
  className,
  ...otherProps
}) => {
  const CardShell = withDefaultCard ? DefaultCard : Card
  const cardShellProps = !withDefaultCard ? { semitight: true, rounded: true, ...otherProps } : { ...otherProps }

  const { DELIVERY, COLLECTION, COUNT, PROVISIONAL, CANCELLED } = RETAILER_SERVICES

  const isDelivery = colorVariant === DELIVERY
  const isCount = colorVariant === COUNT
  const isCollection = colorVariant === COLLECTION
  const isCancelled = colorVariant === CANCELLED
  const isWalletCard = colorVariant === 'wallet'

  return (
    <CardShell
      className={classnames(
        'service-texture-card',
        {'service-texture-card--single': isSingle},
        {'service-texture-card--delivery': isDelivery},
        {'service-texture-card--count': isCount},
        {'service-texture-card--collection': isCollection},
        {'service-texture-card--provisional': colorVariant === PROVISIONAL},
        {'service-texture-card--cancelled': isCancelled},
        {'service-texture-card--wallet': isWalletCard},
        {'service-texture-card--none': withDefaultCard},
        {'service-texture-card--border': withBorder},
        className
      )}
      {...cardShellProps}
    >
      <div className={classnames(
        'service-texture-card__bg',
        {'vs-u-texture-bg-teal': isDelivery},
        {'vs-u-texture-bg-purple': isCount || isWalletCard},
        {'vs-u-texture-bg-yellow': isCollection},
        {'vs-u-texture-bg-grey': isCancelled}
      )} />
      {children}
    </CardShell>
  )
}

export default ServiceCard
