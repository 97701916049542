const { listChildren } = require('./list-children')
const { listPrograms } = require('../../../program/api')
const { createLocationExport } = require('../../tools/export-location-tools')

// This is used only by PSM.
// VAN/SL export allocations, which include product info.
exports.exportLocations = async (state, {template, date} = {}) => {
  const locations = await listChildren(
    state, state.user.location.id, {date, includeInactive: true}
  )
  const programs = await listPrograms(state, true)
  return createLocationExport({locations, programs, template})
}
