const keyBy = require('lodash/keyBy')
const {decorateOrder, replaceSubFunders} = require('../rest-api-utils')

exports.getSuborder = function ({
  doc, alias, warehouseCodeMap = {},
  productMasterData,
  funders,
  useGenericParent
}) {
  const fundersById = keyBy(funders, '_id')

  return decorateOrder({
    doc: replaceSubFunders(doc, fundersById),
    alias,
    warehouseCodeMap,
    productMasterData,
    useGenericParent
  })
}
