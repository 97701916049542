const get = require('lodash/get')

exports.createCommodityTypeSnapshots = function ({snapshots, productsById}) {
  return snapshots
    .reduce((acc, snapshot) => {
      return acc.concat(splitSnapshotByCommodityType({snapshot, productsById}))
    }, [])
}

function splitSnapshotByCommodityType ({snapshot, productsById}) {
  const byCommodityType = Object.keys(snapshot.products)
    .reduce((acc, productId) => {
      const commodityType = get(productsById, `${productId}.commodityType`, 'none')
      acc[commodityType] = acc[commodityType] || {}
      acc[commodityType][productId] = Object.assign({}, snapshot.products[productId])
      return acc
    }, {})

  return Object.keys(byCommodityType)
    .map(commodityType => {
      const products = byCommodityType[commodityType]
      return Object.assign({}, snapshot, {
        products,
        commodityType
      })
    })
}

exports.createStorageTypeSnapshots = function ({snapshots, productsById}) {
  return snapshots
    .reduce((acc, snapshot) => {
      return acc.concat(splitSnapshotByStorageType({snapshot, productsById}))
    }, [])
}

// Heads up: if an order has separate supplies for cold chain vs non, these were already split
// in create-warehouse-suborders. The code paths are different because the params are different enough.
// If we need to modify this, double check that create-warehouse-suborders.js does not need a similar modification.
function splitSnapshotByStorageType ({snapshot, productsById}) {
  const byStorageType = Object.keys(snapshot.products)
    .reduce((acc, productId) => {
      const storageType = get(productsById, `${productId}.storageType`, 'non-cold-chain')
      acc[storageType] = acc[storageType] || {}
      acc[storageType][productId] = Object.assign({}, snapshot.products[productId])
      return acc
    }, {})

  return Object.keys(byStorageType)
    .map(storageType => {
      const products = byStorageType[storageType]
      return Object.assign({}, snapshot, {
        products,
        storageType
      })
    })
}
