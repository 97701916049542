import React, { useContext } from 'react'

import { Banner, Text } from '@fielded/shared-ui'

import { AuthenticationContext } from '../PSMDeliveryAuthenticationContainer'
import OTPInput from '../../../../../../../../van-shared/components/OTPInput/OTPInput'

const PSMDeliveryOTP = ({ isOtpInputDisabled }) => {
  const {
    otp,
    authOptions,
    onHandleOTPChange
  } = useContext(AuthenticationContext)

  const { otp: otpOption } = authOptions
  const hasError = otpOption.hasError

  return (
    <section className='delivery-otp'>
      {hasError && (
        <Banner
          className='vs-u-margin-bottom-double'
          title='Validation failed'
          type='warning'
          center={false}
          inline
          tight
        >
          Please confirm OTP and try again.
        </Banner>
      )}

      <Text className='vs-u-margin-bottom-double' bold>
        Please enter the OTP provided by the facility attendant to proceed with the delivery.
      </Text>

      <OTPInput
        otp={otp}
        hasError={hasError}
        disabled={isOtpInputDisabled}
        onChange={onHandleOTPChange}
      />
    </section>
  )
}

export default PSMDeliveryOTP
