module.exports = getLocationLevel

const levels = require('./location-levels')

function getLocationLevel (location) {
  for (let level of levels.slice(1).reverse()) {
    if (location.indexOf(`${level}:`) > -1) {
      return level
    }
  }

  // for program with national as their parent / top level location
  if (location === 'national') {
    return 'national'
  }

  return 'country'
}
