import React from 'react'
import ShelfLifeLogo from '../../ShelfLifeLogo'

const PrintTemplateSL = ({
  location, children}) => {
  let subCompany = 'Field Technology Development Partners Nigeria, LTD (RC 1303615)'
  let subCompanyAddress = 'No. 4 Adzope Crescent, Wuse II, FCT, Abuja, Nigeria'
  if (location && location.country === 'ke') {
    subCompany = 'Field Technology Development Partners, Limited'
    subCompanyAddress = 'Senteu Plaza, Galana Road, Kilimani, Nairobi, Kenya'
  }
  return (
    <div className='vs-print-template-sl'>
      <div className='vs-print-template-sl__header'>
        <div className='vs-print-template-sl__wordmark'>
          <ShelfLifeLogo />
        </div>
      </div>

      <div className='vs-print-template-sl__footer vs-u-hide-on-screen'>
        {subCompany}
        <div className='vs-print-template-sl__footer-address'>
          {subCompanyAddress}
        </div>
      </div>

      <table className='vs-print-template-sl__container'>
        <thead>
          <tr>
            <td>
              {/* <!--place holder for the fixed-position header--> */}
              <div className='vs-print-template-sl__header-space' />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {/* <!--*** CONTENT GOES HERE ***--> */}
              <div className='vs-print-template-sl__page'>
                {children}
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              {/* <!--place holder for the fixed-position footer--> */}
              <div className='vs-print-template-sl__footer-space' />
            </td>
          </tr>
        </tfoot>
      </table>

      <div className='vs-u-hide-visually' aria-hidden='true'>
        {/*
          These allow us to pre-load the fonts for SL brand styling,
          by rendering them in the browser without them being visible to the user.
          We need that in order to have the fonts ready for the print preview,
          which is used to save as PDF.
          Without this the page may show up without text in print preview.
        */}
        <div className='fb-regular-font'>test</div>
        <div className='fb-regular-font-bold'>test</div>
        <div className='fb-display-font'>test</div>
        <div className='fb-display-font-bold'>test</div>
      </div>
    </div>
  )
}

export default PrintTemplateSL
