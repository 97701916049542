import React from 'react'
import PropTypes from 'prop-types'

import { FieldContext } from '../'
import { requiredValidationErrorMessage } from '../../utils'
import FormFieldRegistrationManager from '../../FormFieldRegistrationManager'
import SignaturePad from '../../../SignaturePad'

/**
 *  Wrapper for input
 *
 *  Adds additional functionality such as validation
 */
const Signature = ({
  required,
  onValueChange,
  defaultValue,
  ...otherProps
}) => (
  <FieldContext.Consumer>
    {
      (field) => (
        <FormFieldRegistrationManager
          register={() => {
            field.register({
              required,
              requiredValidator: async (value) => {
                if (!value || value === '') {
                  return required && requiredValidationErrorMessage(required)
                }
              },
              value: defaultValue,
              ...otherProps
            })
          }}
          deregister={() => field.deregister()}
        >
          <SignaturePad
            showLabel={false}
            signature={defaultValue}
            hasError={field.hasErrors()}
            onValueChange={(value) => {
              field.handleOnChange(value)
              if (typeof onValueChange === 'function') {
                onValueChange(value)
              }
            }}
            {...otherProps}
          />
        </FormFieldRegistrationManager>
      )
    }
  </FieldContext.Consumer>
)

Signature.propTypes = {
  /**
   * Whether the field is required, optionally pass a string to define a custom
   * validation error message
   */
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),

  /**
   * Runs when field value changes (returns new value)
   */
  onValueChange: PropTypes.func,

  /**
   * Initial field value (used if form needs to be reset)
   */
  defaultValue: PropTypes.string,

  /**
   * Disables the field
   */
  disabled: PropTypes.bool
}

Signature.defaultProps = {
  required: false,
  disabled: false
}

Signature.displayName = 'Form.Field.Signature'

export default Signature
