export const serviceFilterData = {
  all: {
    label: 'All service visits',
    value: 'all'
  },

  delivery: {
    label: 'Deliveries',
    value: 'delivery'
  },

  count: {
    label: 'Counts',
    value: 'count'
  },

  'return-collection': {
    label: 'Collections',
    value: 'return-collection'
  },

  'delivery-topup': {
    label: 'Top-ups',
    value: 'delivery-topup'
  },

  return: {
    label: 'Returns',
    value: 'return'
  }
}
