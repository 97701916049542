module.exports = getParentLocation

const getLocationLevel = require('./get-location-level')

function getParentLocation (locationId, locationEntity = null) {
  const locationLevel = (locationEntity && locationEntity.level)
    ? locationEntity.level
    : getLocationLevel(locationId)

  if (locationLevel === 'national' || locationLevel === 'country') {
    return 'country'
  }

  if (locationLevel === 'zone') {
    return 'national'
  }

  const [, zone, , state, , lga] = locationId.split(':')

  if (locationLevel === 'state') {
    return `zone:${zone}`
  }

  if (locationLevel === 'lga' || locationLevel === 'state-satellite') {
    return `zone:${zone}:state:${state}`
  }

  if (locationLevel === 'sdp') {
    return `zone:${zone}:state:${state}:lga:${lga}`
  }
}
