const { TABLE_NAMES: {
  TRANSACTIONS_TABLE_NAME
} } = require('../constants')

const txnTypeQuery = `
  SELECT
    id,
    txn_id,
    company_code,
    quickbooks_doc_number,
    amount,
    amount_paid,
    location_id,
    txn_type
  FROM ${TRANSACTIONS_TABLE_NAME}
  WHERE id = $1
`

module.exports = {
  txnTypeQuery
}
