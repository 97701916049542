const { sha512 } = require('./../../tools/hash')
const {getOTPHeaders} = require('./utils')

module.exports = createOTP
async function createOTP ({mandateId, requestId}) {
  // when creating OTP, the requestId as given int he header needs to be unique per request, while the one
  // given in the body needs to match what was set for the mandate when it was created.
  const headerRequestId = new Date().getTime()

  const {apiToken, merchantId, apiKey} = this
  const hash = await sha512(`${apiKey}${headerRequestId}${apiToken}`)
  const headers = getOTPHeaders({apiToken, merchantId, apiKey, requestId: headerRequestId, hash})

  // we don't know why but this method uses headers & body..
  // while create mandate just uses body.
  return this.fetch(this.endpoints.requestOTP, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      mandateId,
      requestId
    })
  })
}
