module.exports = toShipmentId

const toLocationId = require('./to-location-id')

function toShipmentId (properties) {
  if (typeof properties === 'string') {
    return properties.split(':status:')[0]
  }

  const {origin, destination, date, shipmentNo} = properties

  return `origin:${toLocationId(origin)}:destination:${toLocationId(destination)}:date:${date}:shipmentNo:${shipmentNo}`
}
