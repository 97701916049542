const tools = require('../../tools')
const { getGroup, getOrder } = require('../read/get')
const { getSuborders } = require('../read/internal')
const utils = require('../../tools/utils')

exports.close = async function (state, {groupId, orderId, suborderId, closedStatus = 'cancelled', status, closedAt}) {
  if (!closedAt || isNaN(new Date(closedAt))) {
    throw new Error(`Closed requires a json date, given: ${closedAt}`)
  }
  const level = tools.getLevel(groupId, orderId, suborderId)
  const docs = await getDocs(state, level, {groupId, orderId, suborderId})
  // if no status return all the orders else return the specified(status) orders
    .then(orders => status ? utils.filterSnapshotsByStatus(orders, status) : orders)

  const snapshots = tools.closeSnapshots(docs, closedStatus, state.user, closedAt)

  const userStatus = tools.getUserStatus(state.user)

  const updatedSnapshots = await state.dal.order.bulkDocs(state, snapshots)
  return tools.docsToOrderEntities({docs: updatedSnapshots, status: userStatus})
}

/**
 * Get Docs based on the level (group, order, suborder)
 * @param state
 * @param level
 * @param param.groupId
 * @param param.orderId
 * @param param.suborderId
 *
 * @return Promise<Array<Order>>
 */
function getDocs (state, level, {groupId, orderId, suborderId}) {
  const withSnapshots = true
  switch (level) {
    case tools.LEVELS.GROUP:
      return getGroup(state, {groupId, withSnapshots})
        .then(({orders}) => orders)
        .then(utils.flattenOrdersSnapshots)
    case tools.LEVELS.ORDER:
      return getOrder(state, {orderId, withSnapshots})
        .then(order => [order])
        .then(utils.flattenOrdersSnapshots)
    case tools.LEVELS.SUB_ORDER:
      return getSuborders(state, {orderId, suborderId})
    default:
      return Promise.reject(new Error(`Invalid level type, possible values ${Object.values(tools.LEVELS).join(',')}`))
  }
}
