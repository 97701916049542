import React, { Component, createElement, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class Bullet extends Component {
  static propTypes = {
    /**
     * Alternative wrapper for the bullet, e.g. Link
     */
    component: PropTypes.node,
    /**
     * Icon to render
     */
    icon: PropTypes.node,
    /**
     * Changes label text to uppercase
     */
    uppercase: PropTypes.bool,
    /**
     * The label text
     */
    children: PropTypes.node,
    /**
     * Any additional className you want to add
     */
    className: PropTypes.string
  }

  static defaultProps = {
    component: 'div'
  }

  render () {
    const {
      component,
      icon,
      uppercase,
      children,
      className
    } = this.props

    return createElement(
      component,
      {
        className: classnames(
          'vs-bullet',
          {'vs-bullet--uppercase': uppercase},
          className
        )
      },
      (<Fragment>
        {icon && (
          <span className='vs-bullet__icon'>
            {icon}
          </span>
        )}
        {children && (
          <span className='vs-bullet__text'>
            {children}
          </span>
        )}
      </Fragment>)
    )
  }
}

export default Bullet
