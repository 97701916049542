import React, { Fragment } from 'react'

import { Button, Card, DeemphasizedLink, Loading, Text } from '@fielded/shared-ui'

const PushNotificationSettingsCard = ({
  user,
  status,
  loading,
  onSetNotifications,
  className,
  withPointer,
  appName,
  // Dont show turn off notifications from the home page
  showTurnOffOption = false
}) => {
  return (
    <Card
      withPointer={withPointer}
      tight
      rounded
      className={className}
    >
      <Card.Content>
        {(loading) && (
          <Loading />
        )}
      </Card.Content>
      {!loading && (
        <Fragment>
          {(!status || status === 'dismissed') && (
            <Fragment>
              <Card.Content>
                <Text bold tight size='large'>
                Get instant app notifications!
                </Text>
                <p className='vs-u-margin-top-half'>
                Want to get notified about your {appName} service (such as deliveries and product list updates) as soon as it happens?
                </p>
              </Card.Content>
              <Card.Action
                className='push-notification-settings-card__bar'
                layout='centered'
                nowrap
              >
                <Button
                  colorVariant='brand'
                  disabled={loading}
                  fill='full'
                  onClick={() => onSetNotifications('initialize')}
                >
                Turn on notifications
                </Button>
                <DeemphasizedLink
                  disabled={loading}
                  onClick={() => onSetNotifications('dismiss')}
                >
                Maybe later
                </DeemphasizedLink>
              </Card.Action>
            </Fragment>)
          }
          {/* Cannot turn off the notifications from the home page */}
          {showTurnOffOption && (<Fragment>
            {status === 'initialized' && (
              <Fragment>
                <Card.Content>
                  <Text bold tight size='large'>
                You will get push and email notifications when an action happens in your account
                  </Text>
                  <p className='vs-u-margin-top-half'>
                You receive app push notifications on this device.
                  </p>
                </Card.Content>
                <Card.Action
                  layout='left'>
                  <Button
                    disabled={loading}
                    onClick={() => onSetNotifications('dismiss')}
                    colorVariant='brand'
                    fill='outline'
                  >
                Turn off notifications
                  </Button>
                </Card.Action>
              </Fragment>
            )}
            {status === 'denied' && (
              <Card.Content>
                <Text bold tight size='large'>
                Ok, notifications are off
                </Text>
                <p className='vs-u-margin-top-half'>
                You will not receive app push notifications.
                </p>
              </Card.Content>
            )}
          </Fragment>)}
        </Fragment>
      )}
    </Card>
  )
}

export default PushNotificationSettingsCard
