import React from 'react'

import { Form, Page } from '@fielded/shared-ui'

export default (props) => {
  const { user, locationDisplayName, onShowCancelChangesToast } = props
  const { fullName, email } = user.profile
  const { name } = user

  const isFacilityLevel = user.location.level === 'sdp'
  return (
    <Page.Panel.Section title='General'>
      <Form.Row>
        <Form.Field labelText={`Display Name${isFacilityLevel ? ' / Facility name' : ''}`} fieldName='displayName'>
          <Form.Field.Text defaultValue={fullName} onValueChange={() => onShowCancelChangesToast()} required />
          <Form.Field.ValidationErrors />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Field labelText='Email' fieldName='email'>
          <Form.Field.Text defaultValue={email} onValueChange={() => onShowCancelChangesToast()} required />
          <Form.Field.ValidationErrors />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Field labelText='Username' fieldName='username'>
          <Form.Field.Text defaultValue={name} readOnly />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Field labelText='Location' fieldName='location'>
          <Form.Field.Text defaultValue={locationDisplayName} readOnly />
        </Form.Field>
      </Form.Row>
    </Page.Panel.Section>
  )
}
