const { addAllocations } = require('./add')
const validate = require('../validate-products')

const parser = (format) => {
  switch (format) {
    case 'csv':
      return require('../csv-parse')
    case 'xlsx':
    default:
      return require('../xlsx-parse')
  }
}

const importAllocations = async (state, {
  data,
  format,
  validateProducts = true,
  validateLocations = true,
  effectiveDate = new Date().toISOString(),
  mergeProducts = false
}) => {
  if (!data) {
    throw new Error('Parameter data is required')
  }
  const parse = parser(format)
  const configurations = await parse(data, effectiveDate, state.user.name)

  // validate allocation products
  if (validateProducts) {
    const validationError = await validate(state, configurations)
    if (validationError) {
      throw validationError
    }
  }

  // validate allocation locations
  if (validateLocations) {
    const locationIds = [...new Set(configurations.map(allocation => allocation.facilityId))]
    const existingLocations = await state.allocationApi.mainApi.location.getByIds(locationIds)
    const existingLocationIds = existingLocations.map(location => location._id)
    const invalidLocations = locationIds.filter(id => !existingLocationIds.includes(id))

    if (invalidLocations.length > 0) {
      throw new Error(`No locations available with the following id(s): ${invalidLocations.join(', ')}`)
    }
  }

  return addAllocations(state, { configurations, effectiveDate, mergeProducts })
}

module.exports = importAllocations
