import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Button from '../Button'
import { Close } from '../../icons'

const FilterButton = ({ children, inline, colorVariant = 'dark', onClick, ...otherProps }) => {
  return (
    <Button
      className={classnames(
        'vs-filter-button',
        {'vs-filter-button--inline': inline}
      )}
      fill={inline ? 'outline' : 'full'}
      colorVariant={colorVariant}
      shape='square'
      size={inline ? 'inline' : 'regular'}
      disabled={!onClick}
      onClick={onClick}
      {...otherProps}
    >
      { children }
      {onClick &&
        <div className='vs-filter-button__icon'>
          <Close />
        </div>
      }
    </Button>
  )
}

FilterButton.propTypes = {
  /**
   * Content of button. Can be text or any tag valid by HTML standards.
   */
  children: PropTypes.node.isRequired,
  /**
   * Render inline with e.g. a title
   */
  inline: PropTypes.bool
}

export default FilterButton
