import React from 'react'

import { Clipboardlist, ListOl, Truck, TruckCollection } from '@fielded/shared-ui/src/icons'

export const SERVICE_DATA = {
  delivery: {
    icon: <Truck direction='right' />,
    service: 'delivery'
  },
  count: {
    icon: <ListOl />,
    service: 'count'
  },
  collection: {
    icon: <TruckCollection />,
    service: 'collection'
  },
  provisional: {
    icon: <Clipboardlist />,
    service: 'delivery'
  },
  cancelled: {
    icon: <Truck direction='right' />,
    service: 'delivery'
  }
}
