import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const FormRowItem = ({child}) => (
  <div className={classnames(
    'vs-form-row__item',
    {'vs-form-row__item--spread': child.props.spread}
  )}>
    {child}
  </div>
)

const Row = ({ legend, intro, children, className, ...otherProps }) => {
  if (legend) {
    return (
      <fieldset {...otherProps}
        className={classnames(
          'vs-form-row',
          className
        )}
      >
        <legend className='vs-form-row__legend'>{ legend }</legend>
        {intro && <legend className='vs-form-row__intro'>{ intro }</legend>}
        <div className='vs-form-row__fields'>
          {React.Children.map(children, child => <FormRowItem child={child} />
          )}
        </div>
      </fieldset>
    )
  }
  return (
    <div {...otherProps} className={classnames(
      'vs-form-row',
      className
    )}
    >
      {intro && <legend className='vs-form-row__intro'>{ intro }</legend>}
      {React.Children.map(children, child => {
        // Return null if the child is null to avoid an empty .vs-form-row__item
        // being left in the dom if a field is dynamically removed.
        if (!child) return null
        return <FormRowItem child={child} />
      })}
    </div>
  )
}

Row.displayName = 'Form.Row'

Row.propTypes = {
  /**
   * Optionally add a legend to the fields in the row
   */
  legend: PropTypes.string,

  /**
   * The Fields in this row.
   */

  children: PropTypes.node.isRequired,

  /**
   * Optional additional classes
   */
  className: PropTypes.string
}

Row.defaultProps = {
  legend: '',
  className: ''
}

export default Row
