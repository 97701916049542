import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Accordion, Button } from '@fielded/shared-ui'

import { Chevron, Download } from '@fielded/shared-ui/src/icons'

import {
  buildInventoryStatementUrl,
  buildSalesStatementUrl
} from '../app/routes-urls'

const LocationsListStatementDownload = ({
  statementReport,
  period,
  locationId
}) => {
  const [downloadsVisible, setDownloadsVisibility] = useState(false)

  const reportId = statementReport.id
  const date = period.effectiveEndDate.toJSON()
  const serviceId = statementReport.serviceId
  const inventoryStatementUrl = buildInventoryStatementUrl({reportId, locationId, serviceId, date})
  const salesStatementUrl = buildSalesStatementUrl({reportId, locationId, serviceId, date})
  // Note: we don't have access to the stockCount itself here (due to the get-overview optimization)
  // so for now we can't show/hide the sales statement button depending on recorded sales
  return (
    <Accordion>
      <Accordion.Section
        isExpanded={downloadsVisible}
      >
        <Accordion.Section.Header>
          <Button
            colorVariant='dark'
            size='small'
            onClick={() => setDownloadsVisibility(!downloadsVisible)}
            iconSide='right'
            icon={<Chevron direction={downloadsVisible ? 'up' : 'down'} />}
          >
            Download statements
          </Button>
        </Accordion.Section.Header>
        <Accordion.Section.Content>
          <div className='location-item__statements'>
            <div className='location-item__download-button'>
              <Button
                component={Link}
                to={inventoryStatementUrl}
                icon={<Download />}
                fill='outline'
                colorVariant='brand'
                size='small'
              >
                Inventory Statement
              </Button>
            </div>
            <div className='location-item__download-button'>
              <Button
                component={Link}
                to={salesStatementUrl}
                icon={<Download />}
                fill='outline'
                colorVariant='brand'
                size='small'
              >
                Sales Statement
              </Button>
            </div>
          </div>
        </Accordion.Section.Content>
      </Accordion.Section>
    </Accordion>
  )
}

export default LocationsListStatementDownload
