import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

/**
 *  Generic <Backdrop/> Component.
 */
const Backdrop = (props) => {
  const {
    className,
    onClose
  } = props

  return (
    <div
      onClick={onClose}
      className={classnames(
        'vs-backdrop',
        className
      )}
    />
  )
}

Backdrop.propTypes = {
  /*
   * In case you want to extend the default class names without overriding the default look.
   */
  className: PropTypes.string,
  /*
   * You can pass a function to close the backdrop on click
   */
  onClose: PropTypes.func
}

export default Backdrop
