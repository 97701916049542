const JS_SOURCE = 'https://js.paystack.co/v1/inline.js'

class PaystackWebInterface {
  constructor (props) {
    Object.assign(this, props)
    this.script = null
  }
  init () {
    if (this.script) {
      return
    }
    this.script = document.createElement('script')
    this.script.src = JS_SOURCE
    document.body.appendChild(this.script)
    return new Promise((resolve, reject) => {
      this.script.onload = () => resolve()
      this.script.onerror = () => {
        this.script.remove()
        return reject(new Error('Paystack script failed to load'))
      }
    })
  }

  async create (props, cb) {
    await this.init()
    return new Promise((resolve) => {
      const { publicKey, currency } = this
      const handler = window.PaystackPop && window.PaystackPop.setup({
        currency,
        key: publicKey,
        ...props,
        callback: (response) => resolve(response),
        onClose: (data) => cb ? cb(data) : null
      })
      handler && handler.openIframe()
    })
  }
}

module.exports = PaystackWebInterface
