const getLedgerQuery = `
WITH ledgers as (
  SELECT
    location_id::text,
    cl.sku,
    date,
    sl_balance,
    dp.display_name product
  FROM avocado.couch_ledgers_latest cl
  INNER JOIN avocado.data_productsku dp ON cl.sku = dp.old_sku_id
  WHERE location_id::text = $1
  ORDER BY dp.display_name
),
shipment_base as (
  SELECT
    dl.uuid as location_id,
    sku,
    -amount as amount
  FROM
    avocado.data_shipment s
    LEFT JOIN avocado.data_shipmentline sl ON sl.shipment_id = s.id
    LEFT JOIN avocado.data_location dl ON dl.fsid = s.origin_id
  WHERE status = 'new'
    AND s.created_at > current_date - '2 weeks'::interval
    AND uuid::text = $1
),
shipments as (
  SELECT
    location_id,
    sku,
    sum(amount) as amount
  FROM shipment_base
  GROUP BY location_id, sku
),
orders_base as (
  SELECT
    dl.uuid as location_id,
    dp.display_name product,
    -adjusted as amount
  FROM
    avocado.data_order o
    INNER JOIN avocado.data_orderline ol ON ol.order_id = o.id
    INNER JOIN avocado.data_location dl ON dl.fsid = o.destination_id
    INNER JOIN avocado.data_market dm ON dl.market_id = dm.id
    INNER JOIN avocado.data_productsku dp ON ol.sku = dp.old_sku_id
  WHERE 
     o.supplier_id = $2 AND
    closed_status is null AND
    is_complete = false AND
    (
      coalesce(dl.supply_chain_level, 'sdp') = 'sdp' OR
      dl.classification = 'External Distribution Center'
    ) AND
    dm.country_code IN ('ke', 'ng') AND
    o.created_at > current_date - '2 weeks'::interval
),
orders as (
  SELECT
    product,
    sum(amount) as amount
  FROM orders_base
  GROUP BY product
  ORDER BY product
)
SELECT
  l.location_id,
  dl.fsid as location_fsid,
  max(date) as updated_at,
  json_agg(json_build_object('sku', l.sku, 'amount', sl_balance, 'shipments', s.amount, 'orders', o.amount)) as stock
FROM
  ledgers l
  LEFT JOIN shipments s ON l.location_id = s.location_id AND l.sku = s.sku
  LEFT JOIN orders o ON l.product = o.product
  LEFT JOIN avocado.data_location dl ON dl.uuid = l.location_id
  INNER JOIN avocado.data_productsku ps ON 
    ps.old_sku_id = l.sku AND 
    NOT ps._deleted AND
    ps.market_id = dl.market_id
GROUP BY 1,2
`

module.exports = getLedgerQuery
