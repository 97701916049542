import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const StatusCardLabel = ({
  statusType,
  statusText,
  className
}) => (
  <div
    className={classnames(
      'vs-status-card__label',
      {'vs-status-card__label--type-return': statusType === 'return'},
      {'vs-status-card__label--type-distribution': statusType === 'distribution'},
      {'vs-status-card__label--type-arrival': statusType === 'arrival'},
      {'vs-status-card__label--type-external-arrival': statusType === 'external-arrival'},
      {'vs-status-card__label--type-cancelled': statusType === 'cancelled'},
      className
    )}
  >
    <div className='vs-status-card__label-text'>
      {statusText}
    </div>
  </div>
)

StatusCardLabel.propTypes = {
  /**
   * The status type `arrival|external-arrival\distribution`
   */
  statusType: PropTypes.oneOf(['return', 'cancelled', 'arrival', 'external-arrival', 'distribution', 'provisional', 'provisional-warning', null]),
  /**
   * The status text to display
   */
  statusText: PropTypes.string.isRequired,
  /**
   * Set custom CSS classes to extend the StatusCardLabel for your needs.
   */
  className: PropTypes.string
}

StatusCardLabel.defaultProps = {
  statusType: undefined,
  statusText: '',
  className: ''
}

StatusCardLabel.displayName = 'StatusCard.Label'

export default StatusCardLabel
