import React, { useState, useEffect } from 'react'
import flowRight from 'lodash/flowRight'

import { BackButton, Loading } from '@fielded/shared-ui'

import withConfig from '../../../../../van-shared/hoc/withConfig'
import { withApi } from '../../../../../common/ApiProvider'
import { withUser } from '../../../../../common/AuthenticationProvider'

import StatementsContainer from '../../../../../statements/StatementsContainer'

import ErrorView from '../../../components/LoadingError'

const DeliveryStatementContainer = ({
  api,
  user,
  config,
  match,
  history
}) => {
  const { snapshotId } = match.params
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [delivery, setDelivery] = useState({})

  const initialize = async () => {
    try {
      const delivery = await api.shipment.findById(snapshotId)
      setDelivery(delivery)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  if (error) {
    return <ErrorView error={error} />
  }

  if (loading) {
    return <Loading />
  }

  const locationId = delivery.destination.id
  const date = delivery.snapshotDates.received

  return (
    <div className='print page'>
      <header className='header--back'>
        <BackButton history={history}>go back</BackButton>
      </header>
      <StatementsContainer
        catchLocation
        locationId={locationId}
        shipment={delivery}
        date={date}
        showDelivery
        isWithLedger={false}
      />
    </div>
  )
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig
)

export default withHOCs(DeliveryStatementContainer)
