import React from 'react'

import { BackButton, Button } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

const Confirmation = ({
  history,
  onProceedClick
}) =>
  <div className={'confirmation page page--red'}>
    <header className='header--back'>
      <BackButton history={history} colorVariant='light'>go back</BackButton>
    </header>
    <div className='content'>
      <h2 className='centered-page-title'>
        This device is not connected to the internet!
      </h2>
    </div>
    <footer className='footer--vertical'>
      <p>Please connect to the internet now so the shipment record can be updated on the dashboard and sent to the receiving store.</p>
      <div className='confirmation-footer__button'>
        <Button
          onClick={onProceedClick}
          fill='full'
          colorVariant='brand'
          size='large'
          icon={<Arrow direction='right' />}
          iconSide='right'
        >
          I can’t right now
        </Button>
      </div>
    </footer>
  </div>

export default Confirmation
