module.exports = toSurveyId

const dateToIdFriendlyTimestamp = require('./date-to-id-friendly-timestamp')

function toSurveyId (options) {
  const prefix = options.snapshotId.replace(/:agent:.*$/, '')

  const timestamp = dateToIdFriendlyTimestamp(options.createdAt)
  return `${prefix}:${options.batchId}:survey:${timestamp}`
}
