const { EntityApi } = require('../common')
const { LedgerPGAdapter } = require('./adapters/pg-adapter')
const { LedgerRestAdapter } = require('./adapters/rest-adapter')

class LedgerApi extends EntityApi {
  constructor (state, pgConnection, agaveAdapter, logger) {
    const { user } = state

    let adapter = {}
    if (pgConnection) {
      adapter = new LedgerPGAdapter({ pgConnection, username: user.name, logger })
    } else if (agaveAdapter) {
      adapter = new LedgerRestAdapter({ agaveAdapter, logger })
    }

    super(adapter)
  }
}

module.exports = { LedgerApi }
