const { createLocationId } = require('./id-utils')

exports.getSiblingsNodes = async (
  graph,
  locationId
) => {
  const nodeId = createLocationId(locationId)
  const self = graph.getNode(nodeId)
  if (!self || self.type !== 'location') {
    return []
  }
  const suppliers = graph.getSuccessors(nodeId, 'supplied-by')
  const siblings = []
  for (const supplier of suppliers) {
    const suppliees = graph.getSuccessors(supplier.id, 'supplies')
    for (const suppliee of suppliees) {
      if (suppliee.type !== 'location') {
        continue
      }
      if (suppliee.location.level === self.location.level && suppliee.id !== self.id) {
        siblings.push(suppliee)
      }
    }
  }
  return siblings
}
