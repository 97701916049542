const { createLocationId, createGeoLocationId, createLevelId } = require('./tools/id-utils')
const { getLocationsFromNodes } = require('./tools/get-locations-from-nodes')
const { getSiblingsNodes } = require('./tools/get-siblings-nodes')

/** Create the graph api
 *
 * The api makes calls to the graph more convenient by putting
 * lookups behind explicitly named methods that return locations
 * instead of graph nodes and provide some filtering options.
 */
exports.createGraphApi = (graph) => ({
  getLocation: async (id) => {
    const nodeId = createLocationId(id)
    const node = graph.getNode(nodeId)
    if (node == null) {
      return Promise.resolve()
    }
    if (node.type !== 'location') {
      throw new Error('Node is not of type location: ' + nodeId)
    }
    return Promise.resolve(node.location)
  },

  getSuppliers: async (
    locationId,
    options
  ) => {
    const nodeId = createLocationId(locationId)
    const nodes = graph.getSuccessors(nodeId, 'supplied-by')
    return Promise.resolve(getLocationsFromNodes(nodes, options))
  },

  getSuppliees: async (
    locationId,
    options
  ) => {
    const nodeId = createLocationId(locationId)
    const nodes = graph.getSuccessors(nodeId, 'supplies')
    return Promise.resolve(getLocationsFromNodes(nodes, options))
  },

  getSiblings: async (
    locationId,
    options
  ) => {
    const nodes = await getSiblingsNodes(graph, locationId)
    return Promise.resolve(getLocationsFromNodes(nodes, options))
  },

  /** Get the suppliees and the suppliees of suppliees and so on
   *
   * This will return the descendants in depth first order, which means
   * the suppliees at the end of the supply chain will come first in the list.
   * That is useful in aggregation where we want to traverse from the
   * bottom up. The order of suppliees relies on the order of nodes
   * returned from `graph.getDescendants`.
   */
  getDescendantSuppliees: async (
    locationId,
    options
  ) => {
    const nodeId = createLocationId(locationId)
    const nodes = graph.getDescendants(nodeId, 'supplies')
    return Promise.resolve(getLocationsFromNodes(nodes, options))
  },

  /** Get the suppliers and the suppliers of suppliers and so on
   *
   * The will return the suppliers in depth first order, so the top
   * most supplier comes first. The returned list is in that order because
   * we start from the given location, move up the supply chain and get
   * the result in depth first order. The returned list be reversed
   * to have the closest suppliers at the beginning of the list.
   * The order of suppliees relies on the order of nodes
   * returned from `graph.getDescendants`.
   */
  getDescendantSuppliers: async (
    locationId,
    options
  ) => {
    const nodeId = createLocationId(locationId)
    const nodes = graph.getDescendants(nodeId, 'supplied-by')
    return Promise.resolve(getLocationsFromNodes(nodes, options))
  },

  getDescendantSuppliersForIds: async (
    locationIds,
    options
  ) => {
    const supplierNodesMap = new Map()
    locationIds.forEach(id => {
      const nodeId = createLocationId(id)
      graph.getDescendants(nodeId, 'supplied-by').forEach(node => {
        supplierNodesMap.set(node.id, node)
      })
    })
    const supplierNodes = [...supplierNodesMap.values()]
    return Promise.resolve(getLocationsFromNodes(supplierNodes, options))
  },

  getLocationsInGeoLocation: async (
    geoLocationId,
    options
  ) => {
    const nodeId = createGeoLocationId(geoLocationId)
    const nodes = graph.getSuccessors(nodeId, 'contains')
    return Promise.resolve(getLocationsFromNodes(nodes, options))
  },

  getLocationsOfLevel: async (
    levelId,
    options
  ) => {
    const nodeId = createLevelId(levelId)
    const nodes = graph.getSuccessors(nodeId, 'ranks')
    return Promise.resolve(getLocationsFromNodes(nodes, options))
  },

  // Included for debugging purposes
  __graph: graph
})
