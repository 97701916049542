const { MEMBERSHIPS } = require('./constants')
const { BASIC_TIER_SUFFIX } = require('./../../tools/utils/constants')

const getMembershipTypeAwareServiceId = (serviceId, membershipType) => {
  const classicService = serviceId.replace(new RegExp(BASIC_TIER_SUFFIX + '$'), '')
  return membershipType === MEMBERSHIPS.BASIC ? classicService + BASIC_TIER_SUFFIX : classicService
}

module.exports = {
  getMembershipTypeAwareServiceId
}
