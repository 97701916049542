const { addDays } = require('date-fns')

const APPLICATION_STATUS = {
  NONE: 'none',
  PENDING: 'pending',
  EXPIRED: 'expired',
  APPROVED: 'approved'
}
const EXPIRES_IN_DAYS = 2

module.exports = class CreditApplicationApi {
  constructor (pgConnection, restAdapter) {
    this.pgConnection = pgConnection
    this.restAdapter = restAdapter
  }

  // Called from web
  async submit (formData) {
    return this.restAdapter.create('credit-application', formData)
  }

  // Called from agave
  async get (locationId) {
    const { rows: [{ loan_limit: loanLimit }] } = await this.pgConnection.query(`
      select loan_limit from avocado.data_location where uuid = $1
    `, [locationId])

    const { rows: [application] } = await this.pgConnection.query(`
      select * from avocado.credit_application where location_id = $1
    `, [locationId])

    let status = APPLICATION_STATUS.NONE
    if (application) {
      const expirationDate = addDays(new Date(), EXPIRES_IN_DAYS)
      if (application.submitted_at < expirationDate) {
        status = APPLICATION_STATUS.PENDING
      } else {
        status = APPLICATION_STATUS.EXPIRED
      }
    }
    if (loanLimit) {
      status = APPLICATION_STATUS.APPROVED
    }
    return { status, application }
  }

  // Called from agave
  async storeData ({ locationId, data, files }) {
    const { status } = await this.get(locationId)
    if (status === APPLICATION_STATUS.PENDING) {
      const err = new Error('There is already a pending credit application')
      err.status = 400
      throw err
    }
    if (status === APPLICATION_STATUS.APPROVED) {
      const err = new Error('Credit application has already been approved')
      err.status = 400
      throw err
    }

    // Find CRM location account id
    const { rows: [crmAccount] } = await this.pgConnection.query(`
      select *
      from avocado.data_freshsales
      where external_id = $1
        and entity_name = 'account'
    `, [locationId])
    if (!crmAccount) {
      throw new Error(`No crm account found for location ${locationId}`)
    }
    const crmAccountId = crmAccount.freshsales_id

    // Update CRM
    await Promise.all([
      // Upload field values to CRM
      await this.mainApi.freshsales.crmAdapter.updateAccount(crmAccountId, {
        custom_field: {
          'cf_ca_years_in_operation': data.businessYearsInOperation,
          'cf_ca_annual_gross_revenue': data.businessAnnualGross,
          'cf_ca_rc_number': data.RCNumber,
          'cf_ca_owner_birth_date': data.dateOfBirthOfOwner,
          'cf_ca_bvn_number': data.BVNNumber,
          'cf_ca_credit_references': data.contactInfoOfBusinessCreditReference
        }
      }),
      // Upload files to CRM
      ...files.map(file => (
        this.mainApi.freshsales.crmAdapter.uploadFiles(crmAccountId, {
          // set filename in crm to fieldname
          ...file,
          originalname: file.fieldname
        })
      ))
    ])

    // Save application data in postgres
    await this.pgConnection.query(`
      insert into avocado.credit_application (location_id, data)
        values ($1, $2)
      on conflict (location_id) do update
      set data = excluded.data,
          submitted_at = now()
    `, [locationId, data])

    // Build url for email link
    const crmUrl = `${this.mainApi.freshsales.crmAdapter.getFreshsalesUrl()}accounts/${crmAccountId}`
    return {
      crmUrl,
      status: APPLICATION_STATUS.PENDING
    }
  }
}
