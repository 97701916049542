import React from 'react'
import PropTypes from 'prop-types'

import withPageContext from '../../withPageContext'
import SubNavigation from '../../../SubNavigation'

const PageHeaderSubNavigation = (props) => (
  <div className='vs-page-header__sub-navigation'>
    <SubNavigation
      {...props}
    />
  </div>
)

PageHeaderSubNavigation.propTypes = {
  items: PropTypes.array.isRequired,
  linkRenderer: PropTypes.func
}

PageHeaderSubNavigation.defaultProps = {
  linkRenderer: ({ to, className, children, ...otherProps }) => (
    <a href={to} className={className} {...otherProps}>
      {children}
    </a>
  )
}

PageHeaderSubNavigation.displayName = 'Page.ComposableHeader.SubNavigation'

export default withPageContext(PageHeaderSubNavigation)
