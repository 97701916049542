module.exports = toStockCountId

const locationIdToProperties = require('./location-id-to-properties')

function toStockCountId (params) {
  const {
    location,
    service,
    reportingPeriod
  } = params

  /* istanbul ignore if */
  if (!location) {
    throw new Error('location parameter is required')
  }
  if (!service) {
    throw new Error('service parameter is required')
  }
  if (!reportingPeriod) {
    throw new Error('reportingPeriod parameter is required')
  }

  const locationProps = locationIdToProperties(location)

  let periodPrefix
  switch (service.program.reportingPeriod) {
    case 'weekly':
      periodPrefix = 'week'
      break
    case 'bimonthly':
      periodPrefix = 'bimonth'
      break
    case 'quarterly':
      periodPrefix = 'bimonth'
      break
    default:
      throw new Error('Unsupported reporting period type: ', service.program.reportingPeriod)
  }
  if (service.id !== 'program:immunization:service:immunization') {
    return `${location}:${periodPrefix}:${reportingPeriod}:${service.id}`
  }
  // Immunization has a special id format where zone and state come before
  // the date-period and lga, sdp, and name come after the date-period in the id.
  const joinParts = (...parts) => parts.filter(p => !!p).join(':')
  const periodPart = `${periodPrefix}:${reportingPeriod}`
  const namePart = !!locationProps.name && `name:${locationProps.name}`
  const countryPart = locationProps.country && `country:${locationProps.country}`
  switch (locationProps.level) {
    case 'national':
      return joinParts('national',
        periodPart,
        namePart)
    case 'zone':
      return joinParts(countryPart,
        `zone:${locationProps.zone}`,
        periodPart,
        namePart)
    case 'state':
      return joinParts(countryPart,
        `zone:${locationProps.zone}:state:${locationProps.state}`,
        periodPart,
        namePart)
    case 'lga':
      return joinParts(countryPart,
        `zone:${locationProps.zone}:state:${locationProps.state}`,
        periodPart,
        `lga:${locationProps.lga}`,
        namePart)
    case 'sdp':
      return joinParts(countryPart,
        `zone:${locationProps.zone}:state:${locationProps.state}`,
        periodPart,
        `lga:${locationProps.lga}:sdp:${locationProps.lga}`,
        namePart)
  }
}
