const XLSX = require('@sheet/core')
const keyBy = require('lodash/keyBy')
const {listAll: listProducts} = require('../product')
const {list: listPrograms} = require('../program')
const {new: createNewBatch} = require('../batch')
const {listAll: listConfigurations} = require('../configuration')
const {getByIds: listLocationsById} = require('../location')
const {isUnknownBatchId} = require('../batch/tools')
const {
  findInvalidHeaders,
  findInvalidRows,
  SHIPMENT_IMPORT_SHEET_NAME,
  rowsToShipmentSnapshots,
  parseRow,
  translateCodes
} = require('./tools/read/import')
const createBulkSnapshots = require('./shipment-bulk-create-snapshots')

// having both importShipmentsFromSheet & importShipments is so
// we can have one integration test with an excel file then
// test all our other cases with data that's easy to read
function importShipmentsFromSheet (
  state,
  buffer,
  {dryRun = false} = {}
) {
  const workbook = XLSX.read(buffer, {type: 'array'})
  const sheet = workbook.Sheets[SHIPMENT_IMPORT_SHEET_NAME]
  if (!sheet) {
    throw new Error(`Worksheet '${SHIPMENT_IMPORT_SHEET_NAME}' not found`)
  }

  const rows = XLSX.utils.sheet_to_json(sheet)

  return importShipments(state, {rows, dryRun})
}

async function importShipments (
  state,
  {rows, dryRun = false} = {}
) {
  const invalidHeaderError = findInvalidHeaders(rows[0])
  // we check headers first to see if we can even find error rows to help the user with
  if (invalidHeaderError) {
    throw new Error(invalidHeaderError)
  }

  const products = await listProducts(state)

  const translatedRows = translateCodes({rows, products})

  const programs = await listPrograms(state)
  const configurations = await listConfigurations(state)
  const locations = await listLocationsById(
    state,
    [...new Set(translatedRows.map(r => r.destinationId).filter(x => x))]
  )
  const invalidRows = findInvalidRows({
    rows: translatedRows, products, programs, configurations, locations
  })

  if (invalidRows.length) {
    const error = new Error()
    error.message = 'Invalid Row Found'
    error.invalidRows = invalidRows
    throw error
  }

  const parsedRows = translatedRows.map(parseRow)
  const batchesById = keyBy(parsedRows, 'batchId')

  await createAnyNewBatches(state, {batchesById, dryRun})

  const snapshotParams = rowsToShipmentSnapshots(parsedRows)
  if (dryRun) {
    return snapshotParams
  }

  return createBulkSnapshots(state, snapshotParams)
}

async function createAnyNewBatches (state, {batchesById, dryRun = false}) {
  const batchesToCheck = Object.values(batchesById)
    .filter(batch => !isUnknownBatchId(batch.batchId))

  // NB: if we open up manufacturers beyond chemonics, we can either add
  // validateManufacturer = false to options here for dynamic ones, or
  // hard code the new ones in batch-list-manufacturers
  return Promise.all(
    batchesToCheck.map(batch => createNewBatch(state, batch, {dryRun}))
  )
}

module.exports = {importShipments, importShipmentsFromSheet}
