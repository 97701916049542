const getDraft = require('./get-draft')
const { toDraftId, fromDraftId } = require('../tools/draft')
const updateStockCount = require('../../utils/update-stock-count')

module.exports = async (state, {
  reportId,
  productIds
}) => {
  let draft = await getDraft(state, {reportId, entityOptions: {rawDocs: true}})
  if (!draft) {
    throw new Error('Report draft with not found: ' + reportId)
  }
  const newStock = productIds.reduce((stock, id) => {
    stock[id] = {fields: {
      'field:standard-physical-count': {},
      'field:standard-opening-balance': {}
    }}
    return stock
  }, {})

  draft = updateStockCount(state, {stock: newStock}, draft)
  delete draft.isPristine

  draft._id = toDraftId(draft._id)
  await state.dal.report.write(state, draft)
  draft._id = fromDraftId(draft._id)

  return draft
}
