class EntityApi {
  constructor (adapter) {
    if (!adapter) {
      throw new Error('Entity Api usage: data adapter param is required')
    }

    this.adapter = adapter
  }

  // this is expected to throw a 404 error if the id does not exist
  // TODO: deprecate & convert this to .getOne
  async get (id) {
    return this.adapter.get(id)
  }

  // not a single list call in our app uses a limit or offset / skip
  // so leaving out any params for now.
  // heads up: this defaults to returning the couch/pouch error in a row
  // if there was an individual error.
  // TODO: deprecate & convert this to .getList
  async list () {
    return this.adapter.list()
  }

  // this expects `_find` options, e.g. {selector: {locationId: 'a'}, limit: 1}
  // which is pouch specific syntax, but I see less bugs in stick with that syntax as our
  // query default and write custom finds for other data adapters when they're needed.
  // TODO: deprecate & convert this to .getList with filtering
  async find (options) {
    return this.adapter.find(options)
  }

  // TODO: deprecate & convert this to .getMany (no one seems to use it? or very few)
  async getByIds (ids) {
    return this.adapter.getByIds(ids)
  }

  // this should throw if the entity already exists
  async create (entity) {
    return this.adapter.create(entity)
  }

  // this should throw if the entity does not already exist
  async update (entity) {
    return this.adapter.update(entity)
  }

  // TODO: deprecate & convert this to .delete (no one seems to use it)
  async remove (id) {
    return this.adapter.remove(id)
  }

  async createMany (entities) {
    return this.adapter.createMany(entities)
  }

  async updateMany (entities) {
    return this.adapter.updateMany(entities)
  }

  async getList ({ ordering, filter = {}, limit = 50, offset = 0 } = {}) {
    return this.adapter.getList({ ordering, filter, limit, offset })
  }

  async getOne (id, opts) {
    return this.adapter.getOne(id, opts)
  }

  async getMany (ids = []) {
    return this.adapter.getMany(ids)
  }

  async getManyReference (
    target,
    id,
    { ordering, filter = {}, limit = 50, offset = 0 } = {}
  ) {
    return this.adapter.getManyReference(target, id, {
      ordering,
      filter,
      limit,
      offset
    })
  }

  async delete (id) {
    return this.adapter.delete(id)
  }

  async deleteMany (ids = []) {
    return this.adapter.deleteMany(ids)
  }
}

module.exports = EntityApi
