import React from 'react'

const InputLabel = ({children}) => {
  return (
    <span className='vs-form-input-label'>
      {children}
    </span>
  )
}
InputLabel.displayName = 'Form.InputLabel'
export default InputLabel
