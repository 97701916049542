
const txnTypes = `
txn_types as (
  SELECT * FROM (VALUES
    ('payment', 'credit'),
    ('installment payment', 'credit'),
    ('pending invoice payment', 'pending-credit'),
    ('pending installment payment', 'pending-credit'),
    ('credit memo', 'credit'),
    ('loan', 'credit'),
    ('write-off', 'credit'),
    ('bonus', 'credit'),
    ('invoice', 'debit'),
    ('installment', 'debit'),
    ('refund', 'debit')
  ) tt(txn_type, posting_type)
)
`

module.exports = txnTypes
