import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'
import PriceDisplay from '../PriceDisplay'

import CardTabsCardHeader from './CardTabsCardHeader'
import Text from '../Text/Text'

const CardTitle = ({children}) => (
  <h2 className='tabs-card-body__title'>{children}</h2>
)

const CardText = ({children}) => (
  <div className='tabs-card-body__text'>{children}</div>
)

const CardValue = ({
  value,
  valueType,
  unit,
  subLine,
  country,
  thresholdStatus
}) => {
  const hasPercentSymbol = unit === '%'

  return (
    (
      <div
        className={classnames(
          'vs-u-align-center',
          'tabs-card-body__value'
        )}
      >
        <div>
          {valueType === 'price' ? (
            <PriceDisplay
              className='tabs-card-body__value-highlight'
              value={value}
              currencySymbol
              shorten
              country={country}
            />
          ) : (
            <span className={classnames(
              'tabs-card-body__value-highlight',
              `tabs-card-body__value-highlight--${thresholdStatus}`
            )}>
              {value}{hasPercentSymbol && unit}
            </span>
          )}
        </div>

        <div className={classnames(
          `tabs-card-body__value-highlight--${thresholdStatus}`
        )}>
          {(unit && !hasPercentSymbol && valueType !== 'price') && (
            <span
              className={classnames(
                'tabs-card-body__unit',
                { 'tabs-card-body__unit--small': unit.length > 10 }
              )}
            >
              {unit}
            </span>
          )}
          {subLine &&
            <Text size='large' bold>{subLine}</Text>
          }
        </div>
      </div>
    )
  )
}

class CardTabsCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      flipped: false
    }
  }

  static Title = CardTitle
  static Text = CardText
  static Value = CardValue

  handleCardFlip = () => {
    const { flipped } = this.state
    this.setState({
      flipped: !flipped
    })
  }

  render () {
    const { flipped } = this.state
    const {
      thresholdStatus,
      frontContent,
      frontHeaderText,
      backContent,
      backHeaderText,
      moreButtonProps,
      hideMoreButton,
      moreLabel,
      activeLabel,
      active,
      className,
      onClick
    } = this.props

    return (
      <div
        className={classnames(
          'tabs-card',
          {'tabs-card--tappable': onClick},
          {'tabs-card--flippable': backContent},
          { 'tabs-card--active': active },
          { 'tabs-card--flipped': flipped },
          className
        )}
        onClick={onClick}
      >
        <div className='tabs-card__wrapper'>
          <div className={classnames(
            'tabs-card-front',
            { 'tabs-card-front--active': active }
          )}>
            {(frontHeaderText || backContent) && (
              <CardTabsCard.Header
                text={frontHeaderText}
                thresholdStatus={thresholdStatus}
                flippable={backContent}
                onFlipClick={this.handleCardFlip}
              />
            )}
            <div className='tabs-card-body'>
              {frontContent}
            </div>
            <footer className='tabs-card-footer'>
              {active && moreButtonProps && !hideMoreButton &&
              <span className='tabs-card-footer__label'>{activeLabel}</span>}
              {!active && moreButtonProps && !hideMoreButton &&
                <Button
                  colorVariant='brand'
                  fill='outline'
                  {...moreButtonProps}
                >
                  {moreLabel}
                </Button>
              }
            </footer>
          </div>
          <div className={classnames(
            'tabs-card-back',
            { 'tabs-card-back--active': active }
          )}>
            <CardTabsCard.Header
              text={backHeaderText}
              flipped
              onFlipClick={this.handleCardFlip}
            />
            <section className='tabs-card-content'>
              {backContent}
            </section>
          </div>
        </div>
      </div>
    )
  }
}

CardTabsCard.propTypes = {
  frontContent: PropTypes.node.isRequired,
  frontHeaderText: PropTypes.string.isRequired,
  backContent: PropTypes.node,
  backHeaderText: PropTypes.string,
  thresholdStatus: PropTypes.oneOf(['ok', 'alert', 'warning', 'excessive', 'noData']),
  active: PropTypes.bool,
  moreLabel: PropTypes.string,
  activeLabel: PropTypes.string,
  moreButtonProps: PropTypes.object,
  hideMoreButton: PropTypes.bool
}

CardTabsCard.defaultProps = {
  moreLabel: 'More details',
  activeLabel: 'See details below'
}

CardTabsCard.Header = CardTabsCardHeader
CardTabsCard.displayName = 'CardTabs.Card'

export default CardTabsCard
