const kebabCase = require('lodash/kebabCase')
const { getProgramPeriod } = require('./../../tools')
const { bulkCreateServiceConfigs } = require('../../../service/api/write/bulk-create-service-configs')
const { update } = require('../../../user/api/write/save')

exports.createProgram = async function (state, params) {
  const programsDoc = await state.dal.program.getPrograms(state)
  if (programsDoc['programs'].find(element => element.name === params.name)) {
    const error = new Error(`program name already exists`)
    error.status = 404
    throw error
  }

  const user = state.user
  const username = user ? user.name : ''
  const options = params.options

  const { newProgramDoc, programServices } = prepareProgramDocToSave(programsDoc, params, username)
  const [newProgram] = newProgramDoc.programs.slice(-1)
  user.programs.push(newProgram.id)

  // Create service config docs
  await bulkCreateServiceConfigs(state, programServices, username)

  try {
    // Update program doc with new program and services
    await state.dal.program.save(state, newProgramDoc)

    // Update user doc with new program
    await update(state, user, options)
  } catch (error) {
    console.log(error)
  }
}

const prepareProgramDocToSave = (programsDoc, params, username) => {
  const { name, reportingPeriod } = params

  // transform name to id
  const trimmedName = name.trim()
  const transformedName = kebabCase(trimmedName.toLowerCase())
  const id = `program:${transformedName}`

  // Create default services warehouse and if selected program service
  const services = createDefaultServices({...params, id})

  // Generate program period based on selected reporting period
  const periods = getProgramPeriod(reportingPeriod)

  const newProgram = {
    id,
    name: trimmedName,
    fullName: trimmedName,
    reportingPeriod: reportingPeriod === 'quarterly' ? 'bimonthly' : reportingPeriod, // quarterly is also called bimonthly but with different period rules
    report: {
      periods
    },
    services
  }

  const programs = programsDoc.programs

  programs.push(newProgram)

  const newProgramDoc = {
    ...programsDoc,
    programs,
    updatedBy: username,
    updatedAt: new Date().toJSON()
  }

  return { newProgramDoc, programServices: services }
}

const createDefaultServices = ({name, servicesToAdd, id}) => {
  const services = [{
    name: 'Warehouse',
    fullName: `${name.trim()} Warehouse Service`,
    id: `${id}:service:warehouse`,
    isOptional: true,
    submitsMultiFieldCounts: true,
    tracksBatches: true,
    hasActualProducts: true,
    submitsWarehouseUnits: true
  }]

  if (servicesToAdd && servicesToAdd.length > 0) {
    servicesToAdd.forEach(({ serviceName }) => {
      const transformedId = kebabCase(serviceName.toLowerCase())
      const defaultService = {
        name: serviceName,
        fullName: serviceName,
        id: `${id}:service:${transformedId}`,
        isOptional: false,
        submitsMultiFieldCounts: true,
        tracksBatches: false,
        hasActualProducts: true,
        submitsExpiry: true
      }
      services.push(defaultService)
    })
  }

  return services
}
