const { v4: uuid } = require('uuid')
const { differenceInDays } = require('date-fns')
const { getTokenExpireDate } = require('./tools/utils')
const transformLocationToQboCustomer = require('./tools/transform-location-to-customer')

const QUICKBOOKS_API_VERSION = '57'

class QuickbooksInterface {
  constructor (
    pgConnection,
    quickbooksConfigs,
    isomorphicFetch,
    oAuthClient,
    logger
  ) {
    this.OAuthClient = oAuthClient
    this.logger = logger
    this.initialized = false
    this.pgConnection = pgConnection
    this.isomorphicFetch = isomorphicFetch
    this.billEmailBcc = {}
    this.companies = {
      NG: {
        companyId: quickbooksConfigs.nigeriaCompanyId,
        companyCode: 'NG',
        displayName: 'Nigeria'
      },
      KE: {
        companyId: quickbooksConfigs.kenyaCompanyId,
        companyCode: 'KE',
        displayName: 'Kenya'
      }
    }
    this.apiUrl = quickbooksConfigs.apiUrl
    this.clientId = quickbooksConfigs.clientId
    this.apiVersion = QUICKBOOKS_API_VERSION
    this.environment = quickbooksConfigs.environment
    this.transformLocationToQboCustomer = transformLocationToQboCustomer
    try {
      this.accountIds = JSON.parse(quickbooksConfigs.accountIds)
    } catch (e) {
      this.logger.warn(`Can't parse accountIds`, quickbooksConfigs.accountIds)
    }

    if (quickbooksConfigs.billEmailBcc) {
      try {
        this.billEmailBcc = JSON.parse(quickbooksConfigs.billEmailBcc)
      } catch (e) {
        this.logger.warn(`Can't parse billEmailBcc`, quickbooksConfigs.billEmailBcc, e)
      }
    }
    this.clientSecret = quickbooksConfigs.clientSecret
  }

  // Decorate every quickbooks company/realm in this.companies
  // with quickbooks API access tokens (they expire every hour).
  async init () {
    this._initializedData = this._initializedData || (async () => {
      const rows = await this.getTokenDbRows()

      if (!rows.length) {
        throw new Error('No access quickbooks tokens found.')
      }
      for (const companyConfig of Object.values(this.companies)) {
        const databaseRow = rows.find(row => row.company_code === companyConfig.companyCode)
        if (!databaseRow) {
          throw new Error(
            `No quickbookstoken row found for row ${companyConfig.companyCode}`
          )
        }

        const newToken = await this.requestNewAccessToken({ databaseRow, companyConfig })

        this.companies[companyConfig.companyCode].accessToken = newToken.access_token

        // No need to update the DB's refresh_token if quickbooks is responding with the same one.
        if (newToken.refresh_token === databaseRow.refresh_token) {
          try {
            const accessTokenExpiredDate = getTokenExpireDate(newToken.expires_in)
            await this.pgConnection.query(
              `UPDATE "avocado"."data_quickbookstoken" SET "access_token" = $1, updated_at = $2, "access_token_expiry" = $3 WHERE "id" = $4`,
              [newToken.access_token, new Date().toJSON(), accessTokenExpiredDate, databaseRow.id]
            )
          } catch (error) {
            this.logger.warn(
              `Error updating access token, row id ${databaseRow.id}`,
              error
            )
          }
          continue
        }

        try {
          const insertValues = [
            uuid(),
            new Date().toJSON(),
            new Date().toJSON(),
            databaseRow.company_code,
            newToken.refresh_token,
            newToken.access_token,
            getTokenExpireDate(newToken.expires_in),
            getTokenExpireDate(newToken.x_refresh_token_expires_in)
          ]

          await this.pgConnection.query({
            text: `
          INSERT INTO avocado.data_quickbookstoken (id, created_at, updated_at, company_code, refresh_token, access_token, access_token_expiry, refresh_token_expiry)
          VALUES ($1, $2, $3, $4, $5, $6, $7, $8);`,
            values: insertValues
          })
          // one new token inserted we don't need store previous one it's redundant data and adding extra rows
          await this.pgConnection.query('DELETE FROM "avocado"."data_quickbookstoken" WHERE "id" = $1', [databaseRow.id])
        } catch (err) {
          this.logger.warn(
            `Error updating refresh token in Field's postgres db, company code: ${databaseRow.company_code}, quickbookstoken row id ${databaseRow.company_code}`,
            err
          )

          throw err
        }
      }
    })()
    return this._initializedData
  }

  async requestNewAccessToken ({ databaseRow, companyConfig }) {
    const expiresIn = differenceInDays(databaseRow.refresh_token_expiry, new Date())
    const oauthClient = new this.OAuthClient({
      clientId: this.clientId,
      clientSecret: this.clientSecret,
      environment: this.environment,
      redirectUri: 'https://www.getpostman.com/oauth2/callback',
      token: {
        refresh_token: databaseRow.refresh_token,
        x_refresh_token_expires_in: expiresIn
      }
    })

    /*
     * Refersh token is valid for 100 days if expire date left one day need update refresh token.
     * Once refresh token updated previouse one will be invalid
     * Otherwise lets use refersh token to get new acess_token
     */
    if (expiresIn <= 1) {
      try {
        const authResponse = await oauthClient.refreshUsingToken(
          databaseRow.refresh_token
        )
        return authResponse.getJson()
      } catch (err) {
        this.logger.warn(
          `Error fetching refresh token for company id ${databaseRow.company_code} ${companyConfig.companyId}, cannot proceed`,
          err
        )
        throw err
      }
    }
    try {
      const acessTokenUpdateResponse = await oauthClient.refresh()
      return acessTokenUpdateResponse.json
    } catch (err) {
      this.logger.warn(
        `Error refresh access token for company id ${databaseRow.company_code} ${companyConfig.companyId}, cannot proceed`,
        err, {
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          environment: this.environment,
          redirectUri: 'https://www.getpostman.com/oauth2/callback',
          token: {
            refresh_token: databaseRow.refresh_token,
            x_refresh_token_expires_in: expiresIn
          }
        }
      )
      throw err
    }
  }

  async getTokenDbRows () {
    const { rows } = await this.pgConnection.query({
      text: `
        SELECT *
        FROM avocado.data_quickbookstoken quickbooks_token
        WHERE NOT EXISTS (
          SELECT *
          FROM avocado.data_quickbookstoken quickbooks_token_2
          WHERE quickbooks_token_2.company_code = quickbooks_token.company_code
            AND quickbooks_token_2.updated_at > quickbooks_token.updated_at
          );`
    })

    if (!rows.length) {
      throw new Error('No access quickbooks tokens found.')
    }
    return rows
  }

  async doRequest (companyConfig, endpoint, method = 'GET', body, headers) {
    const url = `${this.apiUrl}/v3/company/${companyConfig.companyId}/${endpoint}`

    const options = {
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        Authorization: `Bearer ${companyConfig.accessToken}`,
        ...headers
      },
      method
    }

    if (body) {
      Object.assign(options, {body: JSON.stringify(body)})
    }

    const response = await this.isomorphicFetch(url, options)
    if (!response.ok) {
      const errorBody = await response.text()
      throw new Error(
        `Calling ${endpoint} for company ${companyConfig.companyId} failed with status code ${response.status} (${response.statusText}): ${JSON.stringify(errorBody)}`
      )
    }
    // if header is not application/json we are considering it's a file/pdf for download
    if (options.headers['content-type'] !== 'application/json') {
      return response.buffer()
    }
    return response.json()
  }

  async get (companyCode, endpoint, headers = {}) {
    await this.init()

    const companyConfig = this.companies[companyCode]
    if (!companyConfig) {
      throw new Error(
        `No company found for given companyCode param: ${companyCode}`
      )
    }

    return this.doRequest(companyConfig, endpoint, 'GET', false, headers)
  }

  async post (companyCode, endpoint, body) {
    await this.init()
    const companyConfig = this.companies[companyCode]
    if (!companyConfig) {
      throw new Error(
        `No company found for given companyCode param: ${companyCode}`
      )
    }

    return this.doRequest(companyConfig, endpoint, 'POST', body)
  }
}

module.exports.transformLocationToQboCustomer = transformLocationToQboCustomer

module.exports.QuickbooksInterface = QuickbooksInterface
