// Format the date as YYYY-MM-DD in UTC
// We do not use `date-fns.format`, because the formatted string will be used to construct a
// date object. If we would use `date-fns.format`, the formatted string would contain a
// local date and if we then pass that formatted string to `new Date(str)`, it would interpret
// the string as UTC and we would have added/subtracted a couple of hours depending on the timezone.
// To not run into that problem we format as UTC in the first place.
const formatDate = date => {
  date = new Date(date)
  return date.toJSON().split('T')[0]
}

const buildPath = segments => {
  for (const s of segments) {
    if (typeof s !== 'string') {
      console.error('URL segments contain non string value:', segments)
    }
  }
  return '/' + segments.join('/')
}

export const buildProgramsUrl = ({locationId, date}) =>
  buildPath(['programs', locationId, formatDate(date)])

export const buildLocationsUrl = ({locationId, date, programId}) =>
  buildPath(['locations', locationId, formatDate(date), programId])

export const buildServicesUrl = ({locationId, date, programId, reportLocationId}) =>
  buildPath(['services', locationId, formatDate(date), programId, reportLocationId])

export const buildOpenReportUrl = ({reportId, productId, addBatch}) => {
  const segments = ['report', reportId]
  if (productId) {
    segments.push(productId)
    if (addBatch) {
      segments.push('add-batch')
    }
  }
  return buildPath(segments)
}

export const buildViewReportUrl = ({reportId, productId}) => {
  const segments = ['view', reportId]
  if (productId) {
    segments.push(productId)
  }
  return buildPath(segments)
}

export const buildFindReportUrl = ({locationId, serviceId, date, productId = null, partialCount}) => {
  const segments = ['find-report', locationId, serviceId, formatDate(date)]
  if (productId) {
    segments.push(productId)
  }

  if (partialCount) {
    segments.push(partialCount)
  }

  return buildPath(segments)
}

export const buildConfirmationUrl = ({reportId}) =>
  buildPath(['confirmation', reportId])

export const buildSuccessUrl = ({locationId}) =>
  buildPath(['success', locationId])

export const buildAddProductsUrl = ({reportId}) =>
  buildPath(['add-products', reportId])

export const buildLedgerUrl = ({locationId, serviceId, productId}) =>
  buildPath(['ledger', serviceId, locationId, productId])

export const buildSalesStatementUrl = ({locationId, serviceId, date, reportId}) =>
  buildPath(['sales-statement', locationId, serviceId, date, reportId])

export const buildInventoryStatementUrl = ({locationId, serviceId, date, reportId}) =>
  buildPath(['inventory-statement', locationId, serviceId, date, reportId])
