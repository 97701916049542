import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ConnectivityStatus = ({
  isOnline,
  compact,
  onLight
}) => {
  return (
    <span className={classnames(
      'vs-connectivity-status',
      {'vs-connectivity-status--offline': !isOnline},
      {'vs-connectivity-status--compact': compact},
      {'vs-connectivity-status--on-light': onLight}
    )}>
      <span className='vs-connectivity-status__icon' />
      <span className='sync-progress__connectivity-label'>
        {isOnline ? 'Online' : 'Offline'}
      </span>
    </span>
  )
}

ConnectivityStatus.propTypes = {
  /**
   * is the user online. Will usually come from the withOffline hoc
   */
  isOnline: PropTypes.bool.isRequired,
  /**
   * decides if label is shown or not
   */
  compact: PropTypes.bool,
  /**
   * decides the colors, set if the background is light
   */
  onLight: PropTypes.bool
}

ConnectivityStatus.defaultProps = {
  compact: false,
  onLight: false
}

export default ConnectivityStatus
