/*
 * NOTE: the code for get-ledger-balance is in the get-ledger-balance2.js file
 *
 * this file used to have the previous version of that code
 * and it's kept around to keep git history of some other stuff
 */
module.exports = {
  getLatestReport,
  getLedgerDate
}

const get = require('lodash/get')
const isShipmentRelevant = require('./is-shipment-relevant')
const { sortByLatestReport } = require('./date-utils')

function getLatestReport (reports, date, includePartialCount) {
  if (!reports) return null

  let filtered = []

  // This is for server side ooc_sellout calculator
  // We need to include partial counts in them
  if (includePartialCount) {
    filtered = reports.filter(r => {
      const period = get(r, 'date.period', {})
      const effectiveEnd = new Date(period.effectiveEnd).toJSON()

      return effectiveEnd && effectiveEnd <= date
    })
  }

  if (!includePartialCount) {
    filtered = reports.filter(r => {
      if (r.partialCount) {
        return false
      }

      return r.submittedAt && r.submittedAt <= date
    })
  }

  const sorted = filtered.sort(sortByLatestReport)

  return sorted.length ? sorted[sorted.length - 1] : null
}

function getLedgerDate (params) {
  const {
    location, date, reports, shipments, includeScheduledOutbound
  } = params

  const latestReport = getLatestReport(reports, date)
  const lastReportDate = get(latestReport, 'submittedAt')
  const lastReportId = get(latestReport, '_id')
  const lastShipmentDate = shipments
    .filter(shipment => isShipmentRelevant({ shipment, location, endDate: date, startDate: lastReportDate, includeScheduledOutbound }))
    .map(shipment => isShipmentRelevant.getShipmentDate(location, includeScheduledOutbound, shipment))
    .sort()
    .pop()

  // If there is a shipment date, isShipmentRelevant will
  // already have filtered those who are earlier than latestReportDate
  return {
    lastChangeDate: lastShipmentDate || lastReportDate,
    stockCountId: lastReportId
  }
}
