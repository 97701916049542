const { parse } = require('../../tools/smart-id')
const { validBatchSubmitData } = require('../../validate/validators')

// Called by the NHLMIS (PSM) REST API
// Original commits: https://github.com/fielded/fs-backend/blob/master/functions/api_batch_submit/src/index.js#L66
module.exports = upsert
async function upsert (fsApi, body, alias = 'one', updatedAliasId) {
  // Validate post against schema:
  const validationErrors = validBatchSubmitData(body)
  if (validationErrors && validationErrors.length) {
    const error = new Error('Validating the payload against the defined schema failed.')
    error.status = 400
    error.errors = validationErrors
    throw error
  }

  const batch = prepareBatchSubmitParams(body)

  const aliasOptions = alias !== 'none'
    ? {resolveAlias: {[alias]: true}}
    : {}

  try {
    await fsApi.product.get(batch.productId, aliasOptions)
  } catch (_) {
    const err = new Error(`Product "${batch.productId}" could not be found.`)
    err.status = 400
    return Promise.reject(err)
  }

  const allowUpdates = !!updatedAliasId
  const res = await fsApi.batch.new(
    batch,
    {
      ...aliasOptions,
      allowUpdates,
      updatedAlias: {[alias]: updatedAliasId},
      validateManufacturer: false
    }
  )

  res.id = res._id
  delete res._id

  if (alias !== 'none' && res.alias && res.alias[alias]) {
    const {manufacturer, batchNo} = parse(res.id)
    res.id = `product:${res.alias[alias]}:manufacturer:${manufacturer}:batchNo:${batchNo}`
  }
  return res
}

// Slightly different from batchGet prepareBatchParams (e.g. no string replace)
function prepareBatchSubmitParams (params) {
  let { productId, manufacturer, batchNo, expiry } = params

  // Prepare input data:
  productId = decodeURIComponent(productId)
  manufacturer = decodeURIComponent(manufacturer)
  batchNo = decodeURIComponent(batchNo) // batchNo allows spaces, etc

  return {
    productId,
    manufacturer,
    batchNo,
    expiry
  }
}
