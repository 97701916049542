const get = require('lodash/get')
const PGAdapter = require('./../common/pg-adapter')
const REMITA_CONFIG = require('./data-access/remita/config')
const SAFARICOM_CONFIG = require('./data-access/safaricom/config')
const PAYSTACK_CONFIG = require('./data-access/paystack/config')
const PAYMENT_DEBIT_TABLE_NAME = 'avocado.data_paymentdebit'
const PAYMENT_DEBIT_COLUMNS = [
  'created_at',
  'updated_at',
  'id',
  'data',
  'amount',
  'created_by',
  'payment_method_id',
  'reference'
]
const PAYMENT_METHOD_REQUEST_TABLE_NAME = 'avocado.data_paymentproviderrequest'
const PAYMENT_METHOD_REQUEST_COLUMNS = [
  'created_at',
  'updated_at',
  'id',
  'payment_provider',
  'type',
  'data',
  'created_by',
  'updated_by',
  'payment_debit_id',
  'payment_method_id'
]

class PaymentDebitAdapter extends PGAdapter {
  constructor (pgConnection, username, remitaInterface, safaricomInterface, paystackInterface, paymentMethod) {
    super(
      pgConnection,
      PAYMENT_DEBIT_TABLE_NAME,
      username,
      PAYMENT_DEBIT_COLUMNS
    )
    this.remitaInterface = remitaInterface
    this.safaricomInterface = safaricomInterface
    this.paystackInterface = paystackInterface
    this.paymentMethod = paymentMethod
    this.paymentMethodRequest = new PGAdapter(this.pgConnection, PAYMENT_METHOD_REQUEST_TABLE_NAME, this.username, PAYMENT_METHOD_REQUEST_COLUMNS)
    this.paymentDebit = new PGAdapter(this.pgConnection, PAYMENT_DEBIT_TABLE_NAME, this.username, PAYMENT_DEBIT_COLUMNS)
  }

  async getLocationDebits (locationId) {
    const query = `SELECT * FROM ${PAYMENT_DEBIT_TABLE_NAME} WHERE "data"->>'locationId' = $1 AND ("data" ->> 'voided')::boolean IS NOT true`
    const { rows } = await this.pgConnection.query(query, [locationId])
    return rows
  }

  async create (data) {
    // form `data` as POSTed from FE
    const paymentProvider = data.data.payment_provider

    switch (paymentProvider) {
      case REMITA_CONFIG.PROVIDER_CODE : {
        // create placeholder debit record in our DB
        data.data.providerAPIParams.requestId = this.remitaInterface.generateRequestId()
        const debitRes = await this.paymentDebit.create(data)

        // make the request to remita...
        const response = await this.remitaInterface.createDebit(data.data.providerAPIParams)

        // ...and then update the debit record with the response
        const update = {
          id: debitRes.id,
          data: {
            ...debitRes.data,
            isActive: response.ok,
            remitaResponse: response
          }
        }
        if (response.body.mandateId) {
          update.data.mandateId = response.body.mandateId
        }
        let updateResponse = await this.paymentDebit.update(update)
        return updateResponse
      }

      case SAFARICOM_CONFIG.PROVIDER_CODE : {
        const debitRes = await this.paymentDebit.create(data)

        const response = await this.safaricomInterface.createDebit(data.data.providerAPIParams)

        const update = {
          id: debitRes.id,
          data: {
            ...debitRes.data,
            isActive: response.ok,
            safaricomResponse: response
          }
        }
        let updateResponse = await this.paymentDebit.update(update)
        return updateResponse
      }

      case PAYSTACK_CONFIG.PROVIDER_CODE(paymentProvider): {
        const paymentMethod = await this.paymentMethod.getOne(data.payment_method_id)
        const autorizationCode = get(paymentMethod, 'data.authorization.authorization_code')
        const email = get(paymentMethod, 'data.customer.email')
        const paystackData = {
          authorization_code: autorizationCode,
          email: email,
          amount: data.amount * 100, // convert amount to cents
          metadata: {
            custom_fields: [
              {
                display_name: 'Field Reference',
                variable_name: 'field_reference',
                value: data.reference
              }
            ]
          }
        }
        const country = paymentProvider.split('_')[1].toLowerCase()
        const response = await this.paystackInterface[country].chargeAuthorization(paystackData)
        if (response.status) {
          const create = {
            amount: data.amount,
            reference: data.reference,
            payment_method_id: data.payment_method_id,
            data: {
              ...data.data,
              isActive: response.status,
              paystackResponse: response.data
            }
          }
          return this.paymentDebit.create(create)
        }
        return false
      }

      default : return data
    }
  }

  async delete (debit) {
    const { data } = debit
    const paymentProvider = data.payment_provider
    switch (paymentProvider) {
      case REMITA_CONFIG.PROVIDER_CODE:
        const { mandateId, requestId, transactionRef } = data.remitaResponse.body
        const { body } = await this.remitaInterface.cancelDebit({
          mandateId,
          requestId,
          transactionRef
        })
        const update = {
          id: debit.id,
          data: {
            ...data,
            voided: body.statuscode === '00',
            cancelResponse: body
          }
        }
        return this.paymentDebit.update(update)
      default :
        return debit
    }
  }

  async update (data) {
    const { debit } = data
    if (debit.safaricomResponse) {
      const CheckoutRequestID = debit.safaricomResponse.CheckoutRequestID

      const response = await this.safaricomInterface.getDebitStatus({CheckoutRequestID})

      const debitId = debit.id
      delete debit.id

      const update = {
        id: debitId,
        data: {
          ...debit
        }
      }
      if (response) {
        Object.assign(update.data, {
          safaricomDebitStatus: response
        })
      }

      let updateResponse = await this.paymentDebit.update(update)
      return updateResponse
    } else if (debit.remitaResponse) {
      let requestId
      let mandateId

      try {
        requestId = debit.providerAPIParams.requestId || debit.remitaResponse.body.requestId
        mandateId = debit.providerAPIParams.mandateId || debit.remitaResponse.body.mandateId
      } catch (e) {
        throw new Error('Required info unavailable for this debit. Please contact support.')
      }

      if (!requestId || !mandateId) {
        throw new Error('Required info unavailable for this debit. Please contact support.')
      }

      const response = await this.remitaInterface.getDebitStatus({mandateId, requestId})

      const {
        id,
        isActive,
        locationId,
        payment_provider, // eslint-disable-line camelcase
        providerAPIParams
      } = debit

      const update = {
        id,
        data: {
          isActive,
          locationId,
          payment_provider,
          providerAPIParams
        }
      }

      if (response) {
        Object.assign(update.data, {
          remitaResponse: response
        })
      }

      let updateResponse = await this.paymentDebit.update(update)
      return updateResponse
    } else {
      return debit
    }
  }
}

module.exports = PaymentDebitAdapter
