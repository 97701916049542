const MAX_REMITA_FEE = -1076 // remita fee is the leftover from transaction so it always negative

const MATCHING_STATUSES = {
  matched: 'Matched',
  notMatched: 'Not Matched'
}

const BOOKING_STATUSES = {
  booked: 'Booked',
  notBooked: 'Not Booked'
}

const FAILED_BOOKING_REASONS = {
  remitaFeeExceeded: `Remita fee exceeds ${MAX_REMITA_FEE}`
}

const FAILED_MATCHING_REASONS = {
  unknownNarration: 'Unknown Narration',
  exceptionError: 'Exception error',
  paymentDebitNotFound: 'Payment Debit not found',
  invoiceNotFound: 'QuickBooks invoice not found',
  multipleLocationWithSameCode: 'Multiple locations with same code',
  invoicePaid: 'Invoice paid',
  invalidInvoice: 'Amount can\'t be less then paid amount',
  noPaymentPlan: 'No existing PP',
  noPaymentInstallment: 'No existing Payment Installment',
  multiplePaymentPlans: 'Multiple PPs'
}

module.exports = {
  MATCHING_STATUSES,
  FAILED_MATCHING_REASONS,
  BOOKING_STATUSES,
  MAX_REMITA_FEE,
  FAILED_BOOKING_REASONS
}
