async function getSupplierLedger ({
  api,
  location
}) {
  try {
    // Get supplier ledgers
    return await api.order.getLedgerForSupplier({
      supplier: location,
      locationId: location._id
    })
  } catch (error) {
    console.error('failed to get ledger with error', error)
    return null
  }
}

export {
  getSupplierLedger
}
