import React, { Fragment, useContext } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { BottomDrawer, Button, Checkbox } from '@fielded/shared-ui'

import { AuthenticationContext } from './PSMDeliveryAuthenticationContainer'
import PSMDeliveryMap from './PSMDeliveryMap/PSMDeliveryMap'
import PSMDeliveryOTP from './PSMDeliveryOTP/PSMDeliveryOTP'
import DriverDeliveryFooterCard from '../../component/DriverDeliveryFooterCard'
import PSMDeliveryAuthenticationModal from './PSMDeliveryAuthenticationModal'

const PSMDeliveryAuthentication = () => {
  const {
    otp,
    isBottomDrawerOpen,
    isVerifying,
    isSaving,
    authType,
    authTypeInfo,
    isLocationAuthType,
    isOTPAuthType,
    authOptions,
    direction,
    withAlternateText,
    isModalOpen,
    isDeniedPermission,
    isWithinLocationRadius,
    onClose,
    onCloseModal,
    onHandleCheckboxToggle,
    onAuthenticate,
    onHandleCommentChange,
    onToggleView
  } = useContext(AuthenticationContext)

  const { location: locationOption, otp: otpOption } = authOptions

  // Checkbox info
  const hasLocationAuthError = isLocationAuthType && locationOption.hasError
  const isOtpInputDisabled = isOTPAuthType && otpOption.isUnverified

  // Disable validation button
  const shouldVerifyLocation = locationOption.hasError && !locationOption.isUnverified
  const hasOTPPassword = !!otp.join('').length
  const shouldVerifyOTP = !(hasOTPPassword || otpOption.isUnverified)

  let buttonText = !isWithinLocationRadius && isLocationAuthType ? 'Continue with delivery' : authTypeInfo['buttonText']

  return (
    <Fragment>
      <BottomDrawer
        className='delivery-auth__bottom-drawer'
        customHeaderText={authTypeInfo.title}
        isOpen={isBottomDrawerOpen}
        onClose={onClose}
        withMultiscreen
      >
        <section className='delivery-auth__wrapper vs-u-align-center'>
          <section className='delivery-auth__section'>
            <SwitchTransition mode='out-in'>
              <CSSTransition
                timeout={300}
                classNames='slide'
                key={authType}
              >
                <section className={direction}>
                  {isLocationAuthType && (
                    <PSMDeliveryMap />
                  )}

                  {isOTPAuthType && (
                    <PSMDeliveryOTP
                      isOtpInputDisabled={isOtpInputDisabled}
                    />
                  )}
                </section>
              </CSSTransition>
            </SwitchTransition>
          </section>

          <section className='delivery-auth__footer vs-u-align-center'>
            {(hasLocationAuthError || isOTPAuthType) && (
              <section className='delivery-auth__section'>
                <section className='delivery-auth__checkbox  vs-u-justify-center'>
                  <Checkbox.Stateless
                    label={isDeniedPermission ? 'Proceed without verification' : authTypeInfo.checkboxText}
                    checked={isLocationAuthType ? locationOption.isUnverified : otpOption.isUnverified}
                    onChange={event => onHandleCheckboxToggle(event)}
                  />
                </section>
              </section>
            )}

            <DriverDeliveryFooterCard>
              <Button
                className='delivery-auth__footer-button'
                colorVariant='brand'
                fill='full'
                loading={isVerifying}
                disabled={isLocationAuthType ? shouldVerifyLocation : shouldVerifyOTP}
                onClick={() => onAuthenticate(authType)}
              >
                {withAlternateText ? authTypeInfo['alternateButtonText'] : buttonText}
              </Button>
            </DriverDeliveryFooterCard>
          </section>
        </section>
      </BottomDrawer>

      <PSMDeliveryAuthenticationModal
        isModalOpen={isModalOpen}
        isSaving={isSaving}
        isLocationAuthType={isLocationAuthType}
        locationOption={locationOption}
        otpOption={otpOption}
        onCloseModal={onCloseModal}
        onHandleCommentChange={onHandleCommentChange}
        onToggleView={onToggleView}
      />
    </Fragment>
  )
}

export default PSMDeliveryAuthentication
