const get = require('lodash/get')

async function createEmptyFullCount (state, { api, location, doNotMergeCounts = false }) {
  // Using fsid and service for the location create a new full count with either a ledger or an empty object
  const serviceId = get(location, 'programs[0].services[0].id')
  const programId = get(location, 'programs[0].id')

  const service = await api.service.get(serviceId)
  const date = new Date().toJSON()
  const period = await api.report.period.get({
    programId,
    date,
    isEffectiveDate: false
  })

  // There could be multiple service changes within the same reporting period
  // Or a change that happened mid reporting period
  // This is to make sure we get the current service we're on after updating
  period.effectiveStartDate = date

  let params = {
    locationId: location._id,
    service,
    period,
    stock: {},
    partialCount: false,
    submittedAt: date,
    documents: [],
    doNotMergeCounts
  }

  return params
}

module.exports = createEmptyFullCount
