class QboTaxCodeNotFoundError extends Error {
  constructor (name) {
    super('Quickbooks tax code not found')
    this.name = name
  }
}

class QboTaxRateNotFoundError extends Error {
  constructor (name) {
    super('Quickbooks tax rate not found')
    this.name = name
  }
}

module.exports = {
  QboTaxCodeNotFoundError,
  QboTaxRateNotFoundError
}
