import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'

import PageContext from './PageContext'

export default function withPageContext (Component) {
  const ComponentWithPageContext = (props) => (
    <PageContext.Consumer>
      {pageConfig => <Component {...props} {...pageConfig} />}
    </PageContext.Consumer>
  )
  ComponentWithPageContext.displayName = `withPageContext(${Component.displayName})`
  return hoistNonReactStatics(ComponentWithPageContext, Component)
}
