import React from 'react'
import PropTypes from 'prop-types'

import { FieldContext } from '../'
import { requiredValidationErrorMessage } from '../../utils'
import FormFieldRegistrationManager from '../../FormFieldRegistrationManager'
import QuantityInput from '../../../QuantityInput'

/**
 *  Wrapper for input
 *
 *  Adds additional functionality such as validation
 */
const Quantity = ({
  required,
  validate,
  onValueChange,
  value,
  defaultValue,
  ...otherProps
}) => {
  const valueOrZero = value === 0 ? '0' : value
  const defaultValueOrZero = defaultValue === 0 ? '0' : defaultValue

  return (
    <FieldContext.Consumer>
      {(field) => (
        <FormFieldRegistrationManager
          register={() => {
            field.register({
              required,
              validate,
              requiredValidator: async (value) => {
                if (!value && value !== 0) {
                  return required && requiredValidationErrorMessage(required)
                }
              },
              value: valueOrZero,
              defaultValue: defaultValueOrZero,
              ...otherProps
            })
          }}
          deregister={() => field.deregister()}
        >
          <QuantityInput
            name={field.props.fieldName}
            className='vs-form-field__input'
            hasError={field.hasErrors()}
            value={field.props.value}
            defaultValue={field.props.defaultValue}
            onQuantityChange={(value) => {
              field.handleOnChange(value)
              if (typeof onValueChange === 'function') {
                onValueChange(value)
              }
            }}
            required={required}
            {...otherProps}
          />
        </FormFieldRegistrationManager>
      )}
    </FieldContext.Consumer>
  )
}

Quantity.propTypes = {
  /**
   * Whether the field is required, optionally pass a string to define a custom
   * validation error message
   */
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  /**
   * A custom validation function
   */
  validate: PropTypes.func,

  /**
   * Runs when field value changes (returns new value)
   */
  onValueChange: PropTypes.func,

  /**
   * Field value (will be handled by form)
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * Initial value if form needs to be reset
   */
  defaultValue: PropTypes.number,

  /**
   * Text to display when no value is present in the field
   */
  placeholder: PropTypes.string
}

Quantity.defaultProps = {
  required: false,
  validate: undefined,
  onValueChange: undefined,
  value: undefined,
  defaultValue: undefined,
  placeholder: undefined
}

Quantity.displayName = 'Form.Field.Quantity'

export default Quantity
