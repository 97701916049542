import { DIRECT_ORDER_TYPES } from '@fielded/fs-api/lib/allocation/config'

// Splits a shipments counts into separate shipments depending on payment type
export const preparePaymentGroups = (shipment, paymentTypes) => {
  if (paymentTypes.length === 0) {
    return shipment
  }

  const paymentGroups = paymentTypes.map((paymentType) => {
    return {
      shipment: shipmentPerPaymentType(shipment, paymentType),
      paymentType
    }
  })
    // If no items in the particular type, no shipment is returned
    // so we should ignore that type
    .filter(x => x.shipment)

  // This should happen in case no shipment item had paymentTypes -
  // this is the case for old shipments created before paymentTypes property was added.
  if (!paymentGroups.length) {
    return shipment
  }

  return paymentGroups
}

const shipmentPerPaymentType = (shipment, paymentType) => {
  // we take a shipment and if the paymentType on the item matches, we return it.
  const counts = Object.keys(shipment.counts).reduce((acc, batchId) => {
    const shipmentPaymentType = shipment.counts[batchId].paymentType || DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL
    if (shipmentPaymentType === paymentType) {
      acc[batchId] = shipment.counts[batchId]
    }
    return acc
  }, {})

  // We only want to return the shipment if there are any items per paymentType
  if (Object.keys(counts).length === 0) {
    return
  }

  return {...shipment, counts}
}
