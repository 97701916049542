const { PouchAdapter } = require('../../../common')
const schema = require('./schema')

// TODO: refactor existing product pouch dal methods to live here.
class ProductPouchAdapter extends PouchAdapter {
  constructor (user, productDB, logger) {
    super(user, schema, productDB, logger)
  }
}

module.exports = ProductPouchAdapter
