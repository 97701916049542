const {isFuture, addWeeks} = require('date-fns')
const get = require('lodash/get')

/**
 * @param location
 * @returns {boolean} whether deposit has been paid
 *
 * REMEMBER paid could still be expired
 * @see isInServicePeriod
 */
function isServiceFeeDepositPaid (location) {
  return !!get(location, 'additionalData.serviceFeeDeposit')
}

/**
 * @param location
 * @returns {boolean|Date} false if incalculable, otherwise date of when free period ends
 */
function getFreePeriodExpiryDate (location) {
  const {serviceStartDate: startDate, serviceFreeWeeks: freeWeeks} = location.additionalData

  if (!(startDate && freeWeeks && freeWeeks.endsWith(' weeks'))) {
    return false
  }

  // parseInt("52 weeks") => 52. nice!
  return addWeeks(new Date(startDate), parseInt(freeWeeks, 10))
}

/**
 * @param location
 * @returns {boolean}
 */
function isInFreePeriod (location) {
  const expiry = getFreePeriodExpiryDate(location)
  return expiry && isFuture(expiry)
}

/**
 * @param location
 * @returns {boolean|Date} false if incalculable, otherwise date of when pre-paid period ends
 */
function getServiceFeeExpiryDate (location) {
  const {serviceStartDate: startDate, serviceFeeDeposit: paidWeeks, serviceFreeWeeks: freeWeeks} = location.additionalData

  if (!(startDate && paidWeeks && freeWeeks && freeWeeks.endsWith(' weeks'))) {
    return false
  }

  const paidWeeksInt = parseInt(paidWeeks, 10)
  const freeWeeksInt = parseInt(freeWeeks, 10)
  const totalWeeksInt = paidWeeksInt + freeWeeksInt

  return addWeeks(new Date(startDate), totalWeeksInt)
}

/**
 * @param location
 * @returns {boolean} - true means not expired yet
 */
function isInServicePeriod (location) {
  const expiry = getServiceFeeExpiryDate(location)
  return expiry && isFuture(expiry)
}

module.exports = {
  isServiceFeeDepositPaid,
  getServiceFeeExpiryDate,
  isInServicePeriod,
  getFreePeriodExpiryDate,
  isInFreePeriod
}
