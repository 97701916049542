function isRouteEditable (route) {
  if (!route) return true

  // If a SL route already has set a delivery date,
  // the route can no longer be edited and users are meant to create
  // a new one.
  return !route.firstDeliveryDate
}

module.exports = isRouteEditable
