exports.isLocationSubscribedToService = (locationId, serviceId, locations) => {
  if (!Array.isArray(locations)) {
    return false
  }
  const foundLocation = locations.find((location) => location._id === locationId)

  if (foundLocation && Array.isArray(foundLocation.services)) {
    return foundLocation.services.includes(serviceId)
  }

  return false
}
