const getToken = require('./get-token')
const createDebit = require('./create-debit')
const getDebitStatus = require('./get-debit-status')
const { ENDPOINTS } = require('./config')

// Heads up: we are using Lipa na M-Pesa with safaricom, a specific
// payment product. Field has plans to add another saficom product.
// If that happens, we need to check if this interface has enough overlap
// or if we should make Interfaces specific to safaricom's financial products.
class SafaricomInterface {
  constructor (safaricomApiConfig = {}, fetch, logger) {
    if (typeof fetch !== 'function') {
      throw new Error(
        'Safaricom Interface usage error: expected fetch as an argument'
      )
    }

    const requiredParams = [
      'url',
      'apiKey',
      'apiSecret',
      'lipaShortCode',
      'lipaPassKey'
    ]
    requiredParams.forEach(key => {
      if (!safaricomApiConfig[key]) {
        throw new Error(
          `Safaricom Interface usage error: safaricomApiConfig.${key} is a required argument`
        )
      }
    })

    this.fetch = (endpoint, params) =>
      fetch(`${safaricomApiConfig.url}${endpoint}`, params)
    this.logger = logger
    this.apiKey = safaricomApiConfig.apiKey
    this.apiSecret = safaricomApiConfig.apiSecret
    this.lipaShortCode = safaricomApiConfig.lipaShortCode
    this.callbackUrl = safaricomApiConfig.callbackUrl
    this.lipaPassKey = safaricomApiConfig.lipaPassKey
    this.endpoints = ENDPOINTS
    this.token = null
  }

  async loadToken () {
    // Heads up: Safaricom tokens are valid for up to one hour.
    // Initially, this keeps the token and its expiry in memory
    // on this interface.
    // We may want to store this somewhere once node REST deployment is figured out.
    // If so, we'd refactor these functions to be interface arguments,
    // or just rework this part.
    const getLatestToken = () => this.tokenDetails
    const storeLatestToken = details => {
      this.tokenDetails = details
    }

    this.token = await getToken(this, getLatestToken, storeLatestToken)
  }

  async createDebit ({PartyA, AccountReference, Amount, TransactionDesc}) {
    // This is to mock safaricom ok response for testing
    if (AccountReference === 'FieldCLITest') {
      return {
        ResponseCode: '0',
        CustomerMessage: 'Success. Request accepted for processing',
        CheckoutRequestID: 'ws_CO_250520211013561354',
        MerchantRequestID: '61916-6756251-1',
        ResponseDescription: 'Success. Request accepted for processing'
      }
    }
    await this.loadToken()
    return createDebit(this, {PartyA, AccountReference, Amount, TransactionDesc})
  }

  async getDebitStatus ({CheckoutRequestID}) {
    // This is to mock safaricom response for testing
    if (CheckoutRequestID === 'ws_CO_220620211108281137_FIELD_TEST') {
      return {
        ResponseCode: '0',
        ResponseDescription: 'The service request has been accepted successsfully',
        MerchantRequestID: '60455-1863552-1',
        CheckoutRequestID: 'ws_CO_220620211108281137_FIELD_TEST',
        ResultCode: '1',
        ResultDesc: 'The balance is insufficient for the transaction'
      }
    }
    await this.loadToken()
    return getDebitStatus(this, {CheckoutRequestID})
  }
}

module.exports = SafaricomInterface
