import { C_R } from '@fielded/fs-api/lib/shipment/tools/planning-types'
import {
  isShipmentComplete
} from '../common/utils'
import sortBy from 'lodash/sortBy'

export const onlyIncompleteArrivals = shipments => Object.keys(shipments)
  .reduce((incompleteShipments, snapshotId) => {
    const shipment = shipments[snapshotId]

    if (!isShipmentComplete(shipment) && shipment.statusType === 'arrival') {
      incompleteShipments.push(shipment)
    }

    if (shipment.planningType === C_R && shipment.status === 'new') {
      incompleteShipments.push(shipment)
    }

    return incompleteShipments
  }, [])

const dateSorted = shipments => (
  sortBy(shipments, ['date'])
)

export const dateSortedIncompleteArrival = shipments =>
  dateSorted(onlyIncompleteArrivals(shipments))
