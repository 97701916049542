/* global XMLHttpRequest */

import get from 'lodash/get'
import generateReplicationId from 'pouchdb-generate-replication-id'
import { updatePending } from './sync-reducer'
import {
  maybeSetUploadCheckpoint,
  writeCheckpoint,
  saveDocsLocally,
  getCouchDbInfo
} from './common'

export async function checkForCheckpoint (dbConfig) {
  const { remoteDb, localDb } = dbConfig
  dbConfig.initialDownloadRepId = await generateReplicationId(remoteDb, localDb, {})
  try {
    await localDb.get(dbConfig.initialDownloadRepId)
    return true
  } catch (e) {
    return false
  }
}

export async function firstReplicateAllDocs (user, dbConfig, dispatch) {
  const { remoteDb, localDb, bidirectional } = dbConfig
  const checkpoint = await downloadAndCheckpoint(user, dbConfig, dispatch)
  if (bidirectional) {
    await maybeSetUploadCheckpoint(remoteDb, localDb)
  }
  return checkpoint
}

async function downloadAndCheckpoint (user, dbConfig, dispatch) {
  const { remoteDb, localDb, name, initialDownloadRepId } = dbConfig
  const info = getCouchDbInfo(remoteDb, name, dispatch)
  const { rows, docs } = await allDocsWithProgress(user, dbConfig, info, dispatch)
  await saveDocsLocally(name, localDb, rows || docs, dispatch)
  const options = { writeTargetCheckpoint: true, writeSourceCheckpoint: true }
  return writeCheckpoint(remoteDb, localDb, initialDownloadRepId, info.update_seq, options) // eslint-disable-line
}

// 1. Couch doesn't respond with a Content-length header on _all_docs, so we guestimate total download
// from info.sizes.active, then on complete call dispatch with data_size / data_size to fix.
// 2. Using XMLHttpRequest over pouch.allDocs() to get progress events on the one http request.
async function allDocsWithProgress (
  user,
  {
    remoteDbUrl,
    name,
    docEndpoint,
    useDocDispenser
  },
  info,
  dispatch
) {
  const dataSize = get(info, 'sizes.active') // changed in CouchDB 3.0
  dispatch(updatePending(name, 'download', dataSize)) // eslint-disable-line

  if (useDocDispenser) {
    // Get all_docs from the doc endpoint
    const url = docEndpoint
    const data = { user, dbName: name }
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.withCredentials = true
      xhr.addEventListener('error', reject)
      xhr.addEventListener('abort', reject)
      xhr.addEventListener('progress', ({loaded}) => {
        if (xhr.status < 400) {
          dispatch(updatePending(name, 'download', dataSize - loaded)) // eslint-disable-line
        }
      })
      xhr.addEventListener('load', () => {
        if (xhr.status >= 400) {
          reject(xhr.responseText)
        } else {
          dispatch(updatePending(name, 'download', 0))
          resolve(JSON.parse(xhr.responseText))
        }
      })
      xhr.open('POST', url)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(JSON.stringify(data))
    })
  } else {
    // Get all_docs from couchdb
    const url = `${remoteDbUrl}${name}/_all_docs?include_docs=true`
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest()
      xhr.withCredentials = true
      xhr.addEventListener('error', reject)
      xhr.addEventListener('abort', reject)
      xhr.addEventListener('progress', ({loaded}) => {
        if (xhr.status < 400) {
          dispatch(updatePending(name, 'download', dataSize - loaded)) // eslint-disable-line
        }
      })
      xhr.addEventListener('load', (event) => {
        if (xhr.status >= 400) {
          reject(xhr.responseText)
        } else {
          dispatch(updatePending(name, 'download', 0))
          resolve(JSON.parse(xhr.responseText))
        }
      })
      xhr.open('GET', url)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send()
    })
  }
}
