import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Select from '../Select'
import { SelectTwoPartOption } from '../../../SelectInput'

const getVirtualizedOptionHeight = (option) => {
  let height = 42 // this is the calculated height of an option
  const twoLines = 62

  const isArray = Array.isArray(option.label)
  if (isArray) {
    const [ main, ...rest ] = option.label
    if (main.length >= 36 && rest.join().length >= 5) {
      // educated guess at when the main part is long enough that
      // the label will break into two lines
      // We prefer false positives, leaving blank space in select, over cramped options
      height = twoLines
    }
  } else if (option.label.length > 50) {
    height = twoLines
  }

  return height
}

const LocationSelect = (props) => {
  const {
    virtualized,
    required,
    placeholder,
    noResultsText,
    optionRenderer,
    ...otherProps
  } = props

  let selectProps = {
    placeholder,
    noResultsText,
    ...otherProps
  }

  const selectedOptionRenderer = optionRenderer || SelectTwoPartOption

  if (virtualized) {
    selectProps = {
      ...selectProps,
      virtualized,
      required,
      optionRenderer: selectedOptionRenderer,
      optionHeight: ({ option }) => getVirtualizedOptionHeight(option)
    }
  }

  return (
    <div className={classnames(
      {'vs-form-location-select--virtualized': virtualized}
    )}>
      <Select
        {...selectProps}
      />
    </div>
  )
}

LocationSelect.propTypes = {
  /**
   * A placeholder showing when no option is selected
   */
  placeholder: PropTypes.string,
  /**
   * Text to show when there are no locations on the list
   */
  noResultsText: PropTypes.string,
  /**
   * A way to display options in the list.
   * By default it uses the Select field SelectTwoPartOption renderer.
   */
  optionRenderer: PropTypes.func,
  /**
   * If the list of locations is very long, this prop will help with performance
   */
  virtualized: PropTypes.bool,
  /**
   * Classname for custom styling of this component
   */
  locationSelectClassName: PropTypes.string
}

LocationSelect.defaultProps = {
  placeholder: 'Type a location name',
  noResultsText: 'No location found'
}

export default LocationSelect

export {
  SelectTwoPartOption
}
