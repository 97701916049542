const get = require('lodash/get')
const flattenDeep = require('lodash/flattenDeep')

exports.mergeSnapshotsIntoOneGroup = (snapshots) => {
  const mainGroupId = get(snapshots[0], '[0].groupId')
  const mainGroup = snapshots[0]
  if (!mainGroupId) {
    throw new Error('Group id not found')
  }
  snapshots.shift()
  const merged = flattenDeep(snapshots).map((snapshot) => {
    snapshot.groupId = mainGroupId
    return snapshot
  })
  return [
    ...mainGroup,
    ...merged
  ]
}
