import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { QuantityDisplay } from '@fielded/shared-ui'
import { Checkmark, MinusCircle } from '@fielded/shared-ui/src/icons'

const noop = () => {}

const ProductItem = ({
  product,
  selected,
  selectForDeletion,
  disabled,
  completed,
  onToggle,
  showTotal,
  showProductCode
}) =>
  <li
    data-qa={`product-item:${product.id}`}
    className={classnames(
      'product-item',
      {'product-item--selected': selected || (product.selected && !product.disabled)},
      {'product-item--completed': completed},
      {'product-item--disabled': disabled || product.disabled},
      {'product-item--delete': selectForDeletion}
    )}
    onClick={product.disabled || disabled ? noop : onToggle}
  >
    <div className='product-item__icon'>
      {(selected || product.selected) && (
        selectForDeletion
          ? <MinusCircle />
          : <Checkmark />
      )}
    </div>
    {showProductCode && (
      <div className='product-item__code'>
        {product.code}
      </div>
    )}
    <div className='product-item__name'>
      {product.name}
    </div>
    {showTotal && (
      <div className='product-item__required'>
        <QuantityDisplay value={product.total} />
      </div>
    )}
  </li>

ProductItem.propTypes = {
  /**
   * The product object to display
   */
  product: PropTypes.object,
  /**
   * Selected or not
   */
  selected: PropTypes.bool,
  /**
   * Show if this is selected for addition or deletion
   */
  selectForDeletion: PropTypes.bool,
  /**
   * Can user choose this or not
   */
  disabled: PropTypes.bool,
  /**
   * For shipment planning, if need has been fulfilled
   */
  completed: PropTypes.bool,
  /**
   * Action to perform when user selects product
   */
  onToggle: PropTypes.func.isRequired,
  /**
   * Show the total count of this product (note: count, not price)
   */
  showTotal: PropTypes.bool,
  /**
   * For SL, show product code as well as name
   */
  showProductCode: PropTypes.bool
}

export default ProductItem
