const get = require('lodash/get')
const sortBy = require('lodash/sortBy')

const OPENING = 'field:standard-opening-balance'
const PARTNER_OPENING = 'field:opening-partner-balance'
const SL_OPENING = 'field:opening-shelflife-balance'
const CLOSING = 'field:standard-physical-count'
const PARTNER_CLOSING = 'field:partner-balance'
const SL_CLOSING = 'field:shelflife-balance'
const SOLD = 'field:standard-consumed'
const PARTNER_SOLD = 'field:partner-sold'
const SL_SOLD = 'field:shelflife-sold'
const BUYOUT = 'field:partner-buyout'
const SALES_ADJUSTMENTS = 'field:sales-adjustments'

const add = (reports, fieldName) => {
  return reports.reduce((sum, item) => {
    const value = get(item, fieldName, { amount: 0 })
    return sum + value.amount
  }, 0)
}

const mergeCounts = (reports) => {
  // Merges stock counts according to rules described here:
  // https://github.com/fielded/van-orga/issues/4493#issuecomment-640594806
  const sortedReports = sortBy(reports, ['submittedAt'])
  const first = sortedReports[0]
  const last = sortedReports[sortedReports.length - 1]
  if (first === last) {
    return first
  }

  const reversed = reports.slice(0).reverse()

  let products = reports.reduce((all, report) => {
    return all.concat(Object.keys(report.stock))
  }, [])
  products = Array.from(new Set(products))

  const stock = {}
  // 1. All products from all reports are neeeded (even though i'd expect it do be the same in all)
  products.forEach((productId) => {
    const field = fieldName => `stock.${productId}.fields.${fieldName}`

    const salesAdjustments = add(reports, field(SALES_ADJUSTMENTS))
    const buyOuts = add(reports, field(BUYOUT))
    const slSales = add(reports, field(SL_SOLD))
    const partnerSales = add(reports, field(PARTNER_OPENING)) - add(reports, field(PARTNER_CLOSING)) - buyOuts

    // Find the last report where this item was manually entered
    let lastEntry = reversed.find(r => {
      const stockEntry = get(r, `stock.${productId}`)
      if (!stockEntry) {
        return false
      }

      const autoFilled = get(r, `stock.${productId}.autoFilled`, false)
      const buyOut = get(r, field(BUYOUT), { amount: 0 })
      // buyOut counts are autofilled, but they still need to be represented
      const use = buyOut.amount !== 0 || !autoFilled

      return use
    })

    // POD products might never have had stock entered
    if (get(first, `stock.${productId}.skipCount`)) {
      return
    }

    let calculated = false
    if (!lastEntry) {
      // This means we haven't counted the product manually
      // this can happen for locations with POD products that
      // have never had a full stock count
      calculated = true
      lastEntry = reversed[0]
    }

    stock[productId] = {
      fields: {
        [OPENING]: get(first, field(OPENING), { amount: 0 }),
        [PARTNER_OPENING]: get(first, field(PARTNER_OPENING), { amount: 0 }),
        [SL_OPENING]: get(first, field(SL_OPENING), { amount: 0 }),

        // These are the ones we need to filter out:
        [CLOSING]: get(lastEntry, field(CLOSING)),
        [SL_CLOSING]: get(lastEntry, field(SL_CLOSING)),
        [PARTNER_CLOSING]: get(lastEntry, field(PARTNER_CLOSING)),

        // NOTE: This is using the partner/sl sold fields
        // because the normal 'standard-consumed' field is not allowed to be negative
        // therefore we'd get the wrong value when adjustments are made upwards
        [SOLD]: { amount: slSales + partnerSales },
        [PARTNER_SOLD]: { amount: partnerSales },
        [SL_SOLD]: { amount: slSales },
        [BUYOUT]: { amount: buyOuts },
        [SALES_ADJUSTMENTS]: {amount: salesAdjustments}
      },
      updatedAt: lastEntry.submittedAt,
      adjusted: !calculated && lastEntry.submittedAt !== first.submittedAt,
      calculated
    }
  })

  const autoFilled = reports.every(count => {
    return count.createdBy === 'sl-lambda-couchdb-user' && count.partialCount
  })

  return Object.assign({}, first, {
    stock,
    updatedAt: last.submittedAt,
    originalCount: Object.keys(first.stock).length,
    autoFilled,
    signed: last.signed,
    mergedReport: true
  })
}

module.exports = mergeCounts
