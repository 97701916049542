const { sha512 } = require('./../../tools/hash')

module.exports = createDebit
async function createDebit ({mandateId, totalAmount, fundingAccount, fundingBankCode, requestId}) {
  const {apiKey, serviceTypeId, merchantId} = this

  const headers = {
    'Content-Type': 'application/json'
  }

  const hash = await sha512(`${merchantId}${serviceTypeId}${requestId}${totalAmount}${apiKey}`)
  const body = {
    merchantId,
    serviceTypeId,
    hash,
    requestId,
    totalAmount,
    mandateId,
    fundingAccount,
    fundingBankCode
  }

  return this.fetch(this.endpoints.createDebit, {
    method: 'POST',
    body: JSON.stringify(body),
    headers
  })
}
