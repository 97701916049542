const PGAdapter = require('./../../common/pg-adapter')
const DATA_ORDER_TABLE_NAME = 'avocado.data_order'
const DATA_ORDER_COLUMNS = [
  'id',
  'order_id',
  'sub_order_id',
  'group_id',
  'funder_id',
  'order_type',
  'status',
  'destination_id',
  'created_at',
  'created_by',
  'updated_at',
  'updated_by',
  'closed_at',
  'closed_by',
  'closed_status',
  'delivery_date',
  'is_complete',
  'rds_created_at',
  'adjusted_at',
  'approved_at',
  'received_at',
  'program_id',
  'supplier_id',
  'delivery_fee',
  'payment_choice',
  'route_id'
]

class DataOrderAdapter extends PGAdapter {
  constructor (pgConnection, username, logger) {
    super(
      pgConnection,
      DATA_ORDER_TABLE_NAME,
      username,
      DATA_ORDER_COLUMNS,
      'order_id',
      null,
      logger
    )
  }

  async getOrder ({
    orderId
  }) {
    if (!orderId) {
      throw new Error(`orderId is required`)
    }

    let query = `
      SELECT *
      FROM ${DATA_ORDER_TABLE_NAME}
      WHERE order_id = $1
    `
    const { rows } = await this.pgConnection.query(query, [orderId])
    return rows
  }
}

module.exports = DataOrderAdapter
