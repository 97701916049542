const get = require('lodash/get')
const keyBy = require('lodash/keyBy')
const shortid = require('shortid')
const tools = require('../../tools')
const { getServiceForLocationId, TERRITORIES_MAP } = require('../../../service/tools')
const {translateTerritoryAlias, productAliasesByMarket} = require('../../../product/tools')
const { getRelatedLocations } = require('../master-data')
const { getFunderOnProgram } = require('../../tools/utils')
const { getLedgerForSupplier } = require('../read/get-group-details')
const format = require('date-fns/format')
const addDays = require('date-fns/add_days')
const { listGroups } = require('../read/list-groups')

exports.createResupplyPlan = async (state, mainApi, {programId, centralResupplyEnabled}) => {
  const {user} = state
  const groupId = shortid.generate()
  const timestamp = new Date().toJSON()
  const deliveryDate = format(addDays(new Date(), 3), 'YYYY-MM-DD')
  const locationId = state.user.location.id
  const {service: serviceId, primaryResupplier} = getServiceForLocationId(locationId)
  let primaryResupplyLedger
  let primaryResupplierService

  if (primaryResupplier && centralResupplyEnabled) {
    const { service } = getServiceForLocationId(primaryResupplier)
    primaryResupplierService = service
    primaryResupplyLedger = await mainApi.order.getHeldStock({
      locationId: primaryResupplier,
      filterAvailable: true,
      includeShipments: false,
      serviceToTranslateTo: primaryResupplierService === serviceId ? false : serviceId
    })
  }

  const allLocations = await getRelatedLocations(state, programId)
  const allLocationsById = keyBy(allLocations, '_id')

  // Get only routine/topup orders and get all packpoints they contain
  const packPointOrders = await getOrdersWithPackPointSource(state, locationId, programId, serviceId, primaryResupplier, primaryResupplierService)
  const packPoints = [...new Set(packPointOrders.map(d => allLocationsById[d.supplierId]).filter(x => x))]

  const allProducts = await mainApi.product.listAll()
  const productsById = keyBy(allProducts, '_id')

  const supplierLedgers = await Promise.all(packPoints.map(async supplier => {
    return getLedgerForSupplier(state, mainApi, {supplier, locationId: user.location.id, products: allProducts})
  }))

  let supplierLedgersById = keyBy(supplierLedgers, 'supplier._id')
  const productsForTranslation = productAliasesByMarket(allProducts)

  // Add together all the planned units across all orders
  const packPointProducts = await packPointOrders.reduce((acc, order) => {
    const { supplierId, products, destinationId } = order

    const {service: destinationService} = getServiceForLocationId(destinationId)
    const needsTranslation = serviceId !== destinationService

    const supplierProductsToProcure = Object.keys(products).reduce((acc, product) => {
      const {adjusted} = products[product]
      if (needsTranslation) {
        product = translateTerritoryAlias(product, productsForTranslation, serviceId)
      }

      acc[product] = acc[product] ? acc[product] += adjusted : adjusted
      return acc
    }, {})

    acc[supplierId] = acc[supplierId]
      ? concatPackPointProductsToProcure(acc[supplierId], supplierProductsToProcure)
      : supplierProductsToProcure
    return acc
  }, {})

  // Calculate amount to resupply by removing amount in ledger from planned units total
  const packPointProductToProcure = Object.keys(packPointProducts).reduce((acc, packPoint) => {
    const supplierLedger = supplierLedgersById[packPoint]['ledger']

    const packPointPlannedProducts = packPointProducts[packPoint]

    const overAllProductsToGet = Object.keys(packPointPlannedProducts).reduce((acc, product) => {
      const plannedUnits = packPointPlannedProducts[product]

      if (plannedUnits <= 0) {
        return acc
      }

      const productLedgerValue = supplierLedger[product]

      if (!productLedgerValue) {
        acc[product] = plannedUnits
        return acc
      }

      const amountToProcure = plannedUnits - productLedgerValue

      if (!amountToProcure || amountToProcure <= 0) return acc

      acc[product] = amountToProcure
      return acc
    }, {})

    acc = Object.assign(acc, {[packPoint]: overAllProductsToGet})
    return acc
  }, {})

  let centralSupplierOrders
  if (primaryResupplier && primaryResupplyLedger && Object.keys(primaryResupplyLedger.ledger).length > 0) {
    // filter out the primary central sort from sourcing products from themselves
    const packPointsToSourceFromPrimary = Object.keys(packPointProductToProcure)
      .filter(pp => pp !== primaryResupplier)
      .reduce((obj, ppId) => {
        obj[ppId] = packPointProductToProcure[ppId]
        return obj
      }, {})

    const { primarySupplierOrders, leftOverProductToProcure } = sourceProductsFromPrimarySupplier(
      packPointsToSourceFromPrimary,
      primaryResupplyLedger
    )
    centralSupplierOrders = primarySupplierOrders
    Object.keys(leftOverProductToProcure).forEach(packpoint => {
      packPointProductToProcure[packpoint] = leftOverProductToProcure[packpoint]
    })
  }

  // Add the suppliers according to the products default supplier for each packpoint
  const {packPointSupplierOrders, productsWithoutSupplier} = appendProductSupplier(packPointProductToProcure, allLocations, productsById)

  const resupplySnapshots = createOrderSnapshots({
    packPointSupplierOrders,
    groupId,
    programId,
    deliveryDate,
    user,
    timestamp
  })

  const centralSortSnapshots = centralSupplierOrders ? createOrderSnapshots({
    packPointSupplierOrders: centralSupplierOrders,
    groupId: shortid.generate(),
    programId,
    deliveryDate,
    user,
    timestamp
  }) : []
  const snapshots = [...resupplySnapshots, ...centralSortSnapshots]
  return {snapshots, productsWithoutSupplier}
}

const getOrdersWithPackPointSource = async (state, locationId, programId, serviceId, primaryResupplier, resupplierService) => {
  const [parentLocationId] = locationId.split(':sdp')
  const territoryMap = keyBy(TERRITORIES_MAP, 'service')
  const parentLocationMap = territoryMap[serviceId] || {}
  const relatedMarkets = []
  if (Object.keys(parentLocationMap).includes('relatedTerritories')) {
    parentLocationMap.relatedTerritories.forEach(t => {
      relatedMarkets.push(territoryMap[t].geoId)
    })
  }
  // append orders for market linked to central resupply pp, if its resupply market planner making request
  if (resupplierService === serviceId) {
    TERRITORIES_MAP.forEach(market => {
      if (!relatedMarkets.includes(market.geoId) && market.primaryResupplier === primaryResupplier) {
        relatedMarkets.push(market.geoId)
      }
    })
  }

  const {groups} = await listGroups(state, {
    parentLocationId,
    programId,
    doNotFilterByLocation: true,
    useSubOrderId: true,
    isComplete: false,
    relatedMarkets
  })
  return groups.reduce((acc, group) => {
    const {orders} = group
    const packPointOrders = orders.filter(({closedStatus, orderType, supplierId}) => {
      if (!supplierId) return false

      const {service: ppServiceId} = getServiceForLocationId(supplierId)
      if (ppServiceId !== serviceId) return false

      return orderType !== 'resupply' && !closedStatus
    })
    acc.push(...packPointOrders)
    return acc
  }, [])
}

const concatPackPointProductsToProcure = (existingProducts, newProducts) => {
  const summedProducts = Object.keys(newProducts).reduce((acc, product) => {
    if (product in existingProducts) {
      acc[product] = existingProducts[product] + newProducts[product]
    } else {
      acc[product] = newProducts[product]
    }
    return acc
  }, {})

  return Object.assign(existingProducts, summedProducts)
}

const appendProductSupplier = (packPointProductToProcure, allLocations, productsById) => {
  const productsWithoutSupplier = []
  const packPointSupplierOrders = Object.keys(packPointProductToProcure).reduce((acc, packPoint) => {
    const productsForPP = packPointProductToProcure[packPoint]

    const packPointSupplierOrdersByProduct = Object.keys(productsForPP).reduce((acc, product) => {
      const productDoc = productsById[product]

      const supplierCode = get(productDoc, 'additionalData.supplier')
      const supplier = getSupplier({allLocations, supplierCode})

      if (!supplier) {
        if (!productsWithoutSupplier.includes(product)) {
          productsWithoutSupplier.push(product)
          return acc
        }
        return acc
      }

      const packPointSupplierPair = `${packPoint}-supplier-${supplier._id}`
      const productObject = {productId: product, quantity: productsForPP[product]}

      acc[packPointSupplierPair] = acc[packPointSupplierPair]
        ? [...acc[packPointSupplierPair], productObject]
        : [productObject]
      return acc
    }, {})

    Object.keys(packPointSupplierOrdersByProduct).forEach(d => {
      acc[d] = packPointSupplierOrdersByProduct[d]
    })

    return acc
  }, {})

  return {packPointSupplierOrders, productsWithoutSupplier}
}

const sourceProductsFromPrimarySupplier = (packPointProductToProcure, primaryResupplier) => {
  const leftOverProductToProcure = JSON.parse(JSON.stringify(packPointProductToProcure))
  const ledger = primaryResupplier.ledger || {}
  const productsInCentralSupplier = Object.keys(ledger)
  const primarySupplierOrders = Object.keys(leftOverProductToProcure).reduce((acc, pp) => {
    const shipmentId = `${pp}-supplier-${primaryResupplier.supplier._id}`
    const productQtyToSource = []
    Object.keys(leftOverProductToProcure[pp]).forEach(product => {
      if (productsInCentralSupplier.includes(product) && ledger[product] > 0) {
        const qtyInLedger = ledger[product]
        const qtyToProcureAtPP = leftOverProductToProcure[pp][product]
        let quantityToProcure = 0

        // there are more quantites available in the ledger to disburse
        if (qtyInLedger >= qtyToProcureAtPP) {
          ledger[product] = qtyInLedger - qtyToProcureAtPP
          leftOverProductToProcure[pp][product] = 0
          quantityToProcure = qtyToProcureAtPP
        } else {
          ledger[product] = 0
          leftOverProductToProcure[pp][product] = qtyToProcureAtPP - qtyInLedger
          quantityToProcure = qtyInLedger
        }
        productQtyToSource.push({
          productId: product,
          quantity: quantityToProcure
        })
      }
    })

    // remove products that have been fully sourced from the primary resupplier from leftOvers
    Object.values(leftOverProductToProcure).forEach(ppGroup => {
      Object.keys(ppGroup).forEach(product => {
        if (ppGroup[product] <= 0) {
          delete ppGroup[product]
        }
      })
    })
    if (productQtyToSource.length > 0) {
      acc[shipmentId] = productQtyToSource
    }
    return acc
  }, {})

  // return the now updated products to be ordered from various other suppliers
  // and the resupply orders to be sourced from primaryResupply/central sort
  return { primarySupplierOrders, leftOverProductToProcure }
}

const getSupplier = ({
  allLocations,
  supplierCode
}) => {
  const supplier = allLocations.find(s => s.level === 'supplier' && supplierCode === get(s, 'additionalData.code'))
  return supplier
}

const addProductParams = (products) => {
  return products.reduce((acc, product) => {
    const {productId, quantity} = product
    acc[productId] = {original: Number(quantity), allocationType: 'allocation_missing'}
    return acc
  }, {})
}

const createOrderSnapshots = ({packPointSupplierOrders, groupId, programId, deliveryDate, user, timestamp}) => {
  const snapshots = Object.keys(packPointSupplierOrders).map(item => {
    const products = addProductParams(packPointSupplierOrders[item])
    const [destinationId, supplierId] = item.split('-supplier-')
    const funderId = getFunderOnProgram(destinationId, programId)

    return tools.createSnapshot({
      status: 'new',
      orderId: shortid.generate(),
      groupId,
      suborderId: shortid.generate(),
      programId,
      destinationId,
      funderId,
      supplierId,
      products,
      reports: [],
      deliveryDate,
      orderType: 'resupply',
      user,
      timestamp
    })
  })
  return snapshots
}
