import React from 'react'
import PropTypes from 'prop-types'

import Banner from '../Banner'

const ErrorView = ({ error, title = 'Sorry, unexpected error:', text }) => {
  return (
    <Banner
      inline
      type='warning'
    >
      <h2>Error: {title}</h2>
      {text && <p>{text}</p>}

      <p>Please provide the following info:</p>
      <pre>
        {error.message}

        {error.stack}
      </pre>
    </Banner>
  )
}

ErrorView.propTypes = {
  /**
   * Optional text to show
   */
  text: PropTypes.string,
  /**
   * Standard javascript stack error format.
   * Pass whatever you get back from JS.
   */
  error: PropTypes.object,
  /**
   * Shows as a headline
   */
  title: PropTypes.string
}

export default ErrorView
