import React from 'react'
import { formatDate } from '../../../van-shared/utils/utils'
import { ExpandableOptions } from '@fielded/shared-ui'

export function ShipmentHeaderDate ({ previousScheduledDates, formattedDate }) {
  let shipmentDelays = []
  const hasDelays = previousScheduledDates && previousScheduledDates.length > 0
  if (hasDelays) {
    shipmentDelays = previousScheduledDates
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      .map(({ date, updatedAt }, index) => {
        const reschedulePhrase = index === previousScheduledDates.length - 1 ? 'created' : 'rescheduled'
        return <div className='shipment__scheduled-delay' key={updatedAt}>
          <span>{formatDate(date, 'long')}</span>
          <span>{reschedulePhrase} on {formatDate(updatedAt, 'long')}</span>
        </div>
      }
      )
  }

  return (
    <div className='shipment__date'>
      {!hasDelays && <div>{formattedDate}</div>}
      {hasDelays && (
        <React.Fragment>
          <div>Arrival rescheduled to {formattedDate}</div>
          <div className='shipment__scheduled-delays-wrapper'>
            <ExpandableOptions
              label='More'
              expandSide
              expandWrapperClassName='shipment__scheduled-delays'
              expandableChildren={shipmentDelays}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
