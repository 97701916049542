const { Customer } = require('./customer')
const { Invoice } = require('./invoice')
const { CreditMemo } = require('./credit-memo')
const { Payment } = require('./payment')
const { Changes } = require('./changes')
const { Query } = require('./query')
const { Batch } = require('./batch')
const { Report } = require('./report')

class EntitiesApi {
  constructor (quickbooks) {
    this.invoice = new Invoice(quickbooks)
    this.creditMemo = new CreditMemo(quickbooks)
    this.payment = new Payment(quickbooks)
    this.changes = new Changes(quickbooks)
    this.query = new Query(quickbooks)
    this.batch = new Batch(quickbooks)
    this.report = new Report(quickbooks)
    this.customer = new Customer(quickbooks)
  }
}

module.exports = {
  EntitiesApi
}
