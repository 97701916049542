import React from 'react'
import { Chevron } from '@fielded/shared-ui/src/icons'
import { ActionsBar, Button, Page } from '@fielded/shared-ui'
import { pluralize } from '@fielded/shared-ui/src/utils'

const Partial = ({
  isAdjustment,
  createResupplyOrder,
  appendResupplyShipment,
  negativeChanges,
  onSkip
}) => {
  const buttonProps = {
    colorVariant: 'brand',
    fill: 'outline'
  }

  return (
    <Page
      situation='caution'
      className={isAdjustment ? 'vs-page vs-page--situation-ready vs-page__content' : 'page--confetti'}
    >
      <Page.Intro
        centered
        title='Delivery partially fulfilled'
      />
      <Page.Panel
        withBackground
        narrow
      >
        <Page.Panel.Header title={`${negativeChanges.length} ${pluralize('product was', negativeChanges.length, 'products were')} not fulfilled`} />
        <Page.Panel.Section>
          To complete the fulfillment, you can create a follow-up delivery or order:
        </Page.Panel.Section>
        <Page.Panel.Actions>
          <Button
            {...buttonProps}
            onClick={createResupplyOrder}
          >
            Create Order
          </Button>
          <Button
            {...buttonProps}
            onClick={appendResupplyShipment}
          >
            Create Delivery
          </Button>
        </Page.Panel.Actions>
      </Page.Panel>
      <ActionsBar layout='centered'>
        <Button
          {...buttonProps}
          iconSide='right'
          icon={<Chevron direction='right' />}
          onClick={onSkip}
        >
          Skip this
        </Button>
      </ActionsBar>
    </Page>
  )
}

export default Partial
