const denormaliseUser = require('./../../../utils/denormalise-user')
const { get } = require('../read/get')
const _get = require('lodash/get')
const { validateLocation } = require('./validate-location')
const { validateUser } = require('./validate-user')
const { validateReportingPeriod } = require('./validate-reporting-period')

exports.prepareUserToSave = prepareUserToSave
function prepareUserToSave (user, username = '') {
  const userToSave = {
    // Explicitly set the _id here (in case it's a new user)
    _id: `org.couchdb.user:${user.name}`,
    funders: user.funders || [],
    routes: user.routes || [],
    location: user.location || {},
    name: user.name,
    profile: user.profile || {},
    programs: user.programs || [],
    roles: [...new Set(user.roles)],
    // for new users `createdAt` and `createdBy` is undefined
    // but we overwrite it a few lines later
    createdAt: user.createdAt,
    createdBy: user.createdBy
  }

  if (user.roles.includes('feature:userRole:external-planner')) {
    const state = user.location.state
    if (!user.roles.includes(`feature:state:${state}`)) {
      userToSave.roles.push(`feature:state:${state}`)
    }
  }

  if (user.acceptedTCs) {
    userToSave.acceptedTCs = user.acceptedTCs
  }
  if (user.userDBSync) {
    userToSave.userDBSync = user.userDBSync
  }

  if (user._rev) {
    userToSave._rev = user._rev
    userToSave.updatedAt = new Date().toJSON()
    userToSave.updatedBy = username
  } else {
    userToSave.createdAt = new Date().toJSON()
    userToSave.createdBy = username
  }

  if (user.password) {
    userToSave.password = user.password
  } else {
    userToSave.derived_key = user.derived_key
    userToSave.iterations = user.iterations
    userToSave.password_scheme = user.password_scheme
    userToSave.salt = user.salt
  }

  if (user.signupUid) {
    userToSave.signupUid = user.signupUid
  }

  return userToSave
}

exports.create = create
async function create (state, user, options) {
  const username = _get(state.user, 'name')
  const userToSave = denormaliseUser(prepareUserToSave(user, username), options)

  // Get our currently logged in user
  // (not necessarily the one we are updating). If this throws,
  // we need it to throw.
  const currentUser = await get(state, username)
  // As CouchDB requires name be unique, first check
  // if we have any existing user with this username.
  try {
    await get(state, userToSave.name)
    throw new Error(`Error: user with name ${user.name} already exists`)
  } catch (err) {
    // This also means there is an existing user, we just do not have access to see it.
    if (err.status === 403) {
      throw new Error(`Error: user with name ${user.name} already exists`)
    }
    // 404 is expected and means we're OK to continue to create
    // but throw if an unexpected error happens.
    if (err.status !== 404) {
      throw err
    }
  }

  const reportingPeriodError = await validateReportingPeriod(state, { newPrograms: userToSave.programs })
  if (!reportingPeriodError) {
    throw new Error('User can not have programs with different reporting period')
  }

  const userValidationErrors = validateUser(currentUser, userToSave)
  if (userValidationErrors) {
    throw new Error(userValidationErrors)
  }

  const locationMappingError = await validateLocation(state, userToSave)
  if (locationMappingError) {
    throw new Error(locationMappingError)
  }
  await state.dal.user.saveOne(state, userToSave)
  return get(state, user.name)
}

exports.update = update
async function update (state, user, options) {
  const username = _get(state.user, 'name')
  const userToSave = denormaliseUser(prepareUserToSave(user, username), options)

  const currentUser = await get(state, username)
  // If this user does not exist, we want this to hard fail
  // as we're doing an update.
  const userBeforeUpdate = await get(state, userToSave.name)

  const reportingPeriodError = await validateReportingPeriod(state, { existingPrograms: userBeforeUpdate.programs, newPrograms: userToSave.programs })
  if (!reportingPeriodError) {
    throw new Error('User can not have programs with different reporting period')
  }

  const userValidationErrors = validateUser(currentUser, userToSave, userBeforeUpdate)
  if (userValidationErrors) {
    throw new Error(userValidationErrors)
  }

  const locationMappingError = await validateLocation(state, userToSave)
  if (locationMappingError) {
    throw new Error(locationMappingError)
  }
  await state.dal.user.saveOne(state, userToSave)
  return get(state, user.name)
}
