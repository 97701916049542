import { loadPickList, LOAD } from '../pick-list/pick-list-reducer'
import { updateShipment } from '../shipments/shipments-reducer'
import {
  batchIdToProductId,
  isShipmentComplete
} from '../common/utils'
import { batchCanBeChecked } from './utils'

const reducerName = 'batches'
export const BATCHES_LIST_UPDATED = `${reducerName}/BATCHES_LIST_UPDATED`

export const load = (state, action) => {
  const shipment = action.shipment
  const snapshot = shipment.history[shipment.snapshotId]
  const counts = isShipmentComplete(shipment) ? snapshot.counts : shipment.counts

  const byBatchId = (index, batchId) => {
    const productId = batchIdToProductId(batchId)
    if (!batchCanBeChecked(counts, batchId)) {
      return index
    }
    const batch = counts[batchId]
    index[batchId] = {
      ...batch,
      id: batchId,
      checked: isShipmentComplete(shipment) ? true : counts[batchId].checked,
      quantity: counts[batchId].quantity,
      productId: productId,
      snapshotId: shipment.snapshotId
    }
    return index
  }

  return Object.keys(counts).reduce(byBatchId, {})
}

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD: return load(state, action)
    default: return state
  }
}

// this marks all batches, known or unknown,
// quantity or 0 as removed on the shipment
export const deleteProductFromShipment = (shipment, productId, products) => (dispatch, getState, { api }) => {
  return api.shipment.removeProduct(shipment.snapshotId, productId)
    .then(shipment => {
      // Since a product was removed from the shipment above,
      // we'll want to update our store with the new shipment
      dispatch(updateShipment(shipment))
      // The picklist is made up of product on this shipment and
      // if a product has been removed, the available products in the picklist
      // should be re-calculated
      dispatch(loadPickList(shipment, products))
      return shipment
    })
}
