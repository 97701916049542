const get = require('lodash/get')
const { parse } = require('../../../tools/smart-id')
const createSnapshot = require('../../../shipment/shipment-create-snapshot')
const { SUBORDER_STATUS } = require('../../constants')

exports.moveShipmentStatus = async function (state, suborderId, status = SUBORDER_STATUS.received) {
  const orderSnapshot = await state.dal.order.getSuborder(state, {suborderId})
  const shipmentSnapshots = await state.dal.order.getShipmentsOnOrder(state, orderSnapshot._id)
  const priorStateSnapshot = shipmentSnapshots.filter(snapshot => {
    if (status === SUBORDER_STATUS.packed && snapshot.type === 'snapshot' && parse(snapshot._id).status === 'new') return snapshot
    if (status === SUBORDER_STATUS.received && snapshot.type === 'snapshot' && parse(snapshot._id).status === SUBORDER_STATUS.packed) return snapshot
    else return snapshot
  })
  const shipmentSnapshot = createShipmentSnapshot(orderSnapshot, priorStateSnapshot, status)
  return createSnapshot(state, shipmentSnapshot)
}

function createShipmentSnapshot (orderSnapshot, shipmentSnapshots, status) {
  const shipmentNo = getShipmentNumber(shipmentSnapshots)
  const oneShipmentNumber = get(orderSnapshot, 'additionalData.shipmentNumber')
  return {
    origin: orderSnapshot.supplierId,
    destination: orderSnapshot.destinationId,
    // VAN store api expects YYYY-MM-DD
    date: orderSnapshot.createdAt.split('T')[0],
    overrideCreatedAt: orderSnapshot.createdAt,
    shipmentTypeId: `allocated:${orderSnapshot.funderId}`,
    funderId: orderSnapshot.funderId,
    orderId: orderSnapshot._id,
    shipmentNo,
    status,
    counts: getCounts(shipmentSnapshots),
    comment: `Created from ONE Shipment ID "${oneShipmentNumber}"`,
    removePaymentType: true
  }
}

function getShipmentNumber (newShipmentSnapshots = []) {
  if (!newShipmentSnapshots.length || !newShipmentSnapshots[0]._id) return null
  const {shipmentNo} = parse(newShipmentSnapshots[0]._id)
  return shipmentNo
}

function getCounts (newShipmentSnapshots) {
  // ignore snapshot comment docs
  const snapshot = newShipmentSnapshots
    .find(snapshot => snapshot.type === 'snapshot')
  if (!snapshot) {
    return {}
  }
  return Object.keys(snapshot.counts)
    .reduce((acc, batchId) => {
      acc[batchId] = {
        quantity: snapshot.counts[batchId].quantity,
        checked: true
      }
      return acc
    }, {})
}
