import React, { Component, Fragment } from 'react'
import get from 'lodash/get'

import { Page } from '@fielded/shared-ui'

import { getNavigationTitle } from '../../../common/utils/navigationInfo'
import DownloadShipmentsButton from '../../../van-shared/components/DownloadShipmentsButton/DownloadShipmentsButton'
import { hasFeature } from '../../../van-shared/utils/features'
import { userIsAuthorised } from '../../../van-shared/utils/auth'

import ReportingProgress from '../common/ReportingProgress'
import Header from './Header'
import OverviewDownloadButton from './OverviewDownloadButton'
import LocationsHeader from './LocationsHeader'
import LocationsList from './LocationsList'
import LocationsSearch from './LocationsSearch'
import DueDate from './due-date'
import SyncUpdateBanner from '../../../sync/components/SyncUpdateBanner/SyncUpdateBanner'

import {
  buildProgramsUrl,
  buildLocationsUrl
} from '../app/routes-urls'

export const LIST_VIEW = 'listView'
export const SEARCH_VIEW = 'searchView'

const getDefaultView = ({ app }) => {
  if (app === 'psm') return SEARCH_VIEW
  return LIST_VIEW
}

const headerTitle = (config, program) => {
  switch (config.app) {
    case 'psm':
      return `Stock reporting for ${program.name}`
    default:
      return getNavigationTitle({ item: 'reports', config })
  }
}

class Locations extends Component {
  state = {
    locationsView: getDefaultView(this.props.config)
  }

  createLocationSelection () {
    const { locationId, date, program, config, isEditablePeriod, user, showShipmentSyncBanner } = this.props
    const urlParams = {
      locationId, date, programId: program.id
    }
    const showPartialCount = get(config.features, 'stockCount.adjustments')
    const alwaysSubmitPartials = userIsAuthorised(user, 'feature:userRole:planner') || userIsAuthorised(user, 'feature:userRole:operator')

    switch (this.state.locationsView) {
      case LIST_VIEW: return (
        <LocationsList
          isEditablePeriod={isEditablePeriod}
          locations={this.props.program.locations.all}
          period={this.props.program.periods.current}
          urlParams={urlParams}
          user={this.props.user}
          showStatementsDownload={get(config.features, 'stockCount.showStatementsDownload')}
          showPartialCount={showPartialCount}
          alwaysSubmitPartials={alwaysSubmitPartials}
          showShipmentSyncBanner={showShipmentSyncBanner}
        />
      )
      case SEARCH_VIEW: return (
        <LocationsSearch
          isEditablePeriod={isEditablePeriod}
          locations={this.props.program.locations.all}
          urlParams={urlParams}
          showPartialCount={showPartialCount}
          alwaysSubmitPartials={alwaysSubmitPartials}
          showShipmentSyncBanner={showShipmentSyncBanner}
        />
      )
      default:
        console.error('Unknown location view type: ' + this.state.locationsView)
        return undefined
    }
  }

  render () {
    const {
      date,
      locationId,
      progress,
      program,
      user,
      api,
      config,
      showCountSyncBanner,
      showShipmentSyncBanner
    } = this.props
    const onSetViewClick = (viewType) => this.setState({locationsView: viewType})

    const programUrl = buildProgramsUrl({locationId, date})

    const prevPeriodUrl = buildLocationsUrl({locationId, date: program.periods.previous.entryStartDate, programId: program.id})
    const nextPeriodUrl = buildLocationsUrl({locationId, date: program.periods.next.entryStartDate, programId: program.id})

    const handlePeriodJump = (month, year) => {
      const { locationId, program, history } = this.props
      const url = '/locations/' + locationId + '/' + year + '-' + month + '-' + '01' + '/' + program.id
      history.push(url)
    }

    const shipmentExport = hasFeature(config.features, 'stockCount:downloadShipments') &&
      (userIsAuthorised(user, 'feature:userRole:planner') || userIsAuthorised(user, 'feature:userRole:operator'))

    return (
      <Fragment>
        <Page title={config.title}>
          <Header
            config={config}
            user={user}
            periods={program.periods}
            program={program}
            programUrl={programUrl}
            title={headerTitle(config, program)}
            nextPeriodUrl={nextPeriodUrl}
            prevPeriodUrl={prevPeriodUrl}
            handlePeriodJump={handlePeriodJump}
          />
          <Page.Panel withBackground>
            {hasFeature(config.features, 'stockCount:reportDueDates') && (
              <Page.Panel.Message fullWidth>
                <DueDate program={program} />
              </Page.Panel.Message>
            )}
            {showCountSyncBanner && <SyncUpdateBanner
              title='Stock report data is currently being downloaded'
            />}
            {showShipmentSyncBanner && <SyncUpdateBanner
              title='Shipment data is being downloaded, cant start report'
            />}
            <Page.Panel.Section fullWidth className='reports-overview'>
              <div className='reports-overview__sidebar'>
                <ReportingProgress
                  progress={progress}
                  programName={program.name}
                >
                  <OverviewDownloadButton
                    user={user}
                    period={program.periods.current.id}
                    program={program}
                  />
                  {shipmentExport && (
                    <DownloadShipmentsButton
                      exportFunction={api.shipment.slExport}
                      locationId={user.location.id}
                      period={program.periods.current}
                      isReceived />
                  )}
                </ReportingProgress>
              </div>
              <div className='main-column'>
                <LocationsHeader
                  activeView={this.state.locationsView}
                  onSetViewClick={onSetViewClick}
                />
                <div className='main-column__content'>
                  {this.createLocationSelection()}
                </div>
              </div>
            </Page.Panel.Section>
          </Page.Panel>
        </Page>
      </Fragment>
    )
  }
}

export default Locations
