import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'
import kebabCase from 'lodash/kebabCase'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const InventoryTable = ({
  rows,
  currency,
  appName
}) => {
  const items = rows.map(row => {
    const { cols = [], colsTotal = [] } = row

    if (isEmpty(cols) || isEmpty(colsTotal)) {
      return null
    }

    const columns = cols.map(col => {
      const colName = kebabCase(col.name)
      const colProps = get(col, 'props', {})
      if (col.url) {
        return <td className={`inventory-table__td--${colName}`} {...colProps}>
          <Link to={col.url}>{ col.value }</Link>
        </td>
      }
      return (
        <td className={`inventory-table__td--${colName}`} {...colProps}>{ col.value }</td>
      )
    })

    const columnsTotal = colsTotal.map(col => {
      const colName = kebabCase(col.name)

      return (
        <td className={`inventory-table__td--${colName}`} colSpan={2}>(Total: { col.value })</td>
      )
    })

    return (
      <Fragment>
        <tr
          key={`${row.key}`}
          className={cx(
            'inventory-table__tr'
          )}
        >
          { columns }
        </tr>
        <tr>
          { columnsTotal }
        </tr>
      </Fragment>
    )
  })

  return (
    <table className={cx(
      'inventory-table'
    )}>
      <thead>
        <tr className='inventory-table__tr'>
          <th className='inventory-table__th inventory-table__th--products'>
            Products
          </th>
          <th className='inventory-table__th inventory-table__th--opening' colSpan={2}>
            Opening
          </th>
          <th className='inventory-table__th inventory-table__th--on-shelf' colSpan={2}>
            On Shelf
          </th>
          <th className='inventory-table__th inventory-table__th--unit-price'>
            Unit Price ({currency})
          </th>
          <th className='inventory-table__th inventory-table__th--total'>
            Total ({currency})
          </th>
        </tr>
        <tr className='inventory-table__tr'>
          <th className='inventory-table__th' />
          <th className='inventory-table__th inventory-table__th--odd'>
            Yours
          </th>
          <th className='inventory-table__th inventory-table__th--even'>
            {appName}'s
          </th>
          <th className='inventory-table__th inventory-table__th--odd'>
            Yours
          </th>
          <th className='inventory-table__th inventory-table__th--even'>
            {appName}'s
          </th>
          <th className='inventory-table__th--side' colSpan={2} />
        </tr>
      </thead>
      <tbody>
        { items }
      </tbody>
    </table>
  )
}

InventoryTable.propTypes = {
  /* An array of rows */
  rows: PropTypes.array,
  /* Currency in which the product is sold */
  currency: PropTypes.string
}

export default InventoryTable
