import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FieldContext } from '../'

const ValidationErrors = ({
  alignRight,
  className
}) => (
  <FieldContext.Consumer>
    {({ hasErrors, getErrors }) =>
      <Fragment>
        {hasErrors() && (
          <ul className={classnames(
            'vs-form-field-errors',
            {'vs-form-field-errors--align-right': alignRight},
            className
          )}>
            {getErrors().map((err, index) => (
              <li
                key={index}
                className='vs-form-field-errors__message'
              >
                {err}
              </li>
            ))}
          </ul>
        )}
      </Fragment>
    }
  </FieldContext.Consumer>
)

ValidationErrors.propTypes = {
  /**
   * Set to true to align the messages on the right
   */
  alignRight: PropTypes.bool,

  /**
   * Optional custom class, please only use for spacing
   */
  className: PropTypes.string
}

ValidationErrors.defaultProps = {
  alignRight: false,
  className: undefined
}

ValidationErrors.displayName = 'Form.Field.ValidationErrors'

export default ValidationErrors
