module.exports = batchId

function batchId (params) {
  const {
    manufacturer,
    productId,
    batchNo
  } = params

  return `${productId}:manufacturer:${manufacturer}:batchNo:${batchNo}`
}
