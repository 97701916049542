import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import batches from '../batches/batches-reducer'
import planning from '../planning/planning-reducer'
import pickList from '../pick-list/pick-list-reducer'
import shipments from '../shipments/shipments-reducer'
import geolocation from '../shipments/psm-shipments/reducers/geolocationSlice'
import masterData from './master-data-reducer'

const reducer = combineReducers({
  batches,
  planning,
  pickList,
  shipments,
  masterData,
  geolocation
})

const persistConfig = {
  key: 'shipments',
  version: 1,
  storage,
  whitelist: ['shipments', 'geolocation']
}

export const persistedReducer = persistReducer(persistConfig, reducer)
