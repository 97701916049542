module.exports = (productQuantities = {}, allProducts) => {
  const genericQuantities = {}
  for (const productId in productQuantities) {
    const {genericParent, genericFactor} = allProducts.find(p => p._id === productId) || {}
    if (!genericParent || !genericFactor) {
      continue
    }
    genericQuantities[genericParent] = genericQuantities[genericParent] || 0
    genericQuantities[genericParent] += productQuantities[productId] * genericFactor
  }
  return genericQuantities
}
