const PGAdapter = require('./../common/pg-adapter')

class PricePGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = [
      'id',
      'sku',
      'date',
      'buyprice',
      'sellprice',
      'discount',
      'markup'
    ]
    const virtualColumns = [
      'product',
      'market',
      'month'
    ]
    const opts = {
      addUuid: true,
      strictColumns: false,
      virtualColumns
    }
    super(pgConnection, 'real.price_history', username, columns, 'id', {}, logger, opts)
  }
  async getList ({ ordering = this.idColumn, filter = {}, limit = 50, offset = 0 } = {}) {
    const tableExpression = `
      (
        select
          ph.id,
          p.product_id as sku,
          p.product,
          p.market,
          p.supplier,
          ph.date,
          ph.month,
          ph.buyprice,
          ph.sellprice,
          ph.discount
          from
          real.price_history ph inner join avocado.vw_products p on p.product_id = ph.sku
      ) t
    `
    return PGAdapter.prototype.getList.call(this, {ordering, filter, limit, offset, tableExpression})
  }

  async create (data) {
    const res = await PGAdapter.prototype.create.call(this, data)
    if (res) {
      await this.clearCaches(data)
    }
    return res
  }

  async upsert (data) {
    const res = await PGAdapter.prototype.upsert.call(this, data, {conflictIdColumns: ['sku', 'date']})
    if (res) {
      await this.clearCaches(data)
    }
    return res
  }

  async update (data) {
    const res = await PGAdapter.prototype.update.call(this, data)
    if (res) {
      await this.clearCaches(data)
    }
    return res
  }

  async delete (id) {
    const obj = await this.getOne(id)
    if (obj) {
      const res = await PGAdapter.prototype.delete.call(this, id)
      if (res) {
        await this.clearCaches(obj)
      }
      return res
    }
  }

  async clearCaches ({date, sku}) {
    if (date && sku) {
      this.logger.debug('clearing cached values')
      await this.pgConnection.query(`DELETE FROM real.buyprice_daily WHERE date >= $1 and sku = $2;`, [date, sku])
      await this.pgConnection.query(`DELETE FROM real.sellprice_daily WHERE date >= $1 and sku = $2;`, [date, sku])
      await this.pgConnection.query(`
          DELETE
          FROM real.cost_basis
          WHERE date >= $1
          AND location_sku in (
              SELECT DISTINCT location_sku
              FROM real.deliveries
              WHERE sku = $2);`, [date, sku])
    }
  }
}

module.exports = {PricePGAdapter}
