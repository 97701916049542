import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const UppercaseLabel = ({ children, dark, deemphasized }) => (
  <span className={classnames(
    'vs-uppercase-label',
    {'vs-uppercase-label--deemphasized': deemphasized},
    {'vs-uppercase-label--dark': dark}
  )}>
    { children }
  </span>
)

UppercaseLabel.propTypes = {
  children: PropTypes.node.isRequired
}

export default UppercaseLabel
