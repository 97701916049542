import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CheckmarkCircle } from '../../icons'

const SelectableListItem = (props) => {
  const {
    children,
    isSelected,
    className,
    handleClick,
    isDisabled,
    withMarker
  } = props

  const selectionMarker = (
    isSelected ? (
      <span
        className='vs-selectable-list-item__trigger vs-selectable-list-item__trigger--selected'
      >
        {withMarker && (
          <span className='vs-selectable-list-item__icon'>
            <CheckmarkCircle />
          </span>
        )}
        Selected
      </span>
    ) : (
      <span className='vs-selectable-list-item__trigger'>
        Select
      </span>
    )
  )

  return (
    <div
      className={classnames(
        'vs-selectable-list-item',
        {'vs-selectable-list-item--selected': isSelected},
        {'vs-selectable-list-item--disabled': isDisabled},
        className
      )}
      onClick={!isDisabled ? handleClick : undefined}
    >
      <div className='vs-selectable-list-item__content'>
        {children}
      </div>
      {selectionMarker}
    </div>
  )
}

SelectableListItem.propTypes = {
  /**
   * A text/element to be shown as a content of the component
   */
  children: PropTypes.node,
  /**
   * Whether or not the item should show in selected state
   */
  isSelected: PropTypes.bool,
  /**
   * In case you want to extend the default class names without overriding the default look.
   * e.g adding custom padding class `className='add-padding'`
   */
  className: PropTypes.string,
  /**
   * A callback to what should happen when element is clicked
  */
  handleClick: PropTypes.func,
  /**
   * Determines if "checkmark" icon should be shown
   */
  withMarker: PropTypes.bool
}

SelectableListItem.defaultProps = {
  children: undefined,
  isSelected: false,
  className: '',
  handleClick: undefined,
  withMarker: true
}

export default SelectableListItem
