import React from 'react'
import PropTypes from 'prop-types'

const PrintHeader = (props) => {
  const {
    logoUrl,
    appName,
    pageTitle,
    subtitle
  } = props
  return (
    <div className='print-header'>
      {logoUrl && (
        <img src={logoUrl} alt={appName} width='100' />
      )}
      <div className='print-header__info'>
        <h1 className='print-header__title'>
          {pageTitle}
        </h1>
        {subtitle && (
          <div>
            {subtitle}
          </div>
        )}
      </div>
    </div>
  )
}

PrintHeader.displayName = 'PrintPage.Header'

PrintHeader.propTypes = {
  /**
   Optional logo to be printed in the header, usually pulled from config
   */
  logoUrl: PropTypes.string,
  /**
   App name used in the header if logo image is not found, usually pulled from config
   */
  appName: PropTypes.string,
  /**
   Page title printed in header
   */
  pageTitle: PropTypes.string.isRequired,
  /**
   Optional subtitle, e.g. the date or location for a report
   */
  subtitle: PropTypes.string
}

export default PrintHeader
