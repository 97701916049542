const startCase = require('lodash/startCase')
const { PouchAdapter } = require('../../../common')
const schema = require('./schema')
const {entityCreateToDoc, entityEditToDoc, validateLocationCreate, docToEntity} = require('../../tools')

const {fieldsMap} = require('../../tools')

class LocationPouchAdapter extends PouchAdapter {
  constructor (user, locationsDB) {
    super(user, schema, locationsDB)
  }

  listAdditionalFields () {
    return fieldsMap
      .filter(field => field.showInFS)
      .map(field => {
        const id = field.docProperty.replace('additionalData.', '')
        return {
          id,
          label: startCase(id),
          disabled: field.disabledInFS,
          fieldType: field.fieldType
        }
      })
  }

  async getByUUIDs (uuids) {
    const selector = {'additionalData.uuid': {'$in': uuids}}
    const {docs} = await this.pouchDB.find({
      selector,
      // i.e. no limit
      limit: Number.MAX_SAFE_INTEGER
    })
    const date = new Date().toJSON()
    return docs.map(doc => docToEntity({ doc, date }))
  }

  async bulkCreate (locationEntities, effectiveDate = new Date().toJSON()) {
    if (!locationEntities.length) return []

    const docs = locationEntities.map(entity => {
      const invalidMessage = validateLocationCreate(entity, effectiveDate)
      if (invalidMessage) {
        throw new Error(invalidMessage)
      }

      return entityCreateToDoc({ edits: entity, effectiveDate })
    })

    const response = await this.pouchDB.bulkDocs(docs)
    return docs.map((doc, index) => {
      if (response[index].error) return response[index]

      return docToEntity({ doc, date: effectiveDate })
    })
  }

  // Unlike our current dal.getRawDocByIds, this throws an error if an id is not found.
  // Other API methods should probably consider which of the two they need.
  // For example getting stock count overview may not want to hard fail on a missing location.
  async getDocsByIdsHardFail (locationIds) {
    const response = await this.pouchDB.allDocs({keys: locationIds, include_docs: true})
    return response.rows.map(row => {
      if (row.error) {
        throw new Error(`Location id not found: ${row.key}`)
      }

      return row.doc
    })
  }

  // TODO: like the product pouch adapter, don't upsert if the doc has not changed
  async bulkUpdate (updatedEntities) {
    const effectiveDate = new Date().toJSON()

    const ids = updatedEntities.map(entity => entity._id)
    const docs = await this.getDocsByIdsHardFail(ids)
    const updatedDocs = updatedEntities
      .map((edits, index) => entityEditToDoc({edits, effectiveDate, currentDoc: docs[index]}))

    const response = await this.pouchDB.bulkDocs(updatedDocs)
    return response.map((row, index) => {
      if (row.error) return row

      return updatedEntities[index]
    })
  }
}

module.exports = LocationPouchAdapter
