import { userIsAuthorised } from '../van-shared/utils/auth'
import { documentInfo, driverDocumentInfo, hasUserContract } from '../subapps/settings/common/utils'

export const getPendingContracts = async (user, api, locationId) => {
  const isRetailer = userIsAuthorised(user, 'feature:userRole:pharmacyUser')
  const driverWithContracts = hasUserContract(user)

  let pendingContracts
  let outdatedContracts = []
  // pending contracts need for shelf life for showing bubble in contracts title
  // whether for retailers or drivers
  if (isRetailer) {
    const { pending, outdated } = await documentInfo(api, locationId)
    pendingContracts = pending
    outdatedContracts = outdated
  } else if (driverWithContracts) {
    const { pending } = await driverDocumentInfo(api, user._id)
    pendingContracts = pending
  }
  return { pending: pendingContracts, outdated: outdatedContracts }
}
