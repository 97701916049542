class ProductSubscriptionRestAdapter {
  constructor (restAdapter) {
    this.restAdapter = restAdapter
  }

  async add (subscriptions) {
    return this.restAdapter.create('productsubscription', subscriptions)
  }

  async update (subscription) {
    return this.restAdapter.update(`productsubscription/${subscription.id}`, subscription)
  }

  async list ({isActive = null, limit = 10000} = {}) {
    let url = `productsubscription/?limit=${limit}`
    if (isActive) {
      url += '&active'
    }
    let results = []
    // Fetch until we collected all records
    while (true) {
      const resp = await this.restAdapter.get(url)
      results = results.concat(resp.results)
      url = resp.next
      if (!url) {
        break
      }
    }
    return results
  }
}

module.exports = ProductSubscriptionRestAdapter
