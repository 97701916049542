import React from 'react'

import { ExpandableOptions, Page } from '@fielded/shared-ui'

import { getNavigationItem } from '../../../common/utils/navigationInfo'
import { userIsAuthorised } from '../../../van-shared/utils/auth'

import Timeframe from './Timeframe'
import ReportingPeriodJumper from './ReportingPeriodJumper'

function enableFutureDate (activeWeekId, currentWeekId) {
  // get the active week and year
  const activeWeek = +activeWeekId.slice(-2)
  const activeYear = +activeWeekId.slice(0, 4)

  // get the current week and year (selected period)
  const currentWeek = +currentWeekId.slice(-2)
  const currentYear = +currentWeekId.slice(0, 4)

  // first we check if the current year is less than the active year (ie in the past), otherwise
  // we check if the current week is less than the active week
  return currentYear < activeYear ? true : currentWeek < activeWeek
}

const Header = ({
  config,
  breadcrumbItems = [],
  periods,
  prevPeriodUrl,
  nextPeriodUrl,
  program,
  programUrl,
  title,
  user = {},
  handlePeriodJump
}) => {
  const userAuthorizedForTimeControls = userIsAuthorised(user, 'feature:userRole:planner') || userIsAuthorised(user, 'feature:userRole:operator') || userIsAuthorised(user, 'feature:userRole:external-planner')
  const showTimeControls = !config.features.stockCount.adminOnlyHistory || userAuthorizedForTimeControls
  const hasMultiplePrograms = user.programs && user.programs.length > 1
  // only enabled for PSM as per request
  const enableDatePicker = config.features.stockCount.historicalDatePicker
  const isPSM = config.app === 'psm'
  // const enableFutureDate = periods && isPSM ? true : isFutureDateUtil(periods.active.id, periods.current.id)
  const isBimonthly = program && program.report.periods[0].periodType && program.report.periods[0].periodType.quantity === 2

  return (
    <Page.ComposableHeader>
      <Page.ComposableHeader.Breadcrumb
        items={[
          {
            ...getNavigationItem({ item: 'reports', config, includePath: false }),
            [hasMultiplePrograms && 'path']: programUrl
          },
          ...breadcrumbItems
        ]}
      />
      <div className='overview-header'>
        <Page.ComposableHeader.Content
          spread
        >
          <div className='overview-header__content'>
            <Page.ComposableHeader.Title title={title} />
            <div className='overview-header__timeframe'>
              {!enableDatePicker && periods && showTimeControls && <Timeframe
                title={periods.current.display}
                prevPeriodUrl={prevPeriodUrl}
                nextPeriodUrl={nextPeriodUrl}
                enableFutureDate={isPSM ? true : enableFutureDate(periods.active.id, periods.current.id)}
              />}
              {enableDatePicker && periods && showTimeControls && <ExpandableOptions
                label='Timeframe'
                labelClassName='overview-header__period-options-header'
                expandWrapperClassName='overview-header__period-options'
                visibleChildren={
                  <Timeframe
                    title={periods.current.display}
                    prevPeriodUrl={prevPeriodUrl}
                    nextPeriodUrl={nextPeriodUrl}
                    enableFutureDate={isPSM ? true : enableFutureDate(periods.active.id, periods.current.id)}
                  />
                }
                expandableChildren={
                  <ReportingPeriodJumper
                    isBimonthly={isBimonthly}
                    handlePeriodJump={handlePeriodJump}
                  />
                }
              />
              }
            </div>
          </div>
        </Page.ComposableHeader.Content>
      </div>
    </Page.ComposableHeader>
  )
}

export default Header
