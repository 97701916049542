export const getLocationsByLevelOrder = (locations) => {
  const locationsByLevel = {}
  for (const location of locations) {
    const idParts = location._id.split(':')
    let path = ''
    for (let i = 0; i < idParts.length; i += 2) {
      const [level, name] = idParts.slice(i, i + 2)
      const existing = locationsByLevel[level] || []
      path = path + (path ? ':' : '') + `${level}:${name}`
      locationsByLevel[level] = existing.concat(path)
    }
  }
  let locationsForVertical = []
  const levelsOrder = ['country', 'state', 'name', 'lga', 'sdp']
  for (const level of levelsOrder) {
    locationsForVertical = locationsForVertical.concat(locationsByLevel[level] || [])
  }

  return [...new Set([...locationsForVertical])]
}
