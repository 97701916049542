const { construct } = require('../../tools')

function getByIds (state, ids, { date = new Date().toISOString() } = {}) {
  return getRaw(state, ids)
    .then(docs => docs.map(doc => construct(doc, { now: date })))
}

function getRaw (state, ids) {
  return state.productsDB.allDocs({
    keys: ids,
    include_docs: true
  })
    .then(results => results.rows
      .map(r => r.doc)
      .filter(r => r)
    )
}

function get (state, id) {
  return state.productsDB.get(id)
}

function getByAlias (state, aliasValue, aliasName) {
  const viewName = `by-${aliasName}-alias`
  const query = { include_docs: true }
  if (Array.isArray(aliasValue)) {
    query.keys = aliasValue
  } else {
    query.key = aliasValue
  }

  return state.productsDB
    .query(viewName, query)
    .catch((err) => {
      if (err.status === 404) {
        throw new Error(`No design doc found for view "${viewName}"`)
      }
      throw err
    })
}

function listAll (state) {
  return state.productsDB.allDocs({
    startkey: 'product:',
    endkey: 'product:\ufff0:',
    include_docs: true
  })
    .then((results) => results.rows.map(r => r.doc))
}

function getProgramConfiguration (state, programId) {
  return state.productsDB.allDocs({
    startkey: `configuration:${programId}:service:`,
    endkey: `configuration:${programId}:service:\ufff0`,
    include_docs: true
  })
    .then((result) => result.rows.map(r => r.doc))
}

function getServiceConfiguration (state, serviceId) {
  return state.productsDB.allDocs({
    key: `configuration:${serviceId}`,
    include_docs: true
  })
    .then((result) => result.rows.map(r => r.doc))
}

module.exports = {
  getRaw,
  getByIds,
  get,
  getByAlias,
  listAll,
  getProgramConfiguration,
  getServiceConfiguration
}
