const { sanitiseBatchId } = require('./../batch/tools')

// removes duplicate batches from stock count. so if there's a duplicate batch with numbers batchNo:OPV2 and batchNo:opv2, the batch will be recorded as batchNo:OPV2 and the value of the last stock batch will be used as the stock count. See https://github.com/fielded/van-orga/issues/2585
function normaliseStockBatches (stock) {
  Object.keys(stock).forEach((currentProductId) => {
    if (stock[currentProductId].batches) {
      const batchesMap = new Map()
      for (const key in stock[currentProductId].batches) {
        batchesMap.set(sanitiseBatchId(key), stock[currentProductId].batches[key])
      }

      // convert batches map to object
      const batches = Object.create(null)
      for (const [batchId, stockCount] of batchesMap) {
        batches[batchId] = stockCount
      }

      stock[currentProductId].batches = batches
    }
  })
  return stock
}

module.exports = normaliseStockBatches
