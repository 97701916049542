import { parse } from '@fielded/fs-api/lib/tools/smart-id'
import { byId } from '../common/utils'

export const getDecoratedBatchesById = (batches) => {
  if (!batches) return {}

  const decoratedBatches = batches.map(batch => {
    const { manufacturer, batchNo } = parse(batch._id)
    return {
      ...batch,
      manufacturerName: batch.manufacturer,
      manufacturer,
      batchNo
    }
  })
  return {
    byId: byId(decoratedBatches, '_id')
  }
}

export const getProcessedMasterData = ({ service, products, locations, fields, batches, manufacturers }) => {
  if (products) {
    products = {
      byId: products,
      allIds: Object.keys(products)
    }
  }

  if (locations) {
    locations = {
      byId: byId(locations, '_id')
    }
  }

  if (fields) {
    fields = {
      byId: byId(fields, 'id'),
      allIds: fields.map(field => field.id)
    }
  }

  if (batches) {
    batches = getDecoratedBatchesById(batches)
  }

  if (manufacturers) {
    manufacturers = {
      byId: byId(manufacturers),
      allIds: manufacturers.map(manufacturer => manufacturer.id)
    }
  }

  return { service, products, locations, fields, batches, manufacturers }
}
