import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import clasnames from 'classnames'
import get from 'lodash/get'

import { PriceDisplay, QuantityDisplay } from '@fielded/shared-ui'

const StockCountTable = ({
  stockCountRows,
  fieldsById,
  expiryRows
}) => {
  // the extra col is for the product column, which is hardcoded and not included in fields
  const columnCount = Object.keys(fieldsById).length + 1
  const hasPhysicalCountField = fieldsById['field:standard-physical-count']
  let hasIssue
  return (
    <table className='stock-count-table'>
      <thead>
        <tr className='stock-count-table__tr'>
          <th className='stock-count-table__products'>Products</th>
          {Object.keys(fieldsById).map(fieldId => (
            <th className='stock-count-table__th' key={`header-${fieldId}`}>{fieldsById[fieldId].name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {stockCountRows.map(row => {
          if (row.isProductLine) {
            return (
              <tr className='stock-count-table__category' key={`${row.name}`}>
                <td colSpan={columnCount}>{row.name}</td>
              </tr>
            )
          }
          if (row.isTotal) {
            return (
              <tr key={`${row.name}`}>
                <td colSpan={Object.keys(fieldsById).length}>{row.name}</td>
                <td><PriceDisplay value={row.total} /></td>
              </tr>
            )
          }

          const fields = row.fields
          let productInfo = row.name
          const managedProduct = !row.disabled
          if (row.basicUnit) {
            productInfo += ` – ${row.basicUnit}`
          }

          if (hasPhysicalCountField) {
            const physicalCount = get(fields, 'field:standard-physical-count.amount')
            hasIssue = !physicalCount && physicalCount !== 0
          }

          return (
            <tr
              className={clasnames(
                'stock-count-table__tr',
                {'stock-count-table__tr--issue': hasIssue},
                {'stock-count-table__unmanaged--product': !managedProduct},
                {'stock-count-table__tr--expiry': row.expirySum}
              )}
              key={`${row.id}`}
            >
              <td>{productInfo}</td>
              {Object.keys(fieldsById).map(fieldId => {
                const field = fields[fieldId] || {}
                const fieldKey = fieldId.replace('field:', '')
                return (
                  <td className={`stock-count-table__${fieldKey}`} key={`${row._id}-${fieldId}`}>
                    {field && typeof field.amount === 'number'
                      ? <QuantityDisplay value={field.amount} />
                      : field.amount || ''
                    }
                    {(field && typeof field.price === 'number') && (
                      <PriceDisplay value={field.price} />
                    )}
                    {(fieldKey === 'standard-physical-count' && row.expirySum) && (
                      <span className='stock-count-table__expiry-sum'>
                        qty expired/expiring: {row.expirySum}
                      </span>
                    )}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
      {expiryRows && (
        <Fragment>
          <thead>
            <tr>
              <th colSpan={columnCount} className='stock-count-table__expiry-header'>
                Stock expired or due to expire
              </th>
            </tr>
            {expiryRows.length > 0 && (
              <tr>
                <th className='stock-count-table__products'>Product</th>
                <th className='stock-count-table__th' colSpan={columnCount - 4} style={{ textAlign: 'left' }}>Batch number</th>
                <th className='stock-count-table__th'>Expiry date</th>
                <th className='stock-count-table__th'>Quantity expired/expiring</th>
                <th className='stock-count-table__th' />
              </tr>
            )}
          </thead>
          <tbody>
            {expiryRows.length > 0 ? expiryRows.map(row => (
              <tr
                className={clasnames(
                  'stock-count-table__tr',
                  'stock-count-table__tr--expiry',
                  {'stock-count-table__tr--issue': row.expired}
                )}
                key={`expiry-row-${row.number}`}
              >
                <td className='stock-count-table__products'>{row.productName}</td>
                <td colSpan={columnCount - 4} style={{ textAlign: 'left' }}>
                  <span className='vs-u-font-regular-number'>{row.number}</span>
                </td>
                <td>{row.expiry}</td>
                <td>{row.count}</td>
                <td />
              </tr>
            )) : (
              <tr>
                <td colSpan={columnCount}>
                  <div className='stock-count-table__no-expiry'>
                    No expired or expiring products entered
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Fragment>
      )}
    </table>
  )
}

StockCountTable.propTypes = {
  /**
   * The count rows with data per product, product line headers, etc.
   */
  stockCountRows: PropTypes.array,
  /**
   * Field definitions, make up the column headers of the table
   */
  fieldsById: PropTypes.object,
  /**
   * Rows of expriring batches
   */
  expiryRows: PropTypes.array
}

export default StockCountTable
