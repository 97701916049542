const cloneDeep = require('lodash/cloneDeep')

const NUM_MONTHS_OF_STOCK = 4

exports.distributeAvailableStock = distributeAvailableStock
function distributeAvailableStock (orders, ledgerForPackOut) {
  const groupedProductOrders = new Map()
  for (const order of orders) {
    const productIds = Object.keys(order.products)
    for (const productId of productIds) {
      const product = order.products[productId]
      const productOrders = groupedProductOrders.get(productId) || []
      productOrders.push(Object.assign({
        originalOrder: order
      }, product, {
        // We need to account for older orders not having SOH property
        soh: product.soh || 0
      }))
      groupedProductOrders.set(productId, productOrders)
    }
  }

  const updatedOrders = new Map()
  for (const [productId, productOrders] of groupedProductOrders.entries()) {
    const stock = ledgerForPackOut[productId]
    const availableQTOs = mapToEvenMOS(productOrders, stock)

    for (const [i, available] of availableQTOs.entries()) {
      const {originalOrder} = productOrders[i]
      const updatedOrder = updatedOrders.get(originalOrder._id) || cloneDeep(originalOrder)
      const orderProduct = originalOrder.products[productId]

      const mosRatio = ((orderProduct.soh + available) / (orderProduct.soh + orderProduct.adjusted))
      const mos = mosRatio * NUM_MONTHS_OF_STOCK

      updatedOrder.products[productId] = Object.assign({}, orderProduct, {
        adjusted: available,
        mos
      })
      updatedOrders.set(updatedOrder._id, updatedOrder)
    }
  }
  return [...updatedOrders.values()]
}

exports.mapToEvenMOS = mapToEvenMOS
function mapToEvenMOS (productOrders, stock) {
  const mosRatio = calculateMOSRatio(productOrders, stock)

  return productOrders.map(({soh, adjusted}) => {
    if (adjusted === 0 || soh / (soh + adjusted) > mosRatio) {
      // Ignore orders with more mos(soh) than the even mos
      return 0
    }

    const mos = soh + adjusted
    const availableMOS = mos * mosRatio
    const availableQTO = Math.max(0, availableMOS - soh)
    return availableQTO
  })
}

const calculateMOSRatio = (productOrders, stock, startMosRatio = 1) => {
  // Calculate the max months of stock we can evenly distribute
  //
  //                           (sum of orders SOH) + warehouse-stock
  // ratio of MOS available =  ------------------------------------------
  //                           (sum of orders SOH) + (sum of orders QTOs)
  //
  // This only works when all orders have less mos(soh) than then even
  // mos we can provide given the stock. If an order has more soh than
  // the even mos we target for, then we need to exclude that order and
  // run the calculation again. We do this until only those orders are
  // included that would not already have more mos than we can provide.
  let sumSOH = 0
  let sumQTO = 0
  const included = []
  for (const [i, {soh, adjusted}] of productOrders.entries()) {
    if (adjusted === 0 || soh / (soh + adjusted) > startMosRatio) {
      // We need to exclude orders who already have enough soh
      continue
    }
    sumSOH += soh
    sumQTO += adjusted
    included.push(i)
  }
  const evenMosRatio = (sumSOH + stock) / (sumSOH + sumQTO)
  if (evenMosRatio >= 1) {
    return 1
  }

  // Check if any of the orders already have more mos(soh) than the
  // even mos we want to provide them with.
  let rerun = false
  for (const i of included) {
    const {soh, adjusted} = productOrders[i]
    if (soh / (soh + adjusted) > evenMosRatio) {
      rerun = true
      break
    }
  }
  if (!rerun) {
    return evenMosRatio
  }
  return calculateMOSRatio(productOrders, stock, evenMosRatio)
}
