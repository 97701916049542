import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import { Download } from '../../icons'

const DownloadButton = ({ children, ...otherProps }) => {
  return (
    <Button
      colorVariant='brand'
      fill='outline'
      size='small'
      icon={<Download />}
      {...otherProps}
    >
      {children}
    </Button>
  )
}

DownloadButton.propTypes = {
  /**
   * Optionally override the standard button text
   */
  children: PropTypes.node
}

DownloadButton.defaultProps = {
  children: 'Export data'
}

export default DownloadButton
