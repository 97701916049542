import React, { Component } from 'react'
import classnames from 'classnames'

import { BackButton, FilterInput, Page } from '@fielded/shared-ui'
import { filterByProp } from '@fielded/shared-ui/src/components/FilterInput'

import ProductItem from '../components/ProductItem'
import ProductsSelectedButton from './ProductsSelectedButton'

class PlanningProducts extends Component {
  static defaultProps = {
    alreadyAddedProducts: new Set([])
  }

  state = {
    filterTerm: this.props.filterTerm
  }

  setFilterTerm = (filterTerm) => {
    this.setState({
      filterTerm
    })
  }

  render () {
    const {
      products,
      selectedProducts,
      alreadyAddedProducts,
      title,
      subTitle,
      onToggle,
      onClickNext,
      onGoBack,
      history,
      submitLabelText,
      showProductCode
    } = this.props

    const {
      filterTerm
    } = this.state

    const filteredProducts = filterByProp(products, filterTerm, 'name')

    let mainContent
    if (products.length === 0) {
      mainContent = (
        <Page.Panel>
          <Page.Panel.Message type='warning'>
            No products with a stock count.
          </Page.Panel.Message>
        </Page.Panel>
      )
    } else if (filteredProducts.length > 0) {
      mainContent = (
        <Page.Panel spread withMargin={false} className='product-bulk-selection'>
          <ul className='selectable-list'>
            {filteredProducts.map(product => {
              const isAlreadyAdded = alreadyAddedProducts.has(product._id)
              return <ProductItem
                product={product}
                disabled={isAlreadyAdded}
                selected={isAlreadyAdded}
                onToggle={() => onToggle(product._id)}
                key={product._id}
                showProductCode={showProductCode}
              />
            })}
          </ul>
        </Page.Panel>
      )
    } else {
      mainContent = (
        <Page.Panel>
          No products found
        </Page.Panel>
      )
    }
    return (
      <Page>
        <Page.Header
          title={title}
          backButton={<BackButton onGoBack={onGoBack} history={history}>go back</BackButton>}
        >
          {subTitle}
        </Page.Header>
        <Page.Intro>
          <FilterInput
            onValueChange={(value) => this.setFilterTerm(value)}
            placeholder='Filter by product name'
            value={this.state.filterTerm}
          />
        </Page.Intro>
        {mainContent}
        <Page.Footer
          className={classnames(
            'product-bulk-selection__footer',
            { 'product-bulk-selection__footer--visible': selectedProducts > 0 }
          )}
        >
          <ProductsSelectedButton
            selectedProducts={selectedProducts}
            onClickNext={onClickNext}
            submitLabelText={submitLabelText}
          />
        </Page.Footer>
      </Page>
    )
  }
}

export default PlanningProducts
