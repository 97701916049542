import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { byId } from '../common/utils'
import { getDecoratedBatchesById, getProcessedMasterData } from './utils'
import { hasNewRootReducer, updateCurrentMasterData } from '../../../van-shared/utils/utils'

const activeChangesFeeds = {
  batches: false
}

const maybeSubscribeToChanges = (api, dispatch) => {
  if (!activeChangesFeeds.batches) {
    activeChangesFeeds.batches = true
    return api.batch.onListUpdated(batches => dispatch(updateBatches(batches)))
  }
}

const initialState = {
  batches: { byId: {} },
  fields: { byId: {}, allIds: [] },
  locations: { byId: {} },
  manufacturers: { byId: {}, allIds: [] },
  products: { byId: {}, allIds: [] },
  service: {
    fullName: '',
    id: '',
    name: '',
    locations: [],
    program: {}
  },
  error: null
}

// Since we added the expiry forms, all services
// will use batches in the reporting in some way
export const fetchMasterData = createAsyncThunk('reports/fetchMasterData', async (args, thunkAPI) => {
  const { serviceId, locationId, productIdsFromReport } = args
  const { extra: { api }, dispatch, getState } = thunkAPI

  const withNewRootReducer = hasNewRootReducer()
  const { batches } = withNewRootReducer ? getState().masterDataReports : getState().masterData

  const promises = [
    api.service.get(serviceId),
    api.product.getByIds(productIdsFromReport).then(products => byId(products, '_id')),
    api.location.listChildren(locationId, {deep: true, includeSelf: true, filters: {services: [serviceId]}}),
    api.service.listReportFields(serviceId),
    api.batch.listManufacturers(serviceId)
  ]

  const batchesLoaded = batches && batches.byId && Object.keys(batches.byId).length
  if (!batchesLoaded) {
    const batchPromise = maybeSubscribeToChanges(api, dispatch)
    promises.push(batchPromise || api.batch.list())
  } else {
    promises.push(Object.values(batches.byId))
  }

  try {
    const response = await Promise.all(promises)
    const [service, products, locations, fields, manufacturers, batches] = response
    const processedMasterData = getProcessedMasterData({ service, products, locations, fields, batches, manufacturers })
    return processedMasterData
  } catch (error) {
    return error
  }
}, {
  condition: (args, thunkAPI) => {
    const { serviceId, locationId } = args
    const { getState } = thunkAPI

    const withNewRootReducer = hasNewRootReducer()
    const { service, locations } = withNewRootReducer ? getState().masterDataReports : getState().masterData

    const serviceLoaded = service && service.id === serviceId
    const locationLoaded = locations && locations.byId[locationId]
    return !(serviceLoaded && locationLoaded)
  }
})

export const masterDataSlice = createSlice({
  name: 'masterData',
  initialState,
  reducers: {
    updateBatches: (state, action) => {
      const batches = action.payload
      state.batches = getDecoratedBatchesById(batches)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMasterData.fulfilled, (state, action) => {
        if (Object.keys(action.payload).length) {
          const { service, products, locations, fields, batches, manufacturers } = action.payload
          state.service = service
          state.products = products
          state.locations = locations
          state.fields = fields
          state.batches = batches
          state.manufacturers = manufacturers
        }
      })
      .addCase(fetchMasterData.rejected, (state, action) => {
        state.error = action.payload
      })
  }
})

const { updateBatches } = masterDataSlice.actions

export const selectMasterData = (state) => {
  const newState = updateCurrentMasterData(state, 'masterDataReports')
  return newState.masterData
}

export const selectHasError = (state) => {
  const newState = updateCurrentMasterData(state, 'masterDataReports')
  return newState.masterData.error
}

export default masterDataSlice.reducer
