// We only have three ordertypes now routine and topup
exports.ORDER_TYPES_TO_PACK_POINT_TYPE = {
  routine: 'isCentral',
  topup: 'isLocal',
  resupply: 'isSupplier',
  return: 'isLocal',
  patient: 'isCentral',
  immediate_purchase: 'isCentral'
}

exports.EXPECTED_HEADERS = ['supplierId', 'destinationId', 'productId', 'quantity']

// To get suppliers when switching a plan's source
exports.ORDER_TYPES_FOR_PLAN_TRANSFER = {
  routine: 'pack-point',
  topup: 'pack-point',
  return: 'pack-point',
  resupply: 'supplier',
  patient: 'pack-point',
  immediate_purchase: 'pack-point'
}

exports.ORDER_TYPES = {
  routine: 'routine',
  topup: 'topup',
  resupply: 'resupply',
  purchase: 'purchase',
  immediate_purchase: 'immediate_purchase', // These are on-line paid purchases
  fast_immediate_purchase: 'fast_immediate_purchase',
  interwarehouse: 'interwarehouse' // These are interwarehouse orders
}

exports.PAYMENT_STATUSES = {
  paid: 'paid',
  unpaid: 'unpaid',
  client_side_paid: 'client_side_paid'
}

exports.PAYMENT_CHOICES = {
  prepaid: 'prepaid',
  on_delivery: 'on_delivery',
  payment_plan: 'payment_plan',
  fixed_term_payment: 'fixed_term_payment'
}

exports.ORDER_TYPES_TO_SUPPLIER_TYPE = {
  routine: 'routine',
  topup: 'top-up',
  resupply: 'resupply',
  return: 'top-up',
  patient: 'routine',
  immediate_purchase: 'immediate_purchase',
  fast_immediate_purchase: 'top-up'
}

// Properties that are shared between order and shipment snapshots
exports.ORDER_SHIPMENT_SHARED_PROPS = [
  'funderId', 'totalAmount', 'paymentStatus', 'routeId',
  'orderAmount', 'deliveryFee', 'patientName', 'patientId', 'paymentChoice'
]

exports.SUBORDER_STATUS = {
  inTransit: 'in-transit',
  packed: 'packed',
  received: 'received',
  failed: 'failed'
}

exports.SUPPLY_PLAN_STATUS = {
  PENDING: 'pending',
  PLANNED: 'planned',
  APPROVED: 'approved'
}
