const { importAllocations, resetAllocationsImport } = require('./import-allocations')

class TransactionApi {
  constructor (state, mainApi, restApi) {
    this.state = state
    this.mainApi = mainApi
    this.restApi = restApi
  }

  async importAllocations () {
    const db = this.state.allocationsDB
    return importAllocations(this.restApi, db)
  }

  async resetAllocationsImport () {
    const db = this.state.allocationsDB
    return resetAllocationsImport(db)
  }
}

module.exports = TransactionApi
