import React, { Component } from 'react'

import { Banner, Loading } from '@fielded/shared-ui'

import { withApi } from '../../../common/ApiProvider'

import PlanningProducts from '../../shipments/planning/PlanningProducts'

import { clearReportCache } from './withReport'
import { buildOpenReportUrl } from '../app/routes-urls'

class AddProductContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      error: null,
      products: null,
      reportProducts: null
    }
  }

  async componentDidMount () {
    const {
      match: {
        params: {
          reportId
        }
      },
      api
    } = this.props

    let draft
    try {
      draft = await api.report.draft.get({reportId})
    } catch (err) {
      console.log(err)
      return this.setState({
        error: 'Error loading report draft'
      })
    }

    let products
    const {serviceId} = draft
    try {
      products = await api.product.listForServices([serviceId])
    } catch (err) {
      console.error(err)
      return this.setState({
        error: 'Error loading products'
      })
    }

    const reportProducts = new Set(Object.keys(draft.stock))

    this.setState({
      loaded: true,
      error: null,
      products,
      reportProducts
    })
  }

  handleToggle = (productId) => {
    const {
      products
    } = this.state
    for (const p of products) {
      if (p._id === productId) {
        p.selected = !p.selected
      }
    }
    this.setState({ products })
  }

  handleConfirm = async () => {
    const {
      api,
      match: {
        params: {
          reportId
        }
      },
      history
    } = this.props
    const {
      products
    } = this.state

    const productIds = products.filter(p => p.selected).map(p => p._id)

    try {
      await api.report.draft.addProducts({reportId, productIds})
    } catch (e) {
      console.error(e)
      return this.setState({
        error: 'Error updating count with new products.'
      })
    }
    // The `withReport` HOC keeps the last report id in a module global var
    // We need to reset that here, so that the report in the report-entry
    // will be reloaded and gets the changes from here.
    clearReportCache()
    history.push(buildOpenReportUrl({reportId, productId: productIds[0]}))
  }

  render () {
    const {
      history
    } = this.props
    const {
      loaded,
      error,
      products,
      reportProducts
    } = this.state

    if (error) {
      return <Banner inline type='warning'><p>{error}</p></Banner>
    }
    if (!loaded) {
      return <Loading loadingText='Loading ...' />
    }

    const alreadyAddedProducts = reportProducts
    const numProducts = products.filter(p => p.selected).length

    return (
      <PlanningProducts
        products={products}
        selectedProducts={numProducts}
        alreadyAddedProducts={alreadyAddedProducts}
        history={history}
        onClickNext={this.handleConfirm}
        onToggle={this.handleToggle}
        showProductCode
        headerText={'Select products to add to count'}
        submitLabelText={'Confirm'}
        breadcrumbItems=''
      />
    )
  }
}

export default withApi(AddProductContainer)
