const { byId } = require('../by-id')

// merges the couch/pouch response of [{ ok: true, rev: '1-rev' }}]
// into the created/updated docs
exports.mergeCouchResponse = function (docs, response) {
  if (docs.length !== response.length) {
    throw new Error('PouchDB/CouchDB response length does not match length of posted docs')
  }

  const errorFound = response.find(row => row.error)

  if (errorFound) {
    throw new Error(`Error found in PouchDB/CouchDB response list, ${errorFound.id}`)
  }

  const responsesById = byId(response)

  return docs.map(doc => {
    if (responsesById[doc._id]) {
      return Object.assign({}, doc, {
        _rev: responsesById[doc._id].rev
      })
    }

    return doc
  })
}
