import React from 'react'

import { BatchNumber, Button, Page } from '@fielded/shared-ui'

import { Arrow } from '@fielded/shared-ui/src/icons'
import { formatDate, toTitleCase } from '../../../../van-shared/utils/utils'

const BatchRow = ({ quantity, batchNo }) => {
  return <li><BatchNumber className='new-psm-shipment__products-header' bold>{batchNo}</BatchNumber><span className='new-psm-shipment__products-header' /><span className='new-psm-shipment__products-header'>{quantity}</span></li>
}

const ProductList = ({ products, quantities, batches = [] }) => {
  return (
    <div className='new-psm-shipment__summary-product-list'>
      <div className='new-psm-shipment__product-header'>
        <span className='new-psm-shipment__products-header'>Product / Batch #</span>
        <span className='new-psm-shipment__products-header' />
        <span className='new-psm-shipment__products-header'>Quantity</span>
      </div>
      <div className='new-psm-shipment__summary-product-list'>
        {products.map((product) => {
          const productBatches = batches.filter((batch) => batch.productId === product._id)
          const total = productBatches.length > 0 ? productBatches.map(b => b.quantity).reduce((total, next) => total + next) : quantities[product._id]
          return (
            <div className='new-psm-shipment__summary-product-wrapper' key={product._id}>
              <div className='new-psm-shipment__summary-product-total'>
                <span className='new-psm-shipment__products-header'>{product.fullName}</span>
                <span className='new-psm-shipment__products-header' /><span className='new-psm-shipment__products-header'>{total}</span>
              </div>
              <ul className='new-psm-shipment__batch-list'>
                {productBatches.map((batch) => <BatchRow quantity={batch.quantity} batchNo={`Batch #${batch.batchNumber}`} key={batch._id} />)}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Header = ({ date, destination, origin, funder, program }) => {
  return (
    <div className='new-psm-shipment__summary-header'>
      <div className='new-psm-shipment__summary-wrapper'>
        <span className='new-psm-shipment__summary-header-label'>Program:</span>
        <span className='new-psm-shipment__summary-header-content'>{program}</span>
      </div>
      <div className='new-psm-shipment__summary-wrapper'>
        <span className='new-psm-shipment__summary-header-label'>Date of Arrival: <span /></span>
        <span className='new-psm-shipment__summary-header-content'>{formatDate(date, 'long')}</span>
      </div>
      <div className='new-psm-shipment__summary-wrapper'>
        <span className='new-psm-shipment__summary-header-label'>Origin:</span>
        <span className='new-psm-shipment__summary-header-content'>{toTitleCase(origin.name)}</span>
      </div>
      <div className='new-psm-shipment__summary-wrapper'>
        <span className='new-psm-shipment__summary-header-label'>Destination:</span>
        <span className='new-psm-shipment__summary-header-content'>{toTitleCase(destination.name)}</span>
      </div>
      {funder && funder.label && <div className='new-psm-shipment__summary-wrapper'>
        <span className='new-psm-shipment__summary-header-label'>Funder:</span>
        <span className='new-psm-shipment__summary-header-content'>{funder.label}</span>
      </div>}
    </div>
  )
}

export default ({ handleSubmit, goBack, shipment }) => {
  return (
    <Page situation='ready'>
      <Page.Intro title='Confirm details' centered />
      <Page.Panel withBackground className='new-psm-shipment__summary-container'>
        <Header date={shipment.date} destination={shipment.destination} origin={shipment.origin} funder={shipment.funder} program={shipment.program.name} />
        <ProductList products={shipment.products} quantities={shipment.quantities} batches={shipment.batches} />
        <div className='new-psm-shipment__button-wrapper'>
          <Button
            onClick={goBack}
            icon={<Arrow direction='left' />}
            iconSide='left'
            colorVariant='brand'
            fill='outline'
          >
            Back
          </Button>
          <Button
            onClick={handleSubmit}
            icon={<Arrow direction='right' />}
            iconSide='right'
            colorVariant='brand'
            fill='full'
          >
            Submit
          </Button>
        </div>
      </Page.Panel>
    </Page>
  )
}
