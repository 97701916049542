const mergeStock = require('./merge-stock')
const mergeCommits = require('./merge-commits')
const mergeBatches = require('./merge-batches')
const filterEmptyStock = require('./filter-empty-stock')

module.exports = (state, params, current) => {
  const now = new Date().toISOString()
  // 1. ONE network REST API uses mergeCommits on default, meaning a full inventory update but leave commits.
  // 2. ONE network REST API uses `mergeBatches: true` if flagged, to update a specific batch and leave the rest the same.
  // The regular path (non-ONE network rest api) uses mergeStock
  let stock = {}
  if (params.mergeBatches) {
    stock = mergeBatches(current.stock, params.stock)
  } else if (params.mergeCommits) {
    stock = mergeCommits(current.stock, params.stock)
  } else {
    stock = mergeStock(current.stock, params.stock)
  }

  const count = Object.assign({}, current, {
    updatedAt: now,
    updatedBy: state.user.name,
    stock
  })

  if (params.submittedAt) {
    count.submittedAt = new Date(params.submittedAt).toJSON()
  }

  if (params.expiry) {
    count.expiry = filterEmptyStock(mergeStock(current.expiry || {}, params.expiry))
  }

  return count
}
