const PGAdapter = require('./../../common/pg-adapter')
const ROUTE_TABLE_NAME = 'avocado.data_route'
const ROUTE_COLUMNS = [
  'id',
  'couchdb_id',
  'couchdb_service_id',
  'name',
  'delivery_day',
  'delivery_frequency',
  'first_delivery_date',
  'count_day',
  'count_frequency',
  'first_count_date',
  'created_by',
  'created_at',
  'updated_by',
  'updated_at',
  'delivery_cycle_weeks',
  'count_cycle_weeks'
]

class RoutePGDataAdapter extends PGAdapter {
  constructor (pgConnection, username, logger) {
    super(
      pgConnection,
      ROUTE_TABLE_NAME,
      username,
      ROUTE_COLUMNS,
      undefined,
      undefined,
      logger
    )
  }
}

module.exports = RoutePGDataAdapter
