import get from 'lodash/get'

export function getShipmentWithSurveys (shipment, { sentSurveys, receivedSurveys }) {
  const rows = shipment.rows.map(row => {
    if (row.isProductRow) {
      return row
    }

    const sentVVM = get(sentSurveys, `${row.batch._id}.survey.vvm`)
    const receivedVVM = get(receivedSurveys, `${row.batch._id}.survey.vvm`)
    return {
      ...row,
      batch: { ...row.batch, sentVVM, receivedVVM }
    }
  })
  return { ...shipment, rows }
}
