const getProductsForFollowUp = require('../../../shipment/tools/get-products-for-follow-up')

module.exports = createFollowUpOrder
async function createFollowUpOrder (state, mainApi, shipment, dateObj = new Date()) {
  const { destination, origin, planningType } = shipment
  const negativeChanges = getProductsForFollowUp(shipment)

  const { snapshots } = await mainApi.order.createResupplySnapshots({
    data: negativeChanges.map(change => ({
      supplierId: origin.id,
      destinationId: destination.id,
      productId: change.productId.split(':')[1],
      quantity: 0 - change.diff
    })),
    deliveryDateObj: dateObj,
    orderType: planningType
  }, false)

  return mainApi.order.resupplyImport(snapshots)
}
