import React from 'react'
import { Link } from 'react-router-dom'

import { Button, DiffSummary, Page } from '@fielded/shared-ui'

import ShipmentHeader from '../common/ShipmentHeader'
import { isExternal, isShelflifePowered } from '../common/utils'
import { getFormattedDates } from '../pick-list/selectors'
import { getNavigationItem } from '../../../common/utils/navigationInfo'
import { isPsm } from '../../reports/common/utils'

const CompletedShipmentSummary = ({
  pickList,
  shipment,
  headerLocation,
  batches = {},
  config,
  isReturn,
  showAdjustment,
  isOperator
}) => {
  const formattedDates = getFormattedDates(shipment.date)

  let btn = null
  if (showAdjustment) {
    btn = (
      <Button
        component={Link}
        to={`/shipments/adjustment/${shipment.snapshotId}`}
        fill='outline'
        colorVariant='brand'
      >
        Adjust delivery
      </Button>
    )

    if (isReturn) {
      btn = (
        <Button
          fill='outline'
          colorVariant='brand'
          disabled
          title='Adjusting shipments is disabled for C&R and return shipments'
        >
          Adjust delivery
        </Button>
      )
    }
  }

  const products = pickList

  const breadcrumb = getNavigationItem({item: 'shipments', config})
  breadcrumb.path = `${breadcrumb.path}completed`
  const isReceived = shipment.status === 'received'

  let buttonText = 'Print Delivery Note'
  let buttonLink = `/shipments/complete/${shipment.snapshotId}/print`
  if (isReturn) {
    buttonText = 'Print Returns Note'
    buttonLink = `/shipments/returns-note/${shipment.snapshotId}`
  }

  // Before the delivery is received we only show one note: package note.
  if (!isReceived) {
    buttonText = 'Print Package Note'
  }

  if (isPsm(config)) {
    buttonLink = `/shipments/last-mile-deliveries/delivery-statement/${shipment.snapshotId}`
  }

  const packageNoteSnapshot = Object.keys(shipment.history).find(id => id.includes(`status:sent`))
  const showPackageNote = !isPsm(config) && !isReturn && isOperator && isReceived && packageNoteSnapshot

  return (
    <Page className='pick-list-complete'>
      <ShipmentHeader
        breadcrumbItems={[breadcrumb]}
        location={headerLocation}
        formattedDate={formattedDates.short}
        shipment={shipment}
        config={config}
        isCompleted
        hasShipmentDetails
      />

      <Page.Panel
        withMargin
        withBackground
        narrow
      >
        <Page.Panel.Section fullWidth>
          <DiffSummary
            intro={['The following quantities were saved:', btn]}
            batches={Object.keys(batches).length ? batches : null}
            productGroups={[{name: 'Products', products}]}
          />

        </Page.Panel.Section>

        {(isShelflifePowered(config) || !isExternal(shipment.snapshotId)) && (
          <Page.Panel.Section>
            <div className='pick-list-complete__print'>
              {showPackageNote && (
                <Button
                  component={Link}
                  to={{
                    pathname: `/shipments/complete/${packageNoteSnapshot}/print`,
                    state: { note: 'packageNote' }
                  }}
                  colorVariant='brand'
                  fill='full'
                >
                  Print Package Note
                </Button>
              )}
              <Button
                component={Link}
                to={buttonLink}
                colorVariant='brand'
                fill='full'
              >
                {buttonText}
              </Button>
            </div>
          </Page.Panel.Section>
        )}
      </Page.Panel>
    </Page>
  )
}

export default CompletedShipmentSummary
