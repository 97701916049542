import React, { Component } from 'react'

/**
 * Factory for creating a higher-order component that passes an `isOnline` prop indicating the
 * current network connectivity state.
 *
 * @param {ReactElement} WrappedComponent React component class to wrap.
 */
const withOfflineState = (WrappedComponent) =>
  class OfflineStateHOC extends Component {
    state = {
      isOnline: window.navigator.onLine
    }

    componentDidMount () {
      window.addEventListener('online', this.setOnline)
      window.addEventListener('offline', this.setOffline)
    }

    componentWillUnmount () {
      window.removeEventListener('online', this.setOnline)
      window.removeEventListener('offline', this.setOffline)
    }

    setRef = (ref) => {
      this.component = ref
    }

    setOnline = () => this.setState({ isOnline: true })

    setOffline = () => this.setState({ isOnline: false })

    isFunctionalComponent = (Component) => {
      return (
        typeof Component === 'function' && // can be various things
        !(
          Component.prototype && // native arrows don't have prototypes
          Component.prototype.isReactComponent // special property
        )
      )
    }

    render () {
      const { isOnline } = this.state
      const optionalProp = this.isFunctionalComponent(WrappedComponent) ? { } : { ref: this.setRef }
      return (
        <WrappedComponent
          {...optionalProp}
          isOnline={isOnline}
          {...this.props}
        />
      )
    }
  }

export default withOfflineState
