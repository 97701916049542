const { getDocsFromKeySearch } = require('../../../dal-adapters/utils')
const { PAYMENT_STATUSES, ORDER_TYPES } = require('../../constants')
const tools = require('../../tools')

// not having a limit defaults find queries to 25
// so we pretend to have no limit
const DEFAULT_LIMIT = Number.MAX_SAFE_INTEGER

exports.listOrdersForUser = listOrdersForUser
async function listOrdersForUser (state, programId, locationId, isComplete, relatedMarkets, forceAtSupplier, filterInterwarehouseOrders) {
  // this checks if we want to look for orders destined for somewhere or supplied by somewhere.
  // orders:requester always look for destination, but orders:warehouse
  // can look for either supplied by (most views) or destined somewhere (just the ProductQuantityAdjustContainer)
  const atSupplier = forceAtSupplier || ((locationId === state.user.location.id) && tools.isWarehouseUser(state.user))
  const params = getlistOrdersForUserParams({ programId, locationId, atSupplier, isComplete, relatedMarkets, filterInterwarehouseOrders })
  const { docs } = await state.orderDb.find(params)
  return docs
}

exports.listGroupsForDeliveryDates = listGroupsForDeliveryDates
async function listGroupsForDeliveryDates (state, programId, locationId, closedOrUnpaid = false) {
  const selector = {
    programId,
    destinationId: {
      '$gte': locationId,
      '$lt': tools.getLtForSelector(locationId)
    }
  }

  if (closedOrUnpaid) {
    selector['$or'] = [
      {
        isComplete: true
      }
    ]
  } else {
    selector['$and'] = [
      {
        $or: [
          {paymentStatus: PAYMENT_STATUSES.paid},
          {paymentStatus: PAYMENT_STATUSES.unpaid},
          { paymentStatus: { $exists: false } },
          {orderType: {$exists: false}},
          {orderType: {$ne: ORDER_TYPES.immediate_purchase}}
        ]},
      { isComplete: false }
    ]
  }

  const { docs } = await state.orderDb.find({ selector, limit: DEFAULT_LIMIT })
  return docs
}

function getlistOrdersForUserParams ({ programId, locationId, atSupplier, isComplete, relatedMarkets, filterInterwarehouseOrders }) {
  const params = {
    selector: {
      programId: {'$eq': programId}
    },
    limit: DEFAULT_LIMIT
  }

  if (typeof isComplete === 'boolean') {
    params.selector.isComplete = isComplete
  }

  // This is to optimize orders fetch to not include interwarehouse orders
  if (filterInterwarehouseOrders) {
    params.selector.orderType = { '$ne': ORDER_TYPES.interwarehouse }
  }

  // national users just see destination:anywhere
  if (locationId.startsWith('national')) {
    return params
  }

  if (atSupplier) {
    params.selector.supplierId = {'$eq': locationId}
  } else if (locationId.includes(':sdp:')) {
    // eq to avoid SDPs with identical starting names
    // sdp:hospital-a
    // sdp:hospital-a-west
    params.selector.destinationId = {'$eq': locationId}
  } else {
    // this will be a state location id, so everything in the state
    params.selector.destinationId = {
      '$gte': locationId,
      '$lt': tools.getLtForSelector(locationId)
    }
  }

  // optimize query for orders fetch in SL
  // locationId is expected to be scoped to the requesting market geoId e.g country:ng:state:fct
  // passing e.g 'country:ng' queries couch for all order docs per country, which is quite slow
  if (Array.isArray(relatedMarkets) && relatedMarkets.length) {
    delete params.selector.destinationId
    relatedMarkets.push(locationId)
    params.selector['$or'] = []
    relatedMarkets.forEach(marketGeoId => {
      params.selector['$or'].push({
        destinationId: {
          '$gte': marketGeoId,
          '$lt': tools.getLtForSelector(marketGeoId)
        }
      })
    })
  }
  return params
}

exports.listReportsByKeys = listReportsByKeys
async function listReportsByKeys (state, { keys }) {
  const response = await state.db.allDocs({
    include_docs: true,
    keys
  })
  return getDocsFromKeySearch(response)
}

exports.get = get
async function get (state, {orderId}) {
  const selector = {orderId: {'$eq': orderId}}
  if (tools.isWarehouseUser(state.user)) {
    selector.supplierId = {'$eq': state.user.location.id}
  }
  const {docs} = await state.orderDb.find({selector, limit: DEFAULT_LIMIT})
  return docs
}

// Exporting orders needs to always see orders at the accepted status,
// so it uses the param useAcceptedStatus: true
// see https://github.com/fielded/van-orga/issues/2802
// Snapshots are otherwise:
// 1. filtered for warehouse users, so they do not see suborders not meant for them
// 2. not filtered at all for state users, who created the orders and can see everything.
exports.getGroup = getGroup
async function getGroup (state, {groupId, useAcceptedStatus = false, onlyIncompleteSnaps = false}) {
  const selector = {groupId: {'$eq': groupId}}
  if (onlyIncompleteSnaps) {
    selector.isComplete = {'$eq': false}
  }

  const limitByUserLocation = useAcceptedStatus
    ? false
    : tools.isWarehouseUser(state.user)

  if (limitByUserLocation) {
    selector.supplierId = {'$eq': state.user.location.id}
  }
  const {docs} = await state.orderDb.find({selector, limit: DEFAULT_LIMIT})
  return docs
}

exports.getShipmentsOnOrder = getShipmentsOnOrder
async function getShipmentsOnOrder (state, orderId) {
  const selector = {orderId: {'$eq': orderId}}
  const {docs} = await state.shipmentsDb.find({selector, limit: DEFAULT_LIMIT})
  return docs
}

exports.getSuborder = getSuborder
async function getSuborder (state, {suborderId}) {
  const selector = {suborderId: {'$eq': suborderId}}
  const {docs} = await state.orderDb.find({selector, limit: DEFAULT_LIMIT})
  return docs.length
    ? docs[0]
    : null
}

exports.listById = listById
async function listById (state, keys) {
  const response = await state.orderDb.allDocs({
    include_docs: true,
    keys
  })
  return getDocsFromKeySearch(response)
}

exports.getOrdersForDeliveryDate = async function getOrdersForDeliveryDate (state, {
  programId,
  deliveryDate,
  parentLocationId
}) {
  const selector = {
    programId,
    deliveryDate,
    isComplete: false,
    destinationId: {
      '$gte': parentLocationId,
      '$lt': tools.getLtForSelector(parentLocationId)
    }
  }
  const {docs} = await state.orderDb.find({selector, limit: DEFAULT_LIMIT})

  return docs
}

exports.getForDestination = getForDestination
async function getForDestination (state, {destinationId, groupId}) {
  const selector = {
    destinationId,
    groupId
  }
  const {docs} = await state.orderDb.find({selector, limit: DEFAULT_LIMIT})
  if (docs.length !== 1) {
    throw new Error(`Expected only one suborder for group destination ${groupId} ${destinationId}`)
  }

  return docs[0]
}

exports.getForDestinationSupplier = getForDestinationSupplier
async function getForDestinationSupplier (state, { destinationId, groupId, supplierId }) {
  const selector = {
    destinationId,
    groupId,
    supplierId
  }
  const { docs } = await state.orderDb.find({ selector, limit: DEFAULT_LIMIT })
  if (docs.length !== 1) {
    throw new Error(`Expected only one suborder for group destination ${groupId} ${destinationId}`)
  }

  return docs[0]
}

exports.getLocationOrders = getLocationOrders
async function getLocationOrders (state, {locationId, closed = false}) {
  const selector = {
    destinationId: locationId,
    isComplete: closed
  }
  const { docs } = await state.orderDb.find({ selector, limit: DEFAULT_LIMIT })
  return docs
}
