import React from 'react'

import { KeyValueTable } from '@fielded/shared-ui'

const LedgerBalanceTable = ({
  appName,
  balances,
  reservations
}) => {
  let balancesTable = [
    {
      text: 'Quantity on shelf',
      value: balances.overallBalance
    }
  ]

  if (balances.overallBalance !== 'N/A') {
    balancesTable.push(
      {
        text: 'Pharmacy Balance',
        value: balances.partnerBalance
      },
      {
        text: `${appName} Balance`,
        value: balances.shelflifeBalance
      }
    )
  }

  if (reservations !== undefined) {
    balancesTable.push(
      {
        text: 'Reservations',
        value: reservations
      }
    )
  }

  return (
    <KeyValueTable
      entries={balancesTable}
      alternated
      semiHeader
    />
  )
}

export default LedgerBalanceTable
