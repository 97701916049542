const getDraft = require('./get-draft')
const findForLocation = require('./find-for-location')
const { get: getService } = require('../../service/api/read/get')
const getPeriodFromProgram = require('../tools/get-period-from-program')

module.exports = async (state, {
  reportId
}) => {
  const draftReport = await getDraft(state, {reportId})
  if (!draftReport) {
    return false
  }

  const locationId = draftReport.location.id
  const serviceId = draftReport.serviceId
  const service = await getService(state, serviceId)
  const period = getPeriodFromProgram(service.program, draftReport.date.period.effectiveStart, true)

  // Find reports in the reporting period
  const reports = await findForLocation(state, {
    locationId,
    serviceId,
    period
  })
  if (reports.length === 0) {
    return false
  }
  const latestReport = reports[reports.length - 1]

  return latestReport.submittedAt && draftReport.createdAt <= latestReport.submittedAt
}
