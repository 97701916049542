module.exports = { getCommitsTotal }

function getCommitsTotal (commits = {}) {
  if (Object.keys(commits).length === 0) {
    return 0
  }

  // commits can sometimes look like this: { commits: { campaign:sia: {} } }
  return Object.keys(commits)
    .reduce((total, key) => {
      const amount = commits[key].amount || 0
      return total + amount
    }, 0)
}
