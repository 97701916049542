import React from 'react'
import classnames from 'classnames'
import { Text } from '@fielded/shared-ui'

import parkedTruck from './images/SL-parked-truck.png'

const ServiceListEmptyState = ({ size, className }) => {
  return (
    <section className={classnames(
      'service-visit__empty-state',
      className
    )}>
      <div className='service-visit__empty-state-img-wrapper'>
        <img className='service-visit__empty-state-img' src={parkedTruck} alt='No scheduled visits' />
      </div>
      <Text.Heading className='service-visit__empty-state-header' size={size}>
        No scheduled visits
      </Text.Heading>
    </section>
  )
}

export default ServiceListEmptyState
