const get = require('lodash/get')
const findReports = require('./find')
const { getPeriod } = require('./get-periods')
/*
 * api.report.submitsPartial(locationids, periodId, programId)
 *
 * returns an object like
 * {
 *    [locationId]: {
 *        reportId: (report id for last full count),
 *        submitPartial: boolean
 *    }
 * }
 *
 * this object will be 'incomplete' so use together with lodash.get
 * get(lastSubmissions, `${location._id}.submitPartial`, false)
 */

module.exports = submitsPartial

async function submitsPartial (state, { locationIds, locations, programId, program, date }) {
  const wantedEntryPeriod = await getPeriod(state, {
    program,
    programId,
    date,
    isEffectiveDate: false
  })
  const periodId = wantedEntryPeriod.id

  if (!locationIds) {
    locationIds = locations.map(l => l._id)
  }

  const latestEntryPeriod = await getPeriod(state, {
    program,
    programId,
    date: new Date().toJSON(),
    isEffectiveDate: false
  })

  const reportsFromCurrentPeriod = await findReports(state, {
    locations,
    locationIds,
    startDate: latestEntryPeriod.effectiveStartDate.toJSON(),
    endDate: latestEntryPeriod.effectiveEndDate.toJSON()
  })

  const fullReportsFromCurrent = reportsFromCurrentPeriod.filter(r => !r.partialCount)
  const locationSubmitMap = {}

  /*
   * NOTE: this does not support multi-service locations
   * if we want to support that, we'd first need to add serviceId
   * on the server side ledger balances
   * (i believe it's enough to modify the ids so we have
   * `locationId:serviceId` rather than just `locationId`)
   * then we can match those ids as well here and in the ledgerDB call below
   */
  fullReportsFromCurrent.forEach(report => {
    locationSubmitMap[report.location.id] = {
      submitPartial: periodId === latestEntryPeriod.id,
      reportId: report._id
    }
  })

  // If we're looking at the current or future period, we're done here
  // (you should not be able to submit partial counts for future periods)
  if (periodId >= latestEntryPeriod.id) {
    return locationSubmitMap
  }

  // This is when you're looking for a period in the past,
  // we're annotating the ledger balance docs with id of hthe last full stock count
  let latestLedgers = await state.ledgerDB.allDocs({ keys: locationIds, include_docs: true })
  latestLedgers = latestLedgers.rows.map(r => r.doc).filter(x => x)

  latestLedgers.forEach(ledgerDoc => {
    // We have already found a report from the latest period
    // so we can not submit a partial count
    if (get(locationSubmitMap, `${ledgerDoc._id}.reportId`)) {
      return
    }

    // Even if the reportId here can be a partial count,
    // that is fine because that means we had a full count as well this period
    // newer version ledger (glb2), has documents.counts
    let fullReportFromLedger = get(ledgerDoc, 'documents.counts', []).find(id => id && id.match(periodId))
    if (!fullReportFromLedger) {
      // old version ledger, only had documents.reportId
      const baseReportId = get(ledgerDoc, 'documents.reportId', '')
      if (baseReportId && baseReportId.match(periodId)) {
        fullReportFromLedger = baseReportId
      }
    }

    if (!fullReportFromLedger) {
      // Latest full report according to ledger
      // does not match the wanted period id,
      // we can not submit partial
      return
    }

    locationSubmitMap[ledgerDoc._id] = {
      submitPartial: true,
      reportId: fullReportFromLedger
    }
  })

  return locationSubmitMap
}
