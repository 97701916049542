/**
 * XLSX Parser
 */
const XLSX = require('@sheet/core')

const {
  createAllocationFromRows,
  columns
} = require('./parse-utils')

const {
  ALLOCATIONS_WORKSHEET_NAME
} = require('./config')

const groupByFacilityId = (rows) => rows.reduce((byFacilityId, row) => {
  const facilityProductAllocation = columns.reduce((allocations, column) => {
    return Object.assign({}, allocations, {
      [column.name]: column.parse(row[column.name])
    })
  }, {})

  return Object.assign(
    {},
    byFacilityId,
    {
      [facilityProductAllocation.id]: [
        ...(byFacilityId[facilityProductAllocation.id] || []),
        facilityProductAllocation
      ]
    }
  )
}, {})

// Filter out rows without a location id
const sanitiseRows = (rows) => rows.filter(r => !!r.id)

module.exports = async (buffer, effectiveDate, userName) => {
  const workbook = XLSX.read(buffer, {type: 'array'})
  const allocationsSheet = workbook.Sheets[ALLOCATIONS_WORKSHEET_NAME]
  if (!allocationsSheet) {
    throw new Error(`Worksheet '${ALLOCATIONS_WORKSHEET_NAME}' not found`)
  }
  const rows = XLSX.utils.sheet_to_json(allocationsSheet)
  const sanitisedRows = sanitiseRows(rows)
  const productsByFacilityId = groupByFacilityId(sanitisedRows)
  const allocations = Object.keys(productsByFacilityId)
    .map(key => createAllocationFromRows(productsByFacilityId[key], effectiveDate, userName))
    .filter(a => !!a)

  return allocations
}
