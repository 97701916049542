const {listBatches, purgeCache} = require('./api/batch-list')
const getBatch = require('./api/batch-get')
const onBatchListUpdated = require('./api/batch-on-list-updated')
const getOrList = require('./api/get-or-list-batch')
const upsert = require('./api/upsert')
const listManufacturers = require('./api/batch-list-manufacturers')
const newBatch = require('./api/batch-new')
const missingBatches = require('./api/batch-missing')
const { wrapEntityApi } = require('../utils/wrap-api')

const rawMethods = {
  new: newBatch,
  list: listBatches,
  _purgeCache: purgeCache,
  listManufacturers: listManufacturers,
  onListUpdated: onBatchListUpdated,
  missing: missingBatches,
  get: getBatch
}

class BatchApi {
  constructor (state, mainApi) {
    const apiMethods = wrapEntityApi(rawMethods, state)
    Object.assign(this, apiMethods)
    this.mainApi = mainApi
  }

  // Called by the NHLMIS (PSM) REST API
  getOrList (queryParams, alias) {
    return getOrList(this.mainApi, queryParams, alias)
  }

  // Called by the NHLMIS (PSM) REST API
  upsert (batch, alias, updatedAliasId) {
    return upsert(this.mainApi, batch, alias, updatedAliasId)
  }
}

module.exports = rawMethods
module.exports.BatchApi = BatchApi
