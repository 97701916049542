const PGAdapter = require('../../common/pg-adapter')
const priceBonusJob = require('./price-bonus-job')
const priceBonusShareJob = require('./price-bonus-share-job')
const priceHistoryJob = require('./price-history-job')

class PriceDataReplicationPGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = ['updated_at', 'id', 'source_market', 'destination_market', 'from_date', 'to_date', 'created_by', 'created_at']
    const opts = {addUuid: true}
    super(pgConnection, 'avocado.data_pricedatareplication', username, columns, 'id', {}, logger, opts)

    this.pgConnection = pgConnection
  }

  async create (data) {
    try {
      await this.pgConnection.query('BEGIN')
      // Start with price history table

      const priceJobCounts = await priceHistoryJob({data, pgConnection: this.pgConnection})

      // Now we move on to the price bonuses

      const bonusJobCounts = await priceBonusJob({data, pgConnection: this.pgConnection})

      // Now we move on to the price bonuses shares

      const bonusShareJobCounts = await priceBonusShareJob({data, pgConnection: this.pgConnection})

      // save to price_data_replication table for logs
      await super.create({
        source_market: data.from_market,
        destination_market: data.to_market,
        from_date: data.from_date,
        to_date: data.to_date
      })

      await this.pgConnection.query('COMMIT')

      return {id: 1, ...priceJobCounts, ...bonusJobCounts, ...bonusShareJobCounts} // id is irrelevant
    } catch (e) {
      // If we get an error at any step roll back all the updates
      await this.pgConnection.query('ROLLBACK')
      throw new Error('Error replicating price data', e)
    }
  }
}

module.exports = { PriceDataReplicationPGAdapter }
