const capitalize = require('lodash/capitalize')

const SYNC_VIEW_IDS_BY_ENV = {
  test: {
    contacts: '30002091927', // "Staging Test" territory
    accounts: '30002091929', // "Staging Test" territory
    deletedContacts: '30001229520',
    deletedAccounts: '30001229538'
  },
  production: {
    contacts: '30001602146',
    accounts: '30001602315',
    deletedContacts: '30001229520',
    deletedAccounts: '30001229538'
  }
}

const PATHS = {
  contacts: 'api/contacts',
  documents: 'api/documents',
  accounts: 'api/sales_accounts',
  lookup: 'api/lookup'
}

// This returns a fetch function that has the stuff we need for
// CRM requests: base url & auth headers
function getCRMFetch (apiKey, url, fetch) {
  const fetcher = async (endpoint, params, type, crmEnv = 'production') => {
    const { fetchParams } = params
    const reqParams = {
      ...fetchParams,
      headers: {
        ...fetchParams.headers,
        Authorization: 'Token token=' + apiKey
      }
    }
    const path = PATHS[endpoint]

    switch (type) {
      // NOTE: You can find any account/contact in the CRM with this function.
      // In other words, this function won't filter out locations outside the
      // "Staging Test" territory when `freshsalesApiConfig.testMode: true`.
      // This is done in the adapter.
      case 'get':
        return fetch(`${url}${path}/${params.freshsalesId}?include=territory`, {
          ...reqParams,
          method: 'GET'
        })

      case 'create':
        return fetch(`${url}${path}`, {
          ...reqParams,
          method: 'POST'
        })

      case 'update':
        return fetch(`${url}${path}/${params.freshsalesId}`, {
          ...reqParams,
          method: 'PUT'
        })

      // NOTE: You can find any account in the CRM with this function.
      // In other words, this function won't filter out locations outside the
      // "Staging Test" territory when `freshsalesApiConfig.testMode: true`.
      // This is done in the adapter.
      case 'accountByUuid':
        const { uuid } = params
        return fetch(
          `${url}${path}?q=${uuid}&f=cf_external_id&entities=sales_account&include=contacts,territory`,
          {
            ...reqParams,
            method: 'GET'
          }
        )

      case 'list':
        const { entity, page, sortBy, onlyDeleted } = params
        const SYNC_VIEW_IDS = SYNC_VIEW_IDS_BY_ENV[crmEnv]
        const view = SYNC_VIEW_IDS[onlyDeleted ? `deleted${capitalize(entity)}` : entity]
        let queryStr = `sort=${sortBy}&sort_type=desc&page=${page}&include=territory,updater`
        if (entity === 'contacts') {
          queryStr = queryStr + `,sales_account,source`
        }
        return fetch(
          `${url}${path}/view/${view}?${queryStr}`,
          {
            ...reqParams,
            method: 'GET'
          }
        )
    }
  }

  return fetcher
}

module.exports = getCRMFetch
