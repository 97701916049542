const last = require('lodash/last')
const sortBy = require('lodash/sortBy')
const { VAT_PER_MARKET } = require('../constants')

module.exports = (country) => {
  if (!country) {
    throw new Error('Country code is required')
  }
  const marketVats = VAT_PER_MARKET[country]
  return last(sortBy(marketVats, 'date'))
}
