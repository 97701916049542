module.exports = validateSheetLocations
const { parseAllocationSheet } = require('./utils/make-bulk-snapshot-from-sheet')
const { getByIds } = require('../location/api/read/get-by-ids')

async function validateSheetLocations (state, buffer, { isCnR = false } = {}) {
  const rows = parseAllocationSheet(buffer, { isCnR })
  let allHavePackPointLevel
  const today = new Date().toJSON()
  const uniqueIdsSet = new Set(rows.map(r => r.id))
  const uniqueIds = Array.from(uniqueIdsSet)

  const uniqueSupplierIdsSet = new Set(rows.map(r => r.supplier))
  const uniqueSupplierIds = Array.from(uniqueSupplierIdsSet)

  const allLocations = [...uniqueIds, ...uniqueSupplierIds]

  try {
    const locations = await getByIds(state, allLocations, today)

    // In cases of CNR the packpoints are in the supplier column
    // But in regualr returns the packpoints are in the id column
    // In both cases we want to make sure they are all pack points
    // This would still work for pack point to point shipments
    // But fail on pack point to pharmacy shipments
    const suppliers = isCnR
      ? locations.filter(location => uniqueSupplierIds.includes(location._id))
      : locations.filter(location => uniqueIds.includes(location._id))

    if (locations.length !== allLocations.length) {
      const missingIds = uniqueIds.filter(id => !locations.some(location => location && location._id === id))
      console.error('Missing locations for IDs:', missingIds)
      allHavePackPointLevel = false
    } else {
      allHavePackPointLevel = suppliers.every(location => location.level !== 'sdp')
    }
  } catch (error) {
    console.error('Error fetching location data:', error)
    return false
  }

  return allHavePackPointLevel
}
