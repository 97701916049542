import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const SortIndicator = ({ sortDirection }) => {
  return (
    <span
      className={classnames(
        'vs-sort-indicator',
        {'vs-sort-indicator--asc': sortDirection === 'ASC'},
        {'vs-sort-indicator--desc': sortDirection === 'DESC'}
      )}
    />
  )
}

SortIndicator.propTypes = {
  /**
   * Pass to indicate an active direction
   */
  sortDirection: PropTypes.oneOf(['asc', 'ASC', 'desc', 'DESC'])
}

SortIndicator.defaultProps = {
  sortDirection: undefined
}

export default SortIndicator
