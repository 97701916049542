import React from 'react'
import PropTypes from 'prop-types'

import { FieldContext } from '../'
import { requiredValidationErrorMessage } from '../../utils'
import { default as RegularCheckbox } from '../../../Checkbox'
import FormFieldRegistrationManager from '../../FormFieldRegistrationManager'

/**
 *  Wrapper for <input>
 *
 *  Adds additional functionality such as validation
 */
const Checkbox = ({
  defaultChecked,
  required,
  validate,
  onValueChange,
  ...otherProps
}) => (
  <FieldContext.Consumer>
    {
      (field) => (
        <FormFieldRegistrationManager
          register={() => {
            field.register({
              value: defaultChecked,
              required,
              validate,
              requiredValidator: async (value) => {
                if (!value) {
                  return required && requiredValidationErrorMessage(required)
                }
              },
              ...otherProps
            })
          }}
          deregister={() => field.deregister()}
        >
          <RegularCheckbox.Stateless
            {...otherProps}
            name={field.props.fieldName}
            checked={field.props.value}
            hasError={field.hasErrors()}
            onChange={e => {
              const { checked } = e.target
              field.handleOnChange(checked)
              if (typeof onValueChange === 'function') {
                onValueChange(checked)
              }
            }}
            required={required}
          />
        </FormFieldRegistrationManager>
      )
    }
  </FieldContext.Consumer>
)

Checkbox.propTypes = {
  /**
   * Whether the field is required, optionally pass a string to define a custom
   * validation error message
   */
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),

  /**
   * A custom validation function
   */
  validate: PropTypes.func,

  /**
   * Runs when field value changes (returns new value)
   */
  onValueChange: PropTypes.func,

  /**
   * Initial value if form needs to be reset
   */
  defaultChecked: PropTypes.bool
}

Checkbox.defaultProps = {
  required: false,
  validate: undefined,
  onValueChange: undefined,
  defaultChecked: false
}

Checkbox.displayName = 'Form.Field.Checkbox'

export default Checkbox
