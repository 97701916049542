const { EntityApi } = require('../common')
const PGAdapter = require('./../common/pg-adapter')
const {flattenObject} = require('../utils/flatten-object')

class AuditPGAdapter extends PGAdapter {
  constructor (pgConnection, username) {
    // TODO: this sucks, where could we get these from?
    const columns = [
      'created_at',
      'updated_at',
      'id',
      'date',
      'author',
      'resource',
      'action',
      'payload'
    ]
    super(pgConnection, 'avocado.data_auditevent', username, columns)
    this.columnPreparers = {
      payload: (key, value) => {
        if (!value) {
          return
        }
        const flatMap = flattenObject(JSON.parse(value))
        return Object.keys(flatMap)
          .map(p => ({statement: `payload->${p.split('.').map(t => `'${t}'`).join('->>')}=%INDEX%`, value: flatMap[p]}))
      }
    }
  }
}

class AuditEventAdapter extends EntityApi {
  constructor (pgConnection, username) {
    super((pgConnection && new AuditPGAdapter(pgConnection, username)) || {})
  }
}

class AuditApi {
  constructor (state, pgConnection) {
    const { user = {} } = state
    const username = user.name

    if (pgConnection) {
      this.event = new AuditEventAdapter(pgConnection, username)
    }
  }

  async getEntity (resource, id, {limit, offset}) {
    const addLimit = limit ? 'limit $3' : ' '
    const addOffset = offset ? 'offset $4' : ' '

    const query = `
      select
        id,
        created_at,
        author,
        date,
        payload
      from avocado.data_auditevent
      where payload->>'id' = $1 and resource = $2
      order by created_at desc
      ${addLimit} ${addOffset}
      `
    const countQuery = `
      select 
        COUNT(*) as total
      from avocado.data_auditevent
      where payload->>'id' = $1 and resource = $2
    `
    const values = [id, resource]
    const { rows } = await this.event.adapter.pgConnection.query(countQuery, values)
    const count = rows[0].total // [ {total: 'num'}]

    if (limit) values.push(limit)
    if (offset) values.push(offset)

    const { rows: results } = await this.event.adapter.pgConnection.query(query, values)
    return { count, results }
  }
}

module.exports = AuditApi
