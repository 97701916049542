import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { PricePromotion, PriceDisplay, QuantityDisplay } from '@fielded/shared-ui'
import { getCurrencySymbol } from '@fielded/shared-ui/src/components/PriceDisplay'

import { getTotalPackedItemVAT } from '../../common/utils'

// TODO: make reusable
// This isn't really a generic component just yet as some things,
// like breakpoint and expected label length, are hardcoded in the scss
const RotatedTh = ({ children, postfix }) => (
  <th className='rotate-col-header'>
    <div className='rotate-col-header__position-wrapper'>
      <div className='rotate-col-header__rotated'>
        {children} {postfix && (
          <span className='rotate-col-header__postfix'>
            {postfix}
          </span>
        )}
      </div>
    </div>
  </th>
)

const ShipmentContentsTable = ({
  items,
  type,
  shipmentCountry,
  includeVat,
  includeCode,
  className
}) => {
  // alternative to delivery is the package note
  const isDelivery = type === 'delivery'

  let colCount = 4
  if (includeCode) colCount += 1
  if (isDelivery) colCount += 2

  return (
    <div
      className={classnames(
        'delivery-contents',
        {'delivery-contents--has-code': includeCode},
        className
      )}
    >
      <table>
        <thead>
          <tr>
            {includeCode && <th>Code</th>}
            <th>Product</th>
            {isDelivery && <RotatedTh>Closing</RotatedTh>}
            <RotatedTh>{isDelivery ? 'Delivered' : 'Packed'}</RotatedTh>
            {isDelivery && <RotatedTh>Balance</RotatedTh>}

            <RotatedTh postfix={getCurrencySymbol({country: shipmentCountry})}>
              Unit price
            </RotatedTh>
            {includeVat && <RotatedTh>VAT</RotatedTh>}
            <RotatedTh postfix={getCurrencySymbol({country: shipmentCountry})}>
              {isDelivery ? 'Balance value' : 'Packed value'}
            </RotatedTh>
          </tr>
        </thead>
        <tbody>
          {items && items.length ? items.map(item => {
            const lineTotalVat = getTotalPackedItemVAT(!isDelivery && includeVat, item)
            return (
              <tr key={item.code}>
                {includeCode && <td>{item.code}</td>}
                <td className='delivery-contents__highlight'>{item.name}</td>
                {isDelivery && <td><QuantityDisplay value={item.closing} /></td>}
                {isDelivery ? (
                  <td><QuantityDisplay value={item.delivered} /></td>
                ) : (
                  <td><QuantityDisplay value={item.packed} /></td>
                )}
                {isDelivery && (
                  <td className='delivery-contents__highlight'><QuantityDisplay value={item.balance} /></td>
                )}
                <td><PricePromotion value={item.price} country={shipmentCountry} /></td>
                {includeVat && (
                  <td className='delivery-contents__highlight'><PriceDisplay value={item.unitVat} country={shipmentCountry} /></td>
                )}
                {isDelivery ? (
                  <td><PriceDisplay value={item.balanceValue} country={shipmentCountry} /></td>
                ) : (
                  <td><PriceDisplay value={lineTotalVat + (parseInt(item.price, 10) * item.packed)} country={shipmentCountry} /></td>

                )}
              </tr>
            )
          }) : (
            <tr>
              <td colSpan={colCount}>
                There are no products in this delivery
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

ShipmentContentsTable.propTypes = {
  /**
   * Array of delivery items (can be generated with makeDeliveryItems())
   */
  items: PropTypes.array,
  /**
   * The type of shipment content to view
   */
  type: PropTypes.oneOf(['delivery', 'package']),
  /**
   * This will decide currency formatting
   */
  shipmentCountry: PropTypes.string,
  /**
   * Should the table include product code
   */
  includeCode: PropTypes.bool
}

ShipmentContentsTable.defaultProps = {
  items: [],
  type: 'delivery',
  shipmentCountry: 'ng',
  includeCode: false
}

export default ShipmentContentsTable
