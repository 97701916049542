const { proposeChange } = require('./propose-change')
const { getByIds } = require('./../../api/read/get-by-ids')

const fillNonUserPrograms = (programsFromForm, programsFromLocation, userPrograms) => {
  const nonUserPrograms = programsFromLocation.filter(lP => {
    return !userPrograms.find(uP => lP.id === uP.id)
  })

  return programsFromForm.concat(nonUserPrograms)
}

exports.saveLocations = async (state, bulkLocations, date, userPrograms) => {
  let docs = bulkLocations.filter(doc => !Object.keys(doc.errorDoc).length)
  let createCount = 0
  let updateCount = 0
  if (!docs.length) return { createCount, updateCount }

  let allDocs = []
  const batchSize = 500
  for (let i = 0; i < docs.length; i += batchSize) {
    const uploadBatch = docs.slice(i, i + batchSize)
    let existingLocationDocs = await getByIds(state, uploadBatch.map(({changes: doc}) => doc._id), date)
    existingLocationDocs = existingLocationDocs.filter(location => location)

    const savedDocs = await Promise.all(uploadBatch.map(async entity => {
      let {
        changes: doc,
        row
      } = entity

      try {
        const locationId = doc._id

        const existingDoc = existingLocationDocs.find(doc => doc._id === locationId)

        if (existingDoc) {
          const programs = fillNonUserPrograms(doc.programs, existingDoc.programs, userPrograms)
          doc.programs = programs

          const changeProposal = {
            edits: doc,
            effectiveDate: date,
            proposalType: 'update',
            createPSMCodes: true
          }
          const result = await proposeChange(state, changeProposal)
          updateCount++
          return result
        } else {
          const changeProposal = {
            edits: doc,
            effectiveDate: date,
            proposalType: 'create',
            createPSMCodes: true
          }
          const result = await proposeChange(state, changeProposal)
          createCount++
          return result
        }
      } catch (e) {
        const error = {
          row,
          fullName: doc.fullName,
          errorDoc: [`${e}`]
        }
        return error
      }
    }))
    allDocs.push(...savedDocs)
  }

  return {
    allDocs,
    createCount,
    updateCount
  }
}
