import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Card from '../Card'
import Text from '../Text'

const CTACard = (props) => {
  const {
    cta,
    header,
    description,
    inRow,
    className
  } = props

  return (
    <Card
      rounded
      className={classnames(
        'vs-cta-card',
        {'vs-cta-card--in-row': inRow},
        className
      )}
    >
      <Card.Content className='vs-cta-card__content'>
        <div className='vs-cta-card__text'>
          <Text color='brand' size='xlarge' weight='bold'>
            {header}
          </Text>
          <Text size='small' className='vs-cta-card__description'>
            {description}
          </Text>
        </div>
        {cta}
      </Card.Content>
    </Card>
  )
}

CTACard.propTypes = {
  /**
   * A Button/Link/other call to action. Custom.
   */
  cta: PropTypes.node,
  /**
   * A card header
   */
  header: PropTypes.string,
  /**
   * A card description
   */
  description: PropTypes.string,
  /**
   * A specific style where button is on right side.
   * Prepared for intercom card.
   */
  inRow: PropTypes.bool,
  /**
   * In case you want to extend the default class names without overriding the default look.
   * e.g adding custom padding class `className='add-padding'`
   */
  className: PropTypes.string
}

CTACard.defaultProps = {
  cta: undefined,
  header: '',
  description: '',
  className: ''
}

export default CTACard
