import PropTypes from 'prop-types'

const alertCommentPropType = PropTypes.shape({
  /** the date on which the comment was created */
  createdAt: PropTypes.date,
  /** the name of the author comment */
  createdBy: PropTypes.string.isRequired,
  /** the comment content */
  message: PropTypes.string.isRequired
})

export default alertCommentPropType
