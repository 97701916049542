import React from 'react'

import { PriceDisplay, QuantityDisplay } from '@fielded/shared-ui'

const ShipmentValueSummary = ({title, country, label, total}) => {
  return (
    <div className='shipment-value-summary'>
      <div className='shipment-value-summary__title'>
        {title}
      </div>
      <div className='shipment-value-summary__value'>
        {label}
        <span className='shipment-value-summary__total'>
          {country ? (
            <PriceDisplay value={total} country={country} currencySymbol />
          ) : (
            <QuantityDisplay value={total} />
          )}
        </span>
      </div>
    </div>
  )
}

export default ShipmentValueSummary
