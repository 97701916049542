import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { Home, Landmark, PlusCircle, Products, Truck } from '@fielded/shared-ui/src/icons'

import { userIsAuthorised } from '../../van-shared/utils/auth'
import { getLeadPath } from '../MainMenu/get-lead-path'
import NavigationLink from './NavigationLink'
import {shouldExcludeUser} from '../../utils/excludeLocations'
import get from 'lodash/get'

class BottomNavigation extends React.Component {
  constructor (props) {
    super(props)
    const {
      user,
      isRetailer
    } = props

    const canViewSourceLedger = userIsAuthorised(user, 'feature:can-view-source-ledger')
    const locationId = get(user, 'location.id')
    this.state = {

      canViewSourceLedger,
      canViewFinances: isRetailer && userIsAuthorised(user, 'feature:view-finances') && !shouldExcludeUser(locationId),
      canViewRetailerCounts: isRetailer && userIsAuthorised(user, 'feature:retail-stock-shipments'),
      canViewRetailerDeliveries: isRetailer && userIsAuthorised(user, 'feature:retail-stock-shipments')
    }
  }

  isSelected (path, exact = false) {
    if (exact) {
      return this.props.location.pathname === path
    }

    return this.props.location.pathname.includes(path)
  }

  render () {
    const {
      location,
      userWithDB,
      isRetailer,
      isLeadUser
    } = this.props

    const {
      canViewFinances,
      canViewRetailerCounts,
      canViewRetailerDeliveries,
      canViewSourceLedger
    } = this.state

    return <div className='bottom-nav bottom-nav__space-evenly'>
      {isLeadUser && (
        <Fragment>
          <NavigationLink
            label='Home'
            to='/retailer/lead'
            selected={this.isSelected('/retailer/lead', true)}
            icon={<Home />}
          />
          <NavigationLink
            label='Products'
            to={(getLeadPath('products', userWithDB))}
            selected={this.isSelected((getLeadPath('products', userWithDB)))}
            icon={<Products />}
          />
          <NavigationLink
            label='Wallet'
            to={(getLeadPath('finances', userWithDB))}
            selected={this.isSelected((getLeadPath('finances', userWithDB)))}
            icon={<Landmark />}
          />
          <NavigationLink
            label='Service'
            to={(getLeadPath('service', userWithDB))}
            selected={this.isSelected((getLeadPath('service', userWithDB)))}
            icon={<Truck />}
          />
        </Fragment>
      )}

      {isRetailer && (
        <Fragment>
          <NavigationLink
            label='Home'
            to='/retailer/home'
            selected={this.isSelected('/retailer/home')}
            icon={<Home />}
          />
          <NavigationLink
            label='Products'
            to='/products'
            selected={this.isSelected('/products')}
            icon={<Products />}
            location={location}
            isRetailer={isRetailer}
            showBadgeLabel
          />
        </Fragment>
      )}

      {canViewFinances &&
        <NavigationLink
          label='Wallet'
          to='/retailer/finances'
          selected={this.isSelected('/retailer/finances')}
          icon={<Landmark />}
        />
      }

      {(canViewRetailerDeliveries || canViewRetailerCounts) &&
        <NavigationLink
          label='Service'
          to='/retailer/service'
          selected={this.isSelected('/retailer/service')}
          icon={<Truck />}
        />
      }

      {canViewSourceLedger && (
        <Fragment>
          <NavigationLink
            label='Home'
            to='/retailer/patient-dashboard'
            selected={this.isSelected('/retailer/patient-dashboard')}
            icon={<Home />}
          />
          <NavigationLink
            label='Orders'
            to='/retailer/patient-orders'
            selected={this.isSelected('/retailer/patient-orders')}
            icon={<Truck />}
          />
          <NavigationLink
            label='New Order'
            to='/retailer/create-order'
            selected={this.isSelected('/retailer/create-order')}
            icon={<PlusCircle />}
          />
        </Fragment>
      )}
    </div>
  }
}

BottomNavigation.propTypes = {
  user: PropTypes.object.isRequired
}

export default withRouter(BottomNavigation)
