import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Accordion from './Accordion'
import Checkbox from './Checkbox'
import BatchSelect from './BatchSelect'
import LocationSelect from './LocationSelect'
import Options from './Options'
import Quantity from './Quantity'
import Select from './Select'
import Signature from './Signature'
import Text from './Text'
import Price from './Price'
import Percentage from './Percentage'
import FileUploadAdvanced from './FileUploadAdvanced'
import ValidationErrors from './ValidationErrors'

import InputLabel from '../InputLabel'

import { FormContext } from '../'

export const FieldContext = React.createContext({})

class Field extends PureComponent {
  static displayName = 'Form.Field'

  static Accordion = Accordion
  static Checkbox = Checkbox
  static BatchSelect = BatchSelect
  static LocationSelect = LocationSelect
  static Options = Options
  static Quantity = Quantity
  static Select = Select
  static Signature = Signature
  static Text = Text
  static Price = Price
  static Percentage = Percentage
  static FileUploadAdvanced = FileUploadAdvanced
  static ValidationErrors = ValidationErrors

  static propTypes = {
    /**
     * The name the field will be registered on the form with.
     */
    fieldName: PropTypes.string.isRequired,

    /**
     * The label text to display
     */
    labelText: PropTypes.string,

    /**
     * Optionally add an intro to the field. You may use a simple string or paragraphs.
     */
    intro: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),

    /**
     * You might add a link next to the label, eg forgot password.
     */

    labelLink: PropTypes.node,

    /**
     * The component's Children
     *
     * Although this component will accept any valid node tree, the primary
     * purpose of this component is to register a single Form.Field.XField component.
     *
     * *** This component should not be used exclusively for layout ***
     */
    children: PropTypes.node.isRequired,

    /**
     * Optional additional classname(s) to apply to the field label
     */
    className: PropTypes.string
  }

  static defaultProps = {
    className: undefined,
    labelText: undefined,
    labelLink: undefined
  }

  render () {
    const {
      labelLink,
      labelText,
      intro,
      children,
      className,
      fieldName,
      keepField
    } = this.props

    return (
      <FormContext.Consumer>
        {(form) => {
          /**
           * The options group field type has a label for each option.
           * To avoid nesting labels, which gives unwanted select behaviour,
           * we check the type of input included for this field
           * so we can render a fitting wrapper element.
           */
          let inputHasLabel
          React.Children.forEach(children, child => {
            if (child.type && child.type.hasLabels) inputHasLabel = true
          })

          return React.createElement(
            inputHasLabel ? 'div' : 'label',
            {className: classnames(
              'vs-form-field',
              { 'vs-form-field--with-errors': form.fieldHasErrors(fieldName) },
              className
            )},
            <Fragment>
              <div className='vs-form-field__info'>
                {labelText && <InputLabel>{labelText}</InputLabel>}
                {labelLink}
              </div>
              {intro && <span className='vs-form-field__intro'>{intro}</span>}
              <FieldContext.Provider
                value={{
                  /**
                   * Set the field props from the Form state or default to an
                   * empty object
                   */
                  props: {
                    ...form.fields[fieldName] || {}
                  },

                  /**
                   * Registers the field with the parent Form.
                   */
                  register: (field) => {
                    form.registerField({
                      fieldName,
                      keepField,
                      labelText,
                      ...field
                    })
                  },

                  deregister: () => {
                    if (!keepField) {
                      form.deregisterField(fieldName)
                    }
                  },

                  /**
                   * Normalised onChange handler
                   */
                  handleOnChange: (value, files) => {
                    form.handleFieldChange(fieldName, value, files)
                  },

                  /**
                   * Does the field have errors?
                   */
                  hasErrors: () => {
                    return form.fieldHasErrors(fieldName)
                  },

                  /**
                   * Returns the errors for the field
                   */
                  getErrors: () => {
                    return form.getFieldErrors(fieldName)
                  }
                }}
              >
                {children}
              </FieldContext.Provider>
            </Fragment>
          )
        }}
      </FormContext.Consumer>
    )
  }
}

export default Field
