import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import NumberFormat from '../NumberFormat'

const quantityCell = ({ cellData }) => (
  <QuantityDisplay value={cellData} />
)

const QuantityDisplay = ({
  value,
  bold,
  className,
  ...otherProps
}) => {
  const classNames = classnames(
    {'vs-u-font-bold': bold},
    className
  )

  return Number.isFinite(parseInt(value, 10)) ? (
    <NumberFormat
      value={value}
      displayType='text'
      thousandSeparator
      renderText={value => (
        <span className={classNames}>
          {value}
        </span>
      )}
      {...otherProps}
    />
  ) : (
    <span className={classNames}>-</span>
  )
}

QuantityDisplay.propTypes = {
  /**
   * the unformatted quantity to display
   */
  value: PropTypes.number,
  /**
   * Optionally render the quantity in bold
   */
  bold: PropTypes.bool,
  /**
   * class name to add to span
   */
  className: PropTypes.string
}

export default QuantityDisplay
export { quantityCell }
