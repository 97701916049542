import { createElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CardHeader from './CardHeader'
import CardContent from './CardContent'
import CardAction from './CardAction'

/**
 * Extends Component so that propTypes show up in storybook, which they
 * otherwise won't when the component's outermost element is createElement
 */
class Card extends Component {
  render () {
    const {
      target,
      component,
      className,
      spread,
      tight,
      semitight,
      rounded,
      withPointer,
      withWarning,
      children,
      ...otherProps
    } = this.props

    const commonProps = {
      className: classnames(
        'vs-card',
        { 'vs-card--spread': spread },
        { 'vs-card--tight': tight },
        { 'vs-card--semitight': semitight },
        { 'vs-card--tappable': target || component },
        { 'vs-card--with-pointer': withPointer },
        { 'vs-card--rounded': rounded },
        { 'vs-card--warning': withWarning },
        className
      ),
      ...otherProps
    }

    let wrapperComponent = component
    let specificProps = {}

    if (target) {
      wrapperComponent = 'a'
      specificProps = {
        href: target
      }
    }

    // if a wrapper hasn't been set yet, we assume it's a regular div
    if (!wrapperComponent) {
      wrapperComponent = 'div'
    }

    return createElement(
      wrapperComponent,
      {
        ...commonProps,
        ...specificProps
      },
      children
    )
  }
}

Card.propTypes = {
  /**
   * Pass a `target` to make the card tappable and redirect to the URL specified in `target`.
   * Without `target` or component, we render a div
   */
  target: PropTypes.string,

  /**
   * Pass an optional alternative top level element to use as the card, e.g. CrossLink.
   * Can be a Component, stateless function, or string corresponding to a default JSX element.
   */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * Optionally add an additional class name
   */
  className: PropTypes.string,

  /**
   * Makes the card spread to take up all available space
   * Works well for list in flex containers,
   * to make cards even size regardless of content
   */
  spread: PropTypes.bool,

  /**
   * Makes the card padding smaller.
   * Works well for containers with limited space.
   */
  tight: PropTypes.bool,

  /**
   * Makes the card padding smaller, but not very tight.
   * Works well for containers with limited space.
   */
  semitight: PropTypes.bool,

  /**
   * Adds border radius and box-shadow.
   * Bit more modern styling.
   */
  rounded: PropTypes.bool,

  /**
   * Adds pointer in the top right corner
   */
  withPointer: PropTypes.bool,

  /**
   * Content of the card.
   * You can use the associated sub-components (CardHeader, CardContent, CardAction)
   * or any other valid HTML content
   */
  children: PropTypes.node.isRequired
}

Card.defaultProps = {
  target: undefined,
  component: undefined,
  className: '',
  spread: false,
  tight: false,
  semitight: false,
  rounded: false
}

Card.Header = CardHeader
Card.Content = CardContent
Card.Action = CardAction

export default Card

export {
  CardHeader,
  CardContent,
  CardAction
}
