const {wrapEntityApi} = require('../utils/wrap-api')
// REFACTOR: let's rename data-access = data-adapters across the board.
const { rawMethods, StockPouchDBAdapter, StockRestAdapter, StockPGAdapter } = require('./data-access')
const nhlmisRestSubmit = require('./data-access/nhlmis-rest-submit')

const TransactionApi = require('./transactions')

// Not an EntityApi, stock is a cross entity concept
class StockApi {
  constructor (state, mainApi, restAdapter, logger, pgConnection) {
    // REFACTOR: this could be in EntityApi and subclasses use super(MyPouchDBAdaper, StockRestAdapter)
    let adapter
    const { user } = state
    adapter = new StockPouchDBAdapter(user, restAdapter)
    this.adapter = adapter
    // TODO: remove this when all raw methods have been ported
    const apiMethods = wrapEntityApi(rawMethods, state)
    Object.assign(this, apiMethods)

    this.restAdapter = new StockRestAdapter(restAdapter)
    if (pgConnection) {
      const username = user.name
      this.pgAdapter = new StockPGAdapter({ pgConnection, username, logger })
    }

    this.transactions = new TransactionApi(state, mainApi, logger)
  }
}

Object.assign(StockApi.prototype, {nhlmisRestSubmit})

// TODO: remove this when all raw methods have been ported
module.exports = rawMethods
module.exports.StockApi = StockApi
