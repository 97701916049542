import React from 'react'

import { BackButton, DiffSummary, Page } from '@fielded/shared-ui'

import { formatDate } from '../../../van-shared/utils'
import { createShipmentTitle } from './helpers'

import ShipmentStatusLabel from './ShipmentStatusLabel'

function ShipmentHeader ({
  shipment,
  isReturn,
  formattedDate,
  history,
  appName
}) {
  return (
    <Page.Header
      title={createShipmentTitle(shipment, isReturn, appName)}
      className='shipment-header'
      backButton={(
        <BackButton history={history} />
      )}
    >
      <div className='shipment-header__status'>
        <ShipmentStatusLabel shipment={shipment} isReturn={isReturn} />
      </div>

      <div className='shipment-header__meta'>
        <ul>
          <li className='shipment-header__meta-item'>
            <span className='header__label'> Date: </span>
            <span className='label--bold'> {formattedDate} </span>
          </li>
          {shipment.shipmentNo != null && (
            <li className='shipment-header__meta-item'>
              <span className='header__label'> ID: </span>
              <span className='label--bold'> {shipment.shipmentNo} </span>
            </li>
          )}
          <li className='shipment-header__meta-item'>
            <span className='header__label'> FP: </span>
            <span className='label--bold'> {shipment.driverName}</span>
          </li>
        </ul>
      </div>
    </Page.Header>
  )
}

export default function ShipmentSummary ({
  shipment,
  isReturn,
  pickList,
  history,
  appName
}) {
  const formattedDate = formatDate(shipment.date, 'long')
  return (
    <Page className='pick-list-complete'>
      <ShipmentHeader
        shipment={shipment}
        isReturn={isReturn}
        formattedDate={formattedDate}
        history={history}
        appName={appName}
      />

      <Page.Panel
        withMargin
        withBackground
        narrow
      >
        <Page.Panel.Section fullWidth>
          <DiffSummary
            intro={'Delivery contains the following quantities:'}
            productGroups={[{name: 'Products', products: pickList}]}
          />

        </Page.Panel.Section>
      </Page.Panel>
    </Page>
  )
}
