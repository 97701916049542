import React from 'react'
import PropTypes from 'prop-types'

import Select, { TwoPartLabel } from '../Select'

import { getManufacturerLabel } from '../../../../utils/manufacturer'

const batchTemplate = {
  type: 'batch',
  productId: '',
  batchNumber: '',
  id: '',
  expiry: '',
  manufacturer: ''
}

export const isBatchAlreadyIncluded = (batchId, alreadyAddedBatches) => alreadyAddedBatches
  .map(a => a.toUpperCase())
  .includes(batchId.toString().toUpperCase())

export const cleanBatchNumberInput = input => (input || '')
  .toUpperCase()
  .replace(/[^A-Z0-9-/]/g, '')

export const makeBatchSelectOptions = ({
  batches,
  manufacturers,
  hiddenManufacturers = [],
  alreadyAddedBatches = [],
  alreadyAddedLabel
}) => {
  if (!batches) return

  const batchOptions = Object.keys(batches).reduce((options, batchId) => {
    const batch = batches[batchId]
    const manufacturer = batch.manufacturer
    const label = [batch.batchNumber]
    let disabled = false

    // PSM has Chemonics for all batches until they can provide a manufacturer list, but
    // they don't want the users to see it https://github.com/fielded/van-orga/issues/3147.
    // So we provide this option to hide manufacturer from the label
    const hiddenManufacturerList = hiddenManufacturers
      ? hiddenManufacturers.map(manufacturer => manufacturer.value)
      : []
    if (!hiddenManufacturerList.includes(manufacturer)) {
      label.push(getManufacturerLabel(manufacturer, manufacturers))
    }

    if (isBatchAlreadyIncluded(batchId, alreadyAddedBatches)) {
      label.push(alreadyAddedLabel)
      disabled = true
    }

    options.push({
      value: batchId,
      label,
      disabled
    })

    return options
  }, [])

  return batchOptions
}

class BatchSelect extends React.Component {
  static propTypes = {
    /**
     * Object with nested products with structure as example:
     * "product:mv:manufacturer:amgen:batchNo:4e": {
     *   "type": "batch",
     *   "version": "1.0.0",
     *   "createdBy": "van-etl",
     *   "createdAt": "2017-09-04T18:24:32.691Z",
     *   "updatedAt": "2017-09-04T18:24:32.691Z",
     *   "productId": "product:mv",
     *   "manufacturer": "amgen",
     *   "expiry": "2018-01-14T05:01:51.754Z",
     *   "_id": "product:mv:manufacturer:amgen:batchNo:4e",
     *   "_rev": "1-f89959a2eb6167ad28afbbfaa5b2e66c",
     *   "id": "product:mv:manufacturer:amgen:batchNo:4e",
     *   "snapshotId": "origin:national:destination:zone-ss:date:2017-01-16:shipmentNo:1269:status:new:agent:abc",
     *   "batchNumber": "4e"
     * },
    */
    batches: PropTypes.object,
    /**
     * Optionally pass this to get the actual manufacturer labels
     * instead of just the manufacturer value from batch
    */
    manufacturers: PropTypes.array,
    /**
     * Pass array of batches that were previously added
    */
    alreadyAddedBatches: PropTypes.array,
    /**
     * Label that should show next to alreadyAdded items
    */
    alreadyAddedLabel: PropTypes.string,
    placeholder: PropTypes.string,
    onValueChange: PropTypes.func,
    onBlur: PropTypes.func
  }

  static defaultProps = {
    batches: {},
    manufacturers: [],
    placeholder: 'Type a batch number',
    alreadyAdded: [],
    alreadyAddedLabel: 'already included',
    onValueChange: () => null,
    onBlur: () => null
  }

  state = {
    highlightValue: undefined
  }

  handleValueChange = (value) => {
    const {
      batches,
      onValueChange
    } = this.props

    if (value) {
      const selectedBatch = batches && (batches[value] || {...batchTemplate, batchNumber: value})
      onValueChange(selectedBatch)
    } else {
      // Select was cleared
      onValueChange(null)
    }

    return value
  }

  handleInputChange = (inputValue) => {
    /**
     * Because state changes mess with react-selects ability to show
     * a newly created option as the active value, we don't set the
     * highlight value if inputValue is empty, which happens
     * - when an option has been selected,
     * - if a new option has been created
     * - if the field was cleared
     */
    if (!inputValue) return inputValue

    const cleanInputValue = cleanBatchNumberInput(inputValue)
    this.setState({highlightValue: cleanInputValue})
    return cleanInputValue
  }

  handleOnBlur = () => {
    this.props.onBlur(this.state.highlightValue)
  }

  render = () => {
    const {
      batches,
      manufacturers,
      hiddenManufacturers,
      alreadyAddedBatches,
      alreadyAddedLabel,
      placeholder,
      noResultsText,
      onValueChange,
      onBlur,
      ...otherProps
    } = this.props

    const batchOptions = makeBatchSelectOptions({batches, manufacturers, alreadyAddedBatches, alreadyAddedLabel, hiddenManufacturers})

    return (
      <Select
        creatable
        promptTextCreator={label => `Create new batch "${label}"`}
        options={batchOptions}
        placeholder={placeholder}
        noResultsText={noResultsText}
        onValueChange={this.handleValueChange}
        onInputChange={inputValue => this.handleInputChange(inputValue)}
        optionRenderer={(option) => <TwoPartLabel option={option} inputValue={this.state.highlightValue} />}
        onBlur={this.handleOnBlur}
        {...otherProps}
      />
    )
  }
}

export default BatchSelect
