const { get: getLocation } = require('../../location')
const findForLocation = require('./find-for-location')
const updateStockCount = require('../../utils/update-stock-count')
const docToReport = require('./doc-to-report')
const createReport = require('../tools/create')
const getIdVersion = require('../tools/get-id-version')

module.exports = async (state, {
  locationId,
  service,
  period,
  stock = {},
  submittedAt = null,
  expiry = null,
  mergeCommits,
  mergeBatches,
  documents = {},
  partialCount = false,
  // doNotMergeCounts is used by automatic full counts as there could be a preceding count before it,
  // we do not want to merge counts but save it as its own independent count.
  doNotMergeCounts = false
}) => {
  const location = await getLocation(state, locationId, period.effectiveStartDate)
  if (!location) {
    throw new Error('Location does not exist: ' + locationId)
  }
  if (!location.services.includes(service.id)) {
    throw new Error('Location has no active service: ' + service.id)
  }

  if (partialCount && getIdVersion(service) < 2) {
    throw new Error('You can not submit a partial count for a service with id version 1')
  }

  let newReport
  if (!partialCount && !doNotMergeCounts) {
    const existingReports = await findForLocation(state, {
      locationId,
      serviceId: service.id,
      period,
      entityOptions: {rawDocs: true}
    })
    const existingReport = existingReports[existingReports.length - 1]
    if (existingReport) {
      newReport = updateStockCount(state, {stock, expiry, mergeCommits, mergeBatches, submittedAt}, existingReport)
    }
  }

  if (!newReport) {
    newReport = createReport({
      locationId,
      service,
      period,
      username: state.user.name,
      submittedAt,
      stock,
      expiry,
      partialCount
    })

    newReport.documents = documents
  }

  await state.dal.report.write(state, newReport)
  return docToReport(state, {doc: newReport, service, period})
}
