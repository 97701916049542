const keyBy = require('lodash/keyBy')

const { ChangesFeed, Checkpoint, withChanges } = require('../../common/changes-feed')
const retry = require('../../common/retry')

const docToStockCountRecord = require('../../tools/doc-to-stock-count-record')

const reconcile = require('./tools/reconcile-stock-report')
const { findLedgerForStockCount, findStockCountForLedger } = require('./tools/find-stock-count-counterpart')

const PROGRAM_ID = 'program:shelflife'
const STOCK_COUNT_CHECKPOINT_ID = 'stock-count-updater-stock-count'
const LEDGER_CHECKPOINT_ID = 'stock-count-updater-ledger'

class StockCountReconciler {
  constructor (api, logger, stockCountDB, ledgerDB, stockCountReconciledDB) {
    this.api = api
    this.logger = logger
    this.stockCountDB = stockCountDB
    this.stockCountReconciledDB = stockCountReconciledDB
    this.ledgerDB = ledgerDB
  }

  async runOnChanges ({ feedOptions = {} } = {}) {
    let numChangesFound = 0
    const changesStats = await withChanges(
      [
        {
          db: this.stockCountDB,
          checkpointId: STOCK_COUNT_CHECKPOINT_ID,
          feedOptions: {
            batchSize: 100,
            maxChanges: 100,
            includeDocs: false,
            ...feedOptions
          }
        },
        {
          db: this.ledgerDB,
          checkpointId: LEDGER_CHECKPOINT_ID,
          feedOptions: {
            batchSize: 100,
            maxChanges: 100,
            includeDocs: false,
            ...feedOptions
          }
        }
      ],
      async ([stockCountChanges, ledgerChanges]) => {
        const stockCountIds = []
        for (const c of stockCountChanges) {
          if (c.id.startsWith('country:')) {
            stockCountIds.push(c.id)
          }
        }
        this.logger.info('found stock count changes', stockCountIds.length)

        const ledgerIds = []
        for (const c of ledgerChanges) {
          if (c.id.startsWith('country:')) {
            ledgerIds.push(c.id)
          }
        }
        this.logger.info('found ledger changes', ledgerIds.length)

        numChangesFound = stockCountIds.length + ledgerIds.length

        await this.reconcileStockCounts(stockCountIds, ledgerIds)
      }
    )
    this.logger.info(changesStats)
    return numChangesFound
  }

  async runForDateAndLocations (date, locationIds = null) {
    const program = await this.api.program.get(PROGRAM_ID)
    const services = await this.api.service.getAll(PROGRAM_ID)
    const period = await this.api.report.period.get({program, date, isEffectiveDate: true})
    const locations = await (
      locationIds != null ? this.api.location.getByIds(locationIds) : this.api.location.listAll()
    )
    this.logger.info('found', locations.length, 'locations')
    const stockCounts = await this.api.report.find({
      locations,
      services,
      startDate: period.effectiveStartDate.toJSON(),
      endDate: period.effectiveEndDate.toJSON()
    })
    this.logger.info('found', stockCounts.length, 'stock counts')
    await this.reconcileStockCounts(stockCounts)
  }

  async reconcileStockCounts (stockCountIds, ledgerIds = []) {
    const services = await retry(() => this.api.service.getAll(PROGRAM_ID))
    const servicesById = keyBy(services, 'id')

    // Get a [stock count, ledger] pair for each individual stock count
    // and each individual ledger. The ledger should always be the most
    // recent ledger before the count
    const results = await Promise.all([
      await this.mapStockCountIds(servicesById, stockCountIds),
      await this.mapLedgerIds(servicesById, ledgerIds)
    ])

    this.logger.info('found', results[0].length, 'stock counts with ledger')
    this.logger.info('found', results[1].length, 'ledgers with stock count')

    // reduce to unique pairs of [stock count, ledger]
    const uniquePairs = new Map()
    for (const pairs of results) {
      for (const [stockCount, ledger] of pairs) {
        if (!uniquePairs.has(stockCount._id)) {
          uniquePairs.set(stockCount._id, [stockCount, ledger])
        } else {
          // In case we already have a [stock count, ledger] pair for the same stock count
          // we will resolve to the latest ledger for that stock count.
          // This can happen because we look at ledger and stock count document changes.
          const [, otherLedger] = uniquePairs.get(stockCount._id)
          if (otherLedger._id !== ledger._id && otherLedger.submittedAt < ledger.submittedAt) {
            uniquePairs.set(stockCount._id, [stockCount, ledger])
          }
        }
      }
    }

    this.logger.info('validating', uniquePairs.size, 'stock counts')
    let numUpdated = 0

    for (const [stockCount, ledger] of uniquePairs.values()) {
      const service = servicesById[stockCount.serviceId]
      const issues = reconcile.validate(stockCount, ledger)
      if (!issues) {
        this.logger.info('stock count', stockCount._id, 'is valid')
        continue
      }
      this.logger.info('stock count needs fixes', stockCount._id, issues, 'using ledger', ledger._id)
      const fields = await this.api.service.listReportFields(service.id)
      const fixedStockCount = reconcile.fix(stockCount, ledger, fields)
      const date = new Date().toJSON()
      fixedStockCount.updatedAt = date
      fixedStockCount.updatedBy = 'stock-count-reconciler'

      await this.stockCountReconciledDB.put({
        _id: stockCount._id + ':' + date,
        date,
        stockCount,
        ledger
      })
      await this.stockCountDB.put(fixedStockCount)

      ++numUpdated
    }
    this.logger.info('updated', numUpdated, 'stock counts')
  }

  async mapStockCountIds (servicesById, ids) {
    const items = []
    for (const id of ids) {
      const doc = await this.stockCountDB.get(id)
      let stockCount
      let service
      try {
        service = servicesById[doc.serviceId]
        if (!service) {
          throw new Error('Service not found', doc.serviceId)
        }
        stockCount = docToStockCountRecord(doc, service)
      } catch (error) {
        // lots of docs on dev data come here
        this.logger.warn(`could not docToStockCountRecord for doc ${doc._id}`, error)
        continue
      }
      const ledger = await retry(() => this.fetchLedgerForStockCount(stockCount))
      if (!ledger) {
        this.logger.info('no ledger found before stock count', stockCount._id, 'skipping...')
        continue
      }
      items.push([stockCount, ledger])
    }
    return items
  }

  async mapLedgerIds (servicesById, ids) {
    const items = []
    for (const id of ids) {
      // First we get the ledger from the change. Later
      // we will check that this is actually the most recent
      // ledger before the stock count.
      let ledger = await this.ledgerDB.get(id)
      let service
      let stockCount
      try {
        service = servicesById[ledger.serviceId]
        if (!service) {
          throw new Error('Service', ledger.serviceId, 'for ledger', ledger._id, 'not found')
        }
        stockCount = await retry(() => this.fetchStockCountForLedger(ledger, service))
      } catch (e) {
        this.logger.warn('Error getting report for ledger', ledger._id, e)
        continue
      }
      if (!stockCount) {
        this.logger.info('no stock count found after ledger', ledger._id, 'skipping...')
        continue
      }
      // Lookup the most recent ledger for the stock count.
      // The ledger from the changes feed is not necessarily the
      // most recent ledger before the stock count.
      try {
        ledger = await this.fetchLedgerForStockCount(stockCount)
      } catch (e) {
        this.logger.warn('Error getting ledger for report when checking ledger', ledger._id, e)
        continue
      }
      items.push([stockCount, ledger])
    }
    return items
  }

  async fetchLedgerForStockCount (stockCount) {
    // find the ledger before the stock count, that is not for the same stock count
    const locationId = stockCount.location.id
    const date = stockCount.submittedAt
    const ledgersResult = await retry(() => this.ledgerDB.allDocs({
      startkey: `${locationId}:date:${date}`,
      endkey: `${locationId}:date:`,
      descending: true,
      include_docs: true,
      limit: 3
    }))
    const ledgers = ledgersResult.rows.map(r => r.doc)
    return findLedgerForStockCount(stockCount, ledgers)
  }

  async fetchStockCountForLedger (ledger, service) {
    // find the stock count after the ledger that is not for the same ledger
    const stockCountsAfter = await retry(() => this.api.report.findForLocation({
      locationId: ledger.location.id,
      service,
      startDate: ledger.submittedAt,
      endDate: new Date().toJSON(),
      limit: 3
    }))
    return findStockCountForLedger(ledger, stockCountsAfter)
  }

  async resetCheckpoints () {
    const stockCountCheckpoint = new Checkpoint(this.stockCountDB, STOCK_COUNT_CHECKPOINT_ID)
    const ledgerCheckpoint = new Checkpoint(this.ledgerDB, LEDGER_CHECKPOINT_ID)
    try {
      await stockCountCheckpoint.reset()
      await ledgerCheckpoint.reset()
    } catch (err) {
      this.logger.warn('failed to reset checkpoints')
      throw err
    }
    this.logger.info('reset done')
  }

  async setCheckpoints () {
    const stockCountCheckpoint = new Checkpoint(this.stockCountDB, STOCK_COUNT_CHECKPOINT_ID)
    const ledgerCheckpoint = new Checkpoint(this.ledgerDB, LEDGER_CHECKPOINT_ID)
    try {
      await stockCountCheckpoint.write('13496-g1AAAAI7eJzLYWBg4MhgTmHgzcvPy09JdcjLz8gvLskBCjMlMiTJ____PyuDOYmBga0hFyjGbmxikJxsZIyuHocJSQpAMskeYYgn2BALI0tjw7QUYg1xABkSjzBkBsQQkyQjcwMDYg1JABlSjzCkGeKdNMMUYxNzIg3JYwGSDA1ACmjOfKhB58EGmZkZG1gmGZFk0AKIQfuhBs0CG5SUaJaSkmZKkkEHIAbdhxq0A2xQmoFFspkxaS56ADEIFkaboQFtkZpsjhFbWQCyYKwX')
      await ledgerCheckpoint.write('161644-g1AAAACReJzLYWBgYMpgTmHgzcvPy09JdcjLz8gvLskBCScyJNX___8_K4M5iYFRRzEXKMaeZphimGJgiq4ehwl5LECSoQFI_Ycb5O0NNijRNDHNPMUcXVsWABT5K8U')
    } catch (err) {
      this.logger.warn('failed to reset checkpoints')
      throw err
    }
    this.logger.info('set done')
  }

  async getSeqs () {
    // find some past seq for testing or setting checkpoints
    const [stockCountSince, ledgerSince] = await Promise.all([
      (async () => {
        const feed = new ChangesFeed(this.stockCountDB, { batchSize: 200, maxChanges: 200, descending: true })
        await feed.readAll()
        return feed.lastSeq
      })(),
      (async () => {
        const feed = new ChangesFeed(this.ledgerDB, { batchSize: 200, maxChanges: 200, descending: true })
        await feed.readAll()
        return feed.lastSeq
      })()
    ])
    console.log('stock count since\n', stockCountSince)
    console.log()
    console.log('ledger since\n', ledgerSince)
    console.log()
  }
}

module.exports = StockCountReconciler
