import React, { Component, Fragment } from 'react'
import get from 'lodash/get'

import { Button, DeemphasizedLink, KeyValueTable, Page, ProgressBar } from '@fielded/shared-ui'

import { Arrow, Issue, PlusCircle } from '@fielded/shared-ui/src/icons'

import Header from './Header'
import NoReportEntry from './NoReportEntry'
import Sidebar from './Sidebar'

function getFieldsData (locationName, fields, appName) {
  const opening = get(fields, 'field:standard-opening-balance.amount')
  const balance = get(fields, 'field:standard-physical-count.amount')
  const sold = get(fields, 'field:standard-consumed.amount')

  const slOpening = get(fields, 'field:opening-shelflife-balance.amount')
  const slBalance = get(fields, 'field:shelflife-balance.amount')

  const slSold = get(fields, 'field:shelflife-sold.amount')

  const pOpening = get(fields, 'field:opening-partner-balance.amount')
  const pBalance = get(fields, 'field:partner-balance.amount')
  const pSold = get(fields, 'field:partner-sold.amount')

  const remark = get(fields, 'field:standard-remark.amount')

  const hasPartnerBalance = (pOpening > 0) || (pBalance > 0)
  const hasSoldIssue = ((balance || 0) > (opening || 0)) || sold < 0

  const dataTables = [
    [
      {
        text: 'Opening Balance',
        value: opening
      },
      ...(hasPartnerBalance
        ? [
          {
            text: locationName,
            value: pOpening
          },
          {
            text: `${appName}'s`,
            value: slOpening
          }
        ] : []
      )
    ],
    [
      {
        text: 'Quantity on shelf',
        value: balance
      },
      ...(hasPartnerBalance
        ? [
          {
            text: locationName,
            value: pBalance
          },
          {
            text: `${appName}'s`,
            value: slBalance
          }
        ] : []
      )
    ],
    [
      {
        text: 'Total Sold',
        value: hasSoldIssue ? <Issue /> : sold
      },
      ...(hasPartnerBalance
        ? [
          {
            text: locationName,
            value: pSold < 0 ? '?' : pSold
          },
          {
            text: `${appName}'s`,
            value: slSold < 0 ? '?' : slSold
          }
        ] : []
      )
    ]
  ]

  return {
    hasPartnerBalance,
    hasIssue: hasSoldIssue,
    remark,
    dataTables
  }
}

class ReportView extends Component {
  render () {
    const {
      service,
      location,
      period,
      report,
      config,

      productHasAdjustments,
      products,
      productLines,
      currentProduct,
      currentProductNumber,

      inventoryStatementUrl,
      salesStatementUrl,

      goBack,
      gotoPreviousProduct,
      gotoNextProduct,
      gotoProductByOffset,
      gotoLedger
    } = this.props

    if (!currentProduct) {
      return <div className='report-entry'>
        <NoReportEntry
          programName={service.program.name}
          serviceName={service.name}
          location={location.name}
          period={period.display}
          updatedAt={report.updatedAt}
          onClickBack={goBack}
        />
      </div>
    }

    const productInfo = `${currentProductNumber}. ${currentProduct.name}`
    const progressPercentage = report.progress.complete / report.progress.total

    const appName = config.name
    const data = getFieldsData(location.name, report.stock[currentProduct._id].fields, appName)

    return (
      <Page className='report-entry' title={config.title}>
        <Header
          programName={service.program.name}
          serviceName={service.name}
          location={location.name}
          period={period.display}
          submittedAt={report.submittedAt}
          submittedBy={report.createdBy}
          config={config}
          onClickSave={goBack}
          backButtonLabel={'go back'}
          inventoryStatementUrl={inventoryStatementUrl}
          salesStatementUrl={salesStatementUrl}
        />
        <main className='wrapper'>
          <Sidebar
            serviceName={service.name}
            productLines={productLines}
            productList={products}
            stock={report.stock}
            activeProductId={currentProduct._id}
            onProductNavigation={gotoProductByOffset}
            markComplete
          />
          <div className='main-column main-column--report'>
            <ProgressBar
              className='vs-u-margin-top'
              percentage={progressPercentage}
              styleVariant='decorated'
              isReadonly
            />
            <section className='main-column__wrapper'>
              <div className='main-column__content'>
                <div className='main-column__header'>
                  <h3 className='main-column__title'>
                    {productInfo}
                    {currentProduct.basicUnit &&
                      <span className='main-column__unit'>{currentProduct.basicUnit}</span>
                    }
                    {data.hasIssue && (
                      <Issue />
                    )}
                    {gotoLedger && (
                      <div>
                        <DeemphasizedLink
                          onClick={gotoLedger}
                          colorVariant='brand'
                        >
                          view ledger
                        </DeemphasizedLink>
                      </div>
                    )}
                  </h3>
                </div>
                <div>
                  { productHasAdjustments ? (
                    <Fragment>
                      {data.dataTables.map((data, id) => (
                        <KeyValueTable
                          key={id}
                          entries={data}
                          semiHeader
                          alternated
                          className='vs-u-margin-bottom'
                        />
                      ))}
                      { data.remark && (
                        <div>
                          <h3>Remark:</h3>
                          <p>{ data.remark }</p>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <PlusCircle /> No recount was done for this product
                    </Fragment>
                  )}
                </div>
              </div>

              <div className='main-column__button-holder'>
                <div className='main-column__button-holder main-column__button-holder--left'>
                  {currentProductNumber > 1 &&
                    <span className='main-column__button--left'>
                      <Button
                        fill='outline'
                        colorVariant='brand'
                        id='prevProductBtn'
                        icon={<Arrow direction='left' />}
                        loading={false}
                        onClick={gotoPreviousProduct}
                      >
                        Previous
                      </Button>
                    </span>
                  }
                </div>

                <div className='main-column__button-holder main-column__button-holder--right'>
                  {currentProductNumber < products.length &&
                    <Button
                      fill='full'
                      colorVariant='brand'
                      id='nextProductBtn'
                      icon={<Arrow direction='right' />}
                      iconSide='right'
                      loading={false}
                      onClick={gotoNextProduct}
                    >
                      Next product
                    </Button>
                  }
                </div>
              </div>
            </section>
          </div>
        </main>
      </Page>
    )
  }
}

export default ReportView
