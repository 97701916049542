import React, { useState } from 'react'
import classnames from 'classnames'

import { Accordion, Button, IconBadge, PricePromotion, QuantityTotal, Text } from '@fielded/shared-ui'
import { Chevron, InfoCircle } from '@fielded/shared-ui/src/icons'

import withConfig from '../../../../van-shared/hoc/withConfig'
import { isShelflifePowered } from '../../../reports/common/utils'

const DeliveryDetailProduct = ({
  product,
  subscriptionType,
  icon,
  isCR,
  onOpenInfoModal,
  showStatsDetails,
  withVat,
  withPrice,
  countryId,
  config
}) => {
  const withAdditionalData = typeof product.requestedQuantity !== 'undefined'
  const [additionalInfoVisible, setAdditionalInfoVisibility] = useState(false)

  return (
    <Accordion.Section
      isExpanded={additionalInfoVisible}
      className='delivery-detail-product'
    >
      <Accordion.Section.Header
        className='delivery-detail-product__row delivery-detail-product__row--main'
      >
        <span className='delivery-detail-product__row-item'>
          {icon && subscriptionType && (
            <IconBadge
              colorCode={subscriptionType}
              icon={icon}
            />
          )}
          <div>
            {product.name}{withVat && <span className='vs-u-price-asterisk'>*</span>}
            {withPrice &&
              <Text size='small'>
                Unit price: <PricePromotion
                  value={product.unitPrice}
                  country={countryId}
                  currencySymbol
                  inColor
                />
              </Text>
            }
          </div>
        </span>

        <span className='delivery-detail-product__row-item'>
          <QuantityTotal
            value={isShelflifePowered(config) ? product.qtyDisplayed : product.quantity}
            size='inherit'
            fallbackText='TBD'
            isProvisional={isCR}
          />

          {showStatsDetails && (
            <Button
              className='delivery-detail-product__info-icon'
              onClick={() => onOpenInfoModal({productId: product._id})}
              icon={<InfoCircle />}
              colorVariant='brand'
              fill='none'
              size='inline'
            />
          )}
          {withAdditionalData &&
            <Button
              onClick={() => setAdditionalInfoVisibility(!additionalInfoVisible)}
              icon={
                <Chevron
                  direction={additionalInfoVisible ? 'up' : 'down'}
                />
              }
              fill='none'
              size='small'
            />
          }
        </span>
      </Accordion.Section.Header>
      {withAdditionalData && (
        <Accordion.Section.Content
          className={classnames(
            'delivery-detail-product__row',
            {'delivery-detail-product__row--sub': additionalInfoVisible}
          )}
        >
          <span>Requested quantity:</span>
          <QuantityTotal
            className='delivery-detail-product__qty-info'
            value={product.requestedQuantity}
            size='inherit'
          />
        </Accordion.Section.Content>
      )}
    </Accordion.Section>
  )
}

DeliveryDetailProduct.defaultProps = {
  withAdditionalData: false
}

export default withConfig(DeliveryDetailProduct)
