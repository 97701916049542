import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const PanelSection = ({
  children,
  fullWidth,
  spread,
  className,
  title,
  ...otherProps
}) => (
  <div
    className={
      classnames(
        'vs-panel__content',
        { 'vs-panel__content--full-width': fullWidth },
        { 'vs-panel__content--spread': spread },
        className
      )
    }
    {...otherProps}
  >
    {title && (
      <div className='vs-panel-section__title'>{ title }</div>
    )}
    {children}
  </div>
)

PanelSection.propTypes = {
  /**
   * The children of the component
   */
  children: PropTypes.node.isRequired,

  /**
   * Whether the component should expand to fit the full width of the Panel,
   * ignoring padding
   */
  fullWidth: PropTypes.bool,

  /**
   * Whether the component should expand to flex the full available height of
   * the Panel
   */
  spread: PropTypes.bool,

  className: PropTypes.string
}

PanelSection.defaultProps = {
  fullWidth: false
}

PanelSection.displayName = 'Page.Panel.Section'

export default PanelSection
