const tools = require('../../tools')

exports.listWarehouseCodes = async (state, prefix) => {
  let result
  try {
    result = await state.locationsDB.query(
      tools.constants.BY_WAREHOUSE_CODE_VIEW_NAME, {
        startkey: prefix,
        endkey: `${prefix}\uffff`,
        include_docs: false
      }
    )
  } catch (err) {
    if (err.status === 404) {
      throw new Error(`No design doc found for view "${tools.constants.BY_WAREHOUSE_CODE_VIEW_NAME}"`)
    }
    throw err
  }

  return result.rows.map(row => row.key)
}
