const tools = require('../../tools')

exports.getByIds = async (state, locationIds, date, options) => {
  const allConfigurations = await state.dal.configuration.listAll(state)
  const filter = tools.getUserFilter(state.user)

  const isoDate = new Date(date).toJSON()
  const docs = await state.dal.location.getRawDocByIds(state, locationIds)

  // If you use this you have to apply the filter in next step
  if (options && options.raw) {
    return docs
  }

  return docs.map(doc =>
    tools.docToEntity({doc, date: isoDate, filter, allConfigurations})
  )
}
