const { get: getLocation, listChildren: listLocationChildren } = require('../../location')
const find = require('./find')

module.exports = async (state, {
  location = null, // optional location, default is the users location
  locationId = null, // optional location id, default is the users location
  service = null, // optional filter by service or multiple services, all services otherwise
  serviceId = null, // optional filter by service, all services otherwise
  period = null, // a reporting period object
  startDate = null, // optional start date, precedes period when given
  endDate = null, // optional end date, precedes period when given
  includeChildren = false, // whether to include children
  entityOptions = null, // {addProducts: false, addFields: false}
  ignoreService = false // Ignore what service a location at the start of a period and get all reports for that period
}) => {
  locationId = (location && location._id) || locationId || state.user.location.id
  startDate = startDate || (period && period.effectiveStartDate)
  endDate = endDate || (period && period.effectiveEndDate)

  const self = location || await getLocation(state, locationId, startDate)

  const locations = self ? [self] : []
  if (includeChildren) {
    const children = await listLocationChildren(
      state,
      locationId,
      {
        date: startDate,
        filters: serviceId ? {services: [serviceId]} : {}
      }
    )
    locations.push(...children)
  }

  if (service) {
    service = Array.isArray(service)
      ? service
      : [service]
  }

  return find(state, {
    locations,
    serviceIds: serviceId ? [serviceId] : null,
    services: service,
    startDate,
    endDate,
    entityOptions,
    ignoreService
  })
}
