module.exports = validateRoute

function validateRoute (route, config) {
  const isShelflifePowered = config && config.appBase === 'shelflife'
  const isPSM = config && config.appBase === 'psm'

  if (!route._id) throw new Error('Property \'_id\' is required')
  if (!route.name) throw new Error('Property \'name\' is required')
  if (!route.programs) throw new Error('Property \'programs\' is required')

  if (isShelflifePowered) {
    if (!route.day) throw new Error('Property \'Delivery Day\' is required')
    if (!route.deliveryFrequencyWeeks) throw new Error('Property \'Delivery Cycle\' is required')
    if (!route.firstDeliveryDate) throw new Error('Property \'First Delivery Date\' is required')
    if (!route.countDay) throw new Error('Property \'Count Day\' is required')
    if (!route.countFrequencyWeeks) throw new Error('Property \'Count Cycle\' is required')
    if (!route.firstCountDate) throw new Error('Property \'First Count Date\' is required')

    if ((parseInt(route.day < 0) || parseInt(route.day > 6))) {
      throw new Error('Property \'day\' is not valid.')
    }

    const deliveryDate = new Date(route.firstDeliveryDate)

    if (isNaN(deliveryDate)) throw new Error('Property \'First Delivery Date\' is not valid.')

    if (deliveryDate.getDay() !== parseInt(route.day)) {
      throw new Error('Property \'First Delivery Date\' does not match the selected weekday')
    }

    if (!(Number.isInteger(route.deliveryFrequencyWeeks) && route.deliveryFrequencyWeeks > 0)) {
      throw new Error('Property \'Delivery Cycle\' is invalid.')
    }

    const countDate = new Date(route.firstCountDate)

    if (isNaN(countDate)) throw new Error('Property \'First Count Date\' is not valid.')

    if ((countDate.getDay() !== parseInt(route.countDay))) {
      throw new Error('Property \'First Count Day\' does not match the selected weekday')
    }

    if (!(Number.isInteger(route.countFrequencyWeeks) && route.countFrequencyWeeks > 0)) {
      throw new Error('Property \'Count Cycle\' is invalid.')
    }
  }

  if (isPSM) {
    if (!route.startDate) throw new Error('Property \'Start date\' is required')
    if (!route.endDate) throw new Error('Property \'End date\' is required')

    const startDate = new Date(route.startDate)

    if (isNaN(startDate)) throw new Error('Property \'Start Date\' is not valid.')

    const endDate = new Date(route.endDate)

    if (isNaN(endDate)) throw new Error('Property \'End Date\' is not valid.')
  }

  Object.keys(route.programs).forEach(program => {
    if (!route.programs[program].hasOwnProperty('services')) {
      throw new Error('A program must have a service property!')
    }
    route.programs[program].services.forEach(service => {
      if (!service) {
        throw new Error('Property \'programs\' is not valid.')
      }
    })
  })
}
