const cloneDeep = require('lodash/cloneDeep')

exports.deleteComment = deleteComment
async function deleteComment (state, {orders, commentId, productId}) {
  const updatedOrders = orders
    .filter((o) => !!o.products[productId])
    .map((order) => {
      const updatedOrder = cloneDeep(order)
      updatedOrder.products[productId].comments = updatedOrder.products[productId].comments
        .filter(({ _id }) => _id !== commentId)
      return updatedOrder
    })
  await state.dal.order.bulkDocs(state, updatedOrders)
  return updatedOrders[0].products[productId]
}
