import React from 'react'

import { BackButton } from '@fielded/shared-ui'

import LedgerActiveFilter from './LedgerActiveFilter'
import { isShelflifePowered } from '../../../van-shared/utils/utils'

export default ({
  productName,
  history,
  filters,
  baseLedgerUrl,
  locationsMap,
  config
}) => {
  const isShelflife = isShelflifePowered(config)
  return (
    <div className='ledger-header'>
      <div>
        {/* If we have a back URL pass it, otherwise pass history for back */}
        <BackButton history={history} />
        <h1 className='ledger-header__product'>
          {productName} {!isShelflife && 'Cold Store'}
        </h1>
      </div>

      {filters && (
        <LedgerActiveFilter
          filters={filters}
          history={history}
          baseLedgerUrl={baseLedgerUrl}
          locationsMap={locationsMap}
        />
      )}
    </div>
  )
}
