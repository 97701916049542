const get = require('lodash/get')
const flatten = require('lodash/flatten')
const flatMap = require('lodash/flatMap')
const sortBy = require('lodash/sortBy')
const { batchIdToProductId } = require('./tools/product-batch-util')
const XLSX = require('@sheet/core')
const PLANNING_TYPES = require('./tools/planning-types')

const exportShipmentsListData = async function (state, { locationId, startdate, enddate, shipmentsList, isReceived, api }) {
  let allProductIds = []
  let allLocationIds = []

  shipmentsList.forEach(shipment => {
    allLocationIds.push(shipment.destination.id)
    allLocationIds.push(shipment.origin.id)

    shipment.products.forEach(product => allProductIds.push(product._id))
  })

  allProductIds = [...new Set(allProductIds)]
  allLocationIds = [...new Set(allLocationIds)]
  const products = await api.product.getProductsViaIds(allProductIds)
  const locations = await api.location.getLocationsViaIds(allLocationIds)

  const DATE_KEY = isReceived ? 'snapshotDates.received' : 'snapshotDates.new'

  let shipments
  // 1. Load all shipments
  // If we already passed the shipments we want to export, there is no need to fetch again.
  if (shipmentsList) {
    shipments = shipmentsList
    shipments.forEach((shipment) => {
      const location = locations.find((location) => shipment.destination.id === location._id)
      shipment.location = location
    })
  }

  shipments = flatten(shipments)

  // 2. Split shipments out to one event per batch per shipment
  const events = flatMap(shipments, (shipment) => {
    const location = shipment.location // just decorated above, shipments don't normally have this
    const snapshotIdForPlanning = Object.keys(shipment.history).find(id => id.includes(`status:new`))
    const snapshotIdForPacking = Object.keys(shipment.history).find(id => id.includes(`status:sent`))

    const receivedCounts = get(shipment, 'counts', {})
    const plannedCounts = get(shipment, `history.${snapshotIdForPlanning}.counts`, {})
    const packedCounts = get(shipment, `history.${snapshotIdForPacking}.counts`, {})
    const operator = get(shipment, `history.${snapshotIdForPacking}.createdBy.user`)

    const batchIds = new Set(
      Object.keys(receivedCounts)
        .concat(Object.keys(plannedCounts))
        .concat(Object.keys(packedCounts))
    )

    return Array.from(batchIds).map((batchId, index) => {
      // explode all shipment counts per-product
      const productId = batchIdToProductId(batchId)
      const product = products.find(product => product._id === productId)

      const planned = get(plannedCounts, `${batchId}.quantity`, '-')
      const packed = get(packedCounts, `${batchId}.quantity`, '-')
      const delivered = get(receivedCounts, `${batchId}.quantity`, '-')

      let comment = ''
      // Only write the comments on the first item
      if (index === 0) {
        comment = get(shipment, 'comments').map(c => c.comment || '').join(' | ')
      }

      let shipmentType = 'routine'
      // Planner initiated C&R has the client as destination and negative numbers
      if (delivered < 0) {
        shipmentType = PLANNING_TYPES.C_R
      // Driver initiated C&R has the client as origin
      } else if (shipment.origin.id === get(location, 'id')) {
        shipmentType = 'collect-redistribute-unplanned'
      } else if (shipment.planningType !== PLANNING_TYPES.ROUTINE) {
        shipmentType = shipment.planningType
      }

      return {
        productId,
        product,
        location,
        shipment,
        planned,
        packed,
        delivered,
        operator,
        comment,
        shipmentType,
        shipmentStatus: shipment.status,
        date: shipment.date,
        deliveryDate: new Date(get(shipment, DATE_KEY)).toLocaleDateString('en-NG')
      }
    })
  })

  // 3. Sort by locationId/date, and format CSV:
  const sortedEvents = sortBy(events, ['location._id', `shipment.${DATE_KEY}`])

  const columnsNew = [
    {
      name: 'Date',
      key: 'date'
    }, {
      name: 'Location Name',
      key: 'location.name'
    }, {
      name: 'Location Id',
      key: 'location._id'
    }, {
      name: 'Delivery ID',
      key: 'shipment.shipmentNo'
    }, {
      name: 'Delivery type',
      key: 'shipmentType'
    }, {
      name: 'Delivery status',
      key: 'shipmentStatus'
    }, {
      name: 'Delivery Date',
      key: 'deliveryDate'
    }, {
      name: 'Product Name',
      key: 'product.fullName'
    }, {
      name: 'Product Code',
      key: 'productId'
    }, {
      name: 'Planned',
      key: 'planned'
    }
  ]

  const columnsReceived = [
    {
      name: 'Location Name',
      key: 'location.name'
    }, {
      name: 'Location Id',
      key: 'location._id'
    }, {
      name: 'Delivery ID',
      key: 'shipment.shipmentNo'
    }, {
      name: 'Delivery type',
      key: 'shipmentType'
    }, {
      name: 'Delivery Date',
      key: 'deliveryDate'
    }, {
      name: 'Delivery Comment',
      key: 'comment'
    }, {
      name: 'Product Name',
      key: 'product.fullName'
    }, {
      name: 'Product Code',
      key: 'product.code'
    }, {
      name: 'Driver',
      key: 'shipment.updatedBy.user'
    }, {
      name: 'Operator',
      key: 'operator'
    }, {
      name: 'Planned',
      key: 'planned'
    }, {
      name: 'Packed',
      key: 'packed'
    }, {
      name: 'Delivered',
      key: 'delivered'
    }
  ]

  const columns = isReceived ? columnsReceived : columnsNew

  const headers = columns.map(column => column.name)
  const data = sortedEvents.map(event => {
    return columns.map(column => {
      return get(event, column.key, '')
    })
  })

  return [headers].concat(data)
}

const generateExcelSheet = (aoa) => {
  return new Promise((resolve) => {
    const ws = XLSX.utils.aoa_to_sheet(aoa)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'SL Shipments Export')
    const wbout = XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array'
    })
    resolve(new global.Blob([wbout], {type: 'application/octet-stream'}))
  })
}

async function exportShipmentsList (state, params) {
  const data = await exportShipmentsListData(state, params)
  return generateExcelSheet(data)
}

module.exports = { exportShipmentsListData, exportShipmentsList }
