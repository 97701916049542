const { EntityApi } = require('../common')
const { wrapEntityApi } = require('../utils/wrap-api')
const tools = require('./tools')
const api = require('./api')

const rawMethods = {...api, tools}
const ServiceDataAdapter = require('./data-access/service-data-adapter')

class ServiceApi extends EntityApi {
  constructor (state, restAdapter) {
    const { user, productsDB } = state
    const adapter = new ServiceDataAdapter(user, productsDB, restAdapter)
    super(adapter)

    // TODO: remove this when all raw methods have been ported
    const apiMethods = wrapEntityApi(rawMethods, state)
    Object.assign(this, apiMethods)
  }
}

module.exports = rawMethods
module.exports.ServiceApi = ServiceApi
