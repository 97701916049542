import React from 'react'
import PropTypes from 'prop-types'
import withOffline from '../../../common/offline'
import withPageContext from '../withPageContext'
import PageComposableHeader from '../PageComposableHeader'

const PageHeader = ({
  title,
  backButton,
  breadcrumbItems,
  subNavigationItems,
  subNavigationLabel,
  linkRenderer,
  children,
  showOfflineWarning,
  className,
  situation,
  isOnline
}) => (
  <PageComposableHeader
    situation={situation}
    className={className}
    isOnline={isOnline}
    showOfflineWarning={showOfflineWarning}
    // Hide SidebarTrigger if backButton exists
    withSidebarTrigger={typeof backButton === 'undefined' || backButton === null}
    withSidebarTriggerWrapped={!breadcrumbItems}
  >
    {backButton && (
      <PageComposableHeader.BackButton {...backButton.props} />
    )}

    {breadcrumbItems && (
      <PageComposableHeader.Breadcrumb
        items={breadcrumbItems}
      />
    )}

    {children && (
      <div className='vs-page-header__custom-content'>
        {children}
      </div>
    )}

    {(title || subNavigationItems) && (
      <PageComposableHeader.Content>
        {title && (
          <PageComposableHeader.Title
            title={title}
          />
        )}
        {subNavigationItems && (
          <PageComposableHeader.SubNavigation
            label={subNavigationLabel}
            items={subNavigationItems}
          />
        )}
      </PageComposableHeader.Content>
    )}
  </PageComposableHeader>
)

PageHeader.propTypes = {
  /*
   * A title to display
   */
  title: PropTypes.string,
  /**
   * Optionally pass a back button for the header
   */
  backButton: PropTypes.node,
  /**
   * Optionally pass an array of breadcrumb items.
   * See Breadcrumb for object shape.
   * Optionally also pass a linkRenderer which will be used for breadcrumb links.
   */
  breadcrumbItems: PropTypes.array,
  /**
   * Optionally pass a sub navigation for the header
   * Optionally also pass a linkRenderer which will be used for breadcrumb links.
   */
  subNavigationItems: PropTypes.array,
  /**
   * Optionally pass a label for the sub navigation
   */
  subNavigationLabel: PropTypes.string,
  /**
   * Pass to make breadcrumb and sub navigation links use a custom link renderer, .e.g. Link.
   * Must have `to`, `className`, `children`.
   */
  linkRenderer: PropTypes.func,
  /**
   * Optionally add your own elements to header instead
   */
  children: PropTypes.node,
  /**
   * Pass this to show a badge in the header when user is offline.
   */
  showOfflineWarning: PropTypes.bool,
  /**
   * The classname(s) to apply to the Page
   */
  className: PropTypes.string,
  /**
   * This will be passed down by the wrapping Page component,
   * so you don't have to set here again.
   */
  situation: PropTypes.oneOf(['ready', 'success', 'caution', 'danger', 'neutral', undefined]),
  /**
   * Is added by the withOffline hoc
   */
  isOnline: PropTypes.bool
}

PageHeader.defaultProps = {
  backButton: undefined,
  breadcrumbItems: undefined,
  subNavigationItems: undefined,
  linkRenderer: undefined,
  title: undefined,
  children: undefined,
  className: undefined
}

PageHeader.displayName = 'Page.Header'

export default withPageContext(withOffline(PageHeader))

// This is to be consumed by the storybook only, otherwise the props are not showing in the stories
export const PageHeaderNoHOC = PageHeader
