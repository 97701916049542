
// Through couch will respond with a 200 on bulk docs, you can have errors
// on every one of them. This throws if there's an error on updating any document,
// which generally doesn't happen. However, for example, our validate-doc-update function
// was rejecting my user object and it was failing silently until putting this in.
async function saveMany (state, products) {
  const response = await state.productsDB.bulkDocs(products)
  const errors = response.filter(row => row.error)
  if (errors.length) {
    const err = new Error(`Error bulk updating documents ${errors[0].error} ${errors[0].reason}`)
    err.data = errors
    throw err
  }

  return response
}

module.exports = {
  saveMany
}
