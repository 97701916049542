import React from 'react'
import { Link } from 'react-router-dom'

import { Banner, Button, Card } from '@fielded/shared-ui'

import { Arrow } from '@fielded/shared-ui/src/icons'

import DueDate from './due-date'
import ReportingProgress from '../common/ReportingProgress'

import { buildLocationsUrl } from '../app/routes-urls'
import SyncUpdateBanner from '../../../sync/components/SyncUpdateBanner/SyncUpdateBanner'

const ProgramCard = ({program, locationId, date}) => {
  const url = buildLocationsUrl({locationId, date, programId: program.id})
  return (
    <Card
      spread
    >
      <Banner>
        <DueDate program={program} />
      </Banner>
      <ReportingProgress
        programName={program.name}
        period={program.periods.current.display}
        progress={program.progress}
      >
        <Button
          component={Link}
          to={url}
          icon={<Arrow direction='right' />}
          iconSide='right'
          colorVariant='brand'
          fill='full'
        >
          Select program
        </Button>
      </ReportingProgress>
    </Card>
  )
}

const ExternalProgramCard = ({program}) => {
  return (
    <Card
      spread
    >
      <Banner>
        <div className='due-date'>
          <span>External Program</span>
        </div>
      </Banner>
      <ReportingProgress
        programName={program.name}
        progressText={program.text}
      >
        <Button
          onClick={() => window.open(program.url, '_blank')}
          icon={<Arrow direction='right' />}
          iconSide='right'
          colorVariant='brand'
          fill='full'
        >
          Go to program
        </Button>
      </ReportingProgress>
    </Card>
  )
}

const ProgramsList = ({programs, locationId, date, showCountSyncBanner, showShipmentSyncBanner}) => {
  return (
    <div>
      {showCountSyncBanner && <SyncUpdateBanner
        title='Stock report data is currently being downloaded'
      />}
      {showShipmentSyncBanner && <SyncUpdateBanner
        title='Shipment data is being downloaded, cant start report'
      />}
      <ul className='programs-list'>
        {programs
          // only display programs that have locations
          // related to https://github.com/fielded/van-orga/issues/1365
          .filter(p => p.isExternal || p.progress.total > 0)
          .map(program => {
            return (
              <li className='programs-list__item' key={program.id}>
                {program.isExternal ? (
                  <ExternalProgramCard program={program} />
                ) : (
                  <ProgramCard program={program} locationId={locationId} date={date} />
                )}
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

ProgramsList.defaultProps = {
  baseUrl: '/locations/'
}

export default ProgramsList
