import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, WindowScroller } from 'react-virtualized'
import cx from 'classnames'

class VirtualizedList extends React.Component {
  constructor (props) {
    super(props)

    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: this.props.componentHeight
    })

    this.rowRenderer = this.rowRenderer.bind(this)
    // Debounce clearing the cache on items change,
    // because it can be a costly operation.
    this.clearCacheWhenItemsDiffer = debounce(this.clearCacheWhenItemsDiffer.bind(this), 250)
  }

  clearCacheWhenItemsDiffer (items1, items2) {
    if (items1.length !== items2.length) {
      this.cache.clearAll()
    } else {
      if (this.props.getItemKey) {
        let isSame = true
        for (let i = 0; i < items1.length; ++i) {
          const a = this.props.getItemKey(items1[i])
          const b = this.props.getItemKey(items2[i])
          if (a !== b) {
            isSame = false
            break
          }
        }
        if (!isSame) {
          this.cache.clearAll()
        }
      }
    }
  }

  componentDidUpdate (prevProps) {
    this.clearCacheWhenItemsDiffer(prevProps.items, this.props.items)
  }

  rowRenderer ({ index, parent, isScrolling, key, style }) {
    const item = this.props.items[index]

    return (
      <CellMeasurer
        key={key}
        cache={this.cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}>
        {({registerChild}) => (
          <div
            ref={registerChild}
            className={cx('vs-virtualized-list__row', this.props.classNameItemComponent)}
            style={{
              ...style,
              overflow: 'hidden',
              width: '100%'
            }}
          >
            {this.props.itemComponent({item})}
          </div>
        )}
      </CellMeasurer>
    )
  }

  render () {
    return (
      <div className='vs-virtualized-list'>
        <WindowScroller scrollElement={window}>
          {({ height, isScrolling, onChildScroll, scrollTop }) => (

            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  width={width}
                  autoHeight
                  height={height}
                  deferredMeasurementCache={this.cache}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  scrollTop={scrollTop}
                  overscanRowsCount={2}
                  rowCount={this.props.items.length}
                  rowHeight={this.cache.rowHeight}
                  rowRenderer={this.rowRenderer}
                  containerStyle={{
                    width: '100%',
                    maxWidth: '100%'
                  }}
                />
              )}
            </AutoSizer>

          )}
        </WindowScroller>
      </div>
    )
  }
}

VirtualizedList.propTypes = {
  /**
   * Items array
   */
  items: PropTypes.array.isRequired,
  /**
   * The component that will be rendered per item
   */
  itemComponent: PropTypes.func.isRequired,
  /**
   * Component height. Virtualized list needs to know the height to render efficiently
   */
  componentHeight: PropTypes.number.isRequired,
  /**
   * Pass an extra class to modify the itemComponent
   */
  classNameItemComponent: PropTypes.string
}

export default VirtualizedList
