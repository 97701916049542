class Query {
  constructor (quickbooks) {
    this.quickbooks = quickbooks
  }

  async send ({
    companyCode,
    type,
    select = null,
    where = null,
    startPosition = null,
    maxResults = null
  }) {
    const query = this.build({
      type,
      select,
      where,
      maxResults,
      startPosition
    })
    const resp = await this.sendRaw({ companyCode, query })
    // The query entityType is case insensitive, but the response
    // will have the entityType as key and that is case sensitive.
    const keys = Object.keys(resp.QueryResponse)
    const key = keys.find(k => k.toLowerCase() === type.toLowerCase())
    const entities = resp.QueryResponse[key]
    return entities
  }

  async sendRaw ({
    companyCode,
    query
  }) {
    const encodedQuery = encodeURIComponent(query)
    const resp = await this.quickbooks.quickbooksInterface.get(
      companyCode,
      `query?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}&query=${encodedQuery}`
    )
    return resp
  }

  build ({
    type,
    select = null,
    where = null,
    startPosition = null,
    maxResults = null
  }) {
    return [
      `SELECT ${select || '*'} FROM ${type}`,
      where ? `WHERE ${where}` : '',
      startPosition != null ? `STARTPOSITION ${startPosition}` : '',
      maxResults ? `MAXRESULTS ${maxResults}` : ''
    ].join(' ')
  }

  async getAll ({
    companyCode,
    type,
    select = null,
    where = null
  }) {
    const all = []
    const maxResults = 500
    let startPosition = 0
    while (true) {
      const entities = await this.send({
        type,
        select,
        where,
        maxResults,
        startPosition
      })
      all.push(...entities)
      if (entities.length < maxResults) {
        break
      }
      startPosition += maxResults
    }
    return all
  }

  getAllForCustomer ({
    companyCode,
    type,
    customerId
  }) {
    return this.getAll({
      companyCode,
      type,
      where: `CustomerRef = '${customerId}'`
    })
  }
}

module.exports = {
  Query
}
