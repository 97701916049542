import PropTypes from 'prop-types'
import React from 'react'
import get from 'lodash/get'
import classnames from 'classnames'

const SummaryTable = ({ data, theme, showVAT }) => {
  const clx = classnames(
    'summary-table',
    {'summary-table--danger': theme === 'danger'},
    {'summary-table--warning': theme === 'warning'},
    {'summary-table--vat': showVAT}
  )

  const body = get(data, 'body', []).map(item => (
    <td key={item.text + item.value}>
      <div className='summary-table__label'>
        <span className='summary-table__text'>{item.text}</span>

        <span className='summary-table__total'>
          <span className='summary-table__value'>{item.amount}</span>
          <span className='summary-table__sign'>x</span>
          <span className='summary-table__value'>{item.price}{showVAT && ` + ${item.vat}(VAT)`} </span>
          {!showVAT && <span className='summary-table__sign'>=</span>}
          {!showVAT && <span className='summary-table__summary'>{item.summary}</span>}
          {showVAT && <span><span className='summary-table__summary'>=  {item.summary}</span></span>}
        </span>
      </div>
    </td>
  ))

  return (
    <table className={clx}>
      <thead>
        <tr>
          <th>
            {get(data, 'header.text', '')}
            {showVAT && <span className='summary-table__badge'>VAT INCLUDED</span>}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {body}
        </tr>
      </tbody>
    </table>
  )
}

SummaryTable.propTypes = {
  /**
   * Children components of the SummaryTable
   */
  children: PropTypes.node,
  /**
   * Set custom CSS classes to extend the SummaryTable for your needs.
   */
  className: PropTypes.string,
  /**
   *  Theme for Summary Table
   */
  theme: PropTypes.oneOf(['default', 'danger']),
  /**
   * A data passed to the table
  */
  data: PropTypes.object,
  /**
   * If we should show VAT value
   */
  showVAT: PropTypes.bool
}

export default SummaryTable
