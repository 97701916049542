const createAlertId = require('./create-alert-id')
const createBaseDoc = require('./create-base-doc')

module.exports = (options) => {
  const alertId = createAlertId(options.serviceId, options.target)
  const baseDoc = createBaseDoc({
    alertId,
    userId: options.userId,
    deviceId: options.deviceId,
    version: '1.0.0'
  })
  return Object.assign({}, baseDoc, {
    type: 'alert',
    serviceId: options.serviceId,
    target: options.target
  })
}
