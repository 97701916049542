const tools = require('../../tools')
const { getLocationsById } = require('../master-data')
const { listOrdersForUser } = require('./internal')
const { getAllocatedStock } = require('./get-allocated-stock')

exports.listLocations = listLocations
async function listLocations (
  state,
  {parentLocationId, productId, programId, groupId, date = new Date().toJSON(), useAcceptedStatus}
) {
  parentLocationId = parentLocationId || state.user.location.id
  tools.validateParams('listLocations', arguments[1])
  const listOrdersForUserResponse = await listOrdersForUser(state, programId, parentLocationId, {withAccepted: !!groupId}, useAcceptedStatus)
  const orders = listOrdersForUserResponse.orders
    .filter(order => order.products[productId])

  const locationIds = tools.getDestinationIdsOnOrders(orders, groupId)
  const locations = await getLocationsById(state, programId, locationIds, date)
  const wrongFunderLocations = locationIds.filter(id => {
    return !locations.map(location => location._id).includes(id)
  })

  const listLocationsResponse = tools.listLocations({
    locations, orders, productId, groupId
  })

  if (!tools.isWarehouseUser(state.user)) {
    return listLocationsResponse
  }

  const {productsById, allocatedStock, allocatedStockSDPUnits} = await getAllocatedStock(
    state, {locationId: state.user.location.id, programId, date}
  )

  // unlike list products, here we don't want generic stock on all products as we want to show them separately
  const stockAvailable = tools.getStockOnProduct({productId, stock: allocatedStockSDPUnits, productsById})
  const warehouseStockAvailable = tools.getStockOnProduct({productId, stock: allocatedStock, productsById})

  return Object.assign({}, listLocationsResponse, {
    stockAvailable,
    warehouseStockAvailable,
    wrongFunderLocations
  })
}
