import { userIsAuthorised } from '../../van-shared/utils/auth'
import { isPsm, isShelflifePowered } from '../../subapps/reports/common/utils'
import { isPlannerUser } from '../../subapps/shipments/common/utils'
import { isLeadBeforeSync } from './is-lead-user'
import { isLongHaulFeatureEnabled } from '../../subapps/longhaul-orders/is-longhaul-enabled'
import { isPsmFacilityUser } from '../../van-shared/utils'

const USER_ROUTES = {
  ALERTS: '/alerts',
  ANALYTICS: '/analytics',
  ORDERS: '/orders',
  LONGHAUL: '/longhaul-orders',
  REPORTING: '/reporting',
  SETTINGS: '/settings',
  SHIPMENTS: '/shipments',
  RETAILER: '/retailer',
  PRODUCTS: '/products',
  PROFILE: '/profile',
  INFORMATION: '/information',
  NOTIFICATIONS: '/notifications'
}

const {
  ALERTS,
  ANALYTICS,
  INFORMATION,
  NOTIFICATIONS,
  ORDERS,
  LONGHAUL,
  PRODUCTS,
  PROFILE,
  REPORTING,
  RETAILER,
  SETTINGS,
  SHIPMENTS
} = USER_ROUTES

const getUserRoutes = (user, config) => {
  if (!user || !config) return []

  const isShelflifeUser = isShelflifePowered(config)
  const isPsmUser = isPsm(config)
  const isFacilityUser = isPsmFacilityUser(config, user)
  const isPlanner = isPlannerUser(user)
  const isRetailer = userIsAuthorised(user, 'feature:userRole:pharmacyUser') && !isPlanner
  const isLeadUserBeforeSync = isLeadBeforeSync(user)

  // adding reporting here because almost all the users need it including retailers
  let allowedRoutes = [PROFILE, REPORTING]

  // ALERTS
  if (userIsAuthorised(user, 'feature:qc-alerts')) {
    allowedRoutes = [...allowedRoutes, ALERTS]
  }

  // ANALYTICS & INFORMATION
  if (isPsmUser) {
    allowedRoutes = [...allowedRoutes, ANALYTICS, INFORMATION]
  }

  // LONGHAUL
  if (isLongHaulFeatureEnabled(user, config)) {
    allowedRoutes = [...allowedRoutes, LONGHAUL]
  }

  // NOTIFICATIONS
  if (isShelflifeUser || isFacilityUser) {
    allowedRoutes = [...allowedRoutes, NOTIFICATIONS]
  }

  // ORDERS
  if (
    userIsAuthorised(user, 'feature:orders:requester') ||
    userIsAuthorised(user, 'feature:orders:bulk') ||
    userIsAuthorised(user, 'feature:orders:reset') ||
    userIsAuthorised(user, 'feature:orders:warehouse')

  ) {
    allowedRoutes = [...allowedRoutes, ORDERS]
  }

  // PRODUCTS && RETAILER
  if (isShelflifeUser && (isRetailer || isLeadUserBeforeSync)) {
    allowedRoutes = [...allowedRoutes, PRODUCTS, RETAILER]
  }

  // SETTINGS
  if (
    userIsAuthorised(user, 'feature:settings') ||
    userIsAuthorised(user, 'feature:settings:planning-calculator') ||
    userIsAuthorised(user, 'feature:settings:facilities:approvals') ||
    userIsAuthorised(user, 'feature:settings:products:readonly') ||
    userIsAuthorised(user, 'feature:settings:facilities:offline-edits') ||
    userIsAuthorised(user, 'feature:settings:facilities') ||
    userIsAuthorised(user, 'feature:settings:facilities:changes') ||
    userIsAuthorised(user, 'feature:routes')
  ) {
    allowedRoutes = [...allowedRoutes, SETTINGS]
  }

  // SHIPMENTS
  if (
    userIsAuthorised(user, 'feature:shipments') ||
    userIsAuthorised(user, 'feature:lastMileDeliveries')
  ) {
    allowedRoutes = [...allowedRoutes, SHIPMENTS]
  }

  return allowedRoutes
}

export {
  USER_ROUTES,
  getUserRoutes
}
