const XLSX = require('@sheet/core')

exports.generateExcelSheet = (rows, name) => {
  return new Promise((resolve) => {
    const ws = XLSX.utils.aoa_to_sheet(rows)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, name)
    const wbout = XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array'
    })
    resolve(new global.Blob([wbout], {type: 'application/octet-stream'}))
  })
}
