import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import indicatorConfigs from './indicatorConfigs'

export const byId = (collection, prop = 'id') => collection
  .reduce((index, element) => {
    // use delve here to support grouping by nested props
    // ex element.location.zone
    index[get(element, prop)] = element
    return index
  }, {})

export const capitalize = string => `${string[0].toUpperCase()}${string.slice(1)}`

export const isAggregated = string => string === 'aggregated'

export const getLocationType = (location) => {
  let locationParts = location.split(/:/)
  // Skip the last 2 parts if we have a name
  // So for example a PSM warehouse is considered up as zone/state location
  if (locationParts.includes('name')) {
    locationParts = locationParts.slice(0, locationParts.length - 2)
  }

  const locationType = (locationParts.length > 1) ? locationParts[locationParts.length - 2] : locationParts[0]

  return locationType
}

export const getMyLocations = rootLocation => {
  const locations = ['national', 'zone', 'state', 'lga', 'sdp']
  const currentLocation = getLocationType(rootLocation)
  const index = locations.indexOf(currentLocation)

  if (index === -1) {
    return locations
  }

  return locations.splice(index)
}

/**
 * gets the parent location of the current location.
 * @param {number} index How down the generation you want to go. This should be in multiples of 2
 * @param {string} location The current child location eg zone:nc:state:benue
 */
export const getParentLocation = (index, location) => {
  const locationParts = location.split(/:/)
  let childRoute = ''
  for (let i = 0; i < index; i++) {
    if (i === 0) {
      childRoute += locationParts[i]
      continue
    }
    childRoute += `:${locationParts[i]}`
  }
  return childRoute
}

export const isOfflineCapable = indicatorName => {
  return indicatorConfigs[indicatorName].offline
}

export const multipleThresholds = (thresholds = []) => {
  const defaultThreshold = thresholds[0]
  for (const threshold of thresholds.slice(1)) {
    if (!isEqual(defaultThreshold.values, threshold.values)) {
      return true
    }
  }
  return false
}
