// Currently all sl users use the role prop
// All psm users asides from vendors and drivers use funders
// Psm vendors and drivers use routes

const {PSM_ROLES, SHELFLIFE_ROLES} = require('../../user/api/read/list-user-roles')

module.exports = user => {
  let filter = []

  if (user && user.hasOwnProperty('routes') && hasRoutesRole(user)) {
    filter = user.routes
  } else if (user && user.hasOwnProperty('funders')) {
    filter = user.funders
  }

  return filter
}

function hasRoutesRole (user) {
  const tenantRoles = [
    ...SHELFLIFE_ROLES,
    ...PSM_ROLES
  ]
    .filter(role => !role.nonRouteUser)
    .map(role => role.roleId)

  if (user.roles && Array.isArray(user.roles)) {
    for (const role of user.roles) {
      if (tenantRoles.includes(role)) {
        return true
      }
    }
  }
  return false
}
