import React from 'react'
import PropTypes from 'prop-types'

import PrintTemplateHeader from './PrintTemplateHeader'
import PrintTemplateBox from './PrintTemplateBox'
import PrintTemplateSection from './PrintTemplateSection'
import PrintTemplateFooter from './PrintTemplateFooter'

const PrintTemplate = ({ children }) => {
  return (
    <div className='print-template'>
      {children}
    </div>
  )
}

PrintTemplate.propTypes = {
  /** Children components of the Print Template */
  children: PropTypes.node
}

PrintTemplate.Header = PrintTemplateHeader
PrintTemplate.Box = PrintTemplateBox
PrintTemplate.Section = PrintTemplateSection
PrintTemplate.Footer = PrintTemplateFooter

export default PrintTemplate
