module.exports = findSurvey

function findSurvey (state, options) {
  const {
    snapshotId,
    batchId
  } = options

  const idPrefix = snapshotId.replace(/:agent:.*$/, `:${batchId}`)
  return state.dal.shipment.getByPrefix(state, idPrefix)

    .then(result => {
      if (result.rows.length === 0) {
        const error = new Error('No survey found for given snapshot & batch')
        error.status = 404
        throw error
      }

      // Reverse to sort as latest survey first
      // We're only interested in the latest one
      return result.rows.reverse()[0].doc
    })
}
