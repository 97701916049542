import React from 'react'
import PropTypes from 'prop-types'

import { StatusCard } from '@fielded/shared-ui'

import {
  isPreAdvice
} from '../../common/utils'

const ShipmentStatusLabel = ({
  shipment,
  isReturn,
  isCompleted
}) => {
  const isDistribution = shipment.statusType === 'distribution'
  const isShipmentPreadvice = isPreAdvice(shipment.snapshotId)

  let shipmentStatus = shipment.statusType
  if (isShipmentPreadvice) {
    shipmentStatus = 'external-arrival'
  }
  if (isReturn) {
    shipmentStatus = 'return'
  }
  let statusText = isDistribution ? 'Scheduled' : 'Incoming'
  if (isCompleted) {
    statusText = isDistribution ? 'Sent' : 'Arrived'
  }
  if (shipment.status === 'received') {
    statusText = 'Received'
  }

  return (
    <StatusCard.Label statusType={shipmentStatus} statusText={statusText} />
  )
}

ShipmentStatusLabel.propTypes = {
  /**
   * The shipment object, used to resolve the status
   */
  shipment: PropTypes.object.isRequired,

  /**
   * Define Shipment is return shipment or note
   */
  isReturn: PropTypes.bool,

  /**
   * Define Shipment is completed
   */
  isCompleted: PropTypes.bool
}

export default ShipmentStatusLabel
