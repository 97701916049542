const getCurrentPrice = require('./get-current-price')

module.exports = products =>
  // We filter out products with no price but we allow the price
  // to be 0, in case in the future we want to add some products
  // we want to give for free.
  products
    .filter(p =>
      p.additionalData.promoted &&
      getCurrentPrice(p.prices) !== undefined
    )
    .reduce((byPromotion, p) => {
      const promotions = p.additionalData.promoted.split(',').map(p => p.toLowerCase().trim())
      promotions.forEach(promo => {
        byPromotion[promo] = byPromotion[promo] || []
        byPromotion[promo].push(p)
      })
      return byPromotion
    }, {})
