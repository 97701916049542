const relationshipRules = require('./relationship-rules')
const cloneDeep = require('lodash/cloneDeep')

exports.getNodeRelationships = function (node, nodesMap) {
  const nodeRelationships = node.location.relationships
  if (!node.location.relationshipRules) return nodeRelationships

  let relationships = cloneDeep(nodeRelationships)

  // this loop runs once if there's one relationshipRules,
  // which to start in PSM there is just one, `suppliesStateSDPs`
  Object.keys(node.location.relationshipRules)
    .forEach(ruleName => {
      const relationshipFunction = relationshipRules[ruleName]
      if (!relationshipFunction) {
        console.warn(`No function found for relationship rule ${ruleName}`)
        return
      }
      relationships = relationshipFunction(relationships, node, nodesMap)
    })

  return relationships
}
