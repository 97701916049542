import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup, SwitchTransition } from 'react-transition-group'
import cx from 'classnames'

import { Arrow } from '../../icons'
import EmptyState from '../EmptyState'

const MAX_ITEMS_PER_PAGE = 3

class ItemPager extends React.Component {
  state = {
    pageSize: MAX_ITEMS_PER_PAGE,
    numPages: 0,
    currentPage: 0,
    from: 0,
    to: MAX_ITEMS_PER_PAGE,
    direction: 'right'
  }

  movePage = (n) => {
    if (this.state.currentPage + n < 0 || this.state.currentPage + n > this.props.items.length / this.state.pageSize) return

    let currentPage = this.state.currentPage + n

    this.setState({ direction: n > 0 ? 'left' : 'right' }, () => {
      this.setState({
        currentPage: currentPage,
        from: currentPage * this.state.pageSize,
        to: (currentPage + 1) * this.state.pageSize
      })
    })
  }

  componentDidMount () {
    this.setState({ numPages: Math.ceil(this.props.items.length / this.state.pageSize) })
  }

  render () {
    const {
      className,
      icon,
      title,
      subtitle,
      items,
      children
    } = this.props

    const {
      currentPage,
      direction,
      numPages,
      from,
      to
    } = this.state
    return <div className={cx('vs-itempager', className)}>
      <div className='vs-itempager__card'>
        <div className='vs-itempager__header'>
          <div className='vs-itempager__image'>
            {icon}
          </div>
          <div>
            <p className='vs-itempager__title'>
              {title}
            </p>
            <p className='vs-itempager__subtitle'>
              {subtitle}
            </p>
          </div>
        </div>
        { /* If we dont have items we show an empty state */
          items.length === 0 && <EmptyState text='No promotions for this category yet' />
        }

        <div className='vs-itempager__items'>
          <TransitionGroup>
            <SwitchTransition >
              <CSSTransition
                key={currentPage}
                timeout={{enter: 300, exit: 300}}
                classNames='slide'
                mountOnEnter
                unmountOnExit
                appear
              >
                <div className={direction}>
                  {items.slice(from, to).map((item, index) => (
                    <div key={item._id}>
                      {children(item)}
                    </div>
                  ))}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </TransitionGroup>
        </div>

        { /* Showing the pager if we have more than 1 page */
          numPages > 1 && <div className='vs-itempager__pager'>
            <button
              onClick={e => this.movePage(-1)}
              className={cx(
                'vs-itempager__pager-button',
                { 'vs-itempager__pager-button--hidden': currentPage === 0 })
              }>
              <Arrow />
              <span>prev</span>
            </button>

            <div className='vs-itempager__page-count'>
              <SwitchTransition >
                <CSSTransition
                  key={currentPage}
                  timeout={{enter: 300, exit: 300}}
                  classNames='fade'
                  mountOnEnter
                  unmountOnExit
                  appear
                >
                  <span className={direction}>
                    {currentPage + 1}
                  </span>
                </CSSTransition>
              </SwitchTransition>
              <span> /</span> {numPages}</div>

            <button
              onClick={e => this.movePage(1)}
              className={cx(
                'vs-itempager__pager-button',
                { 'vs-itempager__pager-button--hidden': currentPage === (numPages - 1) }
              )}>
              <span>next</span>
              <Arrow direction='right' />
            </button>
          </div> }
      </div>
    </div>
  }
}

ItemPager.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  items: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default ItemPager
