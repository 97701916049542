import React from 'react'
import PropTypes from 'prop-types'

import alertCommentPropType from '../alert-comment-proptype'
import AlertCalloutThreadComment from './Comment/AlertCalloutThreadComment'

const AlertCalloutThread = ({comments}) => (
  <div className='fs-alert-comment-thread'>
    {comments.map(comment => (
      <div className='fs-alert-comment-thread__comment' key={`${comment.createdBy}${comment.createdAt}`}>
        <AlertCalloutThread.Comment comment={comment} />
      </div>
    ))}
  </div>
)

AlertCalloutThread.propTypes = {
  /** an array of user comments */
  comments: PropTypes.arrayOf(alertCommentPropType).isRequired
}

AlertCalloutThread.Comment = AlertCalloutThreadComment

AlertCalloutThread.displayName = 'AlertCallout.Thread'

export default AlertCalloutThread
