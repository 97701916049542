/** Group allocations by facility
 */
const groupByFacility = (allocations) => {
  return allocations.reduce((groups, a) => Object.assign({}, groups, {
    [a.facilityId]: (groups[a.facilityId] || []).concat(a)
  }), {})
}

/** Take the latest allocation of each facility
 *
 * Takes as argument a list of allocations that might contain
 * multiple allocations for a single facility.
 * This will reduce the list to only contain one latest allocation
 * per facility.
 */
const takeLatest = (allocations) => {
  const grouped = groupByFacility(allocations)
  return Object.keys(grouped).map(
    id => sortDateDescending(grouped[id])[0]
  )
}

/** Sort allocations by date descending
 */
const sortDateDescending = (allocations, dateField = 'date') =>
  allocations.sort((a, b) => {
    if (a[dateField] > b[dateField]) {
      return -1
    }
    if (a[dateField] < b[dateField]) {
      return 1
    }
    // if dates are equal, look at `createdAt`
    return a.createdAt > b.createdAt ? -1 : 1
  })

module.exports = {
  groupByFacility,
  sortDateDescending,
  takeLatest
}
