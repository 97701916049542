module.exports = translateReport

const docToStockCountRecord = require('./doc-to-stock-count-record')
const get = require('lodash/get')

const toOldFormatUnbatchedStock = stock => {
  return Object.keys(stock).reduce((unbatched, productId) => {
    unbatched[productId] = stock[productId].available || stock[productId].amount
    return unbatched
  }, {})
}

const toNewFormatUnbatchedStock = stock => {
  return Object.keys(stock).reduce((unbatched, productId) => {
    const value = stock[productId]
    unbatched[productId] = value
    return unbatched
  }, {})
}

const VAN_SERVICE = {
  id: 'program:immunization:service:immunization',
  program: { reportingPeriod: 'weekly' }
}

function translateReport (report, version, service) {
  const reportVersion = get(report, 'version')

  let translateToV1 = reportVersion === '2.0.0' && version === '1.0.0'

  const PSM = service && (service.id !== VAN_SERVICE.id)

  // Heads up! This is for converting a PSM '1.0.0' stock count
  // into the OLD NAV format!
  if (reportVersion === '1.0.0' && version === '1.0.0' && PSM) {
    translateToV1 = true
  }

  if (translateToV1) {
    const stockCount = docToStockCountRecord(report, service)

    const storeType = stockCount.location.level
    delete stockCount.location.level
    const weekStr = stockCount.date.reportingPeriod
    delete stockCount.date.reportingPeriod

    const {
      _id,
      location,
      date,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      stock,
      submittedAt
    } = stockCount

    const oldVersion = {
      _id,
      type: report.type,
      version,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      location,
      date,
      weekStr,
      store: {
        type: storeType
      },
      stock: toOldFormatUnbatchedStock(stock)
    }

    if (submittedAt) {
      oldVersion.submittedAt = submittedAt
    }

    // Always add a Service ID so we can recognize different stock counts
    oldVersion.serviceId = service.id

    return oldVersion
  }

  // A PSM report that needs to have the new version
  if (version === '2.0.0' && reportVersion !== '2.0.0') {
    const {
      _id,
      type,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      submittedAt,
      stock,
      expiry
    } = report

    const newVersion = {
      _id,
      type,
      version,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      serviceId: service.id,
      stock: toNewFormatUnbatchedStock(stock)
    }

    if (submittedAt) {
      newVersion.submittedAt = submittedAt
    }

    // This is used in many places to mark the report as complete
    // Important for example for the ledger balance
    if (!submittedAt && reportVersion === undefined) {
      newVersion.submittedAt = report.updatedAt
    }

    if (service.submitsExpiry) {
      newVersion.expiry = expiry
    }

    return newVersion
  }
  return report
}
