import { isValidEmail } from '../../../../../api/lib/utils/validation'
/**
 * Returns a set of given fields cleaned of errors
 *
 * @param {Object} fields
 */
export const cleanFields = (fields) => Object.keys(fields).reduce((cleanedFields, key) => ({
  ...cleanedFields,
  [key]: {
    ...fields[key],
    errors: []
  }
}), {})

/**
 * Returns an array of Field validators inferred from props
 *
 * @param {Object} field
 */
export const getFieldValidators = (field) => {
  const validators = []
  if (field.required) {
    validators.push(field.requiredValidator)
  }
  if (field.validate && typeof field.validate === 'function') {
    validators.push(field.validate)
  }

  if (field.type === 'email') {
    validators.push((value) => {
      if (!isValidEmail(value)) {
        return 'Invalid email format.'
      }
      return null
    })
  }
  return validators
}

/**
 * Helper function to retrieve a field errors for a given fieldName and field
 * set.
 *
 * @param {string} fieldName
 * @param {object} fields
 */
export const getFieldErrors = (fieldName, fields) => fields[fieldName].errors

/**
 * Returns true if a given fieldName in a given fieldset has validation errors
 *
 * @param {string} fieldName
 * @param {object} fields
 */
export const fieldHasErrors = (fieldName, fields) => {
  if (!fields[fieldName]) return false
  return !!(fields[fieldName].errors && fields[fieldName].errors.length)
}

/**
 * Maps a new field object
 *
 * @param {object} field
 */
export const mapField = (field) => {
  const nextField = {
    ...field,
    validators: getFieldValidators(field),
    errors: []
  }
  return nextField
}

/**
 * Check whether given type is 'texty'.
 * Will return true for all types except `file` and `submit`.
 *
 * @param {string} type
 */
export const isTexty = (type) => () => {
  switch (type) {
    case 'file':
    case 'submit':
    case 'radio':
    case 'checkbox':
      return false
    default:
      return true
  }
}

/**
 * Returns a reset field
 *
 * @param {string} fieldName
 * @param {object} fields
 * @param {object} initialFields
 */
export const resetField = (fieldName, fields, initialFields) => {
  const initialField = initialFields[fieldName]
  const field = {
    ...fields[fieldName],
    errors: []
  }

  return {
    ...field,
    value: initialField.defaultValue || initialField.value || ''
  }
}

/**
 * Returns either a custom validation error message or a default message
 *
 * @param {boolean|string} required
 */
export const requiredValidationErrorMessage = (required) => typeof required === 'string' ? required : 'This field is required'
