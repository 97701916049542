import React from 'react'
import classnames from 'classnames'

import { getHeaderDetails } from '../shipments/helpers'
import withConfig from '../../../van-shared/hoc/withConfig'
import ServiceCard from '../../../van-shared/components/ServiceCard'
import ServiceListCardContent from './ServiceListCardContent'
import { RETAILER_SERVICES } from '../../../van-shared/components/ServiceCard/service-constants'

const ServiceListCard = ({
  config,
  service,
  history,
  isTitleShortFormat,
  className
}) => {
  const serviceDetails = service.serviceDetails

  const {
    status,
    date,
    url,
    productsCount,
    withODProducts,
    withPAYSProducts
  } = serviceDetails

  let { serviceType } = serviceDetails

  const { COLLECTION, PROVISIONAL } = RETAILER_SERVICES
  const { title, icon } = getHeaderDetails(service, serviceType, isTitleShortFormat)
  const isProvisional = serviceType === PROVISIONAL

  // we need to change any service type value of 'return'
  // cause we currently work with 'collection' service type
  if (serviceType === 'return') {
    serviceType = COLLECTION
  }

  // there is no link (url) for the next count
  const onViewItem = (url) => {
    if (url) {
      history.push({
        pathname: url,
        state: { prevPath: history.location.pathname }
      })
    }
  }

  return (
    <ServiceCard
      className={classnames(
        'service-visit__card',
        {'service-visit__card--with-pointer': url},
        className
      )}
      colorVariant={serviceType}
      withBorder
      onClick={() => onViewItem(url)}
    >
      <ServiceListCardContent
        title={title}
        icon={icon}
        status={status}
        date={date}
        url={url}
        productsCount={productsCount}
        withODProducts={withODProducts}
        withPAYSProducts={withPAYSProducts}
        isProvisional={isProvisional}
        colorVariant={serviceType}
      />
    </ServiceCard>
  )
}

export default withConfig(ServiceListCard)
