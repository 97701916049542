const installments = require('./common/installments')
const txnTypes = require('./common/txn-types')
const transactions = require('./common/transactions')
const transactionsListSelector = require('./common/transactions-list-selector')
const installmentsListSelector = require('./common/installments-list-selector')

const transactionsSelect = transactionsListSelector()
const installmentsSelect = installmentsListSelector(`WHERE due_date <= (current_date + interval '6 days')`)

const createUpcomingDueTransactionsQuery = ({
  isNotificationSend
} = {}) => {
  const cte = [
    transactions,
    installments,
    txnTypes
  ]
  cte.push(
    `transactions_list AS (
        ${transactionsSelect}
        UNION
        SELECT * FROM (${installmentsSelect}) i
      )`
  )
  return `
    WITH ${cte.join(', ')}
    SELECT tl.*, posting_type FROM transactions_list tl
    LEFT JOIN txn_types tt ON tl.txn_type = tt.txn_type
    ${isNotificationSend ? 'LEFT JOIN "avocado"."data_notification" n ON  "n"."trigger_event" = concat("tl"."id", \':notified\')' : ''}
  `
}

module.exports = { createUpcomingDueTransactionsQuery }
