import React, { Component } from 'react'
import get from 'lodash/get'

import { Loading } from '@fielded/shared-ui'

import { batchIdToProductId } from '@fielded/fs-api/lib/batch/tools'

import { isReturnShipment } from '../../../common/utils/shipment'
import { getShipmentProductIds } from './helpers'

import ShipmentSummary from './ShipmentSummary'

function createPickList (shipment, productsById) {
  const pickList = {}
  for (const [batchId, count] of Object.entries(shipment.counts)) {
    const productId = batchIdToProductId(batchId)
    const product = productsById.get(productId)
    if (!pickList[productId]) {
      pickList[productId] = {
        id: productId,
        name: product.name,
        code: product.code,
        total: count.quantity,
        picked: count.quantity,
        adjustment: count.adjustment,
        snapshotId: shipment.snapshotId
      }
    } else {
      pickList[productId].total += count.quantity
      pickList[productId].picked += count.quantity
      pickList[productId].adjustment += count.adjustment
    }
  }
  return pickList
}

export default class ShipmentSummaryContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  async componentDidMount () {
    let {
      api,
      isExternalPlanner,
      snapshotId
    } = this.props

    // If no snapshotid get from url if available
    if (!snapshotId) {
      snapshotId = this.props.match.params
    }

    const options = {checkSnapShotLocation: true}

    if (isExternalPlanner) {
      options.checkSnapShotLocation = false
    }

    // Find shipment
    const shipment = (await api.shipment.find(snapshotId, options))[0]

    // Create pick list
    const productIds = getShipmentProductIds(shipment)
    const products = await api.product.getByIds(productIds)
    const productsById = products.reduce(
      (map, p) => map.set(p._id, p), new Map()
    )
    const pickList = createPickList(shipment, productsById)

    // Find driver name
    const location = await api.location.get(shipment.destination.id)
    const routeId = get(location, 'programs.0.services.0.routeId', '')
    shipment.driverName = routeId.replace('route:', '')

    this.setState({
      shipment,
      pickList,
      loading: false
    })
  }

  render () {
    const {
      user,
      config
    } = this.props
    const {
      loading,
      shipment,
      pickList
    } = this.state
    const { history } = this.props

    if (loading) {
      return <Loading />
    }

    return (
      <div className='van-shipments'>
        <ShipmentSummary
          shipment={shipment}
          isReturn={isReturnShipment(user, shipment)}
          pickList={pickList}
          history={history}
          appName={config.name}
        />
      </div>
    )
  }
}
