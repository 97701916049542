const get = require('lodash/get')

const latestUpdateFirst = (a, b) =>
  (a.updatedAt > b.updatedAt) ? -1 : ((a.updatedAt < b.updatedAt) ? 1 : 0)

const getLastUpdated = docs => [...docs].sort(latestUpdateFirst)[0]
exports.getLastUpdated = getLastUpdated

const locationListIsEditable = ({groupId, locations}) =>
  groupId && locations && locations.filter(location => location.level === 'sdp')
exports.locationListIsEditable = locationListIsEditable

exports.getAllSnapshots = getAllSnapshots
function getAllSnapshots (snapshotsByStatus = {}) {
  return Object.keys(snapshotsByStatus)
    .reduce((acc, status) => {
      return acc.concat(snapshotsByStatus[status])
    }, [])
}

exports.getDestinationIdsOnOrders = getDestinationIdsOnOrders
function getDestinationIdsOnOrders (orders, groupId) {
  const idSet = orders
    .filter(order => (!groupId) || order.groupId === groupId)
    .reduce((acc, {destinationId}) => {
      acc.add(destinationId)
      return acc
    }, new Set())

  return [...idSet]
}

const filterSnapshotsByStatus = (snapshots, status) =>
  snapshots.filter(item => item.status === status)
exports.filterSnapshotsByStatus = filterSnapshotsByStatus

const flattenOrdersSnapshots = orders => orders.reduce((snapshots, {snapshotsByStatus}) => {
  let orders = []
  Object.values(snapshotsByStatus).forEach(order => {
    orders = orders.concat(order)
  })
  return snapshots.concat(orders)
}, [])
exports.flattenOrdersSnapshots = flattenOrdersSnapshots

exports.getFunderOnProgram = getFunderOnProgram
function getFunderOnProgram (location, programId) {
  const program = (location.programs && location.programs
    .find(program => program.id === programId)) || {}
  // While funders are stored on service because that's where history is tracked,
  // we can just check the first service because the API guarantees
  // only one funder per program:location.
  return get(program, 'services.0.funderId')
}

exports.getRouteOnProgram = getRouteOnProgram
function getRouteOnProgram (location, programId) {
  const program = (location.programs && location.programs
    .find(program => program.id === programId)) || {}
  // While routes are stored on service because that's where history is tracked,
  // we can just check the first service because the API guarantees
  // only one route per program:location.
  return get(program, 'services.0.routeId')
}

exports.getLocationProgramFunder = getLocationProgramFunder
function getLocationProgramFunder (location, programId) {
  const program = location.programs
    .find(program => program.id === programId) || {}
  return get(program, 'services.0.funderId')
}

exports.getLocationProgramRoute = getLocationProgramRoute
function getLocationProgramRoute (location, programId) {
  const program = location.programs
    .find(program => program.id === programId) || {}
  return get(program, 'services.0.routeId')
}

exports.getLtForSelector = getLtForSelector
function getLtForSelector (locationId) {
  // turns 'country:ng:state:fct' into 'country:ng:state:fcu' so we can use it as $lt in couchdb find
  return locationId.slice(0, locationId.length - 1).concat(String.fromCharCode(locationId.charCodeAt(locationId.length - 1) + 1))
}
