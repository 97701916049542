const RestAdapter = require('./rest-adapter')
const TransactionsApi = require('./transactions')

class ProductSubscriptionApi {
  constructor (state, mainApi, restAdapter) {
    const restApi = new RestAdapter(restAdapter)
    this.transactions = new TransactionsApi(state, mainApi, restApi)
  }
}

module.exports = ProductSubscriptionApi
