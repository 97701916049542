const SHIPMENT_IMPORT_SHEET_NAME = 'shipments'

const SHIPMENT_IMPORT_HEADERS = [
  'date',
  'destinationWarehouseCode',
  'productId',
  'batchNo',
  'manufacturer',
  'expiry',
  'quantity',
  'programId',
  'funderId',
  'productCodeAlias'
]

module.exports = {
  SHIPMENT_IMPORT_SHEET_NAME,
  SHIPMENT_IMPORT_HEADERS
}
