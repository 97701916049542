const cloneDeep = require('lodash/cloneDeep')
const get = require('lodash/get')

// the doc, from pouch/couch, boolean if we should filter out programs not in the
// user's programs list, the user's programs list.
exports.docToEntity = function (couchDoc, shouldFilterPrograms, userPrograms = []) {
  const doc = cloneDeep(couchDoc)
  const programs = shouldFilterPrograms
    ? doc.programs.filter(program => userPrograms.includes(program.id))
    : doc.programs

  // Add program object to each service to make the service
  // struct look the same as what is returned from the service
  // api methods.
  for (const program of programs) {
    // We have to define quaterly and bimonthly reporting periods
    let periodQuantity, periodUnit
    const period = get(program, 'report.periods[0]')

    if (period && period.periodType) {
      periodQuantity = period.periodType.quantity
      periodUnit = period.periodType.unit
    }

    if (periodQuantity === 3 && periodUnit === 'month') {
      program.reportingPeriod = 'quarterly'
    }

    for (const service of program.services) {
      let p2 = Object.assign({}, program)
      delete p2.services
      service.program = p2
    }
  }
  return programs
}
