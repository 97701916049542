const tools = require('../tools')

module.exports = {
  get, list, create, update, tools
}

function create (state, params) {
  return state.dal.proposal.create(state, Object.assign({}, params, { user: state.user }))
}

function get (state, { entityId, entityType, proposalId, date }) {
  return state.dal.proposal.get(state, { entityId, entityType, proposalId, date })
}

function list (state, { entityType, currentEntities, startDate, endDate }) {
  return state.dal.proposal.list(state, { entityType, currentEntities, startDate, endDate })
}

function update (state, { proposalId, status }) {
  return state.dal.proposal.update(state, { proposalId, status, user: state.user })
}
