import React from 'react'

export const TruckGift = () => {
  return (
    <svg viewBox='0 0 40 34' fill='none' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#a)' fill='#27303D'>
        <path d='M33.014 27.452c.44 0 .87.131 1.235.379.366.247.651.599.82 1.01a2.282 2.282 0 0 1-.482 2.456 2.194 2.194 0 0 1-2.422.488 2.228 2.228 0 0 1-.997-.83 2.275 2.275 0 0 1 .278-2.842c.416-.422.98-.66 1.568-.66m0-2.047c-.839 0-1.658.253-2.355.725a4.286 4.286 0 0 0-1.562 1.929 4.354 4.354 0 0 0-.241 2.483c.164.834.567 1.6 1.16 2.2a4.222 4.222 0 0 0 2.17 1.177c.823.166 1.676.08 2.45-.245a4.252 4.252 0 0 0 1.903-1.583 4.34 4.34 0 0 0-.528-5.425 4.21 4.21 0 0 0-2.997-1.258v-.003Z' />
        <path d='M33.014 27.452c.44 0 .87.131 1.235.379.366.247.651.599.82 1.01a2.282 2.282 0 0 1-.482 2.456 2.194 2.194 0 0 1-2.422.488 2.228 2.228 0 0 1-.997-.83 2.275 2.275 0 0 1 .278-2.842c.416-.422.98-.66 1.568-.66m0-2.047c-.839 0-1.658.253-2.355.725a4.286 4.286 0 0 0-1.562 1.929 4.354 4.354 0 0 0-.241 2.483c.164.834.567 1.6 1.16 2.2a4.222 4.222 0 0 0 2.17 1.177c.823.166 1.676.08 2.45-.245a4.252 4.252 0 0 0 1.903-1.583 4.34 4.34 0 0 0-.528-5.425 4.21 4.21 0 0 0-2.997-1.258v-.003ZM6.01 27.183H1.23c-.326 0-.639.132-.87.366a1.256 1.256 0 0 0 0 1.764c.231.234.544.366.87.366h4.026a4.15 4.15 0 0 1 .754-2.495Z' />
        <path d='m39.801 24.334-5.48-9.665h-8.29v12.514H8.759a4.15 4.15 0 0 1 .765 2.496h18.46v-.034a5.146 5.146 0 0 1 1.517-3.54 5.006 5.006 0 0 1 3.524-1.457c1.318 0 2.583.523 3.526 1.457a5.146 5.146 0 0 1 1.515 3.54H40v-4.554c0-.265-.068-.526-.199-.757ZM28.247 19.53v-2.536c0-.089.035-.174.096-.236a.327.327 0 0 1 .233-.098h4.65a.328.328 0 0 1 .285.168l4.331 7.695-9.595-4.993Z' />
        <path d='M7.363 27.452a2.2 2.2 0 0 1 1.234.38c.366.247.65.599.819 1.01a2.281 2.281 0 0 1-.482 2.455 2.193 2.193 0 0 1-2.421.488 2.229 2.229 0 0 1-.997-.83 2.274 2.274 0 0 1 .277-2.841 2.206 2.206 0 0 1 1.57-.66m0-2.045c-.839 0-1.659.251-2.356.723a4.286 4.286 0 0 0-1.563 1.93 4.353 4.353 0 0 0-.242 2.483c.164.834.568 1.6 1.16 2.201a4.223 4.223 0 0 0 2.172 1.177c.823.166 1.675.08 2.45-.245a4.252 4.252 0 0 0 1.902-1.585 4.34 4.34 0 0 0 .714-2.389c0-1.139-.446-2.232-1.24-3.038a4.21 4.21 0 0 0-2.997-1.26v.003ZM20.415 8.007a3.905 3.905 0 0 0 1.925-.505 3.97 3.97 0 0 0 1.437-1.391 4.041 4.041 0 0 0 .172-3.897A3.987 3.987 0 0 0 22.756.78a3.895 3.895 0 0 0-3.55-.58 3.938 3.938 0 0 0-1.578.98l-4.076 4.132L9.475 1.18A3.938 3.938 0 0 0 7.898.2a3.895 3.895 0 0 0-3.55.581 3.988 3.988 0 0 0-1.194 1.433A4.048 4.048 0 0 0 3.32 6.1c.351.58.843 1.06 1.428 1.393.586.334 1.245.51 1.917.513h13.75ZM19.083 2.65a1.836 1.836 0 0 1 1.325-.558c.35-.001.695.097.994.283a1.93 1.93 0 0 1 .615 2.631 1.894 1.894 0 0 1-.684.665c-.28.158-.597.242-.918.241h-4.547l3.215-3.262ZM6.665 5.912c-.321 0-.637-.083-.917-.241a1.897 1.897 0 0 1-.685-.664 1.93 1.93 0 0 1 .614-2.632c.3-.187.644-.285.996-.283a1.81 1.81 0 0 1 1.322.558l3.215 3.262H6.665Z' />
        <path d='M1.964 25.647h.734c1.096-1.25 2.693-2.279 4.474-2.279 1.78 0 3.378 1.034 4.474 2.279h1.065V15.315H1.964v10.332ZM1.964 9.376v4.267h10.747V9.376h1.659v4.267h10.058V9.376H1.964ZM24.43 15.325H14.37v10.322h10.06V15.325Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h40v34H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
