module.exports = { suggestBatches, sortFEFO }

const { getUnknownBatchID, getTBDBatchID } = require('./virtual-batch')
const { validateSuggestBatchesParams } = require('../validate/validators')

function suggestBatches (params) {
  const { ledger, batches, shoppingList, products, expiresAfter } = params
  const expiresAfterDate = expiresAfter || new Date().toJSON()
  const usageErrors = validateSuggestBatchesParams(params)
  if (usageErrors) {
    throw new Error(usageErrors[0].message)
  }

  return Object.keys(shoppingList).reduce((memo, productId) => {
    const productBatches = forProduct(
      productId, shoppingList[productId], products, batches, ledger, expiresAfterDate
    )
    return Object.assign(memo, productBatches)
  }, {})
}

function forProduct (productId, rawDesiredQuantity, products, expiryMap, ledger, expiresAfter) {
  const presentation = parseInt(products[productId].presentation, 10)
  const desiredQuantity = roundToPresentation(rawDesiredQuantity, presentation)

  // Unbatched product
  if (!ledger[productId] || !ledger[productId].batches) {
    return {
      [getUnknownBatchID(productId)]: {
        quantity: desiredQuantity
      }
    }
  }

  const productBatches = {}
  const fefoBatches = sortFEFO(ledger[productId].batches, expiryMap, expiresAfter)
  let batch
  let remainingQuantity = desiredQuantity

  for (let i = 0; i < fefoBatches.length; i++) {
    batch = fefoBatches[i]
    if (batch.quantity <= 0) continue

    const quantity = batch.quantity >= remainingQuantity
      ? remainingQuantity
      : batch.quantity
    productBatches[batch.id] = { quantity }
    remainingQuantity -= quantity

    if (remainingQuantity <= 0) {
      break
    }
  }

  if (remainingQuantity) {
    productBatches[getTBDBatchID(productId)] = { quantity: remainingQuantity }
  }

  return productBatches
}

// Using '' for missing expiration to sort it first before existing expirations.
function sortFEFO (ledgerBatches, expiryMap, expiresAfter) {
  return Object.keys(ledgerBatches).map(id => {
    return {
      id,
      quantity: ledgerBatches[id],
      expiry: expiryMap[id] ? expiryMap[id].expiry : ''
    }
  })
    .sort(sortByExpiry)
    .filter(batch => filterByExpiry(batch, expiresAfter))
}

function roundToPresentation (value, presentation) {
  return presentation * Math.ceil(value / presentation)
}

function sortByExpiry (a, b) {
  if (!a.expiry && b.expiry) {
    return -1
  }
  if (!b.expiry && a.expiry) {
    return 1
  }
  if (a.expiry < b.expiry) {
    return -1
  }
  if (a.expiry > b.expiry) {
    return 1
  }
  return 0
}

function filterByExpiry (batch, expiresAfter) {
  if (!batch.expiry) {
    return true
  }
  return batch.expiry > expiresAfter
}
