import RestAdapter from '@fielded/fs-api/lib/common/rest-adapter'
import CurrentUserApiAdapter from '@fielded/fs-api/lib/current-user'

class ForgotPasswordApi {
  constructor (props) {
    const restAdapter = new RestAdapter(global.window.fetch, '', props.agaveApiUrl)
    this.currentUserAdapter = new CurrentUserApiAdapter(restAdapter)
  }

  submit (resetToken, data) {
    return this.currentUserAdapter.changePassword(resetToken, data)
  }
}

export default ForgotPasswordApi
