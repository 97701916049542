const PGAdapter = require('./../common/pg-adapter')
const QUICKBOOKS_LOCATION_TABLE_NAME = 'avocado.data_quickbookslocation'
const QUICKBOOKS_LOCATION_COLUMNS = [
  'id',
  'created_at',
  'updated_at',
  'company_code',
  'quickbooks_customer_id',
  'location_id',
  'quickbooks_sync_token'
]

class QuickbooksLocationAdapter extends PGAdapter {
  constructor (pgConnection, username) {
    super(
      pgConnection,
      QUICKBOOKS_LOCATION_TABLE_NAME,
      username,
      QUICKBOOKS_LOCATION_COLUMNS,
      'id'
    )
  }
  async getOneWithMarketData (id, { whereCondition = 'id' }) {
    const query = `
      SELECT "ql".*, "l"."market", "l"."code", "l"."email", "m"."id" as "market_id"
      FROM "avocado"."data_quickbookslocation" ql
      LEFT JOIN "avocado"."data_location" l ON "l"."uuid" = "ql"."location_id"
      LEFT JOIN "avocado"."data_market" m ON l.market ilike any (m.aliases)
      WHERE "ql"."company_code" != 'LEGACY' AND "ql"."${whereCondition}" = $1
    ` // we need exclude legacy from query
    const { rows } = await this.pgConnection.query(query, [id])
    const row = rows[0]
    if (!row) {
      const err = new Error('Row not found')
      err.status = 404
      throw err
    }
    return row
  }
}

module.exports = QuickbooksLocationAdapter
