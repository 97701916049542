const installmentsListSelector = `
  SELECT
    id,
    location_id,
    due_date,
    amount,
    amount_paid,
    null as description,
    'installment' as txn_type,
    code as quickbooks_doc_number,
    least(now()::date, due_date) as txn_date,
    created_at,
    updated_at,
    null as order_type,
    payment_plan,
    null as shipment_id,
    null as order_id
  FROM installments
`

module.exports = (whereCondition) => `${installmentsListSelector} ${whereCondition || ''}`
