const { sha512 } = require('./../../tools/hash')
const {getOTPHeaders} = require('./utils')

module.exports = validateOTP

async function validateOTP ({remitaTransRef, params}) {
  const {apiToken, merchantId, apiKey} = this

  const d = new Date()
  const requestId = d.getTime()

  const hash = await sha512(`${apiKey}${requestId}${apiToken}`)
  const headers = getOTPHeaders({apiToken, merchantId, apiKey, requestId, hash})

  const body = {
    remitaTransRef,
    // these look like: [
    //   {'param1': 'OTP', 'value': otp},
    //   {'param2': 'CARD', 'value': card}
    //  ]
    authParams: params
  }
  return this.fetch(this.endpoints.validateOTP, {
    method: 'POST',
    body: JSON.stringify(body),
    headers
  })
}
