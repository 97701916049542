import React from 'react'
import classnames from 'classnames'

const AccordionSectionContent = ({ children, className }) => (
  <div className={
    classnames(
      'vs-accordion__section-content',
      className
    )}
  >
    {children}
  </div>
)

export default AccordionSectionContent
