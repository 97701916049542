const {
  ItemsMap,
  updateQuickbooksItems
} = require('./items')

const createLines = async ({
  quickbooks,
  companyCode,
  vatRate,
  lines,
  hasCustomVatLine = false
}) => {
  const accountIds = quickbooks.quickbooksInterface.accountIds[companyCode]

  // Get or create quickbooks items
  const itemsMap = new ItemsMap()
  const totalVatItemName = `Total VAT (${vatRate * 100}%)`
  if (hasCustomVatLine) {
    itemsMap.set(totalVatItemName, { accountId: accountIds.incomeProductAccountId })
  }
  for (const { isFee, item, hsCode } of lines) {
    const accountId = isFee
      ? accountIds.incomeServiceAccountId
      : accountIds.incomeProductAccountId
    itemsMap.set(item, { accountId, hsCode })
  }
  const items = await updateQuickbooksItems(
    quickbooks, { itemsMap, companyCode }
  )

  let totalVat = 0
  const qboLines = []

  for (const line of lines) {
    const item = items.get(line.item)
    totalVat += line.vat
    const qboLine = {
      DetailType: 'SalesItemLineDetail',
      Amount: line.gross,
      Description: line.description,
      SalesItemLineDetail: {
        Qty: line.quantity,
        UnitPrice: line.price,
        ItemRef: {
          value: item.Id,
          name: item.Name
        }
      }
    }
    if (!hasCustomVatLine) {
      qboLine.SalesItemLineDetail.TaxCodeRef = {
        value: line.vatable ? 'TAX' : 'NON'
      }
    }
    qboLines.push(qboLine)
  }

  if (hasCustomVatLine && totalVat > 0) {
    const item = items.get(totalVatItemName)
    qboLines.push({
      DetailType: 'SalesItemLineDetail',
      Amount: totalVat,
      SalesItemLineDetail: {
        Qty: 1,
        UnitPrice: totalVat,
        ItemRef: {
          value: item.Id,
          name: item.Name
        }
      }
    })
  }

  return qboLines
}

module.exports = {
  createLines
}
