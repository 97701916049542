const { Format } = require('./serialize-pg-result')

// The product-rest-adapter provides access to products from postgres.
// It'll call the agave product rest endpoint which in turn uses
// the `./product-pg-adapter.js` to read/write products.
// The `list` method of the rest-adapter takes an optional `format`
// parameter. The `format` controls the shape of the products returned.
// See the `./serialize-pg-result` file for format options.

class ProductRestAdapter {
  constructor (restAdapter, logger) {
    this.restAdapter = restAdapter
    this.logger = logger
    this.Format = Format
  }

  async list ({ ordering, filter, offset, limit, format = Format.Couch } = {}) {
    let params = { format }
    if (offset != null) {
      params.offset = offset
    }
    if (limit != null) {
      params.limit = limit
    }
    if (ordering != null) {
      params.ordering = ordering
    }
    if (filter != null) {
      // Merge the filter props into the params object,
      // because the backend expects it that way in the url.
      params = { ...filter, ...params }
    }

    return this.restAdapter.get('product', params)
  }
}

module.exports = ProductRestAdapter
