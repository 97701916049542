module.exports = function toRelationalModel (locationUUID, routeUUID, assignment) {
  const rel = {
    location_id: locationUUID,
    route_id: routeUUID,
    start_date: assignment.startDate
  }

  if (assignment.endDate) {
    rel.end_date = assignment.endDate
  }
  return rel
}
