const get = require('lodash/get')

module.exports = getProductsForFollowUp
function getProductsForFollowUp (shipment, snapshot) {
  const newSnapshot = snapshot || Object.entries(shipment.history).find(([key]) => key.includes(':status:new:'))[1]
  const changes = Object.entries(newSnapshot.counts)
    .map(([productId, data]) => {
      const receivedQuantity = get(shipment, `counts.${productId}.quantity`, 0)
      const diff = receivedQuantity - data.quantity
      return ({
        ...data,
        productId,
        diff,
        receivedQuantity
      })
    })
  return changes.filter(({ diff }) => diff < 0)
}
