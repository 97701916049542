exports.listProducts = listProducts
function listProducts (
  {
    products,
    orders,
    groupId,
    destinationId
  }) {
  const ordersByProductId = getOrdersByProductId(orders)

  // listProductsOnDestination returns an orderId, as a given group for a given
  // destination will have only one order
  const orderId = findOrderId(destinationId, groupId, orders)

  const rows = products.map(product => {
    return getByProductsRow({product, ordersByProductId, groupId, orderId})
  })

  const sortedRows = sortOnName(rows)
  return flatten(sortedRows)
}

// This returns: { productId: [ list of order details for that product id ] }
function getOrdersByProductId (orders) {
  return orders
    .reduce((acc, order) => {
      Object.keys(order.products).forEach(productId => {
        acc[productId] = acc[productId] || []
        acc[productId].push(Object.assign({}, order.products[productId], {
          groupId: order.groupId,
          orderId: order.orderId,
          status: order.status,
          statusDisplayName: order.statusDisplayName,
          orderType: order.orderType
        }))
      })
      return acc
    }, {})
}

function getByProductsRow (
  {product, ordersByProductId, groupId, orderId}
) {
  const row = Object.assign({}, product, {
    order: {original: 0, adjusted: 0, inOtherOrders: 0}
  })
  const ordersOnProduct = ordersByProductId[product._id]
  if (!ordersOnProduct) return row

  ordersOnProduct.forEach(orderProductDetails => {
    const {comments, orderType} = orderProductDetails
    row.order.original += orderProductDetails.original
    row.order.status = orderProductDetails.status
    row.order.statusDisplayName = orderProductDetails.statusDisplayName

    if (!isMember(orderProductDetails, groupId, orderId)) {
      row.order.inOtherOrders += orderProductDetails.adjusted
      return
    }
    if (comments && comments.length > 0) {
      row.order.hasComments = true
    }

    row.order.adjusted += orderProductDetails.adjusted

    if (orderType) {
      const assigededOrderType = row.order.orderType
      const joinedOrderType = assigededOrderType || []
      if (assigededOrderType && !assigededOrderType.includes(orderType)) {
        joinedOrderType.push(orderType)
      }
      row.order.orderType = joinedOrderType
    }
  })

  return row
}

// Allows filtering on just order id or group id
// for list on location or list across locations
function isMember (orderProductDetails, groupId, orderId) {
  if (orderId) return orderProductDetails.orderId === orderId

  if (!groupId) return true

  return orderProductDetails.groupId === groupId
}

// If there's only one order for the destination and group
exports.findOrderId = findOrderId
function findOrderId (destinationId, groupId, orders) {
  if (!destinationId) return

  const filteredOrders = orders
    .filter(order => order.destinationId === destinationId)
    .filter(order => (!groupId || order.groupId === groupId))

  if (filteredOrders.length === 1) {
    return filteredOrders[0].orderId
  }
}

function sortOnName (products) {
  return products
    .sort((first, second) => first.fullName.localeCompare(second.fullName))
}

function flatten (rows) {
  return rows.map(row => {
    const {order} = row
    delete row.order
    return Object.assign({}, row, order)
  })
}
