const deepSet = require('lodash/set')
const deepGet = require('lodash/get')
const cloneDeep = require('lodash/cloneDeep')
const mergeDeep = require('lodash/merge')
const { byKey } = require('../../tools/by-id')
const createReport = require('./create')
const getPeriodFromProgram = require('./get-period-from-program')

exports.createBulkImportReports = createBulkImportReports
function createBulkImportReports ({
  programs,
  serviceDocs,
  locationEntities,
  productRows,
  username,
  version,
  date = new Date().toJSON()
}) {
  throwOnBadRow(productRows)
  const services = serviceDocs.map(doc => withProgram(doc, programs))
  // returns [{location, rows}, {location, rows}]
  const rowsByLocation = getRowsByLocation(productRows, locationEntities)
  // returns [{location, services: {service, rows}}]
  const rowsByLocationService = getRowsByLocationService(rowsByLocation, services)
  const reports = []
  rowsByLocationService.forEach(row => {
    row.services.forEach(({service, rows}) => {
      const period = getPeriodFromProgram(service.program, date, true)
      const stock = getStockFromRows(rows, version)
      const report = createReport({
        locationId: row.location._id,
        service,
        stock,
        username,
        createdAt: date,
        submittedAt: date,
        period,
        version
      })
      reports.push(report)
    })
  })
  return reports
}

function withProgram (serviceDoc, programs) {
  const programId = serviceDoc.service.split(':service:')[0]
  const programFromDoc = programs.find(program => program.id === programId)
  const program = Object.assign({}, programFromDoc)
  delete program.services
  return Object.assign({}, serviceDoc, {
    id: serviceDoc.service,
    program
  })
}

exports.getRowsByLocation = getRowsByLocation
function getRowsByLocation (rows, locations) {
  const locationsMap = byKey(locations, '_id')
  throwIfMissingLocation(rows, locationsMap)
  const byLocationId = rows
    .reduce((acc, row) => {
      if (!acc[row.locationId]) {
        acc[row.locationId] = {location: locationsMap[row.locationId], rows: []}
      }
      acc[row.locationId].rows.push(row)
      return acc
    }, {})
  return Object.values(byLocationId)
}

function throwIfMissingLocation (rows, locationsMap) {
  const missingLocationRow = rows.find(row => !locationsMap[row.locationId])
  if (missingLocationRow) {
    throw new Error(
      `No location found for row with locationId ${missingLocationRow.locationId}`
    )
  }
}

function getRowsByLocationService (rowsByLocation, services) {
  return rowsByLocation.map(({location, rows}) => {
    return {
      location,
      services: getRowsByService(services, location, rows)
    }
  })
}

function getRowsByService (services, location, rowsOnLocation) {
  const byServiceId = rowsOnLocation
    .reduce((acc, row) => {
      const service = findService(services, row.programId, row.productId, location)
      if (!service) return acc

      if (!acc[service.id]) {
        acc[service.id] = {service, rows: []}
      }
      acc[service.id].rows.push(row)
      return acc
    }, {})

  return Object.values(byServiceId)
}

// becase we have some products with multiple parent services, find the service
// enabled on the location.
// This depends on PSM's "no location has a product on more than one service",
// if that changes the import file will need to know which service you want per product.
exports.findService = findService
function findService (services, programId, productId, location) {
  const availableServices = services.filter(service =>
    service.products.find(serviceProductId => serviceProductId === productId)
  )
  const locationServices = []
  availableServices.forEach(service => {
    location.programs.forEach(program => {
      if (programId !== program.id) return

      program.services.forEach(locationService => {
        if (locationService.id === service.id) {
          locationServices.push(service)
        }
      })
    })
  })

  if (locationServices.length === 0) {
    console.warn(
      `Location ${location._id}
      does not have service enabled for this product ${productId}`
    )
    return null
  }

  if (locationServices.length > 1) {
    console.warn(
      `Location ${location._id}
      has more than one service supporting this product, ${productId}`
    )
  }

  return locationServices[0]
}

function getStockFromRows (rows, version) {
  return rows
    .reduce((acc, row) => {
      const {productId, quantity, fieldName} = row
      deepSet(acc, `${productId}.${fieldName}`, quantity)
      if (version === '2.0.0' && !deepGet(acc, `${productId}.batches`)) {
        deepSet(acc, `${productId}.batches`, {})
      }
      return acc
    }, {})
}

function throwOnBadRow (rows) {
  const expectedFields = ['locationId', 'programId', 'productId', 'quantity', 'fieldName']
  const badRows = rows.filter(row => {
    const missingField = expectedFields.find(field => row[field] === undefined)
    const notANumber = !Number.isFinite(row.quantity)
    return (missingField || notANumber)
  })
  if (badRows.length) {
    const error = new Error()
    error.message = 'Product import rows are not valid'
    error.data = badRows
    throw error
  }
}

exports.mergeExistingReports = mergeExistingReports
function mergeExistingReports (createdReports, existingReports) {
  return createdReports.map((doc, index) => {
    const existingStock = deepGet(existingReports[index] || {}, 'stock', {})
    return Object.assign({}, doc, {
      stock: mergeDeep(cloneDeep(existingStock), cloneDeep(doc.stock))
    })
  })
}
