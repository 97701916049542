import React, { createRef, PureComponent } from 'react'
import PropTypes from 'prop-types'
import DownloadButton from '../DownloadButton'

class ExportButton extends PureComponent {
  static propTypes = {
    /**
     * render loading state?
     */
    loading: PropTypes.bool.isRequired,

    /**
     * The filename of the export file
     */
    filename: PropTypes.string.isRequired,

    /**
     * The button text to display
     */
    buttonText: PropTypes.string,

    /**
     * Export handler (Promise/A+)
     * Expects the promise to resolve with a URL to 'download'
     */
    onExport: PropTypes.func.isRequired,

    /**
     * Exported handler (Promise/A+)
     */
    onExported: PropTypes.func.isRequired
  }

  static defaultProps = {
    buttonText: 'Export'
  }

  downloadLinkRef = createRef()

  render () {
    const {
      loading,
      filename,
      buttonText,
      onExport,
      onExported,
      ...otherProps
    } = this.props
    return (
      <DownloadButton
        fill='full'
        size='regular'
        loading={loading}
        onClick={() => {
          onExport()
            .then((url) => {
              this.downloadLinkRef.current.download = filename
              this.downloadLinkRef.current.href = url
              this.downloadLinkRef.current.dispatchEvent(new global.MouseEvent('click'))
            })
            .then(onExported)
            .catch((error) => console.warn(error))
        }}
        {...otherProps}
      >
        {/* This is a hidden link that we apply a ref to */}
        <a ref={this.downloadLinkRef} className='vs-u-hide-visually' />
        {buttonText}
      </DownloadButton>
    )
  }
}

export default ExportButton
