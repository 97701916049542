/*
  Prepares a where clause out of the supplied filters, providing the clause text
  and an array of values to supply to the query engine
  e.g. {a: 1, b: 'two'}
  returns {whereText: 'WHERE "a"=$1 AND "b"=$2', whereValues: [1,'two']}

  `preparers` is an optional map of functions to make a custom statement for a given property.
  a preparer function should return an array of statements (can be more than one). %INDEX% will
  be replaced by the index of the corresponding value in the value collection. The default preparer
  is (key, value) => [{statement: `"${key}"=%INDEX%`, value}]

  `search` is an optional structure defining a free text search to perform including `text` to search
  for `columns[]` to look in

*/
const getWhereClause = ({filter, preparers, search, positionalArgumentOffset, includeWhereKeyword = true}) => {
  positionalArgumentOffset = positionalArgumentOffset || 0
  let whereText = ``
  const defaultPreparer = (key, value) => [{statement: `"${key}"=%INDEX%`, value}]
  let whereValues = []
  let whereStatements = []
  if (filter) {
    let fieldsToProcess = Object.keys(filter)
    for (let i in fieldsToProcess) {
      let field = fieldsToProcess[i]
      const clauses = (preparers && typeof preparers[field] === 'function' ? preparers[field] : defaultPreparer)(field, filter[field])
      whereStatements = whereStatements.concat(clauses.map(c => c.statement))
      whereValues = whereValues.concat(clauses.map(c => c.value)).filter(val => val)
    }
  }

  if (search) {
    whereStatements.push('(' + search.columns
      .map(c => `"${c}" ILIKE '%'||%INDEX%||'%'`)
      .join(' OR ') + ')')
    whereValues.push(search.text)
  }

  if (whereStatements && whereStatements.length) {
    whereText = (includeWhereKeyword ? 'WHERE ' : '') + whereStatements
      .filter(s => s)
      .map(f => f.replace(/%INDEX%/g, `$${whereStatements.indexOf(f) + 1 + positionalArgumentOffset}`))
      .join(` AND `)
  }
  return { whereText, whereValues }
}

const getOrderByClause = (ordering, columns) => {
  if (!ordering) {
    return ''
  }

  const orderings = Array.isArray(ordering) ? ordering : [ordering]

  const sortExpressions = orderings.map(order => {
    const sortExpression = order.startsWith('-') ? order.replace('-', '') : order
    const sortDirection = order.startsWith('-') ? 'DESC' : 'ASC'

    if (columns && columns.length && !columns.includes(sortExpression)) {
      throw new Error(`sortExpression ${sortExpression} is not an available sort column. Available columns: ${columns.join(', ')}`)
    }

    return `${sortExpression} ${sortDirection}`
  })

  const sortText = `ORDER BY ${sortExpressions.join(', ')}`
  return sortText
}

const mapInserts = (insert) => {
  const fields = Object.keys(insert[0])

  const flattened = insert.reduce(
    (acc, row) => acc.concat(fields.map(field => row[field])),
    []
  )
  const dollarSigns = insert
    .map((_, i) => {
      const rowDollarSigns = `${fields.map(
        (__, j) => `$${i * fields.length + j + 1}`
      )}`
      return `(${rowDollarSigns})`
    })
    .join(', ')

  return {
    fields,
    flattened,
    dollarSigns
  }
}

const getInsertProperties = (rows, columns = null, startIndex = 0) => {
  if (!Array.isArray(rows)) {
    throw new Error('Rows should be an array')
  }
  if (rows.length === 0) {
    return { columns: null, values: [], placeholders: null }
  }
  columns = columns || Object.keys(rows[0])
  // flatten all rows into single values array in columns order
  const values = [].concat(
    ...rows.map(r => columns.map(c => r[c]))
  )
  // create placeholders for each row, like ($1, $2, ...), ($1, $2, ...)
  const placeholders = rows.map((_, i) =>
    '(' + columns.map((_, j) => `$${startIndex + i * columns.length + j + 1}`).join(', ') + ')'
  ).join(', ')

  return { columns: columns.join(', '), values, placeholders }
}

const getPositionalArgs = (params, offset = 1) => params.map((_, key) => `$${key + offset}`).join(',')

/**
 * Generates an SQL string for filtering values based on the given array of columns and table name.
 *
 * @param {string[]} columns - An array of column names for which filtering values are needed.
 * @param {string} tableName - The name of the table from which to fetch data.
 * @param {string} preFilter - A pre-filter to be used as a basis i.e. we first apply it and then get the filtering values
 * @returns {string} JSON string with the corresponding filtering values indexed by column names e.g.:
 *
SELECT
    jsonb_build_object(
        'created_at', json_agg(DISTINCT data_shipment.created_at),
        'origin_id', json_agg(DISTINCT data_shipment.origin_id),
        ...
    ) AS "values"
FROM
    avocado.data_shipment
where
    data_shipment.created_at between '2020-01-01'
    AND '2020-04-01'
*/
const getColumnsUniqueValuesQuery = (table, columns, calculatedColumns = [], preFilter, preparers) => {
  const preFilterWhereClause = getWhereClause({filter: preFilter, preparers, includeWhereKeyword: false})

  const columnDefinitions = calculatedColumns
    .concat(columns.map(column => { return {name: column, expression: column} }))

  let sql = `
  SELECT
    jsonb_build_object(
      ${columnDefinitions.map(columnDefinition => `'${columnDefinition.name}', json_agg(DISTINCT ${columnDefinition.expression})`).join(',')}
    ) AS "values"
  FROM
    data_shipment
  WHERE ${preFilterWhereClause.whereText}
`
  return { query: sql, params: preFilterWhereClause.whereValues }
}

module.exports = {
  getWhereClause,
  getOrderByClause,
  mapInserts,
  getInsertProperties,
  getPositionalArgs,
  getColumnsUniqueValuesQuery
}
