module.exports = findAllSurveysForSnapshot

function findAllSurveysForSnapshot (state, snapshotId) {
  const idPrefix = snapshotId.replace(/:agent:.*$/, '')

  return state.dal.shipment.getByPrefix(state, idPrefix)

    .then(result => {
      const surveyDocs = result.rows
        .map(row => row.doc)
        .reverse() // sort descending

      // Return most recent doc per batch. Docs are already sorted descending so
      // we can filter out all but the first that matches snapshot/batch id prefix
      const knownBatches = {}

      return surveyDocs.filter((doc) => {
        const snapshotBatchId = doc._id.replace(/:survey:.*$/, '')
        if (knownBatches[snapshotBatchId]) {
          return false
        }

        knownBatches[snapshotBatchId] = 1
        return true
      })
    })
}
