import React, { createRef, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Arrow } from '../../icons'

import Button from '../Button'

const fileReader = (file, methodName, { onImported, onError }) => {
  const reader = new global.FileReader()
  reader.onerror = onError
  reader.onload = e => {
    const { result } = e.target
    onImported(result)
  }
  reader[methodName](file)
}

export const textFileReader = (file, events) => fileReader(file, 'readAsText', events)
export const arrayBufferFileReader = (file, events) => fileReader(file, 'readAsArrayBuffer', events)
export const binaryStringFileReader = (file, events) => fileReader(file, 'readAsBinaryString', events)
export const dataUrlFileReader = (file, events) => fileReader(file, 'readAsDataUrl', events)

class ImportButton extends PureComponent {
  static propTypes = {
    /**
     * An array of accepted mime types and file extensions to be passed to the
     * input[file]
     */
    accept: PropTypes.array,

    /**
     * The button text to display
     */
    buttonText: PropTypes.string,

    /**
     * render loading state
     */
    loading: PropTypes.bool,

    /**
     * The File reader function
     */
    reader: PropTypes.func,

    /**
     * Error handler (optional)
     */
    onError: PropTypes.func,

    /**
     * Import handler (Promise/A+)
     */
    onImport: PropTypes.func,

    /**
     * Optional on imported handler
     */
    onImported: PropTypes.func
  }

  static defaultProps = {
    loading: false,
    accept: [],
    buttonText: 'Import',
    onImported: () => null,
    onImport: () => null,
    onError: (error) => console.error(error),
    reader: textFileReader
  }

  importButtonInput = createRef()

  handleChange = () => {
    const {
      accept,
      onError,
      onImport,
      reader,
      onImported
    } = this.props

    const [ importFile ] = this.importButtonInput.current.files

    if (!accept.includes(importFile.type)) {
      return onError(new Error(`Filetype '${importFile.type}' not allowed`))
    }

    onImport(importFile)

    try {
      reader(importFile, { onImported, onError })
    } catch (error) {
      onError(error)
    }
  }

  render () {
    const {
      loading,
      accept,
      buttonText,
      disabled
    } = this.props

    return (
      <Button
        colorVariant='brand'
        loading={loading}
        fill='full'
        icon={<Arrow direction='up' />}
        disabled={disabled}
        onClick={() => {
          // this is to reset the input file value so users can
          // "re-upload" the same file and we still fire handleChange
          this.importButtonInput.current.value = ''
          this.importButtonInput.current.dispatchEvent(new global.MouseEvent('click'))
        }}
      >
        {/* This is a hidden input[type=file] that we assign a ref to and */}
        {/* listen for changes on in order to trigger an import */}
        <input
          style={{ display: 'none' }}
          type='file'
          ref={this.importButtonInput}
          accept={accept.join(',')}
          onChange={this.handleChange}
        />
        {buttonText}
      </Button>
    )
  }
}

export default ImportButton
