const { parse, isValid } = require('date-fns')

const narrationPattern = new RegExp('^REM:R-([0-9]+)\\/([A-Za-z0-9 _]+):(.+)$')

// accountants csv file that they not want to transform contain value_date, credit, narrative.
// in our case we are storing it as trans_date, amount, narration
const transactionsTransformMapper = {
  'value_date': 'trans_date',
  'amount': 'credit',
  'narration': 'narrative'
}

const customDateFormat = (date) => {
  const split = date.split('-')
  return parse(`20${split[2]}-${split[1]}-${split[0]}`)
}

const transform = transactions => {
  const transformed = transactions.reduce((acc, val) => {
    const valueDate = val[transactionsTransformMapper['value_date']]
    const amount = val[transactionsTransformMapper['amount']]
    const narration = val[transactionsTransformMapper['narration']]
    const data = {
      value_date: parse(valueDate),
      amount: parseFloat(amount.replace(/,/g, '')),
      narration
    }
    if (!isValid(data.value_date)) {
      data.value_date = customDateFormat(valueDate)
    }
    if (isValid(data.value_date) && !isNaN(data.amount)) {
      acc.push(data)
    }
    return acc
  }, [])
  if (transformed.length !== transactions.length) {
    const error = new Error('Invalid format')
    error.status = 400
    throw error
  }
  return transformed
}

/**
 * Filter out transactions and return two arrays
 * matchable narration is valid and we can continue matching
 * notMatchable narration is not valid for continue matching
 * @param transactions
 */
const filterTransactions = transactions => transactions.reduce((acc, val) => {
  const validNarration = narrationPattern.exec(val.narration)
  if (validNarration) {
    val.transactionRef = validNarration[1]
    acc.matchable.push(val)
  } else {
    acc.notMatchable.push(val)
  }
  return acc
}, {
  matchable: [],
  notMatchable: []
})

const isPaymentPlan = transactionRef => transactionRef.substring(0, 2) === 'P-'

exports.transform = transform
exports.isPaymentPlan = isPaymentPlan
exports.filterTransactions = filterTransactions
