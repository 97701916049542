module.exports = {
  getStateToZoneMap,
  listStatesAndLGAs,
  descendantsByTier,
  getGeoLocationId
}

const levels = require('../utils/location-levels')

// Returns: { 'fct': 'north-central' }
function getStateToZoneMap (locations) {
  return locations.reduce((memo, facility) => {
    const { zone, state } = facility.location
    memo[state] = memo[state] || zone
    return memo
  }, {})
}

function listStatesAndLGAs (locations) {
  const lgas = [...new Set(locations.map(facility => facility.location.lga))]
  const states = [...new Set(locations.map(facility => facility.location.state))]
  return {
    lgas,
    states
  }
}

function descendantsByTier (descendants, parent) {
  const parentIndex = levels.indexOf(parent.level)
  const descendantLevels = levels.slice(parentIndex + 1)

  return descendantLevels.reduce((tiers, level) => {
    const tier = descendants
      .filter(location => location.level === level)
      .map(location => location._id)
    tiers.push(tier)
    return tiers
  }, [])
}

/** Get the geolocation part of the location id.
 * Location ids may contain a `name` field. This function
 * will return the geo location part of the id without the name.
 */
function getGeoLocationId (locationId) {
  const parts = locationId.split(':')
  const nameIndex = parts.indexOf('name')
  let locPart
  if (nameIndex > -1) {
    locPart = parts.slice(0, nameIndex).join(':')
  } else {
    locPart = locationId
  }
  if (locPart === 'national') {
    return 'country:ng'
  } else {
    return 'country:ng:' + locPart
  }
}
