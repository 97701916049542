import React from 'react'

const AdminLoginBanner = ({ adminName, userName }) => {
  // this will show if user logged in as admin
  return (
    <div className='admin-login-banner'>
      <p className='admin-login-banner__text'>
        <div>You are logged in with <strong>{adminName}</strong> admin credentials as user <strong>{userName}</strong></div>
      </p>
    </div>
  )
}

export default AdminLoginBanner
