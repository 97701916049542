const PGAdapter = require('../../common/pg-adapter')
const isPGUsageErrorCode = require('../../common/isPGUsageErrorCode')

const columns = [
  'id',
  'order_group_id',
  'program_id',
  'start_date',
  'end_date',
  'created_at',
  'updated_at',
  'status',
  'delivery_date'
]

class SupplyPlanPGAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    super(pgConnection, 'avocado.data_supply_plan', username, columns, 'order_group_id', {}, logger, {})
  }

  // Overwrite `PGAdapter::getOne` to include per-product data in result
  async getOne (id) {
    const query = `
    SELECT sp.*,
     (SELECT json_agg(
         json_build_object(
             'product_sku', l.product_sku,
             'scale_factor', l.scale_factor
             )
             ORDER BY l.product_sku
        ) as scale_factors
     )
     FROM avocado.data_supply_plan sp
     LEFT JOIN data_supply_plan_line l
                ON l.supply_plan_id = sp.id
    WHERE sp.${this.idColumn} = $1
    group by sp.id
    `

    let result
    try {
      result = await this.pgConnection.query(query, [id])
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
    if (result.rows.length === 0) {
      const err = new Error('Row not found')
      err.status = 404
      throw err
    }

    return result.rows[0]
  }

  async getList ({ startDate, endDate, program }) {
    const queryParams = []
    let query = `
      SELECT 
          json_build_object(
              'id', sp.id,
              'order_group_id', sp.order_group_id,
              'program_id', sp.program_id,
              'delivery_date', sp.delivery_date,
              'start_date', sp.start_date,
              'end_date', sp.end_date,
              'created_at', sp.created_at,
              'updated_at', sp.updated_at,
              'status', sp.status,
              'product_count', COUNT(l.id)
              ) as supply_plan
      FROM avocado.data_supply_plan sp
      LEFT JOIN data_supply_plan_line l
        ON l.supply_plan_id = sp.id
      `
    if (startDate && endDate && program) {
      query += `
                  WHERE start_date>=$1
                    AND end_date<=$2
                    AND program_id=$3
                `
      queryParams.push(startDate, endDate, program)
    }

    query += `
        GROUP BY sp.id, sp.${this.idColumn}
    `

    let result
    try {
      result = await this.pgConnection.query(query, queryParams)
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
    return result.rows.map(r => r.supply_plan)
  }

  async upsert () {
    throw new Error('Upsert not implemented')
  }

  async delete () {
    throw new Error('Delete not implemented')
  }

  async createProductLine (supplyPlanId, productData) {
    const query = `
    INSERT INTO avocado.data_supply_plan_line (supply_plan_id, product_sku, scale_factor)
    VALUES ($1, $2, $3)
    ON CONFLICT (supply_plan_id, product_sku)
    DO UPDATE SET scale_factor = EXCLUDED.scale_factor
    RETURNING *;
    `
    const values = [supplyPlanId, productData.product_sku.toLowerCase(), productData.scale_factor]
    return this.pgConnection.query(query, values)
  }

  async createProductLines (supplyPlanId, productsData) {
    return Promise.all(productsData.map(product => this.createProductLine(supplyPlanId, product)))
  }
}

module.exports = {
  // Format,
  SupplyPlanPGAdapter
}
