import React from 'react'
import PropTypes from 'prop-types'

import BackButton from '../../../../components/BackButton'

const PageHeaderBackButton = ({
  children,
  ...otherProps
}) => (
  <div className='vs-page-header__back'>
    <BackButton
      {...otherProps}
    >
      {children}
    </BackButton>
  </div>
)

PageHeaderBackButton.propTypes = {
  /**
  * Content for the back button. Can be text or any tag supported inside a button
  */
  children: PropTypes.node.isRequired
}

PageHeaderBackButton.displayName = 'Page.ComposableHeader.BackButton'

export default PageHeaderBackButton
