const transformNewInvoiceToNotificationData = require('./transform/new-invoice')
const transformConvertedInvoiceToNotificationData = require('./transform/invoice-converted-pp')
const transfromTransactionToDueNotificationData = require('./transform/transaction-is-due-tomorrow')

class TransactionsNotificationsAdapter {
  constructor (props) {
    /*
     * {
     *   notificationApi,
     *   locationApi,
     *   notificationsFcmApi,
     *   logger
     *  }
     */
    Object.assign(this, props)
  }

  async sendNotification (notificationData) {
    await this.notificationApi.create({
      locationId: notificationData.locationId,
      notificationText: notificationData.notification.body,
      action: notificationData.data.url,
      code: notificationData.data.label,
      medium: 'push',
      event: notificationData.event,
      notificationData: notificationData
    })
    try {
      await this.notificationsFcmApi.sendMessagesWithLookup(notificationData)
    } catch (_) {}
  }

  prepareTransactionsForSend (transactions, type) {
    const locations = {}
    return Promise.all(
      transactions.map(async (transaction) => {
        // store location data in object in case if there multiple invoice for same location
        let location = locations[transaction.location_id]
        if (!location) {
          location = await this.locationApi.pgAdapter.getOne(transaction.location_id, { whereCondition: 'uuid' })
          locations[location.uuid] = location
        }
        if (location.membership_type === 'basic') {
          return
        }
        let notificationData = null
        switch (type) {
          case 'newInvoice':
            notificationData = transformNewInvoiceToNotificationData(transaction, location)
            break
          case 'convertedPP':
            notificationData = transformConvertedInvoiceToNotificationData(transaction, location)
            break
          case 'transactionIsDue':
            notificationData = transfromTransactionToDueNotificationData(transaction, location)
            break
        }
        if (!notificationData) {
          this.logger.warn(`invalid notification type:${type}`, location.uuid)
          return
        }
        await this.sendNotification(notificationData)
        return notificationData
      })
    )
  }

  newInvoiceNotification (transactions) {
    return this.prepareTransactionsForSend(transactions, 'newInvoice')
  }

  invoiceConvtertedPPNotification (transactions) {
    return this.prepareTransactionsForSend(transactions, 'convertedPP')
  }

  transactionIsDueTomorrow (transactions) {
    return this.prepareTransactionsForSend(transactions, 'transactionIsDue')
  }
}

module.exports = TransactionsNotificationsAdapter
