import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Prompt = ({
  title,
  body,
  footer,
  className
}) => (
  <div
    className={
      classnames(
        'vs-prompt',
        className
      )
    }
  >
    <div className='vs-prompt__content'>
      <div className='vs-prompt__header'>{title}</div>
      <div className='vs-prompt__body'>{body}</div>
      <div className='vs-prompt__footer'>{footer}</div>
    </div>
  </div>
)

Prompt.propTypes = {
  /**
   * Title of the Prompt
   */
  title: PropTypes.node,
  /**
   * The content of the body of the Prompt
   */
  body: PropTypes.node,
  /**
   * The footer of the body of the Prompt
   */
  footer: PropTypes.node
}

Prompt.defaultProps = {

}

export default Prompt
