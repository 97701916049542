import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { Text } from '@fielded/shared-ui'
import { CheckmarkCircle } from '@fielded/shared-ui/src/icons'
import { SHIPMENT_STATUS, SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS } from '@fielded/fs-api/lib/shipment/constants'

import { formatDate } from '../../../../../../van-shared/utils/utils'

const LMDDeliveryDetailTransferInfo = ({
  delivery
}) => {
  const VISIBLE_STATUS_LIST = [
    SHIPMENT_STATUS.NEW,
    SHIPMENT_STATUS.PACKED,
    SHIPMENT_STATUS.SENT,
    SHIPMENT_STATUS.ARRIVED,
    SHIPMENT_STATUS.RECEIVED
  ]

  const baseUrl = '/shipments/last-mile-deliveries'

  return (
    <div className='lmd-detail__transfer-wrapper'>
      {VISIBLE_STATUS_LIST.map((status, index) => {
        const isCompleted = delivery.snapshotDates[status]
        const previousStatus = VISIBLE_STATUS_LIST[index - 1]
        const isInProgress = !delivery.snapshotDates[status] && delivery.snapshotDates[previousStatus]
        const showPackageNote = (isInProgress || isCompleted) && status === SHIPMENT_STATUS.SENT
        const showDeliveryNote = isCompleted && status === SHIPMENT_STATUS.RECEIVED
        return (
          <Fragment key={`${status}-${index}`}>
            {!!VISIBLE_STATUS_LIST[index - 1] && (
              <div className={classnames(
                'lmd-detail__transfer-separator',
                {'lmd-detail__transfer-separator--completed': isCompleted},
                {'lmd-detail__transfer-separator--in-progress': isInProgress}
              )} />
            )}
            <div className='lmd-detail__transfer-item'>
              <div className={classnames(
                'lmd-detail__transfer-item-status',
                {'lmd-detail__transfer-item-status--completed': isCompleted},
                {'lmd-detail__transfer-item-status--in-progress': isInProgress}
              )}>
                <span className='lmd-detail__transfer-item-status-icon'>
                  {isCompleted && <CheckmarkCircle />}
                </span>{SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS[status]}
              </div>
              <div className='lmd-detail__transfer-item-info'>
                {isCompleted && (
                  <Text size='tiny'> {formatDate(delivery.snapshotDates[status], 'long')}</Text>
                )}
                {showPackageNote && (
                  <Link
                    to={`${baseUrl}/package-note/${delivery.snapshotId}`}
                    className='vs-u-link'
                  >
                    Package note
                  </Link>
                )}
                {showDeliveryNote && (
                  <Link
                    to={`${baseUrl}/delivery-statement/${delivery.snapshotId}`}
                    className='vs-u-link'
                  >
                    Delivery note
                  </Link>
                )}
              </div>
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

export default LMDDeliveryDetailTransferInfo
