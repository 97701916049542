const CURRENCY_BY_COUNTRY = {
  ng: 'NGN',
  ke: 'KES'
}

const PAYMENT_METHOD_TABLE_NAME = 'avocado.data_paymentmethod'
const PAYMENT_METHOD_COLUMNS = [
  'created_at',
  'updated_at',
  'id',
  'comment',
  'payment_provider',
  'created_by',
  'updated_by',
  'locations',
  'data'
]

const PAYMENT_METHOD_REQUEST_TABLE_NAME = 'avocado.data_paymentproviderrequest'
const PAYMENT_METHOD_REQUEST_COLUMNS = [
  'created_at',
  'updated_at',
  'id',
  'payment_provider',
  'type',
  'request_data',
  'response_data',
  'created_by',
  'updated_by',
  'payment_debit_id',
  'payment_method_id'
]

const LOCATION_TABLE_NAME = 'avocado.data_location'

module.exports = {
  CURRENCY_BY_COUNTRY,
  PAYMENT_METHOD_TABLE_NAME,
  PAYMENT_METHOD_COLUMNS,
  PAYMENT_METHOD_REQUEST_COLUMNS,
  LOCATION_TABLE_NAME,
  PAYMENT_METHOD_REQUEST_TABLE_NAME
}
