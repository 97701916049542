import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Bullet from '../Bullet'
import { Chevron } from '../../icons'

const defaultLinkRenderer = ({ to, className, children, ...otherProps }) => (
  <a href={to} className={className} {...otherProps}>
    {children}
  </a>
)

const itemContent = (icon, title) => {
  return (
    <Bullet icon={icon}>
      { title }
    </Bullet>
  )
}

const Breadcrumb = ({
  items = [],
  linkRenderer
}) => {
  return items.length && (
    <div className='vs-breadcrumb'>
      {items.map((item, index) => {
        const {
          title,
          path,
          icon,
          className
        } = item
        const isLastItem = index === items.length - 1

        // Allow individual items to override the linkRenderer.
        // We don't want to use a sub-app's internal Link for e.g. Home,
        // since that will have to point back to V-A-S.
        const itemLinkRenderer = item.linkRenderer
          ? item.linkRenderer
          : linkRenderer

        return (
          <Fragment
            key={path + title}
          >
            <span
              className={classnames(
                'vs-breadcrumb__item',
                className
              )}
            >
              {path
                ? itemLinkRenderer(
                  {
                    to: path,
                    className: 'vs-breadcrumb__link',
                    children: itemContent(icon, title)
                  }
                ) : (
                  <span className='vs-breadcrumb__label'>
                    {itemContent(icon, title)}
                  </span>
                )
              }
            </span>
            {!isLastItem && (
              <span className='vs-breadcrumb__separator'><Chevron direction='right' /></span>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

Breadcrumb.propTypes = {
  /**
   * The breadcrumb elements
   */
  items: PropTypes.array.isRequired,

  /**
   * Pass to make breadcrumb links use a custom link renderer, .e.g. Link.
   * Must have `to`, `className`, `children`.
   */
  linkRenderer: PropTypes.func
}

Breadcrumb.defaultProps = {
  linkRenderer: defaultLinkRenderer
}

export default Breadcrumb
