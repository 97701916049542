/** This module contains functions to merge inputted and aggregated products
 *
 * Allocations can contain user inputted product parameters and aggregated
 * products parameters. This will merge both product forecast and supply plan
 * definitions prefering either the user inputted product parameters or the
 * aggregated parameters.
 */

const {methods} = require('../../config')

/** Merge specified props of two objects
 *
 * The props array is a list of tuples of property name and a
 * boolean indicating whether to take the value from the first
 * or second object.
 */
const mergePreferedProps = (a, b, props) => {
  const result = {}
  for (const [prop, fromA] of props) {
    const prefered = fromA ? a : b
    const alternative = fromA ? b : a
    let value = prefered && prefered[prop]
    // If the prefered property is either null or undefined
    // we use the value from the alternative object.
    if (value == null) {
      value = alternative && alternative[prop]
    }
    result[prop] = value
  }
  return result
}

const mergeForecasts = (inputted, aggregated) => {
  let method = inputted && inputted.method
  if (method == null) {
    method = aggregated && aggregated.method
  }
  switch (method) {
    case methods.TP:
      // While merging we prefer the targetPopulation of the aggregated
      // forecast and everything else from the user inputted forecast,
      // if those values exist.
      return mergePreferedProps(inputted, aggregated, [
        ['method', true],
        ['targetPopulation', false],
        ['wastageFactor', true],
        ['coverageFactor', true],
        ['dosesInSchedule', true],
        ['adjustmentFactor', true]
      ])
    default:
      return Object.assign({}, aggregated, inputted)
  }
}

const mergeSupplyPlans = (inputted, aggregated) => {
  return Object.assign({}, aggregated, inputted)
}

exports.mergeProducts = (inputted, aggregated) => {
  if (!inputted && !aggregated) {
    return {}
  }
  if (!inputted) {
    return aggregated
  }
  if (!aggregated) {
    return inputted
  }
  const merged = {}
  for (const productId of Object.keys(inputted)) {
    const a = inputted[productId] || {}
    const b = aggregated[productId] || {}
    const c = {}
    if (a.forecast || b.forecast) {
      c.forecast = mergeForecasts(a.forecast, b.forecast)
    }
    if (a.supplyPlan || b.supplyPlan) {
      c.supplyPlan = mergeSupplyPlans(a.supplyPlan, b.supplyPlan)
    }
    merged[productId] = c
  }
  return merged
}
