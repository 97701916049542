const get = require('lodash/get')
const set = require('lodash/set')
const cloneDeep = require('lodash/cloneDeep')

const { applyCalculatedField } = require('../../../tools')

function validate (stockCount, ledger) {
  let hasIssues = false
  const issues = {
    opening: [],
    openingPartner: [],
    sold: []
  }
  for (const productId of Object.keys(stockCount.stock)) {
    const stockCountProduct = stockCount.stock[productId]
    const ledgerProduct = get(ledger, `stock.${productId}`)
    if (!ledgerProduct) {
      continue
    }
    const opening = get(stockCountProduct, 'fields.field:standard-opening-balance.amount')
    const openingPartner = get(stockCountProduct, 'fields.field:opening-partner-balance.amount')
    const count = get(stockCountProduct, 'fields.field:standard-physical-count.amount')
    const sold = get(stockCountProduct, 'fields.field:standard-consumed.amount')
    // The count from the ledger is what the opening of the stock count should be
    const ledgerCount = get(ledgerProduct, 'fields.field:standard-physical-count.amount')
    const ledgerPartner = get(ledgerProduct, 'fields.field:partner-balance.amount')
    if (opening != null && ledgerCount != null && opening !== ledgerCount) {
      hasIssues = true
      issues.opening.push(productId)
    }
    if (openingPartner != null && ledgerPartner != null && openingPartner !== ledgerPartner) {
      hasIssues = true
      issues.openingPartner.push(productId)
    }
    if (opening != null && count != null && sold != null && opening - count !== sold) {
      hasIssues = true
      issues.sold.push(productId)
    }
  }
  return hasIssues ? issues : null
}

function fix (stockCount, ledger, fields) {
  const fixedStockCount = cloneDeep(stockCount)
  for (const productId of Object.keys(ledger.stock)) {
    const stockCountProduct = get(fixedStockCount, `stock.${productId}`)
    if (!stockCountProduct) {
      continue
    }
    const fixedOpening = get(ledger, `stock.${productId}.fields.field:standard-physical-count.amount`)
    const fixedPartner = get(ledger, `stock.${productId}.fields.field:partner-balance.amount`)
    set(stockCountProduct, 'fields.field:standard-opening-balance.amount', fixedOpening)
    set(stockCountProduct, 'fields.field:opening-partner-balance.amount', fixedPartner)
  }
  fixedStockCount.stock = applyCalculatedField(
    fixedStockCount.stock,
    fields,
    { tracksPartnerBalances: true }
  )
  fixedStockCount.updatedFromLedger = {
    _id: ledger._id,
    _rev: ledger._rev
  }
  fixedStockCount.documents = ledger.documents
  return fixedStockCount
}

module.exports = {
  validate,
  fix
}
