import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import flowRight from 'lodash/flowRight'

import { BackButton, Loading } from '@fielded/shared-ui'
import withConfig from '../../../van-shared/hoc/withConfig'
import withShipment from './../common/WithShipmentWrapper'
import withMasterData from './../common/withMasterData'
import { withApi } from '../../../common/ApiProvider'

import getTemplate from './get-template'
import { shipmentForPrinting } from './selectors'
import { getShipmentWithSurveys } from './utils'
import { updateCurrentMasterData } from '../../../van-shared/utils/utils'

class PrintContainer extends Component {
  constructor (props) {
    super(props)
    this.state = { shipmentWithSurveys: null, allProductsbyId: null }
  }

  async componentDidMount () {
    const {
      api,
      shipment,
      masterData,
      productsById: originalProductsById
    } = this.props

    const formattedShipment = await shipmentForPrinting({masterData}, shipment, originalProductsById, api)

    const surveyDocs = await api.survey.findAllForShipment(formattedShipment.id)
    const shipmentWithSurveys = getShipmentWithSurveys(formattedShipment, surveyDocs)
    const { allProductsbyId } = formattedShipment
    this.setState({ shipmentWithSurveys, allProductsbyId })
  }

  render () {
    const { history, config, match } = this.props
    const { shipmentWithSurveys, allProductsbyId } = this.state

    if (!shipmentWithSurveys) return <Loading />

    const showAllStatements = !!match.params.allStatements
    const printTemplate = get(history, 'location.state.note')

    const PrintComponent = getTemplate(shipmentWithSurveys, config, printTemplate)

    return (
      <div className='print page'>
        <header className='header--back'>
          <BackButton history={history}>go back</BackButton>
        </header>
        <PrintComponent
          shipment={shipmentWithSurveys}
          productsById={allProductsbyId}
          config={config}
          showAllStatements={showAllStatements}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, { shipment }) => {
  const newState = updateCurrentMasterData(state, 'masterDataShipments')
  const productsById = get(newState, 'masterData.products.byId', {})

  return {
    productsById,
    masterData: newState.masterData
  }
}

const withHOCs = flowRight(
  withApi,
  withConfig,
  withMasterData,
  withShipment,
  connect(mapStateToProps)
)

export default withHOCs(PrintContainer)
