const docToReport = require('./doc-to-report')

module.exports = async (state, {
  reportId,
  service = null,
  period = null,
  entityOptions = null
}) => {
  let doc
  try {
    doc = await state.dal.report.read(state, reportId)
  } catch (e) {
    if (e.name === 'not_found') {
      return null
    }
    throw e
  }
  return docToReport(state, {doc, service, period, entityOptions})
}
