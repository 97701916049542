/** This module contains functions to aggregate allocation data
 *
 * The main function is `aggregateAndFilter`. The `aggregate` part will aggregate allocation
 * data for facilities based on their children in the supply graph. The `filter` part will
 * check if the aggregated data is the same as existing data and only yield allocations for
 * facilities where the data differs from the latest existing allocation data.
 *
 * The aggregation is per product. When aggregating allocation data for a facility, we
 * collect the allocation data of the childs of the facility. We then accumulate forecast
 * and supply plan data per product from the children and calculate the average for each
 * product. The aggregation is run on the back of the supply graph. By traversing the supply
 * graph from the bottom up, we can aggregate each facilities child facilities, starting from
 * the leaf facilities.
 */

const {methods} = require('./config')
const {
  createAllocation,
  combine,
  removeExisting
} = require('./allocation')

/** Aggregate allocation data and filter out generate data that already exists
 *
 * This function will accept new allocations and create up to date aggregated allocations
 * data. It will also filter out any aggregated data that is the same as the existing data.
 * In order to run the aggregation, we will also pass in the existing allocations. We don't
 * know if the new allocations are a complete set and will union the new with the existing
 * allocations and then run the aggregation pass. Any allocation data from the aggregation
 * that already exists will be filtered out.
 */
const aggregateAndFilter = async (graph, activeAllocations, newAllocations, effectiveDate, userName, mergeProducts) => {
  const combined = combine(activeAllocations, newAllocations, mergeProducts)
  const aggregated = await aggregate(graph, combined, effectiveDate, userName)
  const actualNewOnes = removeExisting(aggregated, activeAllocations)
  return actualNewOnes
}

/** Aggregate the allocations using the supply graph
 *
 * The supply graph contains all facilities. We traverse the graph from bottom to top
 * and aggregate each facilities/nodes allocation by looking at its children.
 * While doing so, there will be facilties with existing allocation data and facilities
 * without. We will aggregate allocation data for any facilities with child facilities.
 *
 * Allocation documents can have two different product dictionaries. One for allocation
 * data that was specified manually or by some outside process, called `products` and
 * products allocation data that was aggregated by this function, called
 * `aggregatedProducts`.
 */
const aggregate = async (graph, allocations, effectiveDate, userName) => {
  const allocationsById = allocations.reduce((map, a) => map.set(a.facilityId, a), new Map())
  let usedIds = new Set()

  const aggregatedAllocations = []

  // We get the descendant suppliees here and rely on the fact that the order
  // of the returned list is depth first, which means, that the locations at the
  // bottom of the supply graph come first. Because of this order, iterating over
  // the list of descendants means we are traversing the graph from bottom up.
  // To keep this order we add the top-most location, 'national', to the end
  // of the location list.
  const national = await graph.getLocation('location:national')

  // if no national don't aggregate
  if (!national) {
    return allocations.map(allocation =>
      createAllocation(Object.assign({}, allocation, {
        date: effectiveDate,
        createdBy: userName
      }))
    )
  }

  const locations = await graph.getDescendantSuppliees('location:national')
  locations.push(national)

  for (const location of locations) {
    const locationId = location._id
    let allocation = allocationsById.get(locationId)

    const suppliees = await graph.getSuppliees(location._id, 'supplies')
    const childIds = suppliees.map(l => l._id)
    if (childIds.length === 0) {
      if (allocation) {
        aggregatedAllocations.push(
          createAllocation(Object.assign({}, allocation, {
            date: effectiveDate,
            createdBy: userName
          }))
        )
      }
      continue
    }
    const unusedChildIds = childIds.filter(id => !usedIds.has(id))
    const childAllocations = unusedChildIds
      .map(id => allocationsById.get(id))
      .filter(a => !!a)

    usedIds = new Set([...usedIds, ...unusedChildIds])

    const aggregatedProducts = createAggregatedProducts(childAllocations)

    const facilityId = locationId
    const newAllocation = createAllocation({
      facilityId,
      date: effectiveDate,
      aggregatedProducts,
      createdBy: userName
    })
    if (allocation && allocation.products) {
      // Preserve products forecasts from existing allocation
      newAllocation.products = allocation.products
    }
    allocationsById.set(locationId, newAllocation)
    aggregatedAllocations.push(newAllocation)
  }

  return aggregatedAllocations
}

/** Get the allocations products
 *
 * Allocations can have a aggregated products dictionary and non aggregated
 * products dictionary. This function will return one of those and will
 * prefer the aggregated version.
 */
const getAllocationsProducts = (allocation) => {
  const aggProds = allocation.aggregatedProducts
  return aggProds && Object.keys(aggProds).length > 0
    ? aggProds : allocation.products
}

/** Aggreate the allocation data for a facility
 *
 * Takes a list of allocations of the facilities children. It will create
 * a single products dictionary with aggregated data for each product.
 */
const createAggregatedProducts = (allocations) => {
  const groupedProducts = allocations.reduce((acc, allocation) => {
    const products = getAllocationsProducts(allocation)
    Object.keys(products).forEach(productId => {
      acc[productId] = (acc[productId] || []).concat(products[productId])
    })
    return acc
  }, {})
  const products = Object.keys(groupedProducts).reduce((acc, productId) => {
    acc[productId] = createAggregatedProduct(groupedProducts[productId])
    return acc
  }, {})
  return products
}

/** Create a product allocation by aggregating a list of product allocations.
 *
 * The products need to be of same type (i.e. same aggregation method).
 * The aggregation will only take the products into account whose forecasts
 * use the most common forecast method in the passed list.
 */
const createAggregatedProduct = (products) => {
  const method = getPrevailingForecastMethod(products)
  if (!method) {
    console.log('no forecast method found for products', JSON.stringify(products))
  }

  const productsUsingPrevailingMethod = products.filter(product =>
    product.forecast && product.forecast.method === method
  )

  return ({
    forecast: createAggregateForecast(productsUsingPrevailingMethod, method),
    supplyPlan: createAggregateSupplyPlan(productsUsingPrevailingMethod)
  })
}

/** Get the most common forecast method of a list of products
 *
 * We can currently only combine products in an aggregation that use the
 * same forecast method. This will look at a list of products forecast and
 * find the forecast method that is used the most.
 */
const getPrevailingForecastMethod = (products) => {
  const countedMethods = products.reduce((acc, p) => {
    const method = p.forecast.method
    const bucketIndex = acc.findIndex(([m]) => m === method)
    if (bucketIndex === -1) {
      return acc.concat([[method, 1]])
    }
    const [, count] = acc[bucketIndex]
    acc[bucketIndex] = [method, count + 1]
    return acc
  }, [])
  const sorted = countedMethods.sort(([, c1], [, c2]) => c1 > c2 ? -1 : 1)
  const [method] = sorted[0]
  return method
}

/** Create an aggregate forecast from products allocation data.
 *
 * The products need to be of same type and the aggregation will
 * only take the products into account whose forecasts use the most
 * common forecast method in the passed list.
 */
const createAggregateForecast = (products, method) => {
  const aggregateForecasts = forecastAggregators[method]
  if (!aggregateForecasts) {
    throw new Error('Unknown forecast method: ' + method)
  }
  return aggregateForecasts(products)
}

/** Functions to create aggregated forecasts from products allocation data
 */
const forecastAggregators = {
  [methods.TP]: (products) => {
    const totalPop = products.reduce((sum, p) => sum + p.forecast.targetPopulation, 0)
    return products.reduce((acc, {forecast}) => {
      const pop = forecast.targetPopulation
      let weight = 0
      if (totalPop === 0) {
        weight = 1 / products.length
      } else {
        weight = pop / totalPop
      }
      acc.coverageFactor += forecast.coverageFactor * weight
      acc.wastageFactor += forecast.wastageFactor * weight
      acc.dosesInSchedule += forecast.dosesInSchedule * weight
      acc.adjustmentFactor += forecast.adjustmentFactor * weight
      return acc
    }, {
      method: methods.TP,
      targetPopulation: totalPop,
      coverageFactor: 0,
      wastageFactor: 0,
      dosesInSchedule: 0,
      adjustmentFactor: 0
    })
  },

  [methods.BUNDLED]: (products) => {
    const weight = 1 / products.length
    const factor = products.reduce((acc, {forecast}) => acc + forecast.factor * weight, 0)
    const dependentProducts = Object.keys(
      products.reduce((acc, {forecast}) => {
        for (const p of forecast.dependentProducts) {
          acc[p] = true
        }
        return acc
      }, {})
    )
    return {
      method: methods.BUNDLED,
      dependentProducts,
      factor
    }
  },

  [methods.FLAT]: (products) => {
    const weight = 1 / products.length
    return products.reduce((acc, {forecast}) => {
      acc.daysToAverage += forecast.daysToAverage * weight
      return acc
    }, {
      method: methods.FLAT,
      daysToAverage: 0
    })
  },

  [methods.CONSUMPTION]: (products) => {
    // TODO: This 'weight' line was in the original
    // but it makes no sense?
    // Shoule we not just add up all the consumption from below?
    // const weight = 1 / products.length
    return products.reduce((acc, {forecast}) => {
      acc.annualAllocation += forecast.annualAllocation // * weight
      return acc
    }, {
      methods: methods.CONSUMPTION,
      annualAllocation: 0
    })
  }
}

/** Create aggregated supply plan from products allocation data
 */
const createAggregateSupplyPlan = (products) => {
  products = products.filter(p => p.supplyPlan)
  if (products.length === 0) {
    return
  }
  const weight = 1 / products.length
  return products.reduce((acc, {supplyPlan}) => {
    acc.leadTimeDays += supplyPlan.leadTimeDays * weight
    acc.bufferDays += supplyPlan.bufferDays * weight
    acc.supplyPeriodDays += supplyPlan.supplyPeriodDays * weight
    return acc
  }, {
    leadTimeDays: 0,
    bufferDays: 0,
    supplyPeriodDays: 0
  })
}

module.exports = {
  aggregate,
  aggregateAndFilter,
  getAllocationsProducts
}
