import React from 'react'
import { Banner } from '@fielded/shared-ui'

const SyncUpdateBanner = ({title}) => {
  return (
    <Banner
      title={title}
      inline
      className='vs-u-margin-bottom-double'
    />
  )
}

export default SyncUpdateBanner
