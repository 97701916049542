const { EntityApi } = require('../common')

const toForecastPerLocation = require('./tools/read/to-forecast-per-location')
const forecastToLevels = require('./tools/read/forecast-to-levels')
const allocConfigToForecast = require('./tools/read/alloc-config-to-forecast')
const locationToForecast = require('./tools/read/location-to-forecast')
const psmReportToForecast = require('./tools/read/psm-report-to-forecast')

class ForecastDataAdapter {
  constructor (restAdapter) {
    this.restAdapter = restAdapter
  }

  async get (locationId, effectiveOnDate = new Date().toISOString()) {
    const queryParams = {
      effective_on_date: new Date(effectiveOnDate).toISOString(),
      limit: Number.MAX_SAFE_INTEGER,
      location: locationId
    }
    const forecasts = (await this.restAdapter.get('forecast', queryParams)).results
    return forecasts
      .reduce((acc, row) => {
        const {
          allocation,
          allocation_type: allocationType,
          created_at: createdAt,
          daily_demand: dailyDemand,
          delivery_day: deliveryDay,
          product: productId,
          safety_stock: safetyStock,
          supply_period_days: supplyPeriodDays,
          zero_stock_allocation: zeroStockAllocation
        } = row
        if (acc.products[productId]) {
          console.error(`Warning! multiple forecasts found for product id ${productId}`)
        }
        acc.products[productId] = {
          allocation,
          allocationType,
          createdAt,
          dailyDemand,
          productId,
          safetyStock,
          supplyPeriodDays,
          zeroStockAllocation
        }
        acc.deliveryDay = deliveryDay
        return acc
      }, {locationId, products: {}})
  }

  // ShelfLife only for now.
  async list ({ effectiveOnDate = new Date().toISOString(), locationIds } = {}) {
    const queryParams = {
      effective_on_date: new Date(effectiveOnDate).toISOString(),
      limit: Number.MAX_SAFE_INTEGER
    }
    if (locationIds && locationIds.length === 1) {
      // The REST API allows filtering by one single location.
      queryParams.location = locationIds[0]
    }

    // TODO: a future iteration should allow filtering by more than one location in
    // the REST API. The best way to implement this will probably be allowing filtering
    // by territory, since the REST API is using `get` and passing a long list of
    // location ids in the query string won't work.
    let forecasts = (await this.restAdapter.get('forecast', queryParams)).results
    if (locationIds) {
      forecasts = forecasts.filter(forecast => locationIds.includes(forecast.location))
    }
    return toForecastPerLocation(forecasts)
  }
}

class ForecastApi extends EntityApi {
  constructor (state, restAdapter) {
    const adapter = new ForecastDataAdapter(restAdapter)
    super(adapter)
  }

  get (...params) {
    return this.adapter.get(...params)
  }

  // TODO: right now the only allowed params are
  // `effectiveOnDate` and `locationIds`.
  // In the future it should be possible to request all
  // forecasts that were active in a date range.
  //
  // One way of implementing this could be to allow passing
  // to `effectiveOnDate` an ISO string or an object like
  // ```
  // {
  //   from: <ISOString>,
  //   to: <ISOString>
  // }
  // ```
  async list (params = {}, { forecastSource = 'smartForecast' } = {}) {
    if (forecastSource === 'smartForecast') { // ShelfLife: forecasts coming from the Django REST API
      return this.adapter.list(params)
    }

    const {
      effectiveOnDate,
      locationIds
    } = params

    // PSM zones: base forecasts in remote allocation configs
    if (forecastSource === 'allocation') {
      const allocConfigs = await this.mainApi.allocation.getAllConfigurations({
        date: effectiveOnDate,
        includeDemandPlan: true,
        [locationIds && 'facilityIds']: locationIds
      })

      return allocConfigs.map(allocConfig => allocConfigToForecast(allocConfig))
    }

    // PSM state and SDPs: base forecasts in reported consumption and fixed
    // supply plans
    if (forecastSource === 'report') {
      const { reports = {} } = params
      const forecasts = []
      for (const locationId of locationIds) {
        const report = reports[locationId]
        if (!report) {
          continue
        }
        const periodDefinition = await this.mainApi.report.period.getDefinition({
          programId: report.serviceId.split(':service')[0]
        })
        forecasts.push(psmReportToForecast(locationId, report, periodDefinition))
      }
      return forecasts.filter(exist => exist)
    }

    // VAN: very limited implementation, mostly added to make the tests
    // in the web app pass.
    // It uses the pre-calculated weekly levels on the location docs.
    // It won't work for locations with per product supply plans.
    //
    // If VAN comes back we'll need to change this to use local forecast docs
    // that are created by `field-supply/backend` in response to the creation
    // of a new allocation doc.
    if (forecastSource === 'location') {
      const locations = locationIds
        ? await this.mainApi.location.getByIds(locationIds, effectiveOnDate)
        : await this.mainApi.location.listAll({ date: effectiveOnDate })

      return locations.map(location => locationToForecast(location, effectiveOnDate))
    }

    throw new Error('Error: unknown "forecastSource", supported sources are "smartForecast", "allocation", "report" and "location"')
  }

  // Alternative config params can be passed in `altConfigs`. They
  // take precedence over the config params in the forecast object.
  // `altConfigs` are only applied if `forecastSource` is `smartForecast`.
  //
  // Right now the only allowed alternative config param is `supplyPeriodDays`.
  async getLevels ({ locationIds, effectiveOnDate = new Date().toISOString(), altConfigs = {}, reports = {} } = {}, { forecastSource = 'smartForecast' } = {}) {
    const forecasts = await this.list({ effectiveOnDate, locationIds, reports }, { forecastSource })

    // Apply alternative configurations, when available
    if (forecastSource === 'smartForecast') {
      forecasts.forEach(forecast => {
        const altConfig = altConfigs[forecast.locationId]
        if (altConfig) {
          // Assumes that the supply plan is the same for all products in
          // the location, which is currently the case in ShelfLife (not in VAN or PSM)
          forecast.supplyPlans.default.supplyPeriodDays = altConfig.supplyPeriodDays
        }
      })
    }

    // If this is VAN we need to round results to the presentation
    // and for that we need to get the product docs
    const productsById = {}
    if (forecastSource === 'location') {
      const products = await this.mainApi.product.listAll()
      products.forEach(product => {
        productsById[product._id] = product
      })
    }

    // In PSM (`forecastSource` report or allocation) the min/max thresholds are set in months, not days
    const supplyTimeUnit = forecastSource === 'allocation' || forecastSource === 'report' ? 'month' : 'day'
    return forecasts.reduce((index, forecast) =>
      Object.assign(index, { [forecast.locationId]: forecastToLevels(forecast, forecastSource === 'location', productsById, supplyTimeUnit) })
      , {})
  }
}

module.exports = ForecastApi
