import React from 'react'

import { Banner } from '@fielded/shared-ui'

export default ({ error }) => {
  return (
    <Banner
      inline
      type='warning'
    >
      <h2>Shipment Loading Error</h2>

      <p>
        There was an error loading the shipments. Please refresh the page to retry.
      </p>

      <pre>
        {error.message}

        {error.stack}
      </pre>
    </Banner>
  )
}
