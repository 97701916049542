import { combineReducers } from 'redux'

import root from './root-reducer-slice'
import masterData from './master-data-reducer'
import reportEntry from '../report-entry/report-entry-reducer'
import overview from '../overview/overview-reducer'
import { syncReducer as sync } from '../../../sync'

const rootReducer = combineReducers({
  root,
  masterData,
  reportEntry,
  overview,
  sync
})

export default rootReducer
