export default {
  shipments: {
    role: 'feature:shipments',
    displayName: 'Shipments'
  },
  globalShipments: {
    role: 'feature:global-shipments',
    displayName: 'Global shipments',
    description: 'Required for national user that are supposed to see inbound shipments'
  },
  lastMileDeliveries: {
    role: 'feature:lastMileDeliveries',
    displayName: 'Last Mile Deliveries',
    description: 'User can view last mile deliveries'
  }
}
