import { createSlice } from '@reduxjs/toolkit'
import { v4 } from 'uuid'

const initialState = {
  productSelection: {},
  viewType: 'selection',
  cartId: v4()
}

export const fastOrderPlacementSlice = createSlice({
  name: 'fastBasicOrder',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    productUpdate: (state, action) => {
      const { productId, quantity, promo } = action.payload
      state.productSelection = {
        ...state.productSelection,
        [productId]: { quantity, promo }
      }
    },
    productRemove: (state, action) => {
      const productId = action.payload
      const newSelection = { ...state.productSelection }
      delete newSelection[productId]
      state.productSelection = newSelection
    },
    selectionClear: (state) => {
      state.productSelection = {}
      state.viewType = 'selection'
      state.cartId = v4()
    },
    viewTypeSet: (state, action) => {
      state.viewType = action.payload
    }
  }
})

export const {
  productUpdate,
  productRemove,
  selectionClear,
  viewTypeSet
} = fastOrderPlacementSlice.actions

export const selectProductSelection = (state) => state.fastOrderPlacement.productSelection

export const selectViewType = (state) => state.fastOrderPlacement.viewType

export const selectCartId = (state) => state.orderPlacement.cartId

export default fastOrderPlacementSlice.reducer
