import React from 'react'

import PropTypes from 'prop-types'
import {
  SortDirection
} from 'react-virtualized'

import UppercaseLabel from '../UppercaseLabel'
import SortIndicator from '../SortIndicator'

const DataTableHeader = ({
  label,
  dataKey,
  disableSort,
  sortBy,
  sortDirection
}) => {
  return label ? (
    <div className='vs-data-table-header__label'>
      <UppercaseLabel>
        {label}
      </UppercaseLabel>
      {!disableSort && (
        <span className='vs-data-table-header__sort vs-u-hide-on-print'>
          <SortIndicator sortDirection={sortBy === dataKey ? sortDirection : undefined} />
        </span>
      )}
    </div>
  ) : null
}

DataTableHeader.propTypes = {
  /**
   * The label to display
   */
  label: PropTypes.string,

  /**
   * Disables sorting on the column data
   */
  disableSort: PropTypes.bool,

  /**
   * The data key of the column. Provided by Table.
   */
  dataKey: PropTypes.string.isRequired,

  /**
   * The current value of sortBy. Provided by Table.
   */
  sortBy: PropTypes.string.isRequired,

  /**
   * The sort direction. Provided by Table.
   * A react-virtualised SortDirection constant.
   */
  sortDirection: PropTypes.oneOf([
    SortDirection.ASC,
    SortDirection.DESC
  ])
}

DataTableHeader.defaultProps = {
  disableSort: false,
  sortDirection: SortDirection.ASC
}

export default DataTableHeader
