const { get: getService } = require('./service')
const { periodIdToDate } = require('./report/tools/ids')
const getPeriodFromProgram = require('./report/tools/get-period-from-program')
const submitReport = require('./report/api/submit')

module.exports = async (state, {location, serviceId, reportingPeriod}) => {
  const locationId = location ? location.id : state.user.location.id
  const service = await getService(state, serviceId)
  const date = periodIdToDate(reportingPeriod)
  const period = getPeriodFromProgram(service.program, date, true)

  return submitReport(state, {locationId, service, period})
}
