
import React from 'react'
import classNames from 'classnames'
import { PriceDisplay, Text } from '@fielded/shared-ui'
import { ExclamationTriangle, InfoCircle } from '@fielded/shared-ui/src/icons'

export const OrderValueDifferentMessage = ({
  paidTotal,
  currentTotal,
  country,
  isDetailed = false,
  isToBePaidOnDelivery
}) => {
  const difference = paidTotal - currentTotal
  const isOverSpent = difference < 0
  if (difference === 0) {
    return null
  }

  let textMessage = 'Delivery contains less than amount paid'

  if (isOverSpent) {
    textMessage = 'Delivery exceeds amount paid! Reduce total to proceed'
  }

  if (isToBePaidOnDelivery) {
    textMessage = 'This order will be paid on delivery'
  }

  return (
    <div className={classNames(
      'order-value-different-message__container',
      {
        'order-value-different-message__container--info': !isOverSpent || isToBePaidOnDelivery,
        'order-value-different-message__container--warning': isOverSpent && !isToBePaidOnDelivery,
        'order-value-different-message__container--detailed': isDetailed
      }
    )}>
      <Text className={classNames(
        'order-value-different-message__icon',
        {
          'order-value-different-message__info-color': !isOverSpent,
          'order-value-different-message__warning-color': isOverSpent
        }
      )}>

        {isOverSpent ? <ExclamationTriangle /> : <InfoCircle />}
      </Text>
      <div className='order-value-different-message__content'>
        <Text.Heading size='small'>
          {textMessage}
        </Text.Heading>
        {isDetailed && (
          <div className='order-value-different-message__details'>
            <div className='order-value-different-message__row order-value-different-message__row--with-separator'>
              <Text>Already paid:</Text>
              <PriceDisplay country={country} currencySymbol value={paidTotal} />
            </div>
            <div className='order-value-different-message__row'>
              <Text>Delivery total:</Text>
              <PriceDisplay country={country} currencySymbol value={currentTotal} />
            </div>
            <div className='order-value-different-message__row order-value-different-message__row--with-separator'>
              <Text bold>Difference:</Text>
              <Text bold className={
                isOverSpent
                  ? 'order-value-different-message__warning-color'
                  : 'order-value-different-message__info-color'
              }>
                <PriceDisplay country={country} currencySymbol value={Math.abs(difference)} prefixedValue={isOverSpent ? '+ ' : '- '} />
              </Text>
            </div>
          </div>)}
      </div>
    </div>
  )
}
