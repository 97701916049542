const { getProgramPeriod } = require('./../../tools')

exports.updateProgram = async function (state, params) {
  const programsDoc = await state.dal.program.getPrograms(state)
  if (!programsDoc['programs'].find(element => element.id === params.id)) {
    const error = new Error(`program does not exists`)
    error.status = 404
    throw error
  }

  const username = state.user ? state.user.name : ''

  const doc = prepareProgramDocToSave(programsDoc, params, username)

  return state.dal.program.save(state, doc)
}

const prepareProgramDocToSave = (programsDoc, params, username) => {
  const { name, id, reportingPeriod, services } = params

  // transform name to id
  const trimmedName = name.trim()

  // Generate program period based on selected reporting period
  const periods = getProgramPeriod(reportingPeriod)

  const newProgram = {
    id, // We do not update program Id
    name: trimmedName,
    fullName: trimmedName,
    reportingPeriod: reportingPeriod === 'quarterly' ? 'bimonthly' : reportingPeriod, // quarterly is also called bimonthly but with different period rules
    report: {
      periods
    },
    services
  }

  const programs = programsDoc.programs

  // Remove the old programs object
  const updatedPrograms = programs.filter(program => program.id !== id)

  // Add updated program
  updatedPrograms.push(newProgram)

  return {
    ...programsDoc,
    programs: updatedPrograms,
    updatedBy: username,
    updatedAt: new Date().toJSON()
  }
}
