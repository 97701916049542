const SignupPGAdapter = require('./data-access/pg')
const tools = require('./tools')

class SignupApiAdapter {
  constructor (pgConnection, restAdapter) {
    this.pgAdapter = new SignupPGAdapter(pgConnection)
    if (restAdapter) {
      this.restAdapter = restAdapter.extend(this, 'signup')
    }
  }

  getAllExcluding (idsToExclude) {
    return this.pgAdapter.getAllExcluding(idsToExclude)
  }

  getSignupWithDocuments () {
    return this.pgAdapter.getSignupWithDocuments()
  }
}

Object.assign(SignupApiAdapter.prototype, {tools})

module.exports = SignupApiAdapter
