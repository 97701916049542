const cloneDeep = require('lodash/cloneDeep')

// TODO: const snapshots = orders.filter(findMatching).map(updateMatching)

exports.advanceStatusSnapshots = advanceStatusSnapshots
function advanceStatusSnapshots ({orders, currentStatus}) {
  const snapshots = orders
    .reduce((acc, order) => {
      const snapshotToUpdate = getSnapshotToUpdate(order.snapshotsByStatus, currentStatus)
      if (!snapshotToUpdate) return acc

      const updatedSnapshot = Object.assign({}, cloneDeep(snapshotToUpdate), {isComplete: true})
      return acc.concat([updatedSnapshot])
    }, [])

  return snapshots
}

function getSnapshotToUpdate (snapshotsByStatus, currentStatus) {
  const list = snapshotsByStatus[currentStatus]
  return list && list.length
    ? list[list.length - 1]
    : null
}
