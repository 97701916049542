import React from 'react'
import PropTypes from 'prop-types'

import ActionsBar from '../../ActionsBar'

const Actions = ({ children, ...otherProps }) => (
  <div className='vs-form-actions'>
    <ActionsBar {...otherProps}>
      { children }
    </ActionsBar>
  </div>
)

Actions.propTypes = {
  /**
   * User action options, usually buttons. Will be wrapped in bem element.
   */
  children: PropTypes.node,

  /**
   * The layout pattern for the actions
   */
  layout: PropTypes.oneOf(['right', 'left', 'centered', 'space-between']),

  /**
   * Puts items next to each other on small screen
   */
  nowrap: PropTypes.bool,
  /**
   * Optional additional classes
   */
  className: PropTypes.string
}

Actions.defaultProps = {
  children: null
}

Actions.displayName = 'Form.Actions'

export default Actions
