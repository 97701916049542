import React, { createElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'
import DeemphasizedLink from '../DeemphasizedLink'
import { Arrow } from '../../icons'

/**
 * Generic `<BackButton/>` Component. Composes `<Button/>`.
 *
 */
class BackButton extends Component {
  render () {
    const {
      children,
      deemphasized,
      backUrl,
      className,
      history,
      onGoBack,
      ...otherProps
    } = this.props

    const handleGoBack = () => {
      if (backUrl && typeof backUrl === 'string') {
        history.push(backUrl)
      } else if (onGoBack && typeof onGoBack === 'function') {
        onGoBack()
      } else {
        history.goBack()
      }
    }

    let component = DeemphasizedLink
    let componentProps = {
      className: classnames(
        'vs-u-hide-on-print',
        className
      ),
      onClick: handleGoBack,
      ...otherProps
    }

    if (!deemphasized) {
      component = Button
      componentProps = {
        ...componentProps,
        icon: <Arrow direction='left' />
      }
    }

    return createElement(
      component,
      componentProps,
      children
    )
  }
}

BackButton.propTypes = {
  /**
  * Content for the back button. Can be text or any tag supported inside a button
  */
  children: PropTypes.node,
  /**
   * Optional backUrl, for going back to a specific page.
   */
  backUrl: PropTypes.string,
  /**
   * Optionally render this as a deepmhasized link without the icon
   */
  deemphasized: PropTypes.bool,
  /**
   * Any additional class names you want to add to the button
   * */
  className: PropTypes.string,
  /**
  * react-router history (object)
  */
  history: PropTypes.object.isRequired
}

BackButton.defaultProps = {
  children: 'go back'
}

export default BackButton
