import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Loading = ({loadingText, size, spread}) => (
  <div
    className={classnames(
      'vs-loading',
      {'vs-loading--size-regular': size === 'regular'},
      {'vs-loading--size-large': size === 'large'},
      {'vs-loading--spread': spread}
    )}
  >
    <div className='vs-loading__pulse'>
      <div className='vs-loading__text'>
        {loadingText}
      </div>
    </div>
  </div>
)

Loading.propTypes = {
  loadingText: PropTypes.string,
  size: PropTypes.oneOf(['regular', 'large']),
  spread: PropTypes.bool
}

Loading.defaultProps = {
  loadingText: 'Loading…',
  size: 'regular',
  spread: true
}

export default Loading
