import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink, withRouter } from 'react-router-dom'

import { CountBadge } from '@fielded/shared-ui'

import { selectProductSelection } from '../../subapps/facility-products/reducers/orderPlacementSlice'
import { selectSubscribeSelection } from '../../subapps/facility-products/reducers/subscriptionsSlice'

const PRODUCT_ROUTE = '/products'

const MenuItemLink = ({
  path,
  title,
  icon,
  isCompact,
  preventDefault,
  isOfflineDisabled,
  isIntercom,
  isExternal,
  showBadgeLabel,
  location
}) => {
  // if we enter directly to an url with a slug, then the submenu should be open
  const [isHover, setIsHover] = useState(false)

  let count
  if (showBadgeLabel) {
    const productSelection = useSelector(selectProductSelection)
    const subscribeSelection = useSelector(selectSubscribeSelection)

    const productSelectionCount = Object.keys(productSelection).length
    const subscribeSelectionCount = Object.keys(subscribeSelection).length

    count = productSelectionCount || subscribeSelectionCount
  }

  const isProductsRoute = location.pathname === PRODUCT_ROUTE

  const handleOnMouseEnter = () => {
    if (isCompact) setIsHover(true)
  }

  const handleOnMouseLeave = () => {
    if (isCompact) setIsHover(false)
  }

  const handleClick = (e) => {
    if (preventDefault) return e.preventDefault()
  }

  const itemFragmentBadgeLabel = !!count && !isProductsRoute && (
    <CountBadge colorCode='accent' count={count} />
  )

  const itemFragment = (
    <Fragment>
      {icon && (
        <span className='menu-item__icon'>{icon}</span>
      )}
      <span className='menu-item__label menu-item__label--canhide'>
        <span className='vs-u-row-centered menu-item__label-wrapper'>
          <span>
            {isOfflineDisabled ? `${title} is not available offline` : title}
          </span>
          {itemFragmentBadgeLabel}
        </span>
      </span>
    </Fragment>
  )

  const commonWrapperProps = {
    className: classnames(
      'menu-item__link',
      {'menu-item__link--disabled': isOfflineDisabled},
      {'intercom-trigger': isIntercom},
      {'menu-item__link--intercom-trigger': isIntercom}
    )
  }

  return (
    <div onMouseLeave={handleOnMouseLeave}>
      <div onMouseEnter={handleOnMouseEnter}>
        {isIntercom &&
          <button
            {...commonWrapperProps}
          >
            {itemFragment}
          </button>
        }
        {isExternal &&
          <a
            href={path}
            target='_blank'
            rel='noopener noreferrer'
            {...commonWrapperProps}
          >
            {itemFragment}
          </a>
        }
        {!isExternal && !isIntercom &&
          <NavLink
            onClick={handleClick}
            to={path}
            activeClassName='menu-item__link--active'
            isActive={(match) => {
              // prevent highlighting the Home
              if (match && match.url === '') {
                return match.isExact
              } else {
                return match
              }
            }}
            {...commonWrapperProps}
          >
            {itemFragment}
          </NavLink>
        }
      </div>

      {isHover && (
        <div className='menu-item__popover menu-item__popover--fadeout'>
          <div className='menu-item__popover-content'>
            <div className='menu-item__link menu-item__link--noicon'>
              <span className='menu-item__label menu-item__label-wrapper vs-u-row-centered'>
                <span>{title}</span>
                {itemFragmentBadgeLabel}
              </span>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

MenuItemLink.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  badgeLabel: PropTypes.string,
  isOfflineDisabled: PropTypes.bool,
  preventDefault: PropTypes.bool,
  isIntercom: PropTypes.bool,
  isExternal: PropTypes.bool,
  showBadgeLabel: PropTypes.bool,
  location: PropTypes.object
}

export default withRouter(MenuItemLink)
