const { construct } = require('./../../tools/construct')

exports.listAll = function (state, opts = {}) {
  const constructOpts = {}
  if (opts.date) {
    constructOpts.now = opts.date
  }

  return state.dal.product.listAll(state)
    .then(results => results.map(r => construct(r, constructOpts)))
}

exports.listAllForDates = async function (state, { dates = [] } = {}) {
  if (dates.length === 0) {
    return []
  }
  const docs = await state.dal.product.listAll(state)
  return dates.map(date => docs.map(d => construct(d, { now: date })))
}
