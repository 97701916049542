const {SHIPMENT_IMPORT_HEADERS} = require('./config')
const {parse, isValid} = require('date-fns')
const isInteger = require('lodash/isInteger')
const toNumber = require('lodash/toNumber')

function findInvalidHeaders (firstRow) {
  let errors = ''
  SHIPMENT_IMPORT_HEADERS
    .filter(header => header !== 'expiry')
    .forEach(header => {
      if (!firstRow[header]) {
        errors = `Error: could not find column header ${header} ${errors} in the uploaded sheet`
      }
    })

  return (errors !== '')
    ? errors
    : null
}

function findInvalidRows ({rows, programs, products, configurations, locations}) {
  const allProgramIdsSet = new Set(programs.map(p => p.id))
  const allProductsIdsSet = new Set(products.map(p => p._id))
  const allLocationIdsSet = new Set(locations.map(l => l._id))
  const productsByProgramId = configurations
    .reduce((acc, configuration) => {
      const [programId] = configuration._id.replace('configuration:', '').split(':service:')
      acc[programId] = acc[programId] || []
      acc[programId] = acc[programId].concat(configuration.products)
      return acc
    }, {})

  const errors = rows
    .reduce((acc, row, index) => {
      const errors = findRowErrors(
        {row, index, allProgramIdsSet, allProductsIdsSet, allLocationIdsSet, productsByProgramId}
      )
      return acc.concat(errors)
    }, [])

  return errors.concat(findDuplicateRows(rows))
}

function findDuplicateRows (rows) {
  const errors = []
  const foundRows = {}
  rows.forEach((row, index) => {
    const {date, destinationWarehouseCode, productId, batchNo, manufacturer} = row
    const key = `${date}${destinationWarehouseCode}${productId}${batchNo}${manufacturer}`
      .toLowerCase()
    if (!foundRows[key]) {
      foundRows[key] = {index}
    } else {
      // foundRows[key].index will be the index of the first time the row was found
      const message = `Duplicate rows found for ${row.productId}`
      // +2 for the user to see the actual excel number,
      // start at 1 (not zero) and skip header row
      errors.push({
        message,
        index: `first on row ${foundRows[key].index + 1} and second on row ${index + 2}`
      })
    }
  })

  return errors
}

function findRowErrors (
  {row, index, allProgramIdsSet, allProductsIdsSet, allLocationIdsSet, productsByProgramId}
) {
  const errors = []

  // a non ONE/none product code alias is 99% a user error, at least when first
  // releasing this. Adding other aliases might be simple in
  // the products code, but we will probably want to look at location aliases
  // if not ONE then what will they be
  if (row.productCodeAlias !== 'one' && row.productCodeAlias !== 'none') {
    errors.push(
      `given productCodeAlias is not yet supported: ${row.productCodeAlias}`
    )
  }

  if (!allProgramIdsSet.has(row.programId)) {
    errors.push(
      `given programId could not be found ${row.programId}`
    )
  }

  if (!allProductsIdsSet.has(row.productId)) {
    errors.push(
      `given productId could not be found ${row.productId}`
    )
  }

  if (!allLocationIdsSet.has(row.destinationId)) {
    errors.push(
      `given destinationId could not be found ${row.destinationId} ${row.destinationWarehouseCode}`
    )
  }

  if (!productsByProgramId[row.programId] || !productsByProgramId[row.programId].includes(row.productId)) {
    errors.push(
      `given productId ${row.productId} could not be found on program ${row.programId}`
    )
  }

  if (row.expiry && row.batchNo === 'UNKNOWN') {
    errors.push(
      `if expiry is present, batch number must not be UNKNOWN. found ${row.batchNo}`
    )
  }

  if (!isValid(parse(row.date))) {
    errors.push(
      `given date ${row.date} is invalid`
    )
  }

  if (row.expiry && !isValid(parse(row.expiry))) {
    errors.push(
      `given expiry ${row.expiry} is invalid`
    )
  }

  // toNumber returns NaN if given 3.3ad
  // and we want integers here because these are going to be in WH units
  if (!isInteger(toNumber(row.quantity))) {
    errors.push(
      `given quantity invalid, must be integer. Found quantity: ${row.quantity}`
    )
  }

  // so our users know which row index this error came from
  return errors.map(message => ({message, index}))
}

module.exports = {findInvalidHeaders, findInvalidRows}
