function isPGUsageErrorCode (code) {
  // https://www.postgresql.org/docs/11/errcodes-appendix.html
  // KD added these in order to return 400s to API consumers
  // instead of 500s: it's probably not perfect.
  const USAGE_ERRORS = [
    // string_to_uuid
    '22P02',
    // syntax
    '42000',
    // syntax
    '42601',
    // non null
    '23502'
  ]
  return USAGE_ERRORS.includes(code)
}

module.exports = isPGUsageErrorCode
