const transactionsListSelector = `
  SELECT "t"."id",
         "t"."location_id",
         due_date,
         amount,
         amount_paid,
         description,
         txn_type,
         quickbooks_doc_number,
         txn_date,
         quickbooks_created_at as created_at,
         quickbooks_updated_at as updated_at,
         order_type,
         payment_plan,
         shipment_id,
         order_id
  FROM transactions "t"
`

module.exports = (whereCondition) => `${transactionsListSelector} ${whereCondition || ''}`
