import React from 'react'

import { Text } from '@fielded/shared-ui'

import { SHIPMENT_STATUS, SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS } from '@fielded/fs-api/lib/shipment/constants'

const LMDListStatusDistribution = ({
  shipments
}) => {
  const ELIGIBLE_STATUS_LIST = Object.values(SHIPMENT_STATUS)

  const shipmentsNumberPerStatus = ELIGIBLE_STATUS_LIST.reduce((acc, status) => {
    const eligibleShipments = shipments.filter((shipment) => shipment.status === status)
    acc[status] = eligibleShipments.length
    return acc
  }, {})

  const getStatusWidth = (shipmentsWithStatus) => {
    const amountOfShipments = shipments.length
    const percentage = (shipmentsWithStatus / amountOfShipments) * 100
    return Math.floor(percentage)
  }

  return (
    <div className='last-mile-deliveries__status-distribution'>
      <div className='last-mile-deliveries__status-distribution-bar'>
        {ELIGIBLE_STATUS_LIST.map(status => {
          const numberOfShipments = shipmentsNumberPerStatus[status]
          const widthOfTheItem = getStatusWidth(numberOfShipments)
          return (
            <span
              key={status}
              className={`last-mile-deliveries__status-distribution-item last-mile-deliveries__status-distribution-item--${status}`}
              style={{width: `${widthOfTheItem || 0}%`}}
            >
              {SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS[status]}
            </span>
          )
        })}
      </div>
      <div className='last-mile-deliveries__status-distribution-legend vs-u-row vs-u-gap'>
        {ELIGIBLE_STATUS_LIST.map(status => {
          return (
            <span
              key={status}
              className='vs-u-row-centered vs-u-gap-half'
            >
              <span
                className={`last-mile-deliveries__status-distribution-dot last-mile-deliveries__status-distribution-dot--${status}`}
              />
              <Text uppercase bold size='tiny'>{SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS[status]}</Text>
              <Text size='tiny'>({shipmentsNumberPerStatus[status]})</Text>
            </span>
          )
        })}
      </div>
    </div>

  )
}

export default LMDListStatusDistribution
