const generateReportIds = require('./generate-report-ids')
const getPeriodFromProgram = require('../report/tools/get-period-from-program')
const getIdVersion = require('../report/tools/get-id-version')
const getPreviousPeriod = require('../report/tools/get-previous-period')

// returns a flat list of ids so we can `{keys}` get them from db
// if param is just a date, returns ids just on previous period
// if startDate, starting from that start date to the previous period on date
// if specific reportingPeriod, ignores dates & uses that.
exports.createReportIdsOnProgram = function ({
  locations,
  program,
  date = new Date().toJSON(),
  startDate,
  reportingPeriod
}) {
  let till
  if (reportingPeriod) {
    till = reportingPeriod
  } else {
    const period = getPeriodFromProgram(program, date, true)
    const idVersion = getIdVersion(program)

    if (idVersion > 1) {
      throw new Error(`Can not get reports by keys with ID version 2 or larger`)
    }

    const previousPeriod = getPreviousPeriod(program, period)
    till = previousPeriod.effectiveStartDate
  }
  const since = startDate || till

  let ids = []
  locations.forEach(location => {
    const locationServices = getLocationServices(location, program)

    if (!locationServices) {
      console.warn(
        `program ${program.id} not found for location location._id`
      )
    }

    locationServices.forEach(service => {
      const serviceIds = generateReportIds(
        location._id, service, {since, till}
      )
      ids = ids.concat(serviceIds)
    })
  })
  return ids
}

function getLocationServices (location, program) {
  const locationProgram = location.programs
    .find(({id}) => id === program.id)

  if (!locationProgram) return

  return locationProgram.services.map(service => {
    const programEntityService = program.services
      .find(({id}) => id === service.id)

    return Object.assign({}, service, programEntityService)
  })
}
