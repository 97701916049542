import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import StatusLabelButton from '../StatusLabelButton'

const SubNavigationItemTitle = ({icon, title, badgeLabel, badgeStatus}) => (
  <Fragment>
    {badgeLabel && (
      <span className='vs-sub-navigation__item-badge'>
        <StatusLabelButton label={badgeLabel} status={badgeStatus} pointer tight />
      </span>
    )}
    {icon && (
      <span className='vs-sub-navigation__item-icon'>
        {icon}
      </span>
    )}
    { title }
  </Fragment>
)

const SubNavigation = ({
  label,
  items,
  linkRenderer,
  className
}) => {
  return (
    <div className={classnames(
      'vs-sub-navigation',
      className
    )}>
      {label && (
        <span className='vs-sub-navigation__label' key='subnavlabel'>{ label }</span>
      )}
      {items.map(({title, path, active, icon, badgeLabel, badgeStatus, ...otherProps}) => {
        const itemProps = {icon, title, badgeLabel, badgeStatus}
        return (active || !path) ? (
          <span
            key={title}
            className='vs-sub-navigation__item vs-sub-navigation__item--active'
          >
            <SubNavigationItemTitle {...itemProps} />
          </span>
        ) : linkRenderer(
          {
            key: title,
            to: path,
            className: 'vs-sub-navigation__item',
            children: <SubNavigationItemTitle {...itemProps} />,
            // other props might be something like `exact` for Link
            ...otherProps
          })
      })}
    </div>
  )
}

SubNavigation.propTypes = {
  /**
   * To inform on the context for the sub navigation
   */
  label: PropTypes.string,

  /**
   * Items in the menu
   */
  items: PropTypes.array.isRequired,

  /**
   * Pass to make sub navigation links use a custom link renderer, .e.g. Link.
   * Must have `to`, `className`, `children`.
   */
  linkRenderer: PropTypes.func
}

SubNavigation.defaultProps = {
  label: 'In this section:',
  linkRenderer: ({ to, className, children, ...otherProps }) => (<a href={to} className={className} {...otherProps}>{children}</a>)
}

export default SubNavigation
