import React from 'react'
import classnames from 'classnames'

import { ActionsBar, Button, EmptyState, Loading, Text } from '@fielded/shared-ui'

import ServiceHistoryListItem from './ServiceHistoryListItem'
import { getSnapshotId } from '../../shipments/helpers'
import ServiceHistoryFilter from './ServiceHistoryFilter'

const ServiceHistoryList = ({
  title,
  displayList,
  user,
  history,
  label,
  value,
  filterExpandableOptions,
  onFilterServices,
  onLoadMore,
  shouldLoadMore,
  isLoading,
  hasSubscriptionBasedService,
  className
}) => {
  const showList = !!displayList.length

  const onViewItem = (url) => {
    history.push(url)
  }

  return (
    <section className={classnames(
      'service-visit__history',
      className
    )}>
      <div className='vs-u-align-center service-visit__history-header'>
        <Text.Heading size='smallish'>{title || 'Service'} history</Text.Heading>
        <div className='service-visit__history-header-line' />
      </div>

      <section>
        <div className='service-visit__history-list-wrapper'>
          <div className='service-visit__history-list-header'>
            {!hasSubscriptionBasedService ? (
              <Text className='service-visit__history-list-title' bold>Deliveries</Text>
            ) : (
              <div className='service-visit__history-list-filter'>
                <Text size='small'>Showing: </Text>
                <ServiceHistoryFilter
                  label={label}
                  value={value}
                  filterExpandableOptions={filterExpandableOptions}
                  onFilterServices={onFilterServices}
                />
              </div>
            )}
          </div>

          {displayList.length === 0 && (
            <EmptyState
              className='service-visit__history-empty-state'
              text={`No filtered ${label.toLowerCase()}`}
            />
          )}

          {showList && (
            <ul>
              {displayList.map(service => {
                const snapshotId = getSnapshotId(service)
                const url = service.serviceDetails.url

                return (
                  <li
                    key={snapshotId || service._id}
                    onClick={() => onViewItem(url)}
                    className='service-visit__history-list'
                  >
                    <ServiceHistoryListItem service={service} />
                  </li>
                )
              })}
            </ul>
          )}
        </div>

        {showList && shouldLoadMore && (
          <ActionsBar layout='centered'>
            {isLoading && <Loading loadingText='' />}
            {!isLoading && (
              <Button
                onClick={onLoadMore}
                fill='outline'
                colorVariant='brand'
              >
                Load more
              </Button>
            )}
          </ActionsBar>
        )}
      </section>
    </section>
  )
}

export default ServiceHistoryList
