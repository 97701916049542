import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { Button, NumberFormat, OptionsGroup, Text, UppercaseLabel } from '@fielded/shared-ui'
import { Exchange, ExclamationCircle, Issue } from '@fielded/shared-ui/src/icons'

import { formatDate } from '../../../van-shared/utils'
import { buildViewReportUrl } from '../app/routes-urls'
import Qty from '../common/qty'
import { urlWithParams } from './utils'

const PAGE_SIZE = 500

const viewingOptions = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Sales',
    value: 'stockCount'
  },
  {
    label: 'Deliveries',
    value: 'shipment'
  }
]

// Some user names will be of scripts or bots. We translate those
// to _nicer_ display names.
const maybeMapUserName = username => {
  const nameMap = {
    'mark-shipment-received-bot': 'auto received',
    'fs-data-ops/sl-opening-balance': 'auto initialised'
  }
  return nameMap[username] || username
}

const typeLink = ({ _id, productId, subType, type, filters, baseLedgerUrl, otherLocationId, origin, isReceive, destination }) => {
  // Differentiate between partial count (adjustment), Stock Count (not partial) and Balance Transfer
  if (type === 'stockCount') {
    const reportUrl = buildViewReportUrl({ reportId: _id, productId })
    let label = 'Sold (via stock count)'
    if (subType === 'balance-transfer') {
      label = 'Balance Transfer'
    } else if (subType === 'adjustment') {
      label = 'Sold (via Partial Stock Count)'
    } else if (subType === 'ooc-buyout') {
      label = 'Sold'
    } else if (subType === 'topup-buyout') {
      label = 'Sold (due to top up request)'
    }
    return <Link to={reportUrl}>{label}</Link>
  }

  const text = isReceive ? 'Arrived from ' : 'Sent to '
  const locationName = isReceive ? origin.name : destination.name

  if (filters.otherLocationId) {
    return <span>{text} {locationName}</span>
  }

  return (
    <span>
      {text}
      <Link to={urlWithParams(baseLedgerUrl, { ...filters, otherLocationId })} replace>
        {locationName}
      </Link>
    </span>
  )
}

const StockCountSales = ({ balanceTransfer, previousBatchQuantity, quantity }) => {
  const sales = previousBatchQuantity - quantity

  if (balanceTransfer !== 0) {
    // Note: negative transfer; shelflife => partner, positive transfer: partner => shelflife
    return (
      <div className='ledger-table__stock-count-adjustment'>
        <span className='ledger-table__stock-count-adjustment-icon'><Exchange /></span>
        <span>{Math.abs(balanceTransfer)}</span>
      </div>
    )
  }

  return (
    <Fragment>
      <div className={classnames(
        'ledger-table__stock-count-adjustment',
        {'ledger-table__stock-count-adjustment--issue': sales < 0}
      )}>
        <span className='vs-quantity-diff__symbol'>
          {sales < 0 && '+'}
          {sales > 0 && '-'}
        </span>
        <span>{Math.abs(sales)}</span>
      </div>
      {sales < 0 && <span className='ledger-table__stock-count-adjustment-icon'><Issue /></span>}
    </Fragment>

  )
}

export default class LedgerTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visibleRowsCount: PAGE_SIZE,
      visibleRows: props.rows.slice(0, PAGE_SIZE)
    }
  }

  showMore = () => {
    const { visibleRows } = this.state
    const moreLength = visibleRows.length + PAGE_SIZE
    this.setState({ visibleRows: this.props.rows.slice(0, moreLength) })
  }

  render () {
    const {
      baseLedgerUrl,
      filters,
      rows,
      changeLedgerViewingOption,
      viewOption,
      appName,
      appAbbr
    } = this.props

    const { visibleRows } = this.state

    let rowsToShow = visibleRows

    // If a filter is applied, we want to filter the rows by type
    if (viewOption !== 'all') {
      rowsToShow = visibleRows.filter(row => row.type === viewOption)
    }

    return (
      <Fragment>
        <div className='ledger-table__info'>
          <Text size='xlarge' bold className='ledger-table__title'>Transaction history</Text>
          <OptionsGroup
            withInlineLabel
            label='Showing:'
            options={viewingOptions}
            defaultValue={viewOption}
            onValueChange={(selected) => changeLedgerViewingOption(selected)}
          />
        </div>
        <div className='ledger-table'>
          <table className='ledger-table__table'>
            <thead>
              <tr>
                <th rowSpan='2' className='ledger-table__info-cell'>
                  <UppercaseLabel>Date</UppercaseLabel>
                </th>
                <th rowSpan='2' className='ledger-table__info-cell'>
                  <UppercaseLabel>User</UppercaseLabel>
                </th>
                <th rowSpan='2' className='ledger-table__info-cell'>
                  <UppercaseLabel>Type/Location</UppercaseLabel>
                </th>
                <th rowSpan='2' className='ledger-table__amount-cell'>
                  <UppercaseLabel>QTY Sold/Delivered</UppercaseLabel>
                </th>
                <th rowSpan='2' className='ledger-table__amount-cell'>
                  <UppercaseLabel>Balance</UppercaseLabel>
                </th>
              </tr>
            </thead>
            <tbody>
              {rowsToShow.map(({
                ledgerDate,
                type,
                subType,
                productId,
                origin,
                destination,
                location,
                batchNo,
                manufacturer,
                expiry,
                quantity,
                change,
                isReceive,
                newBalance,
                hasDiscrepancy,
                discrepancy,
                balance,
                partnerBalance,
                slBalance,
                balanceTransfer,
                snapshotId,
                previousBatchQuantity,
                _id,
                isUnrecordedArrival,
                otherLocationId,
                user
              }, i) => {
                return (
                  <tr key={i} className='ledger-table__row'>
                    <td className='ledger-table__info-cell ledger-table__nowrap-cell' data-label='Date'>
                      {formatDate(ledgerDate, 'long')}
                    </td>
                    <td className='ledger-table__info-cell ledger-table__nowrap-cell' data-label='User'>
                      {maybeMapUserName(user)}
                    </td>
                    <td className='ledger-table__info-cell' data-label='Type/Location'>
                      {typeLink({
                        _id,
                        productId,
                        subType,
                        type,
                        filters,
                        baseLedgerUrl,
                        otherLocationId,
                        origin,
                        isReceive,
                        destination
                      })}
                      {subType === 'ooc-buyout' && (
                        <Fragment>
                          <div className='ledger-table__stock-count-adjustment-icon ledger-table__stock-count-adjustment-icon--negative'>
                            <ExclamationCircle />
                          </div>
                          <div className='ledger-table__adjustment-note ledger-table__adjustment-note--negative'>
                            (invoiced for {appName} units due to outside buying)
                          </div>
                        </Fragment>
                      )}
                      { hasDiscrepancy && (
                        <div className={classnames(
                          'ledger-table__adjustment-note',
                          {'ledger-table__adjustment-note--negative': discrepancy < 0},
                          {'ledger-table__adjustment-note--positive': discrepancy >= 0}
                        )}>
                          { isReceive ? 'Quantity adjusted on arrival' : 'Receiver adjusted quantities' }
                        </div>
                      )}
                      { isUnrecordedArrival && (
                        <div className={classnames(
                          'ledger-table__adjustment-note',
                          'ledger-table__adjustment-note--negative'
                        )}>
                        * Unrecorded arrival
                        </div>
                      )}
                    </td>
                    <td className='ledger-table__amount-cell' data-label='QTY Sold/Delivered'>
                      {type === 'stockCount'
                        ? (
                          <StockCountSales balanceTransfer={balanceTransfer} previousBatchQuantity={previousBatchQuantity} quantity={quantity} />
                        )
                        : (
                          <div style={{whiteSpace: 'nowrap'}}>
                            <span className='vs-quantity-diff__symbol'>
                              {change > 0 && '+'}
                              {change < 0 && '-'}
                            </span>
                            <NumberFormat
                              value={Math.abs(change)}
                              displayType='text'
                              thousandSeparator
                            />
                          </div>
                        )
                      }
                    </td>
                    <td className='ledger-table__amount-cell ledger-table__total-cell' data-label='Balance'>
                      <Qty quantity={balance} showZero />
                      {type === 'stockCount' && (
                        <div>
                          <Text size='small' element='div' className='ledger-table__split-cell'>Ph: {partnerBalance}</Text>
                          <Text size='small' element='div' className='ledger-table__split-cell'>{appAbbr}: {slBalance}</Text>
                        </div>)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className='ledger-table__show-more'>
            {visibleRows.length < rows.length && (
              <Button
                colorVariant='brand'
                fill='outline'
                size='small'
                onClick={this.showMore}
              >
              Show More {visibleRows.length} of {rows.length}
              </Button>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}
