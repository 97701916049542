const {RRule} = require('rrule')
const format = require('date-fns/format')
const subDays = require('date-fns/sub_days')

const {
  getUntilDate,
  getRuleWeekDay
} = require('../../tools')

const yesterday = subDays(new Date(), 1)

const applyDeliveryRules = ({ frequency, start, day, refDate, endDate }) => {
  if (!frequency || !start || isNaN(day)) {
    return
  }

  // we are using RRule.WEEKLY here because we need to configure a schedule
  // that occurs by the same day of the week for example monday

  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [getRuleWeekDay(`${day}`)],
    dtstart: new Date(start),
    interval: frequency
  })

  return rule.between(new Date(refDate), getUntilDate(frequency, endDate || refDate))
}

const getNextDate = async function (service, refDate, props, mainApi) {
  if (!service) {
    throw new Error('Property \'service\' is required')
  }
  let routes = await mainApi.routes.list()
  const programId = service.split(':service')[0]

  if (service.includes('basic-tier')) {
    service = service.replace('-basic-tier', '')
  }

  routes = routes.filter(route => {
    if (route.programs && route.programs[programId] && route.programs[programId].services && route.programs[programId].services.indexOf(service) > -1) {
      return true
    }
  })

  const deliveryDates = {}

  routes.forEach((route) => {
    const day = route[props.day]
    const frequency = route[props.frequency]
    const start = route[props.start]

    const dates = applyDeliveryRules({
      day, frequency, start, refDate
    })

    if (!dates) {
      // Means route/route is not fully configured yet
      return
    }

    dates.forEach(date => {
      const formattedDate = format(date, 'YYYY-MM-DD')
      if (!deliveryDates[formattedDate]) {
        deliveryDates[formattedDate] = {
          routes: [route]
        }
      } else {
        deliveryDates[formattedDate].routes.push(route)
      }
    })
  })
  const deliveryDatesArray = []

  Object.keys(deliveryDates).forEach(key => {
    deliveryDatesArray.push({date: key, routes: deliveryDates[key].routes})
  })

  return deliveryDatesArray.sort((a, b) => { return new Date(a.date) - new Date(b.date) })
}

exports.applyDeliveryRules = applyDeliveryRules
exports.getNextDate = getNextDate
exports.listDeliveryDates = function (service, refDate = yesterday) {
  const props = {
    frequency: 'deliveryFrequencyWeeks',
    day: 'day',
    start: 'firstDeliveryDate'
  }

  return getNextDate(service, refDate, props, this.mainApi)
}
