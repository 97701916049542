import React, { Component } from 'react'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'

import { batchIdToProductId } from '@fielded/fs-api/lib/batch/tools'
import { OOC_CREATED_BY } from '@fielded/fs-api/lib/report/constants'
import { Banner, Loading } from '@fielded/shared-ui'
import { triggerPrint } from '@fielded/shared-ui/src/components/Page'

import { withApi } from '../common/ApiProvider'
import { withUser } from '../common/AuthenticationProvider'
import withConfig from '../van-shared/hoc/withConfig'

import { removeTime } from '../common/periods'

import Statements from './Statements'
import parse from '@fielded/fs-api/lib/tools/smart-id/parse'
import { isPsm } from '../van-shared/utils/utils'

class StatementsContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      error: null,
      location: null,
      service: null,
      report: null,
      productsById: null
    }
  }

  async componentDidMount () {
    const {
      config,
      api,
      reportId,
      locationId,
      serviceId,
      shipment,
      date,
      showSales,
      showInventory,
      showSummary,
      isWithLedger = true // only Shelf Life for now
    } = this.props

    // Get the location
    let location
    try {
      location = await api.location.get(locationId)
      if (!location) {
        // fetch from agave
        const locations = await api.location.getLocationsViaIds([locationId])
        location = locations[0]
      }
    } catch (e) {
      console.error(e)
      return this.setState({ error: 'Error loading location: ' + locationId })
    }

    if (!location) {
      console.error(`api.location.get(${locationId} returned null`)
      return this.setState({ error: 'Error loading location: ' + locationId })
    }

    // Get the service
    let sId = serviceId
    if (!sId) {
      sId = get(location, 'programs.0.services.0.id')
    }
    let service
    try {
      service = await api.service.get(sId)
    } catch (e) {
      console.error(e)
      if (isWithLedger) {
        return this.setState({ error: 'Error loading service: ' + serviceId })
      }
    }

    // Get the period
    let period
    try {
      period = await api.report.period.get({program: service.program, date})
    } catch (e) {
      console.error(e)
      if (isWithLedger) {
        return this.setState({ error: 'Error loading period: ' + date })
      }
    }

    // Get the report
    let report
    let reportType = 'Statement'
    try {
      /* Statements being displayed from the stock-count view
       * are referencing a specific report,
       * use the report id to find those:
       */
      if (reportId && showInventory && !showSales) {
        // Inventory Statement:
        // This method gets the original report for 'full' counts,
        // and a complete aggregated count for any partial counts
        report = await api.report.findWithAdjustments({ reportId, keepInitialCount: true })
        reportType = 'Inventory statement'
      } else if (reportId) {
        // Sales Statement
        report = await api.report.get({ reportId })

        // If we generated ooc sellout get the whole report with the partial count.
        const createdBy = get(report, 'createdBy')

        if (createdBy === OOC_CREATED_BY) {
          report = await api.report.findWithAdjustments({ reportId, keepInitialCount: false })
        }
        reportType = 'Sales statement'
      } else if (showInventory || showSales || showSummary) {
        // Compound Delivery Statement (summary, delivery, sales and inventory statement) (only happens when count + delivery are right after each other)
        // Linked from https://github.com/fielded/field-supply/blob/develop/web/src/subapps/shipments/complete/PrintContainer.js#L36
        const existingReports = await api.report.findForLocation({locationId, serviceId: service.id, period})
        if (existingReports.length > 0) {
          report = existingReports[0]
        }
        reportType = 'Compound delivery statement'
      }
    } catch (e) {
      console.error(e)
      return this.setState({ error: 'Error finding report' })
    }

    // Get the products
    const productIds = []
    if (report) {
      productIds.push(...Object.keys(report.stock))
    }
    if (shipment) {
      productIds.push(
        ...new Set(Object.keys(shipment.counts).map(batchIdToProductId))
      )
    }
    let products = await api.product.getByIds(productIds)
    if (products && products.length !== productIds.length) {
      products = await api.product.getProductsViaIds(productIds)
    }

    const isPSM = isPsm(config)

    // Lowercase is needed for PSM.
    const getCodeFormat = (code) => {
      return isPSM ? code.toLowerCase() : code
    }
    const productsById = keyBy(products, p => `product:${getCodeFormat(p.code)}`)

    // Get the report fields definitions
    let fieldsById = {}
    try {
      const fields = await api.service.listReportFields(serviceId)
      for (const field of fields) {
        if (field.display !== false || field.displayConfirmation) {
          fieldsById[field.id] = field
        }
      }
    } catch (e) {
      console.error(e)
      return this.setState({ error: 'Error loading fields' })
    }

    let printableDateInfo = removeTime(new Date())
    if (report) {
      printableDateInfo = get(report, 'date.reportingPeriod', '')
    }
    if (shipment) {
      printableDateInfo = shipment.snapshotDates.received || shipment.snapshotDates.arrived || ''
      printableDateInfo = removeTime(printableDateInfo)
      reportType = 'Delivery statement'
    }

    const printableDocumentName = `${reportType} - ${location.name} - ${printableDateInfo}`

    let order = {}
    if (shipment && shipment.orderId) {
      const {orderId} = parse(shipment.orderId)
      order = await api.order.getOrder({orderId})
    }

    this.setState({
      loaded: true,
      error: null,
      service,
      location,
      report,
      productsById,
      order,
      isPSM
    }, triggerPrint(printableDocumentName))
  }

  render () {
    const {
      config,
      shipment,
      showInventory,
      showSales,
      showDelivery,
      showSummary,
      paymentType,
      className,
      user
    } = this.props
    const {
      loaded,
      error,
      report,
      location,
      service,
      productsById,
      order,
      fieldsById,
      isPSM
    } = this.state

    if (error) {
      return (
        <Banner
          inline
          type='warning'
        >
          <h2>Problem Loading Delivery Note</h2>
          <p>{error}</p>
        </Banner>
      )
    }
    if (!loaded) {
      return <Loading loadingText='Loading ...' />
    }

    return (
      <Statements
        config={config}
        location={location}
        service={service}
        report={report}
        shipment={shipment}
        productsById={productsById}
        fieldsById={fieldsById}
        showInventory={report && showInventory}
        showSales={report && showSales}
        showDelivery={shipment && showDelivery && !isPSM}
        showPSMDelivery={shipment && showDelivery && isPSM}
        showSummary={showSummary}
        paymentType={paymentType}
        className={className}
        user={user}
        order={order}
      />
    )
  }
}

export default withUser(withApi(withConfig(StatementsContainer)))
