const get = require('lodash/get')
const { parse } = require('../../../tools/smart-id')
const { stockCountIdToLocationProperties, docToStockCountRecord } = require('../../../tools')
const { getQuantityToOrder } = require('./quantity-to-order')

exports.createAllocations = createAllocations
function createAllocations (
  {programId, locations, products, orders, reports, orderType}
) {
  const ordersByDestinationId = getOrdersByDestinationId(orders)
  return locations
    .map(location => {
      const locationOrders = ordersByDestinationId[location._id]
      const locationReports = listReportsOnProgram(reports, location)
      const allocationProducts = createOrderProducts(
        programId, products, locationReports, locationOrders, orderType
      )
      return {
        location,
        products: allocationProducts,
        reports: locationReports.map(d => ({_id: d._id}))
      }
    })
}

// Returns list of latest report per service on a location
// This is because we get the latest two reports on a service,
// (in case one is missing or on the fence date issues)
exports.listReportsOnProgram = listReportsOnProgram
function listReportsOnProgram (reports, location) {
  const reportOnLocation = reports.filter(doc => {
    const {id: locationId} = stockCountIdToLocationProperties(doc._id)
    return locationId === location._id
  })
  const serviceIdMap = reportOnLocation
    .reduce((acc, report) => {
      const {service: serviceId} = parse(report._id)
      if (!acc[serviceId]) {
        acc[serviceId] = report
        return acc
      }

      if (acc[serviceId].submittedAt < report.submittedAt) {
        acc[serviceId] = report
      }

      return acc
    }, {})
  return Object.values(serviceIdMap)
}

function createOrderProducts (
  programId, products, locationReports, locationOrders, orderType
) {
  return products
    .reduce((acc, product) => {
      const {qto, soh, consumed, comments} = getProductQTO(
        programId, product, locationReports, locationOrders, orderType
      )
      acc[product._id] = {original: qto, soh, consumed, comments}
      return acc
    }, {})
}

// NB: this expects one report per service in locationReports
// TODO: for products that live across services, see if we should be
// combining the field amounts first then running the math.
function getProductQTO (
  programId, product, locationReports, locationOrders = [], orderType = 'main'
) {
  const supplementaryOrder = orderType === 'supplementary'
  const qtoRows = locationReports.map(report => {
    return getQuantityToOrder(programId, report, product)
  })

  const qtoFromReports = qtoRows
    .reduce((acc, row) => {
      acc.qto += row.qto
      acc.soh += row.soh
      acc.consumed += row.consumed
      acc.comments = acc.comments.concat(row.comments)

      return acc
    }, {qto: 0, soh: 0, consumed: 0, comments: []})

  if (supplementaryOrder) {
    return supplementaryQuantityToOrder(qtoFromReports, locationOrders, product)
  }

  const alreadyOrdered = locationOrders
    .reduce((acc, order) => {
      const ordered = get(order, `products.${product._id}.adjusted`, 0)
      acc += ordered
      return acc
    }, 0)
  const qtoMinusOpenOrders = (qtoFromReports.qto - alreadyOrdered)
  const qto = (qtoMinusOpenOrders < 0)
    ? 0
    : qtoMinusOpenOrders

  return Object.assign({}, qtoFromReports, {qto})
}

const supplementaryQuantityToOrder = (qtoFromReports, locationOrders, product) => {
  const qto = locationOrders.reduce((acc, order) => {
    const ordered = get(order, `products.${product._id}.adjusted`, 0)
    const original = get(order, `products.${product._id}.original`, 0)
    const quantityToOrder = original > ordered ? original - ordered : 0
    acc += quantityToOrder
    return acc
  }, 0)

  return Object.assign({}, qtoFromReports, {qto})
}

function getOrdersByDestinationId (orders) {
  return orders
    .reduce((acc, order) => {
      acc[order.destinationId] = acc[order.destinationId] || []
      acc[order.destinationId].push(order)
      return acc
    }, {})
}

exports.translateToStockCountRecord = translateToStockCountRecord
function translateToStockCountRecord (docs, services, fieldDefinitions) {
  services = services.reduce((acc, service) => {
    acc[service.id] = service
    return acc
  }, {})

  return docs.filter(doc => services[doc.serviceId])
    .map(doc => docToStockCountRecord(
      doc,
      services[doc.serviceId],
      {fields: fieldDefinitions[doc.serviceId]})
    )
}
