const cloneDeep = require('lodash/cloneDeep')
const { parse } = require('../../../tools/smart-id')
const { suggestBatches } = require('../../../shipment/tools')
const { byId } = require('../../../tools/by-id')
const { getWithSiblingTotals } = require('../read/generic-utils')
const { distributeAvailableStock } = require('../distribute-stock')
const { applyPackoutToOrders, createAcceptedSnapshots } = require('./create-accepted-snapshots')

exports.acceptOrders = acceptOrders
function acceptOrders (
  {productsList, batchesList, user, snapshots: inputSnapshots, ledger, date}
) {
  const snapshots = squashOrdersByDestinationId(inputSnapshots)
  const productsById = byId(productsList)
  const ledgerForPackOut = getLedgerForPackOut(ledger)
  // distributeAvailableStock doesn't know about sibling products, so here we have to
  // get all products to have the same totals as their siblings, so distributeAvailableStock
  // knows about it.
  // (NB this leans on the fact that QTOs will not live across sibling products in PSM)
  const ledgerForDistribute = getWithSiblingTotals({stock: ledgerForPackOut, productsById})
  const snapshotsWithAvailableQuantities = distributeAvailableStock(snapshots, ledgerForDistribute)
  const snapshotsWithPackOut = applyPackoutToOrders(snapshotsWithAvailableQuantities, ledgerForPackOut, productsList)
  const batchesById = byId(batchesList)
  const acceptedSnapshots = createAcceptedSnapshots({user, snapshots: snapshotsWithPackOut, productsById})
    .filter(snapshot => Object.keys(snapshot.products).length)

  return applyBatchesToSnapshots(
    {ledger, snapshots: acceptedSnapshots, productsById, batchesById, date}
  )
}

exports.applyBatchesToSnapshots = applyBatchesToSnapshots
function applyBatchesToSnapshots (
  {ledger: inputLedger, snapshots, productsById, batchesById, date}
) {
  let ledger = inputLedger
  return snapshots.map(orderSnapshot => {
    const orderProducts = {}
    Object.keys(orderSnapshot.products).forEach(productId => {
      // For now, if there are no batches found in stock, just continue with the requested
      // number from the warehouse user at the product leve.
      // https://github.com/fielded/van-orga/issues/2833#issuecomment-483562215
      // reminder: using `original` for no real reason here, adjusted will be the same
      let {original} = orderSnapshot.products[productId]
      original = toPositiveIntegerOrZero(original)
      orderProducts[productId] = {original, adjusted: original}
      const shoppingList = {[productId]: original}
      const fefoBatches = suggestBatches(
        {ledger, batches: batchesById, shoppingList, products: productsById, expiresAfter: date}
      )

      throwIfNoBatchFound(fefoBatches)

      orderProducts[productId].batches = fefoBatchesToOrderShape(fefoBatches)
      ledger = subtractBatchesFromLedger(ledger, fefoBatches)
    })
    return Object.assign({}, orderSnapshot, {
      products: orderProducts
    })
  })
}

// NB 1: if this is needed elsewhere, move it to a ledger tools directory.
// (has some similarity to tools.getLedgerBalance shipment counts to batches but too confusing to combine)
// NB 2: this doesn't handle commits, if you need to use balances/getCommitsTotal
exports.subtractBatchesFromLedger = subtractBatchesFromLedger
function subtractBatchesFromLedger (inputLedger, batches) {
  const ledger = cloneDeep(inputLedger)
  for (let batchId in batches) {
    const { product } = parse(batchId)
    const productId = `product:${product}`

    const {quantity: batchQuantity} = batches[batchId]

    ledger[productId] = ledger[productId] || {total: 0, batches: {}}
    ledger[productId].batches[batchId] = ledger[productId].batches[batchId] || 0
    ledger[productId].batches[batchId] -= batchQuantity
    if (ledger[productId].batches[batchId] === 0) {
      delete ledger[productId].batches[batchId]
    }
    ledger[productId].total -= batchQuantity
    ledger[productId].availableTotal -= batchQuantity
    if (ledger[productId].total === 0) {
      delete ledger[productId]
    }
  }
  return ledger
}

function fefoBatchesToOrderShape (batches) {
  return Object.keys(batches)
    .reduce((acc, batchId) => {
      acc[batchId] = {original: batches[batchId].quantity, adjusted: batches[batchId].quantity}
      return acc
    }, {})
}

function getLedgerForPackOut (ledger) {
  return Object.keys(ledger)
    .reduce((acc, productId) => {
      acc[productId] = ledger[productId].availableTotal
      return acc
    }, {})
}

function throwIfNoBatchFound (fefoBatches) {
  const noBatchFound = Object.keys(fefoBatches)
    .find(batchId => batchId.includes(':manufacturer:tbd:batchNo:TBD'))

  if (noBatchFound) {
    throw new Error(`Stock not sufficient for product ID ${noBatchFound.replace(':manufacturer:tbd:batchNo:TBD', '')}`)
  }
}

function toPositiveIntegerOrZero (quantity) {
  return Math.floor(
    Math.max(quantity, 0)
  )
}

exports.squashOrdersByDestinationId = squashOrdersByDestinationId
function squashOrdersByDestinationId (snapshots) {
  const snapshotsSorted = createdFirst(snapshots)
  const snapshotsMergedBydestinationId = snapshotsSorted
    .reduce((acc, snapshot) => {
      if (!acc[snapshot.destinationId]) {
        acc[snapshot.destinationId] = cloneDeep(snapshot)
        return acc
      }

      Object.keys(snapshot.products).forEach(productId => {
        if (!acc[snapshot.destinationId].products[productId]) {
          acc[snapshot.destinationId].products[productId] = snapshot.products[productId]
          return
        }
        acc[snapshot.destinationId].products[productId].original += snapshot.products[productId].original
        acc[snapshot.destinationId].products[productId].adjusted += snapshot.products[productId].adjusted

        // in the chance that a bulk upload order came first, which do not have soh nor consumed,
        // take those numbers from the later snapshot
        if (snapshot.products[productId].soh !== undefined && snapshot.products[productId].soh !== undefined) {
          acc[snapshot.destinationId].products[productId].soh = snapshot.products[productId].soh
          acc[snapshot.destinationId].products[productId].consumed = snapshot.products[productId].consumed
        }
      })
      return acc
    }, {})

  return Object.values(snapshotsMergedBydestinationId)
}

function createdFirst (snapshots) {
  return snapshots.sort((a, b) => {
    return (a.createdAt > b.createdAt) ? 1 : ((a.createdAt < b.createdAt) ? -1 : 0)
  })
}
