const assert = require('assert')

class Logger {
  debug (msg, ...args) {
    console.debug(msg, ...args)
  }
  info (msg, ...args) {
    console.log(msg, ...args)
  }
  warn (msg, ...args) {
    console.warn(msg, ...args)
  }
  error (msg, ...args) {
    console.error(msg, ...args)
  }
  static validate (instance) {
    for (const method of ['debug', 'info', 'warn', 'error']) {
      assert.strictEqual(typeof instance[method], 'function', `Expected given logger to implement a "${method}" method.`)
    }
  }
}

module.exports = Logger
