const get = require('lodash/get')

// Generates a forecast object based on an allocation config CouchDB doc
const allocConfigToForecast = allocConfig => {
  const products = Object.values(allocConfig.products)
  // Assumes that in an allocation config doc in which one of the products
  // has forecast method "consumption", all products have that same forecast
  // method.
  // This is currently the case for PSM.
  const forecastMethod = get(products[0], 'forecast.method')
  if (forecastMethod !== 'consumption') {
    throw new Error(`Error: cannot calculate forecast based on the allocation config ${allocConfig._id}, the only supported forecast method is "consumption"`)
  }

  const supplyPlans = { products: {} }
  const dailyDemand = {}
  const safetyStock = {}

  Object.values(allocConfig.products).forEach(product => {
    supplyPlans.products[product.id] = product.supplyPlan
    dailyDemand[product.id] = product.demandPlan.total
    safetyStock[product.id] = supplyPlans.products[product.id].bufferDays * product.demandPlan.total
  })

  return {
    locationId: allocConfig.facilityId,
    effectiveDate: allocConfig.date,
    createdAt: allocConfig.createdAt,
    dailyDemand,
    safetyStock,
    supplyPlans
  }
}

module.exports = allocConfigToForecast
