import React from 'react'

import { StatusCard } from '@fielded/shared-ui'

import { createShipmentStatus } from './helpers'

export default function ShipmentStatusLabel ({
  shipment,
  isReturn
}) {
  const shipmentStatus = createShipmentStatus(shipment, isReturn)

  return (
    <StatusCard.Label statusType={shipmentStatus.type} statusText={shipmentStatus.text} />
  )
}
