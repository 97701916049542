const { construct } = require('./../../tools/construct')

function getByAlias (state, productId, {resolveAlias, date}) {
  const [, aliasValue] = productId.split(':')
  if (!aliasValue) {
    return Promise.reject(
      new Error(
        `Received malformed product id "${productId}", expected "product:<id>"`
      )
    )
  }
  const [aliasName] = Object.keys(resolveAlias)
  return state.dal.product.getByAlias(state, aliasValue, aliasName)
    .then((result) => {
      if (result && result.rows && result.rows.length) {
        return construct(result.rows[0].doc, { now: date })
      }
      throw new Error(`Product "${productId}" could not be found using alias "${aliasName}"`)
    })
}

exports.get = function (state, productId, {resolveAlias = null, date = new Date().toISOString()} = {}) {
  if (!productId) {
    return Promise.reject(new Error('missing productId'))
  }

  if (resolveAlias) {
    return getByAlias(state, productId, {resolveAlias, date})
  }

  return state.dal.product.get(state, productId)
    .then(product => construct(product, { now: date }))
}
