const tools = require('../../tools')
const { getOrder } = require('./get')

// These methods are not used outside /order/api.
// If one's needed, move it to its own file and document it.

exports.getSuborders = getSuborders
async function getSuborders (state, {orderId, suborderId}) {
  return getOrder(state, {orderId, withSnapshots: true})
    .then(order => tools.filterBySuborders(order, suborderId))
}

// Warehouse users need to see accepted orders in the group page,
// but not in the list products nor list locations pages, so they can ration only
// open orders that are not yet accepted. listOrdersForUser takes a withAccepted
// flag that listGroups uses to show accepted orders.
exports.listOrdersForUser = listOrdersForUser
async function listOrdersForUser (
  state,
  programId,
  locationId,
  {withSnapshots = false, withAccepted = true, useSubOrderId, isComplete, relatedMarkets, forceAtSupplier = false, filterInterwarehouseOrders = true} = {},
  useAcceptedStatus = false
) {
  tools.validateParams('listOrdersForUser', {programId})
  // TODO: when we have a multi-virtual location warehouse user,
  // this needs to filter off destination locations
  const fundersFilter = state.user.funders
  const snapshots = await state.dal.order.listOrdersForUser(state, programId, locationId, isComplete, relatedMarkets, forceAtSupplier, filterInterwarehouseOrders)
  const status = tools.getUserStatus(state.user)
  return tools.listOrdersForUser({snapshots, status, withAccepted, fundersFilter, withSnapshots, useAcceptedStatus, useSubOrderId})
}
