const cloneDeep = require('lodash/cloneDeep')
const get = require('lodash/get')
const { translateAliases, convertProductUnits } = require('../../tools/rest-api-utils')
const { getSuborder } = require('../read/get')
const { moveShipmentStatus } = require('./move-shipment-status')
const { getByIds: getProductsById } = require('../../../product/api/read/get-by-ids')
const { time, log } = require('../../tools/logging')
const { SUBORDER_STATUS } = require('./../../constants')

// like its sibling getSuborder, this is only used in the REST API
exports.updateSuborder = async function (
  state,
  {
    suborderId,
    alias = 'one',
    date = new Date().toJSON(),
    productQuantities,
    additionalData = {},
    receiptData = {},
    shipmentNumber,
    logging = false
  }
) {
  const status = receiptData.closedStatus
  if (status === SUBORDER_STATUS.received && !productQuantities) {
    const err = new Error('Cannot transition a suborder into `accepted` state when no `productQuantities` are given on the payload.')
    err.status = 400
    throw err
  }

  if (status && !Object.values(SUBORDER_STATUS).includes(status)) {
    const err = new Error(`Order closed status should be one of ${Object.values(SUBORDER_STATUS)}`)
    err.status = 400
    throw err
  }

  // this weird throw a different error than getSuborder error is so ONE
  // responses are the same as before this refactor.
  const timeOrder = time()
  let suborder = await state.dal.order.getSuborder(state, {suborderId, alias})
  if (!suborder) {
    const err = new Error(
      `No uncompleted suborder with id "${suborderId}" was found. Cannot continue.`
    )
    err.status = 404
    throw err
  }
  log('got order doc before update', logging, timeOrder)

  const timeProducts = time()
  // they can 'fail' orders without productQuantities
  const productIds = Object.keys(productQuantities || {})
  let allProducts = []
  if (alias !== 'none' && productIds.length) {
    allProducts = await getProductsById(state, productIds, { resolveAlias: { [alias]: true } })
  }
  log('got products', logging, timeProducts)

  const updatedProducts = updateProductQuantities(
    suborder.products, productQuantities, alias, allProducts
  )

  const updatedOrderDoc = Object.assign({}, suborder, {
    products: updatedProducts,
    closedStatus: status,
    isComplete: status === SUBORDER_STATUS.received,
    additionalData: Object.assign({}, additionalData, {
      remark: receiptData.remark,
      updatedAt: receiptData.updatedAt,
      shipmentNumber
    })
  })

  await state.dal.order.update(state, updatedOrderDoc)
  const order = await getSuborder(state, {suborderId, alias, logging})

  const timeShipment = time()

  const shipment = ![SUBORDER_STATUS.packed, SUBORDER_STATUS.received].includes(order.closedStatus)
    ? null
    : await moveShipmentStatus(state, suborderId, status)
  log('marked shipment', logging, timeShipment)

  // we just return the ID not the whole shipment
  return {order, shipment: shipment && shipment.id}
}

function updateProductQuantities (currentProducts, productQuantities = {}, alias, allProducts) {
  // we'll be mutating & returning the current products so lets clone it:
  const updatedProducts = cloneDeep(currentProducts)

  // First we zero out all adjusted values on the existing doc as there is
  // no certainty whether a product or batch might have been included in
  // the update payload
  Object.keys(updatedProducts).forEach(productId => {
    if (updatedProducts[productId].batches) {
      Object.values(updatedProducts[productId].batches)
        .forEach((quantities) => {
          quantities.adjusted = 0
        })
    } else {
      updatedProducts[productId].adjusted = 0
    }
  })

  const productQuantitiesAliases = translateAliases(productQuantities, alias, allProducts)

  const updatedProductSet = alias === 'none'
    ? productQuantitiesAliases
    : convertProductUnits(
      productQuantitiesAliases, 'reporting', allProducts
    )

  // Next we insert the values in the update into the doc, using the
  // `adjusted` field for each batch level element. In case the batch was not
  // part of the order before, it's `original` value will be 0.
  Object.keys(updatedProductSet).forEach((productId) => {
    if (!updatedProducts[productId]) {
      updatedProducts[productId] = {batches: {}}
    }
    Object.keys(updatedProductSet[productId].batches)
      .forEach((batchId) => {
        updatedProducts[productId].batches[batchId] = {
          original: get(updatedProducts, `${productId}.batches.${batchId}.original`, 0),
          adjusted: updatedProductSet[productId].batches[batchId].amount
        }
      })
  })

  return updatedProducts
}
