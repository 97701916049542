const PGAdapter = require('../../common/pg-adapter')
const ProductBaseAdapter = require('./product-base-pg-adapter')
const ProductPresentationAdapter = require('./product-presentation-pg-adapter')
const getProductSkuName = require('../tools/get-productsku-name')

const PRODUCT_SKU_TABLE_NAME = 'avocado.data_productsku'
const PRODUCT_SKU_COLUMNS = [
  'created_at',
  'updated_at',
  'id',
  'sku_id',
  'old_sku_id',
  'order_sku',
  'market_id',
  'name',
  'display_name',
  'inventory_sensitivity',
  'display_order',
  'phase',
  'available',
  'ready',
  'promoted',
  'ppmv',
  'ppmv_core',
  'supplier',
  'visibility',
  'markup',
  'vat',
  'presentation_id',
  'eligible_for_od',
  'eligible_for_pays',
  'segments',
  '_deleted',
  'essential_medicines'
]

const searchColumns = [
  'display_name',
  'old_sku_id'
]

class ProductSKUAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    const opts = {
      strictColumns: false,
      addUuid: false,
      searchColumns
    }
    super(
      pgConnection,
      PRODUCT_SKU_TABLE_NAME,
      username,
      PRODUCT_SKU_COLUMNS,
      'id',
      {},
      logger,
      opts
    )
    this.logger = logger
    this.pgConnection = pgConnection
    this.username = username
  }

  async create (data) {
    // We need to build the display name for the product before creating new product sku row

    const displayName = await this.generateDisplayName(data)

    const rowToCreate = {...data, display_name: displayName}

    const row = await super.create(rowToCreate)

    return row
  }

  async generateDisplayName (data) {
    const { presentation_id: presentationId } = data

    const presentationAdapter = new ProductPresentationAdapter(this.logger, this.pgConnection, this.username)

    const {
      product_id: baseId,
      presentation_qty: presentationQty,
      presentation_unit: presentationUnit,
      presentation_type: presentationType
    } = await presentationAdapter.getOne(presentationId)

    const baseAdapter = new ProductBaseAdapter(this.logger, this.pgConnection, this.username)

    const {
      name,
      variety,
      dosage_qty: dosageQty,
      dosage_unit: dosageUnit
    } = await baseAdapter.getOne(baseId)

    const displayName = getProductSkuName({
      presentationQty,
      presentationUnit,
      presentationType,
      name,
      variety,
      dosageQty,
      dosageUnit
    })

    return displayName
  }

  async uploadProductAvailability (data) {
    if (!this.pgConnection) {
      throw new Error('you can only upload from admin tool')
    }
    const newData = Object.values(data)
    newData.forEach(async element => {
      const {old_sku_id: sku, available} = element
      const query = `UPDATE avocado.data_productsku SET available = $1 WHERE old_sku_id = $2`
      const value = [available, sku]

      try {
        const result = await this.pgConnection.query(query, value)
        return result.rows
      } catch (err) {
        throw err
      }
    }
    )
  }
}

module.exports = ProductSKUAdapter
