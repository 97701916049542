import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Form, Page } from '@fielded/shared-ui'
import EntryViewWrapper from '../common/EntryViewWrapper'

const Activate = ({
  config,
  onSuccess,
  onSubmit,
  inProcess,
  hasIntercom,
  error
}) => {
  return (
    <EntryViewWrapper config={config} showIntercom>
      {!onSuccess && (
        <Fragment>
          <Page.Panel.Section>
            <div className='login__panel-title'>
              Choose a password
            </div>
          </Page.Panel.Section>

          {error && (
            <Page.Panel.Message type='danger' cta={false}>
              {error}
            </Page.Panel.Message>
          )}

          <Page.Panel.Section>
            <Form
              onSubmit={onSubmit}
              component='form'
            >
              <Form.Row>
                <Form.Field
                  fieldName='password'
                  labelText='Enter your new password'
                >
                  <Form.Field.Text
                    type='password'
                    disabled={inProcess}
                    required
                    autoCapitalize='off'
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>

              <Form.Row>
                <Form.Field
                  fieldName='confirmPassword'
                  labelText='Re-type password'
                >
                  <Form.Field.Text
                    type='password'
                    disabled={inProcess}
                    required
                    autoCapitalize='off'
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>

              <Form.Actions>
                <NavLink
                  to='/login'
                  className='vs-u-link'
                >
                  Already registered? Log in
                </NavLink>
                <Form.Button
                  alignOpposite
                  size='medium'
                  disabled={inProcess}
                  loading={inProcess}
                >
                  Set password
                </Form.Button>
              </Form.Actions>
            </Form>
          </Page.Panel.Section>
        </Fragment>
      )}
    </EntryViewWrapper>
  )
}

Activate.propTypes = {
  config: PropTypes.object.isRequired,
  loginInProgress: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
}

Activate.defaultProps = {
  loginInProgress: false,
  errorMessage: null
}

export default Activate
