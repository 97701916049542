import React, { Component } from 'react'
import { connect } from 'react-redux'
import withOverview from './withOverview'
import Programs from './Programs'
import { Redirect } from 'react-router-dom'

import { withUser } from '../../../common/AuthenticationProvider'
import { buildLocationsUrl } from '../app/routes-urls'
import ProgramErrorBanner from './ProgramsError'

class ProgramsContainer extends Component {
  render () {
    const {
      locationId,
      date,
      config,
      user,
      showCountSyncBanner,
      showShipmentSyncBanner
    } = this.props
    let { overviews } = this.props

    if (overviews.length === 0) {
      return (
        <ProgramErrorBanner />
      )
    } else if (overviews.length < 2) {
      // Redirect directly to location selection when we don't have multiple programs
      return <Redirect to={buildLocationsUrl({locationId, date, programId: overviews[0].id})} />
    }

    return (
      <Programs
        config={config}
        programs={overviews}
        locationId={locationId}
        date={date}
        user={user}
        showCountSyncBanner={showCountSyncBanner}
        showShipmentSyncBanner={showShipmentSyncBanner}
      />
    )
  }
}

const mapStateToProps = (
  { overview: { overviews }, root: { config } },
  { match: { params: { locationId, date } } }
) => {
  return {
    locationId,
    date,
    overviews,
    config
  }
}

export default withUser(connect(mapStateToProps)(withOverview(ProgramsContainer)))
