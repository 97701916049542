import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Donut from './Donut'

const ReportingProgress = ({
  programName,
  period,
  progress,
  progressText,
  children,
  type
}) => {
  const percentage = progress ? progress.complete / progress.total * 100 : undefined

  return (
    <div className='reporting-progress'>
      <header className='reporting-progress__header'>
        <h2 className='reporting-progress__title'>{programName}</h2>
        {period && <h3 className='reporting-progress__subtitle'>{period}</h3>}
      </header>
      <div className='reporting-progress__content'>
        <div className='reporting-progress__donut-container'>
          {percentage != null && (
            <Donut value={percentage} />
          )}
        </div>
        <div className='reporting-progress__progress'>
          {progress && (
            <Fragment>
              <span className='reporting-progress__complete'>
                {progress.complete}
              </span> of <span className='reporting-progress__total'>
                {progress.total}
              </span>
              <span className='reporting-progress__text-block'>
                {type === 'services' ? 'services' : 'locations'}
              </span>
              <span className='reporting-progress__text-block'>
                complete
              </span>
            </Fragment>
          )}
          {progressText}
        </div>
        { children && (
          <div className='reporting-progress__action'>
            { children }
          </div>
        ) }
      </div>
    </div>
  )
}

ReportingProgress.propTypes = {
  /**
   * Program name. Will be used for the heading.
   */
  programName: PropTypes.string,
  /**
   * Numbers of completed and total reports for program/service.
   * Used for percentage donut and showing the numbers.
   */
  progress: PropTypes.shape({
    complete: PropTypes.number,
    total: PropTypes.number
  }),
  /**
   * Optionally pass a text to show after (or instead of) the progress numbers.
   */
  progressText: PropTypes.string,
  /**
   * Actions that should be available, e.g. Download Reports button or Go to Program link.
   * If left out, no actions wrapper will be rendered.
   */
  children: PropTypes.node
}

ReportingProgress.defaultProps = {
  programName: 'Stock reporting',
  progress: undefined,
  progressText: undefined,
  children: undefined
}

export default ReportingProgress
