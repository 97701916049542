import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import flowRight from 'lodash/flowRight'
import get from 'lodash/get'

import { Loading } from '@fielded/shared-ui'
import { getShipmentBatches } from '@fielded/fs-api/lib/shipment/utils/utils'

import { withApi } from '../../../../../common/ApiProvider'
import { withUser } from '../../../../../common/AuthenticationProvider'
import { isShipmentComplete } from '../../../common/utils'
import { fetchMasterData, selectMasterData } from '../../../root/master-data-reducer'
import FacilityDeliveryDetail from './FacilityDeliveryDetail'

const FacilityDeliveryDetailContainer = ({ api, user, history }) => {
  const dispatch = useDispatch()
  const masterData = useSelector(selectMasterData)
  const shipments = useSelector(state => state.shipments)

  const [otp, setOtp] = useState()
  const [loading, setLoading] = useState(true)
  const { snapshotId } = useParams()

  const delivery = shipments[snapshotId]
  const hasReceivedMasterData = get(masterData, 'hasReceivedMasterData', false)

  const initialize = async () => {
    try {
      const shipmentId = delivery.id
      const otp = await api.shipment.otp.rest.getPassword(shipmentId)
      const otpCode = otp ? otp.password : ''

      if (!hasReceivedMasterData) {
        await dispatch(fetchMasterData())
      }

      setOtp(otpCode)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  if (loading) {
    return <Loading loadingText='Loading delivery details ...' />
  }

  if (!masterData || !Object.keys(masterData).length || !delivery) {
    return <Redirect to='/shipments/facility/deliveries' />
  }

  const productsById = get(masterData, 'products.byId')
  const isDeliveryComplete = isShipmentComplete(delivery, user)
  const batches = getShipmentBatches(delivery, productsById)

  return (
    <FacilityDeliveryDetail
      otp={otp}
      history={history}
      delivery={delivery}
      products={batches}
      isDeliveryComplete={isDeliveryComplete}
    />
  )
}

const withHOCs = flowRight(
  withApi,
  withUser
)

export default withHOCs(FacilityDeliveryDetailContainer)
