const api = require('./api')
const { EntityApi } = require('../common')
const { wrapEntityApi } = require('../utils/wrap-api')

const rawMethods = {
  save: api.save,
  get: api.get,
  list: api.list,
  bulkImport: api.bulkImport
}

const RoutePouchAdapter = require('./data-access/route-pouch-adapter')
const isRouteEditable = require('./tools/is-route-editable')
const RoutePGDataAdapter = require('./data-access/route-pg-data-adapter')
const toRelationalModel = require('./tools/to-relational-model')

class RoutesApi extends EntityApi {
  constructor (state, agaveAdapter, logger, pgConnection) {
    const { user, routesDB } = state
    const adapter = new RoutePouchAdapter(user, routesDB)
    super(adapter)

    this.agaveAdapter = agaveAdapter

    if (pgConnection) {
      this.pgDataAdapter = new RoutePGDataAdapter(pgConnection, user.name, logger)
    }

    // TODO: remove this when all raw methods have been removed
    const apiMethods = wrapEntityApi(rawMethods, state)
    Object.assign(this, apiMethods)
  }

  listDeliveryDates (service, refDate) {
    return this.adapter.listDeliveryDates(service, refDate)
  }

  listCountDates (service, refDate) {
    return this.adapter.listCountDates(service, refDate)
  }

  bulkImportLocationRoutes (routes) {
    return this.agaveAdapter.post('location_routes/bulk-save', routes)
  }

  async syncToRDS (routeId) {
    let couchDBRoute
    try {
      couchDBRoute = await this.get(routeId)
    } catch (e) {
      console.error('Error fetching route from couchDB', e)
      throw e
    }
    // The only possible operation is to create new routes,
    // no to update or delete them.
    // The reason not to update is to keep the a clean
    // history of changes, route assignments change but
    // routes do not.
    // Keeping routes that have been deleted in couchDB
    // can also be useful to make sense of route assignment
    // history
    const rdsRoute = toRelationalModel(couchDBRoute)
    return this.pgDataAdapter.upsert(rdsRoute)
  }
}
Object.assign(RoutesApi.prototype, {isRouteEditable})

module.exports = rawMethods
module.exports.RoutesApi = RoutesApi
