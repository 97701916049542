module.exports = updatedCounts

const VectorClock = require('vectorclock')
const cloneDeep = require('lodash/cloneDeep')
const get = require('lodash/get')
const { parse, idify } = require('../../tools/smart-id')
const { DIRECT_ORDER_TYPES } = require('../../allocation/config')
const { SHIPMENT_PRODUCT_MARKETS } = require('../constants')

const addCheckedField = (counts, isChecked) =>
  Object.keys(counts).forEach(batchId => {
    counts[batchId].checked = isChecked
  })

const addSkuMarket = (counts, status) => {
  const STATUS_TO_MARKET = {
    cancelled: SHIPMENT_PRODUCT_MARKETS.origin_sku,
    'pre-advice': SHIPMENT_PRODUCT_MARKETS.origin_sku,
    'in-process': SHIPMENT_PRODUCT_MARKETS.origin_sku,
    new: SHIPMENT_PRODUCT_MARKETS.origin_sku,
    packed: SHIPMENT_PRODUCT_MARKETS.origin_sku,
    sent: SHIPMENT_PRODUCT_MARKETS.origin_sku,
    arrived: SHIPMENT_PRODUCT_MARKETS.destination_sku,
    received: SHIPMENT_PRODUCT_MARKETS.destination_sku
  }

  const skuMarket = STATUS_TO_MARKET[status]

  if (!skuMarket) {
    console.log(`Invalid shipment snapshot status ${status}`)
    return
  }

  Object.keys(counts).forEach(batchId => {
    counts[batchId].skuMarket = skuMarket
  })
}

function updatedCounts (snapshot, changeDocs) {
  if (!snapshot) {
    return {}
  }
  const removedCount = []
  const snapshotCounts = cloneDeep(snapshot.counts) /* istanbul ignore next */ || {}

  // removes duplicate batches from shipment count. so if there's a duplicate batch with numbers batchNo:OPV2 and batchNo:opv2, the batch will be recorded as batchNo:OPV2 and the value of the last stock batch will be used as the stock count. See: https://github.com/fielded/van-orga/issues/2585
  let batchesMap = new Map()
  for (const key in snapshotCounts) {
    batchesMap.set(sanitiseBatchId(key), snapshotCounts[key])
  }
  const updated = {}
  for (const [key, value] of batchesMap.entries()) {
    if (!value.paymentType) {
      value.paymentType = DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL
    }
    updated[key] = value
  }

  // Set 'recevied' batches as checked, for adjustments workflow
  // see fielded/van-orga#4492
  addCheckedField(updated, snapshot.status === 'received')

  addSkuMarket(updated, snapshot.status)

  // Push all changes as separate objects that we can sort
  // by vector and timestamp
  let changes = []
  changeDocs.forEach(doc => {
    Object.keys(doc.changes).forEach(batchId => {
      const change = doc.changes[batchId]
      changes.push(Object.assign({
        batchId: batchId,
        clock: doc.clock,
        updatedAt: doc.updatedAt,
        createdBy: get(doc, 'createdBy.user', '')
      }, change))
    })
  })

  // Sort by vector and timestamp
  changes = changes.sort((a, b) => {
    // first try to sort by vector clocks
    // need to guard for no clock since that sort ahead of any clock
    const vec = VectorClock.ascSort(a.clock ? a : {}, b.clock ? b : {})
    // if that gave a result, return
    if (vec !== 0) {
      return vec
    }

    if (a.timestamp < b.timestamp) {
      return -1
    }

    if (a.timestamp > b.timestamp) {
      return 1
    }

    return 0
  })

  // Finally, create the updated object
  changes.forEach(change => {
    const batchId = change.batchId
    updated[batchId] = updated[batchId] || { quantity: 0 }

    // Add meta for displaying changes:
    if (change.quantity) {
      const adjustment = updated[batchId].adjustment || {}
      updated[batchId].adjustment = adjustment
      // Display latest user/updated:
      adjustment.user = change.createdBy
      adjustment.date = change.updatedAt
      // Display quantity from snapshot:
      if (!('original' in adjustment)) {
        adjustment.original = updated[batchId].quantity
      }
    }

    // Apply changes on counts:
    if (change.removed) { // current batch to be removed
      updated[batchId].removed = true
      removedCount.push(batchId)
    } else {
      if (updated[batchId].removed) {
        return // batch already marked to be removed
      }
      updated[batchId].quantity = updated[batchId].quantity + change.quantity
      if ('checked' in change) {
        updated[batchId].checked = change.checked
      }
      if ('paymentType' in change) {
        updated[batchId].paymentType = change.paymentType
      }
    }
  })

  removedCount.forEach(batchId => delete updated[batchId])
  return updated
}

// TODO. This function is similar to sanitiseBatchId function in md-api/batch/tools/index.js. When the APIs are merged into one repo (i.e store-api and md-api), we'll remove this one here.
function sanitiseBatchId (batchId) {
  const batchIdParsed = parse(batchId)
  // safe guard against ID that doesn't include a batch no
  if (batchIdParsed.batchNo) {
    batchIdParsed.batchNo = batchIdParsed.batchNo.split(/\s+/).join('').toUpperCase() // removes unwanted spaces and transforms it to uppercase-letters
    return idify(batchIdParsed, 'product:manufacturer:batchNo')
  } else { return batchId }
}
