import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { Text } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

import { formatDate } from '../../utils'

const TO_DELIVERY_NAVIGATION = ['shipment-new', 'shipment-sent', 'provisional-order']
const TO_WALLET_NAVIGATION = ['new-invoice', 'invoice-converted-to-pp', 'invoice-is-due-tomorrow']
const TO_PRODUCT_NAVIGATION = ['price-update']

const NotificationItem = ({
  notification
}) => {
  const isRead = notification.readAt
  const dateCreated = formatDate(notification.createdAt, 'shortWithTimeInverted')

  const linkCopy = (code) => {
    if (TO_DELIVERY_NAVIGATION.includes(code)) {
      return 'Go to delivery'
    }

    if (TO_WALLET_NAVIGATION.includes(code)) {
      return 'Go to Wallet'
    }

    if (TO_PRODUCT_NAVIGATION.includes(code)) {
      return 'Some products have new prices. Check them out!'
    }

    return 'See details'
  }

  return (
    <div className={classnames(
      'vs-notification-item',
      {'vs-notification-item--read': isRead}
    )}>
      <Text bold>{notification.notificationText}</Text>
      {notification.action &&
        <Link
          to={notification.action}
          className={classnames(
            'vs-u-link',
            'vs-u-link--bold',
            'vs-u-link--small',
            'vs-u-link--with-icon',
            {'vs-u-link--brand': !isRead},
            {'vs-u-link--dark': isRead}
          )}
        >
          {linkCopy(notification.code)} <Arrow direction='right' className='vs-u-link__icon' />
        </Link>
      }
      <Text
        className='vs-notification-item__date'
        uppercase
        size='xsmall'
      >
        {dateCreated}
      </Text>
    </div>
  )
}

export default NotificationItem
