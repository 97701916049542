const toDocIdProperties = require('./to-doc-id-properties')
const toLocationProperties = require('./to-location-properties')
const { suggestBatches } = require('./suggest-batches')
const getLocationRank = require('./get-location-rank')
const docsToSentReceivedSurveys = require('./docs-to-surveys-record')
const { getShipmentTypes } = require('./shipment-types')
const sumShipmentsByProduct = require('./sum-shipments-by-product')
const offline = require('./offline')
const findInsufficientStock = require('./find-insufficient-stock')
const PLANNING_TYPES = require('./planning-types')
const getProductsForFollowUp = require('./get-products-for-follow-up')
const COLLECTION_REASONS = require('./collection-reasons')

// not all functions are called through index.js in tests so istanbul complains
/* istanbul ignore next */
module.exports = {
  toDocIdProperties,
  toLocationProperties,
  suggestBatches,
  getLocationRank,
  docsToSentReceivedSurveys,
  getShipmentTypes,
  sumShipmentsByProduct,
  findInsufficientStock,
  getProductsForFollowUp,
  OFFLINE_ERROR: offline.OFFLINE_ERROR,
  PLANNING_TYPES,
  COLLECTION_REASONS
}
