const { createNodesAndEdges } = require('./tools/create-nodes-and-edges')
const { Graph } = require('./tools/graph')
const { createGraphApi } = require('./create-graph-api')

/** Create the graph api from a list of locations
 */
const buildGraph = (locations) => {
  const [nodes, edges] = createNodesAndEdges(locations)
  const graph = new Graph(nodes, edges)
  const graphApi = createGraphApi(graph)
  return graphApi
}

module.exports = buildGraph
