import { isLocationBelowLevel, CREATE_IN_FS_LEVEL } from '@fielded/fs-api/lib/location/tools'
import { isSelfSignup, hasOwnDatabase } from './index'

export const isLeadUserWithDB = (location) => {
  return !!location && isLocationBelowLevel(location, CREATE_IN_FS_LEVEL)
}

export const isLeadUser = (user, location) => {
  if (!location) {
    return isLeadBeforeSync(user)
  }

  if (!(location.additionalData && location.additionalData.level)) {
    return false
  }

  return isLocationBelowLevel(location, CREATE_IN_FS_LEVEL)
}

export const isLeadBeforeSync = (user) => {
  return isSelfSignup(user) && !hasOwnDatabase(user)
}
