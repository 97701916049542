const crypto = require('crypto')

exports.sha512 = async function (str) {
  if (typeof window !== 'undefined') {
    if (!window.crypto || !window.crypto.subtle) {
      throw new Error('Could not find a global crypto implementation, cannot hash.')
    }
    const encoder = new window.TextEncoder()
    const data = encoder.encode(str)
    const hash = await window.crypto.subtle.digest('SHA-512', data)
    const hashArray = Array.from(new Uint8Array(hash))
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  }
  return crypto.createHash('sha512').update(str).digest('hex')
}
