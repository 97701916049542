export const findIndexById = (list, id) => list.findIndex(item => item._id === id)

export const productsWithUpdatedQuantity = (products, productId, newQuantity) => {
  const updatedProducts = [...products]
  const index = findIndexById(updatedProducts, productId)
  updatedProducts.splice(index, 1, {
    ...updatedProducts[index],
    quantity: newQuantity
  })
  return updatedProducts
}

export const productsWithUpdatedPaymentType = (products, productId, newPaymentType) => {
  const updatedProducts = [...products]
  const index = findIndexById(updatedProducts, productId)
  updatedProducts.splice(index, 1, {
    ...updatedProducts[index],
    paymentType: newPaymentType
  })
  return updatedProducts
}

export const productsWithToggledSelected = (products, productId) => {
  const updatedProducts = [...products]
  const index = findIndexById(updatedProducts, productId)
  updatedProducts.splice(index, 1, {
    ...updatedProducts[index],
    selected: !updatedProducts[index].selected
  })
  return updatedProducts
}
