import React, { Component } from 'react'
import PropTypes from 'prop-types'

import withConfig from '../van-shared/hoc/withConfig'
import ForgotPasswordApi from './forgot-password-api'

import ForgotPassword from './ForgotPassword'

class ForgotPasswordContainer extends Component {
  static propTypes = {
    resetLoginStatus: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    config: PropTypes.object.isRequired
  }

  static defaultProps = {
    error: null
  }

  state = {
    inProcess: false,
    onSuccess: false
  }

  componentDidMount () {
    const { config } = this.props
    this.api = new ForgotPasswordApi({agaveApiUrl: config.agaveApiUrl})
  }

   handleSubmit = async (fields) => {
     try {
       this.setState({inProcess: true})
       await this.api.submit(fields)
       this.setState({ onSuccess: true })
     } catch (err) {
       console.error(err)
       this.setState({error: err.message, inProcess: false})
     }
   }

   render () {
     const { inProcess, onSuccess, error } = this.state
     return (
       <ForgotPassword
         error={error}
         inProcess={inProcess}
         onSuccess={onSuccess}
         config={this.props.config}
         onSubmit={this.handleSubmit}
       />
     )
   }
}

export default withConfig(ForgotPasswordContainer)
