module.exports = normaliseUser

const normaliseRoles = require('./normalise-roles')
const normalisePrograms = require('./normalise-programs')

function createProfile (user) {
  // An old PSM script added fullName on user.data
  let profile = {}
  if (user.email && user.email !== '') {
    profile.email = user.email
  }

  if (user.phone && user.phone !== '') {
    profile.phone = user.phone
  }

  profile.fullName = user.fullName || (user.data && user.data['Full Name']) || ''
  return profile
}

function normaliseUser (user) {
  const normalizedUser = {
    _id: user._id,
    _rev: user._rev,
    derived_key: user.derived_key,
    iterations: user.iterations,
    location: user.location || [],
    fullName: user.fullName,
    name: user.name,
    password_scheme: user.password_scheme,
    profile: createProfile(user),
    programs: normalisePrograms(user),
    acceptedTCs: user.acceptedTCs,
    roles: user.roles ? normaliseRoles(user.roles) : [],
    salt: user.salt
  }

  if (user.funders) {
    normalizedUser.funders = user.funders
  }

  if (user.routes) {
    normalizedUser.routes = user.routes
  }

  if (user.createdAt) {
    normalizedUser.createdAt = user.createdAt
  }

  if (user.createdBy) {
    normalizedUser.createdBy = user.createdBy
  }

  if (user.updatedAt) {
    normalizedUser.updatedAt = user.updatedAt
  }

  if (user.updatedBy) {
    normalizedUser.updatedBy = user.updatedBy
  }

  if (user.djangoAuthToken) {
    normalizedUser.djangoAuthToken = user.djangoAuthToken
  }

  // external signup user uuid
  if (user.signupUid) {
    normalizedUser.signupUid = user.signupUid
  }

  if (user.userDBSync) {
    normalizedUser.userDBSync = user.userDBSync
  }
  if (user.loggedInAsAdmin) {
    normalizedUser.loggedInAsAdmin = user.loggedInAsAdmin
  }

  return normalizedUser
}
