import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import get from 'lodash/get'

import { BackButton, Button, Loading, OptionsGroup, Page } from '@fielded/shared-ui'

import { Arrow } from '@fielded/shared-ui/src/icons'
import ProductItemQuantity from './ProductItemQuantity'
import { DIRECT_ORDER_TYPES, DIRECT_ORDER_TYPES_HUMANIZED } from '@fielded/shared-ui/src/utils/subscription-type-constants'
import { MEMBERSHIPS } from '@fielded/fs-api/lib/location/tools/constants'

const PlanningQuantities = ({
  title,
  subTitle,
  products,
  availableStock,
  history,
  nextButtonText,
  onClickSave,
  onGoBack,
  onQuantityChange,
  setPaymentType,
  navigateTo,
  showPlanningNumbers,
  config,
  allocationConfig,
  location,
  shipmentType
}) => {
  const mustShowPaymentOptions = setPaymentType !== undefined
  const membership = get(location, 'membership')
  const [subsTypesToShow, setSubsTypesToShow] = useState({})
  const [subsTypesDefaults, setSubsTypesDefaults] = useState({})
  const getSubscriptionType = (productId) => {
    return get(allocationConfig, `products[${productId}].forecast.type`, DIRECT_ORDER_TYPES.NOT_SUBSCRIBED)
  }
  const getSubsTypesToShow = (productId) => {
    const alreadySubscribedAs = getSubscriptionType(productId)
    if (alreadySubscribedAs !== DIRECT_ORDER_TYPES.NOT_SUBSCRIBED) {
      return [alreadySubscribedAs]
    } else {
      const result = []
      const productInfo = products.find(p => p._id === productId)
      if (productInfo.eligibleForPAYS && membership !== MEMBERSHIPS.CLASSIC_PAYG_ONLY) {
        result.push(DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL)
      }

      if (productInfo.eligibleForOD) {
        result.push(DIRECT_ORDER_TYPES.PAY_ON_DELIVERY)
      }
      if (shipmentType === DIRECT_ORDER_TYPES.IMMEDIATE_PURCHASE) {
        return [DIRECT_ORDER_TYPES.IMMEDIATE_PURCHASE]
      }
      return result
    }
  }

  const getOptionsLabels = (subsTypesToShow) => {
    return subsTypesToShow.map(subsType => {
      return { value: subsType, label: DIRECT_ORDER_TYPES_HUMANIZED[subsType] }
    })
  }

  const getDefaultSubsTypeValue = (subsTypesToShow) => {
    if (subsTypesToShow.includes(DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL)) {
      return DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL
    } else {
      return subsTypesToShow[0]
    }
  }

  const hasAvailableQuantities = !!availableStock
  const hasQuantityError = hasAvailableQuantities && products.some(p => p.quantity > get(availableStock, p._id, 0))

  if (mustShowPaymentOptions) {
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
      const subsTypesToShow = {}
      const defaultValues = {}
      products.map(product => {
        subsTypesToShow[product._id] = getSubsTypesToShow(product._id)
        defaultValues[product._id] = getDefaultSubsTypeValue(subsTypesToShow[product._id])
        // Ensure current state reflects default option group value
        setPaymentType(product._id, defaultValues[product._id])
      })

      setSubsTypesToShow(subsTypesToShow)
      setSubsTypesDefaults(defaultValues)
      setLoaded(true)
    }, [])

    if (!loaded) {
      return <Loading />
    }
  }

  return (
    <Page>
      <Page.Header
        title={title}
        backButton={<BackButton onClick={onGoBack}>Go back</BackButton>}
      >
        {subTitle}
      </Page.Header>
      <Page.Panel spread className='divider--quantities-page'>
        <Page.Panel.Section>
          <div className='new-shipment-form'>
            {products.map(product => {
              const availableQuantity = get(availableStock, product._id, 0)
              const hasWarning = hasAvailableQuantities && product.quantity > availableQuantity
              const defaultValue = subsTypesDefaults[product._id]
              return (
                <Fragment key={product._id}>
                  <ProductItemQuantity
                    product={product}
                    availableQuantity={availableQuantity}
                    hasAvailableQuantity={hasAvailableQuantities}
                    onQuantityChange={onQuantityChange}
                    hasWarning={hasWarning}
                  />
                  {mustShowPaymentOptions && (
                    <OptionsGroup
                      label='Delivery type:'
                      options={getOptionsLabels(subsTypesToShow[product._id])}
                      defaultValue={defaultValue}
                      onValueChange={(paymentType) => setPaymentType(product._id, paymentType)}
                      inRows={false}
                      spread
                    />
                  )}
                </Fragment>)
            })}
          </div>
        </Page.Panel.Section>
      </Page.Panel>
      <Page.Footer>
        {(navigateTo && !onClickSave) &&
          <Button
            component={Link}
            to={navigateTo}
            colorVariant='brand'
            fill='full'
            size='large'
            icon={<Arrow direction='right' />}
            iconSide='right'
            disabled={hasQuantityError}
          >
            Next
          </Button>
        }
        {(onClickSave && !navigateTo) &&
          <Button
            onClick={onClickSave}
            fill='full'
            colorVariant='brand'
            size='large'
            disabled={hasQuantityError}
          >
            {nextButtonText || 'Save and Close'}
          </Button>
        }
      </Page.Footer>
    </Page>
  )
}

export default PlanningQuantities
