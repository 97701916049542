import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Page, BackButton, PrintTemplate, SignatureDisplay, PriceDisplay, TotalAmount } from '@fielded/shared-ui'
import { triggerPrint } from '@fielded/shared-ui/src/components/Page'
import { getCurrencySymbol } from '@fielded/shared-ui/src/components/PriceDisplay'
import { pluralize, formatDate } from '../../../../van-shared/utils'

const Table = ({ rows, country, currencySymbol, serviceId, location, isRetained }) => {
  let baseLedgerUrl
  if (serviceId && location) {
    baseLedgerUrl = `/reporting/ledger/${serviceId}/${location._id}`
  }
  const fields = [
    {
      key: 'code',
      header: 'Code',
      renderCell: ({code}) => <span>{code}</span>
    },
    {
      key: 'name',
      header: 'Product',
      renderCell: ({name, code}) => {
        return baseLedgerUrl ? <Link to={`${baseLedgerUrl}/product:${code}`}>{ name }</Link> : <span>{name}</span>
      }
    },
    {
      key: 'quantity',
      header: isRetained ? 'Retained' : 'Returned',
      renderCell: ({quantity}) => <span>{quantity}</span>
    },
    {
      key: 'price',
      header: `Unit Price (${currencySymbol})`,
      renderCell: ({price}) => <PriceDisplay value={price} country={country} />
    },
    {
      key: 'change',
      header: `Total (${currencySymbol})`,
      renderCell: ({total}) => <PriceDisplay value={total} country={country} />
    }
  ]

  const jsxHeaders = fields.map(({key, header}) => (
    <th
      className={`returns-note-table__th returns-note-table__th--${key}`}
      key={key}
    >
      {header}
    </th>
  ))
  const jsxRows = rows.map(data => (
    <tr
      className='returns-note-table__tr returns-note-table__tr--body'
      key={data.code}
    >
      {fields.map(({key, renderCell}) => (
        <td
          className={`returns-note-table__td returns-note-table__td--${key}`}
          key={key}
        >
          {renderCell(data)}
        </td>
      ))}
    </tr>
  ))

  return (
    <table className='returns-note-table'>
      <thead>
        <tr className='returns-note-table__tr returns-note-table__tr--head'>
          {jsxHeaders}
        </tr>
      </thead>
      <tbody>
        {jsxRows}
      </tbody>
    </table>
  )
}

export default class extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dateToShow: props.returnedAt || props.createdAt
    }
  }

  componentDidMount () {
    const { name } = this.props
    const { dateToShow } = this.state

    const date = formatDate(dateToShow, 'long')
    const printableDocumentName = `Return note - ${name} - ${date}`

    this.setState({
      date: date
    }, triggerPrint(printableDocumentName))
  }

  render () {
    const {
      history,
      country,
      state,
      rows,
      retainedRows,
      signedBy,
      signature,
      totalValue,
      totalRetainedValue,
      location,
      serviceId,
      name,
      address,
      isCR,
      userCanViewSalesStats,
      shipmentNo,
      config
    } = this.props

    const { dateToShow } = this.state

    const date = formatDate(dateToShow, 'long')
    const currencySymbol = getCurrencySymbol({country})
    const numItems = rows.length
    const table = <Table country={country} rows={rows} currencySymbol={currencySymbol} serviceId={serviceId} location={location} />

    let retainedTable
    if (userCanViewSalesStats && isCR && retainedRows && retainedRows.length > 0) {
      retainedTable = <Table country={country} rows={retainedRows} currencySymbol={currencySymbol} serviceId={serviceId} location={location} isRetained />
    }
    const appName = config.name

    return (
      <div className='returns-note'>
        <Page className='page-print'>
          <Page.Header
            backButton={<BackButton history={history} />}
          />
          <PrintTemplate>
            <PrintTemplate.Header
              title={isCR ? 'Collection Note' : 'Returns Note'}
              config={config}
            />
            <PrintTemplate.Box
              left={
                isCR ? (
                  <div className='delivery-statement-info'>
                    Your collection note itemizes all {appName} products collected from your pharmacy on <strong>{date}</strong>.
                    The value of products collected is <strong>{currencySymbol}<PriceDisplay country={country} value={totalValue} /></strong>.
                  </div>
                ) : (
                  <div className='delivery-statement-info'>
                    Your returns note itemizes all {appName} products returned from your pharmacy on <strong>{date}</strong>.
                    The value of products returned is <strong>{currencySymbol}<PriceDisplay country={country} value={totalValue} /></strong>.
                  </div>
                )
              }
              right={
                <div className='delivery-statement-info'>
                  <div>
                    <strong className='delivery-statement-info__header'>
                      {name}
                    </strong>
                  </div>
                  {address}
                </div>
              }
            />
            <PrintTemplate.Section>
              Delivery No. {shipmentNo}
            </PrintTemplate.Section>
            <PrintTemplate.Section
              subtitle={isCR ? `Returned products` : ''}
            >
              The following <strong>{numItems} {pluralize('product', numItems)}</strong> were returned on <strong>{date}</strong>
            </PrintTemplate.Section>
            <PrintTemplate.Section withOverflow>
              {table}
            </PrintTemplate.Section>
            {retainedTable && (
              <Fragment>
                <PrintTemplate.Section
                  withTopMargin
                  subtitle={isCR ? `Retained products` : ''}
                >
                  The following <strong>{retainedRows.length} {pluralize('product', retainedRows.length)}</strong> were refused collection. Ownership of these units you retained will be transferred to you and will be added to your invoice. Please note they will no longer be eligible for return to {appName}.
                </PrintTemplate.Section>
                <PrintTemplate.Section withOverflow>
                  {retainedTable}
                  <TotalAmount
                    value={totalRetainedValue}
                    countryId={country}
                    label='Sub-total to pay'
                  />
                </PrintTemplate.Section>

              </Fragment>
            )}
            <PrintTemplate.Section>
              {signature && <SignatureDisplay signature={signature} name={signedBy} />}
            </PrintTemplate.Section>
            <PrintTemplate.Footer location={state} />
          </PrintTemplate>
        </Page>
      </div>
    )
  }
}
