const FormData = require('form-data')
const { isUuid } = require('../tools')
const getCRMFetch = require('./get-crm-fetch')
const { territoryByName } = require('../../service/tools/territory-lookup')

const FRESHSALES_URL = 'https://field-353846499169273300.myfreshworks.com/crm/sales/'
const TEST_TERRITORY_ID = 30000001714
const TEST_SALES_OWNER_ID = 30000055160

const getError = async (message, code, response) => {
  let errorMessage = message
  if (response) {
    errorMessage = errorMessage + ` (${response.status}-${response.statusText}).`
    try {
      const { errors } = await response.json()
      errorMessage = errorMessage + ' ' + errors.message[0]
    } catch (e) {}
  }
  const error = new Error(`Freshsales API: ${errorMessage}`)
  error.status = code
  return error
}

// TODO: write a README about this adapter
class FreshsalesAdapter {
  constructor (freshsalesApiConfig = {}, fetch, logger) {
    this.logger = logger

    this.fetch = undefined
    if (!freshsalesApiConfig.apiKey) {
      // this.fetch remains undefined, this will trigger a mock (empty) response
      // from all adapter functions.
      logger.info(`Missing Freshsales apiKey, the Freshsales API won't be able to connect to the CRM`)
      return
    }

    if (typeof fetch !== 'function') {
      throw new Error(
        'Freshsales API usage error: expected fetch as an argument'
      )
    }

    this.fetch = getCRMFetch(freshsalesApiConfig.apiKey, FRESHSALES_URL, fetch)
    this.isTestMode = freshsalesApiConfig.testMode
  }

  // entity: 'accounts' or 'contacts'
  // Queries Freshsales API (using pagination) until
  // we get all records updated since the given
  // `since` date.
  async listAll (entity, since, sortBy = 'updated_at', onlyDeleted) {
    if (!this.fetch) {
      const error = await getError('missing Freshsales apiKey', 401)
      throw error
    }

    const fetchParams = {
      headers: { 'Content-Type': 'application/json' }
    }

    let allResults = []
    let nextPage = 1
    // totalPages would be updated after first request, what matters
    // for the first request is that `nextPage <= totalPages`
    let totalPages = 1
    let checkpointReached = false
    while (nextPage <= totalPages && !checkpointReached) {
      const response = await this.fetch(
        entity,
        { fetchParams, entity, page: nextPage, sortBy, onlyDeleted },
        'list',
        this.isTestMode ? 'test' : undefined
      )

      if (!response.ok) {
        const errorMsg = `failed to get ${onlyDeleted ? 'deleted ' : ''}${entity} sorted by ${sortBy}${since ? ` since ${since}` : ''}`
        const error = await getError(errorMsg, response.status, response)
        throw error
      }

      const json = await response.json()
      totalPages = json.meta.total_pages
      nextPage += 1

      const items = entity === 'accounts' ? json.sales_accounts : json[entity]
      let updatedItems = items
      // `listAll` is used in the lambda that syncs from
      // Freshsales to postgres.
      // `since` is the `updated_at` timestamp of the
      // last item synced to postgres.
      // If we have several records with the same `updated_at` (maybe
      // due to an import?) it can happen that the lambda fails after
      // syncing the first one of those records but before syncing the
      // last one. To cover for that edge case `listAll` returns all
      // records updated at or after the given `since` date.
      if (since) {
        updatedItems = items.filter(item =>
          new Date(item.updated_at).toISOString() >= since
        )
      }

      allResults = allResults.concat(updatedItems)

      if (updatedItems.length < items.length) {
        checkpointReached = true
      }
    }

    // Test locations are all coming from "Staging Test" territory,
    // we need to assign them a "real" territory id based on their
    // state.
    if (this.isTestMode) {
      return allResults
        .map(item => {
          const { state } = item
          const territory = territoryByName(state)
          // ignore the ones where the territory is not clear,
          // i.e. where the state does not correspond to any territory.
          // ex: state = 'Other'
          if (!territory) { return }
          item.territory_id = territory.freshsalesId
          return item
        })
        .filter(exists => exists)
    }

    return allResults
  }

  // Accepts a freshsalesId and entity type (account or contact)
  // and returns true if that entity is in the test territory,
  // false otherwise
  async checkTestTerritory (freshsalesId, entity) {
    if (!this.fetch) {
      const error = await getError('missing Freshsales apiKey', 401)
      throw error
    }

    const fetchParams = {
      headers: { 'Content-Type': 'application/json' }
    }
    const response = await this.fetch(
      entity,
      { fetchParams, freshsalesId },
      'get'
    )

    if (!response.ok) {
      const error = await getError(`failed to get ${entity.slice(0, -1)} with id ${freshsalesId}`, response.status, response)
      throw error
    }

    const json = await response.json()
    const item = entity === 'accounts' ? json.sales_account : json.contact
    if (item.territory_id === TEST_TERRITORY_ID) {
      return true
    }
    return false
  }

  async findFreshsalesIds (uuid) {
    if (!this.fetch) {
      const error = await getError('missing Freshsales apiKey', 401)
      throw error
    }

    const fetchParams = {
      headers: { 'Content-Type': 'application/json' }
    }
    const response = await this.fetch(
      'lookup',
      { fetchParams, uuid },
      'accountByUuid'
    )

    if (!response.ok) {
      const error = await getError(`failed to get account and contact by uuid ${uuid}`, response.status, response)
      throw error
    }

    const { sales_accounts } = await response.json() // eslint-disable-line camelcase
    const accounts = sales_accounts.sales_accounts

    if (!accounts.length) {
      return {}
    }

    const { id, contact_ids, territory_id } = accounts[0] // eslint-disable-line camelcase

    // While in testing, do not return freshsalesIds of accounts / contacts outside the test territory
    if (this.isTestMode && territory_id !== TEST_TERRITORY_ID) { // eslint-disable-line camelcase
      this.logger.info(`Cannot access the account / contact with uuid ${uuid} while in test mode. The account / contact exists but it is not assigned to the test territory.`)
      return {}
    }

    if (contact_ids.length > 1) {
      this.logger.warn(`Could not find Freshsales contact with external_id ${uuid}. More than one contact associated to the account`)
      return {}
    }

    return {
      contact: contact_ids[0],
      account: id
    }
  }

  async createAccount (data) {
    if (!this.fetch) {
      const error = await getError('missing Freshsales apiKey', 401)
      throw error
    }

    // While in staging and dev: assign the account to the test territory
    // and the test sales owner. Otherwise the territory and sales owner
    // will be assigned automatically based on the state.
    if (this.isTestMode) {
      data.territory_id = TEST_TERRITORY_ID
      data.owner_id = TEST_SALES_OWNER_ID
    }

    const fetchParams = {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ sales_account: data })
    }
    const response = await this.fetch('accounts', { fetchParams }, 'create')

    if (!response.ok) {
      const error = await getError(`failed to create account`, response.status, response)
      throw error
    }

    return response.json()
  }

  async createContact (data) {
    if (!this.fetch) {
      const error = await getError('missing Freshsales apiKey', 401)
      throw error
    }

    // While in staging and dev: assign the contact to the test territory
    // and the test sales owner. Otherwise the territory and sales owner
    // will be assigned automatically based on the state.
    if (this.isTestMode) {
      data.territory_id = TEST_TERRITORY_ID
      data.owner_id = TEST_SALES_OWNER_ID
    }

    const fetchParams = {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ contact: data })
    }
    const response = await this.fetch('contacts', { fetchParams }, 'create')

    if (!response.ok) {
      const error = await getError(`failed to create contact`, response.status, response)
      throw error
    }

    return response.json()
  }

  async updateAccount (uuidOrFreshsalesId, data) {
    if (!this.fetch) {
      const error = await getError('missing Freshsales apiKey', 401)
      throw error
    }

    let freshsalesId = uuidOrFreshsalesId
    if (isUuid(uuidOrFreshsalesId)) {
      const ids = await this.findFreshsalesIds(uuidOrFreshsalesId)
      freshsalesId = ids.account
      if (!freshsalesId) {
        const error = await getError(
          `failed to update account with uuid ${uuidOrFreshsalesId}, the uuid does not correspond to a Freshsales account. This is likely due to duplication (ex: the lead submitted the sign up form twice with different emails or the lead used the sign up form for an account that had already been created in Freshsales)`,
          404)
        throw error
      }
    } else if (this.isTestMode) {
      // We need to check that the account is in the test territory.
      // This is not necessary when we have a uuid, `findFreshsalesIds`
      // already takes care of it.
      const isInTestTerritory = await this.checkTestTerritory(freshsalesId, 'accounts')
      if (!isInTestTerritory) {
        const error = await getError(
          `failed to update account with id ${freshsalesId} while in test mode. The account exists but it is not assigned to the test territory`,
          403)
        throw error
      }
    }
    // If the territory_id or owner_id is going to change,
    // change it back to the test territory
    if (this.isTestMode) {
      if (data.territory_id) {
        data.territory_id = TEST_TERRITORY_ID
      }
      if (data.owner_id) {
        data.owner_id = TEST_SALES_OWNER_ID
      }
    }
    const fetchParams = {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ sales_account: data })
    }
    const response = await this.fetch(
      'accounts',
      { fetchParams, freshsalesId },
      'update'
    )

    if (!response.ok) {
      const error = await getError(`failed to update account with id ${freshsalesId}`, response.status, response)
      throw error
    }

    // For now the response does not contain the territory_id so we don't need
    // to edit it while in test mode.
    return response.json()
  }

  async updateContact (uuidOrFreshsalesId, data) {
    if (!this.fetch) {
      const error = await getError('missing Freshsales apiKey', 401)
      throw error
    }

    let freshsalesId = uuidOrFreshsalesId
    if (isUuid(uuidOrFreshsalesId)) {
      const ids = await this.findFreshsalesIds(uuidOrFreshsalesId)
      freshsalesId = ids.contact
    } else if (this.isTestMode) {
      // We need to check that the contact is in the test territory.
      // This is not necessary when we have a uuid, `findFreshsalesIds`
      // already takes care of it.
      const isInTestTerritory = await this.checkTestTerritory(freshsalesId, 'contacts')
      if (!isInTestTerritory) {
        const error = await getError(
          `failed to update contact with id ${freshsalesId} while in test mode. The contact exists but it is not assigned to the test territory`,
          403)
        throw error
      }
    }
    // If the territory_id or owner_id is going to change,
    // change it back to the test territory
    if (this.isTestMode) {
      if (data.territory_id) {
        data.territory_id = TEST_TERRITORY_ID
      }
      if (data.owner_id) {
        data.owner_id = TEST_SALES_OWNER_ID
      }
    }
    const fetchParams = {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ contact: data })
    }
    const response = await this.fetch(
      'contacts',
      { fetchParams, freshsalesId },
      'update'
    )

    if (!response.ok) {
      const error = await getError(`failed to update contact with id ${freshsalesId}`, response.status, response)
      throw error
    }

    // For now the response does not contain the territory_id so we don't need
    // to edit it while in test mode.
    return response.json()
  }

  async uploadFiles (uuidOrFreshsalesId, file) {
    if (!this.fetch) {
      const error = await getError('missing Freshsales apiKey', 401)
      throw error
    }

    let freshsalesId = uuidOrFreshsalesId
    if (isUuid(uuidOrFreshsalesId)) {
      const ids = await this.findFreshsalesIds(uuidOrFreshsalesId)
      freshsalesId = ids.account
    } else if (this.isTestMode) {
      // We need to check that the account is in the test territory.
      // This is not necessary when we have a uuid, `findFreshsalesIds`
      // already takes care of it.
      const isInTestTerritory = await this.checkTestTerritory(freshsalesId, 'accounts')
      if (!isInTestTerritory) {
        const error = await getError(
          `failed to upload files to account with id ${freshsalesId} while in test mode. The account exists but it is not assigned to the test territory`,
          403)
        throw error
      }
    }
    const { originalname, buffer } = file
    const rawFile = Buffer.from(buffer)
    const form = new FormData()
    form.append('targetable_id', Number(freshsalesId))
    form.append('targetable_type', 'SalesAccount')
    form.append('file', rawFile, originalname)

    const fetchParams = { body: form }

    const response = await this.fetch('documents', { fetchParams }, 'create')

    if (!response.ok) {
      const error = await getError(`failed to upload files to account with id ${freshsalesId}`, response.status, response)
      throw error
    }

    return response.json()
  }

  getUpdatedSince (entity, since) {
    return this.listAll(entity, since)
  }

  getCreatedSince (entity, since) {
    return this.listAll(entity, since, 'created_at')
  }

  getDeletedSince (entity, since) {
    return this.listAll(entity, since, 'updated_at', true)
  }

  getFreshsalesUrl () {
    return FRESHSALES_URL
  }
}

module.exports = FreshsalesAdapter
