import React, { Component } from 'react'

import { Page } from '@fielded/shared-ui'

import { getNavigationItem } from '../../../common/utils/navigationInfo'
import { getSubNavigationItems } from './navigation-items'
import ShipmentsImportWizard from './ShipmentsImportWizard'

class ShipmentsImport extends Component {
  render () {
    const { config } = this.props
    return <Page>
      <Page.Header
        breadcrumbItems={[getNavigationItem({item: 'shipments', config, includePath: false})]}
        subNavigationItems={getSubNavigationItems({showImportLink: true, activeTab: 'import'})}
        title={'New Shipment'}
      />
      <Page.Intro title='Import shipments data' />
      <ShipmentsImportWizard {...this.props} />
    </Page>
  }
}

export default ShipmentsImport
