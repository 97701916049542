
class LedgerRestAdapter {
  constructor ({agaveAdapter, logger}) {
    this.adapter = agaveAdapter
    this.logger = logger
  }

  async getOne (id, opts = {includeShipments: false, includeOrders: false}) {
    const { includeShipments, includeOrders } = opts
    return this.adapter.get('ledger/get_by_location_id', {locationId: id, includeShipments, includeOrders})
  }

  async getLocationSkuHistory (locationId, productId) {
    return this.adapter.get('ledger/get-location-sku-history', {locationId, productId})
  }

  async getSkuReservations (locationId, productId) {
    return this.adapter.get('ledger/get-sku-reservations', {locationId, productId})
  }
}

module.exports = { LedgerRestAdapter }
