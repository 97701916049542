import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Gem, Star } from '../../icons'
import { MEMBERSHIPS } from '../../utils/membership-type-constants'
const { CLASSIC, CLASSIC_PAYG_ONLY, BASIC } = MEMBERSHIPS

const MembershipTypeLabel = ({
  membershipType,
  isOnAccentColor,
  iconOnly,
  size
}) => {
  const membershipIcon = {
    [BASIC]: <Star />,
    [CLASSIC]: <Gem />,
    [CLASSIC_PAYG_ONLY]: <Gem />
  }

  const membershipText = {
    [BASIC]: 'basic',
    [CLASSIC]: 'classic',
    [CLASSIC_PAYG_ONLY]: 'classic'
  }

  return (
    <span className={classnames(
      'vs-membership-type-label',
      {'vs-membership-type-label--on-accent': isOnAccentColor},
      {'vs-membership-type-label--basic': membershipType === BASIC},
      {'vs-membership-type-label--classic': (membershipType === CLASSIC || membershipType === CLASSIC_PAYG_ONLY)}
    )}>
      <span className={classnames(
        'vs-membership-type-label__icon',
        {'vs-membership-type-label__icon--large': size === 'large'}
      )}>
        {membershipIcon[membershipType]}
      </span>
      {!iconOnly && (
        <span
          className='vs-membership-type-label__text'
        >
          { membershipText[membershipType] }
        </span>
      )}
    </span>
  )
}

MembershipTypeLabel.propTypes = {
  /**
   * One of supported membership types
   */
  membershipType: PropTypes.oneOf([BASIC, CLASSIC, CLASSIC_PAYG_ONLY]),
  /**
   * Whether or not the label shows up on same color as assigned to the membership type as primary
   */
  isOnAccentColor: PropTypes.bool,
  /**
   * Size
   */
  size: PropTypes.oneOf(['default', 'large'])
}

MembershipTypeLabel.defaultProps = {
  membershipType: CLASSIC,
  size: 'default'
}

export default MembershipTypeLabel
