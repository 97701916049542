// sheet describing all of these fields: https://docs.google.com/spreadsheets/d/18S7TJ_uG8_gV46HM_6KL-zNANS5ZId8PM2qOmpzOD1E/edit#gid=836982126
// This is to know what to pull from pipeline CRM, what it maps to in avocado,
// and what that lines up to with couch.
const fields = [
  {
    id: 'active',
    docProperty: 'additionalData.active'
  },
  {
    id: 'is_local',
    docProperty: 'additionalData.isLocal'
  },
  {
    id: 'is_central',
    docProperty: 'additionalData.isCentral'
  },
  {
    id: 'supply_index',
    docProperty: 'additionalData.supplyIndex'
  },
  {
    id: 'address',
    docProperty: 'additionalData.physicalAddress'
  },
  {
    id: 'alt_phone_number',
    docProperty: 'additionalData.altPhoneNumber',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'city',
    docProperty: 'additionalData.city',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'classification',
    docProperty: 'additionalData.classification',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'code',
    docProperty: 'additionalData.code',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'company',
    docProperty: 'additionalData.company',
    showInFS: true
  },
  {
    id: 'country'
    // TODO: location.country in to-couch-transform
  },
  {
    id: 'email',
    docProperty: 'contacts.program:shelflife.email'
  },
  {
    id: 'fsid',
    docProperty: '_id'
  },
  {
    id: 'lat'
    // transform in to-couch-transform
  },
  {
    id: 'supply_chain_level',
    docProperty: 'level'
  },
  {
    id: 'level',
    docProperty: 'additionalData.level',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'location_type',
    docProperty: 'additionalData.locationType',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'long'
    // transform in to-couch-transform
  },
  {
    id: 'market'
  },
  {
    // TODO: this is a calculated field
    // based on status + existing subscriptions,
    // still need to coordinate details with ops.
    id: 'mobilized',
    docProperty: 'additionalData.mobilized'
  },
  {
    id: 'mobilized_date',
    docProperty: 'additionalData.mobilizedDate'
    // This is shown in FS but we have a custom component for it
    // showInFS: true
  },
  {
    id: 'name_of_pharmacy',
    docProperty: 'name'
    // transform in to-couch-transform also write to fullName
  },
  {
    id: 'notes',
    docProperty: 'additionalData.notes',
    showInFS: true,
    disabledInFS: true
    // transform in to-couch-transform also write to fullName
  },
  {
    id: 'owners_phone_number',
    docProperty: 'contacts.program:shelflife.phone'
  },
  {
    id: 'owners_name',
    docProperty: 'contacts.program:shelflife.name'
  },
  {
    id: 'payment_terms',
    docProperty: 'additionalData.paymentTerms',
    showInFS: true,
    fieldType: 'number',
    disabledInFS: true
  },
  {
    id: 'premise_license_number',
    docProperty: 'additionalData.premiseLicenseNumber',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'registration_number',
    docProperty: 'additionalData.registrationNumber',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'route'
    // No 'to-couch-transform'
    // but from-couch will read from: programs.shelflife.services{territoryService}[latestperiod.funder]
  },
  {
    // TODO: this is a rule based on country
    id: 'service_fee',
    docProperty: 'additionalData.serviceFee',
    showInFS: true,
    fieldType: 'number',
    disabledInFS: true
  },
  {
    id: 'service_fee_deposit',
    docProperty: 'additionalData.serviceFeeDeposit',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'service_free_weeks',
    docProperty: 'additionalData.serviceFreeWeeks',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'service_start_date',
    docProperty: 'additionalData.serviceStartDate',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'desired_membership_type',
    docProperty: 'additionalData.desiredMembershipType',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'state'
    // TODO: location.state from lookup?
  },
  {
    id: 'orders_status',
    docProperty: 'additionalData.ordersStatus',
    disabledInFS: true
  },
  {
    id: 'superintending_pharmacist',
    docProperty: 'additionalData.superintendingPharmacist',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'supply_weeks'
  },
  // TODO: this will come from route
  {
    id: 'visit_day',
    docProperty: 'additionalData.visitDay'
  },
  {
    id: 'uuid',
    docProperty: 'additionalData.uuid',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'zone',
    docProperty: 'additionalData.zone',
    showInFS: true,
    disabledInFS: true
  },
  {
    id: 'supplied_by',
    docProperty: 'relationships.suppliedBy'
  },
  {
    id: 'supplies',
    docProperty: 'relationships.supplies'
  },
  {
    id: 'membership_type',
    docProperty: 'membership'
  },
  {
    id: 'desired_membership_type',
    docProperty: 'additionalData.desiredMembershipType'
  }
]

module.exports = fields
