import React from 'react'
import PropTypes from 'prop-types'

import { CheckmarkCircle } from '@fielded/shared-ui/src/icons'
import { SHIPMENT_STATUS, SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS } from '@fielded/fs-api/lib/shipment/constants'

const LMDStatusTag = ({
  status
}) => {
  const STATUS_TO_ICON = {
    [SHIPMENT_STATUS.NEW]: <CheckmarkCircle />,
    [SHIPMENT_STATUS.PACKED]: <CheckmarkCircle />,
    [SHIPMENT_STATUS.SENT]: <CheckmarkCircle />,
    [SHIPMENT_STATUS.ARRIVED]: <CheckmarkCircle />,
    [SHIPMENT_STATUS.RECEIVED]: <CheckmarkCircle />,
    [SHIPMENT_STATUS.DELAYED]: <CheckmarkCircle />
  }

  if (!status) {
    return null
  }

  return (
    <span
      className={`last-mile-deliveries__status-tag last-mile-deliveries__status-tag--${status}`}
    >
      {STATUS_TO_ICON[status]}
      {SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS[status]}
    </span>
  )
}

LMDStatusTag.propTypes = {
  status: PropTypes.string.isRequired
}

export default LMDStatusTag
