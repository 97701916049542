const get = require('lodash/get')
const max = require('lodash/max')
const { deriveLocationId, isNonRetailUser, isRetailUser } = require('../user/tools')
const { EntityApi } = require('../common')
const COLLECTION_REASONS = require('../shipment/tools/collection-reasons')

class SalesStatsAPI extends EntityApi {
  constructor (state, pgConnection, agaveAdapter) {
    // When used client-side - calls agave
    // When used server-side - calls PGConnection
    if (pgConnection) {
      // Should be a PGAdapter, but it's not used here
      super(pgConnection)
      this.pgConnection = pgConnection
    } else if (agaveAdapter) {
      super(agaveAdapter)
      this.agaveAdapter = agaveAdapter
    } else {
      super({})
    }

    this.user = state.user
    this.forecastDB = state.forecastDB
    this.mainApi = state.mainApi
  }

  async getList (params) {
    if (!this.pgConnection) {
      return clientSideList(params, this.agaveAdapter, this.user, this.mainApi, this.forecastDB)
    }

    return serverSideList(params, this.pgConnection, this.user, this.mainApi)
  }
}

const getReportSales = (reverseReports, productId) => {
  const sales = reverseReports.find(report => {
    const sold = get(report, `stock.${productId}.fields.field:standard-consumed.amount`)
    return sold && sold > 0
  })

  if (!sales) {
    return {}
  }

  return {
    skipping: 'recent-sales',
    lastSold: sales.submittedAt,
    collection: false
  }
}

const hasOOC = (fullReport, productId) => {
  if (!fullReport) {
    return false
  }

  const opening = get(fullReport, `stock.${productId}.fields.field:standard-opening-balance.amount`, 0)
  const closing = get(fullReport, `stock.${productId}.fields.field:standard-physical-count.amount`, 0)

  if (closing <= opening) {
    return false
  }

  return {
    opening,
    closing,
    skipping: 'ooc',
    collection: false
  }
}

async function excludeLatestAndOOC (mainApi, salesStats, locationId) {
  // Its important this happens client side,
  // it's a check for any "client data not yet synced to avocado"
  // that's why we check localOnly
  const { lastUpdate, products = {} } = salesStats

  if (Object.keys(products).length === 0) {
    return salesStats
  }

  let reports = await mainApi.report.find({
    locationIds: [locationId],
    startDate: lastUpdate,
    entityOptions: { addFields: true },
    queryOptions: { localOnly: true }
  })

  // Reports come back in a latest-first sort order,
  // so we wanna find the latest full count
  const fullReport = reports.find(r => !r.partialCount)
  // Collect client-side exceptions (sales since last update, OOC)
  // so we can refer to it later
  const exclusions = {}

  Object.keys(products).forEach(productId => {
    // This check is only needed on not-sold products:
    if (products[productId].collection === COLLECTION_REASONS.NOT_SOLD) {
      const reportSales = getReportSales(reports, productId)
      if (reportSales.collection === false) {
        exclusions[productId] = reportSales
      }
      Object.assign(products[productId], reportSales)
    }

    const ooc = hasOOC(fullReport, productId)
    if (ooc) {
      exclusions[productId] = ooc
      Object.assign(salesStats.products[productId], ooc)
    }
  })

  salesStats.exclusions = exclusions
  return salesStats
}

async function clientSideList (params, agaveAdapter, user, mainApi, integratedDataDB) {
  const { locationId, sku, applyLatestAndOOC } = params

  let salesStats
  // 1: try getting the local cache, this initially only works for drivers
  if (!sku) {
    const docId = `sales-stats:${locationId}`
    // state.integratedDataDB
    salesStats = await integratedDataDB.get(docId).catch(e => {
      if (e.status === 404) {
        return null
      }

      throw e
    })
  }

  // 2: if local cache not available, hit endpoint
  if (!salesStats) {
    // Calls agave (later on, reads offline doc if params are right)
    // and merges with sales stats from the stock counts after the last updated date
    salesStats = await agaveAdapter.list('/sales-stats', params)
  }

  if (!applyLatestAndOOC) {
    return salesStats
  }

  return excludeLatestAndOOC(mainApi, salesStats, locationId)
}

async function serverSideList (params, pgConnection, user, mainApi) {
  const locationId = get(params, 'filter.locationId') || get(params, 'locationId')
  const applyLatestAndOOC = get(params, 'filter.applyLatestAndOOC') || get(params, 'applyLatestAndOOC')

  // Allow planners/drivers and server-side users to check all locations
  const allowAllLocations = isNonRetailUser(user) || get(user, 'location.id') === 'national'
  if (allowAllLocations) {
    // All good, see above
  } else if (isRetailUser(user)) {
    const userLocationId = deriveLocationId(user)
    if (userLocationId !== locationId) {
      const err = new Error('No permissions for this location')
      err.status = 403
      throw err
    }
  } else {
    const err = new Error('Not authenticated for this call')
    err.status = 401
    throw err
  }

  const productsForCollection = await getCRStats(pgConnection, locationId)

  const items = {}
  productsForCollection.forEach(item => {
    items[item.productId] = item
    delete item.productId
  })

  const lastUpdate = max(productsForCollection.map(p => p.lastUpdate))

  const pgData = {
    lastUpdate,
    products: items
  }

  if (!applyLatestAndOOC) {
    return pgData
  }

  return excludeLatestAndOOC(mainApi, pgData, locationId)
}

const collectRedistributeQuery = `
  select product, max_level, last_count_date, last_sale_date, remark, safety_stock from summaries.collect_and_redistribute where id = $1
`

/*
 * Note: the constants from the summaries.collect_and_redistribute table
 * are different than the ones we use on the frontend:
 * https://github.com/fielded/field-supply/blob/develop/pipeline/script/summaries.inventory/11_collect_and_redistribute_v2.sql#L202
 * https://github.com/fielded/field-supply/blob/develop/pipeline/script/summaries.inventory/11_collect_and_redistribute_v2.sql#L189
 */
const REMARKS = {
  NOT_SOLD: 'not selling',
  ABOVE_MAX: 'overstocked'
}

async function getCRStats (pgConnection, locationId) {
  const { rows = [] } = await pgConnection.query(collectRedistributeQuery, [locationId])

  return rows.map(row => {
    const { product, max_level, last_count_date, last_sale_date, remark, safety_stock } = row // eslint-disable-line camelcase

    let collection = false
    // See comment above about difference between table and front end
    if (remark === REMARKS.NOT_SOLD) {
      collection = COLLECTION_REASONS.NOT_SOLD
    } else if (remark === REMARKS.ABOVE_MAX) {
      collection = COLLECTION_REASONS.ABOVE_MAX
    } else if (remark) {
      // Lets throw here to at least give some heads up if something changed
      throw new Error(`Unknown collection remark: ${remark}`)
    }

    return {
      productId: `product:${product}`,
      lastSold: last_sale_date,
      collection,
      lastUpdate: last_count_date,
      max: max_level,
      min: parseInt(safety_stock, 10)
    }
  })
}

module.exports = SalesStatsAPI
module.exports.collectRedistributeQuery = collectRedistributeQuery
