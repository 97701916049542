const get = require('lodash/get')
const { MEMBERSHIPS } = require('./constants')

module.exports.isLocationSubscriptionBased = (location) => {
  const membership = get(location, 'membership')
  const country = get(location, 'location.country')
  const allowedCountry = ['ng', 'ke']

  // We only treat classic users as subscription-based, All other users will have the Basic workflow
  return [MEMBERSHIPS.CLASSIC].includes(membership) || ([MEMBERSHIPS.CLASSIC_PAYG_ONLY].includes(membership) && !allowedCountry.includes(country))
}
