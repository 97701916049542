const DBNames = {
  Users: '_users',
  GlobalChanges: '_global_changes',
  Replicator: '_replicator',
  IntegratedData: 'integrated-data',
  Documents: 'documents',
  StockCounts: 'stock-count',
  Shipments: 'van-shipments',
  Orders: 'order',
  Messages: 'retailer-messages',
  UserDBSyncCheckpoints: 'user-db-sync-checkpoints',
  Ledgers: 'ledger-balances'
}

module.exports = {
  DBNames,
  mainDBNames: [
    DBNames.IntegratedData,
    DBNames.Documents,
    DBNames.StockCounts,
    DBNames.Shipments,
    DBNames.Orders,
    DBNames.Ledgers

  ]
}
