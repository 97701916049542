const { v4 } = require('uuid')
const PGAdapter = require('../common/pg-adapter')
const {
  installmentPaymentPlanQuery,
  createPaymentPlanQuery,
  linkInvoicePaymentPlanQuery,
  clearDeletedInvoicePlan
} = require('./queries/payment-plan-queries')
const { getPositionalArgs } = require('../tools/sql-tools')
const PAYMENT_PLAN_TABLE_NAME = 'avocado.data_paymentplan'
const PAYMENT_PLAN_COLUMNS = [
  'id',
  'start_date',
  'installment_amount',
  'past_due',
  'service_fee',
  'waive_service_fee',
  'frequency',
  'currency',
  'term',
  'is_active',
  'created_at',
  'updated_at',
  'location_id',
  'is_paid',
  'quickbooks_account_balance',
  'quickbooks_account_id',
  'company_code',
  'quickbooks_journal_entry_id',
  'quickbooks_payment_id'
]
class PaymentPlansAdapter extends PGAdapter {
  constructor (pgConnection, username) {
    super(
      pgConnection,
      PAYMENT_PLAN_TABLE_NAME,
      username,
      PAYMENT_PLAN_COLUMNS,
      'id'
    )
  }

  async createPaymentPlan (paymentPlan, qboTxnId) {
    paymentPlan.id = v4()
    const { query, values } = createPaymentPlanQuery(paymentPlan)
    const {
      query: linkQuery,
      values: linkValues
    } = linkInvoicePaymentPlanQuery(paymentPlan.id, paymentPlan.company_code, qboTxnId)
    const client = await this.pgConnection.connect()
    await client.query('BEGIN')
    const results = await client.query(query, values)
    await client.query(clearDeletedInvoicePlan, [qboTxnId, paymentPlan.company_code])
    await client.query(linkQuery, linkValues)
    await client.query('COMMIT')
    await client.end()
    const { rows } = results
    return rows[0]
  }

  async getInstalmentPaymentPlan ({txnId, companyCode}) {
    const { rows } = await this.pgConnection.query(installmentPaymentPlanQuery(), [txnId, companyCode])
    return rows[0] // check status with returned object
  }

  async getPaymentPlansInBulk (locationIds) {
    const dollarSigns = getPositionalArgs(locationIds)
    const props = [...locationIds]
    const query = `
      SELECT "pp".*, "instalments"
        FROM "avocado"."data_paymentplan" "pp"
                 LEFT JOIN LATERAL (
            SELECT jsonb_agg("ppi".*) as "instalments"
            FROM "avocado"."data_paymentplaninstallment" "ppi"
            WHERE "ppi"."payment_plan_id" = "pp"."id"
      ) "instalments" on true
      WHERE "pp"."location_id" IN (${dollarSigns})
    `
    const { rows } = await this.pgConnection.query(query, props)
    return rows
  }
}

module.exports = PaymentPlansAdapter
