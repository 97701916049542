/*
 * Some PSM programs have all products without opening balance
 * marked as "disabled" by default
 */
const getDefaultDisabled = (serviceId) => {
  if (serviceId.startsWith('program:hiv-aids')) {
    return true
  }

  if (serviceId.startsWith('program:tb')) {
    return true
  }

  if (serviceId.startsWith('program:essential-medicines')) {
    return true
  }

  if (serviceId.startsWith('program:shelflife')) {
    return undefined
  }

  return false
}

module.exports = getDefaultDisabled
