const { SHIPMENT_ADVANCE_STATUSES } = require('./constants')
const createBulkSnapshots = require('./shipment-bulk-create-snapshots')

// This is used to move PSM packed snapshots to sent in bulk
async function bulkAdvanceSnapshots (state, params) {
  const { shipments } = params

  const newSnapshots = shipments.map(shipment => {
    if (!SHIPMENT_ADVANCE_STATUSES[shipment.status]) {
      throw new Error(`Shipment ${shipment.id} with status ${shipment.status} cannot be advanced`)
    }

    return {
      ...shipment,
      status: SHIPMENT_ADVANCE_STATUSES[shipment.status],
      doCheckSent: false,
      removePaymentType: true
    }
  })

  // Create advanced snapshots
  const snapshots = await createBulkSnapshots(state, newSnapshots)
  return snapshots
}

module.exports = bulkAdvanceSnapshots
