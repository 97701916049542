import React from 'react'

import { Button, DeemphasizedLink, Modal, OptionsGroup, Text } from '@fielded/shared-ui'
import {
  SHIPMENT_GPS_FAIL_REASONS as gpsReasons,
  SHIPMENT_OTP_FAIL_REASONS as otpReasons
} from '@fielded/fs-api/lib/shipment/constants'

import DriverDeliveryFooterCard from '../../component/DriverDeliveryFooterCard'
import { transformShipmentGPSAndOTPFailedOptions } from '../../utils'

const PSMDeliveryAuthenticationModalContent = ({
  isLocationAuthType,
  onHandleCommentChange
}) => {
  const {
    gpsFailedOptions,
    otpFailedOptions
  } = transformShipmentGPSAndOTPFailedOptions(gpsReasons, otpReasons)

  let headerInfo = 'Unable to validate the otp? Please let us know why:'
  let options = otpFailedOptions

  if (isLocationAuthType) {
    headerInfo = 'The shipment is outside the required delivery radius. Please select a reason for delivering outside the designated area'
    options = gpsFailedOptions
  }

  return (
    <section className='delivery-map__modal-info'>
      <Text className='vs-u-margin-bottom-double' bold>
        {headerInfo}
      </Text>

      <section>
        <OptionsGroup
          onChange={onHandleCommentChange}
          options={options}
          inRows
        />
      </section>
    </section>
  )
}

const PSMDeliveryAuthenticationModal = ({
  isModalOpen,
  isSaving,
  isLocationAuthType,
  locationOption,
  otpOption,
  isBtnToContinueDisabled,
  onCloseModal,
  onHandleCommentChange,
  onToggleView
}) => {
  const shouldDisableLocationProceedBtn = !locationOption.comment
  const shouldDisableOtpProceedBtn = !otpOption.comment
  const shouldDisableProceedBtn = isLocationAuthType ? shouldDisableLocationProceedBtn : shouldDisableOtpProceedBtn

  return (
    <Modal
      centered={false}
      isOpen={isModalOpen}
      title='Warning'
      className='delivery-map__modal'
      backdropClassName='delivery-map__modal-backdrop'
      onClose={() => onCloseModal()}
    >
      <section className='delivery-map__modal-content'>
        <PSMDeliveryAuthenticationModalContent
          isLocationAuthType={isLocationAuthType}
          onHandleCommentChange={onHandleCommentChange}
        />

        <DriverDeliveryFooterCard>
          <section className='delivery-map__modal-footer'>
            <DeemphasizedLink
              colorVariant='brand'
              onClick={() => onCloseModal()}
              bold
            >
              Close
            </DeemphasizedLink>

            <Button
              className='delivery-map__modal-button'
              colorVariant='warning'
              fill='full'
              disabled={shouldDisableProceedBtn}
              loading={isSaving}
              onClick={onToggleView}
            >
              Proceed
            </Button>
          </section>
        </DriverDeliveryFooterCard>
      </section>
    </Modal>
  )
}

export default PSMDeliveryAuthenticationModal
