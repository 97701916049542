
const {translateTerritoryAlias, productAliasesByMarket} = require('../../../product/tools')

exports.getHeldStock = getHeldStock

async function transformLedgerToServiceAlias (ledger, db, service) {
  const productIds = Object.keys(ledger)
  const { rows } = await db.allDocs({
    keys: productIds,
    include_docs: true
  })
  const products = rows.map(r => r.doc).filter(x => x)
  const transformedLedger = {}

  const productsForTranslation = productAliasesByMarket(products)
  productIds.forEach(sku => {
    try {
      let productId = translateTerritoryAlias(sku, productsForTranslation, service)
      transformedLedger[productId] = ledger[sku]
    } catch (e) {
      console.warn(`Failed to translate '${sku}' to ${service} equivalent sku`)
    }
  })
  return transformedLedger
}

function removeUnavailable (stock) {
  Object.keys(stock).forEach(productId => {
    if (stock[productId] <= 0) {
      delete stock[productId]
    }
  })
}

async function getHeldStock (state, api, {
  locationId,
  filterAvailable,
  includeShipments,
  serviceToTranslateTo,
  planType = 'routine',
  includeOrders
}) {
  const requestLocation = await api.location.get(locationId)

  if (!requestLocation) {
    throw new Error(`Location ${locationId} not found`)
  }

  let supplier = requestLocation

  if (requestLocation.level === 'sdp') {
    const [firstSupplier] = await api.order.getSupplierIdsForLocation(requestLocation, {planType})
    supplier = await api.location.get(firstSupplier)
  }

  const ledger = await api.order.getLedgerForSupplier({
    supplier,
    locationId: requestLocation._id,
    includeShipments,
    includeOrders
  })

  if (filterAvailable) {
    removeUnavailable(ledger.ledger)
  }

  if (serviceToTranslateTo) {
    ledger.ledger = await transformLedgerToServiceAlias(ledger.ledger, state.productsDB, serviceToTranslateTo)
  }
  return ledger
}
