import { parse } from '@fielded/fs-api/lib/tools/smart-id'

export const makeAllocations = (method, productQtys) => {
  return Object.keys(productQtys).reduce((allocations, productId) => {
    const qty = productQtys[productId]
    allocations[productId] = {
      forecast: {
        method,
        [method]: qty
      }
    }
    return allocations
  }, {})
}

export const getAddedProducts = shipment => {
  const newSnapshotId = Object.keys(shipment.history).find(snapshotId => snapshotId.includes('status:new'))
  const manifest = shipment.history[newSnapshotId].counts
  const counts = shipment.counts

  return Object.keys(counts)
    .filter(id => !manifest[id])
    .reduce((newProducts, id) => {
      const { product } = parse(id)
      newProducts[`product:${product}`] = counts[id].quantity
      return newProducts
    }, {})
}
