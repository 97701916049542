module.exports = toDocIdProperties

function toDocIdProperties (id) {
  const [
    /* 'origin' */, origin,
    /* 'destination' */, destination,
    /* 'date' */, date,
    /* 'shipment' */, shipmentNo,
    /* 'status' */, status,
    /* 'agent' */, agentId,
    /* 'change' */, changeId
    // '_local' is used on shipment adjustments:
  ] = id.replace('_local/', '').split(/:/)
  return {
    origin,
    destination,
    date,
    shipmentNo,
    status,
    agentId,
    changeId
  }
}
