const get = require('lodash/get')
/*
This function joins multiple subOrders of the same group (i.e. destination)
Each product for a destination is expected to look like
"product:1": {
  "quantity": 2, // Total quantity to order for destination
  "allocationType": "existing_subscription",
  "prescription": "3-2-1" // Where this applies for patient orders.
  "suppliers": {
    "country:ng:state:fct:sdp:shelf-life-fct-held": {
      "amount": 1, // Quantity we are getting from held 1
      "snapshotId": "....." // For updating Orders
    },
    "country:ng:state:fct:sdp:shelf-life-fct-held-2": {
      "amount": 1, // Quantity we're getting from held 2
      "snapshotId": "....." // For updating Orders
    },
    ......
}
*/

exports.transformOrders = transformOrders
async function transformOrders (state, orders, defaultSupplier) {
  const ordersBySuppliers = orders.reduce((acc, order) => {
    const { destinationId, supplierId, products, _id: orderId } = order
    if (!acc[destinationId]) {
      const transformedProducts = addSupplierProps(acc, products, destinationId, supplierId, orderId, defaultSupplier)
      acc[order.destinationId] = Object.assign(order, { products: transformedProducts })
      return acc
    }
    const transformedProducts = addSupplierProps(acc, products, destinationId, supplierId, orderId, defaultSupplier)
    // Add new transformed products to existing destination products
    const orderProducts = Object.assign(acc[destinationId]['products'], transformedProducts)
    acc[destinationId] = Object.assign(acc[destinationId], { products: orderProducts })
    return acc
  }, {})
  return Object.values(ordersBySuppliers)
}

function addSupplierProps (previousOrders, products, destinationId, supplierId, orderId, defaultSupplier) {
  const supplierIdToUse = supplierId || defaultSupplier

  return Object.keys(products).reduce((acc, product) => {
    const previousProducts = get(previousOrders, `${destinationId}.products`, {})
    const previousSupplierObject = get(previousProducts, `${product}.suppliers`, {})
    const currentProduct = products[product]
    const allocationType = currentProduct['allocationType']

    // add new values with old values
    const original = get(previousProducts, `${product}.original`, 0) + currentProduct['original']
    const adjusted = get(previousProducts, `${product}.adjusted`, 0) + currentProduct['adjusted']
    const prescription = get(currentProduct, 'prescription') // Patient orders only have one order doc per group.
    const supplierObject = Object.assign({}, { amount: currentProduct['adjusted'] }, { snapshotId: orderId })

    const newSupplierObject = Object.assign(previousSupplierObject, { [supplierIdToUse]: supplierObject })

    // assign to product
    acc[product] = Object.assign({}, { original: original }, { allocationType: allocationType }, { suppliers: newSupplierObject }, { adjusted: adjusted }, {prescription: prescription})
    return acc
  }, {})
}
