import { createSlice } from '@reduxjs/toolkit'

// Add feature types here
export const FEATURE_TYPES = {
  WALLET: 'wallet'
}

const initialState = {
  features: {
    wallet: {
      showOnboarding: false,
      hasViewed: false,
      lastDayForOnboarding: new Date('2024 02 29')
    }
  }
}

export const featureOnboardingSlice = createSlice({
  name: 'featureOnboarding',
  initialState,
  reducers: {
    updateOnboarding: (state, action) => {
      const { showOnboarding, hasViewed, type } = action.payload
      state.features = {
        ...state.features,
        [type]: {
          ...state.features[type],
          showOnboarding,
          hasViewed
        }
      }
    }
  }
})

export const { updateOnboarding } = featureOnboardingSlice.actions

export const selectFeatures = (state) => state.featureOnboarding.features

export default featureOnboardingSlice.reducer
