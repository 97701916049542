import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Form, Modal } from '@fielded/shared-ui'

const TermsAndConditionsModal = ({ appName, documents, onAccept }) => {
  const [isModalOpen, setModalOpen] = useState(true)

  const handleAccept = async () => {
    try {
      await onAccept()
      setModalOpen(false)
    } catch (e) {
      console.error('Accepting T&Cs failed. User might be offline. Error: ', e)
    }
  }

  return (
    <Fragment>
      <Modal title={`${appName ? `${appName} ` : ''}Terms and Conditions`} isOpen={isModalOpen} isCloseable={false} spacing='none'>
        <div className='terms-and-conditions-modal__content'>
          <Form
            onSubmit={handleAccept}
            component='form'
          >
            <Form.Row>
              <Form.Field
                fieldName='checkbox'
              >
                <Form.Field.Checkbox
                  label='By ticking the box and clicking the button, I agree and consent to the terms of:'
                  required='Please accept the terms to continue'
                />
                <ul className='terms-and-conditions-modal__content-links'>
                  {documents.map((document, index) => (
                    <li key={document.path}>
                      <Link to={document.path} target='_blank' className='terms-and-conditions__link'>
                        {document.shortTitle || document.description}
                      </Link>
                    </li>
                  ))}
                </ul>
                <Form.Field.ValidationErrors />
              </Form.Field>
            </Form.Row>
            <Form.Actions>
              <Form.Button>
                I agree
              </Form.Button>
            </Form.Actions>
          </Form>
        </div>
      </Modal>
    </Fragment>
  )
}

TermsAndConditionsModal.propTypes = {
  /**
   * The app name (Ex: "Shelf Life")
   */
  appName: PropTypes.string,
  /**
   * The callback function to handle the acceptance of the T&Cs
   */
  onAccept: PropTypes.func,
  /**
   * The T&Cs documents array
   */
  documents: PropTypes.array.isRequired
}

TermsAndConditionsModal.defaultProps = {
  appName: undefined,
  documents: [],
  onAccept: () => {}
}

export default TermsAndConditionsModal
