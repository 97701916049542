import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'

import { fetchMasterData, selectMasterData } from '../root/master-data-reducer'
import { Loading } from '@fielded/shared-ui'

const WithMasterDataComponent = ({ children }) => {
  const dispatch = useDispatch()
  const masterData = useSelector(selectMasterData)

  const hasReceivedMasterData = get(masterData, 'hasReceivedMasterData', false)

  useEffect(() => {
    const getMasterData = async () => {
      await dispatch(fetchMasterData())
    }

    if (!hasReceivedMasterData) {
      getMasterData()
    }
  }, [])

  if (!hasReceivedMasterData) {
    return <Loading />
  }

  return children
}

const withMasterData = Comp => props => {
  return (
    <WithMasterDataComponent
    >
      <Comp {...props} />
    </WithMasterDataComponent>
  )
}

export default withMasterData
