import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  subscribeSelection: {},
  unsubscribeSelection: {},
  selectionLocation: null
}

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    subscriptionAdd: (state, action) => {
      const { productId, type, quantity } = action.payload
      state.subscribeSelection = {
        ...state.subscribeSelection,
        [productId]: { type, quantity }
      }
    },
    subscriptionRemove: (state, action) => {
      const productId = action.payload
      const newSelection = { ...state.subscribeSelection }
      delete newSelection[productId]
      state.subscribeSelection = newSelection
    },
    unsubscriptionAdd: (state, action) => {
      const {productId, reason} = action.payload
      state.unsubscribeSelection = {
        ...state.unsubscribeSelection,
        [productId]: { reason }
      }
    },
    unsubscriptionRemove: (state, action) => {
      const productId = action.payload
      const newSelection = { ...state.unsubscribeSelection }
      delete newSelection[productId]
      state.unsubscribeSelection = newSelection
    },
    selectionClear: (state) => {
      state.subscribeSelection = {}
      state.unsubscribeSelection = {}
    },
    selectionLocationAdd: (state, action) => {
      const locationId = action.payload
      state.selectionLocation = locationId
    }
  }
})

export const {
  subscriptionAdd,
  subscriptionRemove,
  unsubscriptionAdd,
  unsubscriptionRemove,
  selectionClear,
  selectionLocationAdd
} = subscriptionsSlice.actions

export const selectSubscribeSelection = (state) => state.subscriptions.subscribeSelection

export const selectUnsubscribeSelection = (state) => state.subscriptions.unsubscribeSelection

export const selectSelectionLocation = (state) => state.subscriptions.selectionLocation

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const clearIfLocationIdChanged = (locationId) => (dispatch, getState) => {
  const locationSaved = selectSelectionLocation(getState())

  if (locationSaved && locationSaved !== locationId) {
    dispatch(selectionClear())
  }

  dispatch(selectionLocationAdd(locationId))
}

export default subscriptionsSlice.reducer
