const get = require('lodash/get')
const { ALL_FUNDERS_ID } = require('../../../tools/utils')
const { byId } = require('../../../tools/by-id')
const { getLocationProgramFunder } = require('../utils')
const { createSnapshot } = require('./snapshot-create')

/*
  This takes a list of orders from the requesting user and makes snapshots
  for the warehouses.
*/
exports.createWarehouseSuborders = createWarehouseSuborders
function createWarehouseSuborders ({
  orders,
  user,
  suppliers,
  products,
  allLocations,
  programId
}) {
  const timestamp = new Date().toJSON()
  const productsById = byId(products)
  const locationsById = byId(allLocations)

  let snapshots = []
  const errorDestinations = []
  // Getting supplier has to happen on each order (instead of the whole group of orders)
  // because of bulk order uploads -- bulk upload needs to work across regions.
  orders
    .filter(order => locationsById[order.destinationId])
    .forEach(order => {
      const destination = locationsById[order.destinationId]
      const orderSnapshots = createWarehouseSnapshots({
        order, destination, user, suppliers, programId, productsById, timestamp
      })
      if (!orderSnapshots.length) {
        errorDestinations.push(destination)
        return
      }
      snapshots = snapshots.concat(orderSnapshots)
    })

  if (errorDestinations.length) {
    console.error('errorDestinations found:', errorDestinations)
    throw new Error(`Error finding suppliers for locations: ${errorDestinations.map(d => d._id).join(',')}`)
  }

  return snapshots
}

exports.createWarehouseSnapshots = createWarehouseSnapshots
function createWarehouseSnapshots (
  {order, user, destination, suppliers, programId, productsById, timestamp}
) {
  const suppliersForOrder = listSuppliersForOrder(suppliers, destination, programId)

  if (!suppliersForOrder.length || suppliersForOrder.length > 2) {
    return []
  }

  // In the case that there is just one supplier for both cold chain and non storageType,
  // We return a single snapshot as the same regional user will be working on it. However,
  // we still will split it by storageType after the order is accepted.
  if (suppliersForOrder.length === 1) {
    const snapshot = createWarehouseSnapshot(
      {order, supplierId: suppliersForOrder[0]._id, products: order.products, user, timestamp}
    )
    return [snapshot]
  }

  const isCCSupplier = location => location.suppliesColdChain

  const ccSupplier = suppliersForOrder.find(isCCSupplier)
  const nonCCSupplier = suppliersForOrder.find(location => !isCCSupplier(location))

  if (!ccSupplier && !nonCCSupplier) {
    return []
  }

  // Heads up: here we split by storage type in the case that there are two different suppliers,
  // one dedicated to cold chain and one that does not.
  // However, if there is only one supplier for both, we only create one snapshot in this case and send
  // it to the one warehouse for the regional user step. Once accepted, that will be split by storageType
  // for separate suborders in create-accepted-snapshots.js per https://github.com/fielded/van-orga/issues/4499
  // If we need to modify this code,
  const nonCCSnapshot = createWarehouseSnapshot(
    {
      order,
      supplierId: nonCCSupplier._id,
      products: forStorageType(order.products, productsById, 'non-cold-chain'),
      user,
      timestamp,
      storageType: 'non-cold-chain'
    }
  )

  if (programId !== 'program:hiv-aids' || !ccSupplier) {
    return [nonCCSnapshot]
  }

  const ccSnapshot = createWarehouseSnapshot(
    {
      order,
      supplierId: ccSupplier._id,
      products: forStorageType(order.products, productsById, 'cold-chain'),
      user,
      timestamp,
      storageType: 'cold-chain'
    }
  )

  return [ccSnapshot, nonCCSnapshot]
}

function forStorageType (orderProducts, productsById, type) {
  return Object.keys(orderProducts)
    .filter(productId => {
      // Some products have null for storageType and lodash.get will return that null
      // Defaulting to non-cold-chain, cold-chain products will be specified.
      const storageType = get(productsById, `${productId}.storageType`) || 'non-cold-chain'
      return (storageType === type)
    })
    .reduce((acc, productId) => {
      acc[productId] = orderProducts[productId]
      return acc
    }, {})
}

function createWarehouseSnapshot ({
  order,
  supplierId,
  products,
  user,
  timestamp = new Date().toJSON(),
  storageType
}) {
  const {orderId, groupId, programId, destinationId, funderId, orderType} = order
  return createSnapshot({
    status: 'warehouse',
    orderId,
    orderType,
    groupId,
    programId,
    destinationId,
    supplierId,
    funderId,
    user,
    timestamp,
    storageType,
    products: createOrderProducts(products)
  })
}

exports.listSuppliersForOrder = listSuppliersForOrder
function listSuppliersForOrder (suppliers, destination, programId) {
  const suppliersForDestination = suppliers
    .filter(supplier => supplier.supplies.has(destination._id))

  const funderId = getLocationProgramFunder(destination, programId)

  const suppliersForOrder = suppliersForDestination.filter(supplier => {
    const funderSupplierId = getLocationProgramFunder(supplier, programId)

    // program is not enabled on this supplier
    if (!funderSupplierId) return false

    // HIV program does not use funder-specific locations
    // https://github.com/fielded/van-orga/issues/2835
    if (programId === 'program:hiv-aids') {
      return (funderSupplierId === `funder:${ALL_FUNDERS_ID}`)
    }

    return (funderSupplierId === funderId)
  })

  return suppliersForOrder
}

function createOrderProducts (products) {
  return Object.keys(products)
    .reduce((acc, productId) => {
      const quantity = products[productId].adjusted
      acc[productId] = Object.assign({}, products[productId], {original: quantity, adjusted: quantity})
      return acc
    }, {})
}
