const { calculateStartDate, generatePaymentPlanData } = require('../../payment-plans/tools')
const { smartId } = require('../../tools')
const statementXlsExport = require('./statement-xls-export')

const calculateManagedCreditFee = (loanLimit) => {
  let managedCredit = 0
  if (loanLimit > 0 && loanLimit <= 50000) {
    managedCredit = 500
  } else if (loanLimit > 50000 && loanLimit <= 150000) {
    managedCredit = 1300
  } else if (loanLimit > 150000 && loanLimit <= 500000) {
    managedCredit = 3250
  } else if (loanLimit > 500000) {
    managedCredit = 5000
  }

  return managedCredit
}

const groupTransactions = (transactionList) => {
  return transactionList.reduce((acc, transaction) => {
    const dueDate = new Date(transaction.due_date)
    const startDate = calculateStartDate(dueDate, transaction.paymentOption.frequency)
    const { state } = smartId.parse(transaction.fsid)
    const { paymentPlan, pastDue } = generatePaymentPlanData({
      invoiceData: transaction,
      market: state,
      isPayPerUseClient: transaction.pay_per_use,
      feePercentage: transaction.fee_percentage,
      feePercentageLongTerm: transaction.fee_percentage_long_term,
      startDate,
      term: transaction.paymentOption.term,
      frequency: transaction.paymentOption.frequency,
      serviceFee: (transaction.paymentOption.service_fee_percentage / 100) * (transaction.amount - transaction.amount_paid)
    })
    transaction.paymentPlan = paymentPlan
    transaction.pastDue = pastDue
    acc[transaction.company_code].push(transaction)
    return acc
  }, {
    KE: [],
    NG: []
  })
}

module.exports = {
  calculateManagedCreditFee,
  statementXlsExport,
  groupTransactions
}
