const get = require('lodash/get')

const { isDiscontinued, isPPMV, isCore, isPPMVCore } = require('./utils')
const getCurrentPrice = require('./get-current-price')

const FILTER_ONLY_CORE = 'only-core'
const FILTER_ONLY_PPMV = 'only-ppmv'
const FILTER_ONLY_PPMV_CORE = 'only-ppmv-core'
const FILTER_NO_DISCOUNTS = 'no-discounts'

const filterProducts = (products, filter = null) => {
  let filtered = products.filter(p => !isDiscontinued(p))
  if (filter === FILTER_ONLY_CORE) {
    filtered = products.filter(isCore)
  } else if (filter === FILTER_ONLY_PPMV) {
    filtered = products.filter(isPPMV)
  } else if (filter === FILTER_ONLY_PPMV_CORE) {
    filtered = products.filter(isPPMVCore)
  }
  return filtered
}

const hasVat = product => {
  let vat = get(product, 'additionalData.vat')
  if (!vat) { // null or undefined (or 0)
    return false
  }
  // 0, 1, 0.076, 7.50%, 7.5, 7.5%
  if (typeof vat === 'string') {
    vat = vat.split('%')[0]
    vat = parseFloat(vat)
  }
  return Number.isFinite(vat) && vat > 0
}

const commonRowItems = product => ({
  id: product._id,
  category: (get(product, 'additionalData.category') || '').trim(),
  name: (get(product, 'name') || '').trim(),
  available: get(product, 'additionalData.available'),
  hasVat: hasVat(product)
})

const commonResponseItems = (products, productRows) => {
  const manufacturerSet = new Set()
  const categorySet = new Set()
  for (const product of products) {
    categorySet.add(get(product, 'additionalData.category'))
    manufacturerSet.add(get(product, 'additionalData.manufacturer'))
  }
  return {
    totalSKUs: productRows.length,
    totalManufacturers: manufacturerSet.size,
    totalCategories: categorySet.size,
    productRows
  }
}

const sortRows = productRows =>
  productRows
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => a.category.localeCompare(b.category))

const getCurrentPrices = (products, filter = null) => {
  const filteredProducts = filterProducts(products, filter)

  const productRows = filteredProducts.map(product => ({
    ...commonRowItems(product),
    price: getCurrentPrice(product.prices)
  }))

  sortRows(productRows)

  return {
    ...commonResponseItems(filteredProducts, productRows),
    date: new Date().toJSON()
  }
}

const getUpcomingPrices = (products, filter = null) => {
  const filteredProducts = filterProducts(products, filter)

  // Find nearest future date of all products prices
  const now = new Date().toJSON()
  let effectiveDate = null
  for (const product of filteredProducts) {
    const prices = product.prices.filter(p => {
      if (filter === FILTER_NO_DISCOUNTS && p.discount) {
        return false
      }

      return p.date > now
    })

    for (let i = prices.length - 1; i >= 0; --i) {
      const { date } = prices[i]
      if (!effectiveDate) {
        effectiveDate = date
      } else if (date < effectiveDate) {
        effectiveDate = date
      }
    }
  }

  // Find price and price change for each product
  let totalDecreased = 0
  let totalIncreased = 0
  let totalUnchanged = 0
  const productRows = filteredProducts.map(product => {
    const prices = product.prices
    let selectedPrice
    let selectedIndex
    for (let i = prices.length - 1; i >= 0; --i) {
      const price = prices[i]
      if (price.date <= effectiveDate) {
        selectedPrice = price
        selectedIndex = i
        break
      }
    }
    let change = null
    let changeValue = null
    let currentPrice
    if (selectedPrice && selectedPrice.date > now) {
      currentPrice = prices[selectedIndex - 1]
      if (currentPrice) {
        const upcoming = selectedPrice.price
        const current = currentPrice.price
        if (upcoming !== current) {
          changeValue = upcoming - current
          change = changeValue / current * 100
        }
      }
    }
    if (change == null) {
      ++totalUnchanged
    } else if (change > 0) {
      ++totalIncreased
    } else if (change < 0) {
      ++totalDecreased
    }

    return {
      ...commonRowItems(product),
      currentPrice: currentPrice || {},
      price: selectedPrice || {},
      changeValue,
      change
    }
  })

  sortRows(productRows)

  return {
    ...commonResponseItems(filteredProducts, productRows),
    effectiveDate,
    totalDecreased,
    totalIncreased,
    totalUnchanged
  }
}

module.exports = {
  FILTER_ONLY_CORE,
  FILTER_ONLY_PPMV,
  FILTER_ONLY_PPMV_CORE,
  FILTER_NO_DISCOUNTS,
  getCurrentPrices,
  getUpcomingPrices
}
