const getRelevantSnapshotDate = (isOrigin, shipment) => {
  // Get the relevant snapshot date
  const relevantSnapshotDate = isOrigin
    ? (shipment.snapshotDates.packed || shipment.snapshotDates.sent || shipment.snapshotDates.received)
    : (shipment.snapshotDates.received)

  return relevantSnapshotDate
}

module.exports = getRelevantSnapshotDate
