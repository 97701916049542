const tools = require('../../tools')
const { getProductsOnOrders } = require('../master-data')
const { listOrdersForUser } = require('./internal')
const { getAllocatedStock } = require('./get-allocated-stock')

exports.listProducts = listProducts
async function listProducts (
  state,
  {parentLocationId, programId, groupId, date, useAcceptedStatus = false}
) {
  tools.validateParams('listProducts', arguments[1])
  parentLocationId = parentLocationId || state.user.location.id
  const {orders} = await listOrdersForUser(state, programId, parentLocationId, {withAccepted: !!groupId}, useAcceptedStatus)
  const products = await getProductsOnOrders(state, orders, groupId)

  const status = tools.getUserStatus(state.user)
  const canSubmit = tools.groupCanAdvance(status, orders, groupId)
  const productRows = tools.listProducts({products, orders, groupId})

  if (!tools.isWarehouseUser(state.user)) {
    return {productRows, canSubmit, showWarehouse: false}
  }

  // About this madness:
  // `allocatedStock` the stock reserved per funder at a warehouse, so stuff from `commits` in stock reports.
  // `allocatedStockSDPUnits` same, but translated to SDP units.
  // `ledger` the actual stock at the warehouse, so stuff from `batches` in stock reports.
  const {productsById, allocatedStock, allocatedStockSDPUnits, ledger} = await getAllocatedStock(
    state, {locationId: parentLocationId, programId, date}
  )
  const currentStock = tools.getWithSiblingTotals({stock: allocatedStockSDPUnits, productsById})
  const warehouseRows = tools.listProductsForWarehouse({currentStock, productRows})

  // see note in list-products-warehouse.js findStockReservationErrors
  const stockReservationErrors = tools.findStockReservationErrors({allocatedStock, ledger, productsById})

  const sufficientStock = (stockReservationErrors.length === 0) &&
    (productRows.filter(row => row.insufficient).length === 0)

  return {
    productRows: warehouseRows,
    canSubmit,
    sufficientStock,
    showWarehouse: true,
    stockReservationErrors
  }
}

exports.listProductsOnDestination = listProductsOnDestination
async function listProductsOnDestination (
  state,
  {destinationId, programId, groupId}
) {
  const {orders} = await listOrdersForUser(state, programId, destinationId, {withAccepted: !!groupId})
  const products = await getProductsOnOrders(state, orders, groupId)
  const productRows = tools.listProducts({products, orders, destinationId, groupId})
  const orderId = tools.findOrderId(destinationId, groupId, orders)
  return {productRows, orderId}
}
