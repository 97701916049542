import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { PartnerBalanceIndicator } from '../../icons'

const PrintTemplateSection = ({
  title,
  titlePB,
  subtitle,
  warning,
  withTopMargin,
  withOverflow,
  children
}) => {
  return (
    <section
      className={classnames(
        'print-template__section',
        {'vs-u-margin-top': withTopMargin},
        {'print-template__section--with-overflow': withOverflow}
      )}
    >
      {title && (
        <div
          className='print-template__section-title'
        >
          {title}
        </div>
      )}
      {titlePB && (
        <div
          className='print-template__section-title'
        >
          <PartnerBalanceIndicator fill='#4d2999' /> {titlePB}
        </div>
      )}
      {subtitle && (
        <div
          className='print-template__section-subtitle'
        >
          {subtitle}
        </div>
      )}
      {warning && (
        <div
          className='print-template__section-warning'
        >
          {warning}
        </div>
      )}
      {children}
    </section>
  )
}

PrintTemplateSection.propTypes = {
  /**
   * The child elements of the PrintTemplateSection
   */
  children: PropTypes.node,
  /**
   * Section title
   */
  title: PropTypes.string,
  /**
   * Section title for Partner Balance. Adds PartnerBalanceIndicator icon
   */
  titlePB: PropTypes.string,
  /**
   * Section subtitle
   */
  subtitle: PropTypes.string,
  /**
   * Section title styled as a warning
   */
  warning: PropTypes.string,
  /**
   * Adds margin of standard size
   */
  withTopMargin: PropTypes.bool,
  /**
   * add overflow if the section a child element that wraps (ie a table element)
   */
  withOverflow: PropTypes.bool
}

PrintTemplateSection.displayName = 'PrintTemplate.Section'

export default PrintTemplateSection
