import React, { Fragment } from 'react'
import Proptype from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { CheckmarkCircle, Edit, ExclamationCircle } from '@fielded/shared-ui/src/icons'

import {
  STATUS_NOT_STARTED,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETE,
  STATUS_OFFLINE,
  reportStatusPropType
} from '../../common/report-status'

const reportStatusWrapper = ({ url, content, className }) => {
  return url
    ? <Link to={url} className={className}>{content}</Link>
    : <div className={className}>{content}</div>
}

const ReportStatus = ({ status = 'notStarted', url }) => {
  const isComplete = status === STATUS_COMPLETE
  const isDraft = status === STATUS_IN_PROGRESS
  const isNotStarted = status === STATUS_NOT_STARTED
  const isOffline = status === STATUS_OFFLINE

  return reportStatusWrapper(
    {
      url,
      className: classnames(
        'report-status',
        {'report-status--complete': isComplete},
        {'report-status--draft': isDraft},
        {'report-status--not-started': isNotStarted},
        {'report-status--offline': isOffline},
        {'report-status--is-link': url}
      ),
      content: (
        <Fragment>
          <div className='report-status__icon'>
            {isComplete && <CheckmarkCircle />}
            {isDraft && <Edit />}
            {isNotStarted && <ExclamationCircle />}
            {isOffline && <ExclamationCircle />}
          </div>
          <div className='report-status__label'>
            {isComplete && 'Report complete'}
            {isDraft && 'Report in progress'}
            {isNotStarted && 'Report not started'}
            {isOffline && 'Report not available offline'}
          </div>
        </Fragment>
      )
    }
  )
}

ReportStatus.propTypes = {
  status: reportStatusPropType.isRequired,
  url: Proptype.string
}

ReportStatus.defaultProps = {
  url: undefined
}

export default ReportStatus
