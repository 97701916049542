/*
 * This is the special shipment export defined here
 * https://github.com/fielded/field-supply/issues/623
 * for ad-hoc analytics of shipments
 *
 * it lives here because the use cases are related to the stock-situation-export
 */
const get = require('lodash/get')
const flatten = require('lodash/flatten')
const flatMap = require('lodash/flatMap')
const sortBy = require('lodash/sortBy')
const listLocationChildren = require('../location/api/read/list-children').listChildren
const listAllProducts = require('../product/api/read/list-all').listAll
const findShipments = require('./shipment-find')
const { batchIdToProductId } = require('./tools/product-batch-util')
const XLSX = require('@sheet/core')
const PLANNING_TYPES = require('./tools/planning-types')

const DATE_KEY = 'snapshotDates.received'
const exportShipmentsData = async function (state, { locationId, startdate, enddate }) {
  const locations = await listLocationChildren(state, locationId, { date: startdate })
  const products = await listAllProducts(state)

  // 1. Load all shipments
  let shipments = await Promise.all(
    locations.map(async location => {
      const shipments = await findShipments(state, {
        location: { id: location._id },
        startdate,
        enddate
      })

      // Not sure we should only include received shipments
      // but let's do it for now:
      const received = shipments.filter(shipment => {
        const date = get(shipment, DATE_KEY)
        return shipment.status === 'received' &&
          date >= startdate &&
          date <= enddate
      })

      // Just lazy to be able to reuse this later
      received.forEach(shipment => { shipment.location = location })
      return received
    })
  )
  shipments = flatten(shipments)

  // 2. Split shipments out to one event per batch per shipment
  const events = flatMap(shipments, (shipment) => {
    const location = shipment.location // just decorated above, shipments don't normally have this
    const snapshotIdForPlanning = Object.keys(shipment.history).find(id => id.includes(`status:new`))
    const snapshotIdForPacking = Object.keys(shipment.history).find(id => id.includes(`status:sent`))
    const receivedCounts = get(shipment, 'counts', {})
    const plannedCounts = get(shipment, `history.${snapshotIdForPlanning}.counts`, {})
    const packedCounts = get(shipment, `history.${snapshotIdForPacking}.counts`, {})
    const operator = get(shipment, `history.${snapshotIdForPacking}.createdBy.user`)
    const batchIds = new Set(
      Object.keys(receivedCounts)
        .concat(Object.keys(plannedCounts))
        .concat(Object.keys(packedCounts))
    )
    return Array.from(batchIds).map((batchId, index) => {
      // explode all shipment counts per-product
      const productId = batchIdToProductId(batchId)
      const product = products.find(product => product._id === productId)
      const planned = get(plannedCounts, `${batchId}.quantity`, '-')
      const packed = get(packedCounts, `${batchId}.quantity`, '-')
      const delivered = get(receivedCounts, `${batchId}.quantity`, '-')

      let comment = ''
      // Only write the comments on the first item
      if (index === 0) {
        comment = get(shipment, 'comments').map(c => c.comment || '').join(' | ')
      }
      let shipmentType = 'routine'
      // Planner initiated C&R has the client as destination and negative numbers
      if (delivered < 0) {
        shipmentType = PLANNING_TYPES.C_R
      // Driver initiated C&R has the client as origin
      } else if (shipment.origin.id === location.id) {
        shipmentType = 'collect-redistribute-unplanned'
      } else if (shipment.planningType !== PLANNING_TYPES.ROUTINE) {
        shipmentType = shipment.planningType
      }

      return {
        product,
        location,
        shipment,
        planned,
        packed,
        delivered,
        operator,
        comment,
        shipmentType,
        deliveryDate: new Date(get(shipment, DATE_KEY)).toLocaleDateString('en-NG')
      }
    })
  })
  // 3. Sort by locationId/date, and format CSV:
  const sortedEvents = sortBy(events, ['location._id', `shipment.${DATE_KEY}`])

  const columns = [
    {
      name: 'Location Name',
      key: 'location.name'
    }, {
      name: 'Location Id',
      key: 'location._id'
    }, {
      name: 'Delivery ID',
      key: 'shipment.shipmentNo'
    }, {
      name: 'Delivery type',
      key: 'shipmentType'
    }, {
      name: 'Delivery Date',
      key: 'deliveryDate'
    }, {
      name: 'Delivery Comment',
      key: 'comment'
    }, {
      name: 'Product Name',
      key: 'product.fullName'
    }, {
      name: 'Product Code',
      key: 'product.code'
    }, {
      name: 'Driver',
      key: 'shipment.updatedBy.user'
    }, {
      name: 'Operator',
      key: 'operator'
    }, {
      name: 'Planned',
      key: 'planned'
    }, {
      name: 'Packed',
      key: 'packed'
    }, {
      name: 'Delivered',
      key: 'delivered'
    }
  ]

  const headers = columns.map(column => column.name)
  const data = sortedEvents.map(event => {
    return columns.map(column => {
      return get(event, column.key, '')
    })
  })
  return [headers].concat(data)
}
const generateExcelSheet = (aoa) => {
  return new Promise((resolve) => {
    const ws = XLSX.utils.aoa_to_sheet(aoa)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'SL Shipments Export')
    const wbout = XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array'
    })
    resolve(new global.Blob([wbout], {type: 'application/octet-stream'}))
  })
}
async function exportShipments (state, params) {
  const data = await exportShipmentsData(state, params)
  return generateExcelSheet(data)
}
module.exports = { exportShipmentsData, exportShipments }
