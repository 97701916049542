/* global Event */

import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { Loading } from '@fielded/shared-ui'

import { hasFeature } from '../../../van-shared/utils/features'
import { withUser } from '../../../common/AuthenticationProvider'
import withReport from '../report-entry/withReport'
import ConfirmationPSM from './ConfirmationPSM'
import ConfirmationSL from './ConfirmationSL'
import SalesSummary from './SalesSummary'

import { submitReport } from '../report-entry/report-entry-reducer'

import {
  buildSuccessUrl,
  buildFindReportUrl,
  buildServicesUrl,
  buildLocationsUrl
} from '../app/routes-urls'

import { isShelflifePowered, updateCurrentMasterData } from '../../../van-shared/utils/utils'

class ConfirmationContainer extends Component {
  // The form-ref is used for the signature
  formRef = createRef()

  constructor (props) {
    super(props)
    const { config } = this.props
    this.state = {
      // `isSalesSummary` switches between the count confirmation view
      // and the sales screen in shelflife. The initial state is false.
      // When the user confirmed, we then set it to true to show
      // the sales screen.
      isSalesSummary: false,
      isShelflife: isShelflifePowered(config)
    }
  }

  componentDidMount () {
    window.dispatchEvent(new Event('van-hide-nav'))
  }

  componentWillUnmount () {
    window.dispatchEvent(new Event('van-show-nav'))
  }

  handleGoBack = () => {
    const {
      report,
      service,
      user
    } = this.props

    let url = buildLocationsUrl({
      locationId: user.location.id,
      date: report.createdAt,
      programId: service.program.id
    })

    if (this.props.readOnly) {
      this.props.history.push(url)
    } else {
      this.props.history.goBack()
    }
  }

  handleConfirm = async () => {
    if (this._loading) {
      return false
    }

    const {
      submitReport,
      history,
      report,
      service,
      reportLocation,
      user,
      config
    } = this.props
    const {
      isSalesSummary,
      signed,
      isShelflife
    } = this.state

    const opensExistingReport = hasFeature(config.features, 'stockCount:opensExistingReport')
    const programId = service.program.id || ''
    const linkedServiceId = service.linkedServiceId || ''
    const userLocationId = get(user, 'location.id')

    const date = report.createdAt

    if (isShelflife) {
      if (!isSalesSummary) {
        // quantity confirmation screen
        this._loading = true
        const validationErrors = await this.formRef.current.validate()
        this._loading = false
        if (validationErrors) {
          return
        }
        const fields = this.formRef.current.getFields()
        const wasSigned = fields.name && fields.signature
        this.setState({
          signed: wasSigned ? {
            name: fields.name,
            signature: fields.signature
          } : null,
          isSalesSummary: true
        })
        return
      }
      // Sales summary screen
      try {
        this._loading = true
        await submitReport({ opensExistingReport, signed })
      } catch (e) {
        this._loading = false
        return
      }
      const successUrl = buildSuccessUrl({locationId: reportLocation._id})
      history.push(successUrl)
      return
    }

    try {
      this._loading = true
      await submitReport({ opensExistingReport })
    } catch (e) {
      this._loading = false
      return
    }

    if (linkedServiceId) {
      const reportUrl = buildFindReportUrl({locationId: reportLocation._id, serviceId: linkedServiceId, date})
      history.push(reportUrl)
      return
    }

    const services = reportLocation.services.filter(id => id.startsWith(programId))
    const backToServicePage = services.length > 1
    const url = backToServicePage
      ? buildServicesUrl({locationId: userLocationId, date, programId: service.program.id, reportLocationId: reportLocation._id})
      : buildLocationsUrl({locationId: userLocationId, date, programId: service.program.id})
    history.push(url)
  }

  render () {
    const {
      user,
      isMasterDataLoaded,
      report,
      service,
      reportLocation,
      productsById,
      fieldsById,
      batchesById,
      config,
      readOnly
    } = this.props
    const {
      isSalesSummary,
      isShelflife
    } = this.state

    if (!isMasterDataLoaded) {
      return <Loading loadingText='Loading confirmation ...' />
    }

    if (isShelflife) {
      if (isSalesSummary) {
        return <SalesSummary
          user={user}
          program={service.program}
          config={config}
          report={report}
          programName={get(service, 'program.name', '')}
          serviceId={service.id}
          reportLocation={reportLocation}
          productsById={productsById}
          fieldsById={fieldsById}
          onClickGoBack={() => this.setState({ isSalesSummary: false })}
          onClickConfirm={this.handleConfirm}
          readOnly={readOnly}
        />
      }
      return (<ConfirmationSL
        formRef={this.formRef}
        user={user}
        program={service.program}
        config={config}
        report={report}
        programName={get(service, 'program.name', '')}
        serviceId={service.id}
        reportLocation={reportLocation}
        productsById={productsById}
        fieldsById={fieldsById}
        onClickGoBack={this.handleGoBack}
        onClickConfirm={this.handleConfirm}
        readOnly={readOnly}
      />)
    }

    return <ConfirmationPSM
      user={user}
      program={service.program}
      config={config}
      report={report}
      reportLocation={reportLocation}
      programName={get(service, 'program.name', '')}
      serviceId={service.id}
      serviceName={service.name}
      productsById={productsById}
      fieldsById={fieldsById}
      batchesById={batchesById}
      onClickGoBack={this.handleGoBack}
      onClickConfirm={this.handleConfirm}
      readOnly={readOnly}
    />
  }
}

const mapStateToProps = (state, ownProps) => {
  const newState = updateCurrentMasterData(state, 'masterDataReports')

  const { masterData, reportEntry, root: { config } } = newState
  const report = reportEntry.data.report
  const reportLocationId = get(report, 'location.id')
  const masterDataFieldsById = get(masterData, 'fields.byId', {})

  /*
   * Remove fields that are marked as hidden
   */
  const fieldsById = Object.keys(masterDataFieldsById)
    .reduce((acc, fieldId) => {
      const field = masterDataFieldsById[fieldId]
      if (field.display !== false || field.displayConfirmation) {
        acc[fieldId] = field
      }
      return acc
    }, {})

  return {
    isMasterDataLoaded: !!get(masterData, 'products.allIds', []).length,
    config,
    report,
    reportLocation: get(masterData, `locations.byId.${reportLocationId}`, {}),
    productsById: get(masterData, 'products.byId', {}),
    batchesById: get(masterData, 'batches.byId', {}),
    fieldsById,
    service: get(masterData, 'service'),
    readOnly: get(reportEntry, 'data.hasReadOnlyReports', false) || ownProps.readOnly
  }
}

const mapDispatchToProps = ({
  submitReport
})

ConfirmationContainer.propTypes = {
  report: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isMasterDataLoaded: PropTypes.bool.isRequired,
  productsById: PropTypes.object.isRequired,
  fieldsById: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  reportLocation: PropTypes.object.isRequired
}

export default withUser(withReport(connect(mapStateToProps, mapDispatchToProps)(ConfirmationContainer)))
