import React from 'react'
import PropTypes from 'prop-types'

import StatusLabel from '../StatusLabel'
import { CheckmarkCircle, Clock } from '../../icons'

const DocumentStatus = ({status}) => {
  const isCompleted = status && status === 'completed'
  const isUpdated = status && status === 'updated'
  const statusLabel = (status) => {
    if (isCompleted) {
      return 'Completed'
    }

    if (isUpdated) {
      return 'Updated'
    }

    return 'Not completed'
  }
  return (
    <StatusLabel
      alignOpposite
      status={isCompleted ? 'success' : 'attention'}
      label={statusLabel(status)}
      icon={isCompleted ? <CheckmarkCircle solid /> : <Clock />}
      className='vs-u-hide-on-print'
    />
  )
}

DocumentStatus.propTypes = {
  /**
   * A status of the document (eg. 'completed')
  */
  status: PropTypes.string.isRequired
}

export default DocumentStatus
