import React, { Component } from 'react'
import { DateRange } from 'react-date-range'

import { ActionsBar, Button, DeemphasizedLink } from '@fielded/shared-ui'

import withConfig from '../../hoc/withConfig'

import { queryStringifyFilters } from '../../../common/querystring-filters'
import {
  getPeriodTypeAndDate,
  periodToHumanReadable,
  getCurrentPeriod,
  getPrevPeriod,
  getNextPeriod,
  periodToDateRangeNoTime,
  formatPeriod,
  defaultPeriod,
  PERIOD_CUSTOM,
  PERIOD_MONTH,
  PERIOD_QUARTER,
  PERIOD_YEAR
} from '../../../common/periods'

import 'react-date-range/dist/styles.css' // main style file

const defaultPresetOptions = (app, programIds, hasNoCycleOptions) => {
  const defaultUserPeriod = defaultPeriod(app, programIds)
  const optionsArray = [
    {
      label: 'Previous reporting cycle',
      value: defaultUserPeriod,
      id: 'timeframeShortcutLastCycle'
    },
    {
      label: 'Two cycles ago',
      value: getPrevPeriod(defaultUserPeriod),
      id: 'timeframeShortcutTwoCyclesAgo'
    },
    {
      label: 'This month',
      value: getCurrentPeriod(PERIOD_MONTH),
      id: 'timeframeShortcutThisMonth'
    },
    {
      label: 'Last month',
      value: getPrevPeriod(PERIOD_MONTH),
      id: 'timeframeShortcutLastMonth'
    },
    {
      label: 'This quarter',
      value: getCurrentPeriod(PERIOD_QUARTER),
      id: 'timeframeShortcutThisQuarter'
    },
    {
      label: 'Last quarter',
      value: getPrevPeriod(PERIOD_QUARTER),
      id: 'timeframeShortcutLastQuarter'
    },
    {
      label: 'This year',
      value: getCurrentPeriod(PERIOD_YEAR),
      id: 'timeframeShortcutThisYear'
    },
    {
      label: 'Last year',
      value: getPrevPeriod(PERIOD_YEAR),
      id: 'timeframeShortcutLastYear'
    }
  ]

  if (hasNoCycleOptions) {
    optionsArray.splice(0, 2)
  }

  return optionsArray
}

const cyclePresetOptions = (app, programIds) => {
  const defaultUserPeriod = defaultPeriod(app, programIds)
  const userPeriodType = getPeriodTypeAndDate(defaultUserPeriod).periodType
  const nextPeriod = getNextPeriod(userPeriodType)
  const twoPeriodsAhead = getNextPeriod(nextPeriod)
  const threePeriodsAhead = getNextPeriod(twoPeriodsAhead)

  return [
    {
      label: periodToHumanReadable(getCurrentPeriod(userPeriodType)),
      value: getCurrentPeriod(userPeriodType),
      id: 'timeframeShortcutThisCycle'
    },
    {
      label: periodToHumanReadable(nextPeriod),
      value: nextPeriod,
      id: 'timeframeShortcutNextCycle'
    },
    {
      label: periodToHumanReadable(twoPeriodsAhead),
      value: twoPeriodsAhead,
      id: 'timeframeShortcutTwoCyclesAhead'
    },
    {
      label: periodToHumanReadable(threePeriodsAhead),
      value: threePeriodsAhead,
      id: 'timeframeShortcutThreeCyclesAhead'
    }
  ]
}

class TimeFilter extends Component {
  constructor (props) {
    super(props)
    const {
      filters,
      config,
      programIds,
      isCycleBased,
      hasNoCycleOptions
    } = this.props
    const period = filters.time
    const periodRange = periodToDateRangeNoTime(period)
    const presetOptions = isCycleBased ? cyclePresetOptions(config.app, programIds) : defaultPresetOptions(config.app, programIds, hasNoCycleOptions)

    this.state = {
      options: presetOptions,
      filterValue: props.filters.time,
      dateRangePicker: {
        selection: {
          startDate: periodRange.from,
          endDate: periodRange.to,
          key: 'selection'
        }
      }
    }
  }

  applyFilter = () => {
    const {
      history,
      filters,
      close,
      onTimeframeSelected
    } = this.props

    close()

    if (onTimeframeSelected) {
      return onTimeframeSelected({
        time: this.state.filterValue
      })
    }

    // Default behaviour (if !onTimeframeSelected)
    history.push({
      ...history.location,
      search: queryStringifyFilters({
        ...filters,
        time: this.state.filterValue
      })
    })
  }

  setRange = (filterValue) => {
    const period = periodToDateRangeNoTime(filterValue)

    this.handleRangeChange({
      selection: {
        startDate: period.from,
        endDate: period.to
      },
      filterValue
    })
  }

  handleRangeChange = ({ selection, filterValue }) => {
    if (!filterValue) {
      filterValue = formatPeriod(
        PERIOD_CUSTOM,
        {
          from: selection.startDate,
          to: selection.endDate
        }
      )
    }

    this.setState({
      filterValue,
      dateRangePicker: {
        ...this.state.dateRangePicker,
        selection: {
          ...this.state.dateRangePicker.selection,
          ...selection
        }
      }
    })
  }

  render () {
    const {
      close,
      isCycleBased
    } = this.props

    const {
      options,
      filterValue
    } = this.state

    return (
      <div className='time-filter'>
        <div className='time-filter__shortcuts'>
          {options.map(option => (
            <div
              className='time-filter__shortcut'
              key={option.id}
            >
              <Button
                colorVariant='brand'
                size='small'
                disabled={filterValue === option.value}
                selected={filterValue === option.value}
                onClick={() => this.setRange(option.value)}
                id={option.id}
              >
                {option.label}
              </Button>
            </div>
          ))}
        </div>
        {!isCycleBased && (
          <DateRange
            onChange={this.handleRangeChange}
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            className={'PreviewArea'}
            months={2}
            ranges={[this.state.dateRangePicker.selection]}
            direction='horizontal'
            showDateDisplay={false}
            showMonthAndYearPickers={false}
          />
        )}
        <ActionsBar>
          <Button
            colorVariant='brand'
            fill='outline'
            onClick={() => this.applyFilter()}
          >
            Apply filter
          </Button>
          <DeemphasizedLink
            onClick={() => close()}
          >
            Cancel
          </DeemphasizedLink>
        </ActionsBar>
      </div>
    )
  }
}

export default withConfig(TimeFilter)
