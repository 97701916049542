const { getByIds } = require('../product/api/read/get-by-ids')
module.exports = validateProductsExist

async function validateProductsExist (state, products) {
  let productsInDB = await getByIds(state, products)
  let existingIds = new Set(productsInDB.map(dbProduct => dbProduct._id))
  const dontExist = products.filter(addedProductId => !existingIds.has(addedProductId))
  if (dontExist.length) {
    throw new Error(`Products ${dontExist.join(', ')} do not exist, add them to the products db first`)
  }
}
