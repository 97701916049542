import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Edit } from '@fielded/shared-ui/src/icons'
import { getShipmentBatches } from '@fielded/fs-api/lib/shipment/utils/utils'

import { updateShipment } from '../shipments/shipments-reducer'
import { loadPickList } from '../pick-list/pick-list-reducer'
import withConfig from '../../../van-shared/hoc/withConfig'
import withShipment from './../common/WithShipmentWrapper'
import withMasterData from './../common/withMasterData'
import { withApi } from './../../../common/ApiProvider'
import Count from '../count/Count'
import { shipmentTitle } from '../common/utils'
import { updateCurrentMasterData } from '../../../van-shared/utils/utils'

/*
* Count is for when a user (built for SL) needs to
* check off products in a one-page, "count" view.
* Intentionally built without picklist or batch reducer so we can
* move away from maintaining several batch lists in the redux store.
* see https://github.com/fielded/van-orga/issues/1099
* Supports unbatched products to start.
*/
class CountContainer extends Component {
  componentDidMount () {
    const {loadPickList, shipment, products} = this.props
    loadPickList(shipment, products)
  }

  handleCountUpdate = async (batchId, inputQuantity, checked, paymentType) => {
    const {api, match: {params: {snapshotId}}} = this.props
    const counts = {
      [batchId]: {
        checked,
        quantity: inputQuantity,
        paymentType
      }
    }
    await api.shipment.adjustment.draft(snapshotId, counts)

    // this is a prop from withShipment wrapper
    const updatedShipment = await this.props.reloadShipment(snapshotId)

    // the confirmation container still looks at `store.shipments`,
    // so this is to keep the shipment up to date
    // there as well for whichever point the user advances
    this.props.updateShipment(updatedShipment)
  }

  handleProceed = async () => {
    const {loadPickList, shipment, history, products} = this.props
    // load picklist for confirmation screen from saved batches
    await loadPickList(shipment, products)
    history.push(`/shipments/confirmation/${shipment.snapshotId}`)
  }

  render () {
    const {shipment, config, batches, api, user} = this.props

    return (
      <Count
        batches={batches}
        shipment={shipment}
        config={config}
        api={api}
        user={user}
        /* remove add/remove product, seems a bit much right now, maybe add later */
        hasAdjustment
        title={[<span>{shipmentTitle(shipment, config)}</span>, <div className='shipment-header__label'><Edit width='13' height='17' fill='#4D2999' />Currently Editing</div>]}
        onProductUpdate={this.handleCountUpdate}
        onClickProceed={this.handleProceed}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const newState = updateCurrentMasterData(state, 'masterDataShipments')

  const {products} = newState.masterData
  const productsById = products.byId
  const batches = getShipmentBatches(props.shipment, productsById)

  return {
    ...props,
    batches,
    products
  }
}

const mapDispatchToProps = {
  updateShipment,
  loadPickList
}

// TODO: something more clever here:
export default withApi(
  withConfig(
    withMasterData(
      withShipment(
        connect(mapStateToProps, mapDispatchToProps)(CountContainer),
        { pendingAdjustments: true }
      )
    )
  )
)
