import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'

import { ErrorView, Loading } from '@fielded/shared-ui'

import { withApi } from '../../../common/ApiProvider'
import { getReport } from './../common/api'

import {
  HAS_NO_DATA, IS_LOADING, LOADING_FAILED, LOADING_SUCCEEDED
} from '../common/loading-status'
import { augmentReport, fetchAlerts, useCache, requestReport, selectReportEntry } from './report-entry-reducer'
import { fetchMasterData, selectMasterData } from '../root/master-data-reducer'
import { selectRoot } from '../root/root-reducer-slice'

// this needs to be global for redux store items depending on
// the store to have been populated by updateOngoingEntry on a specific product
let lastReportId = null

export const clearReportCache = () => { lastReportId = null }

const Loader = ({ reportId, api, children }) => {
  const dispatch = useDispatch()
  const reportEntry = useSelector(selectReportEntry)
  const root = useSelector(selectRoot)
  const masterDataFromStore = useSelector(selectMasterData)

  const loadingStatus = reportEntry.status
  const hasAlerts = root.hasAlerts
  const config = root.config

  const load = async () => {
    if (loadingStatus === IS_LOADING) {
      return
    }

    if (reportId === lastReportId && loadingStatus !== HAS_NO_DATA) {
      return dispatch(useCache())
    }

    lastReportId = reportId
    dispatch(requestReport(reportId))

    const report = await getReport(api, { reportId })
    const productIdsFromReport = Object.keys(report.stock)
    const service = await api.service.get(report.serviceId)
    const location = await api.location.get(report.location.id, report.createdAt)

    const response = await dispatch(fetchMasterData({
      serviceId: service.id,
      locationId: report.location.id,
      productIdsFromReport
    }))

    // This check is necessary if there are no changes to the service.id or report.location.id
    // i.e. if there was no api request (fetchMasterData was cancelled based on a condition,
    // we reuse the last data in redux store)
    const masterData = response.payload || masterDataFromStore
    const fieldsById = get(masterData, 'fields.byId', {})
    const productsById = get(masterData, 'products.byId', {})

    dispatch(augmentReport(report, service, fieldsById, productsById, config, location))
    if (hasAlerts) {
      dispatch(fetchAlerts(report, service))
    }
  }

  useEffect(() => {
    load()
  }, [])

  let component = null
  switch (loadingStatus) {
    case HAS_NO_DATA:
    case IS_LOADING:
      component = <Loading />
      break
    case LOADING_FAILED:
      component = <ErrorView text='Error, loading has failed' title='reports' />
      break
    case LOADING_SUCCEEDED:
      component = children
      break
    default:
      console.error('Unknown loading status: ' + loadingStatus)
  }

  return component
}

const ConnectedLoader = withApi(Loader)

// Component decorator function that will ensure the report is
// fetched before the provided component is rendered.
const withReport = Comp => props => {
  const {
    match: {
      params: {
        locationId,
        serviceId,
        reportId,
        productId,
        date
      }
    }
  } = props

  return (
    <ConnectedLoader
      locationId={locationId}
      date={date}
      serviceId={serviceId}
      reportId={reportId}
      productId={productId}
    >
      <Comp {...props} />
    </ConnectedLoader>
  )
}

export default withReport
