const { getGroupStatus } = require('./statuses')
const { byId } = require('../../../tools/by-id')

exports.listGroups = function ({locations, orders, doNotFilterByLocation}) {
  const locationsById = byId(locations)

  if (!doNotFilterByLocation) {
    orders = filterOutOrdersWithNotFoundLocation(orders, locationsById)
  }
  const orderGroupMap = getOrdersMap(orders)
  const groups = getOrderList(orderGroupMap, locationsById)
  return {groups}
}

function filterOutOrdersWithNotFoundLocation (orders, locationsById) {
  const filteredOrders = orders.filter((order) => {
    return locationsById[order.destinationId]
  })

  return filteredOrders
}

function getOrdersMap (orders) {
  return orders
    .reduce((acc, order) => {
      const orderGroup = acc[order.groupId] || getOrderGroup(order)
      orderGroup.orders.push(order)
      orderGroup.locationsSet.add(order.destinationId)
      orderGroup.fundersSet.add(order.funderId)
      orderGroup.routesSet.add(order.routeId)
      order.suppliers.forEach(supplierID => orderGroup.suppliersSet.add(supplierID))
      if (order.closedStatus) {
        orderGroup.closedStatus = order.closedStatus
      }
      Object.keys(order.products)
        .map(productId => orderGroup.productsSet.add(productId))

      acc[order.groupId] = orderGroup
      return acc
    }, {})
}

function getOrderGroup (order) {
  const { programId, createdAt, createdBy, groupId, deliveryDate, patientId, patientName } = order
  const orderGroup = {
    programId,
    createdAt,
    createdBy,
    groupId: groupId,
    orders: [],
    locationsSet: new Set(),
    productsSet: new Set(),
    fundersSet: new Set(),
    routesSet: new Set(),
    suppliersSet: new Set()
  }
  if (deliveryDate) {
    orderGroup.deliveryDate = deliveryDate
  }
  if (patientName) {
    orderGroup.patientName = patientName
  }
  if (patientId) {
    orderGroup.patientId = patientId
  }
  return orderGroup
}

function getOrderList (orderGroupMap, locationsById) {
  return Object.keys(orderGroupMap).map(key => {
    const orderGroup = orderGroupMap[key]
    orderGroup.locationCount = orderGroup.locationsSet.size
    orderGroup.productCount = orderGroup.productsSet.size

    Object.assign(orderGroup, getGroupStatus(orderGroup.orders))
    const states = getStates(orderGroup.orders, locationsById)
    orderGroup.funders = [...orderGroup.fundersSet]
      .filter(funderId => funderId)
      .map(funderId => funderId.replace('funder:', ''))

    orderGroup.routes = [...orderGroup.routesSet]
      .filter(routeId => routeId)
      .map(routeId => {
        const routeName = routeId.startsWith('route:')
          ? routeId.replace('route:', '')
          : routeId.replace('funder:', '')
        return routeName
      })

    // SL: can be 'routine' or 'topup', one group
    // is expected to have the same type
    orderGroup.orderType = orderGroup.orders.reduce((res, item) => item.orderType, '')

    orderGroup.suppliers = [...orderGroup.suppliersSet]
      .filter(supplierID => supplierID)
      .map(supplierID => {
        if (supplierID.includes('name')) {
          return supplierID.split(':name:')[1]
        }
        // For shelflife just pass the supplierID
        return supplierID
      })
    // If the order group was created by a state user,
    // the group will be orders for a single state.
    // If created by a high volume national user,
    // there will be many states and we don't want the group to have a state to display.
    orderGroup.state = states.length > 1
      ? 'Multi-State Orders'
      : states[0]
    delete orderGroup.locationsSet
    delete orderGroup.productsSet
    // delete orderGroup.orders
    delete orderGroup.suppliersSet
    delete orderGroup.fundersSet
    delete orderGroup.routesSet
    return orderGroup
  })
}

function getStates (orders, locationsById) {
  const states = orders
    .reduce((acc, order) => {
      const destination = locationsById[order.destinationId]
      if (!destination) {
        console.warn(`could not get state, location not found for order destination id ${order.destinationId}`)
      } else {
        acc.add(destination.location.state)
      }
      return acc
    }, new Set())
  return [...states]
}
