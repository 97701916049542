module.exports = (serviceId, target) => {
  let targetId = ''
  // Create a id for an alert target. This is used as part of the alert id
  switch (target.type) {
    case 'stock-count-target':
      targetId = `${target.reportId}:${target.productId}:${target.indicator}`
      break
    default:
      throw new Error('Unknown alert target: ' + target.type)
  }
  return `alert:${serviceId}:${targetId}`
}
