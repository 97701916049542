import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import WizardStep from './WizardStep'
import WizardError from './WizardError'

class Wizard extends PureComponent {
  static propTypes = {
    /**
     * The currently active step
     */
    currentStep: PropTypes.string,

    /**
     * An error object
     */
    error: PropTypes.object,

    /**
     * The error renderer
     */
    errorRenderer: PropTypes.func,

    /**
     * The Wizard's children (Wizard.Step's)
     */
    children: PropTypes.node.isRequired,

    headers: PropTypes.node
  }

  static defaultProps = {
    currentStep: 'export',
    error: null,
    errorRenderer: null
  }

  static Step = WizardStep
  static Error = WizardError

  render () {
    const {
      currentStep,
      error,
      children,
      errorRenderer,
      headers
    } = this.props

    /**
     * Iterate through children and create an index of Wizard.Steps
     */
    const steps = React.Children.toArray(children).reduce((accumulator, child) => {
      if (!child.type || (child.type && child.type.displayName !== 'Wizard.Step')) {
        throw new Error('Only <Wizard.Step> components are permitted as top-level children within <Wizard>.')
      }

      if (!child.props.name) {
        throw new Error('<Wizard.Step> components must have a unique \'name\' prop. Please check your markup.')
      }

      if (accumulator[child.props.name]) {
        throw new Error(`<Wizard.Step> components must have a unique 'name' prop. Multiple components with name='${child.props.name}' found. Please check your markup.`)
      }

      return {
        ...accumulator,
        [child.props.name]: { ...child }
      }
    }, {})

    if (error && errorRenderer) {
      return errorRenderer({ error })
    } else if (error) {
      return (
        <WizardError
          title='Error'
          bodyMessage={<p>Something went wrong.</p>}
          error={error}
        />
      )
    }
    const renderStep = steps[currentStep]
    if (!renderStep) {
      console.warn(`Wizard was invoked with non-existent step '${currentStep}'`)
      return null
    }
    return (
      <Fragment>
        {headers && headers}
        {renderStep}
      </Fragment>
    )
  }
}

export default Wizard
