const get = require('lodash/get')
const keyBy = require('lodash/keyBy')
const tools = require('../../tools')
const {getEnabledRouteDetails} = require('../../../location/tools')
const {isDiscontinued} = require('../../../product/tools')
const { DIRECT_ORDER_TYPES } = require('../../../allocation/config')
const { ORDER_TYPES } = require('../../constants')

exports.getLocationOrderDetails = getLocationOrderDetails
async function getLocationOrderDetails (
  state,
  mainApi,
  {
    locationId,
    deliveryDate,
    orderType,
    programId
  }
) {
  const location = await mainApi.location.get(locationId)
  const {serviceId} = getDefaultService(location)
  const service = await mainApi.service.get(serviceId)
  const allRoutes = await mainApi.routes.list()
  const routesById = keyBy(allRoutes, '_id')
  const parentLocationId = state.user.location.id

  const ordersForDeliveryDate = await state.dal.order.getOrdersForDeliveryDate(state, {
    programId,
    deliveryDate,
    parentLocationId
  })

  const {ledger, baseReport = {}, shipments} = await mainApi.stock.getLedgerBalance(
    {
      service,
      location: {...location, id: location._id},
      includeScheduledOutbound: true,
      withEntities: true
    }
  )
  const lastShipment = shipments.length
    ? shipments[shipments.length - 1]
    : {}

  const allocation = await mainApi.allocation.getConfiguration(
    {facilityId: locationId, includeProducts: true}
  )
  if (!allocation) throw new Error(`No Subscriptions found for location ${location._id}`)

  const forecast = await mainApi.forecast.get(locationId)
  const isExistingOrder = !!ordersForDeliveryDate.find(o => o.destinationId === locationId)
  // Filter out basic orders so we don't join them with other order types
  const filteredOrdersForDeliveryDate = ordersForDeliveryDate.filter(order => order.orderType !== ORDER_TYPES.immediate_purchase)
  const groupId = get(filteredOrdersForDeliveryDate, '0.groupId')
  const groupLocationsCount = tools.getUniqueLocationCount(filteredOrdersForDeliveryDate)

  const lastCountDate = get(baseReport, 'submittedAt')
  const lastDeliveryDate = get(lastShipment, 'updatedAt')
  const {routeName, visitDay, supplyWeeks} = getEnabledRouteDetails(location, routesById)

  const products = Object.values(allocation.products)
    .map(row => { return {product: row.product, forecast: row.forecast} })
    .filter(row => !isDiscontinued(row.product) && row.product)
    .map(row => {
      const subscriptionType = get(row.forecast, 'type')
      let subscriptionQuantity
      let lastClosing = get(ledger, `${row.product._id}.total`)
      let lastDelivery = get(lastShipment, `counts.${row.product._id}:manufacturer:unknown:batchNo:UNKNOWN.quantity`)
      let productForecast = get(forecast.products, row.product._id, {})
      let allocationType = get(productForecast, 'allocationType', 'allocation_missing')

      if (subscriptionType === DIRECT_ORDER_TYPES.PAY_ON_DELIVERY) {
        subscriptionQuantity = get(row.forecast, 'directOrder')
        lastClosing = null
        lastDelivery = null
        productForecast = {}
        allocationType = subscriptionType
      }

      const {buyPrice, available, defaultSupplierName} = tools.getProductMasterData(row.product)
      const qto = orderType === 'topup'
        ? productForecast.zeroStockAllocation
        : productForecast.allocation
      // TODO: if existing order, set quantity to adjusted
      const quantity = qto || 0
      return {
        ...row.product,
        buyPrice,
        available,
        defaultSupplierName,
        ...productForecast,
        allocationType,
        lastClosing,
        lastDelivery,
        quantity: subscriptionQuantity || quantity
      }
    })

  return {
    products,
    location,
    details: {
      routeName,
      visitDay,
      supplyWeeks,
      lastDeliveryDate,
      lastCountDate,
      isExistingOrder,
      groupId,
      groupLocationsCount
    }
  }
}

// REFACTOR: put this in location and use it in other places that do this
function getDefaultService (locationEntity) {
  const {id: serviceId, funderId} = get(locationEntity, 'programs.0.services.0', {})
  return {serviceId, funderId}
}
