const { PAYMENT_PLAN_OPTIONS_TABLE_NAME } = require('../constants')

const getPaymentOptionViaTxnIdQuery = (txnIdPlaceholder, companyCodePlaceholder, locationUuid = '') => {
  const locationArg = locationUuid ? ' and i.location_id = $3 ' : ''
  return `
    select
      po.id,
      il.order_id,
      po.service_fee_percentage::float,
      po.frequency,
      po.term::int,
      po.location_id,
      iq.invoice_txn_id,
      iq.company_code
    from avocado.data_invoice_quickbooks iq
    left join avocado.data_invoice i on iq.invoice_id = i.id
    left join avocado.data_invoice_line il on il.invoice_id = i.id
    inner join avocado.payment_plan_options po on po.order_id = il.order_id and po.location_id = i.location_id 
    where iq.invoice_txn_id = ${txnIdPlaceholder} and iq.company_code = ${companyCodePlaceholder} ${locationArg}
    group by 1, 2, 3, 4, 5, 6, 7, 8
  `
}

const getPamentPlanOptionQuery = `
  select
    id,
    order_id,
    service_fee_percentage::float,
    frequency,
    term::int,
    location_id,
    null as invoice_txn_id,
    null as company_code
  from ${PAYMENT_PLAN_OPTIONS_TABLE_NAME} where order_id = $1 and location_id = $2
`

module.exports = {
  getPaymentOptionViaTxnIdQuery,
  getPamentPlanOptionQuery
}
