const { parse, idify } = require('../../tools/smart-id')
const sanitiseManufacturer = require('../../utils/string-to-id')
const { batchIdToProductId, isBatchId, generateUnknownBatchForProduct } = require('../../shipment/tools/product-batch-util')

exports.sanitiseManufacturer = sanitiseManufacturer
exports.batchIdToProductId = batchIdToProductId
exports.isBatchId = isBatchId
exports.generateUnknownBatchForProduct = generateUnknownBatchForProduct

exports.sanitiseBatchNo = sanitiseBatchNo
function sanitiseBatchNo (batchNo) {
  return batchNo.split(/\s+/).join('').toUpperCase() // removes unwanted spaces and transforms it to uppercase-letters
}

exports.sanitiseBatchId = sanitiseBatchId
function sanitiseBatchId (batchId) {
  const batchIdParsed = parse(batchId)

  if (batchIdParsed.batchNo) {
    batchIdParsed.batchNo = sanitiseBatchNo(batchIdParsed.batchNo)
    return idify(batchIdParsed, 'product:manufacturer:batchNo')
  } else { return batchId }
}

exports.sanitiseBatchesForDuplicates = sanitiseBatchesForDuplicates
function sanitiseBatchesForDuplicates (docs) {
  let batchesMap = new Map()
  docs.forEach(batch => {
    const sanitisedBatchId = sanitiseBatchId(batch._id)
    batch._id = sanitisedBatchId
    batchesMap.set(sanitisedBatchId, batch)
  })
  return [...batchesMap.values()]
}

const isUnknownBatchId = (batchId) => !!batchId.toLowerCase().match('batchno:unknown')
exports.isUnknownBatchId = isUnknownBatchId

const isUnknownBatch = (batch) => isUnknownBatchId(batch.id)
exports.isUnknownBatch = isUnknownBatch

const batchNoFromId = batchId => {
  const batchIdParsed = parse(batchId)

  if (batchIdParsed.batchNo) {
    return sanitiseBatchNo(batchIdParsed.batchNo)
  }

  return ''
}
exports.batchNoFromId = batchNoFromId

exports.prepareBatchParams = prepareBatchParams
function prepareBatchParams (params = {}) {
  let { productId, manufacturer, batchNo, expiry } = params

  // Prepare input data:
  productId = productId && decodeURIComponent(productId)
  manufacturer = manufacturer && decodeURIComponent(manufacturer).split(/\s+/).join('-').toLowerCase()
  batchNo = batchNo && decodeURIComponent(batchNo) // batchNo allows spaces, etc

  return {
    productId,
    manufacturer,
    batchNo,
    expiry
  }
}

exports.toBatchShape = toBatchShape
function toBatchShape (doc, alias) {
  const mapped = {
    id: doc._id,
    manufacturer: doc.manufacturer,
    productId: doc.productId,
    expiry: doc.expiry
  }
  if (doc.alias) {
    mapped.alias = doc.alias
  }
  if (alias !== 'none') {
    if (doc.alias && doc.alias[alias]) {
      const {manufacturer, batchNo} = parse(mapped.id)
      mapped.id = `product:${doc.alias[alias]}:manufacturer:${manufacturer}:batchNo:${batchNo}`
    }
  }

  return mapped
}
