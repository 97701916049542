import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Checkmark } from '../../icons'

const ProgressStepMarker = (props) => {
  const {
    isCompleted,
    isActive,
    className
  } = props
  return (
    <span
      className={classnames(
        'vs-progress-step-marker',
        {'vs-progress-step-marker--done': isCompleted},
        {'vs-progress-step-marker--active': isActive},
        className
      )}
    >
      {isCompleted && (
        <span className='vs-progress-step-marker__icon'>
          <Checkmark />
        </span>
      )}
    </span>
  )
}

ProgressStepMarker.propTypes = {
  isCompleted: PropTypes.bool,
  isActive: PropTypes.bool,
  /**
   * In case you want to extend the default class names without overriding the default look.
   * e.g adding custom padding class `className='add-padding'`
   */
  className: PropTypes.string
}

ProgressStepMarker.defaultProps = {
  isCompleted: false,
  isActive: false,
  className: undefined
}

export default ProgressStepMarker
