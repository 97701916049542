const validateService = require('../../../utils/validate-service')

function prepareProgramsToSave (programs, programId, service, username = '') {
  const programsToSave = Object.assign({}, programs, {
    updatedBy: username,
    updatedAt: new Date().toISOString()
  })
  const IndexToUpdate = programsToSave.programs.findIndex(element => element.id === programId)
  if (programsToSave.programs[IndexToUpdate].services.findIndex(element => element.id === service.id) > -1) {
    throw new Error(`Service already exists`)
  }
  programsToSave.programs[IndexToUpdate].services.push(service)
  return programsToSave
}

function prepareConfigToSave (service, username = '') {
  return {
    _id: `configuration:${service.id}`,
    service: service.id,
    type: 'configuration',
    products: [],
    packages: [],
    createdBy: username,
    createdAt: new Date().toISOString()
  }
}

exports.addService = async function (state, programId, service) {
  validateService(service)
  if (!programId) throw new Error('\'programId\' is required')
  if (!service.id.startsWith(`${programId}:service`)) {
    throw new Error(`Service Id needs to start with the Id of the program you're adding it to`)
  }
  const programsDoc = await state.dal.program.getPrograms(state)
  if (!programsDoc['programs'].find(element => element.id === programId)) {
    const error = new Error(`program with ID'${programId}' doesn't exist`)
    error.status = 404
    throw error
  }

  const username = state.user ? state.user.name : ''
  const configDoc = prepareConfigToSave(service, username)
  const doc = prepareProgramsToSave(programsDoc, programId, service, username)

  await state.dal.configuration.save(state, configDoc)
  return state.dal.program.saveService(state, doc, service)
}
