const constructLineItem = ({
  description,
  amount,
  postingType, // Debit or Credit
  entity,
  accountId,
  accountName
}) => {
  const lineItem = {
    Description: description,
    Amount: amount,
    DetailType: 'JournalEntryLineDetail',
    JournalEntryLineDetail: {
      PostingType: postingType,
      Entity: entity,
      AccountRef: {
        value: accountId,
        name: accountName
      }
    }
  }
  return lineItem
}

module.exports = {
  constructLineItem
}
