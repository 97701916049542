const {
  transFormOriginPriceQuery,
  transformDeleteDestinationRowsQuery,
  tranformExistingDestinationRowsQuery,
  transformDeleteBuyPriceDailyRowsQuery,
  transformDeleteSellPriceDailyRowsQuery
} = require('./queries')

async function priceHistoryJob ({data, pgConnection}) {
  const {
    from_date: fromDate,
    to_date: toDate,
    from_market: fromMarket,
    to_market: toMarket
  } = data

  let nulledPriceRows, replicatedPriceRows, deletedPriceRows

  const tableName = 'real.price_history'

  const tranformedExistingDestinationRowsQuery = tranformExistingDestinationRowsQuery(tableName)

  try {
    const { rowCount: nulledPriceRowsCount } = await pgConnection.query(tranformedExistingDestinationRowsQuery, [fromDate, toDate, toMarket])
    nulledPriceRows = nulledPriceRowsCount
  } catch (e) {
    throw new Error('Error nulling exisitng destination price rows', e)
  }

  const tranformedOriginPricesQuery = transFormOriginPriceQuery()

  try {
    const { rowCount: replicatedPriceRowsCount } = await pgConnection.query(tranformedOriginPricesQuery, [fromDate, toDate, fromMarket, toMarket])
    replicatedPriceRows = replicatedPriceRowsCount
  } catch (e) {
    throw new Error('Error creating new price rows', e)
  }

  const transformedDeleteDestinationRowsQuery = transformDeleteDestinationRowsQuery(tableName)
  try {
    const { rowCount: deletedPriceRowsCount } = await pgConnection.query(transformedDeleteDestinationRowsQuery, [fromDate, toDate, toMarket])
    deletedPriceRows = deletedPriceRowsCount
  } catch (e) {
    throw new Error('Error deleting null price rows', e)
  }

  const transformedDeleteBuyPriceDailyRowsQuery = transformDeleteBuyPriceDailyRowsQuery()
  const transformedDeleteSellPriceDailyRowsQuery = transformDeleteSellPriceDailyRowsQuery()

  try {
    await pgConnection.query(transformedDeleteBuyPriceDailyRowsQuery, [toDate, toMarket])
    await pgConnection.query(transformedDeleteSellPriceDailyRowsQuery, [toDate, toMarket])
  } catch (e) {
    throw new Error('Error deleting price daily rows', e)
  }

  return {
    nulledPriceRows: Number(nulledPriceRows),
    replicatedPriceRows: Number(replicatedPriceRows),
    deletedPriceRows: Number(deletedPriceRows)
  }
}

module.exports = priceHistoryJob
