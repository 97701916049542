import React from 'react'

const Confetti = () => (
  <svg>
    <defs>
      <pattern id='confetti' x='0' y='0' width='338' height='577' patternUnits='userSpaceOnUse'>
        <path d='M307.676 253.854l5.732 4.165c.7.51.995 1.41.727 2.235l-2.19 6.74c-.268.824-1.035 1.382-1.902 1.382h-7.086c-.867 0-1.634-.558-1.902-1.382l-2.19-6.74c-.268-.824.026-1.726.727-2.236l5.732-4.166c.7-.51 1.65-.51 2.352 0zM11.676 561.854l5.732 4.165c.7.51.995 1.41.727 2.235l-2.19 6.74c-.268.824-1.035 1.382-1.902 1.382H6.957c-.867 0-1.634-.558-1.902-1.382l-2.19-6.74c-.268-.824.026-1.726.727-2.236l5.732-4.166c.7-.51 1.65-.51 2.352 0z' fill='#FFD166' />
        <path d='M14.676.854l5.732 4.165c.7.51.995 1.41.727 2.235l-2.19 6.74c-.268.824-1.035 1.382-1.902 1.382H9.957c-.867 0-1.634-.558-1.902-1.382l-2.19-6.74c-.268-.824.026-1.726.727-2.236L12.324.853c.7-.51 1.65-.51 2.352 0z' fill='#FB3677' />
        <path d='M156.183 16.96l-2.976 4.413c-.485.72-1.377 1.043-2.21.804l-5.116-1.467c-.832-.24-1.417-.987-1.447-1.853l-.186-5.32c-.03-.865.5-1.652 1.315-1.948l5.002-1.822c.814-.296 1.727-.035 2.26.648l3.277 4.195c.535.683.568 1.632.083 2.35zM14.277 399.907l2.955 5.12c.434.75.334 1.693-.245 2.337l-3.955 4.392c-.58.644-1.51.842-2.3.49l-5.4-2.405c-.79-.35-1.266-1.173-1.175-2.035l.618-5.878c.09-.862.725-1.567 1.573-1.748l5.78-1.23c.85-.18 1.716.206 2.15.957z' fill='#F80' />
        <path d='M258.464 421.188l-2.25 4.824c-.365.786-1.196 1.246-2.056 1.14l-5.283-.648c-.86-.106-1.554-.753-1.72-1.604l-1.015-5.225c-.165-.85.236-1.71.994-2.13l4.655-2.58c.757-.42 1.7-.306 2.333.285l3.893 3.63c.633.59.815 1.523.448 2.308z' fill='#50E3C2' />
        <path d='M89.464 538.188l-2.25 4.824c-.365.786-1.196 1.246-2.056 1.14l-5.283-.648c-.86-.106-1.554-.753-1.72-1.604l-1.015-5.225c-.165-.85.236-1.71.994-2.13l4.655-2.58c.757-.42 1.7-.306 2.333.285l3.893 3.63c.633.59.815 1.523.448 2.308z' fill='#F80' />
        <path d='M12.77 125.12l1.837 6.735c.29 1.066-.338 2.165-1.404 2.456-.34.094-.7.095-1.043.003l-6.797-1.82c-1.067-.287-1.7-1.383-1.414-2.45.09-.342.272-.654.523-.903l4.96-4.915c.784-.777 2.05-.772 2.828.013.243.245.42.55.51.88zM295.77 444.12l1.837 6.735c.29 1.066-.338 2.165-1.404 2.456-.34.094-.7.095-1.043.003l-6.797-1.82c-1.067-.287-1.7-1.383-1.414-2.45.09-.342.272-.654.523-.903l4.96-4.915c.784-.777 2.05-.772 2.828.013.243.245.42.55.51.88zM225.78 9.158l-2.7 6.44c-.426 1.018-1.598 1.498-2.616 1.07-.327-.136-.61-.357-.824-.64l-4.235-5.62c-.664-.882-.488-2.136.394-2.8.282-.213.616-.348.968-.39l6.933-.82c1.098-.13 2.093.655 2.222 1.752.04.343-.008.69-.14 1.008z' fill='#FFD166' />
        <path d='M42.197 369.225l4.96 4.915c.783.778.79 2.044.01 2.828-.248.252-.56.433-.902.524l-6.796 1.82c-1.068.287-2.164-.346-2.45-1.413-.092-.343-.09-.703.002-1.045l1.837-6.736c.29-1.066 1.39-1.694 2.455-1.404.333.09.637.266.882.51z' fill='#FB3677' />
        <path d='M277.197 535.225l4.96 4.915c.783.778.79 2.044.01 2.828-.248.252-.56.433-.902.524l-6.796 1.82c-1.068.287-2.164-.346-2.45-1.413-.092-.343-.09-.703.002-1.045l1.837-6.736c.29-1.066 1.39-1.694 2.455-1.404.333.09.637.266.882.51zM286.997 101.71l1.42-6.836c.226-1.082 1.285-1.776 2.367-1.55.346.07.668.234.93.47l5.23 4.708c.82.74.887 2.004.148 2.825-.237.263-.54.46-.877.567l-6.65 2.127c-1.052.338-2.178-.242-2.514-1.294-.104-.33-.123-.68-.053-1.017z' fill='#50E3C2' />
        <path d='M137.74 428.918l-2.243 6.61c-.354 1.047-1.49 1.607-2.536 1.252-.334-.113-.634-.314-.866-.58l-4.616-5.31c-.724-.835-.636-2.098.197-2.823.268-.232.592-.39.94-.455l6.86-1.302c1.084-.206 2.13.507 2.337 1.592.064.34.04.69-.07 1.016z' fill='#FFD166' />
        <path d='M15.78 212.01l-1.11 6.998c-.135.855-.807 1.527-1.662 1.662l-7 1.11c-.855.134-1.7-.297-2.094-1.07l-3.217-6.313c-.394-.772-.245-1.71.368-2.322l5.01-5.01c.613-.613 1.55-.762 2.322-.368l6.314 3.217c.773.393 1.204 1.24 1.07 2.095z' fill='#50E3C2' />
        <path d='M200.326 159.883l9.63 2.534c1.07.28 1.707 1.375 1.426 2.444-.09.343-.27.655-.52.906l-7.096 7.096c-.78.78-2.048.78-2.83 0-.25-.25-.428-.563-.52-.905l-2.533-9.63c-.28-1.07.357-2.163 1.425-2.444.334-.088.684-.088 1.018 0zM172.326 496.883l9.63 2.534c1.07.28 1.707 1.375 1.426 2.444-.09.343-.27.655-.52.906l-7.096 7.096c-.78.78-2.048.78-2.83 0-.25-.25-.428-.563-.52-.905l-2.533-9.63c-.28-1.07.357-2.163 1.425-2.444.334-.088.684-.088 1.018 0z' fill='#FB3677' />
        <path d='M88.82 76.047l6.982-.153c1.104-.024 2.02.85 2.043 1.956.008.354-.08.703-.25 1.013l-3.41 6.154c-.537.966-1.754 1.315-2.72.78-.31-.172-.57-.423-.75-.727l-3.57-6c-.563-.95-.25-2.178.698-2.742.296-.177.633-.273.978-.28zM337.896 210.757l-1.06 6.9c-.17 1.092-1.19 1.84-2.282 1.673-.35-.053-.68-.2-.955-.422l-5.468-4.428c-.86-.695-.99-1.954-.296-2.813.223-.275.515-.486.846-.61l6.53-2.474c1.032-.392 2.186.13 2.578 1.162.122.322.16.67.106 1.012z' fill='#F80' />
      </pattern>
    </defs>
    <rect x='0' y='0' width='100%' height='100%' fill='url(#confetti)' />
  </svg>
)

export default Confetti
