import { createElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import StatusCardLabel from './StatusCardLabel'
import StatusCardHeader from './StatusCardHeader'
import StatusCardContent from './StatusCardContent'
import StatusCardFooter from './StatusCardFooter'

/**
 * Extends Component so that propTypes show up in storybook, which they
 * otherwise won't when the component's outermost element is createElement
 */
class StatusCard extends Component {
  render () {
    const {
      target,
      component,
      className,
      children,
      noLabel,
      history,
      ...otherProps
    } = this.props

    const commonProps = {
      className: classnames(
        className,
        'vs-status-card',
        {'vs-status-card--tappable': target || component},
        {'vs-status-card--no-label': noLabel},
        className
      ),
      ...otherProps
    }

    let wrapperComponent = component
    let specificProps = {}

    if (target) {
      wrapperComponent = 'a'
      specificProps = {
        onClick: () => history.push(target)
      }
    }

    // if a wrapper hasn't been set yet, we assume it's an article
    if (!wrapperComponent) {
      wrapperComponent = 'article'
    }

    return createElement(
      wrapperComponent,
      {
        ...commonProps,
        ...specificProps
      },
      children
    )
  }
}

StatusCard.propTypes = {
  /**
   * Pass a `target` to make the status card tappable and redirect to the URL specified in `target`.
   * Without `target` or component, we render an article element
   */
  target: PropTypes.string,

  /**
   * Pass an optional alternative top level element to use as the status card, e.g. CrossLink.
   * Can be a Component, stateless function, or string corresponding to a default JSX element.
   */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * Optionally add an additional class name
   */
  className: PropTypes.string,

  /**
   * Content of the status card.
   * You can use the associated sub-components:
   * (StatusCardLabel, StatusCardHeader, StatusCardContent, StatusCardFooter)
   * or any other valid HTML content
   */
  children: PropTypes.node.isRequired
}

StatusCard.defaultProps = {
  target: undefined,
  component: undefined,
  className: '',
  children: undefined
}

StatusCard.Label = StatusCardLabel
StatusCard.Header = StatusCardHeader
StatusCard.Content = StatusCardContent
StatusCard.Footer = StatusCardFooter

export default StatusCard

export {
  StatusCardLabel,
  StatusCardHeader,
  StatusCardContent,
  StatusCardFooter
}
