async function callInBatches (promiseCreators, { batchSize = 10 } = {}) {
  const len = promiseCreators.length
  const results = []
  for (let i = 0; i < len; i += batchSize) {
    const promises = []
    for (let j = i; j < i + batchSize; ++j) {
      if (j >= len) {
        break
      }
      promises.push(promiseCreators[j]())
    }
    results.push(...await Promise.all(promises))
  }
  return results
}

module.exports = { callInBatches }
