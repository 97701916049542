const { sha512 } = require('./../../tools/hash')

module.exports = createMandate
async function createMandate (mandateOptions) {
  const {
    payerName,
    payerEmail,
    payerPhone,
    payerBankCode,
    payerAccount,
    amount,
    startDate,
    endDate,
    mandateType,
    maxNoOfDebits
  } = mandateOptions

  const {merchantId, apiKey, serviceTypeId} = this

  const requestId = this.generateRequestId()
  const hash = await sha512(merchantId + serviceTypeId + requestId + amount + apiKey)

  const remitaCreateMandateRequestBody = {
    merchantId,
    serviceTypeId,
    requestId,
    hash,
    payerName,
    payerEmail,
    payerPhone,
    payerBankCode,
    payerAccount,
    amount: '' + amount,
    startDate,
    endDate,
    mandateType,
    maxNoOfDebits: '' + maxNoOfDebits
  }

  const remitaCreateMandateResponse = await this.fetch(this.endpoints.createMandate, {
    method: 'POST',
    body: JSON.stringify(remitaCreateMandateRequestBody)
  })
  return {remitaCreateMandateRequestBody, remitaCreateMandateResponse}
}
