const createAlertDoc = require('./create-alert-doc')
const createStatusDoc = require('./create-status-doc')
const createAlertId = require('./create-alert-id')
const getAlert = require('./get-alert')

module.exports = async (state, options) => {
  const db = state.alertsDb
  // Check if the alert already exists
  const alertId = createAlertId(options.serviceId, options.target)
  const alert = await getAlert(state, alertId)
  if (alert) {
    // re-open a closed alert when an existing alert was found
    if (alert.status === 'closed' && options.target.indicator) {
      const statusDoc = createStatusDoc({
        alertId: alertId,
        userId: options.userId,
        deviceId: options.deviceId,
        clock: alert.clock,
        status: 'open'
      })
      const resp = await db.put(statusDoc)
      if (resp.error) {
        throw new Error(JSON.stringify(resp))
      }
    }
    return alert.id
  }
  // Create a new alert
  const doc = createAlertDoc(options)
  const resp = await db.put(doc)
  if (resp.error) {
    throw new Error(JSON.stringify(resp))
  }
  return doc.alertId
}
