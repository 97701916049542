/** This module contains functions to compare allocations for equality
 */

const {methods} = require('./config')

const areAllocationsEqual = (a, b) => {
  return areProductDictsEqual(a.products, b.products) &&
    areProductDictsEqual(a.aggregatedProducts, b.aggregatedProducts)
}

const areProductDictsEqual = (a, b) => {
  if (!a && !b) {
    return true
  }
  // Check if either one is an empty dictionary, which we
  // treat as equal to a dictionary being null.
  if (!a && b && Object.keys(b).length === 0) {
    return true
  }
  if (!b && a && Object.keys(a).length === 0) {
    return true
  }
  if (!a || !b) {
    return false
  }
  const keysA = Object.keys(a)
  const keysB = Object.keys(b)
  // Check both have the same amount and types of products
  if (!(keysA.length === keysB.length &&
        keysA.every(key => !!b[key]))) {
    return false
  }
  const notEqual = []
  const equal = keysA.every(key => {
    const eq = areProductsEqual(a[key], b[key])
    if (!eq) {
      notEqual.push(key)
    }
    return eq
  })
  return equal
}

const forecastComperators = {
  [methods.TP]: (a, b) =>
    areNumbersApprox(a.targetPopulation, b.targetPopulation) &&
    areNumbersApprox(a.coverageFactor, b.coverageFactor) &&
    areNumbersApprox(a.wastageFactor, b.wastageFactor) &&
    areNumbersApprox(a.adjustmentFactor, b.adjustmentFactor),

  [methods.BUNDLED]: (a, b) =>
    areNumbersApprox(a.factor, b.factor) &&
    areArraySetsEqual(a.dependentProducts, b.dependentProducts),

  [methods.CONSUMPTION]: (a, b) =>
    areNumbersApprox(a.annualAllocation, b.annualAllocation),

  [methods.FLAT]: (a, b) =>
    areNumbersApprox(a.daysToAverage, b.daysToAverage),

  [methods.DIRECT_ORDER]: (a, b) =>
    areNumbersApprox(a.directOrder, b.directOrder)
}

const areProductsEqual = (x, y) => {
  const a = x.forecast
  const b = y.forecast
  if (a.method !== b.method) {
    throw new Error('Cannot compare forecasts of different methods ' + a.method + ', ' + b.method)
  }
  const areEqual = forecastComperators[a.method]
  if (!areEqual) {
    throw new Error('Cannot compare forecasts of method ' + a.method)
  }
  if (!areEqual(a, b)) {
    return false
  }
  const u = x.supplyPlan
  const v = y.supplyPlan
  if ((u && !v) || (!u && v)) {
    return false
  }
  if (u && v) {
    if (!(areNumbersApprox(u.bufferDays, v.bufferDays) &&
          areNumbersApprox(u.leadTimeDays, v.leadTimeDays) &&
          areNumbersApprox(u.supplyPeriodDays, v.supplyPeriodDays))) {
      return false
    }
  }
  return true
}

/** Compare if two numbers are approximately equal
 *
 * Floating point numbers are an approximation and we have to
 * deal with cases where the aggregation will generate numbers
 * that are similar enough, to be seen as the same, but actually
 * differ by some amount. So we allow for some difference when
 * comparing them.
 */
const areNumbersApprox = (a, b) =>
  (!a && !b) || Math.abs(a - b) < 0.00001

/** Compare if two arrays have the same values ignoring order
 */
const areArraySetsEqual = (a, b) => {
  const x = new Set(a)
  const y = new Set(b)
  return x.size === y.size && [...x].every(n => y.has(n))
}

module.exports = {
  areProductsEqual,
  areAllocationsEqual,
  areArraySetsEqual,
  areNumbersApprox
}
