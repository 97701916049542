const tools = require('../../tools')
const { getProducts, getRelatedLocations } = require('../master-data')
const { getGroup } = require('../read/get')
const { getAllSuppliersBatch } = require('../read/get-all-suppliers-batch')

// This is only called in above advanceStatus, export is for tests.
exports.createWarehouseSuborders = async function (state, {groupId, dryRun, date}) {
  tools.validateParams('createWarehouseSuborders', arguments[1])
  const status = tools.getUserStatus(state.user)
  const {orders, programId} = await getGroup(state, {groupId, withSnapshots: true})

  // Get all locations, so we have supplieres and SDPs
  const [allLocations, products] = await Promise.all([
    getRelatedLocations(state, programId, date),
    getProducts(state, programId)
  ])
  // // Get list of our SPD IDs for the orders
  const locationIds = tools.getDestinationIdsOnOrders(orders, groupId)
  // Get our suppliers for those SDPs
  const suppliers = await getAllSuppliersBatch(state, allLocations, locationIds)

  const updatedSnapshots = tools.createWarehouseSuborders({
    orders,
    suppliers,
    allLocations,
    programId,
    user: state.user,
    products
  })

  if (!dryRun) await state.dal.order.bulkDocs(state, updatedSnapshots)

  const updatedOrders = tools.ordersFromSnapshotUpdates(
    {updatedSnapshots, status, orders, locationId: state.user.location.id}
  )
  return {orders: updatedOrders}
}
