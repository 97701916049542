const addDays = require('date-fns/add_days')
const format = require('date-fns/format')

class AutoInvoiceMissingError extends Error {
  /**
   * @param {string} pgInvoiceId
   */
  constructor (pgInvoiceId) {
    super(`No auto invoice found`)
    this.pgInvoiceId = pgInvoiceId
  }
}

class AutoInvoiceNoCustomerError extends Error {
  /**
   * @param {string} pgInvoiceId
   */
  constructor (pgInvoiceId) {
    super(`No customer found for auto invoice`)
    this.pgInvoiceId = pgInvoiceId
  }
}

class AutoInvoiceNoLocationCodeError extends Error {
  /**
   * @param {string} pgInvoiceId
   */
  constructor (pgInvoiceId) {
    super(`No location code found for auto invoice`)
    this.pgInvoiceId = pgInvoiceId
  }
}

class AutoInvoicePrevFailedError extends Error {
  /**
   * @param {string} pgInvoiceId
   */
  constructor (pgInvoiceId) {
    super(`Previous invoice had failed`)
    this.pgInvoiceId = pgInvoiceId
  }
}

class AutoInvoiceQboInvoiceConflictError extends Error {
  /**
   * @param {string} txnId
   */
  constructor (txnId) {
    super(`Quickbooks invoice conflict`)
    this.txnId = txnId
  }
}

class AutoInvoiceQboCreditConflictError extends Error {
  /**
   * @param {string} txnId
   */
  constructor (txnId) {
    super(`Quickbooks credit conflict`)
    this.txnId = txnId
  }
}

class AutoInvoiceApi {
  constructor (pgInvoiceApi, quickbooksApi) {
    this.pgInvoiceApi = pgInvoiceApi
    this.quickbooksApi = quickbooksApi
  }

  async createInvoice ({
    invoice,
    lines = [],
    qboRecord,
    hasCustomVatLine
  }) {
    if (lines.length === 0) {
      return
    }

    if (qboRecord.invoiceTxnId && !qboRecord.invoiceDeleted) {
      throw new AutoInvoiceQboInvoiceConflictError(qboRecord.invoiceTxnId)
    }

    let customField = ''
    let dueInDays = 1
    if (!lines.every(l => l.isFee)) {
      // invoices for classic and classic-payg-only customers are due in 3 days
      if (['classic', 'classic-payg-only'].includes(invoice.membershipType)) {
        dueInDays = 3
      }

      if (lines.some(l => l.orderInvoiceType === 'pay_on_delivery')) {
        customField = 'POD'
      } else if (['classic-payg-only'].includes(invoice.membershipType)) {
        customField = 'PAYG'
      }
    }

    invoice.dueDate = format(addDays(invoice.date, dueInDays), 'YYYY-MM-DD')

    const qboInvoice = await this.quickbooksApi.entities.invoice.create({
      invoice: { ...invoice, customField },
      lines,
      hasCustomVatLine
    })

    await this.pgInvoiceApi.updateQboRecordInvoiceId(qboRecord.id, qboInvoice.Id)

    await this.quickbooksApi.transform.transformAndSave(
      invoice.companyCode, { Invoice: [qboInvoice] }
    )

    await this.quickbooksApi.entities.invoice.sendEmail({
      companyCode: invoice.companyCode,
      invoice: qboInvoice,
      ccEmails: invoice.ccEmails
    })

    return qboInvoice
  }

  async createCreditMemo ({
    creditMemo,
    lines = [],
    qboRecord,
    hasCustomVatLine
  }) {
    if (lines.length === 0) {
      return
    }
    if (qboRecord.creditMemoTxnId && !qboRecord.creditMemoDeleted) {
      throw new AutoInvoiceQboCreditConflictError(qboRecord.creditMemoTxnId)
    }

    const qboCreditMemo = await this.quickbooksApi.entities.creditMemo.create({
      creditMemo,
      lines,
      hasCustomVatLine
    })
    await this.pgInvoiceApi.updateQboRecordCreditId(qboRecord.id, qboCreditMemo.Id)

    await this.quickbooksApi.transform.transformAndSave(
      creditMemo.companyCode, { CreditMemo: [qboCreditMemo] }
    )

    await this.quickbooksApi.entities.creditMemo.sendEmail({
      companyCode: creditMemo.companyCode,
      creditMemo: qboCreditMemo
    })

    return qboCreditMemo
  }

  async applyCreditMemo ({ companyCode, qboCreditMemo, shipmentIds = [] }) {
    if (qboCreditMemo == null || shipmentIds.length === 0) {
      return
    }

    const qboInvoiceTxns = await this.pgInvoiceApi.getUnpaidQboInvoiceTxns(shipmentIds)

    if (qboInvoiceTxns.length === 0) {
      return null
    }
    const invoice = qboInvoiceTxns[0]

    const outstanding = invoice.amount - invoice.amountPaid
    if (outstanding === 0) {
      return
    }
    const credit = Math.min(outstanding, qboCreditMemo.TotalAmt)

    const qboPayment = await this.quickbooksApi.entities.payment.create({
      payment: {
        totalAmount: 0,
        companyCode,
        customerId: qboCreditMemo.CustomerRef.value
      },
      lines: [
        {
          type: 'Invoice',
          id: invoice.txnId,
          amount: credit
        },
        {
          type: 'CreditMemo',
          id: qboCreditMemo.Id,
          amount: credit
        }
      ]
    })
    return qboPayment
  }

  async pgInvoiceToQuickbooks (
    pgInvoiceId,
    { autoApplyCredit = false, customVatLineCountries = [] } = {}
  ) {
    const {
      invoice,
      lines
    } = await this.pgInvoiceApi.invoiceToQbo(pgInvoiceId)

    if (!invoice) {
      throw new AutoInvoiceMissingError(pgInvoiceId)
    }
    if (invoice.customerId == null) {
      throw new AutoInvoiceNoCustomerError(pgInvoiceId)
    }
    if (invoice.locationCode == null || invoice.locationCode === '') {
      throw new AutoInvoiceNoLocationCodeError(pgInvoiceId)
    }

    const prevFailed = await this.pgInvoiceApi.previousInvoiceOrCreditFailed({
      invoiceId: invoice.id,
      createdAt: invoice.createdAt,
      shipmentIds: invoice.shipmentIds
    })
    if (prevFailed) {
      throw new AutoInvoicePrevFailedError(pgInvoiceId)
    }

    const hasCustomVatLine = customVatLineCountries.includes(invoice.companyCode)

    const debitLines = lines.filter(l => l.bookingType === 'debit')
    const creditLines = lines.filter(l => l.bookingType === 'credit')

    const qboRecord = await this.pgInvoiceApi.getOrCreateQboInvoiceRecord(
      invoice.id, invoice.companyCode
    )

    const qboInvoice = await this.createInvoice({
      invoice,
      lines: debitLines,
      qboRecord,
      hasCustomVatLine
    })

    const qboCreditMemo = await this.createCreditMemo({
      creditMemo: invoice,
      lines: creditLines,
      qboRecord,
      hasCustomVatLine
    })

    let qboPayment = null
    if (autoApplyCredit) {
      qboPayment = await this.applyCreditMemo({
        companyCode: invoice.companyCode,
        qboCreditMemo,
        shipmentIds: invoice.shipmentIds
      })
    }

    return {
      qboInvoice,
      qboCreditMemo,
      qboPayment
    }
  }
}

module.exports = {
  AutoInvoiceApi,
  errors: {
    AutoInvoiceMissingError,
    AutoInvoiceNoCustomerError,
    AutoInvoiceNoLocationCodeError,
    AutoInvoicePrevFailedError,
    AutoInvoiceQboInvoiceConflictError,
    AutoInvoiceQboCreditConflictError
  }
}
