module.exports = getSnapshotId

const internals = getSnapshotId.internals = {}
internals.generateShipmentNumber = require('./generate-shipment-number')
internals.getDateTimestamp = require('./get-date-timestamp')
const toLocationId = require('./to-location-id')

function getSnapshotId (state, options) {
  const date = internals.getDateTimestamp(options.date)
  const shipmentNo = options.shipmentNo || internals.generateShipmentNumber()
  const status = options.status || 'new'
  const origin = toLocationId(options.origin)
  const destination = toLocationId(options.destination)

  return [
    'origin', origin,
    'destination', destination,
    'date', date,
    'shipmentNo', shipmentNo,
    'status', status,
    'agent', state.device.id
  ].join(':')
}
