const get = require('lodash/get')
const { getGenericTotals } = require('./generic-utils')

exports.listProductsForWarehouse = listProductsForWarehouse
function listProductsForWarehouse ({productRows = [], currentStock = {}}) {
  return productRows.map(row => {
    const currentStockForProduct = currentStock[row._id] || 0
    const insufficient = row.adjusted > currentStockForProduct
    return Object.assign(row, {currentStock: currentStockForProduct, insufficient})
  })
}

// this is a throw away function to give PSM a view of which products have
// more reserved stock ("bulk allocation") than actual batches.
// it can go away once they have a stock report working on warehouse locations.
exports.findStockReservationErrors = findStockReservationErrors
function findStockReservationErrors ({allocatedStock, ledger, productsById}) {
  const flattenedLedger = Object.keys(ledger)
    .reduce((acc, productId) => {
      acc[productId] = get(ledger, `${productId}.total`, 0)
      return acc
    }, {})
  const genericLedger = getGenericTotals({stock: flattenedLedger, productsById})
  const byProductId = Object.keys(allocatedStock)
    .reduce((acc, productId) => {
      const allocatedQuantity = allocatedStock[productId]
      const actualQuantity = genericLedger[productId] || 0
      if (allocatedQuantity > 0 && allocatedQuantity > actualQuantity) {
        const productName = productsById[productId] && productsById[productId].fullName
        acc[productId] = {actualQuantity, allocatedQuantity, productId, productName}
      }
      return acc
    }, {})

  return Object.values(byProductId)
}
