import { createSlice } from '@reduxjs/toolkit'

import { getTimestampForNextApiCall } from '../common/utils/redux-timestamp-call'

// 24h
const MINUTES_TO_ADD = 1440

const initialState = {
  timestampForTheNextApiCall: getTimestampForNextApiCall(MINUTES_TO_ADD)
}

export const userSessionSlice = createSlice({
  name: 'userSession',
  initialState,
  reducers: {
    timestampUpdate: (state) => {
      state.timestampForTheNextApiCall = getTimestampForNextApiCall(MINUTES_TO_ADD)
    }
  }
})

export const { timestampUpdate } = userSessionSlice.actions
// Temporary check, should be removed mid-september. This ensures that users who do not have the redux state yet are not going to see errors.
export const selectTimestampToNextSessionCall = (state) => state.userSession ? state.userSession.timestampForTheNextApiCall : getTimestampForNextApiCall(MINUTES_TO_ADD)
export const selectUserSession = (state) => state.userSession

export default userSessionSlice.reducer
