const {SHELFLIFE_PROGRAM_ID, BASIC_TIER_SUFFIX} = require('./constants')

const isShelflifeService = (serviceId = '') => {
  // 2nd one is just a test program used in fixtures
  return serviceId.startsWith(SHELFLIFE_PROGRAM_ID) || serviceId.startsWith('program:new-id-shelflife')
}

const isBasicTierService = (serviceId = '') => {
  return isShelflifeService(serviceId) && serviceId.endsWith(BASIC_TIER_SUFFIX)
}

module.exports = {
  isShelflifeService,
  isBasicTierService
}
