import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Form from '../Form'
import StatusLabel from '../StatusLabel'
import StatusLabelButton from '../StatusLabelButton'
import Checkbox from '../Checkbox'
import AlertCalloutThread from './Thread/AlertCalloutThread'
import alertCommentPropType from './alert-comment-proptype'

const defaultFunction = () => null

class AlertCallout extends Component {
  static propTypes = {
    /** is this issue closed? Determines visual mode of the callout */
    closed: PropTypes.bool,
    /** function to run when user resolves the alert (by marking closed and saving) */
    onToggleClosed: PropTypes.func,
    /** use this to expand the callout message details on load */
    expanded: PropTypes.bool,
    /** function to run when user toggles the details */
    onToggleExpanded: PropTypes.func,
    /** function to run when user saves. Returns comment, if set */
    onSave: PropTypes.func,
    /** contents of the message */
    message: PropTypes.string.isRequired,
    /** an array of user comments for the issue */
    comments: PropTypes.arrayOf(alertCommentPropType),
    /** optional additional classname for the trigger wrapper */
    triggerWrapperClassName: PropTypes.string,
    /** optional additional classname for the details wrapper */
    detailsWrapperClassName: PropTypes.string,
    /** choose whether an overlay should be shown when details are expanded */
    showOverlay: PropTypes.bool,
    /** optional additional classname for the expanded overlay */
    overlayClassName: PropTypes.string
  }

  static defaultProps = {
    closed: false,
    onToggleClosed: defaultFunction,
    expanded: false,
    onToggleExpanded: defaultFunction,
    onSave: defaultFunction,
    comments: [],
    triggerWrapperClassName: '',
    detailsWrapperClassName: '',
    showOverlay: true,
    overlayClassName: ''
  }

  constructor (props) {
    super(props)

    this.state = {
      expanded: props.expanded,
      closed: props.closed,
      commentValue: ''
    }
  }

  commentForm = React.createRef()

  componentDidUpdate (nextProps, prevState) {
    const { onToggleExpanded, onToggleClosed } = this.props
    const currentState = this.state

    if (currentState.expanded !== prevState.expanded) onToggleExpanded(currentState.expanded)
    if (currentState.closed !== prevState.closed) onToggleClosed(currentState.closed)
  }

  toggleExpanded = () => {
    this.setState({
      ...this.state,
      expanded: !this.state.expanded
    })
  }

  discardComment = () => {
    this.setState({
      ...this.state,
      expanded: false,
      commentValue: ''
    })
  }

  toggleClosed = () => {
    this.setState({
      ...this.state,
      closed: !this.state.closed
    })
  }

  save = () => {
    const { onSave } = this.props
    const { commentValue } = this.state

    onSave(commentValue)

    this.setState({
      ...this.state,
      commentValue: ''
    })
  }

  updateCommentValue = (commentValue) => {
    this.setState({ commentValue })
  }

  render () {
    const { message, comments, triggerWrapperClassName, detailsWrapperClassName, showOverlay, overlayClassName } = this.props
    const { expanded, closed, commentValue } = this.state

    const isSaveable = commentValue
    const collapseLabel = isSaveable ? 'Reset and hide details' : 'Hide details'

    const statusLabelStatus = closed ? 'success' : 'warning'
    const statusLabelLabel = closed ? 'Closed' : 'Open'

    return (
      <div
        className={classnames(
          'fs-alert-callout',
          {
            'fs-alert-callout--mode-open': !closed,
            'fs-alert-callout--mode-closed': closed,
            'fs-alert-callout--is-expanded': expanded
          }
        )}
      >
        {showOverlay && expanded && (
          <span
            className={classnames(
              'fs-alert-callout__overlay',
              overlayClassName
            )}
            onClick={this.toggleExpanded} />
        )}
        <div
          className={classnames(
            'fs-alert-callout__trigger',
            triggerWrapperClassName
          )}
        >
          <StatusLabelButton
            status={statusLabelStatus}
            label={statusLabelLabel}
            pointer
            onClick={this.toggleExpanded}
          />
        </div>
        {expanded && (
          <div
            className={classnames(
              'fs-alert-callout__details-wrapper',
              detailsWrapperClassName
            )}
          >
            <div className='fs-alert-callout__details'>
              <div className='fs-alert-callout__detail-header'>
                <StatusLabel status={statusLabelStatus} label={statusLabelLabel} />
                <Checkbox
                  label='Close issue'
                  onChange={this.toggleClosed}
                  defaultChecked={closed}
                />
              </div>
              <div className='fs-alert-callout__detail-body'>
                <div className='fs-alert-callout__message'>
                  { message }
                </div>
                {comments && comments.length > 0 && (
                  <div className='fs-alert-callout__comments'>
                    <AlertCallout.Thread comments={comments} />
                  </div>
                )}
                <Form
                  ref={this.commentForm}
                  onSubmit={this.save}
                  // This is used next to input fields with alerts,
                  // so usually already inside a form
                  component='div'
                  className='fs-alert-callout__comments'
                >
                  <Form.Field
                    labelText='Add a comment'
                    fieldName='alert-comment'
                  >
                    <Form.Field.Text
                      rows={2}
                      value={commentValue}
                      onValueChange={value => this.updateCommentValue(value)}
                    />
                  </Form.Field>
                  <Form.Actions>
                    <Form.Button
                      size='small'
                      disabled={!isSaveable}
                    >
                      Save
                    </Form.Button>
                    <Form.Button
                      alignOpposite
                      variant='deemphasized'
                      onClick={this.discardComment}
                    >
                      { collapseLabel }
                    </Form.Button>
                  </Form.Actions>
                </Form>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

AlertCallout.Thread = AlertCalloutThread

export default AlertCallout
