const validateProduct = require('./../../../utils/validate-product')
const { validateGenericParent } = require('./../../../utils/validate-generic-product')
const { updateConfiguration } = require('./update-configuration')
const utils = require('./../../utils')

exports.save = async function (state, product, options) {
  const existingProduct = product._id
  const fixedProduct = utils.fixProductIds(product)
  validateProduct(fixedProduct, options)

  try {
    await validateGenericParent(state, product, options.validateAlias)
  } catch (err) {
    if (err.status === 404) {
      // db returns "%3A" instead of ":"
      const [, productCode] = err.docId.split('%3A')
      throw new Error(`Generic Parent "${productCode}" does not exist`)
    }
    throw new Error(err.message)
  }

  try {
    await updateConfiguration(state, product, options.updateConfiguration, existingProduct)
  } catch (e) {
    throw new Error(e.message)
  }

  return state.productsDB.put(product)
    .then(savedProduct => state.productsDB.get(savedProduct.id))
}
