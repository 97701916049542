import React, { Component } from 'react'
import { connect } from 'react-redux'

import withOverviews from './withOverview'
import Locations from './Locations'
import {withUser} from './../../../common/AuthenticationProvider'

class LocationsContainer extends Component {
  render () {
    const {
      date,
      locationId,
      overview,
      user,
      api,
      config,
      history,
      showCountSyncBanner,
      showShipmentSyncBanner
    } = this.props
    return (
      <Locations
        date={date}
        locationId={locationId}
        program={overview}
        isEditablePeriod={overview.periods.current.isEditable}
        childLocations={overview.locations.children}
        parentLocation={overview.locations.parent}
        progress={overview.progress}
        config={config}
        user={user}
        api={api}
        history={history}
        showCountSyncBanner={showCountSyncBanner}
        showShipmentSyncBanner={showShipmentSyncBanner}
      />
    )
  }
}

const mapStateToProps = (
  { overview: { overviews }, root: { config } },
  { match: { params: { programId, locationId, date } } }
) => {
  return {
    overview: overviews.find(o => o.id === programId),
    locationId,
    date,
    config
  }
}

export default withUser(withOverviews(
  connect(mapStateToProps)(LocationsContainer)
))
