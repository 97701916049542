const { EntityApi, PGAdapter } = require('../common')
const isPGUsageErrorCode = require('../common/isPGUsageErrorCode')
const fs = require('fs')
const path = require('path')
const STATS_CATEGORIES = require('../product/utils/market-data-recommendation-categories')

class LocationSummaryStatsAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = [
      'id',
      'location_id',
      'month',
      'sold_units',
      'total_sold_value',
      'sl_sold_value',
      'oos',
      'subs',
      'fill_rate',
      'value_delivered'
    ]
    super(pgConnection, 'avocado.data_location', username, columns, 'location_id', {}, logger)
  }

  // this adapter only does one thing; lists summary rows per month for a single location
  async getList ({ filter = {} } = {}) {
    if (!filter.location_id) {
      const err = new Error('LocationSummary requires a location_id param')
      err.status = 400
      throw err
    }

    const script = 'api/sql/stats/location_summary.sql'
    const buffer = await fs.promises.readFile(path.resolve(__dirname, '../../../' + script))
    const query = buffer && buffer.toString()
    let res
    try {
      const {rows} = await this.pgConnection.query(query, [filter.location_id])
      res = {results: rows}
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
    if (!res) {
      const err = new Error('No stats found')
      err.status = 404
      throw err
    }
    return res
  }

  async getOne () {
    throw new Error('getOne is unsupported')
  }

  async create () {
    throw new Error('create is unsupported')
  }
  async update () {
    throw new Error('update is unsupported')
  }
  async delete () {
    throw new Error('delete is unsupported')
  }
}

class StatsApi extends EntityApi {
  constructor (state, pgConnection, logger) {
    const { user = {} } = state
    const username = user.name
    const columns = ['market', 'sku', 'popularity_rank', 'speed_rank', 'earnings_rank']
    super((pgConnection && new PGAdapter(pgConnection, 'summaries.product_stats', username, columns, 'sku')) || {})
    this.pgConnection = pgConnection

    this.locationSummary = new LocationSummaryStatsAdapter({pgConnection, username, logger})
  }

  async getList ({ filter, limit = 10 } = {}) {
    const { category, market } = filter
    if (!STATS_CATEGORIES.includes(category)) {
      const err = new Error(`Unknown product_stats category ${category}`)
      err.status = 404
      throw err
    }

    const rank = `${category}_rank`
    const query = `
      select *
      from summaries.product_stats
      where lower(market) = $1
      order by ${rank}
      limit $2
    `

    const response = await this.pgConnection.query(query, [market, limit])
    if (!(response && response.rows && response.rows.length)) {
      const err = new Error(`No product stats found for category ${category} and market ${market}`)
      err.status = 404
      throw err
    }
    const results = response.rows.map(row => ({ sku: row.sku, rank: row[rank] }))
    return {
      count: results.length,
      results
    }
  }
}

module.exports = StatsApi
