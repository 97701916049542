exports.getOne = getOne
async function getOne (state, userId) {
  const user = await state.serverUserAdapter.get(userId)
  return user
}

exports.getAll = getAll
async function getAll (state, params = {}) {
  const { docs } = await state.serverUserAdapter.find(params)
  return docs
}
