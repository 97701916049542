import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Chevron } from '../../icons'
import CardTabsCard from './CardTabsCard'

/*
Expects an array of CardTabsCard components that can populate `./ui-components/card-card-tabs/card.js` (see comments in that file for details)
*/

const throttle = func => {
  let cancel

  return function () {
    clearTimeout(cancel)
    cancel = setTimeout(func, 100, arguments)
  }
}

class CardTabs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      scroll: 0,
      viewport: 0,
      slider: 0,
      card: 0
    }

    this.resizeHandler = throttle(this.resizeHandler)
  }

  componentDidMount () {
    this.resizeHandler() // Set initial state after mount
    window.addEventListener('resize', this.resizeHandler, false)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeHandler)
  }

  componentDidUpdate (prevProps) {
    // This is needed when we switch between views that don't have the same number of cards
    // like you're in a zonal VAN view and switch from store to agg view
    if (prevProps.children.length !== this.props.children.length) {
      this.resizeHandler()
    }
  }

  // Update buttons on resize
  resizeHandler = () => {
    const viewport = this.viewport.clientWidth // inside measurement
    const card = this.card.offsetWidth // outside measurement
    const slider = this.slider.scrollWidth // size needed for entire scroll pane
    this.setState({ viewport, card, slider })
  }

  // Update buttons on scroll
  scrollHandler = () => {
    const scroll = this.viewport.scrollLeft
    this.setState({ scroll })
  }

  // Scroll the viewport
  scroll = (e, direction) => {
    e.stopPropagation() // stop event from hitting something else below

    const { scroll, card } = this.state
    let left
    if (direction === 'left') {
      left = scroll - card
    } else {
      left = scroll + card
    }

    this.viewport.scroll({ top: 0, left, behavior: 'smooth' })
  }

  render () {
    const { children, className, ...otherProps } = this.props

    const leftButton = this.state.scroll > 0
    const rightButton = this.state.scroll + this.state.viewport < this.state.slider

    return (
      <div className={classnames('card-tabs', className)} {...otherProps}>
        {leftButton &&
          <button
            aria-label='Scroll Left'
            onClick={e => this.scroll(e, 'left')}
            className='card-tabs__button card-tabs__button--left'>
            <span className='card-tabs__button-icon'>
              <Chevron direction='left' />
            </span>
          </button>}
        <div className='card-tabs__viewport' onScroll={this.scrollHandler} ref={(el) => { this.viewport = el }}>
          <div ref={(el) => { this.slider = el }} className='card-tabs__slider'>
            { Children.map(children, child => {
              return (
                <div ref={(el) => { this.card = el }} className='card-tabs__item-wrapper'>
                  <div className='card-tabs__item'>{child}</div>
                </div>
              )
            })}
          </div>
        </div>
        {rightButton &&
          <button
            aria-label='Scroll Right'
            onClick={e => this.scroll(e, 'right')}
            className='card-tabs__button card-tabs__button--right'>
            <span className='card-tabs__button-icon'>
              <Chevron direction='right' />
            </span>
          </button>}
      </div>
    )
  }
}

CardTabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
}

CardTabs.Card = CardTabsCard

export default CardTabs
