/**
 * userIsAuthorised()
 *
 * Checks whether the given user has the relevent access level.
 *
 * For Example:
 *
 * userIsAuthorised({ roles: ['settings'] }, 'settings:users:readOnly')
 * => true
 *
 * userIsAuthorised({ roles: ['settings'] }, 'settings:users')
 * => true
 *
 * userIsAuthorised({ roles: ['settings'] }, 'settings')
 * => true
 *
 * userIsAuthorised({ roles: ['settings:users'] }, 'settings')
 * => false
 *
 * userIsAuthorised({ roles: ['settings:users'] }, 'settings:users')
 * => true
 *
 * Note about this next one: a limitation of userIsAuthorised is that "admin"
 * users are considered to also have `readOnly` roles, just because `readOnly`
 * is a subRole.
 * A workaround solution for this is to check `readOnly` roles explicitely with
 * `user.roles.includes('feature:settings:facilities:readonly'`
 *
 * userIsAuthorised({ roles: ['settings:users'] }, 'settings:users:readOnly')
 * => true
 *
 * @param {*} user - user object
 * @param {*} requiredRole - the required feature role
 */
export const userIsAuthorised = (user, requiredRole) => {
  if (!user) {
    console.warn('userIsAuthorised received no user param')
    return false
  }

  const { roles } = user
  // Return immediately if no roles
  if (!roles) return false

  // Create an array of potentially matching access roles
  const roleSet = requiredRole.split(':').reduce((acc, cur, index) => {
    if (index === 0) {
      return [
        cur
      ]
    }
    return [
      ...acc,
      `${acc[index - 1]}:${cur}`
    ]
  }, [])

  // Check roles for any matching roleSet rule
  return !!roles.find(role => roleSet.find(matchingRole => role === matchingRole))
}
