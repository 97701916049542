const XLSX = require('@sheet/core')

const merge = (a, b) => ([...a, ...b])

// getRef translates a zero-indexed pair of coordinates into a column reference
// i.e. 2, 1 => C2
const getRef = (x, y) => {
  return `${XLSX.utils.encode_col(x)}${y + 1}`
}

// getRange translates two zero-indexed coordinates into a column range
const getRange = (x1, y1, x2, y2) => {
  return `${getRef(x1, y1)}:${getRef(x2, y2)}`
}

const padArrayRight = (arr, target) => [...arr, ...Array.from({ length: target - arr.length }).map(() => '')]

const padArrayLeft = (arr, target) => [...Array.from({ length: target - arr.length }).map(() => ''), ...arr]

const createSumproductFormula = (...args) => `SUMPRODUCT(${args.join(',')})`

const createSumFormula = (x1, y1, x2, y2) => `SUM(${getRange(x1, y1, x2, y2)})`

const createMultiplicationFormula = (x1, y1, x2, y2) => `${getRef(x1, y1)}*${getRef(x2, y2)}`

const createModuloFormula = (range, rangeStart, n) => `MOD(COLUMN(${range})-COLUMN(${rangeStart}),${n})=0`

const createDifferenceFormula = (x1, y1, x2, y2) => {
  const ref1 = getRef(x1, y1)
  const ref2 = getRef(x2, y2)
  return `IF(AND(ISNUMBER(${ref1}), ISNUMBER(${ref2})), ${ref1}-${ref2}, "")`
}

module.exports = { merge, getRef, getRange, padArrayRight, padArrayLeft, createSumproductFormula, createSumFormula, createMultiplicationFormula, createModuloFormula, createDifferenceFormula }
