import React, {createRef} from 'react'
import PropTypes from 'prop-types'
import _last from 'lodash/last'
import { FieldContext } from '..'
import { requiredValidationErrorMessage } from '../../utils'
import FormFieldRegistrationManager from '../../FormFieldRegistrationManager'
import FileInputAdvanced from '../../../FileInputAdvanced'
/**
 *  Wrapper for input
 *
 *  Adds additional functionality such as validation
 */
const FileUploadAdvanced = ({
  accept,
  required,
  validate,
  onValueChange,
  defaultValue,
  ...otherProps
}) => {
  const inputRef = createRef()
  return (
    <FieldContext.Consumer>
      {
        (field) => {
          return (
            <FormFieldRegistrationManager
              register={() => {
                field.register({
                  required,
                  validate,
                  requiredValidator: async (value) => {
                    if (!value || value === '') {
                      return required && requiredValidationErrorMessage(required)
                    }
                  },
                  value: defaultValue,
                  ...otherProps
                })
              }}
              deregister={() => field.deregister()}
            >
              <div className='vs-file-upload--wrapper'>
                <FileInputAdvanced
                  ref={inputRef}
                  name={field.props.fieldName}
                  hasError={field.hasErrors()}
                  value={field.props.value}
                  accept={accept}
                  onValueChange={(value, files) => {
                    field.handleOnChange(value, files)
                    if (typeof onValueChange === 'function') {
                      onValueChange(value)
                    }
                  }}
                  onClearValue={(e) => {
                    e.preventDefault()
                    inputRef.current.value = ''
                    field.handleOnChange('', [])
                    if (typeof onValueChange === 'function') {
                      onValueChange('')
                    }
                  }}
                  required={required}
                  fileName={field.props.value ? _last(field.props.value.split('\\')) : null}
                  {...otherProps}
                />
              </div>
            </FormFieldRegistrationManager>
          )
        }
      }
    </FieldContext.Consumer>
  )
}

FileUploadAdvanced.propTypes = {
  /**
   * Whether the field is required, optionally pass a string to define a custom
   * validation error message
   */
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),

  /**
   * A custom validation function
   */
  validate: PropTypes.func,

  /**
   * Runs when field value changes (returns new value)
   */
  onValueChange: PropTypes.func,

  /**
   * Initial field value (used if form needs to be reset)
   */
  defaultValue: PropTypes.string
}

FileUploadAdvanced.defaultProps = {
  required: false,
  validate: undefined,
  defaultValue: ''
}

FileUploadAdvanced.displayName = 'Form.Field.FileUploadAdvanced'

export default FileUploadAdvanced
