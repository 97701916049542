const { v4 } = require('uuid')
const { mapInserts } = require('../../tools/sql-tools')
const {
  TABLE_NAMES: {
    PAYMENT_PLAN_TABLE_NAME,
    INVOICE_PAYMENTPLAN_TABLE_NAME,
    TRANSACTIONS_TABLE_NAME
  }
} = require('../constants')

// Run below queries in transaction so we can link payment plan to invoices on creation
const createPaymentPlanQuery = (paymentPlan) => {
  const paymentPlanInsert = [{
    id: paymentPlan.id,
    location_id: paymentPlan.location_id,
    start_date: paymentPlan.start_date,
    installment_amount: paymentPlan.installment_amount,
    past_due: paymentPlan.past_due,
    service_fee: paymentPlan.service_fee || 0,
    waive_service_fee: paymentPlan.waive_service_fee,
    frequency: paymentPlan.frequency,
    currency: paymentPlan.currency,
    term: paymentPlan.term,
    is_active: false,
    is_paid: false,
    company_code: paymentPlan.company_code,
    quickbooks_account_balance: paymentPlan.past_due,
    created_at: 'now()',
    updated_at: 'now()'
  }]
  const {fields, flattened: values, dollarSigns} = mapInserts(paymentPlanInsert)
  const query = `
      INSERT INTO ${PAYMENT_PLAN_TABLE_NAME} (${fields.join(', ')})
      VALUES ${dollarSigns} RETURNING *;`
  return {query, values}
}

const linkInvoicePaymentPlanQuery = (paymentPlanId, companyCode, qboTxnId) => {
  const id = v4()
  const insert = [{id, qbo_txn_id: qboTxnId, paymentplan_id: paymentPlanId, qbo_company_code: companyCode}]
  const {fields, flattened: values, dollarSigns} = mapInserts(insert)
  const query = `
    INSERT INTO ${INVOICE_PAYMENTPLAN_TABLE_NAME} (${fields.join(', ')})
    VALUES ${dollarSigns};`
  return {query, values}
}

const installmentPaymentPlanQuery = () => {
  return `
    SELECT
      pp.*,
      t.txn_id,
      t.company_code,
      t.quickbooks_doc_number,
      t.txn_type
    FROM ${INVOICE_PAYMENTPLAN_TABLE_NAME} ip
    LEFT JOIN ${PAYMENT_PLAN_TABLE_NAME} pp
      on ip.paymentplan_id = pp.id
    LEFT JOIN ${TRANSACTIONS_TABLE_NAME} t
      on ip.qbo_txn_id = t.txn_id AND ip.qbo_company_code = t.company_code
    WHERE ip.qbo_txn_id = $1 AND ip.qbo_company_code = $2 AND coalesce(ip._deleted, false) is not true
  `
}

const clearDeletedInvoicePlan = `
  DELETE FROM ${INVOICE_PAYMENTPLAN_TABLE_NAME} where qbo_txn_id = $1 and qbo_company_code = $2 and _deleted is true;
`

module.exports = {
  createPaymentPlanQuery,
  linkInvoicePaymentPlanQuery,
  installmentPaymentPlanQuery,
  clearDeletedInvoicePlan
}
