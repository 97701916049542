
const {
  TABLE_NAMES: {
    TRANSACTIONS_TABLE_NAME,
    PAYMENT_RELATION_TABLE_NAME,
    PAYMENT_INVOICE_TABLE_NAME
  }
} = require('../constants')

const singleTransactionQuery = `
 SELECT
       t.id,
       t.location_id,
       t.amount,
       t.amount_paid,
       t.txn_type,
       t.txn_date,
       t.quickbooks_doc_number,
       t.company_code,
       t.txn_id,
       t.order_type,
       t.due_date,
       coalesce(json_agg(r) filter (where r.id is not null), '[]') as payment_relations,
       coalesce(json_agg(p) filter (where p.id is not null), '[]') as payments
FROM ${TRANSACTIONS_TABLE_NAME} t
         LEFT JOIN ${PAYMENT_RELATION_TABLE_NAME} r
                   on t.txn_id = r.txn_id and t.company_code = r.company_code
         LEFT JOIN ${PAYMENT_INVOICE_TABLE_NAME} p
                   on p.id = r.payment_id
WHERE t.id = $1 AND t.location_id = $2
GROUP BY t.id
`

module.exports = singleTransactionQuery
