import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import withConfig from '../van-shared/hoc/withConfig'
import { withApi } from '../common/ApiProvider'
import Home from './Home'

class HomeContainer extends PureComponent {
  render () {
    const { location, config, user, api, history } = this.props
    const psmUserRequireFillEmail = (config.app !== 'shelflife' && !user.profile.email)
    return (
      <Home
        location={location}
        app={config.app}
        appName={config.name}
        user={user}
        api={api}
        config={config}
        history={history}
        psmUserRequireFillEmail={psmUserRequireFillEmail}
      />
    )
  }
}

HomeContainer.propTypes = {
  config: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  api: PropTypes.object
}

HomeContainer.defaultProps = {
  user: {}
}

export default withApi(withConfig(HomeContainer))
