import React from 'react'
import get from 'lodash/get'

import { Modal, StatusPill } from '@fielded/shared-ui'

const LMDListTableModal = ({
  shipment,
  modalVisibility,
  onCloseModal
}) => {
  const deliveryAlerts = get(shipment, 'deliveryAlerts', [])
  const shipmentNo = get(shipment, 'shipmentNo', '')

  return (
    <Modal
      centered={false}
      isOpen={modalVisibility}
      title={`Delivery alerts for ${shipmentNo}`}
      className='last-mile-deliveries__table-modal'
      onClose={onCloseModal}
    >
      <section className='vs-u-column vs-u-align-start vs-u-gap'>
        {deliveryAlerts.map(alert => (
          <StatusPill
            key={alert.label}
            className='last-mile-deliveries__table-modal-alert'
            label={alert.label}
            value=''
          />
        ))}
      </section>
    </Modal>
  )
}

export default LMDListTableModal
