import React from 'react'

import { Loading, Text } from '@fielded/shared-ui'
import { Truck } from '@fielded/shared-ui/src/icons'

const LMDListEmptyInfoBox = ({loading}) => {
  if (loading) {
    return (
      <div className='last-mile-deliveries__empty-info-box'>
        <Loading
          loadingText='Updating deliveries list...'
        />
      </div>
    )
  }

  return (
    <div
      className='last-mile-deliveries__empty-info-box vs-u-column-centered vs-u-gap-half'
    >
      <div className='last-mile-deliveries__empty-info-box-icon'><Truck direction='right' /></div>
      <Text bold>No last mile deliveries</Text>
      <Text size='small'>No last mile deliveries available. Check back later for updates.</Text>
    </div>
  )
}
export default LMDListEmptyInfoBox
