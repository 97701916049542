const { serializeForInsert } = require('../tools')

const TABLE_NAME = 'avocado.data_signup'
const DOCUMENTS_TABLE_NAME = 'avocado.data_documentmeta'

const notFoundError = () => {
  const err = new Error(`No result found`)
  err.status = 404
  return err
}

class SignupPGAdapter {
  constructor (pgConnection) {
    this.pgConnection = pgConnection
  }

  async getSignup (data) {
    const dataKeys = Object.keys(data)
    const whereCondition = dataKeys.map((val, key) => `${val} = $${key + 1}`).join(' AND ')
    const query = `SELECT * from ${TABLE_NAME} WHERE ${whereCondition}`
    const props = Object.values(data)
    const {rows} = await this.pgConnection.query(query, props)
    if (!rows.length) {
      throw notFoundError()
    }
    return rows[0]
  }

  async getSignupWithDocuments (userId) {
    const props = []
    // Get all rows in the signup table with an extra added column
    // containing the latest version of the documents uploaded by the user
    let query = `
      select signup.*, coalesce(documents.metadata, '[]') as documents
      from ${TABLE_NAME} as signup
      left join lateral(
        with user_documents as (
          select * from ${DOCUMENTS_TABLE_NAME} where created_by = cast(signup.id as text)
        ),
        user_latest_uploads as (
          select max(created_at) as created_at, data->>'fieldname' as fieldname
          from user_documents
          group by data->>'fieldname'
        )
        select json_agg(ud.data) AS metadata
        from user_documents ud
        right join user_latest_uploads ul
        on ud.data->>'fieldname' = ul.fieldname and ud.created_at = ul.created_at
      ) documents on true
    `
    if (userId) {
      query += 'WHERE "signup"."id" = $1'
      props.push(userId)
    }
    const {rows} = await this.pgConnection.query(query, props)
    if (!rows.length) {
      throw notFoundError()
    }
    return rows
  }

  async createSignUp (data) {
    const {insertProps, insertValues, parametrizedString} = serializeForInsert(data)

    // TODO: django's default "auto now" does not use postgres
    // so we have to manually create the timestamp.
    // When we ditch django we can change this to use postgres.
    // Same with uuid
    const query = `INSERT INTO ${TABLE_NAME}(${insertProps}) VALUES(${parametrizedString}) RETURNING *`
    const {rows} = await this.pgConnection.query(query, insertValues)
    return rows[0]
  }

  async updateSignup (id, data) {
    const dataKeys = Object.keys(data)
    const updateCondition = dataKeys.map((val, key) => `${val} = $${key + 1}`).join(', ')
    const query = `UPDATE ${TABLE_NAME} SET ${updateCondition} WHERE id=$${dataKeys.length + 1} RETURNING *`
    const props = Object.values(data)
    props.push(id)
    const {rows} = await this.pgConnection.query(query, props)
    return rows[0]
  }
}

module.exports = SignupPGAdapter
