import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

const Timeframe = ({
  title,
  prevPeriodUrl,
  nextPeriodUrl,
  handleClick,
  enableFutureDate
}) => {
  return (
    <div className='timeframe'>
      <Button
        component={Link}
        to={prevPeriodUrl}
        colorVariant='brand'
        fill='outline'
        icon={<Arrow direction='left' />}
      >
          Prev
      </Button>
      <div className='timeframe__info' onClick={handleClick}>
        <h2 className='timeframe__info-title'>Reporting period:</h2>
        <span className='timeframe__info-date'>{title}</span>
      </div>
      <Button
        component={enableFutureDate && Link}
        to={nextPeriodUrl}
        colorVariant='brand'
        fill='outline'
        icon={<Arrow direction='right' />}
        iconSide='right'
        disabled={!enableFutureDate}
      >
          Next
      </Button>
    </div>
  )
}

Timeframe.propTypes = {
  title: PropTypes.string,
  prevPeriodUrl: PropTypes.string,
  nextPeriodUrl: PropTypes.string,
  handleClick: PropTypes.func,
  enableFutureDate: PropTypes.bool
}

export default withRouter(Timeframe)
