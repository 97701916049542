const aggregateAlertDocs = require('./aggregate-alert-docs')

module.exports = async (state, id) => {
  const db = state.alertsDb
  const result = await db.allDocs({
    include_docs: true,
    startkey: id,
    endkey: `${id}:\uffff`
  })
  const docs = result.rows.map(row => row.doc)
  if (docs.length === 0) {
    return Promise.resolve(null)
  }
  return aggregateAlertDocs(docs)
}
