function time () {
  return new Date().getTime()
}
function log (label, logging, startTime) {
  if (!logging) {
    return
  }

  console.log(label, time() - startTime)
}

module.exports = { time, log }
