/* eslint-disable max-lines */
import React from 'react'

import { Loading } from '@fielded/shared-ui'
import { toast } from '@fielded/shared-ui/src/components/Page/Page'

import NewShipmentMetadata from './NewShipmentMetadata'
import NewShipmentProducts from './NewPSMShipmentProducts'
import NewShipmentProductsQuantity from './NewPSMShipmentProductsQuantity'
import NewShipmentBatchesForm from './NewShipmentBatchesForm'
import NewShipmentSummary from './NewShipmentSummary'
import { formatDate } from '../../../../van-shared/utils/utils'
import { withApi } from '../../../../common/ApiProvider'
import { withUser } from '../../../../common/AuthenticationProvider'
import withConfig from '../../../../van-shared/hoc/withConfig/withConfig'
import displayForm from './utils/displayForm'

const VIEWS = {
  metadata: 0,
  products: 1,
  quantity: 2,
  batches: 3,
  summary: 4
}

const INITIAL_STATE = {
  locations: null,
  products: null,
  loading: false,
  programs: null,
  view: VIEWS.metadata,
  origins: [{ id: 'country', name: 'External', level: 'country' }]
}

class NewPSMShipmentContainer extends React.Component {
  state = INITIAL_STATE

  async componentDidMount () {
    const { api, user, shipmentId } = this.props
    const programs = await api.program.list(true)
    const forecastingProduct = new URLSearchParams(window.location.search).get('product')
    const forecastingProgram = new URLSearchParams(window.location.search).get('program')
    const ENABLED_PROGRAMS = programs.map(program => program.id)
    // current rules of shipment creation, only origin is 'External', but for destination:
    // for national user - all zonal warehouses
    // for zonal user - cannot create shipment
    // for state store user - their own store
    if (!displayForm(user, ENABLED_PROGRAMS)) return
    this.setState({ loading: true })
    const isNationalUser = !!user.location.national
    const destinations = await api.location.listChildren(user.location.id, {
      includeSelf: true,
      filters: {
        levels: isNationalUser ? ['zone'] : []
      }
    })

    if (!destinations.length) return this.setState({ loading: false })

    const batches = await api.batch.list()
    const funders = await api.funders.list()
    const allProducts = {}
    const allLocations = {}
    await Promise.all(programs.map(async (program) => {
      const products = await api.product.listForPrograms([program.id])
      allProducts[program.id] = products
      allLocations[program.id] = {}
      allLocations[program.id].destinations = destinations.filter((d) => d.programs.some(({ id }) => id === program.id))
      allLocations[program.id].origins = this.state.origins
    }))
    const funderList = !user.funders.length ? funders : funders.filter(({ _id }) => user.funders.includes(_id))
    let shipment = {
      products: [],
      batches: [],
      newBatches: []
    }

    if (forecastingProduct || forecastingProgram) {
      shipment.products.push({
        _id: forecastingProduct
      })
      shipment.program = programs.find(program => program.id === forecastingProgram)
    }

    if (shipmentId) {
      const existingShipment = await api.shipment.findById(shipmentId)
      existingShipment.quantities = {}
      existingShipment.batches = []
      const productIds = Object.keys(existingShipment.counts).map(countId => {
        const productId = countId.split(':manufacturer')[0]
        const productQuantity = existingShipment.counts[countId].quantity

        if (!countId.includes('UNKNOWN')) {
          existingShipment.batches.push(batches.find(batch => batch._id === countId))
          existingShipment.batches.map(batch => {
            if (batch._id === countId) {
              batch['quantity'] = productQuantity
              batch['batchNumber'] = countId.split('batchNo:')[1]
            }
            return batch
          })
        } else {
          existingShipment.quantities[productId] = productQuantity
        }
        return productId
      })
      existingShipment.program = programs.find(program => program.id === existingShipment.programId)
      existingShipment.products = allProducts[existingShipment.program.id].filter(product => productIds.includes(product._id))
      existingShipment.destination = allLocations[existingShipment.program.id].destinations.find(destination => destination._id === existingShipment.destination.id)
      existingShipment.origin = this.state.origins[0]
      shipment = {
        ...shipment,
        ...existingShipment
      }
    }
    this.setState({
      batches,
      shipment,
      funders: funderList,
      programs,
      locations: allLocations,
      products: allProducts,
      loading: false
    })
  }

  cancel = () => {
    this.props.history.push('/shipments/list')
  }

  goBack = () => {
    const { view } = this.state
    const summaryView = view === 4
    this.setState({
      view: summaryView ? view - 2 : view - 1
    })
  }

  addBatches = (product) => {
    const { shipment } = this.state
    this.setState({
      shipment: {
        ...shipment,
        selectedProduct: product
      },
      view: 3
    })
  }

  validateProduct = (product) => {
    let errors = []
    if (!product.batch) errors.push('- Batch')
    if (!product.quantity || product.quantity < 0) errors.push('- Quantity')
    if (product.expiry <= new Date()) errors.push('- Expiry')
    return errors
  }

  saveMetadata = ({ destination, origin, type, date, funderId, program }) => {
    this.setState({
      shipment: {
        ...this.state.shipment,
        destination,
        origin,
        type,
        date,
        funderId,
        program
      },
      view: VIEWS.products
    })
  }

  addProduct = (id) => {
    const { shipment, products } = this.state
    const product = products[shipment.program.id].find(({ _id }) => _id === id)
    const existingProducts = shipment.products ? shipment.products : []
    return this.setState({
      shipment: {
        ...shipment,
        products: existingProducts.concat(product)
      }
    })
  }

  removeProduct = (id) => {
    const { shipment } = this.state
    return this.setState({
      shipment: {
        ...shipment,
        products: shipment.products.filter(({ _id }) => _id !== id),
        batches: shipment.batches.filter(({ productId }) => productId !== id)
      }
    })
  }

  toQuantityForm = () => {
    const { shipment } = this.state
    if (!shipment.products.length) return
    return this.setState({
      view: VIEWS.quantity
    })
  }

  addProductQuantity = (products) => {
    const { shipment } = this.state
    return this.setState({
      shipment: {
        ...shipment,
        quantities: products
      },
      view: VIEWS.summary
    })
  }

  pushBatchToContainer = (batch) => {
    const batchParams = batch.batchParams
    const { shipment } = this.state
    const existingBatchParams = shipment.newBatches.filter(b =>
      !(b.batchNo === batchParams.batchNo &&
        b.manufacturer && batchParams.manufacturer &&
        b.manufacturer.toLowerCase() === batchParams.manufacturer.toLowerCase() &&
        b.productId === batchParams.productId
      )
    )
    return this.setState({
      shipment: {
        ...shipment,
        newBatches: existingBatchParams.concat(batchParams)
      }
    })
  }

  saveBatchCount = (batchCount, productId) => {
    const { shipment } = this.state
    if (shipment.quantities) {
      delete shipment.quantities[productId]
    }
    return this.setState({
      shipment: {
        ...shipment,
        batches: shipment.batches.filter(b => b._id !== batchCount._id).concat({ ...batchCount, productId })
      }
    })
  }

  removeBatchCount = (batch) => {
    const { shipment } = this.state
    const filteredBatches = shipment.newBatches
      .filter((b) =>
        !(b.batchNo === batch.batchNumber &&
          b.manufacturer && batch.manufacturer &&
          b.manufacturer.toLowerCase() === batch.manufacturer.toLowerCase() &&
          b.productId === batch.productId)
      )
    return this.setState({
      shipment: {
        ...shipment,
        newBatches: filteredBatches,
        batches: shipment.batches
          .filter(b =>
            !(b.batchNumber === batch.batchNumber &&
              b.manufacturer && batch.manufacturer &&
              b.manufacturer.toLowerCase() === batch.manufacturer.toLowerCase() &&
              b.productId === batch.productId)
          )
      }
    })
  }

  submitNewOrder = async () => {
    const { shipment } = this.state
    const { api } = this.props
    const isEdit = shipment.rev
    const toastMessage = `${isEdit ? 'Updated' : 'New'} shipment to ${shipment.destination.name} created`

    try {
      const counts = {}
      if (shipment.newBatches.length) {
        const newBatches = await Promise.all(shipment.newBatches.map(api.batch.new))
        const newBatchNumbers = newBatches.map(({ _id }) => ({ batchNumber: _id.split(':').pop(), _id }))
        shipment.batches = shipment.batches.map((batch) => {
          const target = newBatchNumbers.find(({ batchNumber }) => batchNumber === batch._id)
          return {
            ...batch,
            _id: target ? target._id : batch._id
          }
        })
      }

      if (shipment.batches.length) {
        shipment.batches.forEach(({ _id, quantity }) => {
          counts[_id] = { quantity }
        })
        if (Object.keys(shipment.quantities).length > 0) {
          shipment.products.forEach(product => {
            if (shipment.quantities[product._id]) {
              const id = `${product._id}:manufacturer:chemonics:batchNo:UNKNOWN`
              counts[id] = {
                quantity: shipment.quantities[product._id]
              }
            }
          })
        }
      } else {
        shipment.products.forEach(product => {
          const id = `${product._id}:manufacturer:chemonics:batchNo:UNKNOWN`
          counts[id] = {
            quantity: shipment.quantities[product._id]
          }
        })
      }
      const doc = {
        origin: shipment.origin.id,
        destination: shipment.destination._id,
        planningType: shipment.planningType || 'routine',
        shipmentTypeId: shipment.shipmentType ? shipment.shipmentType.id : 'routine',
        date: formatDate(shipment.date, 'snapshotId'),
        programId: shipment.program.id,
        funderId: shipment.funderId ? shipment.funderId : undefined,
        counts
      }

      if (!isEdit) {
        doc.status = 'pre-advice'
        doc.removePaymentType = true
        await api.shipment.createSnapshot(doc)
      } else {
        doc.snapshotId = shipment.snapshotId
        doc.createdAt = shipment.createdAt
        doc.createdBy = shipment.createdBy.user
        doc._rev = shipment.rev
        await api.shipment.updateSnapshot(doc)
      }

      toast({
        title: toastMessage,
        type: 'success'
      })
      this.props.history.push('/shipments/list')
    } catch (e) {
      console.log(e)
      toast({
        title: `Something went wrong creating a shipment to ${shipment.destination.name}`,
        type: 'error'
      })
    }
  }

  render () {
    const {
      locations,
      products,
      shipment,
      view,
      funders,
      batches,
      programs
    } = this.state

    if (this.state.loading) return <Loading />
    if (!locations) return null

    let component
    if (view === VIEWS.metadata) {
      component = <NewShipmentMetadata
        locations={locations}
        handleSubmit={this.saveMetadata}
        cancel={this.cancel}
        funders={funders}
        programs={programs}
        shipment={shipment}
      />
    }
    if (view === VIEWS.products) {
      component = <NewShipmentProducts
        products={products}
        goBack={this.goBack}
        cancel={this.cancel}
        shipment={shipment}
        addProduct={this.addProduct}
        removeProduct={this.removeProduct}
        handleSubmit={this.toQuantityForm}
      />
    }
    if (view === VIEWS.quantity) {
      component = <NewShipmentProductsQuantity
        shipment={shipment}
        handleSubmit={this.addProductQuantity}
        goBack={this.goBack}
        cancel={this.cancel}
        addBatches={this.addBatches}
        pushBatchToContainer={this.pushBatchToContainer}
      />
    }
    if (view === VIEWS.batches) {
      component = <NewShipmentBatchesForm
        batches={batches}
        shipment={shipment}
        goBack={this.goBack}
        cancel={this.cancel}
        saveBatchCount={this.saveBatchCount}
        removeBatchCount={this.removeBatchCount}
        pushBatchToContainer={this.pushBatchToContainer}
      />
    }
    if (view === VIEWS.summary) {
      const program = programs.find(p => p.id === shipment.program.id)
      component = <NewShipmentSummary
        shipment={shipment}
        programName={program.fullName}
        goBack={this.goBack}
        handleSubmit={this.submitNewOrder}
      />
    }

    return (
      <div>
        {component}
      </div>
    )
  }
}

export default withUser(withApi(withConfig(NewPSMShipmentContainer)))
