const XLSX = require('@sheet/core')
const { ALLOCATIONS_WORKSHEET_NAME } = require('../../allocation/config')
const { getUnknownBatchID } = require('../tools/virtual-batch')
const PLANNING_TYPES = require('../tools/planning-types')

const ALLOCATION_METHOD = 'directOrder'
const ALLOCATION_METHOD_COLUMN = 'direct order'
const { DIRECT_ORDER_TYPES } = require('../../allocation/config')

const parseAllocationSheet = (buffer, { isCnR = false } = {}) => {
  const workbook = XLSX.read(buffer, {type: 'array'})
  const allocationsSheet = workbook.Sheets[ALLOCATIONS_WORKSHEET_NAME]
  if (!allocationsSheet) {
    throw new Error(`Worksheet '${ALLOCATIONS_WORKSHEET_NAME}' not found`)
  }

  const rows = XLSX.utils.sheet_to_json(allocationsSheet)
  const validRows = []
  for (const row of rows) {
    // Filter out rows with no location id or with wrong allocation method
    if (!row.id ||
        row['allocation-method'] !== ALLOCATION_METHOD) {
      continue
    }
    const quantity = parseInt(row[ALLOCATION_METHOD_COLUMN])
    // Check if the quantity is zero or NaN
    if (quantity === 0 || isNaN(quantity)) {
      // If this is a C&R, then we set the quantity to one,
      // otherwise the row gets skipped.
      if (!isCnR) {
        continue
      }
      row[ALLOCATION_METHOD_COLUMN] = '1'
    }
    validRows.push(row)
  }
  return validRows
}

const getCounts = products => {
  return Object.keys(products)
  // only products with positive and/or negative counts
  // discard products with 0
    .filter(id => Math.abs(products[id].quantity) > 0)
    .reduce((count, id) => {
      // We do not use batches and therefore generate an unknown batch id.
      // That is a magic batch id through which the system knows that the
      // product does not actually use batches.
      count[getUnknownBatchID(id)] = {
        quantity: products[id].quantity,
        paymentType: products[id].paymentType || DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL
      }
      return count
    }, {})
}

// Don't allow negative or zero quantities for C&R
const validateCRRows = products => {
  const errors = Object.keys(products)
    .filter(id => {
      return products[id] <= 0
    })
    .map(id => {
      return { productId: id, directOrder: products[id] }
    })

  if (errors.length) {
    return errors
  }
}

/*
 * create params to be used with api.shipment.createBulkSnapshots
 * @params constructedRows: Object<{[supplier]: {[locationId]: {[products]: number}} }>
 * @return Array<{origin, destination, counts, planningType}>
 */
const createShipmentParams = (constructedRows, { isCnR = false } = {}) => {
  let params = []

  let errorRows = []
  for (let supplier in constructedRows) {
    const supplierShipments = Object.keys(constructedRows[supplier])
      .reduce((shipments, locationId) => {
        const products = constructedRows[supplier][locationId]
        const planningType = isCnR ? PLANNING_TYPES.C_R : constructedRows[supplier][locationId]['planningType'] || PLANNING_TYPES.ROUTINE
        if (isCnR) {
          const errors = validateCRRows(products)
          if (errors) {
            errorRows = errorRows.concat(errors.map(err => {
              err.locationId = locationId
              return err
            }))
            return shipments
          }
        }

        const param = {
          origin: {id: isCnR ? locationId : supplier},
          destination: {id: isCnR ? supplier : locationId},
          counts: getCounts(products),
          planningType
        }
        shipments.push(param)
        return shipments
      }, [])

    params = params.concat(supplierShipments)
  }

  if (errorRows.length) {
    return { error: true, rows: errorRows, params }
  }

  return params
}

const constructSupplierTree = parsedRows => {
  return parsedRows
    .reduce((suppliers, row) => {
      const {supplier, product, id, planningType, paymentType} = row
      suppliers[supplier] = suppliers[supplier] || {[id]: {}}
      const locationProducts = suppliers[supplier][id] || {}
      locationProducts[`product:${product}`] = { quantity: parseInt(row[ALLOCATION_METHOD_COLUMN], 10) }
      if (paymentType) {
        locationProducts[`product:${product}`] = {
          ...locationProducts[`product:${product}`],
          paymentType
        }
      }
      locationProducts['planningType'] = planningType || PLANNING_TYPES.DEFAULT
      suppliers[supplier][id] = locationProducts
      return suppliers
    }, {})
}

module.exports = {
  ALLOCATION_METHOD,
  ALLOCATION_METHOD_COLUMN,
  parseAllocationSheet,
  constructSupplierTree,
  createShipmentParams,
  getCounts
}
