const ENDPOINTS = {
  createMandate: 'exapp/api/v1/send/api/echannelsvc/echannel/mandate/setup',
  getMandateStatus:
    'ecomm/mandate/{{merchantId}}/{{requestId}}/{{hash}}/status.reg',
  getMandateHistory:
    'exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/history',
  stopMandate: 'exapp/api/v1/send/api/echannelsvc/echannel/mandate/stop',
  createDebit:
    'exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/send',
  getDebitStatus:
    'exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/status',
  cancelDebit:
    'exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/stop',
  requestOTP:
    'exapp/api/v1/send/api/echannelsvc/echannel/mandate/requestAuthorization',
  validateOTP:
    'exapp/api/v1/send/api/echannelsvc/echannel/mandate/validateAuthorization'
}

const BANK_CODES = [
  { name: 'FSDH', id: '401' },
  { name: 'TAJ', id: '302' },
  { name: 'CORONATION BANK', id: '459' },
  { name: 'CITIBANK NIG LTD', id: '023' },
  { name: 'FIRST CITY MONUMENT BANK PLC', id: '214' },
  { name: 'FIRST BANK OF NIGERIA PLC', id: '011', allowOtp: true },
  { name: 'WEMA BANK PLC', id: '035' },
  { name: 'STANBIC IBTC BANK PLC', id: '039' },
  { name: 'UBA PLC', id: '033' },
  { name: 'PROVIDUS BANK PLC', id: '101', allowOtp: true },
  { name: 'ACCESS BANK PLC', id: '044' },
  { name: 'ECOBANK NIGERIA PLC', id: '050' },
  { name: 'ZENITH BANK PLC', id: '057' },
  { name: 'DIAMOND / ACCESS', id: '044' },
  { name: 'POLARIS / SKYE BANK PLC', id: '076' },
  { name: 'KEYSTONE BANK', id: '082' },
  { name: 'STERLING BANK PLC', id: '232', allowOtp: true },
  { name: 'UNION BANK OF NIGERIA PLC', id: '032' },
  { name: 'HERITAGE BANK', id: '030' },
  { name: 'JAIZ BANK PLC', id: '301', allowOtp: true },
  { name: 'GUARANTY TRUST BANK PLC', id: '058' },
  { name: 'FIDELITY BANK PLC', id: '070' },
  { name: 'STANDARD CHARTERED BANK NIGERIA LTD', id: '068' },
  { name: 'SUNTRUST BANK NIG LTD', id: '100' },
  { name: 'UNITY BANK PLC', id: '215' }
]

// Field decided these values
const DEFAULT_MANDATE_AMOUNT = 5000000
const DEFAULT_MAX_DEBIT = 150
// We do not know why remita asks this to be a variable,
// It seems to never change.
const DEFAULT_MANDATE_TYPE = 'DD'

module.exports = {
  PROVIDER_CODE: 'REMITA',
  DISPLAY_NAME: 'Remita',
  SUPPORTED_GEOLOCATIONS: ['ng'],
  ENDPOINTS,
  BANK_CODES,
  DEFAULT_MANDATE_AMOUNT,
  DEFAULT_MAX_DEBIT,
  DEFAULT_MANDATE_TYPE
}
