import { userIsAuthorised } from '../../../van-shared/utils/auth'
import { subDays } from 'date-fns'
import { isLocationSubscriptionBased } from '@fielded/fs-api/lib/location/tools'
import { locationIdToProperties } from '@fielded/fs-api/lib/tools'

// For capitalizing strings and substrings following a hyphen, as needed for locations.
// E.g. cross-river -> Cross-River
export const capitalizeWithHyphen = string => {
  if (!string) return string

  return string.split('-')
    .map(word => {
      const [ firstLetter, ...restOfWord ] = word
      return `${firstLetter ? firstLetter.toUpperCase() : null}${restOfWord.join('')}`
    })
    .join('-')
}

export const capitalize = string => string.split(' ').map(w => w[0].toUpperCase() + w.slice(1)).join(' ')

export const userLocationLabelFromId = (locationId) => {
  if (!locationId) return locationId

  // location ids are aggregated like this zone:zone:state:state:lga:lga
  // always in the same order
  const idParts = locationId.split(':')
  const location = {
    zone: capitalizeWithHyphen(idParts[1]),
    state: capitalizeWithHyphen(idParts[3]),
    lga: capitalizeWithHyphen(idParts[5]),
    sdp: capitalizeWithHyphen(idParts[7])
  }

  if (!location.zone) {
    return 'National user'
  }

  if (location.zone.length === 2) {
    location.zone = location.zone.toUpperCase()
  }

  if (!location.state) {
    return [location.zone, 'zonal user']
  }

  if (!location.lga) {
    return [location.state, location.zone] // + ' (state user)'
  }

  if (!location.sdp) {
    return [location.lga, location.state, location.zone]
  }

  return [location.sdp, location.lga, location.state, location.zone]
}

/** Creates a debounce function for the location select
 *
 * The created function will resolve to the options after some
 * idle time. This is used to exploit the selects async feature for
 * debouncing. The filtering on the list is only yet performed after
 * this function resolved. This does nothing except postponing filter.
 */
export function createSelectDebounce (idleTime, selectOptions) {
  let id
  return (input) => {
    const p = new Promise((resolve, reject) => {
      if (id) {
        clearTimeout(id)
      }
      id = setTimeout(() => {
        id = null
        resolve({options: selectOptions})
      }, idleTime)
    })
    return p
  }
}

/**
 * Map funder docs to Select options
 */
export function fundersToSelectOptions (funders) {
  return funders.map(f => ({
    value: `funder:${f.id}`,
    label: f.name
  }))
}

/**
 * Map route docs to Select options
 */
export function routesToSelectOptions (routes) {
  return routes.map(f => ({
    value: `route:${f.id}`,
    label: f.name
  }))
}

/**
 * Map implementingPartner docs to Select options
 */

export const implementingPartnersToSelectOptions = implmentingPartners => {
  if (!implmentingPartners) return
  return implmentingPartners.map(f => ({
    value: f._id,
    label: f.name
  }))
}

/**
 * FIXME: This is an interim hack around the shortcomings of the current
 * authentication framework.
 *
 * See: https://github.com/fielded/docs/pull/63
 */
export const userHasSubRoleOf = (user, role) => user.roles.find(r => r.startsWith(role))

const getCurrentPeriod = async (api, programs, date) => {
  const currentPeriods = await Promise.all(programs.map(program => {
    return api.report.period.get({ program, date, isEffectiveDate: true })
  }))

  /*
   * If a user is mixing programs with different period,
   * let's just throw an error, we don't support these yet
   */
  const allSame = currentPeriods
    .map(period => {
      return `${period.effectiveStartDate.toJSON()}-${period.effectiveEndDate.toJSON()}`
    })
    .every((el, _, all) => {
      return el === all[0]
    })

  if (!allSame) {
    throw new Error('This user is managing programs with different reporting periods, and can not edit locations')
  }

  return currentPeriods[0]
}

// Because you report on the last period, we want to make changes to locations:services
// for the beginning of _this_ period, so that you don't see in the app until _next_ period :)
export async function getDateForLocationEdits (
  {
    api,
    programs,
    date = new Date().toJSON(),
    bufferDays = 0,
    useLastDayOfPreviousPeriod
  }
) {
  const currentPeriod = await getCurrentPeriod(api, programs, date)

  if (useLastDayOfPreviousPeriod) {
    const previous = await api.report.period.getPrevious({
      program: programs[0],
      period: currentPeriod,
      // use this to emulate dateToReportingPeriod behavior
      isEffectiveDate: true
    })
    return previous.effectiveEndDate.toJSON()
  }

  const dateToUse = bufferDays
    ? subDays(date, bufferDays).toJSON()
    : date

  const periodWithBufferDays = await api.report.period.get({
    program: programs[0],
    date: dateToUse,
    isEffectiveDate: true
  })
  return periodWithBufferDays.effectiveStartDate.toJSON()
}

export async function getRangeForProposalsList (
  {
    api,
    programs,
    date = new Date().toJSON(),
    bufferDays = 0
  }
) {
  const dateToUse = bufferDays
    ? subDays(date, bufferDays).toJSON()
    : date

  const currentPeriod = await getCurrentPeriod(api, programs, dateToUse)
  return {
    startDate: currentPeriod.effectiveStartDate.toJSON(),
    endDate: currentPeriod.effectiveEndDate.toJSON()
  }
}

export async function getChangesVisibleDate (
  {
    api,
    programs,
    date = new Date().toJSON(),
    bufferDays = 0
  }
) {
  const dateToUse = bufferDays
    ? subDays(date, bufferDays).toJSON()
    : date

  const periodWithBufferDays = await api.report.period.get({
    program: programs[0],
    date: dateToUse,
    isEffectiveDate: true // use this to emulate dateToReportingPeriod behavior
  })

  const nextReportingPeriod = await api.report.period.getNext({
    program: programs[0],
    period: periodWithBufferDays
  })

  return nextReportingPeriod.effectiveStartDate.toJSON()
}

// `roleCanBeAssignedByUser` takes a user object and returns a new function which
// can be passed a role object, returning true if the user is allowed to
// assign/remove that role and returning false if the user is not allowed to
// assign/remove that role.
export const roleCanBeAssignedByUser = user => {
  const isSuperAdmin = userIsAuthorised(user, 'feature:settings')
  if (isSuperAdmin) {
    return () => true
  }

  const isUserAdmin = userIsAuthorised(user, 'feature:settings:users')
  return ({ role = '' }) => {
    const noRestrictionsApply = !role.startsWith('feature:settings:facilities') &&
      role !== 'feature:settings'
    return isUserAdmin && noRestrictionsApply
  }
}

export const displayTexts = {
  productCategory: {
    dry: 'Dry goods',
    vaccine: 'Vaccines',
    diluent: 'Diluents'
  },
  allocationMethod: {
    tp: 'Target population',
    ba: 'Bundled allocation'
  }
}

export const getSupplier = ({suppliedBy = [], supplies = []} = {}) => {
  return suppliedBy[0]
}

export const getSubscriptionSubNavigationItems = ({ activeTabId, location, pendingContracts = 0, showLocationDetails, showContracts }) => {
  if (!location) {
    return null
  }

  const showProductSubs = isLocationSubscriptionBased(location)

  const items = {
    ...(showLocationDetails && {
      'location-details': {
        title: 'Location details',
        path: `/settings/facilities/${location._id}`
      }
    }),
    ...(showContracts) && {
      'contracts': {
        title: 'Contracts',
        path: `/settings/facilities/${location._id}/contracts`,
        [pendingContracts && 'badgeLabel']: `${pendingContracts}`,
        [pendingContracts && 'badgeStatus']: 'attention'
      }
    },
    ...(showProductSubs && {
      'product-subscriptions': {
        title: 'Product subscriptions',
        path: `/settings/facilities/${location._id}/subscriptions`
      }
    })
  }

  const activeTab = items[activeTabId]
  if (activeTab) {
    delete activeTab.path
    activeTab.active = true
  }

  return Object.values(items)
}

const REQUIRED_DOCUMENTS_NG = ['subscription-agreement', 'terms-and-conditions', 'credit-facility-agreement']
const REQUIRED_DOCUMENTS_KE = ['subscription-agreement', 'terms-and-conditions', 'credit-facility-agreement']

const documents = {
  'retailer': {
    all: ['subscription-agreement', 'terms-and-conditions', 'credit-facility-agreement', 'field-pay'],
    required: {
      ng: REQUIRED_DOCUMENTS_NG,
      ke: REQUIRED_DOCUMENTS_KE
    }
  },
  'driver': {
    all: ['fp-agreement'],
    required: ['fp-agreement']
  }
}

const retailerDocumentsVersionsToBeUpToDate = {
  ng: {
    'terms-and-conditions': 'v6.0.0'
  },
  ke: {
    'terms-and-conditions': 'v6.0.0'
  }
}
const getDocuments = async (api, locationId, allDocumentIds, requiredDocumentIds) => {
  const signEvents = (await Promise.all(
    allDocumentIds
      .map(documentId => api.document.getSignEvent({
        facilityId: locationId,
        documentId
      }))
  )).filter(event => event)

  const { country } = locationIdToProperties(locationId)

  const pending = signEvents.reduce((notSigned, event) => {
    if (requiredDocumentIds.includes(event.documentId)) {
      notSigned = notSigned - 1
    }
    return notSigned
  }, requiredDocumentIds.length)

  const outdated = signEvents.reduce((toSignAgain, event) => {
    if (event.documentId === 'terms-and-conditions') {
      const retailerDocVersionUpToDate = retailerDocumentsVersionsToBeUpToDate[country][event.documentId]
      if (retailerDocVersionUpToDate) {
        const docInfoArray = event.documentVersion.split('-')
        const documentVersion = docInfoArray.pop()
        if (documentVersion !== retailerDocVersionUpToDate) {
          toSignAgain.push(event.documentId)
        }
      }
    }
    return toSignAgain
  }, [])

  return {
    events: signEvents.reduce((byId, event) => {
      byId[event.documentId] = event
      return byId
    }, {}),
    pending,
    outdated: outdated
  }
}

export const documentInfo = async (api, locationId) => {
  const { country } = locationIdToProperties(locationId)
  const { all, required } = documents.retailer
  let requiredDocuments = required[country] || []
  return getDocuments(api, locationId, all, requiredDocuments)
}

export const driverDocumentInfo = async (api, driverId) => {
  return getDocuments(api, driverId, documents.driver.all, documents.driver.required)
}

export const diffLists = (a, b) => {
  const setA = a.reduce((set, x) => set.add(x), new Set())
  const setB = b.reduce((set, x) => set.add(x), new Set())

  const removed = a.filter(x => !setB.has(x))
  const added = b.filter(x => !setA.has(x))

  return [added, removed]
}

export const getDocumentVersionId = async (documentId, locationId, versionId = 'v1.0.0') => {
  const documentVersionFor = regionId => (`${documentId}-${regionId.replace(/:/g, '-')}-${versionId}`)
  const stateId = locationId.split(':sdp:')[0]
  const documentVersionId = documentVersionFor(stateId)
  // If a state/market-specific document exists, we will show that one, otherwise we'll show a country-specific document.
  try {
    await import(`../components/Documents/DocumentText/versioning/${versionId}/${documentVersionId}`)
  } catch (e) {
    const countryId = locationId.split(':state:')[0]
    return documentVersionFor(countryId)
  }
  return documentVersionId
}

export function validateUserName (value) {
  if (!value || value.trim().length < 1) {
    return 'User name must be at least one character long'
  }
}

export function hasUserContract ({ roles = [], location: { id = '' } }) {
  return roles.includes('feature:userRole:fp')
}
