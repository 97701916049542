import React from 'react'
import PropTypes from 'prop-types'
import { Inbox } from '../../icons'
import cx from 'classnames'

const EmptyState = ({ text, className }) => (
  <div className={cx('empty-state', className)}>
    <Inbox />
    <p>{text}</p>
  </div>
)

EmptyState.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
}

export default EmptyState
