import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { PAY_ON_DELIVERY, PAY_AS_YOU_SELL } from '../../utils/subscription-type-constants'

export const colorCodes = [
  'brand',
  'ok',
  'positive',
  'alert',
  'delivery',
  'provisional',
  'count',
  'warning',
  'collection',
  'cancelled',
  PAY_ON_DELIVERY,
  PAY_AS_YOU_SELL
]

const sizes = ['tiny', 'extra-small', 'small', 'medium', 'large']

const IconBadge = ({
  colorCode,
  size,
  icon,
  withShadow,
  isPressed,
  withBorder,
  withDefaultIconStyle,
  withOutline,
  className
}) => {
  return (
    <span className={classnames(
      'icon-badge',
      {'icon-badge--with-border': withBorder},
      {'icon-badge--ok': colorCode === 'ok'},
      {'icon-badge--positive': colorCode === 'positive'},
      {'icon-badge--alert': colorCode === 'alert'},
      {'icon-badge--brand': ['brand', 'count'].includes(colorCode)},
      {'icon-badge--delivery': colorCode === 'delivery'},
      {'icon-badge--provisional': ['provisional', 'warning'].includes(colorCode)},
      {'icon-badge--collection': colorCode === 'collection'},
      {'icon-badge--cancelled': colorCode === 'cancelled'},
      {'icon-badge--on-demand': colorCode === PAY_ON_DELIVERY},
      {'icon-badge--pays': colorCode === PAY_AS_YOU_SELL},
      {'icon-badge--size-tiny': size === 'tiny'},
      {'icon-badge--size-extra-small': size === 'extra-small'},
      {'icon-badge--size-small': size === 'small'},
      {'icon-badge--size-medium': size === 'medium'},
      {'icon-badge--size-large': size === 'large'},
      {'icon-badge--with-shadow': withShadow},
      {'icon-badge--pressed': isPressed},
      {'icon-badge--with-outline': withOutline},
      {'icon-badge--default-style': withDefaultIconStyle},
      className
    )}>
      { icon }
    </span>
  )
}

IconBadge.propTypes = {
  /**
   * Color of the badge to be shown
   */
  colorCode: PropTypes.oneOf([...colorCodes, null]),
  /**
   * Size of the badge
   */
  size: PropTypes.oneOf([...sizes, null]),
  /**
   * The icon representing this item
   */
  icon: PropTypes.node.isRequired,
  /**
   * Determines if the box shadow should be present
   */
  withShadow: PropTypes.bool,
  /**
   * If the background should have a border
   */
  withBorder: PropTypes.bool,
  /**
   * If the background should be transparent
   */
  withOutline: PropTypes.bool,
  /**
   * If the icon should use the default styles
   */
  withDefaultIconStyle: PropTypes.bool,
  /**
   * Any additional class names you want to add
   */
  className: PropTypes.string
}

IconBadge.defaultProps = {
  colorCode: null,
  size: 'small',
  icon: null,
  withShadow: false,
  withBorder: true,
  withOutline: false,
  className: null
}

export default IconBadge
