'use strict';
var formats = require('ajv/lib/compile/formats')();
var validate = (function() {
  var pattern0 = new RegExp('^product:[^:^\\s]+$');
  var pattern1 = new RegExp('^product:[^:^\\s]+:manufacturer:[^:^\\s]+:batchNo:[^:^\\s]+$');
  var refVal = [];
  var refVal1 = {
    "type": "integer"
  };
  refVal[1] = refVal1;
  var refVal2 = (function() {
    var pattern0 = new RegExp('^product:[^:^\\s]+$');
    var pattern1 = new RegExp('^product:[^:^\\s]+:manufacturer:[^:^\\s]+:batchNo:[^:^\\s]+$');
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        var errs__0 = errors;
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !(false || pattern1.test(key0));
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
            break;
          }
        }
        if (valid1) {
          for (var key0 in data) {
            if (pattern1.test(key0)) {
              var data1 = data[key0];
              var errs_1 = errors;
              if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
                if (true) {
                  var errs__1 = errors;
                  var valid2 = true;
                  for (var key1 in data1) {
                    var isAdditional1 = !(false || key1 == 'amount');
                    if (isAdditional1) {
                      valid2 = false;
                      validate.errors = [{
                        keyword: 'additionalProperties',
                        dataPath: (dataPath || '') + '[\'' + key0 + '\']',
                        schemaPath: '#/patternProperties/%5Eproduct%3A%5B%5E%3A%5E%5Cs%5D%2B%3Amanufacturer%3A%5B%5E%3A%5E%5Cs%5D%2B%3AbatchNo%3A%5B%5E%3A%5E%5Cs%5D%2B%24/additionalProperties',
                        params: {
                          additionalProperty: '' + key1 + ''
                        },
                        message: 'should NOT have additional properties'
                      }];
                      return false;
                      break;
                    }
                  }
                  if (valid2) {
                    var data2 = data1.amount;
                    if (data2 === undefined) {
                      valid2 = false;
                      validate.errors = [{
                        keyword: 'required',
                        dataPath: (dataPath || '') + '[\'' + key0 + '\']',
                        schemaPath: '#/patternProperties/%5Eproduct%3A%5B%5E%3A%5E%5Cs%5D%2B%3Amanufacturer%3A%5B%5E%3A%5E%5Cs%5D%2B%3AbatchNo%3A%5B%5E%3A%5E%5Cs%5D%2B%24/required',
                        params: {
                          missingProperty: 'amount'
                        },
                        message: 'should have required property \'amount\''
                      }];
                      return false;
                    } else {
                      var errs_2 = errors;
                      var errs_3 = errors;
                      if ((typeof data2 !== "number" || (data2 % 1) || data2 !== data2)) {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '[\'' + key0 + '\'].amount',
                          schemaPath: '#/definitions/amount/type',
                          params: {
                            type: 'integer'
                          },
                          message: 'should be integer'
                        }];
                        return false;
                      }
                      var valid3 = errors === errs_3;
                      if (valid3) {}
                      if (errors === errs_2) {}
                      var valid2 = errors === errs_2;
                    }
                    if (valid2) {}
                  }
                  if (errs__1 == errors) {}
                }
              } else {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '[\'' + key0 + '\']',
                  schemaPath: '#/patternProperties/%5Eproduct%3A%5B%5E%3A%5E%5Cs%5D%2B%3Amanufacturer%3A%5B%5E%3A%5E%5Cs%5D%2B%3AbatchNo%3A%5B%5E%3A%5E%5Cs%5D%2B%24/type',
                  params: {
                    type: 'object'
                  },
                  message: 'should be object'
                }];
                return false;
              }
              if (errors === errs_1) {}
              var valid1 = errors === errs_1;
              if (!valid1) break;
            } else valid1 = true;
          }
          if (valid1) {}
        }
        if (errs__0 == errors) {}
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      if (errors === 0) {}
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal2.schema = {
    "type": "object",
    "patternProperties": {
      "^product:[^:^\\s]+:manufacturer:[^:^\\s]+:batchNo:[^:^\\s]+$": {
        "type": "object",
        "properties": {
          "amount": {
            "$ref": "#/definitions/amount"
          }
        },
        "additionalProperties": false,
        "required": ["amount"]
      }
    },
    "additionalProperties": false
  };
  refVal2.errors = null;
  refVal[2] = refVal2;
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    'use strict'; /*# sourceURL=https://schema.field.partners/1.0/van-stock-count-external# */
    var vErrors = null;
    var errors = 0;
    if (rootData === undefined) rootData = data;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      if (true) {
        var errs__0 = errors;
        var valid1 = true;
        if (data.id === undefined) {
          valid1 = true;
        } else {
          var errs_1 = errors;
          if (typeof data.id !== "string") {
            validate.errors = [{
              keyword: 'type',
              dataPath: (dataPath || '') + '.id',
              schemaPath: '#/properties/id/type',
              params: {
                type: 'string'
              },
              message: 'should be string'
            }];
            return false;
          }
          var valid1 = errors === errs_1;
        }
        if (valid1) {
          if (data.createdAt === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (typeof data.createdAt !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.createdAt',
                schemaPath: '#/properties/createdAt/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.updatedAt === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.updatedAt !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.updatedAt',
                  schemaPath: '#/properties/updatedAt/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.warehouseCode === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (typeof data.warehouseCode !== "string") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.warehouseCode',
                    schemaPath: '#/properties/warehouseCode/type',
                    params: {
                      type: 'string'
                    },
                    message: 'should be string'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.locationId === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (typeof data.locationId !== "string") {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.locationId',
                      schemaPath: '#/properties/locationId/type',
                      params: {
                        type: 'string'
                      },
                      message: 'should be string'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.funderId === undefined) {
                    valid1 = false;
                    validate.errors = [{
                      keyword: 'required',
                      dataPath: (dataPath || '') + "",
                      schemaPath: '#/required',
                      params: {
                        missingProperty: 'funderId'
                      },
                      message: 'should have required property \'funderId\''
                    }];
                    return false;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.funderId !== "string") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.funderId',
                        schemaPath: '#/properties/funderId/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.programId === undefined) {
                      valid1 = false;
                      validate.errors = [{
                        keyword: 'required',
                        dataPath: (dataPath || '') + "",
                        schemaPath: '#/required',
                        params: {
                          missingProperty: 'programId'
                        },
                        message: 'should have required property \'programId\''
                      }];
                      return false;
                    } else {
                      var errs_1 = errors;
                      if (typeof data.programId !== "string") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.programId',
                          schemaPath: '#/properties/programId/type',
                          params: {
                            type: 'string'
                          },
                          message: 'should be string'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      var data1 = data.date;
                      if (data1 === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        var errs__1 = errors,
                          prevValid1 = false,
                          valid1 = false,
                          passingSchemas1 = null;
                        var errs_2 = errors;
                        if ((typeof data1 === "number")) {
                          if (true) {}
                        }
                        if (errors === errs_2) {
                          if (typeof data1 === "string") {
                            if (!formats['date-time'].test(data1)) {
                              var err = {
                                keyword: 'format',
                                dataPath: (dataPath || '') + '.date',
                                schemaPath: '#/properties/date/oneOf/0/format',
                                params: {
                                  format: 'date-time'
                                },
                                message: 'should match format "date-time"'
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {}
                          } else {
                            var err = {
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.date',
                              schemaPath: '#/properties/date/oneOf/0/type',
                              params: {
                                type: 'string'
                              },
                              message: 'should be string'
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          if (errors === errs_2) {}
                        }
                        var valid2 = errors === errs_2;
                        if (valid2) {
                          valid1 = prevValid1 = true;
                          passingSchemas1 = 0;
                        }
                        var errs_2 = errors;
                        if ((typeof data1 === "number")) {
                          if (true) {}
                        }
                        if (errors === errs_2) {
                          if (typeof data1 === "string") {
                            if (!formats.date.test(data1)) {
                              var err = {
                                keyword: 'format',
                                dataPath: (dataPath || '') + '.date',
                                schemaPath: '#/properties/date/oneOf/1/format',
                                params: {
                                  format: 'date'
                                },
                                message: 'should match format "date"'
                              };
                              if (vErrors === null) vErrors = [err];
                              else vErrors.push(err);
                              errors++;
                            } else {}
                          } else {
                            var err = {
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.date',
                              schemaPath: '#/properties/date/oneOf/1/type',
                              params: {
                                type: 'string'
                              },
                              message: 'should be string'
                            };
                            if (vErrors === null) vErrors = [err];
                            else vErrors.push(err);
                            errors++;
                          }
                          if (errors === errs_2) {}
                        }
                        var valid2 = errors === errs_2;
                        if (valid2 && prevValid1) {
                          valid1 = false;
                          passingSchemas1 = [passingSchemas1, 1];
                        } else {
                          if (valid2) {
                            valid1 = prevValid1 = true;
                            passingSchemas1 = 1;
                          }
                        }
                        if (!valid1) {
                          var err = {
                            keyword: 'oneOf',
                            dataPath: (dataPath || '') + '.date',
                            schemaPath: '#/properties/date/oneOf',
                            params: {
                              passingSchemas: passingSchemas1
                            },
                            message: 'should match exactly one schema in oneOf'
                          };
                          if (vErrors === null) vErrors = [err];
                          else vErrors.push(err);
                          errors++;
                          validate.errors = vErrors;
                          return false;
                        } else {
                          errors = errs__1;
                          if (vErrors !== null) {
                            if (errs__1) vErrors.length = errs__1;
                            else vErrors = null;
                          }
                        }
                        if (errors === errs_1) {}
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        var data1 = data.stock;
                        if (data1 === undefined) {
                          valid1 = false;
                          validate.errors = [{
                            keyword: 'required',
                            dataPath: (dataPath || '') + "",
                            schemaPath: '#/required',
                            params: {
                              missingProperty: 'stock'
                            },
                            message: 'should have required property \'stock\''
                          }];
                          return false;
                        } else {
                          var errs_1 = errors;
                          if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
                            var errs__1 = errors;
                            var valid2 = true;
                            for (var key1 in data1) {
                              var isAdditional1 = !(false || pattern0.test(key1));
                              if (isAdditional1) {
                                valid2 = false;
                                validate.errors = [{
                                  keyword: 'additionalProperties',
                                  dataPath: (dataPath || '') + '.stock',
                                  schemaPath: '#/properties/stock/additionalProperties',
                                  params: {
                                    additionalProperty: '' + key1 + ''
                                  },
                                  message: 'should NOT have additional properties'
                                }];
                                return false;
                                break;
                              }
                            }
                            if (valid2) {
                              for (var key1 in data1) {
                                if (pattern0.test(key1)) {
                                  var data2 = data1[key1];
                                  var errs_2 = errors;
                                  if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                                    var errs__2 = errors;
                                    var valid3 = true;
                                    var data3 = data2.amount;
                                    if (data3 === undefined) {
                                      valid3 = true;
                                    } else {
                                      var errs_3 = errors;
                                      var errs_4 = errors;
                                      if ((typeof data3 !== "number" || (data3 % 1) || data3 !== data3)) {
                                        validate.errors = [{
                                          keyword: 'type',
                                          dataPath: (dataPath || '') + '.stock[\'' + key1 + '\'].amount',
                                          schemaPath: '#/definitions/amount/type',
                                          params: {
                                            type: 'integer'
                                          },
                                          message: 'should be integer'
                                        }];
                                        return false;
                                      }
                                      var valid4 = errors === errs_4;
                                      if (valid4) {}
                                      if (errors === errs_3) {}
                                      var valid3 = errors === errs_3;
                                    }
                                    if (valid3) {
                                      if (data2.batches === undefined) {
                                        valid3 = true;
                                      } else {
                                        var errs_3 = errors;
                                        if (!refVal2(data2.batches, (dataPath || '') + '.stock[\'' + key1 + '\'].batches', data2, 'batches', rootData)) {
                                          if (vErrors === null) vErrors = refVal2.errors;
                                          else vErrors = vErrors.concat(refVal2.errors);
                                          errors = vErrors.length;
                                        } else {}
                                        if (errors === errs_3) {}
                                        var valid3 = errors === errs_3;
                                      }
                                      if (valid3) {}
                                    }
                                    if (errs__2 == errors) {}
                                  } else {
                                    validate.errors = [{
                                      keyword: 'type',
                                      dataPath: (dataPath || '') + '.stock[\'' + key1 + '\']',
                                      schemaPath: '#/properties/stock/patternProperties/%5Eproduct%3A%5B%5E%3A%5E%5Cs%5D%2B%24/type',
                                      params: {
                                        type: 'object'
                                      },
                                      message: 'should be object'
                                    }];
                                    return false;
                                  }
                                  if (errors === errs_2) {
                                    var errs__2 = errors,
                                      prevValid2 = false,
                                      valid2 = false,
                                      passingSchemas2 = null;
                                    var errs_3 = errors;
                                    if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                                      var missing3;
                                      if (((data2.batches === undefined) && (missing3 = '.batches'))) {
                                        var err = {
                                          keyword: 'required',
                                          dataPath: (dataPath || '') + '.stock[\'' + key1 + '\']',
                                          schemaPath: '#/properties/stock/patternProperties/%5Eproduct%3A%5B%5E%3A%5E%5Cs%5D%2B%24/oneOf/0/required',
                                          params: {
                                            missingProperty: '' + missing3 + ''
                                          },
                                          message: 'should have required property \'' + missing3 + '\''
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      } else {}
                                    }
                                    if (errors === errs_3) {}
                                    var valid3 = errors === errs_3;
                                    if (valid3) {
                                      valid2 = prevValid2 = true;
                                      passingSchemas2 = 0;
                                    }
                                    var errs_3 = errors;
                                    if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                                      var missing3;
                                      if (((data2.amount === undefined) && (missing3 = '.amount'))) {
                                        var err = {
                                          keyword: 'required',
                                          dataPath: (dataPath || '') + '.stock[\'' + key1 + '\']',
                                          schemaPath: '#/properties/stock/patternProperties/%5Eproduct%3A%5B%5E%3A%5E%5Cs%5D%2B%24/oneOf/1/required',
                                          params: {
                                            missingProperty: '' + missing3 + ''
                                          },
                                          message: 'should have required property \'' + missing3 + '\''
                                        };
                                        if (vErrors === null) vErrors = [err];
                                        else vErrors.push(err);
                                        errors++;
                                      } else {}
                                    }
                                    if (errors === errs_3) {}
                                    var valid3 = errors === errs_3;
                                    if (valid3 && prevValid2) {
                                      valid2 = false;
                                      passingSchemas2 = [passingSchemas2, 1];
                                    } else {
                                      if (valid3) {
                                        valid2 = prevValid2 = true;
                                        passingSchemas2 = 1;
                                      }
                                    }
                                    if (!valid2) {
                                      var err = {
                                        keyword: 'oneOf',
                                        dataPath: (dataPath || '') + '.stock[\'' + key1 + '\']',
                                        schemaPath: '#/properties/stock/patternProperties/%5Eproduct%3A%5B%5E%3A%5E%5Cs%5D%2B%24/oneOf',
                                        params: {
                                          passingSchemas: passingSchemas2
                                        },
                                        message: 'should match exactly one schema in oneOf'
                                      };
                                      if (vErrors === null) vErrors = [err];
                                      else vErrors.push(err);
                                      errors++;
                                      validate.errors = vErrors;
                                      return false;
                                    } else {
                                      errors = errs__2;
                                      if (vErrors !== null) {
                                        if (errs__2) vErrors.length = errs__2;
                                        else vErrors = null;
                                      }
                                    }
                                    if (errors === errs_2) {}
                                  }
                                  var valid2 = errors === errs_2;
                                  if (!valid2) break;
                                } else valid2 = true;
                              }
                              if (valid2) {}
                            }
                            if (errs__1 == errors) {}
                          } else {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.stock',
                              schemaPath: '#/properties/stock/type',
                              params: {
                                type: 'object'
                              },
                              message: 'should be object'
                            }];
                            return false;
                          }
                          if (errors === errs_1) {}
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {}
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (errs__0 == errors) {}
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    if (errors === 0) {
      var errs__0 = errors,
        prevValid0 = false,
        valid0 = false,
        passingSchemas0 = null;
      var errs_1 = errors;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        var missing1;
        if (((data.warehouseCode === undefined) && (missing1 = '.warehouseCode'))) {
          var err = {
            keyword: 'required',
            dataPath: (dataPath || '') + "",
            schemaPath: '#/oneOf/0/required',
            params: {
              missingProperty: '' + missing1 + ''
            },
            message: 'should have required property \'' + missing1 + '\''
          };
          if (vErrors === null) vErrors = [err];
          else vErrors.push(err);
          errors++;
        } else {}
      }
      if (errors === errs_1) {}
      var valid1 = errors === errs_1;
      if (valid1) {
        valid0 = prevValid0 = true;
        passingSchemas0 = 0;
      }
      var errs_1 = errors;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        var missing1;
        if (((data.locationId === undefined) && (missing1 = '.locationId'))) {
          var err = {
            keyword: 'required',
            dataPath: (dataPath || '') + "",
            schemaPath: '#/oneOf/1/required',
            params: {
              missingProperty: '' + missing1 + ''
            },
            message: 'should have required property \'' + missing1 + '\''
          };
          if (vErrors === null) vErrors = [err];
          else vErrors.push(err);
          errors++;
        } else {}
      }
      if (errors === errs_1) {}
      var valid1 = errors === errs_1;
      if (valid1 && prevValid0) {
        valid0 = false;
        passingSchemas0 = [passingSchemas0, 1];
      } else {
        if (valid1) {
          valid0 = prevValid0 = true;
          passingSchemas0 = 1;
        }
      }
      if (!valid0) {
        var err = {
          keyword: 'oneOf',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/oneOf',
          params: {
            passingSchemas: passingSchemas0
          },
          message: 'should match exactly one schema in oneOf'
        };
        if (vErrors === null) vErrors = [err];
        else vErrors.push(err);
        errors++;
        validate.errors = vErrors;
        return false;
      } else {
        errors = errs__0;
        if (vErrors !== null) {
          if (errs__0) vErrors.length = errs__0;
          else vErrors = null;
        }
      }
      if (errors === 0) {}
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate.schema = {
  "id": "https://schema.field.partners/1.0/van-stock-count-external#",
  "$schema": "http://json-schema.org/draft-04/schema#",
  "title": "Stock count external submission",
  "description": "External submission of a stock count",
  "definitions": {
    "amount": {
      "type": "integer"
    },
    "batches": {
      "type": "object",
      "patternProperties": {
        "^product:[^:^\\s]+:manufacturer:[^:^\\s]+:batchNo:[^:^\\s]+$": {
          "type": "object",
          "properties": {
            "amount": {
              "$ref": "#/definitions/amount"
            }
          },
          "additionalProperties": false,
          "required": ["amount"]
        }
      },
      "additionalProperties": false
    }
  },
  "type": "object",
  "properties": {
    "id": {
      "type": "string"
    },
    "createdAt": {
      "type": "string"
    },
    "updatedAt": {
      "type": "string"
    },
    "warehouseCode": {
      "type": "string"
    },
    "locationId": {
      "type": "string"
    },
    "funderId": {
      "type": "string"
    },
    "programId": {
      "type": "string"
    },
    "date": {
      "oneOf": [{
        "type": "string",
        "format": "date-time"
      }, {
        "type": "string",
        "format": "date"
      }]
    },
    "stock": {
      "type": "object",
      "patternProperties": {
        "^product:[^:^\\s]+$": {
          "type": "object",
          "properties": {
            "amount": {
              "$ref": "#/definitions/amount"
            },
            "batches": {
              "$ref": "#/definitions/batches"
            }
          },
          "additionalProperties": true,
          "oneOf": [{
            "required": ["batches"]
          }, {
            "required": ["amount"]
          }]
        }
      },
      "additionalProperties": false
    }
  },
  "oneOf": [{
    "required": ["warehouseCode"]
  }, {
    "required": ["locationId"]
  }],
  "required": ["stock", "funderId", "programId"]
};
validate.errors = null;
module.exports = validate;