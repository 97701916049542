const PLANNING_TYPES = require('../tools/planning-types')
const VALID_PLANNING_TYPES = Object.values(PLANNING_TYPES)

function isInvalidSnapshot (snapshot) {
  if (snapshot.planningType && !(VALID_PLANNING_TYPES.includes(snapshot.planningType))) {
    return `given planningType is invalid, must be one of the following: '${VALID_PLANNING_TYPES.join("', '")}'. Found planningType: ${snapshot.planningType}`
  }

  return null
}

module.exports = {isInvalidSnapshot}
