const PaystackBase = require('./base')
const PaystackMock = require('./mock')
class PaystackInterface {
  constructor (
    paystackSecrets,
    isomorphicFetch,
    logger
  ) {
    this.ke = paystackSecrets.ke === 'internal_test' ? new PaystackMock(
      paystackSecrets.ke,
      isomorphicFetch,
      logger
    ) : new PaystackBase(
      paystackSecrets.ke,
      isomorphicFetch,
      logger
    )
    this.ng = paystackSecrets.ng === 'internal_test' ? new PaystackMock(
      paystackSecrets.ng,
      isomorphicFetch,
      logger
    ) : new PaystackBase(
      paystackSecrets.ng,
      isomorphicFetch,
      logger
    )
  }
}

module.exports = PaystackInterface
