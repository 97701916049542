const uniqBy = require('lodash/uniqBy')
const createGraph = require('../../../relationship-graph')
const { listAll } = require('./list-all')

exports.listRelated = async (state, id, {date, includeSelf, filters, allLocations} = {}) => {
  const locationId = id || state.user.location.id
  if (!allLocations) {
    allLocations = await listAll(state, {date})
  }
  const graph = createGraph(allLocations)

  // Check if we have a location with the id
  const self = await graph.getLocation(locationId)
  if (self) {
    // Found a location, we'll get suppliers, suppliees and siblings
    const suppliers = await graph.getDescendantSuppliers(self._id, filters)
    const suppliees = await graph.getDescendantSuppliees(self._id, filters)
    // Fetch siblings if the location is not a leaf locations.
    // We don't want to fetch siblings for lgas or sdps
    let siblings = []
    if (suppliees.length > 0) {
      siblings = await graph.getSiblings(self._id, filters)
    }
    const locations = includeSelf ? [self] : []
    locations.push(...suppliers, ...suppliees, ...siblings)
    return locations
  } else {
    // No location found, treat the id as a geolocation id
    const locationsInArea = await graph.getLocationsInGeoLocation(locationId, filters)
    const locationsInAreaIds = locationsInArea.map(location => location._id)
    const theirSuppliers = await graph.getDescendantSuppliersForIds(locationsInAreaIds, filters)
    // unique because locations in area + suppliers can be the same for a national user
    return uniqBy([...locationsInArea, ...theirSuppliers], '_id')
  }
}
