module.exports = { getProposalDetails, withProposalDetails, isApprover }

const { APPROVING_ROLE } = require('./constants')

function getProposalDetails (proposalDoc) {
  if (!proposalDoc) return {}

  return {
    status: proposalDoc.status,
    proposalType: proposalDoc.proposalType,
    effectiveDate: proposalDoc.effectiveDate,
    createdAt: proposalDoc.createdAt,
    createdBy: proposalDoc.createdBy,
    updatedAt: proposalDoc.updatedAt,
    updatedBy: proposalDoc.updatedBy,
    proposalId: proposalDoc._id
  }
}

function withProposalDetails (obj, proposalDoc) {
  const proposalDetails = getProposalDetails(proposalDoc)
  return Object.assign({}, obj, { proposalDetails })
}

// https://github.com/fielded/docs/blob/master/Documentation/Access-Control.md#feature-roles
// the helper function for this lives in van-shared in the UI so doing this for now.
function isApprover ({roles = []}) {
  return roles.includes('admin') ||
    roles.includes('feature:settings') ||
    roles.includes('feature:settings:facilites') ||
    roles.includes(APPROVING_ROLE)
}
