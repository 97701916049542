const getPeriod = require('../tools/get-period')

const getPeriodDefinitionFromProgram = (program) => {
  if (!program.report || !program.report.periods) {
    throw new Error('Program ' + program.id + ' has no period configuration')
  }
  const definition = program.report.periods[0]
  return definition
}

const getRawPeriod = (program, date, isEffectiveDate) => {
  const definition = getPeriodDefinitionFromProgram(program)
  const period = getPeriod(definition, date, isEffectiveDate)
  return period
}

module.exports = (program, date, isEffectiveDate = false, now = new Date()) => {
  date = new Date(date)
  now = new Date(now)
  const period = getRawPeriod(program, date, isEffectiveDate)
  period.isEditable = now >= period.entryStartDate && now <= period.entryCutOffDate
  return period
}
module.exports.getPeriodDefinitionFromProgram = getPeriodDefinitionFromProgram
