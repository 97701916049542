const tools = require('../../tools')
const { listOrdersForUser } = require('./internal')

exports.listRequests = listRequests
async function listRequests (
  state,
  mainApi,
  { asShipments = true, excludeClosed = true } = {}
) {
  const programId = state.user.programs[0]
  const locationId = state.user.location.id
  const { orders } = await listOrdersForUser(state, programId, locationId, { withSnapshots: true }, true)

  if (orders.length === 0) {
    return []
  }

  if (!asShipments) {
    return orders
  }

  const destinationId = orders[0].destinationId
  const orderType = orders[0].orderType
  const destination = await mainApi.location.get(destinationId)
  const packPoints = await mainApi.order.getSupplierIdsForLocation(destination, {planType: orderType})

  const excludeAll = typeof excludeClosed === 'boolean'

  const shipments = []
  for (const order of orders) {
    if (excludeAll && order.closedStatus) {
      // Orders that have a closedStatus will have been confirmed or cancelled.
      // Those are no longer requests and should be skipped.
      continue
    }

    if (excludeClosed === order.closedStatus) {
      continue
    }

    const shipment = await tools.orderAsShipment(state, order, packPoints)
    if (shipment) {
      shipments.push(shipment)
    }
  }

  return shipments
}
