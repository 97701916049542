import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import snakeCase from 'lodash/snakeCase'
import isEmpty from 'lodash/isEmpty'

import { PriceDisplay } from '@fielded/shared-ui'
import { ExclamationCircle, Issue, PartnerBalanceIndicator } from '@fielded/shared-ui/src/icons'

import ConfirmationTableModal from './ConfirmationTableModal'

const ConfirmationTable = ({
  headers,
  rows,
  total,
  currency,
  locationName,
  theme,
  country,
  showVAT,
  appName
}) => {
  const items = rows.map(row => {
    const { hasIssue, hasWarning, hasPartnerBalance, fields, name, cols = [] } = row

    if (isEmpty(cols)) {
      return null
    }

    const columns = cols.map(col => {
      const colName = snakeCase(col.name)
      // Less than 0 will happen when opening balance was 0 and the counted number was higher.
      // If the opening is 0 we don't count this as outside buying hence the sold number is "0" to be shown
      const colValue = col.value < 0 ? '0' : col.value

      return (
        <td key={colName} className={`confirmation-table__td--${colName}`}>{ colValue }</td>
      )
    })

    let status
    if (hasPartnerBalance) {
      status = <PartnerBalanceIndicator fill='#230D53' />
    }

    if (hasIssue) {
      status = <Issue />
    }

    if (hasWarning) {
      status = (
        <span className='confirmation-table__th--status-warning'>
          <ExclamationCircle />
        </span>
      )
    }

    return (
      <tr
        key={`${row.key}`}
        className={cx(
          'confirmation-table__tr',
          {'confirmation-table__tr--issue': hasIssue},
          {'confirmation-table__tr--warning': hasWarning},
          {'confirmation-table__tr--partner-balance': hasPartnerBalance}
        )}
      >
        <td className='confirmation-table__td--status'>
          { status }
        </td>
        { columns }
        <td className='confirmation-table__td--trigger' key={`modal-${row.key}`}>
          <ConfirmationTableModal
            data={fields}
            currency={currency}
            title={name}
            locationName={locationName}
            hasWarning={hasWarning}
            hasPartnerBalance={hasPartnerBalance}
            showVAT={showVAT && theme === 'summary'}
            isSalesSummary={theme === 'summary'}
            appName={appName}
          />
        </td>
      </tr>
    )
  })

  return (
    <table className={cx(
      'confirmation-table',
      {'confirmation-table--summary': theme === 'summary'},
      {'confirmation-table__tr--vat': showVAT}
    )}>
      <thead>
        <tr className='confirmation-table__tr'>
          <th className='confirmation-table__th--status' />
          { headers.map(item => (
            <th className={`confirmation-table__th--${item.name}`}key={`header-${item.name}`}>
              { item.value }
            </th>
          ))}
          <th className='confirmation-table__th--trigger' />
        </tr>
      </thead>
      <tbody>
        { items }
      </tbody>
      {!isEmpty(total) &&
        <tfoot>
          <tr>
            <td colSpan={showVAT ? 5 : 4} className='confirmation-table__td--summary'>
              <div className='confirmation-table__total'>{total.name}</div>
            </td>
            <td className='confirmation-table__td--value'>{total.total < 0 ? '?' : <PriceDisplay value={total.total} country={country} />}*</td>
            <td />
          </tr>
        </tfoot>
      }
    </table>
  )
}

ConfirmationTable.propTypes = {
  /* An array of header fields */
  headers: PropTypes.array,
  /* An array of rows */
  rows: PropTypes.array,
  /* Currency in which the product is sold */
  currency: PropTypes.string,
  /* Theme modifies the table so it can be displayed in Confirmation Sales Summary view */
  theme: PropTypes.oneOf(['default', 'summary']),
  /**
   * Name of the app to display, comes from config
   */
  appName: PropTypes.string
}

export default ConfirmationTable
