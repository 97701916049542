import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Route,
  Redirect
} from 'react-router-dom'

import { withAuth } from '../common/AuthenticationProvider'
import withConfig from '../van-shared/hoc/withConfig'
import { acceptTermsAndConditions, getTCDocuments } from '../common/terms-and-conditions-api'
import { hasFeature } from '../van-shared/utils/features'
import TermsAndConditionsModal from '../van-shared/components/TermsAndConditionsModal'
import { USER_ROUTES, getUserRoutes } from '../common/utils/get-user-routes'

const shouldAcceptTCs = (config, user) => {
  const mustAcceptTCs = hasFeature(config.features, 'termsAndConditions')
  if (!mustAcceptTCs) {
    return false
  }

  const currentTCsVersion = config.features.termsAndConditions.version
  const acceptedTCs = user.acceptedTCs || []
  return !acceptedTCs.some(tcs => tcs.version === currentTCsVersion)
}

const isLegalDocPage = (currentPath, documents) => {
  const docPaths = Object.values(documents).map(doc => doc.path)
  return docPaths.includes(currentPath)
}

const PrivateRoute = ({
  component,
  config,
  redirect,
  loggedIn,
  user,
  componentProps,
  ...rest
}) => {
  const [hasAcceptedTCs, setHasAcceptedTCs] = useState(false)
  const userPaths = getUserRoutes(user, config)

  const onAcceptTCs = async (config) => {
    const currentTCsVersion = config.features.termsAndConditions.version
    await acceptTermsAndConditions(config, currentTCsVersion)
    setHasAcceptedTCs(true)
  }

  return (
    <Route {...rest} render={routeProps => {
      if (redirect) {
        return <Redirect to={redirect} />
      }

      const { history, location, match } = routeProps
      const baseUrl = `/${match.path.split('/')[1]}` // Get the base url ie string after the first foward slash (/)

      const isCurrentRouteProtected = baseUrl && Object.values(USER_ROUTES).includes(baseUrl)
      const shouldRedirect = isCurrentRouteProtected && !userPaths.includes(baseUrl)

      if (loggedIn) {
        if (shouldRedirect) {
          if (location.state && location.state.from === '/login') {
            return <Redirect to='/' />
          }
          return history.goBack()
        }

        const page = React.createElement(component, {
          ...routeProps,
          ...componentProps,
          user,
          config
        })

        // After accepting the T&Cs the local user doc is not updated.
        // This causes a problem with retailer users: when they go to Home page
        // they're redirected to the retailer home page causing this component
        // to be rerendered, while `shouldAcceptTCs` still returns `true`.
        //
        // Using a state var here fixes the issue.
        if (hasAcceptedTCs) {
          return page
        }

        if (!shouldAcceptTCs(config, user)) {
          return page
        }

        const documents = getTCDocuments(config, user)

        if (isLegalDocPage(location.pathname, documents)) {
          return page
        }

        const termsAndConditionsModal = <TermsAndConditionsModal
          appName={config.shortName}
          documents={documents}
          onAccept={() => onAcceptTCs(config)}
        />

        return React.createElement(Fragment, {},
          page,
          termsAndConditionsModal
        )
      }

      return <Redirect to={{
        state: { from: location },
        pathname: '/login'
      }} />
    }} />
  )
}

PrivateRoute.propTypes = {
  user: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([
    // FIXME: Sometimes the prop is a function, other times it's a node. I don't
    // have time to investigate why right now, but this at least clears up the
    // error in the console. To keep an eye on and revisit some time later (it
    // appears to work just fine and has done so for quite some time).
    PropTypes.func,
    PropTypes.node
  ]).isRequired
}

export default withConfig(withAuth('loggedIn', 'user')(PrivateRoute))
