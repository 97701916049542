import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Text from '../../Text'

const PageIntro = ({
  title,
  children,
  centered,
  className
}) => (
  <div
    className={classnames(
      'vs-page-intro',
      {'vs-page-intro--centered': centered},
      className
    )}
  >
    {title && (
      <Text.Heading
        tag='h1'
        className='vs-page-intro__title'
      >
        {title}
      </Text.Heading>
    )}
    {children && (
      <div className='vs-page-intro__intro'>
        {children}
      </div>
    )}
  </div>
)

PageIntro.propTypes = {
  /**
   * The title to display (object in case it needs to contain an icon)
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),

  /**
   * Any additional intro content
   */
  children: PropTypes.node,

  /**
   * Center the intro on screen
   */
  centered: PropTypes.bool,

  /**
   * The classname(s) to apply to the Page
   */
  className: PropTypes.string
}

PageIntro.displayName = 'Page.Intro'

export default PageIntro
