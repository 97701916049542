import React from 'react'

import { Page } from '@fielded/shared-ui'

import shipmentsBreadcrumb from '../common/shipmentsBreadcrumb'

const PlanningError = ({ title, message }) => <Page>
  <Page.Header
    title={title}
    breadcrumbItems={[shipmentsBreadcrumb]}
  />
  <Page.Panel spread>
    <Page.Panel.Message type='danger'>
      {message}
    </Page.Panel.Message>
  </Page.Panel>
</Page>

export default PlanningError
