import settings from './subapps/settings/roles'
import stockReporting from './subapps/reports/roles'
import qcAlerts from './subapps/alerts/roles'
import shipments from './subapps/shipments/roles'

export default {
  settings,
  stockReporting,
  qcAlerts,
  shipments
}
