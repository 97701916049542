const serializeForInsert = (data) => {
  const insertProps = Object.keys(data).join(', ')
  const insertValues = Object.values(data)
  const parametrizedString = insertValues.map((_, key) => `$${key + 1}`).join(',')
  return {
    insertProps,
    insertValues,
    parametrizedString
  }
}

module.exports = serializeForInsert
