import React from 'react'
import classnames from 'classnames'

import { Button, ExpandableOptions } from '@fielded/shared-ui'
import { Chevron } from '@fielded/shared-ui/src/icons'

import { queryStringifyFilters } from '../../../common/querystring-filters'
import {
  getPrevPeriod,
  getNextPeriod,
  periodToHumanReadable
} from '../../../common/periods'

import TimeFilter from '../TimeFilter'

const TimeNavigator = ({
  history,
  filters,
  timeOptionsExpand,
  programIds,
  isCycleBased,
  hasNoCycleOptions,
  onTimeframeSelected,
  centered,
  className
}) => {
  const period = filters.time

  const applyFilter = (time) => {
    if (onTimeframeSelected) {
      return onTimeframeSelected({
        time: time
      })
    }
    history.push({
      ...history.location,
      search: queryStringifyFilters({
        ...filters,
        time
      })
    })
  }

  return (
    <div className={classnames(
      'time-navigator-wrapper',
      className
    )}>
      <div className='time-navigator'>
        <section className='time-navigator__contents'>
          <Button
            colorVariant='neutral'
            fill='none'
            size='small'
            icon={<Chevron direction='left' />}
            onClick={() => applyFilter(getPrevPeriod(period))}
          >
              Prev
          </Button>
          <div className='time-navigator__date-label'>
            <ExpandableOptions
              expandSide='right'
              expandWrapperClassName={centered ? 'time-navigator__expanded-wrapper' : 'time-navigator__expanded-wrapper-wide'}
              ref={timeOptionsExpand}
              label={periodToHumanReadable(period)}
              expandableChildren={
                <TimeFilter
                  history={history}
                  filters={filters}
                  programIds={programIds}
                  close={() => timeOptionsExpand.current.toggleExpand()}
                  isCycleBased={isCycleBased}
                  hasNoCycleOptions={hasNoCycleOptions}
                  onTimeframeSelected={onTimeframeSelected}
                />
              }
            />
          </div>
          <Button
            colorVariant='neutral'
            fill='none'
            size='small'
            icon={<Chevron direction='right' />}
            iconSide='right'
            onClick={() => applyFilter(getNextPeriod(period))}
          >
              Next
          </Button>
        </section>
      </div>
    </div>
  )
}

TimeNavigator.defaultProps = {
  centered: true
}

export default TimeNavigator
