const { substitutionsToProducts } = require('./substitutions-to-products')
const { createCommodityTypeSnapshots, createStorageTypeSnapshots } = require('./split-snapshots-by-type')
const { packWith } = require('./../pack')
const { createSnapshot } = require('./snapshot-create')

// This takes a warehouse suborder snapshot and "clones" it for
// a snapshot for the acceptance user (REST API One integration users)
// to adjust.
exports.createAcceptedSnapshots = createAcceptedSnapshots
function createAcceptedSnapshots ({
  user, snapshots, productsById = {}, timestamp = new Date().toJSON()
}) {
  const splitOnCommodity = createCommodityTypeSnapshots({snapshots, productsById})
  const splitOnStorageType = createStorageTypeSnapshots({snapshots: splitOnCommodity, productsById})
  return splitOnStorageType.map(snapshot =>
    createSnapshot(Object.assign({}, snapshot, {
      timestamp,
      // we want to generage a new suborderId from the warehouse
      // as the order could have been broken down further on commodity type.
      suborderId: undefined,
      status: 'accepted',
      user,
      storageType: snapshot.storageType,
      products: getProducts(snapshot.products)
    }))
  )
}

function getProducts (warehouseSnapshotProducs) {
  // Find any substitutions and make them the actual product, otherwise do nothing
  const products = substitutionsToProducts(warehouseSnapshotProducs)

  // Make an `original` and `adjusted` that's just the warehouse adjusted number
  // so the acceptance user can adjust the adjusted numbers to be different
  return Object.keys(products)
    .reduce((acc, productId) => {
      acc[productId] = {
        original: products[productId].adjusted,
        adjusted: products[productId].adjusted
      }
      return acc
    }, {})
}

exports.applyPackoutToOrders = applyPackoutToOrders
function applyPackoutToOrders (orders, allocatedStock, products) {
  const pack = packWith(products)

  const adjustedAmounts = orders.reduce((acc, order) => {
    acc[order.destinationId] = Object.keys(order.products).reduce((acc, productId) => {
      acc[productId] = order.products[productId].adjusted
      return acc
    }, {})
    return acc
  }, {})

  const containedProductIds = orders.map((order) => {
    return Object.keys(order.products)
  }).reduce((acc, next) => [...acc, ...next])
  const uniqueProductIds = new Set(containedProductIds)

  for (const productId of uniqueProductIds) {
    const {packOut} = pack({productId}, adjustedAmounts, allocatedStock)
    for (const order of orders) {
      if (!order.products[productId]) {
        continue
      }
      order.products[productId].substitutions = Object
        .keys(packOut[order.destinationId] || {})
        .reduce((acc, productId) => {
          acc[productId] = {
            original: order.products[productId]
              ? order.products[productId].original
              : 0,
            adjusted: packOut[order.destinationId][productId]
          }
          return acc
        }, {})
    }
  }
  return orders
}
