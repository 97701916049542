module.exports = createSurvey

const toSnapshotDocIdProperties = require('./tools/to-doc-id-properties')
const toSurveyId = require('./tools/to-survey-id')
const toLocationProperties = require('./tools/to-location-properties')

function createSurvey (state, options) {
  const {
    snapshotId,
    productId,
    batchId,
    survey
  } = options

  const createdAt = new Date()
  const destinationId = toSnapshotDocIdProperties(snapshotId).destination
  const locationId = toLocationProperties(destinationId).id

  const id = toSurveyId({
    snapshotId,
    batchId,
    createdAt
  })
  const doc = {
    _id: id,
    type: 'survey',
    version: '1.0.0',
    createdAt: createdAt.toISOString(),
    createdBy: state.user.name,
    snapshotId,
    locationId,
    productId,
    batchId,
    survey: survey
  }

  return state.dal.shipment.write(state, doc)

    .then(({rev}) => {
      doc._rev = rev
      return doc
    })
}
