/**
 * This module contains functions to encode allocations as csv
 */

const {takeLatest} = require('./tools/read/generic-utils')
const {CSV_DELIMITER} = require('./config')

const {
  createRowsFromAllocation,
  columns
} = require('./encode-utils')

const encode = (rows) => {
  const headerStr = columns.map(({name}) => '"' + name + '"').join(CSV_DELIMITER)
  const rowsStr = rows.map(row => {
    const values = row.map(
      c => '"' + (typeof c === 'string' || typeof c === 'number' ? c.toString() : '') + '"'
    )
    return values.join(CSV_DELIMITER)
  }).join('\n')
  return headerStr + '\n' + rowsStr + '\n'
}

module.exports = (allocations, additionalRows) => {
  const rows = takeLatest(allocations).reduce(
    (acc, allocation) => acc.concat(createRowsFromAllocation(allocation)), []
  )
  return encode(rows.concat(additionalRows))
}
