const get = require('lodash/get')

function getAllKeys (object1, object2) {
  return Array.from(new Set(Object.keys(object1 || {}).concat(Object.keys(object2 || {}))))
}

function addAmounts (count1, count2) {
  const keys = getAllKeys(count1, count2)

  if (keys.length === 0) {
    return null
  }

  return keys.reduce((ret, key) => {
    ret[key] = {
      amount: get(count1, `${key}.amount`, 0) + get(count2, `${key}.amount`, 0)
    }

    return ret
  }, {})
}

function addBatches (batches1, batches2) {
  const batchIds = getAllKeys(batches1, batches2)

  if (batchIds.length === 0) {
    return null
  }

  return batchIds.reduce((batches, id) => {
    const fields = addAmounts(get(batches1, `${id}.fields`), get(batches2, `${id}.fields`))

    if (fields) {
      batches[id] = { fields: fields }
    }

    return batches
  }, {})
}

const standardKeys = ['amount', 'available', 'availableTotal']
function addProduct (mainProduct, otherProduct) {
  const batches = addBatches(get(mainProduct, 'batches'), get(otherProduct, 'batches'))
  const fields = addAmounts(get(mainProduct, 'fields'), get(otherProduct, 'fields'))
  const commits = addAmounts(get(mainProduct, 'commits'), get(otherProduct, 'commits'))

  const product = {}
  standardKeys.forEach(key => {
    product[key] = get(mainProduct, key, 0) + get(otherProduct, key, 0)
  })

  if (batches) {
    product.batches = batches
  }

  if (fields) {
    product.fields = fields
  }

  if (commits) {
    product.commits = commits
  }

  return product
}

function addStock (mainCount, otherCount) {
  const productKeys = getAllKeys(mainCount, otherCount)

  return productKeys.reduce((count, key) => {
    count[key] = addProduct(mainCount[key], otherCount[key])
    return count
  }, {})
}

module.exports = function addStockCounts (counts) {
  if (!Array.isArray(counts)) {
    throw new Error('Pass in an array of stock counts')
  }

  counts = counts.filter(x => x && x.stock)

  const mainCount = counts.shift()
  if (!mainCount || counts.length === 0) {
    return mainCount
  }

  return Object.assign({}, mainCount, {
    stock: counts.map(c => c.stock).reduce((allStock, count) => {
      return addStock(allStock, count)
    }, mainCount.stock)
  })
}
