class CurrentUserApiAdapter {
  constructor (restAdapter) {
    if (restAdapter) {
      this.restAdapter = restAdapter
    }
  }

  update (data) {
    return this.restAdapter.update('user/', data)
  }

  resetPassword (data) {
    return this.restAdapter.create('user/reset-password', data)
  }

  changePassword (resetToken, data) {
    return this.restAdapter.create(`user/change-password?resetToken=${resetToken}`, data)
  }

  activate (signupToken, data) {
    return this.restAdapter.create(`user/activate?signupToken=${signupToken}`, data)
  }

  checkToken (signupToken) {
    return this.restAdapter.get(`user/check-token?signupToken=${signupToken}`, {})
  }

  resendWelcomeEmail (email) {
    return this.restAdapter.create('signup/resend-activation-email', {email})
  }

  sendAccountSyncedEmail (data) {
    return this.restAdapter.create('user/send-account-synced-email', data)
  }

  sendUserMovedToClassicPAYGOnlyEmail (data) {
    return this.restAdapter.create('user/send-user-moved-to-classic-payg-only', data)
  }

  getUsersList (params) {
    return this.restAdapter.get('user/list', {...params})
  }
}

module.exports = CurrentUserApiAdapter
