const PLANNING_TYPES = {
  ROUTINE: 'routine',
  TOPUP: 'topup',
  C_R: 'collect-redistribute',
  RESUPPLY: 'resupply',
  PATIENT: 'patient',
  SPECIAL: 'special', // NOTE: 211209 - not used anywhere in PSM / SL
  PURCHASE: 'purchase',
  IMMEDIATE_PURCHASE: 'immediate_purchase' // These are on-line paid purchases
}
PLANNING_TYPES.DEFAULT = PLANNING_TYPES.ROUTINE

module.exports = PLANNING_TYPES
