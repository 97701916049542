import React, { Component } from 'react'
import flowRight from 'lodash/flowRight'

import { Banner, Button, Loading } from '@fielded/shared-ui'
import { userIsAuthorised } from '../../../van-shared/utils/auth'
import { withUser } from '../../../common/AuthenticationProvider'
import { withApi } from '../../../common/ApiProvider'

import {
  getNavigationTitle,
  getNavigationPath,
  getNavigationEntityName
} from '../../../common/utils/navigationInfo'
import capitalize from '../../../common/utils/capitalize'

const withShipment = (Wrapped, opts = {}) => {
  return class WithShipmentWrapper extends Component {
    constructor () {
      super()
      this.state = { shipment: null, loaded: false, error: null }
    }

    componentDidMount () {
      this.loadShipment(this.props.match.params.snapshotId)
    }

    // To pass down to wrapped children.
    // if shipment already defined in parent container update shipment without loading it
    reloadShipment = (snapshotId, shipment) => {
      if (shipment) {
        this.setState({shipment})
        return shipment
      }
      return this.loadShipment(snapshotId)
    }

    loadShipment =async (snapshotId) => {
      const { config, user, api } = this.props
      const userAsDistributor = userIsAuthorised(user, 'feature:distributor')
      try {
        let shipments = await api.shipment.find(snapshotId, {
          userAsDistributor,
          // This reads the shipment with any adjustments
          // that the user might have saved in their local store
          // used in Count, Confirmation, AddProduct, RemoveProduct views
          pendingAdjustments: opts.pendingAdjustments
        })
        if (shipments.length) {
          const shipment = shipments[0]
          this.setState({ loaded: true, shipment })
          return shipment
        } else {
          const entityName = getNavigationEntityName({item: 'shipments', config})
          this.setState({
            loaded: true,
            error: `${capitalize(entityName)} not found.`
          })
        }
      } catch (e) {
        console.error('Shipment find error', e)
        this.setState({ loaded: true, error: 'This shipment is not available offline.' })
      }
    }

    render () {
      const { config } = this.props
      const { error, loaded, shipment } = this.state

      if (!loaded) {
        return <Loading />
      }

      if (error) {
        return (
          <Banner
            title={`Error loading ${getNavigationEntityName({item: 'shipments', config})}`}
            cta={
              <Button
                href={getNavigationPath({item: 'shipments', config})}
                fill='outline'
                colorVariant='brand'
              >
                Go to {getNavigationTitle({item: 'shipments', config})}
              </Button>
            }
          >
            {error}
          </Banner>
        )
      }

      return (
        <Wrapped
          {...this.props}
          reloadShipment={this.reloadShipment}
          shipment={shipment}
        />
      )
    }
  }
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withShipment
)

export default withHOCs
