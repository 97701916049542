import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { pluralize } from '../../../utils'
import { FormContext } from '../'

const ValidationErrors = ({
  alignRight,
  className
}) => (
  <FormContext.Consumer>
    {({ getErrors, fields }) => {
      const errors = getErrors() || {}
      const errorCount = Object.keys(errors).length
      return errorCount ? (
        <div className={classnames(
          'vs-form-errors',
          {'vs-form-errors--align-right': alignRight},
          className
        )}>
          <div className='vs-form-errors__intro'>
            {errorCount} {pluralize('field', errorCount)} with issues:
          </div>
          <ul>
            {Object.keys(errors).map((key, index) => {
              const field = fields[key]
              const fieldLabel = field.labelText
              return fieldLabel ? (
                <li
                  key={index}
                  className='vs-form-field-errors__message'
                >
                  {fieldLabel}: {field.errors.join(', ')}
                </li>
              ) : null
            })}
          </ul>
        </div>
      ) : null
    }
    }
  </FormContext.Consumer>
)

ValidationErrors.propTypes = {
  /**
   * Set to true to align the messages on the right
   */
  alignRight: PropTypes.bool,

  /**
   * Optional custom class, please only use for spacing
   */
  className: PropTypes.string
}

ValidationErrors.defaultProps = {
  alignRight: false,
  className: undefined
}

ValidationErrors.displayName = 'Form.Field.ValidationErrors'

export default ValidationErrors
