const findReports = require('./stock-find-reports')

module.exports = async (state, options = {}) => {
  if (options.addMissingReports) {
    throw new Error('addMissingReport is no longer supported')
  }
  if (options.ignoreExisting) {
    throw new Error('ignoreExisting is no longer supported')
  }
  const mergedOptions = Object.assign({
    addSubmitConfig: true,
    addProgress: true,
    addMissingStock: 'products'
  },
  options,
  {
    includeChildren: false
  }
  )
  const reports = await findReports(state, mergedOptions)
  return reports[0]
}
