module.exports = {
  getLocation,
  getProgramsList,
  getFakeParentDoc,
  periodIsActive
}

const { isShelflifeService, isBasicTierService } = require('./utils/program')
const { MEMBERSHIPS } = require('../location/tools/constants')
const getLocationLevel = require('../utils/get-location-level')
const getConfigServices = require('./get-config-services')
const smartId = require('./smart-id')

const {
  getFullProgramId,
  getFullServiceID,
  isFilterIncluded,
  getFullId,
  getNames,
  getGeoLocationNames,
  getNormalizedAdditionalData,
  IDENTIFIER_TO_FILTER,
  IDENTIFIERTOID
} = require('./utils')

/*
 * Important!
 * Pass through ALL **location** reads through this one
 * it shapes V0 and V2 docs into the same shape
 *
 * @param {object} doc - A location doc
 * @param {string} date - JSON formatted date, to filter services in 2.0 format
 * @param {array} filter - array of funder ids, to filter which services get returned on docs that have funders or routes
 */
function getLocation (doc, date = new Date().toJSON(), filter = [], allConfigurations = [], includeProgramsHistory = false) {
  let programs
  let configurations
  let services
  let location

  // if someone supplies a null for date we use that, and default to the first service ever configured, which is bad.
  // (e.g. doing new Date(undefined).toJSON() gives you a null). so instead, we force there to always be a date
  // regardless of null or undefined
  date = date || new Date().toJSON()

  // New PSM docs and docs created through the master data api
  if (doc.version === '2.0.0' || doc.version === '3.0.0' || doc.version === '4.0.0') {
    const hasIdentifiers = shouldUseServiceIdentifiers(doc)
    // filter active programs
    programs = getProgramsList(date, doc.programs, filter, hasIdentifiers)
    // add services array based on active programs
    // (turns all programs into flat list of services)
    services = programs.reduce((all, p) => all.concat(p.services.map(s => s.id)), [])
    // create new configurations list based on active programs
    configurations = services.map(id => `configuration:${id}`)
    location = makeLocation(doc)
  // Old VAN/PSM Location Docs
  } else if (!doc.version) {
    // create configurations list based on config/parent config
    // add services array based on config
    const config = getConfigServices(doc)
    configurations = config.configurations
    services = config.services

    // add programs list based on services array
    programs = getV1Programs(services)
    location = makeLocation(doc)
  }

  location = Object.assign({}, location, getGeoLocationNames(doc))
  const additionalData = getNormalizedAdditionalData(doc.additionalData)

  // Add supplier relationship to location doc
  let supplierRelationships = {}
  if (programs[0] && programs[0].id === 'program:shelflife') {
    const service = services[0]

    let locationLevel = doc.level

    const config = allConfigurations.find(config => config.service === service)

    supplierRelationships = config && config.relationships && config.relationships[locationLevel]
      ? { supplierRelationships: config.relationships[locationLevel] }
      : {}
  }

  let programsHistory
  if (
    includeProgramsHistory &&
    doc.programs &&
    doc.programs.shelflife &&
    Object.keys(doc.programs.shelflife).length > 0
  ) {
    programsHistory = doc.programs
  }

  let membership

  if (doc.membership) {
    membership = doc.membership
    // else let's derive the membership from services in case the location
    // still does not hold the membership.

    // This only supports basic and pure classic membership types on purpose
    // as more modern membership types will be held in the membership entity property
  } else if (isBasicTierService(services[0])) {
    membership = MEMBERSHIPS.BASIC
  } else if (isShelflifeService(services[0]) && doc.level === 'sdp') {
    membership = MEMBERSHIPS.CLASSIC
  }
  const membershipObj = membership ? {membership} : {}

  const result = Object.assign({}, doc, getNames(doc), membershipObj, supplierRelationships, {
    fullName: doc.fullName || doc.name,
    programs,
    configurations,
    services,
    location,
    additionalData,
    // TODO: this should be a time-based property and not added on additionalData
    tracksPartnerBalances: !!additionalData.tracksPartnerBalances,
    // this is to exlude locations we never do stock counts at from hogging
    // server side ledger creation
    doesNotTrackLedger: additionalData.doesNotTrackLedger
  })

  if (programsHistory) {
    result.programsHistory = programsHistory
  }

  return result
}

function getProgramsList (date, programs = {}, filter = [], hasIdentifiers = false) {
  return Object.keys(programs).reduce((memo, programKey) => {
    const services = hasIdentifiers
      ? getServiceListWithIdentifiers(programs[programKey], date, programKey, filter)
      : getServicesList(programs[programKey], date, programKey, filter)
    if (services.length) {
      const id = getFullProgramId(programKey)
      memo.push({
        id,
        services
      })
    }
    return memo
  }, [])
}

function getServiceListWithIdentifiers (services, date, shortProgramId, filter) {
  return Object.keys(services).reduce((memo, serviceKey) => {
    const identifiers = services[serviceKey]
    Object.keys(identifiers).forEach(identifier => {
      const periods = identifiers[identifier]
      const activeServicePeriod = periods.find(period => periodIsActive(period, date))

      if (!activeServicePeriod) {
        return
      }

      const serviceFilter = activeServicePeriod[IDENTIFIER_TO_FILTER[identifier]]
      const idenTifierID = IDENTIFIERTOID[identifier]

      if (!isFilterIncluded(serviceFilter, filter, idenTifierID)) {
        return memo
      }

      let service = getServiceFromPeriod({
        id: getFullServiceID(serviceKey, shortProgramId),
        period: activeServicePeriod
      })

      const existingService = memo.find(memoService => memoService.id === service.id)

      // If this service does not exist push the service
      if (!existingService) {
        memo.push(service)
        return memo
      }

      // Find that service and remove it from the array
      const indexToDelete = memo.findIndex(obj => obj.id === existingService.id)
      memo.splice(indexToDelete, 1)

      // Add the updated service object back
      service = Object.assign({}, existingService, service)
      memo.push(service)
    })

    return memo
  }, [])
}

function getServicesList (services, date, shortProgramId, filter) {
  return Object.keys(services).reduce((memo, serviceKey) => {
    const periods = services[serviceKey]
    const activeServicePeriod = periods.find(period => periodIsActive(period, date))

    if (!activeServicePeriod) {
      return memo
    }

    let serviceFilter
    let identifier

    // Todo: If PSM starts storing route info on location docs we would need to refactor this!!
    // Check if the user has funders or routes
    if (shortProgramId === 'shelflife') {
      // SL does not use funderId's till the docs all get migrated we have to manually do this here
      if (activeServicePeriod.funderId) {
        activeServicePeriod.routeId = activeServicePeriod.funderId
        delete activeServicePeriod.funderId
      }
      serviceFilter = activeServicePeriod.routeId
      identifier = 'route'
    } else {
      serviceFilter = activeServicePeriod.funderId
      identifier = 'funder'
    }

    if (!isFilterIncluded(serviceFilter, filter, identifier)) {
      return memo
    }

    const service = getServiceFromPeriod({
      id: getFullServiceID(serviceKey, shortProgramId),
      period: activeServicePeriod
    })

    // For sl we want to make sure the entity has the routeId ot funderId
    if (identifier === 'route' && service.hasOwnProperty('funderId')) {
      service.routeId = service.funderId
      delete service.funderId
    }

    memo.push(service)
    return memo
  }, [])
}

function getServiceFromPeriod ({ id, period }) {
  const service = { id }
  for (let key in period) {
    // check for period[key] in case the UI gives us undefined values
    if (key !== 'startDate' && key !== 'endDate' && period[key]) {
      service[key] = getFullId(key, period[key])
    }
  }
  return service
}

function periodIsActive (period, date) {
  if (!date) return true
  let {startDate, endDate} = period
  if (!endDate) {
    // make enddate very high, so all expected years will be below
    endDate = '9999'
  }
  if (!startDate) {
    return (date < endDate)
  }
  // make startDate starts from beginning of day
  const startOfDay = new Date(startDate)
  startOfDay.setUTCHours(0, 0, 0, 0)
  return (startOfDay.toJSON() <= date && date < endDate)
}

// Creates a similar programs / services object out of a V1 services list
function getV1Programs (services) {
  const programs = {}

  services.forEach(serviceId => {
    const programId = serviceId.split(':service:')[0]
    programs[programId] = programs[programId] || {
      id: programId, services: []
    }

    // V0 docs won't have funders
    programs[programId].services.push({ id: serviceId })
  })

  // Take the object and turn into array
  // (had troubles with Object.entries)
  return Object.keys(programs).map(key => programs[key])
}

// Create a parent location if we did not find one
// TODO: When psm locations are aligned with van locations,
//       remove this fallback and make it fail hard.
function getFakeParentDoc (parentLocation) {
  return {
    _id: parentLocation,
    level: getLocationLevel(parentLocation),
    configurations: []
  }
}

/** Create a geo location info object
 */
function makeLocation (doc) {
  const idObj = doc._id === 'national' ? {} : smartId.parse(doc._id)
  if (idObj.hasOwnProperty('name')) {
    // for backwards compat with sdp level locations we reassign name
    if (doc.level === 'sdp') {
      idObj.sdp = idObj.name
    }
    // the name should not appear in the location object
    delete idObj.name
  }
  // Get the existing geo id or create the geo location id from the doc id
  let geoId
  if (doc.location && doc.location.id && doc.location.id.startsWith('country:')) {
    geoId = doc.location.id
  } else {
    // names are either encoded as 'sdp' or 'name'
    let nameIndex = Math.max(doc._id.indexOf('sdp:'), doc._id.indexOf('name:'))
    let idPart
    if (nameIndex !== -1) {
      idPart = doc._id.slice(0, nameIndex - 1)
    } else {
      idPart = doc._id
    }
    // Fall back to country:ng when we do not have a country value in the id
    geoId = idPart.startsWith('country:') ? '' : 'country:ng'
    if (idPart !== 'national') {
      geoId += ':' + idPart
    }
  }
  return Object.assign(
    {},
    idObj,
    smartId.parse(geoId),
    { id: geoId }
  )
}

function shouldUseServiceIdentifiers (doc) {
  // New version docs always have the right shape
  if (doc.version === '4.0.0') return true

  const programs = doc.programs
  // If this location has no program property return false
  if (!programs) return false
  if (Object.keys(doc.programs).length === 0) return false

  // Get first program
  // If we cant find a program return false
  const firstProgram = Object.keys(doc.programs)[0]
  if (Object.keys(programs[firstProgram]).length === 0) return false

  // Get first service from that propgram
  // The old shape (i.e. less than 4.0.0) the service is an array
  // But from 4.0.0 its an object {funders: [], routes: []}
  const firstService = Object.keys(programs[firstProgram])[0]
  if (Array.isArray(programs[firstProgram][firstService])) return false

  return true
}
