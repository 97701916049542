import React from 'react'
import PropTypes from 'prop-types'
import SelectInput, { TwoPartLabel } from '../../../SelectInput'

import { FieldContext } from '../'
import { requiredValidationErrorMessage } from '../../utils'
import FormFieldRegistrationManager from '../../FormFieldRegistrationManager'

/**
 *  Wrapper for input
 *
 *  Adds additional functionality such as validation
 */
class Select extends React.Component {
  state = {
    inputValue: undefined
  }

  handleInputChange = (inputValue) => {
    this.setState({inputValue})
    return inputValue
  }

  render = () => {
    const {
      defaultValue,
      // we pluck this from the props in order not to overrride the form state
      value,
      onValueChange,
      required,
      validate,
      className,
      ...otherProps
    } = this.props

    const {
      inputValue
    } = this.state

    return (
      <FieldContext.Consumer>
        {
          (field) => (
            <FormFieldRegistrationManager
              register={() => {
                field.register({
                  value: defaultValue,
                  required,
                  validate,
                  requiredValidator: async (value) => {
                    if (!value || (Array.isArray(value) && value.length === 0)) {
                      return required && requiredValidationErrorMessage(required)
                    }
                  },
                  ...otherProps
                })
              }}
              deregister={() => field.deregister()}
            >
              <SelectInput.Stateless
                defaultValue={defaultValue}
                value={field.props.value}
                hasError={field.hasErrors()}
                onValueChange={(content) => {
                  field.handleOnChange(content)

                  if (typeof onValueChange === 'function') {
                    onValueChange(content)
                  }
                }}
                className={className}
                optionRenderer={(option) => <TwoPartLabel option={option} inputValue={inputValue} />}
                valueRenderer={(option) => <TwoPartLabel option={option} />}
                onInputChange={this.handleInputChange}
                {...otherProps}
              />
            </FormFieldRegistrationManager>
          )
        }
      </FieldContext.Consumer>
    )
  }
}

Select.propTypes = {
  /**
   * The initial input value (one of the options or the value of one).
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),

  /**
   * Determines if user can create new options
   */
  creatable: PropTypes.bool,

  /**
   * Whether the field is required, optionally pass a string to define a custom
   * validation error message
   */
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),

  /**
   * A custom validation function
   */
  validate: PropTypes.func,

  /**
   * Classname(s) to pass to the form field
   */
  className: PropTypes.string,

  /**
   * Optional additional onChange handler (called after form onChange hanlder)
   */
  onChange: PropTypes.func,

  /**
   * Runs when field value changes (returns new value)
   */
  onValueChange: PropTypes.func
}

Select.defaultProps = {
  defaultValue: undefined,
  creatable: false,
  required: false,
  validate: undefined,
  classname: undefined,
  onChange: undefined
}

Select.displayName = 'Form.Field.Select'

export default Select
export { TwoPartLabel }
