const get = require('lodash/get')
const { listOrdersForUser } = require('../api/read/internal')
const { getByIds: getLocationsByIds } = require('../../location')
const { list: listFunders } = require('../../funders')
const { list: listPrograms } = require('../../program')
const { getAll: getAllServices } = require('../../service')
const { getByIds: getProductsByIds } = require('../../product')

// getLocationMeta returns the service and implementingPartner name for the given location and
// programId
const getLocationMeta = (location, programId, services, funders) => {
  const unknownMeta = {serviceName: 'Unknown service', funderName: 'Unknown funder'}

  const [matchingProgram = {}] = location.programs.filter(p => p.id === programId)
  // Heads up, weirdness: use the first service found on the program for that location, as
  // funders are stored on services (where there's time-history) but in reality
  // are 1:1 with a program, not a service.
  const [matchingService = null] = matchingProgram.services || []
  if (matchingService === null) {
    return unknownMeta
  }
  const serviceName = services.find(s => s.id === matchingService.id).name || ''

  const matchingFunder = funders.find(f => f._id === matchingService.funderId)

  if (!matchingFunder) {
    return unknownMeta
  }

  const implementingPartner = get(
    matchingFunder,
    `programs.${programId}.implementingPartners`,
    []
  ).find(ip => ip._id === matchingService.implementingPartnerId) || {}

  const funderName = implementingPartner.name || ''

  return {serviceName, funderName}
}

// getOrderExportData fetches all data needed to generate a workbook for the
// given order groupId using the given api instances.
exports.getOrderExportData = async (state, programId) => {
  const locationId = state.user.location.id
  const ordersResponse = await listOrdersForUser(
    state, programId, locationId, {withAccepted: true, withSnapshots: true}
  )

  const orders = ordersResponse.orders.map(order => {
    return Object.assign({}, order, {
      products: getProductsForExport(order)
    })
  })

  let [locations, funders, services] = await Promise.all([
    getLocationsByIds(state, orders.map(o => o.destinationId)),
    listFunders(state),
    listPrograms(state)
      .then((programs) => {
        return Promise.all(programs.map(p => getAllServices(state, p.id)))
      })
      .then((results) => results.reduce((acc, next) => [...acc, ...next], []))
  ])
  const locationsById = locations.reduce((acc, next) => {
    acc[next._id] = next
    return acc
  }, {})

  const productsContainedInOrders = [...orders
    .map(o => Object.keys(o.products))
    .reduce((acc, productIds) => {
      for (const id of productIds) {
        acc.add(id)
      }
      return acc
    }, new Set())]

  const allProducts = await getProductsByIds(state, productsContainedInOrders)

  const productsById = allProducts
    .sort(({_id: a}, {_id: b}) => {
      return a.localeCompare(b)
    })
    .reduce((acc, next) => {
      acc[next._id] = next
      return acc
    }, {})

  return orders
    .filter(order => locationsById[order.destinationId])
    .reduce((acc, order) => {
      // in the LMD export, order quantities are supposed to be displayed
      // as warehouse units, so the ordered quantity needs to be divided by
      // the product's unit of issue
      order.products = Object.keys(order.products).reduce((acc, productId) => {
        acc[productId] = Object.keys(order.products[productId]).reduce((acc, key) => {
          acc[key] = order.products[productId][key]
          if (Number.isFinite(order.products[productId][key])) {
            acc[key] = Math.ceil(acc[key] / (productsById[productId].genericFactor || 1))
          }
          return acc
        }, {})
        return acc
      }, {})

      const location = locationsById[order.destinationId]
      const {
        serviceName, funderName
      } = getLocationMeta(location, programId, services, funders)

      acc.orders.push(Object.assign({}, order, {
        serviceName,
        funderName
      }))
      return acc
    }, {
      orders: [],
      programId,
      productsById,
      locationsById,
      funders,
      services
    })
}

function getProductsForExport (order) {
  // there can only be one new snapshot
  const [newSnapshot] = order.snapshotsByStatus['new'] || []
  // if we are a warehouse user, we do not see a new snapshot, just the warehouse snapshot.
  // a warheouse user will only have one warheouse snapshot per order.
  const [warehouseSnapshot] = order.snapshotsByStatus['warehouse'] || []

  if (!newSnapshot && !warehouseSnapshot) {
    throw new Error(`Could not find original quantities for suborderId ${order.suborderId}`)
  }

  // accepted snapshots can have more than one if the original order was broken
  // down into different warehouses / commodityTypes
  const acceptedSnapshots = get(order.snapshotsByStatus, 'accepted', [])
  const acceptedSnapshot = (acceptedSnapshots.length === 0)
    ? null
    : Object.assign({}, acceptedSnapshots[0], {
      products: acceptedSnapshots
        .reduce((acc, snapshot) => Object.assign({}, acc, snapshot.products), {})
    })

    // get union of any product ids found in any snapshots on this order
  const productIds = Array.from(new Set(
    Object.keys(Object.assign(
      {},
      get(newSnapshot, 'products', {}),
      get(warehouseSnapshot, 'products', {}),
      get(acceptedSnapshot, 'products', {})
    ))
  ))

  return productIds.reduce((acc, productId) => {
    // for each location:product, we need three columns:
    // 1. requested: this is the qto the state user agreed to or edited on the `new` snapshot
    // - or the original quantity on the warehouse snapshot, which should be the same thing.
    // 2. accepted: this is what the warehouse user said to supply when they accepted their warehouse snapshots.
    // it is stored on the accepted snapshots (& sent to ONE network integration via REST api)
    // 3. supplied: this is what ONE network posted back as being supplied

    //
    const requested = newSnapshot
      ? get(newSnapshot, `products.${productId}.adjusted`, 0)
      : get(warehouseSnapshot, `products.${productId}.original`, 0)

    if (!acceptedSnapshot) {
      acc[productId] = {
        requested,
        accepted: '',
        supplied: ''
      }
      return acc
    }

    // because there was an accepted snapshot, we can show a zero here if there's no value.
    const accepted = get(acceptedSnapshot, `products.${productId}.original`, 0)

    // A little awkward branching here:
    // If there is no closedStatus, show '', because we never heard from the ONE network integration.
    let supplied = (acceptedSnapshot.closedStatus)
      ? get(acceptedSnapshot, `products.${productId}.adjusted`, '')
      : ''

    // but if we did hear from ONE, and it was failed, ignore the adjusted and use 0
    if (acceptedSnapshot.closedStatus === 'failed') {
      supplied = 0
    }

    acc[productId] = {
      requested,
      accepted,
      supplied
    }
    return acc
  }, {})
}
