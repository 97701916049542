const PGAdapter = require('./../common/pg-adapter')
const fs = require('fs')
const path = require('path')
const { getPositionalArgs } = require('../tools/sql-tools')

class PriceDailyPGAdapter extends PGAdapter {
  constructor ({pgConnection, tableName, username, logger}) {
    super(pgConnection, 'real.buyprice_daily', username, ['sku', 'date', 'buyprice'], 'sku')
    this.logger = logger
  }

  /*
    buyprice daily update takes a sku and date, and automatically calculates
    daily prices from the `real.price_history` table for every day after that date
    this would normally be called after a price_history update
    if {sku, date} is not passed, then only refresh prices that might be missing already
    without deleting anything
  */
  async create ({date, sku}) {
    this.logger.debug('regenerating price dailies')
    if (date && sku) {
      await this.pgConnection.query(`DELETE FROM real.buyprice_daily WHERE date >= $1 and sku = $2;`, [date, sku])
      await this.pgConnection.query(`DELETE FROM real.sellprice_daily WHERE date >= $1 and sku = $2;`, [date, sku])
    }

    const scripts = [
      'api/sql/price_daily/buyprice_daily.sql',
      'api/sql/price_daily/sellprice_daily.sql'
    ]

    const response = []
    for (let i = 0; i < scripts.length; i++) {
      const buffer = await fs.promises.readFile(path.resolve(__dirname, '../../../' + scripts[i]))
      const query = buffer && buffer.toString()
      buffer && response.push(await this.pgConnection.query(query))
    }
    this.logger.debug('done regenerating price dailies')
    return { id: sku, count: response.reduce((a, c) => a + (c.rowCount || 0), 0) }
  }

  async delete () {
    throw new Error('*price_daily cannot be modified directly')
  }

  async update () {
    throw new Error('*price_daily cannot be modified directly')
  }

  async fetchBulkPrices (skuIds) {
    const values = getPositionalArgs(skuIds)
    const query = `
      SELECT
        p.product_id as sku,
        bp.buyprice,
        CAST(sp.sellprice as DOUBLE PRECISION),
        ps.vat
      FROM
        avocado.vw_products p
        LEFT JOIN real.buyprice_daily bp ON p.product_id = bp.sku and bp.date = CURRENT_DATE
        LEFT JOIN real.sellprice_daily sp ON p.product_id = sp.sku and sp.date = CURRENT_DATE
        LEFT JOIN avocado.data_productsku ps ON p.product_id = ps.old_sku_id
      WHERE p.product_id IN (${values})`
    const { rows } = await this.pgConnection.query(query, skuIds)
    return rows
  }
}

module.exports = {PriceDailyPGAdapter}
