import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  productSelection: {}
}

export const topupsSlice = createSlice({
  name: 'topUp',
  initialState,
  reducers: {
    productUpdate: (state, action) => {
      const { productId, type, quantity } = action.payload
      state.productSelection = {
        ...state.productSelection,
        [productId]: { type, quantity }
      }
    },
    productRemove: (state, action) => {
      const productId = action.payload
      const newSelection = { ...state.productSelection }
      delete newSelection[productId]
      state.productSelection = newSelection
    },
    selectionClear: (state) => {
      state.productSelection = {}
    }
  }
})

export const {
  productUpdate,
  productRemove,
  selectionClear
} = topupsSlice.actions

export const selectProductSelection = (state) => state.topups.productSelection

export default topupsSlice.reducer
