const saveReport = require('../../stock-save-report')
const findReports = require('../../stock-find-reports')
const findReport = require('../../stock-find-report')
const submitReport = require('../../stock-submit-report')
const checkStockShipmentUpdate = require('../../stock-report-shipment-update')
const deleteReport = require('../../stock-delete-report')
const getLedgerBalance = require('../../stock-get-ledger-balance')
const getLedgerBalanceAsReport = require('../../stock-get-ledger-balance-as-report')
const getLedgerBalancesBulk = require('../../stock-get-ledger-balances-bulk')
const {
  exportStockSituation,
  exportStockSituationData
} = require('../stock-situation')

const {
  exportStockSituationData: stockSituationEvent
} = require('../stock-situation-event')
const {
  exportLedgerData
} = require('../ledgers')

const { wrapEntityApi } = require('../../utils/wrap-api')

const rawMethods = {
  findReports: findReports,
  findReport: findReport,
  saveReport: saveReport,
  submitReport: submitReport,
  deleteReport: deleteReport,
  checkStockShipmentUpdate: checkStockShipmentUpdate,
  getLedgerBalance: getLedgerBalance,
  getLedgerBalanceAsReport: getLedgerBalanceAsReport,
  getLedgerBalancesBulk: getLedgerBalancesBulk,
  exportStockSituation: exportStockSituation,
  exportStockSituationData: exportStockSituationData,
  stockSituationEvent,
  exportLedgerData: exportLedgerData
}

class StockPouchDBAdapter {
  constructor (state) {
    // const { user } = state
    // TODO: remove this when all raw methods have been ported
    const apiMethods = wrapEntityApi(rawMethods, state)
    Object.assign(this, apiMethods)
  }
}

module.exports = {
  // TODO: remove this when all raw methods have been ported
  rawMethods,
  StockPouchDBAdapter
}
