import React from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import { Text } from '@fielded/shared-ui'
import { selectFinances } from '../reducers/financesSlice'

export const OverdueInfo = ({ isPaused }) => {
  const finances = useSelector(selectFinances)
  const overduePayments = get(finances, 'payables.overduePayments[0]')
  const overduePaymentsCount = overduePayments ? overduePayments.transactions.length : 0

  const warningText = (
    <Text>
      Please note that your account will be blocked and deliveries suspended <Text tag='span' bold>if any payment is overdue</Text>. Pay immediately to avoid suspension of service.
    </Text>
  )

  const blockedText = (
    <Text>
      You have <Text tag='span' bold>{overduePaymentsCount} overdue payments.</Text>
    </Text>
  )

  return isPaused ? blockedText : warningText
}
