const ReportStatus = {
  NOT_STARTED: 'notStarted',
  IN_PROGRESS: 'inProgress',
  COMPLETE: 'complete'
}

// This is a simplified version of lib/tools/utils/report-progress
// TODO: Does this simplified version work for us?
//       it seems excessive to have to get the products for
//       this progress
module.exports = report => {
  if (!report ||
      !report.stock ||
      Object.keys(report.stock).length === 0) {
    return ReportStatus.NOT_STARTED
  }
  if (report.submittedAt) {
    return ReportStatus.COMPLETE
  }
  return ReportStatus.IN_PROGRESS
}

module.exports.ReportStatus = ReportStatus
