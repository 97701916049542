import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'
import { Copy } from '../../icons'

const CopyButton = ({
  copiedText,
  className
}) => {
  const copyTextToClipboard = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard
        .writeText(copiedText)
    } else {
      return document.execCommand('copy', true, copiedText)
    }
  }

  return (
    <Button
      className={classnames(
        'vs-u-copy-button',
        className
      )}
      colorVariant='brand'
      size='small'
      fill='none'
      icon={<Copy />}
      title='copy'
      onClick={() => copyTextToClipboard()}
    >
      Copy
    </Button>
  )
}

CopyButton.propTypes = {
  /**
   * A text to be copied
   */
  copiedText: PropTypes.string
}

export default CopyButton
