const PaystackBase = require('./base')
const mockData = {
  'transaction/verify/test_reference': {
    'status': true,
    'message': 'Verification successful',
    'data': {
      'id': 111,
      'domain': 'test',
      'status': 'success',
      'reference': 'nms6uvr1pl',
      'amount': 200000,
      'message': null,
      'gateway_response': 'Successful',
      'paid_at': '2020-05-19T12:30:56.000Z',
      'created_at': '2020-05-19T12:26:44.000Z',
      'channel': 'card',
      'currency': 'NGN',
      'ip_address': '154.118.28.239',
      'metadata': '',
      'log': {
        'start_time': 1589891451,
        'time_spent': 6,
        'attempts': 1,
        'errors': 0,
        'success': true,
        'mobile': false,
        'input': [],
        'history': [
          {
            'type': 'action',
            'message': 'Attempted to pay with card',
            'time': 5
          },
          {
            'type': 'success',
            'message': 'Successfully paid with card',
            'time': 6
          }
        ]
      },
      'fees': 300,
      'fees_split': {
        'paystack': 300,
        'integration': 40,
        'subaccount': 19660,
        'params': {
          'bearer': 'account',
          'transaction_charge': '',
          'percentage_charge': '0.2'
        }
      },
      'authorization': {
        'authorization_code': 'AUTH_xxxxxxxxxx',
        'bin': '408408',
        'last4': '4081',
        'exp_month': '12',
        'exp_year': '2020',
        'channel': 'card',
        'card_type': 'visa DEBIT',
        'bank': 'Test Bank',
        'country_code': 'NG',
        'brand': 'visa',
        'reusable': true,
        'signature': 'SIG_xxxxxxxxxxxxxxx',
        'account_name': null
      },
      'customer': {
        'id': 24259516,
        'first_name': null,
        'last_name': null,
        'email': 'customer@email.com',
        'customer_code': 'CUS_xxxxxxxxxxx',
        'phone': null,
        'metadata': null,
        'risk_action': 'default'
      },
      'plan': null,
      'order_id': null,
      'paidAt': '2020-05-19T12:30:56.000Z',
      'createdAt': '2020-05-19T12:26:44.000Z',
      'requested_amount': 20000,
      'transaction_date': '2020-05-19T12:26:44.000Z',
      'plan_object': {},
      'subaccount': {
        'id': 37614,
        'subaccount_code': 'ACCT_xxxxxxxxxx',
        'business_name': 'Cheese Sticks',
        'description': 'Cheese Sticks',
        'primary_contact_name': null,
        'primary_contact_email': null,
        'primary_contact_phone': null,
        'metadata': null,
        'percentage_charge': 0.2,
        'settlement_bank': 'Guaranty Trust Bank',
        'account_number': '0123456789'
      }
    }
  },
  'transaction': {
    status: true,
    message: 'Transactions retrieved',
    data: [{
      'id': 2849088512,
      'domain': 'test',
      'status': 'success',
      'reference': 'T897855707032556',
      'amount': 5025500,
      'message': 'madePayment',
      'gateway_response': 'Approved',
      'paid_at': '2023-06-01T09:15:39.000Z',
      'created_at': '2023-06-01T09:15:19.000Z',
      'channel': 'bank',
      'currency': 'NGN',
      'ip_address': '88.26.169.117',
      'metadata': {
        'custom_fields': [{
          'display_name': 'Order ID',
          'variable_name': 'Order ID',
          'value': 'UwiTs-6rm'
        }, { 'display_name': 'Total Amount', 'variable_name': 'Total Amount', 'value': 50255 }],
        'referrer': 'http://dev.shelflife.localhost:3000/retailer/delivery-adjustment/type:orderSnapshot:groupId:A1QFx9OaFN:orderId:yAzRrDRG8:suborderId:3SwEkN93zD'
      },
      'log': {
        'start_time': 1685610920,
        'time_spent': 19,
        'attempts': 1,
        'authentication': 'payment_token',
        'errors': 0,
        'success': true,
        'mobile': false,
        'input': [],
        'history': [{ 'type': 'input', 'message': 'Filled this field: account number', 'time': 2 }, {
          'type': 'action',
          'message': 'Attempted to pay with bank account',
          'time': 2
        }, { 'type': 'auth', 'message': 'Authentication Required: birthday', 'time': 2 }, {
          'type': 'auth',
          'message': 'Authentication Required: registration_token',
          'time': 6
        }, { 'type': 'auth', 'message': 'Authentication Required: payment_token', 'time': 9 }, {
          'type': 'success',
          'message': 'Successfully paid with bank account',
          'time': 19
        }]
      },
      'fees': 200000,
      'fees_split': null,
      'customer': {
        'id': 98862024,
        'first_name': '',
        'last_name': '',
        'email': 'annsargsyann@gmail.com',
        'phone': '',
        'metadata': null,
        'customer_code': 'CUS_jej8tvrhmpy2u63',
        'risk_action': 'default'
      },
      'authorization': {
        'authorization_code': 'AUTH_lwtt50hhuk',
        'bin': '000XXX',
        'last4': 'X000',
        'exp_month': '12',
        'exp_year': '9999',
        'channel': 'bank',
        'card_type': '',
        'bank': 'Zenith Bank',
        'country_code': 'NG',
        'brand': 'Zenith Emandate',
        'reusable': false,
        'signature': null,
        'account_name': null
      },
      'plan': {},
      'split': {},
      'subaccount': {},
      'order_id': null,
      'paidAt': '2023-06-01T09:15:39.000Z',
      'createdAt': '2023-06-01T09:15:19.000Z',
      'requested_amount': 5025500,
      'source': { 'source': 'checkout', 'type': 'web', 'identifier': null, 'entry_point': 'request_inline' },
      'pos_transaction_data': null
    }],
    meta: {
      'total': 1,
      'total_volume': 1,
      'skipped': 0,
      'perPage': '1000',
      'page': 1,
      'pageCount': 1
    }
  }
}

class PaystackMock extends PaystackBase {
  async request (method, path, body) {
    const [basePath] = path.split('?')
    return mockData[basePath]
  }
}

module.exports = PaystackMock
