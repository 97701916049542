import React, { useState } from 'react'

import { QuantityDiff, Table, Text } from '@fielded/shared-ui'

import getSortDirection from '../../../../../../van-shared/hooks/getSortDirection'

const LMDDeliveryDetailTable = ({
  isReceived,
  isPacked,
  productList
}) => {
  const [sortBy, setSortBy] = useState()
  const [sortDirection, setSortDirection] = useState()

  const handleSort = (columnName, direction) => {
    const newSortDirection = getSortDirection(sortBy, columnName, direction)
    setSortDirection(newSortDirection)
    setSortBy(columnName)
  }

  const tableColumns = [
    {
      header: 'Product',
      key: 'name',
      dataKey: 'name',
      width: 180,
      cellRenderer: ({ cellData }) =>
        <Text bold>
          {cellData}
        </Text>
    },
    {
      header: isPacked ? 'Packed qty' : 'Planned qty',
      key: 'packedQty',
      dataKey: 'packedQty',
      width: 200,
      align: 'right',
      cellRenderer: ({ cellData }) =>
        <Text bold>
          {cellData}
        </Text>
    }
  ]

  if (isReceived) {
    tableColumns.push({
      header: 'Delivered qty',
      key: 'quantity',
      dataKey: 'quantity',
      width: 200,
      align: 'right',
      cellDataGetter: ({rowData}) => rowData,
      cellRenderer: ({ cellData }) => {
        const isDeliveredQtyDifferent = cellData.packedQty !== cellData.quantity

        if (isDeliveredQtyDifferent && cellData.quantity) {
          return (
            <QuantityDiff
              difference={-cellData.quantity}
              size='inherit'
              showSymbol={false}
              showArrow={false}
            />
          )
        }

        return (
          <Text bold color={!cellData.quantity && 'warning'}>
            {cellData.quantity}
          </Text>
        )
      }
    })
  }

  return (
    <Table
      entries={productList}
      onHandleSort={handleSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      columns={tableColumns}
    />
  )
}

export default LMDDeliveryDetailTable
