const { methods } = require('./config')

const getFacilityLocation = (id) => {
  const parts = id.split(':')
  let location
  if (parts[0] === 'national') {
    return 'national'
  }
  location = {}
  for (let i = 0; i < parts.length; i += 2) {
    const key = parts[i]
    const value = parts[i + 1]
    if (key === 'name') {
      break
    }
    location[key] = value
  }
  return location
}

const getFacilityName = (allocation) => {
  const id = allocation.facilityId
  const match = id.match(/:name:([a-z0-9-]+)(?:$|:)/)
  if (match) {
    return match[1]
  }
  return id.split(':').pop()
}

const columns = [
  {
    name: 'id',
    encode: ({facility: {id}}) => id
  },
  {
    name: 'zone',
    encode: ({facility: {location: {zone}}}) => zone || ''
  },
  {
    name: 'state',
    encode: ({facility: {location: {state}}}) => state || ''
  },
  {
    name: 'lga',
    encode: ({facility: {location: {lga}}}) => lga || ''
  },
  {
    name: 'name',
    encode: ({facility: {name}}) => name
  },
  {
    name: 'product',
    encode: ({productId}) => productId ? productId.split(':')[1] : ''
  },
  {
    name: 'allocation-method',
    encode: ({forecast: {method}}) => method
  },
  {
    name: 'tp: target population',
    encode: ({forecast}) =>
      forecast.method === methods.TP
        ? forecast.targetPopulation
        : ''
  },
  {
    name: 'tp: wastage',
    encode: ({forecast}) =>
      forecast.method === methods.TP
        ? forecast.wastageFactor
        : ''
  },
  {
    name: 'tp: coverage',
    encode: ({forecast}) =>
      forecast.method === methods.TP
        ? forecast.coverageFactor
        : ''
  },
  {
    name: 'tp: adjustment',
    encode: ({forecast}) =>
      forecast.method === methods.TP
        ? forecast.adjustmentFactor
        : ''
  },
  {
    name: 'tp: doses',
    encode: ({forecast}) =>
      forecast.method === methods.TP
        ? forecast.dosesInSchedule
        : ''
  },
  {
    name: 'bundled: products',
    encode: ({forecast}) =>
      forecast.method === methods.BUNDLED
        ? forecast.dependentProducts.map(p => p.split(':')[1]).join(',')
        : ''
  },
  {
    name: 'bundled: factor',
    encode: ({forecast}) =>
      forecast.method === methods.BUNDLED
        ? forecast.factor
        : ''
  },
  {
    name: 'flat: annual allocation',
    encode: ({forecast}) =>
      forecast.method === methods.FLAT
        ? 'NOT IMPLEMENTED'
        : ''
  },
  {
    name: 'direct order',
    encode: ({forecast}) => forecast.method === methods.DIRECT_ORDER ? forecast.directOrder : ''
  },
  {
    name: 'consumption: days to average',
    encode: ({forecast}) =>
      forecast.method === methods.CONSUMPTION
        ? 'NOT IMPLEMENTED'
        : ''
  },
  {
    name: 'supply: buffer days',
    encode: ({supplyPlan}) =>
      supplyPlan
        ? supplyPlan.bufferDays
        : ''
  },
  {
    name: 'supply: lead time',
    encode: ({supplyPlan}) =>
      supplyPlan
        ? supplyPlan.leadTimeDays
        : ''
  },
  {
    name: 'supply: period days',
    encode: ({supplyPlan}) =>
      supplyPlan
        ? supplyPlan.supplyPeriodDays
        : ''
  }
]

const createRow = data => columns.map(c => c.encode(data))

const createRowsFromAllocation = (allocation) => {
  const facility = {
    id: allocation.facilityId,
    name: getFacilityName(allocation),
    location: getFacilityLocation(allocation.facilityId)
  }

  const products = allocation.products
  if (!products) {
    return []
  }

  return Object.keys(products).map(productId => {
    const product = products[productId]
    return createRow({
      productId,
      facility,
      forecast: product.forecast,
      supplyPlan: product.supplyPlan
    })
  })
}

module.exports = {
  createRowsFromAllocation,
  createRow,
  columns,
  getFacilityLocation
}
