import React, { Fragment, useState } from 'react'
import get from 'lodash/get'

import { Banner, Button, KeyValueTable, Modal, Text } from '@fielded/shared-ui'
import { InfoCircle, PartnerBalanceIndicator } from '@fielded/shared-ui/src/icons'

import SummaryTable from '../../../van-shared/components/SummaryTable'

// Modal for the Confirmation view
const ConfirmationTableModal = ({ data, hasWarning, title, currency, locationName, hasPartnerBalance, isSalesSummary, showVAT, appName }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const openingBalanceData = [
    {
      text: 'Opening balance',
      value: get(data, 'field:standard-opening-balance.amount')
    }
  ]

  if ((hasPartnerBalance || hasWarning) && !isSalesSummary) {
    openingBalanceData.push(
      {
        text: `${locationName}`,
        value: get(data, 'field:opening-partner-balance.amount')
      }, {
        text: `${appName}'s`,
        value: get(data, 'field:opening-shelflife-balance.amount')
      }
    )
  }

  const partnerBalanceData = [
    {
      text: 'Quantity on shelf',
      value: get(data, 'field:standard-physical-count.amount')
    }
  ]

  if (hasPartnerBalance && !hasWarning && !isSalesSummary) {
    partnerBalanceData.push(
      {
        text: `${locationName}`,
        value: get(data, 'field:partner-balance.amount')
      }, {
        text: `${appName}'s`,
        value: get(data, 'field:shelflife-balance.amount')
      }
    )
  }

  if (hasWarning) {
    const amountOverstocked = get(data, 'field:standard-consumed.amount') * -1

    partnerBalanceData.push(
      {
        text: `${locationName}`,
        value: `(+${amountOverstocked})`,
        warning: true
      }, {
        text: `${appName}'s`,
        value: get(data, 'field:opening-shelflife-balance.amount')
      }
    )
  }

  let soldData = []

  if (!hasWarning) {
    soldData.push({
      text: 'Total Sold',
      value: get(data, 'field:standard-consumed.amount')
    })
  }

  if (hasPartnerBalance && !hasWarning && !isSalesSummary) {
    soldData.push(
      {
        text: `${locationName}`,
        value: get(data, 'field:partner-sold.amount')
      }, {
        text: `${appName}'s`,
        value: get(data, 'field:shelflife-sold.amount')
      }
    )
  }

  if (hasWarning) {
    soldData = [
      {
        text: `Last recorded number of ${appName} products`,
        value: get(data, 'field:opening-shelflife-balance.amount')
      },
      {
        text: 'Total products to be billed',
        value: 'TBD'
      }
    ]
  }

  const amountDueData = {
    header: {
      text: 'Amount due'
    }
  }

  if (!hasWarning) {
    amountDueData.body = [{
      text: `${appName}'s sold:`,
      amount: get(data, 'field:shelflife-sold.amount', 0),
      ...(showVAT && isSalesSummary && {vat: get(data, 'field:unit-vat.vat', 0)}),
      price: get(data, 'field:price.price', 0),
      summary: `${currency} ${get(data, 'field:sold.price', 0)}`
    }]
  }

  if (hasWarning) {
    // If OOC we show opening sl balance as sl sold
    const openingSLBalance = 'TBD'
    const price = get(data, 'field:price.price', 0)
    const total = 'TBD'

    amountDueData.body = [{
      text: `${appName}'s sold:`,
      amount: openingSLBalance,
      price,
      summary: total
    }]
  }

  const modalIcon = (hasPartnerBalance) => {
    if (hasPartnerBalance) {
      return <PartnerBalanceIndicator />
    }
  }

  return (
    <Fragment>
      <Button
        className='confirmation__modal-trigger'
        onClick={() => setModalOpen(true)}
        icon={<InfoCircle />}
        colorVariant='brand'
        fill='none'
        size='inline'
      />

      <Modal title={title} icon={modalIcon(hasPartnerBalance)} isOpen={isModalOpen} onClose={() => setModalOpen(false)} >
        {hasWarning && (
          <Banner
            type='warning'
            inline
            className='vs-u-margin-bottom'
          >
            This product was flagged for outside buying, as we counted units that were not supplied by {appName}. You will be invoiced for the remaining {appName} balance.
          </Banner>
        )}
        <KeyValueTable
          entries={openingBalanceData}
          alternated
          semiHeader
          className='vs-u-margin-bottom'
        />
        <KeyValueTable
          entries={partnerBalanceData}
          alternated
          semiHeader
          className='vs-u-margin-bottom'
        />
        {hasWarning &&
          <Fragment>
            <Text
              size='small'
              className='confirmation-table-modal__ooc-warning'
            >
              The quantity on shelf is higher than the opening balance which means you bought outside.
            </Text>
            <div className='confirmation-table-modal__ooc-warning-box'>
              Due to outside buying, you will be invoiced for all {appName} products in your possession and we will determine the quantity
            </div>
          </Fragment>
        }
        <KeyValueTable
          entries={soldData}
          alternated
          semiHeader
          className='vs-u-margin-bottom'
        />
        <SummaryTable theme='default' data={amountDueData} showVAT={!hasWarning && showVAT} />
      </Modal>
    </Fragment>
  )
}

export default ConfirmationTableModal
