import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Radio = ({
  value,
  label,
  subLabel,
  additionalInfo,
  isSelected,
  controlInvisible,
  ...otherProps
}) => {
  // Because we're hiding the input element
  // we have to click the label in testing
  const dataQa = otherProps['data-qa']
  delete otherProps['data-qa']

  return (
    <div className='vs-radio-wrapper'>
      <label
        className={classnames(
          'vs-radio',
          {'vs-radio--selected': isSelected},
          {'vs-radio--with-sub-label': subLabel},
          {'vs-radio--no-control': controlInvisible}
        )}
        data-qa={dataQa}
        key={`option-${value}`}
      >
        <input
          value={value}
          className='vs-radio__input'
          type='radio'
          checked={isSelected}
          {...otherProps}
        />
        <span className='vs-radio__faux-radio' />
        <span className='vs-radio__label'>
          {label}
          {subLabel && <span className='vs-radio__sub-label'>{subLabel}</span>}
        </span>
      </label>
    </div>
  )
}

Radio.propTypes = {
  /**
   * A radio button will always be part of a group.
   * This is the value of the individual option in the group.
   */
  value: PropTypes.string.isRequired,

  /**
   * Label of the option, e.g. for a sorting selection: Alphabetical
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

  /**
   * Optional sublabel, e.g. for a sorting selection: A-Z
   */
  subLabel: PropTypes.node,

  /**
   * Optional info used in Options Group, must be included here for consistency even though it's yet unused
   */
  additionalInfo: PropTypes.node,

  /**
   * Set to mark as the currently selected Radio in a group
   */
  isSelected: PropTypes.bool,

  /**
   * In this case the radio control is not visible at all (usually used in options group with a highlight)
   */
  controlInvisible: PropTypes.bool
}

Radio.defaultProps = {
  subLabel: undefined,
  isSelected: undefined,
  controlInvisible: false
}

export default Radio
