module.exports = filterEmptyStock

const empty = item => {
  return Object.keys(item || {}).length === 0
}

function filterEmptyStock (stock) {
  const productIds = Object.keys(stock)
  const filteredStock = Object.assign({}, stock)

  productIds.forEach(id => {
    const emptyProduct =
      empty(stock[id]) ||
      (stock[id].batches && empty(stock[id].batches)) ||
      (stock[id].fields && empty(stock[id].fields))

    if (emptyProduct) {
      delete filteredStock[id]
    }
  })

  return filteredStock
}
