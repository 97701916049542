const PGAdapter = require('./../common/pg-adapter')
const TRANSACTIONS_TABLE_NAME = 'avocado.data_quickbookstransactions'
const TRANSACTIONS_COLUMNS = [
  'id',
  'company_code',
  'txn_id',
  'txn_type',
  'txn_date',
  'amount',
  'amount_paid',
  'descriptions',
  'quickbooks_doc_number',
  'quickbooks_account_id',
  'description',
  'quickbooks_created_at',
  'quickbooks_updated_at',
  'location_id',
  'created_at',
  'updated_at'
]

class QuickbooksTransactionsAdapter extends PGAdapter {
  constructor (pgConnection, username) {
    super(
      pgConnection,
      TRANSACTIONS_TABLE_NAME,
      username,
      TRANSACTIONS_COLUMNS,
      'id'
    )
  }

  getTransaction (filter, limit, offset) {
    return this.getList({
      filter,
      limit,
      offset
    })
  }
}

module.exports = QuickbooksTransactionsAdapter
