import React from 'react'
import classnames from 'classnames'
import { Bars } from '../../../icons'

const showMobileSidebar = () => {
  window.dispatchEvent(new CustomEvent('show-mobile-sidebar', { // eslint-disable-line
    detail: { show: true }
  }))
}

const PageHeaderSidebarTriggerNew = ({isFixed}) => {
  return (
    <button
      className={classnames(
        'vs-page-header-new__sidebar-trigger',
        {'vs-page-header-new__sidebar-trigger--fixed': isFixed}
      )}
      onClick={showMobileSidebar}>
      <Bars />
    </button>
  )
}

export default PageHeaderSidebarTriggerNew
