import React from 'react'
import PropTypes from 'prop-types'

import withPageContext from '../../withPageContext'
import Breadcrumb from '../../../Breadcrumb'

const PageHeaderBreadcrumb = ({
  items,
  linkRenderer
}) => (
  <div className='vs-page-header__breadcrumb'>
    <Breadcrumb items={items} linkRenderer={linkRenderer} />
  </div>
)

PageHeaderBreadcrumb.propTypes = {
  items: PropTypes.array,
  linkRenderer: PropTypes.func
}

PageHeaderBreadcrumb.displayName = 'Page.ComposableHeader.Breadcrumb'

export default withPageContext(PageHeaderBreadcrumb)
