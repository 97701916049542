const createBaseDoc = require('./create-base-doc')

module.exports = (options) => {
  const baseDoc = createBaseDoc({
    alertId: options.alertId,
    userId: options.userId,
    deviceId: options.deviceId,
    clock: options.clock,
    version: '1.0.0'
  })
  return Object.assign({}, baseDoc, {
    type: 'alert-comment',
    message: options.message
  })
}
