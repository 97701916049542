import React, { Fragment } from 'react'

import { Landmark, Products, Truck, UserPlus } from '@fielded/shared-ui/src/icons'

import { getLeadPath } from './get-lead-path'
import MenuItem from '../MenuItem/MenuItem'

const MainMenuLeadUserItems = ({
  isCollapsed,
  userWithDB
}) => {
  return (
    <Fragment>
      <MenuItem
        path={`/retailer/my-application`}
        compact={isCollapsed}
        title='Application'
        icon={<UserPlus />}
        isActiveSlug={'/retailer/my-application'}
      />

      <MenuItem
        path={(getLeadPath('products', userWithDB))}
        compact={isCollapsed}
        title='Products'
        icon={<Products />}
        isActiveSlug={(getLeadPath('products', userWithDB))}
      />

      <MenuItem
        path={(getLeadPath('finances', userWithDB))}
        compact={isCollapsed}
        title='Wallet'
        icon={<Landmark />}
        isActiveSlug={(getLeadPath('finances', userWithDB))}
      />

      <MenuItem
        path={(getLeadPath('service', userWithDB))}
        compact={isCollapsed}
        title='Service'
        icon={<Truck />}
        isActiveSlug={(getLeadPath('service', userWithDB))}
        showOnboardBadge
      />
    </Fragment>
  )
}

export default MainMenuLeadUserItems
