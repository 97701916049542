import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'
import { Checkmark, Plus } from '../../icons'

const sizes = ['small', 'large']

const SubscriptionToggleButton = ({
  caption,
  selected,
  size,
  onSwitch,
  className
}) => {
  return (
    <Button
      icon={selected ? <Checkmark /> : <Plus />}
      colorVariant='brand'
      fill='full'
      onClick={onSwitch}
      className={classnames(
        'vs-subscription-toggle__button',
        { 'vs-subscription-toggle__button--confirmed': selected },
        { 'vs-subscription-toggle__button--small': size === 'small' },
        className
      )}
    >
      {caption}
    </Button>
  )
}

SubscriptionToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  size: PropTypes.oneOf([...sizes, null]),
  caption: PropTypes.string,
  onSwitch: PropTypes.func,
  className: PropTypes.string
}

SubscriptionToggleButton.defaultProps = {
  caption: 'Subscribe',
  selected: false
}

export default SubscriptionToggleButton
