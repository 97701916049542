import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Card, DeemphasizedLink, IconBadge, Text } from '@fielded/shared-ui'
import { Arrow, PayAsYouSell, PayOnDelivery } from '@fielded/shared-ui/src/icons'
import { PAY_AS_YOU_SELL, PAY_ON_DELIVERY } from '@fielded/shared-ui/src/utils/subscription-type-constants'

import { formatDate, pluralize } from '../../../van-shared/utils/utils'
import { ServiceListCardStatus } from './shared/ServiceListCardStatus'

const ServiceListCardContent = ({
  title,
  icon,
  status,
  date,
  productsCount,
  withPAYSProducts,
  withODProducts,
  url,
  isProvisional,
  colorVariant
}) => {
  return (
    <Card.Content className='service-visit__card-content'>
      <section className='service-visit__card-content-header'>
        <div className='service-visit__card-content-icon-wrapper vs-u-align-center'>
          <IconBadge
            className='service-visit__card-content-icon'
            icon={icon}
            colorCode={colorVariant}
            withBorder={false}
            withDefaultIconStyle
          />
          <Text bold>{title}</Text>
        </div>

        <ServiceListCardStatus
          status={status}
          colorVariant={colorVariant}
        />
      </section>

      <section className='service-visit__card-content-date-wrapper'>
        {date ? (
          <Fragment>
            <Text.Heading className='service-visit__card-content-date' size='large'>
              {formatDate(date, 'shortWithFullMonth')}
            </Text.Heading>
          </Fragment>
        ) : (
          <Text>No details yet</Text>
        )}
      </section>

      <section className='service-visit__card-content-footer'>
        <div className='service-visit__card-content-footer-items'>
          <Text>{productsCount} {pluralize('product', productsCount)}</Text>
          {withPAYSProducts && (
            <IconBadge
              colorCode={PAY_AS_YOU_SELL}
              icon={<PayAsYouSell />}
              className='service-visit__card-content-footer-icon'
            />
          )}

          {withODProducts && (
            <IconBadge
              colorCode={PAY_ON_DELIVERY}
              icon={<PayOnDelivery />}
              className='service-visit__card-content-footer-icon'
            />
          )}
        </div>

        {url && (
          <div>
            <DeemphasizedLink
              bold
              component={Link}
              to={url}
              icon={<Arrow direction='right' />}
              iconSide='right'
              colorVariant='brand'
              className='service-visit__card-content-footer-link'
            >
              View details
            </DeemphasizedLink>
          </div>
        )}
      </section>
    </Card.Content>
  )
}

export default ServiceListCardContent
