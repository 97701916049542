import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withOffline from '@fielded/shared-ui/src/common/offline'
import withConfig from '../../../van-shared/hoc/withConfig'

import { hasFeature } from '../../../van-shared/utils/features'
import { isShelflifePowered, isPsm } from '../../../van-shared/utils/utils'

import DownloadLMDReportButton from '../common/DownloadLMDReportButton'
import DownloadStockSituationButton from '../common/DownloadStockSituationButton'
import { userHasPermission } from '../common/program.utils'

export class OverviewDownloadButton extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    program: PropTypes.object.isRequired,
    period: PropTypes.string.isRequired,
    isOnline: PropTypes.bool.isRequired,
    config: PropTypes.object.isRequired
  }

  render () {
    const {
      user,
      program,
      isOnline,
      period,
      config
    } = this.props

    if (isPsm(config) &&
      userHasPermission(user, program)) {
      return (
        <DownloadLMDReportButton
          user={user}
          program={program}
          period={period}
          disabled={!isOnline}
          title={!isOnline ? 'Export not available offline' : ''}
          label='Export LMD'
        />
      )
    }

    if (isShelflifePowered(config)) {
      return (
        <DownloadStockSituationButton
          user={user}
          program={program}
          period={period}
          adjustments={hasFeature(config, 'features.shipments.adjustments')}
        />
      )
    }

    return null
  }
}

export default withOffline(withConfig(OverviewDownloadButton))
