const proposalApi = require('../../../proposal')
const tools = require('../../tools')

exports.getWithProposal = function (state, { locationId, date, includeProgramsHistory }) {
  return proposalApi.get(state, { entityId: locationId, entityType: 'location', date })
    .then(proposalEntity => {
      const proposalDetails = proposalApi.tools.getProposalDetails(proposalEntity)
      const filter = tools.getUserFilter(state.user)

      // We want to only return a pending proposal location, the app does not care about showing
      // the details of a rejected or "approved" proposal locations (it will just show currentLocation)
      const proposedLocation = proposalEntity && proposalDetails.status === 'pending'
        ? tools.docToEntity({
          doc: proposalEntity.doc,
          date,
          filter,
          includeProgramsHistory
        })
        : null

      return state.dal.location.get(state, { locationId, date, filter, includeProgramsHistory })
        .then(currentLocation => {
          return { currentLocation, proposedLocation, proposalDetails }
        })
        .catch(error => {
          if (error.status === 404) {
            return { currentLocation: null, proposedLocation, proposalDetails }
          }

          throw error
        })
    })
}
