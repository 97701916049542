import React from 'react'
import { Link } from 'react-router-dom'

import { ActionsBar, Button, DeemphasizedLink, Page } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

import CrossLink from '../../../common/cross-link'

const Success = ({
  locationName,
  locationId,
  history,
  rootHistory
}) => (
  <Page>
    <Page.Panel
      status='success'
      spread
      withBackground
    >
      <Page.Panel.Header
        title={`Stock count submitted for ${locationName}`}
      />
      <Page.Panel.Section>
        Counting has been completed for this location.
        If you have a delivery, continue to stock up this location.
      </Page.Panel.Section>
      <Page.Panel.Section>
        <ActionsBar layout='space-between'>
          <Button
            component={CrossLink}
            to={`/shipments/deliveries/${locationId}`}
            history={rootHistory}
            icon={<Arrow direction='right' />}
            iconSide='right'
            fill='full'
            colorVariant='brand'
          >
            Continue with delivery
          </Button>
          <DeemphasizedLink
            component={Link}
            to='/'
            history={history}
          >
            Finish without delivery
          </DeemphasizedLink>
        </ActionsBar>
      </Page.Panel.Section>
    </Page.Panel>
  </Page>
)

export default Success
