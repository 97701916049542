module.exports = bulkSnapshotsFromSheet
const createBulkSnapshots = require('./shipment-bulk-create-snapshots')
const {createShipmentParams, constructSupplierTree, parseAllocationSheet} = require('./utils/make-bulk-snapshot-from-sheet')

function bulkSnapshotsFromSheet (state, buffer, { isCnR = false } = {}) {
  const rows = parseAllocationSheet(buffer, { isCnR })
  const rowsWithSuppliers = constructSupplierTree(rows)
  const params = createShipmentParams(rowsWithSuppliers, { isCnR })
  if (params.error) {
    return params
  }

  return createBulkSnapshots(state, params)
}
