const { mergeProducts } = require('./merge-products')

const { types } = require('../../config')

const normalize = a => {
  // `normalize` is used both for CouchDB docs and for
  // entity like allocations returned by the data adapter, so it
  // needs to work with `id` and `_id`.
  //
  // We can get rid of this when all functions have been ported
  // to use 'id'.
  const idKey = a.id ? 'id' : '_id'
  const normalized = {
    [idKey]: a[idKey],
    version: a.version,
    date: a.date,
    createdAt: a.createdAt,
    facilityId: a.facilityId,
    products: mergeProducts(a.products, a.aggregatedProducts),
    unsubscriptions: a.unsubscriptions
  }

  // Add the default type to allocations with no type assigned and default type available
  normalized.products = Object.keys(normalized.products).reduce((withType, productId) => {
    const product = normalized.products[productId]
    if (product.forecast) {
      const method = product.forecast.method
      if (!product.forecast.type && types[method] && types[method].default) {
        product.forecast.type = types[method].default
      }
    }
    withType[productId] = product
    return withType
  }, {})

  // v0.1.0 allocations that have been translated to the v1 version
  // keep the original (untranslated) _id in a `originalId` property
  if (a.originalId) {
    normalized.originalId = a.originalId
  }

  return normalized
}

module.exports = normalize
