const transformItem = ({
  qboCustomer,
  account,
  amount,
  classRef,
  description,
  quickbooksInvoice
}) => {
  const lineDescription = description || (quickbooksInvoice ? quickbooksInvoice.quickbooks_doc_number : '')
  const depositLineDetail = {
    Entity: {
      value: qboCustomer.Id,
      name: qboCustomer.FullyQualifiedName,
      type: 'CUSTOMER'
    },
    AccountRef: {
      value: account.Id,
      name: account.Name
    }
  }
  if (classRef) {
    Object.assign(depositLineDetail, {ClassRef: classRef})
  }
  return {
    Amount: amount,
    DetailType: 'DepositLineDetail',
    DepositLineDetail: depositLineDetail,
    Description: lineDescription // in case of payment plan we are not sending quickbooks_doc_number
  }
}

const base = ({
  qboCustomer,
  depositAccount,
  quickbooksInvoice,
  privateNote
}) => {
  return {
    DepositToAccountRef: {
      name: depositAccount.Name,
      value: depositAccount.Id
    },
    CurrencyRef: qboCustomer.CurrencyRef,
    ExchangeRate: 1,
    PrivateNote: privateNote || (quickbooksInvoice ? quickbooksInvoice.quickbooks_doc_number : '')
  }
}

const transformInvoiceToDeposit = ({
  transaction,
  quickbooksInvoice,
  qboCustomer,
  depositAccount,
  remitaFeesAccount,
  classRef,
  payment,
  remitaFee
}) => {
  return {
    ...base({
      transaction,
      qboCustomer,
      depositAccount,
      quickbooksInvoice
    }),
    Line: [
      {
        Amount: payment.TotalAmt,
        LinkedTxn: [
          {
            TxnLineId: '0',
            TxnId: payment.Id,
            TxnType: 'Payment'
          }
        ]
      },
      transformItem({
        qboCustomer,
        account: remitaFeesAccount,
        amount: remitaFee,
        classRef,
        quickbooksInvoice
      })
    ]
  }
}

const transformPaymentPlanToDeposit = ({
  transaction,
  qboCustomer,
  paymentPlan,
  paymentInstallment,
  depositAccount,
  loanReceivableAccount = {},
  remitaFeesAccount,
  paymentPlanFeesAccount = {},
  classRef,
  lrAccountFeeDeposits = [],
  instalmentsFullyPaid = 0
}) => {
  let serviceFee = paymentPlan.service_fee
  // We don't create extra service fee deposits that become more than the terms of the payment plan
  const serviceFeesPaid = lrAccountFeeDeposits.filter(f => f.accountId === paymentPlanFeesAccount && paymentPlanFeesAccount.Id).length
  const splitPaymentToFee = (instalmentsFullyPaid >= serviceFeesPaid) && (serviceFeesPaid < paymentPlan.term)

  const qboAccountBalance = loanReceivableAccount && loanReceivableAccount.CurrentBalance
  let amount = splitPaymentToFee ? (paymentInstallment.amount - serviceFee) : paymentInstallment.amount

  // if amount is 0 we need override amount from paymentinstallment amount.
  // otherwise it will create deposit with 0 amount
  if (!amount) {
    amount = paymentInstallment.amount
    serviceFee = 0
  }

  // payment plan liquidation (early repayment)
  if (paymentInstallment.amount >= qboAccountBalance && !!serviceFee) {
    amount = qboAccountBalance
    serviceFee = (paymentInstallment.amount - qboAccountBalance)

    // This is an edge case. This will only happen if the user
    // decides to liquidate before the first instalment is due
    // i.e. there will be no service fee to deduct
    if (paymentInstallment.amount === qboAccountBalance) {
      serviceFee = 0
    }
  }

  const lines = [
    transformItem({
      qboCustomer,
      classRef,
      amount,
      account: loanReceivableAccount,
      description: paymentInstallment.description
    })
  ]

  if (transaction) {
    lines.push(transformItem({
      qboCustomer,
      classRef,
      account: remitaFeesAccount,
      amount: (transaction.amount - paymentInstallment.amount)
    }))
  }
  if (serviceFee && splitPaymentToFee) {
    lines.push(transformItem({
      qboCustomer,
      account: paymentPlanFeesAccount,
      amount: serviceFee
    }))
  }
  return {
    ...base({
      qboCustomer,
      depositAccount,
      privateNote: paymentInstallment.description
    }),
    Line: lines
  }
}

exports.transformInvoiceToDeposit = transformInvoiceToDeposit
exports.transformPaymentPlanToDeposit = transformPaymentPlanToDeposit
exports.transformItem = transformItem
