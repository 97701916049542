import React from 'react'
import PropTypes from 'prop-types'

import { ExclamationCircle } from '../../icons'

import Wizard from './Wizard'

const WizardError = ({
  title,
  bodyMessage,
  error,
  actions
}) => {
  let errorMessage = error.toString()
  if (typeof error === 'object') {
    errorMessage = JSON.stringify(error.message || error.name, null, 2)
  }
  return (
    <Wizard.Step
      name='error'
      status='warning'
    >
      <Wizard.Step.Header
        title={title}
        icon={<ExclamationCircle />}
      />
      <Wizard.Step.Content>
        {bodyMessage && <p>{bodyMessage}</p>}
        {errorMessage && (
          <p>
            Error details:
            <code className='wizard-step__error'>
              <pre>
                {errorMessage}
              </pre>
            </code>
          </p>
        )}
      </Wizard.Step.Content>
      {actions && (
        <Wizard.Step.Actions>
          {actions}
        </Wizard.Step.Actions>
      )}
    </Wizard.Step>
  )
}

WizardError.displayName = 'Wizard.Error'

WizardError.propTypes = {
  /**
   * The title to display
   */
  title: PropTypes.string.isRequired,

  /**
   * An optional body message
   */
  bodyMessage: PropTypes.string,

  /**
   * Optional actions to display
   */
  actions: PropTypes.node,

  /**
   * The error to handle, could be an instance of Error or just an object
   */
  error: PropTypes.object.isRequired

}

WizardError.defaultProps = {
  bodyMessage: null,
  actions: null
}

export default WizardError
