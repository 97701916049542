import React from 'react'

import { QuantityDisplay } from '@fielded/shared-ui'

import { shipmentTitle } from '../../../../../common/utils'
import { RotatedTh } from '../../../../../components/ShipmentCRContentsTable/ShipmentCRContentsTable'

const ConfirmPickUpContentsTable = ({
  shipments
}) => {
  const colCount = 4

  return (
    <div className='confirm-pick-up-contents'>
      <table>
        <thead>
          <tr>
            <th colSpan={colCount}>Shipments</th>
            <RotatedTh className='confirm-pick-up-contents__products'>No. of products</RotatedTh>
          </tr>
        </thead>

        <tbody>
          {shipments.length ? shipments.map(shipment => {
            const headerTitle = shipmentTitle(shipment)
            const productCount = Object.keys(shipment.counts).length

            return (
              <tr key={shipment.shipmentNo}>
                <td colSpan={colCount} className='confirm-pick-up-contents__highlight'>{headerTitle}</td>
                <td className='confirm-pick-up-contents__products-count'><QuantityDisplay value={productCount} /></td>
              </tr>
            )
          }) : (
            <tr>
              <td colSpan={colCount}>
                There are no products in this delivery
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ConfirmPickUpContentsTable
