import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import TwoPartLabel from './TwoPartLabel'

// This is a special option renderer type needed by react-virtualized-select.
// Unlike the optionRenderer for standard react-select, this requires us to apply
// our "own" focus and selected states as well as click and enter handling,
// based on some standard props that react-virtualized-select passes on the
// custom optionRenderer. More info:
// https://github.com/bvaughn/react-virtualized-select/#optionrenderer-example
const SelectTwoPartOption = ({
  option,
  focusedOption,
  focusOption,
  key,
  selectValue,
  style,
  valueArray
}) => (
  <TwoPartLabel
    option={option}
    className={classnames(
      'vs-select-virtualized-option',
      {'vs-select-virtualized-option--is-focused': option === focusedOption},
      {'vs-select-virtualized-option--is-selected': valueArray.indexOf(option) >= 0}
    )}
    key={key}
    onClick={() => selectValue(option)}
    onMouseEnter={() => focusOption(option)}
    style={style}
  />
)

SelectTwoPartOption.propTypes = {
  /**
   * This is the select option that gets passed on from react-select
   */
  option: PropTypes.shape({
    label: PropTypes.array,
    value: PropTypes.string
  }).isRequired,

  // These are the props passed on by react-virtualized-select. See:
  // https://github.com/bvaughn/react-virtualized-select/#optionrenderer-example
  /**
   * The option currently-focused in the dropdown. Use this property to determine if your rendered option should be highlighted or styled differently.
   */
  focusedOption: PropTypes.object.isRequired,

  /**
   * Callback to update the focused option; for example, you may want to call this function on mouse-over.
   */
  focusOption: PropTypes.func.isRequired,

  /**
   * A unique identifier for each element created by the renderer.
   */
  key: PropTypes.string.isRequired,

  /**
   * Callback to update the selected values; for example, you may want to call this function on click.
   */
  selectValue: PropTypes.func.isRequired,

  /**
   * Styles that must be passed to the rendered option. These styles are specifying the position of each option (required for correct option displaying in the dropdown).
   */
  style: PropTypes.object.isRequired,

  /**
   * Array of the currently-selected options. Use this property to determine if your rendered option should be highlighted or styled differently.
   */
  valueArray: PropTypes.array.isRequired
}

export default SelectTwoPartOption
