import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import withOffline from '../../../common/offline'
import withPageContext from '../withPageContext'
import BackButton from '../../BackButton'
import Bullet from '../../Bullet'
import ConnectivityStatus from '../../ConnectivityStatus'
import PageHeaderSidebarTriggerNew from './PageHeaderSidebarTriggerNew'

const PageHeaderNew = ({
  title,
  subtitle,
  icon,
  showOfflineWarning,
  className,
  situation,
  isOnline,
  history,
  children
}) => {
  const situationNotNeutral = situation && situation !== 'neutral'
  const warnAboutOffline = showOfflineWarning && !isOnline
  return (
    <header
      className={classnames(
        'vs-page-header-new',
        {'vs-page-header-new--offline-warning': warnAboutOffline},
        {'vs-page-header-new--situation': situation},
        {'vs-page-header-new--situation-not-neutral': situationNotNeutral},
        className
      )}
    >
      <div className='vs-page-header-new__row'>
        <PageHeaderSidebarTriggerNew isFixed={children} />
        {title && (
          <Bullet
            icon={icon}
            component='h1'
            className={classnames(
              'vs-page-header-new__title',
              {'vs-page-header-new__title--standalone': !subtitle}
            )}
          >
            {title}
          </Bullet>
        )}
        {warnAboutOffline && (
          <div className='vs-page-header-new__offline'>
            <ConnectivityStatus isOnline={isOnline} onLight={!situationNotNeutral} />
          </div>
        )}
      </div>

      {subtitle && (
        <div className='vs-page-header-new__row'>
          {history && (
            <BackButton
              history={history}
              colorVariant='brand'
              fill='full'
              shape='square'
              size='small'
              className='vs-page-header-new__button'
            />
          )}
          <h2 className='vs-page-header-new__subtitle'>
            {subtitle}
          </h2>
        </div>
      )}
      {children}
    </header>
  )
}

PageHeaderNew.propTypes = {
  /*
   * A title to display, a parent page
   */
  title: PropTypes.string,
  /*
   * A subtitle to display if the page is a child of a main category eg deliveries ->  single delivery
   */
  subtitle: PropTypes.string,
  /**
   * A history object if the page is a child of a main category (fed to arrow back button)
   */
  history: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  /**
   * Optionally add your own elements to header instead
   */
  children: PropTypes.node,
  /**
   * Pass this to show a badge in the header when user is offline.
   */
  showOfflineWarning: PropTypes.bool,
  /**
   * The classname(s) to apply to the Page
   */
  className: PropTypes.string,
  /**
   * This will be passed down by the wrapping Page component,
   * so you don't have to set here again.
   */
  situation: PropTypes.oneOf(['ready', 'success', 'caution', 'danger', 'neutral', undefined]),
  /**
   * Is added by the withOffline hoc
   */
  isOnline: PropTypes.bool
}

PageHeaderNew.defaultProps = {
  title: undefined,
  subtitle: undefined,
  className: undefined,
  history: undefined
}

PageHeaderNew.displayName = 'Page.HeaderNew'

export default withPageContext(withOffline(PageHeaderNew))

// This is to be consumed by the storybook only, otherwise the props are not showing in the stories
export const PageHeaderNewNoHOC = PageHeaderNew
