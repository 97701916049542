const clone = v => JSON.parse(JSON.stringify(v))

const calculateTotalErrorsWith = (productsById) => (orders, distribution) => {
  const toGenericQuantities = (quantities) => {
    return Object.keys(quantities)
      .reduce((acc, productId) => {
        const { genericParent } = productsById[productId]
        acc[genericParent] = acc[genericParent] || 0
        acc[genericParent] += quantities[productId]
        return acc
      }, {})
  }
  return Object.keys(distribution)
    .map((orderId) => {
      // for each order in the proposed distribution map the order back to a
      // generic quantities and calculate the sums for the absolute errors
      // above target and below target across all generic products. This will
      // create a list of [belowTargetValue, aboveTargetValue] tuples
      const requestedCounts = toGenericQuantities(orders[orderId])
      const proposedCounts = toGenericQuantities(distribution[orderId])
      return Object.keys(requestedCounts)
        .reduce(([belowTarget, aboveTarget], genericId) => {
          const proposed = proposedCounts[genericId] || 0
          const requested = requestedCounts[genericId] || 0
          if (!requested) {
            return [belowTarget, aboveTarget]
          }
          // in case the requested product could not be packed at all the difference
          // needs to be the requested amount
          const difference = proposed - requested
          // the absolute error is the percentage at which the proposed packOut
          // deviates from the originally requested amount
          const absoluteError = Math.abs(difference) / requested
          if (difference >= 0) {
            return [belowTarget, aboveTarget + absoluteError]
          }
          return [belowTarget + absoluteError, aboveTarget]
        }, [0, 0])
    })
    .reduce(([belowErrTotal, aboveErrTotal, locationsBelowTotal], [belowTarget, aboveTarget]) => {
      // in order to be able to compare possible distributions we are interested in
      // 3 metrics:
      return [
        // the sum of absolute errors below target across orders
        belowErrTotal + belowTarget,
        // the sum of absolute errors above target across orders
        aboveErrTotal + aboveTarget,
        // the count of orders that would end up with lower quantities than requested
        belowTarget > 0 ? locationsBelowTotal + 1 : locationsBelowTotal
      ]
    }, [0, 0, 0])
}
exports.calculateTotalErrorsWith = calculateTotalErrorsWith

const tryTopUpWith = (productsById) => (productsToPack, orders, productLevelAllocation, reverseSorting = false) => {
  // augment the productLevelStock with the respective generic mapping
  // from the product catalog
  const available = Object.keys(productLevelAllocation).reduce((acc, productId) => {
    acc[productId] = Object.assign({
      amount: productLevelAllocation[productId]
    }, productsById[productId])
    return acc
  }, {})

  // this is the currently available stock that is available for distribution,
  // indexed on generic item ids.
  const stockByGenericId = Object.keys(available).reduce((acc, itemId) => {
    const product = available[itemId]
    const { genericParent } = product
    acc[genericParent] = acc[genericParent] || []
    acc[genericParent].push(Object.assign({}, available[itemId], {itemId}))
    acc[genericParent].sort((a, b) => {
      return a.genericFactor === b.genericFactor
        ? 0
        : a.genericFactor < b.genericFactor ? 1 : -1
    })
    return acc
  }, {})

  // the packOut is a map of {<orderId>: {<productId>: <quantity>, <productId>: <quantity>...}}
  // which will store the final proposed pack-out for each order
  const packOut = Object.keys(orders).reduce((acc, orderId) => {
    // not using object rest spread here is considerably faster for real world order sizes
    acc[orderId] = {}
    return acc
  }, {})
  // The counts in this object will be mutated as the pack outs are being generated
  // The available packsizes / products in this are supposed to be sorted in a way
  // that the first items should be delivered first.
  const stock = clone(stockByGenericId)
  // create a list of orders in the correct order. the amount values in this
  // will mutate while packing the available stock so it need to be a clone
  // of the passed data.
  const orderRecords = Object.keys(orders).map((orderId) => (
    {
      orderId,
      order: clone(orders[orderId])
    }
  ))

  for (const productToPack of productsToPack) {
    // pack sizes are sorted by biggest first, so this will
    // be a smaller pack size on each iteration.
    // for each of these pack sizes, we try to top up all orders until
    // the remaining amounts are too small, or there is no more stock available
    const genericParent = productsById[productToPack].genericParent
    for (const currentPackSize of (stock[genericParent] || [])) {
      // sorting the orders so that the one with the lowest (or highest) remainder against
      // the current pack size will be last optimizes for the different errors in
      // case the stock at hand is not sufficient to be distributed across
      // all orders anymore.
      // TODO: find out if this should consider all packsizes that are still left
      // instead of looking at the current one only
      orderRecords.sort((a, b) => {
        const aRemainder = a.order[productToPack] % currentPackSize.genericFactor
        const bRemainder = b.order[productToPack] % currentPackSize.genericFactor
        return aRemainder === bRemainder
          ? 0
          : aRemainder > bRemainder ? 1 : -1
      })
      if (reverseSorting) {
        orderRecords.reverse()
      }

      while (true) {
        let skipped = 0
        for (const {orderId, order} of orderRecords) {
          if (order[productToPack] >= currentPackSize.genericFactor && currentPackSize.amount >= currentPackSize.genericFactor) {
            // we can add a unit to the order's packout as the remaining amount
            // is bigger than the pack size and stock is still available. at the same
            // time available stock and the remaining quantity to order is adjusted.
            order[productToPack] -= currentPackSize.genericFactor
            currentPackSize.amount -= currentPackSize.genericFactor

            packOut[orderId][currentPackSize.itemId] = packOut[orderId][currentPackSize.itemId] || 0
            packOut[orderId][currentPackSize.itemId] += currentPackSize.genericFactor
          } else {
            skipped++
          }
        }
        if (skipped === Object.keys(orders).length) {
          // in case all orders have been skipped in the last loop, we cannot use
          // the current pack size to top up any of the orders anymore
          // and decide to proceed to the next smaller pack size if available
          break
        }
      }
    }
  }

  // at this point, all quantities in the pack out will be smaller or equal to
  // the requested amount so we try topping up using
  // the smallest available pack sizes
  for (const productToPack of productsToPack) {
    const genericParent = productsById[productToPack].genericParent
    if (!Array.isArray(stock[genericParent])) {
      continue
    }
    // we pick the smallest pack size that still has stock available
    const [smallestPackSizeWithStock] = stock[genericParent].slice().reverse().filter((s) => s.amount)
    if (!smallestPackSizeWithStock) {
      // there might be no stock available at all and we skip
      continue
    }
    for (const {orderId, order} of orderRecords) {
      if (order[productToPack] && smallestPackSizeWithStock.amount >= smallestPackSizeWithStock.genericFactor) {
        // the exact quantity has not yet been matched and there is stock
        // available so we can add a unit of the smallest pack size on top
        const {itemId, genericFactor} = smallestPackSizeWithStock
        packOut[orderId][itemId] = packOut[orderId][itemId] || 0
        packOut[orderId][itemId] += genericFactor
        order[productToPack] -= genericFactor
        smallestPackSizeWithStock.amount -= genericFactor
      }
    }
  }

  return packOut
}

const packWith = (productCatalog) => {
  const productsById = productCatalog.reduce((acc, product) => {
    acc[product._id] = Object.assign({}, product, {
      genericFactor: product.genericFactor || (product.unitOfIssue || 1) / (product.unitOfReporting || 1),
      genericCode: product.genericCode || product._id
    })
    return acc
  }, {})
  return (params, orders, productLevelAllocation) => {
    const { orderId, productId } = params || {}
    // in order to make the packing as high-performance as possible, passing a productId
    // or an orderId in the params will limit the packing to the relevant productId(s).
    //
    // passing `null` will pack all products in all orders
    let productsToPack
    if (productId) {
      productsToPack = new Set([productId])
    } else if (orderId) {
      productsToPack = new Set(Object.keys(orders[orderId]))
    } else {
      productsToPack = Object.keys(orders).reduce((acc, orderId) => {
        for (const productId in orders[orderId]) {
          acc.add(productId)
        }
        return acc
      }, new Set())
    }

    const calculateTotalErrors = calculateTotalErrorsWith(productsById)
    // the simple top-up algorithm is being run against all products that
    // are included in the order. The ordering that yields the lowest total error
    // will be picked.
    const tryTopUp = tryTopUpWith(productsById)
    const packOuts = [
      // we pack the same arrangement twice with sorting on lowest and highest remainder
      // against the packsize
      tryTopUp(productsToPack, orders, productLevelAllocation, false),
      tryTopUp(productsToPack, orders, productLevelAllocation, true)
    ]
      .map((packOut) => {
        const errors = calculateTotalErrors(orders, packOut)
        return {errors, packOut}
      })
      .sort((a, b) => {
        const [aErrBelowTotal, aErrAboveTotal, aLocationsBelowOrdered] = a.errors
        const [bErrBelowTotal, bErrAboveTotal, bLocationsBelowOrdered] = b.errors
        // the most important metric we sort on is the number of locations that would
        // end up with less stock than ordered. When two packings have the same number
        // of such locations, we sort on the total error sums. if the absolute total
        // of errors for two distributions is the same we pick the one that errs above
        // target instead of the one that errs below
        if (aLocationsBelowOrdered === bLocationsBelowOrdered) {
          const aTotal = aErrBelowTotal + aErrAboveTotal
          const bTotal = bErrBelowTotal + bErrAboveTotal
          if (aTotal === bTotal) {
            return aErrAboveTotal === bErrAboveTotal
              ? 0
              : aErrAboveTotal < bErrAboveTotal ? 1 : -1
          }
          return aTotal === bTotal
            ? 0
            : aTotal > bTotal ? 1 : -1
        }
        return aLocationsBelowOrdered === bLocationsBelowOrdered
          ? 0
          : aLocationsBelowOrdered > bLocationsBelowOrdered ? 1 : -1
      })
    // we are interested in the best packout only which the sorting placed on top
    const {errors, packOut} = packOuts[0]
    return {
      packOut,
      errors
    }
  }
}

exports.packWith = packWith
