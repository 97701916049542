const { docsToOrderEntity } = require('./docs-to-order-entity')

exports.docsToOrderEntities = function ({ docs, withSnapshots, status, useAcceptedStatus = false, useSubOrderId = false }) {
  let idToUse = 'orderId'

  if (useSubOrderId) {
    idToUse = 'suborderId'
  }
  const snapshotsById = docs
    .reduce((acc, snapshot) => {
      acc[snapshot[idToUse]] = acc[snapshot.orderId] || []
      acc[snapshot[idToUse]].push(snapshot)
      return acc
    }, {})

  return Object.keys(snapshotsById)
    .map(key => docsToOrderEntity({ docs: snapshotsById[key], withSnapshots, status, useAcceptedStatus }))
}
