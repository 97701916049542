const SoftDeletePGAdapter = require('../../common/soft-delete-pg-adapter.js')

class StockPGAdapter extends SoftDeletePGAdapter {
  constructor ({ pgConnection, username, logger }) {
    const columns = [
      'created_at',
      'updated_at',
      'transaction_id',
      'location_fsid_sku',
      'yearweek',
      'sku',
      'event_type',
      'event_id',
      'location_fsid',
      'date',
      'closing',
      'prev_opening',
      'sold',
      'delivered',
      'current_opening',
      'notes',
      'prev_sl_balance',
      'sl_balance',
      'sl_sold',
      'prev_partner_balance',
      'partner_balance',
      'partner_sold',
      'location_id',
      'driver',
      'delivery_notes',
      'service_id',
      'parent_event_date',
      'delivery_type',
      'data_source',
      'parent_event_invoice_merge'
    ]
    super(pgConnection, 'avocado.data_stocksummaryevent', username, columns, 'id', {}, logger)
  }

  async createStockSummaryEvents (locationId, yearweek, payloadRows) {
    this.logger.info(`upserting ${payloadRows.length} rows for location "${locationId}" and yearweek "${yearweek}"`)

    const rowsWithMissingSku = payloadRows.filter(row => !row.sku)
    if (rowsWithMissingSku.length > 0) {
      this.logger.warn(`found ${rowsWithMissingSku.length} rows with missing sku:`, rowsWithMissingSku)
      payloadRows = payloadRows.filter(row => row.sku)
    }

    // validate payload
    // look for rows with different location id
    const rowsWithDifferentLocationId = payloadRows.filter(row => row.location_id !== locationId)
    if (rowsWithDifferentLocationId.length > 0) {
      throw new Error(`found ${rowsWithDifferentLocationId.length} rows not matching the location id (${locationId}): ${rowsWithDifferentLocationId.map(row => row.location_id)}`)
    }
    // look for rows with different yearweek
    const rowsWithDifferentYearweek = payloadRows.filter(row => row.yearweek !== yearweek)
    if (rowsWithDifferentYearweek.length > 0) {
      throw new Error(`found ${rowsWithDifferentYearweek.length} rows not matching the yearweek (${yearweek}): ${rowsWithDifferentYearweek.map(row => row.yearweek)}`)
    }
    // look for duplicates in terms of location_id, sku and date
    const byConstraintCounts = payloadRows.reduce((memo, row) => {
      const constraintKey = `${row.location_id}:${row.sku}:${row.event_type}:${row.date}`
      memo[constraintKey] = memo[constraintKey] || 0
      memo[constraintKey] += 1
      return memo
    }, {})
    const duplicates = Object.keys(byConstraintCounts).filter(key => byConstraintCounts[key] > 1)
    if (duplicates.length > 0) {
      this.logger.warn(`found ${duplicates.length} duplicate rows for location ${locationId} on period ${yearweek}. Those will not be exported: ${duplicates.join(',')}:`, duplicates)
      payloadRows = payloadRows.filter(row => byConstraintCounts[`${row.location_id}:${row.sku}:${row.event_type}:${row.date}`] === 1)
    }

    // pgConnection can either be a client or a pool.
    // if it's a pool, use an own client to ensure all queries are being ran against the same client
    const useOwnClient = !('connection' in this.pgConnection)
    const client = useOwnClient ? await this.pgConnection.connect() : this.pgConnection

    try {
      await client.query('BEGIN')
      const upsertionResult = await this.upsert(payloadRows, { conflictIdColumns: ['location_id', 'sku', 'event_type', 'date'], client })
      const upsertedIds = upsertionResult.map(row => row[this.idColumn])
      const filter = {
        location_id: locationId,
        yearweek,
        excludeIds: upsertedIds
      }
      const preparers = {
        // Note we can't use `id NOT IN ($3)` here cause we need them casted as uuid
        excludeIds: (key, value) => [{
          statement: 'NOT (id = ANY(%INDEX%::UUID[]))',
          value
        }]
      }
      const deletionResult = await this.deleteWhere({ filter, preparers }, { client })
      await client.query('COMMIT')
      this.logger.info(`upserted ${upsertionResult.length} rows, deleted ${deletionResult.length} rows`)
    } catch (e) {
      await client.query('ROLLBACK')
      throw e
    } finally {
      // if we use our own client, relase it
      if (useOwnClient) {
        client.release()
      }
    }
  }
}

module.exports = StockPGAdapter
