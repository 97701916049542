const utils = require('./../../utils')
const { construct } = require('./../../tools')
const validateProduct = require('./../../../utils/validate-product')

const aggregateErrors = (product, options) => {
  try {
    validateProduct(product, options)
  } catch (e) {
    return `${product._id} - ${e.message}`
  }
}

// separating importProducts and importProductData so we can test excel file input
// & the more readable regular json
exports.importProducts = importProducts
async function importProducts (state, buffer, options = {}) {
  const {additionalColumns = {}} = options
  const data = utils.sheet.decode(buffer)
    .map(row => utils.sheet.sheetToDoc(row, additionalColumns))
  return importProductData(state, data, options)
}

exports.importProductData = importProductData
async function importProductData (state, data, options = {}) {
  const {dryRun = false} = options
  const sheetProducts = data
    .filter(utils.haveCode)
    .map(utils.fixProductIds)
    .map(utils.preparePrices)
    .map(utils.prepareAdditionalData)
    .map(construct)

  const validationErrors = sheetProducts.map(product => aggregateErrors(product, options))
    .filter(e => e)

  if (validationErrors.length) {
    throw new Error(validationErrors.join('\n'))
  }

  const opts = {
    date: new Date().toISOString(),
    username: state.user.name
  }

  const allProducts = await state.dal.product.getByIds(state, sheetProducts.map(({_id}) => _id))

  // get old/new products with updates applied
  const result = utils.organizeProducts(sheetProducts, allProducts, opts)
  const productsForUpdate = result.new.concat(result.update)

  const invalidProductErrors = productsForUpdate
    .map(utils.hasInvalidEdits)
    .filter(err => err)

  if (invalidProductErrors.length) {
    throw new Error(invalidProductErrors.join('\n'))
  }

  if (dryRun) return result

  await state.dal.product.saveMany(state, productsForUpdate)
  return result
}
