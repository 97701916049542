import {VAN_PROGRAM} from './program'

export const isVanProgram = program => VAN_PROGRAM === program.id || VAN_PROGRAM.includes(program.id)

export const userHasPermission = (user, program) => {
  const van = isVanProgram(program)

  if (van) {
    // currently, van user doc don't have a `programs` field
    // TODO add `programs` field to all van user docs which is an array
    // of programs the user have access to
    return true
  }
  // if it's a psm usser, it would have an array of programs the user have access to
  const {programs = []} = user
  if (programs.includes(program.id)) {
    return true
  }

  return false
}
