import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Card from '../Card'
import Text from '../Text'

const colorVariants = ['brand', 'accent', 'pink', 'brandGradient', 'accentGradient']

const AdCard = ({
  colorVariant,
  title,
  description,
  className,
  icon,
  children
}) => {
  return (
    <Card semitight rounded className={classnames(
      'vs-ad-card',
      {'vs-ad-card--brand': colorVariant === 'brand'},
      {'vs-ad-card--brand-gradient': colorVariant === 'brandGradient'},
      {'vs-ad-card--accent': colorVariant === 'accent'},
      {'vs-ad-card--accent-gradient': colorVariant === 'accentGradient'},
      {'vs-ad-card--pink': colorVariant === 'pink'},
      className
    )}>
      <div className='vs-ad-card__texture vs-u-texture-bg-triangle-white-opaque' />
      <Card.Content>
        <Text.Heading bold display size='medium' className='vs-ad-card__title'>
          {title}
        </Text.Heading>
        <p>
          {description}
        </p>
        {children}
      </Card.Content>
      {icon && (
        <div className={classnames(
          'vs-ad-card__icon',
          {'vs-ad-card__icon--alone': !description && !children}
        )}>
          {icon}
        </div>
      )}
    </Card>
  )
}

AdCard.propTypes = {
  colorVariant: PropTypes.oneOf([...colorVariants]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node
}

AdCard.defaultProps = {
  colorVariant: 'brand'
}

export default AdCard
