class EmailApiAdapter {
  constructor (restAdapter, notificationApi) {
    if (restAdapter) {
      this.restAdapter = restAdapter
    }
    if (notificationApi) {
      this.notificationApi = notificationApi
    }
  }

  sendCRNotificationEmail (data) {
    return this.restAdapter.create('email/send-cr-notification-email', data)
  }

  sendProvisionalDeliveryNotificationEmail (data) {
    return this.restAdapter.create('email/send-provisional-order-notification-email', data)
  }

  sendLateFeeReminderEmail (data) {
    return this.restAdapter.create('email/send-late-fee-email-reminder', data)
  }

  sendNewBonusReceivedEmail (data) {
    return this.restAdapter.create('email/send-new-bonus-received-email', data)
  }

  async sendPSMAdvancedShipmentNotificationEmail (data, fsid, shipmentSnapshotId) {
    const { count } = await this.notificationApi.getNotificationCount('email', shipmentSnapshotId)
    if (count >= 1) {
      console.log({ code: 'Email notification already sent, skipping.', triggerEvent: shipmentSnapshotId })
      return
    }

    await this.restAdapter.create('email/send-psm-advanced-shipment-notification-email', data)
    return this.notificationApi.create({
      locationId: fsid,
      notificationText: data.subject,
      action: 'email-notification',
      code: data.label,
      medium: 'email',
      event: shipmentSnapshotId,
      notificationData: data
    }, 'psm')
  }
}

module.exports = EmailApiAdapter
