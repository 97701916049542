const { smartId } = require('../../tools')
const { getUnknownBatchID } = require('./virtual-batch')

const linesToCounts = (lines) => {
  const counts = {}
  lines.forEach(line => {
    const { sku, amount } = line
    const batchId = getUnknownBatchID(`product:${sku}`)
    counts[batchId] = {
      quantity: amount
    }
  })
  return counts
}

const fromRelationalModel = (data) => {
  const { shipment, lines = data.shipment && data.shipment.lines } = data

  const { shipmentNo } = smartId.parse(shipment.shipment_id)

  const snapshotId = `${shipment.shipment_id}:status:${shipment.status}:`

  return {
    shipmentNo,
    id: shipment.shipment_id,
    snapshotId,
    status: shipment.status,
    planningType: shipment.planning_type,
    origin: { id: shipment.origin_id },
    destination: { id: shipment.destination_id },
    orderId: shipment.order_id,
    createdAt: shipment.created_at,
    createdBy: { user: shipment.created_by },
    updatedAt: shipment.updated_at,
    updatedBy: { user: shipment.updated_by },
    comments: shipment.notes ? shipment.notes.split(';').map(comment => {
      const [createdAt, createdBy, commentText] = comment.split(', ')
      return { createdAt, createdBy: { user: createdBy }, comment: commentText }
    }) : [],
    counts: lines ? linesToCounts(lines) : {},
    history: {},
    funder: { id: shipment.funder_id },
    route: { id: shipment.route_id },
    programId: shipment.program_id
  }
}

module.exports = { fromRelationalModel }
