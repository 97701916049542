const canCallEndpoint = (timestampForTheNextApiCall) => {
  const newTimestamp = new Date().getTime()
  return newTimestamp > timestampForTheNextApiCall
}

const getTimestampForNextApiCall = (minutesToAdd) => {
  const nextApiCallTimestamp = new Date().getTime() + (1000 * 60 * minutesToAdd)
  return nextApiCallTimestamp
}

export {
  canCallEndpoint,
  getTimestampForNextApiCall
}
