const _get = require('lodash/get')
const merge = require('lodash/merge')
const pick = require('lodash/pick')
const isEqual = require('lodash/isEqual')
const territoryLookup = require('../../service/tools/territory-lookup')
const countries = territoryLookup.getCountries()

module.exports = (location, qboPrevData) => {
  let country, userName, email, phone, data
  if (location._id) {
    // couch location
    country = countries.find(c => location.location.id.includes(c.geoId))
    userName = (_get(location.contacts, 'program:shelflife.name') || '').split(' ').filter(v => v)
    email = _get(location.contacts, 'program:shelflife.email')
    phone = _get(location.contacts, 'program:shelflife.phone')
    data = {
      DisplayName: location.fullName,
      CompanyName: location.fullName,
      BillAddr: {
        City: location.additionalData.city,
        Line1: location.additionalData.physicalAddress,
        Country: country ? country.name : ''
      }
    }
  } else {
    // postgres location
    country = countries.find(c => location.fsid.startsWith(c.geoId))
    userName = (location.owners_name || '').split(' ').filter(v => v)
    email = location.email
    phone = location.owners_phone_number
    data = {
      DisplayName: location.name_of_pharmacy,
      CompanyName: location.name_of_pharmacy,
      BillAddr: {
        City: location.city,
        Line1: location.address,
        Country: country ? country.name : ''
      }
    }
  }

  if (userName.length) {
    Object.assign(data, {
      FamilyName: userName.slice(1).join(' '),
      GivenName: userName[0]
    })
  }
  if (email) {
    Object.assign(data, {
      PrimaryEmailAddr: {
        Address: email
      }
    })
  }
  if (phone) {
    Object.assign(data, {
      PrimaryPhone: {
        FreeFormNumber: phone
      }
    })
  }

  // if there is no prev data then sending transformed
  if (!qboPrevData) {
    return data
  }

  const comparableData = pick(qboPrevData, Object.keys(data))
  const equal = isEqual(comparableData, data)
  // if comparable data is equal there is nothing to return for update
  if (equal) {
    return null
  }

  const merged = merge(qboPrevData, data)
  delete merged.MetaData // it's readonly from qbo side
  return merged
}
