// This is the ported method used by the external NHLMIS REST API
const dateFns = require('date-fns')
const { warehouseCodeToVirtualId, translateAliases } = require('../../order/tools')
const getPeriodFromProgram = require('../../report/tools/get-period-from-program')
const { validNhlmisRestApiSubmit } = require('../../validate/validators')
const shouldTrackBatches = require('../../tools/should-track-batches')

const STOCK_COUNT_DOC_TYPE = 'stock-count'

const canSubmit = (userLocation, locationId) => {
  return userLocation === locationId || // 1. can submit for your own location
    userLocation === 'national' || // 2. national can submit for everything
    locationId.indexOf(userLocation) === 0 // 3. user location is included in location id
}

const pluckBatchIds = (stockCounts) => {
  return Object.keys(stockCounts)
    .reduce((batchIds, productId) => {
      const productBatches = Object.keys(stockCounts[productId].batches || {})
      return batchIds.concat(productBatches)
    }, [])
}

const inputToFields = (item) => {
  return {
    fields: {
      'field:standard-physical-count': {
        amount: item.amount
      }
    }
  }
}

const convertFields = (stock, alias) => {
  const items = Object.keys(stock)
  const converted = {}

  items.forEach(item => {
    if (stock[item].batches) {
      converted[item] = {
        batches: convertFields(stock[item].batches)
      }
    } else {
      converted[item] = inputToFields(stock[item])
    }
  })

  return converted
}

/*
 * Check that all the products
 * belong to the submitted service
 * and units and names match
 */
const validateProducts = (stock, availableProducts, alias) => {
  const availableProductsById = availableProducts.reduce((acc, product) => {
    if (alias !== 'none') {
      if (product.alias && Array.isArray(product.alias[alias])) {
        for (const itemId of product.alias[alias]) {
          acc.add(`product:${itemId}`)
        }
      }
    } else {
      acc.add(product._id)
    }
    return acc
  }, new Set())

  const invalidProducts = Object.keys(stock)
    .filter(submittedId => {
      return !availableProductsById.has(submittedId)
    })

  return invalidProducts.length
    ? invalidProducts
    : null
}

const batchesNeeded = ({location, stock}, products) => {
  const needsBatches = []
  Object.keys(stock).forEach(productId => {
    const product = products.find(prod => prod._id === productId)
    const shouldTrack = shouldTrackBatches({location: { id: location }, product})

    if (shouldTrack && !stock[productId].batches) {
      needsBatches.push(productId)
    }
  })

  if (needsBatches.length === 0) {
    return false
  }

  return needsBatches
}

const badRequestError = (message) => {
  const err = new Error(message)
  err.status = 400
  return err
}

const toResponseShape = (submittedStock, stockCount, {alias, location, funderId}) => {
  return {
    id: stockCount._id,
    // In case the submitted stock was aliased, it will simply be echoed
    // back instead of converting the submission back into itself
    stock: alias !== 'none' ? submittedStock.stock : stockCount.stock,
    date: dateFns.format(new Date(stockCount.updatedAt), 'YYYY-MM-DD'),
    funderId,
    version: stockCount.version,
    serviceId: stockCount.serviceId,
    warehouseCode: location && location.additionalData && location.additionalData.warehouseCode
  }
}

const createStockCountWith = (user, api) => async (submittedStock, {alias, mergeBatches, useWarehouseCodes}) => {
  let locationId
  const {warehouseCode, funderId, programId} = submittedStock
  try {
    locationId = useWarehouseCodes
      ? warehouseCodeToVirtualId({warehouseCode, funderId, programId})
      : submittedStock.locationId
  } catch (err) {
    err.status = 400
    throw err
  }
  const location = await api.location.get(locationId)
  if (!location) {
    const err = new Error(
      `Invalid location "${useWarehouseCodes ? submittedStock.warehouseCode : submittedStock.locationId}"`
    )
    err.status = 400
    return Promise.reject(err)
  }

  if (!canSubmit(user.location.id, location._id)) {
    const err = new Error(
      `You are not allowed to submit for "${useWarehouseCodes ? submittedStock.warehouseCode : submittedStock.locationId}"`
    )
    err.status = 403
    return Promise.reject(err)
  }

  submittedStock.location = location._id
  delete submittedStock.warehouseCode
  const [products, batchesNotInDB] = await Promise.all([
    // This is used to check whether the submitted products actually
    // belong to the specified program / service
    api.configuration
      .getByIds(
        [`configuration:${submittedStock.service}`],
        {includeProducts: true}
      )
      .then(configs => {
        if (configs.length === 0) {
          const err = new Error(
            `Configuration "configuration:${submittedStock.service}" not found. This probably means the given "programId" does not exist.`
          )
          err.status = 400
          return Promise.reject(err)
        }
        return configs[0].products
      }),
    // This checks if all submitted batches already exist in the database
    api.batch.missing(
      pluckBatchIds(submittedStock.stock),
      alias !== 'none' ? {resolveAlias: {[alias]: true}} : {}
    )
  ])
  if (batchesNotInDB) {
    return Promise.reject(badRequestError(
      `Invalid batches: ${batchesNotInDB.join(', ')} need to be added via the batches API beforehand`
    ))
  }

  const shouldSubmitBatches = batchesNeeded(submittedStock, products)
  if (shouldSubmitBatches) {
    return Promise.reject(badRequestError(
      `You need to report batches for these products before submitting: "${shouldSubmitBatches.join(', ')}"`
    ))
  }

  const misplacedProducts = validateProducts(submittedStock.stock, products, alias)
  if (misplacedProducts) {
    return Promise.reject(badRequestError(
      `Invalid products: "${misplacedProducts.join(', ')}" for "${submittedStock.service}"`
    ))
  }

  const service = await api.service.get(submittedStock.service)
  const period = getPeriodFromProgram(service.program, new Date(), true)
  const stock = translateAliases(submittedStock.stock, alias, products)
  const formattedStock = convertFields(stock, alias)

  let submittedAt
  if (!mergeBatches) {
    submittedAt = new Date()
  }

  const stockCount = await api.report.save({
    locationId: location._id,
    service,
    stock: formattedStock,
    period,
    submittedAt,
    mergeCommits: true,
    mergeBatches
  })
  return toResponseShape(
    submittedStock,
    stockCount,
    {alias, location, funderId: submittedStock.funderId}
  )
}

module.exports = nhlmisRestSubmit
async function nhlmisRestSubmit (stockCounts, {alias = 'one', mergeBatches = false, useWarehouseCodes = true}) {
  if (!Array.isArray(stockCounts)) {
    const err = new Error('No `stockCounts` key found on payload or `stockCounts` is not an array')
    err.status = 400
    throw err
  }

  for (const stockCount of stockCounts) {
    // API Users will only ever submit data for warehouse services
    Object.assign(stockCount, {
      type: STOCK_COUNT_DOC_TYPE,
      service: `${stockCount.programId}:service:warehouse`
    })
  }

  const validationErrors = stockCounts
    .map(validNhlmisRestApiSubmit)
    .filter(v => v)

  if (validationErrors.some(list => list.length)) {
    const err = new Error('Validating the payload against the given schema failed.')
    err.status = 400
    Object.assign(err, {errors: validationErrors})
    throw err
  }

  const createStockCount = await createStockCountWith(this.mainApi.state.user, this.mainApi)
  const stockCountInserts = stockCounts.map((stockCount) => {
    return createStockCount(stockCount, {alias, mergeBatches, useWarehouseCodes})
  })
  return Promise.all(stockCountInserts)
}
