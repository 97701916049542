import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ConnectivityStatus from '../ConnectivityStatus'
import { Checkmark, ExclamationCircle, Spinner } from '../../icons'

const SyncProgress = ({
  isOnline,
  progress,
  isUpToDate,
  compact
}) => (
  <div
    className={classnames(
      'sync-progress',
      {'sync-progress--offline-not-safe': !isOnline && !isUpToDate},
      {'sync-progress--compact': compact}
    )}
  >
    <div className='sync-progress__top'>
      <div className='sync-progress__connection'>
        <ConnectivityStatus isOnline={isOnline} compact={compact} />
      </div>
      <div className='sync-progress__right'>
        {isUpToDate
          ? (
            <span className='sync-progress__updates'>
              <span className='sync-progress__updates-icon'>
                <Checkmark />
              </span>
              <span className='sync-progress__updates-label'>Up to date</span>
            </span>
          )
          : isOnline
            ? (
              <span className='sync-progress__updates sync-progress__updates--in-progress'>
                <span className='sync-progress__updates-icon'>
                  <Spinner />
                </span>
                <span className='sync-progress__updates-label'>Updating...</span>
              </span>
            )
            : (
              <span className='sync-progress__updates sync-progress__updates--not-sent'>
                <span className='sync-progress__updates-icon'>
                  <ExclamationCircle />
                </span>
                <span className='sync-progress__updates-label'> Updates not sent</span>
              </span>
            )
        }
      </div>
    </div>
    {(!isUpToDate && isOnline) && (
      <div className='sync-progress__progressbar-container'>
        <div className='sync-progress__progressbar' style={{ transform: `scaleX(${progress})` }} />
      </div>
    )}
  </div>
)

SyncProgress.propTypes = {
  /**
  * are we online
  */
  isOnline: PropTypes.bool.isRequired,
  /**
  * do we have updates to sync
  */
  isUpToDate: PropTypes.bool.isRequired,
  /**
  * syncing progress, decimal between 0 - 1 (default: 0)
  */
  progress: PropTypes.number,
  /**
  * If the compact version should be shown
  */
  compact: PropTypes.bool
}

SyncProgress.defaultProps = {
  progress: 0
}

export default SyncProgress
