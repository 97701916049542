module.exports = {
  id: 'https://schema.field.partners/1.0/van-status#',
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Status',
  description: 'A status model used in VAN snapshots',
  properties: {
    status: {
      type: 'string',
      'enum': [
        'pre-advice',
        'new',
        'packed',
        'sent',
        'arrived',
        'received',
        'cancelled'
      ]
    }
  },
  required: [
    'status'
  ]
}
