import React from 'react'

import WarningNotice from '../WarningNotice/WarningNotice'

const MembershipBanner = ({
  signupIsComplete,
  location
}) => {
  const applicationLink = '/retailer/my-application'
  const linkVisible = location.pathname !== applicationLink && !signupIsComplete

  let copy = 'Membership not active'

  if (signupIsComplete) {
    copy = 'Membership application is under review'
  }

  return (
    <WarningNotice
      type='attention'
      text={copy}
      link={linkVisible && applicationLink}
      linkCopy='Complete application'
    />
  )
}

export default MembershipBanner
