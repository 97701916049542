
module.exports = ({
  payment,
  quickbooksInvoice,
  totalAmount
}) => {
  return {
    ...payment,
    Line: [{
      Amount: totalAmount,
      LinkedTxn: [{
        TxnId: quickbooksInvoice.Id || quickbooksInvoice.quickbooks_id,
        TxnType: 'Invoice'
      }]
    }]
  }
}
