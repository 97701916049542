import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Button, Modal, Text } from '@fielded/shared-ui'
import { Ban } from '@fielded/shared-ui/src/icons'

import { selectIsPaused } from '../../subapps/retailer/finances/reducers/financesSlice'
import { OverdueInfo } from '../../subapps/retailer/finances/shared/OverdueInvoicesWarning'

const AccountBlockedModal = ({ showAccountBlockedModal, onOpenModal, onCloseModal }) => {
  const isPaused = useSelector(selectIsPaused)

  useEffect(() => {
    if (isPaused) {
      onOpenModal()
    }
  }, [])

  return (
    <Modal
      centered={false}
      isOpen={showAccountBlockedModal}
      title='Account restricted'
      className='account-blocked__modal'
      onClose={onCloseModal}
    >
      <div className='account-blocked__modal-icon-container'>
        <Text className='account-blocked__modal-icon'>
          <Ban />
        </Text>
        <Text className='vs-u-margin-bottom-double'>
          Your account has been restricted to limited payment options.
        </Text>
      </div>

      <div className='account-blocked__modal-info-reason'>
        <Text size='large' bold>Reason</Text>
        <OverdueInfo isPaused={isPaused} />
      </div>

      <div>
        <Text size='large' bold>What now?</Text>
        <Text className='vs-u-margin-bottom'>
          To resume using your credit and more payment options, you must <Text tag='span' bold>clear your overdue payment.</Text>
        </Text>
        <Text className='vs-u-margin-bottom-double'>
          Please pay immediately, or contact your customer success representative for questions or advice.
        </Text>

        <Button
          component={Link}
          to='/retailer/finances'
          fill='full'
          colorVariant='brand'
          className='vs-u-margin-bottom-double'
          onClick={onCloseModal}
        >
          See overdue payment(s)
        </Button>
      </div>
    </Modal>
  )
}

export default AccountBlockedModal
