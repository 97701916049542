const smartId = require('./../../tools/smart-id')

const getBatch = require('./batch-get')

module.exports = missingBatches

function parseBatchId (batchId = '') {
  const {product, manufacturer, batchNo} = smartId.parse(batchId)
  return {
    manufacturer,
    productId: `product:${product}`,
    batchNo
  }
}

function missingBatches (state, batchIds, {resolveAlias = null} = {}) {
  if (!Array.isArray(batchIds)) {
    return Promise.reject(new Error('Pass in an array of the IDs wanted'))
  }

  if (batchIds.length === 0) {
    return Promise.resolve(null)
  }

  let lookups
  if (resolveAlias) {
    lookups = Promise.all(batchIds.map((batchId) => {
      const params = parseBatchId(batchId)
      return getBatch(state, params, {resolveAlias})
        .then((batch) => {
          return null
        })
        .catch((err) => {
          if (err.status === 404) {
            return [batchId]
          }
          throw err
        })
    })).then((results) => {
      return results.reduce((acc, next) => {
        return [...acc, ...(next || [])]
      }, [])
    })
  } else {
    lookups = state.masterDataDB
      .allDocs({
        keys: batchIds,
        include_docs: false
      })
      .then(res => {
        return res.rows
          .filter(row => row.error === 'not_found')
          .map(row => row.key)
      })
  }

  return lookups.then((missing) => {
    return missing.length
      ? missing
      : null
  })
}
