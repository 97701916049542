const {create, update} = require('./save')
const {preparePharmacyUser} = require('../../tools')
const {Roles} = require('../../tools/user-db-sync')

exports.savePharmacyUser = savePharmacyUser
function savePharmacyUser (state, user) {
  const defaultRole = Roles.UserDBSyncUser
  const userToSave = preparePharmacyUser(user, defaultRole)
  if (userToSave._id) {
    return update(state, userToSave, {defaultRole})
  }
  return create(state, userToSave, {defaultRole})
}
