const tools = require('../../tools')
const { getGroup } = require('../read/get')
const { listOrdersForUser } = require('../read/internal')
const { createWarehouseSuborders } = require('./create-warehouse-suborders')
const { acceptOrders } = require('./accept-orders')

exports.advanceStatus = async function (
  state, {groupId, programId, dryRun = false, date = new Date().toJSON()}
) {
  tools.validateParams('advanceStatus', arguments[1])
  // Using user status instead of group status so a orders:requester user never bumps
  // a `warehouse` status to `accepted` status
  const userStatus = tools.getUserStatus(state.user)
  const nextStatus = tools.getNextStatus(userStatus)

  const withSnapshots = true

  // TODO: If duplicate destinations ticket here:
  // https://github.com/fielded/van-orga/issues/2695
  const {orders, status: groupStatus} = groupId
    ? await getGroup(state, {groupId, withSnapshots})
    // always filter out accepted orders, they are not advanced through the UI
    : await listOrdersForUser(state, programId, state.user.location.id, {withSnapshots, withAccepted: false})

  if (groupId && userStatus !== groupStatus) {
    throw new Error(`user cannot advance group ${groupId},
      user status is ${userStatus} while group status is ${groupStatus}`)
  }

  let updatedSnapshots = tools.advanceStatusSnapshots({
    orders,
    currentStatus: userStatus
  })

  if (!dryRun) await state.dal.order.bulkDocs(state, updatedSnapshots)

  if (nextStatus === 'warehouse') {
    return createWarehouseSuborders(state, {groupId, dryRun, date})
  }

  if (nextStatus === 'accepted') {
    return acceptOrders(state, {snapshots: updatedSnapshots, programId, date, dryRun})
  }

  const updatedOrders = tools.ordersFromSnapshotUpdates({
    updatedSnapshots, orders, status: userStatus, locationId: state.user.location.id
  })
  return {orders: updatedOrders}
}
