import React from 'react'

const CurrentEnvironmentBanner = ({environment}) => {
  // we want to show PR preview number for environment
  // that matches this pattern
  const regex = /^pr-\d+/
  const host = window.location.host
  if (regex.test(host)) {
    const previewNumber = host.split('.')[0].replace('pr-', '')
    environment = `preview-${previewNumber}`
  }
  return (
    <div className='current-env-banner'>
      <p className='current-env-banner__text'>
        {environment} environment
      </p>
    </div>
  )
}

export default CurrentEnvironmentBanner
