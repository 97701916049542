const newInvoicesForSmsQuery = `
   SELECT t.due_date, l.owners_phone_number, t.amount, t.amount_paid, l.fsid, l.uuid as location_id, t.id as txn_uuid
    FROM avocado.data_quickbookstransactions t
             INNER JOIN avocado.data_location l ON t.location_id = l.uuid
             LEFT JOIN avocado.data_quickbooksrawtransactions qrt ON qrt.txn_id = t.txn_id  AND qrt.company_code = t.company_code
        WHERE
        t.txn_type = 'invoice'
        -- created in last week
        AND t.txn_date > now() - interval '7 day'
        -- not fully paid
        AND t.amount_paid < t.amount
        -- Only if email has also been sent
        AND qrt.raw_data->>'EmailStatus' = 'EmailSent'
        -- with hard limit being day this change goes live (remember to update this)
        AND t.txn_date >= $1
        -- Where SMS hasn't yet been sent
        AND NOT EXISTS (
            SELECT
            FROM   avocado.data_invoicesms isms
            WHERE  isms.txn_id = t.id
        );
`
module.exports = {
  newInvoicesForSmsQuery
}
