const INDICATOR_CONFIG = {
  'negative-consumption': {
    message: 'Consumption is negative'
  },
  'explain-adjustment-loss': {
    message: 'Adjustment/loss was entered without a remark'
  },
  'physical-stock-count-mismatch': {
    message: 'Physical stock differs from expected'
  },
  'negative-batch': {
    message: 'Negative values in stock count'
  },
  'consumption-spike': {
    message: 'Current consumption has a deviation of +/- 30% compared to previous records'
  },
  'tb-10-patient-rule': {
    message: 'Consumption does not match the number of patients'
  },
  'hiv-consumption-rule': {
    message: 'Consumption does not match the number of patients'
  }
}

export default INDICATOR_CONFIG
