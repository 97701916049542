const { getDocs } = require('../../../dal-adapters/utils')
const { eventToDocId } = require('../../tools')

exports.get = async (state, { facilityId, userId, documentId }) => {
  const prefix = eventToDocId({ facilityId, userId, documentId }).split(':date:')[0]
  const response = await state.documentsDB.allDocs({
    startkey: `${prefix}:\ufff0`,
    endkey: `${prefix}:`,
    descending: true,
    limit: 1,
    include_docs: true
  })
  const docs = getDocs(response)
  return docs[0]
}
