import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Button, Pagination, SubNavigation } from '@fielded/shared-ui'
import { Download } from '@fielded/shared-ui/src/icons'

import { getSubViewLinks } from './utils'
import { pluralize } from '../../../../../../van-shared/utils/utils'
import { VIEWS } from './LMDList'

const LMDListControls = ({
  withViewChangeControls,
  currentOffset,
  onPageChange,
  totalShipmentsNumber,
  pageLimit,
  className,
  view = 'list',
  history,
  exportDeliveries
}) => {
  const subViewLinks = getSubViewLinks(view, history, withViewChangeControls)
  const isListView = view === VIEWS.LIST

  return (
    <aside className={classNames('vs-u-row-spaced', className)}>
      <div>
        {withViewChangeControls && (
          <SubNavigation
            className='last-mile-deliveries__sub-nav vs-u-align-end'
            label=''
            items={subViewLinks}
            linkRenderer={({ key, to, className, children }) => (
              <Link
                key={key}
                to={to}
                className={className}
              >
                {children}
              </Link>
            )}
          />
        )}
      </div>
      <div className='vs-u-row vs-u-gap vs-u-align-center'>
        {isListView && (
          <Fragment>
            <div>
              {totalShipmentsNumber} {pluralize('delivery', totalShipmentsNumber)}
            </div>
            <Pagination
              onChange={onPageChange}
              currentOffset={currentOffset}
              totalItems={totalShipmentsNumber}
              pageLimit={pageLimit}
            />
          </Fragment>
        )}
        <Button
          colorVariant='brand'
          fill='outline'
          onClick={() => exportDeliveries()}
          icon={<Download />}
          iconSide='left'
          disabled={!totalShipmentsNumber}
        >
          Export deliveries
        </Button>
      </div>
    </aside>
  )
}

export default LMDListControls
