import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from '../Button'

const ViewSwitcher = ({
  activeOption,
  options,
  size,
  onOptionClick,
  mode
}) => {
  if (mode === 'halved') {
    return (
      <div className='view-switcher--halved'>
        <div className='view-switcher__option'>
          <button
            className={classnames(
              'view-switcher-button-halved',
              'view-switcher-button-halved--left',
              { 'view-switcher-button-halved--left-active': options[0].viewKey === activeOption }
            )}
            onClick={() => onOptionClick(options[0].viewKey)}
          >
            {options[0].label}
          </button>
          <button
            className={classnames(
              'view-switcher-button-halved',
              'view-switcher-button-halved--right',
              { 'view-switcher-button-halved--right-active': options[1].viewKey === activeOption }
            )}
            onClick={() => onOptionClick(options[1].viewKey)}
          >
            {options[1].label}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div
      className={classnames(
        'view-switcher',
        { 'view-switcher--small': size === 'small' }
      )}
    >
      {options.map(option => {
        const {
          viewKey,
          label,
          icon
        } = option
        const isActive = viewKey === activeOption
        return (
          <div className='view-switcher__option' key={viewKey}>
            <Button
              icon={icon}
              shape='square'
              fill={isActive ? 'full' : 'outline'}
              colorVariant='brand'
              className={classnames(
                'view-switcher-button',
                { 'view-switcher-button--active': isActive }
              )}
              selected={isActive}
              onClick={() => onOptionClick(viewKey)}
              size={size}
            >
              {label}
            </Button>
          </div>
        )
      })}

    </div>
  )
}

ViewSwitcher.propTypes = {
  /**
   * Option that should be highlighted
   */
  activeOption: PropTypes.string,
  /**
   * Array of objects with options to display
   *  [
   *   {
   *    label: 'option 1',
   *     icon: <Icon />,
   *     viewKey: '1'
   *   },
   *   {
   *     label: 'option 2',
   *     icon: <Icon />,
   *     viewKey: '2'
   *   }
   * ]
   */
  options: PropTypes.array,
  /**
   * If the boxes should be smaller or not
   */
  size: PropTypes.oneOf(['small', null]),
  /**
   * Callback function taking a key of clicked option
   */
  onOptionClick: PropTypes.func,
  /**
   * Halved option spans to the width of the box and has two options. Default is just button-like boxes
   */
  mode: PropTypes.oneOf(['halved', 'default'])
}

export default ViewSwitcher
