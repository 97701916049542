exports.getDocsFromKeySearch = function (dbResponse) {
  return dbResponse.rows
    .map(row => row.doc)
    .filter(x => x)
}

exports.getDocs = function (dbResponse) {
  return dbResponse.rows
    .map(row => row.doc)
}
