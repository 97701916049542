import React, { useState, useEffect } from 'react'
import flowRight from 'lodash/flowRight'
import { useHistory } from 'react-router-dom'

import { Loading } from '@fielded/shared-ui'

import withConfig from '../../../../../../van-shared/hoc/withConfig'
import { withApi } from '../../../../../../common/ApiProvider'
import { withUser } from '../../../../../../common/AuthenticationProvider'
import { batchIdToProductId } from '../../../../common/utils'

import LMDDeliveryDetail from './LMDDeliveryDetail'
import ErrorView from '../../../../components/LoadingError'

const LMDDeliveryDetailContainer = ({
  api,
  user,
  config,
  match
}) => {
  const history = useHistory()
  const { snapshotId } = match.params
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [delivery, setDelivery] = useState({})
  const [productsMap, setProductsMap] = useState(new Map())
  const [appPrograms, setAppPrograms] = useState({})

  const initialize = async () => {
    try {
      const delivery = await api.shipment.findById(snapshotId)
      const productIds = Object.keys(delivery.counts).map(batchId => batchIdToProductId(batchId))
      const products = await api.product.getByIds(productIds)
      const productsMap = products.reduce(
        (map, p) => map.set(p._id, p), new Map()
      )
      const appPrograms = await api.program.list()
      setDelivery(delivery)
      setProductsMap(productsMap)
      setAppPrograms(appPrograms)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  if (error) {
    return <ErrorView error={error} />
  }

  if (loading) {
    return <Loading />
  }

  return (
    <LMDDeliveryDetail
      history={history}
      config={config}
      delivery={delivery}
      productsMap={productsMap}
      appPrograms={appPrograms}
    />
  )
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig
)

export default withHOCs(LMDDeliveryDetailContainer)
