const { v4 } = require('uuid')
const PGAdapter = require('../common/pg-adapter')
const { installmentsQuery } = require('./queries/payment-installments-queries')
const { unpaidInstalmentsQuery } = require('./queries/unpaid-installments-query')
const PAYMENT_INSTALLMENT_TABLE_NAME = 'avocado.data_paymentplaninstallment'
const PAYMENT_INSTALLMENT_COLUMNS = [
  'id',
  'amount',
  'due_date',
  'balance',
  'is_paid',
  'payment_plan_id',
  'code'
]

class PaymentInstallmentAdapter extends PGAdapter {
  constructor (pgConnection, username) {
    super(
      pgConnection,
      PAYMENT_INSTALLMENT_TABLE_NAME,
      username,
      PAYMENT_INSTALLMENT_COLUMNS,
      'id'
    )
  }

  // todo: currently lot's of code are depend with paymentplan installments table so we should
  // store generated installments untill we will do refactoring and remove dependencies.
  async createInstalments (paymentPlanId) {
    const query = installmentsQuery()
    const { rows } = await this.pgConnection.query(query, [paymentPlanId])
    const data = rows.map(instalment => {
      return {
        id: v4(),
        amount: instalment.amount,
        is_paid: instalment.is_paid,
        balance: instalment.balance,
        payment_plan_id: paymentPlanId,
        code: instalment.code,
        due_date: instalment.due_date
      }
    })
    return this.create(data)
  }

  async getUnpaidInstalments (paymentPlanId) {
    const query = unpaidInstalmentsQuery(true, paymentPlanId)
    const values = []
    if (paymentPlanId) {
      values.push(paymentPlanId)
    }
    const { rows } = await this.pgConnection.query(query, values)
    return rows
  }
}

module.exports = PaymentInstallmentAdapter
