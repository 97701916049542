import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import withOffline from '@fielded/shared-ui/src/common/offline'

import { BackButton, Banner, Button, Page, Form } from '@fielded/shared-ui'
import { getCurrencySymbol } from '@fielded/shared-ui/src/components/PriceDisplay'

import { Orientation, PartnerBalanceIndicator } from '@fielded/shared-ui/src/icons'
import { pluralize } from '../../../van-shared/utils'

import ConfirmationTable from './ConfirmationTable'
import MissingStockCountTable from './MissingStockCountTable'

import {
  getInvoiceConfirmationRows,
  getConfirmationRowsMissingCount
} from './confirmationUtils'

const unfilledRow = row => {
  // On entry we don't have the 'autoFilled' prop, so we still need to check this:
  const count = get(row, 'fields.field:standard-physical-count.amount')
  return count === undefined
}

const getTableHeaders = (fields) => {
  const keys = [
    'products',
    'standard-opening-balance',
    'standard-physical-count',
    'standard-consumed'
  ]

  return keys.map(k => ({
    name: k,
    value: get(fields, `field:${k}.name`, k)
  }))
}

const getTableRows = (data, filterUnfilled, showOOCWarning) => {
  return data.filter(item => {
    const { isProductLine, isTotal } = item

    if (isProductLine || isTotal) {
      return false
    }

    if (filterUnfilled) {
      return !unfilledRow(item)
    }

    return true
  }).map((item, idx) => {
    const name = get(item, 'name', '-')
    const basicUnit = get(item, 'basicUnit', '-')
    const fullName = `${name} – ${basicUnit}`

    const fields = get(item, 'fields', {})

    // standard
    const sob = get(item, 'fields.field:standard-opening-balance.amount', 0)
    const spc = get(item, 'fields.field:standard-physical-count.amount')
    const sc = get(item, 'fields.field:standard-consumed.amount', 0)

    // other
    const opb = get(item, 'fields.field:opening-partner-balance.amount', 0)
    const pb = get(item, 'fields.field:partner-balance.amount', 0)

    const hasPartnerBalance = (opb > 0) || (pb > 0)
    const hasWarning = showOOCWarning && (spc > sob) && (sob > 0)

    return {
      key: `row.${idx}`,
      cols: [{
        name: 'products',
        value: fullName
      }, {
        name: 'standard-opening-balance',
        value: sob
      }, {
        name: 'standard-physical-count',
        value: spc
      }, {
        name: 'standard-consumed',
        value: hasWarning ? 'TBD' : sc
      }],
      name: fullName,
      fields,
      hasPartnerBalance,
      hasWarning
    }
  })
}

const getRowsMissingCount = (rows = []) => (
  rows.filter(item => {
    const { isProductLine, isTotal } = item

    if (isProductLine || isTotal) {
      return false
    }

    return true
  }).reduce((missingRows, row) => {
    if (row.isProductLine || row.isTotal) {
      return false
    }
    const fullName = `${row.name} – ${row.basicUnit}`
    if (unfilledRow(row)) {
      missingRows.push({name: fullName, id: row.id})
    }
    return missingRows
  }, [])
)

const ConfirmationSL = ({
  report,
  reportLocation,
  serviceId,
  fieldsById: fields,
  productsById,
  onClickGoBack,
  onClickConfirm,
  readOnly = false,
  formRef,
  signature,
  config
}) => {
  const country = get(reportLocation, 'location.country')
  const currency = getCurrencySymbol({country})
  const partialCount = report.partialCount
  const appName = config.name

  // column headers to show in the table
  const confirmationTableHeaders = getTableHeaders(fields)

  // rows to show in the table
  const confirmationRows = getInvoiceConfirmationRows({report, productsById, serviceId, skipAutoFilled: partialCount})
  let confirmationTableRows = getTableRows(confirmationRows, false, true)

  // Non-partial counts:
  // checks if there is stock that hasn't been counted
  let confirmationRowsMissingCount = getConfirmationRowsMissingCount(confirmationRows)
  let rowsMissingCount = getRowsMissingCount(confirmationRows)
  let blockConfirmation = confirmationRowsMissingCount > 0
  // Partial Counts:
  // do not block when products have not been counted:
  if (partialCount) {
    confirmationRowsMissingCount = []
    rowsMissingCount = []
    blockConfirmation = false
    confirmationTableRows = getTableRows(confirmationRows, true, false)
  }

  let issuesWarningText
  if (blockConfirmation) {
    const productsString = pluralize('product', confirmationRowsMissingCount)
    const have = confirmationRowsMissingCount === 1 ? 'has' : 'have'
    issuesWarningText = `${confirmationRowsMissingCount} ${productsString} ${have} not been counted`
  }

  // checks if there are out of contract buyings
  const rowsWithWarnings = confirmationTableRows.filter(row => row.hasWarning).length
  const hasOOCWarning = rowsWithWarnings > 0
  const productsString = pluralize('product', rowsWithWarnings)
  // checks if location has Partner Balance enabled
  const hasPartnerBalance = get(reportLocation, 'tracksPartnerBalances')
  const anyPartnerBalanceItems = confirmationTableRows.some(row => row.hasPartnerBalance)

  let title = 'Confirm quantities '
  if (readOnly) {
    title = 'Stock Count Entry TODO: Add createdBy/submittedAt '
  }
  return (
    <Page situation={blockConfirmation ? 'caution' : null}>
      <header className='confirmation__back-button'>
        <BackButton
          onClick={onClickGoBack}
        />
      </header>
      {blockConfirmation && (<Page.Intro title={issuesWarningText} centered />)}
      {!blockConfirmation && <Page.Intro centered className='confirmation__intro'>{title}<Orientation /></Page.Intro>}
      {hasOOCWarning && !blockConfirmation && !readOnly &&
      <Page.Panel centered className='confirmation__warning'>
        <Banner
          type='warning'
          inline
          title='Outside buying'
        >
          {`${rowsWithWarnings} ${productsString} have been flagged for outside buying, as we counted units that were not supplied by ${appName}. You will be invoiced for the remaining ${appName} balance for these products.`}
        </Banner>
      </Page.Panel>}
      <Page.Panel
        withBackground
      >
        <Page.Panel.Section fullWidth>
          <header className={blockConfirmation ? 'confirmation__header confirmation__header--small' : 'confirmation__header'}>
            <div className='confirmation__header-title'>
              {reportLocation.name}
              <div className='confirmation__header-info'>{appName} Pharmaceuticals</div>
            </div>
            {hasPartnerBalance && anyPartnerBalanceItems && !blockConfirmation && <div className='confirmation__header-pb'>
              <span className='confirmation__header-pb-icon'>
                <PartnerBalanceIndicator fill='#230D53' />
              </span>
              = {appName} subscription with pharmacy stock remaining
            </div>}
          </header>
          {blockConfirmation && <MissingStockCountTable
            rowsMissingCount={rowsMissingCount}
            reportId={report._id}
          />}
          {!blockConfirmation && <ConfirmationTable
            headers={confirmationTableHeaders}
            rows={confirmationTableRows}
            currency={currency}
            theme='default'
            locationName={reportLocation.name}
            country={country}
            appName={appName}
          />}
        </Page.Panel.Section>
        {hasOOCWarning && <Page.Panel.Section fullWidth className='confirmation__block confirmation__block--warning'>Kindly note: Total sold for flagged products will be calculated and available in your sales statement and invoice.</Page.Panel.Section>}
        {!blockConfirmation && !readOnly && (
          <Page.Panel.Section fullWidth className='confirmation__block'>
            <Form ref={formRef}>
              <Form.Section
                title='Signature'
                intro='Please confirm that the stock count above is accurate by signing on behalf of the company below.'
              >
                <Form.Row>
                  <Form.Field
                    fieldName='name'
                    labelText='Name'
                    spread
                  >
                    <Form.Field.Text
                      onChange={() => {}}
                      required={partialCount ? false : 'Please enter a name'}
                    />
                    <Form.Field.ValidationErrors />
                  </Form.Field>
                </Form.Row>

                <Form.Row>
                  <Form.Field
                    labelText='Please sign below'
                    fieldName='signature'
                    spread
                  >
                    <Form.Field.Signature
                      disabled={signature}
                      defaultValue={signature}
                      required={partialCount ? false : 'Please sign'}
                    />
                    <Form.Field.ValidationErrors />
                  </Form.Field>
                </Form.Row>
              </Form.Section>
            </Form>
          </Page.Panel.Section>
        )}
        {blockConfirmation && <Page.Panel.Section className='confirmation-table__button'>
          <Button
            onClick={onClickGoBack}
            colorVariant='brand'
            size='large'
            fill='full'
          >
          Return to count
          </Button>
        </Page.Panel.Section>}
      </Page.Panel>
      {!blockConfirmation && <Page.Footer nowrap>
        {!readOnly &&
          <Button
            onClick={onClickConfirm}
            colorVariant='brand'
            fill='full'
            size='large'
          >
            Confirm
          </Button>}

        <Button
          alignOpposite
          onClick={onClickGoBack}
          colorVariant='brand'
          fill='outline'
        >
          Go back
        </Button>
      </Page.Footer>}
    </Page>
  )
}

ConfirmationSL.propTypes = {
  config: PropTypes.object.isRequired,
  /**
   * Report object for the count to confirm
   */
  report: PropTypes.object.isRequired,
  /**
   * Full location object for report location
   */
  reportLocation: PropTypes.object.isRequired,
  /**
   * From masterdata
   */
  programName: PropTypes.string.isRequired,
  /**
   * From masterdata
   */
  serviceId: PropTypes.string.isRequired,
  /**
   * Indexed, all products from masterdata
   */
  productsById: PropTypes.object.isRequired,
  /**
   * Indexed object, all fields from report definition
   */
  fieldsById: PropTypes.object.isRequired,
  onClickGoBack: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
  isOnline: PropTypes.bool.isRequired
}

export default withOffline(ConfirmationSL)
