const { PouchAdapter } = require('../../common')

// TODO: this is a placeholder, doc schema to be added
const schema = {
  title: 'report',
  name: 'report',
  description: 'A stock count report',
  type: 'object',
  properties: {
    _id: {type: 'string'}
  }
}

class ReportPouchAdapter extends PouchAdapter {
  constructor (user, db) {
    super(user, schema, db)
  }
}

module.exports = ReportPouchAdapter
