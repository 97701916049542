exports.save = save
function save (state, doc) {
  return state.programsDB.put(doc)
}

exports.saveService = saveService
async function saveService (state, doc, service) {
  await state.programsDB.put(doc)
  return service
}
