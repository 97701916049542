exports.getUserStatus = getUserStatus
function getUserStatus (user) {
  return isWarehouseUser(user)
    ? 'warehouse'
    : 'new'
}

exports.isWarehouseUser = isWarehouseUser
function isWarehouseUser (user) {
  return user.roles &&
    user.roles.some(userRole => userRole === 'feature:orders:warehouse')
}
exports.userCanCreate = userCanCreate
function userCanCreate (user) {
  return user.roles &&
    user.roles.some(userRole => userRole.includes('feature:orders:requester')) &&
    !user.roles.includes('feature:userRole:psm-national')
}
exports.isSuperAdminUser = (user) => {
  return user.roles &&
    user.roles.some(userRole => userRole.includes('feature:settings'))
}
