const {RemitaInterface, RemitaMockedInterface} = require('./remita')
const SafaricomInterface = require('./safaricom')
const PaystackInterface = require('./paystack/api')

module.exports = {
  RemitaInterface,
  RemitaMockedInterface,
  SafaricomInterface,
  PaystackInterface
}
