const isEqual = require('lodash/isEqual')
const { getByServiceId } = require('../../../configuration/api/read')
const validateServiceProducts = require('../../../utils/validate-products-for-service')

function prepareConfigToSave (config, products, username = '') {
  const configToSave = Object.assign({}, config, {
    updatedAt: new Date().toJSON(),
    updatedBy: username
  })
  configToSave.products = config.products.filter(c => !products.includes(c))
  for (let i in (configToSave.packages || [])) {
    configToSave.packages[i].products = configToSave.packages[i].products.filter(c => !products.includes(c))
  }
  return configToSave
}

exports.removeProducts = async function (state, service, products, skipValidation) {
  if (!products || !products.length) {
    return
  }
  if (!skipValidation) {
    await validateServiceProducts(state, service, products)
  }
  const config = await getByServiceId(state, service)
  if (!config) {
    throw new Error(`Service ${service} doesn't seem to exist, cannot remove products from it`)
  }

  const username = state.user ? state.user.name : ''
  const doc = prepareConfigToSave(config, products, username)
  if (!isEqual(doc.products, config.products)) {
    const res = await state.dal.configuration.save(state, doc)
    return res
  }
}
