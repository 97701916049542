const get = require('lodash/get')
const {
  QboTaxCodeNotFoundError,
  QboTaxRateNotFoundError
} = require('./errors.js')

async function getTax ({
  quickbooksInterface,
  companyCode
}) {
  const queryStr = encodeURIComponent(`SELECT * FROM TaxCode WHERE Name = '${companyCode}'`)
  const queryUrl = `query?minorversion=${quickbooksInterface.apiVersion}&query=${queryStr}`
  const queryResp = await quickbooksInterface.get(companyCode, queryUrl)
  const qboTaxCode = get(queryResp, 'QueryResponse.TaxCode[0]')
  if (!qboTaxCode) {
    throw new QboTaxCodeNotFoundError(companyCode)
  }
  const taxCodeId = qboTaxCode.Id
  const taxRateId = get(qboTaxCode, 'SalesTaxRateList.TaxRateDetail[0].TaxRateRef.value')
  if (!taxRateId) {
    throw new QboTaxRateNotFoundError(companyCode)
  }
  return {
    codeId: taxCodeId,
    rateId: taxRateId
  }
}

module.exports = {
  getTax
}
