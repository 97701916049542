const LEVELS = {
  GROUP: 'group',
  ORDER: 'order',
  SUB_ORDER: 'suborder'
}
exports.LEVELS = LEVELS

exports.getLevel = (groupId, orderId, suborderId) => {
  if (groupId) {
    return LEVELS.GROUP
  }

  if (orderId && !suborderId) {
    return LEVELS.ORDER
  }

  return LEVELS.SUB_ORDER
}
