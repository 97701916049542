/**
 * override snapshot is updating snapshot with given data for instance deliveryDate
 * todo: probably we need refactor snapshot-update.js right now it's updating only products
 */
const ALLOW_UPDATES = ['deliveryDate']
exports.overrideSnapshot = (snapshots, data, user, updatedAt) =>
  snapshots.map(snapshot => {
    for (const prop of ALLOW_UPDATES) {
      if (data[prop] && snapshot[prop]) {
        snapshot[prop] = data[prop]
      }
    }
    snapshot.updatedAt = updatedAt
    snapshot.updatedBy = user.name
    return snapshot
  })
