'use strict';
var formats = require('ajv/lib/compile/formats')();
var validate = (function() {
  var pattern0 = new RegExp('^product:[^:^\\s]+$');
  var refVal = [];
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    'use strict'; /*# sourceURL=https://schema.field.partners/1.0/van-batch-external# */
    var vErrors = null;
    var errors = 0;
    if (rootData === undefined) rootData = data;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      if (true) {
        var errs__0 = errors;
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !(false || key0 == 'manufacturer' || key0 == 'batchNo' || key0 == 'productId' || key0 == 'expiry');
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
            break;
          }
        }
        if (valid1) {
          if (data.manufacturer === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'manufacturer'
              },
              message: 'should have required property \'manufacturer\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data.manufacturer !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.manufacturer',
                schemaPath: '#/properties/manufacturer/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.batchNo === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'batchNo'
                },
                message: 'should have required property \'batchNo\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (typeof data.batchNo !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.batchNo',
                  schemaPath: '#/properties/batchNo/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              var data1 = data.productId;
              if (data1 === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'productId'
                  },
                  message: 'should have required property \'productId\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if (typeof data1 === "string") {
                  if (!pattern0.test(data1)) {
                    validate.errors = [{
                      keyword: 'pattern',
                      dataPath: (dataPath || '') + '.productId',
                      schemaPath: '#/properties/productId/pattern',
                      params: {
                        pattern: '^product:[^:^\\s]+$'
                      },
                      message: 'should match pattern "^product:[^:^\\s]+$"'
                    }];
                    return false;
                  } else {}
                } else {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.productId',
                    schemaPath: '#/properties/productId/type',
                    params: {
                      type: 'string'
                    },
                    message: 'should be string'
                  }];
                  return false;
                }
                if (errors === errs_1) {}
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                var data1 = data.expiry;
                if (data1 === undefined) {
                  valid1 = false;
                  validate.errors = [{
                    keyword: 'required',
                    dataPath: (dataPath || '') + "",
                    schemaPath: '#/required',
                    params: {
                      missingProperty: 'expiry'
                    },
                    message: 'should have required property \'expiry\''
                  }];
                  return false;
                } else {
                  var errs_1 = errors;
                  var errs__1 = errors,
                    prevValid1 = false,
                    valid1 = false,
                    passingSchemas1 = null;
                  var errs_2 = errors;
                  if ((typeof data1 === "number")) {
                    if (true) {}
                  }
                  if (errors === errs_2) {
                    if (typeof data1 === "string") {
                      if (!formats['date-time'].test(data1)) {
                        var err = {
                          keyword: 'format',
                          dataPath: (dataPath || '') + '.expiry',
                          schemaPath: '#/properties/expiry/oneOf/0/format',
                          params: {
                            format: 'date-time'
                          },
                          message: 'should match format "date-time"'
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      } else {}
                    } else {
                      var err = {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.expiry',
                        schemaPath: '#/properties/expiry/oneOf/0/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    }
                    if (errors === errs_2) {}
                  }
                  var valid2 = errors === errs_2;
                  if (valid2) {
                    valid1 = prevValid1 = true;
                    passingSchemas1 = 0;
                  }
                  var errs_2 = errors;
                  if ((typeof data1 === "number")) {
                    if (true) {}
                  }
                  if (errors === errs_2) {
                    if (typeof data1 === "string") {
                      if (!formats.date.test(data1)) {
                        var err = {
                          keyword: 'format',
                          dataPath: (dataPath || '') + '.expiry',
                          schemaPath: '#/properties/expiry/oneOf/1/format',
                          params: {
                            format: 'date'
                          },
                          message: 'should match format "date"'
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      } else {}
                    } else {
                      var err = {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.expiry',
                        schemaPath: '#/properties/expiry/oneOf/1/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    }
                    if (errors === errs_2) {}
                  }
                  var valid2 = errors === errs_2;
                  if (valid2 && prevValid1) {
                    valid1 = false;
                    passingSchemas1 = [passingSchemas1, 1];
                  } else {
                    if (valid2) {
                      valid1 = prevValid1 = true;
                      passingSchemas1 = 1;
                    }
                  }
                  if (!valid1) {
                    var err = {
                      keyword: 'oneOf',
                      dataPath: (dataPath || '') + '.expiry',
                      schemaPath: '#/properties/expiry/oneOf',
                      params: {
                        passingSchemas: passingSchemas1
                      },
                      message: 'should match exactly one schema in oneOf'
                    };
                    if (vErrors === null) vErrors = [err];
                    else vErrors.push(err);
                    errors++;
                    validate.errors = vErrors;
                    return false;
                  } else {
                    errors = errs__1;
                    if (vErrors !== null) {
                      if (errs__1) vErrors.length = errs__1;
                      else vErrors = null;
                    }
                  }
                  if (errors === errs_1) {}
                  var valid1 = errors === errs_1;
                }
                if (valid1) {}
              }
            }
          }
        }
        if (errs__0 == errors) {}
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    if (errors === 0) {}
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate.schema = {
  "id": "https://schema.field.partners/1.0/van-batch-external#",
  "$schema": "http://json-schema.org/draft-04/schema#",
  "title": "Batch external submission",
  "description": "External submission of batch information",
  "type": "object",
  "properties": {
    "manufacturer": {
      "type": "string"
    },
    "batchNo": {
      "type": "string"
    },
    "productId": {
      "type": "string",
      "pattern": "^product:[^:^\\s]+$"
    },
    "expiry": {
      "oneOf": [{
        "type": "string",
        "format": "date-time"
      }, {
        "type": "string",
        "format": "date"
      }]
    }
  },
  "required": ["productId", "batchNo", "manufacturer", "expiry"],
  "additionalProperties": false
};
validate.errors = null;
module.exports = validate;