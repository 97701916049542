class Changes {
  constructor (quickbooks) {
    this.quickbooks = quickbooks
  }

  async get ({ companyCode, entities, since }) {
    const entitiesStr = entities.join(',')
    const result = await this.quickbooks.quickbooksInterface.get(
      companyCode,
      `cdc?entities=${entitiesStr}&changedSince=${since}&minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`
    )
    return result
  }
}

module.exports = {
  Changes
}
