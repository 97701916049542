const createStatusDoc = require('./create-status-doc')
const getAlert = require('./get-alert')

module.exports = async (state, options) => {
  const db = state.alertsDb
  const alert = await getAlert(state, options.alertId)
  if (!alert) {
    throw new Error('Alert not found with id: ' + options.alertId)
  }
  const doc = createStatusDoc({
    alertId: options.alertId,
    userId: options.userId,
    deviceId: options.deviceId,
    clock: alert.clock,
    status: options.status
  })
  const resp = await db.put(doc)
  if (resp.error) {
    throw new Error(JSON.stringify(resp))
  }
  const newAlert = await getAlert(state, options.alertId)
  if (!newAlert) {
    throw new Error('Error getting updated alert with id: ' + options.alertId)
  }
  return newAlert
}
