import React from 'react'
import { Link } from 'react-router-dom'

import { DeemphasizedLink, IconBadge, Text } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

import { formatDate } from '../../../../van-shared/utils/utils'
import ServiceCard from '../../../../van-shared/components/ServiceCard'
import { RETAILER_SERVICES } from '../../../../van-shared/components/ServiceCard/service-constants'
import { getHeaderDetails } from '../../shipments/helpers'
import { ServiceListCardStatus } from '../shared/ServiceListCardStatus'

const ServiceHistoryListItem = ({ service }) => {
  const { status, date, url } = service.serviceDetails
  const { COLLECTION, CANCELLED } = RETAILER_SERVICES

  let { serviceType } = service.serviceDetails
  const { title, icon } = getHeaderDetails(service, serviceType, true)

  const isCancelled = serviceType === CANCELLED

  // we need to change any service type value of 'return'
  // cause we currently work with 'collection' service type
  if (serviceType === 'return') {
    serviceType = COLLECTION
  }

  return (
    <ServiceCard
      className='service-visit__history-list-item'
      colorVariant={serviceType}
      withBorder
      withDefaultCard
    >
      <div className='service-visit__card-content-wrapper'>
        <Text size='small'>
          {formatDate(date, 'invoiceDateFormat')}
        </Text>

        <div className='service-visit__card-content-icon-status'>
          <div className='service-visit__card-content-icon-wrapper vs-u-align-center'>
            <IconBadge
              icon={icon}
              colorCode={serviceType}
              withBorder={false}
              withDefaultIconStyle
            />
            <Text size='small' bold>{title}</Text>
          </div>
          {isCancelled && <ServiceListCardStatus status={status} />}
        </div>
      </div>

      <div className='service-visit__card-content-footer-items'>
        <DeemphasizedLink
          bold
          component={Link}
          to={url}
          icon={<Arrow direction='right' />}
          iconSide='right'
          colorVariant='brand'
          className='service-visit__card-content-footer-link'
        >
          Details
        </DeemphasizedLink>
      </div>
    </ServiceCard>
  )
}

export default ServiceHistoryListItem
