import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'

import { byTrend } from './utils'

import BatchList from '../BatchList/BatchList'
import ExpandCollapseButton from '../ExpandCollapseButton'
import UppercaseLabel from '../UppercaseLabel'

import DiffSummaryItem from './DiffSummaryItem'

class DiffSummary extends Component {
  static propTypes = {
    /**
     * A title for the DiffSummary, e.g. the location for a stock count
     */
    title: PropTypes.string,

    /**
     * A label with meta data for the DiffSummary, e.g. when a stock count was started
     */
    label: PropTypes.string,

    /**
     * A intro to the DiffSummary, should help the user understand the context of the numbers
     */
    intro: PropTypes.string,

    /**
     * If batches are contained in this diff summary, pass them here
     */
    batches: PropTypes.object,

    /**
     * An array of product groups to render in the summary, each containing a
     * product group title and a pickList object of counted:expected pairs by product
     */
    productGroups: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      products: PropTypes.object.isRequired
    })),

    /**
     * Name of a reservation/campaign that stock might be reserved for
     */
    reservationName: PropTypes.string,

    /**
     * A message to help users understand a difference, if applicable.
     * Use placeholder `{moreless}` to indicate where in the string `XX more` or `XX less` should be inserted
     */
    differenceMessage: PropTypes.string,

    /**
     * Show text text message instead of total if count is 0
     */
    noneCountedMessage: PropTypes.string,

    /**
     * Make rows tappable with a link to the product details by setting this and passing history.
     * The product id will be appended to the base url, so make sure your string ends in `/`
     */
    productDetailsBaseUrl: PropTypes.string,

    /**
     * Pass a React router history object if product rows should be tappable.
     */
    history: PropTypes.object
  }

  static defaultProps = {
    title: undefined,
    label: undefined,
    intro: undefined,
    batches: null,
    productGroups: undefined,
    reservationName: 'campaign',
    differenceMessage: undefined,
    noneCountedMessage: undefined,
    productDetailsBaseUrl: undefined,
    history: undefined
  }

  state = {
    showBatches: false
  }

  getReservedStockProps = reserved => {
    if (!reserved && reserved !== 0) {
      return {}
    }

    return {
      reserved
    }
  }

  getDiffSummaryItem = ({id, name, code, total, picked, reserved, adjustment}) => {
    const {
      batches,
      reservationName,
      differenceMessage,
      noneCountedMessage,
      productDetailsBaseUrl,
      history
    } = this.props

    const { showBatches } = this.state

    // TODO: move into master data with human-readle name, see:
    // https://github.com/fielded/van-orga/issues/71
    const productName = name || code || id.split(':').pop()

    const allBatches = showBatches && batches && batches[productName]
      ? <BatchList batches={batches[productName]} productName={productName} />
      : null

    const productDetailsUrl = productDetailsBaseUrl ? productDetailsBaseUrl + id : ''
    let onSummaryItemClick

    if (productDetailsUrl && history) {
      onSummaryItemClick = () => { history.push(productDetailsUrl) }
    }

    const commonProps = {
      key: id,
      counted: picked,
      expected: total,
      reserved,
      reservationName,
      productName,
      productDetailsBaseUrl,
      differenceMessage,
      isExpanded: showBatches,
      onRowClick: onSummaryItemClick,
      adjustment
    }

    if (noneCountedMessage && (picked === 0 || !picked)) {
      return (
        <DiffSummaryItem
          {...commonProps}
          noneCountedMessage={noneCountedMessage}
        >{allBatches}</DiffSummaryItem>
      )
    }

    return (
      <DiffSummaryItem
        {...commonProps}
      >{allBatches}</DiffSummaryItem>
    )
  }

  handleExpandBatches = ({expanded: showBatches}) => {
    this.setState({showBatches})
  }

  render () {
    const {
      title,
      label,
      intro,
      batches,
      productGroups
    } = this.props

    const { showBatches } = this.state

    return (
      <section className='vs-diff-summary'>
        {(title || label || batches || intro) && (
          <div className='vs-diff-summary__header'>
            <div>
              { title &&
                <h1 className='vs-diff-summary__header-title'>{title}</h1>
              }
              { label &&
                <div className='vs-diff-summary__header-label'>
                  <UppercaseLabel>{label}</UppercaseLabel>
                </div>
              }
            </div>
            { batches &&
              <div className='vs-diff-summary__batch-expand'>
                <ExpandCollapseButton
                  expanded={showBatches}
                  onToggle={this.handleExpandBatches}
                  expandLabel='See all batches'
                  collapseLabel='Hide all batches'
                />
              </div>
            }
            { intro &&
              <p className='vs-diff-summary__header-intro'>{intro}</p>
            }
          </div>
        )}

        {productGroups && (
          <table className='vs-diff-summary__items'>
            {productGroups.map((productGroup) => {
              const name = productGroup.name
              const byTrends = byTrend(productGroup.products)

              return (
                <Fragment key={name}>
                  {name && (
                    <thead className='vs-diff-summary__items-header'>
                      <tr>
                        <th colSpan='2' className='vs-diff-summary__product-group-name'>
                          <UppercaseLabel>
                            { name }
                          </UppercaseLabel>
                        </th>
                        <th className='vs-diff-summary__product-group-total'>
                          <UppercaseLabel>
                            Totals
                          </UppercaseLabel>
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody className='vs-diff-summary__list'>
                    { byTrends.map(productId => (
                      this.getDiffSummaryItem(productGroup.products[productId])
                    ))}
                  </tbody>
                </Fragment>
              )
            })}
          </table>
        )}
      </section>
    )
  }
}

export default DiffSummary
