const get = require('lodash/get')
const { get: getLocation } = require('../../location/api/read/get')

// string to return in case a value is not available.
// useful in tests where we don't have locations set up
const CURRENT_DATE = new Date().toJSON()

async function createLedgerRows (state, ledgers) {
  const rows = []
  const latestRows = []

  for (const ledger of ledgers) {
    // Get location UUID
    const locationId = ledger.location.id
    const location = await getLocation(state, locationId)
    const locationUUID = location.additionalData.uuid

    // Define common attributes
    const ledgerProperties = {
      locationUUID,
      date: ledger.submittedAt,
      eventId: ledger.event,
      ledgerId: ledger._id,
      isStockCount: ledger.hasStockCount
    }

    for (const productId in ledger.stock) {
      const closing = get(ledger.stock[productId], 'fields.field:standard-physical-count.amount', 0)
      const partnerBalance = get(ledger.stock[productId], 'fields.field:partner-balance.amount', 0)
      const slBalance = closing - partnerBalance
      const createdAt = CURRENT_DATE
      const updatedAt = CURRENT_DATE
      const sku = productId.split('product:')[1]
      const _deleted = false

      const row = {
        ...ledgerProperties,
        sku,
        partnerBalance,
        slBalance,
        createdAt,
        updatedAt,
        _deleted
      }

      // get latest ledgers
      if (!ledger._id.includes(':date:')) {
        latestRows.push(row)
      } else {
        rows.push(row)
      }
    }
  }

  return {rows, latestRows}
}

module.exports = {
  createLedgerRows
}
