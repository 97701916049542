const translate = allocation => {
  if (allocation.version === '0.1.0') {
    let createdAt = allocation.date
    if (allocation.updatedAt && allocation.updatedAt < createdAt) {
      createdAt = allocation.updatedAt
    }

    const applyFrom = allocation.date.split('T')[0]

    // `translate` is used both for CouchDB docs and for
    // entity like allocations returned by the data adapter, so it
    // needs to work with `id` and `_id`.
    //
    // We can get rid of this when all functions have been ported
    // to use 'id'.
    const idKey = allocation.id ? 'id' : '_id'

    return Object.assign({}, allocation, {
      [idKey]: `${allocation[idKey].replace(allocation.date, applyFrom)}:createdAt:${createdAt}`,
      date: applyFrom,
      version: '1.0.0',
      type: 'allocation',
      originalId: allocation.originalId || allocation[idKey],
      createdAt
    })
  }

  return allocation
}

module.exports = translate
