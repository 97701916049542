import React, { Fragment } from 'react'

import { Banner, Button, DeemphasizedLink } from '@fielded/shared-ui'

import { toFriendableDate } from '../common/utils'

const StockUpdateNotification = (props) => {
  const { message, acceptLabel, onAccept, onDecline, createdAt } = props
  const reportStartedAt = createdAt ? toFriendableDate(new Date(createdAt)) : ''

  return (
    <div className='van report-entry__notification'>
      <Banner
        cta={<Fragment>
          <Button
            colorVariant='brand'
            fill='full'
            onClick={onAccept}
          >
            { acceptLabel }
          </Button>
          { onDecline && (
            <div style={{ padding: '12px' }}>
              <DeemphasizedLink
                onClick={onDecline}
              > Decline changes and continue with your version
              </DeemphasizedLink>
            </div>
          )}
        </Fragment>}
      >
        {message} <span style={{ whiteSpace: 'nowrap' }}>on {reportStartedAt}</span>
      </Banner>
    </div>
  )
}

export default StockUpdateNotification
