// TODO: move to fs-api
import * as sessionApi from './session'

const fetch = async (url, params = {}) => {
  const init = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    ...params
  }
  const res = await window.fetch(url, init)
  return res.json()
}

const authorization = async (name, password, { config }) => {
  const params = {
    body: JSON.stringify({ name, password }),
    method: 'POST'
  }

  const { remoteDbUrl } = config
  return fetch(remoteDbUrl + '_session', params)
}

export const fetchUserData = async ({
  remoteDbUrl,
  userName
}) => {
  const endpoint = `_users/org.couchdb.user%3A${encodeURIComponent(userName)}`
  return fetch(remoteDbUrl + endpoint)
}

const remoteLogin = async (name, password, { config }) => {
  const { remoteDbUrl } = config
  const user = await authorization(name, password, { config })
  if (user.error) {
    return user
  }
  return fetchUserData({ remoteDbUrl, userName: user.name })
}

export const login = async (username, password, { config }) => {
  const remoteSession = await remoteLogin(username, password, { config })
  if (!remoteSession.error) {
    await sessionApi.set(remoteSession)
  }

  return remoteSession
}

const remoteAdminLogin = async (name, password, { config, loggedInUserName }) => {
  if (!loggedInUserName) {
    throw new Error('User Name is required')
  }
  const { remoteDbUrl } = config
  const user = await authorization(name, password, { config })

  if (user.error) {
    throw new Error(user.error)
  }
  if (!user.roles.includes('_admin')) {
    throw new Error('Permission Denied')
  }

  const userData = await fetchUserData({ remoteDbUrl, userName: loggedInUserName })
  return {
    user: userData,
    adminName: user.name
  }
}

export const loginAsAdmin = async (username, password, { config, loggedInUserName }) => {
  try {
    const remoteSession = await remoteAdminLogin(username, password, { config, loggedInUserName })
    if (!remoteSession.user.error) {
      await sessionApi.set(remoteSession.user)
      await sessionApi.setLoggedInAsAdmin(remoteSession.adminName)
    }
    return {
      ...remoteSession.user,
      loggedInAsAdmin: remoteSession.adminName
    }
  } catch (error) {
    return {
      error: error.message
    }
  }
}

const remoteLogout = url => {
  const params = {
    method: 'DELETE'
  }
  return fetch(url + '_session', params)
}

export const logout = async (user, { config }) => {
  const { remoteDbUrl } = config

  await sessionApi.remove()
  await sessionApi.removeLoggedInAsAdmin()

  return remoteLogout(remoteDbUrl, { config })
}

// this does not 404, it's expected to return nothing if it doesn't exist
export const getCurrentUser = async (remoteDbUrl) => {
  let localUser
  try {
    localUser = await sessionApi.get()
  } catch (error) {
    return null
  }

  if (!localUser) return null

  const adminName = await sessionApi.getLoggedInAsAdmin()

  try {
    const endpoint = `_users/org.couchdb.user%3A${encodeURIComponent(localUser.name)}`
    const userDoc = await fetch(remoteDbUrl + endpoint)
    if (userDoc.error) {
      return null
    }
    await sessionApi.set(userDoc)
    return { ...userDoc, loggedInAsAdmin: adminName }
  } catch (error) {
    return localUser
  }
}
