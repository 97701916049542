module.exports = {
  validateLocationEdit,
  validateLocationCreate,
  locationEditsToDoc,
  getProgramEdits,
  createLocationDoc,
  createFacilityId,
  create20FacilityId,
  reBuildProgramServices
}

const {
  getFullProgramId,
  getShortProgramId,
  getFullServiceID,
  getShortServiceId,
  getShortId,
  isIdenticalDoc,
  getNormalizedAdditionalData
} = require('./utils')

const levels = require('../utils/location-levels')

const jjv = require('jjv')
const schema = require('../location/data-access/pouchdb-adapter/schema')
const smartId = require('./smart-id')
const isEqual = require('lodash/isEqual')
const clone = require('lodash/cloneDeep')
const kebabCase = require('lodash/kebabCase')

function validateLocationEdit (currentDoc, edits, date) {
  if (!currentDoc) return 'Can not edit a proposed location'
  const updatedDoc = locationEditsToDoc(currentDoc, edits, date)
  const noChangesMade = isIdenticalDoc(updatedDoc, currentDoc)
  if (noChangesMade) {
    return 'Existing doc and edited doc are identical.'
  }
  return validateModel(updatedDoc)
}

function validateLocationCreate (edits, date) {
  // Create location doc will create undefined fields so checking them here.
  // locationModel.validate needs transformed doc as against `edits` it will not give correct errors
  const fields = ['programs', 'level', 'location', 'fullName', 'contacts', 'createdAt', 'createdBy']
  const missingField = fields.find(field => !edits[field])
  if (missingField) {
    return `missing required field: ${missingField}`
  }
  const updatedDoc = createLocationDoc(edits, date)
  return validateModel(updatedDoc)
}

function validateModel (doc) {
  const modelError = jjv().validate(schema, doc)
  if (modelError) {
    // jjv returns an eror object
    // so just stringify it for error message.
    return JSON.stringify(modelError)
  }
}

function locationEditsToDoc (currentDoc, edits, date) {
  // This is the only guard against users editing non-editable fields.

  const { fullName, updatedAt, updatedBy, alias, contacts, programs, programsHistory, relationships, level, membership, version } = edits
  const additionalDataEdits = Object.assign(
    {}, currentDoc.additionalData, edits.additionalData
  )
  const additionalData = getNormalizedAdditionalData(additionalDataEdits)

  // Allow for programs history rewrite (e.g. for fixing recent route assignments) via programsHistory variable
  // We require a non empty programsHistory object
  const mustRewriteProgramsHistory = (programsHistory && Object.keys(programsHistory).length > 0)
  const editPrograms = mustRewriteProgramsHistory
    ? programsHistory
    : getProgramEdits(currentDoc.programs, programs, date, version)

  const trimmedName = fullName.trim()
  const newDoc = Object.assign({}, clone(currentDoc), {
    version: '4.0.0',
    name: trimmedName,
    fullName: trimmedName,
    alias,
    membership,
    updatedAt,
    updatedBy,
    additionalData,
    contacts: Object.assign({}, currentDoc.contacts, contacts),
    relationships: Object.assign({}, currentDoc.relationships, relationships),
    programs: editPrograms
  })

  // prevents attempts to set level being overwritten to undefined if it's not one of the edited properties
  if (level) {
    newDoc.level = level
  }
  return newDoc
}

function getProgramEdits (currentPrograms, editPrograms, date, version) {
  if (!editPrograms) return
  // Get edits on programs that already exist.
  const currentProgramsIds = Object.keys(currentPrograms)
  const currentProgramsEdited = currentProgramsIds.reduce((memo, shortProgramId) => {
    const fullProgramId = getFullProgramId(shortProgramId)

    const editedProgram = editPrograms.find(program => program.id === fullProgramId)

    // If this is an old version, we need to update all the programs in the location doc to the new programs shape
    if (version && version !== '4.0.0' && !editedProgram) {
      memo[shortProgramId] = reBuildProgramServices(currentPrograms[shortProgramId])
      return memo
    }

    memo[shortProgramId] = editedProgram
      ? editServicesOnProgram(currentPrograms[shortProgramId], editedProgram.services, date, shortProgramId)
      : disableAllServicesOnProgram(currentPrograms[shortProgramId], date, shortProgramId)

    if (Object.keys(memo[shortProgramId]).length === 0) {
      delete memo[shortProgramId]
    }
    return memo
  }, {})

  // Add any new programs.
  const newProgramsFromEditList = editPrograms.filter(program => {
    const shortProgramId = getShortProgramId(program.id)
    return !currentProgramsIds.includes(shortProgramId)
  })
  const newPrograms = createNewPrograms(date, newProgramsFromEditList)
  return Object.assign({}, currentProgramsEdited, newPrograms)
}

function createNewPrograms (date, programsList = []) {
  return programsList.reduce((memo, program) => {
    const shortProgramId = getShortProgramId(program.id)
    memo[shortProgramId] = getBaseCaseServices(program.services, date)
    return memo
  }, {})
}

function disableAllServicesOnProgram (program, date, shortProgramId) {
  return Object.keys(program).reduce((memo, shortServiceId) => {
    memo[shortServiceId] = maybeDisableService(shortProgramId, program[shortServiceId], date)
    if (memo[shortServiceId].length === 0) {
      delete memo[shortServiceId]
    }
    return memo
  }, {})
}

function maybeDisableService (shortProgramId, currentServicePeriods, date) {
  const routesResult = getServiceIdentifier(shortProgramId, currentServicePeriods, 'routes', 'routeId')
  const fundersResult = getServiceIdentifier(shortProgramId, currentServicePeriods, 'funders', 'funderId')

  const latestRoute = routesResult[routesResult.length - 1] || {}
  const latestFunder = fundersResult[fundersResult.length - 1] || {}

  if (!latestFunder.endDate || !latestRoute.endDate) {
    if (latestFunder.startDate === date || latestRoute.startDate === date) {
      fundersResult.pop()
      routesResult.pop()
    } else {
      latestFunder.endDate = date
      latestRoute.endDate = date

      routesResult[routesResult.length - 1] = latestRoute
      fundersResult[fundersResult.length - 1] = latestFunder
    }
  }
  // No `else` condition: if the last period has an end date the
  // service is already disabled and historical editing is not supported.
  return {funders: fundersResult, routes: routesResult}
}

function editServicesOnProgram (currentServices, editedServices, date, shortProgramId) {
  const result = {}
  const existingServices = clone(currentServices)
  for (let shortServiceId in existingServices) {
    const fullServiceId = getFullServiceID(shortServiceId, shortProgramId)
    const serviceEdits = editedServices.find(service => service.id === fullServiceId)

    if (serviceEdits) {
      const { routesResult, fundersResult } = maybeEnableService(shortProgramId, existingServices[shortServiceId], serviceEdits, date)
      result[shortServiceId] = {
        funders: fundersResult,
        routes: routesResult
      }
    } else {
      result[shortServiceId] = maybeDisableService(shortProgramId, existingServices[shortServiceId], date)
    }

    if (result[shortServiceId].length === 0) {
      delete result[shortServiceId]
    }
  }

  editedServices.forEach(service => {
    const shortServiceId = getShortServiceId(service.id)
    if (!result[shortServiceId]) {
      result[shortServiceId] = {
        funders: [],
        routes: []
      }
      // If we have a funder attach to funder array
      if (service.funderId) {
        const funderId = getShortId(service.funderId)
        result[shortServiceId].funders = [{ funderId, startDate: date }]
      }
      // If we have a route attach to route array
      if (service.routeId) {
        const routeId = getShortId(service.routeId)
        result[shortServiceId].routes = [{ routeId, startDate: date }]
      }
      // If we have neither delete and attach as normal
      if (!service.funderId && !service.routeId) {
        delete result[shortServiceId]
      }
    }
  })

  return result
}

// New services: [{ id: 'program:hiv-aids:service:pmtct', funderId: 'funderId:funder:pepfar' }, ...]
// become object for doc: { pmtct: { routes: [{routeId: 'pepfar', startDate: epoch } ], funders: [{funderId: 'pepfar', startDate: epoch}] ... }
function getBaseCaseServices (servicesList, date) {
  return servicesList.reduce((memo, service) => {
    const shortServiceId = getShortServiceId(service.id)

    const period = getPeriod({
      service,
      startDate: date,
      funderId: service.funderId,
      routeId: service.routeId
    })

    memo[shortServiceId] = {
      funders: [],
      routes: []
    }

    if (service.funderId) {
      memo[shortServiceId].funders.push(period)
    } else if (service.routeId) {
      memo[shortServiceId].routes.push(period)
    } else if (!service.funderId && !service.routeId) {
      memo[shortServiceId].funders.push(period)
      memo[shortServiceId].routes.push(period)
    }

    return memo
  }, {})
}

// service edit shape: { id: 'serviceId', funderId: 'fullFunderid', implementingPartnerId: 'fullId', ...others }
// returns: { funderId, implementingPartnerId, otherIds, startDate, [endDate] }
function getPeriod ({ service, startDate, endDate, funderId, routeId }) {
  const period = { startDate }
  if (endDate) {
    period.endDate = endDate
  }

  if (funderId) {
    period.funderId = funderId
  }

  if (routeId) {
    period.routeId = routeId
  }

  const ignoreKeys = ['id', 'startDate', 'endDate']

  Object.keys(service).forEach(key => {
    if (!ignoreKeys.includes(key)) {
      period[key] = getShortId(service[key])
    }
  })
  return period
}

function maybeEnableService (shortProgramId, currentServicePeriods, editService, date) {
  const routesResult = getServiceIdentifier(shortProgramId, currentServicePeriods, 'routes', 'routeId', editService)
  const fundersResult = getServiceIdentifier(shortProgramId, currentServicePeriods, 'funders', 'funderId', editService)

  if (editService.routeId) {
    let latestServicePeriod = routesResult.length > 0
      ? routesResult[routesResult.length - 1]
      : {}

    const editPeriod = getPeriod({ service: editService, startDate: date })

    if (editPeriod.funderId) delete editPeriod.funderId
    if (editPeriod.implementingPartnerId) delete editPeriod.implementingPartnerId

    if (Object.keys(latestServicePeriod).length === 0) {
      routesResult.push(editPeriod)
      return {routesResult, fundersResult}
    }

    if (periodsHaveSameAttributes(latestServicePeriod, editPeriod) && !latestServicePeriod.endDate) {
      return {routesResult, fundersResult}
    }

    if (latestServicePeriod.startDate === date) {
      routesResult[routesResult.length - 1] = editPeriod
      return { routesResult, fundersResult }
    }

    if (latestServicePeriod.endDate) {
      routesResult.push(editPeriod)
      return { routesResult, fundersResult }
    }

    routesResult[routesResult.length - 1].endDate = date
    routesResult.push(editPeriod)
    return {routesResult, fundersResult}
  }

  if (editService.funderId) {
    let latestServicePeriod = fundersResult.length > 0
      ? fundersResult[fundersResult.length - 1]
      : {}

    const editPeriod = getPeriod({ service: editService, startDate: date })

    if (editPeriod.routeId) delete editPeriod.routeId

    if (Object.keys(latestServicePeriod).length === 0) {
      fundersResult.push(editPeriod)
      return {routesResult, fundersResult}
    }

    if (periodsHaveSameAttributes(latestServicePeriod, editPeriod) && !latestServicePeriod.endDate) {
      return {routesResult, fundersResult}
    }

    if (latestServicePeriod.startDate === date) {
      fundersResult[fundersResult.length - 1] = editPeriod
      return { routesResult, fundersResult }
    }

    if (latestServicePeriod.endDate) {
      fundersResult.push(editPeriod)
      return { routesResult, fundersResult }
    }

    fundersResult[fundersResult.length - 1].endDate = date
    fundersResult.push(editPeriod)
    return {routesResult, fundersResult}
  }

  return {routesResult, fundersResult}
}

// If we ignore dates do these periods have the same funder/ip/X attribute
function periodsHaveSameAttributes (editPeriod, existingPeriod) {
  return isEqual(
    getWithoutDates(editPeriod),
    getWithoutDates(existingPeriod)
  )
}

function getWithoutDates (period) {
  const withoutDates = {}
  for (let key in period) {
    if (key !== 'startDate' && key !== 'endDate') {
      withoutDates[key] = period[key]
    }
  }
  return withoutDates
}

function createLocationDoc (edits, date, supportCountry) {
  let { level, location, fullName, contacts, version, programs, createdAt, createdBy, additionalData = {}, alias = {}, relationships } = edits
  const versionToUse = getLocationVersion(version)
  const geoLocationId = location.id

  const createdID = versionToUse === '3.0.0'
    ? createFacilityId(geoLocationId, level, fullName)
    : create20FacilityId(geoLocationId, fullName, {
      level, supportCountry
    })

  fullName = fullName.trim()

  return {
    _id: edits._id || createdID,
    name: fullName,
    fullName,
    version: versionToUse,
    type: 'location',
    level,
    location,
    alias,
    additionalData,
    contacts,
    createdAt,
    createdBy,
    updatedAt: createdAt,
    updatedBy: createdBy,
    relationships: Object.assign({}, relationships),
    programs: createNewPrograms(date, programs),
    // TODO: remove configurations once backwards compatibility with 1.0 no longer supported
    // https://github.com/fielded/van-orga/issues/1516
    configurations: []
  }
}

function createFacilityId (geoLocationId, level, fullName) {
  // The facility id starts with the geo location id, except for the country part
  let locPart = geoLocationId.split(':').slice(2).join(':')
  if (level === 'national' || level === 'country' || locPart.length === 0) {
    locPart = level
  } else {
    // The location part of facility ids should only be as specific as the facility
    // level, because currently queries using the facility id for view collation
    // count on that. To keep that behaviour, we remove anything from the end of
    // the location part of the id, that is more specific than the level.
    const levelIndex = locPart.indexOf(`${level}:`)
    if (levelIndex !== -1) {
      // The location is more specific than the level, shorten the id location part
      const matchStr = locPart.match(`(${level}:[a-z_]+)`)[0]
      locPart = locPart.slice(0, levelIndex + matchStr.length)
    }
  }
  const name = kebabCase(fullName)
  return `${locPart}:name:${name}`
}

// remove the use of name in specifying geolocation values
// instead of sdp:name:name-of-location we get
// sdp:name-of-location
function create20FacilityId (id, name, {level, supportCountry}) {
  const geolocations = getLocationFromGeoId(id)

  let allowedLevels = levels
  if (!supportCountry) {
    allowedLevels = levels.slice(1, levels.length) // remove country from the levels
  }
  allowedLevels = allowedLevels.slice(0, allowedLevels.indexOf(level))

  let makeId = ''

  for (let allowedLevel of allowedLevels) {
    const geolocation = geolocations[allowedLevel]
    if (!geolocation) {
      continue
    }

    makeId += `${allowedLevel}:${kebabCase(geolocation)}:`
  }

  makeId += `${level}:${kebabCase(name)}`
  return makeId
}

function getLocationFromGeoId (id) {
  return smartId.parse(id)
}

function getServiceIdentifier (program, currentServicePeriods, identifier, id, editService = {}) {
  // For docs that already have the desired routes and funders shape, keep as it is
  if (currentServicePeriods[identifier]) return currentServicePeriods[identifier]

  // Shelflife does not use funders so we just return empty array
  if (program === 'shelflife' && identifier === 'funders') return []

  // If we are trying to update funders we don't need to update routes
  if (editService.hasOwnProperty('funderId') && identifier === 'routes') return []

  const notAllowedProps = ['funderId', 'routeId']
  return currentServicePeriods.map(service => {
    if (service[id]) {
      if (service.funderId && identifier === 'routes') delete service.funderId

      if (service.implementingPartnerId && identifier === 'routes') delete service.implementingPartnerId

      if (service.routeId && identifier === 'funders') delete service.routeId

      return service
    }
    const memo = {}

    Object.keys(service).forEach(prop => {
      if (notAllowedProps.includes(prop)) {
        memo[id] = service[prop]
        return
      }
      memo[prop] = service[prop]
    })

    if (memo.funderId && identifier === 'routes') delete memo.funderId

    if (memo.implementingPartnerId && identifier === 'routes') delete memo.implementingPartnerId

    if (memo.routeId && identifier === 'funders') delete memo.routeId

    return memo
  })
}

function reBuildProgramServices (program) {
  return Object.keys(program).reduce((acc, service) => {
    acc[service] = {
      funders: [],
      routes: []
    }

    program[service].forEach(option => {
      // Shelflife only uses routes so we return after
      if (program === 'shelflife') {
        // If it still has funderId prop we replace it with correct prop
        if (option.hasOwnProperty('funderId')) {
          option.routeId = option.funderId
          delete option.funderId
        }
        acc[service].routes.push(option)
        return
      }

      if (option.hasOwnProperty('funderId')) {
        acc[service].funders.push(option)
      }

      if (option.hasOwnProperty('routeId')) {
        acc[service].routes.push(option)
      }
    })
    return acc
  }, {})
}

function getLocationVersion (version) {
  // If there is no vrsion we always default to 3.0.0 which is for psm warehouses
  if (!version) return '3.0.0'

  // We want to keep versioning for warehouses as it is
  if (version && version === '3.0.0') return '3.0.0'

  // Else we want to update all other location to version 4.0.0
  return '4.0.0'
}
