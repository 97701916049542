import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import clasnames from 'classnames'

import { buildOpenReportUrl } from '../app/routes-urls'

const MissingStockCountTable = ({
  rowsMissingCount,
  reportId
}) => {
  const baseUrl = buildOpenReportUrl({reportId})
  return (
    <table className='confirmation-table confirmation-table--missing-count'>
      <thead>
        <tr className='confirmation-table__tr'>
          <th className='confirmation-table__th confirmation-table__th--products'>Products</th>
        </tr>
      </thead>
      <tbody>
        {rowsMissingCount.map(row => {
          return (
            <tr
              className={clasnames('confirmation-table__tr')}
              key={`${row.id}`}
            >
              <td className={`confirmation-table__td`}>
                <Link
                  className='vs-u-link'
                  to={`${baseUrl}/${row.id}`}
                >
                  {row.name}
                </Link>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

MissingStockCountTable.propTypes = {
  /**
   * Array of product info that has not been counted
   */
  rowsMissingCount: PropTypes.array
}

export default MissingStockCountTable
