const PGAdapter = require('./pg-adapter.js')
const { getOrderByClause } = require('../tools/sql-tools')
const { v4 } = require('uuid')

class SoftDeletePGAdapter extends PGAdapter {
  // define `_deleted` column
  constructor (pgConnection, tableName, username, columns, idColumn = 'id', columnPreparers, logger, opts = {strictColumns: true, addUuid: true}) {
    if (columns.indexOf('_deleted') === -1) {
      columns.push('_deleted')
    }
    columnPreparers = columnPreparers || {}
    columnPreparers._deleted = (key, value) => [{statement: `_deleted IS FALSE`}]
    super(pgConnection, tableName, username, columns, idColumn, columnPreparers, logger, opts)
  }

  applyCreateDefaults (data) {
    // TODO: UUID should happen in postgresql, it's not because
    // our models were created by Django which does auto uuid at
    // the python django layer.
    // Maybe when we switch to running our own sql migrations we make sure uuid
    // auto happens in sql
    if (!data[this.idColumn] && this.columns.includes(this.idColumn) && this.addUuid) {
      data[this.idColumn] = v4()
    }
    if (!data.created_at && this.columns.includes('created_at')) {
      data.created_at = new Date().toJSON()
    }
    if (!data.created_by && this.columns.includes('created_by')) {
      data.created_by = this.username
    }

    if (!data._deleted) {
      data._deleted = false
    }

    this.applyUpdateDefaults(data)
  }

  async getList ({ ordering = this.idColumn, filter = {}, limit = 50, offset = 0, tableExpression = null, search = null, includeDeleted = false } = {}) {
    if (!includeDeleted) {
      filter._deleted = 'false'
    }
    const {whereText, whereValues} = this.getWhereCondition(filter, search)

    const sortText = getOrderByClause(ordering, this.columns.concat(this.virtualColumns))

    const values = whereValues.concat([limit, offset])

    const text = `SELECT * FROM ${tableExpression || this.tableName} ${whereText} ${sortText} LIMIT $${values.length - 1} OFFSET $${values.length};`
    let { rows: results } = await this.pgConnection.query({ text, values })

    if (this.getRelated) {
      results = await this.addCollectionRecords(results, this.getRelated)
    }

    const count = await this.getCount(undefined, whereText, whereValues, tableExpression)
    return { count, results }
  }

  async deleteWhere (condition, { client } = { client: this.pgConnection }) {
    if (typeof condition !== 'object') {
      throw new Error('SoftDeletePGAdapter Usage: deleteWhere expects condition object')
    }
    return this.updateWhere(condition, { _deleted: true }, { client })
  }
}

module.exports = SoftDeletePGAdapter
