import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { dateFormats, parseDate, formatDate } from '../../../van-shared/utils'

import { Button, Form, Page, SelectInput } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

const Planning = ({
  pageTitle,
  breadcrumbItems,
  allowDateChange,
  formattedDate,
  setDate,
  originOptions,
  origin,
  setOrigin,
  destinationOptions,
  destination,
  setDestination,
  onSubmit
}) =>
  <Page>
    <Page.Header
      title={pageTitle}
      breadcrumbItems={breadcrumbItems}
    />
    <Page.Panel spread>
      <Page.Panel.Header title='Select a location and date' />
      <div className='show-when-offline'>
        <Page.Panel.Message type='warning'>
          <h3>Your device is offline!</h3>
          <div>Please connect to the internet to get the latest stock counts before starting planning.</div>
        </Page.Panel.Message>
      </div>
      <Page.Panel.Section className='new-shipment-form'>
        {allowDateChange &&
        <label className='new-shipment-form__row'>
          <Form.InputLabel>Start date of the current distribution:</Form.InputLabel>
          <DayPickerInput
            value={formattedDate}
            onDayChange={date => {
              if (date) {
                date = date.toJSON()
              }
              setDate(date)
            }}
            format={dateFormats.long}
            parseDate={parseDate}
            formatDate={date => formatDate(date, 'long')}
          />
        </label>}
        <label className='new-shipment-form__row'>
          <Form.InputLabel>Origin:</Form.InputLabel>
          <SelectInput
            clearable={false}
            value={origin}
            options={originOptions}
            onChange={({value}) => setOrigin(value)}
            disabled={originOptions.length === 1}
          />
        </label>
        <label className='new-shipment-form__row'>
          <Form.InputLabel>Destination:</Form.InputLabel>
          <SelectInput
            clearable={false}
            value={destination}
            options={destinationOptions}
            onChange={({value}) => setDestination(value)}
            disabled={destinationOptions.length === 1}
          />
        </label>
      </Page.Panel.Section>
    </Page.Panel>
    <Page.Footer>
      <Button
        icon={<Arrow direction='right' />}
        iconSide='right'
        colorVariant='brand'
        fill='full'
        size='large'
        onClick={onSubmit}
      >
        Next
      </Button>
    </Page.Footer>
  </Page>

export default Planning
