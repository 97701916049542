import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import get from 'lodash/get'

import { Bell, Clipboardlist, Exchange, InfoCircle, Intercom, Landmark, Lightbulb, PlusCircle, Products, QuestionMark, Truck, User } from '@fielded/shared-ui/src/icons'

import { hasRetailStock } from '../../subapps/retailer/hasRetail'
import { userIsAuthorised } from '../../van-shared/utils/auth'
import { hasFeature } from '../../van-shared/utils/features'
import { userHasIntercom } from '../../common/init-intercom'

import { getNavigationItem } from '../../common/utils/navigationInfo'

import MenuItem from '../MenuItem/MenuItem'
import MainMenuLeadUserItems from './MainMenuLeadUserItems'
import { getAdministrationSubitems, getMyAccountSubitems, getAnalyticsSubitems, getShipmentsSubitems, getOrdersSubitems } from './get-main-menu-subitems'
import {shouldExcludeUser} from '../../utils/excludeLocations'
import { isLongHaulFeatureEnabled } from '../../subapps/longhaul-orders/is-longhaul-enabled'

const MainMenuItems = ({
  config,
  user,
  isPSM,
  isFacilityUser,
  isSLReseller,
  isRetailer,
  isBasicUser,
  isLeadUser,
  userWithDB,
  isCollapsed,
  isOnline,
  pendingContracts,
  outdatedContracts
}) => {
  const { features } = config
  const canViewSourceLedger = userIsAuthorised(user, 'feature:can-view-source-ledger')
  const hasFinances = isRetailer && userIsAuthorised(user, 'feature:view-finances')
  const retailStockViews = isRetailer && hasRetailStock(user)
  const hasAlerts = hasFeature(features, 'qcAlerts') && userIsAuthorised(user, 'feature:qc-alerts')
  const hasIntercom = userHasIntercom(config)

  // There is a known issue resulting in a broken Analytics UI for `lga` and
  // `sdp` level users:
  // https://github.com/fielded/van-orga/issues/2321
  //
  // Until we have an opporuntity to fix this, we choose to
  // simply not show the Analytics NAV item for users at those levels
  //
  // The same goes for PSM regional users that have "virtual" locations on their
  // user docs:
  // https://github.com/fielded/van-orga/issues/2751
  const nonAnalyticsRoles = [
    'feature:userRole:psm-facility',
    'feature:userRole:psm-lga',
    'feature:orders:warehouse',
    'feature:userRole:psm-vendor',
    'feature:userRole:psm-driver'
  ]
  const hasNonAnalyticsRoles = user.roles.some(role => nonAnalyticsRoles.includes(role))
  const hasAnalytics = hasFeature(features, 'analytics') && !hasNonAnalyticsRoles
  const hasStockCounts = hasFeature(features, 'stockCount') && (userIsAuthorised(user, 'feature:stock-reporting') || userIsAuthorised(user, 'feature:read-only-reports'))
  const hasShipments = hasFeature(features, 'shipments') && userIsAuthorised(user, 'feature:shipments')
  const hasInformationSection = hasFeature(features, 'information')
  const hasOrders = user.roles.some(role => role.includes('orders'))
  const hasLongHaulOrders = isLongHaulFeatureEnabled(user, config)
  const orderAppName = get(config, 'features.order.appName', 'Orders')
  const isPlannerOrSales = userIsAuthorised(user, 'feature:userRole:planner') || userIsAuthorised(user, 'feature:userRole:sales')

  const analyticsItems = getAnalyticsSubitems(features, user)
  const shipmentsItems = getShipmentsSubitems(features, user)
  const administrationItems = getAdministrationSubitems(features, user)
  const myAccountItems = getMyAccountSubitems(user, isBasicUser, isLeadUser, isSLReseller, pendingContracts, outdatedContracts)
  const ordersItems = getOrdersSubitems(config, user)
  const locationId = get(user, 'location.id')
  const isExternalUserLocationExcluded = shouldExcludeUser(locationId)

  return (
    <Fragment>
      <MenuItem
        {...getNavigationItem({ item: 'home', config })}
        compact={isCollapsed}
        exact
      />
      {!user && (
        <li className='menu-item'>
          <NavLink
            to='/login'
            className='menu-item__link'
            activeClassName='menu-item__link--active'
          >
            Login
          </NavLink>
        </li>
      )}
      {user && (
        <Fragment>
          {isLeadUser && (
            <MainMenuLeadUserItems
              isCollapsed={isCollapsed}
              userWithDB={userWithDB}
            />
          )}
          {hasAlerts && (
            <MenuItem
              {...getNavigationItem({ item: 'alerts', config })}
              compact={isCollapsed}
            />
          )}
          {hasAnalytics && (
            <MenuItem
              {...getNavigationItem({ item: 'analytics', config })}
              onlineOnly
              isOnline={isOnline}
              subItems={analyticsItems && analyticsItems}
              compact={isCollapsed}
            />

          )}
          {hasStockCounts && (
            <MenuItem
              {...getNavigationItem({ item: 'reports', config })}
              compact={isCollapsed}
            />
          )}
          {(!!shipmentsItems.length || hasShipments) && !isRetailer && (
            <MenuItem
              {...getNavigationItem({ item: 'shipments', config })}
              subItems={shipmentsItems && shipmentsItems}
              compact={isCollapsed}
            />
          )}
          {administrationItems.length > 0 && (
            <MenuItem
              {...getNavigationItem({ item: 'settings', config })}
              subItems={administrationItems}
              onSubitemToggle={() => this.toggleCollapsed()}
              compact={isCollapsed}
            />
          )}
          {hasOrders && (
            <MenuItem
              {...getNavigationItem({ item: 'orders', config })}
              subItems={isPSM ? ordersItems : null}
              compact={isCollapsed}
              title={`${orderAppName}`}
              icon={<Clipboardlist />}
            />
          )}
          {hasLongHaulOrders && (
            <MenuItem
              path='/longhaul-orders'
              title='Long haul orders'
              icon={<Exchange />}
              isActiveSlug='/longhaul-orders'
              compact={isCollapsed}
            />
          )}
          {isRetailer && !canViewSourceLedger && (
            <Fragment>
              <MenuItem
                path='/products'
                title='Products'
                icon={<Products />}
                isActiveSlug={'/products'}
                compact={isCollapsed}
                showBadgeLabel
              />
              {hasFinances && !isExternalUserLocationExcluded && (
                <MenuItem
                  path='/retailer/finances'
                  title='Wallet'
                  icon={<Landmark />}
                  isActiveSlug='/retailer/finances'
                  compact={isCollapsed}
                />
              )}
            </Fragment>
          )}
          {isRetailer && canViewSourceLedger && (
            <Fragment>
              <MenuItem
                path={'/retailer/patient-orders'}
                title='Orders'
                icon={<Truck />}
                compact={isCollapsed}
              />
              <MenuItem
                path={'/retailer/create-order/'}
                title='New Order'
                icon={<PlusCircle />}
                compact={isCollapsed}
              />
            </Fragment>
          )}
          {retailStockViews && !canViewSourceLedger && (
            <MenuItem
              title='Service'
              path='/retailer/service'
              icon={<Truck />}
              compact={isCollapsed}
            />
          )}
          {
            /* If My profile feature flag is disabled then we need show profile page old way */
          }
          <MenuItem
            path={`/profile`}
            title='My account'
            icon={<User />}
            subItems={!isPSM ? myAccountItems : null}
            onSubitemToggle={() => this.toggleCollapsed()}
            compact={isCollapsed}
          />
          {(isRetailer || isLeadUser) && !canViewSourceLedger && (
            <Fragment>
              <MenuItem
                path={`/retailer/resources`}
                title='Resources'
                icon={<Lightbulb />}
                isActiveSlug={'/retailer/resources'}
                compact={isCollapsed}
              />
              {!isExternalUserLocationExcluded && (
                <MenuItem
                  path='https://fielded.notion.site/Shelf-Life-Retailer-FAQs-67e90d59bceb44cf84de7c8bc3ddb234'
                  title='FAQ'
                  icon={<QuestionMark />}
                  compact={isCollapsed}
                  isExternal
                />
              )}
            </Fragment>
          )}
          {hasInformationSection && <MenuItem
            title='Information'
            path={'/information/guide'}
            icon={<InfoCircle />}
            compact={isCollapsed}
          />}
          {(isRetailer || (isLeadUser && userWithDB)) && <MenuItem
            title='Notifications'
            path={'/retailer/notifications'}
            icon={<Bell />}
            compact={isCollapsed}
          />}
          {((isPlannerOrSales && process.env.NODE_ENV === 'development') || ((isPSM && isFacilityUser))) && <MenuItem
            title='Notifications'
            path={'/notifications'}
            icon={<Bell />}
            compact={isCollapsed}
          />}
          { hasIntercom && (
            <MenuItem
              title='Chat with us'
              icon={<Intercom />}
              compact={isCollapsed}
              isIntercom
            />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

MainMenuItems.propTypes = {
  config: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isPSM: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  isOnline: PropTypes.bool,
  pendingContracts: PropTypes.number
}

export default MainMenuItems
