import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { byId } from '../common/utils'
import { userLocationLabelFromId } from '../../settings/common/utils'
import { getLocationsByLevelOrder } from '../../../common/utils'
import { updateCurrentMasterData } from '../../../van-shared/utils/utils'

const getProcessedMasterData = ({ locations, products, programs, funders }) => {
  const locationsByLevel = getLocationsByLevelOrder(locations)

  // We generate all the search options here to not drop performance
  const locationOptions = locationsByLevel.map(locationId => {
    return { label: userLocationLabelFromId(locationId), value: locationId }
  })
  const funderOptions = funders.map(funder => { return {label: funder.name, value: funder._id} })
  const productOptions = products.map(product => { return {label: product.name, value: product._id} })

  return {
    programs: byId(programs, 'id'),
    products: {
      byId: byId(products, '_id'),
      allIds: products.map(pr => pr._id),
      allProducts: products,
      productOptions
    },
    locations: {
      byId: byId(locations, '_id'),
      allLocations: locations,
      locationOptions
    },
    funders: {
      byId: byId(funders, '_id'),
      funderOptions
    }
  }
}

const initialState = {
  hasReceivedMasterData: false,
  error: null,
  programs: {},
  products: {
    byId: {},
    allIds: [],
    allProducts: [],
    productOptions: []
  },
  locations: {
    byId: {},
    allLocations: [],
    locationOptions: []
  },
  funders: {
    byId: {},
    funderOptions: []
  }
}

export const fetchMasterData = createAsyncThunk('analytics/fetchMasterData', async (args, thunkAPI) => {
  const { programs = [], locationId } = args
  const { extra: { api } } = thunkAPI

  const promises = [
    api.location.listChildren(locationId, { deep: true, includeSelf: true }),
    api.product.listForPrograms(programs),
    api.program.list(),
    api.funders.list({ filterForUser: true })
  ]

  try {
    const response = await Promise.all(promises)
    const [locs, products, programList, funders] = response
    const locations = locs.filter(item => item && !!item.configurations)
    const processedMasterData = getProcessedMasterData({ locations, products, programs: programList, funders })
    return processedMasterData
  } catch (error) {
    return error
  }
})

export const masterDataSlice = createSlice({
  name: 'masterData',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchMasterData.fulfilled, (state, action) => {
        if (Object.keys(action.payload).length) {
          const { programs, products, locations, funders } = action.payload
          state.programs = programs
          state.products = products
          state.locations = locations
          state.funders = funders
          state.hasReceivedMasterData = true
        }
      })
      .addCase(fetchMasterData.rejected, (state, action) => {
        state.error = action.payload
      })
  }
})

export const selectMasterData = (state) => {
  const newState = updateCurrentMasterData(state, 'masterDataAnalytics')
  return newState.masterData
}

export const selectHasError = (state) => {
  const newState = updateCurrentMasterData(state, 'masterDataAnalytics')
  return newState.masterData.error
}

export const selectHasReceivedMasterData = (state) => {
  const newState = updateCurrentMasterData(state, 'masterDataAnalytics')
  return newState.masterData.hasReceivedMasterData
}

export default masterDataSlice.reducer
