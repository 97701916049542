const api = require('./api')
const { EntityApi } = require('../common')
const { wrapEntityApi } = require('../utils/wrap-api')

const rawMethods = {
  save: api.save,
  get: api.get,
  list: api.list
}

const FunderPouchAdapter = require('./data-access/funder-pouch-adapter')

class FundersApi extends EntityApi {
  constructor (state, restAdapter) {
    const { user, locationsDB } = state
    const adapter = new FunderPouchAdapter(user, locationsDB, restAdapter)
    super(adapter)

    // TODO: remove this when all raw methods have been removed
    const apiMethods = wrapEntityApi(rawMethods, state)
    Object.assign(this, apiMethods)
  }
}

module.exports = rawMethods
module.exports.FundersApi = FundersApi
