const { parse } = require('../../../tools/smart-id')
const tools = require('../../tools')
const { getOrder } = require('./get')
const { getSupplierIdsForLocation } = require('../../api/read/get-supplier-ids-for-location')
const { get: getLocation } = require('../../../location/api/read/get')

/*
 * For reading a single topup request
 * based on orderId
 *
 * topup request is an SL thing,
 * where the retailer creates an order doc
 * representing what they want
 */
async function getRequest (
  state,
  { snapshotId,
    orderId,
    asShipments = true,
    useOriginal = false,
    includeZero,
    allowTranslateProducts = true
  }
) {
  if (snapshotId) {
    ({ orderId } = parse(snapshotId))
  }

  const order = await getOrder(state, {
    orderId,
    withSnapshots: true,
    useAcceptedStatus: true
  })

  if (!order) {
    return null
  }

  const { destinationId, orderType } = order
  const destination = await getLocation(state, destinationId)
  const packPoints = await getSupplierIdsForLocation(state, destination, {planType: orderType})
  const defaultPackPoint = packPoints[0]

  if (!asShipments) {
    let orderSnapshot
    if (order.status === 'request') {
      orderSnapshot = order.snapshotsByStatus.request[0]
    } else {
      orderSnapshot = order.snapshotsByStatus.new.length > 1
        ? order.snapshotsByStatus.new.find(order => order.supplierId === defaultPackPoint)
        : order.snapshotsByStatus.new[0]
    }
    return orderSnapshot
  }
  if (useOriginal) {
    // This is used to display differences in purchase orders vs what was received, orderAsShipments read "adjusted" value on the order
    // but here we need the values that the client entered / paid for, so rather than passing down a prop, i just replace "adjusted" with "original"
    Object.values(order.products).forEach(entry => {
      entry.adjusted = entry.original
    })
  }

  return tools.orderAsShipment(state, order, packPoints, includeZero, allowTranslateProducts)
}

exports.getRequest = getRequest
