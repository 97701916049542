import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink, withRouter } from 'react-router-dom'
import { Chevron } from '@fielded/shared-ui/src/icons'
import { StatusLabelButton } from '@fielded/shared-ui'

const MenuItemFolder = ({
  path,
  title,
  icon,
  isOfflineDisabled,
  isCompact,
  location,
  isActiveSlug,
  subItems
}) => {
  // if we enter directly to an url with a slug, then the submenu should be open
  const [showSubItems, setShowSubItems] = useState(location.pathname.startsWith(path))
  const [isHover, setIsHover] = useState(false)
  const initialRender = useRef(true)

  const toggleSubItems = () => {
    if (!isCompact) {
      setShowSubItems(!showSubItems)
    }
  }

  const closeSubItemsIfisCompact = () => {
    if (isCompact) setShowSubItems(false)
  }

  const handleOnMouseEnter = () => {
    if (isCompact) setIsHover(true)
  }

  const handleOnMouseLeave = () => {
    if (isCompact) setIsHover(false)
  }

  // close the submenu when the sidebar collapses / expands
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      setShowSubItems(false)
    }
  }, [isCompact])

  const subItemsFragment = (
    <ul className={classnames(
      'sub-menu',
      {'sub-menu--isCompact': isCompact}
    )}>
      {subItems.map(item => {
        const {
          path,
          label,
          isActiveSlug,
          badgeLabel,
          ...otherProps
        } = item

        return (
          <div onClick={closeSubItemsIfisCompact} key={path}>
            <NavLink
              to={path}
              className='menu-item__sublink'
              activeClassName='menu-item__link--active'
              {...otherProps}
              isActive={(match, { pathname }) => {
                // Find matching subpaths of the subgroup this item is in
                const matchingPaths = subItems.filter(s => pathname.startsWith(s.path))
                if (matchingPaths.length === 0) {
                  return false
                }
                // Sort matches by longest first. The longest match is the best
                const bestMatch = matchingPaths.sort(
                  (a, b) => a.path.length > b.path.length ? -1 : 1
                )[0]
                return path === bestMatch.path
              }}>
              <span className='menu-item__label menu-item__label--smaller'>{label}</span>
              {badgeLabel && <StatusLabelButton label={badgeLabel} status='attention' pointer tight />}
            </NavLink>
          </div>
        )
      })}
    </ul>
  )

  return (
    <div onMouseLeave={handleOnMouseLeave}>
      <div className={classnames('menu-item__folder menu-item__link', {'menu-item__folder--expanded': showSubItems, 'menu-item__link--active': location.pathname.includes(isActiveSlug)})}
        onClick={toggleSubItems}
        onMouseEnter={handleOnMouseEnter}>
        {icon && (
          <span className='menu-item__icon'>{icon}</span>
        )}
        <span className='menu-item__label menu-item__label--canhide'>
          <span className='menu-item__label-text'>
            {title}
          </span>
        </span>
        <div className='menu-item__chevron'>
          <Chevron direction={showSubItems ? 'up' : 'down'} />
        </div>
      </div>

      {showSubItems && subItemsFragment}

      {isHover && (
        <div className='menu-item__popover'>
          <div className='menu-item__popover-content'>
            <div className='menu-item__folder'>
              {icon && (
                <span className='menu-item__icon'>{icon}</span>
              )}
              <span className='menu-item__label'><span className='menu-item__label-text'>{title}</span></span>
            </div>

            {subItemsFragment}
          </div>
        </div>
      )}
    </div>
  )
}

MenuItemFolder.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  isOfflineDisabled: PropTypes.bool,
  isCompact: PropTypes.bool,
  isActiveSlug: PropTypes.bool,
  subItems: PropTypes.array
}

export default withRouter(MenuItemFolder)
