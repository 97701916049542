const get = require('lodash/get')

class Batch {
  constructor (quickbooks) {
    this.quickbooks = quickbooks
  }

  /** Send a batch request
   *
   * The quickbooks batch api allows for up to 30 batch operations. This will partition the
   * operations into sets of 30 and batch send the batches.
   *
   * @param {Object} options
   * @param {string} companyCode
   * @param {{ id: string, operation?: 'create' | 'update', type: string, value: Object }} ops
   */
  async send ({ companyCode, ops }) {
    // Quickbooks allows for max 30 operations in a batch request
    const maxBatchSize = 30
    const url = `batch?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`
    const resultItems = []
    for (let i = 0; i < ops.length; i += maxBatchSize) {
      const batchOps = ops.slice(i, maxBatchSize)
      const reqItems = []
      for (const [i, op] of batchOps.entries()) {
        op.id = op.id || `${i}`
        const item = {
          bId: op.id,
          [op.type]: op.value
        }
        if (op.operation) {
          item.operation = op.operation
        }
        reqItems.push(item)
      }
      const req = { BatchItemRequest: reqItems }
      const resp = await this.quickbooks.quickbooksInterface.post(companyCode, url, req)

      // collect results and errors from response
      const errors = []
      for (const result of get(resp, 'BatchItemResponse', [])) {
        if (result.Fault) {
          errors.push(result)
        } else {
          const op = batchOps.find(op => op.id === result.bId)
          let value
          // Query responses look different than entitiy responses
          if (op.type === 'Query') {
            const queryType = Object.keys(result.QueryResponse)[0]
            value = result.QueryResponse[queryType]
          } else {
            value = result[op.type]
          }
          resultItems.push({
            type: op.type,
            id: result.bId,
            value
          })
        }
      }
      if (errors.length) {
        const err = new Error('Some batch items failed')
        err.quickbooksErrors = errors
        throw err
      }
    }
    return resultItems
  }
}

module.exports = {
  Batch
}
