const PGAdapter = require('./../common/pg-adapter')

class PriceUpdatePGAdapter extends PGAdapter {
  constructor ({ pgConnection, username, logger, priceUpdateEntryAdapter }) {
    const columns = [
      'id',
      'market_id',
      'date',
      'status',
      'floor_threshold',
      'ceiling_threshold',
      'financing_multiplier',
      'delivery_multiplier',
      'created_at',
      'updated_at'
    ]
    const virtualColumns = ['entries']
    const getRelated = {
      entries: {
        relatedTable: 'avocado.data_priceupdateentry',
        pk: 'id',
        fk: 'price_update_id'
      }
    }
    super(
      pgConnection,
      'avocado.data_priceupdate',
      username,
      columns,
      'id',
      {},
      logger,
      { getRelated, virtualColumns }
    )
    this.priceUpdateEntryAdapter = priceUpdateEntryAdapter
  }

  async create (data) {
    const res = await PGAdapter.prototype.create.call(this, data)

    // todo: this ought to be in a transaction so that if one of them fails the others do too
    await this.saveEntries({ ...res, entries: data.entries })

    return res
  }

  async update (data) {
    // publishing saves v_price_calculator_suggestions to price_history
    // presumably the user has reviewed these and thinks they're good to go so we'll pubish them verbatim
    // if there are further updates to the priceupdate made in the same call, which would not be reflected
    // in the suggestions without a refresh (takes long), we'll discard those so as to avoid the confusion
    // of a price update record which doesn't match published prices, with the exception of DATE which will
    // almost certainly screw up planners every round, and doesn't affect the prices.
    // In the future, separating thepublish and update steps in the UI would be good
    const oldObj = await this.getOne(data.id)
    if (oldObj.status === 'draft' && data.status === 'published') {
      const publishResults = await this.publishPrices(data.id)
      if (!publishResults) {
        return publishResults
      }
      return PGAdapter.prototype.update.call(this, {
        ...oldObj,
        status: 'published',
        date: data.date
      })
    }
    // if unpublishing, set the related prices (sku, date) to null
    // so that they have a chance to sync to FS
    if (oldObj.status === 'published' && data.status === 'draft') {
      let res = await this.unPublishPrices(data.id)
      if (res) {
        res = await PGAdapter.prototype.update.call(this, {
          ...oldObj,
          status: 'draft'
        })
      }
      return res
    }

    const res = await PGAdapter.prototype.update.call(this, data)

    // todo: this ought to be in a transaction so that if one of them fails the others do too
    if (res) {
      await this.saveEntries({ ...res, entries: data.entries })
    }

    return res
  }

  async upsert (data) {
    const oldObj = await this.getOne(data.id)
    const res = await PGAdapter.prototype.upsert.call(this, data)

    // todo: this ought to be in a transaction so that if one of them fails the others do too
    const entryRes = await this.saveEntries({ ...res, entries: data.entries })

    const newObj = await this.getOne(data.id)
    if (
      res &&
      entryRes &&
      oldObj.status === 'draft' &&
      newObj.status === 'published'
    ) {
      await this.publishPrices(data.id)
    }
    return res
  }

  async saveEntries (data) {
    // delete existing price entries and create new ones from data each time
    if (!data.id) {
      throw new Error(
        'Cannot save price update entries for an unsaved price update'
      )
    }

    await this.priceUpdateEntryAdapter.clearEntriesForPriceUpdate(data)
    // apply current priceupdateid to these entries
    const entries = (data.entries || []).map((e) => ({
      ...e,
      price_update_id: data.id
    }))
    const res = await this.priceUpdateEntryAdapter.create(entries)

    return res
  }
  async unPublishPrices (id) {
    this.logger.debug('unpublishing prices')
    return this.pgConnection.query(
      `
      update real.price_history
          set sellprice = null
      where id in (
          select
              ph.id
          from real.price_history ph 
          inner join summaries.v_price_calculator_suggestions vs on ph.date = vs.date and ph.sku = vs.sku
          where price_update_id = $1)`,
      [id]
    )
  }

  async publishPrices (id) {
    if (id) {
      this.logger.debug('clearing cached values')
      await this.pgConnection.query(`
        UPDATE real.price_history
          SET sellprice = null
        WHERE id in (
          SELECT
              ph.id
          FROM real.price_history ph 
          INNER JOIN summaries.v_price_calculator_suggestions vs ON ph.date = vs.date AND ph.sku = vs.sku
          WHERE price_update_id = $1);
        `, [id]
      )
      const responses = Promise.all([
        this.pgConnection.query(`
          INSERT INTO real.price_history (
              id, 
              sku, 
              date, 
              month, 
              buyprice, 
              sellprice,
              markup
          )
          SELECT 
              uuid_generate_v4() as id,
              s.sku,
              u.date,
              date_trunc('month', u.date) as month,
              null as buyprice,
              s.suggested_sellprice as sellprice,
              s.markup
          FROM summaries.v_price_calculator_suggestions s
          LEFT JOIN avocado.data_priceupdate u ON s.price_update_id = u.id
          WHERE 
            s.price_update_id = $1
          ON CONFLICT (sku,date) DO UPDATE
          SET
            sellprice = EXCLUDED.sellprice;
        `, [id]),
        this.pgConnection.query(`
          INSERT INTO avocado.data_markupmultiplierhistory (
            created_at,
            updated_at,
            date,
            market_id,
            financing_multiplier,
            delivery_multiplier
          ) SELECT
              CURRENT_TIMESTAMP,
              CURRENT_TIMESTAMP,
              date,
              market_id,
              financing_multiplier,
              delivery_multiplier
            FROM avocado.data_priceupdate
              WHERE id = $1
            ON CONFLICT (market_id,date) DO UPDATE 
            SET 
              updated_at = CURRENT_TIMESTAMP,
              financing_multiplier = EXCLUDED.financing_multiplier,
              delivery_multiplier = EXCLUDED.delivery_multiplier;
        `, [id])
      ])
      return responses
    }
  }

  async getChanges (date, since) {
    let query = `
      SELECT * FROM avocado.data_priceupdate 
      WHERE updated_at > $1
      AND status = 'published'
      AND created_at > $2
      ORDER BY updated_at ASC
    `
    const { rows } = await this.pgConnection.query(query, [date, since])
    return rows
  }
}

module.exports = {PriceUpdatePGAdapter}
