const { prepareBatchParams, toBatchShape } = require('../tools')

// Called by the NHLMIS (PSM) REST API
module.exports = getOrListBatch
async function getOrListBatch (fsApi, queryParams, alias) {
  const aliasOptions = alias !== 'none'
    ? {resolveAlias: {[alias]: true}}
    : {}
  const filters = prepareBatchParams(queryParams)
  const resP = Object.values(filters).filter(Boolean).length
    ? fsApi.batch.get(filters, aliasOptions)
      .then((batch) => [batch])
      .catch((_) => {
        // this likely means there is no parent product
        // for the requested batch
        return []
      })
    : fsApi.batch.list()

  const res = await resP

  const batches = res.map((b) => toBatchShape(b, alias))
  return batches
}
