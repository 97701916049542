const get = require('lodash/get')

const Roles = {
  DBPerUser: 'db-per-user',
  UserDBSyncUser: 'user-db-sync:user'
}

const hasOwnDatabase = (user = {}) => {
  const roles = get(user, 'roles', [])
  const re = new RegExp(`^(feature:)?((${Roles.DBPerUser})|(${Roles.UserDBSyncUser}))$`)
  return roles.some(r => re.test(r))
}

module.exports = {
  Roles,
  hasOwnDatabase
}
