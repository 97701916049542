exports.listAll = function (state) {
  return state.routesDB.allDocs({
    startkey: 'route:',
    endkey: 'route:\ufff0:',
    include_docs: true
  })
    .then(results => results.rows.filter(row => !row.error).map(row => row.doc))
}

exports.get = function (state, id) {
  return state.routesDB.get(id)
}
