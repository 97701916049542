import React, { Component, Fragment } from 'react'
import get from 'lodash/get'

import { PricePromotion, PrintTemplate, SignatureDisplay } from '@fielded/shared-ui'
import { getCurrencySymbol } from '@fielded/shared-ui/src/components/PriceDisplay'

import { removeTime } from '../common/periods'
import { pluralize } from '../van-shared/utils'
import { hasFeature } from '../van-shared/utils/features'

import DeliveryNoteTable from './DeliveryNoteTable'

import {
  getInvoiceConfirmationRows
} from '../subapps/reports/confirmation/confirmationUtils'

const CR_REMARK_PREFIX = 'c&r_invoice '

const getTableHeaders = (currency, showVAT, appName) => {
  return [
    { value: 'Code', name: 'code' },
    { value: 'Products', name: 'products' },
    { value: `${appName.toUpperCase()} QTY SOLD`, name: 'quantity-sold' },
    { value: `UNIT PRICE (${currency})`, name: 'unit-price' },
    showVAT && { value: `VAT (${currency})`, name: 'vat' },
    { value: `TOTAL (${currency})`, name: 'total' }
  ].filter(x => x)
}

export const getTableRows = (
  data = [],
  showVAT,
  country,
  // This abstracts the product units to be shown on screen, may them be sold or retained units
  consumedFieldProperty = 'fields.field:shelflife-sold.amount',
  isApplicableRow
) => {
  // isApplicableRow will filter rows to be displayed to the user
  // A custom isApplicableRow filter can be provided, default one just checks the row belongs to an actual sale
  // by checking provided consumedFieldProperty variable
  if (!isApplicableRow) {
    isApplicableRow = item => {
      const consumedUnits = get(item, consumedFieldProperty, 0)
      const sob = get(item, 'fields.field:standard-opening-balance.amount', 0)
      const spc = get(item, 'fields.field:standard-physical-count.amount', 0)
      const osb = get(item, 'fields.field:opening-shelflife-balance.amount', 0)
      const sas = get(item, 'fields.field:sales-adjustments.amount', 0)
      const adjusted = get(item, 'adjusted', false)
      const mergedReport = get(item, 'mergedReport', false)

      const hasIssue = spc > sob

      if (mergedReport) {
        return ((!hasIssue && consumedUnits !== 0) || (hasIssue && sas > 0 && adjusted))
      }

      return ((!hasIssue && consumedUnits !== 0) || (hasIssue && osb > 0))
    }
  }

  return data.filter(item => {
    const { isProductLine, isTotal } = item

    if (isProductLine || isTotal) {
      return false
    }

    return true
  }).filter(isApplicableRow)
    .map((item, idx) => {
      const name = get(item, 'name', '-')
      const basicUnit = get(item, 'basicUnit', '-')
      const fullName = `${name} – ${basicUnit}`

      const fields = get(item, 'fields', {})

      // standard
      const sob = get(item, 'fields.field:standard-opening-balance.amount', 0)
      const spc = get(item, 'fields.field:standard-physical-count.amount', 0)

      // other
      const opb = get(item, 'fields.field:opening-partner-balance.amount', 0)
      const osb = get(item, 'fields.field:opening-shelflife-balance.amount', 0)
      const pb = get(item, 'fields.field:partner-balance.amount', 0)
      const mergedReport = get(item, 'mergedReport', false)
      const sas = get(item, 'fields.field:sales-adjustments.amount', 0)
      const hasAdjustment = get(item, 'adjusted', false)
      const sls = get(item, consumedFieldProperty, 0)
      const price = get(item, 'fields.field:price.price', 0)
      const vat = get(item, 'fields.field:unit-vat.vat', '-')
      const totalSold = get(item, 'fields.field:sold.price', 0)

      const hasPartnerBalance = (opb > 0) || (pb > 0)
      const hasWarning = spc > sob

      let sl = sls
      let total = totalSold

      // If we have generated ooc selloff for this product use that instead
      if (hasWarning && mergedReport && sas > 0) {
        sl = sas
      }

      // If we also have count with sales adjustment due to e.g CnR retained stock, then use adjustment qty
      if (hasAdjustment && mergedReport && sas > 0) {
        sl = sas
      }

      if (hasWarning && !mergedReport) {
        sl = osb
        total = osb * price
      }

      return {
        key: `row.${idx}`,
        cols: [{
          name: 'code',
          value: item.id.split(':')[1]
        }, {
          name: 'products',
          value: fullName
        }, {
          name: 'shelflife-sold',
          value: sl
        }, {
          name: 'price',
          value: <PricePromotion value={price} country={country} />
        }, showVAT && {
          name: 'vat',
          value: <PricePromotion value={vat} country={country} />
        }, {
          name: 'sold',
          value: <PricePromotion value={total} country={country} />
        }].filter(x => x),
        name: fullName,
        fields,
        hasPartnerBalance,
        hasWarning
      }
    })
}

const getRetainedTableRows = (data = [], showVAT, country) => {
  const consumedFieldProperty = 'fields.field:sales-adjustments.amount'
  const isApplicableRow = item => {
    const sr = get(item, 'fields.field:standard-remark.amount', '')
    return sr.startsWith(CR_REMARK_PREFIX)
  }
  return getTableRows(data, showVAT, country, consumedFieldProperty, isApplicableRow)
}

export const getTableTotal = (data = []) => {
  const element = data.find(item => item.isTotal)
  const { name, total } = element

  return {
    name,
    total
  }
}

class SalesStatement extends Component {
  render () {
    const {
      config,
      location,
      serviceId,
      report,
      productsById
    } = this.props

    const country = get(location, 'location.country')
    const currency = getCurrencySymbol({country})

    const showVAT = hasFeature(config.features, `showVAT.${get(location, 'location.country')}`)

    const locationDetails = get(report, 'location.state')

    const date = report.submittedAt
    const appName = config.name

    const headers = getTableHeaders(currency, showVAT, appName)

    const salesRows = getInvoiceConfirmationRows({report, productsById, serviceId})
    const salesTableRows = getTableRows(salesRows, showVAT, country)
    const numProducts = salesRows.filter(({isProductLine, isTotal}) => !isProductLine && !isTotal).length
    const numAdditionalProducts = numProducts - salesTableRows.length

    // get total for the sales table
    let total = getTableTotal(salesRows).total

    let retainedTableRows = []
    if (report.partialCount) {
      const retainedRows = getInvoiceConfirmationRows({report, productsById, serviceId, soldFieldName: 'field:sales-adjustments'})
      retainedTableRows = getRetainedTableRows(retainedRows, showVAT, country, 'fields.field:sales-adjustments.amount')

      // get total for the retained products table
      total += getTableTotal(retainedRows).total
    }

    // checks if there are out of contract buyings
    const rowsWithWarnings = salesTableRows.filter(row => row.hasWarning).length
    const hasOOCWarning = rowsWithWarnings > 0
    const productsString = pluralize('product', rowsWithWarnings)

    return (
      <div className='sales-statement'>
        <PrintTemplate>
          <PrintTemplate.Header
            title='Sales statement'
            config={config}
          />
          {(salesTableRows.length > 0 || retainedTableRows.length === 0) &&
          <PrintTemplate.Box
            left={<div>As a result of stock count conducted on <strong>{removeTime(date)}</strong>, the following sales of {appName} products were recorded. An invoice will be sent shortly.</div>}
            right={<div><div><strong>{location.fullName}</strong></div>{location.additionalData.physicalAddress}</div>}
          />}
          {(salesTableRows.length > 0 || retainedTableRows.length === 0) &&
          <PrintTemplate.Section withOverflow>
            {salesTableRows.length > 0 &&
            <DeliveryNoteTable
              headers={headers}
              rows={salesTableRows}
              currency={currency}
              country={country}
              theme='sales'
              showVAT={showVAT}
            />}
            {salesTableRows.length === 0 && retainedTableRows.length === 0 &&
            <div className='sales-statement__divider'>There are no {appName} sales recorded on this stock count</div>}
          </PrintTemplate.Section>}
          {hasOOCWarning && <PrintTemplate.Section>
            <div className='sales-statement__warning sales-statement__warning--danger'>
              <strong>{ rowsWithWarnings } { productsString } have been transferred to your balance and added to your invoice due to out of contract buying.</strong>
            </div>
          </PrintTemplate.Section>}

          <PrintTemplate.Section>
            <div className='sales-statement__divider'>You are subscribed to an additional <strong>{ numAdditionalProducts } products</strong> that have no recorded sales.</div>
          </PrintTemplate.Section>

          {retainedTableRows.length > 0 &&
          <Fragment>
            <div className='vs-u-margin-top-tripple'>
              <PrintTemplate.Section title='Retained products'>
                <div className='sales-statement__retained-description'>
                  <PrintTemplate.Box
                    left={<div>The following products were not returned to {appName} at the collection on <strong>{removeTime(date)}</strong> and will be added to your invoice.</div>}
                    right={(!salesTableRows.length && retainedTableRows.length > 0) && <div><div><strong>{location.fullName}</strong></div>{location.additionalData.physicalAddress}</div>}
                  />
                </div>
              </PrintTemplate.Section>
            </div>
            <PrintTemplate.Section withOverflow>
              <DeliveryNoteTable
                headers={headers}
                rows={retainedTableRows}
                currency={currency}
                country={country}
                theme='sales'
                showVAT={showVAT} />
            </PrintTemplate.Section>
          </Fragment>}
          {(salesTableRows.length > 0 || retainedTableRows.length > 0) &&
            <PrintTemplate.Section withOverflow>
              <DeliveryNoteTable
                headers={[]}
                rows={[]}
                currency={currency}
                country={country}
                total={total}
                totalRemark={false}
                theme='sales'
                showVAT={showVAT}
              />
            </PrintTemplate.Section>}

          <PrintTemplate.Section>
            <div className='sales-statement__record'>
            This total is a record of sales as at <strong>{removeTime(date)}</strong>. This total does not include
            service charges or any outstanding balances.
            </div>
          </PrintTemplate.Section>
          {report.signed && (
            <PrintTemplate.Section>
              <SignatureDisplay
                signature={report.signed.signature}
                name={report.signed.name}
              />
            </PrintTemplate.Section>
          )}
          <PrintTemplate.Footer location={locationDetails} />
        </PrintTemplate>
      </div>
    )
  }
}

export default SalesStatement
