module.exports = docsToShipmentRecords

const get = require('lodash/get')
const docToVanRecord = require('./doc-to-van-record')
const toShipmentId = require('./to-shipment-id')
const toDocIdProperties = require('./to-doc-id-properties')
const toLocationProperties = require('./to-location-properties')
const pickWinningSnapshotRecord = require('./pick-winning-snapshot-record')
const updatedCounts = require('./updated-counts')
const draftAdjustmentId = require('./draft-adjustment')
const PLANNING_TYPES = require('./planning-types')
const { DOC_TYPE: COLLECTION_TYPE } = require('../shipment-save-collection-stats')
const pick = require('lodash/pick')
const omitBy = require('lodash/omitBy')
const isNil = require('lodash/isNil')

const { ORDER_SHIPMENT_SHARED_PROPS } = require('../../order/constants')

function pickDefinedProps (obj, propNames) {
  const props = pick(obj, propNames)
  return omitBy(props, isNil) // return defined picked props
}

function docsToShipmentRecords (locationId, docs, options = {}) {
  const {
    useLocationAsOrigin,
    pendingAdjustments,
    user,
    checkSnapShotLocation
  } = options

  const snapshotsByShipment = docs
    .filter(doc => doc.type === 'snapshot')
    .map(doc => docToVanRecord(doc, getLocation(doc, locationId, useLocationAsOrigin), user))
    .reduce(indexByShipment, {})

  const changeSnapshots = docs.filter(doc => doc.type === 'change')
  const voidSnapshots = docs.filter(doc => doc.type === 'snapshot-void')
  const commentSnapshots = docs.filter(doc => doc.type === 'snapshot-comment')
  const otpSnapshots = docs.filter(doc => doc.type === 'otp')

  // not expected to have more than one of these,
  // but could happen due to bugs
  const collectionDoc = docs
    .filter(doc => doc.type === COLLECTION_TYPE)[0]

  const result = []
  for (let shipmentId in snapshotsByShipment) {
    const snapshots = snapshotsByShipment[shipmentId]
    const winningSnapshot = pickWinningSnapshotRecord(snapshots, locationId, checkSnapShotLocation)
    // If there is no winning snaphot
    // For ex: a destination location has no arrived or received snapshot yet, continue
    if (!winningSnapshot) {
      continue
    }

    // skip make shipment when any of the snapshot contains a void doc,
    // irrespective of the status, since this is only allowed to take place
    // for new shipments.
    const isVoid = !!voidSnapshots.find(doc => doc._id.indexOf(shipmentId) === 0)
    if (isVoid) {
      continue
    }
    let created
    // We check both new and sent status because we could have just the new status snapshot and other times, sent, arrived, and received status while excluding the new status snapshot.
    const initialSnapshot = snapshots.find(snapshot => snapshot.status === 'new' || snapshot.status === 'sent')
    // external arrival will only have received/arrived status, so initialSnapshot variable will resolve as null
    if (initialSnapshot) {
      created = {
        createdAt: initialSnapshot.createdAt,
        createdBy: initialSnapshot.createdBy
      }
    } else {
      created = {
        createdAt: winningSnapshot.createdAt,
        createdBy: winningSnapshot.createdBy
      }
    }
    const snapshotDates = getSnapshotDates(snapshots)

    const changes = changeSnapshots
      .filter(doc => {
        const belongsToWinningSnapshot =
          doc._id.indexOf(winningSnapshot.id) === 0 ||
          // draft adjustment ids are local docs so they have special rules:
          draftAdjustmentId(winningSnapshot.id) === doc._id
        return belongsToWinningSnapshot
      })
      .map(doc => docToVanRecord(doc, locationId))
      .map(toChangesItem)

    const comments = commentSnapshots
      .filter(doc => doc._id.indexOf(shipmentId) === 0)
      .map(toCommentItem)

    const shipmentType = winningSnapshot.shipmentType || {}
    const planningType = winningSnapshot.planningType || PLANNING_TYPES.DEFAULT

    const record = {
      ...pickDefinedProps(winningSnapshot, [ // This ensures only copying defined properties
        'rev',
        'shipmentNo',
        'origin',
        'destination',
        'status',
        'statusType',
        'isAutomaticReturnShipment',
        'scheduledDate',
        'programId',
        'shouldReturnProducts',
        'orderId',
        ...ORDER_SHIPMENT_SHARED_PROPS
      ]),
      funderId: winningSnapshot.funderId,
      routeId: winningSnapshot.routeId,
      planningType,
      createdAt: created.createdAt,
      createdBy: created.createdBy,
      updatedAt: winningSnapshot.createdAt,
      updatedBy: winningSnapshot.createdBy,
      // HEADS UP, Why are we creating "funder" and "funderId" props, are both
      // needed? can it be refactored?
      funder: winningSnapshot.funderId,
      counts: updatedCounts(winningSnapshot, changes),
      date: winningSnapshot.scheduledDate ? winningSnapshot.scheduledDate : winningSnapshot.date,
      snapshotId: winningSnapshot.id,
      id: shipmentId,
      history: snapshots.reduce(byId, {}),
      changes,
      comments,
      shipmentType,
      snapshotDates,
      orderId: initialSnapshot ? initialSnapshot.orderId : winningSnapshot.orderId
    }

    if (winningSnapshot.vendorId) {
      record.vendorId = winningSnapshot.vendorId
    }

    if (winningSnapshot.scheduledDate) {
      record.previousScheduledDates = winningSnapshot.previousScheduledDates
    }

    if (collectionDoc) {
      record.collection = collectionDoc.collection
    }

    if (pendingAdjustments) {
      // When we have adjustments (changes after 'received' state), it's useful to see what's in the draft adjustment doc:
      record.pendingAdjustments = toPendingAdjustments(changes.filter(c => c.draft)[0], record.counts)
    }

    if (winningSnapshot.status === 'received') {
      const driverName = get(winningSnapshot, 'createdBy.user', '')
      record.driverName = driverName
    }

    if (typeof (winningSnapshot.isGpsAuthenticated) === 'boolean') {
      record.isGpsAuthenticated = winningSnapshot.isGpsAuthenticated
    }

    if (winningSnapshot.gpsValidationFailComment) {
      record.gpsValidationFailComment = winningSnapshot.gpsValidationFailComment
    }

    // Add otp key and password from snapshots to record
    const otpSnaps = otpSnapshots.filter(doc => doc._id.indexOf(shipmentId) === 0)
    const otpKeySnap = otpSnaps.find(s => s.otpKey)
    const otpPassSnap = otpSnaps.find(s => s.otpPassword)
    const otpFailSnap = otpSnaps.find(s => s.otpUserFailReason)
    if (otpKeySnap || otpPassSnap || otpFailSnap) {
      const key = otpKeySnap && otpKeySnap.otpKey
      const password = otpPassSnap && otpPassSnap.otpPassword
      const userFailReason = otpFailSnap && otpFailSnap.otpUserFailReason
      record.otp = {
        key,
        password,
        userFailReason,
        hasKeyAndPassword: Boolean(key && password)
      }
    }

    result.push(record)
  }
  return result
}

function indexByShipment (index, snapshot) {
  const shipmentId = toShipmentId(toDocIdProperties(snapshot.id))
  if (!index[shipmentId]) {
    index[shipmentId] = []
  }
  index[shipmentId].push(snapshot)
  return index
}

function byId (index, snapshot) {
  index[snapshot.id] = toHistoryItem(snapshot)
  return index
}

function toCommentItem ({
  _id,
  _rev,
  createdAt,
  createdBy,
  comment,
  signature,
  name
}) {
  const commentItem = {
    id: _id,
    rev: _rev,
    createdAt,
    createdBy
  }
  if (comment) {
    commentItem.comment = comment
  }
  if (signature) {
    commentItem.signature = signature
  }
  if (name) {
    commentItem.name = name
  }
  return commentItem
}

function toHistoryItem (snapshot) {
  const historyItem = {
    createdAt: snapshot.createdAt,
    createdBy: snapshot.createdBy,
    counts: snapshot.counts
  }
  if (snapshot.parentDocId) {
    historyItem.parentDocId = snapshot.parentDocId
  }
  return historyItem
}

function toChangesItem (change) {
  const draft = /_local/.test(change.id)
  return {
    draft,
    effectiveAt: change.effectiveAt,
    createdAt: change.createdAt,
    updatedAt: change.updatedAt,
    createdBy: change.createdBy,
    changes: change.changes,
    clock: change.clock
  }
}

function toPendingAdjustments (change, counts) {
  if (!change) {
    return null
  }

  return Object.keys(change.changes).map(batchId => {
    const shipmentVal = get(counts, `${batchId}.quantity`, 0)
    const changeVal = get(change, `changes.${batchId}.quantity`, 0)

    if (changeVal === 0) {
      return null
    }

    return {
      batchId,
      previous: shipmentVal - changeVal,
      updated: shipmentVal
    }
  })
    .filter(x => x)
}

function getSnapshotDates (snapshots) {
  /*
   * Sometimes a shipment has been marked as received 2x
   * and it's important to make a consistent pick
   * (allDocs or views gets the snapshot docs in different order)
   * i went with always going for the earlier one
   */
  return snapshots.reduce((memo, snapshot) => {
    if (!memo[snapshot.status] || memo[snapshot.status] > snapshot.createdAt) {
      memo[snapshot.status] = snapshot.createdAt
    }
    return memo
  }, {})
}

// check if to use the doc origin as the user location id
// or stick with the user location, it can only be allowed
// for new snapshots
function getLocation (doc, locationId, useLocationAsOrigin) {
  const {
    origin,
    status
  } = toDocIdProperties(doc._id)

  const originProps = toLocationProperties(origin)
  return status === 'new' && useLocationAsOrigin ? originProps.id : locationId
}
