import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export const COLOR_VARIANTS = {
  ATTENTION: 'attention',
  BRAND: 'brand',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  WARNING: 'warning'
}

const ProgressBar = ({
  percentage,
  provisionalPercentage,
  colorVariant,
  styleVariant,
  isReadonly,
  size,
  className
}) => {
  const {
    ATTENTION,
    BRAND,
    SECONDARY,
    SUCCESS,
    WARNING
  } = COLOR_VARIANTS

  const hasWarning = colorVariant === WARNING

  return (
    <div className={
      classnames(
        'vs-progress-bar',
        {'vs-progress-bar--pill': styleVariant === 'simple'},
        {'vs-progress-bar--decorated': styleVariant === 'decorated'},
        {'vs-progress-bar--readonly': isReadonly},
        {'vs-progress-bar--size-small': size === 'small'},
        {'vs-progress-bar--size-medium': size === 'medium'},
        className
      )
    }>
      <div className='vs-progress-bar__bar' />
      <div
        className={classnames(
          'vs-progress-bar__progress',
          {'vs-progress-bar__progress--color-brand': colorVariant === BRAND},
          {'vs-progress-bar__progress--color-secondary': colorVariant === SECONDARY},
          {'vs-progress-bar__progress--color-attention': colorVariant === ATTENTION},
          {'vs-progress-bar__progress--color-success': colorVariant === SUCCESS},
          {'vs-progress-bar__progress--color-warning': hasWarning}
        )}
        style={{transform: `scaleX(${percentage})`}}
      />
      {provisionalPercentage && (
        <div
          className={classnames(
            'vs-progress-bar__progress',
            'vs-progress-bar__progress--color-provisional',
            {'vs-progress-bar__progress--color-provisional-warning': hasWarning}
          )}
          style={{transform: `scaleX(${provisionalPercentage})`}}
        />
      )}
    </div>
  )
}

ProgressBar.propTypes = {
  /**
   * 0-1 (including decimals) how much of progressbar should be filled
   * */
  percentage: PropTypes.number,
  /**
   * Color variant, e.g. based on the role it has in the interface
   */
  colorVariant: PropTypes.oneOf(['brand', 'secondary', 'attention', 'success', 'warning']),
  /**
   * Style variant
   */
  styleVariant: PropTypes.oneOf(['simple', 'decorated']),
  /**
   * If the bar should get the readonly styling
   */
  isReadonly: PropTypes.bool,
  /**
   * Height of progressbar
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Optional className for further styling
   */
  className: PropTypes.string
}

ProgressBar.defaultProps = {
  percentage: 0,
  colorVariant: COLOR_VARIANTS.BRAND,
  styleVariant: 'simple',
  size: 'small',
  className: ''
}

export default ProgressBar
