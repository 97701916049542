const sortBy = require('lodash/sortBy')
const get = require('lodash/get')
module.exports = pickWinningSnapshotRecord

/*
When getting the winning snapshot, if checkSnapshotLocation is set to true
We check if the location is the origin or destination of the shipment snapshot
If location is the origin, we only take new, packed, sent and cancelled snapshots
If the location is the destination, we only use arrived, received and cancelled snapshots
shipment status is ordered by the lifecycle stages from the end of the chain to the beginning
*/
function pickWinningSnapshotRecord (snapshots, locationId, checkSnapShotLocation) {
  const byStatus = snapshots.reduce((all, snapshot) => {
    all[snapshot.status] = all[snapshot.status] || []
    all[snapshot.status].push(snapshot)
    return all
  }, {})

  let items = []
  if (!checkSnapShotLocation) {
    items = byStatus['cancelled'] || byStatus['received'] || byStatus['arrived'] || byStatus['sent'] || byStatus['packed'] || byStatus['new'] || byStatus['pre-advice'] || byStatus['in-process'] || byStatus['request']
  }

  const isOrigin = get(snapshots, `[0].origin.id`) === locationId
  const isDestination = get(snapshots, `[0].destination.id`) === locationId

  if (checkSnapShotLocation && isOrigin) {
    items = byStatus['cancelled'] || byStatus['sent'] || byStatus['packed'] || byStatus['new'] || byStatus['pre-advice'] || byStatus['in-process'] || byStatus['request']
    // Exception where we translate a 'received' snapshot to 'sent' if we don't find any
    if (!byStatus['sent'] && byStatus['received']) {
      items = byStatus['received'].map(translateToSent)
    }
  }

  if (checkSnapShotLocation && isDestination) {
    items = byStatus['cancelled'] || byStatus['received'] || byStatus['arrived'] || byStatus['in-process'] || byStatus['request']
  }

  // If there's more than one snapshot of this status,
  // return the earliest one
  return sortBy(items, ['createdAt'])[0]
}

function translateToSent (snapshot) {
  // TODO: add translation of SKUS if cross-territory
  return Object.assign({}, snapshot, {
    _id: snapshot.id.replace(':received:', ':sent:'),
    status: 'sent'
  })
}
