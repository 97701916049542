import React, { Component } from 'react'
import classnames from 'classnames'

import { Page } from '@fielded/shared-ui'

import InventoryStatement from './InventoryStatement'
import SalesStatement from './SalesStatement'
import DeliveryStatement from './DeliveryStatement'
import DeliveryStatementPSM from './DeliveryStatementPSM'
import StatementsSummary from './StatementsSummary'

class Statements extends Component {
  render () {
    const {
      config,
      location,
      service,
      report,
      shipment,
      productsById,
      fieldsById,
      showInventory,
      showSales,
      showDelivery,
      showSummary,
      showPSMDelivery,
      paymentType,
      className,
      user,
      order
    } = this.props

    const serviceId = service ? service.id : null

    return (
      <Page
        className={classnames(
          'page-print',
          className
        )}
      >
        { showSummary && (
          <StatementsSummary
            location={location}
            report={report}
            serviceId={serviceId}
            productsById={productsById}
            shipment={shipment}
            showInventory={showInventory}
            showSales={showSales}
            showSummary={showSummary}
            showDelivery={showDelivery}
            config={config}
            order={order}
          />
        )}
        { showInventory && (
          <InventoryStatement
            report={report}
            location={location}
            fieldsById={fieldsById}
            serviceId={serviceId}
            productsById={productsById}
            user={user}
            config={config}
          />
        )}
        { showSales && (
          <SalesStatement
            config={config}
            report={report}
            location={location}
            fieldsById={fieldsById}
            serviceId={serviceId}
            productsById={productsById}
          />
        )}
        { showDelivery && (
          <DeliveryStatement
            config={config}
            shipment={shipment}
            productsById={productsById}
            location={location}
            serviceId={serviceId}
            paymentType={paymentType}
            order={order}
          />
        )}

        { showPSMDelivery && (
          <DeliveryStatementPSM
            config={config}
            shipment={shipment}
            productsById={productsById}
            location={location}
          />
        )}
      </Page>
    )
  }
}

export default Statements
