module.exports = validateService

function validateService (service) {
  if (!service.id) throw new Error('Property \'id\' is required')
  if (typeof service.id !== 'string' || !service.id.match(/^program:.*service:/)) {
    throw new Error('Property \'id\' must start with \'program:\'')
  }
  if (!service.name) throw new Error('Property \'name\' is required')
  if (!service.fullName) throw new Error('Property \'fullName\' is required')
}
