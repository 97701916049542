import orderBy from 'lodash/orderBy'
import { SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS_FOR_DRIVER } from '@fielded/fs-api/lib/shipment/constants'
import { addServiceDetails } from '../../../../retailer/shipments/helpers'

export const getSortedDeliveries = (deliveries, user) => {
  return orderBy(
    deliveries.map(delivery => {
      delivery = {
        ...addServiceDetails(user, delivery),
        serviceDetails: {
          ...delivery.serviceDetails,
          status: SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS_FOR_DRIVER[delivery.status],
          url: `/shipments/facility/deliveries/${delivery.snapshotId}`,
          withODProducts: null,
          withPAYSProducts: null
        }
      }
      return delivery
    }), ['date'], ['desc']
  )
}
