import React from 'react'
import PropTypes from 'prop-types'
import ThresholdColor from '../ThresholdColor'

const analyticsToThresholdCode = {
  alert: 'critical',
  warning: 'ok',
  ok: 'good',
  excessive: 'extra',
  noData: 'noData'
}

const IndicatorColor = ({ colorCode, ...otherProps }) => {
  const mappedColorCode = analyticsToThresholdCode[colorCode]
  // !colorCode => noData
  if (mappedColorCode || !colorCode) {
    return (
      <ThresholdColor
        colorCode={mappedColorCode || 'noData'}
        {...otherProps}
      />
    )
  }

  // Custom color
  return (
    // Custom color, i.e. not a DS treshold color
    // as needed in analytics expiry indicator
    <div className={`indicator-color indicator-color--${colorCode}`} />
  )
}

IndicatorColor.propTypes = {
  /**
   * Analytics color code for the status, differs from generic threshold color codes. They are same as threshhold keys from utils, but the Storybook doesn't generate a correct select options when array is passed from constant
   */
  colorCode: PropTypes.oneOf(['alert', 'warning', 'ok', 'excessive', 'noData', null]).isRequired,
  /**
   * How much of the item should be filled with the indicator color.
   */
  fillWidth: PropTypes.number
}

IndicatorColor.defaultProps = {
  fillWidth: 100
}

export default IndicatorColor
