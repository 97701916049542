import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import DebouncedInput from '../DebouncedInput'
import { Close } from '../../icons'

/**
 *  Filter field with debounced input and clearing option
 */
class FilterInput extends React.Component {
  static propTypes = {
    /**
     * Field name (if not filter)
     */
    name: PropTypes.string,

    /**
     * set the placeholder, to indicate input type to user.
     */
    placeholder: PropTypes.string,

    /**
     * Align value on the right side
     */
    textAlign: PropTypes.oneOf(['left', 'right']),

    /**
     * the filter value, if controlled by parent component
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /** runs on input changes, returns the value (not the event)  */
    onValueChange: PropTypes.func,

    /** runs on input clear  */
    onClear: PropTypes.func,

    /**
     * hides the clear button if there is not text in the input field
     */
    autoHideClearButton: PropTypes.bool,

    /**
     * set to true of this field should be marked as having issues
     */
    hasError: PropTypes.bool,

    /**
     * Custom class name, to be used for e.g. sibling selector styling based on focus
     */
    className: PropTypes.string
  }

  static defaultProps = {
    name: 'filter',
    placeholder: 'Filter …',
    textAlign: 'left',
    value: undefined,
    onValueChange: () => null,
    onClear: () => null,
    hasError: false,
    autoHideClearButton: false,
    className: undefined
  }

  state = {
    value: this.props.value
  }

  clear = () => {
    this.updateFilter('')
    this.props.onClear()
  }

  updateFilter = (value) => {
    this.setState(
      {
        value
      },
      () => this.props.onValueChange(value)
    )
  }

  render () {
    const {
      name,
      value, // block from otherProps
      onValueChange, // block from otherProps
      onClear,
      disabled,
      className,
      autoHideClearButton,
      ...otherProps
    } = this.props

    const showClearButton = !autoHideClearButton || this.state.value

    return (
      <div
        className={classnames(
          'vs-filter-input',
          { 'vs-text-input--with-clear-button': showClearButton },
          className
        )}
      >

        <DebouncedInput
          value={this.state.value}
          onValueChange={(value) => this.updateFilter(value)}
          className='vs-filter-input__input'
          type='text'
          disabled={disabled}
          {...otherProps}
        />

        { showClearButton && <button
          type='button'
          className='vs-filter-input__clear'
          onClick={this.clear}
          disabled={disabled}
        >
          <Close />
        </button>
        }
      </div>
    )
  }
}

export default FilterInput
