const createMandate = require('./create-mandate')
const getMandateStatus = require('./get-mandate-status')
const getMandateHistory = require('./get-mandate-history')
const stopMandate = require('./stop-mandate')

const createDebit = require('./create-debit')
const getDebitStatus = require('./get-debit-status')
const cancelDebit = require('./cancel-debit')

const createOTP = require('./create-otp')
const validateOTP = require('./validate-otp')

const { getConfiguredFetch } = require('./utils')
const uuid = require('uuid')
const { dateToDashes, dateToSlashes } = require('../../../utils/date-format')
const { ENDPOINTS } = require('./config')

class RemitaInterface {
  constructor (
    remitaApiConfig = {},
    fetch,
    logger,
    generateRequestId = uuid.v4
  ) {
    if (typeof fetch !== 'function') {
      throw new Error('Remita Interface usage error: expected fetch as param')
    }

    const requiredParams = [
      'url',
      'merchantId',
      'serviceTypeId',
      'apiToken',
      'apiKey'
    ]
    requiredParams.forEach(key => {
      if (!remitaApiConfig[key]) {
        throw new Error(
          `Remita Interface usage error: remitaApiConfig.${key} is a required argument`
        )
      }
    })

    this.logger = logger
    this.fetch = getConfiguredFetch(remitaApiConfig.url, fetch)
    this.merchantId = remitaApiConfig.merchantId
    this.serviceTypeId = remitaApiConfig.serviceTypeId
    this.apiToken = remitaApiConfig.apiToken
    this.apiKey = remitaApiConfig.apiKey
    this.generateRequestId = generateRequestId
    this.endpoints = ENDPOINTS
  }
}

Object.assign(RemitaInterface.prototype, {
  createMandate,
  getMandateStatus,
  getMandateHistory,
  stopMandate,
  createDebit,
  getDebitStatus,
  cancelDebit,
  createOTP,
  validateOTP
})

class RemitaMockedInterface {
  constructor () {
    this.__mockMandateId = () => {
      if (!this.__mandateId) {
        this.__mandateId = 123456000000
      } else {
        this.__mandateId++
      }

      return `${this.__mandateId}`
    }

    this.__mockRequestId = () => {
      if (!this.__requestIdFinal6) {
        this.__requestIdFinal6 = 100000
      } else {
        this.__requestIdFinal6++
      }

      return `12345678-abcd-0000-0000-abcdef${this.__requestIdFinal6}`
    }
  }

  generateRequestId () {
    return 'req-id-123'
  }

  async createMandate (mandateOptions) {
    const remitaCreateMandateRequestBody = {}
    let remitaCreateMandateResponse

    switch (mandateOptions.payerName) {
      case 'error-unknown': {
        remitaCreateMandateResponse = {
          ok: false,
          status: 400,
          body: '{"responseData":[],"responseCode":"99","responseMsg":"Unknown error"}',
          url: 'https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/send'
        }
        break
      }
      case 'error-txn-failed': {
        remitaCreateMandateResponse = {
          ok: true,
          status: 200,
          body: {
            statuscode: '02',
            requestId: '9218dc88-3392-4d74-8f0e-37c9b7627bc7',
            mandateId: '180463565739',
            status: 'Transaction Failed'
          },
          url: 'https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/send'
        }
        break
      }
      case 'error-cloudflare': {
        remitaCreateMandateResponse = {
          ok: false,
          status: 524,
          body: '<!DOCTYPE html><html lang="en"><head><title>Cloudflare error page</title></head><body>Cloudflare error page</body></html>',
          url: 'https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/send'
        }
        break
      }
      default: {
        const now = new Date()
        const startDate = new Date(dateToDashes(mandateOptions.startDate))
        const endDate = new Date(dateToDashes(mandateOptions.endDate))

        if (startDate < now || endDate < now || endDate < startDate) {
          remitaCreateMandateResponse = {
            ok: true,
            status: 200,
            body: {
              statuscode: '032',
              status: 'Invalid Date Format'
            },
            url: 'https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/send'
          }
        } else {
          remitaCreateMandateResponse = {
            ok: true,
            status: 200,
            body: {
              statuscode: '040',
              requestId: this.__mockRequestId(),
              mandateId: this.__mockMandateId(),
              status: 'Initail Request OK' // [sic]
            },
            url: 'https://remitademo.net/remita/exapp/api/v1/send/api/echannelsvc/echannel/mandate/setup'
          }
        }
      }
    }

    return Promise.resolve({
      remitaCreateMandateRequestBody,
      remitaCreateMandateResponse
    })
  }

  async getMandateStatus (mandateOptions) {
    // TODO: mandateOptions should only include requestId, but in order to emulate Remita, this mock call somehow needs
    //  to return payload including other [already known] data about the mandate
    const {requestId} = mandateOptions

    const now = new Date()
    const nowAsSlashedDate = dateToSlashes(now.toISOString().substr(0, 10))

    return Promise.resolve({
      ok: true,
      url: `https://remitademo.net/remita/ecomm/mandate/mandate-id-here/crypto-hash-here/status.reg`,
      body: {
        isActive: false, // todo how to set whether this should be returning true or false?
        requestId,
        registrationDate: nowAsSlashedDate
        // mandateId: '....',
        // startDate: '....',
        // endDate: '....',
      },
      status: 200
    })
  }

  async createDebit (mandateOptions) {
    const {mandateId, payerName} = mandateOptions
    switch (payerName) {
      case 'error-unknown': {
        return Promise.resolve({
          ok: false,
          status: 400,
          body: '{"responseData":[],"responseCode":"99","responseMsg":"Unknown error"}',
          url: 'https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/send'
        })
      }
      case 'error-txn-failed': {
        return Promise.resolve({
          ok: true,
          status: 200,
          body: {
            statuscode: '02',
            requestId: '9218dc88-3392-4d74-8f0e-37c9b7627bc7',
            mandateId: mandateId,
            status: 'Transaction Failed'
          },
          url: 'https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/send'
        })
      }
      case 'error-cloudflare': {
        return Promise.resolve({
          ok: false,
          status: 524,
          body: '<!DOCTYPE html><html lang="en"><head><title>Cloudflare error page</title></head><body>Cloudflare error page</body></html>',
          url: 'https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/send'
        })
      }
      default: {
        const randomRRR = Math.floor(100000000000 + Math.random() * 900000000000)
        const randomTransactionRef = Math.floor(100000000 + Math.random() * 900000000)
        return Promise.resolve({
          ok: true,
          status: 200,
          body: {
            statuscode: '069',
            RRR: randomRRR,
            requestId: uuid(),
            mandateId: mandateId,
            transactionRef: randomTransactionRef,
            status: 'New Transaction'
          },
          url: 'https://remitademo.net/remita/exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/send'
        })
      }
    }
  }

  async cancelDebit (cancelDebitOptions) {
    return Promise.resolve({
      ok: true,
      status: 200,
      body: {
        statuscode: '00',
        status: 'Success',
        requestId: cancelDebitOptions.requestId,
        mandateId: cancelDebitOptions.mandateId
      }
    })
  }

  async getDebitStatus (getDebitStatusOptions) {
    const {mandateId, requestId} = getDebitStatusOptions
    return Promise.resolve({
      'statuscode': '069',
      'RRR': '270007740695',
      'requestId': requestId,
      'mandateId': mandateId,
      'transactionRef': 7740695,
      'status': 'New Transaction'
    })
  }

  async getMandateHistory (request) {
    return Promise.resolve({
      'ok': true,
      'status': 200,
      'body': {
        'statuscode': '00',
        'data': {
          'data': {
            'totalTransactionCount': 6,
            'totalAmount': 191669,
            'paymentDetails': [
              {
                'amount': '28315',
                'lastStatusUpdateTime': '2021-06-23 07:38:57',
                'status': 'Successful',
                'statuscode': '00',
                'RRR': '220505883018',
                'transactionRef': '505883018'
              },
              {
                'amount': '27456',
                'lastStatusUpdateTime': '2021-06-28 20:26:55',
                'status': 'Successful',
                'statuscode': '00',
                'RRR': '220508762202',
                'transactionRef': '508762202'
              },
              {
                'amount': '5177',
                'lastStatusUpdateTime': '2021-06-08 23:24:16',
                'status': 'Successful',
                'statuscode': '00',
                'RRR': '230501462169',
                'transactionRef': '501462169'
              },
              {
                'amount': '58973',
                'lastStatusUpdateTime': '2021-07-13 11:43:06',
                'status': 'Successful',
                'statuscode': '00',
                'RRR': '280516278262',
                'transactionRef': '516278262'
              },
              {
                'amount': '18166',
                'lastStatusUpdateTime': '2021-06-16 08:27:37',
                'status': 'Successful',
                'statuscode': '00',
                'RRR': '300503655678',
                'transactionRef': '503655678'
              },
              {
                'amount': '53582',
                'lastStatusUpdateTime': '2021-07-06 07:30:56',
                'status': 'Successful',
                'statuscode': '00',
                'RRR': '350513574599',
                'transactionRef': '513574599'
              }
            ]
          }
        },
        'requestId': request.requestId,
        'mandateId': request.mandateId,
        'status': 'RECORD FOUND'
      },
      'url': 'https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/echannel/mandate/payment/history'
    })
  }

  createOTP () {
    return Promise.resolve({
      'status': 200,
      'ok': true,
      'message': 'Successful',
      'data': {
        'id': 'some-fancy-id',
        'mandate_status': 'cancelled',
        'next_possible_charge_date': 'null',
        'referenceId': '17729300982',
        'statusWebHook': 'https://pettycash.com/status/17729300982/',
        'expansionFields': [
          {
            'id': 'Some random ID',
            'description': 'Some random text',
            'type': 'Some random type',
            'value': 'Some value'
          }
        ]
      }
    })
  }

  validateOTP () {
    return Promise.resolve({
      'status': 200,
      'ok': true,
      'message': 'Successful',
      'data': {
        'mandateID': '270007795536',
        'remitaTransRef': '67789900',
        'authDetails': [
          {
            'type': 'otp',
            'label': '6-digit OTP',
            'Message': 'Kindly enter the OTP sent to ****1412 to complete the transaction'
          },
          {
            'type': 'password',
            'label': 'internet banking password',
            'Message': 'Kindly enter your internet banking password'
          }
        ]
      }
    })
  }
}

module.exports = {RemitaInterface, RemitaMockedInterface}
