'use strict';
var formats = require('ajv/lib/compile/formats')();
var validate = (function() {
  var refVal = [];
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    'use strict'; /*# sourceURL=https://schema.field.partners/1.0/get-ledger-balance-params# */
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      if (true) {
        var errs__0 = errors;
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !(false || key0 == 'ledger' || key0 == 'batches' || key0 == 'shoppingList' || key0 == 'products' || key0 == 'expiresAfter');
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
            break;
          }
        }
        if (valid1) {
          var data1 = data.ledger;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'ledger'
              },
              message: 'should have required property \'ledger\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if ((!data1 || typeof data1 !== "object" || Array.isArray(data1))) {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.ledger',
                schemaPath: '#/properties/ledger/type',
                params: {
                  type: 'object'
                },
                message: 'should be object'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            var data1 = data.batches;
            if (data1 === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'batches'
                },
                message: 'should have required property \'batches\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if ((!data1 || typeof data1 !== "object" || Array.isArray(data1))) {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.batches',
                  schemaPath: '#/properties/batches/type',
                  params: {
                    type: 'object'
                  },
                  message: 'should be object'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              var data1 = data.shoppingList;
              if (data1 === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'shoppingList'
                  },
                  message: 'should have required property \'shoppingList\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if ((!data1 || typeof data1 !== "object" || Array.isArray(data1))) {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.shoppingList',
                    schemaPath: '#/properties/shoppingList/type',
                    params: {
                      type: 'object'
                    },
                    message: 'should be object'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                var data1 = data.products;
                if (data1 === undefined) {
                  valid1 = false;
                  validate.errors = [{
                    keyword: 'required',
                    dataPath: (dataPath || '') + "",
                    schemaPath: '#/required',
                    params: {
                      missingProperty: 'products'
                    },
                    message: 'should have required property \'products\''
                  }];
                  return false;
                } else {
                  var errs_1 = errors;
                  if ((!data1 || typeof data1 !== "object" || Array.isArray(data1))) {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.products',
                      schemaPath: '#/properties/products/type',
                      params: {
                        type: 'object'
                      },
                      message: 'should be object'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  var data1 = data.expiresAfter;
                  if (data1 === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (errors === errs_1) {
                      if (typeof data1 === "string") {
                        if (!formats['date-time'].test(data1)) {
                          validate.errors = [{
                            keyword: 'format',
                            dataPath: (dataPath || '') + '.expiresAfter',
                            schemaPath: '#/properties/expiresAfter/format',
                            params: {
                              format: 'date-time'
                            },
                            message: 'should match format "date-time"'
                          }];
                          return false;
                        }
                      } else {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.expiresAfter',
                          schemaPath: '#/properties/expiresAfter/type',
                          params: {
                            type: 'string'
                          },
                          message: 'should be string'
                        }];
                        return false;
                      }
                    }
                    var valid1 = errors === errs_1;
                  }
                }
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate.schema = {
  "$id": "https://schema.field.partners/1.0/get-ledger-balance-params#",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "suggestBatches parameters",
  "type": "object",
  "properties": {
    "ledger": {
      "type": "object"
    },
    "batches": {
      "type": "object"
    },
    "shoppingList": {
      "type": "object"
    },
    "products": {
      "type": "object"
    },
    "expiresAfter": {
      "type": "string",
      "format": "date-time"
    }
  },
  "required": ["ledger", "batches", "products", "shoppingList"],
  "additionalProperties": false
};
validate.errors = null;
module.exports = validate;