export default {
  stockReporting: {
    role: 'feature:stock-reporting',
    displayName: 'Stock Reporting'
  },
  stockReportingAddProduct: {
    role: 'feature:stock-reporting-add-product',
    displayName: 'Counts: Add products',
    description: 'User can add products to a stock count'
  }
}
