module.exports = locationIdToSubmitProperties

const locationIdToProperties = require('./location-id-to-properties')

// This can be removed when the information has somehow been included
// in location docs
function locationIdToSubmitProperties (locationId) {
  const location = locationIdToProperties(locationId)
  switch (location.level) {
    case 'zone':
    case 'national':
    case 'country':
      return {
        submitsOwnReport: true,
        submitsChildrenReport: false,
        submitsBatchedCounts: true,
        submitsMultiFieldCounts: false
      }
    case 'state':
      return {
        submitsOwnReport: true,
        submitsChildrenReport: true,
        submitsBatchedCounts: true,
        submitsMultiFieldCounts: false
      }
    case 'sdp':
      return {
        submitsOwnReport: false,
        submitsChildrenReport: false,
        submitsBatchedCounts: false,
        submitsMultiFieldCounts: true
      }
    default:
      return {
        submitsOwnReport: false,
        submitsChildrenReport: false,
        submitsBatchedCounts: false,
        submitsMultiFieldCounts: false
      }
  }
}
