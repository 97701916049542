const { PGAdapter } = require('../common')
const { warehousePrograms } = require('../product/api/write/update-configuration')

// filter via month will return only forecasts between given months
const columnPreparers = {
  month: (key, value) => {
    return [
      { statement: `"${key}">=%INDEX%`, value: value.startMonth },
      { statement: `"${key}"<=%INDEX%`, value: value.endMonth }
    ]
  }
}

class ExternalForecastAdapter extends PGAdapter {
  constructor (pgConnection, tableName, userName, columns, idField, logger, opts) {
    opts.strictColumns = false // Uploads may add additional unused columns, that's ok
    super(pgConnection, tableName, userName, columns, idField, columnPreparers, logger, opts)
  }

  async listProductWarehouses ({ productId, startDate, endDate, limit } = {}) {
    const query = `
      SELECT DISTINCT location_fsid
      FROM ${this.tableName}
      WHERE month>=$1 AND month<=$2 AND lower(product_sku) = lower($3)
      LIMIT $4;
    `

    const result = await this.pgConnection.query(query, [
      startDate,
      endDate,
      productId,
      limit
    ])
    return result.rows.map(row => row.location_fsid)
  }

  async delete ({startDate, endDate, programId}) {
    const warehouseProgramKey = Object.keys(warehousePrograms).find(
      (key) => warehousePrograms[key].serviceId.includes(programId)
    )
    const productSkuPattern = `GHSC${warehouseProgramKey}`
    return this.deleteWhere({
      search: { columns: ['product_sku'], text: productSkuPattern },
      filter: { month: { startMonth: startDate, endMonth: endDate } }
    })
  }
}

module.exports = ExternalForecastAdapter
