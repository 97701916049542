import React from 'react'
import PropTypes from 'prop-types'

const ToolTip = ({ items, allDefinitions, value, status, toolTipLink, valuePercentage, text, target, showHeader = true }) => {
  const loadToolTipLink = (target) => {
    toolTipLink(target)
  }
  const header = valuePercentage ? `${valuePercentage}% (${value})` : `${value}%`
  const displayText = status ? `${text} ${status}` : text

  return (
    <div className='tool-tip'>
      {showHeader && <div className='tool-tip__header-value'>{header}</div>}
      <div className='tool-tip__header-text'>{displayText}</div>
      <ul className='tool-tip__list'>
        {items.slice(0, 5).map((item, index) => (
          <li key={index}>{allDefinitions[item.id].name} {item.value || item.value === 0 ? `(${item.value}%)` : ''}</li>
        ))}
      </ul>
      <div className='tool-tip__load-more' onClick={() => loadToolTipLink(target)}>Load more</div>
    </div>
  )
}

ToolTip.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  allDefinitions: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  toolTipLink: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  status: PropTypes.string,
  valuePercentage: PropTypes.number
}

export default ToolTip
