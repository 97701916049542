/*
 * Pluralize a word.
 *
 * Given we currently only need to pluralize english regular nouns, we can use
 * a simple ternary operation.
 *
 * TODO: We have this code copied in some other packages inside our codebase, but we should use this one instead
 *
 * NOTE: Maybe a nitpick but this does not cover for some edge cases such as plurals in 'es' (e.g. buses, boxes, etc) and irregular ones such as oxen, children, etc
 */
exports.pluralize = (noun, count, plural) => {
  if (!noun) return
  const isPlural = count !== 1

  if (plural) {
    // if a custom plural was passed, for irregular nouns
    return isPlural ? plural : noun
  }

  if (noun.endsWith('y') && isPlural) {
    // if second last char is not a vowel, e.g. in "duty" as opposed to "day"
    if (/[aeiou]/.test(noun.substring(0, noun.length - 2))) {
      return noun.replace(/.$/, 'ies')
    }
  }

  return !isPlural ? noun : `${noun}s`
}

/**
 *
 * @param {*} pluralNoun
 * @returns {string} Singular for the provided plural noun, if found. Original noun otherwise
 */
exports.singularize = (pluralNoun) => {
  if (!pluralNoun) return

  const irregularSingulars = {
    children: 'child',
    oxen: 'ox'
  }

  if (pluralNoun.endsWith('ies')) {
    // reverse the transformation for words ending with 'ies'
    return pluralNoun.replace(/ies$/, 'y')
  } else if (/[aeou]es$/i.test(pluralNoun)) {
    // ends in "- [vowel] + es" (values, bees, ...)
    return pluralNoun.slice(0, -1)
  } else if (pluralNoun.endsWith('es')) {
    // ends in "-es" (buses, boxes, ...)
    return pluralNoun.slice(0, -2)
  } else if (pluralNoun.endsWith('s')) {
  // reverse the transformation for regular pluralization by dropping the "s" suffix
    return pluralNoun.slice(0, -1)
  } else if (irregularSingulars[pluralNoun]) {
    // irregular pluralizations
    return irregularSingulars[pluralNoun]
  } else {
    // return original plural noun if singular couldn't be determined
    return pluralNoun
  }
}
