const { closeSnapshots } = require('./snapshot-close')
const { zeroOutSubOrders } = require('./zero-out-suborders')

exports.removeDestination = function (orders, destinationId, closedBy) {
  // filter out destination docs to cancel out of the order
  const filterDestinationDocsToDelete = orders.filter((o) => o.destinationId === destinationId)
  // zero out adjusted field for each product then close
  const zeroedOutDocsToDelete = zeroOutSubOrders(filterDestinationDocsToDelete)
  const destinationDocsToDelete = closeSnapshots(zeroedOutDocsToDelete, 'cancelled', closedBy, new Date().toJSON())
  return destinationDocsToDelete
}
