import React from 'react'
import PropTypes from 'prop-types'

import { Button, Page } from '@fielded/shared-ui'

const Footer = ({onClickSave, onClickSubmit, loading, disabled}) => {
  return (
    <Page.Footer>
      <Button
        fill='outline'
        colorVariant='brand'
        size='large'
        onClick={onClickSubmit}
        id='footerSubmitBtn'
        className='submit-with-hint'
        loading={loading}
        disabled={disabled}
      >
        Submit stock count
      </Button>
    </Page.Footer>
  )
}

Footer.propTypes = {
  onClickSubmit: PropTypes.func.isRequired
}

export default Footer
