import React, { Component } from 'react'
import { canShipmentBeMarkedVoid } from '@fielded/fs-api/lib/shipment/utils/utils'
import {withUser} from '../../../common/AuthenticationProvider'
import withConfig from '../../../van-shared/hoc/withConfig'
import {updateShipment} from '../shipments/shipments-reducer'
import flowRight from 'lodash/flowRight'
import { connect } from 'react-redux'
import withShipment from './../common/WithShipmentWrapper'
import {
  headerLocationFor,
  isShelflifePowered,
  isAdminUser
} from '../common/utils'
import shipmentsBreadcrumb from '../common/shipmentsBreadcrumb'
import { hasFeature } from '../../../van-shared/utils/features'

import ShipmentOptions from './ShipmentOptions'

class ShipmentOptionsContainer extends Component {
  componentDidMount () {
    const {
      shipment,
      updateShipment
    } = this.props

    updateShipment(shipment)
  }

  canDeleteShipment = () => {
    const { shipment, config, user } = this.props
    if (hasFeature(config.features, 'shipments:removeShipment') && canShipmentBeMarkedVoid(shipment)) {
      // for SL, only admin users can see delete shipment link
      if (isShelflifePowered(config) && !isAdminUser(user)) {
        return false
      }

      return true
    }

    return false
  }

  render () {
    const { shipment } = this.props

    if (!shipment) return 'Something went wrong loading the options, please try again'

    const addProductsLink = `/shipments/pick-list/${shipment.snapshotId}/shipment-options/add-product`

    const deleteProducts = shipment.status !== 'received'

    return (
      <ShipmentOptions
        onRemoveShipment={this.handleRemoveShipment}
        breadcrumbItems={[shipmentsBreadcrumb]}
        closeUrl={`/shipments/pick-list/${shipment.snapshotId}`}
        headerLocation={headerLocationFor(shipment)}
        addProductsLink={addProductsLink}
        deleteProducts={deleteProducts}
        deleteShipmentLink={this.canDeleteShipment() && `/shipments/pick-list/${shipment.snapshotId}/shipment-options/delete-shipment`}
        {...this.props}
      />
    )
  }
}

const mapDispatchToProps = {
  updateShipment
}

const withHOCs = flowRight(
  withConfig,
  withShipment,
  withUser,
  connect(null, mapDispatchToProps)
)

export default withHOCs(ShipmentOptionsContainer)
