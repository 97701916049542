const EXCLUDED_LOCATIONS = ['katsina']

function shouldExcludeUser (user) {
  if (!user) {
    return false
  }

  let state
  if (typeof user === 'string' && user.includes(':')) {
    // handle situation where full id is passed
    state = user && user.split(':')[3]
  } else if (typeof user === 'string' && !user.includes(':')) {
    // Handle string case where ':' is not present
    state = user
  }
  const excludeExternalUser = EXCLUDED_LOCATIONS.includes(state)

  return excludeExternalUser
}

export { shouldExcludeUser }
