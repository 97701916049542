/** Create a typed location id
 *
 * Location ids might not have a type prefix 'location'.
 * We add that here on demand.
 */
const createLocationId = (id) =>
  id.startsWith('location:') ? id : 'location:' + id
exports.createLocationId = createLocationId

/** Create a typed geolocation id
 *
 * Geolocation ids might not have a type prefix 'geolocation'.
 * We add that here on demand.
 */
const createGeoLocationId = (id) => {
  if (id.startsWith('geolocation:')) {
    return id
  }
  let geoId = id
  // We graciously transform Location ids
  if (geoId.startsWith('location:')) {
    geoId = geoId.substring(9)
  }
  // And remove any name part they might have
  const nameIndex = geoId.indexOf(':name:')
  if (nameIndex > -1) {
    geoId = geoId.substring(0, nameIndex)
  }
  if (geoId === 'national') {
    geoId = 'country:ng'
  } else if (!geoId.startsWith('country:')) {
    geoId = 'country:ng:' + geoId
  }
  return 'geolocation:' + geoId
}
exports.createGeoLocationId = createGeoLocationId

/** Create a typed level id
 *
 * Level ids may not have a type prefix 'level'.
 * We add that here on demand.
 */
const createLevelId = (id) =>
  id.startsWith('level:') ? id : 'level:' + id
exports.createLevelId = createLevelId
