const ALLOCATIONS_WORKSHEET_NAME = 'Allocations'

const methods = {
  TP: 'tp',
  BUNDLED: 'bundled',
  CONSUMPTION: 'consumption',
  FLAT: 'flat',
  DIRECT_ORDER: 'directOrder'
}

const DIRECT_ORDER_TYPES = {
  PAY_ON_DELIVERY: 'pay_on_delivery',
  PAY_AS_YOU_SELL: 'pay_as_you_sell',
  PURCHASE: 'purchase',
  IMMEDIATE_PURCHASE: 'immediate_purchase'
}

const DIRECT_ORDER_TYPES_HUMANIZED = {
  [DIRECT_ORDER_TYPES.PAY_ON_DELIVERY]: 'Pay As You Go',
  [DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL]: 'Pay As You Sell'
}

const types = {
  [methods.DIRECT_ORDER]: {
    validTypes: [DIRECT_ORDER_TYPES.PAY_ON_DELIVERY, DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL],
    default: DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL
  }
}

const defaultSupplyPlan = ({
  [methods.DIRECT_ORDER]: {
    bufferDays: 3,
    leadTimeDays: 3,
    supplyPeriodDays: 7
  }
})

const CSV_DELIMITER = ';'

module.exports = {
  methods,
  types,
  defaultSupplyPlan,
  CSV_DELIMITER,
  ALLOCATIONS_WORKSHEET_NAME,
  DIRECT_ORDER_TYPES,
  DIRECT_ORDER_TYPES_HUMANIZED
}
