import persistState from 'redux-localstorage'
import logger from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit'

import pkg from '../../package.json'

const getStoreMiddleware = (config) => {
  const middlewares = []

  if (config.reduxLogger) {
    middlewares.push(logger)
  }

  return middlewares
}

const getStoreEnhancers = (config) => {
  const enhancers = []

  if (process.env.NODE_ENV === 'development' && config.enableLocalPersistence) {
    enhancers.push(persistState(null, {key: pkg.name}))
  }

  return enhancers
}

export const configureAppStore = (rootReducer, config = {}, initialState = {}, extraArg) => {
  const additionalMiddleware = getStoreMiddleware(config)
  const additionalEnhancers = getStoreEnhancers(config)

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: extraArg
        },
        immutableCheck: false,
        serializableCheck: false
      }).concat(additionalMiddleware),
    enhancers: additionalEnhancers,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState
  })

  if (process.env.NODE_ENV === 'development' && module && module.hot) {
    module.hot.accept('./reducer', () => store.replaceReducer(rootReducer))
  }

  return store
}
