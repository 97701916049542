module.exports = {
  title: 'funder',
  name: 'funder',
  description: 'A funder',
  type: 'object',
  properties: {
    _id: {
      type: 'string'
    },
    type: {
      type: 'string',
      default: 'funder'
    },
    name: {
      type: 'string'
    }
  },
  required: [
    '_id',
    'type',
    'name'
  ]
}
