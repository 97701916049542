const { docsToOrderEntities } = require('./docs-to-order-entities')
const { getGroupStatus } = require('./statuses')

// listOrdersForUser is used in the app internally
// while list is used in the REST api externally
exports.listOrdersForUser = function ({
  snapshots, status, withSnapshots, withAccepted = true, fundersFilter = [], useAcceptedStatus, useSubOrderId
}) {
  const orders = docsToOrderEntities({docs: snapshots, status, withSnapshots, useAcceptedStatus, useSubOrderId})
    .filter(order => hasFunderMatch(order, fundersFilter))

  if (withAccepted) return {orders}

  return {orders: filterOutAccepted(orders)}
}

exports.filterBySuborders = function ({snapshotsByStatus}, suborderId) {
  return Object.values(snapshotsByStatus)
    .filter(snapshot => snapshot.suborderId === suborderId)
}

function hasFunderMatch (order, fundersFilter = []) {
  if (!fundersFilter.length) return true

  return fundersFilter.includes(order.funderId)
}

function filterOutAccepted (orders) {
  const byGroupId = orders
    .reduce((acc, order) => {
      acc[order.groupId] = acc[order.groupId] || {groupId: order.groupId, orders: []}
      acc[order.groupId].orders.push(order)
      return acc
    }, {})

  const groupIdsWithStatus = Object.values(byGroupId)
    .reduce((acc, group) => {
      acc[group.groupId] = getGroupStatus(group.orders).status
      return acc
    }, {})

  return orders.filter(order => groupIdsWithStatus[order.groupId] !== 'accepted')
}
