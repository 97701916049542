import React, {Component} from 'react'
import PLANNING_TYPES from '@fielded/fs-api/lib/shipment/tools/planning-types'
import withShipment from './../common/WithShipmentWrapper'
import { withApi } from './../../../common/ApiProvider'
import withConfig from '../../../van-shared/hoc/withConfig'
import { hasFeature } from '../../../van-shared/utils/features'
import { Loading } from '@fielded/shared-ui'
/*
 * This view never renders anything except <Loading />
 * it is just there to check if quantities on a C&R shipment
 * needs to be updated based on a recent ledger balance
 */

class UpdateCRQuantitiesContainer extends Component {
  async componentDidMount () {
    const { shipment, history, api, config } = this.props
    const useCRSalesStats = hasFeature(config.features, 'shipments:useCRSalesStats')
    if (shipment.planningType === PLANNING_TYPES.C_R) {
      if (useCRSalesStats) {
        await api.shipment.updateCRQuantitiesStats(shipment, { dryRun: false })
      } else {
        await api.shipment.updateCRQuantities(shipment, { dryRun: false })
      }
      await this.props.reloadShipment(shipment.id)
    }

    return history.replace(`/shipments/pick-list/${shipment.snapshotId}`)
  }

  render () {
    return <Loading loadingText={'Updating Quantities for Collection'} />
  }
}

export default withConfig(withShipment(withApi(UpdateCRQuantitiesContainer)))
