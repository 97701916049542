import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ResetPassword from './ResetPassword'
import withConfig from '../van-shared/hoc/withConfig'
import ResetPasswordApi from '../reset-password/reset-password-api'
// fixme: probably need refactor login-api remove from login component
import { login } from '../login/login-api'

class ResetPasswordContainer extends Component {
  static propTypes = {
    resetLoginStatus: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    config: PropTypes.object.isRequired
  }

  static defaultProps = {
    error: null
  }

  state = {
    inProcess: false,
    onSuccess: false,
    error: null
  }

  componentDidMount () {
    const { config } = this.props
    this.api = new ResetPasswordApi({ agaveApiUrl: config.agaveApiUrl })
  }

  handleSubmit = async (fields) => {
    const { location, history, config } = this.props
    try {
      const query = new URLSearchParams(location.search)
      const resetToken = query.get('resetToken')
      this.setState({ inProcess: true })
      const response = await this.api.submit(resetToken, fields)
      await login(response.name, fields.password, {
        config: {
          remoteDbUrl: config.remoteDbUrl
        }
      })
      history.push('/')
    } catch (error) {
      this.setState({ error: error.message })
      this.setState({ inProcess: false })
    }
  }

  render () {
    const { inProcess, onSuccess, error } = this.state
    return (
      <ResetPassword
        error={error}
        inProcess={inProcess}
        onSuccess={onSuccess}
        config={this.props.config}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

export default withConfig(ResetPasswordContainer)
