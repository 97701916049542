const { format } = require('date-fns')

// Safaricom's API docs tells us to hard code this and always send it.
const LIPA_NA_MPESA_TRANSACTION_TYPE = 'CustomerPayBillOnline'

module.exports = createDebit
async function createDebit (
  {
    lipaShortCode,
    lipaPassKey,
    logger,
    endpoints,
    callbackUrl,
    token,
    fetch
  },
  {
    PartyA,
    AccountReference,
    Amount,
    TransactionDesc
  }
) {
  // From safaricom API docs:
  // > This is the Timestamp of the transaction, normaly in the formart of
  // > YEAR+MONTH+DATE+HOUR+MINUTE+SECOND (YYYYMMDDHHMMSS)
  const timestamp = format(new Date(), 'YYYYMMddHHMMss')
  const password = Buffer.from(
    lipaShortCode + lipaPassKey + timestamp
  ).toString('base64')

  const bodyRequest = {
    BusinessShortCode: lipaShortCode,
    Password: password,
    Timestamp: timestamp,
    TransactionType: LIPA_NA_MPESA_TRANSACTION_TYPE,
    Amount,
    PartyA,
    PartyB: lipaShortCode,
    PhoneNumber: PartyA,
    CallBackURL: callbackUrl,
    AccountReference,
    // Because there is no known use for `TransactionDesc` but it is
    // a required param to safaricom, pass AccountReference
    // which is actually used.
    TransactionDesc: TransactionDesc || AccountReference
  }
  const fetchParams = {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(bodyRequest)
  }

  const response = await fetch(endpoints.createDebit, fetchParams)

  if (!response.ok) {
    let text
    try {
      text = await response.text()
    } catch (e) {
      // pass
    }
    delete bodyRequest.password
    logger.warn(
      'Error createDebit, cannot proceed',
      response.status,
      response.statusText,
      text,
      JSON.stringify(bodyRequest)
    )
    return text
  }

  try {
    const body = await response.json()
    return body
  } catch (e) {
    delete bodyRequest.password
    logger.warn(
      'Error createDebit, response.json()',
      response.status,
      response.statusText,
      JSON.stringify(bodyRequest)
    )
  }
}
