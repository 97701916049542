module.exports = snapshotToVanRecord

const pick = require('lodash/pick')
const omitBy = require('lodash/omitBy')
const isNil = require('lodash/isNil')
const toLocationProperties = require('./to-location-properties')
const { isHeldLocation } = require('./is-held-location')
const toDocIdProperties = require('./to-doc-id-properties')
const { getShipmentType } = require('./shipment-types')
const PLANNING_TYPES = require('./planning-types')
const { ORDER_SHIPMENT_SHARED_PROPS } = require('../../order/constants')

// get doc props that we just want to get into the record
// having same name and value. Only if they're defined
const getPropsToClone = doc => {
  let propsToCloneNames = [
    'updatedAt',
    'updatedBy',
    'scheduledDate',
    'comment',
    'createdAt',
    'signature',
    'type',
    'shipmentTypeId',
    'type',
    'previousScheduledDates',
    'programId',
    'orderId',
    'shouldReturnProducts',
    'isAutomaticReturnShipment',
    'vendorId',
    'isGpsAuthenticated',
    'gpsValidationFailComment',
    ...ORDER_SHIPMENT_SHARED_PROPS
  ]

  if (doc.type === 'snapshot') propsToCloneNames = propsToCloneNames.concat(['parentDocId', 'counts'])
  if (doc.type === 'change') propsToCloneNames = propsToCloneNames.concat(['changes', 'clock', 'effectiveAt'])
  if (doc.type === 'otp') propsToCloneNames = propsToCloneNames.concat(['otpKey', 'otpPassword', 'otpUserFailReason'])

  const props = pick(doc, propsToCloneNames)
  return omitBy(props, isNil) // return defined picked props
}

function snapshotToVanRecord (doc, locationId, user) {
  const {
    origin,
    destination,
    date,
    shipmentNo,
    status,
    agentId,
    changeId
  } = toDocIdProperties(doc._id)
  const originProps = toLocationProperties(origin)
  const destinationProps = toLocationProperties(destination)
  const originIsPackPoint = isHeldLocation(originProps.id)
  const userIsOperator = (user && user.roles) && user.roles.includes('feature:userRole:operator')
  const isSameTerritory = (user && user.location) && user.location.state === originProps.state

  const propsToClone = getPropsToClone(doc)

  const vanRecord = {
    id: doc._id,
    rev: doc._rev,
    shipmentNo,
    origin: originProps,
    destination: destinationProps,
    date: doc.scheduledDate || date,
    status,
    planningType: doc.planningType || PLANNING_TYPES.DEFAULT,
    statusType: (originProps.id === locationId || (originIsPackPoint && isSameTerritory && userIsOperator)) ? 'distribution' : 'arrival',
    createdBy: {
      user: doc.createdBy,
      agent: changeId || agentId
    },
    ...propsToClone
  }

  if (doc.type === 'snapshot') {
    const shipmentTypeId = doc.shipmentTypeId || 'routine'
    const shipmentType = getShipmentType(shipmentTypeId)
    vanRecord.shipmentType = shipmentType
  }

  return vanRecord
}
