const uuid = require('uuid/v4')
const validateRoute = require('../../../utils/validate-route')
const stringToId = require('../../../utils/string-to-id')

function preparerouteToSave (route, username = '') {
  const routeToSave = Object.assign({}, route, {
    name: route.name,
    type: route.type || 'route',
    version: route.version || '1.0.0',
    programs: route.programs || {},
    createdBy: username,
    createdAt: new Date().toISOString()
  })

  routeToSave.day = route.day || ''
  routeToSave.deliveryFrequencyWeeks = route.deliveryFrequencyWeeks || ''
  routeToSave.firstDeliveryDate = route.firstDeliveryDate || ''

  if (route._rev) {
    routeToSave.updatedAt = new Date().toJSON()
    routeToSave.updatedBy = username
  } else {
    routeToSave.createdAt = new Date().toJSON()
    routeToSave.createdBy = username
    routeToSave.uuid = uuid()
  }

  if (!route._id) {
    routeToSave._id = route.useUUID
      ? `route:${routeToSave.uuid}`
      : `route:${stringToId(route.name)}`
  }

  if (route.startDate) {
    routeToSave.startDate = route.startDate
  }

  if (route.endDate) {
    routeToSave.endDate = route.endDate
  }

  if (route.useUUID) {
    delete routeToSave.useUUID
    delete routeToSave.day
    delete routeToSave.deliveryFrequencyWeeks
    delete routeToSave.firstDeliveryDate
  }

  return routeToSave
}

exports.save = function (state, route, config) {
  const username = state.user ? state.user.name : ''
  const doc = preparerouteToSave(route, username)
  // do validation after props are normalized
  validateRoute(doc, config)
  return state.dal.routes.save(state, doc)
}
