const flatMap = require('lodash/flatMap')
const { ALL_FUNDERS_ID } = require('../../tools/utils')

exports.getLocationsFromNodes = (
  nodes,
  options
) => {
  const locations = []

  for (const node of nodes) {
    if (node.type !== 'location') {
      continue
    }
    if (options) {
      if (options.levels && !options.levels.includes(node.location.level)) {
        continue
      }
      if (options.services && !someServiceSupported(node.location, options.services)) {
        continue
      }

      if (options.programs && !someProgramSupported(node.location, options.programs)) {
        continue
      }

      if (options.filter && !someServiceIdentifiersSupported(node.location, options.filter)) {
        continue
      }
    }
    locations.push(node.location)
  }
  return locations
}

const someServiceSupported = (location, services) => {
  if (!location.services || location.services.length === 0) {
    return false
  }
  for (const service of services) {
    if (location.services.includes(service)) {
      return true
    }
  }
  return false
}

const someProgramSupported = (location, programs) => {
  if (!location.programs || location.programs.length === 0) {
    return false
  }

  const locationPrograms = location.programs.map(p => p.id)
  for (const program of programs) {
    if (locationPrograms.includes(program)) {
      return true
    }
  }
  return false
}

exports.someServiceIdentifiersSupported = someServiceIdentifiersSupported
function someServiceIdentifiersSupported (location, identifiers) {
  const locationFilters = flatMap(location.programs, (program) => {
    return program.services.map(s => {
      let identifier
      // If the user filters by funder check the location service funderId
      // Else check if the user filters by route and check the service routeId
      if (s.hasOwnProperty('funderId') && identifiers.find(i => i && i.startsWith('funder:'))) {
        identifier = 'funderId'
      } else if (s.hasOwnProperty('routeId') && identifiers.find(i => i && i.startsWith('route:'))) {
        identifier = 'routeId'
      }
      return s[identifier]
    })
  })

  // For PSM users we want to sync warehouses that have all-funders attached regardless of whatever funder filters are passed
  if (locationFilters.includes(`funder:${ALL_FUNDERS_ID}`)) return true

  return identifiers.some(identifier => locationFilters.includes(identifier))
}
