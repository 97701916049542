import get from 'lodash/get'

import { hasFeature } from '../van-shared/utils/features'

export const userHasIntercom = (config) => {
  return hasFeature(config.features, 'intercom') && process.env.NODE_ENV !== 'development'
}

export const addIntercom = (config) => {
  if (!userHasIntercom(config)) {
    return
  }

  const APP_ID = get(config.features.intercom, 'appId')

  const intercomScript = document.createElement('script')
  intercomScript.innerText = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==='function'){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + '${APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`
  document.head.appendChild(intercomScript)
}

export const bootIntercom = (config, user) => {
  if (!window.Intercom || !!window.intercomSettings) {
    return
  }

  const appId = get(config.features.intercom, 'appId')

  if (!appId) {
    return
  }

  window.intercomSettings = {
    app_id: appId,
    custom_launcher_selector: '.intercom-trigger',
    alignment: 'right',
    horizontal_padding: 20,
    vertical_padding: 20,
    hide_default_launcher: true,
    name: user ? user.name : ''
  }

  window.Intercom('boot')
}

export const bootIntercomBeforeLogin = async (config) => {
  if (!userHasIntercom(config)) {
    return
  }
  await addIntercom(config)
  await bootIntercom(config)
  window.Intercom('show')
}

export const bootIntercomAfterLogin = async (config, user) => {
  if (!userHasIntercom(config)) {
    return
  }
  await bootIntercom(config, user)
  window.Intercom('showNews')
}
