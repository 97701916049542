import React from 'react'

import { BackButton, Button, Page } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

const Confirmation = ({
  history,
  children,
  situation,
  isBatchConfirmation,
  hasDeliveryNote,
  onProceedClick,
  hideBackButton,
  hasCustomFooter,
  isProceedNeeded
}) => {
  const offlineMessage = hasDeliveryNote
    ? 'Make sure your device has an internet connection to upload the delivery note when completing this delivery.'
    : 'Make sure your device has an internet connection to check for updates.'
  return (
    <Page situation={situation}>
      <Page.Header
        backButton={hideBackButton ? null : <BackButton history={history}>go back</BackButton>}
      />

      {children}

      {!isBatchConfirmation && (
        <Page.Panel className='show-when-offline'>
          <p className='footer__connection-hint'>
            {offlineMessage}
          </p>
        </Page.Panel>
      )}
      {!hasCustomFooter && isProceedNeeded && (
        <Page.Footer layout='centered'>
          <Button
            fill='full'
            size='large'
            colorVariant={situation !== 'ready' ? 'brand' : 'confirmation'}
            iconSide='right'
            icon={<Arrow direction='right' />}
            onClick={onProceedClick}
          >
            Proceed
          </Button>
        </Page.Footer>
      )}
    </Page>
  )
}

Confirmation.defaultProps = {
  isProceedNeeded: true,
  hasCustomFooter: false
}

export default Confirmation
