const keyBy = require('lodash/keyBy')
const get = require('lodash/get')
const {getServiceForLocationId} = require('../../../service/tools')
const {translateTerritoryAlias, productAliasesByMarket} = require('../../../product/tools')
const {
  getUserStateLocationId,
  getLocationsForRoutes
} = require('../master-data')
const tools = require('../../tools')
const { getProperRouteIdentifier } = require('../../tools/read/utils')

const { transformOrders } = require('../../tools/transform-orders-by-destination.js')
const { ORDER_TYPES_FOR_PLAN_TRANSFER } = require('../../constants')

exports.getGroupDetails = getGroupDetails
async function getGroupDetails (state, mainApi, groupId) {
  const snapshots = await state.dal.order.getGroup(state, {groupId})
  const status = tools.getUserStatus(state.user)
  const locationId = getUserStateLocationId(state)
  const orders = tools.docsToOrderEntities({
    docs: snapshots, status, locationId, useSubOrderId: true
  })

  if (!orders.length) {
    throw new Error(`No orders found for groupId ${groupId}`)
  }

  const routeIds = Array.from(new Set(orders.map(o => o.routeId)))
  const [firstOrder] = orders
  const {deliveryDate, orderType} = firstOrder
  const jsonDeliveryDate = new Date(deliveryDate).toJSON()

  const { allLocations } = await getLocationsForRoutes(
    state, locationId, jsonDeliveryDate, routeIds, orderType
  )

  // Get default packpoint for territory
  const destination = get(orders[0], `destinationId`)
  const destinationDoc = await mainApi.location.get(destination)
  const [ defaultSupplier ] = await mainApi.order.getSupplierIdsForLocation(destinationDoc, {planType: orderType})

  // return only snapshots who are yet to be completed or return the whole docs if all are marked as complete
  const allOrdersByDestination = await transformOrders(state, orders, defaultSupplier)
  const filterOutUncompletedSnaps = allOrdersByDestination.filter(o => !o.isComplete)
  const ordersByDestination = filterOutUncompletedSnaps.length === 0 ? allOrdersByDestination : filterOutUncompletedSnaps

  const suppliers = await getSuppliersFromOrders(mainApi, orders, defaultSupplier)

  // get route ids for this order group
  let orderRouteIds = []
  ordersByDestination.forEach(order => {
    let routeToPush = getProperRouteIdentifier(order)
    orderRouteIds.push(routeToPush)
  })
  orderRouteIds = [...new Set(orderRouteIds)]

  const locationsById = keyBy(allLocations, '_id')
  const firstOrderLocation = locationsById[firstOrder.destinationId]
  if (!firstOrderLocation) {
    throw new Error(`Cound not find location for order destination ${firstOrder.destinationId}`)
  }

  const {country} = firstOrderLocation.location
  const allRoutes = await mainApi.routes.list()
  const orderRoutes = allRoutes.filter(route => orderRouteIds.includes(route._id))
  const routesById = keyBy(orderRoutes, '_id')

  const products = await mainApi.product.listAll()
  let productsById = keyBy(products, '_id')

  const supplierLedgers = await Promise.all(suppliers.map(async supplier => {
    return getLedgerForSupplier(state, mainApi, {supplier, locationId, products})
  }))

  // for entire order to be referenced as closed, all order docs must haved closedStatus set to 'cancelled'
  const isClosed = orders.every(o => o.closedStatus === 'cancelled' && o.isComplete)
  const { status: groupStatus, statusDisplayName } = tools.getGroupStatus(orders)

  const allSuppliers = await getAllSuppliersForOrder(state, mainApi, orders)

  return {
    orderType,
    orders: ordersByDestination,
    productsById,
    allSuppliers,
    locationsById,
    deliveryDate,
    jsonDeliveryDate,
    country,
    routesById,
    supplierLedgers,
    isClosed,
    status: groupStatus,
    statusDisplayName
  }
}

exports.getLedgerForSupplier = getLedgerForSupplier
async function getLedgerForSupplier (state, mainApi, {supplier, locationId, products, includeShipments = true, includeOrders = true}) {
  const {service: serviceId} = getServiceForLocationId(supplier._id)

  const ledgerResponse = await mainApi.ledger.getOne(supplier._id, {includeShipments, includeOrders})
  const {service: userServiceId} = getServiceForLocationId(locationId)
  const needsTranslation = serviceId !== userServiceId
  let productsForTranslation

  const ledgerKeys = Object.keys(ledgerResponse)
  if (needsTranslation) {
    if (!products) {
      products = await mainApi.product.getByIds(ledgerKeys)
    }
    productsForTranslation = productAliasesByMarket(products)
  }

  const ledger = ledgerKeys.reduce((acc, ledgerProductId) => {
    try {
      const productId = needsTranslation
        ? translateTerritoryAlias(ledgerProductId, productsForTranslation, userServiceId)
        : ledgerProductId

      acc[productId] = acc[productId]
        ? acc[productId] + ledgerResponse[ledgerProductId]
        : ledgerResponse[ledgerProductId]
    } catch (error) {
      console.error(error)
    }
    return acc
  }, {})

  return {supplier, ledger}
}

async function getSuppliersFromOrders (mainApi, orders, defaultSupplier) {
  const orderSuppliers = orders.reduce((acc, order) => {
    const { supplierId } = order
    if (!acc.includes(supplierId) && supplierId) {
      acc.push(supplierId)
    }
    return acc
  }, [])

  if (orderSuppliers.length === 0) {
    orderSuppliers.push(defaultSupplier)
  }

  let suppliersWithDocs = await mainApi.location.getByIds(orderSuppliers)
  if (suppliersWithDocs && suppliersWithDocs.length !== orderSuppliers.length) {
    // fetch locations from agave if we fail to fetch from above method
    suppliersWithDocs = await mainApi.location.getLocationsViaIds(orderSuppliers)
  }

  return suppliersWithDocs
}

async function getAllSuppliersForOrder (state, mainApi, orders) {
  // This get's all supplier/packpoints for a territory
  const { destinationId, orderType } = orders[0]
  const destination = await mainApi.location.get(destinationId)
  const unFilteredSuppliersIds = await mainApi.order.getSupplierIdsForLocation(destination, {planType: orderType, global: true})
  const unFilteredSuppliers = await mainApi.location.getLocationsViaIds(unFilteredSuppliersIds)
  const suppliers = unFilteredSuppliers
    .filter(supplier => get(supplier, `level`) === ORDER_TYPES_FOR_PLAN_TRANSFER[orderType])
    .map(supplier => ({ label: supplier.name, value: supplier._id }))

  return suppliers
}
