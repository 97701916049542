const PGAdapter = require('../common/pg-adapter')

class PriceMarkupMultiplierPGAdapter extends PGAdapter {
  constructor ({ pgConnection, username, logger }) {
    const columns = [
      'id',
      'market_id',
      'date',
      'financing_multiplier',
      'delivery_multiplier',
      'created_at',
      'updated_at'
    ]
    super(
      pgConnection,
      'avocado.data_markupmultiplierhistory',
      username,
      columns,
      'id',
      {},
      logger,
      {addUuid: false}
    )
  }
}

module.exports = { PriceMarkupMultiplierPGAdapter }
