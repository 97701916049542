const shortid = require('shortid')
const { createSnapshot } = require('./snapshot-create')
const { getFunderOnProgram, getRouteOnProgram } = require('../utils')

exports.createNewOrders = function ({
  programId,
  allocations,
  user,
  timestamp = new Date().toJSON(),
  funderId,
  routeId,
  deliveryDate,
  orderType,
  groupId = shortid.generate(),
  doNotAddUpdateParams,
  paymentStatus,
  paymentChoice,
  paymentTimestamp,
  deliveryFee
}) {
  return allocations.map(allocation => {
    const snapshot = createSnapshot({
      status: 'new',
      orderId: allocation.orderId || shortid.generate(),
      groupId,
      suborderId: allocation.suborderId,
      programId,
      destinationId: allocation.location._id,
      supplierId: allocation.supplier,
      funderId: funderId || getFunderOnProgram(allocation.location, programId),
      routeId: routeId || getRouteOnProgram(allocation.location, programId),
      products: allocation.products,
      reports: allocation.reports || [],
      user,
      timestamp,
      deliveryDate: deliveryDate || allocation.deliveryDate,
      orderType: orderType || allocation.orderType,
      doNotAddUpdateParams,
      paymentStatus: paymentStatus || allocation.paymentStatus,
      paymentChoice: paymentChoice || allocation.paymentChoice,
      paymentTimestamp: paymentTimestamp || allocation.paymentTimestamp,
      deliveryFee: deliveryFee || allocation.deliveryFee,
      orderAmount: allocation.orderAmount,
      totalAmount: allocation.totalAmount,
      vat: allocation.vat
    })
    return snapshot
  })
}
