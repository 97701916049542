import React from 'react'
import PropTypes from 'prop-types'

import { FieldContext } from '../'
import { requiredValidationErrorMessage } from '../../utils'
import FormFieldRegistrationManager from '../../FormFieldRegistrationManager'
import TextInput from '../../../TextInput'
import DebouncedInput from '../../../DebouncedInput'

/**
 *  Wrapper for input
 *
 *  Adds additional functionality such as validation
 */
const Text = ({
  required,
  validate,
  onValueChange,
  defaultValue,
  debounced,
  delay,
  type,
  ...otherProps
}) => {
  const spreadCommonProps = (field) => ({
    name: field.props.fieldName,
    className: 'vs-form-field__input',
    hasError: field.hasErrors(),
    value: field.props.value,
    onValueChange: (value) => {
      field.handleOnChange(value)
      if (typeof onValueChange === 'function') {
        onValueChange(value)
      }
    },
    required,
    type
  })

  return (
    <FieldContext.Consumer>
      {
        (field) => (
          <FormFieldRegistrationManager
            register={() => {
              field.register({
                required,
                validate,
                type,
                requiredValidator: async (value) => {
                  if (!value || value === '') {
                    return required && requiredValidationErrorMessage(required)
                  }
                },
                value: defaultValue,
                ...otherProps
              })
            }}
            deregister={() => field.deregister()}
          >
            {debounced
              ? (<DebouncedInput
                {...spreadCommonProps(field)}
                delay={delay}
                {...otherProps}
              />
              ) : (
                <TextInput
                  {...spreadCommonProps(field)}
                  {...otherProps}
                />
              )}
          </FormFieldRegistrationManager>
        )
      }
    </FieldContext.Consumer>
  )
}

Text.propTypes = {
  /**
   * Whether the field is required, optionally pass a string to define a custom
   * validation error message
   */
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),

  /**
   * A custom validation function
   */
  validate: PropTypes.func,

  /**
   * Runs when field value changes (returns new value)
   */
  onValueChange: PropTypes.func,

  /**
   * Initial field value (used if form needs to be reset)
   */
  defaultValue: PropTypes.string,

  /**
   * Text to display when no value is present in the field
   */
  placeholder: PropTypes.string,

  /**
   * Use if you want a multiline text field
   */
  rows: PropTypes.number,

  /**
   * Use if you want a debounced input field
   */
  debounced: PropTypes.bool,

  /**
   * Use only if debounced is set to true
   */
  delay: PropTypes.number,

  /**
   * Type of the input (e.g., "email", "text")
   */
  type: PropTypes.string
}

Text.defaultProps = {
  required: false,
  validate: undefined,
  defaultValue: '',
  placeholder: undefined,
  rows: undefined,
  debounced: false,
  delay: 300
}

Text.displayName = 'Form.Field.Text'

export default Text
