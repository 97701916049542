const { isProductIDInTerritory } = require('../../../service/tools/territory-lookup')
const toShipmentLocationId = require('../../../shipment/tools/to-location-id')

const { DBNames } = require('./db-names')

// This contains id matching functions. The matching is per
// database and per direction. Where the direction is either sync
// from main-dbs to user-db or from user-db to main-dbs.
// For performance reasons this will not get to see the whole
// document, but just an { _id } object.
// If we would need the whole document in the future, we'd need
// to optimize the fetching of documents when the database is
// bootstrapped.
const mainToUserMatchers = {
  [DBNames.IntegratedData]: {
    match: ({ locationId, configId }, { _id }) => (
      _id && (
        _id === 'programs' ||
        _id === 'geolocations' ||
        _id === locationId ||
        _id === configId ||
        _id === `sales-stats:${locationId}` ||
        _id.startsWith(`allocation:${locationId}:`) ||
        (_id.startsWith('product:') && isProductIDInTerritory(_id, locationId, false))
      )
    ),
    includeDocs: false
  },
  [DBNames.Documents]: {
    match: ({ locationId }, { _id }) => (
      _id && (
        _id.startsWith(`documentEvent:${locationId}:`)
      )
    ),
    includeDocs: false
  },
  [DBNames.StockCounts]: {
    match: ({ locationId }, { _id }) => (
      _id && (
        _id === '_design/api' ||
        _id.startsWith(`${locationId}:`)
      )
    ),
    includeDocs: false
  },
  [DBNames.Shipments]: {
    match: ({ locationId }, { _id }) => {
      const shipmentLocationId = toShipmentLocationId(locationId)
      return (
        _id && (
          _id.includes(`:${shipmentLocationId}:`)
        )
      )
    },
    includeDocs: false
  },
  [DBNames.Orders]: {
    match: ({ locationId }, { _id, destinationId }) => (
      _id && (_id.startsWith('type:orderSnapshot') && destinationId === locationId)
    ),
    includeDocs: true
  }
}

const userToMainMatchers = {
  [DBNames.IntegratedData]: {
    match: ({ locationId }, { _id }) => (
      _id && (
        _id === locationId ||
        _id.startsWith(`allocation:${locationId}:`)
      )
    )
  },
  [DBNames.Documents]: {
    match: ({ locationId }, { _id }) => (
      _id && (
        _id.startsWith(`documentEvent:${locationId}:`)
      )
    )
  },
  // For top up requests
  [DBNames.Messages]: {
    match: ({ locationId }, { _id }) => (
      _id && (_id.startsWith('type:orderSnapshot') && _id.endsWith(`:location:${locationId}`))
    )
  },
  // To sync changes made to orders by retailer to mainDb
  [DBNames.Orders]: {
    match: ({ locationId }, { _id, destinationId }) => (
      _id && (_id.startsWith('type:orderSnapshot') && !_id.includes(':location') && destinationId === locationId)
    )
  }
}

module.exports = {
  userToMainMatchers,
  mainToUserMatchers
}
