module.exports.BY_WAREHOUSE_CODE_VIEW_NAME = 'by-warehouse-code'
// How this username gets applied:
// 1. When we update a couch doc from a lambda, we add this as the updatedBy.
// 2. When syncing from sales pipeline or making a SQL patch we want to apply
// to couch (e.g. our very first, import real.clients table), we add this as the
// sql updated_by username.
//
// How it is used:
// 1. Syncing from couch to sql, we EXCLUDE docs with this username.
// 2. Syncing from SQL to couch, we IGNORE updates with this username
// (to filter out updates to sql by couch in the first place.)
module.exports.BACKEND_SERVICE_USERNAME = 'fs-backend-synced-update'

// 1 - 5 is the ranking of prospective clients for the SL sales team
module.exports.ACCOUNT_ACTIVATED_LEVEL = '1'
module.exports.APPLICATION_APPROVED_LEVEL = '3'
module.exports.CLASSIC_LEAD_FULLY_MOBILIZED_LEVEL = '5'
// This constant is still used in a few places but the name is incorrect,
// since currently locations are created in FS from level 1 (ACCOUNT_ACTIVATED_LEVEL).
// The new constant to refer to this level is `APPLICATION_APPROVED_LEVEL`.
module.exports.CREATE_IN_FS_LEVEL = '3'
// This constant is still used in a few places but the name is also incorrect.
// `CLASSIC_LEAD_FULLY_MOBILIZED_LEVEL` should be used instead.
// Heads up, this lives in Python as well
module.exports.INITIALIZED_IN_OPS = '5'

const MEMBERSHIPS = module.exports.MEMBERSHIPS = {
  CLASSIC: 'classic',
  CLASSIC_PAYG_ONLY: 'classic-payg-only',
  BASIC: 'basic'
}

module.exports.isClassicMembership = (membership) => [
  MEMBERSHIPS.CLASSIC,
  MEMBERSHIPS.CLASSIC_PAYG_ONLY
].includes(membership)

module.exports.MOBILIZATION_IN_WEEKS = {
  STANDARD: 4,
  LONG: 13
}

module.exports.SERVICE_FEE_CONSTANTS = {
  'ke': 425,
  'ng': 2650
}

module.exports.PAY_SMALL_SMALL_LOCALIZATION = {
  'ke': 'Lipa Mdogo Mdogo',
  'ng': 'Pay Small Small'
}

module.exports.LOCATION_CLASSIFICATIONS = {
  PHARMACY: 'Pharmacy',
  CHEMIST_PHARM_TECH: 'Chemist/Pharm Tech',
  PPMV: 'PPMV',
  GENERAL_RETAILER: 'General Retailer',
  CLINICS_AND_HOSPITALS: 'Clinics and Hospitals',
  SUPPLIER: 'Supplier',
  PACK_POINT: 'Pack Point',
  EXTERNAL_DISTRIBUTION_CENTER: 'External Distribution Center'
}
