import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AccordionSection from './AccordionSection'

class Accordion extends Component {
  static propTypes = {
    /**
     * Component children
     */
    children: PropTypes.node.isRequired
  }

  static Section = AccordionSection

  render () {
    return (
      <div className='vs-accordion'>
        {this.props.children}
      </div>
    )
  }
}

export default Accordion
