import React from 'react'

import { DeemphasizedLink } from '@fielded/shared-ui'
import { Intercom } from '@fielded/shared-ui/src/icons'
import { userHasIntercom, bootIntercomBeforeLogin } from './init-intercom'

const LoginIntercomLink = ({ config }) => {
  const hasIntercom = userHasIntercom(config)

  if (!hasIntercom || !window.Intercom) {
    return null
  }

  return (
    <div className='login__intercom-wrapper intercom-trigger'>
      <span className='login__intercom-selector'>
        <span>Need help?</span>
        <span className='login__intercom-cta'>
          <Intercom />
          <DeemphasizedLink
            className='login__intercom-link'
            onClick={() => bootIntercomBeforeLogin(config)}
            colorVariant='light'
          >
            Chat with us
          </DeemphasizedLink>
        </span>
      </span>
    </div>
  )
}

export default LoginIntercomLink
