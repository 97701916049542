import React from 'react'

import { Button } from '@fielded/shared-ui'
import { Arrow, Checkmark } from '@fielded/shared-ui/src/icons'

const ServicesList = ({
  program,
  reportLocation,
  isEditablePeriod,
  onOpenReport
}) => (
  <div className='main-colum__content main-colum__content--services'>
    <h4 className='list-header--services'>{program.fullName} reports due for this location</h4>
    <ul className='list--services'>
      {reportLocation.progress.services.map(service => {
        if (isEditablePeriod) {
          const isComplete = service.progress.status === 'complete'
          const icon = isComplete
            ? <Checkmark />
            : <Arrow direction='right' />
          const fill = isComplete ? 'outline' : 'full'
          return (
            <li className='service-item' key={service.id}>
              <Button
                onClick={() => onOpenReport(service)}
                fill={fill}
                colorVariant='brand'
                icon={icon}
                iconSide='right'
                size='large'
                spread
              >
                {service.fullName}
              </Button>
            </li>
          )
        } else {
          return (
            <li className='service-item' key={service.id}>
              <Button
                colorVariant='brand'
                fill='full'
                size='large'
                spread
                disabled
              >
                {service.fullName}
              </Button>
            </li>
          )
        }
      })}
    </ul>
  </div>
)

export default ServicesList
