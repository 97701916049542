const {generateReportData} = require('./stock-situation-export')
const generateExcelSheet = require('./excel')

// returns an excel file thing and is called by the UI
async function exportStockSituation (state, locationId, serviceId, period, options = {}) {
  const result = await exportStockSituationData(state, locationId, serviceId, period, options)
  return generateExcelSheet(result)
}

// returns the data to be used in tests
async function exportStockSituationData (state, locationId, serviceId, period, options = {}) {
  return generateReportData(state, locationId, serviceId, period, options)
}

module.exports = { exportStockSituation, exportStockSituationData }
