module.exports = getLocationRank

const locationRanks = {
  lga: 0,
  state: 1,
  zone: 2,
  national: 3,
  country: 4
}

function getLocationRank (location = {}) {
  if (location.lga) {
    return locationRanks.lga
  }
  if (location.state) {
    return locationRanks.state
  }
  if (location.zone) {
    return locationRanks.zone
  }
  if (location.id === 'national') {
    return locationRanks.national
  }
  if (location.id === 'country') {
    return locationRanks.country
  }

  throw new Error('Unknown location')
}
