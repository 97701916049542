import { endOfMonth } from 'date-fns'
import tools, { PLANNING_TYPES } from '@fielded/fs-api/lib/shipment/tools'
import { updateShipment } from '../shipments/shipments-reducer'
import { selectedProducts } from './selectors'
import {
  productsWithUpdatedQuantity,
  productsWithToggledSelected,
  productsWithUpdatedPaymentType
} from './utils'
import { DIRECT_ORDER_TYPES } from '@fielded/fs-api/lib/allocation/config'

const reducerName = 'planning'
const UPDATE_SHIPMENT_PLANNER_PARAMS = `${reducerName}/UPDATE_SHIPMENT_PLANNER_PARAMS`
const TOGGLE_PRODUCT_SELECTED = `${reducerName}/TOGGLE_PRODUCT_SELECTED`
const UPDATE_PRODUCT_QUANTITY = `${reducerName}/UPDATE_PRODUCT_QUANTITY`
const UPDATE_PRODUCT_PAYMENT_TYPE = `${reducerName}/UPDATE_PRODUCT_PAYMENT_TYPE`
const RECEIVE_ERROR = `${reducerName}/RECEIVE_ERROR`

const initialState = {
  products: [],
  error: null
}

const updateShipmentPlannerParams = (state, {
  products
}) => {
  const decoratedProducts = products.map(product => ({
    ...product,
    selected: false,
    disabled: false,
    quantity: 0
  }))
  return {
    ...state,
    products: decoratedProducts
  }
}

const updateProductQuantityReducer = (state, action) => {
  return {
    ...state,
    products: productsWithUpdatedQuantity(state.products, action.productId, action.quantity)
  }
}

const updatePaymentTypeReducer = (state, action) => {
  return {
    ...state,
    products: productsWithUpdatedPaymentType(state.products, action.productId, action.paymentType)
  }
}

const updateSelected = (state, action) => {
  return {
    ...state,
    products: productsWithToggledSelected(state.products, action.productId)
  }
}

const receiveError = (state, { error }) => {
  return {
    ...state,
    error
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SHIPMENT_PLANNER_PARAMS: return updateShipmentPlannerParams(state, action)
    case UPDATE_PRODUCT_QUANTITY: return updateProductQuantityReducer(state, action)
    case UPDATE_PRODUCT_PAYMENT_TYPE: return updatePaymentTypeReducer(state, action)
    case TOGGLE_PRODUCT_SELECTED: return updateSelected(state, action)
    case RECEIVE_ERROR: return receiveError(state, action)
    default: return state
  }
}

export const setPlannerParams = (destinationId) => async (dispatch, getState, { api }) => {
  try {
    const products = await api.product.list(destinationId)
    return dispatch({
      type: UPDATE_SHIPMENT_PLANNER_PARAMS,
      products
    })
  } catch (e) {
    console.log('Planning error', e)
  }
}

export const updateProductQuantity = (productId, quantity) => ({
  type: UPDATE_PRODUCT_QUANTITY,
  productId,
  quantity
})

export const updatePaymentType = (productId, paymentType) => ({
  type: UPDATE_PRODUCT_PAYMENT_TYPE,
  productId,
  paymentType
})

export const toggleProductSelected = productId => ({
  type: TOGGLE_PRODUCT_SELECTED,
  productId
})

const getShoppingListWithPaymentTypes = planning => {
  const productDatabyId = (index, product) => {
    index[product._id] = {quantity: product.quantity, paymentType: product.paymentType}
    return index
  }
  const selected = selectedProducts(planning)
  return selected.reduce(productDatabyId, {})
}

const getCountsFromShoppingList = (planning, masterData, user, config, planningType) => {
  const { batches } = masterData
  const products = masterData.products.byId
  const shoppingListWithPaymentTypes = getShoppingListWithPaymentTypes(planning)
  const today = new Date()
  const expiresAfter = endOfMonth(today).toJSON()

  // SL don't need to get ledger balance to validate what the available products
  // the parent store have, so we create a dummy ledger balance so our tool can
  // easily parse it here:
  // https://github.com/fielded/van-store-api/blob/master/lib/tools/suggest-batches.js#L27
  // Also build simplified shopping list with just quantities as needed by suggestBatches
  const ledger = {}
  const shoppingList = {}
  for (var productId of Object.keys(shoppingListWithPaymentTypes)) {
    ledger[productId] = {}
    shoppingList[productId] = shoppingListWithPaymentTypes[productId].quantity
  }

  const result = tools.suggestBatches({
    ledger, batches, shoppingList, products, expiresAfter
  })

  // Add corresponding payment type to each batch
  for (let batchId in result) {
    const productId = batchId.split(':manufacturer')[0]
    const productPaymentType =
      shoppingListWithPaymentTypes[productId].paymentType ||
      (planningType === PLANNING_TYPES.IMMEDIATE_PURCHASE && DIRECT_ORDER_TYPES.IMMEDIATE_PURCHASE) ||
      DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL
    result[batchId].paymentType = productPaymentType
  }

  return result
}

export const updateShipmentWithProducts = (shipment, masterData, config) => (dispatch, getState, { user, api }) => {
  const { planning } = getState()

  const counts = getCountsFromShoppingList(planning, masterData, user, config, shipment.planningType)
  // This one should not need handle online/offline cause as long as
  // you can not create shipments that don't involve your own store
  return Promise.resolve(counts)
    .then(counts => {
      if (shipment.status !== 'received') {
        return api.shipment.saveChanges(shipment.snapshotId, counts)
      } else {
        return api.shipment.adjustment.draft(shipment.id, counts)
      }
    })
    .then(shipment => {
      dispatch(updateShipment(shipment))
      return shipment
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
