module.exports = onListUpdated

const {listBatches} = require('./batch-list')
const batchIdPattern = require('./../../van-data-models/van-batch-id-model').batchIdPattern

let changesFeed
const callbacks = []
const runCallbacks = list => callbacks.forEach(callback => callback(list))

function onListUpdated (state, callback) {
  callbacks.push(callback)

  if (!changesFeed) {
    changesFeed = state.masterDataDB.changes({
      live: true,
      since: 'now',
      include_docs: true
    }).on('change', info => {
      if (!(new RegExp(batchIdPattern).test(info.id))) {
        return
      }

      listBatches(state)

        .then(cache => {
          const cachedIndex = cache.findIndex(batch => batch._id === info.id)
          if (info.deleted) {
            return [...cache.slice(0, cachedIndex), ...cache.slice(cachedIndex + 1)]
          } else if (cachedIndex > -1) {
            return [
              ...cache.slice(0, cachedIndex),
              info.doc,
              ...cache.slice(cachedIndex + 1)
            ]
          } else {
            return [
              ...cache,
              info.doc
            ]
          }
        })

        .then(newCache => listBatches(state, {}, newCache))

        .then(runCallbacks)
    })
    return listBatches(state, { updateBefore: true })
  }
  return listBatches(state)
}
