const {getSupplierExport} = require('../tools/index')
const generateExcelSheet = require('./excel')

// returns an excel file thing and is called by the UI
function exportOrderNotes (state,
  suppliers,
  orders,
  locationsById,
  productsById,
  routesById,
  supplierId,
  locationId) {
  const result = getSupplierExport(suppliers,
    orders,
    locationsById,
    productsById,
    routesById)

  return generateExcelSheet(result, supplierId, locationId)
}

module.exports = {exportOrderNotes}
