module.exports = { create, update }

const proposalTools = require('../../tools')
const { withResponseRev } = require('../../../tools/utils')
const { reBuildProgramServices } = require('../../../tools/location-edits')

function create (state, params) {
  const doc = proposalTools.entityCreateToDoc(params)
  return state.proposalsDB.put(doc)
    .then(resp => withResponseRev(doc, resp))
    .then(proposalTools.docToEntity)
}

function update (state, { proposalId, status, user }) {
  return state.proposalsDB.get(proposalId)
    .then(async proposalEntity => {
      const userPrograms = user.programs
        .map(program => program.split('program:')[1]) // logged in user programs
      const proposalPrograms = Object.keys(proposalEntity.doc.programs) // updated programs
      let programDifference = proposalPrograms

      // compare and get the difference in the location program(s)
      if (proposalEntity.proposalType === 'update') {
        const { programs, version } = await state.locationsDB.get(proposalEntity.doc._id)
        let transformedPrograms = programs

        // If they are on different versions convert older version to new version
        if (version !== proposalEntity.doc.version) {
          transformedPrograms = Object.keys(programs).reduce((acc, program) => {
            acc[program] = reBuildProgramServices(programs[program])
            return acc
          }, {})
        }

        // location program(s) before update
        let proposalAndExistingPrograms = Object.keys(transformedPrograms).concat(proposalPrograms)
        // Only get unique values
        proposalAndExistingPrograms = Array.from(new Set(proposalAndExistingPrograms))
        programDifference = proposalAndExistingPrograms.filter(programId => {
          // Comparison function for each program here:
          return JSON.stringify(transformedPrograms[programId]) !== JSON.stringify(proposalEntity.doc.programs[programId])
        })
      }

      for (const program of programDifference) {
        if (!userPrograms.includes(program)) {
          return Promise.reject(new Error(`Logged in user does not have ${program} enabled, so can't approve location`))
        }
      }

      const doc = proposalTools.entityUpdateToDoc({ proposalEntity, user, status })
      return state.proposalsDB.put(doc)
        .then(resp => withResponseRev(doc, resp))
        .then(proposalTools.docToEntity)
    })
}
