import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { QuantityInput, QuantityDiff, PriceDisplay, Text, SubscriptionTypeLabel, DeemphasizedLink } from '@fielded/shared-ui'
import { DIRECT_ORDER_TYPES_HUMANIZED } from '@fielded/fs-api/lib/allocation/config'

import CountCheckmark from '../common/CountCheckmark'

class CountProduct extends Component {
  state = {
    checked: this.props.isChecked,
    quantity: this.props.picked
  }

  updateCount = (checked) => {
    const {
      onToggleChecked,
      batchId,
      isChecked,
      paymentType
    } = this.props

    onToggleChecked(batchId, parseInt(this.state.quantity, 10), !isChecked, paymentType)
  }

  handleCheck = () => {
    const checked = !this.state.checked
    this.setState({checked}, () => this.updateCount(checked))
  }

  highlightInput = event => event.target.select()

  render () {
    const {
      id,
      name,
      picked,
      total,
      isChecked,
      hasAdjustment,
      paymentType,
      originalTotal,
      hideManifest,
      ledgerQuantity,
      unitPrice,
      country,
      history,
      locationId,
      serviceId,
      translatedProductId,
      showLedgerLink,
      isPSMUser
    } = this.props

    const {
      quantity
    } = this.state

    const totalToUse = originalTotal || total
    const difference = picked - totalToUse
    const isMatching = difference === 0

    return (
      <div className={classnames(
        'count-product',
        {'count-product--adjustments': hasAdjustment},
        {'count-product--is-checked': !isChecked && hasAdjustment}
      )}>
        <CountCheckmark
          id={id}
          checked={isChecked}
          onChange={this.handleCheck}
          className='count-product__check'
        />
        <div className='count-product__info'>
          <div className='count-product__name'>
            {name}
            {!isPSMUser && (
              <div className='count-product__ledger-container'>
                {DIRECT_ORDER_TYPES_HUMANIZED[paymentType] && (
                  <SubscriptionTypeLabel size='small' subscriptionType={paymentType} />
                )}
                {(typeof ledgerQuantity !== 'undefined') && (
                  <Text bold size='standard' className='count-product__ledger'> Ledger qty: {ledgerQuantity} </Text>
                )}
              </div>
            )}
          </div>
          {(!hideManifest && !isPSMUser) && <div className='count-product__manifest'>
            <Text className='count-product__unit-price'>
              {'Unit price: '}
              <Text bold><PriceDisplay country={country} currencySymbol value={unitPrice} inColor /></Text>
            </Text>
            {isChecked && !isMatching && (
              <span className='count-product__diff'>
                <QuantityDiff difference={difference} />
              </span>
            )}
            <span>On manifest: {totalToUse}</span>
            <div>
              {showLedgerLink && (
                <DeemphasizedLink
                  onClick={() =>
                    history.push(`/reporting/ledger/${serviceId}/${locationId}/${translatedProductId}`)
                  }
                  colorVariant='brand'
                >
                  view ledger
                </DeemphasizedLink>
              )}
            </div>
          </div>}
        </div>
        <QuantityInput
          textAlign='right'
          value={quantity}
          onValueChange={({value}) => {
            this.setState({
              quantity: value
            })
          }
          }
          className='count-product__count'
          disabled={isChecked}
          onFocus={this.highlightInput}
          allowNegative={false}
        />
      </div>
    )
  }
}

CountProduct.propTypes = {
  /**
   * Exactly one batch for the untracked product
   */
  batchId: PropTypes.string.isRequired,
  /**
   * Unique id of the product being counted, used for count check label
   */
  id: PropTypes.string,
  /**
   * Product name to display on card
   */
  name: PropTypes.string,
  /**
   * The expected total/total on manifest.
   * Used as suggested value if no count has been performed yet.
   */
  total: PropTypes.number,
  /**
   * The unit price of the product
   */
  unitPrice: PropTypes.number,
  /**
   * Confirmed count
   */
  picked: PropTypes.number,
  /**
   * Should the card be checked off?
   */
  isChecked: PropTypes.bool,
  /**
   * Runs when user checks off the count
   */
  onToggleChecked: PropTypes.func.isRequired,
  /**
   * If present, should be used instead of total prop.
   * Sourced from shipments with additional sales stats
   */
  originalTotal: PropTypes.number,
  /** Quantity of ledger in origin pack point **/
  ledgerQuantity: PropTypes.number
}

export default CountProduct
