const shortid = require('shortid')
const tools = require('../../tools')
const { DIRECT_ORDER_TYPES } = require('../../../allocation/config.js')
const { format } = require('date-fns')

const createRequest = async (
  state,
  mainApi,
  { type,
    supplierType,
    products,
    patientId,
    patientName,
    deliveryDate,
    vat,
    deliveryFee,
    totalAmount,
    orderAmount,
    paymentStatus,
    paymentChoice
  }
) => {
  const user = state.user
  const programId = user.programs[0]
  const destinationId = state.user.location.id

  const orderProducts = Object.fromEntries(Object.entries(products).map(
    ([key, value]) => {
      const details = {
        allocationType: value.type,
        // When the payment type is pay-as-you-sell we use a placeholder quantity of 1
        // until forcasting has calculated a proper quantity. If we don't set a quantity
        // then the product would get removed from the order.
        original: value.type === DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL ? 1 : value.quantity
      }
      if (value.prescription) { details.prescription = value.prescription }

      return [key, details]
    }
  ))

  const destination = await mainApi.location.get(destinationId)
  const [ supplierId ] = await mainApi.order.getSupplierIdsForLocation(destination, {planType: supplierType || type})

  const location = await mainApi.location.get(destinationId)
  const params = {
    status: 'request',
    orderId: shortid.generate(),
    groupId: shortid.generate(),
    programId,
    destinationId,
    supplierId,
    routeId: tools.getLocationProgramRoute(location, programId),
    products: orderProducts,
    reports: [],
    user,
    timestamp: new Date().toJSON(),
    orderType: type,
    doNotAddUpdateParams: true,
    appendLocationToId: true,
    deliveryDate,
    deliveryFee,
    vat,
    totalAmount,
    orderAmount
  }

  if (patientId || patientName) {
    params.status = 'new'
    params.patientName = patientName
    params.patientId = patientId
    params.appendLocationToId = false
    params.deliveryDate = format(new Date(), 'YYYY-MM-DDThh:mm')
    params.orderType = 'patient'
  }

  if (paymentStatus) {
    params.paymentStatus = paymentStatus
  }

  if (paymentChoice) {
    params.paymentChoice = paymentChoice
  }

  const snapshot = tools.createSnapshot(params)
  await state.dal.order.bulkDocs(state, [snapshot])
  return snapshot
}

module.exports = {
  createRequest
}
