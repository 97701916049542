import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import withOfflineState from '@fielded/shared-ui/src/common/offline'

import { Button } from '@fielded/shared-ui'
import { configure as configurePage } from '@fielded/shared-ui/src/components/Page'

import Routes from './Routes'

import { withUser } from '../../../common/AuthenticationProvider'
import { withApi } from '../../../common/ApiProvider'

const RoutesWithOfflineState = withOfflineState(Routes)

class AppContainer extends Component {
  constructor () {
    super()
    this.state = {
      error: {},
      initialised: false,
      isAuthenticated: false,
      loading: false
    }
  }

  componentDidMount () {
    const {
      user
    } = this.props

    configurePage({
      linkRenderer: function (props) { return (<Link {...props} />) }
    })

    if (user) {
      this.setState({ isAuthenticated: true })
    }
  }

  onAuthError = () => {
    this.setState({ isAuthenticated: false })
  }

  render () {
    const { error, isAuthenticated, loading } = this.state

    if (!isAuthenticated) {
      return <p>Something went wrong with authentication</p>
    }

    if (error && error.message) {
      return (
        <div>
          <p>Could not initialise. Check your internet connection.</p>
          <p>The error was:</p>
          <pre>{JSON.stringify(error, null, 2)}</pre>
          <Button
            onClick={() => window.location.reload()}
            colorVariant='brand'
            fill='full'
            disabled={loading}
          >
            Retry
          </Button>
        </div>
      )
    }

    return (
      <RoutesWithOfflineState
        basename={this.props.routerParams.basename}
        rootHistory={this.props.rootHistory}
        config={this.props.config}
        user={this.props.user}
      />
    )
  }
}

export default withApi(withUser(AppContainer))
