const getReservationQuery = `
/*
  We start by setting the base row, this query returns only one row so we need a base to join the other ctes to
*/
WITH product_definition AS (
  SELECT
    old_sku_id AS sku,
    display_name AS product
  FROM avocado.data_productsku dp
  WHERE dp.old_sku_id = $2
),
/* We get all status new shipments less than two weeks old for the specified sku supplied by the specified location */
shipment_base AS (
  SELECT
    dl.uuid AS location_id,
    sku,
    amount,
    s.shipment_id,
    s.destination_id,
    'shipment' AS type
  FROM
    avocado.data_shipment s
    LEFT JOIN avocado.data_shipmentline sl ON sl.shipment_id = s.id
    LEFT JOIN avocado.data_location dl ON dl.fsid = s.origin_id
  WHERE
    status = 'new'
    AND s.created_at > current_date - '2 weeks'::interval
    AND uuid::text = $1
    AND sl.sku = $2
),
shipments as (
  SELECT
    location_id,
    sku,
    amount,
    shipment_id,
    destination_id,
    type,
    SUM(amount) OVER () AS total
  FROM shipment_base
),
/* Get all open orders within two weeks that are supplied by the specified location */
orders_base as (
  SELECT
    dl.uuid as location_id,
    dp.display_name  product,
    adjusted as amount,
    o.order_id,
    o.destination_id,
    'order' as type
  FROM
    avocado.data_order o
    inner join avocado.data_orderline ol on ol.order_id = o.id
    inner join avocado.data_location dl on dl.fsid = o.destination_id
    inner join avocado.data_market dm
    on dl.market_id =dm.id
    inner join avocado.data_productsku dp
    on ol.sku=dp.old_sku_id
  WHERE closed_status is null and is_complete = false and
  (coalesce(dl.supply_chain_level, 'sdp') = 'sdp'  or dl.classification = 'External Distribution Center')
  and dm.country_code in ('ke', 'ng')
  AND o.created_at > current_date - '2 weeks'::interval
  AND o.supplier_id::text = $3
),
orders as (
  SELECT
    product,
    amount,
    order_id,
    destination_id,
    type,
    sum(amount) OVER () as total
  from orders_base ob
  inner join avocado.data_productsku dp
    on dp.old_sku_id = $2
  WHERE dp.display_name = ob.product
)
SELECT
  dl.fsid as location_fsid,
  s.total as shipments,
  o.total as orders,
  json_agg(json_build_object('shipmentId', s.shipment_id , 'amount', s.amount, 'type', s.type, 'destination', s.destination_id)) as shipment_reservations,
  json_agg(json_build_object('orderId', o.order_id, 'amount', o.amount, 'type', o.type, 'destination', o.destination_id)) as order_reservations
FROM
  product_definition pd
  left join shipments s on s.sku = pd.sku
  left join avocado.data_location dl on dl.uuid = $1
  inner join avocado.data_productsku ps on ps.old_sku_id = $2 and not ps._deleted and ps.market_id = dl.market_id
  left join orders o on pd.product = o.product
GROUP BY 1, 2, 3
`

module.exports = getReservationQuery
