import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

import { Banner, DownloadButton } from '@fielded/shared-ui'

import {withApi} from '../../../common/ApiProvider'
import {getServiceForLocationId} from '@fielded/fs-api/lib/service/tools/territory-lookup'

class StockSituationDownloadButton extends Component {
  state = {
    loading: false,
    error: null
  }

  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    period: PropTypes.string.isRequired,
    program: PropTypes.object.isRequired
  }

  handleClick = async () => {
    this.setState({loading: true})

    const {api, user, period, adjustments} = this.props

    const { service: serviceId } = getServiceForLocationId(user.location.id)

    let blob
    try {
      blob = await api.stock.exportStockSituation(user.location.id, serviceId, period, { adjustments })
    } catch (error) {
      console.error(error)
      this.setState({loading: false, error})
    }

    this.downloadLinkEl.href = global.URL.createObjectURL(blob)
    this.downloadLinkEl.click()
    this.setState({loading: false})
  }

  render () {
    const content = (() => {
      const cta = (
        <DownloadButton
          loading={this.state.loading}
          loadingLabel='Generating report ...'
          onClick={this.handleClick}
        >
          Export stock situation
        </DownloadButton>
      )
      if (this.state.error) {
        return (
          <Banner
            inline
            type='warning'
            cta={cta}
          >
            {`Generating export failed: ${this.state.error.message}`}
          </Banner>
        )
      }
      return cta
    })()
    return (
      <Fragment>
        {/* eslint-disable */}
        <a
          download={`report-${new Date().toJSON()}.xlsx`}
          ref={(el) => { this.downloadLinkEl = el }}
          style={{display: 'none'}}
        />
        {/* eslint-enable */}
        {content}
      </Fragment>
    )
  }
}

export default withApi(StockSituationDownloadButton)
