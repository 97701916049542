module.exports = routeDoc => {
  const keys = [
    '_id',
    'name',
    'firstDeliveryDate',
    'firstCountDate',
    'day',
    'countDay',
    'uuid',
    'deliveryFrequencyWeeks',
    'countFrequencyWeeks',
    'startDate',
    'endDate'
  ]
  const route = {}
  keys.forEach(key => {
    if (routeDoc[key] !== undefined) {
      route[key] = routeDoc[key]
    }
  })
  return route
}
