import React, { Fragment, lazy, Suspense, useRef } from 'react'
import { useParams } from 'react-router'
import get from 'lodash/get'

import { Loading, Page } from '@fielded/shared-ui'
import { getProgramReadableName } from '@fielded/fs-api/lib/program/tools'
import capitalize from '@fielded/shared-ui/src/utils/capitalize'
import { SHIPMENT_STATUS, SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS } from '@fielded/fs-api/lib/shipment/constants'

import { getNavigationItem } from '../../../../../../common/utils/navigationInfo'

import { VERIFICATION_STATUS } from './utils'
import LMDListStatusDistribution from './LMDListStatusDistribution'
import LMDListTable from './LMDListTable'
import LMDListControls from './LMDListControls'

import TimeNavigator from '../../../../../../van-shared/components/TimeNavigator'

const LMDMap = lazy(() => import(/* webpackChunkName: "feature-lazy-LMD-map" */ './LMDMap/LMDMap'))

export const VIEWS = {
  LIST: 'list',
  MAP: 'map'
}

const LMDList = ({
  history,
  programs,
  appPrograms,
  locationsById,
  fundersById,
  routesById,
  shipments,
  totalShipmentsNumber,
  pageLimit,
  config,
  filters,
  filterOptions,
  onFilterChange,
  onPageChange,
  exportDeliveries,
  loadingShipments
}) => {
  const { view: currentView } = useParams()

  const statusesForFilter = Object.values(SHIPMENT_STATUS)
  const verificationStatusesForFilter = Object.values(VERIFICATION_STATUS)
  const timeOptionsExpand = useRef()
  const currentOffset = Number(filters.offset)

  // default to the list view
  const view = !currentView ? VIEWS.LIST : currentView

  const filterItems = {
    route: {
      name: 'Route',
      items: filterOptions.route.filter(x => x).map(route => ({
        label: get(routesById[route], 'name', route),
        value: route,
        active: filters.route === route
      }))
    },
    state: {
      name: 'State',
      items: filterOptions.state.filter(x => x).map(state => ({
        label: state,
        value: state,
        active: filters.state === state
      }))
    },
    source: {
      name: 'Source',
      items: filterOptions.source.filter(x => x).map(sourceId => ({
        label: get(locationsById[sourceId], 'name', sourceId),
        value: sourceId,
        active: filters.source === sourceId
      }))
    },
    destination: {
      name: 'Destination',
      items: filterOptions.destination.filter(x => x).map(destinationId => ({
        label: get(locationsById[destinationId], 'name', destinationId),
        value: destinationId,
        active: filters.destination === destinationId
      }))
    },
    program: {
      name: 'Program',
      items: programs.map(program => {
        const programName = getProgramReadableName(program, appPrograms) || program
        return {
          value: program,
          label: programName,
          active: filters.program === program
        }
      })
    },
    funder: {
      name: 'Funder',
      items: filterOptions.funder.filter(x => x).map(funder => ({
        label: get(fundersById[funder], 'name', 'funder'),
        value: funder,
        active: filters.funder === funder
      }))
    },
    //    deliveryDate: {
    //      name: 'Delivery Date',
    //      items: filterOptions.deliveryDate.filter(x => x).map(deliveryDate => ({
    //        label: deliveryDate,
    //        value: deliveryDate,
    //        active: filters.deliveryDate === deliveryDate
    //      }))
    //    },
    creationDate: {
      name: 'Creation Date',
      items: Array.from(
        new Set(
          filterOptions.creationDate.filter(x => x)
            .map(creationDate => {
              const date = new Date(creationDate)
              return date.toISOString().split('T')[0]
            })
        )
      ).map(uniqueDate => ({
        label: uniqueDate,
        value: uniqueDate,
        active: filters.creationDate === uniqueDate
      }))
    },
    thirdParty: {
      name: '3PL',
      items: filterOptions.thirdParty.filter(x => x).map(thirdParty => ({
        label: thirdParty,
        value: thirdParty,
        active: filters.thirdParty === thirdParty
      }))
    },
    status: {
      name: 'Status',
      items: statusesForFilter.map(status => ({
        label: capitalize(SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS[status]),
        value: status,
        active: filters.status === status
      }))
    },
    verification: {
      name: 'Verification',
      items: verificationStatusesForFilter.map(verificationStatus => ({
        label: verificationStatus,
        value: verificationStatus,
        active: filters.verification === verificationStatus
      }))
    }
  }

  return (
    <Page
      className='shipments'
    >
      <Page.ComposableHeader>
        <Page.ComposableHeader.Breadcrumb
          items={[getNavigationItem({ item: 'shipments', config, includePath: false })]}
        />
        <Page.ComposableHeader.Content spread>
          <Page.ComposableHeader.Title title='Last mile deliveries' />
          <TimeNavigator
            className='vs-u-margin-bottom-double'
            filters={filters}
            history={history}
            programIds={programs}
            timeOptionsExpand={timeOptionsExpand}
            onTimeframeSelected={onFilterChange}
            centered={false}
            hasNoCycleOptions
          />
        </Page.ComposableHeader.Content>
        <Page.ComposableHeader.Filters
          filters={filterItems}
          onFilterChange={onFilterChange}
          inactiveFiltersVisible={false}
        />
      </Page.ComposableHeader>
      <Page.Panel>
        <LMDListStatusDistribution shipments={shipments} />
      </Page.Panel>

      <Page.Panel withMinimalMargin>
        <LMDListControls
          className='vs-u-margin-top'
          onPageChange={onPageChange}
          currentOffset={currentOffset}
          totalShipmentsNumber={totalShipmentsNumber}
          pageLimit={pageLimit}
          view={view}
          history={history}
          withViewChangeControls
          exportDeliveries={exportDeliveries}
        />
      </Page.Panel>
      {view === VIEWS.LIST && (
        <Page.Panel withMinimalMargin>
          <Fragment>
            <LMDListTable
              history={history}
              programs={programs}
              appPrograms={appPrograms}
              locationsById={locationsById}
              fundersById={fundersById}
              shipments={shipments}
              view={view}
              loadingShipments={loadingShipments}
            />
            <LMDListControls
              className='vs-u-margin-top'
              view={view}
              onPageChange={onPageChange}
              currentOffset={currentOffset}
              totalShipmentsNumber={totalShipmentsNumber}
              pageLimit={pageLimit}
              exportDeliveries={exportDeliveries}
            />
          </Fragment>
        </Page.Panel>
      )}

      {view === VIEWS.MAP && (
        <Suspense fallback={<Loading />}>
          <LMDMap
            shipments={shipments}
            locationsById={locationsById}
            fundersById={fundersById}
            appPrograms={appPrograms}
          />
        </Suspense>
      )}
    </Page>
  )
}

export default LMDList
