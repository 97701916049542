import { render } from 'react-dom'

import Root from './root/Root'

import withInitialisers from './common/with-initialisers'
import { register as registerServiceWorker } from './register-service-worker'

import './van-app-shell.scss'

registerServiceWorker()

const rootElement = document.getElementById('app-host')
const initialisedRoot = withInitialisers(Root)
render(initialisedRoot, rootElement)

if (process.env.NODE_ENV !== 'production' && module && module.hot) {
  module.hot.accept('./root/Root', () => {
    const nextInitialisedRoot = withInitialisers(Root)
    render(nextInitialisedRoot, rootElement)
  })
}
