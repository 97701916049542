import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Text from '../../../Text'
import { CheckmarkCircle, ExclamationCircle } from '../../../../icons'

const statusIcons = {
  success: <CheckmarkCircle />,
  warning: <ExclamationCircle />,
  danger: <ExclamationCircle />
}

const PanelHeaderTitle = ({ children, status }) => {
  const statusIcon = statusIcons[status]

  return (
    <Text.Heading
      tight
      className={classnames(
        'vs-panel-header-title',
        {'vs-panel-header-title--status-info': status === 'info'},
        {'vs-panel-header-title--status-success': status === 'success'},
        {'vs-panel-header-title--status-danger': status === 'danger'},
        {'vs-panel-header-title--status-warning': status === 'warning'}
      )}
    >
      {statusIcon && (
        <div className='vs-panel-header-title__status-icon'>
          {statusIcon}
        </div>
      )}
      {children}
    </Text.Heading>
  )
}

PanelHeaderTitle.propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.string
}

PanelHeaderTitle.defaultProps = {
  status: undefined
}

PanelHeaderTitle.displayName = 'Page.Panel.Header.Title'

export default PanelHeaderTitle
