import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const thresholdKeys = ['critical', 'ok', 'good', 'extra', 'noData']

const ThresholdColor = ({
  children,
  colorCode,
  saturation,
  fillWidth,
  className
}) => {
  return (
    <span
      className={classnames(
        'threshold-color',
        {'threshold-color--critical': colorCode === 'critical'},
        {'threshold-color--ok': colorCode === 'ok'},
        {'threshold-color--good': colorCode === 'good'},
        {'threshold-color--extra': colorCode === 'extra'},
        {'threshold-color--no-data': colorCode === 'noData'},
        {'threshold-color--faded': saturation === 'faded'},
        {'threshold-color--dimmed': saturation === 'dimmed'},
        {'threshold-color--underlined': saturation !== 'full' && children},
        className
      )}
      style={fillWidth ? { transform: `scaleX(${fillWidth / 100}` } : null}
    >
      {children}
    </span>
  )
}

ThresholdColor.propTypes = {
  /**
   * Content to render inside
   */
  children: PropTypes.node,
  /**
   * What status should the block indicate
   */
  colorCode: PropTypes.oneOf(['critical', 'ok', 'good', 'extra', 'noData', null]).isRequired,
  /**
   * How saturated should the color be, options match the color set
   */
  saturation: PropTypes.oneOf(['full', 'faded', 'dimmed']),
  /**
   * How much of the item should be filled with the indicator color.
   */
  fillWidth: PropTypes.number
}

ThresholdColor.defaultProps = {
  saturation: 'full'
}

export default ThresholdColor
export { thresholdKeys }
