module.exports = setup

const findOrGenerateDeviceId = require('./tools/find-or-generate-device-id')

function setup (state) {
  return findOrGenerateDeviceId(state)

    .then(doc => {
      state.device.id = doc.deviceId
    })
}
