// TODO: support REST as well, and move the conditional switch on rest adapter vs. pgConnection
// to this class if there's no pgConnection.
const EntityApi = require('../common/entity-api')
const PaymentMethodNodeAdapter = require('./payment-method-node-adapter')

class PaymentMethodAdapter extends EntityApi {
  constructor (pgConnection, username, remitaInterface, safaricomInterface) {
    const nodeAdapter = new PaymentMethodNodeAdapter(pgConnection, username, remitaInterface, safaricomInterface)
    super(nodeAdapter)
    this.nodeAdapter = nodeAdapter
  }
}

module.exports = PaymentMethodAdapter
