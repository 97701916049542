const { getISOYear, getISOWeek, getYear, getMonth } = require('date-fns')
const { subTZ } = require('./tz')

// Create an id for the period object.
// The id will have a format like '2019-M03'
module.exports = (periodDefinition, effectiveStartDate) => {
  const periodType = periodDefinition.periodType
  const prependZero = n => n < 10 ? '0' + n : '' + n
  const date = new Date(effectiveStartDate)
  switch (periodType.unit) {
    case 'week':
      const week = getISOWeek(subTZ(date))
      // When we use ISO week, we need to use ISO year, which is different from calendar year:
      // https://date-fns.org/v1.30.1/docs/getISOYear
      return getISOYear(subTZ(date)) + '-W' + prependZero(week)
    case 'month':
      const month = getMonth(subTZ(date)) + 1
      return getYear(subTZ(date)) + '-M' + prependZero(month)
    default:
      throw new Error('Unsupported period type: ' + JSON.stringify(periodType))
  }
}
