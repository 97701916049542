const { PGAdapter } = require('../../common')

const TABLE_NAME = 'avocado.data_locationordersstatushistory'

class LocationOrdersStatusHistoryAdapter extends PGAdapter {
  constructor ({ pgConnection, username, logger }) {
    const columns = ['id', 'location_id', 'new_value', 'created_at']
    super(
      pgConnection,
      TABLE_NAME,
      username,
      columns,
      'id',
      {},
      logger
    )
    this.pgConnection = pgConnection
  }

  async latestStatus (uuids) {
    const { rows } = await this.pgConnection.query({
      text: `
        select distinct on (location_id) location_id, new_value
        from ${TABLE_NAME}
        where location_id = any($1)
        order by location_id, created_at desc
      `,
      values: [uuids]
    })
    return rows
  }
}

module.exports = LocationOrdersStatusHistoryAdapter
