const PGAdapter = require('../common/pg-adapter')
const PAYMENT_PLAN_OPTIONS_COLUMNS = [
  'id',
  'order_id',
  'service_fee_percentage',
  'frequency',
  'term',
  'location_id',
  'created_at',
  'updated_at'
]
const { LOCATION_CLASSIFICATIONS, MEMBERSHIPS } = require('../location/tools')
const {
  PAYMENT_PLAN_FREQUENCIES,
  PAYMENT_OPTION_BASE_ORDER_AMOUNT,
  PAYMENT_PLAN_OPTIONS_TABLE_NAME,
  CLINICS_AND_HOSPITALS_DEFAULT_FEE_PERCENTAGE,
  CLINICS_AND_HOSPITALS_DEFAULT_FREQUENCY,
  CLINICS_AND_HOSPITALS_DEFAULT_PAYMENT_TERM
} = require('./constants')
const { getPamentPlanOptionQuery, getPaymentOptionViaTxnIdQuery } = require('./queries/payment-option-queries')

class PaymentPlanOptionsAdapter extends PGAdapter {
  constructor (pgConnection, username) {
    super(
      pgConnection,
      PAYMENT_PLAN_OPTIONS_TABLE_NAME,
      username,
      PAYMENT_PLAN_OPTIONS_COLUMNS
    )
  }

  generatePaymentPlanOptions ({ countryCode, classification, orderAmount, membershipType }) {
    const error = new Error()
    error.status = 400
    if (!Object.values(LOCATION_CLASSIFICATIONS).includes(classification)) {
      error.message = 'Invalid location classification'
      throw error
    }
    if (!['KE', 'NG'].includes(countryCode)) {
      error.message = 'Invalid country code'
      throw error
    }
    if (membershipType !== MEMBERSHIPS.CLASSIC_PAYG_ONLY) {
      // no payment plan options should be returned for non MC clients
      return {}
    }

    const defaultWeeklyTerms = {
      frequency: PAYMENT_PLAN_FREQUENCIES.WEEKLY,
      serviceFeePercentage: 0
    }

    const paymentTerms = {
      WEEKLY: {},
      MONTHLY: {},
      BIMONTHLY: {},
      TRIMONTHLY: {}
    }

    paymentTerms.WEEKLY = {
      4: {
        term: 4,
        ...defaultWeeklyTerms
      },
      6: {
        term: 6,
        ...defaultWeeklyTerms
      },
      8: {
        term: 8,
        frequency: PAYMENT_PLAN_FREQUENCIES.WEEKLY,
        serviceFeePercentage: 0.25
      }
    }

    if (orderAmount > PAYMENT_OPTION_BASE_ORDER_AMOUNT[countryCode].amount && classification !== LOCATION_CLASSIFICATIONS.CLINICS_AND_HOSPITALS) {
      paymentTerms.WEEKLY = {
        ...paymentTerms.WEEKLY,
        12: {
          term: 12,
          frequency: PAYMENT_PLAN_FREQUENCIES.WEEKLY,
          serviceFeePercentage: 0.5
        },
        24: {
          term: 24,
          frequency: PAYMENT_PLAN_FREQUENCIES.WEEKLY,
          serviceFeePercentage: countryCode === 'KE' ? 1.5 : 1.75
        }
      }
    }

    if (classification === LOCATION_CLASSIFICATIONS.CLINICS_AND_HOSPITALS) {
      // dont return weekly terms for clinics and hospitals
      paymentTerms.WEEKLY = {}
      paymentTerms.MONTHLY = {
        1: {
          term: CLINICS_AND_HOSPITALS_DEFAULT_PAYMENT_TERM,
          frequency: CLINICS_AND_HOSPITALS_DEFAULT_FREQUENCY,
          serviceFeePercentage: countryCode === 'KE'
            ? CLINICS_AND_HOSPITALS_DEFAULT_FEE_PERCENTAGE.KE
            : CLINICS_AND_HOSPITALS_DEFAULT_FEE_PERCENTAGE.NG
        }
      }
      paymentTerms.BIMONTHLY = {
        1: {
          term: 1,
          frequency: PAYMENT_PLAN_FREQUENCIES.BIMONTHLY,
          serviceFeePercentage: countryCode === 'KE' ? 0.5 : 1
        }
      }
      paymentTerms.TRIMONTHLY = {
        1: {
          term: 1,
          frequency: PAYMENT_PLAN_FREQUENCIES.TRIMONTHLY,
          serviceFeePercentage: countryCode === 'KE' ? 1.5 : 1.75
        }
      }
    }

    return paymentTerms
  }

  async getPaymentPlanOption (orderId, locationUuid) {
    if (!orderId || !locationUuid) {
      throw new Error('orderId & locationId required')
    }
    const { rows } = await this.pgConnection.query(getPamentPlanOptionQuery, [orderId, locationUuid])
    return rows[0]
  }

  async getPaymentPlanOptionViaTxnId (txnId, companyCode, locationUuid) {
    if (!txnId || !companyCode) {
      throw new Error('invoice txnId and companyCode required')
    }
    const params = [txnId, companyCode]
    if (locationUuid) {
      params.push(locationUuid)
    }
    const { rows } = await this.pgConnection.query(getPaymentOptionViaTxnIdQuery('$1', '$2', locationUuid), params)
    return rows[0]
  }
}

module.exports = PaymentPlanOptionsAdapter
