const { EntityApi } = require('../common')
const PGAdapter = require('./../common/pg-adapter')

class PriceBonusSharePGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = [
      'id',
      'month',
      'market_id',
      'share'
    ]
    const opts = {
      addUuid: false
    }
    super(pgConnection, 'avocado.price_calculator_bonus_share', username, columns, 'id', {}, logger, opts)
  }
}

class PriceBonusPGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = [
      'id',
      'month',
      'sku',
      'product_name',
      'total_bought',
      'bonus_earned',
      'price_percentage'
    ]
    const opts = {
      virtualColumns: ['market', 'supplier', 'product']
    }
    super(pgConnection, 'avocado.price_calculator_bonus', username, columns, 'id', {}, logger, opts)
    this.tableExpression = `
      (
        select
          b.*,
          m.id as market_id,
          p.product,
          p.supplier
        from
          avocado.price_calculator_bonus b
          left join avocado.vw_products p on b.sku = p.product_id
          left join avocado.data_market m on p.market = any(m.aliases)
        order by sku, m.id
      ) t
    `
  }

  async getList ({ ordering = this.idColumn, filter = {}, limit = 50, offset = 0 } = {}) {
    const res = await PGAdapter.prototype.getList.call(this, {ordering, filter, limit, offset, tableExpression: this.tableExpression})
    return res
  }

  async getOne (id, { whereCondition = this.idColumn } = {}) {
    return PGAdapter.prototype.getOne.call(this, id, {whereCondition, tableExpression: this.tableExpression})
  }

  async upsert (data) {
    const res = await PGAdapter.prototype.upsert.call(this, data, {conflictIdColumns: ['month', 'sku']})
    return res
  }
}

class PriceBonusApi extends EntityApi {
  constructor (state, pgConnection, logger) {
    const { user = {} } = state
    const username = user.name
    const opts = {pgConnection, username, logger}

    const adapter = new PriceBonusPGAdapter({...opts})

    super(adapter)

    this.share = new PriceBonusSharePGAdapter({...opts})
  }
}

module.exports = PriceBonusApi
