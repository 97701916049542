const get = require('lodash/get')
const { territoriesByCountry, getTerritoryForProduct } = require('../../service/tools')
const { construct, getCurrentPrice, isDiscontinued } = require('../tools')

const { VAT_PER_MARKET } = require('../constants')

const Format = {
  // Format couch serializes the postgres data in couchdb product doc format
  Couch: 'couch',
  // Format product-list reduces the data to only the fields used in the
  // product list on the frontend to save bandwidth.
  ProductList: 'product-list',
  // Format postgres leaves the data as its returned from the postgres query
  Postgres: 'postgres'
}

const rowToProduct = (row) => {
  // Don't put all keys from the RDS product DB in CouchDB
  const additionalKeys = [
    'product_id',
    'order_sku',
    'market',
    'product',
    'category',
    'inventory_sensitivity',
    'display_order',
    'phase',
    'available',
    'ready',
    'promoted',
    'ppmv',
    'ppmv_core',
    'therapeutic_class',
    'api',
    'substitutes',
    'manufacturer',
    'distributor',
    'supplier',
    'presentation_packaging',
    'visibility_1',
    'markup_1',
    'visibility_2',
    'markup_2',
    'vat'
  ]
  const productId = `product:${(row.product_id + '').replace(/ /g, '')}`
  const doc = {
    _id: productId,
    basicUnit: row.presentation_packaging,
    code: row.product_id,
    description: '',
    fullName: row.product,
    name: row.product,
    presentation: row.presentation_packaging,
    prices: getSellPrices(row),
    buyPrices: getBuyPrices(row),
    alias: getProductAliases(row.alias),
    vats: getVats(row, productId),
    productType: row.category,
    services: [],
    type: 'product',
    unitOfIssue: 1,
    eligibleForPAYS: row.eligible_for_pays,
    eligibleForOD: row.eligible_for_od,
    segments: row.segments
  }
  // add a few selected key as additionalData
  doc.additionalData = Object.keys(row)
    .filter(k => !Object.keys(doc).includes(k))
    .filter(key => additionalKeys.includes(key))
    .reduce((a, c) => {
      a[c] = row[c]
      return a
    }, {})

  // Note: this assumes that a sellprice of 0 is not a valid price and so
  // does getCurrentPrice
  const hasValidSellprice = !!getCurrentPrice(row.prices, 'sellprice')

  doc.active = !row._deleted &&
    !isDiscontinued(doc) &&
    hasValidSellprice

  // TODO: once we start using the new `active` property there would
  // be no need to edit `available` anymore
  if (!hasValidSellprice) {
    doc.additionalData.available = false
  }

  return construct(doc)
}

// Used to dedupe these prices because they were heavily duplicated
// That is not so anymore due to changes in the price_history table
// We want to therefore allow duplicates right now, so that they can
// for instance overwrite mistakes on the fs price history.
// Note: getCurrentPrice filters out null, undefined or 0 prices,
// should we do the same here?
const getSellPrices = (row) => row.prices
  .map(({date, sellprice, discount, financingMarkup, deliveryMarkup}) => {
    return {
      date: new Date(date).toJSON(),
      price: sellprice,
      financingMarkup,
      deliveryMarkup,
      ...(discount ? { discount } : {})
    }
  })

const getBuyPrices = (row) => {
  return row.prices
    .map(({date, buyprice}) => {
      return {
        date: new Date(date).toJSON(),
        price: buyprice
      }
    })
}

// This is used to add the product aliases for that territory to the product docs
const getProductAliases = (alias) => {
  return alias.reduce((acc, d) => {
    const { sku } = d
    const territory = getTerritoryForProduct(sku)

    if (territory && territory.service) {
      acc[territory.service] = [sku]
    } else {
      console.error(`Invalid alias will be skipped: ${sku}`)
    }
    return acc
  }, {})
}

// this is used in sl but doesn't have a historical source in the rest api
// create a hardcoded vat if `vat` is not null and not zero.
// If we change this to be dynamic, we should also update the merging of
// vats in `api/lib/product/utils/index.js::mergeProducts`.
const getVats = (row, productId) => {
  if (!row.vat || row.vat === '0') {
    return []
  }
  const kenyaTerritories = territoriesByCountry('country:ke')
  const isKenyaProduct = kenyaTerritories
    .map(t => t.isProductIDMemberFunction)
    .some(f => f && f(productId))
  if (isKenyaProduct) {
    return VAT_PER_MARKET.ke
  }
  return VAT_PER_MARKET.ng
}

const rowsToProducts = (rows) => {
  const docs = []
  for (const row of rows) {
    try {
      docs.push(rowToProduct(row))
    } catch (err) {
      console.warn(`Cannot construct product from row: ${row.product_id}`, err)
    }
  }
  return docs
}
const serialize = (rows, format = Format.Postgres) => {
  for (const row of rows) {
    if (!row.prices) {
      row.prices = []
    }

    if (!row.alias) {
      row.alias = []
    }
  }
  switch (format) {
    case Format.Postgres: {
      return rows
    }
    case Format.Couch: {
      return rowsToProducts(rows)
    }
    case Format.ProductList: {
      const result = []
      const products = rowsToProducts(rows)
      for (const product of products) {
        result.push({
          _id: product._id,
          name: product.name,
          productType: product.productType,
          additionalData: {
            phase: get(product, 'additionalData.phase'),
            ppmv: get(product, 'additionalData.ppmv'),
            ppmv_core: get(product, 'additionalData.ppmv_core'),
            available: get(product, 'additionalData.available')
          },
          latestPrice: getCurrentPrice(product.prices)
        })
      }
      return result
    }
    default: {
      throw new Error(`Unsupported format: ${format}`)
    }
  }
}

module.exports = {
  Format,
  serialize,
  VAT_PER_MARKET
}
