import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Banner from '../Banner'
import Button from '../Button'
import DeemphasizedLink from '../DeemphasizedLink'
import { CheckmarkCircle } from '../../icons'

class Toast extends Component {
  state = {
    markedDismissed: false
  }

  dismiss = () => {
    this.setState({
      markedDismissed: true
    }, () => {
      setTimeout(() => {
        this.props.onDismiss()
      }, 400) // This is to make sure the css animation is done
    })
  }

  componentDidMount () {
    const { autoDismiss } = this.props
    if (autoDismiss) {
      setTimeout(() => {
        this.dismiss()
      }, autoDismiss)
    }
  }

  render () {
    const {
      title,
      children,
      ctaProps = {},
      type
    } = this.props

    const {
      markedDismissed
    } = this.state

    const {
      label: ctaLabel,
      onClick: ctaOnClick,
      ...otherCtaProps
    } = ctaProps

    return (title || children) && (
      <div
        className={classnames(
          'vs-u-hide-on-print',
          'vs-toast',
          {'vs-toast--dismissed': markedDismissed}
        )}
      >
        <Banner
          type={type}
          title={title}
          inline
          tight
          icon={type === 'success' ? <CheckmarkCircle /> : null}
          center={false}
          footer={(
            <div className='vs-toast__footer'>
              <DeemphasizedLink
                className='vs-toast__close'
                onClick={() => this.dismiss()}
                size='small'
              >Close</DeemphasizedLink>
            </div>
          )}
        >
          {children}
          {ctaLabel && (
            <div className='vs-toast__cta'>
              <Button
                fill='full'
                colorVariant='brand'
                size='small'
                alignEdge='shadow'
                onClick={() => {
                  ctaOnClick && ctaOnClick()
                  this.dismiss()
                }}
                {...otherCtaProps}
              >
                {ctaLabel}
              </Button>
            </div>
          )}
        </Banner>
      </div>
    )
  }
}

Toast.propTypes = {
  /**
   * Banner title, see Banner props
   */
  title: PropTypes.string,
  /**
   * Banner type
   */
  type: PropTypes.oneOf(['attention', 'info', 'success', 'warning', 'danger']),
  /**
   * Banner content, can be text or markup
   */
  children: PropTypes.node,
  /**
   * Props to pass to the CTA Button (only rendered when `ctaProps.label` is passed).
   * Two props won't be passed: `ctaProps.label` and `ctaProps.onClick`.
   * `ctaProps.label` will be used as the Button label.
   * `ctaProps.onClick` will be called when the Button is clicked, before dismissing the toast.
   */
  ctaProps: PropTypes.object,
  /**
   * delay in ms, or false if toast should not autoDismiss
   */
  autoDismiss: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  /**
   * callback when toast is gone from view
   */
  onDismiss: PropTypes.func
}

Toast.defaultProps = {
  title: '',
  children: null,
  type: 'info',
  autoDismiss: 5000,
  onDismiss: () => null
}

export default Toast
