import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Text from '../Text'

const StatusCardHeader = ({
  headerTitle,
  headerDate,
  className,
  spread,
  children
}) => (
  <div
    className={classnames(
      'vs-status-card__header',
      { 'vs-status-card__header--spread': spread },
      className
    )}
  >
    {headerTitle && (
      <div className='vs-status-card__header-title'>
        <Text.Heading size='smallish' tight>
          { headerTitle }
        </Text.Heading>
      </div>
    )}

    {headerDate && (
      <div className='vs-status-card__header-date'>
        { headerDate }
      </div>
    )}

    { children }
  </div>
)

StatusCardHeader.propTypes = {
  /**
   * The status header title to display
   */
  headerTitle: PropTypes.string.isRequired,
  /**
   * The status header right panel with date which sometimes is passed as component
   */
  headerDate: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * Set if the header should fill have space-between and flex props
   */
  spread: PropTypes.bool,
  /**
   * Set custom CSS classes to extend the StatusCardHeader for your needs.
   */
  className: PropTypes.string,
  /**
   * Custom content
   */
  children: PropTypes.node
}

StatusCardHeader.defaultProps = {
  headerTitle: '',
  headerDate: undefined,
  spread: true,
  className: '',
  children: undefined
}

StatusCardHeader.displayName = 'StatusCard.Header'

export default StatusCardHeader
