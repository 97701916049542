import React from 'react'
import withConfig from '../van-shared/hoc/withConfig'
import {withAuth} from '../common/AuthenticationProvider'

const ShowAppConfig = ({config, user}) => (
  <div>
    <pre>{JSON.stringify(user, null, 2)}</pre>
    <pre>{JSON.stringify(config, null, 2)}</pre>
  </div>
)

export default withAuth('user')(withConfig(ShowAppConfig))
