import React, { Component } from 'react'

import get from 'lodash/get'

import { getUnknownBatchID } from '@fielded/fs-api/lib/shipment/tools/virtual-batch.js'
import { Loading, Text } from '@fielded/shared-ui'
import { toast } from '@fielded/shared-ui/src/components/Page/Page'
import { getPrice } from '@fielded/fs-api/lib/product/tools'
import { getServiceForLocationId, countryById } from '@fielded/fs-api/lib/service/tools/territory-lookup'

import { withUser } from '../../../common/AuthenticationProvider'
import { withApi } from '../../../common/ApiProvider'
import withConfig from '../../../van-shared/hoc/withConfig'

import { formatDate } from '../../../van-shared/utils'
import { isOperatorUser, isPlannerUser } from '../common/utils'

import PlanningError from './PlanningError'
import PlanningContainer from './PlanningContainer'
import PlanningProductsContainer from './PlanningProductsContainer'
import PlanningQuantitiesContainer from './PlanningQuantitiesContainer'
import PlanningConfirmationTable from './PlanningConfirmationTable'
import smartId from '@fielded/fs-api/lib/tools/smart-id'

function createInitialState () {
  return {
    view: 'loading',
    errorMessage: null,
    reset: false,
    origins: null,
    destinations: null,
    date: new Date().toJSON(),
    originId: null,
    destinationId: null,
    products: null,
    selectedProducts: null,
    quantities: null,
    savingShipment: false
  }
}

function reducer (state, action) {
  switch (action.type) {
    case 'reset': {
      return createInitialState()
    }
    case 'error': {
      return { ...state, view: 'error', errorMessage: action.message }
    }
    case 'locations-loaded': {
      return {
        ...state,
        view: 'planning',
        locations: action.allLocations,
        origins: action.userLocations,
        originId: action.userLocations[0]._id,
        destinations: action.suppliers,
        destinationId: action.suppliers[0]._id
      }
    }
    case 'goto-products': {
      return {
        ...state,
        view: 'loading',
        date: action.date,
        originId: action.originId,
        destinationId: action.destinationId
      }
    }
    case 'products-loaded': {
      return {
        ...state,
        view: 'products',
        products: action.products,
        availableStock: action.availableStock,
        origin: state.locations.find(l => l._id === state.originId),
        destination: state.locations.find(l => l._id === state.destinationId)
      }
    }
    case 'goto-quantities': {
      return {
        ...state,
        selectedProducts: state.products.filter(p => p.selected),
        view: 'quantities'
      }
    }
    case 'goto-confirmation': {
      return {
        ...state,
        view: 'confirmation',
        quantities: action.quantities
      }
    }
    case 'creating-shipment': {
      return { ...state, savingShipment: true }
    }
    case 'back-to-planning': {
      return {
        ...state,
        view: 'planning',
        // reset products, because we might have a different product list if locations are changed
        products: null,
        selectedProductIds: null,
        selectedProducts: null
      }
    }
    case 'back-to-products': {
      return { ...state, view: 'products', quantities: action.quantities }
    }
    case 'back-to-quantities': {
      return { ...state, view: 'quantities' }
    }
  }
  return state
}

const views = {
  error: ({ state }) => {
    return <PlanningError message={state.errorMessage} title={'Planning'} />
  },

  loading: () => {
    return <Loading />
  },

  planning: ({ state, dispatch, api }) => {
    const goNextHandler = async ({ date, originId, destinationId }) => {
      dispatch({
        type: 'goto-products',
        date,
        originId,
        destinationId
      })
      try {
        const origin = state.locations.find(l => l._id === originId)
        // here we assume that a location has only one service
        const serviceId = origin.services[0]
        const service = await api.service.get(serviceId)
        const ledgerAsReport = await api.stock.getLedgerBalanceAsReport({
          location: { id: origin._id },
          service,
          date: new Date().toJSON(),
          includeScheduledOutbound: true
        })
        const availableStock = {}
        for (const id of Object.keys(ledgerAsReport.stock)) {
          // The available stock is the Shelf Life balance.
          // The ledger currently does not contain a Shelf Life balance field, so we calculate it here.
          const partnerBalance = get(ledgerAsReport, `stock.${id}.fields.field:partner-balance.amount`, 0)
          const totalBalance = get(ledgerAsReport, `stock.${id}.availableTotal`, 0)
          const shelflifeBalance = totalBalance - partnerBalance
          if (shelflifeBalance > 0) {
            availableStock[id] = shelflifeBalance
          }
        }
        const products = await api.product.getByIds(Object.keys(availableStock))
        dispatch({ type: 'products-loaded', products, availableStock })
      } catch (err) {
        console.error(err)
        dispatch({ type: 'error', message: 'Failed to load ledger and products' })
      }
    }
    return (
      <PlanningContainer
        origins={state.origins}
        destinations={state.destinations}
        date={state.date}
        originId={state.originId}
        destinationId={state.destinationId}
        onSubmit={goNextHandler}
      />
    )
  },

  products: ({ state, dispatch, history }) => {
    const goNextHandler = () => {
      dispatch({type: 'goto-quantities'})
    }
    const goBackHandler = () => {
      dispatch({type: 'back-to-planning'})
    }
    return (
      <PlanningProductsContainer
        origin={state.origin}
        history={history}
        destination={state.destination}
        products={state.products}
        onSubmit={goNextHandler}
        onGoBack={goBackHandler}
      />
    )
  },

  quantities: ({ state, dispatch }) => {
    const goNextHandler = (quantities) => {
      dispatch({ type: 'goto-confirmation', quantities })
    }
    const goBackHandler = (quantities) => {
      dispatch({ type: 'back-to-products', quantities })
    }
    return (
      <PlanningQuantitiesContainer
        origin={state.origin}
        destination={state.destination}
        products={state.selectedProducts}
        availableStock={state.availableStock}
        quantities={state.quantities}
        onSubmit={goNextHandler}
        onGoBack={goBackHandler}
      />
    )
  },

  confirmation: ({ state, dispatch, api, history, appName }) => {
    const formRef = React.createRef()
    const confirmHandler = async () => {
      dispatch({ type: 'creating-shipment' })
      try {
        const { signature, name } = formRef.current.getFields()
        const formattedDate = formatDate(state.date, 'snapshotId')
        const counts = Object.keys(state.quantities).reduce((cs, productId) => Object.assign(cs, {
          [getUnknownBatchID(productId)]: { quantity: state.quantities[productId] }
        }), {})
        const snapshot = await api.shipment.createSnapshot({
          origin: state.originId,
          destination: state.destinationId,
          date: formattedDate,
          shipmentTypeId: 'routine',
          counts,
          signature,
          name
        })
        dispatch({ type: 'reset' })
        // Replace current URL with shipments list so that the returns note back button goes there
        history.replace('/shipments/list')
        history.push(`/shipments/returns-note/${snapshot.id}`)
      } catch (err) {
        console.error(err)
        return dispatch({ type: 'error', message: 'Failed to create shipment' })
      }
      toast({
        title: 'New shipment created',
        type: 'success',
        autoDismiss: false,
        children: (
          <Text size='small'>{`Shipment from ${state.origin.name} to ${state.destination.name} successfully created.`}</Text>
        )
      })
    }
    const goBackHandler = (quantities) => {
      dispatch({ type: 'back-to-quantities' })
    }
    const country = state.origin.location.country
    const items = Object.entries(state.quantities).map(([productId, quantity]) => {
      const product = state.products.find(p => p._id === productId)
      const price = getPrice(product.prices, state.date)
      return {
        code: productId.split(':')[1],
        name: product.name,
        packed: quantity,
        price
      }
    })
    return (
      <PlanningConfirmationTable
        formRef={formRef}
        onConfirm={confirmHandler}
        onGoBack={goBackHandler}
        date={state.date}
        country={country}
        items={items}
        includeVAT={false}
        title={'Confirm quantities for shipment'}
        subTitle={`Shipment from ${state.origin.name} to ${state.destination.name}`}
        appName={appName}
      />
    )
  }
}

class NewShipmentContainer extends Component {
  constructor (props) {
    super(props)
    this.state = createInitialState()
  }

  getPath (view = null) {
    const basePath = '/shipments/new'
    if (!view) {
      return basePath
    }
    return `${basePath}/${view}`
  }

  dispatch = (action) => {
    const newState = reducer(this.state, action)
    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  async componentDidMount () {
    const { api, user } = this.props
    const userLocationId = user.location.id
    const userLocations = await api.location.listRelated(userLocationId)
    let sdpLocations = userLocations.filter(loc => loc.level === 'sdp')
    const supplierIds = await api.order.getSupplierIdsForLocation(sdpLocations[0], {planType: 'return'})
    let suppliers = await api.location.getByIds(supplierIds)
    if (supplierIds.length && !suppliers.length) {
      suppliers = await api.location.getLocationsViaIds(supplierIds)
    }
    const allLocations = userLocations.concat(suppliers)

    if (isPlannerUser(user) || isOperatorUser(user)) {
      sdpLocations = suppliers.filter(s => {
        const { state } = smartId.parse(user.location.id)
        return s.location.state === state
      })
      const { service, geoId } = getServiceForLocationId(userLocationId)
      const configDoc = await api.configuration.getByServiceId(service)
      const resupplierIds = get(configDoc, 'relationships.pack-point.resupply')
      if (isPlannerUser(user)) {
        const geoLocal = geoId.split(':state')[0]
        const country = countryById(geoLocal)
        // only global planners can access this endpoint
        const { results: countryPackPoints } = await api.location.restAdapter.getByFilters({
          supply_chain_level: 'pack-point',
          country: country.name
        })
        const countryPackPointIds = countryPackPoints.map(pp => pp.fsid)
        resupplierIds.push(...countryPackPointIds)
      }
      const externalLocations = await api.location.getLocationsViaIds([...resupplierIds])
      suppliers = externalLocations
      allLocations.push(...externalLocations)
    }

    this.dispatch({
      type: 'locations-loaded',
      userLocations: sdpLocations,
      suppliers,
      allLocations
    })
  }

  render () {
    const { api, history, config } = this.props
    const appName = config.name
    const { view } = this.state
    const createView = views[view]
    const viewContext = {
      state: this.state,
      dispatch: this.dispatch,
      api,
      history,
      appName
    }
    return createView(viewContext)
  }
}

export default withUser(withApi(withConfig(NewShipmentContainer)))
