const { getPaymentOptionViaTxnIdQuery } = require('../../payment-plans/queries/payment-option-queries')

const getOverduePAYGInvoicesQuery = `
  with overdue_invoices as (
    select
      l.name_of_pharmacy as name,
      l.fsid,
      l.classification,
      l.uuid as location_id,
      l.pay_per_use,
      l.fee_percentage,
      l.fee_percentage_long_term,
      t.id,
      t.company_code,
      t.txn_id,
      t.quickbooks_doc_number,
      t.due_date,
      t.amount,
      coalesce(t.amount_paid, 0) as amount_paid,
      t.quickbooks_account_id
    FROM avocado.data_location l
    left join avocado.data_quickbookstransactions t on t.location_id = l.uuid
    left join avocado.data_invoicepaymentplan p on p.qbo_txn_id = t.txn_id and p.qbo_company_code = t.company_code
    left join avocado.data_paymentplan pp on pp.id = p.paymentplan_id
    left join avocado.data_invoice_quickbooks iq on iq.invoice_txn_id = t.txn_id and iq.company_code = t.company_code
    left join avocado.data_invoice_line il on il.invoice_id = iq.invoice_id
    where membership_type IN ($1, $2) and t.order_type = $3 and txn_type = 'invoice' and coalesce(il.event_type, '') != 'fee'
    and coalesce(t.amount_paid, 0) < t.amount and now() > t.due_date or pp.is_active = false
    group by 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
  )
  select 
    oi.id,
    oi."name",
    oi.fsid,
    oi.classification,
    oi.location_id,
    oi.pay_per_use,
    oi.fee_percentage,
    oi.fee_percentage_long_term,
    oi.txn_id,
    oi.company_code,
    oi.quickbooks_doc_number,
    oi.due_date,
    oi.amount,
    oi.amount_paid,
    oi.quickbooks_account_id,
    coalesce(
      (
        select to_jsonb(op)
        from (
          ${getPaymentOptionViaTxnIdQuery('oi.txn_id', 'oi.company_code')}
        ) op
      ),
      '{}'::jsonb
  ) as "paymentOption"
  from overdue_invoices oi
`

module.exports = { getOverduePAYGInvoicesQuery }
