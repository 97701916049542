const { TABLE_NAMES } = require('../constants')

const accountReceivableBalanceQuery = `
  with deposit_transactions as (
    select
      qr.txn_id,
      qr.company_code,
      qt.location_id,
      (jsonb_array_elements((qr.raw_data ->> 'Line')::text::jsonb) -> 'DepositLineDetail' -> 'AccountRef' ->> 'value') as quickbooks_account_id,
      (jsonb_array_elements((qr.raw_data ->> 'Line')::text::jsonb) -> 'Amount')::float as deposit_amount
    from ${TABLE_NAMES.TRANSACTIONS_TABLE_NAME} qt
    right join avocado.data_quickbooksrawtransactions qr on qr.txn_id = qt.txn_id and qr.company_code = qt.company_code and qr.txn_type = 'Deposit' 
    WHERE qt."location_id" = $1
  ),
  -- we want to exclude deposits made for payment plans from the account receivable calculation
  -- we do the this cause the deposits have already been applied to the payment plans account balance, so adding it here again is not needed
  -- deposits made that are not applied to any payment plan will be considered as regular payments in the account recevable calculation
  payment_plan_deposits as (
    select
      dt.*
    from deposit_transactions dt
    left join avocado.data_paymentplan pp on
      pp.location_id = dt.location_id and dt.quickbooks_account_id = pp.quickbooks_account_id and dt.company_code = pp.company_code
    where pp.id is not null
  ),
  location_transactions as (
    select
      qt.txn_type,
      qt.amount - coalesce(dt.deposit_amount, 0) as amount
    from ${TABLE_NAMES.TRANSACTIONS_TABLE_NAME} qt
    left join payment_plan_deposits dt on dt.txn_id = qt.txn_id and dt.company_code = qt.company_code and dt.quickbooks_account_id = qt.quickbooks_account_id
    WHERE qt."location_id" = $1

    union all

    select 
      'payment' as txn_type,
      ip.amount
    from ${TABLE_NAMES.PAYMENT_INVOICE_TABLE_NAME} ip
    left join ${TABLE_NAMES.TRANSACTIONS_TABLE_NAME} qt on ip.txn_id = qt.txn_id and ip.company_code = qt.company_code
    WHERE ip."location_id" = $1 and ip."amount" != 0 and coalesce(ip.unapplied_amount, 0) = 0 and qt.id is null
  )
  SELECT
    SUM(
      case
        WHEN txn_type IN ('invoice', 'refund') THEN -amount
        ELSE amount
      end
    ) AS ar_balance
  FROM location_transactions
`

module.exports = accountReceivableBalanceQuery
