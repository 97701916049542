const get = require('lodash/get')
const maxBy = require('lodash/maxBy')
const sumBy = require('lodash/sumBy')
const cloneDeep = require('lodash/cloneDeep')
const {ORDER_TYPES} = require('../../constants')
const {hamilton} = require('../../../tools/apportionment')

/*
 * Related notion doc: https://www.notion.so/fielded/9029dde1d0ce4d6198d0a61b5a69c7e3?v=b6ec705b48d940a7a8c71c89233d62ac&p=bd7200add6b44bf493e954ec62f8615b
 */

// we can't use getSupplierInfo on an order level, because then held stock will be distributed
// across all orders without ever getting deducted.
// So, first we get supplierInfo, then we break things down by order.
exports.getSupplierExport = getSupplierExport

// nb "suppliers" in the context of this function is internal sources (pack-points for example), and does NOT mean or
// include third party suppliers for whom we have no idea how much product they have available.
function getSupplierExport (suppliers, inputOrders, locationsById, productsById, routesById) {
  const supplierAvailableStock = cloneDeep(suppliers)
  const rowsBySupplier = supplierAvailableStock
    .reduce((acc, supplier) => {
      const {id, name} = supplier
      acc[supplier.id] = {supplier: {id, name}, rows: [], creator: null}
      return acc
    }, {})
  // we're going to edit this guy, subtract from quantityToProcure for each order
  // to distrbute out held
  const orders = cloneDeep(inputOrders)
  const deliveryDate = orders[0].deliveryDate// nb these are assuming all orders in same plan will have same data
  const createdAt = orders[0].createdAt
  const orderType = orders[0].orderType
  const orderId = orders[0].orderId
  const productDemands = {}
  orders.forEach(order => {
    const location = locationsById[order.destinationId]
    if (!location) throw new Error(`location not found for ${order._id} ${order.destinationId}`)

    // Resupply orders don't need routes
    const orderType = get(order, 'orderType', 'routine')
    let route = {name: ''}

    if (orderType !== ORDER_TYPES.resupply) {
      route = routesById[order.routeId || order.funderId]
    }

    if (!route) throw new Error(`route not found for ${order._id} ${order.routeId || order.funderId}`)
    Object.keys(order.products).forEach(productId => {
      let orderQTO = order.products[productId].adjusted
      if (orderQTO <= 0) return

      const product = productsById[productId]
      if (!product) throw new Error(`product not found for ${order._id} ${order.productId}`)
      supplierAvailableStock.forEach(supplier => {
        const supplierProduct = get(supplier, `products.${productId}`)
        // set who created order
        if (order.creator && !rowsBySupplier[supplier.id].creator) {
          rowsBySupplier[supplier.id].creator = {
            name: get(order.creator, 'name'),
            fullName: get(order.creator, 'profile.fullName')
          }
        }
        // quantityToProcure = "planned units", total demand across all orders in this plan, from this source
        if (
          !supplierProduct ||
          !supplierProduct.quantityToProcure ||
          supplierProduct.quantityToProcure <= 0
        ) {
          rowsBySupplier[supplier.id].rows.push({
            location,
            product,
            quantityFromPackPoint: 0,
            quantityFromSuppliers: 0,
            quantity: 0,
            route,
            notAvailable: true
          })
          return
        }

        // The loop we're currently in is per-product-per-location, but for our apportionment magic to work, we need to
        // be looping per-location-per-product, but instead of making a huge refactor, instead build list of demand for
        // each product so that they can be apportioned once the data is complete, outside of this loop
        if (!productDemands[productId]) {
          productDemands[productId] = {}
        }
        if (!productDemands[productId][supplier.id]) {
          productDemands[productId][supplier.id] = {demand: {}, supply: supplierProduct.quantityAvailable}
        }
        productDemands[productId][supplier.id].demand[order.destinationId] = orderQTO

        // get total quantity for product, regardless of what's available from who
        let supplierQuantityForOrder = 0
        if (supplierProduct.quantityToProcure > orderQTO) {
          supplierQuantityForOrder = orderQTO
          supplierProduct.quantityToProcure -= orderQTO
        } else {
          supplierQuantityForOrder = supplierProduct.quantityToProcure
          supplierProduct.quantityToProcure = 0
        }

        rowsBySupplier[supplier.id].rows.push({
          location,
          product,
          quantity: supplierQuantityForOrder,
          route,
          notAvailable: supplierProduct.notAvailable
        })
      })
    })
  })

  // Use Hamilton's method of apportionment
  // This will apportion stock in such a way that if there isn't enough stock to fulfill the plan,
  // then all recipients will get an approximately equal share relative to what was expected
  Object.keys(productDemands).forEach(productId => {
    Object.keys(productDemands[productId]).forEach(supplierId => {
      const {demand, supply} = productDemands[productId][supplierId]
      const totalDemand = Object.values(demand).reduce((acc, n) => acc + n, 0)
      const apportionment = supply > 0 && hamilton(Object.values(demand), Math.min(totalDemand, supply))
      productDemands[productId][supplierId].apportionment = {}
      Object.keys(demand).forEach((destinationId, i) => {
        productDemands[productId][supplierId].apportionment[destinationId] = apportionment[i] || 0
      })
    })
  })

  // merge the apportionment data into the spreadsheet rows
  Object.entries(rowsBySupplier).forEach(([supplierId, data]) => {
    data.rows.forEach(row => {
      // if this pack-point has this product, count it, else skip it.
      const productFromSupplier = productDemands[row.product._id] ? productDemands[row.product._id][supplierId] : false
      if (productFromSupplier) {
        const apportioned = productFromSupplier.apportionment[row.location._id]
        row.quantityFromPackPoint = apportioned
        row.quantityFromSuppliers = row.quantity - apportioned
      } else {
        row.quantityFromPackPoint = 0
        row.quantityFromSuppliers = 0
      }
    })
  })

  return Object.values(rowsBySupplier)
    .reduce((acc, supplierExport) => {
      const {supplier, rows, creator} = supplierExport
      const rowsForExport = rows.map(row => getRowForExport(row, orderType)).filter(row => row.quantity > 0)
      acc[supplier.id] = {
        supplier,
        deliveryDate,
        orderType,
        orderId,
        createdAt,
        rows: rowsForExport,
        creator,
        total: sumBy(rowsForExport, 'subTotal')
      }
      return acc
    }, {})
}

function getRowForExport (row, orderType) {
  const {location, product, quantity, quantityFromPackPoint, quantityFromSuppliers, route, notAvailable} = row
  const code = get(location, 'additionalData.code', 'NO CODE FOUND')
  const buyPrice = get(maxBy(product.buyPrices, 'date'), 'price', 0)
  const sellPrice = get(maxBy(product.prices, 'date'), 'price', 0)
  const unitPrice = orderType !== ORDER_TYPES.resupply ? sellPrice : buyPrice
  return {
    packCode: code,
    productName: product.name,
    quantity,
    quantityFromPackPoint,
    quantityFromSuppliers,
    routeName: route.name,
    isAvailable: !notAvailable,
    buyPrice: unitPrice,
    code: product.code,
    subTotal: (unitPrice * quantity)
  }
}
