import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { PayAsYouSell, PayOnDelivery } from '@fielded/shared-ui/src/icons'

import { pluralize, formatDate } from '../van-shared/utils/utils'
import { DIRECT_ORDER_TYPES, DIRECT_ORDER_TYPES_HUMANIZED } from '@fielded/fs-api/lib/allocation/config'
const { PAY_ON_DELIVERY, PAY_AS_YOU_SELL } = DIRECT_ORDER_TYPES

const DeliveryStatementAdjustmentInfo = ({
  adjustmentDate,
  adjustments,
  paymentType,
  shipmentNo
}) => {
  return (
    <div
      className={
        classnames(
          'delivery-statement-info',
          { 'delivery-statement-info--on-demand': paymentType === PAY_ON_DELIVERY },
          { 'delivery-statement-info--pays': paymentType === PAY_AS_YOU_SELL }
        )
      }
    >
      {paymentType === PAY_ON_DELIVERY && (
        <h2 className='delivery-statement-info__header delivery-statement-info__header--on-demand'>
          <PayOnDelivery /> {DIRECT_ORDER_TYPES_HUMANIZED[PAY_ON_DELIVERY]} subscriptions
        </h2>
      )}

      {paymentType === PAY_AS_YOU_SELL && (
        <h2 className='delivery-statement-info__header delivery-statement-info__header--pays'>
          <PayAsYouSell /> {DIRECT_ORDER_TYPES_HUMANIZED[PAY_AS_YOU_SELL]} subscriptions
        </h2>
      )}

      <strong>Adjusted value</strong>
      <br />
      The value of <strong>{adjustments} {pluralize('product', adjustments)} </strong>was updated after delivery, last update on <strong>{formatDate(adjustmentDate, 'long')}</strong>
      <div className='print-template__delivery'>Delivery No. {shipmentNo}</div>
    </div>
  )
}

DeliveryStatementAdjustmentInfo.propTypes = {
  /* A date of last adjustment */
  adjustmentDate: PropTypes.string,
  /* Amount of adjustments made */
  adjustments: PropTypes.number,
  /* payment type (PAY_ON_DELIVERY or PAY_AS_YOU_SELL) */
  paymentType: PropTypes.string,
  /* Number of shipment */
  shipmentNo: PropTypes.number
}

export default DeliveryStatementAdjustmentInfo
