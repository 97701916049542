const { getByServiceId } = require('../../../configuration/api/read')
const validateServiceProducts = require('../../../utils/validate-products-for-service')
const isEqual = require('lodash/isEqual')

function prepareConfigToSave (config, products, username = '') {
  const configToSave = Object.assign({}, config, {
    products: config.products || [],
    updatedAt: new Date().toJSON(),
    updatedBy: username
  })
  configToSave.products = Array.from(new Set(configToSave.products.concat(products)))
  return configToSave
}

exports.addProducts = async function (state, service, products, skipValidation) {
  if (!products || !products.length) {
    return
  }
  if (!skipValidation) {
    await validateServiceProducts(state, service, products)
  }

  const config = await getByServiceId(state, service)
  if (!config) {
    throw new Error(`Service ${service} doesn't seem to exist, cannot add products to it`)
  }
  const username = state.user ? state.user.name : ''
  const doc = prepareConfigToSave(config, products, username)
  if (!isEqual(doc.products, config.products)) {
    const res = await state.dal.configuration.save(state, doc)
    return res
  }
}
