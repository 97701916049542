import React, { Component } from 'react'

import { Loading } from '@fielded/shared-ui'

import { withUser } from '../../../../common/AuthenticationProvider'
import { withApi } from '../../../../common/ApiProvider'
import withConfig from '../../../../van-shared/hoc/withConfig'
import { hasFeature } from '../../../../van-shared/utils/features'
import { toDocIdProperties } from '@fielded/fs-api/lib/shipment/tools'
import { userIsRetailer } from '../../../../common/utils/user'

import ReturnsNote from './ReturnsNote'

class ReturnsNoteContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  async componentDidMount () {
    const {
      api,
      shipmentId,
      user,
      config
    } = this.props

    const userCanViewSalesStats = hasFeature(config, 'features.shipments.useCRSalesStats')
    const noteData = await api.shipment.getReturnsNote(shipmentId, userCanViewSalesStats)

    let location
    let serviceId
    if (userIsRetailer(user)) {
      location = await api.location.get(user.location.id)
      serviceId = location && location.services && location.services.length && location.services[0]
    }

    this.setState({
      loading: false,
      noteData,
      location,
      serviceId,
      userCanViewSalesStats
    })
  }

  render () {
    const {
      history,
      shipmentId,
      config
    } = this.props
    const {
      loading,
      noteData,
      location,
      serviceId,
      userCanViewSalesStats
    } = this.state

    if (loading) {
      return <Loading />
    }

    const { shipmentNo } = toDocIdProperties(shipmentId)
    const noteProps = {
      history,
      location,
      serviceId,
      shipmentNo,
      ...noteData
    }
    return <ReturnsNote
      {...noteProps}
      config={config}
      userCanViewSalesStats={userCanViewSalesStats}
    />
  }
}

export default withConfig(withUser(withApi(ReturnsNoteContainer)))
