const { format } = require('date-fns')
const { createDocNumber } = require('./doc-number')
const { createLines } = require('./lines')
const { getTax } = require('./tax')

class Invoice {
  constructor (quickbooks) {
    this.quickbooks = quickbooks
  }

  async get (companyCode, id) {
    const { Invoice: invoice, Error: error } = await this.quickbooks.quickbooksInterface.get(
      companyCode,
      `invoice/${id}?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`
    )
    if (invoice) {
      return invoice
    }
    if (error) {
      throw new Error(error.Message)
    }
  }

  async create ({
    invoice: {
      marketCode,
      locationCode,
      vatRate,
      email,
      companyCode,
      customerId,
      dueDate = null,
      customField = ''
    },
    lines,
    hasCustomVatLine = false
  }) {
    const qboLines = await createLines({
      quickbooks: this.quickbooks,
      companyCode,
      lines,
      hasCustomVatLine,
      vatRate
    })

    const qboInvoice = {
      CustomField: [{
        DefinitionId: '1',
        StringValue: customField,
        Name: 'OrderType',
        Type: 'StringType'
      }],
      CustomerRef: {
        value: customerId
      },
      DocNumber: createDocNumber(marketCode, locationCode),
      BillEmail: {
        Address: email
      },
      Line: qboLines
    }
    if (dueDate) {
      qboInvoice.DueDate = format(dueDate, 'YYYY-MM-DD')
    }

    if (!hasCustomVatLine) {
      const qboTax = await getTax({
        quickbooksInterface: this.quickbooks.quickbooksInterface,
        companyCode
      })
      qboInvoice.TxnTaxDetail = {
        TxnTaxCodeRef: {
          value: qboTax.codeId
        },
        TaxLine: [
          {
            DetailType: 'TaxLineDetail',
            TaxLineDetail: {
              TaxRateRef: {
                value: qboTax.rateId
              }
            }
          }
        ]
      }
    }

    if (qboLines.length === 0) {
      return null
    }

    const { Invoice: createdInvoice } = await this.quickbooks.quickbooksInterface.post(
      companyCode,
      `invoice?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`,
      qboInvoice
    )
    return createdInvoice
  }

  async sendEmail ({
    companyCode,
    invoice,
    ccEmails
  }) {
    let billEmailBcc = this.quickbooks.quickbooksInterface.billEmailBcc[companyCode]
    const payload = {
      SyncToken: invoice.SyncToken,
      DeliveryAddress: {
        Address: invoice.BillEmail.Address
      }
    }
    if (billEmailBcc) {
      if (ccEmails && ccEmails.length) {
        ccEmails.push(billEmailBcc)
        billEmailBcc = ccEmails.toString()
      }
      payload.DeliveryAddressBcc = {
        Address: billEmailBcc
      }
    }
    const url = `invoice/${invoice.Id}/send?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`
    return this.quickbooks.quickbooksInterface.post(companyCode, url, payload)
  }
}

module.exports = {
  Invoice
}
