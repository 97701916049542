import React, { Component } from 'react'

class Donut extends Component {
  calculateStroke = () => {
    return ((100 - this.props.value) / 100) * 3.14159 * 70 * 2
  }
  render () {
    const {
      value
    } = this.props

    return (
      <div className='donut'>
        <svg className='donut__inner' width='155' height='155' viewport='0 0 100 100' version='1.1' xmlns='http://www.w3.org/2000/svg'>
          <circle className='donut__circle' r='70' cx='75' cy='75' fill='transparent' />
          {!isNaN(value) && (
            <circle className='donut__bar' r='70' cx='75' cy='75' fill='transparent' strokeDasharray='437' strokeDashoffset={this.calculateStroke()} />
          )}
        </svg>
        {!isNaN(value) && (
          <span className='donut__percentage'>{Math.round(value)}%</span>
        )}
      </div>
    )
  }
}

export default Donut
