import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classnames from 'classnames'

import Portal from './Portal'

import { CSSTransition } from 'react-transition-group'

import Backdrop from '../Backdrop'
import Button from '../Button'
import { CloseCircle } from '../../icons'

class Modal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: props.isOpen
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.props.onClose()
      }
    })
  }

  handleOnClose = () => {
    this.props.onClose()
  }

  renderCloseButton () {
    const { isCloseable } = this.props

    if (!isCloseable) {
      return null
    }

    return (
      <Button
        icon={<CloseCircle />}
        size='large'
        className='vs-modal__close-button'
        onClick={this.handleOnClose}
      />
    )
  }

  render () {
    const {
      children,
      className,
      isOpen,
      isCloseable,
      title,
      icon,
      spacing,
      centered,
      backdropClassName,
      ...others
    } = this.props

    const classNames = classnames(
      'vs-modal',
      {'vs-modal--spacing-none': spacing === 'none'},
      {'vs-modal--centered': centered},
      className
    )

    return (
      <Portal>

        {/* Backdrop */}
        <CSSTransition
          in={isOpen}
          timeout={200}
          classNames='fade'
          mountOnEnter
          unmountOnExit
        >
          <Backdrop className={backdropClassName} />
        </CSSTransition>

        {/* Modal */}
        <CSSTransition
          in={isOpen}
          timeout={200}
          classNames='slide-up'
          mountOnEnter
          unmountOnExit
        >
          <div
            className={classNames}
            onClick={() => isCloseable && this.handleOnClose()}
          >
            <div
              className='vs-modal__window'
              role='dialog'
              aria-modal='true'
              tabIndex='-1'
              {...others}
              onClick={(e) => e.stopPropagation()}
            >
              <div className='vs-modal__header'>
                {icon && (
                  <div className='vs-modal__header-icon'>
                    {icon}
                  </div>
                )}
                <div className='vs-modal__title'>{title}</div>
                {this.renderCloseButton()}
              </div>
              <div className='vs-modal__body'>{children}</div>
            </div>
          </div>
        </CSSTransition>

      </Portal>
    )
  }
}

Modal.propTypes = {
  /**
   * Children components of Modal
   */
  children: PropTypes.node,
  /**
   * Set custom CSS classes to extend the Modal for your needs.
   */
  className: PropTypes.string,
  /**
   * In order to control the Modal's visibility set this property to `true` or `false`.
   */
  isOpen: PropTypes.bool,
  /**
   * Control whether or not the Modal will request to be closed when the user hits the ESC-key, clicks the close button or outside the Modal.
   * If set to `false` the close button won't be displayed and user interactions are ignored.
   */
  isCloseable: PropTypes.bool,
  /**
   * Callback function for handling of closing the Modal.
   */
  onClose: PropTypes.func,
  /**
   * You can conditionally pass an icon to be shown in the header.
   */
  icon: PropTypes.node,
  /**
   * Spacing determines if modal should have inner padding.
   */
  spacing: PropTypes.oneOf(['default', 'none']),
  /**
   * Centered determines if modal body should be centered.
   */
  centered: PropTypes.bool,
  /**
   * A custom classname passed to the Backdrop component
   */
  backdropClassName: PropTypes.string
}

Modal.defaultProps = {
  isCloseable: true,
  isOpen: false,
  onClose: () => {},
  icon: undefined,
  spacing: 'default',
  centered: true
}

export default Modal
