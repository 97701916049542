const { transFormOriginBonusQuery } = require('./queries')

async function priceBonusJob ({data, pgConnection}) {
  const {
    from_date: fromDate,
    to_date: toDate,
    from_market: fromMarket,
    to_market: toMarket
  } = data

  const values = [fromDate, toDate, fromMarket, toMarket]

  // Get transform query
  const tranformedOriginBonusQuery = transFormOriginBonusQuery()

  try {
    const { rowCount: replicatedBonusRowsCount } = await pgConnection.query(tranformedOriginBonusQuery, values)
    return {replicatedBonusRows: Number(replicatedBonusRowsCount)}
  } catch (e) {
    throw new Error('Error creating new bonus rows', e)
  }
}

module.exports = priceBonusJob
