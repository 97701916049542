import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Banner from '../Banner'

const ButtonWithErrorHandling = ({
  Button,
  getErrorMessage,
  error
}) => {
  if (error) {
    let message = `Download failed: "${error.message || error}"`
    if (getErrorMessage) {
      message = getErrorMessage(error)
    }
    return (
      <Fragment>
        <Banner
          inline
          cta={Button}
          type='warning'
        >
          {message}
        </Banner>
      </Fragment>
    )
  }

  return Button
}

ButtonWithErrorHandling.propTypes = {
  /**
   * Button component that will be aided by the Banner is something goes wrong
   * */
  Button: PropTypes.node.isRequired,
  /**
   * Helper function to display the error
   * */
  getErrorMessage: PropTypes.func,
  /**
   * Error message that displays inside the Banner
   * */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default ButtonWithErrorHandling
