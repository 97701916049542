import React from 'react'
import PropTypes from 'prop-types'

import PrintSectionHeader from './PrintSectionHeader/PrintSectionHeader'

const PrintSection = ({
  title,
  subtitle,
  children
}) => {
  return (
    <section className='print-page-section'>
      {(title || subtitle) && (
        <PrintSection.Header
          title={title}
          subtitle={subtitle}
        />
      )}
      {children}
    </section>
  )
}

PrintSection.propTypes = {
  /**
   * The child elements of the PrintSection
   */
  children: PropTypes.node,
  /**
   * Page title printed in the PrintSectionHeader
   */
  title: PropTypes.string,
  /**
   * Optional subtitle printed in the PrintSectionHeader
   */
  subtitle: PropTypes.string
}

PrintSection.displayName = 'PrintPage.Section'
PrintSection.Header = PrintSectionHeader

export default PrintSection
