const cloneDeep = require('lodash/cloneDeep')

module.exports = (prev, update) => {
  const mergedReport = Object.keys(update).reduce((updated, productId) => {
    const batches = update[productId].batches
    const fields = update[productId].fields
    const commits = update[productId].commits
    updated[productId] = Object.assign(
      {},
      updated[productId],
      batches && { batches },
      fields && { fields },
      commits && { commits }
    )
    return updated
  }, cloneDeep(prev) || {})

  // Clear out any products not given in the update
  // but, leave any commits they may have.
  // Can't do this in above loop because there may be batches on
  // products not in the update.
  Object.keys(prev).forEach(productId => {
    if (!update[productId]) {
      delete mergedReport[productId].batches
      delete mergedReport[productId].fields
    }
  })

  return mergedReport
}
