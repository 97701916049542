import {
  CartAction,
  CfLog,
  ContentBlock,
  CurrencyCode,
  Country,
  ECommerce,
  IdentityAction,
  ItemType,
  Navigation,
  SearchModuleType,
  ShopMode,
  ItemAction,
  PaymentAction,
  Payments,
  PaymentMethod
} from '@causalfoundry/js-sdk'
import get from 'lodash/get'

import { getPrice } from '@fielded/fs-api/lib/product/tools'

import { userIsAuthorised } from '../van-shared/utils/auth'
import * as sessionApi from '../login/session'
import { hasOwnDatabase } from '../common/utils/user-db-sync'

// little in memory cache to reduce product catalog calls
const productCache = []

/// ///////////////////////////////////////////////
//  Helpers
/// ///////////////////////////////////////////////

const getCFCurrency = (country) => {
  switch (country) {
    case 'ng':
      return CurrencyCode.NGN
    case 'ke':
      return CurrencyCode.KES
  }
}

const getCFCountry = (country) => {
  switch (country) {
    case 'ng':
      return Country.Nigeria
    case 'ke':
      return Country.Kenya
  }
}

export const shouldTrackCF = (user) => {
  // We need to check both versions as the user from session is not normalised.
  const isPharmacyUser = userIsAuthorised(user, 'feature:userRole:pharmacyUser') || userIsAuthorised(user, 'userRole:pharmacyUser')

  // We are only tracking retailers and most functionality tracked is only in the MC/Basic flow.
  // We are not checking for Membership type directly as it would require passing location (which is not always available)
  const isRetailer = hasOwnDatabase(user) && isPharmacyUser
  return isRetailer
}

/// ///////////////////////////////////////////////
// Core/Navigation Block Events
/// ///////////////////////////////////////////////

export const cfLogInit = ({config, user}) => {
  if (!config.causalFoundry) {
    return
  }

  if (!shouldTrackCF(user)) {
    return
  }

  const {apiKey} = config.causalFoundry
  CfLog.createSDKInstance(apiKey,
    {
      activateNudgeMechanism: true,
      selfManagedNudges: false,
      defaultBlock: ContentBlock.ECommerce
    }
  )
}

export const cfLogSignIn = (user) => {
  if (!shouldTrackCF(user)) return
  CfLog.getSDKInstance().identify(IdentityAction.Login, user._id, {})
}

export const cfLogUserDetail = (user, location) => {
  if (!user || !location || !shouldTrackCF(user)) return

  const country = get(location, 'location.country')
  const userProperties = {
    workplace: (user.location || {}).id,
    country: getCFCountry(country),
    region_state: get(location, 'location.state', ''),
    organization_id: location._id,
    organization_name: location.storeFullName || location.storeName
  }

  CfLog.getSDKInstance().updateUserCatalog(user._id, userProperties)
}

export const cfLogSignOut = (user) => {
  if (!shouldTrackCF(user)) return
  CfLog.getSDKInstance().identify(IdentityAction.Logout, user._id, null)
}

export const cfLogSearch = async ({queryText, resultList, filtersObject}) => {
  try {
    if (!queryText) {
      return
    }

    const user = await sessionApi.get()
    if (!shouldTrackCF(user)) return

    const searchProperties = {
      contentBlock: ContentBlock.ECommerce,
      page: 1,
      module: SearchModuleType.ECommerce,
      query: queryText,
      results_list: resultList.map(item => ({id: item._id, type: ItemType.Drug})),
      filter: Object.fromEntries(Object.entries(filtersObject)
        .map(([key, value]) => [key, JSON.stringify(value.selected)]))
    }

    Navigation.logSearchEvent(searchProperties, true)
  } catch (e) {
    console.warn('Error in cf event', e)
  }
}

/// ///////////////////////////////////////////////
// Ecommerce Block Events
/// ///////////////////////////////////////////////

export const cfLogProductCatalog = async ({products}) => {
  try {
    const user = await sessionApi.get()
    if (!shouldTrackCF(user)) return

    products.forEach((product) => {
      if (!productCache.includes(product._id)) {
        // We are using description to track impressions of the specific segment of products
        let descriptionText = ''
        if (product.additionalData.available && product.segments && product.segments.length) {
          descriptionText = product.segments.reduce((acc, segment) => {
            // We only want to add the description for "mncnh" category for now and this seems to be the only way to do so.
            if (segment.toLowerCase() === 'mncnh') {
              acc = 'mncnh'
            }
            return acc
          }, '')
        }

        const drugCatalogObject = {
          market_id: product.additionalData.market,
          name: product.name,
          supplier_id: product.additionalData.supplier || 'not specified',
          supplier_name: product.additionalData.supplier || 'not specified',
          active_ingredients: (product.additionalData.api || '').split(',').map(p => p.trim()),
          otc_or_ethical: product.additionalData.ppmv ? 'ethical' : 'otc',
          description: descriptionText
        }
        ECommerce.updateDrugCatalog(product._id, drugCatalogObject)
        productCache.push(product._id)
      }
    })
  } catch (e) {
    console.warn('Error in cf catalog event', e)
  }
}

const getItemDetail = ({product, quantity, country, promo}) => {
  const price = getPrice(product.prices) || 0
  return {
    id: product._id,
    type: ItemType.Drug,
    quantity: quantity || 1, // todo: is this supposed to be incremental or absolute?
    price: Number(price) * quantity,
    currency: getCFCurrency(country),
    promo_id: promo || ''
  }
}

export const cfLogCartItemViewEvent = async ({product}) => {
  // analytics events are not critical, so we can ignore errors for stability
  try {
    const user = await sessionApi.get()
    if (!shouldTrackCF(user)) return
    const country = user.location.country || user.location.id.match(/country:(\w+)/)[1]

    const itemDetail = getItemDetail({product, quantity: 1, country})

    ECommerce.logItemEvent({
      action: ItemAction.View,
      item: itemDetail
    })
  } catch (e) {
    console.warn('Error in cf event', e)
  }
}

export const cfLogCartAdd = async ({cartId, cartValue, orderType, product, quantity, promo}) => {
  // analytics events are not critical, so we can ignore errors for stability
  try {
    const user = await sessionApi.get()
    if (!shouldTrackCF(user)) return
    const country = user.location.country || user.location.id.match(/country:(\w+)/)[1]

    const itemDetail = getItemDetail({product, quantity, country, promo})

    // nb: this static call only works after the cflog has been initialized on this page
    const payload = {
      id: cartId,
      action: CartAction.AddItem,
      item: itemDetail,
      currency: getCFCurrency(country),
      cart_price: cartValue,
      meta: {
        orderType: orderType // "instant order" / "regular"
      }
    }

    ECommerce.logCartEvent(payload)
  } catch (e) {
    console.warn('Error in cf event', e)
  }
}

export const cfLogCartRemove = async ({cartId, cartValue, orderType, product, quantity}) => {
  // analytics events are not critical, so we can ignore errors for stability
  try {
    const user = await sessionApi.get()
    if (!shouldTrackCF(user)) return
    const country = user.location.country || user.location.id.match(/country:(\w+)/)[1]

    const itemDetail = getItemDetail({product, quantity, country})

    const payload = {
      id: cartId,
      action: CartAction.RemoveItem,
      item: itemDetail,
      currency: getCFCurrency(country),
      cart_price: cartValue,
      meta: {
        orderType: orderType // "instant order" / "regular"
      }
    }

    // nb: this static call only works after the cflog has been initialized on this page
    ECommerce.logCartEvent(payload)
  } catch (e) {
    console.warn('Error in cf event', e)
  }
}

export const cfLogCheckout = async ({orderId, cartId, cartValue, orderType, products, productsMap, country,
  paymentChoice, isSuccessful}) => {
  // analytics events are not critical, so we can ignore errors for stability
  try {
    const user = await sessionApi.get()
    if (!shouldTrackCF(user)) return
    const itemsList = Object.entries(products).map(([productId, {quantity, promo}]) => {
      const product = productsMap[productId]
      return getItemDetail({product, quantity, country, promo})
    })

    const checkoutEvent = {
      id: orderId,
      is_successful: isSuccessful,
      cart_price: cartValue,
      currency: getCFCurrency(country),
      items: itemsList,
      cart_id: cartId,
      shop_mode: ShopMode.Delivery,
      meta: {
        orderType: orderType, // "instant order" / "regular"
        paymentChoice: paymentChoice
      }
    }

    ECommerce.logCheckoutEvent(checkoutEvent)
  } catch (e) {
    console.warn('Error in cf event', e)
  }
}

/// ///////////////////////////////////////////////
// Payment Block Events
/// ///////////////////////////////////////////////

export const cfLogPaymentEvent = async ({orderId, paymentId, paymentAmount, country, isCashPayment}) => {
  try {
    const user = await sessionApi.get()
    if (!shouldTrackCF(user)) return
    const paymentMethodProperties = {
      id: paymentId,
      order_id: orderId,
      action: PaymentAction.Select,
      type: isCashPayment ? PaymentMethod.Cash : PaymentMethod.BankCard,
      payment_amount: paymentAmount,
      currency: getCFCurrency(country)
    }
    Payments.logPaymentMethodEvent(paymentMethodProperties)
  } catch (e) {
    console.warn('Error in cf event', e)
  }
}
