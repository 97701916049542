import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import {
  Route,
  withRouter,
  Switch
} from 'react-router-dom'

import { Banner } from '@fielded/shared-ui'

import PrivateRoutes from './PrivateRoutes'
import Login from '../login/LoginContainer'
import ForgotPassword from '../forgot-password/ForgotPasswordContainer'
import ResetPassword from '../reset-password/ResetPasswordContainer'
import Activate from '../activate/ActivateContainer'
import ShowAppConfig from './ShowAppConfig'
import { hasFeature } from '../van-shared/utils/features'
import withConfig from '../van-shared/hoc/withConfig'

class Routes extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    persistor: PropTypes.object
  }

  render () {
    const { location, config, persistor } = this.props
    const allowResetPass = hasFeature(config.features, 'allowResetPass')

    return (
      <Fragment>
        { process.env.REACT_APP_ENV === 'training' &&
          <Banner type='warning'>This is a training site; data will be periodically deleted.</Banner>
        }
        <Switch>
          <Route exact path='/login' component={Login} location={location} />
          {allowResetPass && <Route exact path='/forgot-password' component={ForgotPassword} location={location} />}
          {allowResetPass && <Route exact path='/reset-password' component={ResetPassword} location={location} />}
          {allowResetPass && <Route exact path='/activate' component={Activate} location={location} />}
          {/*
            this is a field-team internal page for a faster check of config & user objects.
            the 'z' is intentional so we don't collide with any future /config page
          */}
          <Route exact path='/configz' component={ShowAppConfig} />
          <Route render={(props) => <PrivateRoutes {...props} persistor={persistor} />} />
        </Switch>
      </Fragment>
    )
  }
}

export default withConfig(withRouter(Routes))
