const cloneDeep = require('lodash/cloneDeep')

module.exports = (prev, update) => {
  return Object.keys(update).reduce((updated, productId) => {
    if (update[productId].removed) {
      delete updated[productId]
      return updated
    }

    const batches = update[productId].batches
    const fields = update[productId].fields
    const commits = update[productId].commits
    const autoFilled = update[productId].autoFilled
    const skipCount = update[productId].skipCount
    const disabled = update[productId].disabled
    updated[productId] = Object.assign(
      {},
      updated[productId],
      batches && { batches },
      fields && { fields },
      commits && { commits },
      autoFilled && { autoFilled },
      skipCount && { skipCount },
      typeof disabled !== 'undefined' && { disabled }
    )
    return updated
  }, cloneDeep(prev) || {})
}
