import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const KeyValueTable = ({
  entries,
  className,
  alternated,
  semiHeader,
  spaced
}) => {
  if (!entries) return null

  const withWarning = entries.some(entry => entry.warning)
  return (
    <dl className={classnames(
      'vs-key-value-table',
      {'vs-key-value-table--alternated': alternated},
      {'vs-key-value-table--first-row-emphasized': semiHeader},
      {'vs-key-value-table--spaced': spaced},
      {'vs-key-value-table--warning': withWarning},
      className
    )}>
      {entries.map((entry) => (
        <div
          className={classnames(
            'vs-key-value-table__row',
            {'vs-key-value-table__row--warning': entry.warning}
          )}
          key={entry.text}
        >
          <dt className='vs-key-value-table__key'>
            {entry.text}
          </dt>
          <dd className='vs-key-value-table__value'>
            {entry.value}
          </dd>
        </div>
      ))}
    </dl>
  )
}

KeyValueTable.propTypes = {
  /**
   * Entries of KeyValueTable
  **/
  entries: PropTypes.node,
  /**
   * Set custom CSS classes to extend the KeyValueTable for your needs.
  **/
  className: PropTypes.string,
  /**
   * Alternate rows are shown with styling
   */
  alternated: PropTypes.bool,
  /**
   * First row is emphasized
   */
  semiHeader: PropTypes.bool,
  /**
   * There is a space between the rows
   */
  spaced: PropTypes.bool
}

export default KeyValueTable
