const HOST = 'https://api.paystack.co'
const ENDPOINTS = {
  verifyTransaction: (reference) => `transaction/verify/${reference}`,
  chargeAuthorization: 'transaction/charge_authorization',
  transactionsList: 'transaction'
}

class PaystackBase {
  constructor (
    paystackSecret,
    isomorphicFetch,
    logger
  ) {
    this.SECRET_KEY = paystackSecret
    this.fetch = isomorphicFetch
    this.logger = logger
  }

  async request (method, path, body) {
    const endpoint = `${HOST}/${path}`
    const options = {
      method: method.toUpperCase(),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.SECRET_KEY}`
      }
    }
    if (body) {
      Object.assign(options, {
        body: JSON.stringify(body)
      })
    }
    const response = await this.fetch(endpoint, options)
    return response.json()
  }

  verifyTransaction (reference) {
    return this.request('GET', ENDPOINTS.verifyTransaction(reference))
  }

  chargeAuthorization (data) {
    return this.request('POST', ENDPOINTS.chargeAuthorization, data)
  }

  transactionsList (data = {}) {
    const query = new URLSearchParams(data)
    return this.request('GET', `${ENDPOINTS.transactionsList}?=${query.toString()}`)
  }
}

module.exports = PaystackBase
