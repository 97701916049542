const keyBy = require('lodash/keyBy')
const createGraph = require('../../../relationship-graph')
const { listAll } = require('../../../location')

const listSuppliersForLocationIds = async (state, locationIds, {allLocations} = {}) => {
  const graph = createGraph(allLocations)

  const suppliers = {}
  for (let i = 0; i < locationIds.length; i++) {
    const locationId = locationIds[i]
    const locationSuppliers = await graph.getDescendantSuppliers(locationId)

    locationSuppliers.forEach(supplier => {
      if (!suppliers[supplier._id]) {
        suppliers[supplier._id] = Object.assign({}, supplier, {supplies: new Set()})
      }

      suppliers[supplier._id].supplies.add(locationId)
    })
  }

  return Object.values(suppliers)
}

exports.getAllSuppliersBatch = getAllSuppliersBatch
async function getAllSuppliersBatch (state, locationEntities, locationIds) {
  const date = new Date().toJSON()

  if (!locationEntities) {
    locationEntities = await listAll(state, {date, dontUseFilter: true})
  }

  // We sometimes see orders created on locations that later become inactive (through facility editing),
  // so let's filter those out.
  const locationEntitiesById = keyBy(locationEntities, '_id')
  const confirmedLocationIds = locationIds.filter(_id => locationEntitiesById[_id])
  const params = {date, allLocations: locationEntities}

  const suppliers = await listSuppliersForLocationIds(state, confirmedLocationIds, params)
  return suppliers
}
