import get from 'lodash/get'
import { formatDate } from '../../../van-shared/utils'
import { batchNumberFromId } from '../common/utils'
import { compareAsc } from 'date-fns'

const formatBatches = (batches = {}, batchMasterData = {}, productName) =>
  Object.keys(batches).reduce((accumulator, batchId) => {
    const count = get(batches, `${batchId}.fields.field:standard-physical-count.amount`)
    // Used in PSM Expiry Forms, not in VAN at the moment
    const remark = get(batches, `${batchId}.fields.field:standard-remark.amount`)
    const expiry = get(batchMasterData, `${batchId}.expiry`)
    const manufacturer = get(batchMasterData, `${batchId}.manufacturer`)
    const number = batchNumberFromId(batchId)

    // We need all batches in order to both display them and calculate
    // the correct quantities. As we can not gurantee an atomic update of both
    // stock-count/shipment and their given batches, we can't assume that the
    // expiry date will be available. In this case, we simply carry on as normal
    // and only display the expiry date to the user if we have the batch in the
    // master-data db
    const formattedBatch = {
      id: batchId,
      number,
      expiry,
      count,
      manufacturer,
      remark,
      productName
    }
    return accumulator.concat([formattedBatch])
  }, [])

const sortBatches = (batch1, batch2) => compareAsc(batch1.expiry, batch2.expiry)

const tallyCommits = commits => {
  if (!commits) {
    return
  }
  const commitKeys = Object.keys(commits)
  if (!commitKeys.length) {
    return
  }
  return commitKeys.reduce((accumulator, campaign) => {
    const commit = commits[campaign]
    if (!commit.amount) {
      return accumulator
    }
    return accumulator + commit.amount
  }, 0)
}

export const formatBatchesByProduct = (stock = {}, productsById = {}, batchMasterData = {}) =>
  Object.keys(stock).reduce((accumulator, productId) => {
    const productCode = get(productsById, `${productId}.code`)
    const productName = get(productsById, `${productId}.name`)
    if (!productCode) {
      return accumulator
    }

    accumulator[productCode] = formatBatches(stock[productId].batches, batchMasterData, productName).sort(sortBatches).map(batch => ({...batch, expiry: batch.expiry ? formatDate(batch.expiry, 'expiry') : undefined}))
    return accumulator
  }, {})

export const formatCountSummary = (stock = {}, productsById = {}, updatedStock) =>
  Object.keys(stock).reduce((accumulator, productId) => {
    const productCode = get(productsById, `${productId}.code`)
    const productName = get(productsById, `${productId}.name`)
    if (!productCode) {
      return accumulator
    }
    const reportStock = stock[productId]
    let total = reportStock.amount
    let picked = reportStock.amount
    if (updatedStock) {
      total = updatedStock[productId].amount
    }
    const batchCount = {
      code: productCode,
      name: productName,
      id: productId,
      total: total,
      picked
    }
    const reserved = tallyCommits(reportStock.commits)
    if (reserved) {
      batchCount.reserved = reserved
    }
    accumulator[productCode] = batchCount
    return accumulator
  }, {})

export const stockByProductType = (stock = {}, productsById = {}) =>
  Object.keys(stock).reduce((accumulator, productId) => {
    const productType = get(productsById, `${productId}.productType`)
    if (!productType) {
      return accumulator
    }
    if (!accumulator[productType]) {
      accumulator[productType] = {}
    }
    accumulator[productType][productId] = stock[productId]
    return accumulator
  }, {})

export const getProductGroups = (report, products, ledgerBalanceAsReport) => {
  const {
    dry,
    diluent,
    vaccine,
    general
  } = stockByProductType(report.stock, products)
  const ledgerBalanceStockByProductType = stockByProductType(ledgerBalanceAsReport.stock, products)

  const allGroups = [
    {
      name: 'Vaccines',
      products: formatCountSummary(vaccine, products, ledgerBalanceStockByProductType.vaccine)
    },
    {
      name: 'Dry goods',
      products: formatCountSummary(dry, products, ledgerBalanceStockByProductType.dry)
    },
    {
      name: 'Diluents',
      products: formatCountSummary(diluent, products, ledgerBalanceStockByProductType.diluent)
    },
    {
      name: 'Products',
      products: formatCountSummary(general, products, ledgerBalanceStockByProductType.general)
    }
  ]

  return allGroups.filter(group => Object.keys(group.products).length)
}
