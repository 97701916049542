import React, { Component } from 'react'
import { connect, Provider } from 'react-redux'
import flowRight from 'lodash/flowRight'
import { getLocalPouchDB } from '../../../sync'
import getOrCreateDeviceId from '@fielded/fs-api/lib/shipment/tools/find-or-generate-device-id'

import { Loading } from '@fielded/shared-ui'

import withConfig from '../../../van-shared/hoc/withConfig'
import { withUser } from '../../../common/AuthenticationProvider'
import { withApi } from '../../../common/ApiProvider'

import AppContainer from '../app/AppContainer'
import SyncViewPage from '../../../van-shared/components/SyncViewPage'
import { configureAppStore } from './../../../root/store'
import { getInitialState, updateRoot } from './root-reducer-slice'
import reducer from './root-reducer'

import PropTypes from 'prop-types'
import { hasNewRootReducer } from '../../../van-shared/utils/utils'

class Root extends Component {
  state = {
    initialized: false
  }

  static propTypes = {
    config: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    isSyncFinished: PropTypes.bool,
    reportsCanBeAccessed: PropTypes.bool,
    percentComplete: PropTypes.number
  }

  getChildContext () {
    return {
      rootHistory: this.props.rootHistory
    }
  }

  async componentDidMount () {
    const { user, config, api, updateRoot } = this.props
    const { deviceId } = await getOrCreateDeviceId({ PouchDB: getLocalPouchDB(user) })
    const initialState = getInitialState(user, deviceId, config)

    // TODO: remove this when the feature flag is no longer needed
    const withNewRootReducer = hasNewRootReducer()

    if (withNewRootReducer) {
      updateRoot(initialState)
    } else {
      this.store = configureAppStore(reducer, config, initialState, { user, api })
    }

    this.setState({ initialized: true, withNewRootReducer })
  }

  render () {
    const { initialized, withNewRootReducer } = this.state

    if (!initialized) {
      return null
    }

    const {
      config,
      routerParams,
      rootHistory,
      api,
      showCountSyncBanner,
      showShipmentSyncBanner,
      isSyncFinished,
      reportsCanBeAccessed,
      percentComplete
    } = this.props

    if (!reportsCanBeAccessed) {
      return <Loading />
    }

    if (!isSyncFinished) {
      return <SyncViewPage progress={percentComplete} />
    }

    return (
      withNewRootReducer ? (
        <AppContainer
          config={config}
          api={api}
          routerParams={routerParams}
          rootHistory={rootHistory}
          showCountSyncBanner={showCountSyncBanner}
          showShipmentSyncBanner={showShipmentSyncBanner}
        />
      ) : (
        <Provider store={this.store}>
          <AppContainer
            config={config}
            api={api}
            routerParams={routerParams}
            rootHistory={rootHistory}
            showCountSyncBanner={showCountSyncBanner}
            showShipmentSyncBanner={showShipmentSyncBanner}
          />
        </Provider>
      )
    )
  }
}

Root.childContextTypes = {
  rootHistory: PropTypes.object
}

// TODO: Refactor this to a functional component and use hooks
const mapDispatchToProps = {
  updateRoot
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig,
  connect(null, mapDispatchToProps)
)

export default withHOCs(Root)
