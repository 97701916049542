const writeLateFees = `
  insert_installments as (
    insert into avocado.late_fee_installment (
      event_id,
      location_id,
      item_name,
      description,
      amount,
      fee_date,
      percentage,
      overdue_amount,
      paymentplan_id,
      installment_id
    )
    select
      event_id,
      location_id,
      item_name,
      description,
      amount,
      fee_date,
      percentage,
      overdue_amount,
      paymentplan_id,
      installment_id
    from diffed_installment_lines
    returning *
  ),
  insert_invoices as (
    insert into avocado.late_fee_invoice (
      event_id,
      location_id,
      item_name,
      description,
      amount,
      fee_date,
      percentage,
      overdue_amount,
      txn_id,
      company_code
    )
    select
      event_id,
      location_id,
      item_name,
      description,
      amount,
      fee_date,
      percentage,
      overdue_amount,
      txn_id,
      company_code
    from diffed_invoice_lines
    returning *
  )
`

module.exports = writeLateFees
