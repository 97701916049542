module.exports = draftStockCountIdToLocationProperties

const {
  toDraft,
  fromDraft
} = require('./utils/draft')

const stockCountIdToLocationProperties = require('./stock-count-id-to-location-properties')

function draftStockCountIdToLocationProperties (draftId) {
  const id = fromDraft(draftId)
  const locationProperties = stockCountIdToLocationProperties(id)
  return Object.assign({}, locationProperties, {
    id: toDraft(locationProperties.id)
  })
}
