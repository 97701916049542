const getLocationSkuHistoryQuery = `
/*
  We first get counts
*/
with 
count_events as (
  select 
    e.sku, 
    e.partner_balance, 
    e.sl_balance, 
    e.sold,
    null::int as delivered, 
    e.closing,
    e.date, 
    e.driver, 
    e.event_type, 
    null as status,
    e.event_id
  from avocado.data_stocksummaryevent e
  where e.location_fsid = $1
  and e.sku = $2
  and e.event_type <> 'shipment'
  order by e.date desc
  limit $3
),
/*
  Then we get sent shipments
*/
shipments_sent as (
  select
    dsl.sku,
    null::int as partner_balance,
    null::int as sl_balance,
    null::int as sold,
    -dsl.amount as delivered,
    -dsl.amount as closing,
    ds.created_at as date,
    ds.created_by as driver,
    'shipment' as event_type,
    ds.status,
    ds.shipment_id as event_id
  from avocado.data_shipmentsnapshot ds 
  left join avocado.data_shipmentsnapshotline dsl on dsl.shipment_snapshot_id = ds.id 
  where origin_id = $1
  and sku = $2
  and ds.is_automatic_return = false
  and ds.status = 'sent'
  order by ds.created_at desc
),
/*
  Then we get received shipments
*/
shipments_received as (
  select
    dsl.sku,
    null::int as partner_balance,
    null::int as sl_balance,
    null::int as sold,
    dsl.amount as delivered,
    dsl.amount as closing,
    ds.created_at as date,
    ds.created_by as driver,
    'shipment' as event_type,
    ds.status,
    ds.shipment_id as event_id
  from avocado.data_shipmentsnapshot ds 
  left join avocado.data_shipmentsnapshotline dsl on dsl.shipment_snapshot_id = ds.id 
  where destination_id = $1
  and sku = $2
  and ds.status = 'received'
  order by ds.created_at desc
),
/*
  Then we union both to build a single shipments cte
*/
shipments as (
  select * from shipments_sent
  union all
  select * from shipments_received
),
/*
  Then we merge with shipments to create a timeline of stock activity. We also craft a partiton window using count dates
*/
stock_activity as (
  select 
    *,
    date as partition_window
  from count_events
  union all
  select 
    *,
  (select count_events.date from count_events where shipments.date > count_events.date order by count_events.date desc limit 1) as partition_window 
  from shipments
),
/*
  We figure out current openings
*/
running_totals as (
  select 
    sum(closing) over (
      partition by partition_window
      order by date ) as running_total,
    *
  from stock_activity
  order by date desc
)
/*
  Tying it all together
*/
select 
  sku,
  date,
  event_type,
  event_id,
  driver,
  status,
  sold,
  delivered,
  sl_balance,
  partner_balance,
  running_total AS current_opening,
  LAG(running_totals.running_total, 1) OVER (ORDER BY running_totals.date) AS prev_opening
from running_totals
order by date;
`
module.exports = getLocationSkuHistoryQuery
