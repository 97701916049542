import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CrossLink from '../../../common/cross-link'
import get from 'lodash/get'

import { shouldTrackBatches } from '@fielded/fs-api/lib/tools'
import { Button, DeemphasizedLink, Form, Page, ProgressBar, OptionsGroup } from '@fielded/shared-ui'
import { Arrow, ExclamationCircle, PlusCircle } from '@fielded/shared-ui/src/icons'

import Header from './Header'
import NoReportEntry from './NoReportEntry'
import FormContainer from './FormContainer'
import Sidebar from './Sidebar'
import Footer from './Footer'

const scForm = React.createRef()

class ReportEntry extends Component {
  state = {
    isValidating: false,
    isPristine: true
  }

  formChanged = ({isPristine}) => {
    this.setState({
      isPristine: isPristine
    })
  }

  resetProductFields = () => {
    scForm.current.reset()
    this.props.discardOngoingProductEntry()
    this.setState({
      isPristine: true
    })
  }

  validateProductFields = async (successFunction) => {
    this.setState({isValidating: true})
    const validationErrors = await scForm.current.validate()
    this.setState({isValidating: false})

    if (!validationErrors) {
      successFunction()
    } else {
      return validationErrors
    }
  }

  render () {
    const {
      error,
      url,
      report,
      location,
      programName,
      programId,
      serviceName,
      serviceId,
      period,
      products,
      productLines,
      currentProduct,
      currentProductNumber,
      showLocationContact,
      showAddProduct,
      onClickLedgerLink,
      onClickSave,
      onClickPrev,
      onClickNext,
      onClickSubmit,
      onClickAddProducts,
      onProductNavigation,
      goBack,
      alertsRedirect,
      rootHistory,
      config,
      readOnly,
      formContainerProps,
      handleDisableProduct
    } = this.props

    if (!currentProduct) {
      return <div className='report-entry'>
        <NoReportEntry
          programName={programName}
          serviceName={serviceName}
          location={location.name}
          period={period.display}
          updatedAt={report.updatedAt}
          onClickBack={goBack}
        />
      </div>
    }

    const progressPercentage = report.progress.complete / report.progress.total
    let productInfo = `${currentProductNumber}. ${currentProduct.name}`

    // Progress should show for multi-field count
    // on locations that do not submit batches
    // Therefore, only use 'location' object (not product),
    // then it would show/hide depending on product view
    const showProgress = !shouldTrackBatches({ serviceId, location: { id: location._id } })
    const disabledProduct = formContainerProps.disabledByOption

    const alertsButtonIcon = alertsRedirect.isFromAlerts ? <Arrow direction='left' /> : <ExclamationCircle />
    const alertsButtonText = alertsRedirect.isFromAlerts ? 'Back to Alerts' : 'Alerts'

    return (
      <Page className='report-entry' title={config.title}>
        <Header
          programName={programName}
          serviceName={serviceName}
          location={location.name}
          contact={showLocationContact ? get(location, `contacts.${programId}`) : null}
          period={period.display}
          updatedAt={report.updatedAt}
          onClickSave={onClickSave}
          config={config}
        />
        <main className='wrapper'>
          <Sidebar
            serviceName={serviceName}
            productLines={productLines}
            productList={products}
            stock={report.stock}
            activeProductId={currentProduct._id}
            onProductNavigation={onProductNavigation}
            markComplete={showProgress}
          />
          <div className='main-column main-column--report'>
            {error && (
              <Page.Panel.Message type='warning'>
                {error}
              </Page.Panel.Message>
            )}
            {showAddProduct && (
              <Button
                fill='outline'
                colorVariant='brand'
                icon={<PlusCircle />}
                onClick={e => onClickAddProducts()}
              >
                Add product
              </Button>
            )}
            {alertsRedirect.hasAlertsRole && (
              <div className='main-column__alert-button'>
                <Button
                  to={'/alerts'}
                  fill='outline'
                  colorVariant='brand'
                  icon={alertsButtonIcon}
                  history={rootHistory}
                  component={CrossLink}
                >
                  {alertsButtonText}
                </Button>
              </div>
            )}
            {showProgress &&
              <ProgressBar
                className='vs-u-margin-top'
                percentage={progressPercentage}
                styleVariant='decorated'
              />
            }
            <section className='main-column__wrapper'>
              <div>
                <div className='main-column__content'>
                  {handleDisableProduct && <OptionsGroup.Stateless
                    label={'Manage Product:'}
                    value={`${!disabledProduct}`}
                    options={[
                      {
                        value: 'true',
                        label: 'Product in use',
                        checked: !disabledProduct
                      },
                      {
                        value: 'false',
                        label: 'Product not in use',
                        checked: disabledProduct
                      }
                    ]}
                    onValueChange={handleDisableProduct}
                  />}
                  <div className='main-column__header'>
                    <h3 className='main-column__title'>
                      {productInfo}
                      {currentProduct.basicUnit &&
                        <span className='main-column__unit'>{currentProduct.basicUnit}</span>
                      }
                      {onClickLedgerLink && (
                        <span className='report-entry__ledger-link'>
                          <DeemphasizedLink
                            onClick={onClickLedgerLink}
                            colorVariant='brand'
                          >
                            view ledger
                          </DeemphasizedLink>
                        </span>
                      )}
                    </h3>
                    { shouldTrackBatches({ currentProduct, location: { id: location._id } }) &&
                      <Button
                        component={Link}
                        colorVariant='brand'
                        to={`${url}/add-batch`}
                        fill='outline'
                        icon={<PlusCircle />}
                      >
                        Add batch
                      </Button>
                    }
                  </div>
                  <Form
                    ref={scForm}
                  >
                    <FormContainer
                      {...formContainerProps}
                      onFormChange={this.formChanged}
                    />
                    {(!report.createdAt || !this.state.isPristine) && config.app !== 'shelflife' && (
                      <span className='report-form__discard-button'>
                        <DeemphasizedLink
                          onClick={this.resetProductFields}
                        >
                          Discard changes to this product
                        </DeemphasizedLink>
                      </span>
                    )}
                  </Form>
                </div>

                <div className='main-column__button-holder'>
                  <div className='main-column__button-holder--left'>
                    {currentProductNumber > 1 &&
                      <Button
                        fill='outline'
                        colorVariant='brand'
                        id='prevProductBtn'
                        icon={<Arrow direction='left' />}
                        loading={this.state.isValidating}
                        onClick={event => this.validateProductFields(onClickPrev)}
                      >
                        Previous
                      </Button>
                    }
                  </div>

                  <div className='main-column__button-holder--right'>
                    {currentProductNumber < products.length &&
                      <Button
                        fill='full'
                        colorVariant='brand'
                        id='nextProductBtn'
                        icon={<Arrow direction='right' />}
                        iconSide='right'
                        loading={this.state.isValidating}
                        onClick={event => this.validateProductFields(onClickNext)}
                      >
                        Next product
                      </Button>
                    }
                  </div>
                </div>
              </div>

            </section>
          </div>
        </main>
        {!readOnly && (<Footer
          onClickSave={onClickSave}
          onClickSubmit={event => this.validateProductFields(onClickSubmit)}
        />)}
      </Page>
    )
  }
}

ReportEntry.propTypes = {
  url: PropTypes.string.isRequired,
  report: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  programName: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  serviceName: PropTypes.string,
  serviceId: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  productLines: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentProduct: PropTypes.object.isRequired,
  currentProductNumber: PropTypes.number.isRequired,
  showLocationContact: PropTypes.bool.isRequired,
  onClickPrev: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onProductNavigation: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
}

export default ReportEntry
