import { locationIdToProperties } from '@fielded/fs-api/lib/tools'

export default function enableFormForUser (user, enabledPrograms) {
  const hasPrograms = user.programs.some(name => enabledPrograms.includes(name))
  if (hasPrograms) {
    if (user.location.id) {
      const { level } = locationIdToProperties(user.location.id)
      return ['national', 'state'].includes(level)
    }
  }
}
