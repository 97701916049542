const get = require('lodash/get')

exports.addProductsToSubOrders = function (
  snapShotsToAdd,
  snapShotsToAddTo,
  supplierId
) {
  // Get products to add to count for each destination
  const productsToAddPerDestination = snapShotsToAdd.reduce((acc, snapshot) => {
    const { products, destinationId } = snapshot
    const productsForDestination = Object.keys(products).reduce(
      (acc, product) => {
        const adjusted = get(products[product], 'adjusted', 0)
        const allocationType = get(products[product], 'allocationType', '')

        acc[product] = { adjusted, allocationType }
        return acc
      },
      {}
    )
    acc[destinationId] = productsForDestination
    return acc
  }, {})

  return Object.keys(productsToAddPerDestination).reduce((acc, destination) => {
    // Should be only one destination - supplier pair
    const detinationSnapshotToAddTo = snapShotsToAddTo.find(({ destinationId }) => destinationId === destination)

    if (detinationSnapshotToAddTo) {
      const { products } = detinationSnapshotToAddTo
      const destinationProductsKeys = Object.keys(products)
      const productsToAdd = productsToAddPerDestination[destination]

      const finalProducts = Object.keys(productsToAdd).reduce((products, product) => {
        if (destinationProductsKeys.find(e => e === product)) {
          products[product]['adjusted'] += productsToAdd[product]['adjusted']
        } else {
          products[product] = {
            allocationType: productsToAdd[product]['allocationType'],
            original: 0,
            adjusted: productsToAdd[product]['adjusted']
          }
        }
        return products
      }, products)

      const newSnapshot = {
        ...detinationSnapshotToAddTo,
        products: finalProducts
      }
      acc.push(newSnapshot)
      return acc
    }

    // If it donest exist just clone the existing order and switch it's supplierId
    const snapshotToClone = snapShotsToAdd.find(
      ({ destinationId }) => destinationId === destination
    )
    const newSnapshot = {
      ...snapshotToClone,
      supplierId
    }
    acc.push(newSnapshot)
    return acc
  }, [])
}
