import get from 'lodash/get'

export const removeDelimiters = q => q.replace(/,/g, '')
export const removeSuffix = q => q.substr(0, q.length - 1)

/**
 * Filter items on the given prop value
 */
export const filterByProp = (items = [], filterTerm = '', prop = 'name', useSoundex = false) => {
  // We allow passing an array of searchTerms too
  let searchTerms
  if (typeof filterTerm === 'string') {
    const sanitisedFilterTerms = filterTerm.toLowerCase().trim()
    if (!sanitisedFilterTerms) return items
    searchTerms = sanitisedFilterTerms.split(' ')
  } else if (typeof filterTerm === 'object') {
    const lowercaseFilterTerms = filterTerm.map(word => word.toLowerCase())
    searchTerms = lowercaseFilterTerms
  } else return items

  return items.filter(item => {
    const filterProp = get(item, prop)

    if (!filterProp) return false

    if (typeof filterProp === 'boolean') {
      return filterProp
    }

    // We split the property for item into substring array
    const itemWords = filterProp.split(' ')

    // We make sure the words are all lowercase
    const lowercaseItemWords = itemWords.map(word => word.toLowerCase())

    // We make sure that each searched word is matched at least partially
    return searchTerms.every(element => {
      return lowercaseItemWords.find(word =>
        word.includes(element) ||
        (useSoundex && (soundex(word) === soundex(element))))
    })
  })
}

// Adapted from https://github.com/LouisT/node-soundex/blob/49e1f73ad2eb3c5c63e85b36a9972c022f3a5b1a/index.js
// LICENSE https://github.com/LouisT/node-soundex/blob/49e1f73ad2eb3c5c63e85b36a9972c022f3a5b1a/LICENSE
const soundex = function (str, scale) {
  const split = String(str).toUpperCase().replace(/[^A-Z]/g, '').split('')
  const map = {BFPV: 1, CGJKQSXZ: 2, DT: 3, L: 4, MN: 5, R: 6}
  const keys = Object.keys(map).reverse()
  var build = split.map(function (letter, index, array) {
    for (var num in keys) {
      if (keys[num].indexOf(letter) !== -1) {
        return map[keys[num]]
      };
    };
  })

  var first = build.splice(0, 1)[0]
  build = build.filter(function (num, index, array) {
    return ((index === 0) ? num !== first : num !== array[index - 1])
  })
  const max = 4
  return split[0] + (build.join('') + (new Array(max + 1).join('0'))).slice(0, max)
}

/*
 * NOTE: This is far from optimal! At the scale we're dealing with it oughtn't be a
 * big issue but incase we see perf issues we should revisit this.
 */
export const filterByProps = (items, filterTerm = '', props = ['name'], useSoundex = false) => {
  const matchedItems = {}
  const results = props.map(prop => filterByProp(items, filterTerm, prop, useSoundex))
    .reduce((acc, cur) => ([
      ...acc,
      ...cur
    ]), [])

  const filteredResults = results
    // Ensure only unique values
    .filter(item => {
      // Create a uniqueId based on string based public prop keys and values
      const tmpUniqueId = Object.keys(item).map(key => {
        if (typeof item[key] === 'string') {
          return `${key}:${item[key].toLowerCase()}:`
        }
        return ''
      }).join('')

      const uniqueId = tmpUniqueId.substr(0, tmpUniqueId.length - 1)

      if (matchedItems[uniqueId]) {
        return false
      }
      matchedItems[uniqueId] = true
      return true
    })

  return filteredResults
}

/*
 * Pluralize a word.
 *
 * Given we currently only need to pluralize english regular nouns, we can use
 * a simple ternary operation.
 */
export const pluralize = (noun, count, plural) => {
  if (!noun) return
  const isPlural = count !== 1

  if (plural) {
    // if a custom plural was passed, for irregular nouns
    return isPlural ? plural : noun
  }

  if (noun.endsWith('y') && isPlural) {
    // if second last char is not a vowel, e.g. in day
    if (/[aeiou]/.test(noun.substring(0, noun.length - 2))) {
      return noun.replace(/.$/, 'ies')
    }
  }

  return !isPlural ? noun : `${noun}s`
}
