const isAfter = require('date-fns/is_after')
const addDays = require('date-fns/add_days')
const sortBy = require('lodash/sortBy')
const closestTo = require('date-fns/closest_to')
const { CURRENCY_BY_COUNTRY } = require('./../../lib/payment/constants')
const {
  PAYMENT_PLAN_FREQUENCIES,
  ACTIVE_MANDATE_DATE_DELAY,
  NG_DEFAULT_PAYMENT_TERM,
  KE_DEFAULT_PAYMENT_TERM,
  PENDA_DEFAULT_PAYMENT_TERM
} = require('./constants')

function getPaymentPlanBalance (paymentPlans) {
  const today = new Date()
  const balance = { total: 0, overdueBalance: 0 }
  for (const plan of paymentPlans) {
    if (!plan.is_active) {
      continue
    }
    balance.total += plan.quickbooks_account_balance
    const unpaidInstallments = plan.instalments.filter(({ is_paid: isPaid }) => isPaid === false)
    unpaidInstallments.forEach(({ amount, due_date: dueDate }) => {
      if (isAfter(today, new Date(dueDate))) {
        balance.overdueBalance += amount
      }
    })
  }
  return balance
}

function getNextInstalmentDueDate (instalments) {
  if (!instalments.length) return
  const today = new Date()
  const dueDates = instalments.map((i) => new Date(i.due_date))
  const nextDueDate = closestTo(today, dueDates)
  return nextDueDate.toISOString()
}

function getUnpaidInstalmentsCount (paymentPlans) {
  let paymentPlansCount = 0
  paymentPlans.forEach((plan) => {
    if (plan.is_paid) return
    plan.instalments.forEach(({ is_paid: isPaid }) => {
      if (!isPaid) {
        paymentPlansCount++
      }
    })
  })
  return paymentPlansCount
}

function isOverduePaymentPlan (paymentPlans, hasActiveMandate) {
  const today = new Date()
  let isOverdue = false
  for (const plan of paymentPlans) {
    // two or more installments is not paid then it's marked as overdue
    if (plan.is_active === true) {
      const overdueInstallments = plan.instalments.filter(installment => {
        const dueDate = new Date(hasActiveMandate ? addDays(installment.due_date, ACTIVE_MANDATE_DATE_DELAY) : installment.due_date)
        return !installment.is_paid && dueDate < today
      })
      if (overdueInstallments.length >= 2) {
        isOverdue = true
      }
      //  if payment plan that is starting in a future date and at least one of first two installments is not paid then it marked as overdue
      if (new Date(plan.start_date) > today) {
        const instalments = sortBy(plan.instalments, 'due_date').slice(0, 2).filter(installment => !installment.is_paid)
        if (instalments.length >= 1) {
          isOverdue = true
        }
      }
    }
  }
  return isOverdue
}

function addAmountPaidToInstalments (plan) {
  if (!plan.instalments) {
    return plan
  }
  // To calculate the amount paid we use the same strategy as in
  // the transaction list installments query:
  // api/lib/finances/queries/common/installments.js
  const ia = plan.installment_amount - plan.service_fee
  const qb = plan.quickbooks_account_balance
  const num = plan.instalments.length
  // Sort installments by due date ascending
  plan.instalments.sort((a, b) => a.due_date < b.due_date ? -1 : 1)
  // Chronologically calculate what belongs to each installment of the total amount paid
  for (const [i, installment] of plan.instalments.entries()) {
    const principalPaid = Math.max(0, Math.min(ia, ia * num - qb - i * ia))
    const amountPaid = principalPaid > 0 ? principalPaid + plan.service_fee : 0
    installment.amount_paid = Math.round(amountPaid * 100) / 100
  }
  return plan
}

const getFeePercent = (term, defaultTerm, feePercentage, feePercentageLongTerm) => {
  return term <= defaultTerm ? (feePercentage || 0) : (feePercentageLongTerm || 0)
}

function generatePaymentPlanData ({
  invoiceData,
  market,
  isPayPerUseClient,
  frequency = PAYMENT_PLAN_FREQUENCIES.WEEKLY,
  feePercentage = 0,
  feePercentageLongTerm = 0,
  term = 0,
  serviceFee = 0,
  startDate
}) {
  const pastDue = invoiceData.amount - (invoiceData.amount_paid || 0)
  let paymentTerms = invoiceData.company_code === 'NG' ? NG_DEFAULT_PAYMENT_TERM : KE_DEFAULT_PAYMENT_TERM
  paymentTerms = term || paymentTerms
  serviceFee = serviceFee || 0
  let percentageFee = 0
  if (market === 'penda') {
    paymentTerms = term || PENDA_DEFAULT_PAYMENT_TERM
    percentageFee = getFeePercent(paymentTerms, PENDA_DEFAULT_PAYMENT_TERM, feePercentage, feePercentageLongTerm)
  }

  if (invoiceData.company_code === 'NG') {
    percentageFee = getFeePercent(paymentTerms, NG_DEFAULT_PAYMENT_TERM, feePercentage, feePercentageLongTerm)
  } else if (invoiceData.company_code === 'KE' && market !== 'penda') {
    percentageFee = getFeePercent(paymentTerms, KE_DEFAULT_PAYMENT_TERM, feePercentage, feePercentageLongTerm)
  }

  // if no percentageFee set after checks pick the available maximum fee as default
  percentageFee = percentageFee || Math.max(feePercentage, feePercentageLongTerm)
  if (isPayPerUseClient && percentageFee) {
    const feeAmount = pastDue * (percentageFee / 100)
    serviceFee = Math.ceil(feeAmount / paymentTerms)
  }

  // service fee should be an integer
  const serviceFeePerIntalment = Math.ceil(serviceFee / paymentTerms)
  const instalmentAmount = Math.ceil((pastDue + serviceFee) / paymentTerms)
  const paymentPlan = {
    location_id: invoiceData.location_id,
    start_date: startDate,
    past_due: pastDue,
    installment_amount: instalmentAmount,
    term: paymentTerms,
    service_fee: serviceFeePerIntalment,
    waive_service_fee: !serviceFeePerIntalment,
    is_active: false,
    is_paid: false,
    frequency,
    company_code: invoiceData.company_code,
    currency: CURRENCY_BY_COUNTRY[invoiceData.company_code.toLowerCase()]
  }
  return { paymentPlan, pastDue }
}

// all auto payment plans made for loans have a start date of the next friday from the received due date
const calculateStartDate = (dueDate, frequency = PAYMENT_PLAN_FREQUENCIES.WEEKLY) => {
  const frequencyDays = {
    WEEKLY: 0,
    BIWEEKLY: 14,
    MONTHLY: 28,
    BIMONTHLY: 56,
    TRIMONTHLY: 84
  }

  const date = new Date(dueDate)
  let daysToAdd = 0
  const dayOfWeek = date.getUTCDay()
  if (dayOfWeek < 5) {
    daysToAdd = (5 - dayOfWeek)
  } else if (dayOfWeek === 6) {
    daysToAdd = dayOfWeek
  } else {
    daysToAdd = (2 + dayOfWeek)
  }
  return new Date(date.setUTCDate(date.getUTCDate() + daysToAdd + (frequencyDays[frequency] || frequencyDays.WEEKLY))).toJSON().slice(0, 10)
}

module.exports = {
  getPaymentPlanBalance,
  getNextInstalmentDueDate,
  getUnpaidInstalmentsCount,
  isOverduePaymentPlan,
  addAmountPaidToInstalments,
  generatePaymentPlanData,
  calculateStartDate
}
