import { Component } from 'react'
import PropTypes from 'prop-types'

class FormFieldRegistrationManager extends Component {
  static propTypes = {
    /**
     * The component's children
     */
    children: PropTypes.node.isRequired,

    /**
     * The de-registration function to call on unmount
     */
    deregister: PropTypes.func.isRequired,

    /**
     * The registration function to call on mount
     */
    register: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.register()
  }

  componentWillUnmount () {
    this.props.deregister()
  }

  render () {
    return this.props.children
  }
}

export default FormFieldRegistrationManager
