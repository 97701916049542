const { byKey } = require('../../../tools/by-id')

const ORDER_STATUSES = [
  {
    key: 'request',
    displayName: 'request',
    viewedByRole: 'userRole:pharmacyUser'
  },
  {
    key: 'new',
    displayName: 'provisional',
    isCompleteDisplayName: 'error', // modified from "submitted" to address network failure in #4545
    viewedByRole: 'feature:orders:requester',
    useClosedStatus: true // For SL to return "confirmed", "cancelled"
  },
  {
    key: 'warehouse',
    displayName: 'warehouse',
    isCompleteDisplayName: 'accepted',
    viewedByRole: 'feature:orders:warehouse'
  },
  {
    key: 'accepted',
    displayName: 'accepted',
    isCompleteDisplayName: 'completed',
    viewedByRole: 'feature:orders:complete'
  },
  {
    key: 'cancelled',
    displayName: 'cancelled'
  }
]
exports.ORDER_STATUSES = ORDER_STATUSES

const ORDER_STATUSES_MAP = byKey(ORDER_STATUSES, 'key')

const ORDER_STATUSES_KEYS = ORDER_STATUSES.map(({key}) => key)

const isValidOrderStatus = status => ORDER_STATUSES_KEYS.includes(status)
exports.isValidOrderStatus = isValidOrderStatus

exports.getGroupStatus = getGroupStatus
function getGroupStatus (orders) {
  let latestStatus = orders[0] ? orders[0].status : 'new'
  orders.forEach(order => {
    // We want latest status here for the group because some orders
    // can have no products by the time they reach accepted, so there is no accepted snapshot
    latestStatus = isLater(order.status, latestStatus)
      ? order.status
      : latestStatus
  })

  const isComplete = orders.every(({isComplete}) => isComplete)
  // check on closedStatus, that they all are the same
  // this is for being used with SL orders
  const closedStatuses = new Set(orders.map(({closedStatus}) => closedStatus))
  // if there's more than one closed status, use the old way
  // this is only expected for PSM, and they want the old way anyway
  // also they don't put closed status on new orders at the moment
  let closedStatus = null
  if (closedStatuses.size === 1) {
    closedStatus = Array.from(closedStatuses)[0]
  }

  const orderTypes = new Set(orders.map(({ orderType }) => orderType))
  // check that all the order types are the same
  let orderType = 'null'
  if (orderTypes.size === 1) {
    orderType = Array.from(orderTypes)[0]
  }

  const statusDisplayName = getDisplayName(latestStatus, isComplete, closedStatus)
  return {status: latestStatus, statusDisplayName, isComplete, orderType}
}

function getDisplayName (statusKey, isComplete, closedStatus) {
  const statusDefinition = ORDER_STATUSES_MAP[statusKey]
  if (!statusDefinition) return

  if (closedStatus && statusDefinition.useClosedStatus) {
    return closedStatus
  }

  return isComplete
    ? statusDefinition.isCompleteDisplayName
    : statusDefinition.displayName
}

exports.getOrderStatus = getOrderStatus
function getOrderStatus (snapshotsByStatus) {
  // If any snapshot in this status is not complete, status is not complete
  let isComplete = true
  let closedStatus = false
  let latestStatus = ORDER_STATUSES_KEYS[0]
  ORDER_STATUSES.forEach(statusDefinition => {
    const snapshots = snapshotsByStatus[statusDefinition.key]
    if (!snapshots) return

    // Latest snapshot is our order entity status,
    // e.g. we find a 'new', at 'warehouse', then 'cancelled'
    snapshots.forEach(snapshot => {
      latestStatus = isLater(snapshot.status, latestStatus)
        ? snapshot.status
        : latestStatus
      isComplete = snapshot.isComplete
      closedStatus = snapshot.closedStatus
    })
  })
  return {
    status: latestStatus,
    statusDisplayName: getDisplayName(latestStatus, isComplete, closedStatus),
    isComplete
  }
}

function isLater (status, otherStatus) {
  if (!otherStatus) return true
  return ORDER_STATUSES_KEYS.indexOf(status) > ORDER_STATUSES_KEYS.indexOf(otherStatus)
}

exports.getNextStatus = getNextStatus
function getNextStatus (status) {
  const nextIndex = ORDER_STATUSES_KEYS.indexOf(status)
  const nextStatus = ORDER_STATUSES_KEYS[nextIndex + 1]
  if (!nextStatus) {
    throw new Error(`Cannot move order group with status ${status}. Next status not found.`)
  }

  return nextStatus
}

exports.groupCanAdvance = groupCanAdvance
function groupCanAdvance (userStatus, allOrders, groupId) {
  const orders = groupId
    ? allOrders.filter(order => order.groupId === groupId)
    : allOrders
  const {status: groupStatus} = getGroupStatus(orders)
  return (userStatus === groupStatus)
}
