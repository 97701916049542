export const get = () =>
  Promise.resolve(JSON.parse(window.localStorage.getItem('session')))

export const set = session =>
  Promise.resolve(window.localStorage.setItem('session', JSON.stringify(session)))

export const remove = () =>
  Promise.resolve(window.localStorage.removeItem('session'))

export const getLoggedInAsAdmin = () =>
  Promise.resolve(window.localStorage.getItem('loginAsAdmin'))

export const setLoggedInAsAdmin = (adminName) =>
  Promise.resolve(window.localStorage.setItem('loginAsAdmin', adminName))

export const removeLoggedInAsAdmin = () =>
  Promise.resolve(window.localStorage.removeItem('loginAsAdmin'))
