import React from 'react'

import { Banner } from '@fielded/shared-ui'

export default ({ error }) => {
  return (
    <Banner
      type='warning'
      inline
    >
      <h2>Online Only Shipment</h2>

      <div>
        You need a network connection to update shipments outside your sync range.

        As an online-offline user, you sync only open shipments, and shipments marked
        as received within the last months, for your location and for your direct child stores.

        The original error was: {error && (error.message || error)}
      </div>
    </Banner>
  )
}
