import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
import Button from '../Button'
import DocumentStatus from '../DocumentStatus'

const DocumentHeader = ({
  name,
  status
}) => (
  <div className='document-header'>
    <Text.Heading tag='h1' className='document-header__title'>
      {name}
    </Text.Heading>
    {status && (
      <DocumentStatus status={status} />
    )}
    <Button
      fill='full'
      colorVariant='brand'
      className='vs-u-hide-on-print'
      onClick={() => window.print()}
    >
      Print
    </Button>
  </div>
)

DocumentHeader.propTypes = {
  /**
  * A heading that should show as the title
  */
  name: PropTypes.string.isRequired,
  /**
   * A status of the document (eg. 'completed')
  */
  status: PropTypes.string
}

export default DocumentHeader
