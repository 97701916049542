import { batchIdToProductId } from '../common/utils'
import get from 'lodash/get'
import {getLocation as getLocationEntity} from '@fielded/fs-api/lib/tools'

const addProductDataToShipment = (shipment, products) => {
  if (!shipment.counts) return shipment

  const shipmentProductIds = Object.keys(shipment.counts)
    .map(batchId => batchIdToProductId(batchId))

  // IDs were deducted from batch IDs, so there are very likely duplicates
  // from multiple batches of the same product. Clean those out and get
  // the product definitions for a unique set of the products in shipment.
  const shipmentProducts = [...new Set(shipmentProductIds)]
    .map(productId => {
      // UI fallback. if back product data gets in to a shipment (id from other territory)
      // show the id as the name
      const code = productId.replace('product:', '')
      return products[productId]
        ? products[productId]
        : {_id: productId, name: code, code}
    })

  return {
    ...shipment,
    products: shipmentProducts
  }
}

export const addProductData = (shipments, products) => {
  if (!shipments) return null
  if (!products) return shipments

  // A single shipment was passed, just handle
  if (shipments.counts) return addProductDataToShipment(shipments, products)

  if (Array.isArray(shipments)) {
    return shipments.reduce((enrichedShipments, shipment) => {
      const shipmentWithProduct = addProductDataToShipment(shipment, products)
      enrichedShipments.push(shipmentWithProduct)
      return enrichedShipments
    }, [])
  } else {
    return Object.keys(shipments).reduce((enrichedShipments, shipmentId) => {
      const shipment = shipments[shipmentId]
      const shipmentWithProduct = addProductDataToShipment(shipment, products)
      enrichedShipments[shipmentId] = shipmentWithProduct
      return enrichedShipments
    }, {})
  }
}

export const withRouteIds = (rawLocationDocs, shipments) => {
  if (!rawLocationDocs) {
    console.log('returning early no location docs')
    return shipments
  }
  if (!shipments) {
    console.log('returning early no shipments')
    return
  }

  return {
    complete: shipments.complete.map(shipment => withRouteId(shipment, rawLocationDocs)),
    incomplete: shipments.incomplete.map(shipment => withRouteId(shipment, rawLocationDocs))
  }
}

function withRouteId (shipment, rawLocationDocs) {
  const locationDoc = rawLocationDocs[shipment.destination.id]
  if (!locationDoc) return shipment

  const locationEntity = getLocationEntity(locationDoc, shipment.date)
  const {routeId} = get(locationEntity, 'programs.0.services.0', {})

  if (!routeId) {
    return shipment
  }

  const transformedRouteId = routeId.includes('funder')
    ? routeId.replace('funder:', '')
    : routeId.replace('route:', '')

  return {
    ...shipment,
    routeId: transformedRouteId,
    funderId: transformedRouteId
  }
}

export const isShipmentInUserMarket = (shipment, user) => {
  return (
    (shipment.origin.id === user.location.id) ||
    (shipment.destination.id === user.location.id) ||
    (shipment.origin.state === user.location.state) ||
    (shipment.destination.state === user.location.state)
  )
}

export const addNoOpenerAttributeToShipments = (shipments, user) => {
  const updatedShipments = shipments.map((shipment) => {
    if (!isShipmentInUserMarket(shipment, user)) {
      shipment.linkNotAvailable = true
    }
    return shipment
  })
  return updatedShipments
}
