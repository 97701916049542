import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  Card,
  CountBadge,
  IconBadge,
  Text
} from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'
import { toast } from '@fielded/shared-ui/src/components/Page/Page'

import NotificationItem from '../NotificationItem'
import PushNotificationSettingsCard from '../PushNotificationSettingsCard'
import { checkNotifications, initializeNotifications, dismissNotifications, testNotifications } from '../../../utils/notifications'

const HomeNotificationView = ({
  username,
  config,
  api,
  currentUser,
  cardVisible,
  notifications,
  onToggleNotificationsBanner
}) => {
  const [status, setStatus] = useState('')
  const [loadingPushNotifications, setLoadingPushNotifications] = useState(false)
  const [showNotificationsBanner, setShowNotificationsBanner] = useState(true)
  const [notificationSettings, setNotificationSettings] = useState('')

  const userCanEnablePushNotifications = currentUser && config && api

  useEffect(() => {
    if (userCanEnablePushNotifications) {
      const checkNotifSettings = async () => {
        const notificationsStatus = await checkNotifications(currentUser, config, api)
        setStatus(notificationsStatus)
      }

      checkNotifSettings()
    } else {
      setShowNotificationsBanner(false)
    }
  }, [userCanEnablePushNotifications])

  const setupNotifications = async (version) => {
    setLoadingPushNotifications(true)

    if (version === 'initialize') {
      const pr = await initializeNotifications(currentUser, config, api)

      if (pr && pr.firebasePromise) {
        await pr.firebasePromise
      } else {
        setLoadingPushNotifications(false)
        setShowNotificationsBanner(false)
        return
      }

      toast({
        title: 'Notifications are on',
        type: 'success',
        children: (
          <Text size='small'>
            You will receive app push notifications on this device.
          </Text>
        )
      })

      setLoadingPushNotifications(false)
      setShowNotificationsBanner(false)
      setNotificationSettings('initialized')
    }

    if (version === 'dismiss') {
      await dismissNotifications(api)
      setNotificationSettings('dismissed')
    }

    if (version === 'test') {
      await testNotifications(config)
    }

    setLoadingPushNotifications(false)
    setShowNotificationsBanner(false)
    setNotificationSettings(notificationSettings)
  }

  const NUMBER_OF_NOTIFICATIONS_DISPLAYED = 3
  const notificationsToDisplay = notifications.slice(
    0,
    NUMBER_OF_NOTIFICATIONS_DISPLAYED
  )
  const notificationsLength = notifications && notifications.length
  const unreadNotificationsLength = notifications.filter(
    (notification) => !notification.readAt
  ).length

  return (
    <Fragment>
      <div className='retailer-salutation retailer-salutation--with-notifications'>
        <Text.Heading size='smallish'>
          <span className='vs-u-font-regular'>Hello,</span> {username}
        </Text.Heading>
        <div className='retailer-notification--list'>
          <button
            className='retailer-notification__trigger'
            onClick={() => onToggleNotificationsBanner()}
          >
            <IconBadge
              icon='🔔'
              size='large'
              withShadow
              isPressed={cardVisible}
              className='retailer-notification__trigger-icon'
            />
            {unreadNotificationsLength > 0 && (
              <CountBadge
                count={
                  unreadNotificationsLength === 9
                    ? '10+'
                    : unreadNotificationsLength
                }
                colorCode='accent'
                className='retailer-notification__trigger-badge'
              />
            )}
          </button>
          {cardVisible && (
            <Card
              className='retailer-card home-notifications-view__card home-notifications-view__card--fixed'
              tight
              rounded
              withPointer
            >
              {showNotificationsBanner && !notificationSettings
                ? (<PushNotificationSettingsCard
                  user={currentUser}
                  api={api}
                  loading={loadingPushNotifications}
                  onSetNotifications={setupNotifications}
                  withPointer={false}
                  status={status}
                />) : <Card.Content> <Text bold size='large'>Notifications</Text> </Card.Content>
              }
              {!!notificationsLength && (
                <section className='home-notifications-view__list'>
                  {notificationsToDisplay.map((notification) => {
                    return (
                      <NotificationItem
                        notification={notification}
                        key={notification.id}
                      />
                    )
                  })}
                  <Button
                    component={Link}
                    to={{
                      pathname: '/retailer/notifications'
                    }}
                    icon={<Arrow direction='right' />}
                    iconSide='right'
                    fill='full'
                    colorVariant='brand'
                    className='home-notifications-view__link'
                  >
                    See more notifications
                  </Button>
                </section>
              )}
              {!notificationsLength && (
                <Card.Content className='retailer-card__content'> There are no notifications to view</Card.Content>
              )}
            </Card>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default HomeNotificationView
