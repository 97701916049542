const get = require('lodash/get')

const proposalApi = require('../../../proposal')
const { list: listPrograms } = require('../../../program')
const { get: getRoute } = require('../../../routes')
const { getWithProposal } = require('./../read/get-with-proposal')
const { listWarehouseCodes } = require('./../read/list-warehouse-codes')
const tools = require('../../tools')

exports.proposeChange = async function (state, {
  edits,
  effectiveDate,
  proposalType = 'update',
  createPSMCodes = false,
  supportCountry = false,
  joinCurrentRoute = false,
  timestamp = new Date().toISOString(),
  returnProgramsHistory = false
}) {
  const isCreate = proposalType === 'create'
  const proposalEntity = isCreate
    ? await proposeCreate(state, {edits, effectiveDate, timestamp, supportCountry})
    : await proposeUpdate(state, {edits, effectiveDate, timestamp})

  await maybeUpdateLocation(state, {proposalEntity, createPSMCodes, joinCurrentRoute})

  return getWithProposal(state, {locationId: proposalEntity.doc._id, date: effectiveDate, includeProgramsHistory: returnProgramsHistory})
}

async function proposeCreate (state, {edits, effectiveDate, timestamp, supportCountry}) {
  const username = state.user.name
  const timestampedEdits = tools.getWithTimestamps(edits, username, timestamp)

  const validationErrors = tools.validateLocationCreate(timestampedEdits, effectiveDate)
  if (validationErrors) {
    return Promise.reject(new Error(validationErrors))
  }

  const proposedDoc = tools.entityCreateToDoc({edits, effectiveDate, supportCountry, username})
  return proposalApi.create(state, {proposedDoc, effectiveDate})
}

async function proposeUpdate (state, { edits, effectiveDate, timestamp }) {
  const username = state.user.name
  const currentDoc = await state.dal.location.getRawDoc(state, {locationId: edits._id})
  const timestampedEdits = tools.getWithTimestamps(edits, username, timestamp)

  const validationErrors = tools.validateLocationUpdate(currentDoc, timestampedEdits, effectiveDate)
  if (validationErrors) {
    return Promise.reject(new Error(validationErrors))
  }

  const proposedDoc = tools.entityEditToDoc({edits, currentDoc, effectiveDate, username})
  return proposalApi.create(state, {proposedDoc, currentDoc, effectiveDate})
}

exports.decideProposal = async function (state, {proposalId, status, createPSMCodes}) {
  const proposalEntity = await proposalApi.update(state, {proposalId, status})

  if (status === 'approved') {
    await maybeUpdateLocation(state, {proposalId, proposalEntity, createPSMCodes})
  }

  return getWithProposal(
    state, {locationId: proposalEntity.doc._id, date: proposalEntity.effectiveDate}
  )
}

async function maybeUpdateLocation (state, {proposalEntity, createPSMCodes, joinCurrentRoute}) {
  const getCurrentRoute = async locationDoc => {
    const currentRouteId = tools.getCurrentRouteId(locationDoc)
    if (!currentRouteId) {
      return
    }
    try {
      const route = await getRoute(state, currentRouteId)
      return tools.routeDocToCurrentRoute(route)
    } catch (e) {
      console.warn(`location ${locationDoc._id} assigned to unknown route ${currentRouteId}`)
    }
  }

  if (!proposalApi.tools.isApprover(state.user)) return null

  const {effectiveDate, doc, proposalType} = proposalEntity

  if (!createPSMCodes || proposalType !== 'new') {
    // This is used in shelflife, to store the most important info
    // in the funder doc inside the location docs. This allows retailers
    // to access the data of their current funder without the need of
    // syncing any funder docs*
    //
    // * syncing funder docs for retailers is a tricky problem, either they
    // sync only their currently assigned funder, which requires complex
    // logic in the syncing code or they sync all funder docs, adding a lot
    // of unneeded docs to their local dbs.
    //
    // Note that with this implementation the current route
    // will be updated every time the location is updated. That means that
    // if the funder doc has been edited (though it shouldn't!!! see
    // https://github.com/fielded/field-supply/issues/4794) or if the funder
    // has been deleted, `joinedDocs.currentRoute` will be updated accordingly.
    if (joinCurrentRoute) {
      const currentRoute = await getCurrentRoute(doc)
      if (currentRoute) {
        doc.joinedDocs = doc.joinedDocs || {}
        doc.joinedDocs.currentRoute = currentRoute
      } else if (get(doc.joinedDocs, 'currentRoute')) {
        // in case it is currently assigned to an unknown funder,
        delete doc.joinedDocs.currentRoute
      }
    }

    return state.dal.location.update(
      state, {doc, effectiveDate, funders: state.user.funders}
    )
  }

  // To create unique PSM "warehouse codes" for a new location,
  // we need a list of the existing codes in that state
  // Editing additionalData here instead of in createLocationDoc
  // to keep this hack all in one hook
  const existingCodes = await listWarehouseCodes(state, doc)
  // Intentionally not limiting to user programs to create program ids for all programs
  // so PSM team can enable or disable programs over time.
  const programs = await listPrograms(state)
  const additionalData = tools.addWarehouseCodes(doc, programs, existingCodes)

  return state.dal.location.update(
    state, {doc: Object.assign({}, doc, {additionalData}), effectiveDate, funders: state.user.funders}
  )
}
