/* TODO: refactor this monster */
import React, { createRef, Component } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { setMonth, startOfDay } from 'date-fns'

import { BackButton, DeemphasizedLink, Form, Page } from '@fielded/shared-ui'
import { validators } from '@fielded/shared-ui/src/components/Form'

import withConfig from '../../../van-shared/hoc/withConfig'

import {
  yearOptions,
  monthOptions,
  formatDate
} from '../../utils'

import capitalize from '../../../common/utils/capitalize'
import {
  constructBatchId,
  normaliseManufacturerValue,
  getManufacturerLabel
} from '../../../common/utils'

export const STEP_SELECT_BATCH = 'selectBatch'
export const STEP_ADD_EXISTING_BATCH = 'addExistingBatch'
export const STEP_ADD_NEW_BATCH = 'addNewBatch'
export const STEP_CONFIRM_NEW_BATCH = 'confirmNewBatch'
export const STEP_ALREADY_ADDED_BATCH = 'blockAlreadyAddedBatch'

const constructExpiryISOString = ({ month, year }) => {
  let expiry = new Date()
  expiry.setFullYear(year)
  expiry = setMonth(expiry, month - 1) // Javascript Months index starts from 0
  expiry.setDate(1)
  return expiry.toISOString()
}

export const enrichNewBatch = ({
  batch,
  product,
  manufacturer,
  manufacturers,
  expiryMonth,
  expiryYear
}) => {
  batch['id'] = constructBatchId({
    product,
    batchNumber: batch.batchNumber,
    manufacturer,
    manufacturers
  })
  batch['productId'] = product.id
  batch['manufacturer'] = normaliseManufacturerValue(manufacturer, manufacturers)
  batch['expiry'] = constructExpiryISOString({
    month: expiryMonth,
    year: expiryYear
  })

  return batch
}

export const checkForExistingBatch = ({
  batchId,
  batches,
  alreadyAddedBatches
}) => {
  if (!(batchId && batches)) return {}

  const existingBatchID = Object.keys(batches).find(key => {
    // The casing might be different since batchId was not always normalized
    return (key.toLowerCase() === batchId.toLowerCase())
      ? key
      : null
  })

  const existingBatch = existingBatchID
    ? batches[existingBatchID]
    : null

  const isAlreadyAdded = existingBatchID && alreadyAddedBatches
    ? alreadyAddedBatches.includes(existingBatchID)
    : null

  return {
    existingBatchID,
    existingBatch,
    isAlreadyAdded
  }
}

const allowedYearOptions = (allowExpired, latestExpiryDate) => {
  let startingYear = new Date().getFullYear()
  let addNYears
  if (allowExpired) {
    startingYear = startingYear - 1
  }
  if (latestExpiryDate) {
    addNYears = latestExpiryDate.year - startingYear
  }
  return yearOptions(startingYear, addNYears)
}

class AddBatchPage extends Component {
  static propTypes = {
    // title to be passed in, fallback title: 'Add [product.name] batch'
    pageTitle: PropTypes.string,
    breadcrumbItems: PropTypes.array,
    product: PropTypes.object,
    batches: PropTypes.object,
    alreadyAddedBatches: PropTypes.array,
    alreadyAddedLabel: PropTypes.string,
    manufacturers: PropTypes.array,
    /*
     * Set to true to allow entering expired batches
     * (defaults to false)
     */
    allowExpired: PropTypes.bool,
    /*
     * Limit the allowed expiry date.
     */
    latestExpiryDate: PropTypes.shape({
      year: PropTypes.number.isRequired,
      month: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]).isRequired
    }),
    /**
     * For story/testing purposes, you can set the starting step.
     * Make sure you pass the information the step needs.
     */
    defaultStep: PropTypes.oneOf([
      STEP_SELECT_BATCH,
      STEP_ADD_EXISTING_BATCH,
      STEP_ADD_NEW_BATCH,
      STEP_CONFIRM_NEW_BATCH,
      STEP_ALREADY_ADDED_BATCH
    ]),
    /**
     * For story/testing purposes, you can pass a batch.
     * Make sure you pass the information the current step needs.
     */
    batch: PropTypes.object,
    backUrl: PropTypes.string,
    history: PropTypes.object
  }

  static defaultProps = {
    pageTitle: undefined,
    breadcrumbItems: undefined,
    product: {},
    batches: {},
    alreadyAddedBatches: [],
    alreadyAddedLabel: undefined,
    manufacturers: [],
    defaultStep: STEP_SELECT_BATCH,
    batch: {},
    backUrl: undefined,
    history: undefined
  }

  state = {
    currentStep: this.props.defaultStep,
    batch: this.props.batch,
    batches: this.props.batches,
    // This is for holding non-batch values while a new batch is being confirmed
    tempBatchInfo: {}
  }

  form = createRef()

  handleSave = () => {
    const {
      onSubmit
    } = this.props

    const {
      batch,
      currentStep,
      tempBatchInfo
    } = this.state

    const {
      quantity
    } = this.form.current.getFields()

    if (currentStep === STEP_ADD_NEW_BATCH) {
      return this.confirmNewBatch()
    }

    // This is the actual, ultimate save, when all batch information has been collected
    onSubmit({
      batch: batch,
      quantity: quantity || tempBatchInfo.quantity
    })
  }

  handleBatchSelect = (batch) => {
    const {
      batches,
      alreadyAddedBatches,
      manufacturers,
      product
    } = this.props

    if (!batch) {
      // when batch number is cleared, clear whole form and reset view step
      const {
        tempBatchInfo
      } = this.state

      if (tempBatchInfo.batchNumber) {
        delete batches[tempBatchInfo.batchNumber]
      }

      this.form.current.reset()
      this.setState({
        currentStep: STEP_SELECT_BATCH,
        batch: {},
        tempBatchInfo: {},
        batches
      })

      return batch
    }

    // This handles a case for PSM where there is only a single manufacturer
    // so any new batch number needs to be checked as if it comes from that manufacturer.
    // We do this here to catch and prevent double batch registration.
    if (!(batch.id && batch.manufacturer) && manufacturers.length === 1) {
      const manufacturer = manufacturers[0].value
      batch['id'] = constructBatchId({
        product,
        batchNumber: batch.batchNumber,
        manufacturer,
        manufacturers
      })
      // We don't need to set the rest of the fields,
      // since the state swticher below will switch to the existing batch if one exists.
    }

    const {
      existingBatch,
      isAlreadyAdded
    } = checkForExistingBatch({
      batchId: batch.id,
      batches,
      alreadyAddedBatches
    })

    /**
     * We have received a batch.
     * There are three options here, each with their own view, which we set as a STEP:
     *
     * 1. this batch is already added to the shipment/report:
     *    User may not add it again. We display this information.
     *
     * 2. this is an existing batch in the system:
     *    We display the expiry details for now, later will offer an option to edit.
     *    Quantity is prefilled and editable.
     *
     * 3. This is a new batch, prefilled only with batchNumber:
     *    We offer fields to select manufacturer and expiry.
     *    Quantity is editable.
    */
    if (isAlreadyAdded) {
      this.setState({
        currentStep: STEP_ALREADY_ADDED_BATCH,
        tempBatchInfo: {}
      })
      return batch
    }

    if (existingBatch) {
      this.setState({
        currentStep: STEP_ADD_EXISTING_BATCH,
        batch: existingBatch,
        tempBatchInfo: {}
      })
    } else {
      this.setState({
        currentStep: STEP_ADD_NEW_BATCH,
        batch,
        // because the form is re-rendered on state change,
        // we need to update the batch options with this temp batch number
        batches: {
          ...this.props.batches,
          [batch.batchNumber]: batch
        },
        tempBatchInfo: {}
      })
    }

    return batch
  }

  selectExistingBatch = (batch) => {
    this.form.current.setFieldValue('batchNumber', batch.id)
    this.handleBatchSelect(batch)
  }

  confirmNewBatch = () => {
    const {
      product,
      manufacturers
    } = this.props

    const {
      batch
    } = this.state

    const {
      expiryMonth,
      expiryYear,
      manufacturer,
      quantity
    } = this.form.current.getFields()

    let defaultManufacturer

    // This handles a PSM case where there is only a single manufacturer option,
    // so user was not given an option to choose.
    // We just preset it here instead.
    if (!manufacturer && manufacturers.length === 1) {
      defaultManufacturer = manufacturers[0].value
    }

    const newBatch = enrichNewBatch({
      batch,
      product,
      manufacturer: manufacturer || defaultManufacturer,
      manufacturers,
      expiryMonth,
      expiryYear
    })

    this.setState({
      currentStep: STEP_CONFIRM_NEW_BATCH,
      batch: newBatch,
      tempBatchInfo: {
        batchNumber: newBatch.batchNumber,
        quantity,
        expiryMonth,
        expiryYear
      }
    })
  }

  cancelConfirmNewBatch = () => {
    this.setState({
      currentStep: STEP_ADD_NEW_BATCH
    })
  }

  render () {
    const {
      pageTitle,
      breadcrumbItems,
      product,
      alreadyAddedBatches,
      alreadyAddedLabel,
      manufacturers,
      backUrl,
      history,
      allowExpired,
      latestExpiryDate,
      config
    } = this.props

    const {
      currentStep,
      batch,
      batches,
      tempBatchInfo
    } = this.state

    const productName = get(product, 'definition.name', product.name)
    const vialSize = get(product, 'definition.presentation')

    const fallbackPageTitle = `Add ${productName} batch`

    const isAwaitingBatchNumber = currentStep === STEP_SELECT_BATCH
    const isAddingExistingBatch = currentStep === STEP_ADD_EXISTING_BATCH
    const isAddingNewBatch = currentStep === STEP_ADD_NEW_BATCH
    const isAwaitingConfirmation = currentStep === STEP_CONFIRM_NEW_BATCH
    const isAlreadyAdded = currentStep === STEP_ALREADY_ADDED_BATCH
    const canProceed = !isAwaitingBatchNumber && !isAlreadyAdded

    // PSM has Chemonics for all batches until they can provide a manufacturer list, but
    // they don't want the users to see it https://github.com/fielded/van-orga/issues/3147.
    const hideManufacturer = manufacturers.length === 1

    const years = allowedYearOptions(allowExpired, latestExpiryDate)

    const batchOptions = batches
    if (latestExpiryDate) {
      const endDate = new Date(Date.UTC(latestExpiryDate.year, latestExpiryDate.month, 1))
      Object.keys(batchOptions).forEach((batch) => {
        if (startOfDay(batchOptions[batch].expiry) > startOfDay(endDate)) {
          delete batchOptions[batch]
        }
      })
    }
    return (
      <Form
        ref={this.form}
        onSubmit={this.handleSave}
      >
        {isAwaitingConfirmation ? (
          <Page situation='ready' title={config.title}>
            <Page.Header
              backButton={(!breadcrumbItems && history) && (
                <BackButton history={history} backUrl={backUrl} />
              )}
            />
            <Page.Intro title='Confirm new batch' centered />
            <Page.Panel
              withBackground
              withMargin
              narrow
            >
              <Page.Panel.Section>
                You are adding a batch that is not currently in the system, please confirm the details:
              </Page.Panel.Section>
              <Page.Panel.Section>
                <Form.Row>
                  <div>
                    <Form.InputLabel>
                      Batch number
                    </Form.InputLabel>
                    <Form.DisplayValue asNumber>
                      {batch.batchNumber}
                    </Form.DisplayValue>
                  </div>
                </Form.Row>
                {!hideManufacturer && <Form.Row>
                  <div>
                    <Form.InputLabel>
                      Manufacturer
                    </Form.InputLabel>
                    <Form.DisplayValue>
                      {getManufacturerLabel(batch.manufacturer, manufacturers)}
                    </Form.DisplayValue>
                  </div>
                </Form.Row>}
                <Form.Row>
                  <div>
                    <Form.InputLabel>
                      Expiry date
                    </Form.InputLabel>
                    <Form.DisplayValue>
                      {formatDate(batch.expiry, 'expiry')}
                    </Form.DisplayValue>
                  </div>
                </Form.Row>
              </Page.Panel.Section>
              <Page.Panel.Actions title='Is this correct?'>
                <Form.Button
                  colorVariant='brand'
                  fill='full'
                >
                  Yes, confirm
                </Form.Button>
                <Form.Button
                  type='button'
                  colorVariant='brand'
                  fill='outline'
                  onClick={this.cancelConfirmNewBatch}
                >
                  No, go back
                </Form.Button>
              </Page.Panel.Actions>
            </Page.Panel>
          </Page>
        ) : (
          <Page>
            <Page.Header
              title={pageTitle || fallbackPageTitle}
              breadcrumbItems={breadcrumbItems}
              // back button is fallback if no breadcrumb items are passed
              backButton={(!breadcrumbItems && history) && (
                <BackButton history={history} backUrl={backUrl} />
              )}
            />
            <Page.Panel
              withMargin
              spread
              narrow
            >
              <Form.Row>
                <Form.Field
                  labelText='Enter/Select batch number'
                  fieldName='batchNumber'
                >
                  <Form.Field.BatchSelect
                    defaultValue={tempBatchInfo.batchNumber}
                    batches={batchOptions}
                    manufacturers={manufacturers}
                    hiddenManufacturers={hideManufacturer ? manufacturers : []}
                    alreadyAddedBatches={alreadyAddedBatches}
                    alreadyAddedLabel={alreadyAddedLabel}
                    onValueChange={this.handleBatchSelect}
                    hasError={currentStep === STEP_ALREADY_ADDED_BATCH} // TODO: figure out better way to pass error
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>
              {currentStep === STEP_ALREADY_ADDED_BATCH && (
                <div>
                  {capitalize(alreadyAddedLabel)}. You may not add it again.
                </div>
              )}

              {isAddingExistingBatch && (
                <Form.Row>
                  <div>
                    <Form.InputLabel>
                      Expiry date
                    </Form.InputLabel>
                    <span className='vs-add-batch__display-date'>
                      {formatDate(batch.expiry, 'expiry')}
                    </span>
                  </div>
                </Form.Row>
              )}

              {isAddingNewBatch && (
                <Form.Section
                  title={'This batch does not exist in the system.'}
                  intro={<span>Add '<span className='vs-u-font-bold-number'>{batch.batchNumber}</span>' as a new batch</span>}
                >
                  {/* On PSM we don't currently have different manufacturers, so we preset everything to a standard manufacturer.
                      User should not see this field when there is only one option. */}
                  {!hideManufacturer && (
                    <Form.Row>
                      <Form.Field
                        labelText='Select manufacturer'
                        fieldName='manufacturer'
                      >
                        <Form.Field.Select
                          options={manufacturers}
                          defaultValue={batch.manufacturer}
                          required
                          validate={(value) => {
                            // construct id and check if user is trying to add existing batch
                            // from this manufacturer
                            const potentialId = constructBatchId({
                              product,
                              batchNumber: batch.batchNumber,
                              manufacturer: value,
                              manufacturers
                            })

                            const {
                              existingBatch
                            } = checkForExistingBatch({
                              batchId: potentialId,
                              batches: this.props.batches,
                              alreadyAddedBatches
                            })

                            if (existingBatch) {
                              return <span>
                                '{batch.batchNumber}' from {capitalize(value)} already exists. <DeemphasizedLink
                                  size='inherit'
                                  onClick={() => this.selectExistingBatch(existingBatch)}
                                >
                                  Select existing batch
                                </DeemphasizedLink>
                              </span>
                            }
                          }}
                        />
                        <Form.Field.ValidationErrors />
                      </Form.Field>
                    </Form.Row>
                  )}
                  <Form.Row legend='Expiry date'>
                    <div className='vs-add-batch__date-fields-wrapper'>
                      <div className='vs-add-batch__date-field'>
                        <Form.Field
                          fieldName='expiryMonth'
                          labelText='Month'
                        >
                          <Form.Field.Select
                            searchable
                            clearable={false}
                            options={monthOptions()}
                            defaultValue={tempBatchInfo.expiryMonth}
                            placeholder='MM'
                            required='Please select batch expiry month'
                            validate={value => {
                              const enteredYear = this.form.current.getFieldValue('expiryYear')
                              if (enteredYear === undefined) {
                                return null
                              }

                              const enteredMonth = parseInt(value, 10)

                              // first check if the expiry date is later than allowed
                              // (expiry date is limited to 6 months in PSM expiry feature)
                              if (latestExpiryDate) {
                                const {year, month} = latestExpiryDate
                                if (enteredYear > year || (enteredYear === year && (enteredMonth > month + 1))) {
                                  return `A expiry date after ${formatDate(new Date(year, month), 'expiry')} is not allowed. Please check the expiry date again.`
                                }
                              }

                              if (allowExpired) {
                                return null
                              }

                              // then check if the expiry date is in the past
                              const now = new Date()
                              const thisYear = now.getFullYear()
                              const isCurrentYearEntered = enteredYear === thisYear
                              const thisMonth = now.getMonth() + 1
                              const isInThePast = isCurrentYearEntered && (enteredMonth < thisMonth)
                              if (isInThePast) {
                                return 'This date has passed. Please check the expiry date again.'
                              }

                              return null
                            }}
                          />
                          <Form.Field.ValidationErrors />
                        </Form.Field>
                      </div>
                      <div className='vs-add-batch__date-field'>
                        <Form.Field
                          fieldName='expiryYear'
                          labelText='Year'
                        >
                          <Form.Field.Select
                            searchable
                            clearable={false}
                            options={years}
                            defaultValue={tempBatchInfo.expiryYear}
                            placeholder='YYYY'
                            required='Please select batch expiry year'
                          />
                          <Form.Field.ValidationErrors />
                        </Form.Field>
                      </div>
                    </div>
                  </Form.Row>
                </Form.Section>
              )}
              {(isAddingExistingBatch || isAddingNewBatch) && (
                <Form.Row>
                  <Form.Field
                    labelText='Quantity(LMIS Reporting Units)'
                    fieldName='quantity'
                  >
                    <Form.Field.Quantity
                      defaultValue={tempBatchInfo.quantity}
                      required
                      validate={(value) => {
                        let vialSizeValidation
                        if (vialSize) {
                          vialSizeValidation = validators.multipleOf({
                            value,
                            checkValue: vialSize,
                            message: 'The quantity must be a multiple of the full vial size of {allowed} doses.'
                          })
                        }

                        return validators.minimum({
                          value,
                          checkValue: 1,
                          message: 'Please enter the quantity of this batch'
                        }) || vialSizeValidation
                      }}
                    />
                    <Form.Field.ValidationErrors />
                  </Form.Field>
                </Form.Row>
              )}
            </Page.Panel>
            <Page.Footer layout='right'>
              <Form.Button
                colorVariant='brand'
                fill='full'
                size='large'
                disabled={!canProceed}
              >
                {isAddingExistingBatch ? 'Save and close' : 'Proceed'}
              </Form.Button>
              {history && (
                <BackButton
                  alignOpposite
                  history={history}
                  backUrl={backUrl}
                  deemphasized
                >
                  Cancel and discard
                </BackButton>
              )}
            </Page.Footer>
          </Page>
        )}
      </Form>
    )
  }
}

export default withConfig(AddBatchPage)
