const getProgramPeriod = (reportingPeriod) => {
  let period

  switch (reportingPeriod) {
    case 'bimonthly':
      period = {
        periodType: {
          unit: 'month',
          quantity: 2
        },
        entryPeriodRule: 'next-period',
        dueDateOffset: {
          unit: 'day',
          quantity: 15
        },
        entryCutOffOffset: {
          unit: 'month',
          quantity: 1
        }
      }
      break

    case 'quarterly':
      period = {
        periodType: {
          unit: 'month',
          quantity: 3
        },
        entryPeriodRule: 'next-period',
        dueDateOffset: {
          unit: 'day',
          quantity: 15
        },
        entryCutOffOffset: {
          unit: 'month',
          quantity: 1
        }
      }
      break

    default:
      throw new Error('Wrong reporting period entered')
  }

  return [period]
}

module.exports = getProgramPeriod
