const tools = require('./tools')
const api = require('./api')

module.exports = {
  list: api.listPrograms,
  listByIds: api.listProgramsByIds,
  get: api.getProgram,
  save: api.createProgram,
  update: api.updateProgram,
  addService: api.addService,
  tools
}
