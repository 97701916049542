const tools = require('../../tools')
const { getPrograms, getLocations, getProducts } = require('../master-data')
const { listGroups } = require('../read/list-groups')
const { create: createAllocations } = require('../../allocations/api')

exports.create = create
async function create (
  state,
  {
    programId,
    parentLocationId,
    dryRun = false,
    date = new Date().toJSON(),
    reportingPeriod,
    orderType = 'main',
    supplementaryProducts = []
  }
) {
  tools.validateParams('create', arguments[1])

  parentLocationId = parentLocationId || state.user.location.id

  const status = tools.getUserStatus(state.user)
  const { groups } = await listGroups(state, {programId, date})

  const locations = await getLocations(state, programId, parentLocationId, date)
  const allProducts = await getProducts(state, programId, { onSDPServices: true })
  let orders
  if (orderType === 'supplementary') {
    const acceptedOrders = groups.filter(group => group.statusDisplayName === 'accepted')
    orders = acceptedOrders ? acceptedOrders.flatMap(order => order.orders) : []
  } else {
    orders = groups.flatMap(group => group.orders)
  }
  const products = orderType === 'supplementary' ? supplementaryProducts : allProducts
  const allocations = await createAllocations(state,
    {locations, products, orders, programId, date, reportingPeriod, orderType}
  )

  const snapshots = tools.createNewOrders({
    programId,
    allocations,
    orders,
    user: state.user,
    orderType
  })

  if (dryRun) {
    return tools.docsToOrderEntities({docs: snapshots, status, withSnapshots: true})
  }

  const createdSnapshots = await state.dal.order.bulkDocs(state, snapshots)
  return tools.docsToOrderEntities({docs: createdSnapshots, status})
}

exports.getCreateOptions = getCreateOptions
async function getCreateOptions (state) {
  const programs = await getPrograms(state)
  return {programs}
}
