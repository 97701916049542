import React from 'react'

import { QuantityInput } from '@fielded/shared-ui'

import Qty from '../common/qty'

const ProductItemQuantity = ({
  product,
  onQuantityChange,
  hasAvailableQuantity,
  availableQuantity,
  hasWarning,
  noticeText
}) =>
  <label className='new-shipment-form__row planning-quantity-product'>
    <h4 className='planning-quantity-product__title'>{product.name || product.code}</h4>
    <div className='planning-quantity-product__field'>
      <span>Quantity to send:</span>
      {hasAvailableQuantity && <span className={hasWarning ? 'color--danger' : ''}>
        (Available: <Qty quantity={availableQuantity} />)
      </span>}
    </div>
    <QuantityInput
      allowNegative={false}
      value={product.quantity || ''}
      onQuantityChange={quantity => onQuantityChange(product._id, quantity)}
      className={hasWarning ? 'color--danger' : ''}
    />
    { hasWarning && <p className='color--danger'>You entered more than is available</p> }
  </label>

export default ProductItemQuantity
