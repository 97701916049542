const sortBy = require('lodash/sortBy')

const dedupeTimeCollection = (collection, key) => {
  collection = collection
    .filter(x => x.date && (!!x[key] || x[key] === 0))

  collection = sortBy(collection, ['date'])

  return collection.reduce((a, c) =>
    a.length > 0 && a[a.length - 1][key] === c[key]
      ? a
      : a.concat([c])
    , [])
}

module.exports = dedupeTimeCollection
