import RestAdapter from '@fielded/fs-api/lib/common/rest-adapter'
import CurretUserApiAdapter from '@fielded/fs-api/lib/current-user'

class ForgotPasswordApi {
  constructor (props) {
    const restAdapter = new RestAdapter(global.window.fetch, '', props.agaveApiUrl)
    this.currentUserAdapter = new CurretUserApiAdapter(restAdapter)
  }

  submit (data) {
    return this.currentUserAdapter.resetPassword(data)
  }
}

export default ForgotPasswordApi
