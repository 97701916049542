export const findReport = async (api, options) => {
  return api.report.draft.prepare(options)
}

export const getReport = async (api, options) => {
  const { reportId } = options

  // First check if we have a draft
  const draft = await api.report.draft.get({ reportId })
  if (draft) {
    return draft
  }

  // No draft. Do we have a report instead?
  const report = await api.report.get({ reportId })
  if (report) {
    return report
  }

  throw new Error('No report found with id: ' + reportId)
}
