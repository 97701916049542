const get = require('lodash/get')

class SafaricomAdapter {
  constructor (logger, safaricomCredentials, isomorphicFetch) {
    this.logger = logger
    Object.assign(this, safaricomCredentials)
    this.request = isomorphicFetch
  }

  async sendSms (to, message) {
    to = Array.isArray(to) ? to : [to]

    const data = {
      SenderId: this.senderId,
      ApiKey: this.apiKey,
      ClientId: this.clientId,
      MessageParameters: to.map(val => {
        return {
          Number: val,
          Text: message
        }
      })
    }

    const response = await this.request(`${this.apiUrl}sms/SendBulkSMS`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AccessKey: this.accessKey
      },
      body: JSON.stringify(data)
    })
    const responseData = await response.json()
    const success = responseData.ErrorCode === 0

    return {
      providerResponse: responseData,
      providerSmsId: get(responseData, 'Data[0].MessageId'),
      success,
      provider: 'SC'
    }
  }
}

module.exports = SafaricomAdapter
