import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Helmet from 'react-helmet'

import PageContext from './PageContext'
import PageHeader from './PageHeader'
import PageHeaderNew from './PageHeaderNew'
import PageComposableHeader from './PageComposableHeader'
import PageIntro from './PageIntro'
import PageFooter from './PageFooter'
import Panel from './Panel'
import PageFilterTable from './PageFilterTable'
import Confetti from './Confetti'

import PrintTemplateSL from './PrintTemplates/PrintTemplateSL'

let pageConfig = {
  linkRenderer: ({ to, className, children, ...otherProps }) => <a href={to} className={className} {...otherProps}>{children}</a>
}

export function configure (config) {
  pageConfig = {
    ...pageConfig,
    ...config
  }
}

export function toast (detail) {
  // TODO: use redux store when usage pattern has been agreed upon.
  // Note: CustomEvent is not supported in older IE (Edge is ok)
  // https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
  const toastEvent = new CustomEvent('add-global-toast', { // eslint-disable-line
    detail
  })
  window.dispatchEvent(toastEvent)
}

export function triggerPrint (newTitle = null) {
  if (process.env.NODE_ENV !== 'development') {
    setTimeout(() => {
      if (newTitle) {
        document.title = newTitle
      }
      window.print()
    }, 2000)
  }
}

const Page = ({
  title,
  children,
  situation,
  printTemplate,
  className,
  contentClassName,
  verticallyCentered,
  location,
  withFixedFooter
}) => {
  const hasFixedFooter = withFixedFooter || React.Children.toArray(children).find(child =>
    child.type === PageFooter && child.props.fixed)

  pageConfig = {
    ...pageConfig,
    situation
  }

  const PrintTemplate = printTemplate === 'SL'
    ? PrintTemplateSL
    : null

  return (
    <PageContext.Provider
      value={pageConfig}
    >
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <div className={classnames(
        'vs-page',
        {'vs-page--has-fixed-footer': hasFixedFooter},
        {'vs-page--situation-ready': situation === 'ready'},
        {'vs-page--situation-success': situation === 'success'},
        {'vs-page--situation-caution': situation === 'caution'},
        {'vs-page--situation-danger': situation === 'danger'},
        className
      )}>
        {situation === 'success' && (
          <div class='vs-page__confetti'>
            <Confetti />
          </div>
        )}
        <div
          className={classnames(
            'vs-page__content',
            {'vs-page__content--centered': verticallyCentered},
            contentClassName
          )}
        >
          {PrintTemplate
            ? <PrintTemplate location={location}>{children}</PrintTemplate>
            : children
          }
        </div>
      </div>
    </PageContext.Provider>
  )
}

Page.propTypes = {
  title: PropTypes.string,

  /**
   * The child elements of the Page
   */
  children: PropTypes.node.isRequired,
  /**
   * For action pages, such as confirm. Signals to the user what the situation is with the action.
   */
  situation: PropTypes.oneOf(['ready', 'success', 'caution', 'danger', 'neutral', undefined]),
  /**
   * Wraps the page content in a print template with repeating header and footer.
   */
  printTemplate: PropTypes.oneOf(['SL', undefined]),
  /**
   * Any classname(s) to apply to the outer Page wrapper
   */
  className: PropTypes.string,

  /**
   * Any classname(s) to apply to the Page content wrapper
   */
  contentClassName: PropTypes.string,
  /**
   * If you want the items inside the page to be vertically centered, then use this prop
   */
  verticallyCentered: PropTypes.bool
}

Page.defaultProps = {
  title: 'Field Supply',
  situation: undefined,
  className: undefined,
  contentClassName: undefined
}

Page.Header = PageHeader
Page.HeaderNew = PageHeaderNew
Page.ComposableHeader = PageComposableHeader
Page.Intro = PageIntro
Page.Footer = PageFooter
Page.Panel = Panel
Page.FilterTable = PageFilterTable

export default Page
