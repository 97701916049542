const get = require('lodash/get')
const set = require('lodash/set')
const {batchIdToProductId} = require('./tools/product-batch-util')
const { get: getService } = require('../service/api/read/get')
const { get: getLocation } = require('../location')
const saveChanges = require('./shipment-save-changes')
const getLedgerBalance = require('../stock-get-ledger-balance')
const PLANNING_TYPES = require('./tools/planning-types')

async function updateCRQuantities (state, shipment) {
  if (shipment.planningType !== PLANNING_TYPES.C_R) {
    throw new Error('Only collect-redistribute shipments (shelflife) can be used with this')
  }

  if (shipment.status !== 'new') {
    return shipment
  }

  const locationId = shipment.origin.id
  const location = await getLocation(state, locationId)

  const serviceId = get(location, 'programs[0].services[0].id')
  const service = await getService(state, serviceId)

  const ledger = await getLedgerBalance(state, {
    location: { id: locationId },
    service,
    excludeProducts: 'configuration'
  })

  const changes = {}
  let changed = false
  // Calculate SL balances based on ledger balance
  // Filter products where quantities have not changed in the shipment..
  Object.keys(shipment.counts).forEach(batchId => {
    // If a driver has checked the product, let's not update it
    const alreadyChanged = get(shipment, `counts.${batchId}.checked`, false)
    // Don't change an amount that's already been updated before
    if (alreadyChanged) {
      return
    }

    const productId = batchIdToProductId(batchId)
    const currentQuantity = get(shipment, `counts.${batchId}.quantity`)
    const ledgerQuantity = get(ledger, `${productId}.availableTotal`, 0)
    const ledgerPartnerBalance = get(ledger, `${productId}.field:partner-balance`, 0)

    let newQuantity = Math.min(currentQuantity, (ledgerQuantity - ledgerPartnerBalance))
    // if negative quantity in ledger balance, set shipment to 0
    if (newQuantity <= 0) {
      newQuantity = 0
    }

    // Only change the amount when shiment says collect more than the ledger says is available
    // this is to avoid if the shipment has intentionally been set to something lower than SL balance
    // for example to bring the location down to MAX level
    if (newQuantity !== currentQuantity) {
      changed = true
      set(changes, `${batchId}.quantity`, newQuantity)
    }
  })

  if (!changed) {
    return shipment
  }

  return saveChanges(state, shipment.snapshotId, changes)
}

module.exports = updateCRQuantities
