import React, { Fragment } from 'react'
import PriceDisplay from '../PriceDisplay/PriceDisplay'

const PricePromotion = ({
  value,
  ...otherProps
}) => {
  const actualPrice = Number(value)
  const showOriginalPrice = typeof (value) === 'object' && value.originalPrice !== undefined

  return (
    <Fragment>
      {showOriginalPrice && (
        <PriceDisplay
          value={value.originalPrice}
          invalid
          {...otherProps}
        />
      )}
      <PriceDisplay
        value={actualPrice}
        bold
        {...otherProps}
      />
    </Fragment>
  )
}

export default PricePromotion
