const get = require('lodash/get')

const { findById: findShipmentById } = require('./shipment-find')
const { get: getLocation } = require('../location/api/read/get')
const { getByIds: getProductsByIds } = require('../product/api/read/get-by-ids')
const getPrice = require('../product/tools/get-price')
const PLANNING_TYPES = require('./tools/planning-types')
const RECEIVED_DATE_KEY = 'snapshotDates.received'

module.exports = async (state, shipmentId, canViewSalesStats) => {
  const shipment = await findShipmentById(state, shipmentId)
  const location = await getLocation(state, shipment.origin.id) || {}
  const isCR = shipment.planningType === PLANNING_TYPES.C_R

  const createdAt = shipment.createdAt
  const returnedAt = get(shipment, RECEIVED_DATE_KEY)
  const productIds = new Set()
  const rows = []
  const retainedRows = []
  const shouldHaveRetainedRows = canViewSalesStats && isCR && shipment.collection

  for (const [batchId, { quantity }] of Object.entries(shipment.counts)) {
    const code = batchId.split(':', 2)[1]
    const productId = `product:${code}`
    productIds.add(productId)

    // If user can view sales stats, we push products that
    // had bigger initial "marked for collection" qty than
    // qty collected in counts.
    // We then only show items in the main table if any
    // amount was collected at all. If user cannot view
    // sales stats and it is not C&R, we keep things as usual
    if (shouldHaveRetainedRows) {
      const originalQty = get(shipment, `collection.${batchId}.quantity`)
      if (!!originalQty && originalQty > quantity) {
        retainedRows.push({
          productId,
          code,
          quantity: originalQty - quantity
        })
      }

      if (quantity !== 0) {
        rows.push({
          productId,
          code,
          quantity
        })
      }
    } else {
      rows.push({
        productId,
        code,
        quantity
      })
    }
  }

  let totalValue = 0
  const products = await getProductsByIds(state, [...productIds])
  for (const row of rows) {
    const product = products.find(p => p._id === row.productId)
    const price = getPrice(product.prices, createdAt)
    row.name = product.name
    row.price = price
    row.total = row.quantity * row.price
    totalValue += row.total
  }

  let returnNoteData = {
    country: get(location, 'location.country', shipment.origin.country),
    state: get(location, 'location.state', shipment.origin.state),
    name: get(location, 'fullName', shipment.origin.sdp),
    address: get(location, 'additionalData.physicalAddress', ''),
    createdAt,
    returnedAt,
    rows,
    totalValue,
    signedBy: get(shipment, 'comments[0].name'),
    signature: get(shipment, 'comments[0].signature'),
    isCR
  }

  if (shouldHaveRetainedRows) {
    let totalRetainedValue = 0
    for (const row of retainedRows) {
      const product = products.find(p => p._id === row.productId)
      const price = getPrice(product.prices, createdAt)
      row.name = product.name
      row.price = price
      if (row.price) {
        row.total = row.quantity * row.price
        totalRetainedValue += row.total
      }
    }
    returnNoteData = {
      ...returnNoteData,
      retainedRows,
      totalRetainedValue
    }
  }

  return returnNoteData
}
