const tools = require('../../tools')
const { getGroup } = require('../read/get')
const { listOrdersForUser } = require('../read/internal')

exports.updateOrdersOnProduct = async function (
  state,
  {locationQuantities, groupId, programId, productId}
) {
  tools.validateParams('updateOrdersOnProduct', arguments[1])
  const withSnapshots = true
  // groupId means a requesting user editing their order
  // without it, it means a warehouse user updating orders
  // on any group, a case where we don't know which original order
  // is being fulfiled if there are two destination ids.
  // issue here: https://github.com/fielded/van-orga/issues/2695
  const ordersResponse = groupId
    ? await getGroup(state, {groupId, withSnapshots})
    : await listOrdersForUser(
      state, programId, state.user.location.id, {withSnapshots, withAccepted: !!groupId}
    )
  const orders = ordersResponse.orders.filter(order => order.products[productId])
  const status = tools.getUserStatus(state.user)
  const updatedSnapshots = tools.updateOrdersOnProduct({
    locationQuantities,
    orders,
    status,
    productId,
    user: state.user
  })
  await state.dal.order.bulkDocs(state, updatedSnapshots)
  const updatedOrders = tools.ordersFromSnapshotUpdates({
    updatedSnapshots, status, orders, locationId: state.user.location.id
  })
  return {orders: updatedOrders}
}
