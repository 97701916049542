const shortid = require('shortid')
const keyBy = require('lodash/keyBy')

exports.filterOutMissing = filterOutMissing
function filterOutMissing (rows, locations, products) {
  const locationIds = new Set(locations.map(l => l._id))

  const transformedRows = rows
    .map(row => {
      return { ...row, productId: `product:${row.productId}` }
    })

  const missingLocation = transformedRows
    .filter(row => !locationIds.has(row.supplierId) || !locationIds.has(row.destinationId))

  if (missingLocation.length) {
    throwError(
      'Found Source or Pharmacy that does not exist',
      missingLocation
    )
  }

  const productIds = new Set(products.map(p => p._id))

  const missingProduct = transformedRows
    .filter(row => !productIds.has(row.productId))

  if (missingProduct.length) {
    throwError(`Received product ids that do not exist`, missingProduct)
  }

  const filteredRows = transformedRows
    .filter(row => locationIds.has(row.supplierId))
    .filter(row => locationIds.has(row.destinationId))
    .filter(row => productIds.has(row.productId))

  return { rows: filteredRows }
}

exports.parseCSV = parseCSV
function parseCSV (data) {
  const rows = data.split('\n')
  const removeQuotes = field => field.replace(/"/g, '').trim()
  const headers = rows.splice(0, 1)[0].split(',').map(removeQuotes)
  return rows
    .map(row => {
      const rowFields = row.split(',').map(removeQuotes)
      return headers
        .reduce((acc, header, index) => {
          acc[header] = rowFields[index]
          return acc
        }, {})
    })
    // Filter out rows that have no values for anything
    .filter(row => Object.keys(row).some(header => row[header]))
}

exports.throwOnInvalidRow = throwOnInvalidRow
function throwOnInvalidRow (rows, expectedHeaders) {
  const badRows = rows.filter(row => expectedHeaders.find(header => !row[header]))
  if (badRows.length) {
    return throwError(
      `Error found in import file. Expected columns were not found on at least one row.
    expected headers: ${expectedHeaders.join()}`,
      badRows
    )
  }
  const invalidQuantityRows = rows.filter(row => isNaN(row.quantity) || (!isNaN(row.quantity) && row.quantity < 0))
  if (invalidQuantityRows.length) {
    return throwError(
      'Quantity should be positive number',
      invalidQuantityRows
    )
  }
  return null
}

exports.throwError = throwError
function throwError (message, data) {
  const error = new Error()
  error.data = data
  error.message = message
  throw error
}

exports.getAllLocationsFromSheet = getAllLocationsFromSheet
function getAllLocationsFromSheet (rowsbyDestinationId, locations) {
  const locationsById = keyBy(locations, '_id')

  const allocations = Object.values(rowsbyDestinationId).reduce((acc, row) => {
    const orderId = shortid.generate()

    const allocationsPerPackPoint = row.reduce((acc, supplyRow) => {
      const { destinationId, supplierId, quantity, productId } = supplyRow
      const suborderId = shortid.generate()
      const location = locationsById[destinationId]
      const supplier = supplierId
      const destinationSupplierPair = `${destinationId}-${supplier}`

      acc[destinationSupplierPair] = acc[destinationSupplierPair] || { orderId, suborderId, location, supplier, products: {} }
      acc[destinationSupplierPair].products[productId] = { original: Number(quantity), allocationType: 'allocation_missing' }
      return acc
    }, {})

    acc.push(...Object.values(allocationsPerPackPoint))
    return acc
  }, [])

  return allocations
}
