/** This module contains functions to calculate demand forecasts and plans.
 *
 * The allocation have forecast configuration variables. From
 * those variables we can calculate the forecasted demand and demand plan.
 * The `addCalculations` function will add these numbers to the allocation
 * config.
 */

const {methods} = require('../../config')

const addCalculations = (allocation) => {
  if (allocation.products) {
    addCalculationsToProducts(allocation.products)
  }
  if (allocation.aggregatedProducts) {
    addCalculationsToProducts(allocation.aggregatedProducts)
  }
}

const addCalculationsToProducts = (products) => {
  Object.keys(products).map(id => {
    const product = products[id]
    const forecast = product.forecast

    if (forecast) {
      Object.assign(forecast, calculateForecast(forecast, products))
      product.demandPlan = calculateDemandPlan(forecast)
    }
  })
}

const calculateForecast = (forecast, products) => {
  switch (forecast.method) {
    case methods.TP:
      return {
        total: forecast.targetPopulation *
          forecast.wastageFactor *
          forecast.coverageFactor *
          forecast.dosesInSchedule *
          forecast.adjustmentFactor
      }

    case methods.BUNDLED:
      const dependentTotals = forecast.dependentProducts.map(id => {
        if (products[id]) {
          return calculateForecast(products[id].forecast, products).total
        }

        // This is used when normalizing forecasts,
        // child locations might have products that higher locations dont?
        return 0
      })
      const summed = dependentTotals.reduce((s, d) => s + d, 0)
      const total = summed * forecast.factor
      return {summed, total}
    case methods.DIRECT_ORDER:
      return {total: forecast.directOrder}

    case methods.CONSUMPTION: {
      return {
        total: forecast.annualAllocation,
        perWarehouse: forecast.perWarehouse
      }
    }

    default:
      console.error('Cannot calculate forecast value for method', forecast.method)
      return {total: 0}
  }
}

const calculateDemandPlan = forecast => {
  const yearDays = 365
  return ({
    forecast: forecast.total,
    perWarehouse: forecast.perWarehouse,
    days: yearDays,
    total: forecast.total / yearDays
  })
}

module.exports = {
  addCalculations,
  addCalculationsToProducts,
  calculateForecast,
  calculateDemandPlan
}
