class StockRestAdapter {
  constructor (restAdapter) {
    this.restAdapter = restAdapter
  }

  createStockSummary (locationId, yearweek, data) {
    return this.restAdapter.create(`stock_summary/?location=${encodeURIComponent(locationId)}&yearweek=${encodeURIComponent(yearweek)}`, data)
  }

  createStockSummaryEvents (locationId, yearweek, data) {
    return this.restAdapter.create(`stock_summary_event/?location=${encodeURIComponent(locationId)}&yearweek=${encodeURIComponent(yearweek)}`, data)
  }

  async listStockSummary ({ locationId, startDate, endDate, limit = 5000 }) {
    let url = `stock_summary/?limit=${limit}`
    if (startDate) {
      startDate = new Date(startDate).toJSON()
      url += `&startdate=${startDate}`
    }
    if (endDate) {
      endDate = new Date(endDate).toJSON()
      url += `&endDate=${endDate}`
    }
    if (locationId) {
      const translatedLocationId = locationId.replace(/^country:/, '')
      url += `&location=${translatedLocationId}`
    }

    let results = []
    while (true) {
      const resp = await this.restAdapter.get(url)
      results = results.concat(resp.results)
      url = resp.next
      if (!url) {
        break
      }
    }
    return results
  }
}

module.exports = StockRestAdapter
