import React, { Component } from 'react'
import { Loading } from '@fielded/shared-ui'
import Profile from '../components/Profile'
import { withAuth } from '../../../common/AuthenticationProvider'
import { documentInfo, driverDocumentInfo, hasUserContract } from '../../settings/common/utils'
import withConfig from '../../../van-shared/hoc/withConfig'
import { withApi } from '../../../common/ApiProvider'
import { userIsAuthorised } from '../../../van-shared/utils/auth'

class ProfileContainer extends Component {
  state = {
    loading: true,
    isRetailer: false,
    loaded: false,
    success: null,
    error: null
  }

  async componentDidMount () {
    const { api, user } = this.props
    const locationId = user.location.id
    const isRetailer = userIsAuthorised(user, 'feature:userRole:pharmacyUser')
    // Note: this is what disables the contracts for the NG drivers:
    const driverWithContracts = hasUserContract(user)
    let locationDisplayName

    try {
      const location = await api.location.get(locationId)
      locationDisplayName = location.fullName
    } catch (error) {
      locationDisplayName = user.location.id
    }

    let pendingContracts = 0

    if (isRetailer) {
      // pending contracts need for shelf life for showing bubble in contracts title
      const { pending } = await documentInfo(api, locationId)
      pendingContracts = pending
    } else if (driverWithContracts) {
      // pending contracts need for shelf life for showing bubble in contracts title
      const { pending } = await driverDocumentInfo(api, user._id)
      pendingContracts = pending
    }

    this.setState({
      loading: false,
      pendingContracts,
      locationDisplayName,
      isRetailer,
      driverWithContracts
    })
  }

  saveData = async (fields) => {
    const { api } = this.props
    this.setState({ saving: true })

    try {
      const userData = {
        fullName: fields.displayName,
        email: fields.email
      }
      await api.currentUser.update(userData)
      this.props.updateUser()
      this.setState({ success: 'Profile successfully updated', error: false, saving: false })
    } catch (e) {
      this.setState({ error: `Can't update profile`, success: false, saving: false })
    }
  }

  render () {
    const {
      loading,
      locationDisplayName,
      isRetailer,
      success,
      error,
      saving
    } = this.state
    const { user, config, history } = this.props

    if (loading) {
      return <Loading />
    }

    return <Profile
      locationDisplayName={locationDisplayName}
      isRetailer={isRetailer}
      user={user}
      success={success}
      error={error}
      saving={saving}
      saveData={this.saveData}
      config={config}
      history={history}
    />
  }
}

export default withAuth('user', 'updateUser')(withConfig(withApi(ProfileContainer)))
