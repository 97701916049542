import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Collapse, Expand } from '../../icons'
import Button from '../Button'

class ExpandCollapseButton extends Component {
  static propTypes = {
    /**
     * A change callback
     */
    onToggle: PropTypes.func,

    /**
     * Initialise the expanded state
     */
    expanded: PropTypes.bool,

    /**
     * A label to show when collapsed, to expand
     */
    expandLabel: PropTypes.string,

    /**
     * A label to show when expanded, to collapse
     */
    collapseLabel: PropTypes.string
  }

  static defaultProps = {
    onToggle: () => {},
    expanded: false,
    expandLabel: 'Expand',
    collapseLabel: 'Collapse'
  }

  state = {
    expanded: !!this.props.expanded
  }

  componentDidUpdate (nextProps, prevState) {
    const { onToggle } = this.props
    const currentState = this.state

    if (currentState.expanded !== prevState.expanded) onToggle({expanded: currentState.expanded})
  }

  toggle = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  render () {
    const {
      expanded
    } = this.state

    const {
      expandLabel,
      collapseLabel
    } = this.props

    let Icon = expanded ? <Collapse /> : <Expand />

    return (
      <Button
        className={classnames(
          'vs-expand-collapse-button',
          {'vs-expand-collapse-button--collapsed': !expanded},
          {'vs-expand-collapse-button--expanded': expanded}
        )}
        colorVariant='brand'
        fill='outline'
        onClick={this.toggle}
        icon={Icon}
      >
        {expanded ? collapseLabel : expandLabel}
      </Button>
    )
  }
}

export default ExpandCollapseButton
