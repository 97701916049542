import get from 'lodash/get'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

import { toTitleCase } from '../../../van-shared/utils/utils'

const filterTransform = {
  route: 'routeId',
  destination: 'destination.id',
  source: 'origin.id',
  deliveryDate: 'date',
  market: 'destination.state'
}

export const fullPacked = (shipments) => {
  return shipments.filter(shipment => {
    const productCount = Object.keys(shipment.counts).length
    const checked = (Object.values(shipment.counts) || []).filter(product => product.checked)
    const percentage = (checked.length * 100) / productCount
    return percentage === 100
  })
}

export const routesFilter = (shipments) => {
  const routes = shipments
    .filter(shipment => shipment.routeId)
    .map((shipment) => shipment.routeId)
  return uniq(routes)
}

export const marketsFilter = (shipments) => {
  const markets = shipments
    .filter(shipment => shipment.destination.state)
    .map((shipment) => shipment.destination.state)
  return uniq(markets)
}

export const sourcesFilter = (shipments) => {
  const sources = shipments
    .filter(shipment => shipment.origin.id)
    .map((shipment) => {
      const title = toTitleCase(shipment.origin.id.split(':').pop())
      return {
        id: shipment.origin.id,
        title: title
      }
    })
  return uniqBy(sources, 'id')
}

export const destinationsFilter = (shipments) => {
  const destinations = shipments
    .filter(shipment => shipment.destination.id)
    .map((shipment) => {
      const title = toTitleCase(shipment.destination.id.split(':').pop())
      return {
        id: shipment.destination.id,
        title: title
      }
    })
  return uniqBy(destinations, 'id')
}

// Todo remove this fallback once we have the migrations setup for shipments and orders
const filterTransformFallBack = {
  route: 'funderId',
  destination: 'destination.id',
  source: 'origin.id',
  deliveryDate: 'date',
  market: 'destination.state'
}

export const filterShipments = (shipments, filters) => {
  if (!Object.values(filters).length) return shipments
  const filterKeys = Object.keys(filters).filter(prop => prop !== 'pack' && filters[prop])
  const filtered = shipments.filter((shipment) => {
    const statement = filterKeys.reduce((acc, val) => {
      if (filters[val]) {
        let value = get(shipment, filterTransform[val])
        if (!value) value = get(shipment, filterTransformFallBack[val])
        if (value === filters[val]) {
          acc.push(value)
        }
      }
      return acc
    }, [])
    return statement.length === filterKeys.length
  })
  if (filters.pack && filters.pack === 'full') {
    return fullPacked(filtered)
  }
  return filtered
}
