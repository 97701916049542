const {URLSearchParams} = require('whatwg-url')

// Expects the endpoint of a url (not the full URL)
// also adds a trailing slash before the params
// as django rest framework expects it -- it 301s without,
// which will give a CORS error to the frontend.
const withQueryString = (endpoint, queryParams = {}) => {
  if (!Object.keys(queryParams).length) {
    return endpoint
  }

  endpoint = endpoint.endsWith('/') ? endpoint : `${endpoint}/`

  // Support JS objects params
  const queryParamsStrings = Object.keys(queryParams).reduce((acc, key) => {
    const val = typeof queryParams[key] === 'object'
      ? JSON.stringify(queryParams[key])
      : queryParams[key]
    acc[key] = val
    return acc
  }, {})

  const searchParams = new URLSearchParams(queryParamsStrings)
  return `${endpoint}?${searchParams.toString()}`
}

class RestAdapter {
  // endpoint 'table', e.g. 'location'
  constructor (fetch, djangoAuthToken, restApiUrl) {
    if (!fetch || !restApiUrl) {
      throw new Error('RestAdapter requires fetch, restApiUrl params')
    }

    this.fetcher = getFetch(fetch, djangoAuthToken, restApiUrl)
    this.fetchBlob = getFetch(fetch, djangoAuthToken, restApiUrl, 'blob')
  }

  extend (adapter, PATH) {
    ['get', 'create', 'update', 'listAll', 'list'].forEach(method => {
      if (adapter[method]) {
        return
      }

      adapter[method] = (...args) => this[method](PATH, ...args)
    })
  }

  async getBlob (endpoint, queryParams) {
    if (!queryParams) {
      return this.fetchBlob(endpoint)
    }
    return this.fetchBlob(withQueryString(endpoint, queryParams))
  }

  async get (endpoint, queryParams) {
    if (!queryParams) {
      return this.fetcher(endpoint)
    }
    return this.fetcher(withQueryString(endpoint, queryParams))
  }

  // Django Rest framework uses post for create
  async create (endpoint, row) {
    // check window in case of script running in backend
    const body = (typeof window !== 'undefined' && row instanceof window.FormData) ? row : JSON.stringify(row)
    const payload = {method: 'post', body}
    return this.fetcher(endpoint, payload)
  }

  async delete (endpoint, id) {
    const url = endpoint + (endpoint.endsWith('/') ? '' : '/') + id + '/'
    return this.fetcher(url, {method: 'delete'})
  }

  // Django Rest framework uses put for update
  // https://stackoverflow.com/questions/28413389/django-rest-framework-put-returns-404-instead-of-creating-an-object
  // put in Django Rest framework can only be used for update, not create (our puts may not be idempotent because of the auto update timestamp in base model btw)
  async update (endpoint, row) {
    // check window in case of script running in backend
    const body = (typeof window !== 'undefined' && row instanceof window.FormData) ? row : JSON.stringify(row)
    const payload = {method: 'put', body}
    return this.fetcher(endpoint, payload)
  }

  // Django Rest framework uses patch for patch :), i.e. you can send only the fields you want to update
  // and others remain the same
  async patch (endpoint, row) {
    const payload = {method: 'patch', body: JSON.stringify(row)}
    return this.fetcher(endpoint, payload)
  }

  // Sometimes we just need to post <3
  async post (endpoint, data) {
    const payload = {method: 'post', body: JSON.stringify(data)}
    return this.fetcher(endpoint, payload)
  }

  // Used to make a single 'list'call, when we wanna control params & pagination,
  // somewhere else in the app
  async list (endpoint, params = { limit: 500 }) {
    if (typeof params === 'string') {
      return this.fetcher(params)
    }

    let next = withQueryString(endpoint, params)
    return this.fetcher(next)
  }

  // Expects to be calling a django rest framework list endpoint.
  async listAll (endpoint, params = {limit: 500}) {
    let allResults = []
    // On the first fetch, build the endpoint with any GET params as ?search=params
    let next = withQueryString(endpoint, params)
    while (next) {
      const response = await this.fetcher(next)
      allResults = allResults.concat(response.results)
      // On each subsequent request, Django Rest Framework updates the URL params with an offset,
      // so no need to use withQueryString params again.
      next = response.next
    }

    // Make the django rest framework list results shape once we have no more pages.
    return {
      results: allResults,
      count: allResults.length,
      next: null,
      previous: null
    }
  }
}

/* This returns a fetch function that:
  1. has the stuff we need for django requests: base url & auth headers
  2. promise rejects HTTP "not okay" errors (window.fetch does not reject the way normal HTTP wrapper libraries do.)
*/
function getFetch (fetch, apiToken, baseUrl, nonJSONResponseType) {
  baseUrl = baseUrl.endsWith('/')
    ? baseUrl
    : `${baseUrl}/`

  const headers = {
    'Content-Type': 'application/json'
  }

  if (apiToken) {
    headers['Authorization'] = `Token ${apiToken}`
  }

  async function fetcher (endpoint, params) {
    const config = {
      credentials: 'include',
      headers
    }
    Object.assign(config, params)

    // if request body is form data we need remove content-type to allow send multipart form data
    if (params && params.body && (typeof window !== 'undefined' && params.body instanceof window.FormData)) {
      delete config.headers['Content-Type']
    }

    // so we can pass in an endpoint that's relative ('location')
    // or the full url to support the 'next' param from Django rest framework's pagination response
    let url = endpoint.startsWith('http') ? endpoint : `${baseUrl}${endpoint}`

    // ensure trailing slash for drf trailing slash fetish
    if (!url.endsWith('/') && !url.includes('?')) {
      url = `${url}/`
    }
    const response = await fetch(url, config)
    // no need parse anything cuz it's success and body is empty. otherwise it's entering exception state
    if (response.status === 204) {
      return null
    }
    let body
    try {
      if (nonJSONResponseType) {
        body = await response[nonJSONResponseType]()
      } else {
        body = await response.json()
      }
    } catch (e) {
      console.warn('error caught in fetch parsing body', e)
    }
    if (!response.ok) {
      const messageObject = body || {}
      const error = new Error(messageObject.error || messageObject.message)
      Object.assign(error, {status: response.status, statusText: response.statusText}, body)
      throw error
    }
    return body
  }

  return fetcher
}

module.exports = RestAdapter
// Used on tests (see for example integration/forecast/forecast-test)
module.exports.withQueryString = withQueryString
