const PGAdapter = require('../../common/pg-adapter')

const TABLE_NAME = 'avocado.data_productsimilarproducts'
const COLUMNS = [
  'product_presentation_id',
  'product_class'
]

class ProductSimilarProductsPGAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    const opts = {
      strictColumns: false,
      addUuid: false
    }
    super(pgConnection, TABLE_NAME, username, COLUMNS, 'id', {}, logger, opts)
  }

  /* This query is calling the get_sku_substitutes_from_fsid postgres function
     that was added manually to the database and is not define here in the codebase
     so the the deploy process won't create it in the database.
     This way every time we test this method we need to make sure that the function
     is created.
  */

  async getSimilarProductGroups ({ locationFsid }) {
    const { rows } = await this.pgConnection.query(
      `
      SELECT sku1 product_id, array_agg(sku2) similar_products_list
      FROM public.get_sku_substitutes_from_fsid($1)
      GROUP BY sku1
    `,
      [locationFsid]
    )
    return rows
  }
}

module.exports = { ProductSimilarProductsPGAdapter }
