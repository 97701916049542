export const byTrend = products => {
  const above = []
  const below = []
  const matching = []

  Object.keys(products).forEach(productId => {
    const { total, picked } = products[productId]
    if (picked === total) {
      matching.push(productId)
    } else if (picked < total) {
      below.push(productId)
    } else {
      above.push(productId)
    }
  })

  below.sort()
  above.sort()
  matching.sort()

  return below.concat(above, matching)
}
