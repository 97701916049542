const get = require('lodash/get')
const { parse } = require('../../tools/smart-id')
const psmStatePrefixesMap = require('./psm-state-prefixes')

const PSM_PREFIX = 'PSM'

/**
 * @param locationDoc
 *   for {location: {id}} to get geolocation id, e.g`country:ng:zone:north-west:state:kebbi`
 *   and {additionalData} to clone and decorate `programs` with PSM `siteId`s
 * @param programs full master data programs list
 * @param existingCodes array of exiting warehouse codes (for that state)

 * @return additionalData decorated with warehouseCode and
 * program.{programName}.siteId`s for every program. These are just `programName:warehouseCode`
 */
exports.addWarehouseCodes = addWarehouseCodes
function addWarehouseCodes (
  {location: {id: geoLocationId}, additionalData = {}},
  programs,
  existingCodes = []
) {
  const warehouseCode = additionalData.warehouseCode ||
    createUniqueCode(geoLocationId, existingCodes)
  const programsWithCodes = createProgramCodes(additionalData, programs, warehouseCode)
  return Object.assign({}, additionalData, {
    warehouseCode,
    programs: programsWithCodes
  })
}

// Try 100000 times to generate an id for geoLocationId that's unique to existingCodes[]
// if not, throw, something's wrong. (exported for tests). See ./README
exports.createUniqueCode = createUniqueCode
function createUniqueCode (geoLocationId, existingCodes) {
  const existingCodesSet = new Set(existingCodes)

  let warehouseCode

  for (let i = 0; i < 100000; i++) {
    const newCode = createPSMWarehouseCode(geoLocationId, i)
    if (!existingCodesSet.has(newCode)) {
      warehouseCode = newCode
      break
    }
  }

  if (!warehouseCode) {
    throw new Error(`Error: could not generate unique warehouse code ${warehouseCode}`)
  }

  return warehouseCode
}

// Does not overwrite exisitng code if it exists
function createProgramCodes (additionalData, masterDataPrograms, warehouseCode) {
  return masterDataPrograms
    .reduce((acc, program) => {
      const shortProgramId = program.id.replace('program:', '')
      acc[shortProgramId] = get(additionalData, `programs.${shortProgramId}`, {})
      acc[shortProgramId].siteId = acc[shortProgramId].siteId || `${shortProgramId}/${warehouseCode}`
      return acc
    }, {})
}

/**
 * @param geoLocationId string like `country:ng:zone:north-west:state:kebbi`
 *
 * @return a state PSM warehouse code if the geo is at state/lga/sdp level, e.g. PSM/NSW/rand-hash
 * or a generic code if the geo is at country/zone level, e.g. PSM/rand-hash
 */
function createPSMWarehouseCode (geoLocationId, index) {
  if (!geoLocationId) {
    throw new Error(`Error: expected geoLocationId but received ${geoLocationId}`)
  }

  const prefix = getPSMCodePrefix(geoLocationId)
  const suffix = getPSMSuffix(index)
  return `${prefix}${suffix}`
}

// This is exported for api's listWarehouseCodes (for a couch view prefix)
exports.getPSMCodePrefix = getPSMCodePrefix
function getPSMCodePrefix (geoLocationId) {
  const {zone, state} = parse(geoLocationId)
  const stateId = (zone && state) ? `zone:${zone}:state:${state}` : null
  const statePrefix = psmStatePrefixesMap[stateId] || `NONSTATE`
  return `${PSM_PREFIX}/${statePrefix}/`
}

// PSM just wants digits that match the string `00/000`, not anything actually that random.
function getPSMSuffix (index) {
  const numberWithZeros = padWithZeros(index)
  return numberWithZeros.substring(0, 2) + '/' + numberWithZeros.substring(2, 5)
}

// always return 5 digits with zeros for left padding
function padWithZeros (num) {
  const s = '0000' + num
  return s.substring(s.length - 5, s.length)
}
