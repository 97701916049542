import React from 'react'

import alertCommentPropType from '../../alert-comment-proptype'

const AlertCalloutThreadComment = ({comment}) => {
  const date = new Date(comment.createdAt).toLocaleString()
  return (
    <span className='fs-alert-comment'>
      <span className='fs-alert-comment__meta'>
        <span className='fs-alert-comment__author'>
          {comment.createdBy}
        </span>
        &nbsp;commented on&nbsp;
        <span className='fs-alert-comment__created'>
          {date}
        </span>
      </span>
      <span className='fs-alert-comment__comment'>
        {comment.message}
      </span>
    </span>
  )
}

AlertCalloutThreadComment.propTypes = {
  /**
   *  {
   *    createdAt: PropTypes.date,
   *    createdBy: PropTypes.string.isRequired,
   *    message: PropTypes.string.isRequired
   *  }
   */
  comment: alertCommentPropType.isRequired
}

AlertCalloutThreadComment.displayName = 'AlertCallout.Thread.Comment'

export default AlertCalloutThreadComment
