const isArray = require('lodash/isArray')
const mergeWith = require('lodash/mergeWith')

/**
 * Merges multiple filter objects into one, combining their properties and arrays using a customizer function.
 * @param {Object} filterObjects - An object containing filter objects to merge.
 * @returns {Object} - The merged filter object.
*/
exports.mergeFilterObjects = function (filter1, filter2) {
  function customizer (objValue, srcValue) {
    if (srcValue && typeof objValue === 'undefined') {
      return srcValue
    } else if (objValue && typeof srcValue === 'undefined') {
      return objValue
    } else if (
      (isArray(objValue) && srcValue) ||
      (isArray(srcValue) && objValue)
    ) {
      return objValue.concat(srcValue)
    } else {
      return [srcValue, objValue]
    }
  }

  return mergeWith(filter1, filter2, customizer)
}

/*
* Filters an array of locations based on a program ID. Gets locations assigned to given program
*
* @param {Array} locations - The array of locations to filter.
* @param {string} programId - The ID of the program to filter by.
* @returns {Array} - Returns the filtered array of locations.
*/
exports.filterLocationsByProgram = function (locations, programId) {
  return locations.filter(location => locationHasProgram(location, programId))
}

function locationHasProgram (location, programId) {
  return location.programs
    .some((program) => (program.id === programId && program.services && program.services.length))
}
