import React, { createContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import PSMDeliveryAuthentication from './PSMDeliveryAuthentication'
import { PERMISSION_STATES } from '../../../../../../../utils/permissions-service'
import { isWithinDeliveryRadius } from '../../utils'

export const AuthenticationContext = createContext()

const NUMBER_OF_DIGITS = 4
const DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right'
}

// helper auth details
const AUTH_TYPES = {
  location: {
    title: 'Location confirmation',
    type: 'location',
    checkboxText: 'Unable to verify your location?',
    buttonText: 'Verify my location',
    alternateButtonText: 'Proceed'
  },
  otp: {
    title: 'OTP confirmation',
    type: 'otp',
    checkboxText: 'Unable to verify OTP?',
    buttonText: 'Submit',
    alternateButtonText: 'Submit'
  }
}

const DEFAULT_OTP_INPUTS = new Array(NUMBER_OF_DIGITS).fill('')

const PSMDeliveryAuthenticationContainer = ({
  api,
  otpKey,
  skipGPSValidation,
  isBottomDrawerOpen,
  history,
  shipmentId,
  permission,
  geoPosition,
  position,
  distance,
  facilityName,
  locationObject,
  positionErrorMsg,
  onClose
}) => {
  const [locationAuthType, otpAuthType] = Object.keys(AUTH_TYPES)

  const [otp, setOtp] = useState(DEFAULT_OTP_INPUTS)
  const [authType, setAuthType] = useState(skipGPSValidation ? otpAuthType : locationAuthType)
  const [direction, setDirection] = useState(DIRECTIONS.LEFT)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [withAlternateText, setWithAlternateText] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [authOptions, setAuthOptions] = useState({
    location: {
      isAuthenticated: false,
      isUnverified: false,
      hasError: false,
      comment: ''
    },
    otp: {
      isAuthenticated: false,
      isUnverified: false,
      hasError: false,
      comment: ''
    }
  })

  const { snapshotId } = useParams()
  const isWithinLocationRadius = isWithinDeliveryRadius(distance)

  const authTypeInfo = AUTH_TYPES[authType]
  const { location: locationOption, otp: otpOption } = authOptions

  // VIEWS
  const isLocationAuthType = authType === locationAuthType
  const isOTPAuthType = authType === otpAuthType

  const isDeniedPermission = permission === PERMISSION_STATES.DENIED || positionErrorMsg

  const onSetTimeout = (direction) => {
    setTimeout(() => {
      setDirection(direction)
    }, 600)
  }

  const onSetAuthOptions = (prop, value) => {
    setAuthOptions(prevOptions => {
      return {
        ...prevOptions,
        [authType]: {
          ...prevOptions[authType],
          [prop]: value
        }
      }
    })
  }

  // Toggle view
  const onToggleView = () => {
    setIsSaving(true)
    onSaveFailedValidationComment()
  }

  // Save GPS/OTP validation failed comment
  const onSaveFailedValidationComment = async () => {
    try {
      if (isLocationAuthType && locationOption.comment) {
        await api.shipment.addGpsValidationFailComment(snapshotId, locationOption.comment, position)

        setAuthType(otpAuthType)
        onSetTimeout(DIRECTIONS.RIGHT)
        setIsSaving(false)
        onCloseModal(true)
        return
      }

      if (isOTPAuthType && otpOption.comment) {
        await api.shipment.otp.couch.createFailSnapshot(snapshotId, otpOption.comment)

        setIsSaving(false)
        history.push(`/shipments/pick-list/${shipmentId}`)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  // Validate location
  const onAuthenticateLocation = async () => {
    setIsVerifying(true)

    try {
      const response = await api.shipment.gpsValidation(shipmentId, {
        lat: position.lat,
        long: position.lng
      })

      if (response && response.comment) {
        onSetAuthOptions('isAuthenticated', true)
        setAuthType(otpAuthType)
        onSetTimeout(DIRECTIONS.RIGHT)
      }
    } catch (error) {
      console.warn(error)
      onSetAuthOptions('hasError', true)
    } finally {
      setIsVerifying(false)
    }
  }

  // Validate OTP
  const onAuthenticateOTP = async () => {
    setIsVerifying(true)

    const otpPassword = otp.join('')
    const otpConfig = api.shipment.otp.getConfig(otpKey.version)

    if (otpPassword.length !== otpConfig.length) {
      onSetAuthOptions('hasError', true)
      setIsVerifying(false)
      return
    }

    try {
      // verify OTP
      const verified = await api.shipment.otp.verify(
        window.crypto, otpPassword, otpKey
      )

      if (!verified) {
        onSetAuthOptions('hasError', true)
        return
      }

      // Store password in shipment snapshot
      await api.shipment.otp.couch.createPasswordSnapshot(snapshotId, otpPassword)
      onSetAuthOptions('isAuthenticated', verified)

      history.push(`/shipments/pick-list/${shipmentId}`)
    } catch (error) {
      console.warn(error)
      onSetAuthOptions('hasError', true)
    } finally {
      setIsVerifying(false)
    }
  }

  const onDeniedPermission = () => {
    const comment = 'Location permission was denied'
    onSetAuthOptions('comment', comment)
  }

  const onAuthenticate = (type) => {
    if (isLocationAuthType) {
      if (locationOption.isUnverified || !isWithinLocationRadius) {
        if (isDeniedPermission) {
          onDeniedPermission()
          return onToggleView()
        }
        return onOpenModal()
      }
      onAuthenticateLocation()
    }

    if (isOTPAuthType) {
      if (otpOption.isUnverified) {
        return onOpenModal()
      }
      onAuthenticateOTP()
    }
  }

  const onOpenModal = () => {
    setIsModalOpen(true)
  }

  const onCloseModal = (preserveComment = false) => {
    // we need the `preserveComment` arg
    // to keep the comment when we toggle the view
    if (isLocationAuthType && !preserveComment) {
      onSetAuthOptions('comment', '')
    }

    if (isOTPAuthType) {
      onSetAuthOptions('comment', '')
    }
    setIsModalOpen(false)
  }

  const onHandleOTPChange = (value, index) => {
    if (otpOption.hasError) {
      onSetAuthOptions('hasError', false)
    }

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)
  }

  const onHandleCheckboxToggle = (event) => {
    const isChecked = event.target.checked
    onSetAuthOptions('isUnverified', isChecked)

    if (isChecked) {
      if (isLocationAuthType) {
        setWithAlternateText(true)
      }

      if (isOTPAuthType) {
        setOtp(DEFAULT_OTP_INPUTS)
      }
    } else {
      setWithAlternateText(false)
    }
  }

  const onHandleCommentChange = (event) => {
    const comment = event
    onSetAuthOptions('comment', comment)
  }

  useEffect(() => {
    if ((isLocationAuthType && isDeniedPermission)) {
      onSetAuthOptions('hasError', true)
    }
  }, [isLocationAuthType, isDeniedPermission])

  const values = {
    isBottomDrawerOpen,
    history,
    permission,
    position,
    locationObject,
    positionErrorMsg,
    otp,
    authType,
    authTypeInfo,
    isLocationAuthType,
    isOTPAuthType,
    authOptions,
    direction,
    distance,
    facilityName,
    withAlternateText,
    isModalOpen,
    isDeniedPermission,
    isVerifying,
    isSaving,
    isWithinLocationRadius,
    onClose,
    onCloseModal,
    onToggleView,
    onHandleOTPChange,
    onHandleCheckboxToggle,
    onHandleCommentChange,
    onAuthenticate
  }

  return (
    <AuthenticationContext.Provider value={values}>
      <PSMDeliveryAuthentication />
    </AuthenticationContext.Provider>
  )
}

export default PSMDeliveryAuthenticationContainer
