import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { Banner, DeemphasizedLink } from '@fielded/shared-ui'

import { keyboardMovement, keyboardActionMovement } from './../keyboard-nav'
import { VAN_SERVICE } from '../../common/program'
import ReportField from '../report-field/ReportField'
import RemarkReportField from '../report-field/RemarkReportField'
import AlertReportFieldContainer from '../report-field/AlertReportFieldContainer'
import Commits from '../Commits'
import { batchNumberFromId, isShelflifePowered } from '../../common/utils'
import { formatDate } from '../../../../van-shared/utils'

class StockCountFormMultiField extends Component {
  state = {
    tabIndex: 0,
    remarkModalVisible: false
  }

  componentDidUpdate (prevProps) {
    if (prevProps.productId !== this.props.productId) {
      // make the first element in the StockCountFormMultiField active when any
      this.setState({ tabIndex: 0 })
    }
  }

  handleClick = index => {
    this.setState({ tabIndex: index })
  }

  openRemarkModal = () => {
    this.setState({remarkModalVisible: true})
  }

  closeRemarkModal = () => {
    this.setState({remarkModalVisible: false})
  }

  render () {
    const {
      alerts,
      config,
      fields,
      fieldsById,
      orderedFields,
      commits,
      expiry,
      onProductUpdate,
      serviceId,
      productId,
      batchSize,
      url,
      editOpeningBalance,
      disableForm,
      dislayOpeningQuantityIssue
    } = this.props

    const { tabIndex, remarkModalVisible } = this.state

    let maxTabIndex = orderedFields.filter(id => {
      const { fieldType, disabled } = fieldsById[id]

      return fieldType !== 'calculated' && !disabled
    }).length
    if (commits) {
      maxTabIndex += Object.keys(commits).length
    }
    if (expiry) {
      maxTabIndex += Object.keys(expiry).length
    }

    const handleKeyDown = event => {
      const movement = keyboardMovement(event)
      if (movement != null) {
        const navigate = this.state.tabIndex + movement
        if (navigate >= 0 && navigate < maxTabIndex) {
          this.setState({
            tabIndex: this.state.tabIndex + movement
          })
        } else {
          // handle prev or next
          keyboardActionMovement(movement)
        }
      }
    }

    let fieldTabIndex = 0

    // check if out of contract buying
    const openingCount = get(fields, 'field:standard-opening-balance.amount', 0)
    const physicalCount = get(fields, 'field:standard-physical-count.amount', 0)
    const hasIssue = physicalCount > openingCount && dislayOpeningQuantityIssue

    return (
      <div
        className='report-form'
        onKeyDown={e => {
          handleKeyDown(e)
        }}
      >
        {orderedFields.map((fieldId) => {
          const index = fieldTabIndex
          const fieldDef = fieldsById[fieldId]
          const fieldKey = productId + fieldId
          const amount = get(fields, `${fieldId}.amount`, '')
          const fieldIsDisabled = get(fields, `${fieldId}.disabled`, false)

          const {
            name,
            fieldType,
            sublabel,
            type,
            validationRules
          } = fieldDef

          let disabled = fieldDef.disabled

          if (disableForm) {
            disabled = true
          } else if (fieldId === 'field:standard-opening-balance') {
            // PSM disables opening balance, unless there was no value entered in last report.
            // If the amount reported in last period was 0, the field will still be disabled.
            disabled = !editOpeningBalance
          }

          let alert
          if (alerts) {
            if (serviceId === VAN_SERVICE) {
              /* for van we dont compare with the fieldId as
                 unbatched product eg("product:ad-syg") that have
                 single field called field-standard-count.
              */
              alert = alerts.find(a => a.target.productId === fieldId)
            } else {
              alert = alerts.find(a => a.target.fieldId === fieldId)
            }
          }

          // This goes mostly for calculated fields
          // Fields need to specify they should be hidden
          // see https://github.com/fielded/van-orga/issues/1429#issuecomment-395432291
          if (fieldDef.display === false) {
            return null
          }

          if (fieldDef.display === false) {
            return null
          }

          // Only increment field tab index for entry fields
          let tabable = false
          if (fieldDef.fieldType === 'entry') {
            tabable = true
            fieldTabIndex += 1
          }

          /**
            Determine field presentation based on field or input type.
            `type` is the input type, `number` or `text`, and is used to determine
            field presentation for all standard entry fields.
            Non-entry fields, i.e. `calculated` or `display`, have presentations
            specific to their fieldType, so we pass that instead.
           */
          const reportFieldType = fieldType === 'entry'
            ? type
            : fieldType

          const commonProps = {
            id: fieldKey,
            label: name,
            subLabel: sublabel,
            type: reportFieldType,
            fields: fields,
            value: amount,
            fieldIsDisabled,
            validationRules: validationRules,
            onProductUpdate: onProductUpdate,
            handleClick: () => this.handleClick(index)
          }

          const isShelflife = isShelflifePowered(config)

          if (alert) {
            return (
              <AlertReportFieldContainer
                {...commonProps}
                alert={alert}
                key={alert.id}
                autoFocus={tabable && (tabIndex === index)}
              />
            )
          }

          if (isShelflife && fieldId === 'field:standard-remark') {
            const reportFieldProps = {
              ...commonProps,
              isShelflife: true,
              disabled: disabled,
              autoFocus: true,
              hasIssue: hasIssue
            }
            return (
              <RemarkReportField
                key={fieldKey}
                isFilled={!!amount}
                onOpenModal={() => this.openRemarkModal()}
                onCloseModal={() => this.closeRemarkModal()}
                modalVisible={remarkModalVisible}
                reportFieldProps={reportFieldProps}
              />
            )
          }

          return (
            <ReportField
              {...commonProps}
              isShelflife={isShelflife}
              disabled={disabled}
              key={fieldKey}
              autoFocus={tabable && (tabIndex === index)}
              hasIssue={hasIssue}
            />
          )
        })}
        {commits && (
          <Commits
            productId={productId}
            commits={commits}
            currentTabIndex={tabIndex}
            startTabIndex={orderedFields.length}
            onProductUpdate={onProductUpdate}
            setTabIndex={this.handleClick}
            total={physicalCount}
          />)
        }
        {expiry && (
          <Fragment>
            <div className='main-column__header'>
              <h3 className='main-column__title'>
                Batches expired or due to expire
              </h3>
            </div>
            <div className='report-form report-form--batch'>
              <div className='report-form__main-fields'>
                {Object.keys(expiry.batches || {}).map((batchId) => {
                  const index = fieldTabIndex
                  const amount = get(expiry.batches || {}, `${batchId}.fields.field:standard-physical-count.amount`)
                  const batchNumber = batchNumberFromId(batchId)
                  const masterData = this.props.batchesById[batchId]
                  const masterExpiry = masterData ? masterData.expiry : undefined
                  const batchExpiry = masterExpiry ? `Expires ${formatDate(masterExpiry, 'expiry')}` : undefined
                  const fieldId = `expiry:${batchId}`
                  // PSM has Chemonics for all batches until they can provide a manufacturer list, but
                  // they don't want the users to see it https://github.com/fielded/van-orga/issues/3147.
                  // const batchManufacturer = masterData ? masterData.manufacturerName : undefined
                  // const batchInfo = [batchManufacturer, batchExpiry].filter(Boolean).join(' | ')
                  const subLabel = (
                    <Fragment>
                      {batchExpiry && `${batchExpiry} | `}
                      <DeemphasizedLink
                        size='small'
                        colorVariant='brand'
                        onClick={() => onProductUpdate(fieldId, null, null)}>
                          Remove batch
                      </DeemphasizedLink>
                    </Fragment>
                  )

                  fieldTabIndex += 1
                  const fieldProps = {
                    id: fieldId,
                    label: batchNumber,
                    subLabel: subLabel,
                    key: fieldId,
                    value: amount,
                    onProductUpdate: onProductUpdate,
                    autoFocus: tabIndex === index,
                    handleClick: () => this.handleClick(index),
                    type: 'batch',
                    batchSize
                  }

                  return <ReportField {...fieldProps} /> // eslint-disable-line react/jsx-key
                })}
                <div className='report-form__psm-add-batch'>
                  {!disableForm && <DeemphasizedLink
                    component={Link}
                    colorVariant='brand'
                    to={`${url}/add-batch/expiry`}
                    fill='none'
                  >
                    Add batch expired or likely to expire in the next 6 months
                  </DeemphasizedLink>}
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {hasIssue &&
          <Banner type='attention' inline className='report-form__banner'>
            <div className='report-form__banner-text'>
              Quantity on shelf is higher than the opening balance, please check carefully  as client will be billed due to Out of Contract buying.
            </div>
          </Banner>
        }
      </div>
    )
  }
}

StockCountFormMultiField.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object),
  expiry: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]), // submits the (optional) expiry form #3041
  fields: PropTypes.object.isRequired,
  commits: PropTypes.object,
  fieldsById: PropTypes.object.isRequired,
  orderedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onProductUpdate: PropTypes.func.isRequired,
  serviceId: PropTypes.string.isRequired,
  batchesById: PropTypes.object,
  // do we show the warning when you have more closing than opening balance
  dislayOpeningQuantityIssue: PropTypes.bool
}

export default StockCountFormMultiField
