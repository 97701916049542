const EntityApi = require('../common/entity-api')
const PGAdapter = require('../common/pg-adapter')
const TransactionsNotificationsAdapter = require('./transactions-notifications/adapter')

function clean (params) {
  Object.keys(params).forEach(key => {
    if (typeof params[key] === 'undefined') {
      delete params[key]
    }
  })

  return params
}

function translateToPG (params) {
  const newParams = {
    user_id: params.userId || params.user_id,
    location_fsid: params.locationId || params.location_fsid,
    read_at: params.read_at || params.readAt,
    notification_text: params.notification_text || params.notificationText,
    action: params.action,
    fcm_data: params.notificationData,
    trigger_event: params.trigger_event || params.event,
    notification_code: params.notification_code || params.code,
    notification_medium: params.notification_medium || params.medium
  }

  return clean(newParams)
}

function translateToFE (params) {
  const newParams = {
    id: params.id,
    userId: params.userId || params.user_id,
    locationId: params.locationId || params.location_fsid,
    createdAt: params.createdAt || params.created_at,
    readAt: params.read_at,
    notificationText: params.notification_text,
    action: params.action,
    notificationData: params.fcm_data,
    event: params.trigger_event,
    code: params.notification_code,
    medium: params.notfication_medium
  }

  return newParams
}

function adaptFilter (filter) {
  if (!filter.userId && !filter.user_id) {
    throw new Error('You can only get notifications with a userId provided')
  }

  if (filter.userId) {
    filter.user_id = filter.userId
    delete filter.userId
  }

  return filter
}

class NotificationsApi extends EntityApi {
  constructor (state, pgConnection, agaveAdapter, locationApi, notificationsFcmApi, logger) {
    const username = state.user.name
    const columns = [
      'id', 'user_id', 'location_fsid', 'created_at', 'notification_text', 'action', 'fcm_data', 'trigger_event', 'notification_code', 'notification_medium', 'read_at'
    ]

    let adapter = {}
    if (pgConnection) {
      adapter = new PGAdapter(pgConnection, 'avocado.data_notification', username, columns, 'id')
    } else if (agaveAdapter) {
      adapter = agaveAdapter
    }

    super(adapter)
    if (locationApi && pgConnection) {
      this.transactionsNotifications = new TransactionsNotificationsAdapter({
        notificationApi: this,
        locationApi,
        notificationsFcmApi,
        logger
      })
    }
    this.pgConnection = pgConnection
    this.agaveAdapter = agaveAdapter
  }

  async create (item, tenant) {
    if (!this.pgConnection) {
      throw new Error('You can only write notifications server-side')
    }

    if (!item.userId && !item.locationId) {
      throw new Error('A notifications needs to be created for a userId or for a locationId')
    }

    let items = [item]
    if (!item.userId) {
      let query = `select fsid from avocado.data_user where location_id in (select uuid from avocado.data_location where fsid = $1)`

      // psm locations aren't synced to avocado.data_location so we check data_fcmmapping to link their fsid to their user id
      if (tenant === 'psm') {
        query = `
          select
            fsid
          from avocado.data_user where fsid in (
            select
              concat('org.couchdb.user:', user_id)
            from avocado.data_fcmmapping
            where location_fsid = $1
          )
        `
      }

      const { rows: users } = await this.pgConnection.query(query, [item.locationId])
      items = users.map(user => {
        return {
          ...item, userId: user.fsid
        }
      })
    }

    const posted = await this.adapter.create(items.map(item => translateToPG(item)))
    return posted.map(translateToFE)
  }

  async createBulkNotificationsForMarket (market, event, code, title, url, message, type) {
    if (!this.pgConnection) {
      throw new Error('You can only write notifications server-side')
    }

    const query = `Select u.fsid AS user_id, l.fsid AS location_fsid
    FROM avocado.data_user u
    JOIN avocado.data_location l ON u.location_id = l.uuid
    WHERE l.market_id  = $1`

    const { rows } = await this.pgConnection.query(query, [market])
    const notifications = rows.map(row => {
      const data = {
        notification: {
          title,
          body: message
        },
        data: {
          type: 'info',
          url,
          action: 'view',
          label: type
        },
        'webpush': {
          'fcm_options': {
            'link': url,
            'analytics_label': type
          }
        },
        locationId: row.location_fsid
      }

      return {
        locationId: row.location_fsid,
        userId: row.user_id,
        notificationText: title,
        action: url,
        code,
        medium: 'push',
        event,
        notificationData: data
      }
    })

    const posted = await this.adapter.create(notifications.map(notification => translateToPG(notification)))
    return posted
  }

  async getNotificationCount (medium, event) {
    if (!this.pgConnection) {
      throw new Error('Can only get notification count server-side')
    }

    if (!medium || !event) {
      throw new Error('notification medium and notification trigger event required')
    }
    const query = `
      select
        count(1)::integer
      from avocado.data_notification
      where notification_medium = $1 and trigger_event = $2
    `
    const { rows } = await this.pgConnection.query(query, [medium, event])
    return rows[0]
  }

  async getUsersForMarket (market) {
    if (!this.pgConnection) {
      throw new Error('You can only write notifications server-side')
    }

    const query = `SELECT DISTINCT f.id AS token
    FROM avocado.data_user u
    JOIN avocado.data_location l ON u.location_id = l.uuid
    JOIN avocado.data_fcmmapping f ON f.location_fsid = l.fsid
    WHERE l.market_id  = $1`

    const { rows: users } = await this.pgConnection.query(query, [market])
    return users
  }

  async getList ({ filter = {}, ...params }) {
    if (this.pgConnection) {
      filter = adaptFilter(filter)

      // NOTE: In agave: the user_id must come from the cookie
      return this.adapter.getList({
        filter,
        ...params,
        ordering: '-created_at'
      })
        .then(({ results }) => {
          return results.map(translateToFE)
        })
    }

    return this.adapter.list('notifications/', { filter, ...params })
  }

  async markAsRead ({ filter = {} }) {
    if (this.pgConnection) {
      filter = adaptFilter(filter)
      const user_id = filter.user_id // eslint-disable-line camelcase

      // NOTE: In agave: the user_id must come from the cookie
      const updated = await this.adapter.updateWhere({
        filter: {
          user_id,
          // NOTE: This one needs to be last if you add more conditions
          // (because it has no positional argument)
          read_at: null
        },
        preparers: {
          read_at: (key, value) => [{ statement: `"${key}" IS NULL`, value }]
        }
      }, {
        read_at: new Date()
      })

      return updated.map(translateToFE)
    }

    return this.adapter.create('notifications/read', {})
  }

  async createBulkPushNotifications (users, message, title, url, type) {
    const messages = users.map(user => {
      const {token} = user
      return {
        notification: {
          title,
          body: message
        },
        data: {
          type: 'info',
          url,
          cta: title,
          action: 'View',
          label: type
        },
        // Unclear if these works, app uses data.url right now
        'webpush': {
          'fcm_options': {
            'link': url,
            'analytics_label': type
          }
        },
        token
      }
    })
    return this.sendBulkPushNotifications(messages)
  }

  async sendBulkPushNotifications (messages) {
    // This is for tests
    if (!this.agaveAdapter) {
      console.log('No agave adapter so returning messages')
      return messages
    }
    return this.agaveAdapter.post('notifications/send-bulk-push-notifications', { messages })
  }
}

module.exports = NotificationsApi
