import React, { Component, createElement, createContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import merge from 'lodash/merge'

import Actions from './Actions'
import Button from './Button'
import DisplayValue from './DisplayValue'
import Field from './Field'
import InputLabel from './InputLabel'
import Row from './Row'
import Section from './Section'
import ValidationErrors from './ValidationErrors'

import {
  cleanFields,
  getFieldErrors,
  fieldHasErrors,
  mapField,
  resetField
} from './utils'

/**
 * Create FormContext to be used by Form's composite components (e.g.
 * <Form.Input>)
 */
export const FormContext = createContext({})

/**
 * <Form>
 *
 * A simple Form handling & validation component
 *
 */
class Form extends Component {
  constructor (props) {
    super(props)
    /*
     * Let react manage the value passed down as form context
     * https://medium.com/@ryanflorence/react-context-and-re-renders-react-take-the-wheel-cd1d20663647
     * re-creating this object in the render function will cause more re-renders than necessary
     */
    this.state = {
      fields: {},
      initialFields: {},
      fieldHasErrors: fieldName => fieldHasErrors(fieldName, this.state.fields),
      getFieldErrors: fieldName => getFieldErrors(fieldName, this.state.fields),
      hasErrors: this.getErrors,
      getErrors: this.getErrors,
      handleFieldChange: this._handleFieldChange,
      submit: this.submit,
      reset: this.reset,
      registerField: this._registerField,
      deregisterField: this._deregisterField,

      _local_hasErrors: false,
      _local_pristine: true
    }
  }

  static Actions = Actions
  static Button = Button
  static DisplayValue = DisplayValue
  static Field = Field
  static InputLabel = InputLabel
  static Row = Row
  static Section = Section
  static ValidationErrors = ValidationErrors

  static propTypes = {
    /**
     * The component's children
     */
    children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node
    ]).isRequired,

    /**
     * Pass an alternative top level element to use as the "form".
     */
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

    /**
     * We disable HTML5 validation by default.
     * To use it in addition to our own validation, set noValidate to false.
     */
    noValidate: PropTypes.bool,

    /**
     * The submit handler
     */
    onSubmit: PropTypes.func
  }

  static defaultProps = {
    component: 'div',
    noValidate: true,
    onSubmit: () => { /* noop */ }
  }

  /*****************************************************************************
   * PUBLIC API
   ****************************************************************************/

  /**
   * Returns a key/value map of fields with validation errors
   */
  getErrors = () => {
    return Object.keys(this.state.fields).reduce((fields, fieldName) => {
      return fieldHasErrors(fieldName, this.state.fields) ? {
        ...fields,
        [fieldName]: [
          ...getFieldErrors(fieldName, this.state.fields)
        ]
      } : {
        ...fields
      }
    }, {})
  }

  /**
   * Returns a key/value map of form fields
   */
  getFields = () => this._getFields()

  /**
   * Returns a files map of form fields
   */
  getFiles = () => this._getFiles()

  /**
   * Returns a value for a specific form field
   */
  getFieldValue = (fieldName) => this._getFieldValue(fieldName)

  /**
   * Set new value for a named field
   */
  setFieldValue = (fieldName, value) => this._setFieldValue(fieldName, value)

  /**
   * Resets the form to it's intitial state
   */
  reset = () => {
    this.setState({
      ...this.state,
      fields: {
        ...Object
          .keys(this.state.fields)
          .reduce((fields, fieldName) => ({
            ...fields,
            [fieldName]: resetField(fieldName, this.state.fields, this.state.initialFields)
          }), {})
      }
    })
  }

  /**
   * Submits the form
   */
  submit = () => {
    this._handleSubmit()
  }

  /**
   * Is the Form pristine?
   *
   * - Returns true given the form is pristine (no changes)
   * - Returns false given the form is not pristine (data has changed)
   *
   */
  isPristine = () => {
    return this.state._local_pristine
  }

  /**
   * Iterates through the form fields running their respective validators.
   * Returns `true` for a valid form or `false` if any of the form's fields
   * have validation errors.
   */
  validate = async () => {
    /**
     * Iterate through each field applying the given field's respective validators.
     *
     * Returns falsy on successful validation or a key/value map of field names
     * / array of error messages.
     *
     * Currently supports the following `<Form.Field.XInput/>` props:
     *
     * 1. `required {true|false}` (default: false)
     *    A flag to specify whether the given fields value is required
     * 2. `validate {func}`
     *    A custom validator function. Takes the field value as an argument
     *    returning undefined or null for a successful validation or a validation
     *    error message on fail.
     *
     *    e.g:
     *
     *    ```js
     *      // Validate must either be an async function, or a regular function that
     *      // returns a promise:
     *      const validate = async (value) {
     *        if (value && value.length < 10) {
     *          return 'Value must be at least 10 characters long.'
     *        }
     *      }
     *
     *      // Regular promsie:
     *      const validate = (value) => {
     *        if (value && value.length < 10) {
     *          return Promise.resolve('Value must be at least 10 characters long.')
     *        }
     *      }
     *    ```
     */
    const { fields } = this.state
    const fieldNames = Object.keys(fields) || []
    const validationErrors = await fieldNames.reduce(async (accumulatorP, fieldName) => {
      const field = this.state.fields[fieldName]
      const accumulator = await accumulatorP

      const fieldErrors = await Promise.all(field.validators.map(validator => validator(field.value)))
      return {
        ...accumulator,
        [fieldName]: {
          errors: fieldErrors ? fieldErrors.filter(validator => validator) : []
        }
      }
    }, Promise.resolve({}))

    const hasErrors = !!Object.keys(validationErrors).find(fieldName => validationErrors[fieldName].errors.length)

    this.setState({
      fields: merge(cleanFields(fields), validationErrors),
      _local_hasErrors: hasErrors
    })

    return hasErrors && Object.keys(validationErrors).reduce((accumulator, current) => ({
      ...accumulator,
      [current]: validationErrors[current].errors
    }), {})
  }

  render () {
    const {
      component,
      children,
      className,
      ...otherProps
    } = this.props

    return (
      <FormContext.Provider
        value={this.state}
      >
        {createElement(
          component,
          {
            ...otherProps,
            className: classnames(
              'vs-form',
              className
            )
          },
          children
        )}
      </FormContext.Provider>
    )
  }

  /*****************************************************************************
   * PRIVATE API
   ****************************************************************************/

  /**
    * Get the fields state
    */
  _getFields = () => Object
    .keys(this.state.fields)
    .reduce((fields, fieldName) => ({
      ...fields,
      [fieldName]: this.state.fields[fieldName].value
    }), {})

  /**
    * Get the files state
    */
  _getFiles = () => Object
    .keys(this.state.fields)
    .reduce((fields, fieldName) => ({
      ...fields,
      [fieldName]: this.state.fields[fieldName].files
    }), {})

  _getFieldValue = (fieldName) => {
    const fields = this.state.fields
    let field = fields[fieldName]

    if (!field) {
      // in case a consumer tried to get a field that has never been registered
      console.warn(
        `Attempted to get value for "${fieldName}", but no such field has been registered. Skipping.`
      )
      return
    }

    return field.value
  }

  _setFieldValue = (fieldName, value) => {
    const fields = this.state.fields
    let field = fields[fieldName]
    if (!field) {
      // in case a consumer did try to set a field that has never been registered
      // we need to prevent the call from creating a field in the hash
      console.warn(
        `Attempted to set a value for "${fieldName}", but no such field has been registered. Skipping.`
      )
      return
    }
    field = {
      ...field,
      value
    }

    fields[fieldName] = field

    this.setState({fields})
  }

  /**
   * Internal form submission handler
   */
  _handleSubmit = async (event) => {
    if (event) event.preventDefault()

    const {
      onSubmit
    } = this.props

    const validationErrors = await this.validate()

    if (!validationErrors) {
      if (typeof onSubmit === 'function') {
        onSubmit(this._getFields())
      }
    }
  }

  /**
   * Internal field onChange handler
   * Passed to `FormContext`
   */
  _handleFieldChange = async (fieldName, value, files) => {
    const field = this.state.fields[fieldName]
    const updatedField = {
      ...field,
      value,
      files
    }

    this.setState(
      {
        _local_pristine: false,
        fields: {
          ...this.state.fields,
          [fieldName]: {
            ...updatedField
          }
        }
      }, async () => {
        if (this.state._local_hasErrors) {
          return this.validate()
        }
      }
    )
  }

  /**
   * Registers a new field with the form.
   * Passed to `FormContext`
   */
  _registerField = ({ fieldName, keepField, labelText, ...fieldProps }) => {
    this.setState((prevState) => {
      if (prevState.fields[fieldName]) {
        if (!keepField) console.warn(`A field with the name '${fieldName}' has already been registered with the Form.\n\nPlease check and correct your Form markup.\n\nSkipping registration for duplicate field.`)
        return null
      }
      return {
        fields: {
          ...prevState.fields,
          [fieldName]: mapField({ fieldName, labelText, ...fieldProps })
        },
        initialFields: {
          ...prevState.initialFields,
          [fieldName]: mapField({ fieldName, labelText, ...fieldProps })
        }
      }
    })
  }

  /**
   * De-registers a field
   */
  _deregisterField = (fieldName) => {
    this.setState((prevState) => {
      if (!prevState.fields[fieldName]) {
        return prevState
      }

      const newFields = {...prevState.fields}
      const newInitialFields = {...prevState.initialFields}

      delete newFields[fieldName]
      delete newInitialFields[fieldName]

      // this is done to prevent a memory leak
      // some issue when the components are unmounted
      // makes the fields getting kept even after they are de-registered
      // not sure if it is a react bug or something else
      delete prevState.fields
      delete prevState.initialFields

      return {
        fields: newFields,
        initialFields: newInitialFields
      }
    })
  }
}

export default Form
