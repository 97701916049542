import React, { Component } from 'react'

import { formatDate, dateFormats } from '../../../van-shared/utils'
import shipmentsBreadcrumb from '../common/shipmentsBreadcrumb'

import Planning from './Planning'

class PlanningContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedDate: props.date,
      selectedOriginId: props.originId,
      selectedDestinationId: props.destinationId,
      originOptions: props.origins.map(l => ({label: l.name, value: l._id})),
      destinationOptions: props.destinations.map(l => ({label: l.name, value: l._id}))
    }
  }

  submitHandler = () => {
    this.props.onSubmit({
      date: this.state.selectedDate,
      originId: this.state.selectedOriginId,
      destinationId: this.state.selectedDestinationId
    })
  }

  render () {
    return (
      <Planning
        pageTitle={'Planning'}
        breadcrumbItems={[shipmentsBreadcrumb]}
        formattedDate={formatDate(this.state.selectedDate, 'long')}
        allowDateChange
        dateFormat={dateFormats.long}
        setDate={selectedDate => this.setState({ selectedDate })}
        originOptions={this.state.originOptions}
        origin={this.state.selectedOriginId}
        setOrigin={selectedOriginId => this.setState({ selectedOriginId })}
        destinationOptions={this.state.destinationOptions}
        destination={this.state.selectedDestinationId}
        setDestination={selectedDestinationId => this.setState({ selectedDestinationId })}
        onSubmit={this.submitHandler}
      />
    )
  }
}

export default PlanningContainer
