module.exports = (id, seperator = ':') => {
  const parts = id.split(seperator)
  return parts.reduce((accumulator, key, index) => {
    if (index % 2 !== 0) {
      return accumulator
    }
    accumulator[key] = parts[index + 1]
    return accumulator
  }, {})
}
