module.exports = docsToSentReceivedSurveys

const { parse } = require('../../tools/smart-id')

// comparing survey ids:
// snId:batchId:survey:2001-01-01T000000 > snId:batchId:survey:2000-01-01T000001
function docsToSentReceivedSurveys (docs = []) {
  return docs.reduce((memo, doc) => {
    const { status } = parse(doc._id)
    const key = (status === 'arrived' || status === 'received')
      ? 'receivedSurveys'
      : 'sentSurveys'

    // If we don't have a survey for this batch, add it.
    // OR, if we already have a survey for this batch, maybe replace it with a later survey.
    if (!memo[key][doc.batchId] || memo[key][doc.batchId]._id < doc._id) {
      memo[key][doc.batchId] = doc
    }

    return memo
  }, { sentSurveys: {}, receivedSurveys: {} })
}
