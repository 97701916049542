const VAT_PER_MARKET = {
  ke: [
    { date: new Date('2017-01-01').toJSON(), vat: 16 },
    { date: new Date('2020-04-01').toJSON(), vat: 14 },
    { date: new Date('2021-01-04').toJSON(), vat: 16 }
  ],
  ng: [
    { date: new Date('2021-07-09').toJSON(), vat: 7.5 }
  ]
}

const CLASSIFICATIONS = {
  clinics: 'Clinics and Hospitals',
  ppmv: 'PPMV',
  general_retailer: 'General Retailer',
  pharmacy: 'Pharamcy'
}

module.exports = {
  VAT_PER_MARKET,
  CLASSIFICATIONS
}
