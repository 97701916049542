const { format } = require('date-fns')

module.exports = getDebitStatus
async function getDebitStatus (
  { lipaShortCode, token, lipaPassKey, fetch, logger, endpoints },
  {CheckoutRequestID}
) {
  const timestamp = format(new Date(), 'YYYYMMddHHMMss')
  const password = Buffer.from(lipaShortCode + lipaPassKey + timestamp).toString(
    'base64'
  )
  const requestBody = {
    BusinessShortCode: lipaShortCode,
    Password: password,
    Timestamp: timestamp,
    CheckoutRequestID
  }
  const config = {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(requestBody)
  }

  const response = await fetch(
    endpoints.getDebitStatus,
    config
  )

  if (!response.ok) {
    delete requestBody.password
    let text
    try {
      text = await response.text()
    } catch (e) {
      // pass
    }
    logger.warn(
      'error getting transaction status',
      response.status,
      response.statusText,
      text,
      JSON.stringify(requestBody)
    )
    return
  }

  try {
    const body = await response.json()
    return body
  } catch (e) {
    delete requestBody.password
    logger.warn(
      'error callling json on get transaction status body',
      response.status,
      response.statusText,
      JSON.stringify(requestBody)
    )
  }
}
