function addLevels (node, levels) {
  node.level = levels[0]
  if (!node.children) {
    return
  }
  Object.keys(node.children).forEach(id => {
    addLevels(node.children[id], levels.slice(1))
  })
}

const getGeoLocations = function (state) {
  return state.dal.geoLocations.get(state)
    .then(doc => {
      const levels = doc.levels
      // add a level property to each location node.
      Object.keys(doc.locations).forEach(id => {
        addLevels(doc.locations[id], levels)
      })
      return doc.locations
    })
}

function findChildGeoLocation (node, geolocation, geoLocationId, childrenLocations) {
  if (!node || !node.children) {
    return
  }
  // Once we find the users state add to the childrenLocation object
  if (geolocation === geoLocationId) {
    childrenLocations[geoLocationId] = node
  }

  return Object.keys(node.children).forEach(id => {
    return findChildGeoLocation(node.children[id], id, geoLocationId, childrenLocations)
  })
}

const getGeoLocationChildrenOfUserState = async function (state) {
  // Get users geoLocation from state
  const geoLocationId = state.user.location.id.split('state:')[1]
  const geoLocations = await getGeoLocations(state)
  let childrenLocations = {}

  // Search through each geographical location for the users location
  Object.keys(geoLocations).forEach((geolocation) => {
    return findChildGeoLocation(geoLocations[geolocation], geolocation, geoLocationId, childrenLocations)
  })
  return childrenLocations
}

module.exports = {
  getGeoLocations,
  getGeoLocationChildrenOfUserState
}
