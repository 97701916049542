import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CheckmarkCircle, Close, ExclamationTriangle, Info, InfoCircle } from '../../icons'

const CardTabsCardHeader = ({
  text,
  thresholdStatus,
  flippable,
  onFlipClick,
  flipped
}) => (
  <header
    className={classnames(
      'tab-card-header',
      { 'tab-card-header--alert': thresholdStatus === 'alert' },
      { 'tab-card-header--warning': thresholdStatus === 'warning' },
      { 'tab-card-header--ok': thresholdStatus === 'ok' },
      { 'tab-card-header--excessive': thresholdStatus === 'excessive' },
      { 'tab-card-header--no-data': thresholdStatus === 'noData' },
      { 'tab-card-header--neutral': !thresholdStatus || flipped }
    )}
  >
    <span className='tab-card-header__text'>
      <span className='tab-card-header__icon'>
        {flipped && <InfoCircle />}
        {(!flipped && thresholdStatus === 'ok') && <CheckmarkCircle solid />}
        {(!flipped && thresholdStatus === 'warning') && <InfoCircle solid />}
        {(!flipped && thresholdStatus !== 'ok' && thresholdStatus !== 'warning') && <ExclamationTriangle />}
      </span>
      {text}
    </span>
    {(flippable || flipped) && (
      <button onClick={onFlipClick} className='tab-card-header__action'>
        {flipped ? <Close /> : <Info />}
      </button>
    )}
  </header>
)

CardTabsCardHeader.propTypes = {
  /**
   * Card header text, usually a short status, e.g. "On target"
   */
  text: PropTypes.string,

  /**
   * The indicator color code/threshold status
   */
  thresholdStatus: PropTypes.oneOf(['alert', 'warning', 'ok', 'excessive', 'noData', null]),

  /**
   * Should this header be rendered for the flipside of a tab-card?
   */
  isCardFlipped: PropTypes.bool,

  /**
   * function to run on click anywhere on header
   */
  onClick: PropTypes.func
}

CardTabsCardHeader.defaultProps = {
  text: '',
  thresholdStatus: null,
  isCardFlipped: false,
  onClick: () => {}
}

CardTabsCardHeader.displayName = 'CardTabsCard.Header'

export default CardTabsCardHeader
