const PGAdapter = require('./../common/pg-adapter')
const DEPOSIT_TABLE_NAME = 'avocado.data_deposit'
const DEPOSIT_COLUMNS = [
  'id',
  'created_at',
  'updated_at',
  'created_by',
  'updated_by',
  'location_id',
  'amount',
  'date_paid'
]

class DepositAdapter extends PGAdapter {
  constructor (pgConnection, username) {
    super(
      pgConnection,
      DEPOSIT_TABLE_NAME,
      username,
      DEPOSIT_COLUMNS
    )
  }
}

module.exports = DepositAdapter
