const PGAdapter = require('../common/pg-adapter')

class PriceSuggestionPGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = [
      'id',
      'market_id',
      'sku',
      'latest_buyprice',
      'latest_sellprice',
      'avg_forecasted_buyprice',
      'latest_inventory',
      'latest_sl_inventory',
      'latest_sl_inventory_value',
      'total_to_sell',
      'total_in_held',
      'total_to_buy',
      'date',
      'visibility_1',
      'markup',
      'suggested_sellprice',
      'uncensored_suggested_price',
      'updated_at',
      'price_update_id',
      'status',
      'product_sku_id',
      'sellprice_change_perc',
      'avg_cost_basis',
      'most_recent_buyprice_vs_cost_basis',
      'product'
    ]
    super(pgConnection, 'summaries.v_price_calculator_suggestions', username, columns, 'id', {}, logger)
  }

  async refresh () {
    await this.pgConnection.query(`REFRESH MATERIALIZED VIEW summaries.v_price_calculator_suggestions;`)
    return { id: null }
  }
}

class PriceSuggestionTierPGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = [
      'id',
      'price_update_id',
      'visibility',
      'markup',
      'skus_per_tier',
      'total_skus_per_tier'
    ]
    super(pgConnection, 'summaries.v_price_tier_summary', username, columns, 'id', {}, logger)
  }
}

class PriceSuggestionSummaryPGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = [
      'id',
      'market_id',
      'date',
      'latest_inventory',
      'sell_price_v_cost_basis',
      'sell_price_v_most_recent_price',
      'wa_cost_basis_v_most_recent_price',
      'wa_sellprice_d',
      'total_to_sell',
      'total_to_buy'
    ]
    super(pgConnection, 'summaries.v_price_update_summary', username, columns, 'id', logger)
  }
}

module.exports = { PriceSuggestionPGAdapter, PriceSuggestionTierPGAdapter, PriceSuggestionSummaryPGAdapter }
