module.exports = { entityCreateToDoc, entityUpdateToDoc }

const { isApprover } = require('./utils')
const { validateEntityCreateToDoc } = require('./validation')

function entityCreateToDoc ({
  proposedDoc, currentDoc, effectiveDate, user, timestamp = new Date().toJSON()
}) {
  validateEntityCreateToDoc({ proposedDoc, currentDoc, effectiveDate, user, timestamp })

  const proposalType = currentDoc
    ? 'update'
    : 'new'

  const entityType = proposedDoc.type

  // Putting entityType in the id because location ids don't have a `location:` prefix
  const _id = `proposal:${entityType}:id:${proposedDoc._id}:timestamp:${timestamp}`

  const timestamps = {
    createdAt: timestamp,
    createdBy: user.name,
    updatedAt: timestamp,
    updatedBy: user.name
  }

  // Actual document will be stamped with same details as proposal doc
  const stampedDoc = (proposalType === 'new')
    ? Object.assign({}, proposedDoc, timestamps)
    : Object.assign({}, proposedDoc, { updatedAt: timestamp, updatedBy: user.name })

  const status = isApprover(user)
    ? 'approved'
    : 'pending'

  return Object.assign(
    {},
    {
      _id,
      version: '1.0.0',
      type: `proposal:${entityType}`,
      effectiveDate,
      status,
      proposalType,
      entityType,
      doc: stampedDoc
    },
    timestamps
  )
}

function entityUpdateToDoc ({ proposalEntity, user, status, timestamp = new Date().toJSON() }) {
  const newProps = {
    status,
    updatedAt: timestamp,
    updatedBy: user.name
  }

  return Object.assign({}, proposalEntity, newProps)
}
