import PropTypes from 'prop-types'
import { Component } from 'react'
import { createPortal } from 'react-dom'

class Portal extends Component {
  constructor (props) {
    super(props)

    this.portal = document.getElementById('portal')
    this.contentNode = document.createElement('div')

    this.bannersWrapper = document.getElementsByClassName('shell__banners')
    this.bannersHeight = this.bannersWrapper.length ? this.bannersWrapper[0].offsetHeight : 0
    this.portal.style.setProperty('--vs-app-top-offset', this.bannersHeight + 'px')
  }

  componentDidMount () {
    this.portal.appendChild(this.contentNode)
  }

  componentWillUnmount () {
    this.portal.removeChild(this.contentNode)
    this.contentNode = null
  }

  render () {
    return createPortal(this.props.children, this.contentNode)
  }
}

Portal.propTypes = {
  /**
   * Children components of Portal
   */
  children: PropTypes.node
}

export default Portal
