const { PouchAdapter } = require('../../common')

// TODO: this is a placeholder, doc schema to be added
const schema = {
  title: 'service',
  name: 'service',
  description: 'A service',
  type: 'object',
  properties: {
    _id: {type: 'string'}
  }
}

class ServiceDataAdapter extends PouchAdapter {
  constructor (user, productsDB, restAdapter) {
    super(user, schema, productsDB)
    this.restAdapter = restAdapter
  }
}

module.exports = ServiceDataAdapter
