/* global fetch, AbortController */
import { hasOwnDatabase } from './utils/user-db-sync'
import { isNoDBUser } from '../sync/databases'

async function acceptTermsAndConditions (config, version) {
  // Let's set a timeout for the API call
  const controller = new AbortController()
  const timeout = setTimeout(() => controller.abort(), 10000)

  try {
    const response = await fetch(`${config.agaveApiUrl}/user/accept-tcs`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({ version }),
      signal: controller.signal
    })

    clearTimeout(timeout)

    if (!response.ok) {
      const error = new Error(`Api call returned ${response.statusText}`)
      error.status = response.status
      throw error
    }

    return response.json()
  } catch (e) {
    console.log('Failed accepting terms and conditions', e)
    throw e
  }
}

function getTCDocuments (config, user) {
  if (!config.features.termsAndConditions) {
    return []
  }

  const documents = config.features.termsAndConditions.documents

  return documents.filter(document => {
    if (!document.constraints) {
      return true
    }
    if (document.constraints.includes('isRetailer')) {
      return (hasOwnDatabase(user) || isNoDBUser(user))
    }
    return true
  })
}

export {
  acceptTermsAndConditions,
  getTCDocuments
}
