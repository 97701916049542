import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { DeemphasizedLink, Text } from '@fielded/shared-ui'

const WarningNotice = ({
  type,
  text,
  onOpenModal,
  modal,
  icon,
  link,
  linkCopy
}) => {
  return (
    <Fragment>
      <div className={classnames(
        'warning-notice',
        {'warning-notice--attention': type === 'attention'},
        {'warning-notice--warning': type === 'warning'}
      )}>
        <div className='warning-notice__icon-text-container'>
          {icon && (
            <Text className='warning-notice__icon'>
              {icon}
            </Text>
          )}
          {text ? (
            <Text className='warning-notice__text'>{text}</Text>
          ) : (
            <span className='warning-notice__text warning-notice--no-text' />
          )}
        </div>
        {link && (
          <Link
            to={link}
            className='vs-u-link vs-u-link--inherit'
          >
            {linkCopy}
          </Link>
        )}
        {modal && (
          <DeemphasizedLink
            onClick={onOpenModal}
          >
            Learn more
          </DeemphasizedLink>
        )}
      </div>
      {modal}
    </Fragment>
  )
}

export default WarningNotice
