const { setMonth, startOfMonth, endOfMonth, addMonths, startOfDay, setISODay, getISOWeek, setISOWeek, endOfISOWeek } = require('date-fns')

module.exports = (periodType, date) => {
  const func = getPeriodTypeFunction(periodType.unit)
  return func(periodType, date)
}

module.exports.getPeriodTypeFunction = getPeriodTypeFunction
function getPeriodTypeFunction (unit) {
  const functions = {
    'month': monthFunction,
    'week': weekFunction
  }
  const func = functions[unit]
  if (!func) {
    throw new Error(`reporting unit not supported: ${unit}`)
  }
  return func
}

const monthFunction = (periodType, date) => {
  // e.g. bimonthly:
  // if jan, monthNumber = 0, want 0
  // if feb, monthNumber = 1, still want 0
  // if march, monthNumber = 2, want 2
  // if april, monthNumber = 3, still want 2
  // e.g. april, 3 - ((3 * 2) = 1) = 2, so we still start with march
  const monthNumber = date.getMonth()
  const quantity = periodType.quantity || 1
  const periodMonthNumber = monthNumber - (monthNumber % quantity)
  const startDate = startOfMonth(setMonth(date, periodMonthNumber))
  // e.g. bimonthly:
  // if jan, want start of end feb
  const endDate = endOfMonth(addMonths(startDate, quantity - 1))
  return { startDate, endDate }
}

const weekFunction = (periodType, date) => {
  const monday = setISODay(date, 1)
  const quantity = periodType.quantity || 1
  const week = getISOWeek(monday)
  // We always start on week 1 of a year
  const startWeek = Math.max(week - (week % quantity), 1)
  const startDate = startOfDay(setISOWeek(monday, startWeek))
  const endDate = endOfISOWeek(setISOWeek(monday, startWeek + quantity - 1))
  return { startDate, endDate }
}
