module.exports = { get, list }

const tools = require('../../tools')

// TODO: the final filter should be moved out of DAL and into tools
function get (state, { entityId, entityType, proposalId, date }) {
  if (proposalId) {
    return state.proposalsDB.get(proposalId)
      .then(tools.docToEntity)
  }

  return state.proposalsDB.allDocs({
    include_docs: true,
    startkey: `proposal:${entityType}:id:${entityId}:`,
    endkey: `proposal:${entityType}:id:${entityId}:\uffff`
  })
    .then(({ rows }) => {
    // Ignore proposals made in past reporting periods
      rows = rows.filter(row => row.doc.effectiveDate >= date)
      const lastRow = rows[rows.length - 1]
      return lastRow
        ? tools.docToEntity(lastRow.doc)
        : null
    })
}

function list (state, { entityType, currentEntities, startDate, endDate }) {
  return state.proposalsDB.query(
    tools.constants.BY_EFFECTIVE_DATE_VIEW_NAME,
    {
      startkey: [`proposal:${entityType}`, startDate],
      endkey: [`proposal:${entityType}`, endDate],
      include_docs: true
    })
    .then(({ rows }) => rows.map(row => tools.docToEntity(row.doc)))
    .then(proposalEntities => tools.listWithProposals({ currentEntities, proposalEntities }))
}
