
import get from 'lodash/get'
import capitalize from './capitalize'

// the manufacturer value from the DB is sometimes the key ('bilthoven') and sometimes the value ('Bilthoven Biologicals')
// If the latter, adding the batch fails. So we accept either and sort it out here.
export const normaliseManufacturerValue = (ambiguousManufacturer, manufacturers) => {
  if (typeof ambiguousManufacturer !== 'string') {
    throw new Error('normaliseManufacturerValue was called without a string value to normalise')
  }
  if (!manufacturers) {
    console.warn('normaliseManufacturerValue was called without canonical manufacturers')
    return ambiguousManufacturer
  }

  const properManufacturer = manufacturers.filter((manufacturer) => {
    return manufacturer.value === ambiguousManufacturer || manufacturer.label === ambiguousManufacturer
  })

  if (properManufacturer.length < 1) {
    console.log('normaliseManufacturerValue found no match in manufacturers')
    return ambiguousManufacturer
  }

  if (properManufacturer.length > 1) {
    console.log('normaliseManufacturerValue found multiple matches in manufacturers, returning the first')
  }

  return properManufacturer[0].value
}

export const getManufacturerLabel = (value, manufacturers) => {
  if (!value) return undefined

  const fallback = capitalize(value)
  if (!manufacturers) return fallback

  return get(
    manufacturers.find((manufacturer) => manufacturer.value === value),
    'label',
    fallback
  )
}
