import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import NumberFormat from '../NumberFormat'
import get from 'lodash/get'

export const currencies = {
  NGN: {
    code: 'NGN',
    symbol: '₦'
  },
  KES: {
    code: 'KES',
    symbol: 'KSh'
  },
  USD: {
    code: 'USD',
    symbol: '$'
  }
}

export const currencyByCountry = {
  ng: 'NGN',
  ke: 'KES',
  us: 'USD'
}

export const getCurrency = ({country, code}) => {
  const currencyCode = code || currencyByCountry[country]
  const currency = currencies[currencyCode]
  if (currency) {
    return currency
  }
  console.warn('getCurrency could not resolve currency from country:', country, 'code:', code)
  return null
}

export const getCurrencySymbol = ({country, currency}) => {
  if (!(country || currency)) {
    console.warn('getCurrencySymbol was called without a country or currency')
    return null
  }

  let currencyObject
  if (typeof currency === 'object') {
    currencyObject = currency
  }
  if (typeof currency === 'string') {
    currencyObject = getCurrency({code: currency})
  }
  if (!currencyObject && country && typeof country === 'string') {
    currencyObject = getCurrency({country})
  }

  const currencySymbol = get(currencyObject, 'symbol')

  if (currencySymbol) {
    return currencySymbol
  }
  console.warn('getCurrencySymbol could not resolve currency from country:', country, 'currency:', currency)
  return null
}

export const priceCell = ({ cellData }) => (
  <PriceDisplay value={cellData} />
)

export const splitValueAndDecimal = (value) => {
  const values = Number(value).toFixed(2).split('.')
  return {
    value: Number(values[0]),
    decimalValue: `.${values[1]}`
  }
}

const PriceDisplay = ({
  value,
  roundUp,
  country,
  currency,
  currencySymbol,
  shorten,
  bold,
  size,
  inColor,
  className,
  invalid,
  currencyOnRight,
  hideDecimal,
  prefixedValue,
  ...otherProps
}) => {
  const isHuge = size === 'huge'
  const classNames = classnames(
    'vs-price-display',
    {'vs-u-font-bold': bold || isHuge},
    {'vs-price-display--huge': isHuge},
    {'vs-price-display--color': inColor},
    {'vs-price-display--invalid': invalid},
    className
  )

  const currencyCode = getCurrency({country, currency}).code

  if (!Number.isFinite(parseInt(value, 10))) {
    return <span className={classNames}>-</span>
  }

  let roundedValue = currencyCode === 'NGN' && roundUp
    ? Math.round(value)
    : Number(value).toFixed(2)

  let processedValue = hideDecimal ? splitValueAndDecimal(roundedValue).value : roundedValue

  let roundedThousands
  let roundedMillions

  // We have only adapted these rounding rules to Naira rn
  // Might want to be a little less aggressive with the rounding for Shillings when needed.
  // 1.000 should not be shortened, so note the minimum of 10.000
  if (shorten && processedValue >= 10000 && processedValue < 1000000) {
    processedValue = Math.floor((processedValue / 1000))
    roundedThousands = true
  }

  if (shorten && processedValue >= 1000000) {
    // see https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
    // except we want at most 1 decimal, so using 10 instead
    processedValue = Math.floor((processedValue / 1000000) * 10) / 10
    roundedMillions = true
  }

  return (
    <NumberFormat
      value={processedValue}
      displayType='text'
      thousandSeparator
      renderText={formattedValue => (
        <span className={classNames}>
          {prefixedValue}
          {currencySymbol &&
            <span className={classnames(
              'price-display__currency',
              {'price-display__currency--on-right': currencyOnRight}
            )}>
              {getCurrencySymbol({country, currency})}
            </span>
          }
          <span className='vs-price-display__value'>
            {formattedValue}
          </span>
          {roundedThousands && <span>K</span>}{roundedMillions && <span>M</span>}
        </span>
      )}
      {...otherProps}
    />
  )
}

PriceDisplay.propTypes = {
  /**
   * the unformatted quantity to display
   */
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number
  ]),
  /**
   * used to infer the currency
   */
  country: PropTypes.oneOf(['ng', 'ke', 'us']),
  /**
   * used to infer the currency
   */
  currency: PropTypes.oneOf(['NGN', 'KES', 'USD']),
  /**
   * Optionally render the currency symbol
   */
  currencySymbol: PropTypes.bool,
  /**
   * Optionally shorten by showing 'k' for thousands and 'M' for millions
   */
  shorten: PropTypes.bool,
  /**
   * Optionally render the price in bold
   */
  bold: PropTypes.bool,
  /**
   * Optionally render the price in huge, for use on e.g. indicator cards
   */
  size: PropTypes.oneOf(['inherit', 'huge']),
  /**
   * Optionally render price in green color
   */
  inColor: PropTypes.bool,
  /**
   * class name to add to span
   */
  className: PropTypes.string,
  /**
   * If the value should be rounded up
   */
  roundUp: PropTypes.bool,
  /**
   * If the currency should show on right
   */
  currencyOnRight: PropTypes.bool,
  /**
   * hide decimal values when roundUp is set to false
   */
  hideDecimal: PropTypes.bool,
  /**
   * In case the currency is on left and we still want a prefix before,
   * use in favor of 'prefix'
   */
  prefixedValue: PropTypes.string
}

PriceDisplay.defaultProps = {
  country: 'ng',
  currencySymbol: false,
  size: 'inherit',
  roundUp: true,
  inColor: false,
  currencyOnRight: false,
  hideDecimal: false,
  prefixedValue: null
}

export default PriceDisplay
