const installments = require('./common/installments')
const txnTypes = require('./common/txn-types')
const transactions = require('./common/transactions')
const { pending, pendingSelector } = require('./common/pending')
const transactionsListSelector = require('./common/transactions-list-selector')
const installmentsListSelector = require('./common/installments-list-selector')

const transactionsSelect = transactionsListSelector()
const installmentsSelect = installmentsListSelector(`WHERE due_date <= (current_date + interval '6 days') or is_paid = true`)
const pendingSelect = pendingSelector()

const createTransactionsListQuery = () => {
  const cte = [
    transactions,
    installments,
    txnTypes,
    pending,
    `transactions_list AS (
        ${transactionsSelect}
        UNION
        SELECT * FROM (${installmentsSelect}) i
        UNION
        SELECT * FROM (${pendingSelect}) p
      )`
  ]
  return `
    WITH ${cte.join(', ')}
    SELECT tl.*, posting_type FROM transactions_list tl
    LEFT JOIN txn_types tt ON tl.txn_type = tt.txn_type
  `
}

module.exports = { createTransactionsListQuery }
