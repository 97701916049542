import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { Button, Page } from '@fielded/shared-ui'
import { CloseCircle, Settings } from '@fielded/shared-ui/src/icons'

import ShipmentStatusLabel from './ShipmentStatusLabel'

import {
  shipmentTitle,
  shipmentTypeLabel,
  isShelflifePowered
} from './utils'

const ShipmentHeader = ({
  title,
  breadcrumbItems,
  shipment,
  hasShipmentDetails,
  formattedDate,
  isEditable,
  isOptions,
  config,
  isReturn,
  hideMeta
}) => {
  return (
    <Page.Header
      title={title || shipmentTitle(shipment, config, isReturn)}
      breadcrumbItems={breadcrumbItems}
      className={classnames(
        'shipment-header',
        {'shipment-header--is-options': isOptions}
      )}
    >
      <div className='shipment-header__status'>
        <ShipmentStatusLabel shipment={shipment} />
      </div>

      {!hideMeta && <div className='shipment-header__meta'>
        {hasShipmentDetails && (
          <ul>
            {formattedDate && (
              <li className='shipment-header__meta-item'>
                <span className='header__label'> Date: </span>
                <span className='label--bold'> {formattedDate} </span>
              </li>
            )}
            {!isShelflifePowered(config) && <li className='shipment-header__meta-item'>
              <span className='header__label'> Type: </span>
              <span className='label--bold'> {shipment.shipmentType ? shipmentTypeLabel(shipment.shipmentType) : ''} </span>
            </li>}
            {shipment.shipmentNo != null && (
              <li className='shipment-header__meta-item'>
                <span className='header__label'> ID: </span>
                <span className='label--bold'> {shipment.shipmentNo} </span>
              </li>
            )}
          </ul>
        )}
        {isEditable &&
          <div className='shipment-header__meta-action'>
            <Button
              component={Link}
              to={`/shipments/pick-list/${shipment.snapshotId}/shipment-options`}
              fill='outline'
              colorVariant='brand'
              icon={<Settings />}
              title='Options'
              size='small'
            >
              Options
            </Button>
          </div>
        }
        {isOptions &&
          <div className='shipment-header__meta-action'>
            <Button
              component={Link}
              to={`/shipments/pick-list/${shipment.snapshotId}`}
              fill='outline'
              colorVariant='neutral'
              icon={<CloseCircle />}
              title='Close options'
              size='small'
            >
              Close
            </Button>
          </div>
        }
      </div>}
    </Page.Header>
  )
}

ShipmentHeader.propTypes = {
  breadcrumbItems: PropTypes.array,
  location: PropTypes.string,
  locationLabel: PropTypes.string,
  formattedDate: PropTypes.string,
  shipment: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  config: PropTypes.object
}

ShipmentHeader.defaultProps = {
  breadcrumbItems: undefined,
  location: '',
  formattedDate: undefined,
  isEditable: false,
  locationLabel: 'To/from',
  config: undefined
}

export default ShipmentHeader
