
const generateMessage = (transaction) => {
  let message = ''
  if (transaction.txn_type === 'invoice') {
    switch (transaction.order_type) {
      case 'PAYG':
        message = `Your invoice ${transaction.quickbooks_doc_number} is due tomorrow. Go to wallet to pay online or pay later with the loan facility. If you don't do anything by then, a loan will be automatically created against your invoice.`
        break
      default:
        message = `Your invoice  ${transaction.quickbooks_doc_number} is due tomorrow. Go to wallet to pay online or make sure funds are available for direct debit.`
        break
    }
  }
  if (transaction.txn_type === 'installment') {
    message = 'Your instalment is due tomorrow. Go to wallet to pay online or make sure funds are available for direct debit.'
  }
  return message
}

const transformInvoiceToNotificationData = (transaction, location) => {
  const url = '/retailer/finances'
  const notificationType = 'invoice-is-due-tomorrow'
  return {
    notification: {
      title: 'Invoice will be due in 24 hours',
      body: generateMessage(transaction)
    },
    data: {
      type: 'info',
      url,
      action: 'view',
      label: notificationType
    },
    // Unclear if these works, app uses data.url right now
    'webpush': {
      'fcm_options': {
        'link': url,
        'analytics_label': notificationType
      }
    },
    // SL Backend Props:
    locationId: location.fsid,
    event: `${transaction.id}:notified`
  }
}

module.exports = transformInvoiceToNotificationData
