const draftAdjustmentId = require('./tools/draft-adjustment')
const { findById } = require('./shipment-find')

const saveAdjustments = async (state, shipmentOrSnapshotId) => {
  const shipment = await findById(state, shipmentOrSnapshotId)
  if (shipment.status !== 'received') {
    throw new Error('Can\'t adjust a shipment that is not marked as "received"')
  }

  const draftId = draftAdjustmentId(shipmentOrSnapshotId)
  const draftDoc = await state.dal.shipment.getById(state, draftId, { catch404: true })

  // If there's no draft to save, just let the user move on
  if (!draftDoc) {
    return shipment
  }

  const adjustmentDate = new Date().toJSON()
  const adjustmentDoc = Object.assign({}, draftDoc, {
    _id: `${shipment.snapshotId}:change:adjustment:${adjustmentDate}`,
    updatedAt: adjustmentDate,
    effectiveAt: shipment.snapshotDates.received,
    _rev: undefined
  })

  draftDoc._deleted = true

  await state.dal.shipment.write(state, adjustmentDoc)
  await state.dal.shipment.write(state, draftDoc)

  return findById(state, shipmentOrSnapshotId)
}

module.exports = saveAdjustments
