module.exports = addComment

const dateToIdFriendlyTimestamp = require('./tools/date-to-id-friendly-timestamp')

const toCommentId = (snapshotId, createdAt) => {
  const timestamp = dateToIdFriendlyTimestamp(createdAt)
  return `${snapshotId}:comment:${timestamp}`
}

async function addComment (state, snapshotId, comment, signature, name, orderId) {
  if (!(comment || signature || name)) {
    return
  }

  const now = new Date()
  const createdAt = now.toISOString()
  const createdBy = state.user.name
  const _id = toCommentId(snapshotId, now)

  const doc = {
    _id,
    type: 'snapshot-comment',
    version: '1.0.0',
    createdAt,
    createdBy
  }

  if (comment) {
    doc.comment = comment
  }

  if (signature) {
    doc.signature = signature
  }

  if (name) {
    doc.name = name
  }

  if (orderId) {
    doc.orderId = orderId
  }

  await state.dal.shipment.write(state, doc)

  const response = {
    _id,
    snapshotId,
    createdAt,
    createdBy,
    comment,
    signature,
    name
  }
  if (orderId) {
    response.orderId = orderId
  }
  return response
}
