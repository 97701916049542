import React from 'react'
import PropTypes from 'prop-types'

import { Banner } from '@fielded/shared-ui'

import Header from './Header'

const NoReportEntry = ({onClickBack, ...otherProps}) => {
  return (
    <div>
      <Header
        {...otherProps}
        backButtonLabel='go back'
        onClickSave={onClickBack}
      />
      <Banner>No products at this location in this week…</Banner>
    </div>
  )
}

NoReportEntry.propTypes = {
  programName: PropTypes.string.isRequired,
  serviceName: PropTypes.string,
  location: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  onClickBack: PropTypes.func.isRequired
}

export default NoReportEntry
