const { makeDiscountedPrice } = require('./make-discounted-price')

/**
 * get price/maxPrice by date
 *
 * this filters to only check prices that contains the
 * passed type(price/maxPrice) param before getting by nearest date
 *
 * @param prices: Array<{price: number, date: ISOString, maxPrice: number}>
 * @param date = new Date().toJSON()
 * @param type: 'price' | 'maxPrice' = 'price'
 * @return number|string
 */
module.exports = (prices = [], date = new Date().toJSON(), type = 'price') => {
  if (typeof date !== 'string' || date.length !== 24) {
    throw new Error('expecting date as a valid json string')
  }

  if (!Array.isArray(prices)) {
    // This happens when importing PSM products
    return
  }

  const referenceDate = new Date(date)

  const [activePrice = {}] = prices.slice()
    // This line filters out null or undefined prices but also prices equal to 0,
    // this can be problem if at some point we want to have free products. Or
    // maybe it could work if we set a price and apply a 100 % discount?
    .filter(price => !!price[type])
    .filter(price => {
      return new Date(price.date) <= referenceDate
    })
    .sort((a, b) => {
      const aDate = new Date(a.date)
      const bDate = new Date(b.date)
      return Math.abs(aDate - referenceDate) > Math.abs(bDate - referenceDate) ? 1 : -1
    })

  // In case type is 'price' and we have a price with discount,
  // we return a javascript object with metainfo
  if (type === 'price' && activePrice['price'] && activePrice['discount']) {
    return makeDiscountedPrice(activePrice['price'], activePrice['discount'])
  } else { // In case we just have a price entry with no discount we simply return it
    return activePrice[type]
  }
}
