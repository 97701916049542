import React from 'react'

/*

Qty

Takes a number as props.quantity and formats it with thousand-separators.
Source: https://stackoverflow.com/a/2901298

*/

const Qty = ({ quantity, className, showZero }) => {
  let formattedQuantity = null
  if (typeof quantity === 'undefined' || isNaN(quantity) || quantity === null) {
    formattedQuantity = showZero ? 0 : '-'
  } else {
    formattedQuantity = quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return <span className={className}>{formattedQuantity}</span>
}

export default Qty
