const { updateSnapshot } = require('./snapshot-update')
const isEqual = require('lodash/isEqual')

exports.updateOrdersOnProduct = function ({
  locationQuantities,
  orders,
  status,
  user,
  productId,
  timestamp = new Date().toJSON()
}) {
  return orders.map(order => {
    const parentSnapshot = order.snapshotsByStatus[status].length
      ? order.snapshotsByStatus[status][0]
      : null

    if (!parentSnapshot) {
      throw new Error(`Error updating order: existing order for status ${status} does not exist.`)
    }

    if (!parentSnapshot.products[productId]) {
      throw new Error(`Error updating order: product id ${productId} does
          not exist on order ${order.orderId} at destination ${order.destinationId}.`)
    }

    // This is OK, updating just a couple locations would mean not updating every order.
    if (locationQuantities[order.destinationId] === undefined) {
      return
    }

    const products = {[productId]: locationQuantities[order.destinationId]}
    const updatedSnapshot = updateSnapshot({parentSnapshot, products, user, timestamp})
    const changed = !isEqual(updatedSnapshot.products, parentSnapshot.products)

    if (changed) return updatedSnapshot
  })
    .filter(x => x)
}
