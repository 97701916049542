const PGAdapter = require('../../common/pg-adapter')
const isPGUsageErrorCode = require('../../common/isPGUsageErrorCode')
const {
  getWhereClause,
  getOrderByClause
} = require('../../tools/sql-tools')

const { Format, serialize } = require('./serialize-pg-result')

// The product-pg-adapter provides access to the avocado.vw_products table.
// The `getOne` and `getList` methods take an optional `format`
// paramter. The `format` controls the shape of the products returned.
// See the `./serialize-pg-result` file for format options.

const columns = [
  'product_id',
  'order_sku',
  'market',
  'product',
  'category',
  'inventory_sensitivity',
  'display_order',
  'phase',
  'available',
  'ready',
  'promoted',
  'ppmv',
  'ppmv_core',
  'therapeutic_class',
  'api',
  'substitutes',
  'manufacturer',
  'distributor',
  'supplier',
  'presentation_packaging',
  'visibility_1',
  'markup_1',
  'segments',
  'presentation_id',
  '_deleted'
]

const searchColumns = [
  'product',
  'product_id'
]

// Postgres function to do bankers rounding or like its also called ROUND_HALF_EVEN
// Implementation copied from:
// https://stackoverflow.com/questions/22613702/postgresql-roundv-numeric-s-int/30546518#30546518
const ROUND_HALF_EVEN_FN = `
  create or replace function pg_temp.round_half_even(val numeric, prec integer)
    returns numeric
  as $$
  declare
    retval numeric;
    difference numeric;
    even boolean;
  begin
    retval := round(val,prec);
    difference := retval-val;
    if abs(difference)*(10::numeric^prec) = 0.5::numeric then
      even := (retval * (10::numeric^prec)) % 2::numeric = 0::numeric;
      if not even then
        retval := round(val-difference,prec);
      end if;
    end if;
    return retval;
  end;
  $$ language plpgsql immutable strict;
`

class ProductPGAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    super(pgConnection, 'avocado.vw_products', username, columns, 'product_id', {}, logger, {searchColumns})
    this.Format = Format
  }

  // Overwrite `PGAdapter::getOne` to include prices in result
  async getOne (id, { whereCondition = this.idColumn, format = Format.Postgres } = {}) {
    if (!this.columns.includes(whereCondition)) {
      throw new Error(`Column ${whereCondition} not allowed`)
    }
    const query = `
      SELECT product.*,
        (
          SELECT json_agg(
            json_build_object(
              'date', price_history.date,
              'sellprice', pg_temp.round_half_even(price_history.sellprice, 2),
              'buyprice', pg_temp.round_half_even(price_history.buyprice, 2),
              'discount', pg_temp.round_half_even(price_history.discount, 2),
              'financingMarkup', pg_temp.round_half_even(price_history.markup * markup.financing_multiplier * (price_history.sellprice / (1 + price_history.markup + markup.financing_multiplier * price_history.markup + markup.delivery_multiplier * price_history.markup)), 2),
              'deliveryMarkup', pg_temp.round_half_even(price_history.markup * markup.delivery_multiplier * (price_history.sellprice / (1 + price_history.markup + markup.financing_multiplier * price_history.markup + markup.delivery_multiplier * price_history.markup)), 2)
            )
            ORDER BY price_history.date ASC
          ) as prices
          FROM real.price_history price_history
          LEFT JOIN avocado.data_markupmultiplierhistory markup
            ON price_history.date = markup.date
            AND (markup.market_id = ANY(market.aliases) OR markup.market_id = market.id)
          WHERE price_history.sku = product.product_id
        )
      FROM avocado.vw_products product
      JOIN avocado.data_market market ON product.market = market.product_market_code
      WHERE ${whereCondition} = $1
    `
    let result
    try {
      await this.pgConnection.query('BEGIN')
      await this.pgConnection.query(ROUND_HALF_EVEN_FN)
      result = await this.pgConnection.query(query, [id])
      await this.pgConnection.query('COMMIT')
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
    if (result.rows.length === 0) {
      const err = new Error('Row not found')
      err.status = 404
      throw err
    }
    const row = serialize(result.rows, format)[0]
    return row
  }

  // Overwrite `PGAdapter::getList` to include prices in result
  async getList ({ ordering = this.idColumn, filter = {}, limit = 50, offset = 0, format = Format.Postgres, search = null } = {}) {
    // todo: a lot of this is copied from pg-adapter and could probably be replaced
    // by customizing the pg-adapter `tableExpression` and moving the function to the schema
    const searchOpts = search && {
      text: search,
      columns: this.searchColumns
    }
    const { whereText, whereValues } = getWhereClause({
      filter,
      preparers: {
        market: (key, value) => ([
          // Special case NAS products until this is resolved:
          // https://github.com/fielded/field-supply/issues/5123
          { statement: `
              (product_id LIKE '3___' AND %INDEX% = 'nas') OR
              (product_id NOT LIKE '3___' AND (market = %INDEX% OR %INDEX% = ANY(market_aliases)))
            `,
          value }
        ])
      },
      search: searchOpts
    })
    const sortText = getOrderByClause(ordering, this.columns)
    const values = [...whereValues, limit, offset]
    // Get products with json array of prices
    const query = `
      WITH aliases as (
        SELECT
          p.product_id,
          json_agg(
            json_build_object(
              'market', g.market,
              'sku', g.product_id
            )
          ) as alias
        FROM avocado.vw_products p
        JOIN avocado.vw_products g
          ON g._deleted is false
          AND g.presentation_id = p.presentation_id
          AND g.product_id <> p.product_id
        WHERE p._deleted is false
        GROUP BY 1
      ),
      vw as (
        SELECT
          product.*,
          market.aliases as market_aliases,
          (
            SELECT json_agg(
              json_build_object(
                'date', price_history.date,
                'sellprice', pg_temp.round_half_even(price_history.sellprice, 2),
                'buyprice', pg_temp.round_half_even(price_history.buyprice, 2),
                'discount', pg_temp.round_half_even(price_history.discount, 2),
                'financingMarkup', pg_temp.round_half_even(price_history.markup * markup.financing_multiplier * (price_history.sellprice / (1 + price_history.markup + markup.financing_multiplier * price_history.markup + markup.delivery_multiplier * price_history.markup)), 2),
                'deliveryMarkup', pg_temp.round_half_even(price_history.markup * markup.delivery_multiplier * (price_history.sellprice / (1 + price_history.markup + markup.financing_multiplier * price_history.markup + markup.delivery_multiplier * price_history.markup)), 2)
              )
              ORDER BY price_history.date ASC
            ) as prices
            FROM real.price_history price_history
            LEFT JOIN avocado.data_markupmultiplierhistory markup
              ON price_history.date = markup.date
              AND (markup.market_id = ANY(market.aliases) OR markup.market_id = market.id)
            WHERE price_history.sku = product.product_id
          ),
        aliases.alias
        FROM avocado.vw_products product
        LEFT JOIN aliases on product.product_id = aliases.product_id
        JOIN avocado.data_market market ON product.market = market.product_market_code
      )
      SELECT * FROM vw
      ${whereText}
      ${sortText}
      LIMIT $${values.length - 1} OFFSET $${values.length}
    `
    let rows = []
    let count = 0
    try {
      await this.pgConnection.query('BEGIN')
      await this.pgConnection.query(ROUND_HALF_EVEN_FN)
      const result = await this.pgConnection.query(query, values)
      await this.pgConnection.query('COMMIT')
      rows = result.rows
      count = await this.getCount(whereText, whereValues)
    } catch (err) {
      console.log(err)
      throw err
    }
    const results = serialize(rows, format)
    return {
      results,
      count,
      next: offset + rows.length
    }
  }

  async getCount (whereText = '', whereValues = []) {
    const query = `
      WITH vw as (
        SELECT p.*, m.aliases as market_aliases
        FROM avocado.vw_products p
        JOIN avocado.data_market m ON p.market = m.id OR p.market = ANY(m.aliases)
      )
      SELECT COUNT(*) FROM vw
      ${whereText}
    `
    const result = await this.pgConnection.query(query, whereValues)
    return parseInt(result.rows[0].count)
  }

  async translateMarketSkus ({products, market}) {
    if (!market || !products || !products.length) {
      return {}
    }

    const query = `
      WITH sku_presentation AS (
        SELECT
          old_sku_id as sku,
          presentation_id
        FROM avocado.data_productsku p
        WHERE p.old_sku_id = ANY($1::text[])
          AND p._deleted = false
      ),
      market_translation AS (
        SELECT
          sp.sku,
          cm.sku as translated_sku
        FROM sku_presentation sp
        LEFT join lateral (
          SELECT ps2.old_sku_id AS sku
          from avocado.data_productsku ps1
          INNER JOIN avocado.data_productsku ps2
            ON ps2.market_id = (SELECT id FROM avocado.data_market WHERE state = $2 LIMIT 1)
            AND ps2.presentation_id = ps1.presentation_id
          WHERE ps1.old_sku_id = sp.sku
          ORDER BY ps2._deleted nulls first,
                  ps2.visibility = 'Discontinued'
          LIMIT 1
        ) cm ON  true
      )
      SELECT json_object_agg(mt.sku, mt.translated_sku) AS translations
      FROM market_translation mt;
    `

    let result = {}
    try {
      const { rows } = await this.pgConnection.query(query, [products, market])
      if (rows.length) {
        result = rows[0].translations
      }
    } catch (e) {
      if (isPGUsageErrorCode(e.code)) {
        e.status = 400
      }
      throw e
    }
    return result
  }

  async create () {
    throw new Error('Create not implemented')
  }

  async update () {
    throw new Error('Update not implemented')
  }

  async upsert () {
    throw new Error('Upsert not implemented')
  }

  async delete () {
    throw new Error('Delete not implemented')
  }
}

module.exports = {
  Format,
  ProductPGAdapter
}
