const pick = require('lodash/pick')
const {
  RemitaInterface,
  RemitaMockedInterface,
  SafaricomInterface,
  PaystackInterface
} = require('./data-access')

const PaymentMethodAdapter = require('./payment-method-adapter')
const PaymentDebitAdapter = require('./payment-debit-adapter')
const PaymentHistoryAdapter = require('./payment-history-adapter')
const RemitaMandateAdapter = require('./remita-mandate-adapter')
const PaygCreditPaymentAdapter = require('./payg-credit-payment-adapter')
const PaymentAdapter = require('./data-payment/adapter')
const PayStackWebAdapter = require('./data-access/paystack/web')
const { isNoDatabaseUser } = require('../user/tools')
const { parse } = require('../tools/smart-id')
const { CURRENCY_BY_COUNTRY } = require('./constants')

// api.payment is a namespace. It has only one table, paymentMethod
// available on api.payment.paymentMethod level.
// Plan is to add api.payment.debit table as well.
class PaymentApi {
  constructor (
    state,
    {
      isomorphicFetch,
      remitaApiConfig,
      safaricomApiConfig,
      logger,
      pgConnection,
      agaveAdapter,
      paystackSecrets,
      paystackWebConfigs,
      quickbooksApi,
      invoicesApi
    }
  ) {
    const { user = {} } = state
    const username = user.name

    this.user = user
    this.agaveAdapter = agaveAdapter

    if (remitaApiConfig) {
      const RemitaInterfaceConstructor = remitaApiConfig.useRemitaMockInterface
        ? RemitaMockedInterface
        : RemitaInterface

      this.remitaInterface = new RemitaInterfaceConstructor(
        remitaApiConfig,
        isomorphicFetch,
        logger
      )
    }

    if (safaricomApiConfig && safaricomApiConfig.clientUsesSafaricomConfig) {
      this.safaricomInterface = new SafaricomInterface(
        safaricomApiConfig,
        isomorphicFetch,
        logger
      )
    }

    if (paystackSecrets) {
      this.paystackInterface = new PaystackInterface(
        paystackSecrets,
        isomorphicFetch,
        logger
      )
    }

    if (pgConnection) {
      this.paymentMethod = new PaymentMethodAdapter(
        pgConnection,
        username,
        this.remitaInterface,
        this.safaricomInterface,
        user,
        agaveAdapter
      )

      this.paymentDebit = new PaymentDebitAdapter(
        pgConnection,
        username,
        this.remitaInterface,
        this.safaricomInterface,
        this.paystackInterface,
        this.paymentMethod
      )

      this.remitaMandate = new RemitaMandateAdapter(
        pgConnection,
        username,
        this.remitaInterface,
        this.safaricomInterface
      )

      // TODO don't think this is needed?
      this.paymentHistory = new PaymentHistoryAdapter(
        pgConnection,
        username,
        this.remitaInterface,
        this.safaricomInterface
      )
    }

    this.paygCreditPayment = new PaygCreditPaymentAdapter(
      state,
      agaveAdapter,
      pgConnection,
      logger
    )

    this.paymentAdapter = new PaymentAdapter(
      state,
      {
        agaveAdapter,
        pgConnection,
        quickbooksApi,
        invoicesApi,
        logger
      }
    )

    if (paystackWebConfigs && !isNoDatabaseUser(this.user)) {
      const parsedLocation = parse(this.user.location.id)
      this.paystackWebAdapter = new PayStackWebAdapter({
        publicKey: paystackWebConfigs[parsedLocation.country].publicKey,
        currency: CURRENCY_BY_COUNTRY[parsedLocation.country]
      })
    }
  }

  // create function with create transaction depends on payment provider
  // currently only paystack is supporting
  async create (data, cb) {
    if (typeof window === 'undefined') {
      throw new Error('Action allows only thru web app')
    }
    switch (data.paymentProvider) {
      case 'paystack':
        return this.paystackWebAdapter.create(pick(data, ['amount', 'metadata', 'email']), cb)
      default:
        throw new Error(`${data.paymentProvider} not supported`)
    }
  }

  getRetailerPaymentMethod (locationId) {
    return this.agaveAdapter.get(`paymentmethod/retailer/${locationId || this.user.location.id}`)
  }

  cancelPaymentDebit (locationId) {
    return this.agaveAdapter.fetcher(`paymentdebit?locationId=${locationId || this.user.location.id}`, {method: 'delete'})
  }
}

module.exports = PaymentApi
