const parsePattern = (pattern, separator) => {
  const parsePatternField = field => {
    const parts = field.split('?')

    if (parts[0] !== '') {
      return {
        key: field,
        isOptional: false
      }
    }

    return {
      key: parts[1],
      isOptional: true
    }
  }

  return pattern
    .split(separator)
    .map(parsePatternField)
}

const isValid = value =>
  typeof value !== 'undefined' && value !== null && value !== ''

module.exports = (object, pattern, separator = ':') => {
  if (!(object && pattern)) {
    return ''
  }

  const patternFields = parsePattern(pattern, separator)

  return patternFields.reduce((id, field) => {
    const value = object[field.key]
    if (value && isValid(value)) {
      return id + (id.length ? separator + field.key : field.key) + separator + value
    }

    if (!field.isOptional) {
      throw new Error('could not generate id, missing field ' + field.key)
    }

    return id
  }, '')
}
