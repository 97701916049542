const getPrice = require('./get-price')

module.exports = {
  construct
}

const getCode = id => (id.split('product:')[1] || '').toUpperCase()

const validate = (product = {}) => {
  const isValidId = id => /product:[a-zA-Z0-9._%+-]+/.test(id)

  return (product.name || product.fullName) && product._id && isValidId(product._id)
}

/**
 * construct
 *
 * Make product doc return a specific fields irrespective of the vertical
 *
 * @param doc any product doc for SL, PSM or VAN
 * @param options: Object<{now: DateISOString}>
 * @returns doc
 */
function construct (product, { now = new Date().toISOString() } = {}) {
  if (!validate(product)) {
    throw new Error(`Can't construct invalid product, id: ${(product || {})._id}`)
  }

  const links = product.links || {}

  if (product.vspmd) {
    links.vspmd = product.vspmd
  }

  const constructedProduct = {
    _id: product._id,
    basicUnit: product.basicUnit || product.measurement || 'units',
    code: product.code || getCode(product._id),
    createdAt: product.createdAt || now,
    createdBy: product.createdBy || 'fieldsupply',
    description: product.description || product.manufacturer || '',
    fullName: product.fullName || product.name,
    name: product.name || '',
    presentation: Number(product.presentation) || 1,
    prices: product.prices || [],
    buyPrices: product.buyPrices || [],
    vats: product.vats || [],
    productType: product.productType || 'general',
    services: product.services || [],
    storageType: product.storageType || '',
    tracer: product.tracer || false,
    surveys: product.surveys || {},
    type: product.type || 'product',
    unitOfIssue: Number(product.unitOfIssue) || 1,
    updatedAt: product.updatedAt || now,
    updatedBy: product.updatedBy || 'fieldsupply',
    // additionalData: for  keeping props that are not gauranteed to exist across tenants
    additionalData: product.additionalData || {},
    links
  }

  if ('active' in product) {
    constructedProduct.active = product.active
  }

  if ('eligibleForPAYS' in product) {
    constructedProduct.eligibleForPAYS = product.eligibleForPAYS
  }

  if ('eligibleForOD' in product) {
    constructedProduct.eligibleForOD = product.eligibleForOD
  }

  if ('segments' in product) {
    constructedProduct.segments = product.segments
  }

  const unitPrice = getPrice(product.prices, now)
  if (unitPrice || unitPrice === 0) {
    constructedProduct.unitPrice = getPrice(product.prices, now)
  }

  if ('volume' in product) {
    constructedProduct.volume = product.volume || ''
  }

  // these are meta values currently used by PSM only
  ;['genericParent', 'genericFactor', 'alias', 'unitWeight', 'unitOfReporting', 'unitVolume', 'commodityType', 'tracerGroup'].forEach((key) => {
    if (product[key]) {
      constructedProduct[key] = product[key]
    }
  })

  if (product._rev) {
    constructedProduct._rev = product._rev
  }
  return constructedProduct
}
