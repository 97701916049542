exports.eventToDoc = ({ facilityId, userId, documentId, documentVersion, data, createdBy }) => {
  const now = new Date().toISOString()
  const doc = {
    _id: eventToDocId({ facilityId, userId, documentId, date: now }),
    version: '1.0.0',
    type: 'documentEvent',
    documentId,
    documentVersion,
    createdAt: now,
    createdBy
  }

  if (facilityId) {
    doc.facilityId = facilityId
  }

  if (userId) {
    doc.userId = userId
  }

  if (data) {
    doc.data = data
  }
  return doc
}

exports.eventToDocId = eventToDocId
function eventToDocId ({ facilityId, userId, documentId, date = new Date().toISOString() }) {
  let key
  if (facilityId && !userId) {
    key = facilityId
  } else if (!facilityId && userId) {
    key = userId
  } else {
    // The reason to not allow this is to avoid confusion:
    // if a doc belongs to both, i'd expect it to show up both
    // when getting "all docs for userX"
    // and when getting "all docs for facility X"
    // Don't have a good way of doing that right now, if you can get along with that,
    // please go ahead if you need it:
    throw new Error('A document has to be keyed by either facilityId or userId, not both')
  }

  return `documentEvent:${key}:documentId:${documentId}:date:${date}`
}
