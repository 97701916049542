const getParentLocation = require('./../../../utils/get-parent-location')
const { get } = require('./get')

exports.getParentId = async function (state, locationId) {
  if (!locationId) {
    throw new Error('missing mandatory argument locationId')
  }

  // this returns nothing if the location doesn't exist
  // in which case getParentLocation falls back to id
  const location = await get(state, locationId)

  return getParentLocation(locationId, location)
}
