import React, { Fragment } from 'react'
import ShelfLifeLogo from '../ShelfLifeLogo'

const PrintTemplateHeader = ({
  title,
  config
}) => {
  const isShelfLife = !config || config.app === 'shelflife'
  const logoUrl = config.logoUrlWhite || config.logoUrl

  return (
    <div className='print-template__header vs-u-align-center'>
      <h1 className='print-template__title'>
        {title}
      </h1>
      {isShelfLife ? (
        <Fragment>
          <ShelfLifeLogo fill='#fff' className='print-template__logo print-template__logo--sl' />
        </Fragment>
      ) : (
        <Fragment>
          <span className='vs-u-visually-hidden'>
            {config.name}
          </span>
          {logoUrl && (
            <img src={logoUrl} alt={`${config.name} Logo`} className='print-template__logo' />
          )}
        </Fragment>
      )}
    </div>
  )
}

export default PrintTemplateHeader
