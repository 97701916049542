import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Button } from '@fielded/shared-ui'

import VersionInfo from '../../common/VersionInfo'

const MainMenuUserDetails = ({
  hasUser,
  isExpanded,
  userName,
  termsAndConditionsDocs,
  onToggleUserDetails,
  onRequestLogout
}) => {
  return (
    <div
      className={classnames(
        'main-menu__info',
        {'main-menu__info--expanded': isExpanded}
      )}
    >
      {hasUser && (
        <div>
          <div className='main-menu__info-label'>Logged in<span className='main-menu__show-on-expanded'> as:</span></div>
          <button
            className={classnames(
              'main-menu__info-toggle',
              {'main-menu__info-toggle--expanded': isExpanded}
            )}
            onClick={onToggleUserDetails}
            title={userName}
          >
            <span className='main-menu__info-toggle-content'>
              <span className='main-menu__info-user-name main-menu__show-on-expanded'>
                {userName}
              </span>
            </span>
          </button>
          {isExpanded && (
            <Fragment>
              <div className='main-menu__info-details'>
                <Button
                  component={Link}
                  onClick={onRequestLogout}
                  fill='outline'
                  colorVariant='light'
                  size='small'
                >
              Logout
                </Button>
                <VersionInfo />
              </div>
              {termsAndConditionsDocs.length > 0 && (
                <div className='main-menu__legal-links'>
                  {termsAndConditionsDocs.map(document =>
                    <Link
                      to={document.path}
                      key={document.path.replace(/\//, '')}
                      target='_blank'
                    >
                      {document.shortTitle}
                    </Link>
                  )}
                </div>
              )}
            </Fragment>
          )}
        </div>
      )}
    </div>
  )
}

MainMenuUserDetails.propTypes = {
  /**
   * If the details are visible
   */
  isExpanded: PropTypes.bool,
  /**
  * Username to display
  */
  userName: PropTypes.string,
  /**
  * List of docs to display
  */
  termsAndConditionsDocs: PropTypes.array,
  /**
  * Function to toggle user details
  */
  onToggleUserDetails: PropTypes.func,
  /**
  * Function to trigger the logout prompt
  */
  onRequestLogout: PropTypes.func
}

export default MainMenuUserDetails
