import { SORT_DIRECTON } from '@fielded/shared-ui/src/utils/constants'

const getSortDirection = (currentSortBy, newSortBy, currentDirection) => {
  const changeSort = () => {
    let newSortDirection = SORT_DIRECTON.ASC
    // If user clicks same sort header twice, we want to change sort direction
    // If they click new header, we want to go back to ASC
    if (newSortBy === currentSortBy) {
      newSortDirection = currentDirection === SORT_DIRECTON.ASC ? SORT_DIRECTON.DESC : SORT_DIRECTON.ASC
    }

    return newSortDirection
  }

  return changeSort
}
export default getSortDirection
