// # TEAM MELON
const CC_EMAILS = [
  'chiamaka@field.partners',
  'folasade@field.partners',
  'wole@field.partners',
  'sandra@field.partners',
  'esule@field.partners'
]

class FreshDeskAdapter {
  constructor (freshDeskConfigs = {}, isomorphicFetch, pgConnection, logger) {
    this.pgConnection = pgConnection
    this.logger = logger

    if (!freshDeskConfigs.url) {
      freshDeskConfigs.url = 'https://fielded.freshdesk.com/api/v2'
    }
    this.apiUrl = freshDeskConfigs.url
    this.apiKey = freshDeskConfigs.apiKey
    this.isomorphicFetch = isomorphicFetch
  }

  async doRequest (endpoint, method = 'GET', body, headers) {
    const url = `${this.apiUrl}/${endpoint}`
    const base64encodedData = Buffer.from(this.apiKey + ':' + 'X').toString('base64')
    const options = {
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        Authorization: `Basic ${base64encodedData}`,
        ...headers
      },
      method
    }
    if (body) {
      Object.assign(options, {body: JSON.stringify(body)})
    }

    const response = await this.isomorphicFetch(url, options)
    return response.json()
  }
}

class FreshDeskApi {
  constructor (freshDeskConfigs = {}, isomorphicFetch, pgConnection, logger) {
    this.freshDeskAdapter = new FreshDeskAdapter(freshDeskConfigs, isomorphicFetch, pgConnection, logger)
    this.pgConnection = pgConnection
    this.logger = logger
  }

  async createTicket ({email, description, subject}) {
    const body = {
      email,
      description,
      subject,
      cc_emails: CC_EMAILS,
      status: 2,
      priority: 1
    }
    const response = await this.freshDeskAdapter.doRequest('tickets', 'POST', body)
    return response
  }
}

module.exports = FreshDeskApi
