const { EntityApi } = require('../common')
const get = require('lodash/get')

class DepositsApiAdapter {
  constructor (restAdapter, locationApi, logger) {
    this.restAdapter = restAdapter
    this.locationApi = locationApi
  }

  async getUserDeposits (userLocation) {
    const uuid = get(await this.locationApi.get(userLocation), 'additionalData.uuid')
    if (!uuid) {
      throw new Error(`location ${userLocation} missing uuid`)
    }

    const queryParams = {
      location_id: uuid
    }

    try {
      const { results } = await this.restAdapter.get('deposit/', queryParams)
      return results
    } catch (e) {
      // 404 means deposit hasn't been set up, so therefor is not an error.
      return null
    }
  }
}

class DepositsApi extends EntityApi {
  constructor (state, restAdapter, locationApi, logger) {
    const DepositsAdapter = new DepositsApiAdapter(restAdapter, locationApi, logger)
    super(DepositsAdapter)
    this.user = state.user
    this.logger = logger
  }

  async getUserDeposit (userLocation = this.user.location.id) {
    let deposits
    try {
      deposits = await this.adapter.getUserDeposits(userLocation)

      if (deposits.length > 1) {
        // if there is more than one deposit per location, something has gone wrong...
        this.logger.error(`More than one deposit returned for ${userLocation}`)
      }
      return deposits[0]
    } catch (e) {
      this.logger.error(e)
    }
  }
}

module.exports = DepositsApi
