const allowedStatus = ['pre-advice', 'new']
const { findById } = require('./shipment-find')

async function reschedule (state, id, delay) {
  if (!id || !delay) {
    const error = new Error('Shipments-reschedule: missing required parameters')
    throw error
  }

  // First get the full shipment so we can check the current status
  let shipment
  try {
    shipment = await findById(state, id)
  } catch (e) {
    throw new Error('Shipments-reschedule: shipment not found')
  }

  if (!allowedStatus.includes(shipment.status)) {
    const error = new Error('Shipments-reschedule: shipment status does not allow rescheduling')
    throw error
  }

  // Next get the snapshot doc and modify it
  const originalShipmentDoc = await state.dal.shipment.getById(state, shipment.snapshotId)

  const lastUpdate = originalShipmentDoc.updatedAt || originalShipmentDoc.createdAt
  const today = new Date().toISOString()
  const updatedShipmentDoc = {
    ...originalShipmentDoc,
    scheduledDate: delay.split('T')[0],
    updatedAt: today,
    previousScheduledDates: shipment.previousScheduledDates || []
  }

  updatedShipmentDoc.previousScheduledDates.push({
    updatedAt: lastUpdate,
    date: shipment.scheduledDate || shipment.date
  })
  await state.dal.shipment.write(state, updatedShipmentDoc)

  // Finally return the updated shipment
  return findById(state, id)
}

module.exports = {
  reschedule
}
