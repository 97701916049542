import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import NumberFormat from '../NumberFormat'
import { removeDelimiters } from '../../utils'

const defaultOnChange = () => null

/**
 *  Quantity field with number formatting
 */
class QuantityInput extends React.Component {
  static propTypes = {
    /**
     * Field name (if not quantity)
     */
    name: PropTypes.string,

    /**
     * What's the min suggested quantity?
     */
    min: PropTypes.number,

    /**
     * Set the placeholder, to indicate input type to user.
     */
    placeholder: PropTypes.string,

    /**
     * Align value on the right side
     */
    textAlign: PropTypes.oneOf(['left', 'right']),

    /**
     * A size of an input field
     */
    size: PropTypes.oneOf(['small', 'regular']),

    /**
     * The current quantity
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * The initial value of the field
     */
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Runs on input changes, returns the quantity
     * as a cleaned-up number (no delimiters)
     * */
    onQuantityChange: PropTypes.func,

    /**
     * Runs on input change, returns raw input value
     *  (including delimiters)
     */
    onChange: PropTypes.func,

    /**
     * Set to true of this field should be marked as having issues
     */
    hasError: PropTypes.bool,

    /**
     * Custom class name, to be used for e.g. sibling selector styling based on focus
     */
    className: PropTypes.string,
    /**
     * Whether or not the input should be disabled
     */
    disabled: PropTypes.bool
  }

  static defaultProps = {
    name: 'quantity',
    min: 0,
    placeholder: '0',
    textAlign: 'left',
    size: 'regular',
    defaultValue: '',
    onQuantityChange: null,
    onChange: defaultOnChange,
    hasError: false,
    className: undefined,
    disabled: false
  }

  updateQuantity = (e) => {
    const { onQuantityChange } = this.props

    const rawFieldValue = e.target.value
    const cleanedFieldValue = removeDelimiters(rawFieldValue)
    const newQuantity = parseInt(cleanedFieldValue, 10)

    onQuantityChange(newQuantity)
  }

  render () {
    const {
      name,
      min,
      placeholder,
      textAlign,
      size,
      value,
      defaultValue,
      onQuantityChange,
      onChange,
      hasError,
      disabled,
      className,
      ...otherProps
    } = this.props

    let fireOnChange = onChange
    if (onQuantityChange) {
      fireOnChange = this.updateQuantity
    }

    const valueOrZero = value === 0 ? '0' : value
    const defaultValueOrZero = defaultValue === 0 ? '0' : defaultValue

    return (
      <NumberFormat
        className={classnames(
          'vs-quantity-input',
          'vs-text-input',
          { 'vs-text-input--text-align-right': textAlign === 'right' },
          { 'vs-text-input--has-error': hasError },
          className,
          { 'vs-quantity-input--small': size === 'small' }
        )}
        min={min}
        placeholder={placeholder}
        value={defaultValue ? valueOrZero : valueOrZero || ''}
        defaultValue={defaultValueOrZero || ''}
        name={name}
        type='tel'
        thousandSeparator
        onChange={fireOnChange}
        disabled={disabled}
        {...otherProps}
      />
    )
  }
}

export default QuantityInput
