const PGAdapter = require('../common/pg-adapter')
const isPGUsageErrorCode = require('../common/isPGUsageErrorCode')
const { getPositionalArgs } = require('../tools/sql-tools')

class LocationPGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    const columns = [
      'fsid',
      'uuid',
      'market',
      'name_of_pharmacy',
      'visit_day',
      'level',
      'classification',
      'zone',
      'city',
      'state',
      'country',
      'lat',
      'long',
      'location_type',
      'active',
      'code',
      'service_fee',
      'supply_weeks',
      'payment_terms',
      'route',
      'mobilized_date',
      'mobilized',
      'address',
      'alt_phone_number',
      'email',
      'notes',
      'owners_name',
      'owners_phone_number',
      'premise_license_number',
      'registration_number',
      'orders_status',
      'superintending_pharmacist',
      'updated_by',
      'company',
      'gs_id',
      'airtime',
      'area_rating',
      'blood_sugar',
      'bpm',
      'clients_vision',
      'clinical',
      'cosmetics',
      'counseling',
      'date_of_first_contact',
      'density_rating',
      'fmcg',
      'food',
      'lead_time_to_decision',
      'mobile_money',
      'monthly_revenue_estimate',
      'months_in_operation',
      'nearby_cosmetics',
      'nearby_cstore',
      'nearby_electronics',
      'nearby_grocery',
      'nearby_restaurant',
      'open_lead_days',
      'opportunity_weight',
      'other_dx',
      'primary_market',
      'primary_source_of_footfall',
      'sl_decision',
      'supply_chain_level'
    ]
    const opts = {
      addUuid: true,
      searchColumns: ['name_of_pharmacy'],
      getRelated: {
        'supplies': {
          relatedTable: 'avocado.data_locationsupply',
          pk: 'uuid',
          fk: 'supply_from_id'
        },
        'supplied_by': {
          relatedTable: 'avocado.data_locationsupply',
          pk: 'uuid',
          fk: 'supply_to_id'
        }
      }
    }

    super(pgConnection, 'avocado.data_location', username, columns, 'uuid', {}, logger, opts)
  }

  async getOneWithMarketData (id, { whereCondition = this.idColumn, tableExpression } = {}) {
    const query = `SELECT * FROM ${tableExpression || this.tableName} l
        LEFT JOIN "avocado"."data_market" m ON l.market ilike any (m.aliases)
        WHERE ${whereCondition} = $1
    `
    let row
    try {
      const { rows } = await this.pgConnection.query(query, [id])
      row = rows[0]
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
    if (!row) {
      const err = new Error('Row not found')
      err.status = 404
      throw err
    }

    return row
  }

  /**
   * Produce a list of every relationship that supplies from any `from` locations or to any `to` locations.
   * @param from - array of UUIDs
   * @param to - array of UUIDs
   * @returns {Promise<{results: *}>} - returns both UUIDs and FSIDs
   */
  async getBulkLocationsSupply ({ from = [], to = [] }) {
    const text = `
      SELECT supply_from_location.uuid as supply_from_uuid,
             supply_to_location.uuid   as supply_to_uuid,
             supply_from_location.fsid as supply_from_fsid,
             supply_to_location.fsid   as supply_to_fsid
      FROM avocado.data_locationsupply as location_supply
             INNER JOIN avocado.data_location supply_from_location
                        ON supply_from_id = supply_from_location.uuid
             INNER JOIN avocado.data_location supply_to_location
                        ON supply_to_id = supply_to_location.uuid
      WHERE supply_from_id = ANY ($1)
         OR supply_to_id = ANY ($2)
    `

    const values = [from, to]
    let { rows: results } = await this.pgConnection.query(text, values)
    return { results }
  }

  async getBulk (selector = '*', whereCondition = this.idColumn, params = []) {
    selector = Array.isArray(selector) ? selector.join(',') : selector
    const dollarSigns = getPositionalArgs(params)
    const query = `
        SELECT ${selector} FROM ${this.tableName} WHERE ${whereCondition} IN (${dollarSigns})
    `
    const { rows: results } = await this.pgConnection.query(query, [...params])
    return { results }
  }

  async getMarketData (alias) {
    let query = `
        SELECT
            *
        FROM
            "avocado"."data_market" m
    `
    const props = []
    if (alias) {
      query += `WHERE $1 ilike ANY(m.aliases)`
      props.push(alias)
    }
    const { rows } = await this.pgConnection.query(query, props)
    return rows
  }

  async getUserForLocationAndEmail (location, email) {
    const query = `
      SELECT u.name, u.email
      FROM avocado.data_user u
      LEFT JOIN avocado.data_location l ON u.location_id = l.uuid
      WHERE LOWER(u.email) = LOWER($1)
      AND REPLACE(l.name_of_pharmacy, ' ', '') ILIKE $2
    `
    const { rows } = await this.pgConnection.query(query, [email, location])
    return rows
  }

  async getLocationBlockingRule (locationId) {
    const query = `
      SELECT
        "blocking_rule"
      FROM
        "avocado"."data_location_blocking_rules"
      WHERE "location_id" = $1
    `
    const { rows } = await this.pgConnection.query(query, [locationId])
    return rows[0]
  }

  async createLocationBlockingRule ({ locationId, blockingRule }) {
    const query = 'insert into "avocado"."data_location_blocking_rules" (location_id, blocking_rule) values ($1, $2)'
    const { rows } = await this.pgConnection.query(query, [locationId, blockingRule])
    return rows[0]
  }

  async deleteLocationBlockingRule (locationId) {
    const query = `DELETE FROM "avocado"."data_location_blocking_rules" WHERE "location_id" = $1`
    const {rows} = await this.pgConnection.query(query, [locationId])
    return rows
  }
}

module.exports = {
  LocationPGAdapter
}
