import React, { useContext } from 'react'
import classnames from 'classnames'

import { Text } from '@fielded/shared-ui'
import { MapMarker, Truck } from '@fielded/shared-ui/src/icons'

import { AuthenticationContext } from '../PSMDeliveryAuthenticationContainer'
import { isWithinDeliveryRadius } from '../../../utils'

const getProximityMessage = ({
  isWithinLocationRadius,
  distance,
  facilityName
}) => {
  let proximityMessage = ''

  if (isWithinLocationRadius) {
    proximityMessage = <Text>You're just <Text tag='span' bold>{distance}km</Text> away from <Text tag='span' bold>{facilityName}</Text>, well within our delivery radius!</Text>
  } else {
    proximityMessage = <Text>You are located <Text tag='span' bold>{distance}km</Text> away from <Text tag='span' bold>{facilityName}</Text>, which is outside the facility's delivery radius. Please head to the facility to deliver the package.</Text>
  }
  return proximityMessage
}

const PSMDeliveryMap = () => {
  const {
    distance,
    facilityName,
    isDeniedPermission
  } = useContext(AuthenticationContext)

  const isWithinLocationRadius = isWithinDeliveryRadius(distance)

  let proximityMessage = ''
  if (isDeniedPermission) {
    proximityMessage = <Text>Oops! It looks like you haven't granted location permission. Please navigate to your <Text tag='span' bold>browser settings</Text> to grant permission, or if you prefer not to, tick the checkbox below to proceed without verifying your location.</Text>
  } else {
    proximityMessage = getProximityMessage({
      distance: Math.ceil(distance),
      isWithinLocationRadius,
      facilityName
    })
  }

  return (
    <section className='delivery-map'>
      <section className={classnames(
        'vs-u-align-center',
        'delivery-map__content',
        {'delivery-map__content--success': isWithinLocationRadius},
        {'delivery-map__content--failed': !isWithinLocationRadius},
        {'delivery-map__content--no-permission': isDeniedPermission}
      )}>
        <Text
          tag='span'
          className={classnames(
            'delivery-map__content-divider-bg',
            {'delivery-map__content-divider-bg--success': isWithinLocationRadius}
          )}
        >
          {'-'.repeat(100)}
        </Text>

        <Text className='delivery-map__content-icon'>
          <Truck direction='right' />
        </Text>

        {isDeniedPermission && (
          <Text className='delivery-map__content-info'>❌</Text>
        )}

        <Text className='delivery-map__content-icon'>
          <MapMarker />
        </Text>
      </section>

      <Text>{proximityMessage}</Text>
    </section>
  )
}

export default PSMDeliveryMap
