module.exports = {
  getUnknownBatchID,
  getTBDBatchID
}

function getUnknownBatchID (productId) {
  return `${productId}:manufacturer:unknown:batchNo:UNKNOWN`
}

function getTBDBatchID (productId) {
  return `${productId}:manufacturer:tbd:batchNo:TBD`
}
