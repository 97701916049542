const get = require('lodash/get')
const { getPrice } = require('../../../product/tools')
const { allocationTypeIsWarning } = require('./error-statuses')
const { ORDER_TYPES } = require('../../constants')
const { getProperRouteIdentifier } = require('./utils')

const DATA_NOT_FOUND_NAME = 'Not Found'
const ALL_SUPPLIERS_ID = 'all-suppliers'
exports.ALL_SUPPLIERS_ID = ALL_SUPPLIERS_ID
const NOT_AVAILABLE_ID = 'not-available'
exports.NOT_AVAILABLE_ID = NOT_AVAILABLE_ID

function getOrderProductInfo (order, productId) {
  const qto = get(order, `products.${productId}.adjusted`, 0)
  const original = get(order, `products.${productId}.original`, 0)
  const adjustment = qto - original
  const prescription = get(order, `products.${productId}.prescription`)
  const allocationType = get(order, `products.${productId}.allocationType`, '')
  const suppliersProcurement = get(order, `products.${productId}.suppliers`, {})
  return {
    qto, adjustment, original, allocationType, suppliersProcurement, prescription
  }
}

exports.getAggregateProductsRows = getAggregateProductsRows
function getAggregateProductsRows (productsById, supplierLedgers, orders) {
  const { orderType, createdAt: orderDate } = orders[0]
  const orderProductsById = orders
    .reduce((acc, order) => {
      const { orderType } = order
      Object.keys(order.products).forEach(productId => {
        const { qto, adjustment, allocationType, prescription } = getOrderProductInfo(order, productId)

        if (!acc[productId]) {
          acc[productId] = {
            productId,
            name: get(productsById, `${productId}.name`, DATA_NOT_FOUND_NAME),
            qto: 0,
            adjustment: 0,
            locationsCount: 0,
            hasWarnings: false
          }
        }

        if (prescription) {
          acc[productId].prescription = prescription
        }

        acc[productId].qto += qto
        acc[productId].adjustment += adjustment
        acc[productId].locationsCount += 1
        if (allocationTypeIsWarning(allocationType) && adjustment === 0 && orderType !== 'resupply') {
          acc[productId].hasWarnings = true
        }
      })
      return acc
    }, {})

  const productRows = Object.values(orderProductsById)

  return productRows.map(row => {
    const { productId, name, qto, hasWarnings, prescription } = row
    const totalSupplierQto = getTotalSupplierQto(orders, productId)
    const {
      suppliers, totalValue, suppliersDisplay, heldUnits, buyPrice, sellPrice
    } = getProductSupplierInfo(
      productsById, supplierLedgers, productId, qto, totalSupplierQto, orderType, orderDate
    )
    const unitPrice = orderType !== ORDER_TYPES.resupply ? sellPrice : buyPrice
    const totalBuyPrice = unitPrice * qto
    return {
      productId,
      name,
      suppliersDisplay,
      suppliers,
      plannedUnits: qto,
      heldUnits,
      unitBuyPrice: unitPrice,
      totalBuyPrice,
      totalValue,
      hasWarnings,
      prescription
    }
  })
}

function getTotalSupplierQto (orders, productId) {
  return orders
    .reduce((acc, order) => {
      const { suppliersProcurement } = getOrderProductInfo(order, productId)
      Object.keys(suppliersProcurement).forEach(supplier => {
        if (!acc[supplier]) {
          acc[supplier] = suppliersProcurement[supplier]['amount']
        } else {
          acc[supplier] = acc[supplier] + suppliersProcurement[supplier]['amount']
        }
      })
      return acc
    }, {})
}

exports.getAggregateLocationsRows = getAggregateLocationsRows
function getAggregateLocationsRows (locationsById, routesById, orders) {
  const byDestinationId = orders
    .reduce((acc, order) => {
      const routeToCheck = getProperRouteIdentifier(order)

      if (acc[order.destinationId]) {
        throw new Error(`Found multiple orders for the same destination id ${order.destinationId}`)
      }

      acc[order.destinationId] = {
        name: get(locationsById, `${order.destinationId}.name`, DATA_NOT_FOUND_NAME),
        routeName: get(routesById, `${routeToCheck}.name`, DATA_NOT_FOUND_NAME),
        orderType: order.orderType,
        productsCount: Object.keys(order.products).length,
        destinationId: order.destinationId
      }
      return acc
    }, {})

  return Object.values(byDestinationId)
}

exports.getLocationRowsForSelectedProduct = getLocationRowsForSelectedProduct
function getLocationRowsForSelectedProduct (locationsById, routesById, orders, productId, supplierLedgers) {
  return orders
    .filter(order => order.products[productId])
    .map(order => {
      const routeToCheck = getProperRouteIdentifier(order)

      const { qto, adjustment, original, allocationType, suppliersProcurement } = getOrderProductInfo(order, productId)
      const location = get(locationsById, `${order.destinationId}`, {})
      const { id: serviceId } = get(location, 'programs[0].services[0]', {})

      const suppliersWithLedgerInformation = addsuppliersInformationToProcurement(supplierLedgers, suppliersProcurement, productId)

      return {
        orderType: order.orderType,
        destinationId: order.destinationId,
        name: get(location, 'name', DATA_NOT_FOUND_NAME),
        routeName: get(routesById, `${routeToCheck}.name`, DATA_NOT_FOUND_NAME),
        serviceId,
        order,
        qto,
        adjustment,
        original,
        allocationType: allocationType || 'missing_allocation',
        suppliersProcurement: suppliersWithLedgerInformation
      }
    })
}

function addsuppliersInformationToProcurement (supplierLedgers, suppliersProcurement, productId) {
  return supplierLedgers
    .reduce((acc, { supplier, ledger }) => {
      const supplierId = supplier._id
      const quantityToProcure = get(suppliersProcurement, `${supplierId}.amount`, 0)
      const snapshotId = get(suppliersProcurement, `${supplierId}.snapshotId`, '')
      const quantityAvailable = get(ledger, productId, 0)
      acc.push({
        name: supplier.name,
        quantityAvailable,
        quantityToProcure,
        supplierId,
        snapshotId
      })
      return acc
    }, [])
}

exports.getProductRowsForSelectedLocation = getProductRowsForSelectedLocation
async function getProductRowsForSelectedLocation (locationLedger = {}, productsById, orders, destinationId, api, supplierLedgers) {
  const order = orders.find(order => order.destinationId === destinationId)
  if (!order) {
    throw new Error(`Order not found for destination id ${destinationId}`)
  }
  const { products } = await api.forecast.adapter.get(destinationId)

  return Object.keys(order.products).map(productId => {
    const { qto, adjustment, original, allocationType, suppliersProcurement } = getOrderProductInfo(order, productId)
    let closingBalance = 0
    if (locationLedger[productId] && locationLedger[productId].availableTotal) {
      closingBalance = locationLedger[productId].availableTotal
    }

    const averageWeeklySales = (get(products[productId], 'dailyDemand', 0) * 7.0).toFixed(2)
    const suppliersWithLedgerInformation = addsuppliersInformationToProcurement(supplierLedgers, suppliersProcurement, productId)
    const productData = productsById[productId]
    const vatMultiplier = get(productData, 'vats', []).length ? productData.vats[0].vat / 100 : 0

    const productPrice = api.product.tools.getPrice(productData.prices, order.createdAt)

    return {
      name: get(productData, 'name', DATA_NOT_FOUND_NAME),
      qto,
      adjustment,
      original,
      productId,
      closingBalance,
      averageWeeklySales,
      allocationType,
      price: productPrice,
      vatMultiplier,
      suppliersProcurement: suppliersWithLedgerInformation
    }
  })
}

function getProductSupplierInfo (productsById, supplierLedgers, productId, qto, totalSupplierQto, orderType, orderDate) {
  let heldUnits = 0
  const product = productsById[productId]
  const { buyPrice, sellPrice } = getProductMasterData(product, orderDate)
  const suppliersWithLedgerInformation = supplierLedgers
    .map(({ supplier, ledger }) => {
      const quantityAvailable = get(ledger, productId, 0)
      const quantityToProcure = get(totalSupplierQto, supplier._id, 0)
      heldUnits += quantityAvailable
      const unitPrice = orderType !== ORDER_TYPES.resupply ? sellPrice : buyPrice
      const quantityTotalPrice = quantityToProcure * unitPrice
      return {
        name: getShortSupplierName(supplier.name),
        isPackPoint: true,
        id: supplier._id,
        buyPrice,
        sellPrice,
        quantityAvailable,
        quantityToProcure,
        quantityTotalPrice
      }
    })

  const suppliersDisplay = suppliersWithLedgerInformation
    .filter(supplier => (supplier.quantityToProcure > 0))
    .map(supplier => `${supplier.name}`).join(', ')

  const totalValue = suppliersWithLedgerInformation
    .reduce((acc, supplierInfo) => {
      const { quantityTotalPrice } = supplierInfo
      return acc + quantityTotalPrice
    }, 0)

  return {
    suppliers: suppliersWithLedgerInformation,
    suppliersDisplay,
    heldUnits,
    totalValue,
    buyPrice,
    sellPrice
  }
}

function getShortSupplierName (name) {
  return (name.includes('Shelf Life '))
    ? name.replace('Shelf Life ', '')
    : name
}

exports.getSupplyBreakdown = getSupplyBreakdown
function getSupplyBreakdown (productIds, supplierLedgers, productId, orders) {
  const totalQto = orders
    .reduce((acc, order) => {
      const { qto } = getOrderProductInfo(order, productId)
      return acc + qto
    }, 0)
  const totalSupplierQto = getTotalSupplierQto(orders, productId)
  const { suppliers, heldUnits, buyPrice } = getProductSupplierInfo(
    productIds, supplierLedgers, productId, totalQto, totalSupplierQto
  )
  return { suppliers, totalQto, heldUnits, buyPrice }
}

exports.listAvailableSuppliers = listAvailableSuppliers
function listAvailableSuppliers (supplierLedgers, orders) {
  // This only checks for products available on orders for no real reason other than
  // it seemed like the best guess. If we need to switch this to
  // "available in territory union on products" or something, let's do it.
  const productIds = Array.from(new Set(orders
    .reduce((acc, order) => {
      return acc.concat(Object.keys(order.products))
    }, [])))

  const byId = productIds
    .reduce((acc, productId) => {
      const { suppliers } = getSupplyBreakdown(productIds, supplierLedgers, productId, orders)
      suppliers.forEach(supplier => {
        acc[supplier.id] = acc[supplier.id] || { name: supplier.name, id: supplier.id, products: {} }
        acc[supplier.id].products[productId] = supplier
      })
      return acc
    }, {})
  return Object.values(byId)
}

exports.getFilteredProductRowsBySupplier = getFilteredProductRowsBySupplier
function getFilteredProductRowsBySupplier (productRows, supplierId) {
  if (!supplierId || supplierId === ALL_SUPPLIERS_ID) {
    return productRows
  }

  // if (supplierId === NOT_AVAILABLE_ID) {
  //   return productRows.filter(row => row.remaining)
  // }

  return productRows.filter(row => {
    return row.suppliers
      .find(supplier => {
        if (supplierId === NOT_AVAILABLE_ID) {
          return supplier.notAvailable
        }

        if (supplier.id === supplierId) {
          if (supplier.defaultSupplier) return true

          // if held, only return if we're seeing quantities
          // from held's stock.
          return supplier.quantityToProcure
        }
      })
  })
    .map(row => {
      const supplier = row.suppliers.find(s => s.id === supplierId)
      if (supplier && supplier.isPackPoint) {
        return {
          ...row,
          heldUnits: supplier.quantityAvailable
        }
      }
      return row
    })
}

exports.getSupplierOptions = getSupplierOptions
function getSupplierOptions (suppliers) {
  const defaultOptions = [
    { label: 'All', value: ALL_SUPPLIERS_ID }
  ]
  return defaultOptions.concat(
    suppliers.map(supplier => ({ label: supplier.name, value: supplier.id }))
  )
}

exports.getProductMasterData = getProductMasterData
function getProductMasterData (product, date = new Date().toJSON()) {
  const buyPrices = get(product, 'buyPrices', [])
  const buyPrice = getPrice(buyPrices, date) || 0
  const sellPrices = get(product, 'prices', [])
  const sellPrice = getPrice(sellPrices, date) || 0
  const defaultSupplierName = get(product, 'additionalData.supplier')
  const available = get(product, 'additionalData.available', false)
  return {
    buyPrice,
    sellPrice,
    defaultSupplierName,
    available
  }
}

exports.disableGroupConfirm = disableGroupConfirm
function disableGroupConfirm (supplierLedgers, orders) {
  // disable banner if order is resupply
  if (orders.some(({orderType}) => orderType === 'resupply')) {
    return false
  }
  const productIds = Array.from(new Set(orders
    .reduce((acc, order) => {
      return acc.concat(Object.keys(order.products))
    }, [])))

  let showResupplyWarning = false

  productIds
    .forEach((productId) => {
      const { totalQto, heldUnits } = getSupplyBreakdown(productIds, supplierLedgers, productId, orders)
      if (totalQto > heldUnits) {
        showResupplyWarning = true
      }
    })
  return showResupplyWarning
}

exports.getPatientDetails = getPatientDetails
function getPatientDetails (orders) {
  // Topup requests are just one order doc
  const { patientName, patientId } = orders[0]
  return { patientName, patientId }
}
