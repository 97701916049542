module.exports = getConfigAndServices

const getParentLocation = require('../utils/get-parent-location')

function getConfigurationIdsOfLocation (location) {
  if (location.configurations) {
    return location.configurations
  }
  // Fallback for VAN which does not have configurations
  if (location.level === 'lga') {
    return [`configuration:${getParentLocation(location._id)}`]
  } else {
    return [`configuration:${location._id}`]
  }
}

function getServices (configurations) {
  // This is for VAN configurations, that are locations
  // Return the immunization service
  if (configurations.length && !(/service:/.test(configurations[0]))) {
    return [
      'program:immunization:service:immunization'
    ]
  }

  return configurations.map(confId => {
    return confId.replace('configuration:', '')
  })
}

function getConfigAndServices (location) {
  const configurations = getConfigurationIdsOfLocation(location)
  return {
    configurations: configurations,
    services: getServices(configurations) // used in indicators lambdas
  }
}
