import React from 'react'
import classnames from 'classnames'
import { Checkbox, StatusCard, Text } from '@fielded/shared-ui'
import { getProgramReadableName } from '@fielded/fs-api/lib/program/tools'
import { formatDate, pluralize } from '../../../../../van-shared/utils/utils'
import {
  SHIPMENT_STATUS,
  SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS_FOR_DRIVER
} from '@fielded/fs-api/lib/shipment/constants'

const PSMDriverDeliveryCard = ({
  shipment,
  programs,
  history,
  headerTitle,
  showCompleted,
  selectedDeliveries,
  onToggleCheckbox

}) => {
  const {
    date,
    counts,
    status,
    snapshotId
  } = shipment
  const isDeliverySelected = !!selectedDeliveries.find(d => d.snapshotId === shipment.snapshotId)
  const formattedDate = formatDate(date, 'long')
  const productCount = Object.keys(counts).length
  const isCheckboxDisabled = status !== SHIPMENT_STATUS.PACKED
  const programName = getProgramReadableName(shipment.programId, programs)
  const { ARRIVED, RECEIVED } = SHIPMENT_STATUS
  let target = `/shipments/delivery/${snapshotId}`
  if ([ARRIVED, RECEIVED].includes(status)) {
    target = `/shipments/pick-list/${snapshotId}`
  }
  return (
    <section className='vs-u-align-center driver-delivery-card__wrapper'>
      {!showCompleted && (
        <Checkbox.Stateless
          className={classnames(
            'driver-delivery-card__checkbox',
            {'driver-delivery-card__checkbox--disabled': isCheckboxDisabled}
          )}
          checked={isDeliverySelected}
          disabled={isCheckboxDisabled}
          onChange={onToggleCheckbox}
        />
      )}

      <StatusCard
        className='shipment driver-delivery-card__status-card'
        target={target}
        history={history}
      >
        <StatusCard.Label
          className={`driver-delivery-card__status--${status}`}
          statusText={SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS_FOR_DRIVER[status]}
        />

        <StatusCard.Content className='driver-delivery-card__header-box'>
          <Text size='large'>{formattedDate}</Text>
          <Text size='large' bold>{headerTitle}</Text>
        </StatusCard.Content>

        <StatusCard.Footer className='driver-delivery-card__footer' boldLink>
          <section>
            <Text size='small'>Products:</Text>
            <Text size='large' bold>{productCount} {pluralize('product', productCount)}</Text>
            <Text size='small'>ID : <strong> {shipment.shipmentNo} </strong></Text>
            <Text size='small'>Program : <strong> {programName}</strong></Text>
          </section>
        </StatusCard.Footer>
      </StatusCard>
    </section>
  )
}

export default PSMDriverDeliveryCard
