import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Loading, Page } from '@fielded/shared-ui'

import { isPSMFacility } from '../subapps/shipments/common/utils'
import CrossLink from '../common/cross-link'
import HomeNotificationView from '../van-shared/components/HomeNotificationView/HomeNotificationView'
import FacilityDeliveries from '../subapps/shipments/deliveries/psm-deliveries/PSMFacilityDeliveries/FacilityDeliveries'

const Home = ({
  user,
  showProfileLink,
  api,
  config,
  history
}) => {
  const [shipments, setShipments] = useState()
  const [loading, setLoading] = useState(true)
  const [notificationOptions, setNotificationOptions] = useState({
    notificationsList: [],
    showNotificationsBanner: false,
    notificationsMarkedAsRead: false
  })

  const isPSMFacilityUser = isPSMFacility(user)
  const { notificationsList, notificationsMarkedAsRead, showNotificationsBanner } = notificationOptions

  const markNotificationsRead = async () => {
    // check if there are any unread notifications
    const unreadListLength = notificationsList && notificationsList.filter(notification => !notification.readAt).length

    let updatedNotificationsList = notificationsList
    if (unreadListLength) {
      try {
        await api.notification.markAsRead({})
      } catch (e) {
        console.log(e)
      }
      updatedNotificationsList = await api.notification.getList({ limit: 4, offset: 0 })
    }

    setNotificationOptions(prevValues => ({
      ...prevValues,
      notificationsMarkedAsRead: true,
      notificationsList: updatedNotificationsList
    }))
  }

  const toggleNotificationsBanner = () => {
    if (showNotificationsBanner && !notificationsMarkedAsRead) {
      markNotificationsRead()
    }
    setNotificationOptions(prevValues => ({
      ...prevValues,
      showNotificationsBanner: !showNotificationsBanner
    }))
  }

  const initialize = async () => {
    try {
      if (isPSMFacilityUser) {
        const shipments = await api.shipment.find({ loaction: user.location })
        setShipments(shipments)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  if (loading) {
    return <Loading loadingText='Loading...' />
  }

  return (
    <div className='home__container'>
      <Page>
        {user.location.level === 'sdp' ? (
          <Page.Panel className='home__container-notification' narrow alignLeft relative>
            <HomeNotificationView
              username={user.name}
              currentUser={user}
              config={config}
              api={api}
              onToggleNotificationsBanner={toggleNotificationsBanner}
              cardVisible={showNotificationsBanner}
              notifications={notificationsList}
            />
          </Page.Panel>
        ) : (
          <div>
            {user.name && (
              <div>
                You are logged in as {user.name}.
              </div>
            )}
          </div>
        )}

        {showProfileLink && !isPSMFacilityUser && (
          <Page.Panel.Message
            className='user-require-fill-email'
            type='info'
            fullWidth
            cta={
              <Button
                colorVariant='brand'
                component={CrossLink}
                to={'/profile'}
                fill='full'
              >
                Review Profile
              </Button>
            }
          >
            Hello <strong>{user.name}</strong>, please capture your profile details.
            This is important for password recovery.
          </Page.Panel.Message>
        )}

        {isPSMFacilityUser && (
          <section className='home__container-deliveries'>
            <FacilityDeliveries
              user={user}
              history={history}
              shipments={shipments}
              showCompleted={false}
            />
          </section>
        )}
      </Page>
    </div>
  )
}

Home.propTypes = {
  api: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
  config: PropTypes.object,
  showProfileLink: PropTypes.bool
}

Home.defaultProps = {
  user: {}
}

export default Home
