import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Page, Text } from '@fielded/shared-ui'
import { Download } from '@fielded/shared-ui/src/icons'
import { getNavigationItem } from '../../../common/utils/navigationInfo'

const Guides = (props) => {
  const {
    config,
    pdfLink
  } = props
  return (
    <Page title={config.title}>
      <Page.Header
        title='Standard Operating Procedure'
        breadcrumbItems={[getNavigationItem({item: 'information', config, includePath: false})]}
      />
      <Page.Panel>
        <Card
          semitight
        >
          <Card.Header>
            <Text color='brand'>
              <Text.Heading>
                Standard Operating Procedure
              </Text.Heading>
            </Text>
          </Card.Header>
          <Card.Content>
            Download the latest version of the NHLMIS Standard Operating Procedure
          </Card.Content>
          <Card.Action layout='left'>
            <Button
              colorVariant='brand'
              fill='full'
              alignEdge='shadow'
              icon={<Download />}
              component={props => <Link to={pdfLink} {...props} target='_blank' />}
            >
              Download SOP
            </Button>
          </Card.Action>
        </Card>
      </Page.Panel>
    </Page>
  )
}

export default Guides
