const installmentsSubQuery = require('../../finances/queries/common/installments')

const unpaidInstalmentsQuery = (includeAllUnpaidInstalments, paymentPlanId) => {
  const allUnpaidStatement = includeAllUnpaidInstalments ? 'or (i.is_paid is false)' : ''
  const whereClause = paymentPlanId ? 'and i.payment_plan_id = $1' : ''
  return `
    with ${installmentsSubQuery},
    unpaid_installments as (
      select 
        i.*
      from installments i
      left join avocado.data_paymentplaninstallment pi on pi.id = i.id
      where (i.payment_plan ->> 'is_active')::boolean is true
        and upper(i.payment_plan ->> 'company_code') != 'LEGACY'
        and ((i.is_paid::boolean != pi.is_paid::boolean) ${allUnpaidStatement})
        ${whereClause}
    )
    select
      i.payment_plan_id,
      i.payment_plan ->> 'quickbooks_account_id' as quickbooks_account_id,
      i.payment_plan ->> 'company_code' as company_code,
      (i.payment_plan ->> 'quickbooks_account_balance')::float as quickbooks_account_balance,
      coalesce(
        (
          select jsonb_agg(i)
          from (
            SELECT
              id,
              code,
              amount,
              is_paid,
              due_date,
              created_at,
              start_date,
              updated_at,
              amount_paid,
              location_id,
              payment_plan_id
            from unpaid_installments ui
            where ui.payment_plan_id = i.payment_plan_id
          ) i
        ),
        '[]'::jsonb
      ) as installments
    from unpaid_installments i
    group by 1, 2, 3, 4
  `
}

module.exports = { unpaidInstalmentsQuery }
