const defer = (thunk, delay) => new Promise((resolve) => {
  setTimeout(() => {
    resolve(thunk())
  }, delay)
})

// When calling Lambdas that run exports for us, these exports often need to be
// uploaded to S3 in order to work around possible filesize limitations
// (API Gateway only allows for up to 6MB). This is why some exports will
// respond with a signed S3 URL that will be populated with the requested export.
// As this happens asynchronously and **after the initial response** has been issued
// this function repeatedly polls the given URL to see if the file already exists
// and defers download until we know it is present on S3.
//
// TODO: consider removing the actual download part here and transfer this
// responsibility to the caller of the function so it is more generic.
export default function pollForAndDownload (url, retryAfter = 2500, timeoutAfter = 15 * 60 * 1000) {
  const pollForFile = (signal) => {
    const headers = new window.Headers()
    // we are not interested in actually downloading the file,
    // at this point, we just need to know if it exists
    headers.set('Range', 'bytes=0-0')
    return window
      .fetch(url, { method: 'GET', mode: 'cors', headers, signal })
      .then(res => {
        if (res.ok) {
          return null
        } else if (/40(3|4)/.test(res.status)) {
          return defer(() => pollForFile(signal), retryAfter)
        }
        return Promise.reject(
          new Error(
            `Request failed with status ${res.status}: "${res.statusText}"`
          )
        )
      })
  }

  const controller = new window.AbortController()
  setTimeout(() => controller.abort(), timeoutAfter)
  return pollForFile(controller.signal)
    .then(() => window.open(url, '_self'))
}
