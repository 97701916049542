import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  defaultTableRowRenderer,
  AutoSizer,
  Column,
  Table,
  SortDirection,
  WindowScroller
} from 'react-virtualized'
import orderBy from 'lodash/orderBy'

import Banner from '../Banner'
import DataTableHeader from './DataTableHeader'

import get from 'lodash/get'

import 'react-virtualized/styles.css'

const statusRowRenderer = ({props, statusGetter}) => {
  if (!statusGetter || typeof statusGetter !== 'function') {
    console.warn('You called statusRowRenderer without a statusGetter function. Using default rowRenderer instead.')
    return defaultTableRowRenderer(props)
  }

  const status = statusGetter()
  return defaultTableRowRenderer({
    ...props,
    className: classnames(
      props.className,
      {'vs-data-table-row--status-warning': status === 'warning'},
      {'vs-data-table-row--status-attention': status === 'attention'},
      {'vs-data-table-row--status-success': status === 'success'},
      {'vs-data-table-row--status-disabled': status === 'disabled'}
    )
  })
}

const tableHeaderRowRenderer = (props) => {
  const {className, columns, style, tableHeaderRef} = props
  return React.createElement(
    'div',
    { className: className, role: 'row', style: style, ref: tableHeaderRef },
    columns
  )
}

const TightCell = ({ children }) => {
  if (children) {
    return <div className='vs-data-table__cell-content--tight'>
      { children }
    </div>
  }
}

const stickHeaderClassName = 'vs-data-table-row--sticky-header'

const StatelessDataTable = ({
  sortBy,
  children,
  rowClassName,
  withTallRows,
  ...otherProps
}) => {
  /**
   * 44px is the row height that fit our design mockups.
   * 70px is the tall row height.
   * The value is repeated in the css variables file
   * (marked with a comment ref'ing the DataTable component),
   * so if it changes, make sure to update it there as well.
   */
  const defaultHeaderHeight = 44
  const defaultRowHeight = withTallRows ? 70 : defaultHeaderHeight

  if (!children) {
    return null
  }

  return (
    <WindowScroller scrollElement={window}>
      {({ height, isScrolling, onChildScroll, scrollTop, registerChild }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <div className='vs-data-table' ref={registerChild}>
              <Table
                autoHeight
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                scrollTop={scrollTop}
                width={width}
                height={height}
                headerHeight={defaultHeaderHeight}
                rowHeight={defaultRowHeight}
                headerRowRenderer={(props) => tableHeaderRowRenderer({...props, ...otherProps})}
                rowClassName={classnames(
                  'vs-data-table-row',
                  { 'vs-data-table-row--clickable': otherProps.hasOwnProperty('onRowClick') },
                  { 'vs-data-table-row--tall': withTallRows },
                  rowClassName
                )}
                overscanRowCount={2}
                sortBy={sortBy}
                {...otherProps}
              >
                {children}
              </Table>
            </div>
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  )
}

const sortEntries = ({ sortBy, sortDirection, entries }) => {
  return orderBy((entries), [(entry) => {
    const sortedValue = get(entry, sortBy)
    return (typeof sortedValue === 'string') ? sortedValue.toLowerCase() : sortedValue
  }], sortDirection.toLowerCase())
}

class DataTable extends Component {
  static propTypes = {
    /**
     * The data entries for table rows
     */
    entries: PropTypes.array.isRequired,

    /**
     * Column definitions
     */
    children: PropTypes.node.isRequired,

    /**
     * Optional custom sorting
     */
    entrySorter: PropTypes.func,

    /**
     * The column to `sortBy`
     */
    sortBy: PropTypes.string,

    /**
     * The sort direction (SortDirection.ASC|SortDirection.DESC)
     *
     * A react-virtualised SortDirection constant
     */
    sortDirection: PropTypes.oneOf([
      SortDirection.ASC,
      SortDirection.DESC
    ]),

    noRowsBannerContent: PropTypes.node,
    noRowsCustomContent: PropTypes.node
  }

  static defaultProps = {
    sortBy: undefined,
    sortDirection: SortDirection.ASC,
    noRowsBannerContent: 'No entries found'
  }

  static Header = DataTableHeader
  static Stateless = StatelessDataTable
  static Column = Column
  static TightCell = TightCell

  state = {
    sortBy: this.props.sortBy,
    sortDirection: this.props.sortDirection,
    tableHeaderRef: React.createRef()
  }

  constructor (props) {
    super(props)
    // If no sorting is specified by props and there are both rows and columns,
    // use the data key of the first column as the default sorting
    if (!props.sortBy && props.entries.length && React.Children.count(props.children)) {
      this.state.sortBy = React.Children.toArray(props.children)[0].props.dataKey
    }
  }

  componentDidMount () {
    const { tableHeaderRef } = this.state
    const { stickyHeader } = this.props
    if (tableHeaderRef.current && stickyHeader) {
      // we need to observe a parent elem
      const parentElem = tableHeaderRef.current.parentElement
      const topOffset = parentElem.getBoundingClientRect().top
      const marginTop = Math.floor(topOffset) - 30
      const margin = `0px 0px -${marginTop}px 0px`
      const options = {
        rootMargin: margin
      }
      // eslint-disable-next-line no-undef
      this.observer = new IntersectionObserver(this.activateStickHeader, options)
      this.observer.observe(parentElem)
    }
  }

  componentWillUnmount () {
    const { stickyHeader } = this.props
    const { tableHeaderRef } = this.state
    if (stickyHeader && this.observer && tableHeaderRef) {
      tableHeaderRef.current.classList.remove(stickHeaderClassName)
      this.observer.unobserve(tableHeaderRef.current.parentElement)
    }
  }

  activateStickHeader = (entries) => {
    const elem = entries[0]
    const { tableHeaderRef } = this.state
    if (tableHeaderRef && tableHeaderRef.current) {
      if (elem.isIntersecting) {
        if (!tableHeaderRef.current.classList.contains(stickHeaderClassName)) {
          tableHeaderRef.current.classList.add(stickHeaderClassName)
        }
      } else {
        tableHeaderRef.current.classList.remove(stickHeaderClassName)
      }
    }
  }

  handleSort = ({ sortBy: newSortBy }) => {
    const { sortBy, sortDirection } = this.state
    let newSortDirection = SortDirection.ASC
    // If user clicks same sort header twice, we want to change sort direction
    // If they click new header, we want to go back to ASC
    if (newSortBy === sortBy) {
      newSortDirection = sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
    }
    this.setState({sortBy: newSortBy, sortDirection: newSortDirection})
  }

  render () {
    const {
      children,
      entries,
      entrySorter,
      noRowsBannerContent,
      noRowsCustomContent,
      withTallRows,
      ...otherProps
    } = this.props

    const {
      sortBy,
      sortDirection,
      tableHeaderRef
    } = this.state

    const sortedEntries = (entrySorter || sortEntries)({ sortBy, sortDirection, entries })

    return (
      <StatelessDataTable
        sort={this.handleSort}
        sortBy={sortBy}
        tableHeaderRef={tableHeaderRef}
        sortDirection={sortDirection}
        rowGetter={({index}) => sortedEntries[index]}
        rowCount={entries.length}
        noRowsRenderer={() => (
          <div className='vs-data-table-row--no-results'>
            {noRowsCustomContent ? (
              <Fragment>{noRowsCustomContent}</Fragment>
            ) : (
              <Banner inline>
                {noRowsBannerContent}
              </Banner>
            )}
          </div>
        )}
        withTallRows={withTallRows}
        {...otherProps}
      >
        {React.Children.map(children, child => {
          if (!child) return null
          const {
            dataKey,
            label,
            title,
            disableSort,
            capitalized,
            lowercase,
            textAlign,
            strong,
            noEllipsis,
            headerClassName,
            cellClassName
          } = child.props

          const localProps = {
            headerClassName: classnames(
              'vs-data-table-header',
              {'vs-data-table-header--align-left': textAlign === 'left'},
              {'vs-data-table-header--align-center': textAlign === 'center'},
              {'vs-data-table-header--align-right': textAlign === 'right'},
              headerClassName
            ),
            className: classnames(
              'vs-data-table-cell',
              {'vs-data-table-header--align-left': textAlign === 'left'},
              {'vs-data-table-cell--align-center': textAlign === 'center'},
              {'vs-data-table-cell--align-right': textAlign === 'right'},
              {'vs-data-table-cell--strong': strong},
              {'vs-data-table-cell--lowercase': lowercase},
              {'vs-data-table-cell--capitalized': capitalized},
              {'vs-data-table-cell--no-ellipsis': noEllipsis},
              cellClassName
            ),
            disableSort: disableSort || !(label || title),
            headerRenderer: DataTable.Header,
            dataKey: dataKey,
            sortBy: sortBy,
            sortDirection: sortDirection
          }

          return React.cloneElement(child, { ...localProps })
        })}
      </StatelessDataTable>
    )
  }
}

export default DataTable
export { statusRowRenderer }
