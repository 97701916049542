// TODO: extract and unify from VAN into van-shared
import { createSelector } from 'reselect'
import get from 'lodash/get'

const batchesSelector = state => get(state, 'masterData.batches.byId', {})

export const batchesByIDForProductId = (state, productId, includeExpired = true) => {
  const batches = batchesSelector(state)

  return Object.keys(batches)
    .filter(batchId => batches[batchId].productId === productId)
    .reduce((index, batchId) => {
      const batch = batches[batchId]
      const batchNumber = batch.batchNo || batchId.split(':').pop()

      const today = new Date()
      const expiryDate = new Date(batch.expiry)

      if (includeExpired || expiryDate > today) {
        index[batchId] = {
          ...batch,
          id: batchId,
          batchNumber
        }
      }

      return index
    }, {})
}

export const toDropdownOption = manufacturer => ({value: manufacturer.id, label: manufacturer.name})

export const manufacturersSelector = (state) => state.masterData.manufacturers

export const manufacturerOptions = createSelector(manufacturersSelector, ({allIds, byId}) => allIds.map(id => toDropdownOption(byId[id])))

export const createProductLine = (stock, products, serviceName) => ({
  products: products,
  name: serviceName,
  active: true
})

export const byProductLine = (stock, products, serviceId, serviceName, activeProductId) => {
  const productLines = products.reduce((memo, product) => {
    const services = get(product, 'services', [])
    services.forEach(({ productLine = serviceName, id }) => {
      // A product might have multiple services. Service is 1:1 to a product line.
      // We only want the product line on the service on which the user is viewing.
      if (id === serviceId) {
        const products = memo[productLine] ? memo[productLine].products.concat([product]) : [product]
        memo[productLine] = {
          name: productLine,
          active: get(memo, `${productLine}.active`) || (product._id === activeProductId),
          products
        }
      }
    })
    return memo
  }, {})
  return Object.keys(productLines)
    .map(productLine => productLines[productLine])
    .map(productLine => ({
      ...productLine,
      // If any product is not complete we want the service line to also be incomplete
      complete: !productLine.products.find(product => !stock[product._id].progress.isComplete)
    }))
}

// Shelf Life data is structured differently and needs different processing
export const byProductLineSL = (stock, productsList, activeProductId) => {
  const productLines = productsList.reduce((memo, product) => {
    if (!product) return memo
    const productCategory = product.productType
    const products = memo[productCategory] ? memo[productCategory].products.concat([product]) : [product]
    memo[productCategory] = {
      name: productCategory,
      active: get(memo, `${productCategory}.active`) || (product._id === activeProductId),
      products
    }

    return memo
  }, {})

  return Object.keys(productLines)
    .map(productCategory => productLines[productCategory])
    .map(productCategory => ({
      ...productCategory,
      // If any product is not complete we want the service line to also be incomplete
      complete: !productCategory.products.find(product => !stock[product._id].progress.isComplete)
    }))
}
