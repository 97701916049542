import React from 'react'
import { ExpandableOptions, OptionsGroup } from '@fielded/shared-ui'
import { serviceFilterData } from '../shared/serviceFilterData'

const ServiceHistoryFilter = ({
  label,
  value,
  onFilterServices,
  filterExpandableOptions
}) => {
  const serviceOptions = Object.values(serviceFilterData)

  return (
    <ExpandableOptions
      ref={filterExpandableOptions}
      label={label}
      expandableChildren={
        <OptionsGroup
          className='service-visit__history-filter-wrapper'
          options={serviceOptions}
          inRows
          defaultValue={value}
          onChange={(service) => onFilterServices(service)}
        />
      }
    />
  )
}

export default ServiceHistoryFilter
