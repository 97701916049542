// If we cannot find the date from the server, returns false,
// so we're not alerting all the time if the user is offline.
export async function isSystemDateOff (url) {
  const serverDate = await getServerTime(url)
  if (!serverDate) return false

  const systemDate = new Date()
  return isMoreThanADay(serverDate, systemDate)
}

// If we cannot find a system date, returns nothing
export async function getServerTime (url, fetch = window.fetch.bind(window)) {
  if (!url) throw new Error('getServerTime expects param url')
  const response = await fetch(url)
  const date = response.headers.get('date')
  // if no 'date' header, likely a cors issue in dev, ignore it.
  // https://stackoverflow.com/questions/38458803/why-can-i-not-see-all-the-expected-headers-from-a-fetch-response
  if (!date) return

  return new Date(date)
}

export function isMoreThanADay (firstDate, secondDate) {
  if (!isDate(firstDate) || !isDate(secondDate)) {
    throw new Error('isMoreThanADay requires valid js dates')
  }

  const differenceInHours = (firstDate - secondDate) / 1000 / 60 / 60
  return Math.abs(differenceInHours) >= 24
}

// https://stackoverflow.com/questions/643782/how-to-check-whether-an-object-is-a-date
function isDate (date) {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date)
}
