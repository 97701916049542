const {v4} = require('uuid')
const PGAdapter = require('./../common/pg-adapter')
const REMITA_CONFIG = require('./data-access/remita/config')
const isPGUsageErrorCode = require('./../common/isPGUsageErrorCode')
const { getOrderByClause } = require('./../tools/sql-tools')

const PAYMENT_HISTORY_TABLE_NAME = 'avocado.data_paymenthistory'
const PAYMENT_DEBIT_TABLE_NAME = 'avocado.data_paymentdebit'
const PAYMENT_HISTORY_COLUMNS = [
  // columns used for fetch
  'payment_history_id',
  'payment_history_mandate_id',
  'payment_debit_mandate_id',
  'payment_history_amount',
  'payment_debit_id',
  'payment_debit_amount',
  'reference',
  'data',
  'payment_debit_created_at',
  'payment_debit_created_by',
  'payment_debit_updated_at',
  'payment_history_created_at',
  'payment_history_created_by',
  'payment_history_updated_at',
  'payment_history_updated_by',
  // columns used for create and fetch
  'payment_provider',
  'payment_method_id',
  'mandate_id',
  'amount',
  'status_updated_at',
  'status_code',
  'status',
  'rrr',
  'transaction_ref',
  // columns to create
  'id',
  'created_at',
  'created_by',
  'updated_at',
  'updated_by'
]

class PaymentHistoryAdapter extends PGAdapter {
  constructor (pgConnection, username, remitaInterface, safaricomInterface, parent) {
    super(
      pgConnection,
      PAYMENT_HISTORY_TABLE_NAME,
      username,
      PAYMENT_HISTORY_COLUMNS,
      'id'
    )
    this.remitaInterface = remitaInterface
    this.safaricomInterface = safaricomInterface
    this.parent = parent
  }

  async fetchFromProvider (data) {
    switch (data.mandate.payment_provider) {
      case REMITA_CONFIG.PROVIDER_CODE: {
        const request = { requestId: data.mandate.remitaResponse.body.requestId, mandateId: data.mandate.mandateId }
        const response = await this.remitaInterface.getMandateHistory(request)
        return {
          request,
          response
        }
      }
    }
  }

  async deleteMandateHistory (mandateId) {
    let row
    try {
      const {rows} = await this.pgConnection.query(`DELETE FROM ${this.tableName} WHERE mandate_id = $1`, [mandateId])
      row = rows[0]
    } catch (err) {
      if (isPGUsageErrorCode(err.code)) {
        err.status = 400
      }
      throw err
    }
    return row
  }

  async getList ({ ordering = this.idColumn, filter = {}, limit = 50, offset = 0 } = {}) {
    let whereText = ''
    let whereValues = []
    if (filter.payment_method_id) {
      whereText = 'WHERE ph.payment_method_id = $1 OR pd.payment_method_id = $1'
      whereValues = [filter.payment_method_id]
    }

    const sortText = getOrderByClause(ordering, this.columns)

    const values = whereValues.concat([limit, offset])

    const text = `
      SELECT ph.id                                                                            as payment_history_id,
             payment_provider,
             ph.mandate_id                                                                    as payment_history_mandate_id,
             pd.data #>> '{remitaResponse,body,mandateId}'                                    as payment_debit_mandate_id,
             ph.amount                                                                        as payment_history_amount,
             ph.status_updated_at,
             ph.status_code,
             ph.status,
             ph.rrr                                                                           as payment_history_rrr,
             ph.transaction_ref                                                               as payment_history_transaction_ref,
             coalesce(ph.transaction_ref, pd.data #>> '{remitaResponse,body,transactionRef}') as transaction_ref,
             coalesce(ph.payment_method_id, pd.payment_method_id)                             as payment_method_id,
             coalesce(ph.rrr, pd.data #>> '{remitaResponse,body,RRR}')                        as rrr,
             pd.id                                                                            as payment_debit_id,
             pd.data,
             pd.amount                                                                        as payment_debit_amount,
             pd.reference,
             pd.created_at                                                                    as payment_debit_created_at,
             pd.created_by                                                                    as payment_debit_created_by,
             pd.updated_at                                                                    as payment_debit_updated_at,
             ph.created_at                                                                    as payment_history_created_at,
             ph.created_by                                                                    as payment_history_created_by,
             ph.updated_at                                                                    as payment_history_updated_at,
             ph.updated_by                                                                    as payment_history_updated_by
      FROM ${this.tableName} ph
             FULL OUTER JOIN ${PAYMENT_DEBIT_TABLE_NAME} pd
                             ON ph.transaction_ref = pd.data #>> '{remitaResponse,body,transactionRef}'
      ${whereText}
      ${sortText}
      LIMIT $${values.length - 1} OFFSET $${values.length};
 `

    const { rows: results } = await this.pgConnection.query({ text, values })
    const count = await this.getCount(whereText, whereValues)
    return { count, results }
  }

  async getCount (whereText, whereValues) {
    // https://stackoverflow.com/questions/7943233/fast-way-to-discover-the-row-count-of-a-table-in-postgresql
    // you can get an estimate, or you can get a slow count :/ count(*) is worse
    const countResponse = await this.pgConnection.query({
      text: `
        SELECT
            COUNT(*)
        FROM ${this.tableName} ph
             FULL OUTER JOIN ${PAYMENT_DEBIT_TABLE_NAME} pd
                             ON ph.transaction_ref = pd.data #>> '{remitaResponse,body,transactionRef}'
        ${whereText}`,
      values: whereValues})
    return Number(countResponse.rows[0].count)
  }

  async update (data) {
    const {request, response} = await this.fetchFromProvider(data)

    // log API request/response
    try {
      await this.parent.createPaymentMethodRequest(JSON.stringify(request), JSON.stringify(response), data.mandate.id, REMITA_CONFIG.PROVIDER_CODE, 'GET_MANDATE_HISTORY')
    } catch (err) {
      // if this step fails, abort to save future headache
      // (don't want to have payment records we can't account for)
      throw err
    }

    const {body: {data: {data: {paymentDetails}}}} = response

    const {
      id: payment_method_id, // eslint-disable-line camelcase
      payment_provider, // eslint-disable-line camelcase
      mandateId // TODO this comes in camelcase?
    } = data.mandate

    // DELETE existing records for mandate, before INSERTing current data
    // TODO replace with bulk-upsert?
    try {
      await this.deleteMandateHistory(mandateId)
    } catch (err) {
      // don't continue to INSERT if old data has failed to clear.
      throw err
    }

    // prepare new data for INSERTion
    // TODO bulk upsert? (https://stackoverflow.com/questions/758945/whats-the-fastest-way-to-do-a-bulk-insert-into-postgres?)
    const updateData = paymentDetails.map(({
      amount,
      lastStatusUpdateTime,
      status,
      statuscode,
      RRR,
      transactionRef
    }) => {
      const payload = {
        id: v4(),
        payment_method_id,
        payment_provider,
        mandate_id: mandateId,
        amount,
        status_updated_at: lastStatusUpdateTime,
        status,
        status_code: statuscode,
        rrr: RRR,
        transaction_ref: transactionRef
      }
      return this.create(payload)
    })

    try {
      return Promise.all(updateData)
    } catch (err) {
      throw err
    }
  }
}

module.exports = PaymentHistoryAdapter
