// Data adapters inherit and override this,
// Default params shown for reference & editor autocomplete.
class DataAdapterInterface {
  /* getList: get records from a table on which you can sort, filter, limit and offset.
    e.g.: getList('location', {
     ordering: '-created_at',
     filter: {market: 'fct'},
     limit: 10,
     offset: 10
    //  {count: 1034, results: [{id: '123', ...}, ...]}
  }) */
  async getList ({ filter = {}, limit = 50, offset = 0 } = {}) {
    throw new Error('getList must be implemented')
  }
  // getOne should throw if the record does not exist.
  async getOne (id) {
    throw new Error('getOne must be implemented')
  }
  // getMany (get by ids) should throw if any one of the ids do not exist
  async getMany (ids = []) {
    throw new Error('getMany must be implemented')
  }
  /*
  Get many reference means look up records related to a particular record.
  (hint: using getMany and getList is more common.)
  const shipmentsAtABCPharma = shipment.getManyReference(
    'location', 'sdp:abc-pharma', {optional params, same as getList}
  ) */
  async getManyReference (
    target,
    id,
    { filter = {}, limit = 50, offset = 0 } = {}
  ) {
    throw new Error('getManyReference must be implemented')
  }

  // returns the created object exactly as is: {id: '123', ...}
  async create (objectToCreate = {}) {
    throw new Error('create must be implemented')
  }
  async update (updatedObject = {}) {
    throw new Error('update must be implemented')
  }
  async updateMany (objectsToUpdate = []) {
    throw new Error('updateMany must be implemented')
  }
  async delete (id = '') {
    throw new Error('delete must be implemented')
  }
  async deleteMany (ids = []) {
    throw new Error('deleteMany must be implemented')
  }
}

module.exports = DataAdapterInterface
