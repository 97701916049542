const docToVanRecord = require('./tools/doc-to-van-record')

module.exports = findAdjustments
/*
- read all ids (offline)
OR
- read all ids (between dates)
*/
const pluckDocs = result => result.rows.map((row) => row.doc)
const pluckIds = result => result.rows.map(row => row.id)

async function findAdjustments (state, { location: { id: locationId }, startdate, enddate }) {
  const availableRows = await state.dal.shipment.getByLocation(
    state,
    locationId,
    { include_docs: false, startdate, enddate }
  )

  const ids = pluckIds(availableRows)
  const idCheck = /:adjustment:/
  const adjustments = ids.filter(id => {
    if (!idCheck.test(id)) {
      return false
    }

    const date = id.split(idCheck)[1]
    if (startdate && date < startdate) {
      return false
    }

    if (enddate && date > enddate) {
      return false
    }

    return true
  })

  const filteredRows = await state.dal.shipment.getByKeys(state, {
    keys: adjustments,
    include_docs: true
  })

  return pluckDocs(filteredRows)
    .filter(x => x)
    // skipping locationId here,
    // we don't need to know if it's arrival or distribtion
    .map(doc => docToVanRecord(doc, undefined))
}
/*
 * Here's the idea:
 * - read adjustments out separately:
 * - list an 'adjustment' column on each shipment location-SKU
 * - for now, do an 'adjustment' note field, which contains JSON or something
 * - maybe they'll get something smarter later
 */
