module.exports = getSubmitProperties

const locationIdToSubmitProperties = require('./location-id-to-submit-properties')
const {VAN_SERVICE_ID} = require('./utils/constants')

function getSubmitProperties (serviceId, locationId) {
  if (serviceId && serviceId !== VAN_SERVICE_ID) {
    return {
      submitsOwnReport: false,
      submitsChildrenReport: false,
      submitsBatchedCounts: false,
      submitsMultiFieldCounts: true
    }
  }

  return locationIdToSubmitProperties(locationId)
}
