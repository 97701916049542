const cloneDeep = require('lodash/cloneDeep')
const uuid = require('uuid')

exports.addComment = addComment
async function addComment (state, { comment, orders, productId, createdBy, createdAt = new Date().toISOString() }) {
  const commentId = uuid()
  const newComment = {
    createdAt,
    createdBy,
    text: comment,
    _id: commentId
  }
  let existingComments
  const updatedOrders = orders
    .filter(order => !!order.products[productId])
    .map((order) => {
      const updatedOrder = cloneDeep(order)
      updatedOrder.products[productId].comments = updatedOrder.products[productId].comments || []
      updatedOrder.products[productId].comments.push(newComment)

      if (!existingComments) {
        existingComments = updatedOrder.products[productId].comments
      }

      return updatedOrder
    })
  await state.dal.order.bulkDocs(state, updatedOrders)
  return existingComments
}
