// from https://github.com/fielded/van-data-models/blob/master/packages/node_modules/%40fielded/van-location-model/schema.js
module.exports = {
  title: 'Location',
  name: 'location',
  description: 'A location',
  type: 'object',
  properties: {
    _id: {
      type: 'string',
      pattern: '[a-z0-9-.:]+$'
    },
    version: {
      type: 'string',
      format: 'semver'
    },
    createdBy: {
      type: 'string'
    },
    updatedBy: {
      type: 'string'
    },
    level: {
      type: 'string'
    },
    name: {
      type: 'string'
    },
    fullName: {
      type: 'string'
    },
    location: {
      type: 'object',
      properties: {
        country: {
          type: 'string'
        },
        zone: {
          type: 'string'
        },
        state: {
          type: 'string'
        },
        lga: {
          type: 'string'
        },
        required: [
          'country'
        ]
      }
    },
    contacts: {
      type: 'object',
      '^[a-z0-9-]+$': {
        properties: {
          name: {
            type: 'string'
          },
          phone: {
            type: 'string'
          }
        }
      }
    },
    implementingPartners: {
      type: 'object'
    },
    programs: {
      type: 'object',
      '^[a-z0-9-]+$': {
        type: 'object',
        '^[a-z0-9-]+$': {
          items: {
            type: 'object',
            properties: {
              startDate: {
                type: 'string'
              },
              endDate: {
                type: 'string'
              },
              funderId: {
                type: 'string'
              },
              additionalProperties: false
            }
          }
        }
      }
    }
  },
  required: [
    '_id',
    'type',
    'createdAt',
    'createdBy',
    'updatedAt',
    'updatedBy',
    'name',
    'fullName',
    'level',
    'programs',
    'location',
    'contacts'
  ]
}
