module.exports = applyCalculatedField

function applyCalculatedField (stock, fieldDefinitions, options = {}) {
  if (!stock) {
    return
  }
  if (!fieldDefinitions) {
    return stock
  }
  const calculatedStock = Object.keys(stock).reduce((acc, productId) => {
    const fields = stock[productId].fields
    if (!fields) {
      acc[productId] = stock[productId]
      return acc
    }

    const fieldsToCalculate = fieldDefinitions.filter(field => field.fieldType === 'calculated')
    const result = Object.assign({}, fields)

    for (let field of fieldsToCalculate) {
      // In shelflife, we need to make calculations based
      // on other calculated fields so we need to pass in the product of all these
      const amount = field.func(result, options)

      // Only apply the field if successfully calculated
      if (typeof amount === 'number') {
        result[field.id] = {amount: amount}
      } else {
        // Leave an empty object so the object structure can be read reliably
        result[field.id] = {}
      }
    }
    acc[productId] = Object.assign({}, stock[productId])

    acc[productId].fields = result
    return acc
  }, {})

  return calculatedStock
}
