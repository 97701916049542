import * as batchTools from '@fielded/fs-api/lib/batch/tools'

import {
  manufacturerFromBatch,
  normaliseManufacturerValue
} from '../../../common/utils'

export const unknownBatchFor = productId => `${productId}:manufacturer:unknown:batchNo:UNKNOWN`

export const isUntracked = product => product && (product.storageType === 'dry' || product.storageType === '')

const isKnown = batchId => !batchTools.isUnknownBatch({ id: batchId })

export const isNotTBD = batchId => batchId.indexOf('manufacturer:tbd:batchNo:TBD') === -1

// Batches that can be checked are:
// - not "unknown" batches with zero quantity
// - not To Be Determined ("tbd") batches
// Unknown batches are used for (A) untracked batches (product.storageType === 'dry') and
// (B) as a hack in external arrivals for adding products, since the store API only allows adding batches.
// Tbd batches are used in planning a distribution when there is no available stock.
export const batchCanBeChecked = (counts, batchId) => ((isKnown(batchId) || counts[batchId].quantity) && isNotTBD(batchId))

// construct a batch object
export const constructBatchObject = ({
  storageType,
  batch,
  productId,
  manufacturers,
  total = 0
}) => {
  if (storageType === 'dry') {
    // Build a batch object with unknown batch number, no manufacturer and undefined expiry
    return {
      productId: batch.productId,
      snapshotId: batch.snapshotId,
      id: unknownBatchFor(productId),
      expiry: undefined,
      batchNumber: 'unknown',
      quantity: total
    }
  }
  if (batch.id) {
    // If the manufacturer was changed, strip the id from the batch so it gets recreated in the reducer, and
    // added as a new batch.
    if (normaliseManufacturerValue(batch.manufacturer, manufacturers) !== manufacturerFromBatch(batch)) {
      return {
        ...batch,
        id: undefined,
        _id: undefined
      }
    }
  }
  return {
    ...batch,
    manufacturer: normaliseManufacturerValue(batch.manufacturer, manufacturers)
  }
}

export const isBatchNumberAlreadyInShipment = (batchNumber, batchesInPickList) => {
  // Unknown batches shouldn’t block this (you can always add to them)
  if (batchNumber === 'unknown') return false
  return !!batchesInPickList.find((batch) => {
    // format for id: "product:yf:manufacturer:ucb:batchNo:932"
    return batch.id.split(':').pop() === batchNumber
  })
}
