import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  desiredMembership: null
}

export const leadExperienceSlice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    desiredMembershipUpdate: (state, action) => {
      state.desiredMembership = action.payload
    }
  }
})

export const {
  desiredMembershipUpdate
} = leadExperienceSlice.actions

export const selectDesiredMembership = (state) => state.leadExperience.desiredMembership

export default leadExperienceSlice.reducer
