import qs from 'query-string'

/*
 * These are some lightweight ways of setting/parsing query strings
 * so they can be used for filters
 *
 * right now they are used in analytics and in the planning dashboard
 */
export const queryStringifyFilters = (search) => qs.stringify(search)

export function parseQueryString (queryString) {
  const queryStringProps = qs.parse(queryString)
  return Object.keys(queryStringProps).reduce((props, key) => {
    // Important: this needs to preserve true/false as strings
    // (booleans won't work with the radio group component in van-shared)
    return {
      ...props,
      [key]: queryStringProps[key]
    }
  }, {})
}

export function resolveFilters (filters, availableFilters, withValueValidation, withUndefinedValues = true) {
  const extraFilters = {
    productId: {
      validator: (value) => value.startsWith('product:')
    },
    funderId: {
      validator: (value) => value.startsWith('funder:')
    },
    searchValue: {
      validator: (value) => value && value !== ''
    },
    searchView: {
      validator: (value) => value && value !== ''
    }
  }
  const resolvedFilters = Object.keys(filters)
    .reduce((resolvedFilters, key) => {
      const value = filters[key]

      let keyIsValid = !!availableFilters[key]
      let valueIsValid

      // Remove malaria specific filters for other programs
      const selectedProgram = filters['programs']
      const isNotMalaria = selectedProgram && selectedProgram !== 'program:malaria'

      if (Object.keys(extraFilters).includes(key)) {
        // These keys are always valid:
        const filter = extraFilters[key]
        valueIsValid = filter.validator(value)

        if (valueIsValid) {
          return {
            ...resolvedFilters,
            [key]: value
          }
        } else {
          return resolvedFilters
        }
      }

      // do not resolve invalid filter
      if (!keyIsValid) {
        return resolvedFilters
      }

      const availableValues = availableFilters[key].values
      valueIsValid = withValueValidation ? availableValues.includes(value) : true

      // Time period has a period and a range part, so the check is a bit different
      if (key === 'time') {
        valueIsValid = withValueValidation ? availableValues.find(type => value.startsWith(type)) : true
      }

      // Filter and value are valid
      if (valueIsValid) {
        if (key === 'service') {
          return {
            ...resolvedFilters,
            [key]: isNotMalaria ? availableFilters[key].defaultValue : value
          }
        }
        if (key === 'sortType') {
          return {
            ...resolvedFilters,
            [key]: isNotMalaria ? availableFilters[key].defaultValue : value,
            'service': filters[key] !== 'daysOutOfStock' ? availableFilters['service'].defaultValue : filters['service']
          }
        }
        return {
          ...resolvedFilters,
          [key]: value
        }
      }

      // Filter key is valid, but value is not, apply the default value:
      return {
        ...resolvedFilters,
        [key]: availableFilters[key].defaultValue
      }
    }, {})

  // Ensure all (incl. default) filters are resolved
  return Object.keys(availableFilters).reduce((resolvedFilters, key) => {
    if (
      resolvedFilters[key] === undefined &&
      (availableFilters[key].defaultValue !== undefined || withUndefinedValues)
    ) {
      // The filter key has not been resolved, apply the default
      return {
        ...resolvedFilters,
        [key]: availableFilters[key].defaultValue
      }
    }
    return resolvedFilters
  }, resolvedFilters)
}

export function resolveQueryStringFilterState (queryString, { sorters, availableFilters, history, withValueValidation = true, withUndefinedValues = true }) {
  const queryMap = parseQueryString(queryString)
  const filters = resolveFilters(queryMap, availableFilters, withValueValidation, withUndefinedValues)
  const sortBy = sorters[filters.sortBy]
  if (history.location.search !== `?${queryStringifyFilters(filters)}`) {
    history.push({
      search: queryStringifyFilters(filters)
    })
  }

  return {
    filters,
    sortBy
  }
}

export function getSortingValues ({ filters }) {
  if (!filters || !filters.sortBy) return {}

  const values = filters.sortBy.split('-')
  return {
    sortBy: values[0],
    // uppercase is the standard in the indicator component
    sortDirection: values[1].toUpperCase()
  }
}
