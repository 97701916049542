const XLSX = require('@sheet/core')
const { merge, getRef, getRange, padArrayRight, padArrayLeft, createSumproductFormula, createSumFormula, createMultiplicationFormula, createModuloFormula, createDifferenceFormula } = require('./helpers')
const { COLUMN_WIDTH_WIDE, COLUMN_WIDTH_X_WIDE, COLUMN_WIDTH_NARROW, ROW_HEIGHT_DEFAULT, ROW_HEIGHT_HIDDEN, ROW_HEIGHT_HIGH, ROW_HEIGHT_X_HIGH, ROW_HEIGHT_XX_HIGH, STYLE_TEXT_CENTER, STYLE_TEXT_WHITE, STYLE_TEXT_BOLD, STYLE_BORDER, STYLE_BACKGROUND_FAWN, STYLE_BACKGROUND_FAWN_LIGHT, STYLE_BACKGROUND_BLUE, STYLE_BACKGROUND_BLUE_LIGHT, STYLE_BACKGROUND_GREEN, LOCATION_HEADERS, PADDING_LEFT, PADDING_TOP, PER_PRODUCT_HEADERS, PER_PRODUCT_COLUMNS, HEADLINE_FACILITY_PROFILE, PER_LOCATION_TOTALS_HEADERS, PER_PRODUCT_TOTALS_HEADERS } = require('./style-definitions')

// createWorksheetData transforms the data for a single into a set of
// attributes that can then be turned into a worksheet
exports.createWorksheetData = createWorksheetData
function createWorksheetData ({orders, productsById, locationsById} = {}) {
  const merges = []
  const formulae = {}
  const styles = {}
  let columnWidths = []
  let rowHeights = []
  let autofilter
  let freeze

  const sortProductsByFullName = (a, b) => {
    if (a.fullName < b.fullName) return -1
    if (a.fullName > b.fullName) return 1
    return 0
  }
  const emptyRow = padArrayRight([], Object.values(productsById).length)

  const products = Object.values(productsById).sort(sortProductsByFullName)

  const productCodes = products.map(product => {
    return padArrayRight([product.code], PER_PRODUCT_COLUMNS)
  }).reduce(merge, padArrayRight([], PADDING_LEFT))

  let currentlyAtRow = 2
  // for the second row, add the product names for each product and the header
  // for displaying facility data
  const productNames = products.map((product) => {
    return padArrayRight([product.fullName || product.name || product.id], PER_PRODUCT_COLUMNS)
  }).reduce(merge, padArrayRight([HEADLINE_FACILITY_PROFILE], PADDING_LEFT))

  styles[getRef(0, currentlyAtRow)] = Object.assign({}, STYLE_TEXT_CENTER, STYLE_TEXT_BOLD, STYLE_BORDER, STYLE_BACKGROUND_FAWN)

  // merge the "Facility Profile" header
  merges.push({
    s: getRef(0, currentlyAtRow),
    e: getRef(PADDING_LEFT - 1, currentlyAtRow + 1)
  })

  const productHeaderStyle = Object.assign({}, STYLE_TEXT_CENTER, STYLE_TEXT_BOLD, STYLE_BACKGROUND_BLUE, STYLE_BORDER)
  Object.keys(productsById).forEach((id, index) => {
    const columnNumber = index * PER_PRODUCT_COLUMNS + PADDING_LEFT
    merges.push({
      s: getRef(columnNumber, currentlyAtRow),
      e: getRef(columnNumber + PER_PRODUCT_COLUMNS - 1, currentlyAtRow)
    })
    styles[getRef(columnNumber, currentlyAtRow)] = productHeaderStyle

    const widths = Array.from({length: PER_PRODUCT_COLUMNS})
      .map(() => COLUMN_WIDTH_NARROW)
    columnWidths = [...columnWidths, ...widths]
  })

  currentlyAtRow++
  // for each product, display the basic units that the counts are using
  const productUnits = products.map(({basicUnit, unitOfIssue}) => {
    // if the unit of issue is greater than 1, we want to display it in the
    // product header, e.g. `12 packs` for specific products or `1000 tabs`
    // for generic ones
    const header = unitOfIssue > 1
      ? `${unitOfIssue} x ${basicUnit}`
      : basicUnit
    return padArrayRight([header], PER_PRODUCT_COLUMNS)
  }).reduce(merge, padArrayRight([], PADDING_LEFT))

  Object.keys(productsById).forEach((id, index) => {
    const columnNumber = index * PER_PRODUCT_COLUMNS + PADDING_LEFT
    merges.push({
      s: getRef(columnNumber, currentlyAtRow),
      e: getRef(columnNumber + PER_PRODUCT_COLUMNS - 1, currentlyAtRow)
    })
    styles[getRef(columnNumber, currentlyAtRow)] = productHeaderStyle
  })

  currentlyAtRow++
  // add the location headers, then add headers for each metric for each product
  // and prepend the headers for the per-location totals
  let productCountHeaders = Object.keys(productsById).reduce((acc) => {
    return [...acc, ...PER_PRODUCT_HEADERS]
  }, LOCATION_HEADERS)
  productCountHeaders = [
    ...productCountHeaders,
    ...PER_LOCATION_TOTALS_HEADERS
  ]

  // style the location headers and ensure correct column widths
  LOCATION_HEADERS.forEach((item, index) => {
    styles[getRef(index, currentlyAtRow)] = Object.assign({}, STYLE_TEXT_CENTER, STYLE_BACKGROUND_FAWN_LIGHT, STYLE_BORDER)
  })
  columnWidths = [
    COLUMN_WIDTH_WIDE,
    COLUMN_WIDTH_WIDE,
    COLUMN_WIDTH_WIDE,
    COLUMN_WIDTH_WIDE,
    COLUMN_WIDTH_X_WIDE,
    COLUMN_WIDTH_WIDE,
    COLUMN_WIDTH_WIDE,
    ...columnWidths
  ]

  // style the per-product headers
  Object.keys(productsById).forEach((productId, index) => {
    const x = PADDING_LEFT + index * PER_PRODUCT_COLUMNS
    ;[
      STYLE_BACKGROUND_BLUE_LIGHT,
      STYLE_BACKGROUND_BLUE_LIGHT,
      STYLE_BACKGROUND_FAWN_LIGHT,
      STYLE_BACKGROUND_GREEN,
      STYLE_BACKGROUND_GREEN
    ].forEach((colorStyling, index) => {
      styles[getRef(x + index, currentlyAtRow)] = Object.assign({}, STYLE_TEXT_CENTER, colorStyling, STYLE_BORDER)
    })
  })

  // enable Autofilter for all location data headers
  autofilter = getRange(0, currentlyAtRow, PADDING_LEFT - 1, currentlyAtRow)
  // freeze the location data and product headers
  freeze = getRef(PADDING_LEFT, currentlyAtRow + 1)

  // add background to "per location total" headers
  {
    const x = PADDING_LEFT + Object.keys(productsById).length * PER_PRODUCT_COLUMNS
    const style = Object.assign({}, STYLE_TEXT_CENTER, STYLE_BACKGROUND_BLUE_LIGHT, STYLE_BORDER)
    for (let i = 0; i < 4; i++) {
      const ref = getRef(x + i, currentlyAtRow)
      styles[ref] = style
    }
  }

  // ensure the correct row heights for all header cells
  rowHeights = [...rowHeights, ROW_HEIGHT_DEFAULT, ROW_HEIGHT_HIDDEN, ROW_HEIGHT_X_HIGH, ROW_HEIGHT_HIGH, ROW_HEIGHT_XX_HIGH]
  // generate all data for the per-location rows by joining location data
  // and counts. styling and formulae will be added in a second step
  const sortProductsByService = (a, b) => {
    if (a.serviceName < b.serviceName) return -1
    if (a.serviceName > b.serviceName) return 1
    return 0
  }
  let productCounts = orders.sort(sortProductsByService).map((order) => {
    let location = locationsById[order.destinationId]
    const { serviceName, funderName, orderType } = order
    const locationDataForRow = [
      (location.additionalData && location.additionalData.warehouseCode) || '',
      (location.location && location.location.state) || '',
      funderName,
      serviceName,
      location.name,
      location.location.lga,
      orderType
    ]

    return products.map((product) => {
      // add all blanks for products not on the order
      if (!order.products[product._id]) {
        return padArrayLeft([], PER_PRODUCT_COLUMNS)
      }

      const {requested = '', accepted = '', supplied = ''} = order.products[product._id]

      return [
        requested,
        accepted,
        supplied,
        '' // this will later accommodate the variance formula
      ]
    })
      .reduce(merge, locationDataForRow)
  })

  // add the formulae for summing up the counts per location
  productCounts.forEach((row) => {
    currentlyAtRow++
    rowHeights.push(ROW_HEIGHT_DEFAULT)
    // for each product there is a variance field that substracts the validated
    // quantity from the ordered quantity
    Object.keys(productsById).forEach((productId, productIndex) => {
      const columnNumber = productIndex * PER_PRODUCT_COLUMNS + PADDING_LEFT + 3
      formulae[getRef(columnNumber, currentlyAtRow)] =
        createDifferenceFormula(columnNumber - 2, currentlyAtRow, columnNumber - 3, currentlyAtRow)
    })

    const perLocationSumStyle = Object.assign(
      {},
      STYLE_BACKGROUND_BLUE,
      STYLE_TEXT_BOLD,
      STYLE_TEXT_WHITE,
      STYLE_TEXT_CENTER,
      STYLE_BORDER
    )
    const numProductRows = productCounts.length
    // the total count sums up the validated quantities, so there is an offset of
    // 3 (which is +2 because of zero-indexing) in these ranges
    const rangeX = PADDING_LEFT + 2
    const rangeXEnd = row.length - 2
    const formulaX = row.length + 1

    const countRangeStart = getRef(rangeX, currentlyAtRow)
    const countRangeEnd = getRef(rangeXEnd, currentlyAtRow)
    const countRange = `$${countRangeStart}:$${countRangeEnd}`

    const relevantCountRange = createModuloFormula(countRange, countRangeStart, PER_PRODUCT_COLUMNS)

    formulae[getRef(row.length, currentlyAtRow)] =
      createSumproductFormula(
        `--(${relevantCountRange})`,
        `--ISNUMBER(${countRange})`,
        `--(${countRange}>0)`
      )
    styles[getRef(row.length, currentlyAtRow)] = perLocationSumStyle

    // this references the rows that will contain the factors per product
    const factorsY = numProductRows + 2 + PADDING_TOP
    // total volume
    // TODO: use real volume values once they are available in master data
    const volumeRangeStart = getRef(rangeX, factorsY)
    const volumeRangeEnd = getRef(rangeXEnd, factorsY)
    const volumeRange = `$${volumeRangeStart}:$${volumeRangeEnd}`
    formulae[getRef(formulaX, currentlyAtRow)] =
      createSumproductFormula(
        `--(${relevantCountRange})`,
        countRange,
        `${volumeRange}`
      )
    styles[getRef(formulaX, currentlyAtRow)] = perLocationSumStyle

    // total weight
    // TODO: use real weight values once they are available in master data
    const weightRangeStart = getRef(rangeX, factorsY + 1)
    const weightRangeEnd = getRef(rangeXEnd, factorsY + 1)
    const weightRange = `$${weightRangeStart}:$${weightRangeEnd}`
    formulae[getRef(formulaX + 1, currentlyAtRow)] =
      createSumproductFormula(
        `--(${relevantCountRange})`,
        countRange,
        `${weightRange}`
      )
    styles[getRef(formulaX + 1, currentlyAtRow)] = perLocationSumStyle

    // total price
    // TODO: use real price data once it is available in master data
    const priceRangeStart = getRef(rangeX, factorsY + 2)
    const priceRangeEnd = getRef(rangeXEnd, factorsY + 2)
    const priceRange = `$${priceRangeStart}:$${priceRangeEnd}`
    formulae[getRef(formulaX + 2, currentlyAtRow)] =
      createSumproductFormula(
        `--(${relevantCountRange})`,
        countRange,
        `${priceRange}`
      )
    styles[getRef(formulaX + 2, currentlyAtRow)] = perLocationSumStyle
  })

  currentlyAtRow++
  // sum up all per-location totals to grand totals
  rowHeights.push(ROW_HEIGHT_DEFAULT)
  const perMeasureTotals = padArrayLeft([], Object.keys(productsById).length + PADDING_LEFT + 4)
  {
    const x = Object.keys(productsById).length * PER_PRODUCT_COLUMNS + PADDING_LEFT
    for (let i = 0; i < 4; i++) {
      formulae[getRef(x + i, currentlyAtRow)] =
        createSumFormula(x + i, PADDING_LEFT, x + i, currentlyAtRow - 1)
    }
  }

  const [
    totalQuantityHeader, unitVolumeHeader, unitWeightHeader, unitPriceHeader,
    totalVolumeHeader, totalWeightHeader, totalPriceHeader
  ] = PER_PRODUCT_TOTALS_HEADERS

  // add per product totals
  const perProductTotals = [
    ...padArrayLeft([totalQuantityHeader], PADDING_LEFT),
    // these fields will contain per-product formulae
    ...padArrayRight([], (productCounts[0] && productCounts[0].length) || 0 + 1)
  ]

  // add the factors for calculating product volume
  const unitVolumeData = Object.values(productsById).map((product) => {
    const factor = product.unitVolume || 0 // if the value is not available, we use 0 so formulae keep working
    return padArrayRight([factor, factor, factor, factor], PER_PRODUCT_COLUMNS)
  }).reduce(merge, padArrayLeft([unitVolumeHeader], PADDING_LEFT))

  // add the factors for calculating product weight
  const unitWeightData = Object.values(productsById).map((product) => {
    const factor = product.unitWeight || 0 // if the value is not available, we use 0 so formulae keep working
    return padArrayRight([factor, factor, factor, factor], PER_PRODUCT_COLUMNS)
  }).reduce(merge, padArrayLeft([unitWeightHeader], PADDING_LEFT))

  // add the factors for calculating product price
  const unitPriceData = Object.values(productsById).map((product) => {
    const factor = product.unitPrice || 0 // if the value is not available, we use 0 so formulae keep working
    return padArrayRight([factor, factor, factor, factor], PER_PRODUCT_COLUMNS)
  }).reduce(merge, padArrayLeft([unitPriceHeader], PADDING_LEFT))

  // these will contain the formulae for calculating per-product volume totals
  const emptyFieldsPerProduct = Object.keys(productsById).map((productId) => {
    return padArrayRight([], PER_PRODUCT_COLUMNS)
  })
  const totalVolumeData = emptyFieldsPerProduct.reduce(merge, padArrayLeft([totalVolumeHeader], PADDING_LEFT))
  // these will contain the formulae for calculating per-product weight totals
  const totalWeightData = emptyFieldsPerProduct.reduce(merge, padArrayLeft([totalWeightHeader], PADDING_LEFT))
  // these will contain the formulae for calculating per-product price totals
  const totalPriceData = emptyFieldsPerProduct.reduce(merge, padArrayLeft([totalPriceHeader], PADDING_LEFT))

  const totalQuantityY = currentlyAtRow + 1
  // there are 7 rows below the location rows that will sum up values per
  // product
  for (let i = 0; i < 7; i++) {
    currentlyAtRow++
    const style = i === 0
      ? Object.assign(
        {},
        STYLE_TEXT_CENTER,
        STYLE_TEXT_BOLD,
        STYLE_BACKGROUND_FAWN,
        STYLE_BORDER
      )
      : Object.assign(
        {},
        STYLE_TEXT_CENTER,
        STYLE_BACKGROUND_FAWN,
        STYLE_BORDER
      )

    styles[getRef(PADDING_LEFT - 1, currentlyAtRow)] = style
    rowHeights.push(ROW_HEIGHT_X_HIGH)

    Object.keys(productsById).forEach((productId, index) => { // eslint-disable-line no-loop-func
      const x = PADDING_LEFT + index * PER_PRODUCT_COLUMNS

      for (let j = 0; j < PER_PRODUCT_COLUMNS; j++) {
        styles[getRef(x + j, currentlyAtRow)] = Object.assign({}, STYLE_TEXT_CENTER, STYLE_BORDER)
      }

      switch (i) {
        case 0: // total quantity
          for (let j = 0; j < PER_PRODUCT_COLUMNS - 1; j++) {
            formulae[getRef(x + j, currentlyAtRow)] =
              createSumFormula(x + j, PADDING_TOP, x + j, orders.length + 4)
          }
          break
        case 1: // unit weight
        case 2: // unit volume
        case 3: // unit price
          // these will just contain the factor values
          // so no formula needs to be inserted
          break
        case 4: // total weight
        case 5: // total volume
        case 6: // total price
          for (let j = 0; j < PER_PRODUCT_COLUMNS - 1; j++) {
            formulae[getRef(x + j, currentlyAtRow)] =
              createMultiplicationFormula(x + j, totalQuantityY, x + j, currentlyAtRow - 3)
          }
          break
        default:
          break
      }
    })
  }

  // Following the template the sheet should start with an emtpy row
  const rowData = [
    emptyRow,
    productCodes,
    productNames,
    productUnits,
    productCountHeaders,
    ...productCounts,
    perMeasureTotals,
    perProductTotals,
    unitVolumeData,
    unitWeightData,
    unitPriceData,
    totalVolumeData,
    totalWeightData,
    totalPriceData
  ]

  return {rowData, merges, formulae, styles, columnWidths, rowHeights, autofilter, freeze}
}

// buildWorksheetFromData is responsible for creating an XLSX worksheet
// from the given data. The data is expected to be in the shape of
// the return value of createWorksheetData
const buildWorksheetFromData = (data) => {
  const {
    autofilter,
    freeze,
    rowHeights,
    columnWidths,
    rowData,
    merges,
    formulae,
    styles
  } = data
  const worksheet = XLSX.utils.aoa_to_sheet([
    ...rowData
  ])

  worksheet['!merges'] = merges
  worksheet['!autofilter'] = { ref: autofilter }
  worksheet['!freeze'] = freeze

  worksheet['!cols'] = worksheet['!cols'] || []
  columnWidths.forEach((width, index) => {
    worksheet['!cols'][index] = { wpx: width }
  })

  worksheet['!rows'] = worksheet['!rows'] || []
  rowHeights.forEach((height, index) => {
    if (height === ROW_HEIGHT_HIDDEN) {
      worksheet['!rows'][index] = { hidden: true }
    } else {
      worksheet['!rows'][index] = { hpx: height }
    }
  })

  Object.keys(formulae).forEach((ref) => {
    worksheet[ref] = { f: formulae[ref] }
  })

  Object.keys(styles).forEach((ref) => {
    worksheet[ref].s = styles[ref]
  })

  return worksheet
}

// buildWorkbook creates an XLSX workbook from the given data. Data is to be
// expected in the shape returned by getOrderExportData
exports.buildWorkbook = buildWorkbook
function buildWorkbook (worksheetData) {
  const workbook = XLSX.utils.book_new()
  const worksheet = buildWorksheetFromData(worksheetData)
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders')
  return XLSX.write(workbook, {
    bookType: 'xlsx',
    bookSST: false,
    type: 'array',
    cellStyles: true
  })
}
