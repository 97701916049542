import React from 'react'
import classnames from 'classnames'
import { RETAILER_SERVICES } from '../../../../van-shared/components/ServiceCard/service-constants'

const { CANCELLED } = RETAILER_SERVICES

export const ServiceListCardStatus = ({ status, colorVariant }) => {
  const isCancelled = status.toLowerCase() === CANCELLED

  return (
    <div className={classnames(
      'service-list-card-status',
      {'service-list-card-status--cancelled': isCancelled}
    )}>
      {status}
    </div>
  )
}
