import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const ShipmentPrescriptionsContentsList = ({
  items,
  className
}) => {
  return (
    <div
      className={classnames(
        'delivery-prescription-contents',
        className
      )}
    >
      <div className='delivery-prescription-contents__header'>Prescription notes</div>

      <ul className='delivery-prescription-contents__list'>
        {items.map(({name, prescription, code}) => {
          return (
            <li className='delivery-prescription-contents__list-item' key={code}>
              <div className='delivery-prescription-contents__prescription-product-name'>{name}</div>
              <div className='delivery-prescription-contents__prescription-notes'>{prescription}</div>
            </li>
          )
        })}
      </ul>

    </div>
  )
}

ShipmentPrescriptionsContentsList.propTypes = {
  /**
   * Array of delivery items (can be generated with makeDeliveryItems())
   */
  items: PropTypes.array
}

ShipmentPrescriptionsContentsList.defaultProps = {
  items: []
}

export default ShipmentPrescriptionsContentsList
