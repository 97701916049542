import React, { Component } from 'react'
import flowRight from 'lodash/flowRight'
import get from 'lodash/get'
import { Link } from 'react-router-dom'

import { Banner, Button, Card, DownloadButton, Loading, Page, SubscriptionTypeLabel, Text, UppercaseLabel } from '@fielded/shared-ui'
import { Products } from '@fielded/shared-ui/src/icons'

import { DIRECT_ORDER_TYPES } from '@fielded/fs-api/lib/allocation/config'

import { userIsAuthorised } from '../../../van-shared/utils/auth'
import withConfig from '../../../van-shared/hoc/withConfig'
import { withUser } from '../../../common/AuthenticationProvider'
import { ledgerRowTools } from '@fielded/fs-api/lib/tools'
import download from '../../../common/utils/ledger-download'
import { withApi } from '../../../common/ApiProvider'
import { getNextCount } from '../../retailer/common/api'

import LedgerHeader from './LedgerHeader'
import LedgerTable from './LedgerTable'
import LedgerBalanceTable from './LedgerBalanceTable'
import { parseSearchParams, shallowEquals } from './utils'
import { buildLedgerUrl } from '../app/routes-urls'

import withOffline from '@fielded/shared-ui/src/common/offline'

const OfflineWarning = () => {
  return (
    <div className='ledger-offline'>
      <Banner
        inline
        type='warning'
      >
        <h2>Offline</h2>
        <p>
          To see the ledger history, make sure you are on a very reliable connection.
        </p>
      </Banner>
    </div>
  )
}

const getDeliveryText = (frequency) => {
  frequency = frequency || false
  switch (frequency.toString()) {
    case '4':
      return 'every 4 weeks'
    case '3':
      return 'every 3 weeks'
    case '2':
      return 'every 2 weeks'
    case '1':
      return 'every week'
    default: // when we don't know
      return 'every delivery'
  }
}

export class LedgerContainer extends Component {
  state = {
    loaded: false,
    filteredRows: [],
    filteredBalances: [],
    unfilteredRows: [],
    unfilteredBalances: {},
    locationsMap: {},
    viewOption: 'all',
    isOnline: true
  }

  async componentDidMount () {
    const { api, user, config, match: { params: { serviceId, locationId, productId } }, history, isOnline } = this.props
    const isRetailer = userIsAuthorised(user, 'feature:userRole:pharmacyUser')
    const searchParams = parseSearchParams(history.location.search)
    const filters = searchParams
    const [service, product, location, subscription, nextVisit] = await Promise.all([
      api.service.get(serviceId),
      api.product.get(productId),
      api.location.get(locationId),
      api.allocation.getConfiguration({ facilityId: locationId }),
      isRetailer ? getNextCount(config) : {} // This is not available via endpoint for non-retail users
    ])

    let productSubscription = get(subscription, `products.${productId}`)
    productSubscription = {
      count: get(productSubscription || {}, 'forecast.directOrder'),
      type: !productSubscription ? 'not_subscribed' : get(productSubscription, 'forecast.type', DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL),
      delivery: get(nextVisit, 'local.funder.deliveryFrequencyWeeks', false)
    }

    const rows = await api.ledger.adapter.getLocationSkuHistory(locationId, productId)

    const {items, locations, balances} = ledgerRowTools.transformLocationSkuHistory(locationId, rows)
    const locationsMap = await api.location.getLocationsViaIds(locations)

    let allReservations = null
    let reservations = null
    if (!isRetailer) {
      allReservations = await api.ledger.adapter.getSkuReservations(locationId, productId)
      reservations = allReservations.total
    }

    const unfilteredRows = ledgerRowTools.decorateWithMasterData(items, locationsMap)
    const withFilters = ledgerRowTools.applyFilters(filters, balances, unfilteredRows)

    const isExternalPackPoint = location.level === 'pack-point' && location.additionalData.classification === 'External Distribution Center'

    if (productSubscription.type === DIRECT_ORDER_TYPES.PAY_ON_DELIVERY && !isExternalPackPoint) {
      withFilters.balances = {
        overallBalance: 'N/A'
      }
    }

    this.setState({
      loaded: true,
      unfilteredRows,
      unfilteredBalances: balances,
      filteredRows: items,
      filteredBalances: withFilters.balances,
      locationsMap,
      offline: !isOnline,
      productSubscription,
      service,
      product,
      location,
      filters,
      isRetailer,
      reservations,
      reservationRows: allReservations ? allReservations.rows : null
    })
  }

  componentDidUpdate () {
    if (!this.state.loaded) {
      return
    }
    const searchParams = parseSearchParams(this.props.history.location.search)
    const filters = searchParams
    const { unfilteredBalances, unfilteredRows } = this.state
    if (!shallowEquals(filters, this.state.filters)) {
      this.setState({ loaded: false })
      setTimeout(() => {
        const { rows, balances } = ledgerRowTools.applyFilters(filters, unfilteredBalances, unfilteredRows)
        this.setState({ filters, filteredRows: rows, filteredBalances: balances, loaded: true })
      }, 10)
    }
  }

  onClickDownload = event => {
    event.preventDefault()
    const { product, location } = this.state
    download(this.state.filteredRows, `${product.name} ${location.name} ledger `)
  }

  onClickProductDownload = event => {
    event.preventDefault()
    const { product, location, reservationRows } = this.state

    // Define headers for the CSV file
    const headers = [
      {
        name: 'Location',
        key: 'location'
      },
      {
        name: 'Quantity',
        key: 'quantity'
      },
      {
        name: 'Type',
        key: 'type'
      },
      {
        name: 'Id',
        key: 'id'
      }
    ]

    // Download the combined reservations data as a CSV file
    download(reservationRows, `${product.name} ${location.name} Reservations`, headers)
  }

  changeViewOption = (option) => {
    this.setState({
      viewOption: option
    })
  }

  render () {
    const {
      match: { params: { serviceId, locationId, productId } },
      history,
      rootHistory,
      config,
      user
    } = this.props

    const {
      filters,
      product,
      location,
      loaded,
      filteredRows,
      locationsMap,
      filteredBalances,
      offline,
      productSubscription,
      viewOption,
      isRetailer,
      reservations
    } = this.state

    let baseLedgerUrl = buildLedgerUrl({locationId, serviceId, productId})

    const isFP = userIsAuthorised(user, 'feature:userRole:fp')
    const isExternalPlanner = userIsAuthorised(user, 'feature:userRole:external-planner')
    const appName = config.name
    const appAbbr = config.abbr || config.name
    let transferAllowed = true
    let downloadAllowed = true
    let reservationsDownload = reservations && reservations > 0
    if (isRetailer) {
      transferAllowed = false
      downloadAllowed = false
      reservationsDownload = false
    } else if (isFP) {
      transferAllowed = false
    } else if (isExternalPlanner) {
      transferAllowed = false
    }

    if (!loaded) return <Loading />

    const balanceTable = {
      header: { 'text': 'Quantity on shelf', value: filteredBalances.overallBalance },
      body: filteredBalances.overallBalance === 'N/A' ? [] : [
        { 'text': 'Pharmacy Balance', value: filteredBalances.partnerBalance },
        { 'text': `${appName} Balance`, value: filteredBalances.shelflifeBalance }
      ]
    }

    if (!isRetailer) {
      balanceTable.body.push({ 'text': 'Reservations', value: reservations })
    }

    const commonPanelProps = {
      narrow: isRetailer,
      alignLeft: isRetailer
    }

    return (
      <div className='ledger-page'>
        {!isRetailer && (
          <LedgerHeader
            history={history}
            productName={product.name}
            filters={filters}
            baseLedgerUrl={baseLedgerUrl}
            locationsMap={locationsMap}
            config={config}
          />
        )}
        {isRetailer && (
          <Page.HeaderNew
            title='Products'
            subtitle='Product Ledger'
            icon={<Products />}
            history={history}
          />
        )}
        <Page.Panel {...commonPanelProps}>
          {isRetailer && (
            <Page.Panel.Header
              title={product.name}
            />
          )}
          <Page.Panel.Section>
            {offline && <OfflineWarning />}
            <Card
              semitight
              rounded
            >
              <Card.Content>
                <div className='ledger-info'>
                  <div className='ledger-info__basics'>
                    <Text size='xlarge' bold>{location.name}</Text>
                    <div className='ledger-info__item'>
                      <UppercaseLabel>
                        Current Subscription Type:
                      </UppercaseLabel>
                      <SubscriptionTypeLabel subscriptionType={productSubscription.type} />
                    </div>
                    {productSubscription.type === DIRECT_ORDER_TYPES.PAY_ON_DELIVERY && (
                      <div className='ledger-info__item'>
                        <UppercaseLabel>
                          Recurring Supply Quantity:
                        </UppercaseLabel>
                        {productSubscription.count} units {getDeliveryText(productSubscription.delivery)}
                      </div>
                    )}
                    <div
                      className='ledger-info__actions'
                    >
                      {transferAllowed && location.tracksPartnerBalances && (
                        <Button
                          colorVariant='brand'
                          className='ledger__transfer'
                          fill='full'
                          component={Link}
                          alignEdge='box'
                          to={`/transfer/${serviceId}/${locationId}/${productId}`}
                        >
                          Transfer stock
                        </Button>
                      )}
                      {downloadAllowed && (
                        <DownloadButton
                          onClick={this.onClickDownload}
                          size='regular'
                          className='ledger__transfer'
                        />
                      )}
                    </div>
                  </div>
                  <div className='ledger-info__balances'>
                    <LedgerBalanceTable
                      balances={filteredBalances}
                      reservations={!isRetailer && reservations}
                      appName={appName}
                    />
                    {reservationsDownload ? (
                      <DownloadButton
                        onClick={this.onClickProductDownload}
                        size='small'
                      >
                        Reservations
                      </DownloadButton>
                    ) : null }
                  </div>
                </div>
              </Card.Content>
            </Card>
          </Page.Panel.Section>

          <Page.Panel.Section>
            <LedgerTable
              rows={filteredRows}
              productId={product._id}
              history={history}
              rootHistory={rootHistory}
              baseLedgerUrl={baseLedgerUrl}
              filters={filters}
              viewOption={viewOption}
              changeLedgerViewingOption={this.changeViewOption}
              appName={appName}
              appAbbr={appAbbr}
            />
            {filteredRows.length === 0 && <div className='ledger__no-data'>No data found.</div>}
          </Page.Panel.Section>
        </Page.Panel>
      </div>
    )
  }
}

const withHOCs = flowRight(
  withApi,
  withOffline,
  withConfig,
  withUser
)

export default withHOCs(LedgerContainer)
