class NotificationsApiAdapter {
  constructor (restAdapter) {
    if (restAdapter) {
      this.restAdapter = restAdapter
    }
  }

  newOrderRequest (data) {
    return this.restAdapter.create('notifications/send-email-to-planners', data)
  }
}

module.exports = NotificationsApiAdapter
