const leadPaths = {
  noDB: {
    products: '/retailer/lead/products',
    finances: '/retailer/lead/finances',
    service: '/retailer/lead/service'
  },
  withDB: {
    products: '/retailer/lead/products',
    finances: '/retailer/finances',
    service: '/retailer/service'
  }
}

export const getLeadPath = (item, hasUserDB) => {
  const subSection = hasUserDB ? 'withDB' : 'noDB'

  return leadPaths[subSection][item]
}
