import React from 'react'

export const Orientation = () => {
  return (
    <svg stroke='currentColor' fill='currentColor' strokeWidth='0' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34.84 35.12'>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path d='M21.42,33.11a2.07,2.07,0,0,1-1.6-.68L9.09,21.69c-2.16-2.16-4.3-4.33-6.46-6.47-1.25-1.24-1.25-2.38,0-3.61l8.82-8.84c1.22-1.22,2.37-1.19,3.6,0,3.44,3.47,6.9,6.91,10.35,10.36L32,19.81a2.19,2.19,0,0,1,0,3.53l-8.9,8.93A2.22,2.22,0,0,1,21.42,33.11Zm9.28-11.5c-.19-.2-.41-.46-.64-.69Q21.89,12.73,13.71,4.54c-.31-.32-.49-.34-.81,0Q8.63,8.84,4.32,13.11c-.27.27-.32.44,0,.73.85.81,1.66,1.66,2.5,2.49q6,6,12,12c.88.88,1.75,1.78,2.61,2.66Z' />
          <path d='M16.61,0c1.09.07,2.22.06,3.32.24A17.57,17.57,0,0,1,34.78,15.32c.12.78.11.79-.64.79-1.42,0-1.39,0-1.67-1.39a15,15,0,0,0-8-10.79.65.65,0,0,0-.89.12,18,18,0,0,1-1.36,1.26.58.58,0,0,1-.54,0C19.94,3.63,18.26,1.92,16.56.2,16.56.19,16.57.16,16.61,0Z' />
          <path d='M0,19c.6,0,1.05,0,1.51,0s.58.14.64.56a15.15,15.15,0,0,0,8.18,11.65.66.66,0,0,0,.89-.14c.51-.5,1-1,1.54-1.43l5.41,5.45A17.48,17.48,0,0,1,0,19Z' />
        </g>
      </g>
    </svg>
  )
}
