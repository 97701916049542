const { parseCoordinates } = require('./coordinate-helper')

const validateOtherDocs = (locationDoc) => {
  const errors = []
  // validate contacts
  Object.keys(locationDoc.contacts).forEach(program => {
    if (!locationDoc.contacts[program]) {
      errors.push(`contact for ${program} not found`)
    }
    const contactFields = ['name', 'phone']
    const missingContactField = contactFields.find(field => !locationDoc.contacts[program][field])
    if (missingContactField) {
      errors.push(`${missingContactField} not found for ${program} in contact`)
    }
  })

  // validate additionalData doc
  const additionalDataFields = ['physicalAddress', 'ward']
  const missingADFields = additionalDataFields.find(field => !locationDoc.additionalData[field])
  if (missingADFields) errors.push(`${missingADFields} not found in additionalData`)

  // partial entry for location longitude & latitude not allowed
  const coordinates = parseCoordinates(locationDoc.additionalData.latLng)
  if ((!coordinates.lat && coordinates.long) || (coordinates.lat && !coordinates.long)) {
    errors.push(`Both longitude and latitude field is required for coordinates`)
  }

  // validate locations doc
  const locationFields = ['zone', 'state', 'lga', 'sdp']
  const missingLocationField = locationFields.find(field => !locationDoc.location[field])
  if (missingLocationField) errors.push(`${missingLocationField} not found in location`)
  return errors
}

const validateProgramsDoc = (locationDoc, funderList) => {
  const errors = []
  // validate services
  if (!locationDoc.services.length) errors.push('At least one service is required')

  // validate programs, funders and implementing partners
  if (!locationDoc.programs.length) {
    errors.push('At least one program is required')
  } else {
    locationDoc.programs.forEach(programDoc => {
      programDoc.services.forEach(service => {
        const funder = funderList.find(funder => funder._id === service.funderId)
        if (!funder) return errors.push(`Assigned Funder for ${service.id} not found in system`)
        const newImplementingPartner = service.implementingPartnerId
        const findImplementingPartner = funder.programs[programDoc.id] &&
          funder.programs[programDoc.id].implementingPartners.find(ip => ip._id === newImplementingPartner)
        if (!findImplementingPartner) errors.push(`Implementing partner for ${service.id} does not match ${funder._id}`)
      })
    })
  }
  return errors
}

exports.sanitizeDoc = async (existingLocationDocs, locationDoc, funderList) => {
  let errors = {
    row: locationDoc.row
  }

  // return fullName for debugging purposes
  errors.fullName = !locationDoc.fullName ? 'Name is required' : locationDoc.fullName

  // validate services, programs, funders and implementing partners
  const programErrors = validateProgramsDoc(locationDoc, funderList)
  const otherDocsErrors = validateOtherDocs(locationDoc)
  const lgaErrors = await lgaChanges(existingLocationDocs, locationDoc)
  const errorDocs = lgaErrors ? programErrors.concat(otherDocsErrors, lgaErrors) : programErrors.concat(otherDocsErrors)

  delete locationDoc.row
  return {
    ...errors,
    errorDoc: errorDocs || [],
    changes: locationDoc
  }
}

const lgaChanges = async (existingLocationDocs, doc) => {
  const locationId = doc._id
  const errors = []
  // validate new doc against existing doc
  if (locationId) {
    const currentLocation = existingLocationDocs.find(location => location._id === locationId)
    if (currentLocation) {
      const lgaChange = currentLocation.location.lga === doc.location.lga
      if (!lgaChange) {
        errors.push('LGA can not be changed for a location')
        return errors
      }
    }
  }
}
