const { list: listPrograms } = require('./../../../program')

exports.getAll = function (state, programId) {
  return listPrograms(state)
    .then(programs => {
      const program = programs.find(program => program.id === programId)
      if (!program) {
        return Promise.reject(new Error(`Services with id ${programId} not found`))
      }
      const services = program.services
      const found = services.map(service => {
        // Attach the program fields except service to the service object
        const p2 = Object.assign({}, program)
        delete p2.services
        return Object.assign({}, service, {program: p2})
      })
      return found
    })
}
