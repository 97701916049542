const { getByIds: getLocationsByIds } = require('../../location')
const { list: listPrograms } = require('../../program')
const { getAll: getAllServices, get: getService } = require('../../service')
const docToReport = require('./doc-to-report')
const { createV1IdsBetweenDates, isPeriodId, periodIdToDate } = require('../tools/ids')
const getIdVersion = require('../tools/get-id-version')
const { parse } = require('../../tools/smart-id')
const stockCountIdToLocationProperties = require('../../tools/stock-count-id-to-location-properties')
const getPeriodFromProgram = require('../tools/get-period-from-program')
const getPreviousPeriodFromProgram = require('../tools/get-previous-period')
const locationTools = require('../../location/tools')

const DELIMITER = '%'
const buildQuery = (user, locations, services, startDate, endDate, idVersion, ignoreService) => {
  const query = {
    // V1: query by ids
    ids: new Map(),
    // V2: query by keys on a view
    // (we need to keep track of which ones we did not receive)
    expectedReports: []
  }
  const v2Keys = new Set()

  let currentDate = isPeriodId(endDate) ? periodIdToDate(endDate) : new Date(endDate)
  let sinceDate = isPeriodId(startDate) ? periodIdToDate(startDate) : new Date(startDate)

  services.forEach(service => {
    let period = getPeriodFromProgram(service.program, currentDate, true)

    do {
      locations.forEach(rawLocation => {
        const location = locationTools.docToEntity({
          doc: rawLocation,
          date: period.effectiveStartDate.toJSON(),
          fundersFilter: user.funders
        })

        if (!ignoreService && !location.services.includes(service.id)) {
          return
        }

        if (idVersion === 1) {
          const ids = createV1IdsBetweenDates({
            locationId: location._id,
            service,
            startDate: period.effectiveStartDate.toJSON(),
            endDate: period.effectiveEndDate.toJSON()
          })

          for (let id of ids) {
            query.ids.set(id, {locationId: location._id, service})
          }
        } else if (idVersion === 2) {
          const reportKey = `${period.id}-${location._id}-${service.id}`
          v2Keys.add(`${period.id}${DELIMITER}${location._id}`)

          query.expectedReports.push({
            reportKey: reportKey,
            periodId: period.id,
            locationId: location._id,
            service: service,
            key: `${period.id}${DELIMITER}${location._id}`
          })
        }
      })

      period = getPreviousPeriodFromProgram(service.program, period)
    } while (period.effectiveEndDate > sinceDate)
  })

  // V2 Keys:
  query.keys = Array.from(v2Keys).map(s => s.split('%'))

  return query
}

async function runQuery (state, query, queryOptions) {
  const ids = [...query.ids.keys()]
  // Get docs which ids we know in advance
  const idsResult = await state.dal.report.read(state, ids, queryOptions)

  const allRows = []
  // Add ids query rows with location and service info
  for (const row of idsResult.rows) {
    const {locationId, service} = query.ids.get(row.key)
    row.locationId = locationId
    row.service = service
    allRows.push(row)
  }

  return allRows
}

const generateKey = (id) => {
  // extract serviceId, locationId and periodId out of the stock count id
  // put it together in a way we can match a view result up with an expected report:
  // needs to work wth both:
  // zone:south-west:name:lagos:bimonth:2018-M01:program:tb:service:dstb
  // zone:south-west:name:lagos:program:tb:service:dstb:period:2018-M01:date:2018-01-03T13:00:00.000Z
  let { period, week, bimonth, program, service } = parse(id)
  const location = stockCountIdToLocationProperties(id)
  // period = V2, week/month: V1
  const periodId = period || week || bimonth

  // Immunization program ids do not show up on counts:
  if (!program && !service) {
    program = 'immunization'
    service = 'immunization'
  }

  return `${periodId}-${location.id}-program:${program}:service:${service}`
}

async function runQueryV2 (state, query, queryOptions) {
  const keysResult = await state.dal.report.readView(state, query.keys, queryOptions)
  // The query is gonna look like this:
  /*
  {
    '${periodId}-${locationId}-${serviceId}': {
      key: [...] // keys to query the view with
      locationId: locationId,
      service: service
    }
  }
  // We're getting a response like this:
  // Note that 'not_found' will just be that the rows are not there
  // if there is an ID it means we found a doc.
  {"total_rows":7001,"offset":38,"rows":[
    {"id":"country:ke:state:nairobi:sdp:blueguard-donholm:week:2019-W16:program:shelflife:service:kenya","key":["2019-W16","country:ke:state:nairobi:sdp:blueguard-donholm"],"value":null},
    {"id":"country:ke:state:nairobi:sdp:blueguard-donholm:week:2019-W17:program:shelflife:service:kenya","key":["2019-W17","country:ke:state:nairobi:sdp:blueguard-donholm"],"value":null},
    {"id":"country:ke:state:nairobi:sdp:blueguard-donholm:week:2019-W18:program:shelflife:service:kenya:date:1","key":["2019-W18","country:ke:state:nairobi:sdp:blueguard-donholm"],"value":null}
    {"id":"country:ke:state:nairobi:sdp:blueguard-donholm:week:2019-W18:program:shelflife:service:kenya:date:2","key":["2019-W18","country:ke:state:nairobi:sdp:blueguard-donholm"],"value":null}
  ]}
  */

  /*
   * There's a difference between "alldocs with keys" and "view with keys":
   * keys that are not found are just missing from the response when we query a view
   * so, we need to figure out what is missing from the response:
   * and I do this by matching up the results against the expected reports:
   */
  const results = []
  for (const row of keysResult.rows) {
    // this means error or offline
    // this is not from couchdb response but added internally:
    if (!row.id && row.error) {
      const key = row.key.join(DELIMITER)
      query.expectedReports.filter(r => r.key === key)
        .forEach(report => {
          report.found = false
          report.error = row.error
        })
      continue
    } else if (!row.id) {
      continue
    }

    const periodLocationService = generateKey(row.id)
    const expectedReport = query.expectedReports.find(r => r.reportKey === periodLocationService)
    // Querying this view gives us services that we might not have access to
    // (not right funder etc), this filters those out
    if (!expectedReport) {
      continue
    }

    const { locationId, service, periodId } = expectedReport
    expectedReport.found = true

    results.push({
      locationId,
      service,
      periodId,
      key: row.id,
      doc: row.doc
    })
  }

  query.expectedReports
  // 2. skip all wanted rows that have been fullfilled
    .filter(reportItem => !reportItem.found)
  // 3. add all 'not_found' rows, or use existing error like 'offline'
    .forEach(reportItem => {
      reportItem.error = reportItem.error || 'not_found'
      reportItem.key = reportItem.key.split(DELIMITER)
      results.push(reportItem)
    })

  return results
}

function cleanupRows (rows) {
  return rows
    // Filter out rows with not found or offline errors and deleted docs
    .filter(row => {
      if (row.error) {
        if (row.error === 'not_found') {
          return false
        }
        const err = new Error('Error fetching report: ' + row.key)
        err.status = row.error
        throw err
      }
      if (row.value && row.value.deleted) {
        return false
      }
      return true
    })
}

module.exports = async (state, {
  locations = null, // list of locations, either locations or locationIds can be used
  locationIds = null, // see above
  // defaults to user location

  services = null, // list of services, either services or servicesIds can be passed
  serviceIds = null, // see above
  // defaults to all services of all locations

  startDate = null, // start date, defaults to 2017
  endDate = null, // end date, defaults to now

  queryOptions = null, // { include_docs: true, localOnly: true }
  entityOptions = null, // see doc-to-report
  ignoreService = false // This gets all reports within a requested time frame ignoring service constraints. It's used by SL.
}) => {
  if (locations) {
    locationIds = locations.map(l => l._id)
  }

  if (!locationIds) {
    locationIds = [state.user.location.id]
  }

  locations = await getLocationsByIds(state, locationIds, startDate, { raw: true })
  if (locations.length === 0) {
    return []
  }

  if (services) {
    // user passed in services, do nothing, loaded already
  } else if (serviceIds) {
    services = await Promise.all(serviceIds.map(getService.bind(null, state)))
  } else if (state.user.programs) {
    for (let programId of state.user.programs) {
      services = services || []
      services = services.concat(await getAllServices(state, programId))
    }
  } else {
    // Last resort: list for all services,
    // mainly in lambdas / planning_api_endpoint:
    for (let program of await listPrograms(state)) {
      services = services || []
      services = services.concat(await getAllServices(state, program.id))
    }
  }

  startDate = startDate ? new Date(startDate) : new Date('2017-01-01T00:00:00.000Z')
  endDate = endDate ? new Date(endDate) : new Date()

  // Look up ID Version on programs:
  // a program has the same id version for all services, but if it's multi-program it can be different.
  // V2 queries work for V1 docs too, but are a little bit slower.
  const idVersion = Math.max.apply(null, services.map(service => getIdVersion(service)))
  const query = buildQuery(state.user, locations, services, startDate, endDate, idVersion, ignoreService)
  let rows
  if (idVersion === 1) {
    rows = await runQuery(state, query, queryOptions)
  } else if (idVersion === 2) {
    rows = await runQueryV2(state, query, queryOptions)
  } else {
    throw new Error(`Invalid id version ${idVersion}`)
  }

  if (entityOptions && entityOptions.rawRows) {
    // Return just the rows from the query
    return rows
  }

  rows = cleanupRows(rows)

  if (entityOptions && entityOptions.rawDocs) {
    // Return raw documents
    return rows.map(r => r.doc)
  }

  // Return documents converted to report entities
  return Promise.all(
    rows.map(row => docToReport(state, {doc: row.doc, service: row.service, entityOptions}))
  )
}

module.exports.cleanupRows = cleanupRows
module.exports.runQuery = runQuery
