import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'

const history = createBrowserHistory()

let initialized = false
export default (config, user) => {
  const { sentryPublicKey, sentryProjectId } = config
  if (!(
    process.env.NODE_ENV === 'production' &&
    sentryPublicKey &&
    sentryProjectId
  )) {
    return
  }

  Sentry.init({
    dsn: `https://${sentryPublicKey}@sentry.io/${sentryProjectId}`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      }),
      new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: 'field-supply@' + process.env.REACT_APP_BUILD_VERSION
  })

  if (user) {
    Sentry.setUser({username: user.name})
  }

  initialized = true
}

export const setUserInSentry = (user) => {
  if (user) {
    Sentry.setUser({username: user.name})
  }
}

export const logError = (error, extraInfo) => {
  if (initialized) {
    Sentry.withScope(scope => {
      scope.setExtras(extraInfo)
      Sentry.captureException(error)
    })
  }
}
