import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Page from '../Page'

import WizardStepHeader from './WizardStepHeader'
import WizardStepContent from './WizardStepContent'
import WizardStepActions from './WizardStepActions'

const WizardStep = ({
  status,
  children,
  render
}) => {
  const classNames = classnames(
    'wizard-step',
    { 'wizard-step--status-info': status === 'info' },
    { 'wizard-step--status-warning': status === 'warning' },
    { 'wizard-step--status-danger': status === 'danger' },
    { 'wizard-step--status-success': status === 'success' }
  )

  if (render) {
    return (
      <div className={classNames}>
        {render({ status, children })}
      </div>
    )
  }

  return (
    <Page.Panel
      withBackground
      status={status}
    >
      <div className={classNames}>
        {children}
      </div>
    </Page.Panel>
  )
}

WizardStep.displayName = 'Wizard.Step'

WizardStep.Header = WizardStepHeader
WizardStep.Content = WizardStepContent
WizardStep.Actions = WizardStepActions

WizardStep.propTypes = {
  /**
   * The step status
   */
  status: PropTypes.oneOf([
    'info',
    'warning',
    'danger',
    'success'
  ]),

  /**
   * The step children
   */
  children: PropTypes.node.isRequired,

  /**
   * Custom render function
   */
  render: PropTypes.func
}

WizardStep.defaultProps = {
  status: undefined,
  render: null
}

export default WizardStep
