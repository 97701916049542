import { createSlice } from '@reduxjs/toolkit'
import { PERMISSION_STATES } from '../../../../../utils/permissions-service'

const initialState = {
  newPermission: PERMISSION_STATES.PROMPT,
  positionErrorMsg: '',
  watchId: null,
  geoPosition: {},
  position: {
    lat: null,
    lng: null
  }
}

export const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    updateGeolocation: (state, action) => {
      const {
        newPermission,
        positionErrorMsg,
        watchId,
        geoPosition,
        position
      } = action.payload

      state.geoPosition = geoPosition
      state.position = position
      state.newPermission = newPermission
      state.positionErrorMsg = positionErrorMsg
      state.watchId = watchId
    },

    onClearGeolocation: (state, action) => {
      state.newPermission = PERMISSION_STATES.PROMPT
      state.positionErrorMsg = ''
      state.watchId = null
      state.geoPosition = {}
      state.position = {
        lat: null,
        lng: null
      }
    }
  }
})

export const { updateGeolocation, onClearGeolocation } = geolocationSlice.actions

export const selectGeolocation = (state) => state.geolocation

export default geolocationSlice.reducer
