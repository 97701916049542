const set = require('lodash/set')
const sheet = require('./../../tools/sheet')
const { list: listFunders } = require('./../../../funders/api')
const { getHeaders } = require('../../tools/export-location-headers')
const { sanitizeDoc } = require('./../../tools/bulk-location-edits')
const { getByIds } = require('./../../api/read/get-by-ids')

const getContacts = (programsDoc, contactsDoc) => {
  const contacts = {}
  programsDoc.forEach(program => {
    contacts[program.id] = contactsDoc[program.id]
  })
  return {contacts}
}

const addOtherDocs = (doc) => {
  const {programs, funders, implementingPartners, additionalData} = doc
  const programDoc = []
  const services = []
  Object.keys(programs).forEach(program => {
    const serviceDoc = []
    Object.keys(programs[program].services).forEach(service => {
      if (programs[program].services[service]) {
        serviceDoc.push({
          id: `program:${program}:service:${service}`,
          funderId: `funder:${funders[program]}`,
          implementingPartnerId: `implementingPartner:${implementingPartners[program]}`
        })
      }
      if (programs[program].services[service]) {
        services.push(`program:${program}:service:${service}`)
      }
    })
    if (serviceDoc.length) {
      programDoc.push({
        id: `program:${program}`,
        services: serviceDoc
      })
    }
    additionalData.programs[program]['ip'] = implementingPartners[program]
  })
  const contacts = getContacts(programDoc, doc.contacts)
  const otherDocs = {
    programs: programDoc,
    services,
    ...contacts,
    additionalData
  }
  return otherDocs
}

const dataToDocs = data => {
  const { location } = data
  const docTemplate = {
    _id: data._id,
    fullName: data.fullName || '',
    level: 'sdp',
    version: '2.0.0',
    location: {
      ...location,
      id: `zone:${location.zone}:state:${location.state}:lga:${location.lga}`
    },
    alias: data.facilityId ? {
      hrfc: data.facilityId.trim()
    } : {},
    additionalData: data.additionalData
  }

  // parse coordinate values to float if defined and null when not defined
  if (docTemplate.additionalData && docTemplate.additionalData.coordinates) {
    let lat = Number.parseFloat(docTemplate.additionalData.coordinates.lat)
    let long = Number.parseFloat(docTemplate.additionalData.coordinates.long)
    // parse coordinates to latLng prop
    docTemplate.additionalData.latLng = `${Number.isNaN(lat) ? null : lat},${Number.isNaN(long) ? null : long}`
    delete docTemplate.additionalData.coordinates
  }
  const otherDocs = addOtherDocs(data)
  return {
    ...docTemplate,
    ...otherDocs,
    row: data.row
  }
}

const toCreateAndUpdateLocations = (headers, sheetRows) => {
  const createLocations = []
  const updateLocations = []
  const keywords = ['create', 'update']

  for (let i = 0; i < sheetRows.length; i++) {
    const row = sheetRows[i]
    const command = row['changeType'] ? row['changeType'].toLowerCase().trim() : null
    if (!command || !keywords.includes(command.toLowerCase())) {
      continue
    }

    let location = {
      row: i + 2
    }
    for (const key of headers) {
      if (key.includes('changeType')) continue
      set(location, key, row[key], null)
    }

    if (location._id) location._id = location._id.toLowerCase().trim()

    if (command === 'update') {
      updateLocations.push(location)
    } else {
      createLocations.push(location)
    }
  }
  return { createLocations, updateLocations }
}

exports.importLocations = async (state, {
  buffer,
  userPrograms,
  effectiveDate
}) => {
  if (!buffer) {
    throw new Error('Parameter data is required')
  }

  const {logger} = state
  const data = sheet.decode(buffer)
  const funderList = await listFunders(state)

  const headers = getHeaders(userPrograms, { template: true })

  const { createLocations, updateLocations } = toCreateAndUpdateLocations(headers, data)
  const createCount = createLocations.length
  const updateCount = updateLocations.length
  const locationDocs = createLocations.concat(updateLocations)
  let allDocs = []
  const batchSize = 500 // validate docs in batches of 500
  try {
    for (let i = 0; i < locationDocs.length; i += batchSize) {
      const batch = locationDocs.slice(i, i + batchSize)
      let existingLocationDocs = await getByIds(state, batch.map(doc => doc._id), effectiveDate)
      existingLocationDocs = existingLocationDocs.map(existingLocationDoc => existingLocationDoc)

      const validatedDocs = await Promise.all(
        batch.map(location => dataToDocs(location))
          .map(async location => sanitizeDoc(existingLocationDocs, location, funderList))
      )
      allDocs.push(...validatedDocs)
    }
  } catch (error) {
    logger.warn(`error processing documents: ${error}`)
  }

  return { allDocs, createCount, updateCount }
}
