const get = require('lodash/get')
const sortBy = require('lodash/sortBy')
const { parse } = require('../../tools/smart-id')
const { warehouseCodeToVirtualId } = require('../tools')

const COMMON_HEADERS = [
  'warehouseCode',
  'funderId',
  'programId',
  'productId',
  'productName'
]

const BULK_ALLOCATION_HEADERS = [
  ...COMMON_HEADERS,
  'inventoryQuantity',
  'previousAllocatedQuantity',
  'newAllocatedQuantity'
]

const STOCK_LEVELS_HEADERS = [
  ...COMMON_HEADERS,
  'manufacturer',
  'batchNo',
  'expiry',
  'quantity'
]

// NB this will make N getLedgerBalances via getAllocatedStock calls per number of funders at a
// specific location, which will probably never go beyond 3 but it is worth taking
// the negligible performance hit over messing up getAllocatedStock with more params.
exports.getAllocatedStockParams = getAllocatedStockParams
function getAllocatedStockParams (
  {
    programs = [{
      programId: 'program:hiv-aids',
      funders: ['funder:pepfar'],
      warehouseCode: 'DC-303 :: Axios Warehouse Awka'
    }]
  } = {}
) {
  return programs
    .reduce((acc, program) => {
      const {funders, programId, warehouseCode} = program
      const params = funders
        ? funders
          .map(funderId => {
            const locationId = warehouseCodeToVirtualId({programId, funderId, warehouseCode})
            return {locationId, programId, fundersFilter: [funderId], funderId, warehouseCode}
          })
        : []
      return acc.concat(params)
    }, [])
}

// Default param here is to reference shape.
exports.createExport = createExport
function createExport (
  warehouseStockSituations = [
    {
      ledger: {
        'product:ghsc-aid0020': {
          total: 1000000,
          batches: {'product:ghsc-aid0020:manufacturer:testmanu:batchNo:2': 1000000},
          commits: {'allocated:funder:global-fund': {amount: 1000000}}
        }
      },
      allocatedStock: {'product:ghsc-aid0020': 1000000},
      productsByID: {'product:ghsc-aid0020': {_id: 'product:ghsc-aid0020', fullName: 'ghsc-aid0020'}},
      warehouseCode: 'NDC-201 :: Abuja Premier Medical Warehouse',
      programId: 'program:hiv-aids',
      funderId: 'funder:global-fund'
    }
  ],
  exportStockLevels = false,
  batches = []
) {
  const rows = warehouseStockSituations
    .flat()
    .reduce((acc, warehouseStockSituation) => {
      const {
        ledger, allocatedStock, productsById, warehouseCode, programId, funderId
      } = warehouseStockSituation
      const warehouseRows = Object.keys(productsById)
        .map(productId => {
          const inventoryQuantity = get(ledger, `${productId}.total`, 0)
          const previousAllocatedQuantity = get(allocatedStock, productId, 0)
          const batches = get(ledger, `${productId}.batches`, {})
          return {
            warehouseCode,
            funderId,
            programId,
            productId,
            productName: get(productsById, `${productId}.fullName`),
            inventoryQuantity,
            previousAllocatedQuantity,
            batches
          }
        })
      return acc.concat(warehouseRows)
    }, [])

  const sorted = sortBy(rows, ['warehouseCode', 'funderId', 'programId', 'productId', 'productName'])

  if (exportStockLevels) {
    const withBatches = expandBatches(sorted, batches)
    return formatData(withBatches, STOCK_LEVELS_HEADERS)
  }

  return formatData(sorted, BULK_ALLOCATION_HEADERS)
}

const formatData = (inputData, headers) => {
  const rows = inputData.map(row => {
    return headers
      .reduce((acc, header) => {
        acc.push(row[header])
        return acc
      }, [])
  })
  rows.unshift(headers)
  return rows
}
exports.formatData = formatData

function expandBatches (rows, batches = []) {
  const expiryByBatchId = batches
    .reduce((acc, batch) => {
      acc[batch._id] = batch.expiry
      return acc
    }, {})

  return rows
    .reduce((acc, row) => {
      const batches = row.batches || {}
      const batchRows = Object.keys(batches)
        .reduce((acc, batchId) => {
          const quantity = batches[batchId]
          const {manufacturer, batchNo} = parse(batchId)
          const expiry = get(expiryByBatchId, batchId, '')
          const batchRow = Object.assign({}, row, {quantity, manufacturer, batchNo, expiry})
          return acc.concat([batchRow])
        }, [])
      return acc.concat(batchRows)
    }, [])
}
