module.exports = (programs, serviceId) => {
  for (let p of programs) {
    for (let s of p.services) {
      if (s.id === serviceId) {
        // Here we attach the program fields except service to the service object
        const p2 = Object.assign({}, p)
        delete p2.services
        return Object.assign({}, s, {program: p2})
      }
    }
  }
  throw new Error(`Service with id ${serviceId} not found`)
}
