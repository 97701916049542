import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { parseQueryString } from '../common/querystring-filters'
import withConfig from '../van-shared/hoc/withConfig'
import { withAuth } from '../common/AuthenticationProvider'

import Login from './Login'

class LoginContainer extends Component {
  static propTypes = {
    resetLoginStatus: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    config: PropTypes.object.isRequired,
    loginInProgress: PropTypes.bool
  }

  static defaultProps = {
    error: null,
    loginInProgress: false
  }

  componentDidMount () {
    this.props.resetLoginStatus()
  }

  handleLoginSubmit = (fields) => {
    const qsProps = parseQueryString(this.props.location.search)

    if (fields.username && fields.password) {
      const { location, history, config } = this.props
      const { from } = location.state || { from: { pathname: '/' } }

      return this.props.login(
        fields.username,
        fields.password,
        {
          isAdminLogin: qsProps.loginAs && qsProps.loginAs === 'admin',
          loggedInUserName: fields.loginAsUser,
          history,
          from,
          config
        }
      )
    }
  }

  render () {
    const {
      error
    } = this.props

    const qsProps = parseQueryString(this.props.location.search)

    return (
      <Login
        config={this.props.config}
        onSubmit={this.handleLoginSubmit}
        loginInProgress={this.props.loginInProgress}
        errorMessage={error && 'Error logging in: Please make sure that your username and password are correct and try again. If the problem persists, please contact support.'}
        extras={qsProps}
      />
    )
  }
}

export default withAuth('login', 'loginInProgress', 'error', 'resetLoginStatus')(withConfig(LoginContainer))
