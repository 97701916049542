const aggregateAlertDocs = require('./aggregate-alert-docs')
const { serviceIdToProgramId } = require('../tools')
const { isLocationSubscribedToService } = require('./utils/location-subscribed-to-service')
const { getByIds } = require('./../location/api/read/get-by-ids')

module.exports = async (state, options) => {
  const db = state.alertsDb
  let startkey = 'alert'
  let endkey = `${startkey}:\uffff`

  if (options && options.target) {
    if (options.target.type === 'stock-count-target') {
      startkey = `alert:${options.target.serviceId}`
      if (options.target.reportId) {
        startkey = `${startkey}:${options.target.reportId}`
      }
      endkey = `${startkey}:\uffff`
    }
  }

  const result = await db.allDocs({
    include_docs: true,
    startkey,
    endkey
  })

  // group docs by alert id
  const groups = result.rows.reduce((acc, row) => {
    if (acc[row.doc.alertId]) {
      acc[row.doc.alertId].push(row.doc)
    } else {
      acc[row.doc.alertId] = [row.doc]
    }
    return acc
  }, {})

  // remove groups that are missing an alert doc
  const groupsWithAlert = Object.keys(groups)
    .filter(alertId => groups[alertId].some(alert => alert.type === 'alert'))
    .reduce((acc, alertId) => {
      acc[alertId] = groups[alertId]
      return acc
    }, {})

  // Create aggregate alert records from documents
  let alerts = Object.keys(groupsWithAlert)
    .map(alertId => aggregateAlertDocs(groupsWithAlert[alertId]))

  // Filter alerts by location
  const locationId = options && options.locationId
  if (locationId) {
    alerts = alerts.filter(a => {
      if (a.target.type === 'stock-count-target') {
        if (locationId === 'national') {
          return true
        }
        return a.target.reportLocationId.startsWith(locationId)
      }
    })
  }

  // check that alert's service is in the
  // location's services
  const locationIds = alerts.map(({target}) => target.reportLocationId)
  const allLocations = await getByIds(state, locationIds)
  alerts = alerts.filter(({target, serviceId}) => isLocationSubscribedToService(target.reportLocationId, serviceId, allLocations))

  // filter alerts by programs the user have access to
  // if a serviceId is provided, alerts would already be filtered by that service's program.
  const {target = {}, programs = []} = options || {}
  const hasService = !!(target.serviceId)
  if (!hasService && programs.length) {
    alerts = alerts.filter(alert => programs.includes(serviceIdToProgramId(alert.serviceId)))
  }

  return alerts
}
