import React, { lazy, Suspense } from 'react'

import { Loading } from '@fielded/shared-ui'

const DocumentText = ({
  documentVersionId,
  ...props
}) => {
  const Component = lazy(() => import(`./versioning/${documentVersionId.split('-').pop()}/${documentVersionId}`))
  return (
    <Suspense fallback={<Loading loadingText='Loading document…' />}>
      <Component {...props} />
    </Suspense>
  )
}

export default DocumentText
