module.exports = { listWithProposals }

const { withProposalDetails } = require('./utils')

function listWithProposals ({ currentEntities, proposalEntities }) {
  // Index by the real doc id, not the proposalEntity id
  // proposalEntities is expected to be sorted by date from the view response for latest doc last
  const proposalEntitiesById = proposalEntities.reduce((acc, entity) => {
    acc[entity.doc._id] = entity
    return acc
  }, {})

  const existingIds = new Set()

  const currentWithProposals = currentEntities.map(entity => {
    const proposalEntity = proposalEntitiesById[entity._id]
    existingIds.add(entity._id)
    return withProposalDetails(entity, proposalEntity)
  })

  const pendingNew = Object.keys(proposalEntitiesById)
    .reduce((acc, entityId) => {
      if (existingIds.has(entityId)) return acc

      const proposalEntity = proposalEntitiesById[entityId]
      const pendingNewDoc = withProposalDetails(proposalEntity.doc, proposalEntity)
      return acc.concat([pendingNewDoc])
    }, [])

  return pendingNew.concat(currentWithProposals)
}
