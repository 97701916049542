module.exports = {
  validateGetLedgerBalanceParams,
  validBatchSubmitData,
  validNhlmisRestApiSubmit
}

const validateLedgerParams = require('./validate-ledger-params')
const validateBatchSubmit = require('./validate-batch-submit')
const validateNhlmisRestApiSubmit = require('./validate-nhlmis-rest-api-stock-report')

function validateGetLedgerBalanceParams (params) {
  const valid = validateLedgerParams(params)

  if (valid) {
    return null
  }

  return validateLedgerParams.errors
}

function validBatchSubmitData (params) {
  const valid = validateBatchSubmit(params)

  if (valid) {
    return null
  }

  return validateBatchSubmit.errors
}

function validNhlmisRestApiSubmit (params) {
  const valid = validateNhlmisRestApiSubmit(params)

  if (valid) {
    return null
  }

  return validateNhlmisRestApiSubmit.errors
}
