const { getByIds: getLocationsByIds } = require('./../location')
const { findById: findShipmentById } = require('./shipment-find')
const { parseCoordinates } = require('./../location/tools/coordinate-helper')
const { SHIPMENT_GPS_FAIL_REASONS } = require('./constants')
const distance = require('@turf/distance').default
const helpers = require('@turf/helpers')
const MAXIMUM_DELIVERY_RADIUS_KM = 2

function calculateDistance (state, fromCoordinates, toCoordinates) {
  const from = helpers.point([fromCoordinates.long, fromCoordinates.lat])
  const to = helpers.point([toCoordinates.long, toCoordinates.lat])
  return distance(from, to, { units: 'kilometers' })
}

async function addGpsValidationFailComment (state, snapshotId, reason, position) {
  const isValidReason = Object.values(SHIPMENT_GPS_FAIL_REASONS).includes(reason)
  if (!isValidReason) {
    const error = new Error('Invalid gps validation fail reason')
    error.status = 400
    throw error
  }

  const snapshot = await state.shipmentsDb.get(snapshotId)
  const date = new Date().toJSON()
  snapshot.updatedAt = date
  snapshot.gpsValidationFailComment = { reason, date, position }
  await state.shipmentsDb.put(snapshot)
  return { _id: snapshotId, reason, date, position }
}

async function gpsValidation (state, shipmentId, currentCoordinates) {
  const shipment = await findShipmentById(state, shipmentId)
  const { destination } = shipment
  const hasPriorGPSValidation = shipment.isGpsAuthenticated
  if (hasPriorGPSValidation) {
    const error = new Error('GPS validation already approved for current shipment.')
    error.status = 400
    throw error
  }

  const [location] = await getLocationsByIds(state, [destination.id])
  if (!location) {
    const error = new Error('Shipment destination not found.')
    error.status = 404
    throw error
  }

  const destinationCoordinates = parseCoordinates(location.additionalData.latLng)
  if (!destinationCoordinates.lat || !destinationCoordinates.long) {
    const error = new Error('Missing destination coordinates.')
    error.status = 404
    throw error
  }

  const distance = calculateDistance(state, currentCoordinates, destinationCoordinates)
  const snapshot = await state.shipmentsDb.get(shipment.snapshotId)
  snapshot.updatedAt = new Date().toJSON()
  if (distance > MAXIMUM_DELIVERY_RADIUS_KM) {
    const errorText = `GPS validation failed, current distance from destination is ${distance.toFixed(2)} km. Maximum distance allowed is ${MAXIMUM_DELIVERY_RADIUS_KM} km.`
    const error = new Error(errorText)
    error.status = 400
    snapshot.isGpsAuthenticated = false
    await state.shipmentsDb.put(snapshot)
    throw error
  }

  const validationText = `GPS validation succeeded, distance from destination was ${distance.toFixed(2)} km.`
  snapshot.isGpsAuthenticated = true
  await state.shipmentsDb.put(snapshot)
  return { message: 'GPS validation succeeded', comment: validationText }
}

module.exports = { calculateDistance, gpsValidation, addGpsValidationFailComment }
