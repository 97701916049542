import React, { Fragment } from 'react'

import { Button, DeemphasizedLink, Modal } from '@fielded/shared-ui'
import { CheckmarkCircle, Plus } from '@fielded/shared-ui/src/icons'

import ReportField from './ReportField'

const RemarkReportField = ({isFilled, modalVisible, onOpenModal, onCloseModal, reportFieldProps}) => {
  return (
    <Fragment>
      <div className='report-field__field'>
        <span>
        Remark
        </span>
        {!isFilled ? (
          <Button
            colorVariant='brand'
            fill='outline'
            onClick={onOpenModal}
            icon={<Plus />}
            iconSide='left'
          >
          Add remark
          </Button>
        ) : (
          <div>
            <span className='remark-report-field__remark-added'>
              <CheckmarkCircle />
              Remark added
            </span>
            <DeemphasizedLink
              colorVariant='brand'
              onClick={onOpenModal}
            >
            Edit
            </DeemphasizedLink>
          </div>
        )}
      </div>
      <Modal
        isOpen={modalVisible}
        title='Add a remark'
        onClose={onCloseModal}
      >
        <ReportField
          isInModal
          {...reportFieldProps}
        />
        <Button
          colorVariant='brand'
          fill='full'
          onClick={onCloseModal}
        >
          Submit
        </Button>
      </Modal>
    </Fragment>
  )
}

export default RemarkReportField
