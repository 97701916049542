import { updateGeolocation } from '../subapps/shipments/shipments/psm-shipments/reducers/geolocationSlice'
import { PERMISSION_ERRORS, PERMISSION_STATES } from './permissions-service'

const defaultPosition = {
  lat: null,
  lng: null
}

const geolocationValues = {
  newPermission: PERMISSION_STATES.PROMPT,
  geoPosition: {}, // this will contain all geolocation information
  position: defaultPosition, // this only contains the latitude and longitude
  positionErrorMsg: '',
  watchId: null
}

const handlePermissionGranted = (resolve, dispatch) => (newPosition) => {
  const newGeolocation = {
    ...geolocationValues,
    newPermission: PERMISSION_STATES.GRANTED,
    geoPosition: newPosition,
    position: {
      lat: newPosition.coords.latitude,
      lng: newPosition.coords.longitude
    }
  }

  dispatch(updateGeolocation(newGeolocation))
  resolve(newGeolocation)
}

const handlePermissionDenied = (reject, dispatch) => (error) => {
  let values = {
    ...geolocationValues,
    newPermission: PERMISSION_STATES.DENIED
  }

  switch (error.code) {
    case error.PERMISSION_DENIED:
      values = { ...values, positionErrorMsg: PERMISSION_ERRORS.PERMISSION_DENIED }
      dispatch(updateGeolocation(values))
      reject(values)
      break

    case error.POSITION_UNAVAILABLE:
      values = { ...values, positionErrorMsg: PERMISSION_ERRORS.POSITION_UNAVAILABLE }
      dispatch(updateGeolocation(values))
      reject(values)
      break

    case error.TIMEOUT:
      values = { ...values, positionErrorMsg: PERMISSION_ERRORS.TIMEOUT }
      dispatch(updateGeolocation(values))
      reject(values)
      break

    default:
      values = { ...values, positionErrorMsg: PERMISSION_ERRORS.DEFAULT }
      dispatch(updateGeolocation(values))
      reject(values)
      break
  }
}

const defaultConfigOptions = {
  timeout: Infinity, // Maximum allowed time (in milliseconds) for the device to return a position
  maximumAge: 0, // Maximum acceptable age (in milliseconds) of cached position data
  enableHighAccuracy: true // Boolean flag indicating preference for best possible accuracy in position results
}

// The config options will overide the default config if the values are given
const getGeolocationArguments = ({ resolve, reject, dispatch, configOptions }) => {
  const getPermissionGranted = () => handlePermissionGranted(resolve, dispatch)
  const getPermissionDenied = () => handlePermissionDenied(reject, dispatch)
  const geolocationConfig = {
    ...defaultConfigOptions,
    ...configOptions
  }

  return [
    getPermissionGranted(),
    getPermissionDenied(),
    geolocationConfig
  ]
}

const handleGetGeolocation = (permission, dispatch, configOptions) => {
  const geolocation = navigator.geolocation
  const isPermissionPrompt = permission === PERMISSION_STATES.PROMPT

  return new Promise((resolve, reject) => {
    if (isPermissionPrompt) {
      geolocation.getCurrentPosition(
        ...getGeolocationArguments({ resolve, reject, dispatch, configOptions })
      )
    } else {
      geolocationValues.watchId = geolocation.watchPosition(
        ...getGeolocationArguments({ resolve, reject, dispatch, configOptions })
      )
    }
  })
}

const getGeolocation = async (permission, dispatch, configOptions = {}) => {
  try {
    const result = await handleGetGeolocation(permission, dispatch, configOptions)
    return result
  } catch (error) {
    throw new Error('New geolocation permission error', { cause: error })
  }
}

const clearGeolocationWatch = (id) => {
  if (id) {
    navigator.geolocation.clearWatch(id)
    return geolocationValues
  }
}

export {
  getGeolocation,
  clearGeolocationWatch
}
