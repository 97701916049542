const proposal = require('../../proposal/data-access/pouchdb-adapter')
const location = require('../../location/data-access/pouchdb-adapter')
const product = require('../../product/data-access/pouchdb-adapter')
const order = require('../../order/data-access')
const report = require('../../report/data-access/pouchdb-adapter')
const shipment = require('../../shipment/data-access/pouchdb-adapter')
const program = require('../../program/data-access/pouchdb-adapter')
const user = require('../../user/data-access/fetch-adapter')
const configuration = require('../../configuration/data-access/pouchdb-adapter')
const funders = require('../../funders/data-access/pouchdb-adapter')
const routes = require('../../routes/data-access/pouchdb-adapter')
const geoLocations = require('../../geo-locations/data-access/pouchdb-adapter')
const document = require('../../document/data-access/pouchdb-adapter')

module.exports = {
  product,
  proposal,
  location,
  order,
  report,
  shipment,
  program,
  user,
  configuration,
  funders,
  geoLocations,
  document,
  routes
}
