const camelCase = require('lodash/camelCase')
const PGAdapter = require('../common/pg-adapter')
const PaymentDebitAdapter = require('./payment-debit-adapter')
const PaymentHistoryAdapter = require('./payment-history-adapter')
const REMITA_CONFIG = require('./data-access/remita/config')
const { dateToSlashes } = require('../utils/date-format')

const {
  PAYMENT_METHOD_TABLE_NAME,
  PAYMENT_METHOD_COLUMNS,
  PAYMENT_METHOD_REQUEST_TABLE_NAME,
  PAYMENT_METHOD_REQUEST_COLUMNS,
  LOCATION_TABLE_NAME
} = require('./constants')

class PaymentMethodNodeAdapter extends PGAdapter {
  constructor (pgConnection, username, remitaInterface, safaricomInterface) {
    super(
      pgConnection,
      PAYMENT_METHOD_TABLE_NAME,
      username,
      PAYMENT_METHOD_COLUMNS
    )
    this.remitaInterface = remitaInterface
    this.safaricomInterface = safaricomInterface
    this.paymentMethodRequest = new PGAdapter(this.pgConnection, PAYMENT_METHOD_REQUEST_TABLE_NAME, this.username, PAYMENT_METHOD_REQUEST_COLUMNS)
    this.paymentDebit = new PaymentDebitAdapter(this.pgConnection, this.username, this.remitaInterface, this.safaricomInterface, this)
    this.paymentHistory = new PaymentHistoryAdapter(this.pgConnection, this.username, this.remitaInterface, this.safaricomInterface, this)
  }

  /**
   * Creates a record of a payment method creation.
   * todo: docs for further info
   * @param {object} data - data from admin form
   * @returns {Promise<Object>}
   */
  async create (data) {
    // first step is to save a "placeholder record"
    const res = await super.create(data)
    // second step...
    switch (res.payment_provider) {
      case REMITA_CONFIG.PROVIDER_CODE: {
        // ...if remita, send request to create mandate
        const params = {
          ...res.data.apiParams,
          startDate: dateToSlashes(res.data.apiParams.startDate),
          endDate: dateToSlashes(res.data.apiParams.endDate)
        }
        const {remitaCreateMandateRequestBody, remitaCreateMandateResponse} = await this.remitaInterface.createMandate(params)

        // step 3: log response (whether successful or not), by creating `data_paymentproviderrequest` row request + response data
        this.createPaymentMethodRequest(remitaCreateMandateRequestBody, remitaCreateMandateResponse, null, REMITA_CONFIG.PROVIDER_CODE, 'CREATE_MANDATE')

        // step 4: update original (placeholder) payment method row's data to include remita response
        const updatedRow = {
          id: res.id,
          data: {
            ...res.data,
            // todo, even though we already save the response to its own table, we also store it hear so we can prevent it in React-Admin
            // this could be altered so we just display the newest result of a join with that table.
            remitaResponse: remitaCreateMandateResponse
          }
        }

        if (remitaCreateMandateResponse.body.mandateId) {
          updatedRow.data.mandateId = remitaCreateMandateResponse.body.mandateId
        }

        return super.update(updatedRow)
      }
      default:
        break
    }
    return data
  }

  // TODO: voiding/archiving a mandate https://www.notion.so/Void-a-created-mandate-6b1795fe119d49d7b94d4b71482e0699
  // for now, we block users from deleting a mandate, which is our only realy history
  // record of what we've requested from remita for create mandates.
  async delete () {
    const err = new Error('Delete payment method is not yet implemented (void)')
    err.status = 400
    throw err
  }

  /**
   * Logs the provider's response from a request to create a payment method.
   * @param {object} requestData
   * @param {object} responseData
   * @param {string} paymentMethodId - uuid of the parent RDS data_paymentmethod row
   * @param {string} paymentProviderCode - payment provider code, eg. 'REMITA'
   * @param {('CREATE_MANDATE'|'CREATE_DEBIT'|'GET_MANDATE_HISTORY')} type
   * @returns {Promise<void>}
   */
  async createPaymentMethodRequest (requestData, responseData, paymentMethodId, paymentProviderCode, type) {
    const paymentRequestData = {
      payment_provider: paymentProviderCode,
      type,
      request_data: requestData,
      response_data: responseData,
      payment_method_id: paymentMethodId
    }
    return this.paymentMethodRequest.create(paymentRequestData)
  }

  async update (data) {
    let update

    switch (data.type) {
      case 'getMandateStatus': {
        // NB note this doesn't update anything in DB. it simply prepare an update to be carried out further below...
        update = await this.mandateStatusRequest(data)
        break
      }
      case 'getMandateHistory': {
        return {data: {id: data.mandateId, ...await this.paymentHistory.update(data)}}
      }
      case 'getDebitStatus': {
        return {data: {id: data.mandateId, ...await this.debitStatus.update(data)}}
      }
      case 'updateMandateComment': {
        const {mandate, comment} = data
        update = {id: mandate.id, comment}
        break
      }
      default: {
        update = data
      }
    }

    if (update) {
      try {
        return super.update(update)
      } catch (e) {
        throw new Error(e)
      }
    }
  }

  async mandateStatusRequest (data) {
    switch (data.mandate.payment_provider) {
      case REMITA_CONFIG.PROVIDER_CODE: {
        const [mandateRecord, remitaMandateStatusResponse] = await Promise.all([
          this.getOne(data.mandate.id),
          this.remitaInterface.getMandateStatus({ requestId: data.mandate.remitaResponse.body.requestId })
        ])

        return {
          id: mandateRecord.id,
          data: {
            ...mandateRecord.data,
            isActive: remitaMandateStatusResponse.body.isActive,
            remitaMandateStatusResponse
          }
        }
      }
    }
  }

  async getByMandateId (mandateId) {
    return super.getOne(mandateId, { whereCondition: 'data ->> \'mandateId\'' })
  }

  async getByLocationId (locationId) {
    return super.getOne(locationId, { whereCondition: 'data ->> \'locationId\'' })
  }

  async getOne (id) {
    const row = await super.getOne(id)
    if (row.payment_provider === 'REMITA') {
      const bankConfigs = REMITA_CONFIG.BANK_CODES.find(c => c.id === row.data.apiParams.payerBankCode)
      row.allowOtp = bankConfigs && bankConfigs.allowOtp
    }
    return row
  }

  async getList ({ ordering = this.idColumn, filter = {}, limit = 50, offset = 0 } = {}) {
    let whereCondition = ''
    let joinCondition = ` LEFT JOIN ${LOCATION_TABLE_NAME} ON ${LOCATION_TABLE_NAME}.uuid = ${this.tableName}.data ->> 'locationId'`
    let initialQuery = `SELECT ${this.tableName}.* FROM ${this.tableName}`
    let initialCountQuery = `SELECT COUNT(${this.tableName}.id) FROM ${this.tableName}`
    const props = []
    const countProps = []
    const dataOrdering = ['mandate_id', 'is_active']
    let sortExpression = ordering.startsWith('-') ? ordering.replace('-', '') : ordering
    if (dataOrdering.includes(sortExpression)) {
      sortExpression = `"data"->>'${camelCase(sortExpression)}'`
    }
    const sortDirection = ordering.startsWith('-') ? 'DESC' : 'ASC'

    let sortCondition = `ORDER BY ${this.tableName}.${sortExpression} ${sortDirection}`
    if (sortExpression === 'name_of_pharmacy') {
      sortCondition = `ORDER BY ${LOCATION_TABLE_NAME}.${sortExpression} ${sortDirection}`
    }

    if (filter.mandateId) {
      whereCondition = ` WHERE ${this.tableName}.data ->> 'mandateId' ILIKE $${props.length + 1}`
      countProps.push(`${filter.mandateId}%`)
      props.push(...countProps)
    }

    if (filter.location) {
      whereCondition = `${props.length > 0 ? `${whereCondition} AND` : ' WHERE'} ${LOCATION_TABLE_NAME}.name_of_pharmacy ILIKE $${props.length + 1}`
      countProps.push(`%${filter.location}%`)
      props.push(`%${filter.location}%`)
    }

    props.push(...[limit, offset])

    const query = `${initialQuery} ${joinCondition} ${whereCondition}  ${sortCondition} LIMIT $${props.length - 1} OFFSET $${props.length}`
    const {rows: results} = await this.pgConnection.query(query, props)
    const countQuery = `${initialCountQuery} ${joinCondition} ${whereCondition}`
    const countResponse = await this.pgConnection.query(countQuery, countProps)
    return { count: Number(countResponse.rows[0].count), results }
  }
}

module.exports = PaymentMethodNodeAdapter
