exports.listAll = function (state) {
  return state.locationsDB.allDocs({
    startkey: 'funder:',
    endkey: 'funder:\ufff0:',
    include_docs: true
  })
    .then(results => results.rows.filter(row => !row.error).map(row => row.doc))
}

exports.get = function (state, id) {
  return state.locationsDB.get(id)
}
