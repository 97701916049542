import { MEMBERSHIPS } from '@fielded/fs-api/lib/location/tools'
import { PLANNING_TYPES } from '@fielded/fs-api/lib/shipment/tools'

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { canCallEndpoint, getTimestampForNextApiCall } from '../../../../common/utils/redux-timestamp-call'

const MINUTES_TO_ADD = 5

const initialState = {
  ledgerTopUp: null,
  ledger: null,
  timestampForTheNextApiCall: 0,
  timestampForTheNextApiCallTopUp: 0,
  laodingTopUp: false,
  loading: false,
  error: null,
  errorTopUp: null
}

export const getHeldLedger = createAsyncThunk('heldLedger/getHeldLedger', async ({ api, membershipType, forceRefresh = false }, { rejectWithValue }) => {
  // this call will only be triggered if the condition (prop) is true
  try {
    const params = {}
    if (membershipType === MEMBERSHIPS.BASIC) {
      params.planType = PLANNING_TYPES.IMMEDIATE_PURCHASE
    }
    const response = await api.order.getHeldStock(params)
    if (response.error) {
      return rejectWithValue(response.error)
    }
    return response.ledger
  } catch (error) {
    return error
  }
}, {
  condition: (apiData, { getState }) => {
    const { forceRefresh } = apiData
    if (forceRefresh) return true // This ensures that the API is called

    const { timestampForTheNextApiCall, ledger } = getState().heldLedger
    const callEndpoint = !ledger || !Object.keys(ledger).length || canCallEndpoint(timestampForTheNextApiCall)
    return callEndpoint
  }
})

export const getHeldLedgerTopUp = createAsyncThunk('heldLedger/getHeldLedgerTopUp', async ({ api, forceRefresh = false }, { rejectWithValue }) => {
  // this call will only be triggered if the condition (prop) is true
  try {
    const params = { planType: PLANNING_TYPES.TOPUP }
    const response = await api.order.getHeldStock(params)
    if (response.error) {
      return rejectWithValue(response.error)
    }

    return response.ledger
  } catch (error) {
    return error
  }
}, {
  condition: (apiData, { getState }) => {
    const { forceRefresh } = apiData
    if (forceRefresh) return true // This ensures that the API is called

    const { timestampForTheNextApiCallTopUp, ledgerTopUp } = getState().heldLedger
    const callEndpoint = !ledgerTopUp || !Object.keys(ledgerTopUp).length || canCallEndpoint(timestampForTheNextApiCallTopUp)
    return callEndpoint
  }
})

export const heldLedgerSlice = createSlice({
  name: 'heldLedger',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHeldLedger.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getHeldLedger.fulfilled, (state, action) => {
        if (action.payload) {
          const ledger = action.payload
          const nextApiCallTimestamp = getTimestampForNextApiCall(MINUTES_TO_ADD)

          state.loading = false
          state.ledger = ledger
          state.timestampForTheNextApiCall = nextApiCallTimestamp
        } else {
          state.loading = false
          state.timestampForTheNextApiCall = 0
        }
      })
      .addCase(getHeldLedger.rejected, (state, action) => {
        state.loading = false
        state.timestampForTheNextApiCall = 0
        state.error = action.payload
      })
      .addCase(getHeldLedgerTopUp.pending, (state, action) => {
        state.loadingTopUp = true
      })
      .addCase(getHeldLedgerTopUp.fulfilled, (state, action) => {
        if (action.payload) {
          const ledger = action.payload
          const nextApiCallTimestamp = getTimestampForNextApiCall(MINUTES_TO_ADD)

          state.loadingTopUp = false
          state.ledgerTopUp = ledger
          state.timestampForTheNextApiCallTopUp = nextApiCallTimestamp
        } else {
          state.loadingTopUp = false
          state.timestampForTheNextApiCallTopUp = 0
        }
      })
      .addCase(getHeldLedgerTopUp.rejected, (state, action) => {
        state.laodingTopUp = false
        state.timestampForTheNextApiCallTopUp = 0
        state.errorTopUp = action.payload
      })
  }
})

export const selectHeldLedger = (state) => state.heldLedger.ledger
export const selectHeldLedgerLoadingState = (state) => state.heldLedger.loading
export const selectHeldLedgerErrorState = (state) => state.heldLedger.error

export const selectHeldLedgerTopUp = (state) => state.heldLedger.ledgerTopUp
export const selectHeldLedgerTopUpLoadingState = (state) => state.heldLedger.loadingTopUp
export const selectHeldLedgerTopUpErrorState = (state) => state.heldLedger.errorTopUp

export default heldLedgerSlice.reducer
