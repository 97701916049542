import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Banner from '../../../Banner'

// since we rely on Banner behind the scene, we can easily transfer message props to Banner
const PanelMessage = ({ children, fullWidth, className, ...otherProps }) => {
  return (
    <div className={classnames(
      'vs-panel__message',
      { 'vs-panel__message--fullWidth': fullWidth },
      className
    )}>
      <Banner
        {...otherProps}
        inline={!fullWidth}
      >
        {children}
      </Banner>
    </div>
  )
}

PanelMessage.propTypes = {
  ...Banner.propTypes,
  /**
   * Will make the message banner go to the edge of the panel, like the fullWidth sections.
   * Only use if message is the first element of the panel.
   */
  fullWidth: PropTypes.bool,
  /**
   * Any additional class names you want to add to the message wrapper
   * */
  className: PropTypes.string,
  /**
   * What should show inside the message
   * */
  children: PropTypes.node,
  /**
   * The type of feedback, determines color scheme.
   * The prop get sent to Banner component.
   */
  type: PropTypes.oneOf(['attention', 'info', 'success', 'warning', 'danger']),
  /**
   * optional Button, will be placed next to content on larger screens.
   */
  cta: PropTypes.node
}

PanelMessage.displayName = 'Page.Panel.Message'

export default PanelMessage
