import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import UppercaseLabel from '../../../UppercaseLabel'

import PanelHeaderTitle from './PanelHeaderTitle'
import PanelHeaderCta from './PanelHeaderCta'

const PanelHeader = ({
  title,
  meta,
  cta,
  backButton,
  children,
  status,
  centered,
  separated,
  className,
  id
}) => (
  <header
    id={id}
    className={classnames(
      'vs-panel-header',
      {'vs-panel-header--centered': centered},
      {'vs-panel-header--separated': separated},
      className
    )}
  >
    {backButton && (
      <div className='vs-panel-header__back'>
        {backButton}
      </div>
    )}
    <div className='vs-panel-header__content'>
      {title && (
        <PanelHeaderTitle status={status}>
          {title}
          {meta && (
            <div className='vs-panel-header__meta'>
              <UppercaseLabel>{meta}</UppercaseLabel>
            </div>
          )}
        </PanelHeaderTitle>
      )}
      {cta && (
        <PanelHeaderCta>{cta}</PanelHeaderCta>
      )}
    </div>
    {children && (
      <div className='vs-panel-header__custom'>
        {children}
      </div>
    )}
  </header>
)

PanelHeader.propTypes = {
  /**
   * Optionally pass a panel title
   */
  title: PropTypes.node,

  /**
   * Optionally pass a panel call to action button.
   */
  cta: PropTypes.node,

  /**
   * Optionally pass a back button for the header
   */
  backButton: PropTypes.node,

  /**
   * Optional additional content, to be rendered below the standard content.
   * You may use the components for the standard content.
   */
  children: PropTypes.node,

  /**
   * Should match the status on Panel. Will add an icon matching the status to title.
   */
  status: PropTypes.oneOf([
    null,
    'info',
    'warning',
    'danger',
    'success'
  ]),

  /**
   * Centers the header content.
   */
  centered: PropTypes.bool,

  /**
   * On panels with bckground, this separates the header from the content with a border
   */
  separated: PropTypes.bool,

  /**
   * Any additional classes you need added to header
   */
  className: PropTypes.string,

  /**
   * Id you might need to use on the header
   */
  id: PropTypes.string
}

PanelHeader.defaultProps = {
  title: undefined,
  cta: undefined,
  backButton: undefined,
  children: undefined,
  status: undefined,
  separated: true,
  className: '',
  id: undefined
}

PanelHeader.Title = PanelHeaderTitle
PanelHeader.Cta = PanelHeaderCta

PanelHeader.displayName = 'Page.Panel.Header'

export default PanelHeader
