import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import kebabCase from 'lodash/kebabCase'
import isEmpty from 'lodash/isEmpty'

import { PayAsYouSell, PayOnDelivery } from '@fielded/shared-ui/src/icons'

import { PriceDisplay, PricePromotion, TotalAmount } from '@fielded/shared-ui'

import { DIRECT_ORDER_TYPES, DIRECT_ORDER_TYPES_HUMANIZED } from '@fielded/fs-api/lib/allocation/config'

const { PAY_ON_DELIVERY, PAY_AS_YOU_SELL } = DIRECT_ORDER_TYPES

const DeliveryNoteTable = ({
  headers,
  rows,
  total,
  showVAT,
  theme,
  country,
  paymentType,
  deliveryFee,
  isDirectOrder = false,
  isBasicOrder,
  procurementServiceFeePAYG,
  procurementServiceFeeRate,
  isExternalPackPoint = false,
  isAntara = false,
  paid,
  refund
}) => {
  const totalColumns = showVAT ? 6 : 5
  const isSales = theme === 'sales'
  const sumTotalServiceFee = total + procurementServiceFeePAYG

  const hideTableFooter = (isDirectOrder || isAntara) && !isExternalPackPoint
  const showSubTotal = paymentType === PAY_ON_DELIVERY && isExternalPackPoint
  const displayProcurementServiceFeeRate = procurementServiceFeeRate * 100 // (decimal)

  const items = rows.map(row => {
    const { hasWarning, hasIssue, cols = [] } = row

    if (isEmpty(cols)) {
      return null
    }

    const columns = cols.map(col => {
      const colName = kebabCase(col.name)
      const colValue = col.value
      if (col.url) {
        return <td key={row.code + colName} className={`delivery-note-table__td--${colName}`}>
          <Link to={col.url}>{ colValue }</Link>
        </td>
      }

      if (col.name === 'Total' && !!paymentType) {
        return <td
          key={row.code + colName} className='delivery-note-table__td--total delivery-note-table__td--no-bg'
        >
          { colValue }
        </td>
      }

      if (col.name === 'Unit Price') {
        return <td
          key={row.code + colName} className={`delivery-note-table__td--${colName}`}
        >
          <PricePromotion value={colValue} />
        </td>
      }
      return (
        <td key={row.code + colName} className={`delivery-note-table__td--${colName}`}>{ colValue }</td>
      )
    })

    return (
      <tr
        key={`${row.key}`}
        className={classnames(
          'delivery-note-table__tr',
          {'delivery-note-table__tr--issue': hasIssue},
          {'delivery-note-table__tr--warning': hasWarning}
        )}
      >
        { columns }
      </tr>
    )
  })

  return (
    <Fragment>
      <table className={classnames(
        'delivery-note-table',
        {'delivery-note-table--sales': isSales},
        {'delivery-note-table--vat': showVAT}
      )}>
        <thead>
          <tr className='delivery-note-table__tr'>
            { headers.map(item => (
              <th className={`delivery-note-table__th--${kebabCase(item.name).toLowerCase()}`}key={`header-${item.name}`}>
                { item.value }
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          { items }
        </tbody>
        {total > 0 &&
          <tfoot
            className={classnames(
              {'delivery-note-table__tfoot--pays': !hideTableFooter},
              {'delivery-note-table__tfoot--hidden': hideTableFooter}
            )}>
            {isSales && (
              <TotalAmount
                value={total}
                countryId={country}
                asTableRow
                totalColumns={totalColumns}
                label='Total to pay'
                footnote='Products marked for review are due to out of contract purchases'
              />
            )}
            {!isSales && (
              <Fragment>
                {showSubTotal && (
                  <Fragment>
                    <TotalAmount
                      value={total}
                      countryId={country}
                      asTableRow
                      totalColumns={totalColumns}
                      label='Products total'
                      className='delivery-note-table__td--summary-on-demand delivery-note-table__sub-total'
                    />
                    <TotalAmount
                      value={procurementServiceFeePAYG}
                      countryId={country}
                      asTableRow
                      totalColumns={totalColumns}
                      label={`Procurement Service Fee for PAYG (${displayProcurementServiceFeeRate}%)`}
                      className='delivery-note-table__td--summary-on-demand delivery-note-table__sub-total'
                    />
                  </Fragment>
                )}
                <tr>
                  <td
                    colSpan={totalColumns - 1}
                    className={classnames(
                      'delivery-note-table__td--summary',
                      {'delivery-note-table__td--summary-on-demand': paymentType === PAY_ON_DELIVERY},
                      {'delivery-note-table__td--summary-pays': paymentType === PAY_AS_YOU_SELL}
                    )}
                  >
                    <div className='delivery-note-table__total'>
                      {!paymentType && (
                        'Value of delivered products'
                      )}

                      {paymentType === PAY_AS_YOU_SELL &&
                        <Fragment>
                          <PayAsYouSell /> Total value of {DIRECT_ORDER_TYPES_HUMANIZED[PAY_AS_YOU_SELL]} products delivered:
                        </Fragment>
                      }

                      {paymentType === PAY_ON_DELIVERY &&
                        <Fragment>
                          <PayOnDelivery /> Total value of {DIRECT_ORDER_TYPES_HUMANIZED[PAY_ON_DELIVERY]} products delivered:
                        </Fragment>
                      }
                    </div>
                  </td>
                  <td
                    className={classnames(
                      'delivery-note-table__td--value',
                      {'delivery-note-table__td--value-on-demand': paymentType === PAY_ON_DELIVERY},
                      {'delivery-note-table__td--value-pays': paymentType === PAY_AS_YOU_SELL}
                    )}
                  >
                    <PriceDisplay value={!showSubTotal ? total : sumTotalServiceFee} country={country} currencySymbol />
                  </td>
                </tr>
                {deliveryFee != null && (
                  <Fragment>
                    <tr>
                      <td
                        colSpan={totalColumns - 1}
                        className={classnames(
                          'delivery-note-table__td--summary',
                          {'delivery-note-table__td--summary-on-demand': paymentType === PAY_ON_DELIVERY}
                        )}
                      >
                        <div className='delivery-note-table__total'>
                        Delivery fee
                        </div>
                      </td>
                      <td
                        className={classnames(
                          'delivery-note-table__td--value',
                          {'delivery-note-table__td--value-on-demand': paymentType === PAY_ON_DELIVERY},
                          {'delivery-note-table__td--value-pays': paymentType === PAY_AS_YOU_SELL}
                        )}
                      >
                        <PriceDisplay value={deliveryFee} country={country} currencySymbol />
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={totalColumns - 1}
                        className={classnames(
                          'delivery-note-table__td--summary',
                          {'delivery-note-table__td--summary-on-demand': paymentType === PAY_ON_DELIVERY}
                        )}
                      >
                        <div className='delivery-note-table__total'>
                          Total
                        </div>
                      </td>
                      <td
                        className={classnames(
                          'delivery-note-table__td--value',
                          {'delivery-note-table__td--value-on-demand': paymentType === PAY_ON_DELIVERY},
                          {'delivery-note-table__td--value-pays': paymentType === PAY_AS_YOU_SELL}
                        )}
                      >
                        <PriceDisplay value={deliveryFee + total} country={country} currencySymbol />
                      </td>
                    </tr>
                  </Fragment>
                )}
              </Fragment>
            )}
          </tfoot>
        }
      </table>
    </Fragment>
  )
}

DeliveryNoteTable.propTypes = {
  /* An array of header fields */
  headers: PropTypes.array,
  /* An array of rows */
  rows: PropTypes.array,
  /* Determines if a VAT column should be shown */
  showVAT: PropTypes.bool,
  /* Theme of the table */
  theme: PropTypes.oneOf(['', 'sales'])
}

export default DeliveryNoteTable
