// Here we get all products with totals added up
// across generic parents. so in other words if a & b are siblings
// and stock = {productA: 10, productB: 5}
// result is: {productA: 15, productB: 15}
// It is expected that the input stock is already in generic units
exports.getWithSiblingTotals = getWithSiblingTotals
function getWithSiblingTotals ({stock, productsById}) {
  const products = Object.values(productsById)
  return Object.keys(stock)
    .reduce((acc, productId) => {
      const product = productsById[productId]
      const total = getTotalWithSiblings({product, stock, products})
      acc[productId] = total
      return acc
    }, {})
}

exports.getGenericTotals = getGenericTotals
function getGenericTotals ({stock, productsById}) {
  const products = Object.values(productsById)
  const genericStock = convertToSDPUnits({stock, productsById})
  return Object.keys(genericStock)
    .reduce((acc, productId) => {
      const product = productsById[productId]
      const genericTotal = getTotalWithSiblings({product, stock: genericStock, products})
      const warehouseUnitsTotal = getWarehouseUnits(genericTotal, product)
      acc[productId] = warehouseUnitsTotal
      return acc
    }, {})
}

function getTotalWithSiblings ({product = {}, stock, products}) {
  if (!product.genericParent) return stock[product._id]

  // NB this list include the target product itself
  const genericSiblings = products.filter(p => p.genericParent === product.genericParent)

  return genericSiblings
    .reduce((acc, product) => {
      acc += stock[product._id] || 0
      return acc
    }, 0)
}

// Here we don't want the same thing as above, we want the specific stock
// on a product's generic siblings and the total across siblings. So if:
// productId = a, and stock is {productA: 10, productB: 5, (...other products)}
// we get back: {total: 15, productA: 10, productB: 5}
exports.getStockOnProduct = getStockOnProduct
function getStockOnProduct ({productId, stock, productsById}) {
  const product = productsById[productId] || {}
  const total = getTotalWithSiblings({product, stock, products: Object.values(productsById)})

  if (!product.genericParent) {
    return {total, currentStock: stock[productId]}
  }

  const stockOnSiblings = Object.keys(stock)
    .reduce((acc, pId) => {
      if (productsById[pId].genericParent === product.genericParent) {
        acc[pId] = stock[pId]
      }
      return acc
    }, {})

  return {
    total,
    currentStock: stockOnSiblings
  }
}

exports.convertToSDPUnits = convertToSDPUnits
function convertToSDPUnits ({stock, productsById}) {
  return Object.keys(stock)
    .reduce((acc, productId) => {
      acc[productId] = getSDPUnits(stock[productId], productsById[productId])
      return acc
    }, {})
}

function getSDPUnits (quantity, product = {}) {
  const {genericFactor = 1} = product
  return Math.floor(quantity * genericFactor)
}

function getWarehouseUnits (quantity, product = {}) {
  const {genericFactor = 1} = product
  return Math.floor(quantity / genericFactor)
}

// Heads up: ignores commits, they're not used by the caller of this function
exports.convertLedgerToSDPUnits = convertLedgerToSDPUnits
function convertLedgerToSDPUnits ({ledger, productsById, allocatedStockSDPUnits}) {
  return Object.keys(ledger)
    .reduce((acc, productId) => {
      const product = productsById[productId]
      const totalToSDPUnits = getSDPUnits(ledger[productId].total, product)
      const allocatedAmount = allocatedStockSDPUnits[productId]
      acc[productId] = {
        total: totalToSDPUnits,
        availableTotal: Math.min(totalToSDPUnits, allocatedAmount),
        batches: getSDPBatches(ledger[productId].batches, product)
      }
      return acc
    }, {})
}

function getSDPBatches (batches = {}, product = {}) {
  return Object.keys(batches)
    .reduce((acc, batchId) => {
      acc[batchId] = getSDPUnits(batches[batchId], product)
      return acc
    }, {})
}
