import { createElement, Component } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import TextInput from '../TextInput'

class DebouncedInput extends Component {
  static propTypes = {
    /**
     * The value of the wrapped input
     */
    value: PropTypes.string,

    /**
     * Delay in ms that should be applied to the debounced function
     */
    delay: PropTypes.number,

    /** runs on input changes, returns the value (not the event)  */
    onValueChange: PropTypes.func
  }

  static defaultProps = {
    value: '',
    delay: 300, // ms
    onValueChange: () => null
  }

  state = {
    value: this.props.value
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line
    if (nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value
      })
    }
  }

  constructor (props) {
    super(props)
    this.handleOnChange = debounce(this.handleOnChange, props.delay)
  }

  handleOnChange = (value) => {
    this.props.onValueChange(value)
  }

  render () {
    const { ...props } = this.props
    return createElement(
      TextInput,
      {
        ...props,
        value: this.state.value,
        onValueChange: (value) => {
          this.setState(
            {
              value
            },
            () => this.handleOnChange(value)
          )
        }
      }
    )
  }
}

export default DebouncedInput
