// Currently we have multiple location doc versions
// The latest being 4.0.0 where we updated the location programs object
// To have a seperate funder and route arrays
// We need all versions of the raw location doc to work so we use this function.

module.exports = (service) => {
  const serviceIterator = Array.isArray(service)
    ? service
    : service.routes

  return serviceIterator
}
