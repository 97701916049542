/*
 * Takes a service or a program
 * please use this function instead of just reading the prop, so it can be refactored later
 */
const get = require('lodash/get')
module.exports = serviceOrProgram => {
  if (/:service:/.test(serviceOrProgram.id)) {
    return get(serviceOrProgram, 'program.report.idVersion', 1)
  }

  return get(serviceOrProgram, 'report.idVersion', 1)
}
