const get = require('lodash/get')
const PGAdapter = require('../common/pg-adapter')
const { EntityApi } = require('../common')

class MarketApi extends EntityApi {
  constructor (state, logger, pgConnection) {
    const adapter = new MarketPgAdapter({pgConnection, logger, username: get(state, 'user.name')})

    // This hack is copy pasted from other instances of the adapter initiation...
    // hack: sometimes the api is instantiated without a pgconnection, e.g. on the client
    // creating a pgadapter without a pgconnection will fail, but so will creating an entityapi
    // without an adapter so for that we just pass an empty object to get them to shut up
    super(adapter)

    if (pgConnection) {
      this.pgAdapter = adapter
    }
  }
}

class MarketPgAdapter extends PGAdapter {
  constructor ({ pgConnection, username, logger }) {
    const columns = [
      'id',
      'name',
      'fs_service_id',
      'geolocation_id',
      'virtual_location_id',
      'state',
      'aliases',
      'country_code',
      'emails',
      'product_market_code',
      'exchange_rate',
      'product_prefix',
      'is_external',
      'sync_prices',
      'invoice_emails',
      'created_at',
      'updated_at'
    ]
    super(
      pgConnection,
      'avocado.data_market',
      username,
      columns,
      'id',
      {},
      logger
    )
  }
}

module.exports = MarketApi
