const PGAdapter = require('../../common/pg-adapter')

const PRODUCT_TAG_TABLE_NAME = 'avocado.data_producttag'
const PRODUCT_TAG_COLUMNS = [
  'created_at',
  'created_by',
  'id',
  'updated_at'
]

class ProductTagAdapter extends PGAdapter {
  constructor (logger, pgConnection, username) {
    const opts = {
      strictColumns: false,
      addUuid: false
    }
    super(
      pgConnection,
      PRODUCT_TAG_TABLE_NAME,
      username,
      PRODUCT_TAG_COLUMNS,
      'id',
      {},
      logger,
      opts
    )
  }
}

module.exports = ProductTagAdapter
