Object.assign(exports, require('./read/list'))
Object.assign(exports, require('./read/list-groups'))
Object.assign(exports, require('./read/list-products'))
Object.assign(exports, require('./read/list-locations'))
Object.assign(exports, require('./read/list-open-orders'))
Object.assign(exports, require('./read/get'))
Object.assign(exports, require('./read/get-allocated-stock'))
Object.assign(exports, require('./read/get-request'))
Object.assign(exports, require('./read/get-all-suppliers-batch'))
Object.assign(exports, require('./read/get-supplier-ids-for-location'))
// for tests
Object.assign(exports, require('./read/internal'))

Object.assign(exports, require('./write/create'))
Object.assign(exports, require('./write/add-comment'))
Object.assign(exports, require('./write/delete-comment'))
Object.assign(exports, require('./write/edit-comment'))
Object.assign(exports, require('./write/bulk-import'))
Object.assign(exports, require('./write/close'))
Object.assign(exports, require('./write/advance-status'))
Object.assign(exports, require('./write/update-order'))
Object.assign(exports, require('./write/update-orders-on-product'))
Object.assign(exports, require('./write/reset'))
Object.assign(exports, require('./write/move-shipment-status'))
Object.assign(exports, require('./write/update-orders'))
Object.assign(exports, require('./write/update-suborder'))
Object.assign(exports, require('./write/update-orders-on-group'))
Object.assign(exports, require('./write/merge-groups'))
Object.assign(exports, require('./write/switch-supplier'))
Object.assign(exports, require('./write/close-destination-snapshots'))
