import { byId } from '../common/utils'
import { getLocationsByLevelOrder } from '../../../common/utils'
import { userLocationLabelFromId } from '../../settings/common/utils'

const reducerName = 'masterData'
export const RECEIVE_MASTER_DATA = `${reducerName}/RECEIVE_MASTER_DATA`

const receiveMasterData = (state, { products, locations, programs, funders }) => {
  const locationsByLevel = getLocationsByLevelOrder(locations)
  // We generate all the search options here to not drop performance
  const locationOptions = locationsByLevel.map(locationId => {
    return { label: userLocationLabelFromId(locationId), value: locationId }
  })
  const funderOptions = funders.map(funder => { return {label: funder.name, value: funder._id} })
  const productOptions = products.map(product => { return {label: product.name, value: product._id} })
  return {
    ...state,
    hasReceivedMasterData: true,
    products: {
      byId: byId(products, '_id'),
      allIds: products.map(pr => pr._id),
      allProducts: products,
      productOptions
    },
    programs: byId(programs, 'id'),
    locations: {
      byId: byId(locations, '_id'),
      allLocations: locations,
      locationOptions
    },
    funders: {
      byId: byId(funders, '_id'),
      funderOptions
    }
  }
}

export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_MASTER_DATA: return receiveMasterData(state, action)
    default: return state
  }
}

export const fetchMasterData = (programs, locationId) => (dispatch, getState, { api }) => {
  programs = programs || []

  let fetchProducts
  if (programs.length === 0 || programs.includes('program:immunization')) {
    // empty programs = van-user
    // + masterData.listForPrograms does not support immunization
    // because it looks for service configuration docs
    fetchProducts = api.product.listAll()
  } else {
    // PSM Users should go here
    fetchProducts = api.product.listForPrograms(programs)
  }

  return Promise.all([
    api.location.listChildren(locationId, {
      deep: true,
      includeSelf: true
    }),
    fetchProducts,
    api.program.list(),
    api.funders.list({ filterForUser: true })
  ])
    .then(([locs, products, programs, funders]) => {
      const locations = locs.filter(item => item && !!item.configurations)
      dispatch({ type: RECEIVE_MASTER_DATA, products, locations, programs, funders })
    })
    .catch(err => {
      console.warn('Fetching err', err)
    })
}
