import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'
import MenuItemLink from './MenuItemLink'
import MenuItemFolder from './MenuItemFolder'

const MenuItem = ({
  path,
  title,
  icon,
  subItems,
  compact,
  onlineOnly,
  isOnline,
  onSubitemToggle,
  isActiveSlug,
  preventDefault,
  location,
  isIntercom,
  isExternal,
  badgeLabels,
  isDisabled,
  showBadgeLabel,
  ...otherProps
}) => {
  const isOfflineDisabled = onlineOnly && !isOnline
  const hasSubItems = subItems && subItems.length > 0

  return (
    <li
      key={isIntercom ? 'intercom' : path}
      className={classnames(
        'menu-item',
        {'menu-item--compact': compact},
        {'menu-item--has-sub-items': hasSubItems},
        {'menu-item--disabled': isDisabled}
      )}
    >

      {/*
        * 1) No subItems => We render a normal subapp Link
        * 2) Subitems => We render a "folder" with the subitems
        */}
      {!hasSubItems && (
        <MenuItemLink
          title={title}
          path={path}
          icon={icon}
          isOfflineDisabled={isOfflineDisabled}
          isCompact={compact}
          isIntercom={isIntercom}
          isExternal={isExternal}
          showBadgeLabel={showBadgeLabel}
          location={location}
        />
      )}

      {hasSubItems && (
        <MenuItemFolder
          title={title}
          path={path}
          subItems={subItems}
          icon={icon}
          isCompact={compact}
          badgeLabels={badgeLabels}
          preventDefault
        />
      )}
    </li>
  )
}

MenuItem.propTypes = {
  path: PropTypes.string,
  /**
   * What should the item label say
   */
  title: PropTypes.string.isRequired,
  /**
   * The menu icon representing this item
   */
  icon: PropTypes.node,
  /**
   * Items under here that should render when main item is active
   */
  subItems: PropTypes.array,
  /**
   * If the menu is collapsed and it should render compact
   */
  compact: PropTypes.bool,
  /**
   * If this page will only function when user is online
   */
  onlineOnly: PropTypes.bool,
  /**
   * Comes from withOffline
   */
  isOnline: PropTypes.bool,
  /**
   * To be passed on to any subitems, so that menu can be expanded from there
   */
  onSubitemToggle: PropTypes.func,
  /**
   * With this property we can disable the item links
   */
  preventDefault: PropTypes.bool
}

MenuItem.defaultProps = {
  icon: null,
  subItems: null,
  onlineOnly: false,
  isOnline: undefined,
  onSubitemToggle: () => null
}

export default withRouter(MenuItem)
