import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const DisplayValue = ({
  children,
  asNumber
}) => {
  return children ? (
    <span className={classnames(
      'vs-form-display-value',
      {'vs-form-display-value--number': asNumber}
    )}>
      {children}
    </span>
  ) : null
}

DisplayValue.proptypes = {
  /**
   * The form value to display, can also be another display formatting
   * component, like e.g. QuantityDisplay
   */
  children: PropTypes.node,
  /**
   * Display in our number font, usually used for batch numbers
   */
  asNumber: PropTypes.bool
}

DisplayValue.displayName = 'Form.DisplayValue'

export default DisplayValue
