function suppliesStateSDPs (relationships = {}, node, nodesMap) {
  relationships.supplies = relationships.supplies || []

  const stateIds = node.location.relationshipRules.suppliesStateSDPs
  if (!stateIds || stateIds.length === 0) return

  const stateIdsSet = new Set(stateIds)

  nodesMap.forEach(node => {
    if (node.type !== 'location' || node.location.level !== 'sdp') {
      return
    }
    const {zone, state} = node.location.location
    const stateId = `zone:${zone}:state:${state}`
    if (stateIdsSet.has(stateId)) {
      relationships.supplies.push(node.location._id)
    }
  })

  return relationships
}

module.exports = {suppliesStateSDPs}
