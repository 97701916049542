import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import remove from 'lodash/remove'
import classnames from 'classnames'
import { stringify, parse } from 'query-string'

import { DeemphasizedLink, FilterInput, Page } from '@fielded/shared-ui'
import { filterByProp } from '@fielded/shared-ui/src/components/FilterInput'

import withConfig from '../../../van-shared/hoc/withConfig'
import { hasFeature } from '../../../van-shared/utils/features'

import { headerLocationFor } from '../common/utils'

import withShipment from '../common/WithShipmentWrapper'
import withMasterData from '../common/withMasterData'
import shipmentsBreadcrumb from '../common/shipmentsBreadcrumb'
import ProductItem from '../components/ProductItem'
import ProductsSelectedButton from '../planning/ProductsSelectedButton'

export class DeleteProductsContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedProducts: props.preselected || [],
      filterTerm: this.props.filterTerm
    }
  }

  setFilterTerm = (filterTerm) => {
    this.setState({
      filterTerm
    })
  }

  toggleProduct = (id) => {
    const { selectedProducts } = this.state
    const newSelected = selectedProducts
    if (selectedProducts.includes(id)) {
      remove(newSelected, (a) => a === id)
    } else {
      newSelected.push(id)
    }
    this.setState({ selectedProducts: newSelected })
  }

  onClickNext = () => {
    const { snapshotId, history } = this.props
    const { selectedProducts } = this.state

    const productsQuery = stringify({products: selectedProducts}, {arrayFormat: 'bracket'})
    history.push(`/shipments/pick-list/${snapshotId}/shipment-options/remove-products/confirm?${productsQuery}`)
  }

  render () {
    const {
      config,
      pickedProducts,
      snapshotId,
      shipment
    } = this.props

    const {
      selectedProducts,
      filterTerm
    } = this.state

    const productOptions = Object.keys(pickedProducts).map(id => {
      const product = pickedProducts[id]
      return {
        ...product
      }
    })
    const showProductCode = config && hasFeature(config.features, 'shipments:addProduct:showProductCode')
    const filteredProducts = filterByProp(productOptions, filterTerm, 'name')
    const destination = headerLocationFor(shipment, config)
    const shipmentLabel = shipment.statusType === 'distribution' ? 'Distribution to' : 'Arrival from'

    return (
      <Page>
        <Page.Header
          title='Remove products'
          breadcrumbItems={[
            shipmentsBreadcrumb,
            {
              title: `${shipmentLabel} ${destination}`,
              path: `/shipments/pick-list/${snapshotId}`
            }
          ]}
        />
        <Page.Intro>
          <FilterInput
            onValueChange={(value) => this.setFilterTerm(value)}
            placeholder='Filter by product name'
            value={this.state.filterTerm}
          />
        </Page.Intro>
        {filteredProducts.length > 0 ? (
          <Page.Panel spread withMargin={false}>
            <section className='product-bulk-selection'>
              <ul className='selectable-list'>
                {filteredProducts
                  .map(product =>
                    <ProductItem
                      key={product.id}
                      product={pickedProducts[product.id]}
                      selected={selectedProducts.includes(product.id)}
                      showTotal
                      selectForDeletion
                      showProductCode={showProductCode}
                      onToggle={() => this.toggleProduct(product.id)}
                    />
                  )}
              </ul>
            </section>
          </Page.Panel>
        ) : (
          <Page.Panel>
            0 products found
          </Page.Panel>
        )}
        <Page.Footer
          className={classnames(
            'product-bulk-selection__footer',
            {'product-bulk-selection__footer--visible': selectedProducts.length > 0}
          )}
        >
          <ProductsSelectedButton
            data-qa='shipment-delete-product-save'
            selectedProducts={selectedProducts.length}
            onClickNext={this.onClickNext}
          />
          <DeemphasizedLink
            alignOpposite
            component={Link}
            to={`/shipments/pick-list/${snapshotId}`}
            style={{ marginRight: 'auto' }}
          >
            Cancel and go back
          </DeemphasizedLink>
        </Page.Footer>
      </Page>
    )
  }
}

const mapStateToProps = (state, { match: { params }, location: { search } }) => {
  const { products = [] } = parse(search, {arrayFormat: 'bracket'})
  return {
    preselected: products,
    pickedProducts: state.pickList,
    snapshotId: params.snapshotId
  }
}

export default withConfig(
  withMasterData(
    withShipment(
      connect(mapStateToProps)(DeleteProductsContainer)
    )
  )
)
