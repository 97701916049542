import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const colorCodes = ['brand', 'accent', 'warning']
const sizes = ['large']
const shapes = ['round', 'square']

const CountBadge = ({
  colorCode,
  size,
  count,
  shapeVariant,
  withPointer,
  className
}) => {
  return (
    <span className={classnames(
      'vs-count-badge',
      {'vs-count-badge--brand': colorCode === 'brand'},
      {'vs-count-badge--accent': colorCode === 'accent'},
      {'vs-count-badge--warning': colorCode === 'warning'},
      {'vs-count-badge--size-large': size === 'large'},
      {'vs-count-badge--shape-round': shapeVariant === 'round'},
      {'vs-count-badge--shape-square': shapeVariant === 'square'},
      {'vs-count-badge--with-pointer': withPointer},
      className
    )}>
      {count}
    </span>
  )
}

CountBadge.propTypes = {
  /**
   * Color of the badge to be shown
   */
  colorCode: PropTypes.oneOf([...colorCodes, null]),
  /**
   * Size of the badge
   */
  size: PropTypes.oneOf([...sizes, null]),
  /**
   * shape of the badge
   */
  shapeVariant: PropTypes.oneOf([...shapes]),
  /**
   * A pointer indicator. It can only be used when the shape variant is square
   */
  withPointer: PropTypes.bool,
  /**
   * The number to show in the counter
   */
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Any additional class names you want to add
   */
  className: PropTypes.string
}

CountBadge.defaultProps = {
  colorCode: 'brand',
  size: null,
  shapeVariant: 'round',
  withPointer: false,
  count: 0,
  className: null
}

export default CountBadge
