import React, { Fragment } from 'react'

import {
  ActionsBar,
  Button,
  DeemphasizedLink,
  IconBadge,
  Form,
  Modal,
  Page,
  PriceDisplay,
  TotalAmount,
  TextInput
} from '@fielded/shared-ui'

import { Arrow, PayAsYouSell, PayOnDelivery } from '@fielded/shared-ui/src/icons'

import ShipmentContentsTable from '../../components/ShipmentContentsTable'
import ShipmentCRContentsTable from '../../components/ShipmentCRContentsTable'
import PaymentConfirmationSection from '../../../../van-shared/components/PaymentConfirmationSection'

import { pluralize } from '../../../../van-shared/utils/utils'

import { DIRECT_ORDER_TYPES } from '@fielded/fs-api/lib/allocation/config'
import { batchIdToProductId } from '../../common/utils'
import { userIsAuthorised } from '../../../../van-shared/utils/auth'
import { PLANNING_TYPES } from '@fielded/fs-api/lib/shipment/tools'
import { withUser } from '../../../../common/AuthenticationProvider'
import NonCheckedOffProductsTable from '../NonCheckedOffProductsTable'
const { PAY_ON_DELIVERY, PAY_AS_YOU_SELL } = DIRECT_ORDER_TYPES

const SLDeliveryConfirmation = ({
  location,
  shipment,
  productsById,
  pickList,
  items,
  shipmentCountry,
  signature,
  formRef,
  showTotalAmountConfirmation,
  onProceed,
  onCloseModal,
  onOpenModal,
  isCollectRedistribute,
  config,
  comment,
  name,
  onCommentChange,
  user
}) => {
  const appName = config.name
  const totalAmount = items.reduce((acc, item) => {
    acc = acc + item.totalToPay
    return acc
  }, 0)

  let signatureInfoText = 'Please confirm delivery and acknowledge receipt of the products listed above from Field Technology Development Partners LTD by signing on behalf of the company here below:'

  if (isCollectRedistribute) {
    if (totalAmount > 0) {
      signatureInfoText = `By refusing to return in full the requested quantities of ${appName}-managed products, you are agreeing to pay for and take full ownership of the units you retain. They will no longer be eligible for return to ${appName}. By signing below, any quantities refused return will be added to you invoice and ownership transferred to you.`
    } else {
      signatureInfoText = `Please confirm return of the products listed above to ${appName} by signing below:`
    }
  }

  const productList = items
    .filter(item => item.totalToPay > 0)
    .map(item => (
      <li
        key={item.code}
        className='sl-delivery-confirmation-modal__item'
      >
        <span>
          {item.subscriptionType === PAY_AS_YOU_SELL && (
            <IconBadge
              colorCode={PAY_AS_YOU_SELL}
              icon={<PayAsYouSell />}
              className='sl-delivery-confirmation-modal__item-badge'
            />
          )}

          {item.subscriptionType === PAY_ON_DELIVERY && (
            <IconBadge
              colorCode={PAY_ON_DELIVERY}
              icon={<PayOnDelivery />}
              className='sl-delivery-confirmation-modal__item-badge'
            />
          )}
          {item.name} x {item.quantityToCover}
        </span>
        <PriceDisplay
          value={item.totalToPay}
          country={shipmentCountry}
          currencySymbol
        />
      </li>
    ))

  let nonCheckedProducts = []
  if (shipment.planningType === PLANNING_TYPES.C_R && userIsAuthorised(user, 'feature:userRole:fp')) {
    nonCheckedProducts = Object.keys(shipment.counts).filter(batch => shipment.counts[batch].checked === false)
  }

  const nonCheckedProductsWithNames = nonCheckedProducts.map(batchId => {
    const productId = batchIdToProductId(batchId)
    return {id: productId, name: productsById[productId].name}
  })
  const blockConfirmation = nonCheckedProducts.length > 0

  let pageTitle = 'Confirm quantities to be delivered'
  let pageContent = (
    <ShipmentContentsTable items={items} shipmentCountry={shipmentCountry} />
  )

  if (blockConfirmation) {
    const productsString = pluralize('product', nonCheckedProducts.length)
    const have = nonCheckedProducts.length === 1 ? 'has' : 'have'
    pageTitle = `${nonCheckedProducts.length} ${productsString} ${have} not been checked`

    pageContent = (
      <Fragment>
        <header className={'confirmation__header confirmation__header--small'}>
          <div className='confirmation__header-title'>
            {location.name}
            <div className='confirmation__header-info'>{appName} Pharmaceuticals</div>
          </div>
        </header>

        <NonCheckedOffProductsTable
          rowsNonCheckedOff={nonCheckedProductsWithNames}
        />
      </Fragment>
    )
  } else if (isCollectRedistribute) {
    pageTitle = 'Confirm quantities returned'

    pageContent = (
      <Fragment>
        <ShipmentCRContentsTable items={items} shipmentCountry={shipmentCountry} />
        <TotalAmount
          label='Sub-total to pay'
          value={totalAmount}
          countryId={shipmentCountry}
          isTight
          footnoteOnRight
          footnote={totalAmount <= 0 ? '' : 'excluding VAT'}
          isDimmed={totalAmount <= 0}
        />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Page.Intro title={pageTitle} />
      <Page.Panel withBackground>
        <Page.Panel.Section fullWidth>
          {pageContent}
        </Page.Panel.Section>

        {!blockConfirmation && <Page.Panel.Section
          fullWidth
          className='sl-delivery-confirmation__signature'
        >
          <Form ref={formRef}>
            <Form.Section>
              <Form.Row>
                <Form.Field
                  fieldName='name'
                  labelText='Name'
                  spread
                >
                  <Form.Field.Text
                    disabled={signature || name}
                    defaultValue={name}
                    required={'Please enter a name'}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>
              <Form.Row>
                <Form.Field
                  labelText={signatureInfoText}
                  fieldName='signature'
                  spread
                >
                  <Form.Field.Signature
                    disabled={signature}
                    defaultValue={signature}
                    required={'Please sign'}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>
            </Form.Section>
          </Form>
        </Page.Panel.Section>}
        {!blockConfirmation && <Page.Panel.Section
          fullWidth
          className='sl-delivery-confirmation__signature'
        >
          <p>
            If you have any comment, please note it here.
          </p>
          <TextInput
            className='confirmation-commment__input'
            name='comment'
            value={comment}
            onChange={onCommentChange}
            rows={5}
          />
        </Page.Panel.Section>}
      </Page.Panel>

      <Page.Footer layout='centered'>
        <Button
          fill='full'
          size='large'
          colorVariant={blockConfirmation ? undefined : 'confirmation'}
          iconSide='right'
          icon={<Arrow direction='right' />}
          disabled={blockConfirmation}
          onClick={totalAmount > 0 ? onOpenModal : onProceed}
        >
          Proceed
        </Button>
      </Page.Footer>

      {isCollectRedistribute && (
        <Modal
          className='sl-delivery-confirmation-modal'
          centered={false}
          spacing='none'
          onClose={onCloseModal}
          isOpen={showTotalAmountConfirmation}
          title='Adding to invoice:'
        >
          <PaymentConfirmationSection
            countryId={shipmentCountry}
            totalAmount={totalAmount}
            headerLabel={`${pluralize('product', productList.length)} refused return`}
          >
            {productList}
          </PaymentConfirmationSection>
          <p className='sl-delivery-confirmation-modal__notice'>
            I understand that by proceeding these items will be added to my invoice.
          </p>
          <ActionsBar
            className='sl-delivery-confirmation-modal__actions'
            layout='centered'
          >
            <Button
              colorVariant='brand'
              fill='full'
              size='large'
              onClick={onProceed}
            >
              Agree and proceed
            </Button>
            <DeemphasizedLink
              onClick={onCloseModal}
            >
              Go back and review
            </DeemphasizedLink>
          </ActionsBar>
        </Modal>
      )}
    </Fragment>
  )
}

export default withUser(SLDeliveryConfirmation)
