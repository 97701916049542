const {getAll: listServicesForProgram, listReportFields} = require('../../service')
const {toStockCountId, docToStockCountRecord} = require('../../tools')
const getIdVersion = require('../tools/get-id-version')

async function listForLMD (state, {programId, reportingPeriod, locations}) {
  const services = await listServicesForProgram(state, programId)
  services.forEach(service => {
    const idVersion = getIdVersion(service)
    if (idVersion > 1) {
      throw new Error(
        `listForLMD found program with idVersion ${idVersion}, it can only support id version 1`
      )
    }
  })

  const fields = await Promise.all(services.map(service => listReportFields(state, service.id)))
  const fieldsByServiceId = fields
    .reduce((acc, serviceFields, index) => {
      acc[services[index].id] = serviceFields
      return acc
    }, {})
  const ids = {}
  locations.forEach(location => {
    services.forEach(service => {
      if (location.services.includes(service.id)) {
        const id = toStockCountId({location: location._id, service, reportingPeriod})
        ids[id] = {service, location, id, fields: fieldsByServiceId[service.id]}
      }
    })
  })
  const {rows} = await state.dal.report.read(state, Object.keys(ids))
  const docs = rows.map(row => row.doc).filter(x => x)
  return docs.map(doc => docToStockCountRecord(doc, ids[doc._id].service, {fields: ids[doc._id].fields}))
}

module.exports = listForLMD
