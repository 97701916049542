const { getByIds: getProductByIds } = require('./../product')

module.exports = async function validateProducts (state, allocations) {
  // validate products for each allocation
  const allocationProducts = allocations.reduce((ids, allocation) => {
    const {products = {}, aggregatedProducts = {}} = allocation
    const mergedProductIds = Object.keys(products).concat(Object.keys(aggregatedProducts))
    return ids.concat(mergedProductIds)
  }, [])

  const productIds = Array.from(new Set(allocationProducts))
  const products = await getProductByIds(state, productIds)
  const foundProductIds = products.map(product => product._id)

  if (foundProductIds.length !== productIds.length) {
    const invalidProducts = productIds.filter(id => foundProductIds.indexOf(id) === -1)
    return new Error(`No products available for ${invalidProducts.join(',')}`)
  }

  return null
}
