// reads in an array of order snapshots
// then returns an array of unique products and an array of products in order mapped by sku & qty
exports.getOrderProducts = getOrderProducts
function getOrderProducts (orderSnapshots) {
  const productMap = []
  const products = new Set()
  orderSnapshots.forEach(o => {
    const ids = Object.keys(o.products)
    const values = Object.values(o.products)
    for (let i = 0; i < ids.length; i++) {
      let sku = ids[i].split(':')[1]
      products.add(sku)
      productMap.push({
        sku,
        quantity: values[i].adjusted
      })
    }
  })

  const uniqueProducts = Array.from(products)
  return { productMap, uniqueProducts }
}

exports.tallyOrderAmounts = tallyOrderAmounts
function tallyOrderAmounts (productMap, productPrices, vatPercent = 0) {
  const prices = productPrices.reduce((acc, product) => {
    const vatAmount = product.vat ? (product.sellprice * vatPercent) / 100 : 0
    acc[product.sku] = {
      sellPrice: product.sellprice,
      buyPrice: product.buyprice,
      vat: vatAmount
    }
    return acc
  }, {})

  const productGroup = productMap.reduce((acc, product) => {
    if (prices[product.sku]) {
      const sellPrice = prices[product.sku].sellPrice
      if (acc[product.sku]) {
        acc[product.sku].quantity += product.quantity
        acc[product.sku].amount += (product.quantity * sellPrice)
      } else {
        acc[product.sku] = {
          quantity: product.quantity,
          amount: sellPrice * product.quantity,
          skuPrice: sellPrice
        }
      }
      acc[product.sku].vat = prices[product.sku].vat
    }
    return acc
  }, {})

  const totalAmount = Object.values(productGroup).reduce((acc, prods) => acc + prods.amount + prods.vat, 0)
  return { productGroup, totalAmount }
}
