import React from 'react'
import classnames from 'classnames'

const DriverDeliveryFooterCard = ({ children, className }) => {
  return (
    <section className={classnames(
      'driver-delivery-footer-card__container',
      className
    )}>
      {children}
    </section>
  )
}

export default DriverDeliveryFooterCard
