
// Permissions
const PERMISSIONS = {
  GEOLOCATION: 'geolocation'
}

// Permission API states
const PERMISSION_STATES = {
  GRANTED: 'granted',
  PROMPT: 'prompt',
  DENIED: 'denied'
}

// Permission API errors (3)
const PERMISSION_ERRORS = {
  PERMISSION_DENIED: 'You denied the request for Geolocation.',
  POSITION_UNAVAILABLE: 'Location information is unavailable',
  TIMEOUT: 'The request to get user location timed out.',

  // generic error
  DEFAULT: 'An unknown error occurred.'
}

const getPermission = async (name) => {
  if ('permissions' in navigator) {
    const result = await navigator.permissions.query({ name })
    return result.state
  } else {
    console.warn('Browser does not support the Permission API')
  }
}

export {
  PERMISSIONS,
  PERMISSION_STATES,
  PERMISSION_ERRORS,
  getPermission
}
