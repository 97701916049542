import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Eye, EyeSlash } from '../../icons'

const defaultOnChange = () => null

class TextInput extends React.Component {
  state = {
    passwordVisible: false
  }

  static propTypes = {
    /**
     * An optional className to apply to the element
     */
    className: PropTypes.string,

    /**
     * An optional type to pass to the element
     */
    type: PropTypes.string,

    /**
     * The error state
     */
    hasError: PropTypes.bool,

    /**
     * Runs on input changes, returns the text value (not the event)
     */
    onValueChange: PropTypes.func,

    /**
     * Runs on input change, returns whole event
     */
    onChange: PropTypes.func,

    /**
     * use to ref the input field
     */
    getInputRef: PropTypes.func,

    /**
     * The input value
     */
    value: PropTypes.string,

    /**
     * Number of rows to display
     */
    rows: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),

    /**
     * Align value on the right side
     */
    textAlign: PropTypes.oneOf(['left', 'right'])
  }

  static defaultProps = {
    className: '',
    type: 'text',
    hasError: false,
    onChange: defaultOnChange,
    onValueChange: null,
    getInputRef: () => null,
    // Set to '' because we want React to know this a controlled component
    value: '',
    rows: 1,
    textAlign: 'left'
  }

  handleChange = e => {
    const { onValueChange, onChange } = this.props

    if (typeof onValueChange === 'function') {
      const newValue = e.target.value
      onValueChange(newValue)
    }

    if (typeof onChange === 'function') {
      e.persist()
      onChange(e)
    }
  }

  togglePasswordVisibility = () => {
    const { passwordVisible } = this.state
    this.setState({
      passwordVisible: !passwordVisible
    })
  }

  render () {
    const {
      className,
      type,
      hasError,
      value,
      rows,
      textAlign,
      onValueChange,
      onChange,
      getInputRef,
      ...otherProps
    } = this.props

    const { passwordVisible } = this.state

    const multiRow = parseInt(rows, 10) > 1

    const props = {
      className: classnames(
        'vs-text-input',
        { 'vs-text-input--multirow': multiRow },
        { 'vs-text-input--text-align-right': textAlign === 'right' },
        { 'vs-text-input--has-error': hasError },
        className
      ),
      'data-qa': this.props.dataQa || this.props.name,
      onChange: this.handleChange,
      rows,
      value,
      ref: getInputRef,
      ...otherProps
    }

    // If the rows prop is set to a value greater than 1 then render a textarea
    // instead of an input
    if (multiRow) {
      return (
        <textarea {...props} />
      )
    }

    if (type === 'password') {
      return (
        <div className='vs-text-input__password-wrapper'>
          <input
            type={passwordVisible ? 'text' : 'password'}
            {...props}
          />
          <button
            className={classnames(
              'vs-text-input__visibility-button',
              { 'vs-text-input__visibility-button--visible': passwordVisible }
            )}
            aria-hidden // This will not be helpful for visually impaired user
            tabIndex='-1'
            type='button'
            onClick={() => this.togglePasswordVisibility()}
            title={passwordVisible ? 'Hide password' : 'Show password'}
          >
            {passwordVisible ? <EyeSlash /> : <Eye />}
          </button>
        </div>
      )
    } else if (type === 'email') {
      return (
        <div className='vs-text-input__password-wrapper'>
          <input
            type='email'

            {...props}
          />

        </div>
      )
    }

    return (
      <input type={type} {...props} />
    )
  }
}

export default TextInput
