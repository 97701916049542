import * as React from 'react'
import withConfig from '../../../van-shared/hoc/withConfig/withConfig'
import ShipmentsCreate from './ShipmentsCreate'
import { withApi } from '../../../common/ApiProvider'

class ShipmentsCreateContainer extends React.PureComponent {
  render () {
    const { history, config, match: { params: { snapshotId } } } = this.props

    return (
      <ShipmentsCreate
        history={history}
        config={config}
        shipmentId={snapshotId}
      />
    )
  }
}

export default withApi(withConfig(ShipmentsCreateContainer))
