const { createRow, getFacilityLocation } = require('../encode-utils')

const encoder = (format) => {
  switch (format) {
    case 'csv':
      return require('../csv-encode')
    case 'xlsx':
    default:
      return require('../xlsx-encode')
  }
}

const exportAllocations = async (state, { format } = {}) => {
  const now = new Date().toISOString()
  const encode = encoder(format)
  const allLocations = await state.allocationApi.mainApi.location.listAll({ date: now })
  const facilityIds = new Set(allLocations.map(l => l._id))
  const allocations = await state.allocationApi.getAllConfigurations({ facilityIds, date: now, raw: true })

  // Find locations without allocations
  const locationsWith = new Set(allocations.map(a => a.facilityId))
  const locationsWithout = allLocations.filter(l => !locationsWith.has(l._id))

  // Create rows without product values for locations without
  // allocation, so that those are visible in the exported xlsx
  // and can be filled out on demand.
  const additionalRows = locationsWithout.map(l => createRow({
    facility: {
      id: l._id,
      name: l.name,
      location: getFacilityLocation(l._id)
    },
    forecast: { method: '' }
  }))

  return encode(allocations, additionalRows)
}

module.exports = exportAllocations
