const createBulkSnapshots = require('../../../shipment/shipment-bulk-create-snapshots')
const { VALIDATION_REMARKS } = require('./../../../order/tools/validate-planning')
const tools = require('../../tools')
const closeOrders = require('./close').close
const get = require('lodash/get')

exports.confirmGroup = confirmGroup
async function confirmGroup (
  state, mainApi, {groupId, dryRun = false}, {supplierLedgers, orders, excludedOrders = [], notMappedProducts = [], productsWithNoPrice = []} = {}
) {
  const closedAt = new Date().toJSON()
  // TODO: we get cached orders from the UI, but we again go get the raw
  // snapshots to use the order snapshot shape (has _id for shipmentSnapshot.orderId)
  // don't create shipments for snapshots whose doc is already marked as complete
  const excludedViaLoanBalanceOnly = excludedOrders.filter(order => {
    return order.remark === VALIDATION_REMARKS.failed
  })
  const snapshots = (
    await state.dal.order.getGroup(state, {groupId, onlyIncompleteSnaps: true})
  ).filter(snapshot => {
    return !excludedViaLoanBalanceOnly.some(order => (order._id === snapshot._id) || (order.groupId === snapshot.groupId && order.destinationId === snapshot.destinationId))
  })

  // delete products with no sellprice from snapshots
  snapshots.forEach(snap => {
    productsWithNoPrice.forEach(id => {
      delete snap.products[id]
    })
  })

  // if products not mapped they should be removed from snapshots
  snapshots.forEach(snapshot => {
    notMappedProducts.forEach(product => {
      delete snapshot.products[product._id]
    })
  })
  if (!snapshots.length) {
    return []
  }

  const products = await mainApi.product.listAll()
  // Get default packpoint for territory
  const destinationId = get(snapshots[0], `destinationId`)
  const orderType = get(snapshots[0], `orderType`)

  const destination = await mainApi.location.get(destinationId)
  const membershipType = destination.membership
  const [ defaultSupplier ] = await mainApi.order.getSupplierIdsForLocation(destination, {planType: orderType})

  const destinationSnapshots = tools.createDestinationShipments({
    orderSnapshots: snapshots,
    closedAt,
    products,
    defaultSupplier,
    membershipType
  })
  if (dryRun) {
    return destinationSnapshots
  }

  const shipments = await createBulkSnapshots(state, destinationSnapshots)
  const closedResponse = await closeOrders(state, {groupId, closedAt, closedStatus: 'confirmed'})
  return {shipments, closedResponse}
}
