module.exports = findAllForShipment
const docsToSentReceivedSurveys = require('./tools/docs-to-surveys-record')

function findAllForShipment (state, shipmentId) {
  return state.dal.shipment.getByPrefix(state, shipmentId)

    .then(result => {
      const surveyDocs = result.rows.map(row => row.doc)
        .filter(doc => doc.type === 'survey')
      return docsToSentReceivedSurveys(surveyDocs)
    })
}
