import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const CardContent = ({
  children,
  className
}) => (
  <section className={classnames(
    'vs-card__content',
    className
  )}>
    {children}
  </section>
)

CardContent.propTypes = {
  /**
   * Additional content, e.g. text
   */
  children: PropTypes.node,
  /**
   * Optionally add an additional class name
   */
  className: PropTypes.string
}

CardContent.displayName = 'Card.Content'

export default CardContent
