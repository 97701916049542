import { isShelflifePowered } from '../common/utils'
import PackageNoteTemplate from './print-templates/sl-package-note/SLPackageNote'
import DeliveryNoteTemplate from './print-templates/sl-delivery-note/SLDeliveryNote'

export default function (shipment, config, printTemplate) {
  if (isShelflifePowered(config)) {
    if (printTemplate === 'packageNote' || shipment.status === 'sent') {
      return PackageNoteTemplate
    }
    return DeliveryNoteTemplate
  }
}
