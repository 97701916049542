// Import required libraries
const XLSX = require('@sheet/core')
const { format } = require('date-fns')
const capitalize = require('lodash/capitalize')
const { TXN_TYPES, DEBITS } = require('../../constants')
const PAYABLE_TXN_TYPES = ['credit memo', 'invoice', 'installment']
const toStatementTxnTransform = {
  'credit memo': 'Credit memo',
  'installment': 'Instalment',
  'installment payment': 'Payment',
  'write-off': 'Write-off'
}

const exportData = (statements = []) => {
  // Sample data
  const data = [
    ['Date', 'Type', 'Description', 'Amount', 'Status'],
    ...statements.map(statement => {
      const date = format(statement.txn_date || statement.created_at, 'DD/MM/YYYY')
      const txnType = toStatementTxnTransform[statement.txn_type] || capitalize(TXN_TYPES[statement.txn_type])
      const isDebit = !!DEBITS[statement.txn_type]
      const amount = isDebit ? -statement.amount : statement.amount
      const isPaid = !(PAYABLE_TXN_TYPES.includes(statement.txn_type) && statement.amount !== statement.amount_paid)
      return [date, txnType, (statement.quickbooks_doc_number || ''), amount, (isPaid ? 'Paid' : 'Unpaid')]
    })
  ]

  // Create a new workbook and add a worksheet
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(data)

  // Set column widths
  ws['!cols'] = [
    { width: 15 },
    { width: 29 },
    { width: 31 },
    { width: 12 },
    { width: 12 }
  ]

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

  return XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' })
}

module.exports = {
  exportData
}
