import React from 'react'
import { withRouter } from 'react-router-dom'
import flatMap from 'lodash/flatMap'
import last from 'lodash/last'

import { Form } from '@fielded/shared-ui'
import { isHeldLocation } from '@fielded/fs-api/lib/shipment/tools/is-held-location'

import { hasFeature } from '../../../van-shared/utils/features'
import withConfig from '../../../van-shared/hoc/withConfig'

import { buildServicesUrl, buildViewReportUrl } from '../app/routes-urls'

const LocationsSearch = ({
  locations,
  urlParams,
  isEditablePeriod,
  showPartialCount,
  alwaysSubmitPartials,
  history,
  config,
  showShipmentSyncBanner
}) => {
  const isPSM = config.app === 'psm'

  const options = locations.map(location => {
    /**
     * For tenant with feature showLgaOnLocationListSearch we add the lga name to the
     * labelParts array that wull show on the location list search in stockreporting,
     * presently we do that for only PSM
     */
    const labelParts = [location.fullName]
    if (location.location && location.location.lga && hasFeature(config.features, 'stockCount:showLgaOnLocationListSearch')) {
      labelParts.push(location.location.lga)
    }

    // Used in SL only: Find last submitted report for this period, see getOverview response
    const reports = flatMap(location.progress.services, s => s.progress.reports)
    const reportId = (last(reports) || {}).id
    const heldLocationForAdmin = alwaysSubmitPartials && isHeldLocation(location._id)

    // SL Only: disable items from past periods if no report is available
    // SL Only: link to report view if there is a reportId
    const listAsDisabled = ((showPartialCount && !isEditablePeriod && !reportId) || (showShipmentSyncBanner && isPSM))
    if (listAsDisabled) {
      return {
        value: location._id,
        label: labelParts,
        disabled: true
      }
    }

    // SL Only: link to partial reports for held locations
    if (!isPSM && (heldLocationForAdmin || (showPartialCount && reportId))) {
      const url = buildServicesUrl({...urlParams, reportLocationId: location._id})
      return {
        value: location._id,
        label: labelParts,
        url: `${url}/partial`
      }
    }

    // SL Only: link to report view if there is a reportId
    if (!isPSM && (!showPartialCount && reportId)) {
      return {
        value: location._id,
        label: labelParts,
        url: buildViewReportUrl({ reportId: reportId })
      }
    }

    // For open periods: open report for writing
    // PSM for closed periods: opens "read only" report
    return {
      value: location._id,
      label: labelParts,
      url: buildServicesUrl({ ...urlParams, reportLocationId: location._id })
    }
  })

  const onChange = (selected) => {
    history.push(selected.url)
  }

  return (
    <div className='location-search'>
      <h4 className='location-search__title'>Choose a location</h4>
      <Form>
        <Form.Field fieldName='search'>
          <Form.Field.LocationSelect
            options={options}
            onChange={onChange}
          />
        </Form.Field>
      </Form>
    </div>
  )
}

export default withRouter(withConfig(LocationsSearch))
