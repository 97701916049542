const saveChanges = require('./shipment-save-changes')
const findShipment = require('./shipment-find')
const toShipmentId = require('./tools/to-shipment-id')
const { isBatchId, isSnapshotId } = require('./tools/product-batch-util')
const getBatchesMatchingProductId = require('./tools/get-batches-matching-product-id')

function removeProduct (state, snapshotId, productId) {
  if (isBatchId(productId)) return Promise.reject(new Error(`${productId} should be a productId`))
  if (!isSnapshotId(snapshotId)) return Promise.reject(new Error(`${snapshotId} should be a snapshotId`))

  const shipmentId = toShipmentId(snapshotId)
  return findShipment(state, shipmentId)
    .then(shipments => {
      const shipment = shipments[0]
      const batchesToRemove = getBatchesMatchingProductId(productId, shipment.counts)

      if (!batchesToRemove.length) {
        return shipment
      }

      const changes = batchesToRemove.reduce((index, batchId) => {
        index[batchId] = {
          removed: true
        }
        return index
      }, {})

      return saveChanges(state, snapshotId, changes)
    })
}

module.exports = removeProduct
