// Check if a location is a held location.
// Held locations receive the shipments from suppliers and distribute
// them to pharmacies.
// Atm we have no better way to check this than doing a regex on the id
// that captures if a location a certain string in its id.
const isHeldLocation = locationId =>
  locationId.match(/^country:[^:]+:state:[^:]+:sdp:[^:]*\b(sl|held|pack-point|hq|packpoint|facility)\b/)

module.exports = {
  isHeldLocation
}
