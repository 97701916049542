import React from 'react'
import PropTypes from 'prop-types'
import clasnames from 'classnames'

const NonCheckedOffProductsTable = ({
  rowsNonCheckedOff
}) => {
  return (
    <table className='shipment-confirmation-table shipment-confirmation-table--non-checked-product'>
      <thead>
        <tr className='shipment-confirmation-table__tr'>
          <th className='shipment-confirmation-table__th shipment-confirmation-table__th--products'>Products</th>
        </tr>
      </thead>
      <tbody>
        {rowsNonCheckedOff.map(row => {
          return (
            <tr
              className={clasnames('shipment-confirmation-table__tr')}
              key={`${row.id}`}
            >
              <td className={`shipment-confirmation-table__td`}>
                {row.name}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

NonCheckedOffProductsTable.propTypes = {
  /**
   * Array of products that have not been checked off
   */
  rowsNonCheckedOff: PropTypes.array
}

export default NonCheckedOffProductsTable
