import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import userSession from '../login/userSessionSlice'
import { syncReducer as sync } from '../sync'
import location from '../subapps/retailer/common/reducers/locationSlice'
import featureOnboarding from '../subapps/retailer/common/reducers/featureOnboardingSlice'
import heldLedger from '../subapps/retailer/common/reducers/heldLedgerSlice'
import subscriptions from '../../src/subapps/facility-products/reducers/subscriptionsSlice'
import orderPlacement from '../../src/subapps/facility-products/reducers/orderPlacementSlice'
import fastOrderPlacement from '../../src/subapps/facility-products/reducers/fastOrderPlacementSlice'
import topups from '../subapps/retailer/orders/reducers/topupsSlice'
import leadExperience from '../subapps/retailer/lead/reducers/leadExperienceSlice'
import finances from '../subapps/retailer/finances/reducers/financesSlice'

import { getUserReducers } from './get-user-reducers'

const reducer = combineReducers({
  userSession,
  sync,
  location,
  featureOnboarding,
  heldLedger,
  orderPlacement,
  fastOrderPlacement,
  subscriptions,
  topups,
  leadExperience,
  finances
})

// this ensures your redux state is saved to persisted storage whenever it changes
// we pass this to the store
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // if you do not want to persist this part of the state
  blacklist: ['sync'] // whitelist orderPlacement, fastOrderPlacement, location, leadExperience
}

export const persistedReducer = persistReducer(persistConfig, reducer)

// TODO: this will replace the persistedReducer above
export const getPersistedReducer = (user) => {
  const { reducers, persistConfig } = getUserReducers(user)
  const reducer = combineReducers(reducers)
  const persistedReducer = persistReducer(persistConfig, reducer)
  return persistedReducer
}
