exports.save = function (state, doc) {
  return state.productsDB.put(doc).then(response => {
    return Object.assign({}, doc, {
      _rev: response.rev
    })
  })
}
exports.bulkDocs = async function (state, docs) {
  const response = await state.productsDB.bulkDocs(docs)
  const errors = response.filter(row => row.error)
  if (errors.length) {
    const err = new Error(`Error bulk updating documents ${errors[0].error} ${errors[0].reason}`)
    err.data = errors
    throw err
  }

  return response
}
