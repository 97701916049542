const _get = require('lodash/get')
const { listAllConfigurations } = require('./../../configuration/api/read/list-all')
const { listPrograms } = require('./../../program/api/read/list-programs')
const { getByIds: getLocationsById } = require('./../../location/api/read/get-by-ids')
const tools = require('../tools/create-bulk-import-reports')
const getLedgerBalanceAsReport = require('../../stock-get-ledger-balance-as-report')
const { stockCountIdToLocationProperties } = require('../../tools')
const { byId } = require('../../tools/by-id')
const get = require('./get')

exports.bulkImport = bulkImport
async function bulkImport (
  state,
  {
    productRows = [],
    date = new Date().toJSON(),
    version = '2.0.0',
    dryRun = false,
    mergeWithLedgerBalance = true,
    // TODO: remove this when https://github.com/fielded/van-orga/issues/4024 is fixed, see below
    // only used in bulk-import-test.
    throwOnMissingBatches = true,
    noSubmittedAt = false
  }
) {
  const locationIds = [...new Set(productRows.map(r => r.locationId))]
  const {programs, serviceDocs, locationEntities} = await fetchMasterData(state, locationIds, date)
  let createdReports = tools.createBulkImportReports({
    programs,
    serviceDocs,
    locationEntities,
    productRows,
    username: state.user.name,
    date,
    version
  })
  if (noSubmittedAt) {
    createdReports.forEach(async report => {
      const lastReport = await get(state, {reportId: report._id})
      const submittedAt = lastReport && lastReport.submittedAt ? lastReport.submittedAt : report.submittedAt
      report.submittedAt = submittedAt
    })
  }

  if (mergeWithLedgerBalance) {
    const existingBalances = await getExistingBalances({
      state, createdReports, date, programs, locationEntities
    })
    createdReports = tools.mergeExistingReports(createdReports, existingBalances)
  }

  // TODO: temporary troubleshooting, see https://github.com/fielded/van-orga/issues/4024
  // bulk-import is used only by PSM orders right now, and a report with
  // all zero batches is definitely a problem.
  if (throwOnMissingBatches && allBatchesAreZero(createdReports)) {
    throw new Error(`Error! All batches are about to be zero, cannot proceed.`)
  }

  if (hasNegativeValues(createdReports)) {
    throw new Error(`Error! Negative values are about to be created, cannot proceed.`)
  }

  if (dryRun) return createdReports

  return state.dal.report.bulkUpsert(state, createdReports)
}

async function fetchMasterData (state, locationIds, date) {
  const locationEntities = await getLocationsById(state, locationIds, date)
  const programs = await listPrograms(state)
  const serviceDocs = await listAllConfigurations(state)
  return {locationEntities, programs, serviceDocs}
}

async function getExistingBalances ({
  state, createdReports, locationEntities, date, programs
}) {
  const servicesById = programs
    .reduce((acc, program) => {
      program.services.forEach(service => {
        acc[service.id] = service
      })
      return acc
    }, {})

  const locationEntitiesById = byId(locationEntities)

  return Promise.all(createdReports.map(doc => {
    const {serviceId} = doc
    const service = servicesById[serviceId]
    const {id} = stockCountIdToLocationProperties(doc._id)
    const location = Object.assign({}, locationEntitiesById[id], {id})
    return getLedgerBalanceAsReport(state, {service, location, date, asDocument: true})
  }))
}

exports.allBatchesAreZero = allBatchesAreZero
function allBatchesAreZero (reports) {
  let allZero = true
  reports.find(report => {
    Object.keys(report.stock).forEach(productId => {
      Object.keys(report.stock[productId].batches || {})
        .forEach(batchId => {
          const amount = _get(report, `stock.${productId}.batches.${batchId}.fields.field:standard-physical-count.amount`)
          if (amount) {
            allZero = false
          }
        })
    })
  })
  return allZero
}

exports.hasNegativeValues = hasNegativeValues
function hasNegativeValues (reports) {
  let hasNegative = false
  reports.find(report => {
    Object.keys(report.stock).forEach(productId => {
      Object.keys(report.stock[productId].batches || {})
        .forEach(batchId => {
          const amount = _get(report, `stock.${productId}.batches.${batchId}.fields.field:standard-physical-count.amount`)
          if (amount < 0) {
            hasNegative = true
          }
        })
    })
  })
  return hasNegative
}
