const docToReport = require('./doc-to-report')
const createReport = require('../tools/create')

module.exports = async (state, {
  locationId,
  service,
  period,
  entityOptions = null
}) => {
  const doc = createReport({
    locationId,
    service,
    period,
    stock: {},
    username: state.user.name,
    isDraft: true
  })
  return docToReport(state, {doc, service, period, entityOptions})
}
