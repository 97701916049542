import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import UppercaseLabel from '../UppercaseLabel'

const CardHeader = ({title, label, className, children}) => (
  <div className={classnames(
    'vs-card__header',
    className
  )}>
    {title && (
      <span className='vs-card__header-title'>{title}</span>
    )}
    {label && (
      <span className='vs-card__header-label'>
        <UppercaseLabel>
          {label}
        </UppercaseLabel>
      </span>
    )}
    {children && (
      <div className='vs-card__header-content'>
        {children}
      </div>
    )}
  </div>
)

CardHeader.propTypes = {
  /**
   * Main title of the card
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Subtitle, shown as an uppercase-label
   */
  label: PropTypes.string,
  /**
   * Additional custom styles
   */
  className: PropTypes.string,
  /**
   * Additional content, e.g. an intro to the card
   */
  children: PropTypes.node
}

CardHeader.displayName = 'Card.Header'

export default CardHeader
