import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'
import QuantityInput from '../QuantityInput'
import { MinusCircle, PlusCircle } from '../../icons'

class QuantityInputWithControls extends Component {
  constructor (props) {
    super(props)

    this.state = {
      quantity: props.initialQuantity
    }
  }

  changeQuantity = (newQuantity) => {
    const { onUpdateQuantity } = this.props

    // We don't want trouble calculating null values for total
    const updateValue = newQuantity || 0
    onUpdateQuantity(updateValue)
    this.setState({
      quantity: newQuantity
    })
  }

  increaseQty = () => {
    const { quantity } = this.state
    this.changeQuantity(quantity + 1)
  }

  decreaseQty = () => {
    const { quantity } = this.state
    this.changeQuantity(quantity - 1)
  }

  render () {
    const {
      minQty,
      maxQty,
      size,
      align,
      disabled
    } = this.props
    const {
      quantity
    } = this.state

    return (
      <div className={classnames(
        'vs-quantity-input-with-controls',
        {'vs-quantity-input-with-controls--left': align === 'left'},
        {'vs-quantity-input-with-controls--right': align === 'right'}
      )}>
        <Button
          className='vs-quantity-input-with-controls__action'
          icon={<MinusCircle />}
          onClick={this.decreaseQty}
          disabled={disabled || (quantity <= minQty)}
        />
        <QuantityInput
          className={classnames(
            'vs-quantity-input-with-controls__input',
            {'vs-quantity-input-with-controls__input--small': size === 'small'}
          )}
          onQuantityChange={this.changeQuantity}
          min={minQty}
          value={quantity}
          size={size}
          disabled={disabled}
          isAllowed={({ value }) => (value >= minQty) && (value <= maxQty)}
        />
        <Button
          className='vs-quantity-input-with-controls__action'
          icon={<PlusCircle />}
          onClick={this.increaseQty}
          disabled={disabled || (quantity >= maxQty)}
        />
      </div>
    )
  }
}

QuantityInputWithControls.propTypes = {
  /**
   * The initial quantity to be displayed
   */
  initialQuantity: PropTypes.number.isRequired,
  /**
   * The maximum quantity that can be set
   */
  maxQty: PropTypes.number,
  /**
   * The minimum quantity that can be set
   */
  minQty: PropTypes.number,
  /**
   * Callback function
   */
  onUpdateQuantity: PropTypes.func,
  /**
   * A size of an input field
   */
  size: PropTypes.oneOf(['small', 'regular']),
  /**
   * Alignment
   */
  align: PropTypes.oneOf([null, 'left', 'right'])
}

QuantityInputWithControls.defaultProps = {
  minQty: 0,
  maxQty: 100,
  initialQuantity: 0,
  onUpdateQuantity: () => {},
  size: 'regular',
  align: null,
  disabled: false
}

export default QuantityInputWithControls
