const { format } = require('date-fns')

module.exports = (periodType, startDate, endDate) => {
  const displayFunctions = {
    'week': displayWeekFunction,
    'month': displayMonthFunction
  }

  const displayFunction = displayFunctions[periodType.unit]

  if (!displayFunction) {
    throw new Error(`Display function not found for periodType: ${periodType.unit}`)
  }

  return displayFunction(periodType, startDate, endDate)
}

const displayWeekFunction = (periodType, startDate) => {
  return format(startDate, '[Week] GGGG [/] W')
}

const displayMonthFunction = (periodType, startDate, endDate) => {
  const firstMonth = format(startDate, 'MMM')
  const lastMonth = format(endDate, 'MMM YYYY')
  return `${firstMonth}-${lastMonth}`
}
