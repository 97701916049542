const get = require('lodash/get')
const tools = require('../../tools')
const { translateTerritoryAlias, productAliasesByMarket } = require('../../../product/tools')
const { getServiceForLocationId } = require('../../../service/tools')

exports.bulkUpdateSupplierSubOrder = async (state, mainApi, {groupId, destinationId, supplierId, updates, productsById}) => {
  const { service: supplierService } = getServiceForLocationId(supplierId)
  const { service: destinationService } = getServiceForLocationId(destinationId)
  const needsTranslation = supplierService !== destinationService
  let productsForTranslation
  if (needsTranslation) {
    productsForTranslation = productAliasesByMarket(productsById)
  }

  const productUpdates = Object.keys(updates).reduce((acc, product) => {
    const quantity = get(updates, `${product}.quantity`)
    if (needsTranslation) {
      try {
        product = translateTerritoryAlias(product, productsForTranslation, destinationService)
      } catch (error) {
        console.warn(`Unable to translate ${product} to ${destinationService} sku equivalent`)
      }
    }
    acc[product] = { adjusted: quantity }
    return acc
  }, {})

  const parentSnapshot = await state.dal.order.getForDestinationSupplier(state, { groupId, destinationId, supplierId })
  const snapshotProps = {
    parentSnapshot,
    products: productUpdates,
    user: state.user,
    timestamp: new Date().toJSON(),
    paymentStatus: parentSnapshot.paymentStatus, // Just preserve whatever it is there,
    paymentChoice: parentSnapshot.paymentChoice
  }
  const updatedSnapshot = tools.updateSnapshot(snapshotProps)
  await state.dal.order.update(state, updatedSnapshot)
}
