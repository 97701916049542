module.exports = locationIdToProperties

function locationIdToProperties (id) {
  if (id === 'national') {
    return {
      national: 'national',
      level: 'national',
      id
    }
  }

  if (id === 'country') {
    return {
      country: 'country',
      level: 'country',
      id
    }
  }

  const [, country] = id.match(/country:([^:]+)/) || []
  const [, zone] = id.match(/zone:([^:]+)/) || []
  const [, state] = id.match(/state:([^:]+)/) || []
  const [, lga] = id.match(/lga:([^:]+)/) || []
  const [, sdp] = id.match(/sdp:([^:]+)/) || []
  const [, name] = id.match(/name:([^:]+)/) || []

  const locationId = []
  const properties = {
    level: 'country'
  }
  if (country) {
    properties.level = 'country'
    properties.country = country
    locationId.push('country', country)
  }
  if (zone) {
    properties.level = 'zone'
    properties.zone = zone
    locationId.push('zone', zone)
  }
  if (state) {
    properties.state = state
    properties.level = 'state'
    locationId.push('state', state)
  }
  if (lga) {
    properties.lga = lga
    properties.level = 'lga'
    locationId.push('lga', lga)
  }
  if (sdp) {
    properties.sdp = sdp
    properties.level = 'sdp'
    locationId.push('sdp', sdp)
  }
  if (name) {
    properties.name = name
    locationId.push('name', name)
  }
  properties.id = locationId.join(':')
  return properties
}
