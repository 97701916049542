import React, { Component } from 'react'

import { BackButton } from '@fielded/shared-ui'

import StatementsContainer from '../../../statements/StatementsContainer'

class SalesStatementContainer extends Component {
  render () {
    const {
      history,
      match: {
        params: {
          reportId,
          serviceId,
          locationId,
          date
        }
      }
    } = this.props

    return (
      <div className='print-page'>
        <header className='header--back'>
          <BackButton history={history}>go back</BackButton>
        </header>
        <StatementsContainer
          reportId={reportId}
          locationId={locationId}
          serviceId={serviceId}
          date={date}
          showSales
        />
      </div>
    )
  }
}

export default SalesStatementContainer
