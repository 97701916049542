// This function is used to bulk zero out a subOrder when switching suppliers

exports.zeroOutSubOrders = function (snapshots) {
  return snapshots.map(snapshot => {
    const { products } = snapshot
    const zeroedOutProducts = Object.keys(products).reduce((acc, product) => {
      acc[product] = { ...products[product], adjusted: 0 }
      return acc
    }, {})
    return { ...snapshot, products: zeroedOutProducts }
  })
}
