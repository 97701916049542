const vectorClock = require('vectorclock')

// Aggregate documents for an alert into a single alert record.
// There may be multiple documents for the same alert and a list
// of events that effect the alert, e.g. comment, close.
// All these documents will be reduced into a single record.
module.exports = (docs) => {
  // group docs into alert docs and event docs
  // all alert docs must be for the same alert
  const alertDocs = []
  const eventDocs = []
  // merge all clocks to get the latest clock
  let clock = {}
  for (let d of docs) {
    clock = vectorClock.merge(clock, JSON.parse(JSON.stringify(d.clock)))
    if (d.type === 'alert') {
      alertDocs.push(d)
    } else {
      eventDocs.push(d)
    }
  }

  if (!alertDocs.length) {
    const alertIds = eventDocs
      .map((event) => event.alertId)
      .filter((id, index, collection) => {
        return collection.indexOf(id) === index
      })
      .join(', ')

    if (!alertIds.length) {
      // this means that there have been no event docs at all or none of
      // them had an alert id
      throw new Error(
        'Could not find any valid alert or event documents in the given set.'
      )
    }

    throw new Error(
      `Could not find a parent document of type "alert" for given alertIds: "${alertIds}"`
    )
  }
  // We randomly pick the first alert, because all should have the
  // same values, except for creation time and creating user.
  const alertDoc = alertDocs[0]
  const orderedEvents = eventDocs.sort(vectorClock.compare)
  const alert = {
    id: alertDoc.alertId,
    createdAt: alertDoc.createdAt,
    createdBy: alertDoc.createdBy,
    serviceId: alertDoc.serviceId,
    target: alertDoc.target,
    status: 'open',
    comments: [],
    clock
  }
  for (let event of orderedEvents) {
    switch (event.type) {
      case 'alert-comment':
        alert.comments.push({
          createdAt: event.createdAt,
          createdBy: event.createdBy,
          message: event.message
        })
        break
      case 'alert-status':
        alert.status = event.status
        break
      default:
        console.error(new Error('Unknown event: ' + JSON.stringify(event)))
    }
  }
  return alert
}
