module.exports = isShipmentRelevant

module.exports.getShipmentDate = getShipmentDate

function getShipmentDate (location, includeScheduledOutbound, shipment) {
  const { snapshotDates, origin } = shipment
  if (includeScheduledOutbound) {
    // If including scheduled distributions, a shipment is relevant from its earlist date
    return snapshotDates.new || snapshotDates.packed || snapshotDates.sent || snapshotDates.arrived || snapshotDates.received
  } else if (origin.id === location.id) {
    // If at origin, it has to at least be packed or sent.
    return snapshotDates.packed || snapshotDates.sent || snapshotDates.arrived || snapshotDates.received
  }

  // If at destination, it has to be fully received.
  return snapshotDates.received
}

// See spec doc: https://github.com/fielded/van-orga/issues/1482
function isShipmentRelevant (params) {
  const { shipment, location, startDate, endDate, includeScheduledOutbound } = params
  const { status, origin, destination, isAutomaticReturnShipment } = shipment

  // If it's an automatically created return shipment and it's the origin of the  shipment we should ignore
  if (isAutomaticReturnShipment && origin.id === location.id) return false

  // If it's not to me or from me forget it.
  const isRelevantLocation = (origin.id === location.id || destination.id === location.id)
  if (!isRelevantLocation) return false

  // Never include scheduled external arrivals in ledger calculations.
  if (status === 'pre-advice') return false

  // For shipments where the location is the origin, only use sent, packed and new shipments (if scheduled outbound)
  if (origin.id === location.id && (status === 'received' || status === 'arrived')) return false

  // For shipments where the location is the destination, only use received shipments and new shipments (if scheduled outbound)
  if (destination.id === location.id && (status === 'sent' || status === 'packed')) return false

  // Don't include scheduled distributions (unless this 'include' flag is set)
  if (!includeScheduledOutbound && status === 'new') return false

  // If we are to include scheduled distributions, don't include inbound ones
  if (includeScheduledOutbound && status === 'new' && destination.id === location.id) {
    return false
  }

  // Reminder: new < sent < arrived < received
  const shipmentDate = getShipmentDate(location, includeScheduledOutbound, shipment)

  if (!shipmentDate) return false

  // If there never has been a stock count at this location:
  if (!startDate) return (shipmentDate <= endDate)

  // Do '<' comparison for start date, since we're comparing against
  // server generated ledger balances. A server generated ledger balance
  // that is triggered by a shipment will have the shipments event time as 'submittedAt'
  // and the shipment should not be included in there
  return startDate < shipmentDate && shipmentDate <= endDate
}
