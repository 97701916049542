const keyBy = require('lodash/keyBy')
const { updateSnapshot } = require('./snapshot-update')

// for the REST API
exports.updateOrders = updateOrders
function updateOrders ({
  currentDocs = [],
  updatedOrders = [],
  user,
  date = new Date().toJSON()
}) {
  const currentDocsById = keyBy(currentDocs, '_id')
  throwIfErrors({currentDocsById, updatedOrders})

  return updatedOrders
    .map(updatedOrder => {
      return updateSnapshot({
        parentSnapshot: currentDocsById[updatedOrder._id],
        products: getUpdateProductsShape(updatedOrder.productQuantities),
        user,
        timestamp: date,
        paymentStatus: updatedOrder.paymentStatus,
        paymentTimestamp: updatedOrder.paymentTimestamp,
        totalAmount: updatedOrder.totalAmount,
        paymentChoice: updatedOrder.paymentChoice
      })
    })
}

exports.throwIfErrors = throwIfErrors
function throwIfErrors ({currentDocsById = {}, updatedOrders = []}) {
  const missingOrderIDs = updatedOrders
    .filter(order => !currentDocsById[order._id])
    .map(order => order._id)

  if (missingOrderIDs.length) {
    const error = new Error()
    error.data = missingOrderIDs
    error.message = `Error: could not find existing order for order ${missingOrderIDs[0]}`
    throw error
  }
}

function getUpdateProductsShape (products) {
  return Object.keys(products)
    .reduce((acc, productId) => {
      acc[productId] = {adjusted: products[productId]}
      return acc
    }, {})
}
