import React, { Fragment, useState } from 'react'
import classnames from 'classnames'
import get from 'lodash/get'

import { DeemphasizedLink, Modal, Text } from '@fielded/shared-ui'
import { CheckmarkCircle, CloseCircle } from '@fielded/shared-ui/src/icons'
import { SHIPMENT_OTP_FAIL_REASONS } from '@fielded/fs-api/lib/shipment/constants'

const LMDDeliveryDetailVerificationInfo = ({
  delivery
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const otpFailedReason = get(delivery, 'otp.userFailReason', '')
  const otpFailedReadableReason = SHIPMENT_OTP_FAIL_REASONS[otpFailedReason]

  const verificationTags = [
    {
      searchName: 'gps-validation',
      displayText: 'Location',
      isVerified: get(delivery, 'isGpsAuthenticated', false),
      failedReason: get(delivery, 'gpsValidationFailComment.reason', ''),
      position: get(delivery, 'gpsValidationFailComment.position', null)
    },
    {
      searchName: 'otp',
      displayText: 'OTP',
      isVerified: get(delivery, 'otp.hasKeyAndPassword', false),
      failedReason: otpFailedReadableReason
    }
  ]

  const onOpenModal = () => {
    setIsModalOpen(true)
  }

  const onCloseModal = () => {
    setIsModalOpen(false)
  }

  const unverifiedMethods = verificationTags.filter((tag) => !tag.isVerified)

  return (
    <aside className='lmd-detail__verification-wrapper'>
      <div className='lmd-detail__verification-tags'>
        {verificationTags.map((tag) => {
          const isVerified = tag.isVerified
          return (
            <div
              className={classnames(
                'lmd-detail__verification-tag',
                {'lmd-detail__verification-tag--success': isVerified}
              )}
              key={tag.searchName}
            >
              <Text>
                {tag.displayText} verification:
              </Text>
              {isVerified ? (
                <Fragment>
                  <Text bold>
                    Successful
                  </Text>
                  <span className='lmd-detail__verification-tag-icon'><CheckmarkCircle /></span>
                </Fragment>
              ) : (
                <Fragment>
                  <Text bold>
                    Flagged
                  </Text>
                  <span className='lmd-detail__verification-tag-icon'><CloseCircle /></span>
                </Fragment>
              )}
            </div>
          )
        })}
      </div>
      {unverifiedMethods.length > 0 && (
        <DeemphasizedLink
          onClick={() => onOpenModal()}
          colorVariant='brand'
        >
          More details
        </DeemphasizedLink>
      )}
      <Modal
        centered={false}
        isOpen={isModalOpen}
        title='Verification details'
        onClose={onCloseModal}
      >
        <section>
          {unverifiedMethods.map((tag) => {
            return (
              <div className='vs-u-margin-bottom-double' key={tag.displayText}>
                <dt className='vs-u-margin-bottom'>
                  <Text bold>
                    {tag.displayText} verification flagged!
                  </Text>
                </dt>
                <dd>
                  <Text size='xsmall'>Reason (given by Driver):</Text>
                  <Text>{tag.failedReason}</Text>
                </dd>
                {tag.position && (
                  <dd className='vs-u-margin-top-double'>
                    <Text size='xsmall'>Most recent delivery location coordinates:</Text>
                    <Text>Latitude: {tag.position.lat}</Text>
                    <Text>Longitude: {tag.position.lng}</Text>
                  </dd>
                )}
              </div>
            )
          })}
        </section>
      </Modal>
    </aside>
  )
}

export default LMDDeliveryDetailVerificationInfo
