const get = require('lodash/get')
const sortBy = require('lodash/sortBy')
const keyBy = require('lodash/keyBy')
const XLSX = require('@sheet/core')
const { parse } = require('../tools/smart-id')

const exportLMDShipmentsData = async function (state, {api, shipments}) {
  const routes = await api.routes.list()
  const routesById = keyBy(routes, '_id')

  const lmds = shipments.map(shipment => {
    const destinationId = get(shipment, 'destination.id', '')
    const { state } = parse(destinationId)
    const routeId = get(shipment, 'route.id', '')
    const route = get(routesById[routeId], 'name', '')

    return {
      originId: get(shipment, 'origin.id', ''),
      orderId: get(shipment, 'orderId', ''),
      destinationId,
      funderId: get(shipment, 'funder.id', ''),
      route,
      programId: get(shipment, 'programId', ''),
      vendorId: get(shipment, 'vendorId'),
      status: get(shipment, 'status', ''),
      createdAt: get(shipment, 'createdAt'),
      state
    }
  })

  const sortedLMDs = sortBy(lmds, ['destinationId', 'createdAt'])

  const columns = [
    {
      name: 'Order ID',
      key: 'orderId'
    }, {
      name: 'Source',
      key: 'originId'
    }, {
      name: 'Destination',
      key: 'destinationId'
    }, {
      name: 'Funder',
      key: 'funderId'
    }, {
      name: 'Program',
      key: 'programId'
    }, {
      name: 'State',
      key: 'state'
    }, {
      name: 'Route',
      key: 'route'
    }, {
      name: '3PL',
      key: 'vendorId'
    }, {
      name: 'Status',
      key: 'status'
    }, {
      name: 'Creation Date',
      key: 'createdAt'
    }
  ]

  const headers = columns.map(column => column.name)
  const data = sortedLMDs.map(event => {
    return columns.map(column => {
      return get(event, column.key, '')
    })
  })
  return [headers].concat(data)
}

const generateExcelSheet = (aoa) => {
  return new Promise((resolve) => {
    const ws = XLSX.utils.aoa_to_sheet(aoa)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'last-mile-deliveries-export')
    const wbout = XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array'
    })
    resolve(new global.Blob([wbout], {type: 'application/octet-stream'}))
  })
}

async function exportLMDShipments (state, params) {
  const data = await exportLMDShipmentsData(state, params)

  if (params.dryRun) return data

  return generateExcelSheet(data)
}
module.exports = { exportLMDShipments }
