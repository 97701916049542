import React from 'react'

import { Button, Page, Form } from '@fielded/shared-ui'

import { Arrow } from '@fielded/shared-ui/src/icons'

class NewPSMShipmentProductsQuantity extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      selectedBatches: props.shipment.batches || []
    }
  }

  submit = (fields) => {
    const { handleSubmit } = this.props
    handleSubmit(fields)
  }

  render () {
    const { cancel, goBack, addBatches, shipment } = this.props
    const selectedProducts = shipment.products.map(product => product)
    const { error, selectedBatches } = this.state
    return (
      <Form onSubmit={this.submit}>
        <Page.Intro title={'Enter quantities'} />
        {error && <div className='new-psm-shipment__error-wrapper'>
          {<span className='new-psm-shipment__errors'>{error}</span>}
        </div>
        }
        <Page.Panel className='product-bulk-selection'>
          {selectedProducts.map(product => {
            const quantity = shipment.quantities && shipment.quantities[product._id]
            const productBatches = selectedBatches.filter(batch => batch.productId === product._id)
            const total = productBatches.length > 0 && productBatches.map(b => b.quantity).reduce((total, next) => total + next)
            return (
              <div key={product._id} className='new-psm-shipment__quantity-container'>
                <Form.Section>
                  {!productBatches.length && (
                    <Form.Row intro={product.fullName}>
                      <Form.Field fieldName={product._id} labelText='Qty'>
                        <Form.Field.Quantity
                          value={quantity}
                          required='Add quantity for product'
                        />
                      </Form.Field>
                    </Form.Row>
                  )}
                  {productBatches.length > 0 && (
                    <Form.Section intro={product.fullName}>
                      <Form.Row intro='Total Batch Qty'>
                        <Form.DisplayValue asNumber>{total}</Form.DisplayValue>
                      </Form.Row>
                      {productBatches.map(batch => {
                        return (
                          <Form.Row>
                            <Form.Field fieldName={batch.quantity} labelText={`Batch #${batch.batchNumber}`}>
                              <Form.Field.Quantity
                                value={batch.quantity}
                                required='Add quantity for product'
                                disabled
                              />
                            </Form.Field>
                          </Form.Row>
                        )
                      })}
                    </Form.Section>
                  )}
                  <div className='shipment__batches-button-wrapper'>
                    <Button
                      onClick={() => addBatches(product)}
                      colorVariant='brand'
                      fill='full'
                    >
                      {productBatches.length > 0 ? `Edit Batches` : `Enter Batches`}
                    </Button>
                  </div>
                </Form.Section>
              </div>
            )
          })}
        </Page.Panel>
        <Page.Footer fixed>
          <Form.Actions className='new-psm-shipment__button-wrapper'>
            <Form.Button
              icon={<Arrow direction='right' />}
              iconSide='right'
              fill='full'
              colorVariant='brand'
              type='submit'
            >
              Next
            </Form.Button>
            <Form.Button
              onClick={goBack}
              icon={<Arrow direction='left' />}
              iconSide='left'
              fill='outline'
              colorVariant='brand'
            >
              Prev
            </Form.Button>
            <Button
              onClick={cancel}
              colorVariant='brand'
              alignOpposite
            >
              Cancel
            </Button>
          </Form.Actions>
        </Page.Footer>
      </Form>
    )
  }
}

export default NewPSMShipmentProductsQuantity
