import React from 'react'
import { useSelector } from 'react-redux'

import { Ban } from '@fielded/shared-ui/src/icons'

import { selectIsPaused } from '../../subapps/retailer/finances/reducers/financesSlice'
import AccountBlockedModal from '../AccountBlockedModal/AccountBlockedModal'
import WarningNotice from '../WarningNotice/WarningNotice'

const AccountBlockedBanner = ({ showModal, onOpenModal, onCloseModal }) => {
  const isPaused = useSelector(selectIsPaused)

  if (!isPaused) {
    return null
  }

  const warningModal = (
    <AccountBlockedModal
      showAccountBlockedModal={showModal}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
    />
  )

  return (
    <WarningNotice
      type='warning'
      onOpenModal={onOpenModal}
      modal={warningModal}
      icon={<Ban />}
    />
  )
}

export default AccountBlockedBanner
