import React from 'react'

import Header from './Header'
import ProgramsList from './ProgramsList'

const Programs = ({
  programs,
  locationId,
  date,
  user,
  config,
  showCountSyncBanner,
  showShipmentSyncBanner
}) => {
  const hasPrograms = programs && programs.length

  if (!hasPrograms) {
    return <span>No programs found</span>
  }
  return (
    <div className='program-selection'>
      <Header
        config={config}
        title='Stock reporting for all programs'
        user={user}
      />
      <main className='program-selection__programs'>
        <ProgramsList
          programs={programs}
          locationId={locationId}
          date={date}
          showCountSyncBanner={showCountSyncBanner}
          showShipmentSyncBanner={showShipmentSyncBanner}
        />
      </main>
    </div>
  )
}

export default Programs
