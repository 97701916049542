const get = require('lodash/get')
const { REPORT_BALANCE_FIELD } = require('../../tools/utils/constants')
const { batchIdToProductId } = require('./product-batch-util')
/*
 * This function is used for https://github.com/fielded/van-orga/issues/3457
 * returns a 'counts' object that can be used for saveChanges
 * to remove invalid batches
 */

module.exports = (shipment, stockCount) => {
  const batches = Object.keys(shipment.counts)
  const results = batches.map(batchId => {
    // If its not checked, we're not sending it anyway
    if (!shipment.counts[batchId].checked) {
      return
    }

    const productId = batchIdToProductId(batchId)
    const productStock = stockCount.stock[productId] || {}
    let stockValue
    if (productStock.batches) {
      stockValue = get(productStock.batches, `${batchId}.${REPORT_BALANCE_FIELD}`)
    } else {
      stockValue = get(productStock, `${REPORT_BALANCE_FIELD}`)
    }

    return (stockValue || 0) - shipment.counts[batchId].quantity
  })

  const changeSet = results.reduce((changeSet, stockAfterShipment, index) => {
    const batchId = batches[index]

    // https://github.com/fielded/fs-api/blob/master/lib/shipment/README.md#apishipmentsavechanges
    if (stockAfterShipment && stockAfterShipment < 0) {
      changeSet[batchId] = {
        quantity: stockAfterShipment,
        checked: true // keep checked batches checked, not 100% sure about this
      }

      if (shipment.counts[batchId].quantity + stockAfterShipment <= 0) {
        changeSet[batchId] = { removed: true }
      }
    }

    return changeSet
  }, {})

  if (Object.keys(changeSet).length === 0) {
    return null
  }

  return changeSet
}
