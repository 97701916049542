module.exports = {
  validateSuggestBatchesParams
}

const validator = require('./validate-suggest-batches')

function validateSuggestBatchesParams (params) {
  const valid = validator(params)

  if (valid) {
    return null
  }

  return validator.errors
}
