const tools = require('../../tools')

// This is a SL update
exports.updateOrdersOnGroup = async function (
  state,
  {locationQuantities, groupId, productId}
) {
  const snapshots = await state.dal.order.getGroup(state, {groupId})
  const locationId = state.user.location.id
  const status = tools.getUserStatus(state.user)
  const orders = tools.docsToOrderEntities({
    docs: snapshots.filter(order => order.products[productId]),
    status,
    locationId,
    withSnapshots: true
  })

  const changedSnapshots = tools.updateOrdersOnProduct({
    locationQuantities,
    orders,
    status,
    productId,
    user: state.user
  })
  // REFACTOR: this could be cleaned up a bit to save snapshots that have changed as we do here,
  // but then smart merge them with the ones we've already fetched above. (If performance
  // gets bad on adjusting orders.)
  await state.dal.order.bulkDocs(state, changedSnapshots)
  const updatedGroupSnapshots = await state.dal.order.getGroup(state, {groupId})

  return {
    orders: tools.docsToOrderEntities({
      docs: updatedGroupSnapshots, status, locationId
    })
  }
}
