import React from 'react'

export const CardGift = () => {
  return (
    <svg viewBox='0 0 33 26' fill='none' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#a)'>
        <path fillRule='evenodd' clipRule='evenodd' d='M30.403 25.437H4.431c-1.433 0-2.597-1.14-2.597-2.544V7.631c0-1.404 1.164-2.544 2.597-2.544h2.112l.101.36a5.997 5.997 0 0 0 1.234 2.184H5.08a.643.643 0 0 0-.65.636v6.995h25.972V8.267a.643.643 0 0 0-.649-.636h-2.797a5.97 5.97 0 0 0 1.336-2.544h2.11c1.434 0 2.598 1.14 2.598 2.544v15.262c0 1.404-1.164 2.544-2.598 2.544Zm-25.972-6.36v3.18c0 .351.291.636.65.636h24.673c.358 0 .65-.285.65-.636v-3.18H4.43Zm11.572-6.359h-3.78c.758-1.726 1.682-3.601 2.597-5.087-.84-.001-2.041.009-2.597 0-2.729-.044-3.88-2.297-3.896-3.815C8.307 1.865 9.892-.01 12.223 0c1.946.008 3.654 1.11 5.194 2.942C18.957 1.109 20.665.008 22.612 0c2.332-.009 3.916 1.865 3.896 3.816-.017 1.518-1.168 3.77-3.896 3.815-.556.009-1.757-.001-2.597 0 .96 1.524 1.936 3.525 2.597 5.087h-3.78c-.314-1.574-.91-3.373-1.415-4.304-.504.931-1.171 3.044-1.414 4.304ZM12.688 2.546c-1.469 0-1.957 2.44.156 2.541.307.016 2.902 0 2.902 0-1.347-1.831-1.974-2.54-3.058-2.54Zm9.442 0c1.469 0 1.957 2.44-.156 2.541-.308.016-2.885 0-2.885 0 1.346-1.831 1.957-2.54 3.041-2.54Z' fill='#27303D' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h33v26H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
