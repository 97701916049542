const { PouchAdapter } = require('../../../common')

// TODO: create real schema
const schema = {
  type: 'object',
  name: 'order',
  properties: {},
  additionalProperties: true,
  required: []
}

// TODO: refactor existing order pouch dal methods to live here
class OrderPouchAdapter extends PouchAdapter {
  constructor (user, orderDB) {
    super(user, schema, orderDB)
  }
}

module.exports = OrderPouchAdapter
