import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ActivateApi from './activate-api'
import { login } from '../login/login-api'
import Activate from './Activate'
import withConfig from '../van-shared/hoc/withConfig'
import ResendEmail from './ResendEmail'

class ActivateContainer extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    config: PropTypes.object.isRequired
  }

  static defaultProps = {
    error: null
  }

  state = {
    inProcess: false,
    onSuccess: false,
    loading: true,
    tokenIsValid: false,
    signupToken: null
  }

  async componentDidMount () {
    const { location, config } = this.props
    this.api = new ActivateApi({agaveApiUrl: config.agaveApiUrl})
    const query = new URLSearchParams(location.search)
    const signupToken = query.get('signupToken')
    this.setState({ signupToken })
    try {
      await this.api.checkToken(signupToken)
      this.setState({tokenIsValid: true, loading: false})
    } catch (e) {
      console.warn(e)
      this.setState({loading: false})
    }
  }

  handleError (error) {
    console.error(error)
    this.setState({ inProcess: false, error: error.message })
  }

  handleResendEmail = async fields => {
    try {
      this.setState({inProcess: true})
      await this.api.resendWelcomeEmail(fields.email)
      this.setState({ onSuccess: true, inProcess: true })
    } catch (error) {
      if (error.status === 404) {
        error.message = 'Email address does not exist.'
      }
      this.handleError(error)
    }
  }

  handleSubmit = async fields => {
    const { history, config } = this.props
    const {signupToken} = this.state
    try {
      this.setState({ inProcess: true })
      const response = await this.api.submit(signupToken, fields)
      await login(response.name, fields.password, {
        config: {
          remoteDbUrl: config.remoteDbUrl
        }
      })
      history.push('/')
    } catch (error) {
      this.handleError(error)
    }
  }

  render () {
    const { inProcess, onSuccess, error, loading, tokenIsValid } = this.state
    const { config } = this.props

    if (loading) return null

    const commonProps = {
      error,
      inProcess,
      onSuccess,
      config
    }

    return (
      tokenIsValid
        ? <Activate {...commonProps} onSubmit={this.handleSubmit} />
        : <ResendEmail {...commonProps} onSubmit={this.handleResendEmail} />
    )
  }
}

export default withConfig(ActivateContainer)
