module.exports = fetchErrorInterceptor

function fetchErrorInterceptor (response) {
  if (!response.ok) {
    return response.json()
      .then(error => {
        const err = new Error(error.message)
        err.status = response.status
        return Promise.reject(err)
      })
  }
  return Promise.resolve(response)
}
