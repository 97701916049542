const {rawMethods, StockPouchDBAdapter} = require('./stock-pouchdb-adapter')
const StockRestAdapter = require('./stock-rest-adapter')
const StockPGAdapter = require('./stock-pg-adapter')

module.exports = {
  rawMethods,
  StockPouchDBAdapter,
  StockRestAdapter,
  StockPGAdapter
}
