import React, { createElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import UppercaseLabel from '../UppercaseLabel'
import SortIndicator from '../SortIndicator'

class SortHeader extends Component {
  onSortClick = () => {
    const {
      dataKey,
      sortBy,
      sortDirection
    } = this.props

    let newSortDirection = 'asc'

    if (dataKey === sortBy) {
      newSortDirection = sortDirection.toLowerCase() === 'asc' ? 'desc' : 'asc'
    }

    this.props.onSortClick(dataKey, newSortDirection)
  }

  render () {
    const {
      component,
      children,
      dataKey,
      sortBy,
      sortDirection,
      className,
      onSortClick,
      ...otherProps
    } = this.props

    return createElement(
      component,
      {
        className: classnames(
          'sort-header',
          className
        ),
        onClick: this.onSortClick,
        ...otherProps
      },
      (
        <div className='sort-header__content' tabIndex='-1'>
          {typeof children === 'string'
            ? <UppercaseLabel>{children}</UppercaseLabel>
            : children
          }
          {(sortBy && sortDirection) && (
            <span className='sort-header__indicator'>
              <SortIndicator sortDirection={sortBy === dataKey ? sortDirection.toUpperCase() : undefined} />
            </span>
          )}
        </div>
      )
    )
  }
}

SortHeader.propTypes = {
  /**
   * Content of the SortHeader. Can be text or any tag valid by HTML standards. The SortIndicator will be appended
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Pass an optional alternative top level element to use as the sort "button", e.g. Link.
   */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  /**
   * The data key of the column
   */
  dataKey: PropTypes.string,
  /**
   * The current sort value of the container
   */
  sortBy: PropTypes.string,
  /**
   * The sort direction (SortDirection.ASC|SortDirection.DESC)
   *
   * A react-virtualised SortDirection constant or matching strings
   */
  sortDirection: PropTypes.oneOf([
    'ASC',
    'asc',
    'DESC',
    'desc'
  ]),
  /**
   * Returns the new sortBy and sortDirection
   */
  onSortClick: PropTypes.func,
  /**
   * Any additional class names you want to add to the button
   * */
  className: PropTypes.string
}

SortHeader.defaultProps = {
  children: null,
  component: 'button',
  onSortClick: () => null,
  className: ''
}

export default SortHeader
