import get from 'lodash/get'
// Returns array of productLines and products
// [productLineName, productId, productId, productLineName, productId, ...]
import { sortProductByIndices } from './../common/utils'
import { formatBatchesByProduct } from '../common/batch-utils'
import fsApiTools from '@fielded/fs-api/lib/tools'
import { formatDate } from '../../../van-shared/utils'

const sortByString = (a, b) => {
  if (a > b) {
    return -1
  } else if (a < b) {
    return 1
  }

  return 0
}

const sortBySoldOrCredited = (a, b) => {
  // We arrange the rows in order by sales/credited,
  // as requested in https://github.com/fielded/van-orga/issues/3198
  // Sold products (positive consumed amount) come first,
  // then credited products (negative consumed amount),
  // then those with unchanged count (consumed amount 0).
  const consumedA = get(a, 'fields.field:standard-consumed.amount', 0)
  const consumedB = get(b, 'fields.field:standard-consumed.amount', 0)
  let sortGroupA = 3
  let sortGroupB = 3
  if (consumedA !== 0) sortGroupA = consumedA > 0 ? 1 : 2
  if (consumedB !== 0) sortGroupB = consumedB > 0 ? 1 : 2

  return sortGroupA - sortGroupB
}

export const getProductSales = (report, product, field = 'field:standard-consumed') => {
  // Sales are based on the time they are reported (when the stock count is submitted)
  // This implementation is supposed to get the most recent date as of the submittedAt time
  // TODO: this could be bogus for partial (adjustment) counts. Need to ensure partial counts use their
  // parent counts' submittedAt somehow
  let price = fsApiTools.product.getPrice(product.prices, report.submittedAt || report.updatedAt)
  if (price && price.discountPrice !== undefined) {
    price = price.discountPrice
  }

  const vatPercentage = getVatPercentage(product, report.submittedAt)
  const unitVat = !vatPercentage ? undefined : price / 100 * vatPercentage

  const mergedReport = get(report, `mergedReport`, false)

  const sas = get(report, `stock.${product._id}.fields.field:sales-adjustments.amount`, 0)

  // Special property only added when reading aggregated reports
  // via api.report.findWithAdjustments
  const adjusted = get(report, `stock.${product._id}.adjusted`, false)

  // If it's a merged report (ooc) and the sku has been adjusted look out
  // sales adjustment instead.
  const fieldToUse = (mergedReport && adjusted && sas > 0) ? 'field:sales-adjustments' : field

  const consumed = Math.max(get(report, `stock.${product._id}.fields.${fieldToUse}.amount`, 0), 0)
  const priceWithVat = unitVat ? price + unitVat : price
  const sold = (priceWithVat && consumed)
    ? Math.round((priceWithVat * consumed) * 100) / 100 : undefined
  return {sold, price, consumed, unitVat}
}

export const getExpiryRows = ({report, productsById, batchesById}) => {
  const hasExpired = batch => {
    const now = formatDate(new Date(), 'expiry')
    const [currentMonth, currentYear] = now.split('/')
    const [expiryMonth, expiryYear] = batch.expiry.split('/')
    if (expiryYear < currentYear) {
      return true
    }
    if (expiryYear === currentYear && expiryMonth < currentMonth) {
      return true
    }
    return false
  }

  const expiryBatches = report.expiry
    ? formatBatchesByProduct(report.expiry, productsById, batchesById)
    : undefined
  const rows = expiryBatches
    ? Array.prototype.concat.apply([], Object.values(expiryBatches))
    : []
  rows.forEach(row => {
    row.expired = hasExpired(row)
  })
  return rows
}

// For a PSM confirmation:
// gives a flat array with Product Line headers in the right place
export const getConfirmationRows = ({report, productsById, serviceId, serviceName}) => {
  const sortedProductIds = sortProductByIndices(serviceId, report, productsById)
  // productLinesMap = { productLineAbc: [ unordered list of its products ] }
  const productLinesMap = sortedProductIds.reduce((memo, productId) => {
    const product = productsById[productId]
    const services = get(product, 'services', [])
    const currentService = services.find(service => service.id === serviceId)
    if (currentService) {
      const productLine = currentService.productLine || serviceName
      const products = memo[productLine] ? memo[productLine].concat([product]) : [product]
      memo[productLine] = products
    }

    return memo
  }, {})

  // productLinesMapSortedProducts = [{ name: productLineAbc, products: [ordered list of its products] }]
  const productLinesMapSortedProducts = Object.keys(productLinesMap).map(productLineName => {
    const sortedProducts = productLinesMap[productLineName].sort((a, b) => {
      try {
        const i1 = a.services.find(s => s.id === serviceId).index
        const i2 = b.services.find(s => s.id === serviceId).index
        return i1 < i2 ? -1 : 1
      } catch (e) {
        return -1
      }
    })
    const productRows = sortedProducts.map(row => {
      const rowData = {}
      rowData.id = row.id || row._id
      rowData.name = row.name
      rowData.basicUnit = row.basicUnit
      rowData.fields = get(report, `stock[${row._id}].fields`)
      rowData.expirySum = get(report, `expiry[${row._id}].amount`)
      rowData.disabled = get(report, `stock[${row._id}].disabled`)

      return rowData
    })
    return {
      name: productLineName,
      products: productRows
    }
  })

  // Returns mixed list: [{ name: productLineAbc, isProductLine: true }, { product }, { product }, ]
  return productLinesMapSortedProducts.reduce((memo, productLine) => {
    memo.push({ name: productLine.name, isProductLine: true }, ...productLine.products)
    return memo
  }, [])
}

// For a shelflife confirmation, returns products as flat array
// Products with sales are listed first, to ease the confirmation process
export const getInvoiceConfirmationRows = ({report, productsById, serviceId, skipAutoFilled, soldFieldName = 'field:shelflife-sold'}) => {
  const sortedProductIds = sortProductByIndices(serviceId, report, productsById)
  let productRows = sortedProductIds.map(id => {
    const product = productsById[id]
    if (!product) {
      return
    }
    const rowData = {}
    rowData.fields = get(report, `stock[${product._id}].fields`)

    // This a property added to reports
    // generated by combining multiple reports in the same week
    const mergedReport = get(report, `mergedReport`, false)
    rowData.mergedReport = mergedReport

    // Special property only added when reading aggregated reports
    // via api.report.findWithAdjustments
    const adjusted = get(report, `stock.${product._id}.adjusted`, false)
    rowData.adjusted = adjusted

    if (adjusted) {
      rowData.updatedAt = get(report, `stock.${product._id}.updatedAt`, '')
    }

    const { sold, price, unitVat } = getProductSales(report, product, soldFieldName)

    rowData.id = product.id || product._id
    rowData.name = product.name
    rowData.basicUnit = product.basicUnit
    rowData.fields = get(report, `stock[${product._id}].fields`)

    if (get(report, `stock[${product._id}].skipCount`)) {
      return
    }

    const autoFilled = get(report, `stock.${product._id}.autoFilled`)
    if (autoFilled && skipAutoFilled) {
      return
    }

    rowData.fields['field:price'] = {
      price
    }
    rowData.fields['field:unit-vat'] = {
      vat: unitVat
    }

    if (sold) {
      rowData.fields['field:sold'] = {
        price: sold
      }
    } else {
      // if no sales remove the field from the report
      // incase it was modified from sold to no sales
      // see: https://github.com/fielded/van-orga/issues/3606
      delete rowData.fields['field:sold']
    }

    return rowData
  })
    .filter(x => x)

  const total = productRows.reduce((sum, product) => {
    const soldValue = get(product, `fields['field:sold'].price`)
    const sob = get(product, 'fields.field:standard-opening-balance.amount', 0)
    const spc = get(product, 'fields.field:standard-physical-count.amount', 0)
    const price = get(product, 'fields.field:price.price', 0)

    // This means this report was gotten with adjustments
    const mergedReport = get(product, 'mergedReport', false)

    const hasIssue = spc > sob
    const osb = get(product, 'fields.field:opening-shelflife-balance.amount', 0)

    let salesValue = soldValue

    if (hasIssue && !mergedReport && osb > 0) {
      salesValue = osb * price
    }

    if (hasIssue && report.partialCount) {
      salesValue = 0
    }

    return salesValue
      ? sum + Math.round(salesValue * 100) / 100
      : sum
  }, 0)

  const sortedProductRows = productRows.sort((a, b) => {
    return sortBySoldOrCredited(a, b) || sortByString(b.name, a.name)
  })

  sortedProductRows.push({
    isTotal: true,
    name: 'Total',
    total
  })

  return sortedProductRows
}

function getVatPercentage (product, date) {
  if (!product.vats || !product.vats.length) return

  return fsApiTools.product.getPrice(product.vats, date, 'vat')
}

export const getConfirmationRowsMissingCount = (confirmationRows) => (
  confirmationRows.reduce((missingRows, row) => {
    if (row.isProductLine || row.isTotal || row.disabled) return missingRows
    const count = get(row, 'fields.field:standard-physical-count.amount')
    if (count === undefined) missingRows.push(row._id)
    return missingRows
  }, []).length
)

export const getUnManagedProductIds = (stock) => {
  return Object.keys(stock).filter((productId) => {
    return stock[productId].disabled
  })
}
