import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@fielded/shared-ui'
import { PayAsYouSell, PayOnDelivery } from '@fielded/shared-ui/src/icons'

import { pluralize } from '../van-shared/utils/utils'
import { DIRECT_ORDER_TYPES, DIRECT_ORDER_TYPES_HUMANIZED } from '@fielded/fs-api/lib/allocation/config'
const { PAY_ON_DELIVERY, PAY_AS_YOU_SELL } = DIRECT_ORDER_TYPES

const DeliveryStatementDeliveryInfo = ({
  numberOfItems,
  date,
  paymentType,
  immediatePayment
}) => {
  if (paymentType === PAY_ON_DELIVERY) {
    return (
      <div className='delivery-statement-info delivery-statement-info--on-demand'>
        <h2 className='delivery-statement-info__header delivery-statement-info__header--on-demand'>
          <PayOnDelivery /> {DIRECT_ORDER_TYPES_HUMANIZED[PAY_ON_DELIVERY]} subscriptions
        </h2>
        The following {numberOfItems} {pluralize('product', numberOfItems)} {numberOfItems > 1 ? 'were' : 'was'}  delivered on {date} and have been added to your invoice. Your invoice will be available in the app. Payment is due immediately upon receipt.
      </div>
    )
  }

  if (paymentType === PAY_AS_YOU_SELL) {
    return (
      <div className='delivery-statement-info delivery-statement-info--pays'>
        <h2 className='delivery-statement-info__header delivery-statement-info__header--pays'>
          <PayAsYouSell /> {DIRECT_ORDER_TYPES_HUMANIZED[PAY_AS_YOU_SELL]} subscriptions
        </h2>
        The following {numberOfItems} {pluralize('product', numberOfItems)} {numberOfItems > 1 ? 'were' : 'was'} delivered on {date}. You will be invoiced for each unit only once you have sold it.
      </div>
    )
  }

  return (
    <div className='delivery-statement-info'>
      {immediatePayment && (
        <Text.Heading
          size='largish'
          className='vs-u-margin-bottom-half'
        >
          Sale purchase
        </Text.Heading>
      )}
      The following <strong>{numberOfItems} {pluralize('product', numberOfItems)}</strong> {numberOfItems > 1 ? 'were' : 'was'} delivered on <strong>{date}</strong>. {immediatePayment && `You will receive an invoice which will be available in the app. Payment is due immediately upon receipt.`}
    </div>
  )
}

DeliveryStatementDeliveryInfo.propTypes = {
  /* Number of items */
  numberOfItems: PropTypes.number.isRequired,
  /* Date of delivery */
  date: PropTypes.string.isRequired,
  /* paymentType type (PAY_ON_DELIVERY or PAY_AS_YOU_SELL) */
  paymentType: PropTypes.string
}

export default DeliveryStatementDeliveryInfo
