import React from 'react'
import { Checkbox } from '@fielded/shared-ui'

import PSMDriverDeliveryCard from './PSMDriverDeliveryCard'
import { getPSMDriverDeliveryTitle } from './utils'

const PSMDriverDeliveryCardContainer = ({
  history,
  showCompleted,
  programs,
  filteredShipments,
  selectedDeliveries,
  packedDeliveries,
  onToggleCheckbox
}) => {
  const hasSelectedAllPackedDeliveries = packedDeliveries.length && packedDeliveries.length === selectedDeliveries.length
  return (
    <section>
      {!!packedDeliveries.length && !showCompleted && (
        <section className='vs-u-margin-top'>
          <Checkbox.Stateless
            className='driver-delivery-card-container__select-all'
            label='Select all'
            checked={hasSelectedAllPackedDeliveries}
            onChange={event => onToggleCheckbox(event)}
          />
        </section>
      )}

      <section>
        {filteredShipments.map(shipment => {
          const { title: headerTitle } = getPSMDriverDeliveryTitle(shipment)
          return (
            <PSMDriverDeliveryCard
              key={shipment.snapshotId}
              shipment={shipment}
              headerTitle={headerTitle}
              history={history}
              programs={programs}
              showCompleted={showCompleted}
              selectedDeliveries={selectedDeliveries}
              onToggleCheckbox={event => onToggleCheckbox(event, shipment)}
            />
          )
        })}
      </section>
    </section>
  )
}

export default PSMDriverDeliveryCardContainer
