module.exports = toLocationProperties

/** Convert dash seperated location id string to location object
 *
 * Shipment origin and destination location id strings use only
 * dashes to seperate key/value pairs. There are three ways location
 * ids can be encoded:
 * 1. colon seperated key/value pairs: 'zone:nc:state:kano:lga:yagba-west'
 * 2. dash seperated: 'zone-nc-state-kano-lga-yagba-west'
 * 3. as objects: {zone: 'nc', state: 'kano', lga: 'yagba-west'}
 * This function converts from the second representation to the third.
 * It should be noted though, that dash seperated id strings should
 * be avoided where possible, because they are more error prone
 * to parse.
 */
function toLocationProperties (id) {
  if (id === 'national') {
    return {
      national: 'national',
      id
    }
  }

  if (id === 'country') {
    return {
      country: 'country',
      id
    }
  }

  // Parse the hyphen seperated id and create a colon seperate id from it
  // while also collecting the key/value pairs as object properties.
  const props = {}
  const parts = id.split('-')
  let key = ''
  let value = ''
  for (const str of parts) {
    // We check that a property for that `key` doesn't already exists, to guard against
    // satellite stores that contain `zone` in their name
    if (['country', 'zone', 'state', 'lga', 'sdp', 'name'].includes(str) && !props[str]) {
      if (value) {
        props[key] = value
        props.id = props.id != null ? `${props.id}:${key}:${value}` : `${key}:${value}`
      }
      key = str
      value = ''
    } else {
      value = value ? `${value}-${str}` : str
    }
  }
  if (value) {
    props[key] = value
    props.id = props.id != null ? `${props.id}:${key}:${value}` : `${key}:${value}`
  }
  return props
}
