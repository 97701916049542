const get = require('lodash/get')

function findLedgerForStockCount (stockCount, ledgers) {
  let ledger
  for (const l of ledgers) {
    if (l.submittedAt < stockCount.submittedAt &&
        l.event !== stockCount._id &&
        get(l, 'documents.reportId') !== stockCount._id) {
      if (ledger == null) {
        ledger = l
      } else if (l.submittedAt > ledger.submittedAt) {
        ledger = l
      }
    }
  }
  return ledger
}

function findStockCountForLedger (ledger, stockCounts) {
  let stockCount
  for (const sc of stockCounts) {
    if (sc.submittedAt > ledger.submittedAt &&
        sc._id !== ledger.event &&
        get(sc, 'documents.reportId') !== sc._id) {
      if (stockCount == null) {
        stockCount = sc
      } else if (sc.submittedAt < stockCount.submittedAt) {
        stockCount = sc
      }
    }
  }
  return stockCount
}

module.exports = {
  findLedgerForStockCount,
  findStockCountForLedger
}
