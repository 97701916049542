import React from 'react'

import { Button, Modal, Text } from '@fielded/shared-ui'
import { MapMarked } from '@fielded/shared-ui/src/icons'

import DriverDeliveryFooterCard from './DriverDeliveryFooterCard'

const DriverDeliveryPermissionModal = ({
  isPermissionModalOpen,
  isRequestingPermission,
  onClosePermissionModal,
  onRequestLocationPermission
}) => {
  return (
    <Modal
      centered={false}
      isOpen={isPermissionModalOpen}
      title='Location permission'
      className='delivery-map__modal'
      onClose={onClosePermissionModal}
    >
      <section className='delivery-map__modal-content'>
        <section className='delivery-map__modal-info'>
          <Text className='delivery-map__modal-info-icon'>
            <MapMarked />
          </Text>

          <Text bold>
            We require access to your device's location in order to verify your delivery address accurately.
          </Text>
        </section>

        <DriverDeliveryFooterCard>
          <Button
            colorVariant='brand'
            fill='full'
            loading={isRequestingPermission}
            onClick={onRequestLocationPermission}
          >
            Grant permision
          </Button>

        </DriverDeliveryFooterCard>
      </section>
    </Modal>
  )
}

export default DriverDeliveryPermissionModal
