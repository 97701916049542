import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { pluralize } from '../../utils'
import FilterButton from '../FilterButton'

const FilterTitle = ({
  count,
  entity,
  entityContext,
  filterTerm,
  onFilterClear,
  extra,
  compact
}) => {
  const casedEntity = entity.toLowerCase()

  let title = filterTerm
    ? `${count} ${pluralize(casedEntity, count)} matching`
    : `All ${pluralize(casedEntity, 2)} ${entityContext}`

  if (compact) {
    title = filterTerm
      ? `Showing ${count} ${pluralize(casedEntity, count)} matching`
      : `Showing all ${pluralize(casedEntity, 2)} ${entityContext}`

    return (
      <div className='vs-filter-title'>
        <div>
          {title}
          {filterTerm && (
            <FilterButton className='vs-filter-title__button' fill='outline' onClick={() => onFilterClear()}>
              {filterTerm}
            </FilterButton>
          )}
        </div>
        <div className='vs-filter-title__extra'>{extra}</div>
      </div>
    )
  }

  return (
    <Fragment>
      {title}
      {filterTerm && (
        <FilterButton inline onClick={() => onFilterClear()}>
          {filterTerm}
        </FilterButton>
      )}
    </Fragment>
  )
}

FilterTitle.propTypes = {
  /**
   * Count of active items. Will be ignored if no filterTerm is set.
   */
  count: PropTypes.number,
  /**
   * Noun that describes the type of item shown, e.g. "product"
   */
  entity: PropTypes.string,
  /**
   * string to place after the pluralized noun in the unfiltered state,
   * e.g. "at this location". We currently only print this when no filter term,
   * is applied in order to keep the length of the title down.
   */
  entityContext: PropTypes.string,
  /**
   * Active filter applied to the entries
   */
  filterTerm: PropTypes.string,
  /**
   * Called when user clicks filter button to clear the filter
   */
  onFilterClear: PropTypes.func
}

FilterTitle.defaultProps = {
  count: 0,
  entity: 'entry',
  entityContext: '',
  onFilterClear: () => null
}

export default FilterTitle
