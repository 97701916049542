export function parseSearchParams (params) {
  if (!params) return {}
  var search = params.substring(1)
  return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}

export function shallowEquals (a, b) {
  return (Object.keys(a).every(key => b[key] === a[key])) &&
    (Object.keys(b).every(key => a[key] === b[key]))
}

export function urlWithParams (baseUrl, params) {
  if (!params || !Object.keys(params).length) {
    return baseUrl
  }
  const base = baseUrl.includes('?')
    ? `${baseUrl}&`
    : `${baseUrl}?`
  return base + Object.keys(params).map(key => key + '=' + params[key]).join('&')
}

export function urlWithoutFilter (baseUrl, allFilters, filterToRemove) {
  const filters = { ...allFilters }
  delete filters[filterToRemove]
  return urlWithParams(baseUrl, filters)
}
