const get = require('lodash/get')

exports.byId = function (entities) {
  return entities.reduce((acc, entity) => {
    acc[entity._id || entity.id] = entity
    return acc
  }, {})
}

exports.byKey = function (entities, key) {
  return entities.reduce((acc, entity) => {
    acc[get(entity, key)] = entity
    return acc
  }, {})
}
