import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Checkmark, Clock } from '../../icons'

import ProgressStepMarker from '../ProgressStepMarker'

const ProgressSteps = (props) => {
  const {
    steps,
    activeStep,
    activeStepPending,
    className
  } = props

  const stepKeys = Object.keys(steps)
  const activeIndex = stepKeys.indexOf(activeStep)

  return (
    <ul className='vs-progress-steps'>
      {stepKeys.map((step, index) => {
        const stepActive = step === activeStep
        const stepDone = index < activeIndex
        const currentStep = steps[step]

        return (
          <li
            key={step}
            className={classnames(
              'vs-progress-steps__step',
              {'vs-progress-steps__step--done': stepDone},
              {'vs-progress-steps__step--active': stepActive},
              className
            )}
          >
            <ProgressStepMarker
              isActive={stepActive && !activeStepPending}
              isCompleted={stepDone || (stepActive && activeStepPending)}
              className={classnames(
                'vs-progress-steps__marker',
                {'vs-progress-steps__marker--active': stepActive && !activeStepPending}
              )}
            />
            <span>
              {currentStep.description}
              {stepActive && activeStepPending && currentStep.infoPending && (
                <Fragment>
                  {currentStep.infoPending.map(item =>
                    <span
                      className='vs-progress-steps__substep'
                      key={item}
                    >
                      <span className='vs-progress-steps__substep-icon'>
                        <Clock />
                      </span>
                      {item}
                    </span>
                  )}
                </Fragment>
              )}
              {stepDone && currentStep.infoCompleted && (
                <span className='vs-progress-steps__substep vs-progress-steps__substep--done'>
                  <span className='vs-progress-steps__substep-icon vs-progress-steps__substep-icon--done'>
                    <Checkmark />
                  </span>
                  {currentStep.infoCompleted}
                </span>
              )}
            </span>
          </li>
        )
      }
      )}
    </ul>
  )
}

ProgressSteps.propTypes = {
  /**
   * An object containing steps
   * {
   *   stepName: {
   *     description: 'text',
   *      infoPending: ['optional text', 'another optional text'],
   *      infoCompleted: 'optional text',
   *   },
   *   stepName2: {
   *     ...
   *   }
   * }
   */
  steps: PropTypes.object,
  /**
   * A key of active step
   */
  activeStep: PropTypes.string,
  /**
   * A key of active step
   */
  activeStepPending: PropTypes.bool,
  /**
   * In case you want to extend the default class names without overriding the default look.
   * e.g adding custom padding class `className='add-padding'`
   */
  className: PropTypes.string
}

ProgressSteps.defaultProps = {
  steps: {},
  activeStep: undefined,
  className: undefined,
  activeStepPending: false
}

export default ProgressSteps
