const { sha512 } = require('./../../tools/hash')

module.exports = getMandateHistory
async function getMandateHistory ({mandateId, requestId}) {
  const {merchantId, apiKey} = this

  const hash = await sha512(mandateId + merchantId + requestId + apiKey)

  const body = {
    merchantId,
    mandateId,
    requestId,
    hash
  }

  return this.fetch(this.endpoints.getMandateHistory, {
    method: 'POST',
    body: JSON.stringify(body)
  })
}
