const get = require('lodash/get')

exports.substitutionsToProducts = function (snapshotProducts) {
  const result = {}
  Object.keys(snapshotProducts).forEach(productId => {
    const product = snapshotProducts[productId]
    // If using substitutions, that is where you specify any amount of the original
    // product.
    if (product.substitutions) {
      Object.assign(result, product.substitutions)
      if (product.substitutions[productId]) {
        result[productId] = {
          original: product.original,
          adjusted: get(product, `substitutions.${productId}.adjusted`, 0)
        }
      }
      return
    }

    result[productId] = {original: product.original, adjusted: product.adjusted}
  })

  return result
}
