import React, { Component } from 'react'
import Guide from './Guides'

class GuidesContainer extends Component {
  render () {
    const {
      config,
      isSelfSignupUser
    } = this.props
    const pdfLink = '/psm/pdf/NHLMIS.Standard.Operation.Procedure.SOP.1.pdf'
    return (
      <Guide
        config={config}
        pdfLink={pdfLink}
        isSelfSignupUser={isSelfSignupUser}
      />
    )
  }
}

export default GuidesContainer
