import React from 'react'
import PropTypes from 'prop-types'

import { FieldContext } from '../'
import { requiredValidationErrorMessage } from '../../utils'
import OptionsGroup from '../../../OptionsGroup'
import FormFieldRegistrationManager from '../../FormFieldRegistrationManager'

/**
 *  Wrapper for input
 *
 *  Adds additional functionality such as validation
 */
const Options = ({
  defaultValue,
  required,
  validate,
  onValueChange,
  ...otherProps
}) => (
  <FieldContext.Consumer>
    {
      (field) => (
        <FormFieldRegistrationManager
          register={() => {
            field.register({
              value: defaultValue,
              required,
              validate,
              requiredValidator: async (value) => {
                if (Array.isArray(value) && value.length === 0) {
                  // this is a multi choice, check for length instead
                  return required && requiredValidationErrorMessage(required)
                } else if (!value) {
                  return required && requiredValidationErrorMessage(required)
                }
              },
              ...otherProps
            })
          }}
          deregister={() => field.deregister()}
        >
          <OptionsGroup.Stateless
            name={field.props.fieldName}
            defaultValue={defaultValue}
            value={field.props.value}
            hasError={field.hasErrors()}
            onValueChange={(value, changedOption) => {
              field.handleOnChange(value)
              if (typeof onValueChange === 'function') {
                onValueChange(value, changedOption)
              }
            }}
            required={required}
            {...otherProps}
          />
        </FormFieldRegistrationManager>
      )
    }
  </FieldContext.Consumer>
)

Options.propTypes = {
  /**
   * Whether the field is required, optionally pass a string to define a custom
   * validation error message
   */
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),

  /**
   * A custom validation function
   */
  validate: PropTypes.func,

  /**
   * Fires when user selects/deselects an option.
   * Returns the value(s) of the selected option(s) as string/array (if multi)
   * If multi it also returns the clicked option
   */
  onValueChange: PropTypes.func,

  /**
   * Pass the value of an option to pre-select it.
   * For multi groups, pass an array of the values that should be selected.
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  /**
   * Options to be rendered
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      value: PropTypes.string
    })
  ).isRequired,
  /**
   * Render each option in an own row no matter the screen size
   */
  inRows: PropTypes.bool,
  /**
   * Makes the group spread to take up all available space
   * Works well for multiple groups in flex containers,
   * e.g. where some groups have sublabels and others don't.
   */
  spread: PropTypes.bool,

  /**
   * This will render a box around the options with a light grey background
   */
  withBackground: PropTypes.bool,

  /**
   * This will render a box around the options with a light background, for use on grey
   */
  withLightBackground: PropTypes.bool,
  /**
   * Label describing the group, e.g. "Sort list by:"
   */
  label: PropTypes.node
}

Options.defaultProps = {
  required: false,
  validate: undefined,
  onValueChange: undefined,
  defaultValue: undefined
}

Options.displayName = 'Form.Field.Options'
Options.hasLabels = true

export default Options
