import React from 'react'

import { Loading } from '@fielded/shared-ui'

import {withApi} from '../../../common/ApiProvider'
import Success from './Success'

class SuccessContainer extends React.Component {
  state = {loaded: false, locationName: '', locationId: ''}

  async componentDidMount () {
    const {api, match: {params: {locationId}}} = this.props
    const {name} = await api.location.get(locationId)
    this.setState({locationName: name, locationId, loaded: true})
  }

  render () {
    const {rootHistory, history} = this.props
    const {loaded, locationId, locationName} = this.state
    if (!loaded) return <Loading />

    return (
      <Success
        locationName={locationName}
        locationId={locationId}
        history={history}
        rootHistory={rootHistory}
      />
    )
  }
}

export default withApi(SuccessContainer)
