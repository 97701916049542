import { useOnlineOffline } from './common'

async function fetcher (endpoint, params = {}) {
  const options = {
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    ...params
  }
  const res = await window.fetch(`${endpoint}`, options)
  return res.json()
}

export async function post (resource, body, params) {
  const options = {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(body),
    ...params
  }
  return fetcher(resource, options)
}

export const ID_DISP_DOC = '_local/id_dispenser'

export async function fetchIds (idEndpoint, user, dbName, localDb) {
  // Removing credentials in the header to avoid the CORS error:
  // "The value of the 'Access-Control-Allow-Origin' header in the response must not be the wildcard '*'
  // when the request's credentials mode is 'include'"
  const body = { user, dbName }
  const onlineOffline = useOnlineOffline(user)
  if (onlineOffline) {
    body.version = 2
  }

  const options = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include', // Needed to include CORS cookie in localhost mode
    method: 'POST',
    body: JSON.stringify(body)
  }
  const res = await window.fetch(`${idEndpoint}`, options)
  const idResponse = await res.json()
  // Write the id dispenser doc so online/offline
  // knows what can be found locally
  if (idResponse.version === 2) {
    await localDb.get(ID_DISP_DOC)
      .catch(e => { return {} })
      .then(existingDoc => {
        idResponse._id = ID_DISP_DOC
        idResponse.type = 'idDispenser'
        idResponse._rev = existingDoc._rev
        return localDb.put(idResponse)
      })
  } else {
    return { ids: idResponse }
  }

  return idResponse
}

export async function get (resource) {
  const params = {
    method: 'GET'
  }
  return fetcher(resource, params)
}
