import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Page } from '@fielded/shared-ui'

const HomeShelfLife = ({
  user,
  error
}) => {
  return (
    <Fragment>
      <div className={'home__container'}>
        <div>
          Welcome
          <h2>{user.nameOfPharmacy || user.name}</h2>
        </div>
      </div>
      {error && <Page.Panel.Message type='danger' cta={false} fullWidth>
        {error}
      </Page.Panel.Message>}
    </Fragment>
  )
}

HomeShelfLife.propTypes = {
  user: PropTypes.object,
  error: PropTypes.string
}

HomeShelfLife.defaultProps = {
  user: {}
}

export default HomeShelfLife
