/* global URL, Blob */
import get from 'lodash/get'
import { formatDate } from '../../van-shared/utils'

export const HEADERS = [
  {
    name: 'Date',
    key: 'ledgerDate',
    // using snapshot id so excel doesn't f it up
    transform: value => formatDate(value, 'snapshotId')
  },
  {
    name: 'Type',
    key: 'type'
  },
  {
    name: 'From location',
    key: 'origin.name'
  },
  {
    name: 'To location',
    key: 'destination.name'
  },
  {
    name: 'Discrepancy at receiving store',
    key: 'discrepancy'
  },
  {
    name: 'Batch Number',
    key: 'batchNo',
    transform: value => (value === 'UNKNOWN') ? '' : value
  },
  {
    name: 'Manufacturer',
    key: 'manufacturer',
    transform: value => value === 'unknown' ? '' : value
  },
  {
    name: 'Expiry',
    key: 'expiry',
    // using snapshot id so excel doesn't f it up
    transform: value => formatDate(value, 'snapshotId')
  },
  {
    name: 'Previous Value',
    key: 'previousBatchQuantity'
  },
  {
    name: 'Current Value',
    key: 'currentValue'
  },
  {
    name: 'Change',
    key: 'change'
  },
  {
    name: 'Resulting Balance',
    key: 'balance'
  }
]

export default function (rows, fileName, headers = HEADERS) {
  const a = document.createElement('a')
  a.download = fileName.replace(/[\/:*?"<>|]/g, '') + new Date().toISOString() + '.csv' // eslint-disable-line no-useless-escape
  const tsvRows = rows
    .map(row => headers.map(header => (
      header.transform ? header.transform(get(row, header.key, '')) : get(row, header.key, '')
    )).join(','))
  tsvRows.unshift(headers.map(header => header.name).join(','))
  const blob = new Blob([tsvRows.join('\n')], { type: 'text/plain' })
  a.href = URL.createObjectURL(blob)
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
