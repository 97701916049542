const createShipmentSnapshot = require('../../../shipment/shipment-create-snapshot')

const { listAll: listAllProducts } = require('../../../product/api/read/list-all')
const tools = require('../../tools')

const RECEIVED_STATUS = 'received'

exports.addShipment = async function (
  state,
  mainApi,
  {
    suborderId,
    alias = 'one',
    date = new Date().toJSON(),
    productQuantities,
    additionalData = {},
    receiptData = {},
    shipmentNumber,
    logging = false
  }
) {
  if (receiptData.closedStatus === RECEIVED_STATUS && !productQuantities) {
    const err = new Error('Cannot transition a suborder into `accepted` state when no `productQuantities` are given on the payload.')
    err.status = 400
    throw err
  }

  const suborder = await state.dal.order.getSuborder(state, {suborderId, alias})
  if (!suborder) {
    const err = new Error(
      `No uncompleted suborder with id "${suborderId}" was found. Cannot continue.`
    )
    err.status = 404
    throw err
  }

  const allProducts = await listAllProducts(state)

  const updatedOrderDoc = Object.assign({}, suborder, {
    closedStatus: receiptData.closedStatus,
    additionalData: Object.assign({}, additionalData, {
      remark: receiptData.remark,
      updatedAt: receiptData.updatedAt,
      shipmentNumber
    })
  })

  const snapshot = tools.createReceivedShipmentSnapshot(
    {orderSnapshot: updatedOrderDoc, productQuantities, allProducts}
  )

  const responseFromShipSnaps = await createShipmentSnapshot(state, {
    ...snapshot,
    status: RECEIVED_STATUS,
    removePaymentType: true
  })

  return {id: responseFromShipSnaps.id}
}
