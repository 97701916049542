import React from 'react'

import { TextInput } from '@fielded/shared-ui'

import { isShelflifePowered } from '../common/utils'

const ConfirmationComment = ({comment, onCommentChange, config}) => {
  const title = !isShelflifePowered(config) && 'Discrepancies noted'
  const intro = isShelflifePowered(config)
    ? 'If you have any comment, please note it here.'
    : `The quantities counted don't match the SIV. If you have any comment on the discrepancies, please note it here.`
  return (
    <div className='content'>
      <h2 className='centered-page-title'>{title}</h2>
      <section className='floating-card'>
        <h3 className='floating-card__heading'>Any comments?</h3>
        {intro && <p>{intro}</p>}
        <TextInput
          className='confirmation-commment__input'
          name='comment'
          value={comment}
          onChange={onCommentChange}
          rows={5}
        />
      </section>
    </div>
  )
}
export default ConfirmationComment
