// Limitation:
// - it won't work with locations that have per product supply plans
// - it won't work if the version of the relevant `targetPopulations` in the location
// is other than 1 (version 1 means pre-calculated weekly levels)
const locationToForecast = (location, effectiveOnDate = new Date().toISOString()) => {
  const effectiveDate = effectiveOnDate.split('T')[0]
  const allocation = location.allocations.filter(levels => levels.date <= effectiveDate).pop()
  const plan = location.plans.filter(plan => plan.date <= effectiveDate).pop()

  const weeklyLevels = allocation.weeklyLevels
  const dailyDemand = {}
  const safetyStock = {}

  Object.keys(weeklyLevels).forEach(productId => {
    dailyDemand[productId] = weeklyLevels[productId] / 7
    safetyStock[productId] = weeklyLevels[productId] * plan.weeksOfStock.min
  })

  return {
    locationId: location._id,
    effectiveDate: new Date(effectiveDate).toISOString(),
    createdAt: new Date().toISOString(),
    dailyDemand,
    safetyStock,
    supplyPlans: {
      default: {
        supplyPeriodDays: (plan.weeksOfStock.max - plan.weeksOfStock.min) * 7,
        leadTimeDays: (plan.weeksOfStock.reOrder - plan.weeksOfStock.min) * 7
      }
    }
  }
}

module.exports = locationToForecast
