const tools = require('../../tools')
const { get: getService } = require('../../service')
const findForLocation = require('./find-for-location')
const { createId } = require('../tools/ids')
const getDraft = require('./get-draft')
const docToReport = require('./doc-to-report')
const removeDraft = require('./remove-draft')
const getPeriodFromProgram = require('../tools/get-period-from-program')
const fillOpeningAsClosing = require('../tools/fill-opening-as-closing')

module.exports = async (state, {
  reportId,
  reportDoc,
  submittedAt = new Date().toJSON(),
  signed = null,
  dryRun = false
}) => {
  let draftDoc
  if (reportId) {
    // load the local draft document
    draftDoc = await getDraft(state, { reportId, entityOptions: { rawDocs: true } })
  } else if (reportDoc) {
    draftDoc = reportDoc
  }

  const service = await getService(state, draftDoc.serviceId)
  const period = getPeriodFromProgram(service.program, draftDoc.date.period.effectiveStart, true)
  const locationId = tools.stockCountIdToLocationProperties(reportId || draftDoc._id).id
  const partialCount = draftDoc.partialCount

  // find existing reports in the period
  const existingReports = await findForLocation(state, {
    locationId,
    serviceId: service.id,
    period,
    entityOptions: { rawDocs: true }
  })

  let newReport
  if (!partialCount && existingReports.length > 0) {
    const existingReport = existingReports[existingReports.length - 1]

    if (!draftDoc) {
      return docToReport(state, {doc: existingReport, service, period})
    }
    // Update existing report document
    newReport = Object.assign({}, draftDoc, {
      _id: existingReport._id,
      _rev: existingReport._rev,
      createdAt: existingReport.createdAt,
      createdBy: existingReport.createdBy,
      submittedAt
    })
  } else {
    // Create a new report document
    const _id = createId({
      locationId,
      service,
      period,
      withDate: submittedAt
    })
    newReport = Object.assign({}, draftDoc, {
      _id,
      submittedAt
    })
    // Drafts have a local _rev which we must remove before saving in the stock count db
    delete newReport._rev
  }

  if (partialCount) {
    newReport = fillOpeningAsClosing(newReport)
  } else {
    // This is an SL thing to skip counting some products
    // but still keep them on the ledger balance
    const skippedProducts = Object.keys(newReport.stock)
      .filter(productId => newReport.stock[productId].skipCount)
    newReport = fillOpeningAsClosing(newReport, skippedProducts)
  }

  newReport.updatedAt = new Date().toJSON()
  newReport.updatedBy = state.user.name
  if (signed) {
    newReport.signed = signed
  }
  // Delete isPristine if its there. Reports never have that flag. Its only for drafts
  delete newReport.isPristine

  if (!dryRun) {
    await state.dal.report.write(state, newReport)
  }

  if (reportId) {
    // Delete the local draft document
    await removeDraft(state, {reportId: draftDoc._id})
  }

  return docToReport(state, {doc: newReport, service, period})
}
