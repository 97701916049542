import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ActionsBar from '../ActionsBar'

const CardAction = ({children, centered, className, ...otherProps}) => (
  <footer
    className={classnames(
      'vs-card__footer',
      className
    )}
  >
    <ActionsBar
      /**
       * TODO: factor out deprecated prop `centered`.
       * We used to have some simple layout control directly on this component
       * (before we had a shared actions bar). Cards using this had buttons in non-reverse order,
       * so we take a few measures here to stay backward compatible.
       * Please remove once cards have been updated.
       * It may still be in use, in e.g.
       * https://github.com/fielded/van/blob/06b28a8be36b2f34725688ef582b5b53080ac39e/src/pick-list/ShipmentLineCardWithoutBatches.js#L29
       * https://github.com/fielded/van-stock-count/blob/0765eef3e2895cad7aca66a05b5165e2c26dac44/src/update-review-page/UpdateReviewPage.js
       */
      layout={centered ? 'centered' : undefined}
      className={centered ? 'vs-card__footer-legacy-actions' : undefined}
      {...otherProps}
    >
      {children}
    </ActionsBar>
  </footer>
)

CardAction.propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
  className: PropTypes.string
}

CardAction.defaultProps = {
  centered: false,
  className: null
}

CardAction.displayName = 'Card.Action'

export default CardAction
