import React from 'react'
import { Button, DeemphasizedLink, Modal, Text } from '@fielded/shared-ui'

const CountWarningModal = ({
  isModalOpen,
  onCloseModal,
  onClickProceed
}) => {
  return (
    <Modal
      className='count__warning-modal'
      centered={false}
      isOpen={isModalOpen}
      title='Count quantity mismatch'
      onClose={onCloseModal}
    >
      <section>
        <Text className='vs-u-margin-bottom-double' bold>Some product quantities delivered do not match the quantities packed</Text>

        <section className='count__warning-modal-buttons'>
          <DeemphasizedLink
            colorVariant='brand'
            onClick={() => onCloseModal()}
            bold
          >
            Back to editing
          </DeemphasizedLink>

          <Button
            colorVariant='warning'
            fill='full'
            onClick={() => onClickProceed()}
          >
            Proceed
          </Button>
        </section>
      </section>
    </Modal>
  )
}

export default CountWarningModal
