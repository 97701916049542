import React from 'react'

export const Products = (props) => {
  return (
    <svg stroke='currentColor' fill='currentColor' strokeWidth='0' viewBox='0 0 16.61 20.71' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8,15.18l3.44-3.44a3.41,3.41,0,0,1,2.29-1V6.18A2.48,2.48,0,0,0,11.26,3.7H2.5A2.49,2.49,0,0,0,.16,5.38h0A5.15,5.15,0,0,0,0,5.94v2H0v7.25H0v1.94a2.5,2.5,0,0,0,2.5,2.3H7C6,17.63,7.15,16.22,8,15.18ZM5.77,13.63v-1h-1A.73.73,0,0,1,4,11.91V11.2a.73.73,0,0,1,.73-.72h1v-1a.73.73,0,0,1,.73-.73h.7a.73.73,0,0,1,.73.73v1h1a.72.72,0,0,1,.72.72h0v.71a.72.72,0,0,1-.72.72h-1v1a.73.73,0,0,1-.73.72H6.5A.73.73,0,0,1,5.77,13.63Z' />
      <path d='M3,2.81h7.8a.67.67,0,0,0,.73-.57V.58A.66.66,0,0,0,10.78,0H3a.67.67,0,0,0-.73.57V2.24A.67.67,0,0,0,3,2.81Z' />
      <path d='M15.86,12.58l-.2-.2a2.58,2.58,0,0,0-3.62,0l-1.43,1.43L12,15.18l.65.65,1.07,1.07.73.73,1.43-1.43A2.59,2.59,0,0,0,15.86,12.58Z' />
      <path d='M11,15.83l-.65-.65-.55-.55-.55.55-.65.65-.31.31A2.57,2.57,0,0,0,8,19.44a2.69,2.69,0,0,0,.27.32l.2.2a2.56,2.56,0,0,0,3.62,0l1.51-1.51-.25-.25Z' />
    </svg>
  )
}
