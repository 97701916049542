// This is for PSM orders app. TODO: If concept of real product is
// needed in more generic way, this can be replaced with a master data prop on product docs.
exports.filterByReal = filterByReal
function filterByReal (products) {
  return products
    .filter(({basicUnit}) => basicUnit !== 'Patients')
}

// For orders app, we need lists of products by cold chain or non cold chain.
// If a product has no storageType, it should be non-cold-chain.
exports.filterByColdChainType = filterByColdChainType
function filterByColdChainType (products, type) {
  return products.filter(product =>
    type === (product.storageType || 'non-cold-chain')
  )
}
