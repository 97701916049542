const get = require('lodash/get')
const { REPORT_BALANCE_FIELD, REPORT_BALANCE_FIELD_SHORT } = require('../../tools/utils/constants')
/*
 * this function is to fill all closing balances with undefined values to be the opening balances.
 * this is used in shelflife to do partial stock counts.
 */
module.exports = (report, productIds) => {
  productIds = productIds || Object.keys(report.stock)
  const stock = {...report.stock}

  productIds.forEach(productId => {
    stock[productId] = report.stock[productId]

    const amountField = `stock.${productId}.${REPORT_BALANCE_FIELD}`
    const openingField = `stock.${productId}.fields.field:standard-opening-balance.amount`
    const amount = get(report, amountField)

    if (isNaN(amount)) {
      const opening = get(report, openingField)

      // This was the best i could do to not mutate the passed in report
      stock[productId] = Object.assign({ autoFilled: true }, stock[productId], {
        fields: Object.assign({}, stock[productId].fields, {
          [REPORT_BALANCE_FIELD_SHORT]: {
            amount: opening
          }
        })
      })
    }
  })

  return Object.assign({}, report, { stock })
}
