const get = require('lodash/get')
const sheet = require('./sheet')
const { getHeaders } = require('./export-location-headers')

exports.createLocationExport = createLocationExport
function createLocationExport ({ locations = [], programs = [], template = false }) {
  const headers = getHeaders(programs, template)
  const alllocations = prepLocationsForExport(locations)
  return template ? sheet.encode(alllocations, headers) : createCSV(alllocations, headers)
}

exports.prepLocationsForExport = prepLocationsForExport
function prepLocationsForExport (locations = []) {
  // retired is a prop we use to remove duplicate facilities PSM does not want
  // to see at all (vs. inactive, which we still need to include to enable).
  return locations
    .filter(location => !location.retired)
    // PSM warehouses are maintained not by the users by by
    // the fs-data-ops `psm-warehouses` scripts
    .filter(location => !get(location, 'additionalData.isPSMWarehouse'))
    .map(prepLocation)
}

function prepLocation (location) {
  const programs = {}
  const funders = {}
  const implementingPartners = {}

  location.programs.forEach(({ id, services }) => {
    const programKey = id.split('program:')[1]
    programs[programKey] = { services: {} }
    services.forEach(service => {
      programs[programKey].services[`${service.id.split('service:')[1]}`] = 'YES'

      if (service.funderId) {
        funders[programKey] = service.funderId.split('funder:')[1]
      }

      if (service.implementingPartnerId) {
        implementingPartners[programKey] = service.implementingPartnerId.split('implementingPartner:')[1]
      }
    })
  })

  location.facilityId = location.alias && location.alias.hrfc

  return Object.assign(
    {},
    location,
    { programs },
    { funders },
    { implementingPartners }
  )
}

function createCSV (inputRows, headers) {
  const rows = inputRows.map(row => {
    return headers
      .reduce((acc, header) => {
        acc += `"${get(row, header, '')}",`
        return acc
      }, '')
  })
  return rows
    .reduce((acc, row) => {
      acc += `\n${row}`
      return acc
    }, headers.join(','))
}
