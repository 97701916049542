import React, { Component } from 'react'
import withOfflineState from '@fielded/shared-ui/src/common/offline'

import { withApi } from '../../../common/ApiProvider'
import withConfig from '../../../van-shared/hoc/withConfig'

import Confirmation from './Confirmation'
import ConfirmationOfflineWarning from './ConfirmationOfflineWarning'
import ConfirmationComment from './ConfirmationComment'
import ShipmentOnlineError from '../common/ShipmentOnlineError'
import withShipment from './../common/WithShipmentWrapper'

class ConfirmationCommentContainer extends Component {
  constructor () {
    super()
    this.state = {
      comment: '',
      showOfflineWarning: false,
      isOnline: false
    }
  }

  handleCommentChange = ({target: { value: comment }}) => {
    this.setState({
      comment
    })
  }

  handleProceedClick = async () => {
    const {
      isOnline
    } = this.props

    if (isOnline) {
      this.setState({ showOfflineWarning: false })
      this.makeSnapshot()
    } else {
      this.setState({ showOfflineWarning: true })
    }
  }

  handleOfflineClick = () => {
    return this.makeSnapshot()
  }

  makeSnapshot = async () => {
    const {
      history,
      shipment,
      match: { params: { snapshotId } },
      api
    } = this.props

    const opts = {
      status: shipment.statusType === 'distribution' ? 'sent' : 'received'
    }
    const shipmentTypeId = shipment.shipmentType.id
    const params = {
      ...shipment,
      shipmentTypeId,
      ...opts,
      parentDocId: shipment.snapshotId,
      comment: this.state.comment
    }

    try {
      if (shipment.status === 'received') {
        // we're adjusting a completed shipment
        // and the adjustment was created in the previous step
        await api.shipment.adjustment.submit(shipment.id)
      } else {
        await api.shipment.createSnapshot(params)
      }
    } catch (error) {
      // this would only happen to online offline users
      // editing shipments outside the sync range
      this.setState({ error: error })
      return
    }

    history.push(`/shipments/complete/${snapshotId}`)
  }

  render () {
    const { history, config, isOnline } = this.props
    const { error } = this.state
    let errorMsg = null
    if (error) {
      errorMsg = <ShipmentOnlineError error={error} />
    }

    if (!isOnline && this.state.showOfflineWarning) {
      return (
        <ConfirmationOfflineWarning
          history={history}
          onProceedClick={this.handleOfflineClick}
        />
      )
    } else {
      return (
        <Confirmation
          history={history}
          situation='ready'
          onProceedClick={this.handleProceedClick}
        >
          {errorMsg}
          <ConfirmationComment
            comment={this.state.comment}
            onCommentChange={this.handleCommentChange}
            config={config}
          />
        </Confirmation>
      )
    }
  }
}

export default withConfig(
  withApi(
    withShipment(
      withOfflineState(
        ConfirmationCommentContainer
      )
    )
  )
)
