import clone from 'lodash/clone'
import { getServiceForLocationId } from '@fielded/fs-api/lib/service/tools/territory-lookup'
import { productAliasesByMarket, translateTerritoryAlias } from '@fielded/fs-api/lib/product/tools'
import { batchIdToProductId } from '@fielded/fs-api/lib/batch/tools'
import { getUnknownBatchID } from '@fielded/fs-api/lib/shipment/tools/virtual-batch'
import { getShipmentProductIds } from '../subapps/retailer/shipments/helpers'

/**
 * If needed, translates product ids in a shipment from origin market product ID into destination market product ID
 * @param {*} delivery with counts to be translated
 * @param {*} api Our shared FSApi object, as created on app init
 * @returns {Object} A person object with the following properties:
 * - translatedShipment Delivery with translated counts
 * - translatedIds {Array} All translated product ids
*/

export const translateProductsToRetailerMarket = async (delivery, api) => {
  const translatedShipment = clone(delivery)
  const translatedIds = []

  // First see if translation is needed at all
  const { service: originServiceId } = getServiceForLocationId(delivery.origin.id)
  const { service: destinationServiceID } = getServiceForLocationId(delivery.destination.id)

  // If no translation needed (same market for origin and location) just return what we originally had
  if (destinationServiceID === originServiceId) {
    return { translatedShipment, translatedIds }
  }

  // Get product aliases
  const priceReferenceDate = delivery.snapshotDates.received || delivery.snapshotDates.arrived || delivery.snapshotDates.sent || delivery.snapshotDates.new
  const productIds = getShipmentProductIds(delivery)
  const allProducts = await api.product.getProductsViaIds([...productIds], { date: priceReferenceDate })
  const productsForTranslation = productAliasesByMarket(allProducts)

  // Translate products from the delivery into the resulting translated delivery
  translatedShipment.counts = Object.keys(delivery.counts)
    .reduce((acc, batchId) => {
      const originalProductCountProps = delivery.counts[batchId]
      const productId = batchIdToProductId(batchId)
      const convertedProductId = translateTerritoryAlias(productId, productsForTranslation, destinationServiceID)
      const newBatchId = getUnknownBatchID(convertedProductId)

      translatedIds.push(convertedProductId)

      return {
        ...acc,
        [newBatchId]: originalProductCountProps
      }
    }, {})

  return {translatedShipment, translatedIds}
}
