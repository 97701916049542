const PSM_WAREHOUSE_DEFINITIONS = {
  'DC-301 :: MDS Abuja': {
    warehouseCode: 'DC-301 :: MDS Abuja',
    zone: 'zone:north-central',
    name: 'MDS Abuja',
    _id: 'zone:north-central:name:mds-abuja',
    suppliesColdChain: true,
    lookupId: 'mds-abuja'
  },
  'NDC-201 :: Abuja Premier Medical Warehouse': {
    warehouseCode: 'NDC-201 :: Abuja Premier Medical Warehouse',
    zone: 'zone:north-central',
    name: 'Abuja Premier Medical Warehouse',
    _id: 'zone:north-central:name:abuja',
    suppliesColdChain: true,
    lookupId: 'abuja'
  },
  'DC-303 :: Axios Warehouse Awka': {
    warehouseCode: 'DC-303 :: Axios Warehouse Awka',
    zone: 'zone:south-south',
    name: 'Axios Warehouse Awka',
    _id: 'zone:south-south:name:awka',
    lookupId: 'awka'
  },
  'DC-309 :: MDS Warehouse Calabar': {
    warehouseCode: 'DC-309 :: MDS Warehouse Calabar',
    zone: 'zone:south-south',
    name: 'MDS Warehouse Calabar',
    _id: 'zone:south-south:name:calabar',
    lookupId: 'calabar'
  },
  'DC-305 :: Axios Warehouse Gombe': {
    warehouseCode: 'DC-305 :: Axios Warehouse Gombe',
    zone: 'zone:north-east',
    name: 'Axios Warehouse Gombe',
    _id: 'zone:north-east:name:gombe',
    lookupId: 'gombe'
  },
  'DC-306 :: Axios Warehouse Sokoto': {
    warehouseCode: 'DC-306 :: Axios Warehouse Sokoto',
    zone: 'zone:north-west',
    name: 'Axios Warehouse Sokoto',
    _id: 'zone:north-west:name:sokoto',
    lookupId: 'sokoto'
  },
  'NDC-202 :: Lagos Premier Medical Warehouse': {
    warehouseCode: 'NDC-202 :: Lagos Premier Medical Warehouse',
    zone: 'zone:south-west',
    name: 'Lagos Premier Medical Warehouse',
    _id: 'zone:south-west:name:lagos',
    suppliesColdChain: true,
    lookupId: 'lagos'
  },
  'SDC/601/BAU': {
    warehouseCode: 'SDC/601/BAU',
    zone: 'zone:north-east',
    name: 'Bauchi State Central Medical Store',
    _id: 'zone:north-east:name:bauchi',
    lookupId: 'bauchi'
  },
  'SDC/608/SKT': {
    warehouseCode: 'SDC/608/SKT',
    zone: 'zone:north-west',
    name: 'Sokoto State Central Medical Store',
    _id: 'zone:north-west:name:sokoto-state',
    lookupId: 'sokoto'
  },
  'SDC/609/ZMF': {
    warehouseCode: 'SDC/609/ZMF',
    zone: 'zone:north-west',
    name: 'Zamfara State Central Medical Store',
    _id: 'zone:north-west:name:zamfara-state',
    lookupId: 'zamfara'
  },
  'SDC/611/NSW': {
    warehouseCode: 'SDC/611/NSW',
    zone: 'zone:north-central',
    name: 'Nasarawa State Central Medical Store',
    _id: 'zone:north-central:name:nasarawa-state',
    lookupId: 'nasarawa'
  },
  'SDC/626/BEN': {
    warehouseCode: 'SDC/626/BEN',
    zone: 'zone:north-central',
    name: 'Benue State Central Medical Store',
    _id: 'zone:north-central:name:benue-state',
    lookupId: 'benue'
  }
}
exports.PSM_WAREHOUSE_DEFINITIONS = PSM_WAREHOUSE_DEFINITIONS

// expects `DC-303 :: Axios Warehouse Awka`, `funder:pepfar`
exports.warehouseCodeToVirtualId = warehouseCodeToVirtualId
function warehouseCodeToVirtualId ({warehouseCode, funderId, programId}) {
  if (!warehouseCode ||
    (programId && !programId.startsWith('program:')) ||
    (funderId && !funderId.startsWith('funder:'))
  ) {
    throw new Error(
      `Error usage: expects ({warehouseCode, funderId, programId}), e.g.
      warehouseCodeToVirtualId({
        warehouseCode: 'DC-303 :: Axios Warehouse Awka',
        programId: 'program:hiv-aids',
        funderId: 'funder:pepfar'
      })`
    )
  }
  const {_id} = warehouseCodeToVirtualProps(warehouseCode)

  // HIV program does not separate stock by funder (just commmits, the so-called "bulk allocation")
  if (programId && programId === 'program:hiv-aids') {
    return _id
  }

  const shortFunderId = funderId.replace('funder:', '')
  return `${_id}-${shortFunderId}`
}

// These are our hard-coded PSM supply warehouse codes:
// https://github.com/fielded/van-orga/issues/2702
// DC-301 :: MDS Abuja
// DC-303 :: Axios Warehouse Awka
// DC-309 :: MDS Warehouse Calabar
// DC-305 :: Axios Warehouse Gombe
// DC-306 :: Axios Warehouse Sokoto
// NDC-201 :: Abuja Premier Medical Warehouse
// NDC-202 :: Lagos Premier Medical Warehouse

// HEADS UP: these warehouses really don't correspond to Nigeria's geopolitical zones
// calabar for example is in south-south but supplies some south-east states
// that's what relationshipRules are used for.
exports.warehouseCodeToVirtualProps = warehouseCodeToVirtualProps
function warehouseCodeToVirtualProps (warehouseCode, lookupMap = PSM_WAREHOUSE_DEFINITIONS) {
  if (!lookupMap[warehouseCode]) {
    throw new Error(`Usage error: warehouseCode ${warehouseCode} not found in hard-coded list`)
  }
  return lookupMap[warehouseCode]
}
