/** Directed graph
 *
 * The graph supports any kind of node as long as the node
 * implements the `INode` interface which requires the node to
 * have an `id` property.
 */
exports.Graph = class Graph {
  constructor (nodes, edges) {
    this.nodes = nodes
    this.nodesIndexMap = nodes.reduce((map, node, index) => {
      return map.set(node.id, index)
    }, new Map())

    this.edgesMap = edges.reduce((map, [pId, edgeType, sId]) => {
      const edgeTypeMap = map.get(edgeType) || new Map()
      const pIndex = this.nodesIndexMap.get(pId)
      const sIndex = this.nodesIndexMap.get(sId)

      if (pIndex == null) {
        return map
      }

      if (sIndex == null) {
        return map
      }

      const successors = edgeTypeMap.get(pIndex) || new Set()
      edgeTypeMap.set(pIndex, successors.add(sIndex))
      return map.set(edgeType, edgeTypeMap)
    }, new Map())
  }

  /** Get a node by id.
   *
   * e.g. `graph.getNode('location', 'zone:nc')
   */
  getNode (id) {
    const nodeIndex = this.nodesIndexMap.get(id)
    if (nodeIndex == null) {
      return
    }
    return this.nodes[nodeIndex]
  }

  /** Get all nodes
   */
  getAllNodes () {
    return this.nodes
  }

  /** Get the direct successors of a node by relationship type
   *
   * e.g. `graph.getSuccessor('location', 'zone:nc', 'supplies')`
   */
  getSuccessors (id, edgeType) {
    const nodeIndex = this.nodesIndexMap.get(id)
    if (nodeIndex == null) {
      return []
    }
    const edgeMap = this.edgesMap.get(edgeType)
    if (!edgeMap) {
      return []
    }
    const successorIndexes = edgeMap.get(nodeIndex)
    if (!successorIndexes) {
      return []
    }
    return [...successorIndexes].map(i => this.nodes[i])
  }

  /** Get all descendants starting from a node following a relationship type
   *
   * e.g. `graph.getDescendants('location', 'zone:nc', 'supplies')`
   *
   * The descendants are returned in depth first order.
   */
  getDescendants (rootId, edgeType) {
    const edgesMap = this.edgesMap.get(edgeType)
    if (!edgesMap) {
      return []
    }
    const rootIndex = this.nodesIndexMap.get(rootId)
    if (rootIndex == null) {
      throw new Error('Root node not found: ' + rootId)
    }
    const visited = new Set()
    const indexes = new Set()
    let toVisit = [rootIndex]
    while (toVisit.length > 0) {
      const currentIndex = toVisit[toVisit.length - 1]
      if (!visited.has(currentIndex)) {
        visited.add(currentIndex)
        const successors = edgesMap.get(currentIndex)
        if (successors) {
          toVisit.push(...successors.keys())
          continue
        }
      }
      if (currentIndex !== rootIndex) {
        indexes.add(currentIndex)
      }
      toVisit.pop()
    }
    return [...indexes.keys()].map(i => this.nodes[i])
  }
}
