const { addDays, isSameWeek, isSameDay, subWeeks } = require('date-fns')

// Since the rules for Kenya and Nigeria are very different,
// we are splitting the methods into two

module.exports.calculateCutoffDateForPurchase = (country, market, firstDate, secondDate, thirdDate) => {
  let cutoffDates = {
    routine: null,
    calendar: null
  }

  if (country === 'ke') {
    const kenyaCutoff = calculateCutoffDateKenya(market)
    cutoffDates = {
      routine: kenyaCutoff,
      calendar: kenyaCutoff
    }
  }

  if (country === 'ng') {
    cutoffDates = {
      routine: calculateCutoffDateNigeria(market, firstDate, secondDate, thirdDate),
      calendar: calculateCutoffDateNigeriaOffRoutine(market)
    }
  }

  return cutoffDates
}

const calculateCutoffDateKenya = (market) => {
  const today = new Date()
  const tomorrow = addDays(today, 1)
  const dayAfterTomorrow = addDays(tomorrow, 1)

  // We want to check if time is before/after 1 pm (13)
  const currentHour = today.getHours()

  // We need to make sure the next day won't fall on the weekend
  const currentDay = today.getDay()

  // For Nairobi, as long as it is before 1pm,
  // we allow next day delivery.
  if (market === 'nairobi') {
    if (currentHour < 13) {
      return tomorrow
    }

    // Days of weekend are blocked by default
    // so if dayAfterTomorrow falls on weekend,
    // it is not a problem as it will resolve Monday.
    return dayAfterTomorrow
  }

  // This check is just for readability
  if (market !== 'nairobi') {
    // we are checking if it is not Friday
    // or a weekend (Sunday is 0)

    if (currentDay < 5 && currentDay !== 0) {
      if (currentHour < 13) {
        return tomorrow
      }

      // If hour is after 1pm we can deliver
      // day after tomorrow. For Thursday it lands on a weekend,
      // so Monday will we first available date in calendar
      return dayAfterTomorrow
    }

    // If it is Friday before 1pm, we deliver Monday
    if (currentDay === 5 && currentHour < 13) {
      return addDays(today, 3) // Monday
    }

    // For orders after 1pm Friday and on weekends,
    // we want to make sure the earliest possible
    // day available is Tuesday next week.
    // We count how many days of the week are left
    // and add 2 (Tuesday is second day of week)
    const daysToAdd = (currentDay === 0) ? 2 : (7 - currentDay) + 2
    return addDays(today, daysToAdd)
  }
}

const calculateCutoffDateNigeria = (market, firstDate, secondDate, thirdDate) => {
  const today = new Date()
  // We want to check if time is before/after 1 pm (13)
  const currentHour = today.getHours()

  // We need to make sure the next day won't fall on the weekend
  const currentDay = today.getDay()

  const isNextDeliverySameWeek = isSameWeek(
    today,
    firstDate,
    { weekStartsOn: 1 }
  )

  // We are checking if after deducting one week from second date,
  // we end up with first delivery date.
  // It means the delivery is weekly then
  const weekBeforeSecondDelivery = subWeeks(secondDate, 1)
  const isDeliveryWeekly = isSameDay(weekBeforeSecondDelivery, firstDate)

  const isBeforeCutoffHour = currentHour < 18
  const isBeforeCutoffDay = (day) => {
    return currentDay <= day
  }

  // These markets can have same week delivery if it is before cutoff
  const marketsWithCutoffMonday = [
    'oyo',
    'kwara'
  ]

  const marketsWithCutoffTuesday = [
    'lagos'
  ]

  // These are markets that have additional week added
  const marketsWithCutoffDelayedTuesday = [
    'delta',
    'edo',
    'enugu',
    'ph',
    'rivers',
    'medbury',
    'kano',
    'kaduna',
    'nasarawa',
    'fct',
    'ogun'
  ]

  const calculateDate = (cutoffDay) => {
    if (isNextDeliverySameWeek && !(isBeforeCutoffHour && isBeforeCutoffDay(cutoffDay))) {
      return secondDate
    }

    return firstDate
  }

  const calculateDateWithDelay = (cutoffDay) => {
    if (isBeforeCutoffHour && isBeforeCutoffDay(cutoffDay)) {
      if (isNextDeliverySameWeek) {
        return secondDate
      } else {
        return firstDate
      }
    }

    if (isNextDeliverySameWeek && isDeliveryWeekly) {
      return thirdDate
    } else {
      return secondDate
    }
  }

  if (marketsWithCutoffMonday.includes(market)) {
    return calculateDate(1)
  }

  if (marketsWithCutoffTuesday.includes(market)) {
    return calculateDate(2)
  }

  if (marketsWithCutoffDelayedTuesday.includes(market)) {
    return calculateDateWithDelay(2)
  }

  // If market was not matched, let's give it the longest delay
  return (calculateDateWithDelay(2))
}

const calculateCutoffDateNigeriaOffRoutine = (market) => {
  const today = new Date()
  let currentDay = today.getDay()

  let dateToUse = today
  let currentHour = today.getHours()
  const isWeekend = (currentDay === 6) || (currentDay === 0)

  if (isWeekend) {
    const daysTillMonday = currentDay === 6 ? 2 : 1
    dateToUse = addDays(today, daysTillMonday) // We want to mimic monday
    currentDay = dateToUse.getDay()
    currentHour = 6 // we are treating it as if before start of business Monday
  }

  const OFFSET_IN_DAYS_PER_MARKET = {
    delta: 4,
    edo: 4,
    enugu: 4,
    fct: 4,
    kaduna: 5,
    kano: 5,
    kwara: 1,
    lagos: 1,
    oyo: 1,
    ogun: 1,
    rivers: 4,
    medbury: 4,
    ph: 4,
    nasarawa: 4,
    nasdma: 4,
    reliance: 4,
    rjolad: 1,
    cedar: 1,
    default: 5
  }

  const CUTOFF_HOUR_PER_MARKET = {
    delta: 10,
    edo: 10,
    enugu: 10,
    fct: 10,
    kaduna: 10,
    kano: 10,
    kwara: 12,
    lagos: 17,
    oyo: 12,
    ogun: 17,
    rivers: 10,
    medbury: 10,
    ph: 10,
    nasarawa: 10,
    nasdma: 10,
    reliance: 10,
    rjolad: 17,
    cedar: 17,
    default: 17
  }

  let offsetInDays = OFFSET_IN_DAYS_PER_MARKET[market] || OFFSET_IN_DAYS_PER_MARKET.default

  // If we pass the cutoff hour, we are adding one day to possible delivery (unless user order during the weekend, we don't care about the time then)
  const cutoffHour = CUTOFF_HOUR_PER_MARKET[market] || CUTOFF_HOUR_PER_MARKET.default
  const isBeforeCutoffHour = currentHour < cutoffHour
  if (!isBeforeCutoffHour) {
    offsetInDays = offsetInDays + 1
  }

  // We are calculating if current day in addition with the offsetInDays
  // will land before Friday (day number 5). If not, we add two days for weekend.
  let daysToAdd = offsetInDays
  const isDayWithOffsetBeforeWeekend = ((currentDay + offsetInDays) <= 5)
  if (!isDayWithOffsetBeforeWeekend) {
    // If day is not Saturday, we are adding 2 days.
    const amountOfWeekendDaysToAdd = currentDay === 6 ? 1 : 2
    daysToAdd = daysToAdd + amountOfWeekendDaysToAdd
  }

  return addDays(dateToUse, daysToAdd)
}
