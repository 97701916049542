const { createNewOrders } = require('./create-new-orders')
const get = require('lodash/get')

exports.createPSMBulkImportSnapshots = createPSMBulkImportSnapshots
function createPSMBulkImportSnapshots (data, locations, products, user) {
  const unfilteredRows = parseCSV(data)
  throwOnInvalidRow(unfilteredRows)
  const codeToIdMap = getCodeToIdMap(locations)
  const {rows} = filterOutMissing(unfilteredRows, codeToIdMap, products)

  const withWarehouseCodes = rows
    .map(row => Object.assign({}, row, {location: codeToIdMap[row.warehouseCode]}))

  const byProgram = getByProgram(withWarehouseCodes)

  const byProgramsLocations = getProgramsByLocation(byProgram)

  const snapshotsByProgram = byProgramsLocations.map(({allocations, programId}) => {
    return createNewOrders({
      programId,
      allocations,
      user
    })
  })

  const asRows = getSnapshotsByProgramAsRows(snapshotsByProgram, locations, codeToIdMap)

  const counts = {
    products: asRows.length,
    orders: new Set(asRows.map(row => row.orderId)).size,
    locations: new Set(asRows.map(row => row.destinationId)).size
  }

  const snapshots = snapshotsByProgram
    .reduce((acc, snapshots) => acc.concat(snapshots), [])

  return {snapshots, asRows, counts}
}

function filterOutMissing (rows, codeToIdMap, products) {
  // const errors = [missingCodes: ]
  const missingCodes = rows
    .filter(row => !locationExistsWithProgram(Object.assign({codeToIdMap}, row)))

  if (missingCodes.length) {
    throwError(
      'Found warehouse codes that do not exist or do not have programs enabled. Double check the warehouse code is for an SDP (and not a warehouse)',
      missingCodes
    )
  }

  const productIds = new Set(products.map(p => p._id))

  const missingProduct = rows
    .filter(row => !productIds.has(row.productId))

  // TODO: return these as errors & display as list in UI
  if (missingProduct.length) {
    throwError(`Received product ids that do not exist`, missingProduct)
  }

  const filteredRows = rows
    .filter(row => locationExistsWithProgram(Object.assign({}, row, {codeToIdMap})))
    .filter(row => productIds.has(row.productId))

  return {rows: filteredRows}
}

function locationExistsWithProgram ({codeToIdMap, warehouseCode, programId}) {
  const location = codeToIdMap[warehouseCode]
  if (!location) return false

  return !!location.programs.find(program => program.id === programId)
}

exports.getByProgram = getByProgram
function getByProgram (productRows) {
  const asMap = productRows
    .reduce((acc, productRow) => {
      acc[productRow.programId] = acc[productRow.programId] || []
      acc[productRow.programId].push(productRow)
      return acc
    }, {})

  return Object.keys(asMap)
    .map(programId => ({programId, productRows: asMap[programId]}))
}

exports.getProgramsByLocation = getProgramsByLocation
function getProgramsByLocation (programRows) {
  return programRows.map(programRow => {
    const {programId, productRows} = programRow
    const allocations = getByLocation(productRows)
    return {
      programId,
      allocations
    }
  })
}

// expects already reduced on programId
function getByLocation (productRows) {
  const asMap = productRows
    .reduce((acc, row) => {
      const {location, productId, quantity, orderType} = row
      acc[location._id] = acc[location._id] || {location, products: {}}
      acc[location._id].products[productId] = {original: Number(quantity)}
      acc[location._id].orderType = orderType
      return acc
    }, {})

  return Object.values(asMap)
}

function getCodeToIdMap (locations) {
  return locations
    .filter(location =>
      (!location.retired && get(location, 'additionalData.warehouseCode'))
    )
    .reduce((acc, location) => {
      acc[location.additionalData.warehouseCode] = location
      return acc
    }, {})
}

function parseCSV (data) {
  const rows = data.split('\n')
  const removeQuotes = field => field.replace(/"/g, '').trim()
  const headers = rows.splice(0, 1)[0].split(',').map(removeQuotes)
  return rows
    .map(row => {
      const rowFields = row.split(',').map(removeQuotes)
      return headers
        .reduce((acc, header, index) => {
          acc[header] = rowFields[index]
          return acc
        }, {})
    })
    // Filter out rows that have no values for anything
    .filter(row => Object.keys(row).some(header => row[header]))
}

function getSnapshotsByProgramAsRows (snapshotsByProgram, locations) {
  const locationsById = locations
    .reduce((acc, location) => {
      acc[location._id] = location
      return acc
    }, {})

  const rows = []
  snapshotsByProgram.forEach(programRows => {
    programRows.forEach(order => {
      const {programId, destinationId, orderId, orderType} = order
      const {
        fullName: locationName,
        additionalData: {warehouseCode},
        location: {state, lga}
      } = locationsById[destinationId]

      const shortProgramId = programId.replace('program:', '')
      Object.keys(order.products).forEach(productId => {
        const shortProductId = productId.replace('product:', '')
        const quantity = order.products[productId].original
        rows.push({
          programId: shortProgramId,
          warehouseCode,
          locationName,
          state,
          lga,
          productId: shortProductId,
          quantity,
          destinationId,
          orderId,
          orderType
        })
      })
    })
  })
  return rows
}

function throwOnInvalidRow (rows) {
  const EXPECTED_HEADERS = ['warehouseCode', 'orderType', 'productId', 'programId', 'quantity']
  const badRows = rows.filter(row => EXPECTED_HEADERS.find(header => !row[header]))
  if (badRows.length) {
    throwError(
      `Error found in import file. Expected columns were not found on at least one row.
      expected headers: warehouseCode, orderType, productId, programId, quantity`,
      badRows
    )
  }

  // validate orderType row
  const allowedOrderTypes = ['emergency', 'adhoc']

  // check that orderType is same
  // all orders will be under one order group
  const orderTypes = new Set(rows.map(({ orderType }) => orderType))
  if (orderTypes.size !== 1) {
    throwError('Multiple orderTypes found', rows)
  }

  // check that orderType is valid
  for (const orderType of orderTypes) {
    if (!allowedOrderTypes.includes(orderType)) {
      throwError(`Invalid orderType, '${orderType}'`)
      break
    }
  }
}

function throwError (message, data) {
  const error = new Error()
  error.data = data
  error.message = message
  throw error
}
