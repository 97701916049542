const get = require('lodash/get')
const XLSX = require('@sheet/core')

const SHEET_NAME = 'locations'

const encode = (alllocations, headers) => {
  const rows = createSheetRows(alllocations, headers)
  const ws = XLSX.utils.aoa_to_sheet([
    headers,
    ...rows
  ])

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, SHEET_NAME)
  return XLSX.write(wb, {type: 'array', compression: true})
}

const decode = buffer => {
  if (!buffer) {
    throw new Error('buffer parameter is required')
  }

  const wb = XLSX.read(buffer, {type: 'array'})
  const ws = wb.Sheets[SHEET_NAME]

  if (!ws) {
    throw new Error(`Worksheet '${SHEET_NAME}' not found`)
  }

  return XLSX.utils.sheet_to_json(ws)
}

const createSheetRows = (inputRows, headers) => {
  const rows = inputRows.map(row => {
    return headers
      .reduce((acc, header) => {
        acc.push(get(row, header, ''))
        return acc
      }, [])
  })
  return rows
}

module.exports = {
  encode, decode
}
