const installmentsQuery = require('./common/installments')

const futureInstalmentQuery = `
  WITH ${installmentsQuery}
  SELECT
    *
  FROM installments
  WHERE due_date > (current_date + interval '6 days') AND location_id = $1
`

module.exports = futureInstalmentQuery
