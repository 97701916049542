const XLSX = require('@sheet/core')
const {
  SHIPMENT_IMPORT_HEADERS,
  SHIPMENT_IMPORT_SHEET_NAME
} = require('./tools/read/import')

exports.getImportTemplate = function () {
  const workSheet = XLSX.utils.aoa_to_sheet([SHIPMENT_IMPORT_HEADERS])
  const workBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workBook, workSheet, SHIPMENT_IMPORT_SHEET_NAME)
  return XLSX.write(workBook, {type: 'array', compression: true})
}
