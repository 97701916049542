const cloneDeep = require('lodash/cloneDeep')

exports.updateSnapshot = updateSnapshot
function updateSnapshot ({
  parentSnapshot,
  products,
  user,
  timestamp,
  paymentStatus,
  paymentTimestamp,
  paymentChoice,
  totalAmount
}) {
  const changedSnapshot = cloneDeep(parentSnapshot)
  changedSnapshot.products = updateProducts(changedSnapshot.products, products)
  changedSnapshot.updatedAt = timestamp
  changedSnapshot.updatedBy = user.name
  changedSnapshot.paymentStatus = paymentStatus || parentSnapshot.paymentStatus
  changedSnapshot.paymentTimestamp = paymentTimestamp
  changedSnapshot.paymentChoice = paymentChoice
  changedSnapshot.totalAmount = totalAmount
  return changedSnapshot
}

exports.updateProducts = updateProducts
function updateProducts (orderProducts, editedProducts) {
  const products = Object.keys(editedProducts)
    .reduce((acc, productId) => {
      acc[productId] = updateProduct(orderProducts[productId], editedProducts[productId], productId)
      return acc
    }, {})

  return Object.assign({}, orderProducts, products)
}

exports.updateProduct = updateProduct
function updateProduct (orderProduct, editedProduct, updateProductId) {
  // if there's no orderProduct, set the original to zero
  const updatedProduct = Object.assign({original: 0}, orderProduct, editedProduct)

  if (!editedProduct.substitutions) {
    return Object.assign({}, updatedProduct, editedProduct)
  }

  // sum adjusted if using substitutions
  updatedProduct.adjusted = Object.values(editedProduct.substitutions)
    .reduce((acc, val) => {
      acc += val.adjusted
      return acc
    }, 0)

  updatedProduct.substitutions = {}
  // only take adjusted values from edits, original will be zero on substitutions
  Object.keys(editedProduct.substitutions).forEach(productId => {
    updatedProduct.substitutions[productId] = {
      original: productId === updateProductId ? orderProduct.original : 0,
      adjusted: editedProduct.substitutions[productId].adjusted
    }
  })

  return updatedProduct
}
