const {
  TABLE_NAMES: {
    PAYMENT_PLAN_TABLE_NAME
  }
} = require('../../constants')

module.exports = ({instalmentTableJoin, isActiveClause, includeBalance}) => {
  return `
    SELECT
      ${instalmentTableJoin ? `coalesce(ppi.id, inst.id) as id` : `inst.id`},
      ${instalmentTableJoin ? `coalesce(ppi.code, inst.code) as code` : `inst.code`},
      inst.location_id,
      inst.payment_plan_id,
      inst.start_date,
      inst.due_date,
      ${includeBalance ? 'inst.balance,' : ''}
      inst.amount,
      inst.amount_paid,
      inst.is_paid,
      inst.created_at,
      inst.updated_at,
      row_to_json(pp)::jsonb as payment_plan
    FROM ${PAYMENT_PLAN_TABLE_NAME} pp
    LEFT JOIN avocado.data_location l ON pp.location_id = l.uuid
    LEFT JOIN LATERAL (
      -- The lateral join will generate a series of installment rows
      -- per payment plan row and calculate if and how much was paid on
      -- an installment using the customers loan quickbooks account balance.
      SELECT
        pp.id as id,
        'P-' || upper(l.code) || '-' || upper(substring(l.market_id from 1 for 3)) || '-' ||
        to_char(pp.start_date + (nth - 1) * calc.freq, 'YYMMDD') as code,
        pp.location_id,
        pp.id as payment_plan_id,
        pp.start_date as start_date,
        cast(pp.start_date + (nth - 1) * calc.freq as date) as due_date,
        -- The installment amount on the paymentplan includes the service fee
        pp.installment_amount as amount,
        -- The assumption is, that if any amount has been paid for the installment,
        -- then the service fee for the installment is already paid, because that
        -- is booked first.
        case when calc.installment_principal_paid > 0
          then calc.installment_principal_paid + pp.service_fee
          else 0 end as amount_paid,
        balance,
        calc.installment_principal_paid + pp.service_fee = pp.installment_amount as is_paid,
        pp.created_at,
        pp.updated_at
      FROM generate_series(1, pp.term) nth,
      -- Lateral subquery to calculate some values for the installment rows
      LATERAL (
        SELECT
          (pp.installment_amount*term) - (pp.installment_amount*nth)  as balance,
          -- Get the amount paid on the installment without service fee.
          -- We iterate over the installments and set the amount paid to
          -- the total paid minus what was already assigned to the previous
          -- installments.
          -- We don't use payment_plan.past_due(the principal) here, because
          -- payment_plan.installment_amount has been rounded up and
          -- multiplying installment_amount(excluding the service fee)
          -- with payment_plan.term would most likely not be equal to past_due.
          -- The payment plan document for the customer shows rounded installment
          -- amounts as does the UI and thats why we use the same here.
          greatest(0, least(pp.installment_amount - pp.service_fee,
              ((pp.installment_amount - pp.service_fee) * term - pp.quickbooks_account_balance)
              - (nth - 1) * (pp.installment_amount - pp.service_fee)
            )) as installment_principal_paid,
          case
            when frequency = 'WEEKLY' then interval '1 week'
            when frequency = 'BIWEEKLY' then interval '2 week'
            when frequency = 'MONTHLY' then interval '1 month'
            when frequency = 'BIMONTHLY' then interval '2 month'
            when frequency = 'TRIMONTHLY' then interval '3 month'
          end as freq
        ) as calc
    ) as inst ON true 
    ${instalmentTableJoin ? `LEFT join avocado.data_paymentplaninstallment ppi on ppi.payment_plan_id = inst.id and ppi.due_date = inst.due_date` : ``}
    ${isActiveClause ? `WHERE  "pp"."is_active" IS TRUE` : ``}
  `
}
