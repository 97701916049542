import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import orderBy from 'lodash/orderBy'

import Banner from '../Banner'
import TableHeaderCell from './TableHeaderCell'
import { SORT_DIRECTON } from '../../utils/constants'

import get from 'lodash/get'

const Table = ({
  columns,
  entries,
  styleVariant,
  sortBy,
  sortDirection,
  noRowsContent,
  sorterFunction,
  withStatus,
  onRowClick,
  rowClassName,
  rowClassNameCondition,
  onHandleSort,
  headerTextStyle
}) => {
  const sortEntries = ({ sortBy, sortDirection, entries }) => {
    return orderBy((entries), [(entry) => {
      const sortedValue = get(entry, sortBy)
      return (typeof sortedValue === 'string') ? sortedValue.toLowerCase() : sortedValue
    }], sortDirection.toLowerCase())
  }

  const onRowClickTriggered = (row) => {
    !!onRowClick && onRowClick(row)
  }

  let sortedEntries = entries
  if (sortBy) {
    sortedEntries = (sorterFunction || sortEntries)({ sortBy, sortDirection, entries })
  }

  if (!columns) return null

  return (
    <table className={classnames(
      'vs-u-table',
      {'vs-u-table--deemphasized': styleVariant === 'deemphasized'}
    )}>
      <thead className='vs-u-table__header'>
        <tr>
          {columns.map(cell => {
            return (
              <TableHeaderCell
                key={cell.key}
                cell={cell}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onHandleSort={onHandleSort}
                headerTextStyle={headerTextStyle}
              />
            )
          })}
        </tr>
      </thead>
      <tbody>
        {(!sortedEntries || !sortedEntries.length) && (
          <tr>
            <td colSpan={columns.length}>
              <div>
                {noRowsContent ? (
                  <Fragment>{noRowsContent}</Fragment>
                ) : (
                  <Banner inline>No entries found</Banner>
                )}
              </div>
            </td>
          </tr>
        )}
        {sortedEntries && sortedEntries.map((rowData, index) => {
          return (
            <tr
              className={classnames(
                'vs-u-table__row',
                {[rowClassName]: rowClassName && rowClassNameCondition(rowData)},
                {'vs-u-table__row--clickable': !!onRowClick},
                {'vs-u-table__row--status-warning': withStatus && rowData.status === 'warning'},
                {'vs-u-table__row--status-attention': withStatus && rowData.status === 'attention'},
                {'vs-u-table__row--status-success': withStatus && rowData.status === 'success'}
              )}
              key={rowData._id || rowData.id || index}
              onClick={() => onRowClickTriggered(rowData)}
            >
              {columns.map(cell => {
                const cellData = cell.cellDataGetter ? cell.cellDataGetter({ rowData }) : rowData[cell.key]
                let cellChildren = cellData
                if (cell.cellRenderer) {
                  cellChildren = cell.cellRenderer({ cellData })
                }
                return (
                  <td
                    key={cell.dataKey}
                    className={classnames(
                      'vs-u-table__cell',
                      {'vs-u-table__cell--right': cell.align === 'right'},
                      {'vs-u-table__cell--center': cell.align === 'center'},
                      {'vs-u-table__cell--ellipsis': cell.ellipsis},
                      cell.cellClassName
                    )}
                  >
                    {cellChildren}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

Table.propTypes = {
  /**
   * The column definitions
   */
  columns: PropTypes.arrayOf(PropTypes.shape({
    dataKey: PropTypes.string.isRequired, //
    key: PropTypes.string.isRequired, // The property to find results by in the object
    disableSort: PropTypes.bool, // Will remove sorting widget on the header
    header: PropTypes.string, // header for column
    hideHeader: PropTypes.bool, // hides column header
    headerClassName: PropTypes.string, // additional class for the header cell
    cellClassName: PropTypes.string, // additional class for the body cell
    align: PropTypes.oneOf(['center', 'right', null]), // will align both header and body cells in column
    ellipsis: PropTypes.bool, // will crop text if the width is added
    width: PropTypes.number, // will restrict the minimum width for the column
    headerTextStyle: PropTypes.oneOf(['uppercase', 'lowercase']) // Will put header in all uppercase or lowercase
  })),

  /**
   * The data entries for table rows
   */
  entries: PropTypes.array.isRequired,

  /**
   * Optional style version for the table
   */

  styleVariant: PropTypes.oneOf(['regular', 'deemphasized']),

  /**
   * Optional custom sorting
   */
  sorterFunction: PropTypes.func,

  /**
   * The column to `sortBy`
   */
  sortBy: PropTypes.string,

  /**
   * The sort direction (SORT_DIRECTON.ASC|SORT_DIRECTON.DESC)
   *
   */
  sortDirection: PropTypes.oneOf([
    SORT_DIRECTON.ASC,
    SORT_DIRECTON.DESC
  ]),

  /**
   * A content to display in case there are no entries.
   */
  noRowsContent: PropTypes.node
}

Table.defaultProps = {
  sortBy: null,
  sortDirection: SORT_DIRECTON.ASC,
  noRowsBannerContent: 'No entries found',
  rowClassNameCondition: (row) => row,
  onHandleSort: () => {},
  onRowClick: null,
  styleVariant: 'regular',
  headerTextStyle: 'uppercase'
}

export default Table
