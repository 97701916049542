import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { DeemphasizedLink, FilterInput } from '@fielded/shared-ui'
import { Checkmark, CheckmarkCircle, Chevron, Issue } from '@fielded/shared-ui/src/icons'
import { filterByProp } from '@fielded/shared-ui/src/components/FilterInput'

import { pluralize } from '../../../van-shared/utils'

class ProductLineFilter {
  constructor (productLines) {
    this.productLines = productLines
  }

  filter (filterValue) {
    if (!filterValue || filterValue === '') return this.productLines

    return this.productLines.reduce((productLines, productLine) => {
      const matchingProducts = filterByProp(productLine.products, filterValue)
      if (matchingProducts.length) {
        return [
          ...productLines,
          {
            ...productLine,
            products: matchingProducts,
            hasMatches: true
          }
        ]
      }
      return productLines
    }, [])
  }
}

const ANIMATION_DURATION = 0.4

const Product = ({
  product,
  active,
  isComplete,
  markComplete,
  hasIssue,
  index,
  navigationIndex,
  onClick
}) => {
  const [values, setValues] = useState({
    activeIndex: navigationIndex,
    previousIndex: 0
  })
  const { activeIndex, previousIndex } = values

  // const shouldFlipAnimation = activeIndex <= previousIndex
  const shouldFlipAnimation = previousIndex <= activeIndex

  const isCountCompleted = !hasIssue && markComplete && isComplete
  const animationDelay = `${(ANIMATION_DURATION * index) / 10}s`
  const classes = classnames(
    'product-list__item',
    {'product-list__item--active': active},
    {'product-list__item--active-flip': shouldFlipAnimation},
    {'product-list__item--approved': markComplete && isComplete},
    {'product-list__item--hasissue': markComplete && hasIssue}
  )

  let productInfo = `${product.name}`

  const onUpdateValues = () => {
    setValues(previousValues => ({
      activeIndex: navigationIndex,
      previousIndex: previousValues.activeIndex
    }))
  }

  const onHandleClick = () => {
    onClick(navigationIndex)
  }

  useEffect(() => {
    onUpdateValues()
  }, [navigationIndex])

  return (
    <li className={classes} style={{ animationDelay }}>
      <button
        className={classnames(
          'vs-u-justify-spaced',
          'product-list__button',
          {'product-list__button--active': active}
        )}
        onClick={onHandleClick}
      >
        <span className='vs-u-row'>
          <span className={classnames(
            'vs-u-align-baseline',
            'product-list__icon-name',
            {'product-list__icon-name--show': !hasIssue || !isCountCompleted}
          )}>
            {hasIssue && <Issue />}
            {isCountCompleted && <CheckmarkCircle />}
          </span>

          <span className='product-list__name'>{productInfo}</span>
        </span>

        {product.basicUnit ? (
          <span className={classnames(
            'product-list__unit',
            {'product-list__unit--complete': isCountCompleted && !active}
          )}>
            {product.basicUnit}
          </span>
        ) : null}
      </button>
    </li>
  )
}

const findIndexProductId = (productId, productList) => productList.findIndex(product => product._id === productId)

const filterProductsByValue = (productFilterValue, productLines) => {
  const filterableProductLines = new ProductLineFilter(productLines)
  const filteredProductLines = filterableProductLines.filter(productFilterValue)
  const filteredProducts = filteredProductLines.reduce((products, productLine) => ([
    ...products,
    ...(productLine ? productLine.products : [])
  ]), [])

  return {
    productFilterValue,
    filteredProductLines,
    filteredProducts
  }
}

const Sidebar = ({
  productLines,
  productList,
  activeProductId,
  stock,
  onProductNavigation,
  markComplete
}) => {
  const initialValues = filterProductsByValue('', productLines)

  const [productsNavigationVisible, setProductsNavigationVisible] = useState(false)
  const [values, setValues] = useState({
    productFilterValue: initialValues.productFilterValue,
    filteredProductLines: initialValues.filteredProductLines,
    filteredProducts: initialValues.filteredProducts
  })

  const {
    productFilterValue,
    filteredProductLines,
    filteredProducts
  } = values

  const toggleproductsNavigationVisibility = () => {
    setProductsNavigationVisible(!productsNavigationVisible)
  }

  const filterValueChange = productFilterValue => {
    const values = filterProductsByValue(productFilterValue, productLines)
    setValues({ ...values })
  }

  const goToProduct = navigationIndex => {
    onProductNavigation(navigationIndex)
    toggleproductsNavigationVisibility()
  }

  const activeProductIdIndex = findIndexProductId(activeProductId, productList)

  // On VAN not all entries from products are represented in the printed product line (there is only one).
  // HIV has multiple product lines which each has X amount of products.
  // We therefore cannot rely on product list length to count how many products are actually shown,
  // but must sum up the total amount of products from the product lines.
  const totalProducts = productLines.reduce((accumulator, productLine) => {
    let total = accumulator
    if (productLine.products) {
      total = accumulator + productLine.products.length
    }
    return total
  }, 0)

  return (
    <div className='product-lines'>
      <div className='product-lines__title-wrapper'>
        <h2 className='product-lines__title'>{totalProducts} products</h2>
        {!productsNavigationVisible && (
          <span className='product-lines__show-button'>
            <DeemphasizedLink
              icon={<Chevron direction='down' />}
              iconSide='right'
              onClick={toggleproductsNavigationVisibility}
            >
              Show product list
            </DeemphasizedLink>
          </span>
        )}

        {productsNavigationVisible && (
          <span className='product-lines__show-button'>
            <DeemphasizedLink
              icon={<Chevron direction='up' />}
              iconSide='right'
              onClick={toggleproductsNavigationVisibility}
            >
              Hide product list
            </DeemphasizedLink>
          </span>
        )}
      </div>

      <div
        className={classnames(
          'product-list-container',
          {'product-list-container--expanded': productsNavigationVisible}
        )}
      >
        <div className='product-lines__filter'>
          <FilterInput
            value={productFilterValue}
            onValueChange={filterValueChange}
          />

          {filteredProducts.length !== totalProducts && (
            <p>Showing <strong>{filteredProducts.length} {pluralize('product', filteredProducts.length)}</strong></p>
          )}
        </div>

        {filteredProductLines.map(({ name, products, hasMatches }) => {
          // filteredProductLines is duplicated on component state
          // therefore might not be updated when the active/complete status changes
          const { active, complete } = productLines.find(p => p.name === name) || {}
          const showElement = active || hasMatches

          const goToProductGroup = () => {
            onProductNavigation(findIndexProductId(products[0]._id, productList) - activeProductIdIndex)
          }

          return (
            <ul className='product-list' key={name}>
              <li
                className={classnames(
                  'product-list__item-container',
                  {'product-line--active': active},
                  {'product-list__item--approved': markComplete && complete}
                )}
              >
                <button
                  className='product-list__button--group'
                  onClick={goToProductGroup}
                >
                  <span className='icon-container__allsubmitted'>
                    {markComplete && complete && <Checkmark />}
                  </span>

                  <span className={classnames(
                    'product-list__item-wrapper',
                    {'product-list__item-wrapper--active': active}
                  )}>
                    <span className='product-list__item-wrapper-name'>{name}</span>
                    <span className='product-list__item-wrapper-icon'>
                      <Chevron direction={active ? 'up' : 'down'} />
                    </span>
                  </span>
                </button>

                {showElement && (
                  <ul className={classnames(
                    'product-list',
                    'product-list__wrapper',
                    {'product-list__wrapper--active': active}
                  )}>
                    {products.map((product, index) => {
                      const navigationIndex = findIndexProductId(product._id, productList) - activeProductIdIndex
                      return (
                        <Product
                          key={product._id}
                          product={product}
                          active={product._id === activeProductId}
                          isComplete={stock[product._id].progress.isComplete}
                          hasIssue={stock[product._id].progress.hasIssue}
                          markComplete={markComplete}
                          onClick={goToProduct}
                          navigationIndex={navigationIndex}
                          index={index}
                        />
                      )
                    })}
                  </ul>
                )}
              </li>
            </ul>
          )
        })}
        <span className='product-lines__hide-button'>
          <DeemphasizedLink
            icon={<Chevron direction='up' />}
            iconSide='right'
            onClick={toggleproductsNavigationVisibility}
          >
            Hide Product List
          </DeemphasizedLink>
        </span>
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  productLines: PropTypes.arrayOf(PropTypes.object).isRequired,
  productList: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeProductId: PropTypes.string.isRequired,
  onProductNavigation: PropTypes.func.isRequired,
  stock: PropTypes.object.isRequired
}

Product.propTypes = {
  product: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default Sidebar
