import React from 'react'
import { batchIdToProductId } from '../common/utils'

const getPerBatchQuantity = (products, counts) => {
  const batches = Object.keys(counts)
  const perProduct = {}
  batches.forEach((batch) => {
    const quantity = counts[batch].quantity
    const batchId = batch.split(':').pop()
    const product = products.find(({ _id }) => batchIdToProductId(batch) === _id)
    perProduct[product._id] = perProduct[product._id] || {}
    perProduct[product._id].name = product.fullName
    perProduct[product._id].batches = perProduct[product._id].batches || []
    perProduct[product._id].batches.push({ quantity, batchId })
  })
  return perProduct
}

const ShipmentProductLines = ({ products, counts }) => {
  const perProductBatches = getPerBatchQuantity(products, counts)
  const perProductKeys = Object.keys(perProductBatches)
  return (
    <div className='shipment__card-product-line-wrapper'>
      <div className='shipment__card-product-line-header'>
        <span>Quantity</span>
      </div>
      {perProductKeys.map((key) => {
        const productBatches = perProductBatches[key]
        return (
          <div className='shipment__card-product-wrapper'>
            <span className='shipment__card-product-name'>{productBatches.name}</span>
            <ul className='shipment__card-product-list'>
              {productBatches.batches.map((line) => <li key={line.batchId} className='shipment__card-product-list-item'><span>#{line.batchId}</span><span>{line.quantity}</span></li>)}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default ShipmentProductLines
