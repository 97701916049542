const TransactionsAdapter = require('./transactions-adapter')
const {filterTransactions, isPaymentPlan} = require('./tools')
const {MATCHING_STATUSES, FAILED_MATCHING_REASONS, BOOKING_STATUSES, MAX_REMITA_FEE, FAILED_BOOKING_REASONS} = require('./constants')

class PaymentReconciliation {
  constructor (
    state,
    logger,
    pgConnection,
    paymentApi,
    paymentPlansApi,
    quickbooksApi
  ) {
    this.paymentApi = paymentApi
    this.paymentPlansApi = paymentPlansApi
    this.logger = logger
    const { user = {} } = state
    const username = user.name
    if (pgConnection) {
      this.transactions = new TransactionsAdapter(
        pgConnection,
        username
      )
      this.quickbooksAdapter = quickbooksApi
    }
  }

  matchFailed (id, reason) {
    return this.transactions.update({
      matching_status: MATCHING_STATUSES.notMatched,
      failed_reason: reason,
      id: id
    })
  }

  async matchPaymentPlan (paymentDebit, transaction) {
    try {
      const paymentInstallment = await this.paymentPlansApi.installmentAdapter.getOne(paymentDebit.reference, { whereCondition: 'code' })
      const paymentPlan = await this.paymentPlansApi.paymentPlanAdapter.getOne(paymentInstallment.payment_plan_id, { whereCondition: 'id' })
      return this.transactions.update({
        matching_status: MATCHING_STATUSES.matched,
        matched_data: { paymentInstallment, paymentPlan },
        id: transaction.id
      })
    } catch (e) {
      return this.matchFailed(transaction.id, FAILED_MATCHING_REASONS.noPaymentPlan)
    }
  }

  async matchInvoice (paymentDebit, transaction) {
    try {
      const {results: quickbooksInvoice} = await this.quickbooksAdapter.transactions.getList({
        ordering: '-created_at',
        filter: {
          txn_type: 'invoice',
          quickbooks_doc_number: paymentDebit.reference
        }
      })
      if (!quickbooksInvoice.length) {
        return this.matchFailed(transaction.id, FAILED_MATCHING_REASONS.invoiceNotFound)
      }
      if (quickbooksInvoice.length > 1) {
        return this.matchFailed(transaction.id, FAILED_MATCHING_REASONS.multipleLocationWithSameCode)
      }
      if (quickbooksInvoice[0].amount === quickbooksInvoice[0].amount_paid) {
        return this.matchFailed(transaction.id, FAILED_MATCHING_REASONS.invoicePaid)
      }
      // this should never happen
      if (quickbooksInvoice[0].amount < quickbooksInvoice[0].amount_paid) {
        return this.matchFailed(transaction.id, FAILED_MATCHING_REASONS.invalidInvoice)
      }
      return this.transactions.update({
        matching_status: MATCHING_STATUSES.matched,
        matched_data: {paymentDebit, quickbooksInvoice: quickbooksInvoice[0]},
        id: transaction.id
      })
    } catch (e) {
      this.logger.warn(`Failed match transactions-invoice with error:`, e)
      return this.matchFailed(transaction.id, FAILED_MATCHING_REASONS.exceptionError)
    }
  }

  async bookPaymentPlanTransaction ({
    paymentPlan,
    paymentInstallment,
    transaction
  }) {
    try {
      const { deposit } = await this.quickbooksAdapter.createPaymentPlanDeposit({
        transaction,
        paymentPlan,
        paymentInstallment
      })
      await this.transactions.update({
        booking_status: BOOKING_STATUSES.booked,
        failed_reason: null,
        quickbooks_deposit_data: deposit,
        id: transaction.id
      })
    } catch (error) {
      await this.transactions.update({
        booking_status: BOOKING_STATUSES.notBooked,
        failed_reason: error,
        id: transaction.id
      })
      this.logger.warn(`Failed book payment plan ${transaction.id}`, error)
    }
  }

  async bookInvoiceTransaction ({
    transaction,
    quickbooksInvoice
  }) {
    try {
      const totalAmount = (quickbooksInvoice.amount - quickbooksInvoice.amount_paid)
      const remitaFee = (transaction.amount - totalAmount)

      if (remitaFee <= MAX_REMITA_FEE) {
        return this.transactions.update({
          booking_status: BOOKING_STATUSES.notBooked,
          failed_reason: FAILED_BOOKING_REASONS.remitaFeeExceeded,
          id: transaction.id
        })
      }

      const {
        payment,
        quickbooksLocation,
        qboCustomer,
        depositAccount
      } = await this.quickbooksAdapter.createPayment({
        quickbooksInvoice,
        totalAmount
      })

      const {deposit} = await this.quickbooksAdapter.createPaymentInvoiceDeposit({
        transaction,
        quickbooksInvoice,
        qboCustomer,
        quickbooksLocation,
        depositAccount,
        payment,
        totalAmount,
        remitaFee
      })
      await this.transactions.update({
        quickbooks_deposit_data: deposit,
        booking_status: BOOKING_STATUSES.booked,
        id: transaction.id
      })
      const {payment: finalPayment} = await this.quickbooksAdapter.updatePayment({
        quickbooksInvoice,
        payment,
        totalAmount
      })

      await this.transactions.update({
        quickbooks_payment_data: finalPayment,
        booking_status: BOOKING_STATUSES.booked,
        id: transaction.id
      })
    } catch (error) {
      await this.transactions.update({
        booking_status: BOOKING_STATUSES.notBooked,
        failed_reason: error,
        id: transaction.id
      })
      this.logger.warn(`Failed book transaction ${transaction.id}`, error)
    }
  }

  async doMatch (transaction) {
    try {
      const paymentDebit = await this.paymentApi.paymentDebit.getOne(transaction.transactionRef, { whereCondition: `"data"->'remitaResponse'->'body'->'transactionRef'` })
      if (isPaymentPlan(paymentDebit.reference)) {
        return this.matchPaymentPlan(paymentDebit, transaction)
      }
      return this.matchInvoice(paymentDebit, transaction)
    } catch (e) {
      return this.matchFailed(transaction.id, FAILED_MATCHING_REASONS.paymentDebitNotFound)
    }
  }

  async match () {
    const {results: transactions} = await this.transactions.getList({
      ordering: '-created_at',
      filter: {
        matching_status: null
      }
    })
    const {matchable, notMatchable} = filterTransactions(transactions)
    await Promise.all([
      ...matchable.map((val) => this.doMatch(val)),
      // we can immediately update transactions which are not matched
      ...notMatchable.map(val => this.matchFailed(val.id, FAILED_MATCHING_REASONS.unknownNarration))
    ])

    return true
  }
}

module.exports = PaymentReconciliation
