const endOfDay = require('date-fns/end_of_day')
const { EntityApi, PouchAdapter } = require('../common')
const { wrapEntityApi } = require('../utils/wrap-api')
const { takeLatest } = require('./tools/read/generic-utils')
const toEntity = require('./tools/read/to-entity')
const { addCalculations } = require('./tools/read/calculate')
const { addAllocations, unsubscribe, unsubscribeAll, setAllToPAYG } = require('./api/add')

const rawMethods = {
  addConfiguration: addAllocations,
  getConfiguration: require('./api/get'),
  importConfigurations: require('./api/import'),
  exportConfigurations: require('./api/export'),
  getOfftrend: require('./api/offtrend-get'),
  addOfftrend: require('./api/offtrend-add'),
  unsubscribe,
  unsubscribeAll,
  setAllToPAYG
}

// TODO
const schema = {
  type: 'object',
  name: 'allocation',
  properties: {},
  additionalProperties: true,
  required: []
}

class AllocationDataAdapter extends PouchAdapter {
  async list (options) {
    let {
      date,
      facilityIds,
      raw
    } = options

    const selector = {
      _id: { $regex: '^allocation' }
    }

    if (facilityIds) {
      selector['facilityId'] = { $in: [...new Set(facilityIds)] }
    }

    let docs
    if (facilityIds && facilityIds.length === 1) {
      let endDate
      if (typeof date === 'string') {
        // See comment below on the selector section
        endDate = endOfDay(new Date(date)).toJSON()
      } else {
        endDate = '{}'
      }

      const rows = await this.pouchDB.allDocs({
        startkey: `allocation:${facilityIds[0]}:date`,
        endkey: `allocation:${facilityIds[0]}:date:${endDate}`,
        include_docs: true
      })

      docs = rows.rows.map(r => r.doc)
    } else if (!facilityIds && !date) {
      const rows = await this.pouchDB.allDocs({
        startkey: `allocation:`,
        endkey: `allocation:{}`,
        include_docs: true
      })
      docs = rows.rows.map(r => r.doc)
    } else {
      // In v1.0.0 allocation config docs `date` contains a date in the format
      // YYYY-MM-DD. In v0.1.0 docs however, `date` contains an ISO date string
      // with a random time, that should be ignored since allocation configs
      // always apply from the beginning of the given day.
      //
      // Because of these old allocation configs we need to pass a full ISO date
      // string to the `$lte` selector, and it needs to refer to the end of the
      // day, so that we find all relevant allocation regardless of if they include
      // a random time in their `date` param.
      if (date) {
        selector['date'] = { $lte: endOfDay(new Date(date)).toJSON() }
      }

      docs = await super.list({ selector })
    }

    const entities = docs.map(doc => toEntity(doc, { normalize: !raw }))

    // toEntity takes care of translating `v0.1.0` docs to `v1.0.0`.
    // `takeLatest` should be called after the translation.
    if (date) {
      return takeLatest(entities)
    }

    return entities
  }

  async listByKey (options) {
    const {
      key,
      raw
    } = options
    const {rows} = await this.pouchDB.allDocs({
      startkey: key,
      endkey: `${key}\ufff0`,
      include_docs: true
    })
    return rows.filter(row => row.doc).map(row => toEntity(row.doc, { normalize: !raw }))
  }
}

class AllocationApi extends EntityApi {
  constructor (state) {
    const { user, allocationsDB } = state
    const adapter = new AllocationDataAdapter(user, schema, allocationsDB)
    super(adapter)

    // TODO: We can remove this when all raw methods have been ported.
    // `allocationApi` is used to access class functions inside the raw methods.
    // `state` is required as a first param for the raw methods.
    const apiMethods = wrapEntityApi(rawMethods, Object.assign({}, state, { allocationApi: this }))
    Object.assign(this, apiMethods)
  }

  async getAllConfigurations (options = {}) {
    const configs = await this.adapter.list(options)

    if (options.includeDemandPlan) {
      configs.forEach(addCalculations)
    }

    return configs
  }
}

module.exports = AllocationApi
