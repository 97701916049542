import React from 'react'

const defaultState = { api: null }

const ApiContext = React.createContext(defaultState)

export default (props) => (
  <ApiContext.Provider value={props}>
    {React.Children.map(props.children, c => c)}
  </ApiContext.Provider>
)

export const withApi = (WrappedComponent) => (props) => (
  <ApiContext.Consumer>
    {ctxValue => <WrappedComponent {...props} api={ctxValue.api} />}
  </ApiContext.Consumer>
)
