module.exports = fromWarehouseUnits

const get = require('lodash/get')
const set = require('lodash/set')

const { REPORT_BALANCE_FIELD } = require('../../tools/utils/constants')

/*
 * PSM Warehouses report in 'warehouse units'
 * which is a representation of how many boxes
 * they have rather than how many pills/doses/single units
 */
function fromWarehouseUnits (stock, products) {
  const productIds = Object.keys(stock)
  const newStock = {}

  productIds.forEach(productId => {
    const productDoc = products.find(p => p._id === productId)

    const batches = get(stock, `${productId}.batches`)

    // Bail on unbatched counts, right now all warehouses have batches
    if (!batches) {
      newStock[productId] = stock[productId]
      return
    }

    const genericFactor = get(productDoc || {}, 'genericFactor')
    // GenericFactor has to be an int, otherwise there's something weird going on
    if (typeof genericFactor !== 'number') {
      console.warn(`No valid genericFactor not found for ${productId} - ${genericFactor}`)
      newStock[productId] = stock[productId]
      return
    }

    // Translate batches:
    const batchIds = Object.keys(batches)
    const newBatches = {}
    batchIds.forEach(batchId => {
      const translatedValue = get(batches[batchId], REPORT_BALANCE_FIELD) * genericFactor
      if (!isNaN(translatedValue)) {
        // there are one or two decimal generic factors
        // PSM has asked the number to be floored when multiplying with those
        // https://github.com/fielded/fs-api/pull/385#discussion_r353224339
        set(newBatches, `${batchId}.${REPORT_BALANCE_FIELD}`, Math.floor(translatedValue))
      }
    })

    // Translate commits:
    const commits = get(stock, `${productId}.commits`)
    let newCommits = null
    if (commits) {
      newCommits = {}
      Object.keys(commits).forEach(commitKey => {
        const readKey = `${commitKey}.amount`
        const amount = get(commits, readKey)
        set(newCommits, readKey, amount * genericFactor)
      })
    }

    newStock[productId] = {
      batches: newBatches,
      commits: newCommits
    }
  })

  return newStock
}
