const { listChildren } = require('./list-children')
const {list: listAllRoutes} = require('../../../routes')
const {getAll: listAllForProgram} = require('../../../service')
const {decorateAdminLocations} = require('../../tools/decorate-admin-locations')

exports.listAdminLocations = listAdminLocations
async function listAdminLocations (state) {
  const date = new Date().toJSON()
  const programId = 'program:shelflife'

  let locations = await listChildren(
    state, state.user.location.id, {deep: true, includeSelf: true}
  )

  if (state && state.user.roles && state.user.roles.includes('feature:userRole:external-planner')) {
    locations = locations.filter(l => l.level !== 'supplier')
  }

  const routes = await listAllRoutes(state)
  const services = await listAllForProgram(state, programId)
  return decorateAdminLocations(locations, routes, services, programId, date)
}
