import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

const DueDate = ({ program }) => {
  const dueDate = get(program, 'periods.active.displayDue')
  return (
    <div className='due-date'>
      Next report due: {dueDate ? (
        <span className='due-date__date'>{dueDate}</span>
      ) : (
        <span>No set date</span>
      )}
    </div>
  )
}

DueDate.propTypes = {
  /**
   * The program object, as returned from api, containing reporting periods.
   */
  program: PropTypes.object
}

export default DueDate
