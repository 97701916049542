module.exports = async function (state, locationId, date) {
  const {ledgerDB, remoteLedgerDB} = state
  // Step 1. Check if our current ledger balance is older than the date asked:
  const latestLedger = await ledgerDB.get(locationId)
    // If we don't have this, let this cascade to search online instead
    .catch(e => { return null })

  // an offlineLedger that is OLDER than the date we asked for is considered OK
  // (remember that this was the latest ledger available the last time we were able to sync)
  if (validLedger(latestLedger, date)) {
    return latestLedger // docToStockCountRecord here?
  }

  // if not, lets look if we have anything else saved locally:
  const olderOfflineLedger = await ledgerDB
    .allDocs({
      startkey: `${locationId}:date:${date}`,
      endkey: `${locationId}:date:`,
      descending: true,
      limit: 1,
      include_docs: true
    })
    .then(pluckDocs)
    .then(ledgers => {
      // Give us the LATEST ledger that is valid for this things we have locally
      return ledgers[0]
    })

  if (olderOfflineLedger) {
    return olderOfflineLedger
  }

  if (!remoteLedgerDB) {
    return offlineError()
  }

  // We have not found an offline ledger, we have to go online:
  return remoteLedgerDB
    .allDocs({
      startkey: `${locationId}:date:${date}`,
      endkey: `${locationId}:date:`,
      descending: true,
      limit: 1,
      include_docs: true
    })
    .then(pluckDocs)
    .then(remoteLedgers => {
      return remoteLedgers[0]
    })
    .then(ledger => {
      if (!ledger) {
        // no ledger found, maybe you were asking for really old stuff
        return null
      }
      // Here, we can optionally save the ledger with a 'fetched' timestamp to our local ledger-balances DB,
      // then we can have a bot that wipes those when the the local ledger-balances DB gets too large
      ledgerDB.bulkDocs([Object.assign({}, ledger, {fetched: new Date().toJSON()})], { new_edits: false })

      // return online doc (docToStockCountRecord?)
      return ledger
    })
    .catch(e => {
      // Catch any network errors here
      // TODO: activate this for online/offline behavior
      // throw offlineError()
      // but we're not ready for that yet, so lets just swallow all errors and
      // give 'no ledger found' back
      return null
    })
}

function validLedger (ledger, date) {
  return ledger && ledger.submittedAt <= date
}

function offlineError () {
  const e = new Error('This data is not available offline')
  e.statusCode = 0
  // This prop is so that the UI can catch this and render a 'this view is not available offline' message
  e.notAvailableOffline = true
  return e
}

function pluckDocs (res) {
  return res.rows.map(res => res.doc)
}
