module.exports = getBatch

const batchId = require('../../utils/batch-id')
const {get: getProduct} = require('./../../product')

async function getBatch (state, data, {resolveAlias = null} = {}) {
  let {productId} = data

  if (resolveAlias) {
    const product = await getProduct(state, productId, {resolveAlias})
    productId = product._id
  }

  const id = batchId(Object.assign({}, data, {productId}))
  return state.masterDataDB.get(id)
}
