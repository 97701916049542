import React from 'react'
import PropTypes from 'prop-types'

import { CSSTransition } from 'react-transition-group'

import { Button, Prompt } from '@fielded/shared-ui'

const MainMenuLogoutPrompt = ({
  appName,
  logoutStep,
  triggerAnimation,
  onCancelLogout,
  onLogout
}) => {
  return (
    <CSSTransition in={triggerAnimation} timeout={0}>
      <div>
        {logoutStep === 'confirm' && (
          <Prompt
            title='Are you sure?'
            body={`Logging out will clear your cache of ${appName} data, including any unsubmitted drafts.`}
            footer={<div className='main-menu__actions'>
              <Button
                fill='outline'
                colorVariant='light'
                size='regular'
                onClick={onCancelLogout}
              >
              Cancel
              </Button>
              <Button
                fill='outline'
                colorVariant='light'
                size='regular'
                onClick={onLogout}
              >
              Yes, log out
              </Button>
            </div>
            }
          />

        )}
        {logoutStep === 'block' && (
          <Prompt
            title='Cannot log out'
            body={`${appName} is still updating. Please wait a few moments until everything is up to date and then try again.`}
            footer={<Button
              fill='outline'
              colorVariant='light'
              size='regular'
              onClick={onCancelLogout}
            >
              Close
            </Button>}
          />
        )}
        {logoutStep === 'loggingOut' && (
          <Prompt
            title='Logging out'
            body={`Please wait a moment while ${appName} clears local data.`}
            footer={<Button
              fill='outline'
              colorVariant='light'
              size='regular'
              loading
            >
              Logging out
            </Button>}
          />
        )}
      </div>
    </CSSTransition>
  )
}

MainMenuLogoutPrompt.propTypes = {
  /**
   * Name of the current app taken from config
   */
  appName: PropTypes.string.isRequired,

  /**
   * The current logout step
   */
  logoutStep: PropTypes.string,

  /**
   * Whether or not the animation should kick in
   */
  isAnimating: PropTypes.bool
}

export default MainMenuLogoutPrompt
