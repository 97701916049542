module.exports = { getShipmentTypes, getShipmentType }

// TODO: this should come from an app config, but that would mean
// passing an app config everywhere we get shipments. When the app
// config becomes a couch document this can be done in the API
function getShipmentTypes () {
  return {
    routine: {
      id: 'routine',
      name: 'Routine',
      abbreviation: 'RI'
    },
    'campaign:sia': {
      id: 'campaign:sia',
      name: 'Campaign',
      abbreviation: 'SIA'
    }
  }
}

function getShipmentType (shipmentTypeId) {
  const defaultTypes = getShipmentTypes()

  // If a VAN shipment type, return it
  if (defaultTypes[shipmentTypeId]) return defaultTypes[shipmentTypeId]

  // If a PSM shipment type and not a funder specific one
  if (shipmentTypeId === 'allocated:funder:all-funders') {
    return {
      id: shipmentTypeId,
      name: 'Shipment',
      abbreviation: 'AF'
    }
  }

  // If a funder specific PSM type
  if (shipmentTypeId.startsWith('allocated:')) {
    return {
      id: shipmentTypeId,
      name: shipmentTypeId.replace('allocated:', ''),
      abbreviation: 'FUNDER'
    }
  }

  if (shipmentTypeId.startsWith('topup')) {
    return {
      id: 'topup',
      name: 'Topup',
      abbreviation: 'TP'
    }
  }
}
