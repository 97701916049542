const { MEMBERSHIPS } = require('../../location/tools')

module.exports.MEMBERSHIP_TYPE_LABELS = {
  [MEMBERSHIPS.BASIC]: 'Basic',
  [MEMBERSHIPS.CLASSIC]: 'Classic',
  [MEMBERSHIPS.CLASSIC_PAYG_ONLY]: 'Classic (PAYG only)'
}

module.exports.PRODUCT_ACCESS_TYPES = {
  PPMV: 'ppmv',
  PHARMACY: 'pharmacy',
  CLINICAL: 'clinical'
}

module.exports.MOBILIZATION_LEVEL_LABELS = {
  blocked: 'Defaulting/Blocked'
}

module.exports.isUuid = str =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(str)
