import React, { Component } from 'react'

import { Button, DeemphasizedLink, ExportButton, ImportButton, Wizard } from '@fielded/shared-ui'
import { Checkmark } from '@fielded/shared-ui/src/icons'
import { arrayBufferFileReader } from '@fielded/shared-ui/src/components/ImportButton/ImportButton'

const EXPORT_FILE_LABEL = 'Shipments Excel File'

const ACCEPTED_FILE_TYPES = [
  'application/vnd.ms-excel',
  'application/wps-office.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xlsx'
]

export default class ShipmentsImport extends Component {
  state = { showPreview: false }

  render () {
    const {
      loading,
      history,
      error,
      currentStep,
      onExport,
      onExported,
      onError,
      onImported,
      onConfirm,
      onCancel,
      previewData
    } = this.props

    const { showPreview } = this.state

    return (
      <Wizard
        currentStep={currentStep}
        error={error}
        errorRenderer={({ error }) => (
          <Wizard.Error
            error={error}
            bodyMessage={error.invalidRows && <pre>{JSON.stringify(error.invalidRows, null, 2)}</pre>}
            title={`Step 1: Error importing ${EXPORT_FILE_LABEL}`}
            actions={(
              <React.Fragment>
                {/* after getting an error,
                  clicking import again would not call handle import in the container.
                  Workaround, just have the user start over with cancel.
                  */}
                {!error && (
                  <ImportButton
                    accept={ACCEPTED_FILE_TYPES}
                    loading={loading}
                    reader={arrayBufferFileReader}
                    onError={onError}
                  />
                )}
                <DeemphasizedLink
                  onClick={onCancel}
                >
                  Try Again
                </DeemphasizedLink>
              </React.Fragment>
            )}
          />
        )}
      >
        <Wizard.Step name='import'>
          <Wizard.Step.Header title={`Import ${EXPORT_FILE_LABEL}`} />
          <Wizard.Step.Content>
            <p>Import your Excel file. Press the button below to find the file.</p>
          </Wizard.Step.Content>
          <Wizard.Step.Actions>
            <ExportButton
              buttonText='Export Shipment Template'
              loading={loading}
              filename={`shipments-${new Date().toJSON()}.xlsx`}
              onExport={onExport}
              onExported={onExported}
            />
            <ImportButton
              buttonText='Import'
              loading={loading}
              onImported={onImported}
              onError={onError}
              reader={arrayBufferFileReader}
              accept={ACCEPTED_FILE_TYPES}
            />
          </Wizard.Step.Actions>
        </Wizard.Step>
        <Wizard.Step name='confirm'>
          <Wizard.Step.Header title='Step 3: Review and confirm' />
          <Wizard.Step.Content>
            <div>
              <div>
                No errors found in import. Importing {previewData.length} shipment(s). <DeemphasizedLink
                  onClick={() => this.setState({ showPreview: !showPreview })}
                >
                  Show technical details
                </DeemphasizedLink>
              </div>
              <div>
                {showPreview && (
                  <pre>{JSON.stringify(previewData, null, 2)}</pre>
                )}
              </div>
              <div>Are you sure you want to proceed?</div>
            </div>
          </Wizard.Step.Content>
          <Wizard.Step.Actions>
            <Button
              colorVariant='brand'
              fill='full'
              loading={loading}
              onClick={onConfirm}
            >
              Confirm
            </Button>
            <DeemphasizedLink
              onClick={onCancel}
            >
              Cancel import
            </DeemphasizedLink>
          </Wizard.Step.Actions>
        </Wizard.Step>
        <Wizard.Step
          status='success'
          name='finished'
        >
          <Wizard.Step.Header
            icon={<Checkmark />}
            title='Step 4: Finished'
          />
          <Wizard.Step.Content>
            <p>Success</p>
          </Wizard.Step.Content>
          <Wizard.Step.Actions>
            <Button
              colorVariant='brand'
              fill='full'
              onClick={() => {
                history.push('/shipments')
              }}
            >
              Go to shipments
            </Button>
            <DeemphasizedLink
              onClick={onCancel}
            >
              Start again?
            </DeemphasizedLink>
          </Wizard.Step.Actions>
        </Wizard.Step>
      </Wizard>
    )
  }
}
