import { shouldTrackCF, cfLogCartAdd, cfLogCartRemove, cfLogCheckout, cfLogPaymentEvent, cfLogSearch, cfLogProductCatalog } from '../cf-adapter'

export const trackCartEvent = ({ user, country, productsMap, productId, cartId, cartValue, qty, orderType, eventType, promo }) => {
  if (!shouldTrackCF(user)) return

  const product = productsMap[productId]

  const eventProps = {
    country,
    cartId,
    orderType,
    product: product,
    quantity: qty,
    cartValue
  }

  if (eventType === 'add') {
    eventProps.promo = promo
    cfLogCartAdd(eventProps)
  }

  if (eventType === 'remove') {
    cfLogCartRemove(eventProps)
  }
}

export const trackCheckoutEvent = ({
  user,
  orderId,
  cartId,
  cartValue,
  orderType,
  products,
  productsMap,
  country,
  paymentChoice,
  isSuccessful, skipPayment
}) => {
  if (!shouldTrackCF(user)) return

  cfLogCheckout({
    orderId: orderId,
    cartId,
    cartValue,
    orderType,
    products,
    productsMap,
    country,
    paymentChoice,
    isSuccessful: true
  })

  cfLogPaymentEvent({
    orderId: orderId,
    paymentId: orderId, // TODO: Needs to be paymentId if any
    paymentAmount: cartValue,
    country: country,
    isCashPayment: skipPayment
  })
}

export const trackSearchEvent = ({
  term,
  filtersInUse,
  results
}) => {
  cfLogSearch({
    queryText: term,
    resultList: results,
    filtersObject: filtersInUse
  })
}

export const updateProductCatalog = ({products}) => {
  cfLogProductCatalog({products})
}
