const getProductsForFollowUp = require('./tools/get-products-for-follow-up')
const createSnapshot = require('./shipment-create-snapshot')

module.exports = createFollowUpShipment
async function createFollowUpShipment (state, shipment) {
  console.log({shipment})

  const negativeChanges = getProductsForFollowUp(shipment)

  const counts = {}
  negativeChanges.forEach(change => {
    counts[change.productId] = {
      paymentType: change.paymentType,
      quantity: 0 - change.diff
    }
  })

  return createSnapshot(state, {
    // default values for: date, status, shipmentNo, shipmentTypeId
    origin: shipment.origin.id,
    destination: shipment.destination.id,
    counts,
    orderId: shipment.orderId,
    routeId: shipment.routeId,
    programId: 'program:shelflife',
    planningType: shipment.planningType,
    dryRun: false
  })
}
