import React from 'react'
import { Banner } from '@fielded/shared-ui'

const ProgramErrorBanner = () => {
  return (
    <Banner inline type='warning'>
      <h2>Program Loading Error</h2>
      <p>
        You are not mapped to any programs. Please contact support for help.
      </p>
    </Banner>
  )
}

export default ProgramErrorBanner
