export default (baseUrl) => (endpoint, config) => {
  const headers = new global.Headers()
  headers.append('Accept', 'application/json;utf-8')
  headers.append('ContentType', 'application/json;utf-8')

  const init = {
    ...config,
    mode: 'cors',
    credentials: 'include'
  }

  const request = new global.Request(`${baseUrl}${endpoint}`)
  return global.window.fetch(request, init)
}
