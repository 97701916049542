const tools = require('../../tools')
const { transformOrders } = require('../../tools/transform-orders-by-destination')

// This is a SL update
exports.updateOrdersOnDestination = async function (
  state, mainApi,
  { groupId, destinationId, productId, finalSupplierValues }
) {
  const snapshots = await state.dal.order.getGroup(state, { groupId })
  const orders = tools.docsToOrderEntities({
    docs: snapshots, status: 'new', useSubOrderId: true
  })
  const ordersBySupplierDestination = orders.reduce((acc, order) => {
    const supplierId = order.supplierId
    const destinationId = order.destinationId

    acc[`${supplierId}-${destinationId}`] = order
    return acc
  }, {})
  const updatedOrders = await Promise.all(
    Object.keys(finalSupplierValues).map(async (supplierId) => {
      const snapshot = await state.dal.order.getForDestinationSupplier(state, { groupId, destinationId, supplierId })
      const updatedSnapshot = tools.updateSnapshot({
        parentSnapshot: snapshot,
        products: { [productId]: { adjusted: finalSupplierValues[supplierId] } },
        user: state.user,
        timestamp: new Date().toJSON()
      })
      await state.dal.order.update(state, updatedSnapshot)
      return tools.docsToOrderEntity({ docs: [updatedSnapshot], status: 'new' })
    })
  )

  updatedOrders.forEach(order => {
    const supplierId = order.supplierId
    const destinationId = order.destinationId
    const search = `${supplierId}-${destinationId}`

    for (let supplier in ordersBySupplierDestination) {
      if (search === supplier) {
        ordersBySupplierDestination[search] = order
      }
    }
  })

  return transformOrders(state, Object.values(ordersBySupplierDestination))
}
