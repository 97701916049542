const validateFunder = require('./../../../utils/validate-funder')
const stringToId = require('../../../utils/string-to-id')

function prepareFunderToSave (funder, username = '') {
  const funderToSave = Object.assign({}, funder, {
    name: funder.name,
    type: funder.type || 'funder',
    version: funder.version || '1.0.0',
    programs: funder.programs || {},
    createdBy: username,
    createdAt: new Date().toISOString()
  })

  if (!funder._id) {
    funderToSave._id = `funder:${stringToId(funder.name)}`
  }

  if (funder._rev) {
    funderToSave.updatedAt = new Date().toJSON()
    funderToSave.updatedBy = username
  } else {
    funderToSave.createdAt = new Date().toJSON()
    funderToSave.createdBy = username
  }
  return funderToSave
}

exports.save = function (state, funder) {
  const username = state.user ? state.user.name : ''
  const doc = prepareFunderToSave(funder, username)
  // do validation after props are normalized
  validateFunder(doc)
  return state.dal.funders.save(state, doc)
}
