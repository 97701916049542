function parseCoordinates (latLng) {
  if (!latLng) {
    return { lat: null, long: null }
  }
  const [lat, long] = latLng.split(',')
  return {
    lat: lat === 'null' ? null : parseFloat(lat),
    long: long === 'null' ? null : parseFloat(long)
  }
}

module.exports = { parseCoordinates }
