import React, { Component } from 'react'

import PlanningQuantities from './PlanningQuantities'

class PlanningQuantitiesContainer extends Component {
  constructor (props) {
    super(props)
    const quantities = {}
    for (const product of this.props.products) {
      if (this.props.quantities != null) {
        quantities[product._id] = this.props.quantities[product._id] || 0
      } else {
        quantities[product._id] = 0
      }
    }
    this.state = { quantities }
  }

  quantityChangeHandler = (productId, quantity) => {
    const quantities = {
      ...this.state.quantities,
      [productId]: quantity || 0
    }

    // We are also setting `product.quantity` here, because that is what
    // is expected by the PlanningQuantities component and that component
    // is used in another place that already works this way.
    const product = this.props.products.find(p => p._id === productId)
    product.quantity = quantity

    this.setState({ quantities })
  }

  submitHandler = () => {
    this.props.onSubmit(this.state.quantities)
  }

  goBackHandler = () => {
    this.props.onGoBack(this.state.quantities)
  }

  render () {
    const {
      origin,
      destination,
      products,
      availableStock
    } = this.props

    return (
      <PlanningQuantities
        title={'Enter quantities for shipment'}
        subTitle={`New shipment from ${origin.name} to ${destination.name}`}
        destination={destination}
        products={products}
        availableStock={availableStock}
        onClickSave={this.submitHandler}
        nextButtonText={'Next'}
        onGoBack={this.goBackHandler}
        onQuantityChange={this.quantityChangeHandler}
      />
    )
  }
}

export default PlanningQuantitiesContainer
