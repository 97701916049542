import get from 'lodash/get'
import _last from 'lodash/last'

import { getReturnShipmentDestinationByState } from '@fielded/fs-api/lib/service/tools/territory-lookup'
import { getShipmentTypes } from '@fielded/fs-api/lib/shipment/tools'
import * as batchTools from '@fielded/fs-api/lib/batch/tools'

import { userIsAuthorised } from '../../../van-shared/utils/auth'
import { toTitleCase } from '../../../van-shared/utils/utils'
import { isReturnShipment, isShipmentStatusComplete } from '../../../common/utils/shipment'

// Allows batchIds with non-URL-safe chars, such as forward slashes.
// Since the other parts of an ID can’t have these chars, we only do encodeURIComponent
// on the batch number, for readability. Otherwise the URLS look like
// "/product%3Aopv%3Amanufacturer%3Abilthoven%3AbatchNo%3A111%2F123/send-confirmation"
// With this, they’re:
// "/product:opv:manufacturer:bilthoven:batchNo:111%2F123/send-confirmation"
// To decode, just use decodeURIComponent() on the whole ID
export const encodeBatchID = (batchID) => {
  let parts = batchID.split(':')
  parts[parts.length - 1] = encodeURIComponent(parts[parts.length - 1])
  return parts.join(':')
}

export const byId = (collection, prop = 'id') => collection
  .reduce((index, element) => {
    index[element[prop]] = element
    return index
  }, {})

export const batchIdToProductId = batchTools.batchIdToProductId

export const preferUnknown = prop => (a, b) => {
  // If neither batch is unknown, don’t interfere with sorting at all
  if (!a.id.match('unknown') && !b.id.match('unknown')) {
    return 0
  }
  if (a.id.match('unknown') && b.id.match('unknown')) {
    return 0
  }
  if (a.id.match('unknown') && !b.id.match('unknown')) {
    return -1
  } else {
    return 1
  }
}

export const isShipmentComplete = (shipment, user) => {
  if (user && isFpUser(user) && shipment.status === 'sent' && isReturnShipment(user, shipment)) {
    return true
  }
  if (shipment.statusType === 'distribution') {
    return isShipmentStatusComplete(shipment.status)
  }

  return shipment.status === 'received'
}

export const isDistributor = user => userIsAuthorised(user, 'feature:distributor')

export const isGlobalUser = user => userIsAuthorised(user, 'feature:global-shipments')

export const isFpUser = user => userIsAuthorised(user, 'feature:userRole:fp')

export const isOperatorUser = user => userIsAuthorised(user, 'feature:userRole:operator')

export const isPlannerUser = user => userIsAuthorised(user, 'feature:userRole:planner')

export const isExternalPlannerUser = user => userIsAuthorised(user, 'feature:userRole:external-planner')

export const isPSMDriver = user => userIsAuthorised(user, 'feature:userRole:psm-driver')

export const isPSMVendor = user => userIsAuthorised(user, 'feature:userRole:psm-vendor')

export const isPSMFacility = user => userIsAuthorised(user, 'feature:userRole:psm-facility')

export const isLastMileDeliveryUser = user => userIsAuthorised(user, 'feature:lastMileDeliveries')

export const isPreAdvice = id => id.indexOf('status:pre-advice') > -1
export const isArrival = statusType => statusType === 'arrival'
export const isDistribution = statusType => statusType === 'distribution'

export const isNew = id => id.indexOf('status:new') > -1

export const appendLocationType = (locationName, config = {}) => {
  if (config.app !== 'van' || locationName.indexOf('Cold Store') !== -1) {
    return locationName
  }

  return `${toTitleCase(locationName)} Cold Store`
}

export const isShelflifePowered = config => config && config.appBase === 'shelflife'
export const isAdminUser = user => userIsAuthorised(user, 'feature:settings')

export const headerLocationFor = (shipment, config, isPSMManualShipment, defaultDestinationId) => {
  const pickDestination = defaultDestinationId || shipment.destination.id
  const destination = toTitleCase(pickDestination.split(':').pop())
  const { statusType } = shipment

  if (statusType === 'distribution' || (statusType === 'arrival' && isShelflifePowered(config)) || isPSMManualShipment) {
    return destination
  }

  if (statusType === 'arrival' && shipment.origin.id === 'country') {
    return 'external'
  }

  return shipment.origin.id.split(':').pop()
}

export const shipmentTitle = (shipment, config, isReturn, defaultDestinationId, defaultOriginId) => {
  const pickOrigin = defaultOriginId || shipment.origin.id
  const origin = toTitleCase(pickOrigin.split(':').pop())
  const isPSMManualShipment = shipment.statusType === 'arrival' && !isShelflifePowered(config)
  const destination = headerLocationFor(shipment, config, isPSMManualShipment, defaultDestinationId)
  if (isReturn) {
    return `Return from ${origin} to ${destination}`
  }
  if (isShelflifePowered(config) && isPreAdvice(shipment.snapshotId)) {
    return 'Pre-advice'
  }
  const direction = (shipment.statusType === 'distribution' || isPSMManualShipment)
    ? 'Distribution to'
    : `Arrival ${isShelflifePowered(config) ? 'to' : 'from'}`
  const title = `${direction} ${destination} from ${origin}`
  return title
}

export const getDefaultReturnDestination = (returnShipmentConfigEnabled, state) => {
  let destination
  if (returnShipmentConfigEnabled) {
    const configuredDestination = getReturnShipmentDestinationByState(state)
    if (configuredDestination) {
      destination = configuredDestination
    }
  }
  return destination
}

// TODO: human readable location names, see: fielded/van-orga#135
export const formatLocation = location => {
  if (location.lga) {
    return toTitleCase(location.lga) + ' LGA'
  }
  if (location.state) {
    return toTitleCase(location.state) + ' State'
  }
  if (location.zone) {
    return location.zone.toUpperCase()
  }
  if (location.id === 'national') {
    return 'National'
  }
  if (location.id === 'country') {
    return 'Country'
  }
  return location.id
}

export const isUnknownBatch = (batch) => !!batch.id.toLowerCase().match('batchno:unknown')

// TODO: this criteria is unstable and needs to be changed
export const isExternal = snapshotId => /^origin:country/.test(snapshotId)

export const getProductTotalFromPickList = (pickList, productName) => get(pickList, `product:${productName}.total`, 0)

export const validNewShipmentNo = (input, shipments) => {
  const shipmentNumberExists = (input, shipments) => (
    Object.keys(shipments).find(shipmentId => (shipments[shipmentId].shipmentNo === input))
  )
  return (input.length === 4 && /^\d+$/.test(input) && !shipmentNumberExists(input, shipments))
}

export const numberWithCommas = (quantity) => {
  return quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const shipmentTypeLabel = (shipmentType) => `${shipmentType.abbreviation} (${shipmentType.name})`

export const getShipmentTypeOptions = () => {
  const shipmentTypes = getShipmentTypes()
  return Object.keys(shipmentTypes).map(key => (
    { value: shipmentTypes[key].id, label: shipmentTypeLabel(shipmentTypes[key]) })
  )
}

export const getLastSnapshotSignature = (shipment) => {
  const commentsWithSignatures = shipment.comments.filter(comment => comment.signature)
  return commentsWithSignatures.length === 0 ? undefined : commentsWithSignatures.pop().signature
}
export const getLastSnapshotSignedBy = (shipment) => {
  const commentsWithSignedBy = shipment.comments.filter(comment => comment.name)
  return commentsWithSignedBy.length === 0 ? undefined : _last(commentsWithSignedBy).name
}

export const getLastSnapshotComment = (shipment) => {
  const filledComments = shipment.comments.filter(comment => comment.comment)
  return filledComments.length === 0 ? undefined : _last(filledComments).comment
}

export const getTotalPackedItemVAT = (addVAT, item) => {
  return (addVAT && !!item.unitVat) ? item.packed * item.unitVat : 0
}
