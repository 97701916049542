// This is PSM team's list of state prefixes they use to prefix
// location identifiers.
module.exports = {
  'zone:south-east:state:abia': 'ABI',
  'zone:north-east:state:adamawa': 'ADM',
  'zone:south-south:state:akwa-ibom': 'AKB',
  'zone:south-east:state:anambra': 'ANB',
  'zone:north-east:state:bauchi': 'BAU',
  'zone:north-central:state:benue': 'BEN',
  'zone:north-east:state:borno': 'BON',
  'zone:south-south:state:bayelsa': 'BYS',
  'zone:south-south:state:cross-river': 'CRS',
  'zone:south-south:state:delta': 'DLT',
  'zone:south-east:state:ebonyi': 'EBY',
  'zone:south-south:state:edo': 'EDO',
  'zone:south-west:state:ekiti': 'EKT',
  'zone:south-east:state:enugu': 'ENG',
  'zone:north-central:state:fct': 'FCT',
  'zone:north-east:state:gombe': 'GMB',
  'zone:south-east:state:imo': 'IMO',
  'zone:north-west:state:jigawa': 'JGW',
  'zone:north-west:state:kano': 'KAN',
  'zone:north-west:state:kaduna': 'KDN',
  'zone:north-west:state:kebbi': 'KEB',
  'zone:north-central:state:kogi': 'KOG',
  'zone:north-west:state:katsina': 'KST',
  'zone:north-central:state:kwara': 'KWR',
  'zone:south-west:state:lagos': 'LAG',
  'zone:north-central:state:niger': 'NGR',
  'zone:north-central:state:nasarawa': 'NSW',
  'zone:south-west:state:ogun': 'OGN',
  'zone:south-west:state:ondo': 'OND',
  'zone:south-west:state:osun': 'OSN',
  'zone:south-west:state:oyo': 'OYO',
  'zone:north-central:state:plateau': 'PLT',
  'zone:south-south:state:rivers': 'RIV',
  'zone:north-west:state:sokoto': 'SKT',
  'zone:north-east:state:taraba': 'TRB',
  'zone:north-east:state:yobe': 'YBE',
  'zone:north-west:state:zamfara': 'ZMF'
}
