import React, { createElement, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Spinner } from '../../icons'

class Button extends React.Component {
  renderLabelContent () {
    const {
      children,
      icon,
      iconSide,
      loading,
      loadingLabel
    } = this.props

    if (loading) {
      return (
        <Fragment>
          <span className='vs-button__loading-spinner'>{<Spinner />}</span>
          <span
            className='vs-button__label'
          >
            {loadingLabel || children}
          </span>
        </Fragment>
      )
    }

    return (
      <Fragment>
        {icon && (iconSide === 'left') && (
          <span
            className={classnames(
              'vs-button__icon'
            )}
          >
            {icon}
          </span>
        )}
        {children &&
          <span
            className='vs-button__label'
          >
            {children}
          </span>
        }
        {icon && (iconSide === 'right') && (
          <span
            className={classnames(
              'vs-button__icon'
            )}
          >
            {icon}
          </span>
        )}
      </Fragment>
    )
  }

  render () {
    const {
      className,
      type,
      colorVariant,
      fill,
      href,
      children,
      title,
      icon,
      iconSide,
      selected,
      loading,
      loadingLabel,
      disabled,
      shape,
      spread,
      size,
      inline,
      alignEdge,
      component,
      ...otherProps
    } = this.props

    const labelContent = this.renderLabelContent()
    const commonProps = {
      className: classnames(
        'vs-button',
        {'vs-button--fill-none': fill === 'none'},
        {'vs-button--fill-full': fill === 'full'},
        {'vs-button--fill-outline': fill === 'outline'},
        {'vs-button--color-neutral': colorVariant === 'neutral'},
        {'vs-button--color-dark': colorVariant === 'dark'},
        {'vs-button--color-light': colorVariant === 'light'},
        {'vs-button--color-brand': colorVariant === 'brand'},
        {'vs-button--color-accent': colorVariant === 'accent'},
        {'vs-button--color-confirmation': colorVariant === 'confirmation'},
        {'vs-button--color-warning': colorVariant === 'warning'},
        {'vs-button--color-attention': colorVariant === 'attention'},
        {'vs-button--color-danger': colorVariant === 'danger'},
        {'vs-button--color-retailer-green': colorVariant === 'retailerGreen'},
        {'vs-button--shape-pill': shape === 'pill'},
        {'vs-button--shape-square': shape === 'square'},
        {'vs-button--size-large': size === 'large'},
        {'vs-button--size-regular': size === 'regular'},
        {'vs-button--size-small': size === 'small'},
        {'vs-button--size-inline': size === 'inline'},
        {'vs-button--align-content': alignEdge === 'content'},
        {'vs-button--align-shadow': alignEdge === 'shadow'},
        {'vs-button--has-icon-left': (icon && iconSide === 'left') || loading},
        {'vs-button--has-icon-right': icon && iconSide === 'right'},
        {'vs-button--spread': spread},
        {'vs-button--selected': selected},
        className
      ),
      title: title,
      ...otherProps
    }
    let wrapperComponent = component
    let specificProps = {}

    if (href) {
      wrapperComponent = 'a'
      specificProps = {
        href: href
      }
    }

    // if a wrapper hasn't been set yet, we assume it's a regular button
    if (!wrapperComponent) {
      wrapperComponent = 'button'
      specificProps = {
        type: type,
        disabled: disabled || loading
      }
    }

    return createElement(
      wrapperComponent,
      {
        ...commonProps,
        ...specificProps
      },
      labelContent
    )
  }
};

Button.propTypes = {
  /**
   * Content of button. Can be text or any tag valid by HTML standards.
   */
  children: PropTypes.node,
  /**
   * Pass a link destination to render as a button-looking link.
   * If href is set, type and disabled will not be applied.
   */
  href: PropTypes.string,
  /**
   * Pass a title to a Button to have a small tooltip rendered on hover.
   * It should be used with an icon-only Button (without text).
   */
  title: PropTypes.string,
  /**
   * Pass an optional alternative top level element to use as the "button", e.g. CrossLink.
   * Can be a Component, stateless function, or string corresponding to a default JSX element.
   * Object type is added as it is typeof Link
   */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  /**
   * Pass an icon component to add an icon to the button
   * */
  icon: PropTypes.node,
  /**
   * Shift the side of the icon
   * */
  iconSide: PropTypes.oneOf(['left', 'right']),
  /**
   * Set the button to a selected state, when used like options/tabs
   * */
  selected: PropTypes.bool,
  /**
   * Set the button to a loading state
   * */
  loading: PropTypes.bool,
  /**
   * Pass an alternative label while in loading state
   * */
  loadingLabel: PropTypes.string,
  /**
   * Use to disable the button (not applied when link)
   * */
  disabled: PropTypes.bool,
  /**
   * type attribute, e.g. submit (not applied when link)
   * */
  type: PropTypes.string,
  /**
   * Determines the shape of the button
   */
  shape: PropTypes.oneOf(['pill', 'square']),
  /**
   * Makes the button spread to take up all available space
   */
  spread: PropTypes.bool,
  /**
   * Determine the size of the button
   */
  size: PropTypes.oneOf(['small', 'regular', 'large', 'inline']),
  /**
   * Determines if Button renders inline as a part of a text
   */
  inline: PropTypes.bool,
  /**
   * Determine the outer edge for alignment
   */
  alignEdge: PropTypes.oneOf(['box', 'content', 'shadow']),
  /**
   * Determines fill mode of button. Note that `none` also removes padding.
   */
  fill: PropTypes.oneOf(['none', 'full', 'outline']),
  /**
   * Color variant, e.g. based on the role it has in the interface
   */
  colorVariant: PropTypes.oneOf(['neutral', 'dark', 'light', 'brand', 'accent', 'warning', 'attention', 'confirmation', 'danger', 'retailerGreen']),
  /**
   * Any additional class names you want to add to the button
   * */
  className: PropTypes.string
}

Button.defaultProps = {
  children: null,
  title: null,
  href: null,
  component: undefined,
  icon: null,
  iconSide: 'left',
  selected: null,
  loading: null,
  loadingLabel: '',
  disabled: false,
  type: 'button',
  shape: 'pill',
  spread: false,
  size: 'regular',
  alignEdge: 'box',
  fill: 'none',
  colorVariant: 'neutral',
  className: ''
}

export default Button
