exports.getUniqueProductCount = getUniqueProductCount
function getUniqueProductCount (orders) {
  const productSet = new Set()
  orders.forEach(order => {
    Object.keys(order.products).forEach(productId => productSet.add(productId))
  })
  return productSet.size
}

exports.getUniqueLocationCount = getUniqueLocationCount
function getUniqueLocationCount (orders) {
  const locationSet = new Set(orders.map(order => order.destinationId))
  return locationSet.size
}

exports.getProperRouteIdentifier = getProperRouteIdentifier
function getProperRouteIdentifier (prop) {
  let routeToCheck = prop.routeId || ''

  if (routeToCheck.includes('funder:')) {
    routeToCheck = prop.routeId.replace('funder:', 'route:')
  }

  return routeToCheck
}
