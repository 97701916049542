const createDocNumber = (marketCode, locationCode) => {
  return [
    marketCode.toUpperCase(),
    locationCode.toUpperCase(),
    new Date().getTime().toString(36).toUpperCase()
  ].join('-').slice(0, 21)
}

module.exports = {
  createDocNumber
}
