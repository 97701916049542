const EntityApi = require('../common/entity-api')
const PGAdapter = require('../common/pg-adapter')

function clean (params) {
  Object.keys(params).forEach(key => {
    if (typeof params[key] === 'undefined') {
      delete params[key]
    }
  })

  return params
}

function translateToPG (params) {
  const newParams = {
    id: params.fcmToken || params.id,
    user_id: params.userId || params.user_id,
    location_fsid: params.locationId || params.location_fsid
  }

  return clean(newParams)
}

function translateToFE (params) {
  const newParams = {
    fcmToken: params.fcmToken || params.id,
    userId: params.userId || params.user_id,
    locationId: params.locationId || params.location_fsid,
    createdAt: params.createdAt || params.created_at,
    updatedAt: params.updatedAt || params.updated_at
  }

  return clean(newParams)
}

class NotificationsFCMAdapter extends EntityApi {
  constructor (state, pgConnection, agaveAdapter, fcmConfigs) {
    const username = state.user.name
    const columns = [
      'id', 'user_id', 'location_fsid', 'created_at', 'updated_at'
    ]

    let adapter = {}
    if (pgConnection) {
      adapter = new PGAdapter(pgConnection, 'avocado.data_fcmmapping', username, columns, 'id')
    } else if (agaveAdapter) {
      adapter = agaveAdapter
    }

    super(adapter)
    this.pgConnection = !!pgConnection
    if (fcmConfigs) {
      Object.assign(this, {...fcmConfigs})
    }
  }

  sendMessagesWithLookup (data) {
    if (!this.fcm) {
      throw new Error('fcm package is not configured')
    }
    const notificationData = Array.isArray(data) ? data : [data]
    // todo: refactor package fcm package remove dependency from api
    return this.fcm.sendMessagesWithLookup({
      notificationsFcm: {
        getList: this.getList,
        delete: this.delete
      }
    },
    [notificationData],
    this.secret, undefined,
    JSON.parse(this.analyticsConfig)
    )
  }

  upsert (params) {
    // Translate JS-friendly params to db columns
    const newParams = translateToPG(params)
    newParams.created_at = new Date().toJSON()
    newParams.updated_at = new Date().toJSON()

    if (this.pgConnection) {
      // PG
      return this.adapter.upsert(newParams)
    }

    // Agave Adapter
    return this.adapter.create('notifications/fcm-session', newParams)
  }

  delete (id) {
    if (this.pgConnection) {
      // PG
      return this.adapter.delete(id)
    }

    // Agave Adapter
    return this.adapter.delete('notifications/fcm-session', id)
  }

  getList ({ filter = {}, ...params }) {
    if (this.pgConnection) {
      // PG
      return this.adapter.getList({ filter: translateToPG(filter), ...params })
        .then(({ results }) => {
          return results.map(translateToFE)
        })
    }

    this.adapter.getList({ filter, ...params })
  }
}

module.exports = NotificationsFCMAdapter
