import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { QuantityInput } from '@fielded/shared-ui'

const OTPInput = ({
  otp,
  hasError,
  disabled,
  onChange,
  className
}) => {
  const otpInputLength = otp.length
  const inputRef = useRef(new Array(otpInputLength).fill(null))

  const onHandleOTPChange = (event, index) => {
    const { target: { value } } = event

    if (value.length && index < otpInputLength - 1) {
      inputRef.current[index + 1].focus()
    }

    if (!value.length && index > 0) {
      inputRef.current[index - 1].focus()
    }

    onChange(value, index)
  }

  const onKeyUp = (event, index) => {
    const { key, target: { value } } = event

    if (key === 'Backspace' && !value && index > 0) {
      inputRef.current[index - 1].focus()
    }

    if (key === 'Enter' && value && index < otpInputLength - 1) {
      inputRef.current[index + 1].focus()
    }
  }

  return (
    <section className={classnames(
      'vs-otp-input__container',
      className
    )}>
      {otp.map((digit, index) => (
        <QuantityInput
          key={index}
          className='vs-otp-input'
          value={digit}
          maxLength={1}
          placeholder=''
          autoComplete='off'
          hasError={hasError}
          disabled={disabled}
          getInputRef={(reference) => (inputRef.current[index] = reference)}
          onChange={(e) => onHandleOTPChange(e, index)}
          onKeyUp={(e) => onKeyUp(e, index)}
        />
      ))}
    </section>
  )
}

OTPInput.propTypes = {
  /**
   * An array of string or numbers to hold the initial OTP values
   */
  otp: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  /**
   * If there are any errors on OTP validation
   */
  hasError: PropTypes.bool,
  /**
   * If OTP is disabled
   */
  disabled: PropTypes.bool,
  /**
   * callback function for when an input changes
   */
  onChange: PropTypes.func,
  /**
   * A className for custom styles
   */
  className: PropTypes.string
}

OTPInput.defaultProps = {
  otp: new Array(4).fill('')
}

export default OTPInput
