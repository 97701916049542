const { listPrograms } = require('../../../program/api/read/list-programs')
const isEqual = require('lodash/isEqual')

const validateReportingPeriod = async (state, {existingPrograms = [], newPrograms = []}) => {
  if (isEqual(existingPrograms, newPrograms) || newPrograms.length === 1) return true

  const allPrograms = await listPrograms(state)
  const unitReportingPeriod = allPrograms.find(program => program.id === newPrograms[0]).reportingPeriod

  return newPrograms.every(newProgram => {
    const reportingPeriod = allPrograms.find(program => program.id === newProgram).reportingPeriod
    return unitReportingPeriod === reportingPeriod
  })
}

exports.validateReportingPeriod = validateReportingPeriod
