const generateUnknownBatchForProduct = require('./virtual-batch').getUnknownBatchID

const batchIdToProductId = batchId => batchId.split(':').slice(0, 2).join(':')

const isBatchId = id => id.indexOf(':manufacturer:') !== -1 && id.indexOf(':batchNo:') !== -1

const isSnapshotId = id => id.indexOf(':agent:') > id.indexOf(':status:')

module.exports = {
  generateUnknownBatchForProduct,
  batchIdToProductId,
  isBatchId,
  isSnapshotId
}
