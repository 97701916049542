import { createElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// Extends Component for propTypes to show up in storybook,
// which they otherwise won't when the component starts with
// createElement
class Heading extends Component {
  render () {
    const {
      children,
      size,
      tight,
      color,
      tag,
      display,
      className,
      bold,
      ...otherProps
    } = this.props
    return createElement(
      tag,
      {
        className: classnames(
          'vs-heading',
          {'vs-heading--display': display},
          {'vs-heading--size-small': size === 'small'},
          {'vs-heading--size-smallish': size === 'smallish'},
          {'vs-heading--size-medium': size === 'medium'},
          {'vs-heading--size-largish': size === 'largish'},
          {'vs-heading--size-large': size === 'large'},
          {'vs-heading--tight': tight},
          {'vs-heading--color-light': color === 'light'},
          className
        ),
        ...otherProps
      },
      children
    )
  }
}

Heading.propTypes = {
  /**
   * Content for the heading.
   */
  children: PropTypes.node.isRequired,
  /**
   * The size you want the heading to render at
   */
  size: PropTypes.oneOf(['inherit', 'small', 'smallish', 'medium', 'largish', 'large']),
  /**
   * Should this heading render with a tight line-height?
   * This is recommended in narrow spaces, e.g. narrow columns.
   */
  tight: PropTypes.bool,
  /**
   * Uses display type of font
   * instead of standard one
   */
  display: PropTypes.bool,
  /**
   * Optionally set color if heading is used on a colored background
   */
  color: PropTypes.oneOf(['inherit', 'light']),
  /**
   * HTML tag, e.g. a semantic heading tag representing the level of the heading
   */
  tag: PropTypes.string,
  /**
   * Any classname(s) to apply custom styles
   */
  className: PropTypes.string
}

Heading.defaultProps = {
  size: 'medium',
  tight: false,
  display: false,
  color: 'inherit',
  tag: 'div'
}

Heading.displayName = 'Text.Heading'

export default Heading
