import React from 'react'
import PropTypes from 'prop-types'
import ReportField from './report-field'

const Commits = ({
  productId,
  commits,
  currentTabIndex,
  startTabIndex,
  onProductUpdate,
  setTabIndex,
  total
}) => {
  return Object.keys(commits).map((commitId, i) => {
    const fieldTabIndex = startTabIndex + i
    const commit = commits[commitId]

    // Apply a maxValue validator to ensure that values entered into the
    // 'Quantity reserved for SIA (campaign)' field are less than the
    // total/physical count.
    const validationRules = commitId === 'campaign:sia' || commitId === 'commit:campaign:sia' ? {
      maxValue: {
        value: total,
        message: 'SIA(campagin) reserve must not exceed the total quantity'
      }
    } : null

    return (
      <ReportField
        key={productId + commitId}
        type='commit'
        label='Quantity reserved for SIA (campaign)'
        id={commitId}
        inputPrefix={'commit:'}
        handleClick={() => setTabIndex(fieldTabIndex)}
        value={commit.amount}
        onProductUpdate={onProductUpdate}
        autoFocus={currentTabIndex === fieldTabIndex}
        validationRules={validationRules}
      />
    )
  })
}

Commits.propTypes = {
  commits: PropTypes.object.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  startTabIndex: PropTypes.number.isRequired,
  onProductUpdate: PropTypes.func.isRequired,
  setTabIndex: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired
}

export default Commits
