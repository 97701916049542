const { docToStockCountRecord } = require('../../../lib/tools')
const { parse } = require('../../../lib/tools/smart-id')
const get = require('lodash/get')
const set = require('lodash/set')
const { addMilliseconds, max: maxDate } = require('date-fns')

async function sellOffSLBalance (state, {
  api,
  location,
  message,
  productIds,
  service,
  period,
  date = new Date().toJSON(),
  submittedAt
}) {
  const ledger = await api.stock.getLedgerBalanceAsReport({
    location: { id: location._id },
    service,
    excludeProducts: 'configuration',
    online: true,
    ignoreService: true
  })

  if (!ledger || Object.keys(ledger.stock).length === 0) {
    console.log(`Location ${location._id} does not have a ledger being.`)
    return
  }

  // Copy opening-balances
  Object.values(ledger.stock).forEach(productField => {
    const opening = get(productField, `fields.field:standard-physical-count.amount`)
    const openingPartner = get(productField, `fields.field:partner-balance.amount`, 0)

    set(productField, `fields.field:standard-opening-balance.amount`, opening)
    set(productField, `fields.field:opening-partner-balance.amount`, openingPartner)
  })

  // Generate a stock count with all fields
  let fields = await api.service.listReportFields(service.id)
  const stockCount = docToStockCountRecord(ledger, service, { fields, fieldOpts: { tracksPartnerBalances: location.tracksPartnerBalances } })

  Object.values(stockCount.stock).forEach(productField => {
    // Set all fields as autofilled
    // we're only interested in the balance transfers
    productField.autoFilled = true
  })

  // Sell off all existing SL stock
  let invoiced = false
  const stockToCheck = productIds || Object.keys(stockCount.stock)

  stockToCheck.forEach(productId => {
    const slBalance = get(stockCount, `stock.${productId}.fields.field:shelflife-balance.amount`, 0)

    if (slBalance > 0) {
      invoiced = true
      set(stockCount, `stock.${productId}.fields.field:standard-physical-count.amount`, 0)
      set(stockCount, `stock.${productId}.fields.field:standard-remark.amount`, message)
      set(stockCount, `stock.${productId}.autoFilled`, false)
    }
  })

  // If location does not have any sl balace do nothing
  if (!invoiced) {
    console.log(`Location ${location._id} does not have any sl balance to sell off`)
    return
  }

  // run calculated fields again to get sold, sl-sold, partner sold
  const finalCount = docToStockCountRecord(stockCount, service, { fields, fieldOpts: { tracksPartnerBalances: location.tracksPartnerBalances } })

  if (!period) {
    // We might be able to take the reporting period from the ledger
    let periodId = ledger.date.reportingPeriod
    let week
    if (ledger.documents.reportId) {
      ({ period: periodId, week } = parse(ledger.documents.reportId))
      // V1 id
      if (week) {
        periodId = week
      }
    }
    period = await api.report.period.fromId({ periodId, program: service.program })
  }

  if (!submittedAt) {
    submittedAt = maxDate(date, addMilliseconds(ledger.submittedAt, 100)).toJSON()
  }

  const params = {
    locationId: location._id,
    service,
    period,
    stock: finalCount.stock,
    partialCount: true,
    submittedAt,
    documents: [ledger.documents]
  }

  return params
}

module.exports = sellOffSLBalance
