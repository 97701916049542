import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Checkmark } from '@fielded/shared-ui/src/icons'

const CountCheckmark = ({
  id,
  checked,
  onChange,
  className
}) => {
  return (
    <div
      className={classnames(
        'count-checkmark',
        className
      )}
    >
      <input
        className='count-checkmark__checkbox'
        name='checked'
        type='checkbox'
        id={id}
        value='none'
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>
        <Checkmark />
      </label>
    </div>
  )
}

CountCheckmark.propTypes = {
  /**
   * id of the product being counted
   */
  id: PropTypes.string.isRequired,
  /**
   * Initial checked status
   */
  checked: PropTypes.bool,
  /**
   * Called when the checked status changes
   */
  onChange: PropTypes.func,
  /**
   * Optional className to handle positioning/spacing
   */
  className: PropTypes.string
}

CountCheckmark.defaultProps = {
  checked: false,
  onChange: () => null,
  className: ''
}

export default CountCheckmark
