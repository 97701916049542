// Retry an async function
//
// Usage:
// ```
// async function fetchData(url, params) {
//   await someAsyncOp(...)
// }
// retry(() => fetchData(url, params))
// ```
module.exports = async function retry (fn, numRetries = 3) {
  let lastError
  for (let i = 0; i < numRetries; ++i) {
    try {
      return await fn()
    } catch (e) {
      lastError = e
    }
  }
  throw lastError
}
