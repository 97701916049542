import React from 'react'
import PropTypes from 'prop-types'

import ActionsBar from '../ActionsBar'

const WizardStepActions = ({
  children,
  layout,
  ...otherProps
}) => (
  <div className='wizard-step__actions'>
    <ActionsBar layout={layout} {...otherProps}>
      {children}
    </ActionsBar>
  </div>
)

WizardStepActions.displayName = 'Wizard.Step.Actions'

WizardStepActions.propTypes = {
  /**
   * The components' children
   */
  children: PropTypes.node.isRequired
}

WizardStepActions.defaultProps = {
  layout: 'left'
}

export default WizardStepActions
