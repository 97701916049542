const stringToId = require('../../utils/string-to-id')
const { dedupeTimeCollection } = require('../tools')
const sheet = require('./sheet')
const { sanitize } = require('../tools')
const cloneDeep = require('lodash/cloneDeep')
const isEqual = require('lodash/isEqual')
const get = require('lodash/get')
const keyBy = require('lodash/keyBy')

/**
 * Organize both old and new products  and apply changes by sanitizing
 * each product
 *
 * @param sheetProducts constructed sheet rows
 * @param dbProducts products from DB to check against if a product is existing or not
 * @param opt.date the date to sanitize a product with
 * @param opt.username the user performing the current action
 *
 * @return Object:
 * {new: Array<Clean Product Doc>, update: Array<Clean Product Doc>, unchanged new: Array<Clean Product Doc>}
 */
const organizeProducts = (sheetProducts, dbProducts, {date, username}) => {
  const productsById = keyBy(dbProducts, '_id')

  const result = {
    new: [],
    update: [],
    unchangedIds: []
  }

  // segrate and sanitize old and new product
  for (let product of sheetProducts) {
    const existing = productsById[product._id]
    const opts = {date, username}
    if (!existing) {
      result.new.push(sanitize(product, opts))
      continue
    }

    opts.existingProduct = existing
    const sanitizedProduct = sanitize(product, opts)
    const isUnchanged = productUnchanged(sanitizedProduct, existing)
    if (isUnchanged) {
      result.unchangedIds.push(sanitizedProduct._id)
    } else {
      result.update.push(sanitizedProduct)
    }
  }

  return result
}

// for new products we infer product id from either product code or name
const fixProductIds = product => {
  if (product._id) {
    product._id = product._id.toLowerCase()
  } else if (product.code) {
    product._id = `product:${stringToId(product.code)}`
  }

  return product
}

const haveCode = row => !!row.code

const preparePrices = row => {
  row.prices = {price: row.prices, maxPrice: row.maxPrice}
  row.buyPrices = {buyPrice: row.buyPrice}
  row.vats = {vat: row.vat}
  return row
}

const prepareAdditionalData = row => {
  row.additionalData = {}
  return row
}

// get what actually will make it to couch, e.g. undfined fields removed.
const jsonResult = doc => JSON.parse(JSON.stringify(doc))

function productUnchanged (rawUpdateProduct, rawExistingProduct) {
  const updateProduct = jsonResult(cloneDeep(rawUpdateProduct))
  const existingProduct = jsonResult(cloneDeep(rawExistingProduct))
  // product aliases shouldn't be considered changed
  // if the update has duplicates in it
  if (existingProduct.alias) {
    existingProduct.alias = dedupeAlias(existingProduct)
  }

  const ignoreFields = ['_rev', 'updatedAt', 'updatedBy']

  ignoreFields.forEach(field => {
    delete updateProduct[field]
    delete existingProduct[field]
  })

  return isEqual(updateProduct, existingProduct)
}

// HEADS UP: ONE is hard coded here as the default alias
function dedupeAlias (product) {
  const foundAliases = get(product, 'alias.one', [])
  const aliases = [...new Set(foundAliases)]
  return {one: aliases}
}

function hasInvalidEdits (product) {
  if (product.tracer !== undefined && typeof product.tracer !== 'boolean') {
    return `product ${product._id} tracer must be true or false, found ${product.tracer}`
  }

  const numberFields = [
    'unitOfIssue', 'unitOfReporting', 'genericFactor', 'unitWeight', 'unitVolume', 'unitPrice'
  ]
  const invalidNumber = number => (number !== undefined) && !isFinite(number)
  const invalidNumberFields = numberFields.filter(field => invalidNumber(product[field]))

  if (invalidNumberFields.length) {
    return `product ${product._id} invalid numbers found for ${invalidNumberFields}`
  }

  const oneInvalid = (product.alias !== undefined) &&
    (
      !Array.isArray(product.alias.one) ||
      product.alias.one.find(alias => typeof alias !== 'string')
    )

  if (oneInvalid) {
    return `product ${product._id} invalid ONE alias found`
  }
}

// TODO this is gross and the business rules need to be checked
// - always overwrite existing entries of the same date as the incoming change
// TODO also compare these dates by day, not time as the RDS doesn't have time
function mergeProducts (existingRow, doc) {
  // preserve original created props - rest adapter always fills these anew
  const { createdAt, createdBy } = Object.assign({}, doc, existingRow)

  // Don't merge vats for now.
  // The vats are hardcoded here: `product/data-access/rest-adapter/product-rest-transform::getVets`
  const eVats = doc.vats
  // merge price collections
  const ePrices = (existingRow.prices || [])
    .filter(ev => !(doc.prices || []).some(v => new Date(v.date).getTime() === new Date(ev.date).getTime()))
  const eBuyPrices = (existingRow.buyPrices || [])
    .filter(ev => !(doc.buyPrices || []).some(v => new Date(v.date).getTime() === new Date(ev.date).getTime()))

  // copy all props, preferring incoming doc
  const newDoc = Object.assign({}, existingRow, doc, { createdAt, createdBy })

  // add in the ones that the new doc didn't have
  // for vat, if the incoming doc has no vat, then eliminate the vat collection. this is so that
  // a product can be made entirely vat-free. if an incoming change has a 0 however, add that to the
  // vat history
  newDoc.vats = (doc.vats || []).length === 0 ? [] : dedupeTimeCollection(newDoc.vats.concat(eVats), 'vat')
  newDoc.prices = dedupeTimeCollection(newDoc.prices.concat(ePrices), 'price')
  newDoc.buyPrices = dedupeTimeCollection(newDoc.buyPrices.concat(eBuyPrices), 'price')

  // gross couchdb leakage
  delete newDoc.value

  return newDoc
}

module.exports = {
  sheet,
  haveCode,
  preparePrices,
  prepareAdditionalData,
  fixProductIds,
  organizeProducts,
  hasInvalidEdits,
  mergeProducts
}
