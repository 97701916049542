const { mergeCouchResponse } = require('../../../tools/utils/couch-response')

const DEFAULT_LIMIT = Number.MAX_SAFE_INTEGER

exports.bulkDocs = bulkDocs
async function bulkDocs (state, docs) {
  const response = await state.orderDb.bulkDocs(docs)
  return mergeCouchResponse(docs, response)
}

exports.clearShipmentsOnGroup = clearShipmentsOnGroup
async function clearShipmentsOnGroup (state, groupId) {
  const selector = {orderId: {'$regex': groupId}}
  let {docs} = await state.shipmentsDb.find({selector, limit: DEFAULT_LIMIT})
  return state.shipmentsDb.bulkDocs({docs: docs.map(removeDoc)})
}

function removeDoc ({_id, _rev}) {
  return {_id, _rev, _deleted: true}
}

exports.update = update
async function update (state, doc) {
  return state.orderDb.put(doc)
}
