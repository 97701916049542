module.exports = normaliseRoles
function normaliseRoles (roles) {
  return roles.map(role => {
    switch (true) {
      case role === 'forth-driver':
      case role === 'forth-user':
      case role === 'nav-user':
      case role === 'psm-user':
      case role === 'shelflife-user':
      case role === 'van-user':
        return null
      case role === 'admin':
        return role
      case role === 'analytics-utilization':
        return 'feature:analytics:utilization'
      case role.startsWith('role:'):
      // These are NAV specific roles and they are not needed anymore
        return null
      case role === 'stock-reports':
        return `feature:stock-reporting`
      default:
        return `feature:${role}`
    }
  })
  // Filter out falsy values
    .filter(role => role)
}
