const get = require('lodash/get')
const keyBy = require('lodash/keyBy')

const { getServiceForLocationId } = require('../service/tools')

const tools = require('./tools')
const utils = require('./utils')
const api = require('./api')
const { isPPMV: isPPMVLocation } = require('../location/tools')
const { EntityApi } = require('../common')
const { wrapEntityApi } = require('../utils/wrap-api')
const { locationIdToProperties } = require('../tools')

const rawMethods = {
  export: api.exportProducts,
  import: api.importProducts,
  importProductData: api.importProductData,
  getByIds: api.getByIds,
  save: api.save,
  get: api.get,
  listAll: api.listAll,
  listAllForDates: api.listAllForDates,
  listForLocations: api.listForLocations,
  listForPrograms: api.listProductsForPrograms,
  listForServices: api.listProductsForServices,
  toGenericQuantities: api.toGenericQuantities,
  list: api.list,
  tools,
  utils
}

const ProductDataAdapter = require('./data-access/pouchdb-adapter/product-pouch-adapter')
const ProductRestAdapter = require('./data-access/rest-adapter')
const { ProductPGAdapter } = require('./data-access/product-pg-adapter')
const ProductBasePGAdapter = require('./data-access/product-base-pg-adapter')
const ProductTagAdapter = require('./data-access/product-tag-pg-adapter')
const ProductPresentationPGAdapter = require('./data-access/product-presentation-pg-adapter')
const { ProductSimilarProductsPGAdapter } = require('./data-access/product-similar-products-pg-adapter')
const ProductSKUPGAdapter = require('./data-access/product-sku-pg-adapter')
const STATS_CATEGORIES = require('./utils/market-data-recommendation-categories')

class ProductApi extends EntityApi {
  constructor (state, logger, agaveAdapter, pgConnection) {
    const { user, productsDB } = state
    const pouchAdapter = new ProductDataAdapter(user, productsDB, logger)
    // sets this.adapter = adapter
    super(pouchAdapter)
    // TODO: remove this when all raw methods have been ported
    const apiMethods = wrapEntityApi(rawMethods, state)
    Object.assign(this, apiMethods)

    this.user = user
    this.agaveAdapter = agaveAdapter
    this.restAdapter = new ProductRestAdapter(agaveAdapter, logger)
    this.logger = logger
    if (pgConnection) {
      this.pgAdapter = new ProductPGAdapter(logger, pgConnection, user.name)
      this.productBase = new ProductBasePGAdapter(logger, pgConnection, user.name)
      this.productPresentation = new ProductPresentationPGAdapter(logger, pgConnection, user.name)
      this.productSimilarProducts = new ProductSimilarProductsPGAdapter(logger, pgConnection, user.name)
      this.productTag = new ProductTagAdapter(logger, pgConnection, user.name)
      this.productSKU = new ProductSKUPGAdapter(logger, pgConnection, user.name)
    }
  }

  async promoted (locationId, { location, products, allocations, withAllocation = true } = {}) {
    location = location || await this.mainApi.location.get(locationId)

    // There is no promotions available for PPMV locations
    if (!products || (withAllocation && !allocations) || isPPMVLocation(location)) {
      return {
        products: {},
        totalProducts: 0
      }
    }

    let filteredProducts = Object.values(products)
    if (withAllocation) {
      const subscriptionIds = get(allocations, locationId, [])
      filteredProducts = Object.values(products).filter(
        p => !subscriptionIds.includes(p._id)
      )
    }

    const promotedProducts = tools.getPromoted(filteredProducts)

    return {
      products: promotedProducts,
      totalProducts: Object.values(promotedProducts).flat().length
    }
  }

  async marketDataRecommendations (locationId, { limit = 10, categories = STATS_CATEGORIES, products, location } = {}) {
    const territory = getServiceForLocationId(locationId)

    location = location || await this.mainApi.location.get(locationId)
    // There is no market data available for PPVM locations
    if (isPPMVLocation(location)) {
      return {
        marketName: territory.name,
        products: {},
        totalProducts: 0
      }
    }

    if (!products) {
      ({ products } = await this.mainApi.product.listForLocations([locationId]))
    }

    if (Array.isArray(products)) {
      products = keyBy(products, '_id')
    }

    const promises = categories.map(category => {
      // We request limit * 2 to have some buffer in case we need to filter out some products
      // that have no price.
      // Requesting double the amount of products doesn't 100 % guarantee that we'll
      // find enough products with price, although the risk of not getting enough
      // valid products is low because, since users cannot subscribe to products with
      // no price, it is unlikely that those products will end up being the most
      // popular/fastest selling/giving the best value.
      //
      // A better way of doing this would be something like:
      // let results = []
      // while (true) {
      //   const lastRes = await getThings()
      //   results = results.concat(lastRes.filter(condition))
      //   results = results.slice(0, limit)
      //   if (lastRes.length < limit || results.length === limit) {
      //    break
      //   }
      // }
      //
      // For this we'll need to improve the agave endpoint to understand `offset`.
      return this.agaveAdapter.get('stats', { market: territory.rdsMarketAlias, category, limit: limit * 2 })
        .then(({ results }) => results)
        .catch(e => {
          console.error(`Error while retrieving product stats for ${category}: `, e)
          return []
        })
    })

    const results = await Promise.all(promises)
    const recommendations = {}

    // We filter out products with no price but we allow the price
    // to be 0, in case in the future we want to add some products
    // we want to give for free.
    categories.forEach((category, index) => {
      recommendations[category] = results[index]
        .map(p => products[`product:${p.sku}`])
        .filter(p => p)
        .filter(p => tools.getCurrentPrice(p.prices) !== undefined)
        .slice(0, limit)
    })

    // Flat takes 1 level deep arrays inside array and treats it as one array
    const totalProducts = Object.values(recommendations).flat().length

    return {
      marketName: territory.name,
      products: recommendations,
      totalProducts: totalProducts
    }
  }

  async marketDataTopProduct (locationId, category, options = {}) {
    const recommendations = await this.marketDataRecommendations(locationId, { limit: 1, categories: [category], ...options })
    return {
      marketName: recommendations.marketName,
      product: get(recommendations, `products.${category}.[0]`)
    }
  }

  async bundleForProducts (products, isPPMVLocation, isGRlocation) {
    const { isCore, isPPMVCore, CORE_BUNDLES } = tools
    if (isPPMVLocation || isGRlocation) {
      return {
        ...CORE_BUNDLES.ppmv,
        products: products.filter(isPPMVCore).map(p => p._id)
      }
    }
    return {
      ...CORE_BUNDLES.default,
      products: products.filter(isCore).map(p => p._id)
    }
  }

  async bundleForLocation (locationId, { location, products } = {}) {
    const { isCore, isPPMVCore, CORE_BUNDLES } = tools
    location = location || await this.mainApi.location.get(locationId)

    if (!products) {
      ({ products } = await this.mainApi.product.listForLocations([locationId]))
    }

    let productsArray = products
    if (!Array.isArray(productsArray)) {
      productsArray = Object.values(productsArray)
    }

    if (isPPMVLocation(location)) {
      return {
        ...CORE_BUNDLES.ppmv,
        products: productsArray.filter(isPPMVCore).map(p => p._id)
      }
    }
    return {
      ...CORE_BUNDLES.default,
      products: productsArray.filter(isCore).map(p => p._id)
    }
  }

  // Gets the upcoming price for all products relevant to the location territory
  async getUpcomingPrices (locationId, { filter = null } = {}) {
    const { service: serviceId } = getServiceForLocationId(locationId)
    const products = await this.mainApi.product.listForServices([serviceId])
    const upcomingPrices = tools.getUpcomingPrices(products, filter)
    const { country, state } = locationIdToProperties(locationId)
    return {
      location: {
        country,
        state
      },
      ...upcomingPrices
    }
  }

  // Gets the current price for all products relevant to the location territory
  async getCurrentPrices (locationId, { filter = null } = {}) {
    const { service: serviceId } = getServiceForLocationId(locationId)
    const products = await this.mainApi.product.listForServices([serviceId])
    const prices = tools.getCurrentPrices(products, filter)
    const { country, state } = locationIdToProperties(locationId)
    return {
      location: {
        country,
        state
      },
      ...prices
    }
  }

  getProductsViaIds (productIds) {
    if (this.agaveAdapter) {
      return this.agaveAdapter.create('product/by-ids', { productIds })
    }
    // Fallback to pouch
    return this.mainApi.product.getByIds(productIds)
  }

  // Getting the list of similar products for a given list of product ids
  // Both parameter are optional. If no productIds are provided, it will return
  // the similar products for all products in the location.
  // LocationFsid is required only if there's no agaveAdapter aka. we have a PG connection
  // We implicitly assume that agaveAdapter and PgConnection are mutually exclusive

  async getSimilarProducts ({ locationFsid: locationFsidParam } = {}) {
    // We always try to get the locationFsid from the user object first
    // then we fallback to the locationFsidParam if it's not available
    const locationFsid = get(this.user, 'location.id') || locationFsidParam
    if (this.agaveAdapter) {
      return this.agaveAdapter.get('product/similar-products', {
        locationFsid
      })
    }
    if (!locationFsid) {
      throw new Error('locationFsid is required to get similar product data')
    }
    const rows = await this.productSimilarProducts.getSimilarProductGroups({
      locationFsid
    })
    return rows.reduce((result, row) => ({
      ...result, [`product:${row.product_id}`]: row.similar_products_list.map(product => `product:${product}`)
    }), {})
  }
}

module.exports = rawMethods
module.exports.ProductApi = ProductApi
