const get = require('lodash/get')
const set = require('lodash/set')
const _isEqual = require('lodash/isEqual')

const { aggregateAndFilter } = require('../aggregate')
const createGraph = require('../../relationship-graph')
const { filterByDateMoreRecent, createAllocation } = require('../allocation')
const { methods, types, DIRECT_ORDER_TYPES } = require('../config')
const { COLLECTION_REASONS } = require('../../shipment/tools')

// Helper to ensure parameters are set
const ensureParameterDef = (action, param, paramName) => {
  if (!param) {
    throw new Error(`Tried to ${action} with undefined ${paramName}`)
  }
}

const mapResponseToConfigurations = (resp, allocations) => {
  return resp
    // Pouch "nicely" adds {ok: true} to pretty much anything in bulk docs, so gotta check for errors here
    .filter(res => !res.error)
    .map(res => {
      const allocation = allocations.find(item => item._id === res.id) || {}
      allocation._rev = res.rev
      return allocation
    })
}

// Whether there is more than one allocation per location
const hasDuplicatedLocations = allocations => {
  const facilities = allocations.map(allocation => allocation.facilityId)
  const uniqueFacilities = [...new Set(facilities)]
  return facilities.length > uniqueFacilities.length
}

const newerThanDate = (allocations, currentAllocations, date) => {
  const locationIds = new Set(allocations.map(allocation => allocation.facilityId))
  const effectiveDate = new Date(date).toISOString().split('T')[0]
  return currentAllocations
    .filter(allocation =>
      locationIds.has(allocation.facilityId) && allocation.date > effectiveDate
    )
}

// In ShelfLife we need to update the `directOrder` allocation configuration when a new
// product is added to a shipment.
// This directOrder allocation does not need a supply plan on the document, that is calculated in the pipeline forecast
//
// The PSM warehouse allocation docs, which are a cache used in the forecast to sum up the needs of thousands of locations
// (not used in actual ordering) do need a supply plan. These docs have the forecast.method "consumption"
const isValidForecastMethod = method => Object.values(methods).includes(method)

const validateConfiguration = configuration => {
  const { products } = configuration
  Object.keys(products).forEach(productId => {
    const product = products[productId]
    const method = get(product, 'forecast.method')
    const type = get(product, 'forecast.type')

    if (!isValidForecastMethod(method)) {
      throw new Error(`Invalid forecast method ${method} in allocation configuration for ${configuration.facilityId}`)
    }

    if (type && !get(types, `${method}.validTypes`, []).includes(type)) {
      throw new Error(`Invalid type ${type} for forecast method ${method} in allocation configuration for ${configuration.facilityId}`)
    }

    if (method !== 'directOrder' && !product.supplyPlan) {
      throw new Error(`Missing supplyPlan for ${productId} in allocation configuration for ${configuration.facilityId}`)
    }
  })
}

const addAllocations = async (state, {configurations, effectiveDate = new Date().toISOString(), mergeProducts = false, noAggregate = false}) => {
  if (!configurations) {
    return Promise.reject(new Error('Parameter configurations is required'))
  }
  if (hasDuplicatedLocations(configurations)) {
    return Promise.reject(new Error('Found multiple allocation configurations referring to the same location. Only one allocation configuration is allowed per location'))
  }
  if (noAggregate) {
    const docs = []
    for (const { facilityId, products, unsubscriptions } of configurations) {
      // Will throw on invalid configs
      validateConfiguration({ products })

      docs.push(createAllocation({
        facilityId,
        date: effectiveDate,
        products,
        unsubscriptions,
        createdBy: state.user.name
      }))
    }
    return state.allocationsDB.bulkDocs(docs)
  }
  const [allLocations, existingAllocations] = await Promise.all([
    state.allocationApi.mainApi.location.listAll({date: effectiveDate}),
    state.allocationApi.getAllConfigurations({raw: true})
  ])

  const currentlyActive = filterByDateMoreRecent(existingAllocations, new Date().toISOString())
  if (newerThanDate(configurations, currentlyActive, effectiveDate).length) {
    return Promise.reject(new Error('It is not allowed to add allocation configurations older than the currently active one'))
  }

  // configurations no longer sanitized but just validated
  configurations.forEach(validateConfiguration)

  const activeOnEffectiveDate = filterByDateMoreRecent(existingAllocations, effectiveDate)

  const graph = createGraph(allLocations)
  const toBeImported = await aggregateAndFilter(graph, activeOnEffectiveDate, configurations, effectiveDate, state.user.name, mergeProducts)
  return state.allocationsDB
    .bulkDocs(toBeImported)
    .then(resp => mapResponseToConfigurations(resp, toBeImported))
}

const setAllToPAYG = async (state, locationId, author, productQuantities) => {
  const allocationDoc = await state.allocationApi.mainApi.allocation.getConfiguration({facilityId: locationId})
  if (allocationDoc) {
    // Let's make all subscriptions PAYG
    const productIds = Object.keys(allocationDoc.products)
    try {
      await setToPAYG(state, allocationDoc, productIds, author, productQuantities)
    } catch (e) {
      console.error(e)
    }
  }
}

const setToPAYG = async (state, allocationDoc, productIds, author, productQuantities) => {
  ensureParameterDef('set to PAYG', allocationDoc, 'allocationDoc')
  ensureParameterDef('set to PAYG', author, 'author')

  const subscriptionsToBeChanged = new Set(productIds)
  const currentSubscriptions = { ...allocationDoc.products }

  // Set ids to pay as you go (means pay on delivery)
  for (const id of subscriptionsToBeChanged) {
    set(currentSubscriptions[id], 'forecast.type', DIRECT_ORDER_TYPES.PAY_ON_DELIVERY)
    // Set quantity to order based on provided product quantities, if any
    if (productQuantities && productQuantities[id]) {
      set(currentSubscriptions[id], 'forecast.directOrder', productQuantities[id])
    }
  }

  // Add new allocation configuration
  const allocation = createAllocation({
    facilityId: allocationDoc.facilityId,
    date: new Date().toJSON(),
    products: currentSubscriptions,
    unsubscriptions: { ...(allocationDoc.unsubscriptions || {}) },
    createdBy: author
  })
  await addAllocations(state, {configurations: [allocation], noAggregate: true})
}

const unsubscribeAll = async (state, locationId, reason, author, subsFilter) => {
  const allocationDoc = await state.allocationApi.mainApi.allocation.getConfiguration({facilityId: locationId})
  const productIds = allocationDoc ? Object.keys(allocationDoc.products) : []
  await unsubscribe(state, locationId, productIds, reason, author, subsFilter)
}

const unsubscribe = async (state, locationId, productIds, reason, author, subsFilter) => {
  if (!locationId) {
    console.error('Tried to unsubscribe from undefined location')
    return
  }

  if (!reason) {
    console.error('Tried to unsubscribe with undefined reason')
    return
  }

  if (!author) {
    console.error('Tried to unsubscribe with undefined author')
    return
  }

  const allocationDoc = await state.allocationApi.mainApi.allocation.getConfiguration({facilityId: locationId})

  // No allocation doc found, so no active subscription -> return
  if (!allocationDoc) {
    return
  }

  const productIdsToBeRemoved = new Set(productIds)
  const subscriptions = { ...allocationDoc.products }
  const unsubscriptions = { ...(allocationDoc.unsubscriptions || {}) }
  const date = new Date().toJSON()

  for (const id of productIdsToBeRemoved) {
    if (!subsFilter || subsFilter(id, subscriptions)) {
      unsubscribeProduct(id, subscriptions, unsubscriptions, date, reason)
    }
  }

  // Add new allocation configuration
  const allocation = createAllocation({
    facilityId: locationId,
    date,
    products: subscriptions,
    unsubscriptions,
    createdBy: author
  })
  if (_isEqual(allocationDoc.unsubscriptions, allocation.unsubscriptions)) return
  await addAllocations(state, { configurations: [allocation], noAggregate: true })
}

function unsubscribeProduct (id, subscriptions, unsubscriptions, date, reason) {
  if (subscriptions[id]) {
    // remove from subscriptions
    delete subscriptions[id]
    // add to unsubscriptions.
    // This also overwrites an existing unsubscription if new unsubscription reason is NOT_SOLD and old one is not
    if (!unsubscriptions[id] ||
      (unsubscriptions[id].reason !== COLLECTION_REASONS.NOT_SOLD && reason === COLLECTION_REASONS.NOT_SOLD)) {
      unsubscriptions[id] = {
        createdAt: date,
        reason
      }
    }
  }
}

module.exports = {
  addAllocations,
  unsubscribe,
  unsubscribeAll,
  setAllToPAYG
}
