const { listForLocations } = require('./list-for-locations')

exports.list = function (state, locationId, options) {
  locationId = locationId || (state && state.user && state.user.location && state.user.location.id)
  if (!locationId) {
    return Promise.reject(new Error('missing location id'))
  }
  return listForLocations(state, [locationId], options)
    .then(resp => Object.keys(resp.products).map(key => resp.products[key]))
}
