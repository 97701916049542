const { listPrograms } = require('./list-programs')

exports.getProgram = async function (state, programId) {
  const programs = await listPrograms(state)
  const program = programs.find(program => program.id === programId)
  if (program) {
    return program
  }

  const error = new Error(`Program with id ${programId} not found`)
  error.status = 404
  throw error
}
