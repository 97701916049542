import React from 'react'
import { Route } from 'react-router-dom'

import Home from './Home'

import ProgramsContainer from '../overview/ProgramsContainer'
import LocationsContainer from '../overview/LocationsContainer'
import ServicesContainer from '../overview/ServicesContainer'

import AddBatchContainer from '../report-entry/AddBatchContainer'
import AddProductsContainer from '../report-entry/AddProductsContainer'
import FindReportContainer from '../report-entry/FindReportContainer'
import ReportEntryContainer from '../report-entry/ReportEntryContainer'
import ReportViewContainer from '../report-entry/ReportViewContainer'
import ConfirmationContainer from '../confirmation/ConfirmationContainer'
import SuccessContainer from '../success/SuccessContainer'
import LedgerContainer from '../ledger/LedgerContainer'
import TransferContainer from '../transfer/TransferContainer'
import SalesStatementContainer from '../statements/SalesStatementContainer'
import InventoryStatementContainer from '../statements/InventoryStatementContainer'

const Routes = ({ api, rootHistory, showCountSyncBanner, showShipmentSyncBanner }) => (
  <div className='van-stock-count'>
    <Route exact
      path='/'
      component={Home}
    />

    <Route exact
      path='/programs/:locationId/:date'
      render={(props) => <ProgramsContainer {...props} rootHistory={rootHistory} showCountSyncBanner={showCountSyncBanner} showShipmentSyncBanner={showShipmentSyncBanner} />}
    />

    <Route exact
      path='/locations/:locationId/:date/:programId'
      render={(props) => <LocationsContainer {...props} api={api} rootHistory={rootHistory} showCountSyncBanner={showCountSyncBanner} showShipmentSyncBanner={showShipmentSyncBanner} />}
    />

    <Route exact
      path='/services/:locationId/:date/:programId/:reportLocationId/:partialCount?'
      component={ServicesContainer}
    />

    <Route exact
      path='/find-report/:locationId/:serviceId/:date/:productId?/:partialCount?'
      component={FindReportContainer}
    />

    <Route exact
      path='/report/:reportId/:productId?'
      component={ReportEntryContainer}
    />

    <Route exact
      path='/report/:reportId/:productId/add-batch/:expiryForm?'
      component={AddBatchContainer}
    />

    <Route exact
      path='/add-products/:reportId'
      component={AddProductsContainer}
    />

    <Route exact
      path='/confirmation/:reportId'
      component={ConfirmationContainer}
    />

    <Route exact
      path='/view/:reportId/:productId?'
      component={ReportViewContainer}
    />

    <Route exact
      path='/sales-statement/:locationId/:serviceId/:date/:reportId'
      component={SalesStatementContainer}
    />

    <Route exact
      path='/inventory-statement/:locationId/:serviceId/:date/:reportId'
      component={InventoryStatementContainer}
    />

    <Route exact
      path='/success/:locationId'
      render={(props) => <SuccessContainer {...props} rootHistory={rootHistory} />}
    />

    <Route exact
      path='/ledger/:serviceId/:locationId/:productId'
      render={(props) => <LedgerContainer {...props} rootHistory={rootHistory} />}
    />

    <Route exact
      path='/transfer/:serviceId/:locationId/:productId'
      render={(props) => <TransferContainer {...props} rootHistory={rootHistory} />}
    />

  </div>
)

export default Routes
