const cloneDeep = require('lodash/cloneDeep')
const get = require('lodash/get')

// This copies merges each product of a stock report
// - the second param's `fields` overwrite the first
// - the `commits` are merged, but if any of the `commits` are they same, the second param's `commit` will win
// - the `batches` are merged, but if any of the `batches` keys are the same, the second param's `batch` will win
module.exports = (prev, update) => {
  return Object.keys(update).reduce((acc, productId) => {
    const batches = update[productId].batches
    const fields = update[productId].fields
    const commits = update[productId].commits
    const prevProduct = get(acc, productId, {})
    acc[productId] = Object.assign(
      {},
      prevProduct,
      batches && { batches: Object.assign({}, prevProduct.batches, batches) },
      fields && { fields: { fields } },
      commits && { commits: Object.assign({}, prevProduct.commits, commits) }
    )
    return acc
  }, cloneDeep(prev) || {})
}
