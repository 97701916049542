import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { Loading } from '@fielded/shared-ui'

import MultiFieldForm from './stock-count-form-multi-field/StockCountFormMultiField'
import BatchedForm from './stock-count-form-batched/StockCountFormBatched'

class FormContainer extends Component {
  handleProductUpdate = (key, type, update) => {
    const {
      productId,
      report,
      updateProduct,
      onFormChange,
      masterData
    } = this.props

    // Inputs on the forms are dynamically generated and their 'types' varies
    // The condition below should only apply when type on input is number
    // it checks and corrects invalid user inputs where update.amount === truthsy
    if (type === 'number') {
      update.amount = Math.abs(parseInt(update.amount, 10))
      if (isNaN(update.amount)) {
        update.amount = undefined
      }
    }

    updateProduct(report._id, productId, key, update, masterData.fields)

    onFormChange({isPristine: false})
  }

  componentDidUpdate (prevProps, prevState) {
    const { productId, onFormChange } = this.props
    if (prevProps.productId !== productId) {
      // Fresh form, all fields have their initial values
      onFormChange({isPristine: true})
    }
  }

  getCountedBatchTotal = (fields) => {
    if (fields) {
      return Object.keys(fields).reduce((sum, formField) => {
        let amount = 0

        if (formField.includes('batchNo')) {
          const thisField = fields[formField]
          amount = get(thisField, 'fields.field:standard-physical-count.amount', 0)
          if (isNaN(amount)) {
            amount = 0
          }
        } else {
          amount = get(fields, `${formField}.amount`, 0)
        }

        return sum + amount
      }, 0)
    }
  };

  render () {
    const {
      alerts,
      config,
      fields,
      fieldsById,
      batchesById,
      presentation,
      productId,
      orderedFields,
      countType,
      commits,
      serviceId,
      productReportBalance,
      expiry,
      url,
      report,
      editOpeningBalance,
      disableForm,
      dislayOpeningQuantityIssue
    } = this.props

    if (!fields) {
      return <Loading />
    }

    let formContainer
    switch (countType) {
      case 'batches':
        const countedTotal = this.getCountedBatchTotal(fields)
        formContainer = (
          <BatchedForm
            batchesInReport={fields}
            alerts={alerts}
            productId={productId}
            masterDataBatches={batchesById}
            commits={commits}
            countedTotal={countedTotal}
            onProductUpdate={this.handleProductUpdate}
            batchSize={presentation}
          />
        )
        break
      case 'standard':
        formContainer = (
          <MultiFieldForm
            config={config}
            alerts={alerts}
            fields={fields}
            editOpeningBalance={editOpeningBalance && !get(report, `stock.${productId}.fields.field:standard-opening-balance.disabled`, true)}
            disableForm={disableForm}
            productId={productId}
            fieldsById={fieldsById}
            batchesById={batchesById}
            batchSize={presentation}
            commits={commits}
            productReportBalance={productReportBalance}
            orderedFields={orderedFields}
            onProductUpdate={this.handleProductUpdate}
            serviceId={serviceId}
            expiry={expiry}
            url={url}
            dislayOpeningQuantityIssue={dislayOpeningQuantityIssue}
          />
        )
        break
      default:
        console.error('Unknown countType', countType)
    }
    return (
      <Fragment>
        {formContainer}
      </Fragment>
    )
  }
}

FormContainer.propTypes = {
  expriy: PropTypes.object,
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
  config: PropTypes.object,
  report: PropTypes.object.isRequired,
  fields: PropTypes.object,
  previousReport: PropTypes.object,
  // Collection of stock that is committed to a campaign or other store
  commits: PropTypes.object,
  countedTotal: PropTypes.number,
  productId: PropTypes.string,
  fieldsById: PropTypes.object.isRequired,
  batchesById: PropTypes.object,
  orderedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateProduct: PropTypes.func.isRequired
}

export default FormContainer
