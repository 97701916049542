import get from 'lodash/get'
import { getCurrentPrice } from '@fielded/fs-api/lib/product/tools'

export function isProductDiscounted (product) {
  const currentPrice = getCurrentPrice(product.prices)

  // Get discount from the newest price
  const discount = get(currentPrice, 'discount', 0)
  return discount > 0
}
