const ACTIVE_MANDATE_DATE_DELAY = 8
const GRACE_PERIOD = 5
const OVERDUE_AMOUNT_THRESHOLD = {
  ke: 1000,
  ng: 2500
}

module.exports = {
  ACTIVE_MANDATE_DATE_DELAY,
  GRACE_PERIOD,
  OVERDUE_AMOUNT_THRESHOLD
}
