const uuid = require('uuid')
const get = require('lodash/get')
const omit = require('lodash/omit')
const sumBy = require('lodash/sumBy')
const { TXN_TYPES } = require('../../finances/constants')

const importDate = new Date().toJSON()

const mapObj = (obj, propsMapping) => {
  const result = {}
  for (const [prop, source] of Object.entries(propsMapping)) {
    let value = null
    if (typeof source === 'function') {
      value = source(obj)
    } else if (Array.isArray(source)) {
      value = get(obj, ...source)
    } else {
      value = source
    }
    result[prop] = value
  }
  return result
}

const mapRaw = (rawRow, mapping) => {
  const result = {
    entity: {
      meta: mapObj(rawRow, mapping.entity.meta || {}),
      row: mapObj(rawRow, mapping.entity.row || {})
    }
  }
  if (mapping.line) {
    result.lines = []
    for (const rawLine of get(rawRow, 'raw_data.Line', [])) {
      result.lines.push({
        meta: mapObj(rawLine, mapping.line.meta || {}),
        row: mapObj(rawLine, mapping.line.row || {})
      })
    }
  }
  return result
}

const isManuallyCreatedPayment = (rawRow) => {
  const acceptableTransactions = ['Invoice', 'Expense']
  const invoices = rawRow.raw_data.Line.filter(
    l => l.LinkedTxn.filter(txn => acceptableTransactions.includes(txn.TxnType)).length
  )
  const otherTransactions = rawRow.raw_data.Line.filter(
    l => l.LinkedTxn.filter(txn => !acceptableTransactions.includes(txn.TxnType)).length

  )
  return invoices.length > 0 && otherTransactions.length === 0
}

const isRefundJournalEntry = (lines, accIds) => {
  const debit = lines.filter(line => {
    const accId = line.row.quickbooks_account_id
    const postingType = line.meta.postingType
    return postingType === 'Debit' && accIds.accountReceivableIds.includes(accId)
  })

  const credit = lines.filter(line => line.meta.postingType === 'Credit')

  return debit.length && credit.length
}

const isLoanJournalEntry = (lines, accIds) => {
  return lines.find(line => {
    const accId = line.row.quickbooks_account_id
    const { postingType: type } = line.meta
    return accIds.accountReceivableIds.includes(accId) && type === 'Credit'
  })
}

const getAmountPaid = (r) => (
  get(r, 'raw_data.TotalAmt', 0) - get(r, 'raw_data.Balance', 0)
)

const commonColumns = {
  'company_code': ['company_code'],
  'txn_date': ['raw_data.TxnDate'],
  'txn_id': ['txn_id'],
  'quickbooks_created_at': ['raw_data.MetaData.CreateTime'],
  'quickbooks_updated_at': ['raw_data.MetaData.LastUpdatedTime'],
  // TODO: we don't upsert, so these dates will always be the same
  'created_at': importDate,
  'updated_at': importDate
}

const entityMappings = {
  Invoice: {
    map: (rawRow) => mapRaw(rawRow, {
      entity: {
        meta: {
          customerId: ['raw_data.CustomerRef.value'],
          orderType: (r) => get(
            get(r, 'raw_data.CustomField', []).find(({ StringValue }) => ['PAYG', 'POD'].includes(StringValue)),
            'StringValue'
          )
        },
        row: {
          ...commonColumns,
          'txn_type': TXN_TYPES.invoice,
          'quickbooks_doc_number': ['raw_data.DocNumber'],
          'due_date': ['raw_data.DueDate'],
          'amount': ['raw_data.TotalAmt', 0],
          'amount_paid': getAmountPaid
        }
      }
    }),
    transform: async (mapped, { companyCode, logger, getLocationId }) => {
      const { entity } = mapped
      const orderType = entity.meta.orderType
      const customerId = entity.meta.customerId
      const locationId = await getLocationId(customerId)
      if (!locationId) {
        logger.warn({ code: 'location-not-found', customerId, companyCode, entity })
        return null
      }
      return {
        ...entity.row,
        'id': uuid.v4(),
        'location_id': locationId,
        'order_type': orderType
      }
    },
    persist: async (transformed, { insertTransactions, sendInAppNotifications }) => {
      const response = await insertTransactions([transformed])
      await sendInAppNotifications([transformed])
      return response
    }
  },
  Payment: {
    map: (rawRow) => {
      const mapped = mapRaw(rawRow, {
        entity: {
          meta: {
            customerId: ['raw_data.CustomerRef.value']
          },
          row: {
            ...commonColumns,
            'amount': ['raw_data.TotalAmt', 0],
            'unapplied_amount': ['raw_data.UnappliedAmt', 0]
          }
        },
        line: {
          row: {
            'amount': ['Amount'],
            'txn_id': ['LinkedTxn.0.TxnId'],
            'created_at': importDate,
            'updated_at': importDate
          }
        }
      })
      const manuallyCreated = isManuallyCreatedPayment(rawRow)
      if (manuallyCreated) {
        Object.assign(mapped, {
          manuallyCreated: {
            ...omit(mapped.entity.row, 'unapplied_amount'),
            'txn_type': TXN_TYPES.payment,
            'quickbooks_account_id': get(rawRow, 'raw_data.DepositToAccountRef.value'),
            'description': get(rawRow, 'raw_data.PrivateNote')
          }
        })
      }
      return mapped
    },
    transform: async (mapped, { companyCode, logger, getLocationId }) => {
      const { entity, lines, manuallyCreated } = mapped
      const customerId = entity.meta.customerId
      const locationId = await getLocationId(customerId)
      if (!locationId) {
        logger.warn({ code: 'location-not-found', customerId, companyCode, entity })
        return null
      }
      const data = {
        payment: {
          ...entity.row,
          'id': uuid.v4(),
          'location_id': locationId
        },
        paymentRelations: lines.map(line => ({
          ...line.row,
          'id': uuid.v4(),
          'company_code': entity.row.company_code,
          'location_id': locationId
        }))
      }

      if (manuallyCreated) {
        Object.assign(data, {
          transaction: {
            ...manuallyCreated,
            'id': uuid.v4(),
            'location_id': locationId
          }
        })
      }
      return data
    },
    persist: async (transformed, { insertPayment, insertPaymentRelations, insertTransactions }) => {
      const paymentId = await insertPayment(transformed.payment)
      if (transformed.transaction) {
        await insertTransactions([transformed.transaction])
      }
      return insertPaymentRelations(
        transformed.paymentRelations.map(rel => ({
          ...rel,
          'payment_id': paymentId
        }))
      )
    }
  },
  CreditMemo: {
    map: (rawRow) => mapRaw(rawRow, {
      entity: {
        meta: {
          customerId: ['raw_data.CustomerRef.value']
        },
        row: {
          ...commonColumns,
          'txn_type': TXN_TYPES.credit_memo,
          'quickbooks_doc_number': ['raw_data.DocNumber'],
          'amount': ['raw_data.TotalAmt', 0],
          'amount_paid': getAmountPaid,
          'description': ['raw_data.CustomerMemo.Value', '']
        }
      }
    }),
    transform: async (mapped, { companyCode, logger, getLocationId }) => {
      const { entity } = mapped
      const customerId = entity.meta.customerId
      const locationId = await getLocationId(customerId)
      if (!locationId) {
        logger.warn({ code: 'location-not-found', customerId, companyCode, entity })
        return null
      }
      return {
        ...entity.row,
        'id': uuid.v4(),
        'location_id': locationId
      }
    },
    persist: async (transformed, { insertTransactions }) => {
      return insertTransactions([transformed])
    }
  },
  Deposit: {
    map: (rawRow) => mapRaw(rawRow, {
      entity: {
        row: {
          ...commonColumns,
          'txn_type': TXN_TYPES.payment
        }
      },
      line: {
        meta: {
          customerId: ['DepositLineDetail.Entity.value']
        },
        row: {
          amount: ['Amount'],
          description: ['Description'],
          'quickbooks_account_id': ['DepositLineDetail.AccountRef.value']
        }
      }
    }),
    transform: async (mapped, { companyCode, logger, getLocationId, getAccountIds, existsPaymentPlanWithLrAccount, getCustomerIdThruPaymentPlan }) => {
      const { entity, lines } = mapped
      const accIds = await getAccountIds()
      const rows = []
      for (const line of lines) {
        const quickbooksAccountId = line.row.quickbooks_account_id
        if (
          !accIds.accountReceivableIds.includes(quickbooksAccountId) &&
          !(await existsPaymentPlanWithLrAccount(quickbooksAccountId))
        ) {
          continue
        }
        let customerId = line.meta.customerId
        // if deposit don't have assigned customer we let's try find customer
        // thru payment plan
        if (!customerId) {
          customerId = await getCustomerIdThruPaymentPlan(line.row.quickbooks_account_id)
        }
        const locationId = await getLocationId(customerId)
        if (!locationId) {
          logger.warn({ code: 'location-not-found', customerId, companyCode, entity })
          continue
        }
        rows.push({
          ...entity.row,
          ...line.row,
          'id': uuid.v4(),
          'location_id': locationId
        })
      }
      return rows
    },
    persist: async (transformed, { insertTransactions }) => {
      return insertTransactions(transformed)
    }
  },
  JournalEntry: {
    map: (rawRow) => mapRaw(rawRow, {
      entity: {
        row: commonColumns
      },
      line: {
        meta: {
          customerId: ['JournalEntryLineDetail.Entity.EntityRef.value'],
          postingType: ['JournalEntryLineDetail.PostingType']
        },
        row: {
          amount: ['Amount'],
          description: ['Description'],
          'quickbooks_account_id': ['JournalEntryLineDetail.AccountRef.value']
        }
      }
    }),
    transform: async (mapped, helpers) => {
      const { entity, lines } = mapped
      const {
        companyCode,
        logger,
        getAccountIds,
        getLocationId,
        getLoanReceivablesAccountIds
      } = helpers
      const accIds = await getAccountIds()
      const linesByCustomer = new Map()
      const lrAccsByCustomer = new Map()
      const bonusLines = []
      const badDebtsLines = []
      if (isRefundJournalEntry(lines, accIds)) {
        const debits = lines.filter(l => l.meta.postingType === 'Debit')
        const amount = sumBy(debits, 'row.amount')
        const customerId = debits[0].meta.customerId
        const locationId = await getLocationId(customerId)
        return [{
          ...entity.row,
          ...debits[0].row,
          amount,
          'id': uuid.v4(),
          'txn_type': TXN_TYPES.refund,
          'location_id': locationId
        }]
      }
      for (const line of lines) {
        const { customerId, postingType: type } = line.meta
        const accId = line.row.quickbooks_account_id
        const { arLines, lrLines } = linesByCustomer.get(customerId) || { arLines: [], lrLines: [] }
        if (!lrAccsByCustomer.has(customerId)) {
          lrAccsByCustomer.set(customerId, await getLoanReceivablesAccountIds(customerId))
        }
        const lrIds = lrAccsByCustomer.get(customerId)
        if (accId === accIds.bonusAccountId && type === 'Debit') {
          bonusLines.push(line)
        } else if (accIds.badDebtsAccountIds.includes(accId) && type === 'Debit') {
          badDebtsLines.push(line)
        } else if (lrIds.includes(accId) && type === 'Debit' && isLoanJournalEntry(lines, accIds)) {
          lrLines.push(line)
        } else if (accIds.accountReceivableIds.includes(accId) && type === 'Credit') {
          arLines.push(line)
        }
        if (customerId) {
          linesByCustomer.set(customerId, { arLines, lrLines })
        }
      }
      const rows = []
      for (const [customerId, { arLines, lrLines }] of linesByCustomer.entries()) {
        const locationId = await getLocationId(customerId)
        if (!locationId) {
          logger.warn({ code: 'location-not-found', customerId, companyCode, entity })
          continue
        }

        let txnType = null

        if (lrLines.length > 0) {
          txnType = TXN_TYPES.loan
        } else if (badDebtsLines.length > 0) {
          txnType = TXN_TYPES.write_off
        } else if (bonusLines.length > 0) {
          txnType = TXN_TYPES.bonus
        }
        if (!txnType) {
          continue
        }
        const txnLines = txnType === TXN_TYPES.loan ? lrLines : arLines
        // Write-offs should be mapped at any case. We require a condition to ensure that it is not already present in the txnLines array.
        if (txnType === 'write-off' && !txnLines.length) {
          txnLines.push(...badDebtsLines)
        }
        rows.push(...txnLines.map(line => ({
          ...entity.row,
          ...line.row,
          'id': uuid.v4(),
          'txn_type': txnType,
          'location_id': locationId
        })))
      }
      return rows
    },
    persist: async (transformed, { insertTransactions }) => {
      return insertTransactions(transformed)
    }
  },
  Purchase: {
    map: (rawRow) => mapRaw(rawRow, {
      entity: {
        row: {
          ...commonColumns,
          'txn_type': TXN_TYPES.refund
        }
      },
      line: {
        meta: {
          customerId: ['AccountBasedExpenseLineDetail.CustomerRef.value']
        },
        row: {
          amount: ['Amount'],
          description: ['Description'],
          'quickbooks_account_id': ['AccountBasedExpenseLineDetail.AccountRef.value']
        }
      }
    }),
    transform: async (mapped, { companyCode, logger, getAccountIds, getLocationId, existsPaymentPlanWithLrAccount, getCustomerIdThruPaymentPlan }) => {
      const { entity, lines } = mapped
      const rows = []
      const accIds = await getAccountIds()
      for (const line of lines) {
        const quickbooksAccountId = line.row.quickbooks_account_id
        if (
          !accIds.accountReceivableIds.includes(quickbooksAccountId) &&
          !(await existsPaymentPlanWithLrAccount(quickbooksAccountId))
        ) {
          continue
        }
        let customerId = line.meta.customerId
        if (!customerId) {
          customerId = await getCustomerIdThruPaymentPlan(line.row.quickbooks_account_id)
        }
        const locationId = await getLocationId(customerId)
        if (!locationId) {
          logger.warn({ code: 'location-not-found', customerId, companyCode, entity })
          continue
        }
        rows.push({
          ...entity.row,
          ...line.row,
          'id': uuid.v4(),
          'location_id': locationId
        })
      }
      return rows
    },
    persist: async (transformed, { insertTransactions }) => {
      return insertTransactions(transformed)
    }
  }
}

const getMapping = (quickbooksType) => {
  return entityMappings[quickbooksType]
}

module.exports = {
  getMapping
}
