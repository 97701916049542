import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import NumberFormat from 'react-number-format'

const QuantityTotal = ({
  value,
  size,
  color,
  alignEdge,
  isProvisional,
  className,
  fallbackText,
  ...otherProps
}) => {
  const classNames = classnames(
    'vs-quantity-total',
    {'vs-quantity-total--color-dark': color === 'dark'},
    {'vs-quantity-total--color-light': color === 'light'},
    {'vs-quantity-total--color-warning': color === 'warning'},
    {'vs-quantity-total--provisional': isProvisional},
    {'vs-quantity-total--size-large': size === 'large'},
    {'vs-quantity-total--size-regular': size === 'regular'},
    {'vs-quantity-total--size-small': size === 'small'},
    {'vs-quantity-total--align-content': alignEdge === 'content'},
    className)

  if (Number.isFinite(value)) {
    return (
      <NumberFormat
        value={value}
        displayType='text'
        thousandSeparator
        renderText={value => (
          <span className={classNames}>
            {value}
          </span>
        )}
        {...otherProps}
      />
    )
  }

  if (fallbackText) {
    return (
      <span className={classNames}>
        {fallbackText}
      </span>
    )
  }

  return null
}

QuantityTotal.propTypes = {
  /**
   * the unformatted quantity to show as a total
   */
  value: PropTypes.number,
  /**
   * Sets background color, based on the role it has in the interface
   */
  color: PropTypes.oneOf(['light', 'dark', 'warning']),
  /**
   * Determine the size of the label
   */
  size: PropTypes.oneOf(['inherit', 'small', 'regular', 'large']),
  /**
   * Pull out the edge so that text sits flush with neighbours
   */
  alignEdge: PropTypes.oneOf(['box', 'content']),
  /**
   * Text to show optionally if value is not available
   */
  fallbackText: PropTypes.string,
  /**
   * Switches on the appearance for provisional numbers.
   */
  isProvisional: PropTypes.bool
}

QuantityTotal.defaultProps = {
  size: 'regular',
  color: 'light',
  alignEdge: 'box',
  value: undefined,
  fallbackText: undefined
}

export default QuantityTotal
