import React from 'react'
import PropTypes from 'prop-types'
import Text from '../Text'

const SignatureDisplay = ({ signature, name }) => {
  return (
    <React.Fragment>
      <div className='signature-display vs-u-margin-top-tripple'>
        <img
          alt='signature'
          src={signature}
        />
      </div>
      <Text weight='bold'>{ name ? `Signed by: ${name}` : 'Signature' }</Text>
    </React.Fragment>
  )
}

SignatureDisplay.defaultProps = {
  signature: null,
  name: null
}

SignatureDisplay.propTypes = {
  signature: PropTypes.string,
  name: PropTypes.string
}

export default SignatureDisplay
