const pick = require('lodash/pick')
const { v4 } = require('uuid')
const get = require('lodash/get')
const { QuickbooksInterface } = require('./quickbooks-interface')
const QuickbooksLocationAdapter = require('./quickbooks-location-adapter')
const QuickbooksTransactionsAdapter = require('./quickbooks-transactions-adapter')
const QuickbooksRawTransactionsAdapter = require('./quickbooks-raw-transactions-adapter')
const QuickbooksTransformAdapter = require('./quickbooks-transform-adapter')
const { transformInvoiceToDeposit, transformPaymentPlanToDeposit } = require('./tools/transform-to-deposit')
const transformToPayment = require('./tools/transform-to-payment')
const { constructLineItem } = require('./tools/journal-entry-utils')
const utils = require('./tools/utils')
const { EntitiesApi } = require('./entities/api.js')

const companyCodes = [{
  companyCode: 'KE',
  geoId: 'country:ke',
  currency: 'KES',
  currencyName: 'Kenyan Shilling'
}, {
  companyCode: 'NG',
  geoId: 'country:ng',
  currency: 'NGN',
  currencyName: 'Nigerian Naira'
}]

const getCompany = (locationId) => {
  const company = companyCodes.find(company => locationId.startsWith(company.geoId))
  if (!company) {
    const error = new Error(`Cant find company code for location ${locationId}`)
    error.status = 400
    throw error
  }
  return company
}

class QuickbooksAdapter {
  constructor (
    state,
    pgConnection,
    quickbooksConfigs,
    isomorphicFetch,
    quickbooksOAuthClient,
    notificationsApi,
    logger
  ) {
    const { user = {} } = state
    const username = user.name
    this.tools = {
      ...utils
    }

    this.logger = logger
    if (quickbooksConfigs && quickbooksConfigs.quickbooksEnabled) {
      this.quickbooksInterface = new QuickbooksInterface(
        pgConnection,
        quickbooksConfigs,
        isomorphicFetch,
        quickbooksOAuthClient,
        logger
      )

      this.entities = new EntitiesApi(this)
    }

    if (pgConnection) {
      this.transactions = new QuickbooksTransactionsAdapter(
        pgConnection,
        username
      )

      this.rawTransactions = new QuickbooksRawTransactionsAdapter(
        pgConnection,
        username
      )

      this.transform = new QuickbooksTransformAdapter(
        pgConnection,
        username,
        logger,
        this.rawTransactions,
        this.quickbooksInterface,
        notificationsApi,
        this.tools
      )

      this.location = new QuickbooksLocationAdapter(
        pgConnection,
        username
      )
    }
    // For our quickbooks sandbox, the default currency setting is set to USD
    // we do this so that staging and dev can work when testing
    if (quickbooksConfigs && quickbooksConfigs.environment === 'sandbox') {
      companyCodes.forEach(company => {
        company.currency = 'USD'
        company.currencyName = 'United States Dollar'
      })
    }
  }

  async createPaymentPlanDeposit ({
    transaction,
    paymentPlan,
    paymentInstallment,
    isPaystackDeposit,
    instalments = []
  }) {
    await this.quickbooksInterface.init()

    const {
      paymentPlanFeesAccountId,
      remitaFeesAccountId,
      classRef
    } = this.quickbooksInterface.accountIds[paymentPlan.company_code]

    const depositAccountId = this.quickbooksInterface.accountIds[paymentPlan.company_code][isPaystackDeposit ? 'paystackDepositAccountId' : 'depositAccountId']

    const { results } = await this.location.getList({
      ordering: '-created_at',
      filter: {
        location_id: paymentPlan.location_id,
        company_code: paymentPlan.company_code
      },
      limit: 1
    })

    const quickBooksLocation = results[0]

    const {Customer: qboCustomer} = await this.quickbooksInterface.get(
      quickBooksLocation.company_code,
      `/customer/${quickBooksLocation.quickbooks_customer_id}`
    )

    const { Account: depositAccount } = await this.quickbooksInterface.get(
      paymentPlan.company_code,
      `/account/${depositAccountId}`
    )

    const {Account: loanReceivableAccount} = await this.quickbooksInterface.get(
      paymentPlan.company_code,
      `/account/${paymentPlan.quickbooks_account_id}`
    )

    const lrAccountFeeDeposits = await this.rawTransactions.getLoanAccountFeeDeposits(
      paymentPlan.quickbooks_account_id,
      paymentPlan.company_code
    )

    let remitaFeesAccount = null
    if (remitaFeesAccountId && transaction) {
      const { Account } = await this.quickbooksInterface.get(
        paymentPlan.company_code,
        `/account/${remitaFeesAccountId}`
      )
      remitaFeesAccount = Account
    }

    let paymentPlanFeesAccount = null
    if (paymentPlan.service_fee && paymentPlanFeesAccountId) {
      const { Account } = await this.quickbooksInterface.get(
        paymentPlan.company_code,
        `/account/${paymentPlanFeesAccountId}`
      )
      paymentPlanFeesAccount = Account
    }

    const depositData = transformPaymentPlanToDeposit({
      transaction,
      qboCustomer,
      paymentPlan,
      paymentInstallment,
      depositAccount,
      loanReceivableAccount,
      remitaFeesAccount,
      paymentPlanFeesAccount,
      classRef,
      lrAccountFeeDeposits,
      instalmentsFullyPaid: instalments.filter(i => i.is_paid).length
    })
    const deposit = await this.quickbooksInterface.post(paymentPlan.company_code, 'deposit', depositData)
    await this.transform.transformAndSave(paymentPlan.company_code, { Deposit: [deposit.Deposit] })
    return {
      deposit
    }
  }

  async createPaymentInvoiceDeposit ({
    transaction,
    quickbooksInvoice,
    qboCustomer,
    quickbooksLocation,
    depositAccount,
    payment,
    remitaFee
  }) {
    const {
      accountReceivableIds,
      remitaFeesAccountId,
      classRef
    } = this.quickbooksInterface.accountIds[quickbooksLocation.company_code]
    const [accountReceivableId] = accountReceivableIds
    const { Account: accountReceivable } = await this.quickbooksInterface.get(
      quickbooksLocation.company_code,
      `/account/${accountReceivableId}`
    )

    const { Account: remitaFeesAccount } = await this.quickbooksInterface.get(
      quickbooksLocation.company_code,
      `/account/${remitaFeesAccountId}`
    )

    const depositData = transformInvoiceToDeposit({
      transaction,
      quickbooksInvoice,
      qboCustomer,
      depositAccount,
      accountReceivable,
      remitaFeesAccount,
      classRef,
      payment,
      remitaFee
    })

    const { Deposit } = await this.quickbooksInterface.post(quickbooksLocation.company_code, 'deposit', depositData)

    return {
      deposit: Deposit,
      qboCustomer,
      depositAccount
    }
  }

  async createPayment ({
    quickbooksInvoice,
    totalAmount
  }) {
    const {results} = await this.location.getList({
      ordering: '-created_at',
      filter: {
        location_id: quickbooksInvoice.location_id,
        company_code: quickbooksInvoice.company_code
      }
    })
    if (!results.length) {
      const error = new Error(`Failed booking: quickbooks_location ${quickbooksInvoice.location_id} not found`)
      error.status = 400
      throw error
    }

    const quickbooksLocation = results[0]
    await this.quickbooksInterface.init()

    const {
      depositAccountId
    } = this.quickbooksInterface.accountIds[quickbooksLocation.company_code]

    const {Customer: qboCustomer} = await this.quickbooksInterface.get(
      quickbooksLocation.company_code,
      `/customer/${quickbooksLocation.quickbooks_customer_id}`
    )

    const { Account: depositAccount } = await this.quickbooksInterface.get(
      quickbooksLocation.company_code,
      `/account/${depositAccountId}`
    )

    const paymentData = {
      TotalAmt: totalAmount,
      CustomerRef: {
        value: qboCustomer.Id,
        name: qboCustomer.DisplayName
      }
    }
    const { Payment } = await this.quickbooksInterface.post(
      quickbooksInvoice.company_code,
      `payment`,
      paymentData
    )

    return {
      payment: Payment,
      quickbooksLocation,
      qboCustomer,
      depositAccount
    }
  }

  async updatePayment ({
    quickbooksInvoice,
    payment,
    totalAmount
  }) {
    const transformedData = transformToPayment({
      payment: pick(payment, ['TotalAmt', 'CurrencyRef', 'Id', 'SyncToken', 'CustomerRef', 'MetaData', 'LinkedTxn']),
      quickbooksInvoice,
      totalAmount
    })

    const {Payment: finalPayment} = await this.quickbooksInterface.post(
      quickbooksInvoice.company_code,
      `payment`,
      transformedData
    )
    return {
      payment: finalPayment
    }
  }

  async getCustomerByLocation (location) {
    const company = getCompany(location._id)
    const { QueryResponse } = await this.quickbooksInterface.get(
      company.companyCode,
      `query?query=${encodeURIComponent(`select * from Customer Where DisplayName = '${location.name}'`)}`
    )
    return get(QueryResponse, 'Customer.0')
  }

  async getAccountByName ({companyCode, accountName}) {
    const { QueryResponse } = await this.quickbooksInterface.get(
      companyCode,
      `query?query=${encodeURIComponent(`select * from Account Where Name = '${accountName}'`)}`
    )
    return get(QueryResponse, 'Account.0')
  }

  async createCustomer (location) {
    const company = getCompany(location._id)
    const qboData = this.quickbooksInterface.transformLocationToQboCustomer(location)
    const date = new Date().toJSON()
    let customer = await this.getCustomerByLocation(location)
    if (!customer) {
      const { Customer } = await this.quickbooksInterface.post(company.companyCode, 'customer', qboData)
      customer = Customer
    }
    return this.location.create({
      id: v4(),
      created_at: date,
      updated_at: date,
      company_code: company.companyCode,
      quickbooks_customer_id: customer.Id,
      quickbooks_sync_token: customer.SyncToken, // SyncToken is required for doing update
      location_id: location.additionalData.uuid
    })
  }

  async createAccount ({
    locationFsid,
    accountName,
    accountType,
    accounSubType,
    description,
    classification,
    parentAccountId,
    isSubAccount
  }) {
    const company = getCompany(locationFsid)
    const newAccount = {
      Name: accountName, // is unique
      AccountType: accountType,
      AccountSubType: accounSubType,
      Description: description,
      CurrentBalance: 0,
      Classification: classification,
      SubAccount: false,
      CurrencyRef: {
        value: company.currency,
        name: company.currencyName
      }
    }
    if (isSubAccount) {
      newAccount.SubAccount = true
      newAccount.ParentRef = {
        value: parentAccountId
      }
    }

    const { Account } = await this.quickbooksInterface.post(
      company.companyCode,
      `account`,
      newAccount
    )
    return Account
  }

  async createJournalEntry ({location, description, loanReceivable, accountReceivable, amount, docNumber}) {
    const company = getCompany(location._id)
    const uuid = location.uuid || location.additionalData.uuid

    const { results } = await this.location.getList({
      filter: {
        location_id: uuid,
        company_code: company.companyCode
      }
    })
    if (!results || !results.length) {
      const error = new Error(`Cant find quickbooks customer for location ${uuid}`)
      error.status = 400
      throw error
    }

    const entity = {
      Type: 'Customer',
      EntityRef: {
        value: results[0].quickbooks_customer_id,
        name: location.name
      }
    }
    const accountName = `${accountReceivable.Name} - ${company.currency}` // account to be credited
    const creditAccount = constructLineItem({
      description,
      amount,
      postingType: 'Credit',
      entity,
      accountId:
      accountReceivable.Id,
      accountName
    })
    const debitAccount = constructLineItem({
      description,
      amount,
      postingType: 'Debit',
      entity,
      accountId: loanReceivable.Id,
      accountName: loanReceivable.Name
    })
    const newJournalEntry = {
      CurrencyRef: {
        value: company.currency,
        name: company.currencyName
      },
      Line: [creditAccount, debitAccount],
      DocNumber: docNumber || `Auto PP LrId-${loanReceivable.Id}`
    }

    const { JournalEntry } = await this.quickbooksInterface.post(
      company.companyCode,
      `journalentry`,
      newJournalEntry
    )
    return JournalEntry
  }

  // via a payment
  async linkInvoiceToJournalEntry ({location, amount, txnId, journalId}) {
    const company = getCompany(location._id)
    const uuid = location.uuid || location.additionalData.uuid

    const { results } = await this.location.getList({
      filter: {
        location_id: uuid,
        company_code: company.companyCode
      }
    })
    if (!results) {
      const error = new Error(`Cant find quickbooks customer for location ${uuid}`)
      error.status = 400
      throw error
    }

    const newPayment = {
      CustomerRef: { value: results[0].quickbooks_customer_id, name: location.name },
      CurrencyRef: { value: company.currency, name: company.currencyName },
      Line: [
        {
          Amount: amount,
          LinkedTxn: [{TxnId: journalId, TxnType: 'JournalEntry'}]
        },
        {
          Amount: amount,
          LinkedTxn: [{TxnId: txnId, TxnType: 'Invoice'}]
        }
      ]
    }
    const { Payment } = await this.quickbooksInterface.post(
      company.companyCode,
      `payment`,
      newPayment
    )
    return Payment
  }

  async bookQboTransaction ({ journalEntryId, paymentId, qboInvoiceId, companyCode, qboEntities = {} }) {
    const { Invoice } = await this.quickbooksInterface.get(
      companyCode,
      `/invoice/${qboInvoiceId}`
    )
    qboEntities.Invoice = [Invoice]

    if (!qboEntities.JournalEntry) {
      const { JournalEntry } = await this.quickbooksInterface.get(
        companyCode,
        `/journalentry/${journalEntryId}`
      )
      qboEntities.JournalEntry = [JournalEntry]
    }
    if (!qboEntities.Payment) {
      const { Payment } = await this.quickbooksInterface.get(
        companyCode,
        `/payment/${paymentId}`
      )
      qboEntities.Payment = [Payment]
    }
    return this.transform.transformAndSave(companyCode, qboEntities)
  }

  async convertPaymentPlanToInvoice ({
    locationName,
    loanQboAccountId,
    qboDocNumber,
    invoiceTxnId,
    companyCode,
    locationUuid,
    locationFsid,
    paymentPlanId,
    journalEntryId,
    paymentId,
    pastDueAmount,
    paymentPlanApi
  }) {
    const parentAccounts = utils.getParentAccounts(this.quickbooksInterface.accountIds, companyCode)
    const parsedName = utils.removeSpecialChars(locationName)
    const loanAccountName = `L/R - ${parsedName} - ${qboDocNumber}`
    const cacheQboIds = new Map()
    const qboEntities = { JournalEntry: null, Payment: null }

    try {
      let loanAccount = await this.getAccountByName({
        companyCode: companyCode,
        accountName: loanAccountName
      })

      if (!loanQboAccountId || !loanAccount) {
        if (!loanAccount) {
          loanAccount = await this.createAccount({
            locationFsid: locationFsid,
            accountName: loanAccountName,
            accountType: 'Other Current Asset',
            accounSubType: 'LoansToOthers',
            classification: 'Asset',
            description: `Payment Plan for ${locationName.slice(0, 60)} ${qboDocNumber}`,
            isSubAccount: true,
            parentAccountId: parentAccounts.loanReceivableId
          })
        }
        await paymentPlanApi.updatePaymentPlan({id: paymentPlanId, quickbooks_account_id: loanAccount.Id})
      }
      cacheQboIds.set('accountId', loanAccount.Id)

      if (journalEntryId) {
        try {
          const { JournalEntry } = await this.quickbooksInterface.get(
            companyCode,
            `/journalentry/${journalEntryId}`
          )
          qboEntities.JournalEntry = [JournalEntry]
          cacheQboIds.set('journalId', JournalEntry.Id)
        } catch (error) {
          this.logger.warn(`Get JournalEntry failed: `, error)
        }
      }

      if (!journalEntryId || !qboEntities.JournalEntry) {
        const journalEntry = await this.createJournalEntry({
          location: {
            _id: locationFsid,
            uuid: locationUuid
          },
          description: `PP - ${qboDocNumber}`,
          accountReceivable: {
            Id: parentAccounts.accountReceivableId,
            Name: 'Accounts Receivable:Accounts Receivable (A/R)'
          },
          loanReceivable: {
            Id: loanQboAccountId || cacheQboIds.get('accountId'),
            Name: loanAccountName
          },
          amount: pastDueAmount,
          docNumber: null
        })
        cacheQboIds.set('journalId', journalEntry.Id)
        qboEntities.JournalEntry = [journalEntry]
        await paymentPlanApi.updatePaymentPlan({id: paymentPlanId, quickbooks_journal_entry_id: journalEntry.Id})
      }

      if (paymentId) {
        try {
          const { Payment } = await this.quickbooksInterface.get(
            companyCode,
            `/payment/${paymentId}`
          )
          qboEntities.Payment = [Payment]
        } catch (error) {
          this.logger.warn(`Get Payment failed: `, error)
        }
      }

      if (!paymentId || !qboEntities.Payment) {
        const payment = await this.linkInvoiceToJournalEntry({
          location: {
            _id: locationFsid,
            uuid: locationUuid
          },
          amount: pastDueAmount,
          txnId: invoiceTxnId,
          journalId: journalEntryId || cacheQboIds.get('journalId')
        })
        qboEntities.Payment = [payment]
        await paymentPlanApi.updatePaymentPlan({id: paymentPlanId, quickbooks_payment_id: payment.Id})
      }

      await this.bookQboTransaction({
        paymentId,
        journalEntryId,
        companyCode,
        qboInvoiceId: invoiceTxnId,
        qboEntities
      })
      return paymentPlanApi.activatePaymentPlan(paymentPlanId)
    } catch (error) {
      throw error
    }
  }
}

module.exports = QuickbooksAdapter
