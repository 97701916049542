import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import StatusLabel from '../StatusLabel'

const StatusLabelButton = (props) => {
  const {
    status,
    label,
    tight,
    inactive,
    pointer,
    ...otherProps
  } = props

  return (
    <button
      type='button'
      className={classnames(
        'vs-status-label-button',
        { 'vs-status-label-button--success': status === 'success' },
        { 'vs-status-label-button--warning': status === 'warning' },
        { 'vs-status-label-button--attention': status === 'attention' },
        { 'vs-status-label-button--inactive': inactive },
        { 'vs-status-label-button--pointer': pointer },
        { 'vs-status-label-button--pointer-on-bottom': pointer === 'bottom' }
      )}
      {...otherProps}
    >
      <StatusLabel
        status={status}
        label={label}
        inactive={inactive}
        tight={tight}
      />
    </button>
  )
}

StatusLabelButton.propTypes = {
  /**
   * The mode of the button, will decide the color scheme and be passed on to the status label
   */
  status: PropTypes.oneOf(['success', 'warning', 'attention']).isRequired,
  /**
   * Should this button render with a pointer
   */
  pointer: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['bottom'])]),
  /**
   * Use less vertical padding
   */
  tight: PropTypes.bool,
  /**
   * If set, the color scheme will indicate that the button is inactive.
   * This can be used for e.g. a status toggle or switcher
   */
  inactive: PropTypes.bool
}

StatusLabelButton.defaultProps = {
  pointer: false,
  tight: false,
  inactive: false
}

export default StatusLabelButton
