import React, { Component } from 'react'
import { Route, Switch, Link } from 'react-router-dom'

import { configure as configurePage } from '@fielded/shared-ui/src/components/Page'

import { withApi } from '../../common/ApiProvider'

import GuideDownload from './Guides/GuidesContainer'
export class InformationApp extends Component {
  componentDidMount () {
    configurePage({
      linkRenderer: function (props) { return (<Link {...props} />) }
    })
  }

  render () {
    const {config} = this.props

    return (
      <div className='information'>
        <Switch>
          <Route
            exact
            path='/information/guide'
            render={props => (
              <GuideDownload
                {...props}
                config={config}
              />
            )}
          />
        </Switch>
      </div>
    )
  }
}

export default withApi(InformationApp)
