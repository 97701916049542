export const formatErrorMessage = (message, entered, allowed) => {
  return message.replace('{entered}', entered).replace('{allowed}', allowed)
}

export const multipleOf = ({value, checkValue, message}) => {
  const errorMessage = message || 'Please enter a value that is a multiple of {allowed}.'
  if (value && value % checkValue !== 0) {
    return formatErrorMessage(errorMessage, value, checkValue)
  }
}

export const maximum = ({value, checkValue, message}) => {
  const errorMessage = message || 'Value may not exceed {allowed}.'
  if (value && value > checkValue) {
    return formatErrorMessage(errorMessage, value, checkValue)
  }
}

export const minimum = ({value, checkValue, message}) => {
  const errorMessage = message || 'Value must be at least {allowed}.'
  if (value && value < checkValue) {
    return formatErrorMessage(errorMessage, value, checkValue)
  }
}

export const validateEmptyFields = ({message, fields}) => {
  // returns true if all fields are empty
  const checkIfEmptyFields = (fields) => {
    return Object.keys(fields)
      .filter(field => !field.includes('standard-remark'))
      .reduce((previousValue, fieldName) => {
        if (!previousValue) {
          return false
        }
        const fieldValue = fields[fieldName]
        const { amount } = fieldValue
        return Boolean(!amount)
      }, true)
  }
  const hasOnlyZeroValues = checkIfEmptyFields(fields)
  if (!hasOnlyZeroValues) {
    return message
  }
}
