const keyBy = require('lodash/keyBy')

// TODO: add products to this as well
// also, consider if we branch from PSM's listLocations, listProducts calls
// or switch those to be unique on orders as well.
exports.entitiesWithRelations = function ({orderEntities, locations}) {
  const locationsById = keyBy(locations, '_id')
  return orderEntities.map(order => {
    return Object.assign({}, order, {location: locationsById[order.destinationId]})
  })
}
