import React from 'react'

import { ViewSwitcher } from '@fielded/shared-ui'
import { ListUl, Search } from '@fielded/shared-ui/src/icons'

import { LIST_VIEW, SEARCH_VIEW } from './Locations'

const LocationsHeader = ({
  activeView,
  onSetViewClick,
  history,
  programUrl
}) => (
  <header className='main-column__header'>
    <div className='main-column__controls'>
      <ViewSwitcher
        onOptionClick={view => onSetViewClick(view)}
        activeOption={activeView}
        options={[
          {
            label: 'Search',
            icon: <Search />,
            viewKey: SEARCH_VIEW
          },
          {
            label: 'List',
            icon: <ListUl />,
            viewKey: LIST_VIEW
          }
        ]}
      />
    </div>
  </header>
)

export default LocationsHeader
