const { PouchAdapter } = require('../../common')
const schema = require('../schema')
const { listDeliveryDates } = require('../api/read/list-delivery-dates')
const { listCountDates } = require('../api/read/list-count-dates')

class RoutePouchAdapter extends PouchAdapter {
  constructor (user, routesDB) {
    super(user, schema, routesDB)
  }
}
RoutePouchAdapter.prototype.listDeliveryDates = listDeliveryDates
RoutePouchAdapter.prototype.listCountDates = listCountDates

module.exports = RoutePouchAdapter
