import RestAdapter from '@fielded/fs-api/lib/common/rest-adapter'
import CurrentUserApiAdapter from '@fielded/fs-api/lib/current-user'

class ActivateApi {
  constructor (props) {
    const restAdapter = new RestAdapter(global.window.fetch, '', props.agaveApiUrl)
    this.currentUser = new CurrentUserApiAdapter(restAdapter)
  }

  checkToken (signupToken) {
    return this.currentUser.checkToken(signupToken)
  }

  submit (signupToken, data) {
    return this.currentUser.activate(signupToken, data)
  }

  resendWelcomeEmail (email) {
    return this.currentUser.resendWelcomeEmail(email)
  }
}

export default ActivateApi
