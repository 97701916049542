const pluckDocs = require('../../../utils/pluck-docs')
const { construct } = require('../../tools')

exports.getByIds = async function (state, productIds, opts = {}) {
  if (opts.resolveAlias) {
    const aliasValues = productIds.map(id => id.split(':')[1])
    // we only read one alias at the time
    // let's be consistent with the other aliased reads
    // { resolveAlias: { 'one': true } }
    const [aliasName] = Object.keys(opts.resolveAlias)
    const result = await state.dal.product.getByAlias(state, aliasValues, aliasName)
    const docs = pluckDocs(result)
    return docs.map(doc => construct(doc, { now: opts.date || new Date().toISOString() }))
  }

  return state.dal.product.getByIds(state, productIds, opts)
}
